import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';

import { IOpportunityService } from '../../services/opportunity-service';

export class ContactSection {
  private opportunity: ModelTypes["deal"];

  constructor(@IOpportunityService private service: IOpportunityService) { }

  attached() {
    this.opportunity = this.service.opportunitySubscription;
  }

  @watch('service.opportunitySubscription')
  async handleDealSubscription(newData: ModelTypes['deal'], oldData: ModelTypes['deal']) {
    this.opportunity = newData;
  }

  private sendEmail() {
    window.open(`mailto:${this.opportunity.email}`, '_blank');
  }

  private phoneCall() {
    let phone = this.opportunity.phone
    if (phone.includes("+55")) {
      phone = phone.replace("+55", "")
    }
    if (phone.includes("-")) {
      phone = phone.replace("-", "")
    }
    if (phone.includes(" ")) {
      phone = phone.replace(" ", "")
    }
    if (phone.startsWith("0")) {
      phone = phone.substring(1)
    }

    window.open(`tel:0${phone}`);
  }

  private showWhatsApp() {
    let whatsappPhone = this.opportunity.phone
    if (whatsappPhone.includes("+55")) {
      whatsappPhone = whatsappPhone.replace("+55", "")
    }
    if (whatsappPhone.includes("-")) {
      whatsappPhone = whatsappPhone.replace("-", "")
    }
    if (whatsappPhone.includes(" ")) {
      whatsappPhone = whatsappPhone.replace(" ", "")
    }
    if (whatsappPhone.startsWith("0")) {
      whatsappPhone = whatsappPhone.substring(1)
    }

    window.open(`https://api.whatsapp.com/send?phone=55${whatsappPhone}&text=Olá%20${this.opportunity.name},%20tudo%20bem?`);
  }
}