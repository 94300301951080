<import from='@components/promise-bind/error/error-component' />
<import from='@components/promise-bind/pending/pending-component' />

<import from="./../integration-card/integration-card-component"></import>
<import from="./../integration-card-soon/integration-card-soon-component"></import>

<div id="add-integration-modal" data-modal-target="modal-output-brand" data-modal-backdrop="static" aria-hidden="true"
  tabindex="-1"
  class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative w-full max-w-7xl max-h-full">

    <!-- begin::modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

      <!-- begin::modal header -->
      <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">

        <div class="flex flex-col">
          <h5 class="text-xl font-semibold text-gray-900 dark:text-white">Adicionar integração</h5>
          <p class="text-sm font-normal text-gray-700 dark:text-gray-400">Selecione uma das redes sociais e clique em
            "configurar" para iniciar a integração</p>
        </div>

        <button type="button" data-modal-hide="add-integration-modal" click.trigger="hideModal()"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- end::modal header -->

      <!-- begin::modal body -->
      <div class="flex flex-col sm:flex-row gap-6 p-6">

        <div if.bind="state == 'selecting'" class="flex gap-2 flex-wrap w-full" promise.bind="getAppIntegrations()">

          <template pending>
            <pending-component></pending-component>
          </template>

          <template then.from-view="AppIntegration">
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 grow gap-4 p-4">
              <template repeat.for="integration of AppIntegration">

                <template if.bind="integration.type !== 'soon' ">
                  <integration-card-component class="w-full" integration.bind="integration"
                    configure-integration-callback.bind="e => configureIntegrationCallback(e)"></integration-card-component>
                </template>
                <template else>
                  <integration-card-soon-component class="w-full"
                    integration.bind="integration"></integration-card-soon-component>
                </template>

              </template>
            </div>
          </template>

          <template catch.from-view="error">
            <error-component></error-component>
          </template>

        </div>

        <div if.bind="state == 'configuring'" class="flex-1">
          <div class="mb-3">
            <validation-container>
              <label for="wp-site-url" class="form-label">URL do site</label>
              <div class="flex">
                <span
                  class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-400 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600">
                  https://
                </span>

                <div class="relative w-full">
                  <input value.bind="wordpressUrl & validate" type="text"
                    class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-400 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    id="wp-site-url" placeholder="Insira a URL do site">
                </div>
              </div>
            </validation-container>
          </div>
        </div>

      </div>
      <!-- end::modal body -->

      <!-- beggin::footer -->
      <div
        class="flex items-center justify-between p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button click.trigger="cancelOrBack()" type="button" class="btn-neutral">${state == 'selecting' ? 'Cancelar' :
          'Voltar'}</button>
        <button if.bind="state == 'configuring'" click.trigger="next()" type="button"
          class="btn-primary">Continuar</button>
      </div>
      <!-- end::footer -->

    </div>
    <!-- end::modal content -->
  </div>
</div>