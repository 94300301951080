<import from='@components/card-title/card-title-component' />

<section class="mb-6 w-full">
  <card-title-component title-id="conteudoSite"></card-title-component>

  <!-- begin::tab -->
  <div id="website-tabs">
    <ul
      class="flex -mb-px text-xs font-medium text-center w-full overflow-x-auto scrollbar-thin scrollbar-thumb-rounded-md scrollbar-thumb-gray-300 gap-4">
      <li class="flex-shrink-0 border rounded-lg border-orange-400 text-gray-500">
        <a load="home" class="focus:outline-none">
          <span
            class="inline-block text-sm font-bold px-7 py-3 cursor-pointer hover:bg-orange-800 rounded-lg hover:text-gray-100">Home</span>
        </a>
      </li>
      <li class="flex-shrink-0 border rounded-lg border-orange-400 text-gray-500">
        <a load="about" class="focus:outline-none">
          <span
            class="inline-block text-sm font-bold px-7 py-3 cursor-pointer hover:bg-orange-800 rounded-lg hover:text-gray-100">Sobre</span>
        </a>
      </li>
      <!-- <li class="flex-shrink-0 border rounded-lg border-orange-400 text-gray-500">
        <a load="product" class="focus:outline-none">
          <span class="inline-block text-sm font-bold px-7 py-3 cursor-pointer hover:bg-orange-800 rounded-lg hover:text-gray-100">Produtos</span>
        </a>
      </li> -->
      <li class="flex-shrink-0 border rounded-lg border-orange-400 text-gray-500">
        <a load="contact" class="focus:outline-none">
          <span
            class="inline-block text-sm font-bold px-7 py-3 cursor-pointer hover:bg-orange-800 rounded-lg hover:text-gray-100">Contato</span>
        </a>
      </li>
      <!-- <li class="flex-shrink-0 border rounded-lg border-orange-400 text-gray-500">
        <a load="blog" class="focus:outline-none">
          <span class="inline-block text-sm font-bold px-7 py-3 cursor-pointer hover:bg-orange-800 rounded-lg hover:text-gray-100">Blog</span>
        </a>
      </li> -->
      <li class="flex-shrink-0 border rounded-lg border-orange-400 text-gray-500">
        <a load="settings" class="focus:outline-none">
          <span
            class="inline-block text-sm font-bold px-7 py-3 cursor-pointer hover:bg-orange-800 rounded-lg hover:text-gray-100">Outros</span>
        </a>
      </li>
    </ul>
  </div>
  <!-- end::tab -->

  <div class="h-full overRflow-y-auto hide-scrollbar pt-2">
    <au-viewport></au-viewport>
  </div>

</section>