import { Modal } from 'flowbite'
import type { ModalOptions, ModalInterface } from 'flowbite'
import { ICustomElementViewModel, IEventAggregator } from 'aurelia';
import { AlertModalEvent, AlertModalType, IAlertModalEvent } from './events/alert-modal-event';

export class AlertModalComponent implements ICustomElementViewModel {
  alertModal: ModalInterface;

  label: string;
  message: string;
  confirmLabel: string;
  type: string;
  confirmFunction: Function;
  toastMessage: string | null = null;

  constructor(@IEventAggregator readonly ea: IEventAggregator) { }

  attached() {
    const $modalElement: HTMLElement = document.querySelector('#alert-modal');

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
    }

    this.alertModal = new Modal($modalElement, modalOptions);

    this.ea.subscribe(AlertModalEvent.SHOW, (payload: IAlertModalEvent) => {
      this.label = payload.label;
      this.message = payload.message;
      this.handleBtnClass(payload.type);
      this.confirmFunction = payload.confirmFunction;
      this.toastMessage = payload.toastMessage;

      this.showModal();
    });
  }

  showModal(): void {
    this.alertModal.show();
  }

  hideModal(): void {
    this.alertModal.hide();
  }

  handleBtnClass(type: AlertModalType): void {
    switch (type) {
      case AlertModalType.SUCCESS:
        this.type = 'btn-success';
        break;
      case AlertModalType.DANGER:
        this.type = 'btn-danger';
        break;
      case AlertModalType.WARNING:
        this.type = 'btn-warning';
        break;
      default:
        this.type = 'btn-primary';
        break;
    }
  }

  async executeConfirmationFunction(): Promise<void> {
    await this.confirmFunction();

    this.alertModal.hide();
  }

  static show({ event, payload }: { event: IEventAggregator, payload: IAlertModalEvent }): void {
    event.publish(AlertModalEvent.SHOW, payload);
  }

}