import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"
import { IZeusClient, ZeusClient } from "@services/graphql-service/zeus-client";
import { ModelTypes } from "@zeus";
import { toastifyError, toastifySuccess } from 'common/helpers/toastify';

export class EditCaptionDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private project: ModelTypes['project'];

  constructor(
    @IDialogDom private dialogDom: DefaultDialogDom,
    @IZeusClient private zeusClient: ZeusClient
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async activate(model?: any) {
    this.project = model.project;
  }

  async close() {
    this.$dialog.ok();
  }

  private async handleSaveTemplate(): Promise<void> {
    const response = await this.zeusClient.chain('mutation')({
      update_project_by_pk: [
        {
          pk_columns: {
            id: this.project.id
          },
          _set: {
            caption: this.project.caption
          }
        },
        {
          id: true
        }
      ]
    });

    if (!response?.update_project_by_pk?.id) {
      toastifyError({ message: 'Error ao salvar legenda' })
    }

    toastifySuccess({ message: 'Legenda salva com sucesso' })

    this.$dialog.ok();
  }
}