<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Nossa História">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">
      <div class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class="row">
          <div class="flex-1 my-2">
            <input-component type="text" label="Título Sobre a História da Marca"
              value.bind="website.aboutUsHistoryTitle" />
          </div>

          <div class="flex-1">
            <input-component type="text" label="Descrição Sobre a História da Marca"
              value.bind="website.aboutUsHistoryDescription" />
          </div>
        </div>
        <div class="row">
          <div class="flex-1 my-2">
            <input-component type="text" label="Título Relevante Sobre a História #1"
              value.bind="website.aboutUsHistorytKeypointTitleOne" />
          </div>

          <div class="flex-1">
            <input-component type="text" label="Subtítulo Relevante Sobre a História #1"
              value.bind="website.aboutUsHistorytKeypointSubtitleOne" />
          </div>
        </div>
        <div class="row">
          <div class="flex-1 my-2">
            <input-component type="text" label="Título Relevante Sobre a História #2"
              value.bind="website.aboutUsHistorytKeypointTitleTwo" />
          </div>

          <div class="flex-1">
            <input-component type="text" label="Subtítulo Relevante Sobre a História #2"
              value.bind="website.aboutUsHistorytKeypointSubtitleTwo" />
          </div>
        </div>
      </div>

      <div class="flex-1">
        <single-file-upload-component label="Banner Sobre a História"
          selected-file-id.bind="website.aboutUsHistoryBannerImageFileId"></single-file-upload-component>
      </div>

      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20"
          trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>