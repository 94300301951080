<import from='@components/promise-bind/error/error-component' />
<import from='@components/promise-bind/pending/pending-component' />

<div class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="pexels" role="tabpanel" aria-labelledby="pexels-tab">

  <div class="relative w-full">
    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
          clip-rule="evenodd"></path>
      </svg>
    </div>
    <input type="text" value.bind="query & debounce:300"
      class="bg-gray-50 border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder="Search for images">
  </div>

  <div if.bind="!query">
    <div class="flex flex-wrap items-center justify-center mt-6">
      <p class="mt-4 text-sm text-gray-500 dark:text-gray-400">Pesquise imagens digitando palavra-chave acima.</p>
    </div>
  </div>

  <div if.bind="query" class="relative">

    <div scroll.trigger="loadMore($event)"
      class="grid grid-cols-2 gap-2 mt-3 h-96 overflow-y-auto scrollbar scrollbar-h-1 scrollbar-w-1.5 scrollbar-thumb-rounded-md pr-1">
      <div repeat.for="parameter of pexelImages" class="grow shrink-0 basis-0">

        <div
          class="${selectedImageId == parameter.id ? 'border-4 border-orange-500 bg-orange-100' : ''} cursor-pointer items-center flex flex-col justify-center border border-gray-200 dark:border-gray-200 hover:bg-blue-50 hover:text-blue-500 dark:hover:text-blue-500 text-gray-400 dark:text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm p-1"
          click.trigger="selectPexelsImage(parameter.id)" for="kt_radio_buttons_2_option_2">
          <img src="${parameter.small ?? parameter.url}" class="w-full rounded-lg overflow-hidden">
        </div>

      </div>
    </div>

    <div if.bind="loading" class="absolute w-full bottom-0">
      <div role="status" class="flex justify-center pr-1.5 mr-3">
        <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill" />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>

  </div>

</div>