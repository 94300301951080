import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";

@valueConverter('filterLocalePrompt')
export class FilterLocalePrompt {

  constructor(@I18N private I18N: I18N) { }

  toView(variations) {
    if (!variations) return [];

    const locale = this.I18N.getLocale();
    const value = variations.filter((v: { language: string; }) => v.language === locale);

    if (!value) return [];

    return value.prompt;
  }
}