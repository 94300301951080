<!-- <div class="w-full mx-auto"> -->
<div class="h-full flex">
  <div class="flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-4 card">
    <!-- <div class="flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-12 card"> -->

    <!-- <div class="w-full px-4 flex justify-center">
      <div class="relative">
        <img src="${archetype.avatar}" alt="img-blur-shadow" class="shadow-lg dark:shadow-current rounded-2xl align-middle h-auto max-w-[100px] absolut -m-12 mx-auto ${archetype.adherence == 0 ? 'grayscale' : 'border-[3px] border-green-400 dark:border-green-600'}">
      </div>
    </div> -->

    <!-- <div class="flex flex-col items-center p-4 mt-11 h-full gap-2"> -->
    <div class="flex flex-col items-center p-4 h-full gap-2">
      <h5 class="text-sm font-bold text-gray-700 dark:text-gray-300">${personality.name ?? 'N/A'}</h5>
      <h5 class="text-sm font-bold text-gray-700 dark:text-gray-300">${personality.profession ?? 'N/A'}</h5>

      <h5 class="text-xs font-bold text-center text-gray-700 dark:text-gray-300 line-clamp-2 grow">${personality.overallDescription ?? '...'}</h5>
      <p class="leading-relaxed text-xs text-gray-700 dark:text-gray-400 mb-auto">${personality.interests[0]}</p>

      <button type="button" click.trigger="openAudienceDialog()" class="inline-block w-full px-8 py-2 mb-0 mt-1 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-lg shadow-none cursor-pointer leading-[1.4] ease-in text-[.75rem] hover:scale-[1.02] active:shadow-xs tracking-tight border-[#cb0c9f] text-[#cb0c9f] hover:border-[#cb0c9f] hover:bg-transparent hover:text-[#cb0c9f] hover:opacity-75 hover:shadow-none active:bg-[#cb0c9f] active:text-white active:hover:bg-transparent active:hover:text-[#cb0c9f]">
        Ver mais
      </button>

    </div>


  </div>
</div>