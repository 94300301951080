<div class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="media" role="tabpanel" aria-labelledby="media-tab">

  <div class="relative">
    <div class="grid grid-cols-2 gap-2 mt-3 h-96 overflow-y-auto scrollbar scrollbar-h-1 scrollbar-w-1.5 scrollbar-thumb-rounded-md pr-1">
      <div repeat.for="item of userMedia" class="grow shrink-0 basis-0">
        <div class="${selectedImageId == item.id ? 'border-4 border-orange-500 bg-orange-100' : 'border border-gray-200 dark:border-gray-200'} cursor-pointer items-center flex flex-col justify-center hover:bg-blue-50 focus:ring-4 focus:ring-blue-300 rounded-lg p-1" click.trigger="selectMedia(item.id)">
          <img src="${item.url}" class="w-full rounded-lg overflow-hidden">
        </div>
      </div>
    </div>
  </div>

</div>