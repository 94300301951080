import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR'

@valueConverter('dayOfMonth')
export class DayOfMonth {
  constructor(@I18N private I18N: I18N) { }

  toView(date) {
    return format(date, 'd', { locale: ptBR });
  }
}