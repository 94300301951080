import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface ITasksService {
  setToFinish(taskId: string): Promise<ModelTypes["task"]>;
  createTask(command: ModelTypes["task_insert_input"]): Promise<ModelTypes["task"]>;
}

export class TasksService implements ITasksService {
  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  async createTask(command: ModelTypes["task_insert_input"]): Promise<ModelTypes["task"]> {
    const result = await this.zeusClient.chain('mutation')({
      insert_task_one: [
        {
          object: command,
        },
        {
          id: true,
        }
      ]
    });

    return result.insert_task_one as ModelTypes["task"];
  }

  async setToFinish(taskId: string): Promise<ModelTypes["task"]> {
    const result = await this.zeusClient.chain('mutation')({
      update_task_by_pk: [
        {
          pk_columns: { id: taskId },
          _set: { finished: true },
        },
        {
          id: true,
        }
      ]
    });

    return result.update_task_by_pk as ModelTypes["task"];
  }

}

export const ITasksService = DI.createInterface<ITasksService>((x) => x.singleton(TasksService));
