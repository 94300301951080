import { I18N } from '@aurelia/i18n';
import { bindable } from 'aurelia';

interface TitlesProps {
  [key: string]: {
    [key: string]: {
      title: string;
      subtitle: string;
    }
  }
}

export class CardTitleComponent {
  @bindable icon: string;
  @bindable title: string;
  @bindable subtitle: string;
  @bindable titleId: string;

  constructor(@I18N private I18N: I18N) { }

  private titles: TitlesProps = {
    dashboard: {
      pt_BR: {
        title: "Painel de Controle",
        subtitle: "Gerencie todas as suas atividades em um só lugar",
      },
      en_US: {
        title: "Dashboard",
        subtitle: "Manage all your activities in one place",
      },
      es_ES: {
        title: "Panel de Control",
        subtitle: "Gestiona todas tus actividades en un solo lugar",
      },
    },
    marcas: {
      'pt_BR': {
        title: "Gerencie suas Marcas",
        subtitle: "Faça o mapeamento da sua marca para refinar os conteúdos criados pela inteligência artificial",
      },
      en_US: {
        title: "Manage Your Brands",
        subtitle: "Map your brand to refine content created by artificial intelligence",
      },
      es_ES: {
        title: "Administra tus etiquetas",
        subtitle: "Mapee su marca para refinar el contenido creado por inteligencia artificial",
      },
    },
    mídias: {
      'pt_BR': {
        title: "Gerencie suas Mídias",
        subtitle: "Encontre, adicione ou crie novas imagens e vídeos para seus projetos",
      },
      en_US: {
        title: "Manage your Media",
        subtitle: "Find, add, or create new images and videos for your projects.",
      },
      es_ES: {
        title: "Gestiona tus Medios",
        subtitle: "Encuentra, agrega o crea nuevas imágenes y videos para tus proyectos",
      },
    },
    projetos: {
      pt_BR: {
        title: "Gerencie seus Projetos",
        subtitle: "Organize e acompanhe o progresso de suas criações",
      },
      en_US: {
        title: "Manage your Projects",
        subtitle: "Organize and track the progress of your creations",
      },
      es_ES: {
        title: "Gestiona tus Proyectos",
        subtitle: "Organiza y sigue el progreso de tus creaciones",
      },
    },
    blog: {
      pt_BR: {
        title: "Gerencie seus Artigos",
        subtitle: "Organize os artigos do seu site e mantenha o crescimento em SEO",
      },
      en_US: {
        title: "Manage Your Articles",
        subtitle: "Organize your site's articles and maintain SEO growth",
      },
      es_ES: {
        title: "Gestiona tus Artículos",
        subtitle: "Organiza los artículos de tu sitio y mantén el crecimiento en SEO",
      },
    },
    conteudoSite: {
      pt_BR: {
        title: "Gerencie o Conteúdo do Seu Site",
        subtitle: "Troque textos, banners e informações de cada uma das páginas",
      },
      en_US: {
        title: "Manage Your Website Content",
        subtitle: "Update texts, banners, and information on each page"
      },
      es_ES: {
        title: "Gestiona el Contenido de tu Sitio Web",
        subtitle: "Cambia textos, banners e información de cada una de las páginas"
      },
    },
    projeto: {
      pt_BR: {
        title: "Parabéns! aqui está sua arte",
        subtitle: "Para agendar ou publicar a arte, clique no botão 'Publicar' e siga a orientação",
      },
      en_US: {
        title: "Congratulations! here is your art",
        subtitle: "To schedule or publish artwork, click the 'Publish' button and follow the guidance",
      },
      es_ES: {
        title: "¡Felicidades! aquí está tu arte",
        subtitle: "Para programar o publicar obras de arte, haga clic en el botón 'Publicar' y siga las instrucciones",
      },
    },
    calendario: {
      pt_BR: {
        title: "Calendário de Marketing",
        subtitle: "Crie um calendário mensal completo com ações para todos os canais de marketing",
      },
      en_US: {
        title: "Marketing Calendar",
        subtitle: "Create a complete monthly calendar with actions for all marketing channels",
      },
      es_ES: {
        title: "Calendario de marketing",
        subtitle: "Crea un completo calendario mensual con acciones para todos los canales de marketing",
      },
    },
    images: {
      pt_BR: {
        title: "Crie Imagens",
        subtitle: "Utilize a criatividade para gerar imagens incríveis com inteligência artificial"
      },
      en_US: {
        title: "Create Images",
        subtitle: "Use creativity to generate incredible images with artificial intelligence"
      },
      es_ES: {
        title: "Crear imágenes",
        subtitle: "Usa la creatividad para generar imágenes increíbles con inteligencia artificial"
      },
    },
    texts: {
      pt_BR: {
        title: "Crie Textos e Artigos",
        subtitle: "Crie artigos voltados para SEO, com a cara da sua marca e integrados com o seu site",
      },
      en_US: {
        title: "Create Texts and Articles",
        subtitle: "Create SEO-focused articles that reflect your brand and are integrated with your website",
      },
      es_ES: {
        title: "Crear textos y artículos",
        subtitle: "Crea artículos enfocados en SEO, con la identidad de tu marca e integrados con tu sitio web",
      },
    },
    posts: {
      pt_BR: {
        title: "Crie Publicações Para Redes Sociais",
        subtitle: "Amplie seu alcance e engajamento com conteúdo personalizado",
      },
      en_US: {
        title: "Create Social Media Posts",
        subtitle: "Expand your reach and engagement with personalized content",
      },
      es_ES: {
        title: "Crea Publicaciones para Redes Sociales",
        subtitle: "Amplía tu alcance y engagement con contenido personalizado",
      },
    },
    videos: {
      pt_BR: {
        title: "Crie Videos",
        subtitle: "Subtitle",
      },
      en_US: {
        title: "Crie Videos",
        subtitle: "Subtitle",
      },
      es_ES: {
        title: "Crie Videos",
        subtitle: "Subtitle",
      },
    },
    prints: {
      pt_BR: {
        title: "Crie Impressos",
        subtitle: "Subtitle",
      },
      en_US: {
        title: "Crie Impressos",
        subtitle: "Subtitle",
      },
      es_ES: {
        title: "Crie Impressos",
        subtitle: "Subtitle",
      },
    },
    presentations: {
      pt_BR: {
        title: "Crie Apresentações",
        subtitle: "Subtitle",
      },
      en_US: {
        title: "Crie Apresentações",
        subtitle: "Subtitle",
      },
      es_ES: {
        title: "Crie Apresentações",
        subtitle: "Subtitle",
      },
    },
    adverts: {
      pt_BR: {
        title: "Crie Anúncios",
        subtitle: "Subtitle",
      },
      en_US: {
        title: "Crie Anúncios",
        subtitle: "Subtitle",
      },
      es_ES: {
        title: "Crie Anúncios",
        subtitle: "Subtitle",
      },
    },
    courses: {
      pt_BR: {
        title: "Crie Cursos",
        subtitle: "Subtitle",
      },
      en_US: {
        title: "Crie Cursos",
        subtitle: "Subtitle",
      },
      es_ES: {
        title: "Crie Cursos",
        subtitle: "Subtitle",
      },
    },
    plataformas: {
      pt_BR: {
        title: "Integrações com outras Plataformas",
        subtitle: "Conecte suas contas para possibilitar o agendamento e postagem automáticos",
      },
      en_US: {
        title: "Integrations with other Platforms",
        subtitle: "Connect your accounts to enable automatic scheduling and posting",
      },
      es_ES: {
        title: "Integraciones con otras Plataformas",
        subtitle: "Conecte sus cuentas para habilitar la programación y publicación automáticas",
      },
    },
    parceiros: {
      pt_BR: {
        title: "Networking Especializado",
        subtitle: "Disponibilize o seu produto ou serviço para rede de usuários e parceiros da amplitude ou encontre produtos e serviços para o seu negócio",
      },
      en_US: {
        title: "Specialized Networking",
        subtitle: "Make your product or service available to amplitude's network of users and partners or find products and services for your business",
      },
      es_ES: {
        title: "Redes especializadas",
        subtitle: "Haga que su producto o servicio esté disponible para la red de usuarios y socios de amplitude o encuentre productos y servicios para su negocio",
      },
    },
    tutoriais: {
      pt_BR: {
        title: "Guias e Tutoriais Amplitude",
        subtitle: "Aprenda a utilizar todas as funcionalidades da Amplitude",
      },
      en_US: {
        title: "Amplitude Guides and Tutorials",
        subtitle: "Learn how to use all the features of Amplitude",
      },
      es_ES: {
        title: "Guías y tutoriales de Amplitude",
        subtitle: "Aprende a usar todas las funciones de Amplitude",
      },
    },
    cursos: {
      pt_BR: {
        title: "Desenvolva Novas Habilidades",
        subtitle: "Amplie seu conhecimento com cursos sobre marketing, Inteligência artificial e muito mais",
      },
      en_US: {
        title: "Develop New Skills",
        subtitle: "Expand your knowledge with courses on marketing, artificial intelligence and more",
      },
      es_ES: {
        title: "Desarrolla nuevas habilidades",
        subtitle: "Amplia tus conocimientos con cursos de marketing, inteligencia artificial y más",
      },
    },
    duvidas: {
      pt_BR: {
        title: "Perguntas Frequentes e Suporte",
        subtitle: "Encontre respostas para suas dúvidas ou peça ajuda para nossa equipe de suporte",
      },
      en_US: {
        title: "FAQ and Support",
        subtitle: "Find answers to your questions or ask our support team for help",
      },
      es_ES: {
        title: "Preguntas frecuentes y soporte",
        subtitle: "Encuentre respuestas a sus preguntas o solicite ayuda a nuestro equipo de soporte",
      },
    },
    minhaConta: {
      pt_BR: {
        title: "Minha Conta",
        subtitle: "Gerencie suas informações, configurações, plano e outras informações relacionadas à sua conta",
      },
      en_US: {
        title: "My Account",
        subtitle: "Manage your information, settings, plan, and other account-related details, and other account-related details.",
      },
      es_ES: {
        title: "Mi Cuenta",
        subtitle: "Gestiona tu información, configuraciones, plan y otros detalles relacionados con tu cuenta",
      },
    },
    changePasswordCardHeader: {
      pt_BR: {
        title: "Alterar Senha",
        subtitle: "Altere sua senha de acesso",
      },
      en_US: {
        title: "Change Password",
        subtitle: "Change your access password",
      },
      es_ES: {
        title: "Cambiar contraseña",
        subtitle: "Cambie su contraseña de acceso",
      },
    },
    produtos: {
      pt_BR: {
        title: "Gerencie seus Produtos",
        subtitle: "Cadastre os seus produtos para utilizá-los em suas criações",
      },
      en_US: {
        title: "Manage your Products",
        subtitle: "Register your products to use them in your creations",
      },
      es_ES: {
        title: "Administre sus productos",
        subtitle: "Registra tus productos para utilizarlos en tus creaciones",
      },
    },
    audiencias: {
      pt_BR: {
        title: "Gerencie suas Audiências",
        subtitle: "Conheça o seu público-alvo e segmente suas campanhas",
      },
      en_US: {
        title: "Manage your Audiences",
        subtitle: "Know your target audience and segment your campaigns",
      },
      es_ES: {
        title: "Gestiona tus audiencias",
        subtitle: "Conoce tu público objetivo y segmenta tus campañas",
      },
    },
    afiliados: {
      pt_BR: {
        title: "Afiliados",
        subtitle: "...",
      },
      en_US: {
        title: "Afiliados",
        subtitle: "...",
      },
      es_ES: {
        title: "Afiliados",
        subtitle: "...",
      },
    },
    faq: {
      pt_BR: {
        title: "Perguntas Frequentes",
        subtitle: "Encontre uma resposta para o seu problema",
      },
      en_US: {
        title: "Common questions",
        subtitle: "Find an answer to your problem",
      },
      es_ES: {
        title: "Preguntas frecuentes",
        subtitle: "Encuentre una respuesta a su problema",
      },
    },
  }
}