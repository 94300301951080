import { valueConverter } from 'aurelia'

@valueConverter('codigoMDE')
export class CodigoMDEConverter {
  toView(value: number | bigint) {
    if (!value) {
      return value;
    }

    let prefix = 'COD';

    const codigo = prefix + value.toString().padStart(6, '0')

    return codigo;
  }
}