<import from='@components/card-header/card-header-component' />
<import from="@components/spinner-button/spinner-button-component" />

<section>
  <div class="flex flex-row gap-4 mb-6 mt-8">

    <div class="card w-full h-fit">
      <card-header-component header-id="myAccountChangePassword"></card-header-component>

      <form class="p-4" novalidate="novalidate">

        <div class="flex flex-col gap-4 mb-6">
          <!-- beggin::password -->
          <div class="flex-1">
            <label for="siteUrl" class="form-label">Senha</label>
            <validation-container>
              <input type="password" id="siteUrl" value.bind="password & validate" autocomplete="off" class="form-input">
            </validation-container>
          </div>
          <!-- end::password -->
        </div>

        <!-- beggin::button -->
        <div class="flex justify-end">
          <button type="button" click.trigger="handleChangePassword()" class="btn-primary">Salvar senha</button>
        </div>
        <!-- end::button -->
      </form>
    </div>

  </div>
</section>