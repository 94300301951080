<import from='@components/card-header/card-header-component' />
<import from="@components/spinner-button/spinner-button-component" />
<import from='@components/promise-bind/error/error-component' />
<import from='@components/promise-bind/pending/pending-component' />
<import from="@converters/currency-format-converter" />

<div class="card mb-6">
  <card-header-component header-id="myAccountSubscription"></card-header-component>
  <template promise.bind="getPricingPlans()">

    <template pending>
      <pending-component></pending-component>
    </template>

    <template then.from-view="pricingPlans">

      <div class="flex gap-3 p-5">
        <!-- Primeira coluna -->
        <div class="flex-1 p-10 bg-secondary-600 rounded-2xl text-white relative " repeat.for="pricingPlan of pricingPlans">
          <div class="inset-0 bg-secondary-600 opacity-100"></div>
          <div class="relative z-10">
            <div class="w-12 h-12 mb-4">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 32 32" class="text-white">
                <path fill="white" d="M16 30.548c-7.357 0-14.777-1.576-14.777-5.096 0-0.367 0.299-0.667 0.667-0.667h28.221c0.368 0 0.667 0.3 0.667 0.667 0 3.521-7.42 5.096-14.777 5.096zM2.783 26.119c1.080 1.592 5.929 3.096 13.217 3.096s12.139-1.504 13.217-3.096h-26.435zM23.333 26.119h-14.667c-0.215 0-0.417-0.103-0.539-0.277-0.128-0.173-0.161-0.397-0.095-0.601l5.136-15.401c0.028-0.092 0.083-0.177 0.148-0.248l7.533-7.932c0.219-0.232 0.571-0.273 0.839-0.104s0.38 0.507 0.265 0.804l-3.019 7.801 5.028 15.083c0.068 0.203 0.031 0.427-0.093 0.601-0.12 0.172-0.323 0.275-0.537 0.275zM9.591 24.785h12.817l-4.812-14.432c-0.048-0.145-0.044-0.305 0.011-0.451l1.888-4.88-5.109 5.38-4.795 14.383zM21.333 20.785h-10.667c-0.367 0-0.667-0.299-0.667-0.667 0-0.367 0.3-0.667 0.667-0.667h10.667c0.367 0 0.667 0.3 0.667 0.667 0 0.368-0.3 0.667-0.667 0.667zM9.333 22.119h13.333v1.333h-13.333v-1.333zM13.333 20.785h1.333v1.333h-1.333v-1.333zM16 20.785h1.333v1.333h-1.333v-1.333z">
                </path>
              </svg>
            </div>
            <p class="text-2xl font-semibold">${pricingPlan.alias}</p>
            <div class="text-5xl font-semibold mb-4">
              <sup><span class="text-sm">R$</span></sup>${pricingPlan.monthlyPrice | currency:'pt-BR':false}<span class="text-sm">/ mês</span>
            </div>
            <div class="border-b border-white mb-4"></div>

            <ul class="list-disc pl-5 mb-6 text-sm leading-6 font-light" innerhtml.bind="pricingPlan.htmlFeatureItems"></ul>

            <div class="flex flex-col">
              <spinner-button-component if.bind="zeusClient.userDetails.stripePriceId !== pricingPlan.stripePriceId" label="Escolher" btn-class="btn-secondary-reverse w-full" trigger-function.bind="() => subscribe(pricingPlan.stripePriceId)"></spinner-button-component>
            </div>
          </div>
        </div>

      </div>

    </template>

    <template catch.from-view="error">
      <error-component error.bind="error"></error-component>
    </template>

  </template>

  <div class="flex p-4 gap-10 items-center">

    <!--  -->
    <!-- <div if.bind="!zeusClient.userDetails.stripeSubscriptionItem && auth.user" class="flex-grow">
      <div
        class="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800"
        role="alert">
        <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
          fill="currentColor" viewBox="0 0 20 20">
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span class="sr-only">Info</span>
        <div>
          <span t="myaccount.subscribeState.message.caption" class="font-medium">Danger alert!</span>
          <span t="myaccount.subscribeState.message.text">Change a few things up and try submitting again.</span>
        </div>
      </div>
    </div> -->
    <!--  -->

    <!--  -->
    <div if.bind="zeusClient.userDetails.stripeSubscriptionItem && auth.user" class="flex-grow">
      <div class="flex items-center p-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
        <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span class="sr-only">Info</span>
        <div>
          <!-- <span class="font-medium">Success alert!</span> -->
          <span t="myaccount.manageState.message.text">Change a few things up and try submitting again.</span>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- 
    <button if.bind="!zeusClient.userDetails.stripeSubscriptionItem && auth.user" click.trigger="subscribe()"
      t="myaccount.subscribeState.action" class="btn-primary">Subscribe</button> -->

    <spinner-button-component if.bind="zeusClient.userDetails.stripeSubscriptionItem && auth.user" label="Gerenciar" btn-class="btn-primary-small" trigger-function.bind="() => getManageSubscription()"></spinner-button-component>

  </div>

</div>