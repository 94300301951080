<import from="@components/toast/toast-component" />
<import from="@components/onboarding/onboarding" />
<import from="@components/topnav/topnav-component" />
<import from="@components/drawer/drawer-component" />
<import from="@components/sidebar/sidebar-component" />
<import from="@components/feedback/feedback-component" />
<import from="@components/alert-modal/alert-modal-component" />

<div class="flex bg-gray-50 dark:bg-gray-900 h-screen">
	<drawer-component></drawer-component>
	<sidebar-component if.bind="auth.user" open.two-way="isOpenDrawer" class="hidden md:inline"></sidebar-component>

	<div class="bg-gray-50 dark:bg-gray-900 flex flex-col overflow-hidden w-full">
		<topnav-component if.bind="auth.user" open.two-way="isOpenDrawer"></topnav-component>

		<div class="fixed inset-0 z-10 hidden bg-gray-900/50 dark:bg-gray-900/90" id="sidebarBackdrop"></div>

		<main id="main-content" class="relative w-full overflow-y-auto scrollbar-thin scrollbar-rounded-md scrollbar-thumb-rounded-md scrollbar-thumb-gray-300 h-full">
			<div class="px-4 pb-4">
				<au-viewport></au-viewport>
			</div>
		</main>

	</div>
</div>


<toast-component></toast-component>
<alert-modal-component></alert-modal-component>