import { BindingMode, bindable } from "aurelia";
import { initTabs } from "flowbite";

export class BackgroundImageComponent {
  @bindable productId: string;
  @bindable displayDimensionId: string;

  @bindable({ mode: BindingMode.twoWay }) selectedImage: string;
  @bindable({ mode: BindingMode.twoWay }) currentImageTabIndex: number = 0;

  constructor() {
    initTabs();
  }

  attached() {
    initTabs();
  }

  detaching() {
    this.productId = null;
    this.selectedImage = null;
    this.currentImageTabIndex = 0;
    this.displayDimensionId = null;
  }

  changeCurrentImageTabIndex(index: number) {
    this.selectedImage = null;

    this.currentImageTabIndex = index;
  }
}