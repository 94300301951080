export interface IAtrStyleData {
  id: string;
  value: number;
  text: string;
  image: string;
  description: string;
}

export const atrStyleData: IAtrStyleData[] = [
  { id: '1', value: 0, text: 'Halloween', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles/halloween-thumb.webp', description: 'Halloween' },
  { id: '2', value: 0, text: 'Euphoric', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-20.webp', description: 'Euphoric' },
  { id: '3', value: 0, text: 'Tattoo', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v1/tattoo-thumb.webp', description: '' },
  { id: '4', value: 0, text: '3D Game', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v1/3d-thumb.webp', description: '' },
  { id: '5', value: 0, text: 'Cinematic Render', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-47.webp', description: '' },
  { id: '6', value: 0, text: 'Crayon Art', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v1/crayon-thumb.webp', description: '' },
  { id: '7', value: 0, text: 'GTA', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-06.webp', description: '' },
  { id: '8', value: 0, text: 'Minimalism', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v1/minimal-thumb.webp', description: '' },
  { id: '9', value: 0, text: 'Glitch', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v1/glitch-thumb.webp', description: '' },
  { id: '10', value: 0, text: 'Extra-terrestrial', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-26.webp', description: '' },
  { id: '11', value: 0, text: 'Picasso', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-53.webp', description: '' },
]