import { ICustomElementController, IHydratedController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import { ModelTypes } from "@zeus";
import { nhost } from "lib/nhost";

export class ViewCalendarEventDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;
  project: ModelTypes["project"];
  previewImage: string;
  constructor(@IDialogDom private dialogDom: DefaultDialogDom) {
    dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    dialogDom.contentHost.style.position = "fixed";
    dialogDom.contentHost.style.margin = "0";
    dialogDom.contentHost.style.top = "0";
    dialogDom.contentHost.style.right = "0";
    dialogDom.contentHost.style.overflowX = "hidden";
    dialogDom.contentHost.style.overflowY = "auto";
    dialogDom.contentHost.style.maxHeight = "100%";
    dialogDom.contentHost.style.height = "calc(100% - 1rem)";
    // dialogDom.contentHost.className = 'flex flex-col bg-white rounded-lg dark:bg-gray-700 dark:text-gray-200 overflow-y-auto p-0 shadow-soft-xl inset-y-0 m-4 border-0 antialiased fixed top-0 right-0 z-40 w-[470px] transition-transform duration-500 h-[calc(100vh-32px)] hide-scrollbar transform-none'
  }

  async attaching() {
    const animation = this.dialogDom.contentHost.animate([
      { transform: 'translateX(100%)' },
      { transform: 'translateX(0)' }
    ], {
      duration: 500,
      easing: 'ease-in-out',
      fill: 'forwards'
    });
    await animation.finished;

  }

  async detaching() {
    const animation = this.dialogDom.contentHost.animate([
      { transform: 'translateX(0)' },
      { transform: 'translateX(100%)' }
    ], {
      duration: 500,
      easing: 'ease-in-out',
      fill: 'forwards'
    });

    await animation.finished;
  }

  async activate(model?: any) {
    this.project = model.project;

    if (this.project.featuredImageFileId) {
      this.previewImage = `https://rcspllpedqcxwwixkqbz.storage.sa-east-1.nhost.run/v1/files/${this.project.featuredImageFileId}?${new Date().getTime()}`;
    }

    if (this.project.previewImageFileId) {
      this.previewImage = `https://rcspllpedqcxwwixkqbz.storage.sa-east-1.nhost.run/v1/files/${this.project.previewImageFileId}?${new Date().getTime()}`;
    }
  }

  close() {
    this.$dialog.cancel();
  }
}