import { ModelTypes } from '@zeus';
import { IDialogService } from '@aurelia/dialog';
import { ITemplateService } from '../../services/templates-service';
import { CreateProjectDialog } from '@components/create-project-dialog/create-project-dialog';

export class PostTemplatesPage {
  private channel: string = 'posts';

  constructor(
    @ITemplateService private service: ITemplateService,
    @IDialogService private dialogService: IDialogService
  ) { }

  private async getNewTemplateType(channel: string): Promise<ModelTypes['newTemplateType'][]> {
    const result = await this.service.getNewTemplateType(channel);

    return result;
  }

  private async openCreateProjectDialog() {
    await this.dialogService.open({
      component: () => CreateProjectDialog,
      model: {},
      lock: false,
      startingZIndex: 100,
    });
  }
}