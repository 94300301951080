interface IColorsCallback {
  id: string;
  backgroundColor: string;
  foregroundColor: string;
}

interface IColorsModalEvent {
  id: string;
  backgroundColor: string;
  foregroundColor: string;
  addColor: (callback: IColorsCallback) => void;
}

class ColorsModalEvent {
  static readonly SHOW = 'colors-modal:show';

  constructor(public payload: IColorsModalEvent) { }
}

export { IColorsModalEvent, IColorsCallback, ColorsModalEvent }