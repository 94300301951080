<import from='@components/undraw/undraw-component' />
<import from='@components/card-header/card-header-component' />
<import from="@components/spinner-button/spinner-button-component" />

<div class="bg-white rounded-lg shadow dark:bg-gray-700 min-h-[30vh] h-full w-[30vw]">

  <!-- begin::header -->
  <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">

    <div class="flex flex-col">
      <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Salvar artigo</h3>
      <p class="text-sm font-normal text-gray-700 dark:text-gray-400">...</p>
    </div>

    <button type="button" click.trigger="$dialog.cancel()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
  </div>
  <!-- end::header -->

  <!-- begin::body -->
  <div class="flex flex-col gap-4 p-4">

    <!-- begin::action -->
    <div>
      <label for="action" class="form-label">Ação</label>
      <select id="action" value.bind="selectedAction" class="form-input">
        <option model.bind="null">Selecione</option>
        <option repeat.for="item of actionsFromPublishData" model.bind="item.value">${item.text}</option>
      </select>
    </div>
    <!-- end::action -->

    <!-- begin::scheduleDate -->
    <div if.bind="selectedAction == 'schedule' ">
      <label for="scheduleDate" class="form-label">Data</label>
      <input type="datetime-local" name="scheduleDate" id="scheduleDate" value.bind="scheduleDate" min="${minDate}" class="form-input">
    </div>
    <!-- end::scheduleDate -->

  </div>
  <!-- end::body -->

  <!-- begin::footer -->
  <div class="flex items-center p-4 justify-end space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
    <button type="button" click.trigger="$dialog.cancel()" class="btn-primary-small">Fechar</button>
    <spinner-button-component label="Salvar" btn-class="btn-success-small" trigger-function.bind="() => handleSaveTemplate()"></spinner-button-component>
  </div>
  <!-- end::footer -->

</div>