import { ModelTypes } from '@zeus';
import { Drawer } from 'flowbite';
import type { DrawerInterface, DrawerOptions, InstanceOptions } from 'flowbite';

export class ViewCalendarEventDrawer {
  private drawer: DrawerInterface;
  private project: ModelTypes["project"];

  attached() {
    document.addEventListener('keydown', this.handleEscKey, { once: true });

    const $targetEl: HTMLElement = document.getElementById('drawer-event');

    const options: DrawerOptions = {
      placement: 'right',
      backdrop: true,
      bodyScrolling: false,
      edge: false,
      edgeOffset: '',
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
    };

    const instanceOptions: InstanceOptions = {
      id: 'drawer-event',
      override: true,
    };

    this.drawer = new Drawer($targetEl, options, instanceOptions);
  }

  detached() {
    document.removeEventListener('keydown', this.handleEscKey);
  }

  public drawerShow(project: ModelTypes["project"]) {
    this.project = project;

    this.drawer.show();
  }

  public drawerHide() {
    this.drawer.hide();
  }

  private handleEscKey(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') {
      event.stopPropagation();
    }
  }
}