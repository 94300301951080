<div class="w-full max-w-full shrink-0">
  <!-- begin::card -->
  <div class="card p-4">
    <div id="carousel-dashboard" class="relative w-full overflow-hidden rounded-xl" data-carousel="slide">
      <!-- Carousel wrapper -->
      <div class="relative h-72 overflow-hidden rounded-xl">

        <template repeat.for="item of carousel">
          <div id="${item.id}" class="hidden duration-700 ease-in-out bg-black" data-carousel-item>
            <img src="${item.image}" class="absolute block max-w-full w-full h-auto -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 rounded-xl" style.bind="`opacity: ${item.opacity}`" alt="">

            <!-- beggin::text -->
            <div class="absolute bottom-0 left-0 z-20 flex flex-col items-start justify-center w-full h-full px-20 text-white gap-1">
              <h2 class="text-2xl md:text-4xl font-bold text-left line-clamp-2">${item.title}</h2>
              <h2 class="text-xl md:text-3xl font-semibold text-left line-clamp-2">${item.subTitle}</h2>
            </div>
            <!-- end::text -->
          </div>
        </template>

      </div>

      <!-- beggin::slider controls -->
      <button id="data-carousel-prev" type="button" class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none bg-transparent" data-carousel-prev>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
          <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
          </svg>
          <span class="sr-only">Previous</span>
        </span>
      </button>
      <button id="data-carousel-next" type="button" class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none bg-transparent" data-carousel-next>
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
          <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
          </svg>
          <span class="sr-only">Next</span>
        </span>
      </button>
      <!-- end::slider controls -->

    </div>
  </div>
  <!-- end::card -->
</div>