import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import { ICustomElementController } from '@aurelia/runtime-html';
import { IValidationController } from '@aurelia/validation-html';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { interactionChannel } from 'common/data';
import { IInteractionsService } from '../services/interactions-service';
import { ToastComponent } from '@components/toast/toast-component';
import { ToastType } from '@components/toast/events/toast-event';
import { IEventAggregator } from 'aurelia';
import moment from 'moment';

export class InteractionDialog implements IDialogCustomElementViewModel {
  private date: string;
  private channel: string;
  private observation: string;
  private opportunityId: string;
  private success: boolean = false;

  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private channelData = interactionChannel;

  constructor(
    @IEventAggregator readonly ea: IEventAggregator,
    @IDialogDom private dialogDom: DefaultDialogDom,
    @IInteractionsService private service: IInteractionsService,
    @IValidationRules private validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) private validationController: IValidationController,
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  private closeAndSubmit() {
    this.$dialog.ok();
  }

  async activate(model?: any) {
    this.opportunityId = model.opportunityId;
  }

  private async handleSave() {
    try {
      const response = await this.service.createInteraction({
        date: new Date(this.date),
        channel: this.channel,
        success: this.success,
        observation: this.observation,
        dealId: this.opportunityId,
      });

      if (!response) throw new Error('Erro ao salvar dados');

      this.closeAndSubmit();

      this.showMessage({ title: 'Sucesso', message: 'Dados salvo com sucesso', type: ToastType.SUCCESS });
    } catch (error) {
      if (error instanceof Error) {
        this.showMessage({ title: 'Erro', message: error.message, type: ToastType.DANGER });
      } else {
        this.showMessage({ title: 'Erro', message: 'Algo deu errado, tente novamente mais tarde', type: ToastType.DANGER });
      }
    }
  }

  private showMessage({ title, message, type }: { title: string; message: string; type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type,
      },
    });
  }
}