import { ModelTypes } from '@zeus';
import { I18N } from '@aurelia/i18n';
import { IEventAggregator } from 'aurelia';
import { IAudiencesService } from './services/audiences-service';

export class AudiencesPage {
  private audience: ModelTypes["audiences"];

  constructor(
    @I18N private I18N: I18N,
    @IEventAggregator readonly ea: IEventAggregator,
    @IAudiencesService private service: IAudiencesService,
  ) { }

  attached() {
    this.getAudiences();
  }

  private async getAudiences(): Promise<void> {
    const audiences = await this.service.getAudiences();

    if (audiences.length <= 0) return;

    this.audience = {
      ...audiences[0],
      personas: typeof audiences[0].personas === 'string' ? JSON.parse(audiences[0].personas) : audiences[0].personas,
    };
  }

}