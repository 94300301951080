<import from="./sections/contact/contact-section" />
<import from="./sections/tasks/tasks-section" />
<import from="./sections/products/products-section" />
<import from="./sections/historic/historic-section" />
<import from="./sections/interactions/interactions-section" />
<import from="./sections/attachments/attachments-section" />

<import from="./components/status-bar/status-bar-component" />

<section>

  <!-- begin:status-bar-component -->
  <status-bar-component opportunity.bind="service.opportunitySubscription"></status-bar-component>
  <!-- end:status-bar-component -->

  <div class="flex w-full items-center justify-center h-[calc(100vh-13rem)]">
    <div class="h-full w-full grid grid-cols-12 grid-rows-12 gap-4">

      <!-- begin:contact-section -->
      <div class="col-span-4 row-span-7 card overflow-auto hide-scrollbar">
        <contact-section></contact-section>
      </div>
      <!-- end:contact-section -->

      <!-- begin::tasks-section -->
      <div class="col-span-4 row-span-5 card overflow-auto hide-scrollbar">
        <tasks-section></tasks-section>
      </div>
      <!-- end::tasks-section -->

      <!-- begin::products-section -->
      <div class="col-span-4 row-span-7 card overflow-auto hide-scrollbar">
        <products-section></products-section>
      </div>
      <!-- end::products-section -->

      <!-- begin::historic-section -->
      <div class="col-span-4 row-span-7 card overflow-auto hide-scrollbar">
        <historic-section></historic-section>
      </div>
      <!-- end::historic-section -->

      <!-- begin::interactions-section -->
      <div class="col-span-4 row-span-5 card overflow-auto hide-scrollbar">
        <interactions-section></interactions-section>
      </div>
      <!-- end::interactions-section -->

      <!-- begin::attachments-section -->
      <div class="col-span-4 row-span-5 card overflow-auto hide-scrollbar">
        <attachments-section></attachments-section>
      </div>
      <!-- end::attachments-section -->

    </div>
  </div>

</section>