import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";
import { format, parseISO } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR'

@valueConverter('dateToFullDateFormat')
export class DateToFullDateFormat {
  constructor(@I18N private I18N: I18N) { }

  toView(date) {
    return format(parseISO(date), 'dd/MM/yyyy', { locale: ptBR });
  }
}