<div class="card">
  <div class="relative z-10 flex flex-col flex-auto h-full max-w-full overflow-hidden rounded-xl p-4">
    <h5 class="font-bold text-gray-900 dark:text-white">Projetos</h5>
    <h6 class="text-xs font-semibold text-gray-400 dark:text-gray-400 mb-4">Subtitle</h6>

    <h5 class="mb-0 font-bold dark:text-white">
      ${label}
      <span class="font-bold leading-normal text-sm text-lime-500">+${total}</span>
    </h5>
  </div>
</div>