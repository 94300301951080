<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Cartão de Contato">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">
      <div class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class="flex-1">
          <input-component type="text" label="Link para Twitter" value.bind="website.brand.twitterUrl" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Link para Facebook" value.bind="website.brand.facebookUrl" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Link para Instagram" value.bind="website.brand.instagramUrl" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Link para Linkedin" value.bind="website.brand.linkedinUrl" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Link para TikTok" value.bind="website.brand.tiktokUrl" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Link para Youtube" value.bind="website.brand.youtubeUrl" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Telefone" value.bind="website.brand.phoneNumber" input-mask="phone" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Whatsapp" value.bind="website.brand.whatsappNumber" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="E-Mails Para Contato" value.bind="website.brand.email" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="CEP do Endereço" value.bind="website.brand.addressZipCode"
            input-mask="cep" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Nome da Rua" value.bind="website.brand.addressStreet" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Número do Endereço" value.bind="website.brand.addressNumber" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Complemento do Endereço" value.bind="website.brand.addressComplement" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="UF - Estado" value.bind="website.brand.addressUf" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Cidade" value.bind="website.brand.addressCity" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Bairro" value.bind="website.brand.addressDistrict" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Latitude Para Identificar no Mapa"
            value.bind="website.brand.addressLatitude" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Longitude Para Identificar no Mapa"
            value.bind="website.brand.addressLongitude" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Descrição Curta Sobre a Marca" value.bind="website.brand.shortAbout" />
        </div>
      </div>

      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20"
          trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>