import { I18N } from '@aurelia/i18n';
import { IEventAggregator } from 'aurelia';
import { ToastType } from '@components/toast/events/toast-event';
import { ToastComponent } from '@components/toast/toast-component';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IGenericListConfig, GenericListComparsionType, GenericListFildInputType } from '@components/generic-list/interfaces/generic-filtered-interface';
import { ModelTypes } from '@zeus';

export class BlogPage {
  private config: IGenericListConfig;

  constructor(
    @I18N private I18N: I18N,
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator
  ) { }

  loading() {
    this.config = {
      total: 0,
      tableName: 'project',
      streamName: 'project_stream',
      aggregateName: 'project_aggregate',
      columns: ['id', 'blogTitle', 'status', 'type', 'previewImageFileId', 'publishDate', 'scheduledDate', 'deleted'],
      fixedFilters: [{
        comparsionType: GenericListComparsionType.Equals,
        title: 'type',
        field: 'type',
        type: GenericListFildInputType.Text,
        value: 'text',
      }],
      filters: [],
      data: [],
      perPage: 12,
      currentPage: 1,
    }
  }

  private async deleteProject(projectId: string) {
    const { id } = await this.zeusClient.deleteProjectById(projectId);

    if (!id) {
      this.showMessage({ title: 'Erro', message: 'Erro ao excluir criação', type: ToastType.DANGER });
      return;
    }

    this.showMessage({ title: 'Sucesso', message: 'Criação excluida com sucesso', type: ToastType.SUCCESS });
  }

  private setIconByType(type: string): string {
    switch (type) {
      case 'image':
        return 'fa-regular fa-image';
      case 'advert':
        return 'fa-solid fa-rectangle-ad';
      case 'post':
        return 'fa-solid fa-calendar-days';
      case 'text':
        return 'fa-solid fa-blog';
      case 'video':
        return 'fa-solid fa-video';
      case 'print':
        return 'fa-solid fa-print';
      case 'presentation':
        return 'fa-solid fa-person-chalkboard';
      default:
        return 'fas fa-question';
    }
  }

  private handleStatus(project: ModelTypes['project']): string {
    if (project.publishDate && project.scheduledDate) {
      return 'scheduled';
    }

    if (project.publishDate) {
      return 'published';
    }

    return 'draft';
  }

  private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type
      }
    });
  }

}