import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface IAudiencesService {
  getAudiences(): Promise<ModelTypes["audiences"][]>;
}

export class AudiencesService implements IAudiencesService {

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  async getAudiences(): Promise<ModelTypes["audiences"][]> {
    const result = await this.zeusClient.chain('query')({
      audiences: [
        {
          limit: 3,
        },
        {
          id: true,
          deleted: true,
          segment: true,
          brandName: true,
          created_at: true,
          updated_at: true,
          targetAudience: true,
          productOrService: true,
          personas: [{ path: '$', }, true],
        }
      ]
    });

    return result.audiences as ModelTypes["audiences"][];
  }
}

export const IAudiencesService = DI.createInterface<IAudiencesService>((x) => x.singleton(AudiencesService));
