import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class UserSubscriptionComponent {

  constructor(
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient,
  ) { }

  async getManageSubscription() {
    const result = await this.zeusClient.getManageSubscription();
    window.location.href = result.url;
  }

  async subscribe(price_id) {
    const urlResponse = await this.zeusClient.getPaymentUrl(price_id);
    window.location.href = urlResponse.url;

    window.dataLayer.push({
      event: 'purchaseIntent_' + price_id,
    });
  }

  async getPricingPlans() {
    return await this.zeusClient.getPricingPlans();
  }
}