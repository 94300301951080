<button id="dropdown-models-button" type="button" class="form-input">
  <div class="flex h-full w-full items-center">
    <div class="flex flex-1 items-center gap-2">
      <img if.bind="selectedModel" src="${selectedModel.image}" alt="Image" class="h-5 w-5 rounded-md bg-neutral-20">
      <span class="max-w-[133px] truncate ${selectedModel ? '' : 'pl-2'}">${selectedModel ? selectedModel.text : 'Selecione'}</span>
    </div>
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" class="h-5 w-5 rotate-0 transform stroke-[1.5] text-neutral-400 transition-all duration-500 group-data-[state=open]:rotate-90 dark:text-neutral-40 [.sm_&amp;]:h-[18px] [.sm_&amp;]:w-[18px]">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="currentStroke" d="m9 18 6-6-6-6"></path>
    </svg>
  </div>
</button>

<div id="dropdown-models" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-md dark:bg-gray-700 dark:divide-gray-600 border max-h-[620px] min-w-[373px] max-w-[373px] w-[373px]">

  <div class="h-full w-full overflow-hidden text-neutral-900 dark:text-neutral-400">
    <div class="mt-2 flex h-[38px] items-center px-4 pb-0.5">
      <span class="font-medium">Selecione um Modelo</span>
    </div>

    <div>
      <span style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px;"></span>

      <ul class="hide-scrollbar flex h-full max-h-[492px] flex-wrap items-center gap-x-1 gap-y-1 overflow-hidden overflow-y-auto p-4 pt-2">
        <template repeat.for="item of modelsData">

          <li id="popover-models-button-${item.id}">
            <label for="${item.id}-model" click.trigger="handleSelectedModel(item)" class="peer relative max-h-[92px] min-h-[92px] min-w-[110px] max-w-[110px] ${item.value === 0 ? 'pointer-events-none grayscale' : ''}">

              <div type="button" class="peer relative inline-flex h-[92px] w-[110px] flex-col overflow-hidden rounded-lg cursor-pointer peer-checked:border">
                <div class="relative h-full w-full bg-neutral-20 pb-[100%] dark:bg-neutral-800">
                  <div class="absolute inset-0 h-full w-full transition-opacity duration-500">
                    <img src="${item.image}" alt="assets/models/thumb-v5.webp" class="h-full w-full object-cover object-center">
                  </div>
                </div>
                <div class="absolute inset-0 h-full w-full bg-[linear-gradient(180deg,rgba(0,0,0,0.00)_37.77%,rgba(0,0,0,0.32)_65.93%,rgba(0,0,0,0.48)_78.19%,rgba(0,0,0,0.64)_87.41%,rgba(0,0,0,0.80)_98.87%)]"></div>
                <div class="absolute inset-0 h-full w-full rounded-lg ring-1 ring-inset ring-transparent transition-all duration-500 peer-hover:ring-primary-500 dark:peer-hover:ring-primary-400"></div>
                <span class="absolute bottom-2 w-full px-1 text-xs font-semibold text-neutral-50">${item.text}</span>
                <div class="absolute inset-0 h-full w-full bg-primary-500/25 transition-all duration-500 dark:bg-primary-400/25 opacity-0"></div>
              </div>

            </label>
          </li>

          <div id="popover-models-${item.id}" role="tooltip" class="absolute z-30 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
            <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
              <h3 class="font-semibold text-gray-900 dark:text-white">${item.text}</h3>
            </div>
            <div class="px-3 py-2 text-xs">
              <p>${item.description}</p>
            </div>
            <div data-popper-arrow></div>
          </div>

        </template>
      </ul>

      <span id="headlessui-tabs-panel-:rc1:" role="tabpanel" tabindex="-1" aria-labelledby="headlessui-tabs-tab-:rbu:" style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px;"></span>
    </div>
  </div>

</div>