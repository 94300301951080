
import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";
import slugify from "slugify";

@valueConverter('slugify')
export class SlugifyConverter {
  constructor(@I18N private I18N: I18N) { }

  toView(url: string) {
    if (!url) return '';
    return slugify(url, { lower: true, strict: true });

  }
}