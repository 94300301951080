import { IEventAggregator, bindable } from 'aurelia';

import { ToastEvent, ToastType } from '@components/toast/events/toast-event'
import { IDialogService } from '@aurelia/dialog';
// import { FullscreenSpinnerDialog } from '@components/fullscreen-spinner/fullscreen-spinner-dialog';

export class SpinnerButtonComponent {
  @bindable label: string;
  @bindable disabled: boolean;
  @bindable btnClass: string;
  @bindable triggerFunction: Function;
  @bindable toastMessage: string | null = null;

  isLoading: boolean;

  constructor(@IEventAggregator readonly ea: IEventAggregator, @IDialogService private dialogService: IDialogService) { }

  async executeTriggerFunction() {
    this.isLoading = true;

    // const { dialog } = await this.dialogService.open({
    //   component: () => FullscreenSpinnerDialog,
    //   model: {},
    //   lock: true,
    //   startingZIndex: 100,
    // });

    try {
      await this.triggerFunction();

      if (this.toastMessage) {
        this.ea.publish(ToastEvent.SHOW, {
          title: 'Sucesso',
          message: this.toastMessage,
          duration: 5000,
          type: ToastType.SUCCESS
        });
      }

    } catch (error) {
      console.log(error);
      this.ea.publish(ToastEvent.SHOW, {
        title: 'Erro',
        message: 'Ocorreu um erro ao executar a ação',
        duration: 5000,
        type: ToastType.DANGER
      });
    } finally {
      this.isLoading = false;
      // await dialog.ok();
    }

  }
}