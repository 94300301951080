import { I18N } from "@aurelia/i18n";
import { IAurelia, IEventAggregator } from 'aurelia';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { newInstanceForScope } from '@aurelia/kernel';
import { ToastComponent } from '@components/toast/toast-component';
import { ToastType } from '@components/toast/events/toast-event';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IRouter } from '@aurelia/router';

export class RegisterPage {
  private email: string;
  private password: string;

  private isLoading: boolean;

  constructor(
    @IAurelia private au: IAurelia,
    @IAuthService private Auth: IAuthService,
    @I18N private I18N: I18N,
    @newInstanceForScope(IValidationController) private validationController: IValidationController,
    @IValidationRules private validationRules: IValidationRules,
    @IEventAggregator readonly ea: IEventAggregator,
    @IRouter private router: IRouter,
  ) {
    validationRules
      .on(this)
      .ensure('email')
      .email()
      .required()
      .ensure('password')
      .minLength(9)
      .required()
  }

  loading(parameters: any) {
    if (parameters) {
      localStorage.setItem('cm_metadata', JSON.stringify(parameters));
    }
  }

  private async createUserWithEmailAndPassword() {

    const result = await this.validationController.validate();

    if (result.valid) {
      this.isLoading = true;

      try {
        const cm_metadata = localStorage.getItem('cm_metadata');

        await this.Auth.signUp({
          email: this.email,
          password: this.password,
          ...cm_metadata && { metadata: JSON.parse(cm_metadata) }
        });

        localStorage.removeItem('cm_metadata');

        // await this.au.stop();

        await this.router.load('/email-verification');

      } catch (error) {
        if (error instanceof Error) {
          this.showMessageError(error.message)
        } else {
          this.showMessageError('Algo deu errado, tente novamente mais tarde');
        }
      } finally {
        this.isLoading = false;
      }
    }
  }

  private async handleLoginWithGoogle() {
    try {
      const cm_metadata = localStorage.getItem('cm_metadata');

      await this.Auth.signInForGoogle({
        ...cm_metadata && { metadata: JSON.parse(cm_metadata) }
      });

      localStorage.removeItem('cm_metadata');

      await this.au.stop();
    } catch (error) {
      if (error instanceof Error) {
        this.showMessageError(error.message)
      } else {
        this.showMessageError('Algo deu errado, tente novamente mais tarde');
      }
    }
  }

  private showMessageError(message: string) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: 'Error',
        message,
        duration: 5000,
        type: ToastType.DANGER
      }
    });
  }
}