import { DI } from "aurelia";
import { StorageService } from '@services/storage/storage-service';
import { FileResponse, StorageUploadFileParams } from '@nhost/nhost-js';

interface UrlParams { url: string }

export interface UploadFromFileParams extends StorageUploadFileParams { }
export interface UploadFromUrlParams extends Omit<StorageUploadFileParams, 'file'>, UrlParams { }
export interface UploadParams { id?: string, name?: string, data: File | string, bucketId: string }

export interface IStorageService {
  upload(params: UploadParams): Promise<FileResponse>
  deleteById(fileId: string): Promise<boolean>
  // uploadFromUrl(params: UploadFromUrlParams): Promise<FileResponse>
  // uploadFromFile(params: UploadFromFileParams): Promise<FileResponse>

}

export const IStorageService = DI.createInterface<IStorageService>(x => x.singleton(StorageService));