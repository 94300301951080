import { ModelTypes } from '@zeus';
import { IModelsData } from './components/models/data';
import { IAtrStyleData } from './components/art-style/data';
import { IImagineService } from './services/imagine-service';
import { IAspectRatioData } from './components/aspect-ratio/data';
import { toastifyError, toastifySuccess } from 'common/helpers/toastify';

export class ImagineModule {
  private ilustration: string = null;

  private brandId: string = null;
  // private productId: string = null;
  // private audienceId: string = null;

  private prompt: string;
  private selectedBrandId: string;
  // private selectedProductId: string;
  // private selectedAudienceId: string;

  // private seed: number = null;
  // private cfgScale: number = 3;
  // private stepScale: number = 30;
  // private negativePrompt: string = null;
  // private selectedModel: IModelsData = null;
  // private selectedAtrStyle: IAtrStyleData = null;
  private selectedAspectRatio: IAspectRatioData = null;

  private brands: ModelTypes['brands'][];
  // private products: ModelTypes['products'][];
  // private audiences: ModelTypes['audiences'][];

  constructor(@IImagineService private service: IImagineService) { }

  async attached() {
    await this.handleGetBrands();

    this.selectedBrandId = this.brandId;
    // this.selectedProductId = this.productId;
    // this.selectedAudienceId = this.audienceId;

    if (!this.selectedBrandId) {
      this.selectedBrandId = this.brands[0].id;
    }
  }

  private async handleGetBrands(): Promise<void> {
    this.brands = await this.service.brands();
    // this.products = await this.service.products();
    // this.audiences = await this.service.audiences();
  }

  private async handleGenerate(): Promise<void> {
    try {
      const { fileId, success, errorMessage } = await this.service.imagine({
        prompt: this.prompt,
        aspectRatio: this.selectedAspectRatio.value,
      });

      if (!success) {
        toastifyError({ message: 'Erro ao tentar gerar ilustração' });
        return;
      }

      toastifySuccess({ message: 'Ilustração gerada com sucesso' });

      this.ilustration = `https://rcspllpedqcxwwixkqbz.storage.sa-east-1.nhost.run/v1/files/${fileId}`
    } catch (error) {
      toastifyError({ message: error });
    }
  }
}