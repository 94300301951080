<import from='@components/generic-kanban/generic-kanban-component' />

<import from="./../../components/flow-card/flow-card-component" />

<section>

  <generic-kanban-component config.bind="config">
    <div au-slot="body">
      <div repeat.for="deal of $host.deals" style="min-width: 18rem; margin-bottom: 1rem;" class="overflow-y-auto shadow-lg rounded-2xl">
        <!-- begin::flow-card-component -->
        <flow-card-component deal.bind="deal"></flow-card-component>
        <!-- end::flow-card-component -->
      </div>
    </div>
  </generic-kanban-component>

</section>