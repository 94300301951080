import { bindable } from 'aurelia';
import { IDialogService } from '@aurelia/dialog';
import { AudienceDialog } from '../audience-dialog/audience-dialog';

export class CardComponent {
  @bindable personality: any;

  constructor(@IDialogService private dialogService: IDialogService) { }

  private async openAudienceDialog() {
    const { dialog } = await this.dialogService.open({
      component: () => AudienceDialog,
      model: { personas: this.personality },
      lock: false,
      startingZIndex: 100,
    });
  }

  private getPainsFromMan(personas: string): string {
    if (!personas) return '...';

    const data = typeof personas === 'string' ? JSON.parse(personas) : personas;

    const pains = data[0].pains.map((pain: string) => pain).join(', ');

    return pains;
  }
}