// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#drawer-event {
  --tw-translate-x: 110% !important;
}`, "",{"version":3,"sources":["webpack://./src/common/components/calendar/components/view-calendar-event-drawer/view-calendar-event-drawer.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC","sourcesContent":["#drawer-event {\r\n  --tw-translate-x: 110% !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
