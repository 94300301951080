import { bindable, observable } from 'aurelia';
import { IDialogService } from '@aurelia/dialog';

import { ArchetypeDialog } from '../archetype-dialog/archetype-dialog';

export class OutputCardComponent {
  @bindable archetype: JSON

  private showVideo: boolean = false;

  constructor(@IDialogService private dialogService: IDialogService,) { }

  private async openArchetypeDialog() {
    const { dialog } = await this.dialogService.open({
      component: () => ArchetypeDialog,
      model: { archetype: this.archetype },
      lock: false,
      startingZIndex: 100,
    });
  }
}