<import from="@components/spinner-button/spinner-button-component" />

<div class="bg-white rounded-lg shadow dark:bg-gray-700 max-h-[calc(100vh-5rem)] h-full w-[50vw] overflow-y-auto">

  <!-- begin::header -->
  <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
    <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Registrar nova tarefa</h3>
    <button type="button" click.trigger="$dialog.cancel()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
  </div>
  <!-- end::header -->

  <!-- begin::body -->
  <form class="flex flex-col gap-4 p-4" novalidate="novalidate">
    <!-- beggin::date -->
    <div class="flex-1">
      <label for="date" class="form-label">Concluir até</label>
      <validation-container>
        <input type="datetime-local" id="date" value.bind="date & validate" class="form-input" autocomplete="off">
      </validation-container>
    </div>
    <!-- end::date -->

    <!-- begin::category -->
    <div class="flex-1">
      <label for="category" class="form-label">Categoria da tarefa</label>
      <validation-container>
        <select id="category" value.bind="category & validate" class="form-input">
          <option model.bind="null">Selecione</option>
          <option repeat.for="item of categoryData" model.bind="item.value">${item.text}</option>
        </select>
      </validation-container>
    </div>
    <!-- end::category -->

    <!-- beggin::title -->
    <div class="flex-1">
      <label for="title" class="form-label">Titulo</label>
      <validation-container>
        <input type="text" id="title" value.bind="title & validate" class="form-input" autocomplete="off">
      </validation-container>
    </div>
    <!-- end::title -->

    <!-- beggin::description -->
    <div class="flex-1">
      <label for="date" class="form-label">Descrição</label>
      <validation-container>
        <textarea type="text" id="description" value.bind="description & validate" autocomplete="off" maxlength="200" class="form-input h-[calc(100%-2rem)]"></textarea>
      </validation-container>
    </div>
    <!-- end::description -->
  </form>
  <!-- end::body -->

  <!-- begin::footer -->
  <div class="flex items-center p-4 justify-end space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
    <button type="button" click.trigger="closeAndSubmit()" class="btn-primary-small">Fechar</button>
    <spinner-button-component label="Salvar" trigger-function.bind="() => handleSave()"></spinner-button-component>
  </div>
  <!-- end::footer -->
</div>