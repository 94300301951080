<import from="@components/loading/loading-component" />

<import from="./sections/information/information-section" />

<div class="flex flex-col gap-4 my-6">

  <template if.bind="website">
    <information-section website.bind="website"></information-section>
  </template>

  <template else>
    <loading-component></loading-component>
  </template>

</div>