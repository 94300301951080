import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { bindable } from 'aurelia';

export class FunnelComponent {

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  private async getFunnels() {
    const result = await this.zeusClient.getGeneratedPromptByTemplateAggregateFunnel();

    return result;
  }
}