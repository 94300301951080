import { nhost } from 'lib/nhost';
import { bindable } from 'aurelia';
import { watch } from '@aurelia/runtime-html';
import { IProductService } from '../../services/products-service';

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';

type ImageCarouselType = {
  alias: string;
  linkImage: string;
  linkConversion: string;
};

export class ImageCarouselComponent {
  @bindable type: string;

  constructor(@IProductService private service: IProductService) { }

  private files: ImageCarouselType[] = [];

  @watch('type')
  async handleDealSubscription(newData: string, oldData: string) {
    this.type = newData;

    await this.getThumbnailURL();
  }

  initSwiper() {
    new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 1,
      freeMode: false,
      navigation: {
        prevEl: '.swiper-button-prev-custom',
        nextEl: '.swiper-button-next-custom',
      },
    });
  }

  private async getThumbnailURL(): Promise<void> {
    const products = await this.service.getProducts(this.type);

    for (const product of products) {
      const fileId = product.imageOneId ?? product.imageTwoId ?? product.imageThreeId ?? product.imageFourId;

      if (!fileId) {
        this.files.push({ alias: product.alias, linkConversion: product.linkConversion, linkImage: 'assets/images/default_image.jpg' });
        continue;
      }

      this.files.push({ alias: product.alias, linkConversion: product.linkConversion, linkImage: this.getFileURL(fileId) });
    }

    this.initSwiper();
  }

  private getFileURL(fileId: string): string {
    const publicUrl = nhost.storage.getPublicUrl({ fileId, width: 300, height: 150 });

    return publicUrl;
  }

  private handleNavigateToPage(linkConversion: string) {
    window.open(linkConversion, '_blank', 'noopener,noreferrer');
  }
}