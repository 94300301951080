import Tagify from '@yaireo/tagify'
import { bindable } from 'aurelia';

import '@yaireo/tagify/dist/tagify.css'

export class TagifyBasicComponent {
  private id: string = crypto.randomUUID();

  @bindable label: string;
  @bindable maxTags: number;

  @bindable tagify: Tagify;

  attached() {
    this.tagify = new Tagify(document.querySelector(`#taglify-${this.id}`), {
      maxTags: this.maxTags || 3,
      placeholder: 'Enter tags',
      editTags: {
        clicks: 2,
        keepInvalid: false
      }
    })
  }

  detached() {
    this.tagify.destroy();
  }
}