import { valueConverter } from 'aurelia';

@valueConverter('shortDate')
export class ShortDateFormatConverter {
  toView(value: string, locale = 'pt-BR') {
    const date = new Date(value);

    const format = Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: 'short',
      timeZone: 'UTC'
    });

    if (Number.isNaN(date.valueOf())) {
      return '00 Jan'
    }

    const formattedDate = format.format(date);

    return formattedDate.replace(' de', '');
  }
}