import { ModelTypes } from '@zeus';
import { I18N } from '@aurelia/i18n';
import { createEditor } from './editor.js';
import { IDialogService } from '@aurelia/dialog';
import { setTranslations } from 'polotno/config';
import { IDisposable, IEventAggregator } from 'aurelia';
import { IPolotnoService } from './services/polotno-service';
import { Navigation, RoutingInstruction } from '@aurelia/router';
import { toastifyError, toastifySuccess } from 'common/helpers/toastify';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { EditCaptionDialog } from './components/edit-caption-dialog/edit-caption-dialog';
import { SaveTemplateDialog } from './components/save-template-dialog/save-template-dialog';

import US from './I18n/en_US.json';
import ES from './I18n/es_ES.json';
import BR from './I18n/pt_BR.json';

import './polotno-module.scss'

export class PolotnoModule {
  private id: string;

  private store: any = null;
  private localeSubscription: IDisposable
  private colorThemeSubscription: IDisposable;
  private project: ModelTypes['project'];

  constructor(
    @I18N private I18N: I18N,
    @IAuthService private auth: IAuthService,
    @IEventAggregator readonly ea: IEventAggregator,
    @IPolotnoService private service: IPolotnoService,
    @IDialogService private dialogService: IDialogService
  ) { }

  loading(parameters: { id: string; }, instruction: RoutingInstruction, navigation: Navigation) {
    this.id = parameters.id;
  }

  attached() {
    this.handleColorTheme();
    this.handleCreateEditor();
    this.handleLoadGetProject();
  }

  // private useUpdateImagesSrc(pages) {
  //   pages.forEach(page => {
  //     page.children.forEach(child => {
  //       if (child.type === 'image') {
  //         // Verifica se já existe "?" na URL
  //         const separator = child.src.includes('?') ? '&' : '?';

  //         // Altere o valor do `src` conforme necessário
  //         child.src = `${child.src}${separator}${new Date().getTime()}`;
  //       }
  //     });
  //   });
  // }

  private async handleLoadGetProject(): Promise<void> {
    if (!this.id) return;

    const project = await this.service.getProject(this.id);

    if (!project.template) return;
    this.project = project;

    const json = typeof project.template === 'string' ? JSON.parse(project.template) : project.template;

    // this.useUpdateImagesSrc(json.pages);

    this.store.loadJSON(json);
  }

  private handleCreateEditor(): void {
    if (this.store) return;

    this.handleSetTranslations();

    const user = this.auth.getUser();

    this.store = createEditor({
      showCredit: true,
      key: 'ZRySQwqaiM3YAu3mXiY_',
      width: "1080",
      height: "1080",
      userId: user.id,
      token: this.auth.getAccessToken(),
      container: document.getElementById('container'),
    });
  }

  private async handleSave(): Promise<void> {
    try {
      const storeJson = this.store.toJSON()

      const response = await this.service.upsertProject({
        id: this.id,
        alias: 'post',
        caption: 'post',
        creditPrice: 0,
        rawText: 'post',
        type: 'post',
        template: JSON.stringify(storeJson),
      });

      if (response?.id) toastifySuccess({ message: 'Projeto salvo com sucesso!' });

      if (response.id && !this.id) {
        window.history.replaceState({}, 'Projeto', `/editor/${response.id}`);
      }
    } catch (error) {
      toastifyError({ message: 'Erro ao salvar projeto' });
    }
  }

  private handleColorTheme(): void {
    const colorTheme = localStorage.getItem('rankmyseite-color-theme');

    const div = document.querySelector("#polotno");

    if (colorTheme === 'light') {
      div.classList.remove('bp5-dark');
    } else {
      div.classList.add('bp5-dark');
    }

    this.colorThemeSubscription = this.ea.subscribe('color-theme', (colorTheme: { theme: string }) => {
      if (colorTheme.theme === 'light') {
        div.classList.remove('bp5-dark');
      } else {
        div.classList.add('bp5-dark');
      }
    });
  }

  private handleSetTranslations(): void {
    let locale = this.I18N.getLocale();

    this.localeSubscription = this.ea.subscribe('language-changed', (payload: { language: string, elementId: string }) => {
      locale = payload.language;

      this.handleTranslate(locale);
    });

    this.handleTranslate(locale);
  }

  private handleTranslate(locale: string): void {
    switch (locale) {
      case 'pt_BR':
        setTranslations(BR as any)
        break;
      case 'en_US':
        setTranslations(US as any)
        break;
      case 'es_ES':
        setTranslations(ES as any)
        break;
      default:
        setTranslations(US as any)
        break;
    }
  }

  detaching() {
    this.localeSubscription.dispose();
    this.colorThemeSubscription.dispose();

    this.store.clear({ keepHistory: true });
    this.store.addPage();
    this.store.setSize(1080, 1080);
  }

  private handleSelectedTemplate(event: { target: { files: Blob[]; }; }): void {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const template = JSON.parse(fileReader.result as string);

      this.store.loadJSON(template);
    } // end fileReader.onload

    fileReader.readAsText(event.target.files[0]);
  }

  private async openSaveTemplateDialog() {
    const template = this.store.toJSON();

    await this.dialogService.open({
      component: () => SaveTemplateDialog,
      model: { template },
      lock: false,
      startingZIndex: 100,
    });
  }

  async openEditCaption() {
    await this.dialogService.open({
      component: () => EditCaptionDialog,
      model: { project: this.project },
      lock: false,
      startingZIndex: 100,
    });
  }
}