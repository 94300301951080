<div class="w-full max-w-full px-3 shrink-0 mt-6">
  <!-- begin::card -->
  <div class="card">
    <div class="relative h-full max-w-full overflow-hidden rounded-xl">
      <div class="relative z-10 flex flex-col flex-auto h-full p-4">
        <h5 class="pt-2 mb-4 font-bold dark:text-white text-lg">Status</h5>



      </div>
    </div>
  </div>
  <!-- end::card -->
</div>