import { Carousel } from "flowbite";
import type { CarouselItem, CarouselOptions, CarouselInterface, InstanceOptions } from "flowbite";

interface ICarousel {
  id: string;
  image: string;
  title?: string;
  subTitle?: string;
  opacity?: number;
}

export class CarouselComponent {

  private carousel: ICarousel[] = [];

  attached() {
    const carouselElement: HTMLElement = document.getElementById('carousel-dashboard');

    this.carousel = [
      {
        id: 'carousel-item-1',
        image: 'assets/images/carousel/13fb8bcf-854a-4dec-9107-0baa24ce2ded.png',
        title: 'Transforme Ideias em Conteúdo Incrível!',
        subTitle: 'Explore o poder da Amplitude para dar vida às suas ideias com facilidade.',
        opacity: 0.5
      },
      {
        id: 'carousel-item-2',
        image: 'assets/images/carousel/462c3dc6-2053-4c4b-9bf0-75340ebc466d.png',
        title: 'Agilize Sua Estratégia de Conteúdo!',
        subTitle: 'Ganhe tempo e eficiência com nossa plataforma de criação de conteúdo.',
        opacity: 0.5
      },
      {
        id: 'carousel-item-3',
        image: 'assets/images/carousel/6427c3ce-c740-4d78-b64f-0e67264bd7ab.png',
        title: 'Conquiste a Audiência dos Seus Sonhos!',
        subTitle: 'Crie conteúdo envolvente que cativa e converte seus seguidores.',
        opacity: 0.5
      },
      {
        id: 'carousel-item-4',
        image: 'assets/images/carousel/bf634d4f-7280-4332-aedd-65e268610c22.png',
        title: 'Marketing Simplificado, Resultados Amplificados!',
        subTitle: 'Descubra como a Amplitude pode impulsionar sua estratégia de marketing.',
        opacity: 0.5
      },
      {
        id: 'carousel-item-5',
        image: 'assets/images/carousel/c09af1bb-4c1c-4adb-a605-516782c62a21.png',
        title: 'Faça Parte da Revolução do Conteúdo!',
        subTitle: 'Junte-se a milhares de criadores de conteúdo e transforme sua presença online.',
        opacity: 0.5
      }
    ]

    const items: CarouselItem[] = this.carousel.map((item: ICarousel, index: number) => {
      return {
        position: index,
        el: document.getElementById(item.id) as HTMLElement
      }
    })

    const options: CarouselOptions = {
      defaultPosition: 1,
      interval: 5000,
    }

    const instanceOptions: InstanceOptions = {
      id: 'carousel-dashboard',
      override: true
    };

    const carousel: CarouselInterface = new Carousel(carouselElement, items, options, instanceOptions)

    carousel.cycle()

    const $prevButton = document.getElementById('data-carousel-prev');
    const $nextButton = document.getElementById('data-carousel-next');

    $prevButton.addEventListener('click', () => {
      carousel.prev();
    });

    $nextButton.addEventListener('click', () => {
      carousel.next();
    });
  }

}