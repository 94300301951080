<!-- drawer component -->
<aside class="flex">
  <div id="drawer-navigation" data-drawer-target="drawer-navigation" tabindex="-1" aria-labelledby="drawer-navigation-label" class="flex flex-col bg-white dark:bg-gray-800 overflow-y-auto scrollbar-thin scrollbar-rounded-md scrollbar-thumb-rounded-md scrollbar-thumb-gray-300 h-screen p-5 pt-5 shadow-sm fixed top-0 left-0 z-40 w-64 transition-transform -translate-x-full">

    <div class="inline-flex px-3">
      <img src="assets/images/logo.png" class="h-10 mt-1.5 duration-500 ${open && 'rotate-[360deg]'}" alt="logo-cropped" />
    </div>

    <div class="py-4 flex flex-col">
      <template repeat.for="navModel of navigationHelper.getNavigationModel()">
        <ul if.bind="navModel.routes.length > 0" class="pt-4 mt-4 space-y-1 font-medium ${$index > 0 ? 'border-t border-gray-200 dark:border-gray-700' : ''}">

          <span t="menu.${navModel.groupTitle}" class="pb-4 text-xs font-semibold text-gray-500 uppercase dark:text-gray-400">${navModel.groupTitle}</span>

          <template repeat.for="route of navModel.routes">

            <li class="${route.data.soon ? 'relative pointer-events-none' : ''}" data-drawer-hide="drawer-navigation">
              <a load="${route.path.length === 0 ? '/' : route.path}" class="flex items-center p-2 focus:outline-none hover:no-underline hover:text-current text-sm text-gray-900 rounded-lg hover:bg-gray-100 group dark:text-gray-200 dark:hover:bg-gray-700">
                <i class="${route.data.icon}"></i>
                <span t="menu.${route.data.label}" class="ml-3 flex-none" sidebar-toggle-item="">${route.data.label}</span>
              </a>
              <span if.bind="route.data.soon" class="absolute right-0 -translate-y-7 bg-secondary-600 text-white text-xs font-medium px-2.5 py-0.5 rounded">Em breve</span>
            </li>
          </template>

        </ul>
      </template>

    </div>

  </div>
</aside>