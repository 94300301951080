<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Principal">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">
      <div class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">

        <div class="flex-1">
          <input-component type="text" label="Pré-Título" value.bind="website.homeHeroLabel" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Título Principal" value.bind="website.homeHeroTitle" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Descrição Principal" value.bind="website.homeHeroDescription" />
        </div>
      </div>


      <!-- <div class="flex-1">
        <input-component type="text" label="homeHeroCallToActionText" value.bind="website.homeHeroCallToActionText" />
      </div>

      <div class="flex-1">
        <input-component type="text" label="homeHeroCallToActionTargetUrl" value.bind="website.homeHeroCallToActionTargetUrl" />
      </div> -->

      <div class="flex-1">
        <single-file-upload-component label="Banner Principal" selected-file-id.bind="website.homeHeroBannerImageFileId"></single-file-upload-component>
      </div>

      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20" trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>