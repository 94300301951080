import { watch } from "@aurelia/runtime-html";
import { ToastType } from '@components/toast/events/toast-event';
import { ToastComponent } from '@components/toast/toast-component';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ModelTypes } from '@zeus';
import { IEventAggregator } from 'aurelia';
import type { ModalInterface, ModalOptions } from 'flowbite';
import { Modal } from 'flowbite';
import { ICreateModalService } from '../services/create-modal-service';

interface IWhitelist {
  id: string;
  value: string;
}

export class CreateCampaignModalComponent {
  private modal: ModalInterface;

  private productsTagify: Tagify;
  private audiencesTagify: Tagify;

  private brands: Array<ModelTypes['brands']>;

  private selectedBrandId: string;
  private whitelistProducts: IWhitelist[];
  private whitelistAudiences: IWhitelist[];

  private campaignObjective: string;;

  constructor(
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator,
    @ICreateModalService private service: ICreateModalService
  ) { }

  async attached() {
    await this.handleGetBrands();

    const $modalElement: HTMLElement = document.querySelector('#modal-create-campaigns');

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
    }

    this.modal = new Modal($modalElement, modalOptions);
  }

  private showModal(): void {
    this.modal.show();
  }

  private hideModal(): void {
    this.modal.hide();
  }

  private async handleGetBrands(): Promise<void> {
    this.brands = await this.service.brands();
  }

  @watch('selectedBrandId')
  async handleSelectedBrandId(newData: string, oldData: string): Promise<void> {
    if (newData && newData !== oldData) {
      this.productsTagify?.removeAllTags();
      this.whitelistProducts = await this.handleGetProducts();

      this.audiencesTagify?.removeAllTags();
      this.whitelistAudiences = await this.handleGetAudiences();
    }
  }

  private async handleGetProducts(): Promise<IWhitelist[]> {
    const response = await this.service.products();

    const whitelist: IWhitelist[] = [];

    for (const product of response ?? []) {
      whitelist.push({
        id: product.id,
        value: product.alias,
      })
    }

    return whitelist;
  }

  private async handleGetAudiences(): Promise<IWhitelist[]> {
    const response = await this.service.audiences();

    const whitelist: IWhitelist[] = [];

    for (const audience of response ?? []) {
      whitelist.push({
        id: audience.id,
        value: audience.brandName,
      })
    }

    return whitelist;
  }

  private async handleGenerateCalendarEx(): Promise<void> {
    try {
      const { success } = await this.service.generateCalendarEx({
        brandId: this.selectedBrandId,
        productIds: this.productsTagify.value.map((x: IWhitelist) => x.id),
        audiencesIds: this.audiencesTagify.value.map((x: IWhitelist) => x.id),
        campaignObjective: this.campaignObjective,
      });

      if (!success) {
        throw new Error('Erro ao criar campanha');
      }

      this.hideModal();

      this.showMessage({ title: 'Sucesso', message: 'Campanha criada com sucesso', type: ToastType.SUCCESS });
    } catch (_) {
      this.showMessage({ title: 'Erro', message: 'Erro ao criar campanha', type: ToastType.DANGER });
    }
  }

  private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type
      }
    });
  }
}