// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sidebar-navigation .active {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}`, "",{"version":3,"sources":["webpack://./src/common/components/sidebar/sidebar-component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uDAAuD;EACvD,+BAA+B;EAC/B,2DAA2D;AAC7D","sourcesContent":["#sidebar-navigation .active {\r\n  --tw-bg-opacity: 1;\r\n  background-color: rgb(234 88 12 / var(--tw-bg-opacity));\r\n  --tw-text-opacity: 1 !important;\r\n  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
