import { DI } from "aurelia";
import { User } from '@nhost/nhost-js';
import { AuthService } from '@services/auth-service/auth-service';

// create auth0 implementation interface to login and logout
export interface IAuthService {
  user: User;
  signUp({ email, password, metadata }: { email: string, password: string, metadata: any }): Promise<void>
  signIn({ email, password }: { email: string, password: string }): Promise<void>
  signInForGoogle(metadata: any): Promise<void>
  signOut(): Promise<void>;
  resetPassword({ email }: { email: string }): Promise<void>
  getAccessToken(): string;
  getUser(): User;
  changePassword(newPassword: string): Promise<void>
}

export const IAuthService = DI.createInterface<IAuthService>(x => x.singleton(AuthService));