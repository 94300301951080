import { lifecycleHooks } from 'aurelia';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { Parameters, Navigation, RoutingInstruction, IRouter } from '@aurelia/router';

@lifecycleHooks()
export class AuthenticationHook {

  constructor(
    @IRouter private router: IRouter,
    @IAuthService private auth: IAuthService) { }

  canLoad(viewModel, params: Parameters, instruction: RoutingInstruction, navigation: Navigation) {
    if (!this.auth.user) {
      if (instruction.route.match.data.private === false) {
        return true;
      }

      this.router.load('login');

      return false
    } else {
      if (instruction.route.match.data.private === false) {

        this.router.load('/');
        return false;
      }

      return true;
    }
  }
}