<section>

  <div class="flex justify-between items-center gap-2">
    <!-- <i class="fa-solid fa-address-card text-lg text-gray-900 dark:text-white"></i> -->

    <div class="flex flex-col">
      <h5 class="text-base font-bold text-gray-900 dark:text-white">${title}</h5>
      <h6 class="text-sm font-normal text-gray-700 dark:text-gray-400">${subtitle}</h6>
    </div>

    <au-slot name="actions"></au-slot>
  </div>

</section>