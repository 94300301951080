<import from="@components/loading/loading-component" />

<import from="./sections/hero/hero-section" />
<import from="./sections/brand-highlight/brand-highlight-section" />
<import from="./sections/what-we-do/what-we-do-section" />
<import from="./sections/our-differentiators/our-differentiators-section" />

<div class="flex flex-col gap-4 my-6">

  <template if.bind="website">
    <hero-section website.bind="website"></hero-section>

    <brand-highlight-section website.bind="website"></brand-highlight-section>

    <what-we-do-section website.bind="website"></what-we-do-section>

    <our-differentiators-section website.bind="website"></our-differentiators-section>
  </template>

  <template else>
    <loading-component></loading-component>
  </template>

</div>