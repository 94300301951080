<import from='./../../components/header/header-component' />

<section class="p-4">
  <header-component title="Registro de Tarefas" subtitle="Adicione atividades para serem realizadas">
    <div au-slot="actions">
      <button type="button" click.trigger="openTaskDialog()"
        class="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs p-2 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
  </header-component>

  <div class="flex flex-col gap-2 mt-6">

    <template if.bind="tasks.length > 0" repeat.for="item of tasks">
      <!-- begin::card -->
      <div
        class="flex flex-row w-full h-[75px] rounded-lg bg-white dark:bg-gray-800 shadow-soft-xs shadow-gray-300 dark:shadow-current border border-solid border-gray-200 dark:border-gray-700 px-4 py-2.5 gap-4">
        <div class="w-1 bg-yellow-400"></div>

        <div class="flex flex-col gap-1 w-full">
          <div class="flex justify-between items-center">

            <div class="flex items-center me-4">
              <input checked id="orange-checkbox" type="checkbox" checked.bind="item.finished"
                click.trigger="resolveTask(task, $event)"
                class="size-3 text-[10px] text-orange-500 bg-gray-100 border-gray-400 rounded focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer">
              <label for="orange-checkbox" click.trigger="resolveTask(item, $event)"
                class="ms-2 text-xs font-semibold text-gray-900 dark:text-gray-200 cursor-pointer line-clamp-1">${item.title}</label>
            </div>

            <span class="text-[10px] text-gray-700 dark:text-gray-400 line-clamp-1">${item.date |
              dateTime:'pt-BR'}</span>
          </div>

          <span class="text-[10px] text-gray-700 dark:text-gray-400 line-clamp-2">${item.description}</span>
        </div>
      </div>
      <!-- end::card -->
    </template>
    <template else>
      <div class="flex flex-col items-center justify-center gap-4 mt-6">
        <span class="text-gray-700 dark:text-gray-400 text-sm">Você não possui tarefas</span>
      </div>
    </template>

  </div>

</section>