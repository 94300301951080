<!-- <div class="flex flex-col justify-center items-center">
  <span class="text-base font-semibold text-gray-400 dark:text-gray-400 mt-28">Registro realizado com sucesso 🎉🎊</span>
  <span class="text-base font-semibold text-gray-400 dark:text-gray-400 mt-1">Verefique seu e-email para validar... ... ...</span>

  <a href="/login" class="mb-0 text-base font-semibold leading-normal text-gray-400 dark:text-gray-400 mt-5 pb-3 group">
    Voltar para tela de login
    <i class="fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.5 ml-1 leading-normal transition-all duration-200"></i>
  </a>

  <img src="assets/images/undraw_mail_sent.svg" alt="undraw" class="h-80 mt-6">
</div> -->

<section class="mt-0 transition-all duration-200 ease-soft-in-out ps">

  <!-- beggin::header -->
  <div class="pb-56 pt-12 m-4 min-h-[50vh] items-start rounded-xl p-0 relative overflow-hidden flex bg-cover bg-center bg-[url('assets/images/socialmedia3.png')]">
    <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover opacity-60 bg-gradient-to-tl from-gray-900 to-slate-800"></span>
    <div class="w-full mx-auto px-6 z-50">
      <div class="flex flex-wrap justify-center -mx-3">
        <div class="w-full max-w-full px-3 mx-auto text-center shrink-0 lg:flex-0 lg:w-7/12">
          <h1 class="mb-3 text-white text-5xl font-bold">Bem vindo!</h1>
          <!-- <p class="mb-3 text-white text-base">Use esses formulários incríveis para fazer login ou criar uma nova conta em seu projeto gratuitamente.</p> -->
        </div>
      </div>
    </div>
  </div>
  <!-- end::header -->

  <!-- begin::card -->
  <div class="w-full mx-auto px-6">
    <div class="flex flex-wrap justify-center -mx-3 -mt-60 lg:-mt-56 md:-mt-56">
      <div class="w-full max-w-full px-3 mx-auto shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
        <div class="relative z-0 flex flex-col min-w-0 break-words bg-white border-0 py-4 dark:bg-gray-950 shadow-xl rounded-2xl bg-clip-border">

          <div class="flex flex-col justify-center items-center p-6 py-4">

            <!-- beggin::tab -->
            <span class="text-base font-semibold text-gray-400 dark:text-gray-400">Registro realizado com sucesso 🎉🎊</span>
            <span class="text-base font-semibold text-gray-400 dark:text-gray-400 mt-1">Verefique seu e-email para validar... ... ...</span>

            <a href="/login" class="mb-0 text-base font-semibold leading-normal text-gray-400 dark:text-gray-400 mt-5 pb-3 group">
              Voltar para tela de login
              <i class="fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.5 ml-1 leading-normal transition-all duration-200"></i>
            </a>
            <!-- end::tab -->

            <img src="assets/images/undraw_mail_sent.svg" alt="undraw" class="h-80 mt-6">
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- end::card -->

</section>

<!-- beggin::footer -->
<footer class="py-12">
  <div class="w-full mx-auto px-6">
    <div class="flex flex-wrap -mx-3">
      <div class="w-8/12 max-w-full px-3 mx-auto mt-1 text-center flex-0">
        <p class="mb-0 text-slate-400">
          Copyright ©
          <script>document.write(new Date().getFullYear())</script>2023
          Todos os direitos reservados - CopyMágiico.
        </p>
      </div>
    </div>
  </div>
</footer>
<!-- end::footer -->