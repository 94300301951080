import { newInstanceForScope } from '@aurelia/kernel';
import { IRouteableComponent, IRouter, Navigation, Parameters, RoutingInstruction, routes } from '@aurelia/router';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { AlertModalComponent } from '@components/alert-modal/alert-modal-component';
import { AlertModalType } from '@components/alert-modal/events/alert-modal-event';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { companySegmentData, productOrServiceData } from 'common/data';
import { IEventAggregator, bindable } from 'aurelia';

export class AudiencePage {
  private audienceId: string;
  private companyName: string;
  private segment: string;
  private productOrService: string;
  private targetAudience: string;

  private personasData = [];

  constructor(
    @IRouter private router: IRouter,
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator,
    @IValidationRules readonly validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) readonly validationController: IValidationController,
  ) {
    validationRules
      .on(this)
      .ensure('companyName')
      .required().withMessage('Nome da empresa é obrigatório')
      .ensure('segment')
      .required().withMessage('Segmento é obrigatório')
      .ensure('productOrService')
      .required().withMessage('Produto ou serviço é obrigatório')
      .ensure('targetAudience')
      .required().withMessage('Público alvo é obrigatório')
  }

  private companySegmentData = companySegmentData;
  private productOrServiceData = productOrServiceData;

  async loading(params: { id: string }) {
    this.audienceId = params.id;

    if (this.audienceId) {
      await this.getAudienceById(this.audienceId);
    }
  }

  async getAudienceById(id: string): Promise<void> {
    const response = await this.zeusClient.getAudienceById(id);

    this.segment = response?.segment;
    this.companyName = response?.brandName;
    this.productOrService = response?.productOrService;
    this.targetAudience = response?.targetAudience;

    if (response?.personas) {
      this.personasData = typeof response.personas === 'string' ? JSON.parse(response.personas) : response.personas;
    }
  }

  private async handleUpsertAudience(): Promise<void> {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    AlertModalComponent.show({
      event: this.ea,
      payload: {
        label: 'Salvar audiência',
        message: 'Gostaria de salvar a audiência?',
        type: AlertModalType.SUCCESS,
        toastMessage: 'Audiência salva com sucesso!',
        confirmFunction: async () => {

          const { id, personas } = await this.zeusClient.upsertAudience({
            id: this.audienceId,
            brandName: this.companyName,
            segment: this.segment,
            productOrService: this.productOrService,
            targetAudience: this.targetAudience,
          });

          if (personas) {
            this.personasData = typeof personas === 'string' ? JSON.parse(personas) : personas;
          }

          if (!this.audienceId) {
            this.router.load(`/audience/${id}`);
          }
        }
      }
    });
  }
}