<import from="./components/action/action-component" />

<section class="flex flex-row card w-full mb-8">
  <div class="flex flex-row justify-between px-5 py-3 w-full gap-1">

    <div class="flex flex-col justify-between">
      <span class="text-base font-bold text-primary-600 uppercase">${opportunity.name ?? 'N/A'}</span>
      <span class="text-sm font-bold text-gray-400">${opportunity.dealStatus.displayName}</span>
    </div>

    <div class="flex items-center flex-shrink-0 gap-3">
      <a href="/opportunity-management" type="button" class="btn-neutral-outline-small"><i class="fa-solid fa-arrow-left mr-2"></i>Voltar</a>

      <!-- begin::action-component -->
      <action-component opportunity.bind="opportunity"></action-component>
      <!-- end::action-component -->
    </div>

  </div>
</section>