<import from="@components/spinner-button/spinner-button-component" />

<div id="modal-${modalId}" data-modal-backdrop="static" aria-hidden="true" tabindex="-1"
  class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative w-full max-w-2xl max-h-full">

    <!-- begin::content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

      <!-- begin::header -->
      <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">

        <div class="flex flex-col">
          <h5 class="text-xl font-semibold text-gray-900 dark:text-white">Crie Conteúdo Para Seus Projetos</h5>
          <p class="text-sm font-normal text-gray-700 dark:text-gray-400">Escolha o tipo de conteúdo que você deseja
            criar</p>
        </div>

        <button type="button" click.trigger="hideModal()"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- end::header -->

      <!-- begin::body -->
      <form class="flex flex-col gap-4 p-6" autocomplete="off">

        <div class="flex-1">
          <label for="brand" class="form-label">Marca</label>
          <validation-container>
            <select id="brand" value.bind="selectedBrandId & validate" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of brands" model.bind="item.id">${item.brandName}</option>
            </select>
          </validation-container>
        </div>

        <template if.bind="selectedBrandId">

          <div if.bind="channel !== 'images' " class="flex-1">
            <label for="product" class="form-label">Produtos</label>
            <select id="product" value.bind="selectedProductId" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of products" model.bind="item.id">${item.alias}</option>
            </select>
          </div>

          <div if.bind="channel !== 'images' " class="flex-1">
            <label for="audience" class="form-label">Audiências</label>
            <select id="audience" value.bind="selectedAudienceId" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of audiences" model.bind="item.id">${item.brandName}</option>
            </select>
          </div>

          <div if.bind="!['posts','videos','prints','presentations','adverts'].includes(channel)" class="flex-1">
            <label for="prompt" class="form-label">Prompt</label>
            <textarea id="prompt" class="form-input" value.bind="prompt"></textarea>
          </div>
        </template>

      </form>
      <!-- end::body -->

      <!-- begin::footer -->
      <div
        class="flex items-center justify-end py-4 px-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button type="button" click.trigger="hideModal()" class="btn-neutral">Cancelar</button>
        <spinner-button-component label="Gerar" btn-class="btn-success"
          trigger-function.bind="() => handleRedirectToGeneration()"></spinner-button-component>
      </div>
      <!-- end::footer -->

    </div>
    <!-- end::content -->
  </div>
</div>