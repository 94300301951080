import { nhost } from 'lib/nhost';
import { IEventAggregator, inject } from "aurelia";
import { AuthChangeEvent, NhostSession, User } from '@nhost/nhost-js';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';

@inject()
export class AuthService implements IAuthService {
  user: User | null = null;

  constructor(@IEventAggregator private EA: IEventAggregator) {
    nhost.auth.onAuthStateChanged((event: AuthChangeEvent, session: NhostSession) => {
      if (session && session.user) {
        this.user = session.user;

        window.dataLayer.push({
          event: 'login',
          userId: session.user.id,
        });
      } else {
        this.user = null;
      }
    })
  }

  async signUp({ email, password, metadata }: { email: string, password: string, metadata: any }): Promise<void> {
    try {
      await nhost.auth.signUp({
        email,
        password,
        options: {
          ...metadata && { metadata }
        }
      });

    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async signIn({ email, password }: { email: string, password: string }): Promise<void> {
    try {
      const { error } = await nhost.auth.signIn({ email, password })

      if (error) {
        await nhost.auth.sendVerificationEmail({ email })

        throw new Error(error.message)
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async signInForGoogle(metadata: any): Promise<void> {
    try {
      await nhost.auth.signIn({
        provider: 'google',
        options: {
          ...metadata && { metadata }
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async signOut(): Promise<void> {
    try {
      await nhost.auth.signOut()

    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async resetPassword({ email }: { email: string }): Promise<void> {
    try {
      await nhost.auth.resetPassword({
        email,
        options: {
          redirectTo: "/my-account"
        }
      })
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  async changePassword(newPassword: string): Promise<void> {
    try {
      await nhost.auth.changePassword({ newPassword })
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      } else {
        throw new Error('Erro inesperado, tente novamente')
      }
    }
  }

  getAccessToken(): string {
    const accessToken = nhost.auth.getAccessToken()
    return accessToken
  }

  getUser(): User {
    const user = nhost.auth.getUser()
    return user;
  }


}