<div id="video-player-modal" data-modal-target="video-player-modal" data-modal-backdrop="static" aria-hidden="true" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative max-h-full">

    <!-- begin::modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">


      <!-- begin::modal header -->
      <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">

        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Video player</h3>

        <button type="button" data-modal-hide="video-player-modal" click.trigger="hideModal()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- end::modal header -->

      <!-- begin::modal body -->
      <div id="player"></div>
      <!-- end::modal body -->

    </div>
    <!-- end::modal content -->
  </div>
</div>