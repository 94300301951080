export interface IGenericListConfig {
  // title: string;
  data: any[];
  perPage: number;
  currentPage?: number;
  tableName: string;
  streamName: string;
  aggregateName: string;
  columns: string[];
  filters: IGenericListFilter[];
  fixedFilters?: IGenericListFilter[];
  orderBy?: IGenericListOrderBy[];
  total: number;
  // loading?: boolean;
  // pageSizes: number[];
  // onRowClick: (row: any) => void;
}

export interface IGenericListOrderBy {
  title: string;
  field: string;
  value: string | null;
}

export interface IGenericListFilter {
  title: string;
  field: string;
  value: any;
  comparsionType: GenericListComparsionType;
  options?: any[];
  type: GenericListFildInputType;
  between?: {
    title: string;
    value: any;
    comparsionType: GenericListComparsionType;
  }[]
}

export enum GenericListFildInputType {
  Text = 'text',
  Date = 'date',
  Number = 'number',
  Currency = 'currency',
  Boolean = 'boolean',
  Select = 'select',
  Custom = 'custom',
  Checkbox = 'checkbox',
}

export enum GenericListComparsionType {
  Equals = 'equals',
  In = 'in',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  GreaterThanEquals = 'greaterThanEquals',
  LessThanEquals = 'lessThanEquals',
  Like = 'like',
  ILike = 'ilike',
  Between = 'between',
} 