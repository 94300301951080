enum ToastType {
  SUCCESS,
  DANGER,
  WARNING,
  INFO,
  GENERATE,
}

interface IToastEventShow {
  id?: string;
  title: string;
  message: string;
  type: ToastType;
  duration?: number;
}

interface IToastEventClose {
  id: string;
}

class ToastEvent {
  static readonly SHOW = 'toast:show';
  static readonly CLOSE = 'toast:close';

  constructor(public payload: IToastEventShow | IToastEventClose) { }
}

export { IToastEventShow, IToastEventClose, ToastEvent, ToastType };