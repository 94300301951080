<button type="button" id="${elementId}" class="w-full md:w-auto md:bg-gray-50 text-gray-500 dark:text-gray-400 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none md:focus:ring-4 md:focus:ring-gray-200 md:dark:focus:ring-gray-700 md:rounded-lg text-sm md:px-3 md:py-1.5 px-4 py-2 items-center flex gap-2 md:justify-center cursor-pointer hover:text-gray-900  dark:hover:text-white">
	<img class="h-5 w-5 rounded-full mt-0.5" src="assets/images/flags/${selectedLanguageFlag}.svg" alt="" />
	<span class="md:hidden">${selectedLanguageFriendlyName}</span>
</button>

<div class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700" id="language-dropdown-${elementId}" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(184px, 316px);">
	<ul class="py-1" role="none">
		<li repeat.for="language of availableLanguages">
			<a click.trigger="setLocale(language.code)" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer" role="menuitem">
				<div class="inline-flex items-center">
					<img class="h-5 w-5 rounded-full mt-0.5 me-1" src="assets/images/flags/${language.flag}.svg" alt="" />
					<span class="${!isOpenDrawer && 'hidden'}">${language.name}</span>
				</div>
			</a>
		</li>
	</ul>
</div>