import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface ITemplateService {
  getNewTemplateType(mediaTypeName: string): Promise<Array<ModelTypes['newTemplateType']>>
}

class TemplateService implements ITemplateService {

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  public async getNewTemplateType(channel: string): Promise<Array<ModelTypes['newTemplateType']>> {
    const result = await this.zeusClient.chain('query')({
      newTemplateType: [
        {
          ...(channel && channel != '') && { where: { channel: { _eq: channel } } },
        },
        {
          id: true,
          name: true,
          description: true,
          icon: true,
          saleCreditPrice: true,
          creditPrice: true,
          channel: true,
          thumbnailFileId: true,
        }
      ]
    });

    return result?.newTemplateType as Array<ModelTypes['newTemplateType']>
  }

}

export const ITemplateService = DI.createInterface<ITemplateService>(x => x.singleton(TemplateService));