<button id="dropdown-${product.id}-button" type="button" class="inline-flex items-center p-2 text-xs font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
  <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
  </svg>
</button>

<div id="dropdown-${product.id}" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-md w-44 dark:bg-gray-700 dark:divide-gray-600">
  <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-${project.id}-button">
    <li>
      <a href="/product/${product.id}" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"><i class="fa fa-pen-to-square pr-2"></i> Editar</a>
    </li>
  </ul>
  <div if.bind="product.linkConversion" class="py-2">
    <a click.trigger="handleSharingProduct()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"><i class="fa-solid fa-share-nodes pr-2"></i> Compartilhar</a>
  </div>
  <div class="py-2">
    <a click.trigger="handleDeleteProduct()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"><i class="fa fa-trash pr-2"></i> Excluir</a>
  </div>
</div>