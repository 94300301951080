import { ISignaler } from 'aurelia';
import { IGenericKanbanConfig } from '@components/generic-kanban/generic-kanban-component';

export class OpportunityManagementKanbanPage {

  private kanbanTimeagoSignaler: void;

  constructor(@ISignaler readonly signaler: ISignaler) { }

  attached() {
    setInterval(() => {
      this.kanbanTimeagoSignaler = this.signaler.dispatchSignal('kanbanTimeago');
    }, 5000);
  }

  detached() {
    this.kanbanTimeagoSignaler;
  }

  private config: IGenericKanbanConfig = {
    headerTitle: 'Funil Comercial',
    headerSubtitle: 'Gestão de leads e oportunidades de venda',
    tableName: 'deal',
    statusTableName: 'dealStatus',
    streamName: 'deal_stream',
    aggregateName: 'deal_aggregate',
    columns: [
      'id',
      'name',
      'email',
      'phone',
      'rating',
      'productId',
      'created_at',
      'statusId',
      'product.salePrice'
    ],
    fixedColumnFilters: [],
    filters: [],
    data: [],
    perPage: 10,
    currentPage: 1,
  }

}