import moment from 'moment';
import { Modal } from 'flowbite'
import { IRouter } from '@aurelia/router';
import { IEventAggregator } from 'aurelia';
import { actionsFromPublish } from 'common/data';
import type { ModalOptions, ModalInterface } from 'flowbite'
import { ToastType } from '@components/toast/events/toast-event';
import { ToastComponent } from '@components/toast/toast-component';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class PublishTextComponent {
  private modal: ModalInterface;

  private projectId: string;
  private integrationId: string;
  private selectedCategory: string;

  private tagify: Tagify;

  private scheduleDate: string;
  private selectedAction: string;

  private minDate: string;

  private actionsFromPublishData = actionsFromPublish;

  constructor(
    @IRouter private router: IRouter,
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator
  ) { }

  attached() {
    this.minDate = moment().format('YYYY-MM-DDTHH:mm');

    const $modalElement: HTMLElement = document.querySelector('#modal-publish');

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
      onShow: () => {
        this.selectedCategory = null;
        this.selectedAction = null;
        this.scheduleDate = null;
        this.integrationId = null;
        this.tagify?.removeAllTags();
      },
      onHide: () => {
        this.selectedCategory = null;
        this.selectedAction = null;
        this.scheduleDate = null;
        this.integrationId = null;
        this.tagify?.removeAllTags();
      }
    }

    this.modal = new Modal($modalElement, modalOptions);
  }

  private async getUserAppIntegrations() {
    const result = await this.zeusClient.getUserAppIntegrations();

    return result.filter((integration) => integration.integrationType === 'wordpress');
  }

  private async getWordpressCategories(integrationId: string) {
    const categories = await this.zeusClient.getWordpressCategories(integrationId);

    return categories;
  }

  private async getWordpressTags(userAppIntegrationId: string) {
    if (!userAppIntegrationId) return;

    const result = await this.zeusClient.getWordpressTags(userAppIntegrationId);

    this.tagify.settings.whitelist = result.tags.map(tag => { return { value: tag.name, id: tag.id } });
  }

  private async siteChanged(integrationId: string) {
    await this.getWordpressTags(integrationId);
  }

  private validateForm() {
    if (!this.selectedCategory || !this.selectedAction || !this.integrationId) return false;

    if (this.selectedAction === 'schedule' && !this.scheduleDate) return false;

    return true;
  }

  private async handlePublish() {
    if (!this.validateForm()) {
      this.showMessage({ title: 'Erro', message: 'Preencha todos os campos', type: ToastType.DANGER });
      return;
    }

    const { success, errorMessage } = await this.zeusClient.publishOrScheduleProject({
      channel: 'blog',
      subtype: 'wordpress',
      projectId: this.projectId,
      integrationId: this.integrationId,
      blogCategory: +this.selectedCategory,
      blogTags: this.tagify.value.map(tag => +tag.id),
      ...this.selectedAction === 'schedule' && { scheduledDate: moment(this.scheduleDate).toDate() }
    });

    if (!success) {
      this.showMessage({ title: 'Erro', message: errorMessage ?? 'Erro ao tentar publicar', type: ToastType.DANGER });
      return;
    }

    this.showMessage({ title: 'Sucesso', message: 'Publicação realizada com sucesso', type: ToastType.SUCCESS });

    this.hideModal();

    await this.router.load('/creations');
  }

  private showModal(projectId: string) {
    this.projectId = projectId;

    this.modal.show();
  }

  private hideModal() {
    this.modal.hide();
  }

  private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type
      }
    });
  }
}