<import from='@components/undraw/undraw-component' />
<import from='@components/card-title/card-title-component' />
<import from='@components/card-header/card-header-component' />

<import from='./../../components/output-card/output-card-component' />

<section>

  <card-title-component title-id="audiencias">
    <div au-slot="actions">
      <div class="flex justify-end gap-3">
        <a href="/audiences" type="button" class="btn-neutral-outline"><i class="fa-solid fa-arrow-left mr-2"></i>Voltar</a>
        <button click.trigger="handleUpsertAudience()" class="btn-primary">Salvar audiência</button>
      </div>
    </div>
  </card-title-component>

  <div class="flex flex-col lg:flex-row  gap-4 mb-6 mt-8">

    <!-- beggin:inputs -->
    <div class="card w-full lg:w-[40%] h-fit">
      <card-header-component header-id="audiencias" show-popover.bind="false"></card-header-component>

      <form class="p-4" novalidate="novalidate">
        <div class="flex flex-col gap-4 mb-6">
          <div>
            <label for="company_name" class="form-label">Nome da empresa</label>
            <validation-container>
              <input type="text" id="company_name" value.bind="companyName & validate" autocomplete="off" class="form-input">
            </validation-container>
          </div>

          <div>
            <label for="segment" class="form-label">Segmento</label>
            <validation-container>
              <select id="segment" value.bind="segment & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of companySegmentData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>

          <div>
            <label for="product_or_service" class="form-label">Descreva resumidamente seu produto / serviço</label>
            <validation-container>
              <select id="product_or_service" value.bind="productOrService & validate" class="form-input">
                <option model.bind="null">Selecione</option>
                <option repeat.for="item of productOrServiceData" model.bind="item.value">${item.text}</option>
              </select>
            </validation-container>
          </div>

          <div>
            <div class="flex flex-row justify-between">
              <label for="target_audience" class="form-label">Descreva resumidamente seu público alvo</label>

              <button data-popover-target="popover-target_audience" data-popover-placement="right" type="button">
                <svg class="w-4 h-4 mr-2 mb-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path>
                </svg>
              </button>

            </div>
            <validation-container>
              <input type="text" id="target_audience" value.bind="targetAudience & validate" autocomplete="off" class="form-input">
            </validation-container>
          </div>
        </div>

        <!-- beggin::button -->
        <div class="flex justify-end">
          <button click.trigger="handleUpsertAudience()" class="btn-primary">Salvar audiência</button>
        </div>
        <!-- end::button -->

      </form>
    </div>
    <!-- end:inputs -->

    <!-- beggin:outputs -->
    <div class="w-full lg:w-[60%] h-fit">
      <template if.bind="personasData.length === 0">
        <div class="grid grid-cols-2 gap-4 px-4">
          <output-card-component></output-card-component>
          <output-card-component></output-card-component>
        </div>
      </template>
      <template else>
        <div class="grid grid-cols-2 gap-4 px-4">
          <template repeat.for="data of personasData">
            <output-card-component persona.bind="data"></output-card-component>
          </template>
        </div>
      </template>
    </div>
    <!-- end:outputs -->
  </div>

  <div data-popover id="popover-target_audience" role="tooltip" class="absolute z-30 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white  border-gray-200 card shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
    <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
      <h3 class="font-semibold text-gray-900 dark:text-white">Sobre a descrição:</h3>
    </div>
    <div class="px-3 py-2 text-xs">
      <p>Explique em poucas palavras qual o perfil da pessoa que precisa do seu produto ou serviço</p>
    </div>
    <div data-popper-arrow></div>
  </div>

</section>