<import from="@components/loading/loading-component" />

<import from="./sections/our-history/our-history-section" />
<import from="./sections/what-move-us/what-move-us-section" />
<import from="./sections/strong-statement/strong-statement-section" />
<import from="./sections/we-are-ready/we-are-ready-section" />
<import from="./sections/contact-form/contact-form-section" />


<div class="flex flex-col gap-4 my-6">

  <template if.bind="website">
    <our-history-section website.bind="website"></our-history-section>

    <what-move-us-section website.bind="website"></what-move-us-section>

    <strong-statement-section website.bind="website"></strong-statement-section>

    <we-are-ready-section website.bind="website"></we-are-ready-section>

    <contact-form-section website.bind="website"></contact-form-section>
  </template>

  <template else>
    <loading-component></loading-component>
  </template>

</div>