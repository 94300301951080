import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import { actionsFromPublish } from 'common/data';
import moment from 'moment';
import { toastifyError, toastifySuccess, toastifyWarning } from 'common/helpers/toastify';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ITextModuleService } from '../../services/text-module-services';

export class SaveTextDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private projectId: string;
  private selectedAction: string;
  private scheduleDate: string;

  private minDate: string;
  private actionsFromPublishData = actionsFromPublish;

  constructor(
    @IZeusClient private zeusClient: ZeusClient,
    @IDialogDom private dialogDom: DefaultDialogDom,
    @ITextModuleService private service: ITextModuleService,
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async activate(model?: any) {
    this.projectId = model.projectId;

    this.minDate = moment().format('YYYY-MM-DDTHH:mm');
  }

  async close() {
    this.$dialog.ok();
  }

  private validateForm() {
    if (!this.selectedAction) return false;

    if (this.selectedAction === 'schedule' && !this.scheduleDate) return false;

    return true;
  }

  private async handleSaveTemplate(): Promise<void> {
    if (!this.validateForm()) {
      toastifyWarning({ message: 'Preencha todos os campos' });
      return;
    }

    try {

      const mutation = await this.service.publishOrScheduleProject({
        projectId: this.projectId,
        ...this.selectedAction === 'schedule' && { scheduledDate: moment(this.scheduleDate).toDate() },
        ...this.selectedAction === 'schedule' ? { publishDate: moment(this.scheduleDate).toDate() } : { publishDate: moment().toDate() }
      });

      toastifySuccess({ message: 'Publicação agendada com sucesso' });

      this.$dialog.ok();
    } catch (error) {
      toastifyError({ message: error });
    }

  }
}