<import from='@components/card-title/card-title-component' />
<import from='@components/breadcrumbs/breadcrumbs-component' />
<import from='@components/promise-bind/error/error-component' />
<import from='@components/promise-bind/pending/pending-component' />

<import from='./components/video-player-modal/video-player-modal-component' />

<section>
  <!-- <breadcrumbs-component breadcrumb-id="tutorials"></breadcrumbs-component> -->


  <card-title-component title-id="tutoriais">
    <div au-slot="actions"></div>
  </card-title-component>

  <template promise.bind="getYoutubePlaylists()">

    <template pending>
      <pending-component></pending-component>
    </template>

    <template then.from-view="data">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <template repeat.for="item of data.items">

          <!-- begin::card -->
          <div class="card p-2">
            <div
              class="flex flex-col h-full min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">

              <a class="block shadow-xl rounded-2xl dark:shadow-current">
                <img src="${item.snippet.thumbnails.medium.url}" alt="img-blur-shadow"
                  class="max-w-full shadow-lg rounded-2xl w-full ">
              </a>
              <div class="flex flex-col px-1 pt-6 h-full">
                <p
                  class="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-[#141727] to-[#3a416f] text-[.875rem] bg-clip-text">
                  Project #2</p>

                <h5 class="text-base font-bold text-gray-700 dark:text-gray-300 mb-2">${item.snippet.title}</h5>

                <p class="leading-relaxed text-xs text-gray-700 dark:text-gray-400 line-clamp-4 mb-6 grow">
                  ${item.snippet.description}</p>

                <div class="flex items-center justify-between">
                  <button type="button" click.trigger="handleVideoPlay(item.snippet.resourceId.videoId)"
                    class="inline-block px-8 py-2 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-lg shadow-none cursor-pointer leading-[1.4] ease-in text-[.75rem] hover:scale-[1.02] active:shadow-xs tracking-tight border-[#cb0c9f] text-[#cb0c9f] hover:border-[#cb0c9f] hover:bg-transparent hover:text-[#cb0c9f] hover:opacity-75 hover:shadow-none active:bg-[#cb0c9f] active:text-white active:hover:bg-transparent active:hover:text-[#cb0c9f]">
                    Play Video
                  </button>

                  <div class="mt-2">
                    <a href="javascript:;"
                      class="relative z-20 inline-flex items-center justify-center w-6 h-6 text-white transition-all duration-200 border-2 border-white border-solid ease-in-out text-[.75rem] rounded-[50%] hover:z-30">
                      <img
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/team-1.jpg"
                        class="w-full rounded-[50%]" alt="Image placeholder">
                    </a>
                    <a href="javascript:;"
                      class="relative z-20 inline-flex items-center justify-center w-6 h-6 -ml-4 text-white transition-all duration-200 border-2 border-white border-solid ease-in-out text-[.75rem] rounded-[50%] hover:z-30">
                      <img
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/team-2.jpg"
                        class="w-full rounded-[50%]" alt="Image placeholder">
                    </a>
                    <a href="javascript:;"
                      class="relative z-20 inline-flex items-center justify-center w-6 h-6 -ml-4 text-white transition-all duration-200 border-2 border-white border-solid ease-in-out text-[.75rem] rounded-[50%] hover:z-30">
                      <img
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/team-3.jpg"
                        class="w-full rounded-[50%]" alt="Image placeholder">
                    </a>
                    <a href="javascript:;"
                      class="relative z-20 inline-flex items-center justify-center w-6 h-6 -ml-4 text-white transition-all duration-200 border-2 border-white border-solid ease-in-out text-[.75rem] rounded-[50%] hover:z-30">
                      <img
                        src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/team-4.jpg"
                        class="w-full rounded-[50%]" alt="Image placeholder">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end::card -->

        </template>
      </div>
    </template>

    <template catch.from-view="error">
      <error-component></error-component>
    </template>

  </template>

  <video-player-modal-component></video-player-modal-component>

</section>