import { DI } from 'aurelia';
import { ModelTypes, ValueTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { IInsertOrUpdateProduct, IUpsertBrand } from '@interfaces/graphql-service/commands';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';

export interface IProductsService {
  getBrand(): Promise<ModelTypes["brands"]>;
  getProducts(): Promise<ModelTypes["products"][]>;
  getProductById(id: string): Promise<ModelTypes["products"]>;
  insertProduct(command: Omit<IInsertOrUpdateProduct, 'id'>): Promise<ModelTypes["products"]>;
  updateProductById(command: IInsertOrUpdateProduct): Promise<ModelTypes["products"] | undefined>;
  deleteProductById(id: string): Promise<ModelTypes["products"] | undefined>;
}

export class ProductsService implements IProductsService {

  constructor(
    @IAuthService private authService: IAuthService,
    @IZeusClient private zeusClient: ZeusClient
  ) { }

  async getBrand(): Promise<ModelTypes["brands"]> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {
          limit: 1
        },
        {
          id: true,
        }
      ]
    });

    if (!result.brands.length) return;

    return result.brands[0] as ModelTypes["brands"];
  }

  async getProducts(): Promise<ModelTypes["products"][]> {
    const user = this.authService.getUser();

    if (!user) return;

    const result = await this.zeusClient.chain('query')({
      products: [
        {
          where: {
            created_by: { _eq: user.id },
            deleted: { _eq: false },
          }
        },
        {
          id: true,
          alias: true,
          type: true,
          category: true,
          linkConversion: true,
          price: true,
          salePrice: true,
          characteristics: [{ path: '$', }, true],
          benefits: [{ path: '$', }, true],
          wordKeys: [{ path: '$', }, true],
          testimonials: [{ path: '$', }, true],
          description: true,
          imageOneId: true,
          imageTwoId: true,
          imageThreeId: true,
          imageFourId: true,
          created_by: true,
          created_at: true,
          updated_at: true,
        }
      ]
    });

    return result.products as ModelTypes["products"][];
  }

  async getProductById(id: string): Promise<ModelTypes["products"]> {
    const result = await this.zeusClient.chain('query')({
      products_by_pk: [
        { id: id },
        {
          id: true,
          alias: true,
          type: true,
          category: true,
          linkConversion: true,
          price: true,
          salePrice: true,
          characteristics: [{ path: '$', }, true],
          benefits: [{ path: '$', }, true],
          wordKeys: [{ path: '$', }, true],
          testimonials: [{ path: '$', }, true],
          description: true,
          imageOneId: true,
          imageTwoId: true,
          imageThreeId: true,
          imageFourId: true,
          created_by: true,
          created_at: true,
          updated_at: true,
        }
      ]
    });

    return result.products_by_pk as ModelTypes["products"];
  }

  async insertProduct(command: Omit<IInsertOrUpdateProduct, 'id'>): Promise<ModelTypes["products"]> {
    const result = await this.zeusClient.chain('mutation')({
      insert_products_one: [
        {
          object: command as ValueTypes["products_insert_input"]
        },
        {
          id: true,
        }
      ]
    });

    return result.insert_products_one as ModelTypes["products"];
  }

  async updateProductById(command: IInsertOrUpdateProduct): Promise<ModelTypes["products"]> {
    const { id, ...set } = command;

    const result = await this.zeusClient.chain('mutation')({
      update_products_by_pk: [
        {
          pk_columns: {
            id: id,
          },
          _set: set as ValueTypes["products_set_input"]
        },
        {
          id: true,
        }
      ]
    });

    return result.update_products_by_pk as ModelTypes["products"];
  }

  async deleteProductById(id: string): Promise<ModelTypes["products"] | undefined> {
    const result = await this.zeusClient.chain('mutation')({
      update_products_by_pk: [
        {
          pk_columns: {
            id: id,
          },
          _set: {
            deleted: true,
          }
        },
        {
          id: true,
        }
      ]
    });

    return result.update_products_by_pk as ModelTypes["products"];
  }
}

export const IProductsService = DI.createInterface<IProductsService>((x) => x.singleton(ProductsService));
