<import from="@components/calendar/calendar-component" />
<import from='@components/card-title/card-title-component' />

<section>

  <card-title-component title-id="calendario"></card-title-component>

  <div class="card p-4">
    <h5 class="font-bold text-base text-gray-900 dark:text-white">Editorial de Marketing</h5>
    <h6 class="text-sm text-gray-700 dark:text-gray-400 mb-4">Clique no evento para visualizar ou realizar edições</h6>

    <calendar-component></calendar-component>
  </div>

</section>