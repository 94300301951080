import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';

import { IOpportunityService } from '../../services/opportunity-service';

export class ProductsSection {
  private product: ModelTypes["products"];

  constructor(@IOpportunityService private service: IOpportunityService) { }

  attached() {
    this.product = this.service.opportunitySubscription?.product;
  }

  @watch('service.opportunitySubscription')
  async handleDealSubscription(newData: ModelTypes['deal'], oldData: ModelTypes['deal']) {
    this.product = newData?.product;
  }
}