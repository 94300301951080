import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface IArchetypeService {
  getBrands(): Promise<ModelTypes["brands"][]>;
}

export class ArchetypeService implements IArchetypeService {

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  async getBrands(): Promise<ModelTypes["brands"][]> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {
          limit: 1,
        },
        {
          id: true,
          brandName: true,
          slogan: true,
          voiceTone: true,
          segment: true,
          productOrService: true,
          fontPrimary: true,
          fontSecondary: true,
          isBrand: true,
          personality: true,
          colors: [{ path: '$', }, true],
          archtypeAdherence: [{ path: '$', }, true],
          logoFileId: true,
        }
      ]
    });

    return result.brands as ModelTypes["brands"][];
  }
}

export const IArchetypeService = DI.createInterface<IArchetypeService>((x) => x.singleton(ArchetypeService));
