import { bindable } from 'aurelia';

export class ColorPickerComponent {

  private id: string = "color-picker-" + Math.floor(Math.random() * 1000000).toString()
  @bindable label: string
  @bindable currentColor: string


  private isColorDark(color?: string): boolean {

    if (!color) {
      return false
    }

    // Converter a cor hexadecimal para RGB
    const hexToRgb = (hex: string) => {
      const bigint = parseInt(hex.replace("#", ""), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    };

    // Calcular a luminosidade da cor usando a fórmula HSL
    const hslToLuminosity = ([r, g, b]) => {
      r /= 255;
      g /= 255;
      b /= 255;
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      return (max + min) / 2;
    };

    // Definir o valor de luminosidade de corte para decidir entre preto ou branco
    const luminosityThreshold = 0.5;

    // Converter a cor hexadecimal para RGB e calcular a luminosidade
    const [r, g, b] = hexToRgb(color);
    const luminosity = hslToLuminosity([r, g, b]);

    // Retorna verdadeiro se a luminosidade da cor for menor do que o limiar
    return luminosity < luminosityThreshold;
  }
}