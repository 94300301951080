import { ModelTypes } from '@zeus';
import { bindable } from 'aurelia';
import { nhost } from 'lib/nhost';

export class ProductThumbnailImageComponent {
  @bindable product: ModelTypes["products"];


  private async getThumbnailURL(product: ModelTypes["products"]): Promise<string> {
    const fileId = product.imageOneId ?? product.imageTwoId ?? product.imageThreeId ?? product.imageThreeId;

    if (!fileId) {
      return "";
    }

    return this.getFileURL(fileId);
  }

  private getFileURL(fileId: string): string {

    const publicUrl = nhost.storage.getPublicUrl({ fileId, width: 100, height: 100 });

    return publicUrl;
  }

}