enum AlertModalType {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
}

interface IAlertModalEvent {
  label: string;
  message: string;
  type: AlertModalType;
  toastMessage?: string | null;
  confirmFunction: () => void;
}

class AlertModalEvent {
  static readonly SHOW = 'alert-modal:show';

  constructor(public payload: IAlertModalEvent) { }
}

export { IAlertModalEvent, AlertModalEvent, AlertModalType }

