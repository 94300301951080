import { IRouter } from '@aurelia/router';
import { initDropdowns, initCollapses, initDrawers } from 'flowbite';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
// import { IRouterNavigationHelper } from 'common/helpers/router/router-navigation-helper';

export class DrawerComponent {

  public constructor(
    @IRouter private router: IRouter,
    @IAuthService private auth: IAuthService,
    // @IRouterNavigationHelper private navigationHelper: IRouterNavigationHelper
  ) { }

  attached() {
    initDrawers();
    initCollapses();
    initDropdowns();
  }
}