<import from='@components/promise-bind/pending/pending-component' />

<section>
  <tenplate promise.bind="getThumbnailURL(product)">

    <template pending>
      <pending-component></pending-component>
    </template>

    <template then.from-view="data">

      <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-xl bg-gray-100 dark:bg-gray-700 border">
        <template if.bind="data">
          <img class="w-full h-full" src="${data}" alt="logo" />
        </template>
        <template else>
          <i class="fas fa-question text-sm text-gray-600 dark:text-gray-300"></i>
        </template>
      </div>

    </template>

    <template catch.from-view="error">
      <div class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-xl bg-gray-100 dark:bg-gray-700 border">
        <i class="fas fa-question text-sm text-gray-600 dark:text-gray-300"></i>
      </div>
    </template>

  </tenplate>
</section>