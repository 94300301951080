<import from="../spinner-button/spinner-button-component" />

<div id="alert-modal" data-modal-target="alert-modal" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative w-full max-w-md max-h-full">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- icon close -->
      <button type="button" click.trigger="hideModal()" data-modal-hide="alert-modal" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
        <span class="sr-only">Close modal</span>
      </button>

      <div class="p-6 text-center">

        <!-- icon -->
        <svg aria-hidden="true" class="mx-auto mb-4 text-gray-500 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>

        <!-- message -->
        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">${message}</h3>

        <!-- beggin::actions -->
        <div class="flex flex-row justify-center gap-2">
          <button click.trigger="hideModal()" data-modal-hide="alert-modal" type="button" class="btn-neutral">Cancelar</button>
          <spinner-button-component label="${label}" btn-class.bind="type" toast-message.bind="toastMessage" trigger-function.bind="() => executeConfirmationFunction()"></spinner-button-component>
        </div>
        <!-- end::actions -->

      </div>
    </div>
  </div>
</div>