import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class ProductsComponent {
  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  private async getProductAggregate() {
    const result = await this.zeusClient.getProductAggregate();

    return result;
  }
}