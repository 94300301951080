import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IAurelia, IEventAggregator, bindable } from 'aurelia';
import { Dropdown } from 'flowbite';
import { watch } from "@aurelia/runtime-html";
import type { DropdownOptions, DropdownInterface } from "flowbite";
import { User } from '@nhost/nhost-js';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { CreateProjectDialog } from '@components/create-project-dialog/create-project-dialog';
import { IRouter } from '@aurelia/router';
import { IDialogService } from '@aurelia/dialog';

export class TopnavComponent {
  @bindable open: boolean;
  @bindable isDark: boolean;
  domain
  private dropdown: DropdownInterface

  private user: User;

  constructor(
    @IAurelia private au: IAurelia,
    @IRouter private router: IRouter,
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator,
    @IDialogService private dialogService: IDialogService,
  ) { this.verifyIsOpenDrawer() }

  async attached() {

    this.user = this.auth.user;
    await this.zeusClient.getUserSubscription();
    const getBrandDomain = await this.zeusClient.chain('query')({
      website: [
        {
          where: {
            brand: {
              created_by: {
                _eq: this.user.id
              }
            }
          }
        },
        {
          domain: true
        }
      ]
    });
    if (getBrandDomain.website.length > 0) {
      this.domain = 'https://' + getBrandDomain.website[0].domain;
    }
    const $targetEl: HTMLElement = document.getElementById('dropdownUserMenu');

    const $triggerEl: HTMLElement = document.getElementById('dropdownUserMenuButton');

    const options: DropdownOptions = {
      placement: 'bottom-start',
      triggerType: 'click',
      offsetSkidding: 10,
      offsetDistance: 20,
      delay: 300,
    };

    this.dropdown = new Dropdown($targetEl, $triggerEl, options);

    // theme
    this.verifyColorTheme();

    // drawer
    this.verifyIsOpenDrawer();
  }

  @watch('zeusClient.userSubscription')
  handleCurrentUser(newUser: User, oldUSer: User) {
    this.user = newUser;
  }

  private verifyColorTheme = () => {
    const colorTheme = localStorage.getItem('rankmyseite-color-theme');

    if (colorTheme) {
      if (colorTheme === 'light') {
        this.isDark = false;
        document.body.classList.remove('dark');
      } else {
        this.isDark = true;
        document.body.classList.add('dark');
      }
    } else {
      this.isDark = true;
      document.body.classList.add('dark');
    }
  }

  private verifyIsOpenDrawer = () => {
    const isOpenDrawer = localStorage.getItem('rankmyseite-drawer-open');

    if (isOpenDrawer) {
      if (isOpenDrawer === 'true') {
        this.open = true;
      } else {
        this.open = false;
      }
    }
  }

  private openDrawer = () => {
    this.open = !this.open;

    const isOpenDrawer = localStorage.getItem('rankmyseite-drawer-open');

    if (isOpenDrawer) {
      if (isOpenDrawer === 'true') {
        localStorage.setItem('rankmyseite-drawer-open', 'false');
      } else {
        localStorage.setItem('rankmyseite-drawer-open', 'true');
      }
    } else {
      if (this.open) {
        localStorage.setItem('rankmyseite-drawer-open', 'true');
      } else {
        localStorage.setItem('rankmyseite-drawer-open', 'false');
      }
    }
  }

  private signOut = async () => {
    try {
      await this.auth.signOut();
      this.router.load('login');

    } catch (error) {
      console.log(error);
    }
  }

  private toggleDarkMode = () => {
    const colorTheme = localStorage.getItem('rankmyseite-color-theme');

    if (colorTheme) {

      if (colorTheme === 'light') {
        this.isDark = true;
        document.body.classList.add('dark');
        localStorage.setItem('rankmyseite-color-theme', 'dark');
      } else {
        this.isDark = false;
        document.body.classList.remove('dark');
        localStorage.setItem('rankmyseite-color-theme', 'light');
      }

      // if NOT set via local storage previously
    } else {
      if (document.body.classList.contains('dark')) {
        this.isDark = false;
        document.body.classList.remove('dark');
        localStorage.setItem('rankmyseite-color-theme', 'light');
      } else {
        this.isDark = true;
        document.body.classList.add('dark');
        localStorage.setItem('rankmyseite-color-theme', 'dark');
      }
    }

    this.ea.publish('color-theme', { theme: this.isDark ? 'dark' : 'light' });
  }

  private closeDropdownUserMenu = () => {
    this.dropdown.hide();
  }

  private async openCreateProjectDialog() {
    const { dialog } = await this.dialogService.open({
      component: () => CreateProjectDialog,
      model: {},
      lock: false,
      startingZIndex: 100,
    });
  }

}