<import from="./value-converters/month-year"></import>
<import from="./value-converters/date-to-iso-day"></import>
<import from="./value-converters/day-of-month"></import>
<import from="./value-converters/same-month-class"></import>
<import from="./value-converters/same-day-class"></import>
<import from="./value-converters/date-to-full-date-format"></import>
<import from="./components/view-calendar-event-drawer/view-calendar-event-drawer" />

<div ref="calendarHtmlElement">

</div>

<view-calendar-event-drawer component.ref="viewCalendarEventDrawerRef"></view-calendar-event-drawer>