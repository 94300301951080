<import from='@components/card-title/card-title-component' />
<import from='@components/card-header/card-header-component' />
<import from='@components/promise-bind/error/error-component' />
<import from='@components/promise-bind/pending/pending-component' />

<import from='./../../components/card-component' />

<section>

  <card-title-component title-id="images"></card-title-component>

  <div class="flex gap-4">

    <div class="grow">

      <template promise.bind="getNewTemplateType(channel)">

        <template pending>
          <pending-component></pending-component>
        </template>

        <template then.from-view="data">
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <template repeat.for="item of data">
              <card-component template.bind="item" action.bind="() => handleRedirect()"></card-component>
            </template>
          </div>
        </template>

        <template catch.from-view="error">
          <error-component></error-component>
        </template>

      </template>

    </div>
  </div>

</section>