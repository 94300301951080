<import from="@converters/currency-format-converter" />

<section>

  <a load="/oportunidade/${deal.id}">

    <div class="flex flex-row">
      <!-- <div class="w-2 bg-secondary-600 rounded-l-lg"></div> -->

      <div class="flex flex-col text-ocean-600 bg-white dark:bg-gray-800 max-w-xs w-80 gap-4">
        <div class="flex flex-col w-full p-4 gap-4">

          <div class="flex flex-row items-center gap-4">
            <span class="text-value flex items-center gap-2.5"><i class="fa-solid fa-square text-blue-700"></i> Em andamento</span>
            <span class="text-value flex items-center gap-2.5"><i class="fa-solid fa-square text-yellow-300"></i>Criado há ${deal.created_at | timeago & signal:'kanbanTimeago'}</span>
          </div>

          <div class="flex flex-row gap-1">
            <div class="flex flex-col flex-1">
              <span class="text-value !text-base font-bold line-clamp-1 mb-1">${deal.name ?? 'N/A'}</span>
              <span class="text-value line-clamp-1">${deal.email ?? 'N/A'}</span>
            </div>
          </div>

          <div class="flex items-center gap-6">
            <span class="text-value flex items-center gap-2.5"><i class="fa-solid fa-star"></i> ${deal.rating ?? '0'}</span>
            <i class="fa-solid fa-user text-value"></i>
            <span if.bind="deal.productId" class="text-value flex items-center gap-2.5"><i class="fa-solid fa-credit-card"></i> ${deal.product.salePrice | currency}</span>
            <span else class="text-value flex items-center gap-2.5"><i class="fa-solid fa-credit-card"></i> Nenhum produto</span>
          </div>

          <div class="flex justify-center items-center rounded-sm bg-gray-100 dark:bg-gray-900 py-1.5">
            <span class="text-xs text-gray-500 font-bold">Sem tarefa</span>
          </div>

        </div>

      </div>
    </div>

  </a>

</section>