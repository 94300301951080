import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';

import { IOpportunityService } from '../../services/opportunity-service';

export class HistoricSection {
  private opportunity: ModelTypes["deal"];

  constructor(@IOpportunityService private service: IOpportunityService) { }

  attached() {
    this.opportunity = this.service.opportunitySubscription;
  }

  @watch('service.opportunitySubscription')
  async handleDealSubscription(newData: ModelTypes['deal'], oldData: ModelTypes['deal']) {
    this.opportunity = newData;
  }
}