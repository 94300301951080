<div class="w-full mx-auto">
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg card">

    <div class="flex flex-col p-4 h-full">
      <p class="form-label mb-2 text-left grow">
        Nome:
        <span class="font-normal leading-relaxed text-xs text-gray-700 dark:text-gray-400">${persona.name}</span>
      </p>

      <p class="form-label mb-2 text-left grow">
        Idade:
        <span class="font-normal leading-relaxed text-xs text-gray-700 dark:text-gray-400">${persona.age}</span>
      </p>

      <p class="form-label mb-2 text-left grow">
        Profissão:
        <span class="font-normal leading-relaxed text-xs text-gray-700 dark:text-gray-400">${persona.profession}</span>
      </p>

      <p class="form-label mb-2 text-left grow">
        Interesses:
        <span class="font-normal leading-relaxed text-xs text-gray-700 dark:text-gray-400" repeat.for="data of persona.interests">${data}${$index < persona.interests.length - 1 ? ', ' : '' }</span>
      </p>

      <p class="form-label mb-2 text-left grow">
        Dores:
        <span class="font-normal leading-relaxed text-xs text-gray-700 dark:text-gray-400" repeat.for="data of persona.pains">${data}${$index < persona.pains.length - 1 ? ', ' : '' }</span>
      </p>

      <p class="form-label mb-6 text-left grow">
        Expectativas:
        <span class="font-normal leading-relaxed text-xs text-gray-700 dark:text-gray-400" repeat.for="data of persona.expectations">${data}${$index < persona.expectations.length - 1 ? ', ' : '' }</span>
      </p>

      <p class="form-label mb-6 text-left grow">
        Descrição geral:
        <span class="font-normal leading-relaxed text-xs text-gray-700 dark:text-gray-400">${persona.overallDescription}</span>
      </p>

    </div>
  </div>
</div>