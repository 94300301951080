<div class="relative p-4 w-full max-w-2xl max-h-full">
  <!-- begin::content -->
  <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
    <!-- begin::header -->
    <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
      <div class="flex flex-col">
        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Projeto</h3>
        <p class="text-sm font-normal text-gray-700 dark:text-gray-400">Resumo do Projeto</p>
      </div>
      <button type="button" click.trigger="$dialog.cancel()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
        </svg>
        <span class="sr-only">Fechar</span>
      </button>
    </div>
    <!-- end::header -->
    <!-- begin::body -->
    <div class="flex flex-col p-4 gap-4">
      <div class="flex flex-col items-center">
        <img src.bind="previewImage" alt="preview" class="w-full h-auto max-w-72">
      </div>
      <div class="flex-1 flex md:flex-row flex-col gap-4">
        <div class="flex flex-col flex-1">
          <label for="alias" class="form-label">Nome do Projeto</label>
          <input type="text" id="alias" value.bind="project.alias || project.blogTitle" class="form-input" readonly>
        </div>
        <div class="flex flex-col flex-1">
          <label for="type" class="form-label">Tipo</label>
          <input type="text" id="type" value.bind="project.type" class="form-input" readonly>
        </div>

      </div>
      <div class="flex-1 flex md:flex-row flex-col gap-4">
        <div class="flex flex-col flex-1">
          <label for="status" class="form-label">Status</label>
          <input type="text" id="status" value.bind="project.status" class="form-input" readonly>

        </div>

        <div class="flex flex-col flex-1">
          <label for="created_at" class="form-label">Data da Criação</label>
          <input type="text" id="created_at" value.bind="project.created_at | date:pt-BR" class="form-input" readonly>

        </div>


      </div>


      <div class="flex-1" if.bind="project.type === 'post'">
        <label for="caption" class="form-label">Legenda ou Texto</label>
        <textarea type="text" id="caption" value.bind="project.caption" rows="4" class="form-input h-[calc(100%-2rem)]" readonly></textarea>
      </div>

      <div class="flex-1" if.bind="project.type === 'text'">
        <label for="caption" class="form-label">Conteúdo:</label>
        <div innerhtml.bind="project.blogContent" class="border rounded-lg p-4 max-h-56 overflow-x-hidden form-input"></div>
      </div>
    </div>
    <!-- end::body -->
    <!-- begin::footer -->
    <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
      <a if.bind="project.type === 'post'" class="btn-primary" href="/editor/${project.id}" click.trigger="$dialog.cancel()">Ver projeto</a>
      <a if.bind="project.type === 'text'" class="btn-primary" href="/text/${project.id}" click.trigger="$dialog.cancel()">Ver projeto</a>
    </div>
    <!-- end::footer -->
  </div>
  <!-- end::content -->
</div>