export const yesOrNoData = [
  { value: "Sim", text: "Sim" },
  { value: "Não", text: "Não" }
]

export const socialNetworksData = [
  { value: "Facebook", text: "Facebook" },
  { value: "Instagram", text: "Instagram" },
  { value: "TikTok", text: "TikTok" },
  { value: "Twitter", text: "Twitter" },
  { value: "Youtube", text: "Youtube" },
  { value: "Pinterest", text: "Pinterest" },
  { value: "LinkedIn", text: "LinkedIn" },
  { value: "Blog", text: "Blog" },
  { value: "Outro", text: "Outro" }
]

export const staffCountData = [
  { value: "Apenas Eu", text: "Apenas Eu" },
  { value: "2-5 membros", text: "2-5 membros" },
  { value: "6-10 membros", text: "6-10 membros" },
  { value: "11-20 membros", text: "11-20 membros" },
  { value: "21-50 membros", text: "21-50 membros" },
  { value: "Mais de 50 membros", text: "Mais de 50 membros" }
]

export const companySegmentData = [
  { value: 'Tecnologia', text: 'Tecnologia' },
  { value: 'Saúde', text: 'Saúde' },
  { value: 'Varejo', text: 'Varejo' },
  { value: 'Educação', text: 'Educação' },
  { value: 'Alimentação', text: 'Alimentação' },
  { value: 'Serviços Financeiros', text: 'Serviços Financeiros' },
  { value: 'Construção Civil', text: 'Construção Civil' },
  { value: 'Logística', text: 'Logística' },
  { value: 'Marketing e Publicidade', text: 'Marketing e Publicidade' },
  { value: 'Moda e Vestuário', text: 'Moda e Vestuário' },
  { value: 'Automotivo', text: 'Automotivo' },
  { value: 'Turismo e Hospitalidade', text: 'Turismo e Hospitalidade' },
  { value: 'Entretenimento e Mídia', text: 'Entretenimento e Mídia' },
  { value: 'Agricultura', text: 'Agricultura' },
  { value: 'Energia e Recursos Naturais', text: 'Energia e Recursos Naturais' },
  { value: 'Imobiliário', text: 'Imobiliário' },
  { value: 'Outro', text: 'Outro' },
]

export const productOrServiceData = [
  { value: 'Produto Físico', text: 'Produto Físico' },
  { value: 'Serviço', text: 'Serviço' },
  { value: 'SaaS (Software como Serviço)', text: 'SaaS (Software como Serviço)' },
  { value: 'Marketplace', text: 'Marketplace' },
  { value: 'Consultoria', text: 'Consultoria' },
  { value: 'Assinatura/Conteúdo Exclusivo', text: 'Assinatura/Conteúdo Exclusivo' },
  { value: 'Franquia', text: 'Franquia' },
  { value: 'E-commerce', text: 'E-commerce' },
  { value: 'Aplicativo Móvel', text: 'Aplicativo Móvel' },
  { value: 'Produto Digital (eBooks, Cursos)', text: 'Produto Digital (eBooks, Cursos)' },
  { value: 'Dispositivos Eletrônicos', text: 'Dispositivos Eletrônicos' },
  { value: 'Hardware', text: 'Hardware' },
  { value: 'Plataforma de Treinamento', text: 'Plataforma de Treinamento' },
  { value: 'Outro', text: 'Outro' },
]

export const expectationData = [
  { text: 'Aumentar a visibilidade da marca', value: 'Aumentar a visibilidade da marca' },
  { text: 'Gerar mais leads qualificados', value: 'Gerar mais leads qualificados' },
  { text: 'Melhorar a conversão de vendas', value: 'Melhorar a conversão de vendas' },
  { text: 'Aumentar o engajamento nas redes sociais', value: 'Aumentar o engajamento nas redes sociais' },
  { text: 'Automatizar o marketing', value: 'Automatizar o marketing' },
  { text: 'Melhorar o posicionamento SEO', value: 'Melhorar o posicionamento SEO' },
]

export const marketingObjectiveData = [
  { value: "Aumentar o reconhecimento da marca", text: "Aumentar o reconhecimento da marca" },
  { value: "Gerar leads qualificados", text: "Gerar leads qualificados" },
  { value: "Aumentar as vendas e conversões", text: "Aumentar as vendas e conversões" },
  { value: "export Construir relacionamento com os clientes", text: "export Construir relacionamento com os clientes" },
  { value: "Fidelizar os clientes existentes", text: "Fidelizar os clientes existentes" },
  { value: "Posicionar-se como autoridade no mercado", text: "Posicionar-se como autoridade no mercado" },
  { value: "Expandir para novos mercados ou segmentos", text: "Expandir para novos mercados ou segmentos" },
  { value: "Lançar um novo produto ou serviço", text: "Lançar um novo produto ou serviço" },
  { value: "Educar o público sobre a solução oferecida", text: "Educar o público sobre a solução oferecida" },
  { value: "Melhorar a experiência do cliente", text: "Melhorar a experiência do cliente" }
]

export const voiceToneData = [
  { value: "Profissional e sério", text: "Profissional e sério" },
  { value: "Amigável e descontraído", text: "Amigável e descontraído" },
  { value: "Sofisticado e elegante", text: "Sofisticado e elegante" },
  { value: "Divertido e brincalhão", text: "Divertido e brincalhão" },
  { value: "Acolhedor e empático", text: "Acolhedor e empático" },
  { value: "Autoritário e confiante", text: "Autoritário e confiante" },
  { value: "Inspirador e motivador", text: "Inspirador e motivador" },
  { value: "Direto e objetivo", text: "Direto e objetivo" },
  { value: "Técnico e especializado", text: "Técnico e especializado" },
  { value: "Moderno e inovador", text: "Moderno e inovador" }
]

export const personalityData = [
  { value: "Jovem e descolada", text: "Jovem e descolada" },
  { value: "Tradicional e clássica", text: "Tradicional e clássica" },
  { value: "Aventureira e exploradora", text: "Aventureira e exploradora" },
  { value: "Ambiciosa e determinada", text: "Ambiciosa e determinada" },
  { value: "Criativa e inovadora", text: "Criativa e inovadora" },
  { value: "Sofisticada e refinada", text: "Sofisticada e refinada" },
  { value: "Humilde e autêntica", text: "Humilde e autêntica" },
  { value: "Inteligente e perspicaz", text: "Inteligente e perspicaz" },
  { value: "Confiável e honesta", text: "Confiável e honesta" },
  { value: "Encantadora e cativante", text: "Encantadora e cativante" }
]

export const fontFamilyData = [
  { value: "Roboto", text: "Roboto" },
  { value: "Open Sans", text: "Open Sans" },
  { value: "Lato", text: "Lato" },
  { value: "Montserrat", text: "Montserrat" },
  { value: "Raleway", text: "Raleway" },
  { value: "Oswald", text: "Oswald" },
  { value: "Source Sans Pro", text: "Source Sans Pro" }
]

export const isBrandData = [
  { value: false, text: "Marca pessoal" },
  { value: true, text: "Marca corporativa" }
]

export const socioeconomicProfileData = [
  { value: 'Alta renda (poder aquisitivo elevado, foco em qualidade)', text: 'Alta renda (poder aquisitivo elevado, foco em qualidade)' },
  { value: 'Renda média-alta (poder aquisitivo moderado, busca valor)', text: 'Renda média-alta (poder aquisitivo moderado, busca valor)' },
  { value: 'Renda média (foco em custo-benefício)', text: 'Renda média (foco em custo-benefício)' },
  { value: 'Renda média-baixa (preocupação com preço e quantidade)', text: 'Renda média-baixa (preocupação com preço e quantidade)' },
  { value: 'Renda baixa (busca pelo menor preço possível)', text: 'Renda baixa (busca pelo menor preço possível)' },
]


export const behaviorProfileData = [
  { value: 'Analítico (decisões baseadas em dados e pesquisa)', text: 'Analítico (decisões baseadas em dados e pesquisa)' },
  { value: 'Impulsivo (decisões rápidas e emocionais)', text: 'Impulsivo (decisões rápidas e emocionais)' },
  { value: 'Pragmático (foco em eficiência e solução rápida)', text: 'Pragmático (foco em eficiência e solução rápida)' },
  { value: 'Relacional (valorização de conexões e recomendações)', text: 'Relacional (valorização de conexões e recomendações)' },
  { value: 'Indeciso (precisa de muita informação e persuasão)', text: 'Indeciso (precisa de muita informação e persuasão)' },
]

export const clientPreferenceData = [
  { value: 'Preço (prioriza o custo mais baixo)', text: 'Preço (prioriza o custo mais baixo)' },
  { value: 'Qualidade (valoriza a excelência do produto/serviço)', text: 'Qualidade (valoriza a excelência do produto/serviço)' },
  { value: 'Quantidade (busca por maior volume ou valor agregado)', text: 'Quantidade (busca por maior volume ou valor agregado)' },
  { value: 'Praticidade (procura facilidade e conveniência)', text: 'Praticidade (procura facilidade e conveniência)' },
  { value: 'Velocidade (espera entrega ou resposta rápidas)', text: 'Velocidade (espera entrega ou resposta rápidas)' },


]

export const purchaseBehaviorData = [
  { value: 'Compra impulsiva (decisão rápida, com pouca consideração)', text: 'Compra impulsiva (decisão rápida, com pouca consideração)' },
  { value: 'Compra planejada (pesquisa extensa antes da compra)', text: 'Compra planejada (pesquisa extensa antes da compra)' },
  { value: 'Compra recorrente (compra frequente do mesmo produto)', text: 'Compra recorrente (compra frequente do mesmo produto)' },
  { value: 'Compra sazonal (compra relacionada a uma estação ou evento específico)', text: 'Compra sazonal (compra relacionada a uma estação ou evento específico)' },
  { value: 'Compra de emergência (decisão por necessidade imediata)', text: 'Compra de emergência (decisão por necessidade imediata)' },
]


export const purchaseChallengesData = [
  { value: 'Falta de informações claras sobre o produto/serviço', text: 'Falta de informações claras sobre o produto/serviço' },
  { value: 'Desconfiança na qualidade ou eficácia', text: 'Desconfiança na qualidade ou eficácia' },
  { value: 'Preço elevado', text: 'Preço elevado' },
  { value: 'Processo de compra complicado ou demorado', text: 'Processo de compra complicado ou demorado' },
  { value: 'Pouca familiaridade com a marca ou mercado', text: 'Pouca familiaridade com a marca ou mercado' },
]

export const purchaseInfluencersData = [
  { value: 'Avaliações e Recomendações (confia em opiniões de outros consumidores)', text: 'Avaliações e Recomendações (confia em opiniões de outros consumidores)' },
  { value: 'Reputação da Marca (considera a confiança e a credibilidade da marca)', text: 'Reputação da Marca (considera a confiança e a credibilidade da marca)' },
  { value: 'Garantia e Suporte (valorização de garantias e pós-venda eficazes)', text: 'Garantia e Suporte (valorização de garantias e pós-venda eficazes)' },
  { value: 'Facilidade de Uso (importância de uma experiência simples e intuitiva)', text: 'Facilidade de Uso (importância de uma experiência simples e intuitiva)' },
  { value: 'Benefícios e Funcionalidades (análise de características e benefícios do produto/serviço)', text: 'Benefícios e Funcionalidades (análise de características e benefícios do produto/serviço)' }
]
export const natureOfProduct = [
  { value: "Produtos Físicos", text: "Produtos Físicos" },
  { value: "Produtos Digitais", text: "Produtos Digitais" },
  { value: "Serviços", text: "Serviços" },
  { value: "Produtos Artesanais", text: "Produtos Artesanais" },
  { value: "Materiais de export Construção", text: "Materiais de export Construção" },
  { value: "Produtos Farmacêuticos", text: "Produtos Farmacêuticos" },
  { value: "Produtos Agrícolas", text: "Produtos Agrícolas" },
  { value: "Produtos de Entretenimento", text: "Produtos de Entretenimento" },
  { value: "Produtos Financeiros", text: "Produtos Financeiros" },
  { value: "Produtos de Viagem", text: "Produtos de Viagem" },
]

export const categoryOfProduct = {
  'Produtos Físicos': [
    { value: "Eletrônicos", text: "Eletrônicos" },
    { value: "Vestuário", text: "Vestuário" },
    { value: "Alimentos", text: "Alimentos" }
  ],
  'Produtos Digitais': [
    { value: "Software", text: "Software" },
    { value: "E-books", text: "E-books" },
    { value: "Cursos online", text: "Cursos online" }
  ],
  'Serviços': [
    { value: "Consultoria", text: "Consultoria" },
    { value: "Reparos", text: "Reparos" },
    { value: "Saúde e bem-estar", text: "Saúde e bem-estar" }
  ],
  'Produtos Artesanais': [
    { value: "Joias artesanais", text: "Joias artesanais" },
    { value: "Decoração", text: "Decoração" },
    { value: "Roupas e acessórios", text: "Roupas e acessórios" }
  ],
  'Materiais de export Construção': [
    { value: "Madeira", text: "Madeira" },
    { value: "Ferramentas", text: "Ferramentas" },
    { value: "Acabamentos", text: "Acabamentos" }
  ],
  'Produtos Farmacêuticos': [
    { value: "Medicamentos", text: "Medicamentos" },
    { value: "Suplementos", text: "Suplementos" },
    { value: "Cosméticos", text: "Cosméticos" }
  ],
  'Produtos Agrícolas': [
    { value: "Cereais", text: "Cereais" },
    { value: "Frutas", text: "Frutas" },
    { value: "Animais", text: "Animais" }
  ],
  'Produtos de Entretenimento': [
    { value: "Brinquedos", text: "Brinquedos" },
    { value: "Música", text: "Música" },
    { value: "Filmes e séries", text: "Filmes e séries" }
  ],
  'Produtos Financeiros': [
    { value: "Seguros", text: "Seguros" },
    { value: "Investimentos", text: "Investimentos" },
    { value: "Empréstimos", text: "Empréstimos" }
  ],
  'Produtos de Viagem': [
    { value: "Alojamento", text: "Alojamento" },
    { value: "Transporte", text: "Transporte" },
    { value: "Experiências", text: "Experiências" }
  ],
}

export const actionsFromPublish = [
  { value: "schedule", text: "Agendar" },
  { value: "now", text: "Publicar agora" },
]

export const availableLanguages = [
  { code: 'en_US', name: 'English', flag: 'united-states' },
  { code: 'pt_BR', name: 'Português', flag: 'brazil' },
  { code: 'es_ES', name: 'Español', flag: 'spain' },
];

export const socialMediaData = [
  { value: 'facebook', text: 'Facebook' },
  { value: 'instagram', text: 'Instagram' },
  { value: 'linkedin', text: 'LinkedIn' }
];

export const subTypeData = [
  { value: 'feed', text: 'feed' },
  { value: 'story', text: 'Story' }
];

export const interactionChannel = [
  { value: 'f7d82e08-ddb0-4bd7-9465-95356a8f1257', text: 'Email' },
  { value: '69f932d6-d249-4d68-84fc-6edc83d0c0f5', text: 'Ligação' },
  { value: '366583b2-8b96-4d02-8c6c-42d61e99eb5b', text: 'WhatsApp' },
  { value: '724a84b4-6960-45db-a2a9-5ffec9f66741', text: 'Outro' }
];

export const taskCategory = [
  { value: '894faef0-d85e-4c65-b9de-dee90310be8d', text: 'Completar a Venda' },
  { value: 'b4d73cbe-a32f-45e2-97a5-9897efd17082', text: 'Cobrar Cliente' },
  { value: '0bdd7b90-ab4d-41c7-b52e-1d4dd1226ce2', text: 'Enviar Material Adicional' },
  { value: '73d7a716-a1cd-4d24-80db-c8d2b16d1b2e', text: 'Retomar Contato' },
  { value: 'ab794229-a686-4954-91fa-a13504e68a63', text: 'Tentar Primeiro Contato' }
]; 
