<div class="flex flex-col gap-1.5">
  <span class="text-base font-bold text-gray-900 dark:text-white">Produtos relacionados</span>

  <div class="swiper relative w-full h-[150px] flex flex-row gap-4 overflow-clip rounded-xl bg-gray-100 dark:bg-gray-700 border">

    <div class="swiper-wrapper">
      <template repeat.for="item of files">
        <div class="swiper-slide relative bg-black">
          <img src="${item.linkImage}" alt="${item.alias}" class="w-full h-full brightness-50 md:brightness-100 opacity-60">

          <!-- beggin::text -->
          <div class="absolute top-4 left-4 right-4 flex flex-col text-white gap-1">
            <h2 class="text-base font-bold text-left line-clamp-1">${item.alias}</h2>

            <div if.bind="item.linkConversion" class="flex flex-row gap-1">
              <button type="button" click.trigger="handleNavigateToPage(item.linkConversion)">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 mr-2 mb-2 text-gray-400 hover:text-gray-500">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                </svg>
              </button>

              <h2 class="text-sm font-normal text-left line-clamp-1 h-fit">${item.linkConversion}</h2>
            </div>
          </div>
          <!-- end::text -->
        </div>
      </template>
    </div>

    <div class="absolute bottom-4 right-4 hidden md:flex flex-row gap-4">
      <div class="swiper-button-prev-custom p-3 rounded-full bg-slate-200 hover:bg-slate-300 cursor-pointer transition-colors duration-500 z-50">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </div>

      <div class="swiper-button-next-custom p-3 rounded-full bg-slate-200 hover:bg-slate-300 cursor-pointer transition-colors duration-500 z-50">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </div>
    </div>

  </div>
</div>