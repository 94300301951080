import { ModelTypes } from '@zeus';
import { Dropdown } from 'flowbite';
import { IDialogService } from '@aurelia/dialog';
import { IEventAggregator, bindable } from 'aurelia';
import { ToastType } from '@components/toast/events/toast-event';
import { ToastComponent } from '@components/toast/toast-component';
import type { DropdownOptions, DropdownInterface, InstanceOptions } from 'flowbite';

import { dealStatus } from './../../../../data'
import { AdvancedStepComponent } from '../advanced-step/advanced-step-component';

export class ActionComponent {
  @bindable opportunity: ModelTypes["deal"];

  private dropdown: DropdownInterface;
  private dealStatusData = dealStatus;

  constructor(
    @IEventAggregator readonly ea: IEventAggregator,
    @IDialogService private dialogService: IDialogService,
  ) { }

  attached() {
    this.initDropdown();
  }

  private initDropdown() {
    const $targetEl: HTMLElement = document.getElementById('status-bar-dropdown-menu');

    const $triggerEl: HTMLElement = document.getElementById('status-bar-dropdown-button');

    const options: DropdownOptions = {
      placement: 'bottom-end',
      triggerType: 'click',
      offsetSkidding: 20,
      offsetDistance: 20,
      delay: 300
    };

    const instanceOptions: InstanceOptions = {
      id: 'status-bar-dropdown-menu',
      override: true
    };

    this.dropdown = new Dropdown($targetEl, $triggerEl, options, instanceOptions);
  }

  private async handleAdvancedStep(statusId: string) {
    const { dialog } = await this.dialogService.open({
      component: () => AdvancedStepComponent,
      model: { opportunity: this.opportunity, statusId },
      lock: false,
      startingZIndex: 100,
    });

    const response = await dialog.closed;
  }

  private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type
      }
    });
  }
}