<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Missão, Visão & Valores">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">

      <div class="flex-1">
        <input-component type="text" label="Título da Sessão Sobre Valores da Marca"
          value.bind="website.aboutUsWhatMoveUsTitle" />
      </div>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <div class="flex-1">
          <input-component type="text" label="Missão da Marca" value.bind="website.aboutUsWhatMoveUsMission" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Visão da Marca" value.bind="website.aboutUsWhatMoveUsVision" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Valores da Marca" value.bind="website.aboutUsWhatMoveUsValues" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Propósito da Marca" value.bind="website.aboutUsWhatMoveUsPurpose" />
        </div>
      </div>

      <div class="flex-1">
        <single-file-upload-component label="Banner Da Sessão Sobre Valores"
          selected-file-id.bind="website.aboutUsWhatMoveBannerImageFileId"></single-file-upload-component>
      </div>

      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20"
          trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>