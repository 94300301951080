import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class ProjectsCreatedComponent {
  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  private async getBrandAggregate() {
    const result = await this.zeusClient.getProductsAggregate();

    return result;
  }
}