<section>

  <template if.bind="isSuccess">
    <div class="flex flex-col justify-center items-center">
      <span class="text-base font-semibold text-gray-400 dark:text-gray-400 pt-6">Integração realizada com sucesso 🎉🎊</span>
      <a href="/social-media" class="mt-auto mb-0 text-base font-semibold leading-normal text-gray-400 dark:text-gray-400 pt-1 pb-3 group">
        Voltar para integrações
        <i class="fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.5 ml-1 leading-normal transition-all duration-200"></i>
      </a>

      <img src="assets/images/undraw_integration-success.svg" alt="undraw" class="h-80 mt-6">
    </div>
  </template>
  <template else>
    <div class="flex flex-col justify-center items-center">
      <span class="text-base font-semibold text-gray-400 dark:text-gray-400 pt-6">Erro ao realizar integração 🤕👎</span>
      <a href="/social-media" class="mt-auto mb-0 text-base font-semibold leading-normal text-gray-400 dark:text-gray-400 pt-1 pb-3 group">
        Voltar para integrações
        <i class="fas fa-arrow-right ease-bounce text-sm group-hover:translate-x-1.5 ml-1 leading-normal transition-all duration-200"></i>
      </a>

      <img src="assets/images/undraw_integration-success.svg" alt="undraw" class="h-80 mt-6">
    </div>
  </template>

</section>