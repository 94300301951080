import { IValidationRules, ValidateInstruction } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { newInstanceForScope } from '@aurelia/kernel';
import { IEventAggregator } from 'aurelia';
import { ToastComponent } from '@components/toast/toast-component';
import { ToastType } from '@components/toast/events/toast-event';

export class RecoverPasswordPage {
  private email: string;
  private isLoading: boolean;

  private isMessageSent: boolean = false;

  constructor(
    @IAuthService private auth: IAuthService,
    @IEventAggregator readonly ea: IEventAggregator,
    @IValidationRules private validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) private validationController: IValidationController
  ) {
    validationRules
      .on(this)
      .ensure('email')
      .email()
      .when((vm: RecoverPasswordPage) => vm.email !== null && vm.email !== undefined)
      .required()
      .when((vm: RecoverPasswordPage) => vm.isMessageSent === false);
  }

  private async sendPasswordResetEmail() {
    const result = await this.validationController.validate();

    if (result.valid) {
      this.isLoading = true;

      try {
        await this.auth.resetPassword({ email: this.email });

        this.email = null;
        this.isMessageSent = true;

      } catch (error) {
        // if (error instanceof Error) {
        //   this.shoeMessageError(error.message)
        // } else {
        //   this.shoeMessageError('Something went wrong, please try again later');
        // }
      } finally {
        this.isLoading = false;
      }
    }
  }

  // private shoeMessageError(message: string) {
  //   ToastComponent.show({
  //     event: this.ea,
  //     payload: {
  //       title: 'Error',
  //       message,
  //       duration: 5000,
  //       type: ToastType.DANGER
  //     }
  //   });
  // }
}
