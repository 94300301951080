import { bindable } from 'aurelia';
import { Dropdown } from "flowbite";
import type { DropdownOptions, DropdownInterface } from "flowbite";

import { IGenericListFilter } from '@components/generic-list/interfaces/generic-filtered-interface';

export class FilterComponent {
  private id: string = crypto.randomUUID();

  private dropdown: DropdownInterface
  private isShowDropdown: boolean = false;

  @bindable filters: IGenericListFilter[];
  @bindable handleFilterCallback: Function;

  async attached() {
    const $targetEl: HTMLElement = document.getElementById(`dropdownFilterListMenu-${this.id}`);

    const $triggerEl: HTMLElement = document.getElementById(`dropdownFilterListButton-${this.id}`);

    const options: DropdownOptions = {
      placement: 'bottom',
      triggerType: 'click',
      offsetSkidding: 10,
      offsetDistance: 0,
      delay: 300,
      onShow: () => {
        this.isShowDropdown = true;
      },
      onHide: () => {
        this.isShowDropdown = false;
      }
    };

    this.dropdown = new Dropdown($targetEl, $triggerEl, options);
  }

  public hideDropdown() {
    this.dropdown.hide();
  }

  public async handleFilter(): Promise<void> {
    this.handleFilterCallback();

    this.hideDropdown();
  }

  private async handleClearFilter(): Promise<void> {
    this.filters.forEach((filter: IGenericListFilter) => {

      switch (filter.comparsionType) {
        case 'between':
          for (let i = 0; i < filter.between.length; i++) {
            filter.between[i].value = null;
          }
          break;
        case 'in':
          filter.value = [];
          break;
        default:
          filter.value = null;
          break;
      }
    });

    this.handleFilterCallback();

    this.hideDropdown();
  }
}