// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#website-tabs .active * {
    --tw-bg-opacity: 1;
    background-color: rgb(255 90 31 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
 `, "",{"version":3,"sources":["webpack://./src/app/private/pages/website-config/website-config.css"],"names":[],"mappings":"AACE;IAAA,kBAA+B;IAA/B,uDAA+B;IAA/B,oBAA+B;IAA/B;AAA+B","sourcesContent":["#website-tabs .active *{\r\n  @apply bg-orange-500 text-white;\r\n}\r\n "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
