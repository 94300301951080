<import from='@components/promise-bind/pending/pending-component' />
<import from='@components/create-modal/create-project-modal/create-project-modal-component' />

<section>

  <button id="dropdown-search-template-button" type="button" class="btn-primary-small">Criar mídia</button>

  <div id="dropdown-search-template" class="z-30 hidden h-80 w-72 md:w-[23rem] bg-white divide-y divide-gray-100 rounded-lg shadow-md dark:bg-gray-700 dark:divide-gray-600 overflow-hidden overflow-y-auto hide-scrollbar">

    <!-- begin::input-search -->
    <div class="px-4 py-3">
      <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
          </svg>
        </div>

        <input id="search" type="search" value.bind="search & debounce:200" class="form-input !ps-10" placeholder="Search templates..." autocomplete="off">
      </div>
    </div>
    <!-- end::input-search -->

    <template promise.bind="searchNewTemplateType(search)">

      <template pending>
        <pending-component></pending-component>
      </template>

      <ul>
        <template then.from-view="data">
          <li repeat.for="item of data" click.trigger="handleSearchTemplateClick(item.channel)" class="p-4 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">
            <div class="flex">
              <i class="${item.icon} pr-2"></i>
              <span class="line-clamp-1">${item.name}</span>
            </div>
          </li>
        </template>
      </ul>

    </template>

  </div>

  <create-project-modal-component view-model.ref="createProjectModalRef"></create-project-modal-component>
</section>