import { ModelTypes } from '@zeus';
import { bindable } from 'aurelia';
import Toastify from 'toastify-js';
import { watch } from '@aurelia/runtime-html';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class StrongStatementSection {
  @bindable website: ModelTypes['website']

  constructor(
    @IZeusClient private zeusClient: ZeusClient,
  ) { }

  @watch('website')
  async handleDealSubscription(newData: ModelTypes['website'], oldData: ModelTypes['website']) {
    if (newData && newData !== oldData) {
      this.website = newData;
    }
  }

  async handleSave() {
    try {
      const result = await this.zeusClient.chain('mutation')({
        update_website_by_pk: [
          {
            pk_columns: { id: this.website.id },
            _set: {
              aboutUsStatementBannerImageFileId: this.website.aboutUsStatementBannerImageFileId,
              aboutUsStatementBannerText: this.website.aboutUsStatementBannerText,
            }
          },
          {
            id: true,
          }
        ]
      })

      if (!result) {
        throw new Error('Error saving data!')
      }

      Toastify({ text: 'Salvo com sucesso!', duration: 3000, gravity: 'top', position: 'right', backgroundColor: '#4CAF50', stopOnFocus: true }).showToast();
    } catch (error) {
      console.error(error)

      Toastify({ text: 'Erro ao salvar!', duration: 3000, gravity: 'top', position: 'right', backgroundColor: '#F44336', stopOnFocus: true }).showToast();
    }
  }
}