import { bindable } from 'aurelia';
import type { AccordionInterface, AccordionItem, AccordionOptions, InstanceOptions } from "flowbite";
import { Accordion } from "flowbite";

enum Status {
  Completed = 'completed',
  Disapproved = 'disapproved',
  Unknown = 'unknown',
  Locked = 'locked',
  Unlocked = 'unlocked',
}

export class AccordionComponent {
  private id: string = crypto.randomUUID();

  @bindable title: string;
  @bindable subtitle: string;

  @bindable opened: boolean = false;
  @bindable status: Status = Status.Unknown;

  attached() {
    const accordionEl: HTMLElement = document.querySelector(`#accordion-${this.id}`);

    const accordionItems: AccordionItem[] = [
      {
        id: `accordion-${this.id}-heading`,
        triggerEl: document.querySelector(`#accordion-${this.id}-heading`),
        targetEl: document.querySelector(`#accordion-${this.id}-body`),
        iconEl: document.querySelector(`#accordion-${this.id}-icon`),
        active: false
      }
    ];

    const options: AccordionOptions = {
      alwaysOpen: true,
      activeClasses: 'bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-white',
      inactiveClasses: 'text-gray-500 dark:text-gray-400',
    };

    const instanceOptions: InstanceOptions = {
      id: `#accordion-${this.id}`,
      override: true
    };

    const accordion: AccordionInterface = new Accordion(accordionEl, accordionItems, options, instanceOptions);

    if (this.opened) {
      accordion.open(`accordion-${this.id}-heading`);
    }
  }
}