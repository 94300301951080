<import from="@components/icons/icons-component" />

<section class="relative">

  <button id="dropdownFilterOrderByButton-${id}" type="button" class="rounded-full p-1 text-gray-900 dark:text-gray-400 hover:text-gray-900 cursor-pointer hover:bg-gray-100 dark:hover:text-white dark:hover:bg-gray-700">
    <i class="fa-solid fa-arrow-up-wide-short p-1"></i>
  </button>

  <div id="dropdownFilterOrderByMenu-${id}" class="z-50 hidden p-4 h-[calc(100%-1rem)] max-h-full w-96">
    <div class="w-full max-h-full">
      <!-- beggin::content -->
      <div class="bg-[#F6F6F6] rounded-lg shadow dark:bg-gray-700">

        <!-- beggin::header -->
        <div class="flex items-center justify-between p-5 rounded-t">
          <span class="text-base font-bold text-[#071C42] dark:text-white"><i class="fa-solid fa-arrow-up-wide-shortmr-3"></i>Filtro:</span>
          <icons-component click.trigger="hideDropdown()" class="h-5 w-5 text-[#071C42] cursor-pointer" icon="X"></icons-component>
        </div>
        <!-- end::header -->

        <!-- beggin:body -->
        <div class="flex flex-col gap-4 px-6 pb-3 h-[250px] overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-md scrollbar-thumb-gray-300">

          <div>
            <label for="segment" class="form-label">Ordernar por</label>
            <select id="segment" value.bind="listOrderBySelected" class="form-input">
              <option model.bind="null">Selecione</option>
              <option repeat.for="item of createListOrderBy()" model.bind="item">${item.title}</option>
            </select>
          </div>

          <!-- <ul class="flex flex-col gap-2">
            <li repeat.for="order of orderBy">

              <input type="checkbox" id="${order.field}" checked.bind="order.value" model.bind="order.value" class="hidden peer">
              <label for="${order.field}" class="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                <div class="flex flex-row items-center">

                  <template switch.bind="order.value">
                    <i case.bind="true" class="fa-solid fa-arrow-up-wide-short mr-2"></i>
                    <i case.bind="false" class="fa-solid fa-arrow-down-short-wide mr-2"></i>
                    <i default-case class="fa-solid fa-bars mr-2"></i>
                  </template>

                  <div class="w-full text-sm font-semibold">${order.title}</div>
                </div>
              </label>

            </li>
          </ul> -->

        </div>
        <!-- end:body -->

        <!-- beggin::footer -->
        <div class="flex flex-row justify-between items-center p-5 rounded-b gap-3">
          <button class="btn-primary-small flex-1" click.trigger="handleOrdering()">Filtrar</button>
          <!-- <button class="btn-primary-outline-small" click.trigger="handleClearOrdering()"><i class="fa-solid fa-bars"></i></button> -->
        </div>
        <!-- end::footer -->

      </div>
      <!-- end::content -->
    </div>
  </div>

  <div if.bind="isShowDropdown" click.trigger="hideDropdown()" class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"></div>
</section>