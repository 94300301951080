<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Proposta de Valor">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">

      <div class="flex-1">
        <input-component type="text" label="Título Para Sessão Sobre Proposta de Valor"
          value.bind="website.aboutUsWeAreReadyTitle" />
      </div>
      <div class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class="flex-1">
          <input-component type="text" label="Descrição Sobre Experiência"
            value.bind="website.aboutUsWeAreReadySubjectMatterExpertiseDescription" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Descrição Sobre Suporte"
            value.bind="website.aboutUsWeAreReadySpecializedSupportDescription" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Descrição Sobre Transparência"
            value.bind="website.aboutUsWeAreReadyTransparencyDescription" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Descrição Sobre Inovação"
            value.bind="website.aboutUsWeAreReadyInnovationDescription" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Descrição Sobre Avaliação de Clientes"
            value.bind="website.aboutUsWeAreReadyCustomerSatisfactionDescription" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Descrição Sobre Responsabilidade Social"
            value.bind="website.aboutUsWeAreReadySocialResponsibilityDescription" />
        </div>
      </div>

      <div class="flex-1">
        <single-file-upload-component label="Banner da Sessão da Proposta de Valor"
          selected-file-id.bind="website.aboutUsWeAreReadyBannerImageId"></single-file-upload-component>
      </div>

      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20"
          trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>