<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Outras Informações">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">

      <div class="flex-1">
        <input-component type="text" label="Link Para o Seu Site" value.bind="website.domain" />
      </div>

      <div class="flex-1">
        <input-component type="text" label="Graph Description" value.bind="website.openGraphDescription" />
      </div>

      <div class="flex flex-row gap-4">
        <div class="flex-1">
          <input-component type="text" label="Google Analytics" value.bind="website.googleAnalyticsKey" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Google Tag Manager" value.bind="website.googleTagManagerKey" />
        </div>
      </div>

      <div class="flex flex-row gap-8">

        <!-- begin::backgroundColor -->
        <div class="">
          <label class="form-label">Cor Principal</label>

          <label for="backgroundColor" class="size-20 inline-flex relative items-center justify-center cursor-pointer text-gray-400 border border-gray-400 dark:text-gray-500 dark:border-gray-600 rounded-md transition-all ease-in hover:scale-[1.02] hover:border-[#cb0c9f] hover:shadow-md group" style.bind="`background-color: ${website.backgroundColor ? website.backgroundColor : ''};`">
            <input id="backgroundColor" type="color" value.bind="website.backgroundColor" class="invisible size-20">
          </label>
        </div>
        <!-- end::backgroundColor -->

        <!-- begin::foregroundColor -->
        <div class="">
          <label class="form-label">Cor Base</label>

          <label for="foregroundColor" class="size-20 inline-flex relative items-center justify-center cursor-pointer text-gray-400 border border-gray-400 dark:text-gray-500 dark:border-gray-600 rounded-md transition-all ease-in hover:scale-[1.02] hover:border-[#cb0c9f] hover:shadow-md group" style.bind="`background-color: ${website.foregroundColor ? website.foregroundColor : ''};`">
            <input id="foregroundColor" type="color" value.bind="website.foregroundColor" class="invisible size-20">
          </label>
        </div>
        <!-- end::foregroundColor -->

        <!-- begin::accentColor -->
        <div class="">
          <label class="form-label">Cor de Destaque</label>

          <label for="accentColor" class="size-20 inline-flex relative items-center justify-center cursor-pointer text-gray-400 border border-gray-400 dark:text-gray-500 dark:border-gray-600 rounded-md transition-all ease-in hover:scale-[1.02] hover:border-[#cb0c9f] hover:shadow-md group" style.bind="`background-color: ${website.accentColor ? website.accentColor : ''};`">
            <input id="accentColor" type="color" value.bind="website.accentColor" class="invisible size-20">
          </label>
        </div>
        <!-- end::accentColor -->

      </div>

      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20" trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>