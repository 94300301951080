import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { bindable, BindingMode } from 'aurelia';
import { nhost } from 'lib/nhost';

interface IDropzoneFile {
  id: string;
  file: string;
  size: number;
  upload: {
    progress: number;
    bytesSent: number;
  }
}

export class FileUploadComponent {
  @bindable id: string
  @bindable label: string
  @bindable isPublic: boolean = false

  @bindable({ mode: BindingMode.twoWay }) currentFile: File
  @bindable({ mode: BindingMode.twoWay }) currentFileId: string

  // private currentFile: string
  private dropzoneFile: IDropzoneFile[] = []

  constructor(@IAuthService private auth: IAuthService,) { }

  async attached() {
    const token = this.auth.getAccessToken();

    nhost.storage.setAccessToken(token)

    await this.addFilesToDropzone();
  }

  // private async fileToBase64(file: File): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();

  //     reader.readAsDataURL(file);

  //     reader.onload = () => {
  //       resolve(reader.result as string);
  //     };

  //     reader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // }

  private async addFilesToDropzone(): Promise<void> {

    if (this.currentFileId) {

      let url: string;

      if (this.isPublic) {
        const publicUrl = nhost.storage.getPublicUrl({
          fileId: this.currentFileId,
        })

        url = publicUrl;
      } else {
        const { presignedUrl, error } = await nhost.storage.getPresignedUrl({
          fileId: this.currentFileId,
        })

        if (error) {
          console.error(error)
          return;
        }

        url = presignedUrl.url;
      }

      const fileData: IDropzoneFile = {
        id: this.currentFileId,
        file: url,
        size: 0,
        upload: {
          progress: 100,
          bytesSent: 1000
        }
      }


      this.dropzoneFile = [fileData];

      const currentFileElement = document.getElementById(fileData.id)
      currentFileElement?.classList.add('dz-complete');
    }
  }

  private filesChanged(event: { target: { files: FileList }; }) {
    if (event.target.files) {

      const files: FileList = event.target.files

      for (let i = 0; i < files.length; i++) {
        const file: File = files[i]

        const fileReader = new FileReader();

        fileReader.onloadend = () => {
          const base64Data = fileReader.result as string;

          const fileId = Math.random().toString(36).substring(2, 9);

          const fileData: IDropzoneFile = {
            id: fileId,
            file: base64Data,
            size: file.size,
            upload: {
              progress: 0,
              bytesSent: 0
            }
          }

          this.dropzoneFile = [fileData];
          this.currentFile = file;

          // Simulating upload progress
          this.handleProgressUpload(fileData);
        }

        if (file) {
          fileReader.readAsDataURL(file);
        }
      }
    }
  }

  private drop(event: DragEvent) {
    event.preventDefault();

    const files: FileList = event.dataTransfer.files

    for (let i = 0; i < files.length; i++) {
      const file: File = files[i]

      const fileType = file.type.split('/')[0];

      const validTypes = ['image'];

      if (!validTypes.includes(fileType)) {
        alert('Tipo de arquivo inválido');
        return;
      }

      const fileReader = new FileReader();

      fileReader.onload = () => {
        const base64Data = fileReader.result as string;

        const fileId = Math.random().toString(36).substring(2, 9);

        const fileData: IDropzoneFile = {
          id: fileId,
          file: base64Data,
          size: file.size,
          upload: {
            progress: 0,
            bytesSent: 0
          }
        }

        this.dropzoneFile = [fileData];
        this.currentFile = file;

        // Simulating upload progress
        this.handleProgressUpload(fileData);
      }

      if (file) {
        fileReader.readAsDataURL(file);
      }
    }
  }

  private async removeFile(fileId: string): Promise<void> {

    // if (this.currentFileId) {
    //   const { error } = await nhost.storage.delete({ fileId: fileId })

    //   if (error) {
    //     console.error(error)
    //     return;
    //   }
    // }

    this.dropzoneFile = [];
    this.currentFile = null;
    this.currentFileId = null;
  }

  private handleProgressUpload(file: IDropzoneFile) {
    var minSteps = 6, maxSteps = 60, timeBetweenSteps = 100, bytesPerStep = 100000;

    const currentFileElement = document.getElementById(file.id)

    const totalSteps = Math.round(Math.min(maxSteps, Math.max(minSteps, file.size / bytesPerStep)));

    for (let step = 0; step < totalSteps; step++) {
      const duration = timeBetweenSteps * (step + 1);

      setTimeout(() => {
        file.upload.progress = (step + 1) / totalSteps * 100;
        file.upload.bytesSent = (step + 1) * (file.size / totalSteps);

      }, duration);
    }

    setTimeout(() => {
      currentFileElement?.classList.add('dz-complete', 'dz-success');
    }, 1000);

    currentFileElement?.classList.remove('dz-success');
  }

}

