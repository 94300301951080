<import from='@components/undraw/undraw-component' />
<import from='@components/card-title/card-title-component' />

<import from='./components/output-card/output-card-component' />

<section>
  <card-title-component title-id="marcas"></card-title-component>

  <!-- beggin:outputs -->
  <div class="w-full h-fit">
    <template if.bind="archetypesData.length === 0">
      <undraw-component message="Nenhum arquétipo encontrado, Clique em 'Salvar'"></undraw-component>
    </template>
    <template else>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 p-4">
        <template repeat.for="data of archetypesData">
          <output-card-component archetype.bind="data"></output-card-component>
        </template>
      </div>
    </template>

  </div>
  <!-- end:outputs -->

</section>