// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom__look {
  --tag-opacity: 1;
  --tag-bg: rgb(225 239 254 / var(--tag-opacity));
  --tag-text-color: rgb(30 66 159 / var(--tag-opacity));
  --tag-border-radius: 0.25rem;
}

.custom__look .tagify__tag-text {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/common/components/tagify/basic/tagify-basic-component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,+CAA+C;EAC/C,qDAAqD;EACrD,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".custom__look {\r\n  --tag-opacity: 1;\r\n  --tag-bg: rgb(225 239 254 / var(--tag-opacity));\r\n  --tag-text-color: rgb(30 66 159 / var(--tag-opacity));\r\n  --tag-border-radius: 0.25rem;\r\n}\r\n\r\n.custom__look .tagify__tag-text {\r\n  font-weight: bold;\r\n  font-size: 0.75rem;\r\n  line-height: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
