<import from='./components/ai/ai-component' />
<import from='./components/media/media-component' />
<import from='./components/pexels/pexels-component' />
<import from='./components/product/product-component' />

<div class="p-6 space-y-6">
  <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
      <!-- begin::pexels -->
      <li class="mr-2" role="presentation">
        <button click.trigger="changeCurrentImageTabIndex(0)" class="inline-block p-4 border-b-2 rounded-t-lg text-xs focus:outline-none" id="pexels-tab" data-tabs-target="#pexels" type="button" role="tab" aria-controls="pexels" aria-selected="false">Banco de imagens</button>
      </li>
      <!-- end::pexels -->

      <!-- begin::ai -->
      <li class="mr-2" role="presentation">
        <button click.trigger="changeCurrentImageTabIndex(1)" class="inline-block p-4 border-b-2 rounded-t-lg text-xs focus:outline-none" id="ai-tab" data-tabs-target="#ai" type="button" role="tab" aria-controls="ai" aria-selected="false">Criar com I.A.</button>
      </li>
      <!-- end::ai -->

      <!-- begin::media -->
      <li class="mr-2" role="presentation">
        <button click.trigger="changeCurrentImageTabIndex(3)" class="inline-block p-4 border-b-2 rounded-t-lg text-xs focus:outline-none" id="media-tab" data-tabs-target="#media" type="button" role="tab" aria-controls="media" aria-selected="false">Mídias</button>
      </li>
      <!-- end::media -->

      <!-- begin::product -->
      <li class="mr-2" role="presentation">
        <button click.trigger="changeCurrentImageTabIndex(4)" class="inline-block p-4 border-b-2 rounded-t-lg text-xs focus:outline-none" id="product-tab" data-tabs-target="#product" type="button" role="tab" aria-controls="product" aria-selected="false">Produto</button>
      </li>
      <!-- end::product -->
    </ul>
  </div>

  <div id="myTabContent">

    <!-- beggin::pexels -->
    <pexels-component selected-image.bind="selectedImage" display-dimension-id.bind="displayDimensionId"></pexels-component>
    <!-- end::pexels -->

    <!-- beggin::ai -->
    <ai-component selected-image.bind="selectedImage"></ai-component>
    <!-- end::ai -->

    <!-- beggin::media -->
    <media-component selected-image.bind="selectedImage"></media-component>
    <!-- end::media -->

    <!-- beggin::product -->
    <product-component selected-image.bind="selectedImage" product-id.bind="productId"></product-component>
    <!-- end::product -->

  </div>
</div>