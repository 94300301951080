<import from='@components/card-title/card-title-component' />

<section>

  <card-title-component title-id="cursos">
    <div au-slot="actions"></div>
  </card-title-component>

  <div class="mb-6">

    <!--    -->
    <div class="w-full max-w-full p-2 mb-6 md:flex-none bg-white md:w-6/12 lg:w-2/5 xl:mb-0 xl:w-3/12 shadow-xl rounded-2xl bg-clip-border">
      <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
        <div class="relative"><a class="block shadow-xl rounded-2xl">
            <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/home-decor-1.jpg" alt="img-blur-shadow" class="max-w-full shadow-lg rounded-lg">
          </a>
        </div>
        <div class="flex-auto px-1 pt-6">
          <p class="relative z-10 mb-2 leading-normal text-transparent bg-gradient-to-tl from-[#141727] to-[#3a416f] text-[.875rem] bg-clip-text">Project #2</p>
          <a href="javascript:;">
            <h5>Modern</h5>
          </a>
          <p class="mb-6 leading-normal text-[.875rem]">As Uber works through a huge amount of internal management turmoil.</p>
          <div class="flex items-center justify-between"><button type="button" class="inline-block px-8 py-2 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-lg shadow-none cursor-pointer leading-[1.4] ease-in text-[.75rem] hover:scale-[1.02] active:shadow-xs tracking-tight border-[#cb0c9f] text-[#cb0c9f] hover:border-[#cb0c9f] hover:bg-transparent hover:text-[#cb0c9f] hover:opacity-75 hover:shadow-none active:bg-[#cb0c9f] active:text-white active:hover:bg-transparent active:hover:text-[#cb0c9f]">View Project</button>
            <div class="mt-2">
              <a href="javascript:;" class="relative z-20 inline-flex items-center justify-center w-6 h-6 text-white transition-all duration-200 border-2 border-white border-solid ease-in-out text-[.75rem] rounded-[50%] hover:z-30">
                <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/team-1.jpg" class="w-full rounded-[50%]" alt="Image placeholder">
              </a>
              <a href="javascript:;" class="relative z-20 inline-flex items-center justify-center w-6 h-6 -ml-4 text-white transition-all duration-200 border-2 border-white border-solid ease-in-out text-[.75rem] rounded-[50%] hover:z-30">
                <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/team-2.jpg" class="w-full rounded-[50%]" alt="Image placeholder">
              </a>
              <a href="javascript:;" class="relative z-20 inline-flex items-center justify-center w-6 h-6 -ml-4 text-white transition-all duration-200 border-2 border-white border-solid ease-in-out text-[.75rem] rounded-[50%] hover:z-30">
                <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/team-3.jpg" class="w-full rounded-[50%]" alt="Image placeholder">
              </a>
              <a href="javascript:;" class="relative z-20 inline-flex items-center justify-center w-6 h-6 -ml-4 text-white transition-all duration-200 border-2 border-white border-solid ease-in-out text-[.75rem] rounded-[50%] hover:z-30">
                <img src="https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard/assets/img/team-4.jpg" class="w-full rounded-[50%]" alt="Image placeholder">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</section>