<import from='@components/undraw/undraw-component' />
<import from='@components/card-title/card-title-component' />
<import from='@components/generic-list/generic-list-component' />
<import from='./components/table-actions/table-actions-component' />
<import from="./components/add-integration-modal/add-integration-modal-component"></import>

<section>

  <card-title-component title-id="plataformas">
    <div au-slot="actions">
      <button type="button" click.trigger="openAddIntegrationModal()" class="btn-success-small">Conectar Rede
        Social</button>
    </div>
  </card-title-component>
  <div class="card p-4">

    <generic-list-component config.bind="config">
      <div au-slot="body">

        <template if.bind="$host.data.length === 0">
          <undraw-component message="Nenhuma integração encontrada, Clique em 'Criar integração'"></undraw-component>
        </template>
        <template else>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
            <template repeat.for="item of $host.data">

              <!-- begin::card -->
              <div class="card h-full rounded-2xl border border-gray-100 dark:border-gray-700">
                <div class="flex flex-col h-full">
                  <div class="flex items-center justify-between p-4">
                    <div class="flex items-center gap-3 mr-2">

                      <div
                        class="relative inline-flex items-center justify-center w-10 h-10 min-w-[2.5rem] min-h-[2.5rem] overflow-hidden rounded-xl bg-gray-100 dark:bg-gray-700">
                        <i class="${setIconByType(item.integrationType)} text-sm text-gray-600 dark:text-gray-300"></i>
                      </div>

                      <h3 class="text-sm font-bold tracking-tight text-gray-900 dark:text-white line-clamp-2">
                        ${item.alias}</h3>
                    </div>
                  </div>

                  <div class="flex flex-wrap justify-between items-center p-4 gap-3">
                    <div class="flex flex-col gap-1 w-fit">
                      <div class="flex flex-col justify-start items-start gap-1">
                        <span class="text-xs font-normal text-gray-700 dark:text-gray-400">Data de integração:</span>
                        <span class="ttext-xs font-normal text-gray-500 text-right">${item.created_at |
                          dateTime:I18N.getLocale().replace('_', '-') & signal:'parameterChanged' }</span>
                      </div>
                    </div>

                    <table-actions-component app-integration.bind="item"
                      delete-callback.bind="() => deleteUserAppIntegration(item.id)"></table-actions-component>
                  </div>
                </div>
              </div>
              <!-- end::card -->

            </template>
          </div>
        </template>

      </div>
    </generic-list-component>

    <add-integration-modal-component view-model.ref="addIntegration"></add-integration-modal-component>
  </div>
</section>