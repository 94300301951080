import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class ProjectsComponent {

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  public async getProjectsAggregates() {
    const response = await this.zeusClient.getGeneratedPromptByTemplateAggregateProject();

    return response;
  }
}