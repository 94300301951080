<import from='./../../components/header/header-component' />

<section class="p-4 flex flex-col justify-between h-full">

  <div class="flex flex-col">
    <header-component title="Dados do Lead" subtitle="Dados de contato do potencial cliente"></header-component>

    <div class="flex flex-row justify-center my-3 gap-2 ">
      <button type="button" click.trigger="phoneCall()"
        class="btn-neutral-small flex justify-center items-center gap-1 w-4/12">
        <i class="fa-solid fa-phone-volume"></i>
        Ligar
      </button>

      <button type="button" click.trigger="sendEmail()"
        class="btn-neutral-small flex justify-center items-center gap-1 w-4/12">
        <i class="fa-regular fa-envelope"></i>
        E-mail
      </button>

      <button type="button" click.trigger="showWhatsApp()"
        class="btn-neutral-small flex justify-center items-center gap-1 w-4/12">
        <i class="fa-brands fa-whatsapp"></i>
        Whatsapp
      </button>
    </div>

    <div class="flex flex-col gap-3 mt-1">
      <!-- begin::name -->
      <div class="flex-1">
        <label for="name" class="form-label">Nome</label>
        <input type="text" id="name" value.bind="opportunity.name" class="form-input" readonly>
      </div>
      <!-- end::name -->

      <!-- begin::email -->
      <div class="flex-1">
        <label for="email" class="form-label">E-mail</label>
        <input type="text" id="email" value.bind="opportunity.email" class="form-input" readonly>
      </div>
      <!-- end::email -->

      <!-- begin::phone -->
      <div class="flex-1">
        <label for="phone" class="form-label">Telefone</label>
        <input type="text" id="phone" value.bind="opportunity.phone | phone" class="form-input" readonly>
      </div>
      <!-- end::phone -->
    </div>
  </div>

</section>