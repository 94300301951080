<import from="@components/calendar/calendar-component" />

<div class="card p-4">
  <div class="relative">
    <h5 t="dashboardcards.calendar" class="text-lg font-bold text-gray-900 dark:text-white">Calendário de Marketing</h5>
    <h6 t="dashboardcards.calendarsubtitle" class="text-sm text-gray-400 dark:text-gray-400 mb-4"> Gerencie o agendamento e publicação de postagens no menu <span class="font-bold">Calendário</span></h6>

    <button if.bind="zeusClient.userDetails.onboardCompleted && zeusClient.userDetails.generatingNextMonthCalendarFromOnboard === null" class="btn-secondary absolute -translate-y-1/2 -translate-x-1/2 top-2/4 left-1/2 z-30 motion-safe:animate-pulse w-[250px]">Gerar</button>

    <div class="${zeusClient.userDetails.onboardCompleted && zeusClient.userDetails.generatingNextMonthCalendarFromOnboard === null ? 'blur-sm pointer-events-none' : ''} pt-2">
      <calendar-component hide-events="true"></calendar-component>
    </div>

  </div>
</div>