import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface IOpportunityService {
  opportunitySubscription: ModelTypes['deal'];

  getOpportunityByIdSubscription(dealId: string): void;
  advanceStep(command: { dealId: string, statusId: string }): Promise<ModelTypes["deal"]>
}

export class OpportunityService implements IOpportunityService {
  public opportunitySubscription: ModelTypes['deal'];

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  getOpportunityByIdSubscription(dealId: string): void {
    const result = this.zeusClient.subscription('subscription')({
      deal_by_pk: [
        { id: dealId },
        {
          id: true,
          name: true,
          phone: true,
          email: true,
          rating: true,
          created_at: true,
          statusId: true,
          dealStatus: {
            id: true,
            displayName: true,
          },
          product: {
            id: true,
            alias: true,
            category: true,
            type: true,
            linkConversion: true,
            description: true,
            price: true,
            salePrice: true,
            imageOneId: true,
            imageTwoId: true,
            imageThreeId: true,
            imageFourId: true,
          },
          interactions: [
            {},
            {
              id: true,
              success: true,
              channel: true,
              observation: true,
              date: true,
              created_at: true,
            }
          ],
          tasks: [
            {},
            {
              id: true,
              title: true,
              description: true,
              date: true,
              category: true,
              finished: true,
              created_at: true,
            }
          ],
        },
      ]
    });

    result.error((error) => { console.error('opportunitySubscription', error); });

    result.on(({ deal_by_pk }: { deal_by_pk: ModelTypes["deal"] }) => {
      this.opportunitySubscription = deal_by_pk;
    })
  }

  async advanceStep(command: { dealId: string, statusId: string }): Promise<ModelTypes["deal"]> {
    const result = await this.zeusClient.chain('mutation')({
      update_deal_by_pk: [
        {
          pk_columns: { id: command.dealId },
          _set: { statusId: command.statusId },
        },
        {
          id: true,
        }
      ]
    });

    return result.update_deal_by_pk as ModelTypes["deal"];
  }

}

export const IOpportunityService = DI.createInterface<IOpportunityService>((x) => x.singleton(OpportunityService));
