import { I18N } from "@aurelia/i18n";
import { IAurelia, IEventAggregator } from 'aurelia';
import { IValidationRules, ValidateInstruction } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { newInstanceForScope } from '@aurelia/kernel';
import { ToastComponent } from '@components/toast/toast-component';
import { ToastType } from '@components/toast/events/toast-event';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IRouter } from '@aurelia/router';

export class LoginPage {
  private email: string;
  private password: string;

  private isLoading: boolean;

  constructor(
    @I18N private I18N: I18N,
    @IAurelia private au: IAurelia,
    @IRouter private router: IRouter,
    @IAuthService private Auth: IAuthService,
    @IEventAggregator readonly ea: IEventAggregator,
    @IValidationRules private validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) private validationController: IValidationController
  ) {
    validationRules
      .on(this)
      .ensure('email')
      .email()
      .required()
      .ensure('password')
      .minLength(9)
      .required()
  }

  private async signInWithEmailAndPassword() {
    const result = await this.validationController.validate();

    if (result.valid) {
      this.isLoading = true;

      try {
        await this.Auth.signIn({ email: this.email, password: this.password });

        this.router.load('/');
      } catch (error) {
        if (error instanceof Error) {
          this.showMessageError(error.message)
        } else {
          this.showMessageError('Algo deu errado, tente novamente mais tarde');
        }
      } finally {
        this.isLoading = false;
      }
    }
  }

  private async handleLoginWithGoogle() {
    try {
      const cm_metadata = localStorage.getItem('cm_metadata');

      await this.Auth.signInForGoogle({
        ...cm_metadata && { metadata: JSON.parse(cm_metadata) }
      });

      localStorage.removeItem('cm_metadata');

      this.router.load('/');
    } catch (error) {
      if (error instanceof Error) {
        this.showMessageError(error.message)
      } else {
        this.showMessageError('Algo deu errado, tente novamente mais tarde');
      }
    }
  }

  private showMessageError(message: string) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: 'Error',
        message,
        duration: 5000,
        type: ToastType.DANGER
      }
    });
  }
}
