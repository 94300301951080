import axios from 'axios'
import { VideoPlayerModalComponent } from './components/video-player-modal/video-player-modal-component';
import { IEventAggregator } from 'aurelia';

export class TutorialsPage {

  constructor(
    @IEventAggregator readonly ea: IEventAggregator,
  ) { }

  // private async getYoutubePlaylists() {
  //   const url = 'https://www.googleapis.com/youtube/v3/playlistItems'

  //   const params = {
  //     part: 'snippet',
  //     // channelId: 'UCplT2lzN6MHlVHHLt6so39A',
  //     playlistId: 'PLlBnICoI-g-cW2_yx2C7kNYOum-9frtPJ',
  //     key: 'AIzaSyB-b3rPi9LLHsb8whZ9NYZmq5rfZQjH6uY',
  //     maxResults: 5,
  //   }

  //   try {
  //     const result = await axios.get(url, { params })

  //     console.log(result.data)
  //     return result.data
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  private handleVideoPlay(videoId: string) {
    VideoPlayerModalComponent.show({
      event: this.ea,
      payload: {
        videoId
      }
    })
  }

  private async getYoutubePlaylists() {
    // aguardar 5 segundos
    await new Promise(resolve => setTimeout(resolve, 1000))

    const data = {
      "kind": "youtube#playlistItemListResponse",
      "etag": "YBXOCnSEl6i1gHgvZbrC0YU-75c",
      "nextPageToken": "EAAaBlBUOkNBVQ",
      "items": [
        {
          "kind": "youtube#playlistItem",
          "etag": "TvZZ4d1dDpZvuGNkJWvCsYz1t64",
          "id": "UExsQm5JQ29JLWctY1cyX3l4MkM3a05ZT3VtLTlmcnRQSi41NkI0NEY2RDEwNTU3Q0M2",
          "snippet": {
            "publishedAt": "2022-09-20T18:59:43Z",
            "channelId": "UCplT2lzN6MHlVHHLt6so39A",
            "title": "Vanessa Weber - Quero meu código limpo! (Clean Code)",
            "description": "Olá Devs!\nHoje vamos entrevista a Vanessa Weber do Código Fonte TV.\nVamos falar sobre Clean Code.\n\nCanal Código Fonte TV\nhttps://www.youtube.com/user/codigofontetv\n\nCurso Gratuito de Clean code e SOLID\nhttps://balta.io/cursos/modelando-dominios-ricos",
            "thumbnails": {
              "default": {
                "url": "https://i.ytimg.com/vi/qGSYNPArUwU/default.jpg",
                "width": 120,
                "height": 90
              },
              "medium": {
                "url": "https://i.ytimg.com/vi/qGSYNPArUwU/mqdefault.jpg",
                "width": 320,
                "height": 180
              },
              "high": {
                "url": "https://i.ytimg.com/vi/qGSYNPArUwU/hqdefault.jpg",
                "width": 480,
                "height": 360
              },
              "standard": {
                "url": "https://i.ytimg.com/vi/qGSYNPArUwU/sddefault.jpg",
                "width": 640,
                "height": 480
              },
              "maxres": {
                "url": "https://i.ytimg.com/vi/qGSYNPArUwU/maxresdefault.jpg",
                "width": 1280,
                "height": 720
              }
            },
            "channelTitle": "Flutterando",
            "playlistId": "PLlBnICoI-g-cW2_yx2C7kNYOum-9frtPJ",
            "position": 0,
            "resourceId": {
              "kind": "youtube#video",
              "videoId": "qGSYNPArUwU"
            },
            "videoOwnerChannelTitle": "Flutterando",
            "videoOwnerChannelId": "UCplT2lzN6MHlVHHLt6so39A"
          }
        },
        {
          "kind": "youtube#playlistItem",
          "etag": "JFp5wxZFn9M0dIm7HlpnRdgqkYw",
          "id": "UExsQm5JQ29JLWctY1cyX3l4MkM3a05ZT3VtLTlmcnRQSi4yODlGNEE0NkRGMEEzMEQy",
          "snippet": {
            "publishedAt": "2022-09-20T19:00:18Z",
            "channelId": "UCplT2lzN6MHlVHHLt6so39A",
            "title": "FlutterCast - O Mercado de TI para Mulheres. (feat. Bullas Attekita)",
            "description": "Olá Devs!\nHoje nós vamos falar de diversidade e como anda o mercado de TI para as mulheres.\nE para isso teremos a participação de uma desenvolvedora de jogos que tem se destacado com seus cursos no YouTube, Bulas Attekita do canal, Attekita Dev.\n\nCanal Attekita Dev:\nhttps://www.youtube.com/channel/UCetRsdZxDQDcgVDJd6erz6g",
            "thumbnails": {
              "default": {
                "url": "https://i.ytimg.com/vi/2-GfoTVX124/default.jpg",
                "width": 120,
                "height": 90
              },
              "medium": {
                "url": "https://i.ytimg.com/vi/2-GfoTVX124/mqdefault.jpg",
                "width": 320,
                "height": 180
              },
              "high": {
                "url": "https://i.ytimg.com/vi/2-GfoTVX124/hqdefault.jpg",
                "width": 480,
                "height": 360
              },
              "standard": {
                "url": "https://i.ytimg.com/vi/2-GfoTVX124/sddefault.jpg",
                "width": 640,
                "height": 480
              },
              "maxres": {
                "url": "https://i.ytimg.com/vi/2-GfoTVX124/maxresdefault.jpg",
                "width": 1280,
                "height": 720
              }
            },
            "channelTitle": "Flutterando",
            "playlistId": "PLlBnICoI-g-cW2_yx2C7kNYOum-9frtPJ",
            "position": 1,
            "resourceId": {
              "kind": "youtube#video",
              "videoId": "2-GfoTVX124"
            },
            "videoOwnerChannelTitle": "Flutterando",
            "videoOwnerChannelId": "UCplT2lzN6MHlVHHLt6so39A"
          }
        },
        {
          "kind": "youtube#playlistItem",
          "etag": "ge7ViU0e-cwYc-sZsR-HpfMAew8",
          "id": "UExsQm5JQ29JLWctY1cyX3l4MkM3a05ZT3VtLTlmcnRQSi4wMTcyMDhGQUE4NTIzM0Y5",
          "snippet": {
            "publishedAt": "2022-09-20T19:02:07Z",
            "channelId": "UCplT2lzN6MHlVHHLt6so39A",
            "title": "Conhecendo o Flutter (feat. Leonardo Leitão da COD3R)",
            "description": "Muitos já aprenderam alguma tecnologia com ele, conhecido pela sua didática sensacional, coleciona vários cursos de sucesso.\n\nDia 13, nessa segunda, às 20h, bateremos um papo com Leonardo Leitão da Cod3r Cursos sobre a entrada dele no Flutter.\n\nCanal da Cod3r\nhttps://www.youtube.com/channel/UCcMcmtNSSQECjKsJA1XH5MQ",
            "thumbnails": {
              "default": {
                "url": "https://i.ytimg.com/vi/i4P69kRlgvQ/default.jpg",
                "width": 120,
                "height": 90
              },
              "medium": {
                "url": "https://i.ytimg.com/vi/i4P69kRlgvQ/mqdefault.jpg",
                "width": 320,
                "height": 180
              },
              "high": {
                "url": "https://i.ytimg.com/vi/i4P69kRlgvQ/hqdefault.jpg",
                "width": 480,
                "height": 360
              },
              "standard": {
                "url": "https://i.ytimg.com/vi/i4P69kRlgvQ/sddefault.jpg",
                "width": 640,
                "height": 480
              },
              "maxres": {
                "url": "https://i.ytimg.com/vi/i4P69kRlgvQ/maxresdefault.jpg",
                "width": 1280,
                "height": 720
              }
            },
            "channelTitle": "Flutterando",
            "playlistId": "PLlBnICoI-g-cW2_yx2C7kNYOum-9frtPJ",
            "position": 2,
            "resourceId": {
              "kind": "youtube#video",
              "videoId": "i4P69kRlgvQ"
            },
            "videoOwnerChannelTitle": "Flutterando",
            "videoOwnerChannelId": "UCplT2lzN6MHlVHHLt6so39A"
          }
        },
        {
          "kind": "youtube#playlistItem",
          "etag": "IMs_kIagPGSSfL8mIHHzU-g3rD0",
          "id": "UExsQm5JQ29JLWctY1cyX3l4MkM3a05ZT3VtLTlmcnRQSi41MjE1MkI0OTQ2QzJGNzNG",
          "snippet": {
            "publishedAt": "2022-09-20T19:02:12Z",
            "channelId": "UCplT2lzN6MHlVHHLt6so39A",
            "title": "Cíngulo - Eleito o melhor App de 2019 para Android",
            "description": "",
            "thumbnails": {
              "default": {
                "url": "https://i.ytimg.com/vi/SPEAV2qYwTI/default.jpg",
                "width": 120,
                "height": 90
              },
              "medium": {
                "url": "https://i.ytimg.com/vi/SPEAV2qYwTI/mqdefault.jpg",
                "width": 320,
                "height": 180
              },
              "high": {
                "url": "https://i.ytimg.com/vi/SPEAV2qYwTI/hqdefault.jpg",
                "width": 480,
                "height": 360
              },
              "standard": {
                "url": "https://i.ytimg.com/vi/SPEAV2qYwTI/sddefault.jpg",
                "width": 640,
                "height": 480
              },
              "maxres": {
                "url": "https://i.ytimg.com/vi/SPEAV2qYwTI/maxresdefault.jpg",
                "width": 1280,
                "height": 720
              }
            },
            "channelTitle": "Flutterando",
            "playlistId": "PLlBnICoI-g-cW2_yx2C7kNYOum-9frtPJ",
            "position": 3,
            "resourceId": {
              "kind": "youtube#video",
              "videoId": "SPEAV2qYwTI"
            },
            "videoOwnerChannelTitle": "Flutterando",
            "videoOwnerChannelId": "UCplT2lzN6MHlVHHLt6so39A"
          }
        },
        {
          "kind": "youtube#playlistItem",
          "etag": "Z5azD786q-HB7rKtCEK6oPdEfqA",
          "id": "UExsQm5JQ29JLWctY1cyX3l4MkM3a05ZT3VtLTlmcnRQSi4wOTA3OTZBNzVEMTUzOTMy",
          "snippet": {
            "publishedAt": "2022-09-20T19:02:18Z",
            "channelId": "UCplT2lzN6MHlVHHLt6so39A",
            "title": "Porque escolhemos o Flutter? (Feat Programadores do iFood)",
            "description": "** SEJA MEMBRO E AJUDE O CANAL **\nhttps://www.youtube.com/channel/UCplT...\n\nNossos canais (Facebook, Telegram, Discord, Forum)\nhttps://linktr.ee/Flutterando\n\n# Redes do pessoal do Ifood\nSamuel Matias: \n - https://www.linkedin.com/in/samuelematias/\n - https://github.com/samuelematias\nIsac Junior: \n - https://www.linkedin.com/in/isacjunior/\n - https://github.com/isacjunior\nPedro Culque: \n - https://www.linkedin.com/in/pculque/\nThiago Mendes: \n - https://www.linkedin.com/in/thiago-mendes-8723aaa3/\n - https://github.com/thim\n\n# Github da Nubank\nhttps://github.com/nubank\n\n# Github do Ifood\nhttps://github.com/ifood\n\n# Github do Igor\nhttps://github.com/comigor\n\n# iFood para Parceiros\nhttps://play.google.com/store/apps/details?id=br.com.ifood.partner&hl=pt_BR\nhttps://apps.apple.com/br/app/ifood-para-parceiros/id1492920987\n\n# Vagas na Nubank\n​https://nubank.com.br/carreiras\n\n# Vagas no Ifood\n​https://www.ifood.com.br/carreiras",
            "thumbnails": {
              "default": {
                "url": "https://i.ytimg.com/vi/8ZzJZd0r-4c/default.jpg",
                "width": 120,
                "height": 90
              },
              "medium": {
                "url": "https://i.ytimg.com/vi/8ZzJZd0r-4c/mqdefault.jpg",
                "width": 320,
                "height": 180
              },
              "high": {
                "url": "https://i.ytimg.com/vi/8ZzJZd0r-4c/hqdefault.jpg",
                "width": 480,
                "height": 360
              },
              "standard": {
                "url": "https://i.ytimg.com/vi/8ZzJZd0r-4c/sddefault.jpg",
                "width": 640,
                "height": 480
              },
              "maxres": {
                "url": "https://i.ytimg.com/vi/8ZzJZd0r-4c/maxresdefault.jpg",
                "width": 1280,
                "height": 720
              }
            },
            "channelTitle": "Flutterando",
            "playlistId": "PLlBnICoI-g-cW2_yx2C7kNYOum-9frtPJ",
            "position": 4,
            "resourceId": {
              "kind": "youtube#video",
              "videoId": "8ZzJZd0r-4c"
            },
            "videoOwnerChannelTitle": "Flutterando",
            "videoOwnerChannelId": "UCplT2lzN6MHlVHHLt6so39A"
          }
        }
      ],
      "pageInfo": {
        "totalResults": 26,
        "resultsPerPage": 5
      }
    }

    return data;
  }
}