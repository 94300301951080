import { ICustomElementViewModel, IEventAggregator } from 'aurelia'
import { Modal, type ModalOptions, type ModalInterface } from 'flowbite'

import { IVideoPlayerModalEvent, VideoPlayerModalEvent } from './events/video-player-modal-event';

export class VideoPlayerModalComponent {
  private videoId: string = null;
  private videoPlayerModal: ModalInterface;

  private player: YT.Player;

  constructor(@IEventAggregator readonly ea: IEventAggregator) { }

  async attached() {

    await this.loadYoutubeApi()

    const $modalElement: HTMLElement = document.querySelector('#video-player-modal');

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
      onShow: () => {
        this.player.loadVideoById(this.videoId);
        this.player.playVideo();
      },
      onHide: () => {
        this.player.stopVideo();
      }
    }

    this.videoPlayerModal = new Modal($modalElement, modalOptions);

    this.ea.subscribe(VideoPlayerModalEvent.SHOW, (payload: IVideoPlayerModalEvent) => {
      this.videoId = payload.videoId;

      this.showModal();
    });
  }

  private showModal(): void {
    this.videoPlayerModal.show();
  }

  private hideModal(): void {
    this.videoPlayerModal.hide();
  }

  static show({ event, payload }: { event: IEventAggregator, payload: IVideoPlayerModalEvent }): void {
    event.publish(VideoPlayerModalEvent.SHOW, payload);
  }

  // YOUTUBE
  private loadYoutubeApi() {
    return new Promise<void>((resolve, reject) => {
      if (typeof window.YT !== 'undefined' && typeof window.YT.Player !== 'undefined') {
        resolve();
      } else {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
          this.player = new window.YT.Player('player', {
            height: '360',
            width: '640',
            autoplay: 1,
            origin: window.location.origin,
          });

          resolve();
        };
      }
    });
  }
}