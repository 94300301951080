<section>

  <bindable property="title" default="Title"></bindable>
  <bindable property="subtitle" default="Subtitle"></bindable>

  <div class="flex items-center justify-between flex-wrap border-b dark:border-gray-700 px-4 pt-4 pb-2">
    <div class="text-base font-medium m-2 ml-0 block w-full">

      <div class="flex flex-row justify-between">
        <h5 class="font-bold text-gray-900 dark:text-white">${(headerId ? headers[headerId][I18N.getLocale()].title : 'Title') & signal:'parameterChanged'}</h5>

        <button show.bind="showPopover" data-popover-target="popover-${elementId}" data-popover-placement="right" type="button">
          <svg class="w-4 h-4 ml-2 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>

      <au-slot name="subtitle">
        <h6 class="text-sm font-normal text-gray-700 dark:text-gray-400">${(headerId ?
          headers[headerId][I18N.getLocale()].subtitle : 'Subtitle') & signal:'parameterChanged'}</h6>
      </au-slot>
    </div>
  </div>

  <div data-popover id="popover-${elementId}" role="tooltip" class="absolute z-30 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
    <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
      <h3 class="font-semibold text-gray-900 dark:text-white">${(headerId ?
        headers[headerId][I18N.getLocale()].popoverTitle : 'Title') & signal:'parameterChanged'}</h3>
    </div>
    <div class="px-3 py-2 text-xs">
      <p>${(headerId ? headers[headerId][I18N.getLocale()].popoverDescription : 'Description') &
        signal:'parameterChanged'}</p>
    </div>
    <div data-popper-arrow></div>
  </div>

</section>