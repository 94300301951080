import moment from 'moment';
import { Modal } from 'flowbite'
import { IRouter } from '@aurelia/router';
import { actionsFromPublish, socialMediaData, subTypeData } from 'common/data';
import type { ModalOptions, ModalInterface } from 'flowbite'
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ToastComponent } from '@components/toast/toast-component';
import { ToastType } from '@components/toast/events/toast-event';
import { IEventAggregator } from 'aurelia';

export class PublishSocialMediaComponent {
  private modal: ModalInterface;

  private projectId: string;
  private socialMedia: string;
  private integrationId: string;
  private facebookPageId: string;
  private instagramBusinessId: string;

  private subType: string = 'feed';

  private scheduleDate: string;
  private selectedAction: string;

  private minDate: string;

  private subTypeData = subTypeData;
  private socialMediaData = socialMediaData;
  private actionsFromPublishData = actionsFromPublish;

  constructor(
    @IRouter private router: IRouter,
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator
  ) { }

  attached() {
    this.minDate = moment().format('YYYY-MM-DDTHH:mm');

    const $modalElement: HTMLElement = document.querySelector('#modal-publish');

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
      onShow: () => {
        this.socialMedia = null;
        this.subType = 'feed';
        this.integrationId = null;
        this.facebookPageId = null;
        this.instagramBusinessId = null;
        this.selectedAction = null;
        this.scheduleDate = null;
      },
      onHide: () => {
        this.socialMedia = null;
        this.subType = 'feed';
        this.integrationId = null;
        this.facebookPageId = null;
        this.instagramBusinessId = null;
        this.selectedAction = null;
        this.scheduleDate = null;
      }
    }

    this.modal = new Modal($modalElement, modalOptions);
  }

  private async getUserAppIntegrations(socialMedia: string) {
    const result = await this.zeusClient.getUserAppIntegrations();

    return result.filter((integration) => integration.integrationType === socialMedia);
  }

  private async getInstagramPages(integrationId: string) {
    const result = await this.zeusClient.getInstagramPages(integrationId);

    return result;
  }

  private async getFacebookPages(integrationId: string) {
    const result = await this.zeusClient.getFacebookPages(integrationId);

    return result;
  }

  private validateForm() {
    if (!this.selectedAction) return false;

    if (this.selectedAction === 'schedule' && !this.scheduleDate) return false;

    if (!this.socialMedia) return false

    if (!this.subType) return false;

    if (!this.integrationId) return false;

    if (this.socialMedia === 'instagram' && !this.instagramBusinessId) return false;

    if (this.socialMedia === 'facebook' && !this.facebookPageId) return false;

    return true;
  }

  private async handlePublish() {
    if (!this.validateForm()) {
      this.showMessage({ title: 'Erro', message: 'Preencha todos os campos', type: ToastType.DANGER });
      return;
    }

    const { success, errorMessage } = await this.zeusClient.publishOrScheduleProject({
      channel: this.socialMedia,
      subtype: this.subType,
      projectId: this.projectId,
      integrationId: this.integrationId,
      facebookPageId: this.facebookPageId,
      instagramBusinessId: this.instagramBusinessId,
      ...this.selectedAction === 'schedule' && { scheduledDate: moment(this.scheduleDate).toDate() }
    });

    if (!success) {
      this.showMessage({ title: 'Erro', message: errorMessage ?? 'Erro ao tentar publicar', type: ToastType.DANGER });
      return;
    }

    this.showMessage({ title: 'Sucesso', message: 'Publicação realizada com sucesso', type: ToastType.SUCCESS });

    this.hideModal();

    await this.router.load('/creations');
  }

  private showModal(projectId: string) {
    this.projectId = projectId;

    this.modal.show();
  }

  private hideModal() {
    this.modal.hide();
  }

  private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type
      }
    });
  }
}