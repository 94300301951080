import { IRouter } from '@aurelia/router';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class FacebookSuccessPage {
  async loading(parameters: { code: any, state: any }) {
    if (parameters) {
      const { code, state } = parameters;

      await this.handleFacebookTokenCallback(code, state);
    }
  }

  constructor(
    @IZeusClient private zeusClient: ZeusClient,
    @IRouter private router: IRouter
  ) { }

  private async handleFacebookTokenCallback(code: string, brandId: string) {
    await this.zeusClient.facebookAuthCallback(code, brandId);
  }
}