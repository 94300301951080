import { nhost } from 'lib/nhost';
import { IRouter } from '@aurelia/router';
import { IEventAggregator, bindable } from 'aurelia';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { StorageUploadFileResponse } from '@nhost/nhost-js';
import { IValidationController } from '@aurelia/validation-html';
import { AlertModalType } from '@components/alert-modal/events/alert-modal-event';
import { AlertModalComponent } from '@components/alert-modal/alert-modal-component';
import { ColorsModalComponent } from './components/colors-modal/colors-modal-component';
import { IColorsCallback } from './components/colors-modal/events/colors-modal-event';
import { IBrandGuidService } from './services/brand-guide-service';
import chroma from 'chroma-js';
import WebFont from 'webfontloader';

import { companySegmentData, fontFamilyData, isBrandData, personalityData, productOrServiceData, voiceToneData } from 'common/data';

export class BrandGuidePage {
  @bindable logoFile: File;
  @bindable logoFileId: string;

  private brandId: string;
  private brandName: string;
  private isBrand: boolean;
  private slogan: string;
  private segment: string;
  private productOrService: string;
  private fontPrimary: string;
  private fontSecondary: string;
  private voiceTone: string;
  private personality: string;

  private colors: IColorsCallback[] = []

  private isBrandData = isBrandData;
  private voiceToneData = voiceToneData;
  private fontFamilyData = fontFamilyData;
  private segmentData = companySegmentData;
  private personalityData = personalityData;
  private productOrServiceData = productOrServiceData;

  private colorErrors: string;

  private textColor: string = '#ffffff';
  private shapeBlue: string = '#4a90e2';
  private shapeOrange: string = '#ff3e00';

  private titleRef: HTMLElement;
  private headlineRef: HTMLElement;

  constructor(
    @IRouter private router: IRouter,
    @IEventAggregator readonly ea: IEventAggregator,
    @IBrandGuidService private service: IBrandGuidService,
    @IValidationRules readonly validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) readonly validationController: IValidationController
  ) {
    validationRules
      .on(this)
      .ensure('brandName')
      .required().withMessage('Informe o nome da marca')

      .ensure('isBrand')
      .required().withMessage('Selecione o contexto da marca')

      .ensure('logoFile')
      .required()
      .when((x: BrandGuidePage) => !x.logoFileId)
      .withMessage('Selecione um logo')

      .ensure('segment')
      .required().withMessage('Selecione o segmento')

      .ensure('productOrService')
      .required().withMessage('Selecione o produto ou serviço')

      .ensure('fontPrimary')
      .required().withMessage('Selecione a fonte primária')

      .ensure('fontSecondary')
      .required().withMessage('Selecione a fonte secundária')

      .ensure('voiceTone')
      .required().withMessage('Selecione o tom de voz')

      .ensure('personality')
      .required().withMessage('Selecione a personalidade')
  }

  async loading() {
    await this.getBrands();
  }

  private async getBrands() {
    const result = await this.service.getBrands();

    if (!result.length) return;

    const brand = result[0];

    this.brandId = brand.id;
    this.brandName = brand.brandName;
    this.isBrand = brand.isBrand;
    this.logoFileId = brand.logoFileId;
    this.slogan = brand.slogan;
    this.segment = brand.segment;
    this.productOrService = brand.productOrService;
    this.fontPrimary = brand.fontPrimary;
    this.fontSecondary = brand.fontSecondary;
    this.voiceTone = brand.voiceTone;
    this.personality = brand.personality;

    if (brand.colors) {
      this.colors = (typeof brand.colors === 'string') ? JSON.parse(brand.colors) : brand.colors;
      // this.colors = JSON.parse(brand.colors);

      this.gandleGetShade();
    }

    this.updateFont();
  }

  private addColors() {
    if (this.colors.length >= 3) return;

    const colorId = Math.random().toString(36).substring(7);

    this.colors.push({ id: colorId, backgroundColor: null, foregroundColor: null });

    this.showColorsModal(this.colors.at(-1));
  }

  private removeColors(id: string) {
    this.colors = this.colors.filter((color) => color.id !== id);
  }

  private showColorsModal(colorPalette: IColorsCallback): void {
    ColorsModalComponent.show({
      event: this.ea,
      payload: {
        ...colorPalette,
        addColor: (callback: IColorsCallback) => {
          this.colors.filter((color) => color.id === callback.id)[0].backgroundColor = callback.backgroundColor;
          this.colors.filter((color) => color.id === callback.id)[0].foregroundColor = callback.foregroundColor;

          this.gandleGetShade();
        },
      }
    });
  }

  private validateColorPalette(): boolean {
    this.colorErrors = null;

    if (this.colors.length <= 0) {
      this.colorErrors = 'Informe pelo menos uma paleta de cores';
      return false;
    }

    if (this.colors.filter((color) => color.backgroundColor === null || color.foregroundColor === null).length > 0) {
      this.colorErrors = 'Paleta de cores invalida';
      return false;
    }

    return true;
  }

  private gandleGetShade() {
    if (this.colors.length <= 0) return;

    this.textColor = this.colors[0].foregroundColor;
    this.shapeOrange = this.colors[0].backgroundColor;
    this.shapeBlue = this.getShade(this.colors[0].backgroundColor, 1);
  }

  private getShade(color: string, index: string | number): string {
    const shades = chroma.scale([color, '#ffffff']).colors(7)
    return shades[index];
  }

  private updateFont() {
    if (this.fontPrimary) {
      this.loadFont(this.fontPrimary);
    }
  }

  private loadFont(fontPrimary: string) {
    WebFont.load({
      google: {
        families: [fontPrimary]
      },
      fontactive: (familyName: string, fvd: string) => {
        this.applyFont(familyName);
      }
    });
  }

  private applyFont(familyName: string) {
    if (this.titleRef) {
      this.titleRef.style.fontFamily = familyName;
    }

    if (this.headlineRef) {
      this.headlineRef.style.fontFamily = familyName;
    }
  }

  private async handleUpsertBrand(): Promise<void> {
    const isValidColors = this.validateColorPalette();

    const result = await this.validationController.validate();

    if (!result.valid || !isValidColors) return;

    AlertModalComponent.show({
      event: this.ea,
      payload: {
        label: 'Salvar marca',
        message: 'Gostaria de salvar a marca?',
        type: AlertModalType.SUCCESS,
        toastMessage: 'Marca salva com sucesso!',
        confirmFunction: async () => {

          let logoResponse: StorageUploadFileResponse | null = null;

          if (this.logoFile) {
            logoResponse = await nhost.storage.upload({
              bucketId: 'public',
              file: this.logoFile,
              ...this.logoFileId && { fileId: this.logoFileId }
            });
          }

          await this.service.upsertBrand({
            brandId: this.brandId,
            brandName: this.brandName,
            isBrand: this.isBrand,
            logoFileId: logoResponse ? logoResponse.fileMetadata.id : this.logoFileId,
            slogan: this.slogan,
            segment: this.segment,
            productOrService: this.productOrService,
            fontPrimary: this.fontPrimary,
            fontSecondary: this.fontSecondary,
            voiceTone: this.voiceTone,
            personality: this.personality,
            colors: JSON.stringify(this.colors) as string,
          });

        }
      }
    });
  }

  private currentFile: CurrentFile;

  private logoFileChanged(file: File) {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      const base64Data = fileReader.result as string;

      const fileId = Math.random().toString(36).substring(2, 9);

      const fileData: CurrentFile = {
        id: fileId,
        file: base64Data,
        size: file.size,
      }

      this.currentFile = fileData;
    }

    if (file) {
      fileReader.readAsDataURL(file);
    }

  }
}

type CurrentFile = {
  id: string;
  file: string;
  size: number;
}