import { I18N } from '@aurelia/i18n';
import { IEventAggregator } from 'aurelia';
import type { ModalInterface } from 'flowbite'
import { ToastType } from '@components/toast/events/toast-event';
import { ToastComponent } from '@components/toast/toast-component';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { GenericListComparsionType, GenericListFildInputType, IGenericListConfig } from '@components/generic-list/interfaces/generic-filtered-interface';

export class SocialMediaPage {

  private modal: ModalInterface;
  private addIntegration: { openAddIntegrationModal: (arg0: string) => void; };

  private brandId: string;
  private config: IGenericListConfig;

  constructor(
    @I18N private I18N: I18N,
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator
  ) { }

  loading(params: { id: string; }) {
    this.brandId = params.id;

    this.config = {
      total: 0,
      tableName: 'userAppIntegration',
      streamName: 'userAppIntegration_stream',
      aggregateName: 'userAppIntegration_aggregate',
      columns: ['id', 'alias', 'integrationType', 'created_at', 'updated_at', 'deleted'],
      fixedFilters: [{
        comparsionType: GenericListComparsionType.Equals,
        title: 'brandId',
        field: 'brandId',
        type: GenericListFildInputType.Text,
        value: this.brandId,
      }],
      filters: [],
      orderBy: [
        { title: 'Alias', field: 'alias', value: null },
        { title: 'Data criação', field: 'created_at', value: null },
      ],
      data: [],
      perPage: 12,
      currentPage: 1,
    }
  }

  private async deleteUserAppIntegration(integrationId: string) {
    const { id } = await this.zeusClient.deleteUserAppIntegrationById(integrationId);

    if (!id) {
      this.showMessage({ title: 'Erro', message: 'Erro ao excluir integração', type: ToastType.DANGER });
      return;
    }

    this.showMessage({ title: 'Sucesso', message: 'Integração excluida com sucesso', type: ToastType.SUCCESS });
  }

  openWordpressModal() {
    this.modal.show();
  }

  openAddIntegrationModal() {
    this.addIntegration.openAddIntegrationModal(this.brandId);
  }

  private setIconByType(channel: string): string {
    switch (channel) {
      case 'instagram':
        return 'fa-brands fa-instagram';
      case 'facebook':
        return 'fa-brands fa-facebook-f';
      case 'linkedin':
        return 'fa-brands fa-linkedin-in';
      case 'tiktok':
        return 'fa-brands fa-tiktok';
      case 'blog':
        return 'fas fa-rss';
      case 'site':
        return 'fas fa-globe';
      case 'email':
        return 'fas fa-envelope';
      case 'wordpress':
        return 'fa-brands fa-wordpress';
      default:
        return 'fas fa-question';
    }
  }

  private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type
      }
    });
  }
}