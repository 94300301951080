import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class UserIntegrationComponent {
  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  private async getUserAppIntegrationAggregate() {
    const result = await this.zeusClient.getUserAppIntegrationAggregate();

    return result;
  }
}