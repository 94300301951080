import { IEventAggregator } from 'aurelia';
import achetypes from './data/archetypes.json'
import { IArchetypeService } from './services/archetype-service';

export class ArchetypePage {

  private archetypesData = achetypes;

  constructor(
    @IEventAggregator readonly ea: IEventAggregator,
    @IArchetypeService private service: IArchetypeService
  ) { }

  attached() {
    this.getBrands();
  }

  private async getBrands() {
    const result = await this.service.getBrands();

    if (!result) return;

    const brand = result[0];

    if (brand.archtypeAdherence) {
      this.archetypesData = achetypes;

      const archtypeAdherence = JSON.parse(brand.archtypeAdherence);

      const map = this.archetypesData.map((archetype) => {
        archetype.adherence = archtypeAdherence[archetype.id] ?? 0.0;
        return archetype;
      });

      this.archetypesData = map.sort((a, b) => b.adherence - a.adherence);
    }
  }

}