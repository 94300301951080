import { valueConverter } from 'aurelia';

@valueConverter('date')
export class DateFormatConverter {
  toView(value: string, locale = 'en-US', options: Intl.DateTimeFormatOptions = {}) {
    const date = new Date(value);

    const format = Intl.DateTimeFormat(locale, {
      month: options.month ?? 'numeric',
      day: options.day ?? 'numeric',
      year: options.year ?? 'numeric',
      timeZone: options.timeZone ?? 'UTC'
    });

    if (Number.isNaN(date.valueOf())) {
      return 'Invalid Date';
    }

    return format.format(date);
  }
}

@valueConverter('dateTime')
export class DateTimeFormatConverter {
  toView(value: string, locale = 'en-US', options: Intl.DateTimeFormatOptions = {}) {
    const date = new Date(value);

    const format = Intl.DateTimeFormat(locale, {
      month: options.month ?? 'numeric',
      day: options.day ?? 'numeric',
      year: options.year ?? 'numeric',
      hour: options.hour ?? 'numeric',
      minute: options.minute ?? 'numeric',
      hour12: options.hour12 ?? false,
      timeZone: options.timeZone ?? 'UTC'
    });

    if (Number.isNaN(date.valueOf())) {
      return 'Invalid Date';
    }

    return format.format(date);
  }
}