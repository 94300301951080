interface IVideoPlayerModalEvent {
  videoId: string;
}

class VideoPlayerModalEvent {
  static readonly SHOW = 'play-modal:show';

  constructor(public payload: IVideoPlayerModalEvent) { }
}

export { IVideoPlayerModalEvent, VideoPlayerModalEvent }