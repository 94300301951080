import { watch } from "@aurelia/runtime-html";
import { ModelTypes } from '../../../../../../../utils/generated/zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class CategoriesComponent {
  private consumptionByChannelViewSubscription: Array<ModelTypes["consumptionByChannelView"]>;

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  async attached() {
    await this.zeusClient.getConsumptionByChannelViewSubscription();
  }

  @watch('zeusClient.consumptionByChannelViewSubscription')
  handleSubscription(newData: Array<ModelTypes["consumptionByChannelView"]>, oldData: Array<ModelTypes["consumptionByChannelView"]>): void {
    this.consumptionByChannelViewSubscription = newData;
  }

  private transformeChannelName(channel: string): string {
    switch (channel) {
      case 'instagram':
        return 'Instagram';
      case 'facebook':
        return 'Facebook';
      case 'linkedin':
        return 'LinkedIn';
      case 'blog':
        return 'Blog';
      default:
        return '';
    }
  }

  private iconByChannel(channel: string): string {
    switch (channel) {
      case 'instagram':
        return 'fa-brands fa-instagram';
      case 'facebook':
        return 'fa-brands fa-facebook-f';
      case 'linkedin':
        return 'fa-brands fa-linkedin-in';
      case 'blog':
        return 'fas fa-rss';
      default:
        return '';
    }
  }

}