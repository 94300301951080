<div class="h-full rounded-2xl border border-gray-100 dark:border-gray-600">
  <div class="flex flex-col h-full">

    <div class="flex items-center gap-2 p-4">
      <i class="${integration.icon} text-xl text-gray-500 dark:text-gray-400"></i>
      <h3 class="text-sm font-bold tracking-tight text-gray-900 dark:text-white">${integration.name}</h3>
    </div>

    <div class="border-y dark:border-gray-700 p-4 grow">
      <p class="text-xs font-normal text-gray-700 dark:text-gray-400 ">${integration.description}</p>
    </div>

    <div class="flex justify-end items-center p-4">
      <button type="button" click.trigger="configureIntegration()" class="btn-primary-small">
        Configurar
        <i class="fa-solid fa-arrow-right ml-2"></i>
      </button>
    </div>

  </div>
</div>