export interface IModelsData {
  id: string;
  value: number;
  text: string;
  image: string;
  description: string;
}

export const modelsData: IModelsData[] = [
  { id: '1', value: 33, text: 'V5 (Beta)', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/thumb-v5.webp', description: 'V5 (Beta)' },
  { id: '2', value: 34, text: 'V5 Anime', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/thumb-v5a.webp', description: 'V5 Anime' },
  { id: '3', value: 30, text: 'Imagine V4', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v1/Imagine_V3.5.webp', description: 'Imagine V4' },
  { id: '4', value: 122, text: 'SDXL', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/thumb-sdxl.webp', description: '' },
  { id: '5', value: 28, text: 'Imagine V3', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v1/thumbv1_27.webp', description: '' },
  { id: '6', value: 0, text: 'Magic Mix', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/thumb-78.webp', description: '' },
  { id: '7', value: 0, text: 'Disney', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/thumb-76.webp', description: '' },
  { id: '8', value: 0, text: 'Orange Mix', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/thumb-79.webp', description: '' },
  { id: '9', value: 0, text: 'Realistic Vision', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/Realistic.webp', description: '' },
  { id: '10', value: 21, text: 'Anime V2', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v1/thumb_37.webp', description: '' },
  { id: '11', value: 0, text: 'Dream Shaper', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/Dream%20Shaper.webp', description: '' },
  { id: '12', value: 27, text: 'Imagine V1', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-63.webp', description: '' },
  { id: '13', value: 32, text: '4.1', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/thumb-81.webp', description: '' },
  { id: '14', value: 0, text: 'Meina Mix', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/Meina%20Mix.webp', description: '' },
  { id: '15', value: 29, text: 'Realistic', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-72.webp', description: '' },
  { id: '16', value: 0, text: 'Toon You', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/Toon%20You.webp', description: '' },
  { id: '17', value: 0, text: 'Lyriel', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/models/thumb-77.webp', description: '' },
  { id: '18', value: 31, text: 'Creative', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-73.webp', description: '' },
  { id: '19', value: 26, text: 'Portrait', image: 'https://1966211409.rsc.cdn77.org/appStuff/imagine-fncisndcubnsduigfuds/assets/styles_v4/thumb-65.webp', description: '' },
]