import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';
import { IPolotnoService } from '../../services/polotno-service';
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export class SaveTemplateDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private titleMaxLength: string;
  private descriptionMaxLength: string;
  private subtitleMaxLength: string;
  private category: string;
  private template: string;

  constructor(
    @IDialogDom private dialogDom: DefaultDialogDom,
    @IPolotnoService private service: IPolotnoService
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async activate(model?: any) {
    this.template = model.template;
  }

  async close() {
    this.$dialog.ok();
  }

  private async handleSaveTemplate(): Promise<void> {
    const response = await this.service.saveTemplate({
      titleMaxLength: Number(this.titleMaxLength),
      descriptionMaxLength: Number(this.descriptionMaxLength),
      subtitleMaxLength: Number(this.subtitleMaxLength),
      category: this.category,
      json: this.template,
      visibility: 'public'
    })

    if (!response?.id) {
      Toastify({
        text: 'Error saving template',
        duration: 3000,
        gravity: 'top',
        position: 'right',
        style: { background: 'red' }
      }).showToast();
    }

    Toastify({
      text: 'Template saved successfully',
      duration: 3000,
      gravity: 'top',
      position: 'right',
      style: { background: 'green' }
    }).showToast();

    this.$dialog.ok();
  }
}