import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { IEventAggregator } from 'aurelia';
import { IStorageService } from '@interfaces/storage/storage-interface';
import { AlertModalType } from '@components/alert-modal/events/alert-modal-event';
import { AlertModalComponent } from '@components/alert-modal/alert-modal-component';
import { nhost } from 'lib/nhost';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class ChangePasswordComponent {
  private password: string;

  constructor(
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator,
    @IStorageService private storageService: IStorageService,
    @IValidationRules readonly validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) readonly validationController: IValidationController
  ) {
    validationRules
      .on(this)
      .ensure('password')
      .minLength(9).withMessage('A senha deve conter no mínimo 9 caracteres')
      .required().withMessage('Informe a senha')
  }

  private async handleChangePassword(): Promise<void> {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    AlertModalComponent.show({
      event: this.ea,
      payload: {
        label: 'Salvar senha',
        message: 'Gostaria de salvar senha?',
        type: AlertModalType.SUCCESS,
        toastMessage: 'Senha salva com sucesso!',
        confirmFunction: async () => {

          await nhost.auth.changePassword({
            newPassword: this.password
          });

        }
      }
    });
  }
}