import { ICustomElementController } from "@aurelia/runtime-html";
import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from '@aurelia/dialog';

export class AudienceDialog implements IDialogCustomElementViewModel {
  $dialog: IDialogController;
  $controller?: ICustomElementController<this>;

  private personas: JSON = null;

  constructor(
    @IDialogDom private dialogDom: DefaultDialogDom
  ) {
    this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  }

  async activate(model?: any) {
    this.personas = model.personas;

    console.table(this.personas);
  }

  async close() {
    this.$dialog.ok();
  }
}