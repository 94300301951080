<div class="bg-white rounded-lg shadow dark:bg-gray-700 max-h-[80vh] w-[70vw]">
  <!-- begin::header -->
  <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
    <div class="flex flex-col">
      <h5 class="text-xl font-semibold text-gray-900 dark:text-white">Arquétipos de marca</h5>
      <p class="text-sm font-normal text-gray-700 dark:text-gray-400">Conjunto de padrões que definem a personalidade, valores e objetivos de uma marca</p>
    </div>

    <button type="button" click.trigger="$dialog.cancel()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
      <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
      </svg>
      <span class="sr-only">Close modal</span>
    </button>
  </div>
  <!-- end::header -->

  <!-- begin::modal body -->
  <div class="flex flex-col sm:flex-row gap-6 p-6">
    <div class="w-full sm:w-[30%]">

      <div class="h-full flex">
        <div class="flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-12 card">

          <div class="w-full px-4 flex justify-center">
            <div class="relative">
              <img src="${archetype.avatar}" alt="img-blur-shadow" class="shadow-lg dark:shadow-current rounded-2xl align-middle h-auto max-w-[100px] absolut -m-12 mx-auto">
            </div>
          </div>

          <div class="flex flex-col items-center p-4 mt-11 h-full gap-2">
            <h5 class="text-sm font-bold text-gray-700 dark:text-gray-300">${archetype.name}</h5>

            <h5 class="text-xs font-bold text-center text-gray-700 dark:text-gray-300">${archetype.description}</h5>

            <p class="leading-relaxed font-semibold text-xs text-gray-700 dark:text-gray-300 grow">${archetype.essence}</p>

            <p class="leading-relaxed text-xs text-gray-700 dark:text-gray-300 grow">${archetype.adherence * 100}% afinidade</p>
            <p class="leading-relaxed text-xs text-gray-700 dark:text-gray-400">${archetype.voiceTone}</p>
            <p class="leading-relaxed text-xs text-gray-700 dark:text-gray-300 mt-2">${archetype.message}</p>
          </div>

        </div>
      </div>

    </div>

    <div class="w-full sm:w-[70%] h-fit">

      <div class="flex flex-col gap-4">
        <div class="flex flex-col card gap-1 p-4">
          <span class="text-sm font-bold text-gray-700 dark:text-gray-300">Estratégia de posicionamento</span>
          <span class="text-xs text-gray-700 dark:text-gray-400">${archetype.positioningStrategy}</span>
        </div>
        <div class="flex flex-col card gap-1 p-4">
          <span class="text-sm font-bold text-gray-700 dark:text-gray-300">Direcional Artístico</span>
          <span class="text-xs text-gray-700 dark:text-gray-400">${archetype.artDirection}</span>
        </div>
        <div class="flex flex-col card gap-1 p-4">
          <span class="text-sm font-bold text-gray-700 dark:text-gray-300">Exemplos de Marcas</span>
          <span class="text-xs text-gray-700 dark:text-gray-400">${archetype.brandExamples}</span>
        </div>
        <div class="flex flex-col card gap-1 p-4">
          <span class="text-sm font-bold text-gray-700 dark:text-gray-300">Exemplos de Campanhas</span>
          <span class="text-xs text-gray-700 dark:text-gray-400">${archetype.examplesOfCampaigns}</span>
        </div>

        <!-- <button class="btn-secondary w-full">Quero um relatório completo da minha marca</button> -->
      </div>

    </div>
  </div>
  <!-- end::modal body -->
</div>