<!-- <div class="w-full mx-auto"> -->
<div class="h-full flex">
  <div class="flex flex-col min-w-0 break-words w-full mb-6 rounded-lg mt-12 card">

    <div class="w-full px-4 flex justify-center">
      <div class="relative cursor-pointer">
        <img src="${archetype.avatar}" alt="img-blur-shadow" class="shadow-lg dark:shadow-current rounded-2xl align-middle h-auto max-w-[100px] absolut -m-12 mx-auto ${archetype.adherence == 0 ? 'grayscale' : 'border-[3px] border-green-400 dark:border-green-600'}">
      </div>
      <!-- <div class="relative cursor-pointer" mouseover.trigger="showVideo = true" mouseout.trigger="showVideo = false">
        <img src="${archetype.avatar}" alt="img-blur-shadow" class="shadow-lg dark:shadow-current rounded-2xl align-middle h-auto max-w-[100px] absolut -m-12 mx-auto ${archetype.adherence == 0 ? 'grayscale' : 'border-[3px] border-green-400 dark:border-green-600'} transition-opacity" show.bind="!showVideo" class.bind="{ 'fade-out': showVideo }">
        <video src="${archetype.video}" alt="img-blur-shadow" class="shadow-lg dark:shadow-current rounded-2xl align-middle h-[141px] max-w-[100px] absolut -m-12 mx-auto ${archetype.adherence == 0 ? 'grayscale' : 'border-[3px] border-green-400 dark:border-green-600'} transition-opacity" muted autoplay loop show.bind="showVideo" class.bind="{ 'fade-in': showVideo }">
      </div> -->
    </div>

    <div class="flex flex-col items-center p-4 mt-11 h-full gap-2">
      <h5 class="text-sm font-bold text-gray-700 dark:text-gray-300">${archetype.name}</h5>
      <h5 class="text-sm font-bold text-gray-700 dark:text-gray-300">${archetype.adherence * 100}%</h5>

      <h5 class="text-xs font-bold text-center text-gray-700 dark:text-gray-300 line-clamp-2 grow">${archetype.description}</h5>
      <p class="leading-relaxed text-xs text-gray-700 dark:text-gray-400 mb-auto">${archetype.essence}</p>

      <button type="button" click.trigger="openArchetypeDialog()" class="inline-block w-full px-8 py-2 mb-0 mt-1 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-lg shadow-none cursor-pointer leading-[1.4] ease-in text-[.75rem] hover:scale-[1.02] active:shadow-xs tracking-tight border-[#cb0c9f] text-[#cb0c9f] hover:border-[#cb0c9f] hover:bg-transparent hover:text-[#cb0c9f] hover:opacity-75 hover:shadow-none active:bg-[#cb0c9f] active:text-white active:hover:bg-transparent active:hover:text-[#cb0c9f]">Ver mais</button>

    </div>


  </div>
</div>

<style>
  .fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
</style>