import { DefaultDialogDom, IDialogController, IDialogCustomElementViewModel, IDialogDom } from "@aurelia/dialog";
import { ICustomElementController } from "@aurelia/runtime-html";
import { ToastType } from '@components/toast/events/toast-event';
import { ToastComponent } from '@components/toast/toast-component';
import { IZeusClient, ZeusClient } from "@services/graphql-service/zeus-client";
import { ModelTypes } from '@zeus';
import { IOpportunityService } from 'app/private/pages/opportunity/services/opportunity-service';
import { IEventAggregator } from "aurelia";

export class AdvancedStepComponent implements IDialogCustomElementViewModel {
	private toStatusId: string;
	public opportunity: ModelTypes['deal'];

	constructor(
		@IDialogDom private dialogDom: DefaultDialogDom,
		@IEventAggregator readonly ea: IEventAggregator,
		@IOpportunityService private service: IOpportunityService,
	) {
		this.dialogDom.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
	}

	$dialog: IDialogController;
	$controller?: ICustomElementController<this>;

	async activate(model: { opportunity: ModelTypes['deal'], statusId: string }) {
		this.toStatusId = model.statusId;
		this.opportunity = model.opportunity;
	}

	private async handleAdvanceStep() {
		if (this.opportunity.statusId === this.toStatusId) {
			this.showMessage({ title: 'Info', message: 'Oportunidade já esta no status escolhido', type: ToastType.INFO });
			this.closeModal();

			return;
		}

		try {
			const response = await this.service.advanceStep({ dealId: this.opportunity.id, statusId: this.toStatusId });

			if (!response) throw new Error('Erro ao avançar etapa');

			this.closeModal();

			this.showMessage({ title: 'Sucesso', message: 'Oportunidade avançada com sucesso', type: ToastType.SUCCESS });
		} catch (error) {
			if (error instanceof Error) {
				this.showMessage({ title: 'Erro', message: error.message, type: ToastType.DANGER });
			} else {
				this.showMessage({ title: 'Erro', message: 'Erro inesperado, tente novamente', type: ToastType.DANGER });
			}
		}
	}

	private closeModal() {
		this.$dialog.cancel();
	}

	private showMessage({ title, message, type }: { title: string, message: string, type: ToastType }) {
		ToastComponent.show({
			event: this.ea,
			payload: {
				title: title,
				message,
				duration: 5000,
				type: type
			}
		});
	}

}