<import from="@components/promise-bind/pending/pending-component" />
<import from="@components/spinner-button/spinner-button-component" />

<div id="modal-publish" data-modal-backdrop="static" aria-hidden="true" tabindex="-1"
  class="fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative w-full max-w-lg max-h-full">

    <!-- begin::modal-content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

      <!-- begin::modal-header -->
      <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Agende ou Publique Agora</h3>

        <button type="button" click.trigger="hideModal()"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- end::modal-header -->

      <!-- begin::modal-body -->
      <div class="flex flex-col gap-4 p-6">

        <div>
          <label for="integration" class="form-label">Rede social</label>
          <select id="integration" value.bind="socialMedia" class="form-input">
            <option model.bind="null">Selecione</option>
            <option repeat.for="item of socialMediaData" model.bind="item.value">${item.text}</option>
          </select>
        </div>

        <template if.bind="socialMedia">

          <!-- begin::user-app-integration -->
          <template promise.bind="getUserAppIntegrations(socialMedia)">
            <template pending>
              <pending-component></pending-component>
            </template>

            <template then.from-view="data">

              <template if.bind="data.length === 0">
                <div class="flex flex-col justify-center items-center">
                  <span class="text-sm dark:text-gray-300">Você não possui integração para rede social selecionada
                  </span>
                  <a href="/social-media" click.trigger="hideModal()"
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500">ir para integração</a>
                </div>
              </template>

              <!-- begin::else -->
              <template else>

                <!-- begin::action -->
                <div>
                  <label for="action" class="form-label">Ação</label>
                  <select id="action" value.bind="selectedAction" class="form-input">
                    <option model.bind="null">Selecione</option>
                    <option repeat.for="item of actionsFromPublishData" model.bind="item.value">${item.text}</option>
                  </select>
                </div>
                <!-- end::action -->

                <!-- begin::scheduleDate -->
                <div if.bind="selectedAction == 'schedule' ">
                  <label for="scheduleDate" class="form-label">Data</label>
                  <input type="datetime-local" name="scheduleDate" id="scheduleDate" value.bind="scheduleDate"
                    min="${minDate}" class="form-input">
                </div>
                <!-- end::scheduleDate -->

                <template switch.bind="socialMedia">

                  <!-- begin::facebook -->
                  <template case="facebook">
                    <div>
                      <label for="integration" class="form-label">Canal</label>
                      <select id="integration" value.bind="integrationId" class="form-input">
                        <option model.bind="null">Selecione</option>
                        <option repeat.for="item of data" model.bind="item.id">${item.alias}</option>
                      </select>
                    </div>

                    <div if.bind="integrationId">
                      <template promise.bind="getFacebookPages(integrationId)">
                        <template pending>
                          <pending-component></pending-component>
                        </template>

                        <template then.from-view="pages">
                          <div>
                            <label for="facebookPageId" class="form-label">Página</label>
                            <select id="facebookPageId" value.bind="facebookPageId" class="form-input">
                              <option model.bind="null">Selecione</option>
                              <option repeat.for="item of pages.pages" model.bind="item.id">${item.name}</option>
                            </select>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                  <!-- end::facebook -->

                  <!-- begin::instagram -->
                  <template case="instagram">
                    <div>
                      <label for="subType" class="form-label">Tipo</label>
                      <select id="subType" value.bind="subType" class="form-input">
                        <option model.bind="null">Selecione</option>
                        <option repeat.for="item of subTypeData" model.bind="item.value">${item.text}</option>
                      </select>
                    </div>

                    <div>
                      <label for="integration" class="form-label">Canal</label>
                      <select id="integration" value.bind="integrationId" class="form-input">
                        <option model.bind="null">Selecione</option>
                        <option repeat.for="item of data" model.bind="item.id">${item.alias}</option>
                      </select>
                    </div>

                    <div if.bind="integrationId">
                      <template promise.bind="getInstagramPages(integrationId)">
                        <template pending>
                          <pending-component></pending-component>
                        </template>

                        <template then.from-view="pages">
                          <div>
                            <label for="instagramBusinessId" class="form-label">Página</label>
                            <select id="instagramBusinessId" value.bind="instagramBusinessId" class="form-input">
                              <option model.bind="null">Selecione</option>
                              <option repeat.for="item of pages.accounts.filter(x => x.instagramBusinessAccountId)"
                                model.bind="item.instagramBusinessAccountId">${item.name}</option>
                            </select>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                  <!-- end::instagram -->

                  <!-- begin::linkedin -->
                  <template case="linkedin">
                    <div>
                      <label for="integration" class="form-label">Canal</label>
                      <select id="integration" value.bind="integrationId" class="form-input">
                        <option model.bind="null">Selecione</option>
                        <option repeat.for="item of data" model.bind="item.id">${item.alias}</option>
                      </select>
                    </div>
                  </template>
                  <!-- end::linkedin -->

                </template>

              </template>
              <!-- end::else -->

            </template>
          </template>
          <!-- end::user-app-integration -->

        </template>



      </div>
      <!-- end::modal-body -->

      <!-- begin::modal-footer -->
      <div class="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button click.trigger="hideModal()" type="button" class="btn-neutral">Cancelar</button>
        <spinner-button-component label="Publicar" disabled.bind="!projectId"
          trigger-function.bind="() => handlePublish()"></spinner-button-component>
      </div>
      <!-- end::modal-footer -->

    </div>
    <!-- end::modal-content -->
  </div>
</div>