/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';
export const HOST = "https://rcspllpedqcxwwixkqbz.hasura.sa-east-1.nhost.run/v1/graphql"


export const HEADERS = {}
import { createClient, type Sink } from 'graphql-ws'; // keep

export const apiSubscription = (options: chainOptions) => {
	const client = createClient({
		url: String(options[0]),
		connectionParams: Object.fromEntries(new Headers(options[1]?.headers).entries()),
	});

	const ws = new Proxy(
		{
			close: () => client.dispose(),
		} as WebSocket,
		{
			get(target, key) {
				if (key === 'close') return target.close;
				throw new Error(`Unimplemented property '${String(key)}', only 'close()' is available.`);
			},
		},
	);

	return (query: string) => {
		let onMessage: ((event: any) => void) | undefined;
		let onError: Sink['error'] | undefined;
		let onClose: Sink['complete'] | undefined;

		client.subscribe(
			{ query },
			{
				next({ data }) {
					onMessage && onMessage(data);
				},
				error(error) {
					onError && onError(error);
				},
				complete() {
					onClose && onClose();
				},
			},
		);

		return {
			ws,
			on(listener: typeof onMessage) {
				onMessage = listener;
			},
			error(listener: typeof onError) {
				onError = listener;
			},
			open(listener: (socket: unknown) => void) {
				client.on('opened', listener);
			},
			off(listener: typeof onClose) {
				onClose = listener;
			},
		};
	};
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
	if (!response.ok) {
		return new Promise((_, reject) => {
			response
				.text()
				.then((text) => {
					try {
						reject(JSON.parse(text));
					} catch (err) {
						reject(text);
					}
				})
				.catch(reject);
		});
	}
	return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
	(options: fetchOptions) =>
		(query: string, variables: Record<string, unknown> = {}) => {
			const fetchOptions = options[1] || {};
			if (fetchOptions.method && fetchOptions.method === 'GET') {
				return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
					.then(handleFetchResponse)
					.then((response: GraphQLResponse) => {
						if (response.errors) {
							throw new GraphQLError(response);
						}
						return response.data;
					});
			}
			return fetch(`${options[0]}`, {
				body: JSON.stringify({ query, variables }),
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				...fetchOptions,
			})
				.then(handleFetchResponse)
				.then((response: GraphQLResponse) => {
					if (response.errors) {
						throw new GraphQLError(response);
					}
					return response.data;
				});
		};

export const InternalsBuildQuery = ({
	ops,
	props,
	returns,
	options,
	scalars,
}: {
	props: AllTypesPropsType;
	returns: ReturnTypesType;
	ops: Operations;
	options?: OperationOptions;
	scalars?: ScalarDefinition;
}) => {
	const ibb = (
		k: string,
		o: InputValueType | VType,
		p = '',
		root = true,
		vars: Array<{ name: string; graphQLType: string }> = [],
	): string => {
		const keyForPath = purifyGraphQLKey(k);
		const newPath = [p, keyForPath].join(SEPARATOR);
		if (!o) {
			return '';
		}
		if (typeof o === 'boolean' || typeof o === 'number') {
			return k;
		}
		if (typeof o === 'string') {
			return `${k} ${o}`;
		}
		if (Array.isArray(o)) {
			const args = InternalArgsBuilt({
				props,
				returns,
				ops,
				scalars,
				vars,
			})(o[0], newPath);
			return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
		}
		if (k === '__alias') {
			return Object.entries(o)
				.map(([alias, objectUnderAlias]) => {
					if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
						throw new Error(
							'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
						);
					}
					const operationName = Object.keys(objectUnderAlias)[0];
					const operation = objectUnderAlias[operationName];
					return ibb(`${alias}:${operationName}`, operation, p, false, vars);
				})
				.join('\n');
		}
		const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
		const keyForDirectives = o.__directives ?? '';
		const query = `{${Object.entries(o)
			.filter(([k]) => k !== '__directives')
			.map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
			.join('\n')}}`;
		if (!root) {
			return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
		}
		const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
		return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
	};
	return ibb;
};

export const Thunder =
	(fn: FetchFunction) =>
		<O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
			operation: O,
			graphqlOptions?: ThunderGraphQLOptions<SCLR>,
		) =>
			<Z extends ValueTypes[R]>(
				o: (Z & ValueTypes[R]) | ValueTypes[R],
				ops?: OperationOptions & { variables?: Record<string, unknown> },
			) =>
				fn(
					Zeus(operation, o, {
						operationOptions: ops,
						scalars: graphqlOptions?.scalars,
					}),
					ops?.variables,
				).then((data) => {
					if (graphqlOptions?.scalars) {
						return decodeScalarsInResponse({
							response: data,
							initialOp: operation,
							initialZeusQuery: o as VType,
							returns: ReturnTypes,
							scalars: graphqlOptions.scalars,
							ops: Ops,
						});
					}
					return data;
				}) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
	(fn: SubscriptionFunction) =>
		<O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
			operation: O,
			graphqlOptions?: ThunderGraphQLOptions<SCLR>,
		) =>
			<Z extends ValueTypes[R]>(
				o: (Z & ValueTypes[R]) | ValueTypes[R],
				ops?: OperationOptions & { variables?: ExtractVariables<Z> },
			) => {
				const returnedFunction = fn(
					Zeus(operation, o, {
						operationOptions: ops,
						scalars: graphqlOptions?.scalars,
					}),
				) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
				if (returnedFunction?.on && graphqlOptions?.scalars) {
					const wrapped = returnedFunction.on;
					returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
						wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
							if (graphqlOptions?.scalars) {
								return fnToCall(
									decodeScalarsInResponse({
										response: data,
										initialOp: operation,
										initialZeusQuery: o as VType,
										returns: ReturnTypes,
										scalars: graphqlOptions.scalars,
										ops: Ops,
									}),
								);
							}
							return fnToCall(data);
						});
				}
				return returnedFunction;
			};

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
	Z extends ValueTypes[R],
	O extends keyof typeof Ops,
	R extends keyof ValueTypes = GenericOperation<O>,
>(
	operation: O,
	o: Z,
	ops?: {
		operationOptions?: OperationOptions;
		scalars?: ScalarDefinition;
	},
) =>
	InternalsBuildQuery({
		props: AllTypesProps,
		returns: ReturnTypes,
		ops: Ops,
		options: ops?.operationOptions,
		scalars: ops?.scalars,
	})(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
	headers: {
		'Content-Type': 'application/json',
		...HEADERS,
	},
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
	response,
	scalars,
	returns,
	ops,
	initialZeusQuery,
	initialOp,
}: {
	ops: O;
	response: any;
	returns: ReturnTypesType;
	scalars?: Record<string, ScalarResolver | undefined>;
	initialOp: keyof O;
	initialZeusQuery: InputValueType | VType;
}) => {
	if (!scalars) {
		return response;
	}
	const builder = PrepareScalarPaths({
		ops,
		returns,
	});

	const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
	if (scalarPaths) {
		const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
		return r;
	}
	return response;
};

export const traverseResponse = ({
	resolvers,
	scalarPaths,
}: {
	scalarPaths: { [x: string]: `scalar.${string}` };
	resolvers: {
		[x: string]: ScalarResolver | undefined;
	};
}) => {
	const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
		if (Array.isArray(o)) {
			return o.map((eachO) => ibb(k, eachO, p));
		}
		if (o == null) {
			return o;
		}
		const scalarPathString = p.join(SEPARATOR);
		const currentScalarString = scalarPaths[scalarPathString];
		if (currentScalarString) {
			const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
			if (currentDecoder) {
				return currentDecoder(o);
			}
		}
		if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
			return o;
		}
		const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
		const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
			a[k] = v;
			return a;
		}, {});
		return objectFromEntries;
	};
	return ibb;
};

export type AllTypesPropsType = {
	[x: string]:
	| undefined
	| `scalar.${string}`
	| 'enum'
	| {
		[x: string]:
		| undefined
		| string
		| {
			[x: string]: string | undefined;
		};
	};
};

export type ReturnTypesType = {
	[x: string]:
	| {
		[x: string]: string | undefined;
	}
	| `scalar.${string}`
	| undefined;
};
export type InputValueType = {
	[x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
	| undefined
	| boolean
	| string
	| number
	| [any, undefined | boolean | InputValueType]
	| InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
	| PlainType
	| {
		[x: string]: ZeusArgsType;
	}
	| Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
	[x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
	operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
	data?: Record<string, any>;
	errors?: Array<{
		message: string;
	}>;
}
export class GraphQLError extends Error {
	constructor(public response: GraphQLResponse) {
		super('');
		console.error(response);
	}
	toString() {
		return 'GraphQL Response Error';
	}
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
	scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
	if (mappedParts.length === 0) {
		return;
	}
	const oKey = mappedParts[0];
	const returnP1 = returns[oKey];
	if (typeof returnP1 === 'object') {
		const returnP2 = returnP1[mappedParts[1]];
		if (returnP2) {
			return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
		}
		return undefined;
	}
	return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
	const ibb = (
		k: string,
		originalKey: string,
		o: InputValueType | VType,
		p: string[] = [],
		pOriginals: string[] = [],
		root = true,
	): { [x: string]: `scalar.${string}` } | undefined => {
		if (!o) {
			return;
		}
		if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
			const extractionArray = [...pOriginals, originalKey];
			const isScalar = ExtractScalar(extractionArray, returns);
			if (isScalar?.startsWith('scalar')) {
				const partOfTree = {
					[[...p, k].join(SEPARATOR)]: isScalar,
				};
				return partOfTree;
			}
			return {};
		}
		if (Array.isArray(o)) {
			return ibb(k, k, o[1], p, pOriginals, false);
		}
		if (k === '__alias') {
			return Object.entries(o)
				.map(([alias, objectUnderAlias]) => {
					if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
						throw new Error(
							'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
						);
					}
					const operationName = Object.keys(objectUnderAlias)[0];
					const operation = objectUnderAlias[operationName];
					return ibb(alias, operationName, operation, p, pOriginals, false);
				})
				.reduce((a, b) => ({
					...a,
					...b,
				}));
		}
		const keyName = root ? ops[k] : k;
		return Object.entries(o)
			.filter(([k]) => k !== '__directives')
			.map(([k, v]) => {
				// Inline fragments shouldn't be added to the path as they aren't a field
				const isInlineFragment = originalKey.match(/^...\s*on/) != null;
				return ibb(
					k,
					k,
					v,
					isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
					isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
					false,
				);
			})
			.reduce((a, b) => ({
				...a,
				...b,
			}));
	};
	return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
	const [isArg, isField] = p.split('<>');
	if (isField) {
		return {
			v: isField,
			__type: 'field',
		} as const;
	}
	return {
		v: isArg,
		__type: 'arg',
	} as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
	const ResolvePropsType = (mappedParts: Part[]) => {
		const oKey = ops[mappedParts[0].v];
		const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
		if (propsP1 === 'enum' && mappedParts.length === 1) {
			return 'enum';
		}
		if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
			return propsP1;
		}
		if (typeof propsP1 === 'object') {
			if (mappedParts.length < 2) {
				return 'not';
			}
			const propsP2 = propsP1[mappedParts[1].v];
			if (typeof propsP2 === 'string') {
				return rpp(
					`${propsP2}${SEPARATOR}${mappedParts
						.slice(2)
						.map((mp) => mp.v)
						.join(SEPARATOR)}`,
				);
			}
			if (typeof propsP2 === 'object') {
				if (mappedParts.length < 3) {
					return 'not';
				}
				const propsP3 = propsP2[mappedParts[2].v];
				if (propsP3 && mappedParts[2].__type === 'arg') {
					return rpp(
						`${propsP3}${SEPARATOR}${mappedParts
							.slice(3)
							.map((mp) => mp.v)
							.join(SEPARATOR)}`,
					);
				}
			}
		}
	};
	const ResolveReturnType = (mappedParts: Part[]) => {
		if (mappedParts.length === 0) {
			return 'not';
		}
		const oKey = ops[mappedParts[0].v];
		const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
		if (typeof returnP1 === 'object') {
			if (mappedParts.length < 2) return 'not';
			const returnP2 = returnP1[mappedParts[1].v];
			if (returnP2) {
				return rpp(
					`${returnP2}${SEPARATOR}${mappedParts
						.slice(2)
						.map((mp) => mp.v)
						.join(SEPARATOR)}`,
				);
			}
		}
	};
	const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
		const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
		const mappedParts = parts.map(mapPart);
		const propsP1 = ResolvePropsType(mappedParts);
		if (propsP1) {
			return propsP1;
		}
		const returnP1 = ResolveReturnType(mappedParts);
		if (returnP1) {
			return returnP1;
		}
		return 'not';
	};
	return rpp;
};

export const InternalArgsBuilt = ({
	props,
	ops,
	returns,
	scalars,
	vars,
}: {
	props: AllTypesPropsType;
	returns: ReturnTypesType;
	ops: Operations;
	scalars?: ScalarDefinition;
	vars: Array<{ name: string; graphQLType: string }>;
}) => {
	const arb = (a: ZeusArgsType, p = '', root = true): string => {
		if (typeof a === 'string') {
			if (a.startsWith(START_VAR_NAME)) {
				const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
				const v = vars.find((v) => v.name === varName);
				if (!v) {
					vars.push({
						name: varName,
						graphQLType,
					});
				} else {
					if (v.graphQLType !== graphQLType) {
						throw new Error(
							`Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
						);
					}
				}
				return varName;
			}
		}
		const checkType = ResolveFromPath(props, returns, ops)(p);
		if (checkType.startsWith('scalar.')) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [_, ...splittedScalar] = checkType.split('.');
			const scalarKey = splittedScalar.join('.');
			return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
		}
		if (Array.isArray(a)) {
			return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
		}
		if (typeof a === 'string') {
			if (checkType === 'enum') {
				return a;
			}
			return `${JSON.stringify(a)}`;
		}
		if (typeof a === 'object') {
			if (a === null) {
				return `null`;
			}
			const returnedObjectString = Object.entries(a)
				.filter(([, v]) => typeof v !== 'undefined')
				.map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
				.join(',\n');
			if (!root) {
				return `{${returnedObjectString}}`;
			}
			return returnedObjectString;
		}
		return `${a}`;
	};
	return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
	type: T,
	field: Z,
	fn: (
		args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
		source: any,
	) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
	T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
	N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
	__typename?: boolean;
	__directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
	__alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
	[P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
	? T extends keyof SCLR
	? SCLR[T]['decode'] extends (s: unknown) => unknown
	? ReturnType<SCLR[T]['decode']>
	: unknown
	: unknown
	: S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
	? InputType<R, U, SCLR>[]
	: InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
	| ZEUS_INTERFACES
	| ZEUS_UNIONS
	? {
		[P in keyof SRC]: SRC[P] extends '__union' & infer R
		? P extends keyof DST
		? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
		: IsArray<R, '__typename' extends keyof DST ? { __typename: true } : Record<string, never>, SCLR>
		: never;
	}[keyof SRC] & {
		[P in keyof Omit<
			Pick<
				SRC,
				{
					[P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
				}[keyof DST]
			>,
			'__typename'
		>]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
	}
	: {
		[P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
		? IsScalar<SRC[P], SCLR>
		: IsArray<SRC[P], DST[P], SCLR>;
	};

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
	? IsInterfaced<SRC, DST, SCLR>
	: never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
	? {
		[P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
	} & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
	: MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
	ws: WebSocket;
	on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
	off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
	error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
	open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
	GraphQLTypes[NAME],
	SELECTOR,
	SCLR
>;

export type ScalarResolver = {
	encode?: (s: unknown) => string;
	decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <Z extends V>(
	t: Z & {
		[P in keyof Z]: P extends keyof V ? Z[P] : never;
	},
) => Z;

type BuiltInVariableTypes = {
	['String']: string;
	['Int']: number;
	['Float']: number;
	['ID']: unknown;
	['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
	? R1 extends VR<infer R2>
	? R2 extends VR<infer R3>
	? R3 extends VR<infer R4>
	? R4 extends VR<infer R5>
	? R5
	: R4
	: R3
	: R2
	: R1
	: T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
	? Array<DecomposeType<R, Type>> | undefined
	: T extends `${infer R}!`
	? NonNullable<DecomposeType<R, Type>>
	: Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
	? ZEUS_VARIABLES[T]
	: T extends keyof BuiltInVariableTypes
	? BuiltInVariableTypes[T]
	: any;

export type GetVariableType<T extends string> = DecomposeType<
	T,
	ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
	[K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
	[P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
	' __zeus_name': Name;
	' __zeus_type': T;
};

export type ExtractVariablesDeep<Query> = Query extends Variable<infer VType, infer VName>
	? { [key in VName]: GetVariableType<VType> }
	: Query extends string | number | boolean | Array<string | number | boolean>
	? // eslint-disable-next-line @typescript-eslint/ban-types
	{}
	: UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariablesDeep<Query[K]>> }[keyof Query]>;

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
	? { [key in VName]: GetVariableType<VType> }
	: Query extends [infer Inputs, infer Outputs]
	? ExtractVariablesDeep<Inputs> & ExtractVariables<Outputs>
	: Query extends string | number | boolean | Array<string | number | boolean>
	? // eslint-disable-next-line @typescript-eslint/ban-types
	{}
	: UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
	return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	bigint?: ScalarResolver;
	bytea?: ScalarResolver;
	citext?: ScalarResolver;
	date?: ScalarResolver;
	json?: ScalarResolver;
	jsonb?: ScalarResolver;
	numeric?: ScalarResolver;
	timestamp?: ScalarResolver;
	timestamptz?: ScalarResolver;
	uuid?: ScalarResolver;
}
type ZEUS_UNIONS = never

export type ValueTypes = {
	["AIImageResult"]: AliasType<{
		id?: boolean | `@${string}`,
		url?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "AppIntegration" */
	["AppIntegration"]: AliasType<{
		configParams?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "AppIntegration" */
	["AppIntegration_aggregate"]: AliasType<{
		aggregate?: ValueTypes["AppIntegration_aggregate_fields"],
		nodes?: ValueTypes["AppIntegration"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "AppIntegration" */
	["AppIntegration_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["AppIntegration_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["AppIntegration_max_fields"],
		min?: ValueTypes["AppIntegration_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["AppIntegration_append_input"]: {
		configParams?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "AppIntegration". All fields are combined with a logical 'AND'. */
	["AppIntegration_bool_exp"]: {
		_and?: Array<ValueTypes["AppIntegration_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["AppIntegration_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["AppIntegration_bool_exp"]> | undefined | null | Variable<any, string>,
		configParams?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		icon?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "AppIntegration" */
	["AppIntegration_constraint"]: AppIntegration_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["AppIntegration_delete_at_path_input"]: {
		configParams?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["AppIntegration_delete_elem_input"]: {
		configParams?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["AppIntegration_delete_key_input"]: {
		configParams?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "AppIntegration" */
	["AppIntegration_insert_input"]: {
		configParams?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["AppIntegration_max_fields"]: AliasType<{
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["AppIntegration_min_fields"]: AliasType<{
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "AppIntegration" */
	["AppIntegration_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["AppIntegration"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "AppIntegration" */
	["AppIntegration_on_conflict"]: {
		constraint: ValueTypes["AppIntegration_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["AppIntegration_update_column"]> | Variable<any, string>,
		where?: ValueTypes["AppIntegration_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "AppIntegration". */
	["AppIntegration_order_by"]: {
		configParams?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		icon?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: AppIntegration */
	["AppIntegration_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["AppIntegration_prepend_input"]: {
		configParams?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "AppIntegration" */
	["AppIntegration_select_column"]: AppIntegration_select_column;
	/** input type for updating data in table "AppIntegration" */
	["AppIntegration_set_input"]: {
		configParams?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "AppIntegration" */
	["AppIntegration_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["AppIntegration_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["AppIntegration_stream_cursor_value_input"]: {
		configParams?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "AppIntegration" */
	["AppIntegration_update_column"]: AppIntegration_update_column;
	["AppIntegration_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["AppIntegration_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["AppIntegration_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["AppIntegration_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["AppIntegration_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["AppIntegration_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["AppIntegration_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["AppIntegration_bool_exp"] | Variable<any, string>
	};
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
	["Boolean_comparison_exp"]: {
		_eq?: boolean | undefined | null | Variable<any, string>,
		_gt?: boolean | undefined | null | Variable<any, string>,
		_gte?: boolean | undefined | null | Variable<any, string>,
		_in?: Array<boolean> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: boolean | undefined | null | Variable<any, string>,
		_lte?: boolean | undefined | null | Variable<any, string>,
		_neq?: boolean | undefined | null | Variable<any, string>,
		_nin?: Array<boolean> | undefined | null | Variable<any, string>
	};
	["Category"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "Course" */
	["Course"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageUrl?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "CourseCategory" */
	["CourseCategory"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "CourseCategory" */
	["CourseCategory_aggregate"]: AliasType<{
		aggregate?: ValueTypes["CourseCategory_aggregate_fields"],
		nodes?: ValueTypes["CourseCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "CourseCategory" */
	["CourseCategory_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["CourseCategory_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["CourseCategory_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["CourseCategory_max_fields"],
		min?: ValueTypes["CourseCategory_min_fields"],
		stddev?: ValueTypes["CourseCategory_stddev_fields"],
		stddev_pop?: ValueTypes["CourseCategory_stddev_pop_fields"],
		stddev_samp?: ValueTypes["CourseCategory_stddev_samp_fields"],
		sum?: ValueTypes["CourseCategory_sum_fields"],
		var_pop?: ValueTypes["CourseCategory_var_pop_fields"],
		var_samp?: ValueTypes["CourseCategory_var_samp_fields"],
		variance?: ValueTypes["CourseCategory_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["CourseCategory_avg_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "CourseCategory". All fields are combined with a logical 'AND'. */
	["CourseCategory_bool_exp"]: {
		_and?: Array<ValueTypes["CourseCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["CourseCategory_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["CourseCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		id?: ValueTypes["bigint_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "CourseCategory" */
	["CourseCategory_constraint"]: CourseCategory_constraint;
	/** input type for incrementing numeric columns in table "CourseCategory" */
	["CourseCategory_inc_input"]: {
		id?: ValueTypes["bigint"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "CourseCategory" */
	["CourseCategory_insert_input"]: {
		id?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["CourseCategory_max_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["CourseCategory_min_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "CourseCategory" */
	["CourseCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["CourseCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "CourseCategory" */
	["CourseCategory_on_conflict"]: {
		constraint: ValueTypes["CourseCategory_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["CourseCategory_update_column"]> | Variable<any, string>,
		where?: ValueTypes["CourseCategory_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "CourseCategory". */
	["CourseCategory_order_by"]: {
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: CourseCategory */
	["CourseCategory_pk_columns_input"]: {
		id: ValueTypes["bigint"] | Variable<any, string>
	};
	/** select columns of table "CourseCategory" */
	["CourseCategory_select_column"]: CourseCategory_select_column;
	/** input type for updating data in table "CourseCategory" */
	["CourseCategory_set_input"]: {
		id?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["CourseCategory_stddev_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["CourseCategory_stddev_pop_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["CourseCategory_stddev_samp_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "CourseCategory" */
	["CourseCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["CourseCategory_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["CourseCategory_stream_cursor_value_input"]: {
		id?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["CourseCategory_sum_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "CourseCategory" */
	["CourseCategory_update_column"]: CourseCategory_update_column;
	["CourseCategory_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["CourseCategory_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["CourseCategory_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["CourseCategory_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["CourseCategory_var_pop_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["CourseCategory_var_samp_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["CourseCategory_variance_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "Course" */
	["Course_aggregate"]: AliasType<{
		aggregate?: ValueTypes["Course_aggregate_fields"],
		nodes?: ValueTypes["Course"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "Course" */
	["Course_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["Course_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["Course_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["Course_max_fields"],
		min?: ValueTypes["Course_min_fields"],
		stddev?: ValueTypes["Course_stddev_fields"],
		stddev_pop?: ValueTypes["Course_stddev_pop_fields"],
		stddev_samp?: ValueTypes["Course_stddev_samp_fields"],
		sum?: ValueTypes["Course_sum_fields"],
		var_pop?: ValueTypes["Course_var_pop_fields"],
		var_samp?: ValueTypes["Course_var_samp_fields"],
		variance?: ValueTypes["Course_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["Course_avg_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "Course". All fields are combined with a logical 'AND'. */
	["Course_bool_exp"]: {
		_and?: Array<ValueTypes["Course_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["Course_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["Course_bool_exp"]> | undefined | null | Variable<any, string>,
		courseCategoryId?: ValueTypes["bigint_comparison_exp"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		imageUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "Course" */
	["Course_constraint"]: Course_constraint;
	/** input type for incrementing numeric columns in table "Course" */
	["Course_inc_input"]: {
		courseCategoryId?: ValueTypes["bigint"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "Course" */
	["Course_insert_input"]: {
		courseCategoryId?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageUrl?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["Course_max_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageUrl?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["Course_min_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageUrl?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "Course" */
	["Course_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["Course"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "Course" */
	["Course_on_conflict"]: {
		constraint: ValueTypes["Course_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["Course_update_column"]> | Variable<any, string>,
		where?: ValueTypes["Course_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "Course". */
	["Course_order_by"]: {
		courseCategoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: Course */
	["Course_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "Course" */
	["Course_select_column"]: Course_select_column;
	/** input type for updating data in table "Course" */
	["Course_set_input"]: {
		courseCategoryId?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageUrl?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["Course_stddev_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["Course_stddev_pop_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["Course_stddev_samp_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "Course" */
	["Course_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["Course_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["Course_stream_cursor_value_input"]: {
		courseCategoryId?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageUrl?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["Course_sum_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "Course" */
	["Course_update_column"]: Course_update_column;
	["Course_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["Course_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["Course_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["Course_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["Course_var_pop_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["Course_var_samp_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["Course_variance_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["CreateAutomaticProjectOutput"]: AliasType<{
		projectId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["CreateImageUsingImagineOutput"]: AliasType<{
		errorMessage?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["ExportToWordpressInput"]: {
		category: number | Variable<any, string>,
		generatedTemplateById?: string | undefined | null | Variable<any, string>,
		integrationId: string | Variable<any, string>,
		postTimeType: string | Variable<any, string>,
		scheduledDate?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		tags?: Array<number> | undefined | null | Variable<any, string>
	};
	["ExportToWordpressOutput"]: AliasType<{
		url?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["FacebookAuthCallbackOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["FinishOnboardOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GenerateCalendarExOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GenerateFromTextTemplateInput"]: {
		locale: string | Variable<any, string>,
		selectedParameters?: Array<ValueTypes["StringKeyValuePair"]> | undefined | null | Variable<any, string>,
		templateId: string | Variable<any, string>,
		templateVariables?: Array<ValueTypes["StringKeyValuePair"]> | undefined | null | Variable<any, string>
	};
	["GenerateFromTextTemplateOutput"]: AliasType<{
		text?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GenerateImageFromPromptInput"]: {
		provider: string | Variable<any, string>,
		query: string | Variable<any, string>
	};
	["GenerateImageFromPromptOutput"]: AliasType<{
		images?: ValueTypes["AIImageResult"],
		__typename?: boolean | `@${string}`
	}>;
	["GenerateNextMonthCalendarInput"]: {
		campaignObjective: string | Variable<any, string>
	};
	["GenerateNextMonthCalendarOutput"]: AliasType<{
		created?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate"]: AliasType<{
		GeneratedPromptByTemplateImages?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplateImages_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage_aggregate"]],
		/** An object relationship */
		TextPromptTemplate?: ValueTypes["TextPromptTemplate"],
		active?: boolean | `@${string}`,
		alias?: boolean | `@${string}`,
		backgroundImageFileId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		command?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		/** An object relationship */
		displayFormat?: ValueTypes["displayFormats"],
		displayImageUrl?: boolean | `@${string}`,
		displayMapper?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		generatingDisplayStatus?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		/** An object relationship */
		pricingPlan?: ValueTypes["pricingPlan"],
		pricingPlanId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		result?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		selectedDisplayFormatId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate"]: AliasType<{
		aggregate?: ValueTypes["GeneratedPromptByTemplateImage_aggregate_fields"],
		nodes?: ValueTypes["GeneratedPromptByTemplateImage"],
		__typename?: boolean | `@${string}`
	}>;
	["GeneratedPromptByTemplateImage_aggregate_bool_exp"]: {
		count?: ValueTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["GeneratedPromptByTemplateImage_max_fields"],
		min?: ValueTypes["GeneratedPromptByTemplateImage_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["GeneratedPromptByTemplateImage_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["GeneratedPromptByTemplateImage_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_arr_rel_insert_input"]: {
		data: Array<ValueTypes["GeneratedPromptByTemplateImage_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["GeneratedPromptByTemplateImage_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "GeneratedPromptByTemplateImage". All fields are combined with a logical 'AND'. */
	["GeneratedPromptByTemplateImage_bool_exp"]: {
		GeneratedPromptByTemplateId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		_and?: Array<ValueTypes["GeneratedPromptByTemplateImage_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["GeneratedPromptByTemplateImage_bool_exp"]> | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_constraint"]: GeneratedPromptByTemplateImage_constraint;
	/** input type for inserting data into table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_insert_input"]: {
		GeneratedPromptByTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["GeneratedPromptByTemplateImage_max_fields"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_max_order_by"]: {
		GeneratedPromptByTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["GeneratedPromptByTemplateImage_min_fields"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_min_order_by"]: {
		GeneratedPromptByTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["GeneratedPromptByTemplateImage"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_on_conflict"]: {
		constraint: ValueTypes["GeneratedPromptByTemplateImage_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["GeneratedPromptByTemplateImage_update_column"]> | Variable<any, string>,
		where?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "GeneratedPromptByTemplateImage". */
	["GeneratedPromptByTemplateImage_order_by"]: {
		GeneratedPromptByTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: GeneratedPromptByTemplateImage */
	["GeneratedPromptByTemplateImage_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_select_column"]: GeneratedPromptByTemplateImage_select_column;
	/** input type for updating data in table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_set_input"]: {
		GeneratedPromptByTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["GeneratedPromptByTemplateImage_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["GeneratedPromptByTemplateImage_stream_cursor_value_input"]: {
		GeneratedPromptByTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_update_column"]: GeneratedPromptByTemplateImage_update_column;
	["GeneratedPromptByTemplateImage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["GeneratedPromptByTemplateImage_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | Variable<any, string>
	};
	/** aggregated selection of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate"]: AliasType<{
		aggregate?: ValueTypes["GeneratedPromptByTemplate_aggregate_fields"],
		nodes?: ValueTypes["GeneratedPromptByTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	["GeneratedPromptByTemplate_aggregate_bool_exp"]: {
		bool_and?: ValueTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"] | undefined | null | Variable<any, string>,
		bool_or?: ValueTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["GeneratedPromptByTemplate_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"]: {
		arguments: ValueTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"]: {
		arguments: ValueTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["GeneratedPromptByTemplate_max_fields"],
		min?: ValueTypes["GeneratedPromptByTemplate_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["GeneratedPromptByTemplate_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["GeneratedPromptByTemplate_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["GeneratedPromptByTemplate_append_input"]: {
		command?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		displayMapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_arr_rel_insert_input"]: {
		data: Array<ValueTypes["GeneratedPromptByTemplate_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["GeneratedPromptByTemplate_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "GeneratedPromptByTemplate". All fields are combined with a logical 'AND'. */
	["GeneratedPromptByTemplate_bool_exp"]: {
		GeneratedPromptByTemplateImages?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>,
		GeneratedPromptByTemplateImages_aggregate?: ValueTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		TextPromptTemplate?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		_and?: Array<ValueTypes["GeneratedPromptByTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["GeneratedPromptByTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		active?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		alias?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		backgroundImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		command?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		displayFormat?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>,
		displayImageUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		displayMapper?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		generatingDisplayStatus?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		pricingPlan?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>,
		pricingPlanId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		result?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		selectedDisplayFormatId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		status?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_constraint"]: GeneratedPromptByTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["GeneratedPromptByTemplate_delete_at_path_input"]: {
		command?: Array<string> | undefined | null | Variable<any, string>,
		displayMapper?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["GeneratedPromptByTemplate_delete_elem_input"]: {
		command?: number | undefined | null | Variable<any, string>,
		displayMapper?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["GeneratedPromptByTemplate_delete_key_input"]: {
		command?: string | undefined | null | Variable<any, string>,
		displayMapper?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_insert_input"]: {
		GeneratedPromptByTemplateImages?: ValueTypes["GeneratedPromptByTemplateImage_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		TextPromptTemplate?: ValueTypes["TextPromptTemplate_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		active?: boolean | undefined | null | Variable<any, string>,
		alias?: string | undefined | null | Variable<any, string>,
		backgroundImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		channel?: string | undefined | null | Variable<any, string>,
		command?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayFormat?: ValueTypes["displayFormats_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		displayImageUrl?: string | undefined | null | Variable<any, string>,
		displayMapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		generatingDisplayStatus?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		pricingPlan?: ValueTypes["pricingPlan_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		pricingPlanId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		result?: string | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		selectedDisplayFormatId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["GeneratedPromptByTemplate_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		backgroundImageFileId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		displayImageUrl?: boolean | `@${string}`,
		generatingDisplayStatus?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		pricingPlanId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		result?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		selectedDisplayFormatId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_max_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		backgroundImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayImageUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		generatingDisplayStatus?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		pricingPlanId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		result?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		selectedDisplayFormatId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		status?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["GeneratedPromptByTemplate_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		backgroundImageFileId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		displayImageUrl?: boolean | `@${string}`,
		generatingDisplayStatus?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		pricingPlanId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		result?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		selectedDisplayFormatId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_min_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		backgroundImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayImageUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		generatingDisplayStatus?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		pricingPlanId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		result?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		selectedDisplayFormatId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		status?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["GeneratedPromptByTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_on_conflict"]: {
		constraint: ValueTypes["GeneratedPromptByTemplate_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["GeneratedPromptByTemplate_update_column"]> | Variable<any, string>,
		where?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "GeneratedPromptByTemplate". */
	["GeneratedPromptByTemplate_order_by"]: {
		GeneratedPromptByTemplateImages_aggregate?: ValueTypes["GeneratedPromptByTemplateImage_aggregate_order_by"] | undefined | null | Variable<any, string>,
		TextPromptTemplate?: ValueTypes["TextPromptTemplate_order_by"] | undefined | null | Variable<any, string>,
		active?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		backgroundImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		command?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayFormat?: ValueTypes["displayFormats_order_by"] | undefined | null | Variable<any, string>,
		displayImageUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayMapper?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		generatingDisplayStatus?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		pricingPlan?: ValueTypes["pricingPlan_order_by"] | undefined | null | Variable<any, string>,
		pricingPlanId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		result?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		selectedDisplayFormatId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		status?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: GeneratedPromptByTemplate */
	["GeneratedPromptByTemplate_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["GeneratedPromptByTemplate_prepend_input"]: {
		command?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		displayMapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column"]: GeneratedPromptByTemplate_select_column;
	/** select "GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_set_input"]: {
		active?: boolean | undefined | null | Variable<any, string>,
		alias?: string | undefined | null | Variable<any, string>,
		backgroundImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		channel?: string | undefined | null | Variable<any, string>,
		command?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayImageUrl?: string | undefined | null | Variable<any, string>,
		displayMapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		generatingDisplayStatus?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		pricingPlanId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		result?: string | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		selectedDisplayFormatId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["GeneratedPromptByTemplate_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["GeneratedPromptByTemplate_stream_cursor_value_input"]: {
		active?: boolean | undefined | null | Variable<any, string>,
		alias?: string | undefined | null | Variable<any, string>,
		backgroundImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		channel?: string | undefined | null | Variable<any, string>,
		command?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayImageUrl?: string | undefined | null | Variable<any, string>,
		displayMapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		generatingDisplayStatus?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		pricingPlanId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		result?: string | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		selectedDisplayFormatId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_update_column"]: GeneratedPromptByTemplate_update_column;
	["GeneratedPromptByTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["GeneratedPromptByTemplate_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["GeneratedPromptByTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["GeneratedPromptByTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["GeneratedPromptByTemplate_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["GeneratedPromptByTemplate_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["GeneratedPromptByTemplate_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | Variable<any, string>
	};
	["GetFacebookPagesOutput"]: AliasType<{
		pages?: ValueTypes["facebookPage"],
		__typename?: boolean | `@${string}`
	}>;
	["GetFacebookTokenOutput"]: AliasType<{
		loginUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetImageFromDisplayRendererOutput"]: AliasType<{
		image?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetInstagramAuthCallbackOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetInstagramPagesOutput"]: AliasType<{
		accounts?: ValueTypes["instagramPageAccount"],
		__typename?: boolean | `@${string}`
	}>;
	["GetInstagramTokenOutput"]: AliasType<{
		loginUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetPaymentUrlCommand"]: {
		email: string | Variable<any, string>,
		price_id: string | Variable<any, string>,
		user_id: string | Variable<any, string>
	};
	["GetPaymentUrlResponse"]: AliasType<{
		url?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetWordpressCategoriesInput"]: {
		integrationId: string | Variable<any, string>
	};
	["GetWordpressCategoriesOutput"]: AliasType<{
		categories?: ValueTypes["Category"],
		__typename?: boolean | `@${string}`
	}>;
	["GetWordpressTagsInput"]: {
		integrationId: string | Variable<any, string>
	};
	["GetWordpressTagsOutput"]: AliasType<{
		tags?: ValueTypes["Tag"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
	["Int_comparison_exp"]: {
		_eq?: number | undefined | null | Variable<any, string>,
		_gt?: number | undefined | null | Variable<any, string>,
		_gte?: number | undefined | null | Variable<any, string>,
		_in?: Array<number> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: number | undefined | null | Variable<any, string>,
		_lte?: number | undefined | null | Variable<any, string>,
		_neq?: number | undefined | null | Variable<any, string>,
		_nin?: Array<number> | undefined | null | Variable<any, string>
	};
	["LinkedInTokenCallbackOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["ManageSubscriptionOutput"]: AliasType<{
		url?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PexelsImageResult"]: AliasType<{
		alt?: boolean | `@${string}`,
		height?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		photographer?: boolean | `@${string}`,
		photographer_url?: boolean | `@${string}`,
		small?: boolean | `@${string}`,
		url?: boolean | `@${string}`,
		width?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostCarouselToInstagramOutput"]: AliasType<{
		error?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostFeedToInstagramOutput"]: AliasType<{
		error?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostStoryToInstagramOutput"]: AliasType<{
		error?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostToFacebookOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostToLinkedInOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PromptOutput"]: AliasType<{
		text?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PublishOrScheduleProjectOutput"]: AliasType<{
		errorMessage?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["RenderAndSaveDisplayImageOutput"]: AliasType<{
		image?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["RenderDisplayOutput"]: AliasType<{
		image?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SaveAndGeneratePolotnoImageOutput"]: AliasType<{
		message?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SearchImagesFromPexelsResponse"]: AliasType<{
		images?: ValueTypes["PexelsImageResult"],
		__typename?: boolean | `@${string}`
	}>;
	["SelectNewDisplayOutput"]: AliasType<{
		error?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestInput"]: {
		domain?: string | undefined | null | Variable<any, string>
	};
	["SerpStatTestInputData"]: AliasType<{
		ad_keywords?: boolean | `@${string}`,
		ads?: boolean | `@${string}`,
		ads_dynamic?: boolean | `@${string}`,
		domain?: boolean | `@${string}`,
		down_keywords?: boolean | `@${string}`,
		keywords?: boolean | `@${string}`,
		keywords_dynamic?: boolean | `@${string}`,
		new_keywords?: boolean | `@${string}`,
		out_keywords?: boolean | `@${string}`,
		prev_date?: boolean | `@${string}`,
		rised_keywords?: boolean | `@${string}`,
		traff?: boolean | `@${string}`,
		traff_dynamic?: boolean | `@${string}`,
		visible?: boolean | `@${string}`,
		visible_dynamic?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestInputResult"]: AliasType<{
		data?: ValueTypes["SerpStatTestInputData"],
		summary_info?: ValueTypes["SerpStatTestInputSummaryInfo"],
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestInputSummaryInfo"]: AliasType<{
		left_lines?: boolean | `@${string}`,
		page?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestOutput"]: AliasType<{
		data?: ValueTypes["SerpStatTestOutputData"],
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestOutputData"]: AliasType<{
		id?: boolean | `@${string}`,
		result?: ValueTypes["SerpStatTestInputResult"],
		__typename?: boolean | `@${string}`
	}>;
	["StringKeyValuePair"]: {
		key: string | Variable<any, string>,
		value: string | Variable<any, string>
	};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
	["String_array_comparison_exp"]: {
		/** is the array contained in the given array value */
		_contained_in?: Array<string> | undefined | null | Variable<any, string>,
		/** does the array contain the given value */
		_contains?: Array<string> | undefined | null | Variable<any, string>,
		_eq?: Array<string> | undefined | null | Variable<any, string>,
		_gt?: Array<string> | undefined | null | Variable<any, string>,
		_gte?: Array<string> | undefined | null | Variable<any, string>,
		_in?: Array<Array<string> | undefined | null> | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: Array<string> | undefined | null | Variable<any, string>,
		_lte?: Array<string> | undefined | null | Variable<any, string>,
		_neq?: Array<string> | undefined | null | Variable<any, string>,
		_nin?: Array<Array<string> | undefined | null> | Variable<any, string>
	};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
	["String_comparison_exp"]: {
		_eq?: string | undefined | null | Variable<any, string>,
		_gt?: string | undefined | null | Variable<any, string>,
		_gte?: string | undefined | null | Variable<any, string>,
		/** does the column match the given case-insensitive pattern */
		_ilike?: string | undefined | null | Variable<any, string>,
		_in?: Array<string> | undefined | null | Variable<any, string>,
		/** does the column match the given POSIX regular expression, case insensitive */
		_iregex?: string | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		/** does the column match the given pattern */
		_like?: string | undefined | null | Variable<any, string>,
		_lt?: string | undefined | null | Variable<any, string>,
		_lte?: string | undefined | null | Variable<any, string>,
		_neq?: string | undefined | null | Variable<any, string>,
		/** does the column NOT match the given case-insensitive pattern */
		_nilike?: string | undefined | null | Variable<any, string>,
		_nin?: Array<string> | undefined | null | Variable<any, string>,
		/** does the column NOT match the given POSIX regular expression, case insensitive */
		_niregex?: string | undefined | null | Variable<any, string>,
		/** does the column NOT match the given pattern */
		_nlike?: string | undefined | null | Variable<any, string>,
		/** does the column NOT match the given POSIX regular expression, case sensitive */
		_nregex?: string | undefined | null | Variable<any, string>,
		/** does the column NOT match the given SQL regular expression */
		_nsimilar?: string | undefined | null | Variable<any, string>,
		/** does the column match the given POSIX regular expression, case sensitive */
		_regex?: string | undefined | null | Variable<any, string>,
		/** does the column match the given SQL regular expression */
		_similar?: string | undefined | null | Variable<any, string>
	};
	["Tag"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "TextGeneratorOption" */
	["TextGeneratorOption"]: AliasType<{
		Label?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		TextGeneratorOptionEntries?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOptionEntries_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry_aggregate"]],
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		popover_tooltip?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry"]: AliasType<{
		Label?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		/** An object relationship */
		TextGeneratorOption?: ValueTypes["TextGeneratorOption"],
		TextGeneratorOptionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate"]: AliasType<{
		aggregate?: ValueTypes["TextGeneratorOptionEntry_aggregate_fields"],
		nodes?: ValueTypes["TextGeneratorOptionEntry"],
		__typename?: boolean | `@${string}`
	}>;
	["TextGeneratorOptionEntry_aggregate_bool_exp"]: {
		count?: ValueTypes["TextGeneratorOptionEntry_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["TextGeneratorOptionEntry_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["TextGeneratorOptionEntry_max_fields"],
		min?: ValueTypes["TextGeneratorOptionEntry_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["TextGeneratorOptionEntry_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["TextGeneratorOptionEntry_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOptionEntry_append_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_arr_rel_insert_input"]: {
		data: Array<ValueTypes["TextGeneratorOptionEntry_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["TextGeneratorOptionEntry_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "TextGeneratorOptionEntry". All fields are combined with a logical 'AND'. */
	["TextGeneratorOptionEntry_bool_exp"]: {
		Label?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		TextGeneratorOption?: ValueTypes["TextGeneratorOption_bool_exp"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		_and?: Array<ValueTypes["TextGeneratorOptionEntry_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["TextGeneratorOptionEntry_bool_exp"]> | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_constraint"]: TextGeneratorOptionEntry_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextGeneratorOptionEntry_delete_at_path_input"]: {
		Label?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextGeneratorOptionEntry_delete_elem_input"]: {
		Label?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextGeneratorOptionEntry_delete_key_input"]: {
		Label?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_insert_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		TextGeneratorOption?: ValueTypes["TextGeneratorOption_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["TextGeneratorOptionEntry_max_fields"]: AliasType<{
		TextGeneratorOptionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_max_order_by"]: {
		TextGeneratorOptionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["TextGeneratorOptionEntry_min_fields"]: AliasType<{
		TextGeneratorOptionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_min_order_by"]: {
		TextGeneratorOptionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["TextGeneratorOptionEntry"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_on_conflict"]: {
		constraint: ValueTypes["TextGeneratorOptionEntry_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["TextGeneratorOptionEntry_update_column"]> | Variable<any, string>,
		where?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "TextGeneratorOptionEntry". */
	["TextGeneratorOptionEntry_order_by"]: {
		Label?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		TextGeneratorOption?: ValueTypes["TextGeneratorOption_order_by"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: TextGeneratorOptionEntry */
	["TextGeneratorOptionEntry_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOptionEntry_prepend_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_select_column"]: TextGeneratorOptionEntry_select_column;
	/** input type for updating data in table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_set_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["TextGeneratorOptionEntry_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["TextGeneratorOptionEntry_stream_cursor_value_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_update_column"]: TextGeneratorOptionEntry_update_column;
	["TextGeneratorOptionEntry_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["TextGeneratorOptionEntry_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["TextGeneratorOptionEntry_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["TextGeneratorOptionEntry_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["TextGeneratorOptionEntry_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["TextGeneratorOptionEntry_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["TextGeneratorOptionEntry_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | Variable<any, string>
	};
	/** aggregated selection of "TextGeneratorOption" */
	["TextGeneratorOption_aggregate"]: AliasType<{
		aggregate?: ValueTypes["TextGeneratorOption_aggregate_fields"],
		nodes?: ValueTypes["TextGeneratorOption"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "TextGeneratorOption" */
	["TextGeneratorOption_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["TextGeneratorOption_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["TextGeneratorOption_max_fields"],
		min?: ValueTypes["TextGeneratorOption_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOption_append_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		popover_tooltip?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "TextGeneratorOption". All fields are combined with a logical 'AND'. */
	["TextGeneratorOption_bool_exp"]: {
		Label?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionEntries?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionEntries_aggregate?: ValueTypes["TextGeneratorOptionEntry_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		_and?: Array<ValueTypes["TextGeneratorOption_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["TextGeneratorOption_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["TextGeneratorOption_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		popover_tooltip?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "TextGeneratorOption" */
	["TextGeneratorOption_constraint"]: TextGeneratorOption_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextGeneratorOption_delete_at_path_input"]: {
		Label?: Array<string> | undefined | null | Variable<any, string>,
		popover_tooltip?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextGeneratorOption_delete_elem_input"]: {
		Label?: number | undefined | null | Variable<any, string>,
		popover_tooltip?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextGeneratorOption_delete_key_input"]: {
		Label?: string | undefined | null | Variable<any, string>,
		popover_tooltip?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "TextGeneratorOption" */
	["TextGeneratorOption_insert_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionEntries?: ValueTypes["TextGeneratorOptionEntry_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		popover_tooltip?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["TextGeneratorOption_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["TextGeneratorOption_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "TextGeneratorOption" */
	["TextGeneratorOption_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["TextGeneratorOption"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "TextGeneratorOption" */
	["TextGeneratorOption_obj_rel_insert_input"]: {
		data: ValueTypes["TextGeneratorOption_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["TextGeneratorOption_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "TextGeneratorOption" */
	["TextGeneratorOption_on_conflict"]: {
		constraint: ValueTypes["TextGeneratorOption_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["TextGeneratorOption_update_column"]> | Variable<any, string>,
		where?: ValueTypes["TextGeneratorOption_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "TextGeneratorOption". */
	["TextGeneratorOption_order_by"]: {
		Label?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		TextGeneratorOptionEntries_aggregate?: ValueTypes["TextGeneratorOptionEntry_aggregate_order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		popover_tooltip?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: TextGeneratorOption */
	["TextGeneratorOption_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOption_prepend_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		popover_tooltip?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "TextGeneratorOption" */
	["TextGeneratorOption_select_column"]: TextGeneratorOption_select_column;
	/** input type for updating data in table "TextGeneratorOption" */
	["TextGeneratorOption_set_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		popover_tooltip?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "TextGeneratorOption" */
	["TextGeneratorOption_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["TextGeneratorOption_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["TextGeneratorOption_stream_cursor_value_input"]: {
		Label?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		popover_tooltip?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "TextGeneratorOption" */
	["TextGeneratorOption_update_column"]: TextGeneratorOption_update_column;
	["TextGeneratorOption_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["TextGeneratorOption_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["TextGeneratorOption_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["TextGeneratorOption_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["TextGeneratorOption_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["TextGeneratorOption_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["TextGeneratorOption_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["TextGeneratorOption_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "TextPromptTemplate" */
	["TextPromptTemplate"]: AliasType<{
		GeneratedPromptByTemplates?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate"]],
		GeneratedPromptByTemplates_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate_aggregate"]],
		/** An object relationship */
		TextPromptTemplateCategory?: ValueTypes["TextPromptTemplateCategory"],
		/** An object relationship */
		TextTemplateClientCategory?: ValueTypes["TextTemplateClientCategory"],
		TextTemplateClientCategoryId?: boolean | `@${string}`,
		availableDisplays?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["textTemplateDisplayFormat_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["textTemplateDisplayFormat_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat"]],
		availableDisplays_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["textTemplateDisplayFormat_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["textTemplateDisplayFormat_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat_aggregate"]],
		categoryId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		definitions?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		description?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		flexible?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplateText?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory"]: AliasType<{
		TextPromptTemplates?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate"]],
		TextPromptTemplates_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate_aggregate"]],
		categoryName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_aggregate"]: AliasType<{
		aggregate?: ValueTypes["TextPromptTemplateCategory_aggregate_fields"],
		nodes?: ValueTypes["TextPromptTemplateCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["TextPromptTemplateCategory_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["TextPromptTemplateCategory_max_fields"],
		min?: ValueTypes["TextPromptTemplateCategory_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "TextPromptTemplateCategory". All fields are combined with a logical 'AND'. */
	["TextPromptTemplateCategory_bool_exp"]: {
		TextPromptTemplates?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		TextPromptTemplates_aggregate?: ValueTypes["TextPromptTemplate_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		_and?: Array<ValueTypes["TextPromptTemplateCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["TextPromptTemplateCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		categoryName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_constraint"]: TextPromptTemplateCategory_constraint;
	/** input type for inserting data into table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_insert_input"]: {
		TextPromptTemplates?: ValueTypes["TextPromptTemplate_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		categoryName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["TextPromptTemplateCategory_max_fields"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["TextPromptTemplateCategory_min_fields"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["TextPromptTemplateCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_obj_rel_insert_input"]: {
		data: ValueTypes["TextPromptTemplateCategory_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["TextPromptTemplateCategory_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_on_conflict"]: {
		constraint: ValueTypes["TextPromptTemplateCategory_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["TextPromptTemplateCategory_update_column"]> | Variable<any, string>,
		where?: ValueTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "TextPromptTemplateCategory". */
	["TextPromptTemplateCategory_order_by"]: {
		TextPromptTemplates_aggregate?: ValueTypes["TextPromptTemplate_aggregate_order_by"] | undefined | null | Variable<any, string>,
		categoryName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: TextPromptTemplateCategory */
	["TextPromptTemplateCategory_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_select_column"]: TextPromptTemplateCategory_select_column;
	/** input type for updating data in table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_set_input"]: {
		categoryName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["TextPromptTemplateCategory_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["TextPromptTemplateCategory_stream_cursor_value_input"]: {
		categoryName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_update_column"]: TextPromptTemplateCategory_update_column;
	["TextPromptTemplateCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["TextPromptTemplateCategory_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["TextPromptTemplateCategory_bool_exp"] | Variable<any, string>
	};
	/** aggregated selection of "TextPromptTemplate" */
	["TextPromptTemplate_aggregate"]: AliasType<{
		aggregate?: ValueTypes["TextPromptTemplate_aggregate_fields"],
		nodes?: ValueTypes["TextPromptTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	["TextPromptTemplate_aggregate_bool_exp"]: {
		bool_and?: ValueTypes["TextPromptTemplate_aggregate_bool_exp_bool_and"] | undefined | null | Variable<any, string>,
		bool_or?: ValueTypes["TextPromptTemplate_aggregate_bool_exp_bool_or"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["TextPromptTemplate_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["TextPromptTemplate_aggregate_bool_exp_bool_and"]: {
		arguments: ValueTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["TextPromptTemplate_aggregate_bool_exp_bool_or"]: {
		arguments: ValueTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["TextPromptTemplate_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["TextPromptTemplate_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "TextPromptTemplate" */
	["TextPromptTemplate_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["TextPromptTemplate_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["TextPromptTemplate_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["TextPromptTemplate_max_fields"],
		min?: ValueTypes["TextPromptTemplate_min_fields"],
		stddev?: ValueTypes["TextPromptTemplate_stddev_fields"],
		stddev_pop?: ValueTypes["TextPromptTemplate_stddev_pop_fields"],
		stddev_samp?: ValueTypes["TextPromptTemplate_stddev_samp_fields"],
		sum?: ValueTypes["TextPromptTemplate_sum_fields"],
		var_pop?: ValueTypes["TextPromptTemplate_var_pop_fields"],
		var_samp?: ValueTypes["TextPromptTemplate_var_samp_fields"],
		variance?: ValueTypes["TextPromptTemplate_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "TextPromptTemplate" */
	["TextPromptTemplate_aggregate_order_by"]: {
		avg?: ValueTypes["TextPromptTemplate_avg_order_by"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["TextPromptTemplate_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["TextPromptTemplate_min_order_by"] | undefined | null | Variable<any, string>,
		stddev?: ValueTypes["TextPromptTemplate_stddev_order_by"] | undefined | null | Variable<any, string>,
		stddev_pop?: ValueTypes["TextPromptTemplate_stddev_pop_order_by"] | undefined | null | Variable<any, string>,
		stddev_samp?: ValueTypes["TextPromptTemplate_stddev_samp_order_by"] | undefined | null | Variable<any, string>,
		sum?: ValueTypes["TextPromptTemplate_sum_order_by"] | undefined | null | Variable<any, string>,
		var_pop?: ValueTypes["TextPromptTemplate_var_pop_order_by"] | undefined | null | Variable<any, string>,
		var_samp?: ValueTypes["TextPromptTemplate_var_samp_order_by"] | undefined | null | Variable<any, string>,
		variance?: ValueTypes["TextPromptTemplate_variance_order_by"] | undefined | null | Variable<any, string>
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextPromptTemplate_append_input"]: {
		definitions?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "TextPromptTemplate" */
	["TextPromptTemplate_arr_rel_insert_input"]: {
		data: Array<ValueTypes["TextPromptTemplate_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["TextPromptTemplate_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["TextPromptTemplate_avg_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_avg_order_by"]: {
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "TextPromptTemplate". All fields are combined with a logical 'AND'. */
	["TextPromptTemplate_bool_exp"]: {
		GeneratedPromptByTemplates?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		GeneratedPromptByTemplates_aggregate?: ValueTypes["GeneratedPromptByTemplate_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		TextPromptTemplateCategory?: ValueTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null | Variable<any, string>,
		TextTemplateClientCategory?: ValueTypes["TextTemplateClientCategory_bool_exp"] | undefined | null | Variable<any, string>,
		TextTemplateClientCategoryId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		_and?: Array<ValueTypes["TextPromptTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["TextPromptTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		availableDisplays?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>,
		availableDisplays_aggregate?: ValueTypes["textTemplateDisplayFormat_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		definitions?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		flexible?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		icon?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		newTemplateText?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "TextPromptTemplate" */
	["TextPromptTemplate_constraint"]: TextPromptTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextPromptTemplate_delete_at_path_input"]: {
		definitions?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextPromptTemplate_delete_elem_input"]: {
		definitions?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextPromptTemplate_delete_key_input"]: {
		definitions?: string | undefined | null | Variable<any, string>
	};
	/** input type for incrementing numeric columns in table "TextPromptTemplate" */
	["TextPromptTemplate_inc_input"]: {
		creditPrice?: number | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: number | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "TextPromptTemplate" */
	["TextPromptTemplate_insert_input"]: {
		GeneratedPromptByTemplates?: ValueTypes["GeneratedPromptByTemplate_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		TextPromptTemplateCategory?: ValueTypes["TextPromptTemplateCategory_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		TextTemplateClientCategory?: ValueTypes["TextTemplateClientCategory_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		TextTemplateClientCategoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		availableDisplays?: ValueTypes["textTemplateDisplayFormat_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		channel?: string | undefined | null | Variable<any, string>,
		creditPrice?: number | undefined | null | Variable<any, string>,
		definitions?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		flexible?: boolean | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		newTemplateText?: string | undefined | null | Variable<any, string>,
		saleCreditPrice?: number | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["TextPromptTemplate_max_fields"]: AliasType<{
		TextTemplateClientCategoryId?: boolean | `@${string}`,
		categoryId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplateText?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_max_order_by"]: {
		TextTemplateClientCategoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		icon?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		newTemplateText?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["TextPromptTemplate_min_fields"]: AliasType<{
		TextTemplateClientCategoryId?: boolean | `@${string}`,
		categoryId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplateText?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_min_order_by"]: {
		TextTemplateClientCategoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		icon?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		newTemplateText?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "TextPromptTemplate" */
	["TextPromptTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["TextPromptTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "TextPromptTemplate" */
	["TextPromptTemplate_obj_rel_insert_input"]: {
		data: ValueTypes["TextPromptTemplate_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["TextPromptTemplate_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "TextPromptTemplate" */
	["TextPromptTemplate_on_conflict"]: {
		constraint: ValueTypes["TextPromptTemplate_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["TextPromptTemplate_update_column"]> | Variable<any, string>,
		where?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "TextPromptTemplate". */
	["TextPromptTemplate_order_by"]: {
		GeneratedPromptByTemplates_aggregate?: ValueTypes["GeneratedPromptByTemplate_aggregate_order_by"] | undefined | null | Variable<any, string>,
		TextPromptTemplateCategory?: ValueTypes["TextPromptTemplateCategory_order_by"] | undefined | null | Variable<any, string>,
		TextTemplateClientCategory?: ValueTypes["TextTemplateClientCategory_order_by"] | undefined | null | Variable<any, string>,
		TextTemplateClientCategoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		availableDisplays_aggregate?: ValueTypes["textTemplateDisplayFormat_aggregate_order_by"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		definitions?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		flexible?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		icon?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		newTemplateText?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: TextPromptTemplate */
	["TextPromptTemplate_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextPromptTemplate_prepend_input"]: {
		definitions?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column"]: TextPromptTemplate_select_column;
	/** select "TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "TextPromptTemplate" */
	["TextPromptTemplate_set_input"]: {
		TextTemplateClientCategoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		channel?: string | undefined | null | Variable<any, string>,
		creditPrice?: number | undefined | null | Variable<any, string>,
		definitions?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		flexible?: boolean | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		newTemplateText?: string | undefined | null | Variable<any, string>,
		saleCreditPrice?: number | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["TextPromptTemplate_stddev_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_order_by"]: {
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_pop on columns */
	["TextPromptTemplate_stddev_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_pop_order_by"]: {
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_samp on columns */
	["TextPromptTemplate_stddev_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_samp_order_by"]: {
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "TextPromptTemplate" */
	["TextPromptTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["TextPromptTemplate_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["TextPromptTemplate_stream_cursor_value_input"]: {
		TextTemplateClientCategoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		channel?: string | undefined | null | Variable<any, string>,
		creditPrice?: number | undefined | null | Variable<any, string>,
		definitions?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		flexible?: boolean | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		newTemplateText?: string | undefined | null | Variable<any, string>,
		saleCreditPrice?: number | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["TextPromptTemplate_sum_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_sum_order_by"]: {
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "TextPromptTemplate" */
	["TextPromptTemplate_update_column"]: TextPromptTemplate_update_column;
	["TextPromptTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["TextPromptTemplate_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["TextPromptTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["TextPromptTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["TextPromptTemplate_delete_key_input"] | undefined | null | Variable<any, string>,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["TextPromptTemplate_inc_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["TextPromptTemplate_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["TextPromptTemplate_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["TextPromptTemplate_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["TextPromptTemplate_var_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_var_pop_order_by"]: {
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate var_samp on columns */
	["TextPromptTemplate_var_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_var_samp_order_by"]: {
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate variance on columns */
	["TextPromptTemplate_variance_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_variance_order_by"]: {
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketPriceInCredits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "TextTemplateClientCategory" */
	["TextTemplateClientCategory"]: AliasType<{
		displayName?: boolean | `@${string}`,
		iconClass?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "TextTemplateClientCategory" */
	["TextTemplateClientCategory_aggregate"]: AliasType<{
		aggregate?: ValueTypes["TextTemplateClientCategory_aggregate_fields"],
		nodes?: ValueTypes["TextTemplateClientCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "TextTemplateClientCategory" */
	["TextTemplateClientCategory_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["TextTemplateClientCategory_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["TextTemplateClientCategory_max_fields"],
		min?: ValueTypes["TextTemplateClientCategory_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "TextTemplateClientCategory". All fields are combined with a logical 'AND'. */
	["TextTemplateClientCategory_bool_exp"]: {
		_and?: Array<ValueTypes["TextTemplateClientCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["TextTemplateClientCategory_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["TextTemplateClientCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		iconClass?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_constraint"]: TextTemplateClientCategory_constraint;
	/** input type for inserting data into table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_insert_input"]: {
		displayName?: string | undefined | null | Variable<any, string>,
		iconClass?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["TextTemplateClientCategory_max_fields"]: AliasType<{
		displayName?: boolean | `@${string}`,
		iconClass?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["TextTemplateClientCategory_min_fields"]: AliasType<{
		displayName?: boolean | `@${string}`,
		iconClass?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["TextTemplateClientCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_obj_rel_insert_input"]: {
		data: ValueTypes["TextTemplateClientCategory_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["TextTemplateClientCategory_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_on_conflict"]: {
		constraint: ValueTypes["TextTemplateClientCategory_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["TextTemplateClientCategory_update_column"]> | Variable<any, string>,
		where?: ValueTypes["TextTemplateClientCategory_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "TextTemplateClientCategory". */
	["TextTemplateClientCategory_order_by"]: {
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		iconClass?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: TextTemplateClientCategory */
	["TextTemplateClientCategory_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_select_column"]: TextTemplateClientCategory_select_column;
	/** input type for updating data in table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_set_input"]: {
		displayName?: string | undefined | null | Variable<any, string>,
		iconClass?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["TextTemplateClientCategory_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["TextTemplateClientCategory_stream_cursor_value_input"]: {
		displayName?: string | undefined | null | Variable<any, string>,
		iconClass?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_update_column"]: TextTemplateClientCategory_update_column;
	["TextTemplateClientCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["TextTemplateClientCategory_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["TextTemplateClientCategory_bool_exp"] | Variable<any, string>
	};
	["UpdateUserAvatarOutput"]: AliasType<{
		message?: boolean | `@${string}`,
		photoURL?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["UpsertAudienceOutput"]: AliasType<{
		brandName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		personas?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		targetAudience?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "audiences" */
	["audiences"]: AliasType<{
		brandId?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		personas?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		targetAudience?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "audiences" */
	["audiences_aggregate"]: AliasType<{
		aggregate?: ValueTypes["audiences_aggregate_fields"],
		nodes?: ValueTypes["audiences"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "audiences" */
	["audiences_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["audiences_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["audiences_max_fields"],
		min?: ValueTypes["audiences_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["audiences_append_input"]: {
		personas?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "audiences". All fields are combined with a logical 'AND'. */
	["audiences_bool_exp"]: {
		_and?: Array<ValueTypes["audiences_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["audiences_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["audiences_bool_exp"]> | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		brandName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamp_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		personas?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		productOrService?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		segment?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		targetAudience?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "audiences" */
	["audiences_constraint"]: audiences_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["audiences_delete_at_path_input"]: {
		personas?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["audiences_delete_elem_input"]: {
		personas?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["audiences_delete_key_input"]: {
		personas?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "audiences" */
	["audiences_insert_input"]: {
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		personas?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		segment?: string | undefined | null | Variable<any, string>,
		targetAudience?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["audiences_max_fields"]: AliasType<{
		brandId?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		targetAudience?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["audiences_min_fields"]: AliasType<{
		brandId?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		targetAudience?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "audiences" */
	["audiences_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["audiences"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "audiences" */
	["audiences_on_conflict"]: {
		constraint: ValueTypes["audiences_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["audiences_update_column"]> | Variable<any, string>,
		where?: ValueTypes["audiences_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "audiences". */
	["audiences_order_by"]: {
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brandName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		personas?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		productOrService?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		segment?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		targetAudience?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: audiences */
	["audiences_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["audiences_prepend_input"]: {
		personas?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "audiences" */
	["audiences_select_column"]: audiences_select_column;
	/** input type for updating data in table "audiences" */
	["audiences_set_input"]: {
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		personas?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		segment?: string | undefined | null | Variable<any, string>,
		targetAudience?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "audiences" */
	["audiences_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["audiences_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["audiences_stream_cursor_value_input"]: {
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		personas?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		segment?: string | undefined | null | Variable<any, string>,
		targetAudience?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "audiences" */
	["audiences_update_column"]: audiences_update_column;
	["audiences_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["audiences_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["audiences_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["audiences_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["audiences_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["audiences_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["audiences_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["audiences_bool_exp"] | Variable<any, string>
	};
	/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authProviderRequests"]: AliasType<{
		id?: boolean | `@${string}`,
		options?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.provider_requests" */
	["authProviderRequests_aggregate"]: AliasType<{
		aggregate?: ValueTypes["authProviderRequests_aggregate_fields"],
		nodes?: ValueTypes["authProviderRequests"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "auth.provider_requests" */
	["authProviderRequests_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["authProviderRequests_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["authProviderRequests_max_fields"],
		min?: ValueTypes["authProviderRequests_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["authProviderRequests_append_input"]: {
		options?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
	["authProviderRequests_bool_exp"]: {
		_and?: Array<ValueTypes["authProviderRequests_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["authProviderRequests_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["authProviderRequests_bool_exp"]> | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		options?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.provider_requests" */
	["authProviderRequests_constraint"]: authProviderRequests_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["authProviderRequests_delete_at_path_input"]: {
		options?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["authProviderRequests_delete_elem_input"]: {
		options?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["authProviderRequests_delete_key_input"]: {
		options?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "auth.provider_requests" */
	["authProviderRequests_insert_input"]: {
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		options?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["authProviderRequests_max_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["authProviderRequests_min_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "auth.provider_requests" */
	["authProviderRequests_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["authProviderRequests"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.provider_requests" */
	["authProviderRequests_on_conflict"]: {
		constraint: ValueTypes["authProviderRequests_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["authProviderRequests_update_column"]> | Variable<any, string>,
		where?: ValueTypes["authProviderRequests_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.provider_requests". */
	["authProviderRequests_order_by"]: {
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		options?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.provider_requests */
	["authProviderRequests_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["authProviderRequests_prepend_input"]: {
		options?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "auth.provider_requests" */
	["authProviderRequests_select_column"]: authProviderRequests_select_column;
	/** input type for updating data in table "auth.provider_requests" */
	["authProviderRequests_set_input"]: {
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		options?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "authProviderRequests" */
	["authProviderRequests_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["authProviderRequests_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["authProviderRequests_stream_cursor_value_input"]: {
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		options?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.provider_requests" */
	["authProviderRequests_update_column"]: authProviderRequests_update_column;
	["authProviderRequests_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["authProviderRequests_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["authProviderRequests_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["authProviderRequests_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["authProviderRequests_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["authProviderRequests_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["authProviderRequests_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["authProviderRequests_bool_exp"] | Variable<any, string>
	};
	/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authProviders"]: AliasType<{
		id?: boolean | `@${string}`,
		userProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders"]],
		userProviders_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders_aggregate"]],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.providers" */
	["authProviders_aggregate"]: AliasType<{
		aggregate?: ValueTypes["authProviders_aggregate_fields"],
		nodes?: ValueTypes["authProviders"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "auth.providers" */
	["authProviders_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["authProviders_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["authProviders_max_fields"],
		min?: ValueTypes["authProviders_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
	["authProviders_bool_exp"]: {
		_and?: Array<ValueTypes["authProviders_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["authProviders_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["authProviders_bool_exp"]> | undefined | null | Variable<any, string>,
		id?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		userProviders?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>,
		userProviders_aggregate?: ValueTypes["authUserProviders_aggregate_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.providers" */
	["authProviders_constraint"]: authProviders_constraint;
	/** input type for inserting data into table "auth.providers" */
	["authProviders_insert_input"]: {
		id?: string | undefined | null | Variable<any, string>,
		userProviders?: ValueTypes["authUserProviders_arr_rel_insert_input"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["authProviders_max_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["authProviders_min_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "auth.providers" */
	["authProviders_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["authProviders"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "auth.providers" */
	["authProviders_obj_rel_insert_input"]: {
		data: ValueTypes["authProviders_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["authProviders_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "auth.providers" */
	["authProviders_on_conflict"]: {
		constraint: ValueTypes["authProviders_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["authProviders_update_column"]> | Variable<any, string>,
		where?: ValueTypes["authProviders_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.providers". */
	["authProviders_order_by"]: {
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userProviders_aggregate?: ValueTypes["authUserProviders_aggregate_order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.providers */
	["authProviders_pk_columns_input"]: {
		id: string | Variable<any, string>
	};
	/** select columns of table "auth.providers" */
	["authProviders_select_column"]: authProviders_select_column;
	/** input type for updating data in table "auth.providers" */
	["authProviders_set_input"]: {
		id?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "authProviders" */
	["authProviders_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["authProviders_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["authProviders_stream_cursor_value_input"]: {
		id?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.providers" */
	["authProviders_update_column"]: authProviders_update_column;
	["authProviders_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["authProviders_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["authProviders_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "auth.refresh_token_types" */
	["authRefreshTokenTypes"]: AliasType<{
		comment?: boolean | `@${string}`,
		refreshTokens?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokens_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens"]],
		refreshTokens_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokens_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens_aggregate"]],
		value?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.refresh_token_types" */
	["authRefreshTokenTypes_aggregate"]: AliasType<{
		aggregate?: ValueTypes["authRefreshTokenTypes_aggregate_fields"],
		nodes?: ValueTypes["authRefreshTokenTypes"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "auth.refresh_token_types" */
	["authRefreshTokenTypes_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["authRefreshTokenTypes_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["authRefreshTokenTypes_max_fields"],
		min?: ValueTypes["authRefreshTokenTypes_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
	["authRefreshTokenTypes_bool_exp"]: {
		_and?: Array<ValueTypes["authRefreshTokenTypes_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["authRefreshTokenTypes_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["authRefreshTokenTypes_bool_exp"]> | undefined | null | Variable<any, string>,
		comment?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		refreshTokens?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>,
		refreshTokens_aggregate?: ValueTypes["authRefreshTokens_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		value?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.refresh_token_types" */
	["authRefreshTokenTypes_constraint"]: authRefreshTokenTypes_constraint;
	["authRefreshTokenTypes_enum"]: authRefreshTokenTypes_enum;
	/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
	["authRefreshTokenTypes_enum_comparison_exp"]: {
		_eq?: ValueTypes["authRefreshTokenTypes_enum"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["authRefreshTokenTypes_enum"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["authRefreshTokenTypes_enum"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["authRefreshTokenTypes_enum"]> | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "auth.refresh_token_types" */
	["authRefreshTokenTypes_insert_input"]: {
		comment?: string | undefined | null | Variable<any, string>,
		refreshTokens?: ValueTypes["authRefreshTokens_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		value?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["authRefreshTokenTypes_max_fields"]: AliasType<{
		comment?: boolean | `@${string}`,
		value?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["authRefreshTokenTypes_min_fields"]: AliasType<{
		comment?: boolean | `@${string}`,
		value?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "auth.refresh_token_types" */
	["authRefreshTokenTypes_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["authRefreshTokenTypes"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.refresh_token_types" */
	["authRefreshTokenTypes_on_conflict"]: {
		constraint: ValueTypes["authRefreshTokenTypes_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["authRefreshTokenTypes_update_column"]> | Variable<any, string>,
		where?: ValueTypes["authRefreshTokenTypes_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.refresh_token_types". */
	["authRefreshTokenTypes_order_by"]: {
		comment?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		refreshTokens_aggregate?: ValueTypes["authRefreshTokens_aggregate_order_by"] | undefined | null | Variable<any, string>,
		value?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.refresh_token_types */
	["authRefreshTokenTypes_pk_columns_input"]: {
		value: string | Variable<any, string>
	};
	/** select columns of table "auth.refresh_token_types" */
	["authRefreshTokenTypes_select_column"]: authRefreshTokenTypes_select_column;
	/** input type for updating data in table "auth.refresh_token_types" */
	["authRefreshTokenTypes_set_input"]: {
		comment?: string | undefined | null | Variable<any, string>,
		value?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "authRefreshTokenTypes" */
	["authRefreshTokenTypes_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["authRefreshTokenTypes_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["authRefreshTokenTypes_stream_cursor_value_input"]: {
		comment?: string | undefined | null | Variable<any, string>,
		value?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.refresh_token_types" */
	["authRefreshTokenTypes_update_column"]: authRefreshTokenTypes_update_column;
	["authRefreshTokenTypes_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["authRefreshTokenTypes_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["authRefreshTokenTypes_bool_exp"] | Variable<any, string>
	};
	/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authRefreshTokens"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		expiresAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		metadata?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		refreshTokenHash?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		/** An object relationship */
		user?: ValueTypes["users"],
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.refresh_tokens" */
	["authRefreshTokens_aggregate"]: AliasType<{
		aggregate?: ValueTypes["authRefreshTokens_aggregate_fields"],
		nodes?: ValueTypes["authRefreshTokens"],
		__typename?: boolean | `@${string}`
	}>;
	["authRefreshTokens_aggregate_bool_exp"]: {
		count?: ValueTypes["authRefreshTokens_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["authRefreshTokens_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "auth.refresh_tokens" */
	["authRefreshTokens_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["authRefreshTokens_max_fields"],
		min?: ValueTypes["authRefreshTokens_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.refresh_tokens" */
	["authRefreshTokens_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["authRefreshTokens_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["authRefreshTokens_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["authRefreshTokens_append_input"]: {
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "auth.refresh_tokens" */
	["authRefreshTokens_arr_rel_insert_input"]: {
		data: Array<ValueTypes["authRefreshTokens_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["authRefreshTokens_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
	["authRefreshTokens_bool_exp"]: {
		_and?: Array<ValueTypes["authRefreshTokens_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["authRefreshTokens_bool_exp"]> | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		expiresAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		refreshTokenHash?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["authRefreshTokenTypes_enum_comparison_exp"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.refresh_tokens" */
	["authRefreshTokens_constraint"]: authRefreshTokens_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["authRefreshTokens_delete_at_path_input"]: {
		metadata?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["authRefreshTokens_delete_elem_input"]: {
		metadata?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["authRefreshTokens_delete_key_input"]: {
		metadata?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "auth.refresh_tokens" */
	["authRefreshTokens_insert_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		expiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		refreshTokenHash?: string | undefined | null | Variable<any, string>,
		type?: ValueTypes["authRefreshTokenTypes_enum"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["authRefreshTokens_max_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		expiresAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		refreshTokenHash?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.refresh_tokens" */
	["authRefreshTokens_max_order_by"]: {
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		expiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		refreshTokenHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["authRefreshTokens_min_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		expiresAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		refreshTokenHash?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.refresh_tokens" */
	["authRefreshTokens_min_order_by"]: {
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		expiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		refreshTokenHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "auth.refresh_tokens" */
	["authRefreshTokens_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["authRefreshTokens"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.refresh_tokens" */
	["authRefreshTokens_on_conflict"]: {
		constraint: ValueTypes["authRefreshTokens_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["authRefreshTokens_update_column"]> | Variable<any, string>,
		where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.refresh_tokens". */
	["authRefreshTokens_order_by"]: {
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		expiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		refreshTokenHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.refresh_tokens */
	["authRefreshTokens_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["authRefreshTokens_prepend_input"]: {
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "auth.refresh_tokens" */
	["authRefreshTokens_select_column"]: authRefreshTokens_select_column;
	/** input type for updating data in table "auth.refresh_tokens" */
	["authRefreshTokens_set_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		expiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		refreshTokenHash?: string | undefined | null | Variable<any, string>,
		type?: ValueTypes["authRefreshTokenTypes_enum"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "authRefreshTokens" */
	["authRefreshTokens_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["authRefreshTokens_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["authRefreshTokens_stream_cursor_value_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		expiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		refreshTokenHash?: string | undefined | null | Variable<any, string>,
		type?: ValueTypes["authRefreshTokenTypes_enum"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.refresh_tokens" */
	["authRefreshTokens_update_column"]: authRefreshTokens_update_column;
	["authRefreshTokens_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["authRefreshTokens_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["authRefreshTokens_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["authRefreshTokens_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["authRefreshTokens_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["authRefreshTokens_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["authRefreshTokens_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["authRefreshTokens_bool_exp"] | Variable<any, string>
	};
	/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authRoles"]: AliasType<{
		role?: boolean | `@${string}`,
		userRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles"]],
		userRoles_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles_aggregate"]],
		usersByDefaultRole?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["users_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["users_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["users"]],
		usersByDefaultRole_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["users_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["users_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["users_aggregate"]],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.roles" */
	["authRoles_aggregate"]: AliasType<{
		aggregate?: ValueTypes["authRoles_aggregate_fields"],
		nodes?: ValueTypes["authRoles"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "auth.roles" */
	["authRoles_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["authRoles_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["authRoles_max_fields"],
		min?: ValueTypes["authRoles_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
	["authRoles_bool_exp"]: {
		_and?: Array<ValueTypes["authRoles_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["authRoles_bool_exp"]> | undefined | null | Variable<any, string>,
		role?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		userRoles?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>,
		userRoles_aggregate?: ValueTypes["authUserRoles_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		usersByDefaultRole?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		usersByDefaultRole_aggregate?: ValueTypes["users_aggregate_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.roles" */
	["authRoles_constraint"]: authRoles_constraint;
	/** input type for inserting data into table "auth.roles" */
	["authRoles_insert_input"]: {
		role?: string | undefined | null | Variable<any, string>,
		userRoles?: ValueTypes["authUserRoles_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		usersByDefaultRole?: ValueTypes["users_arr_rel_insert_input"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["authRoles_max_fields"]: AliasType<{
		role?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["authRoles_min_fields"]: AliasType<{
		role?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "auth.roles" */
	["authRoles_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["authRoles"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "auth.roles" */
	["authRoles_obj_rel_insert_input"]: {
		data: ValueTypes["authRoles_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["authRoles_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "auth.roles" */
	["authRoles_on_conflict"]: {
		constraint: ValueTypes["authRoles_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["authRoles_update_column"]> | Variable<any, string>,
		where?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.roles". */
	["authRoles_order_by"]: {
		role?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userRoles_aggregate?: ValueTypes["authUserRoles_aggregate_order_by"] | undefined | null | Variable<any, string>,
		usersByDefaultRole_aggregate?: ValueTypes["users_aggregate_order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.roles */
	["authRoles_pk_columns_input"]: {
		role: string | Variable<any, string>
	};
	/** select columns of table "auth.roles" */
	["authRoles_select_column"]: authRoles_select_column;
	/** input type for updating data in table "auth.roles" */
	["authRoles_set_input"]: {
		role?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "authRoles" */
	["authRoles_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["authRoles_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["authRoles_stream_cursor_value_input"]: {
		role?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.roles" */
	["authRoles_update_column"]: authRoles_update_column;
	["authRoles_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["authRoles_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["authRoles_bool_exp"] | Variable<any, string>
	};
	/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserProviders"]: AliasType<{
		accessToken?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		/** An object relationship */
		provider?: ValueTypes["authProviders"],
		providerId?: boolean | `@${string}`,
		providerUserId?: boolean | `@${string}`,
		refreshToken?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		/** An object relationship */
		user?: ValueTypes["users"],
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.user_providers" */
	["authUserProviders_aggregate"]: AliasType<{
		aggregate?: ValueTypes["authUserProviders_aggregate_fields"],
		nodes?: ValueTypes["authUserProviders"],
		__typename?: boolean | `@${string}`
	}>;
	["authUserProviders_aggregate_bool_exp"]: {
		count?: ValueTypes["authUserProviders_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["authUserProviders_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "auth.user_providers" */
	["authUserProviders_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["authUserProviders_max_fields"],
		min?: ValueTypes["authUserProviders_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.user_providers" */
	["authUserProviders_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["authUserProviders_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["authUserProviders_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "auth.user_providers" */
	["authUserProviders_arr_rel_insert_input"]: {
		data: Array<ValueTypes["authUserProviders_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["authUserProviders_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
	["authUserProviders_bool_exp"]: {
		_and?: Array<ValueTypes["authUserProviders_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["authUserProviders_bool_exp"]> | undefined | null | Variable<any, string>,
		accessToken?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		provider?: ValueTypes["authProviders_bool_exp"] | undefined | null | Variable<any, string>,
		providerId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		providerUserId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		refreshToken?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.user_providers" */
	["authUserProviders_constraint"]: authUserProviders_constraint;
	/** input type for inserting data into table "auth.user_providers" */
	["authUserProviders_insert_input"]: {
		accessToken?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		provider?: ValueTypes["authProviders_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		providerId?: string | undefined | null | Variable<any, string>,
		providerUserId?: string | undefined | null | Variable<any, string>,
		refreshToken?: string | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["authUserProviders_max_fields"]: AliasType<{
		accessToken?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		providerId?: boolean | `@${string}`,
		providerUserId?: boolean | `@${string}`,
		refreshToken?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.user_providers" */
	["authUserProviders_max_order_by"]: {
		accessToken?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		providerId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		providerUserId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		refreshToken?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["authUserProviders_min_fields"]: AliasType<{
		accessToken?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		providerId?: boolean | `@${string}`,
		providerUserId?: boolean | `@${string}`,
		refreshToken?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.user_providers" */
	["authUserProviders_min_order_by"]: {
		accessToken?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		providerId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		providerUserId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		refreshToken?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "auth.user_providers" */
	["authUserProviders_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["authUserProviders"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.user_providers" */
	["authUserProviders_on_conflict"]: {
		constraint: ValueTypes["authUserProviders_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["authUserProviders_update_column"]> | Variable<any, string>,
		where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.user_providers". */
	["authUserProviders_order_by"]: {
		accessToken?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		provider?: ValueTypes["authProviders_order_by"] | undefined | null | Variable<any, string>,
		providerId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		providerUserId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		refreshToken?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.user_providers */
	["authUserProviders_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "auth.user_providers" */
	["authUserProviders_select_column"]: authUserProviders_select_column;
	/** input type for updating data in table "auth.user_providers" */
	["authUserProviders_set_input"]: {
		accessToken?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		providerId?: string | undefined | null | Variable<any, string>,
		providerUserId?: string | undefined | null | Variable<any, string>,
		refreshToken?: string | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "authUserProviders" */
	["authUserProviders_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["authUserProviders_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["authUserProviders_stream_cursor_value_input"]: {
		accessToken?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		providerId?: string | undefined | null | Variable<any, string>,
		providerUserId?: string | undefined | null | Variable<any, string>,
		refreshToken?: string | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.user_providers" */
	["authUserProviders_update_column"]: authUserProviders_update_column;
	["authUserProviders_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["authUserProviders_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["authUserProviders_bool_exp"] | Variable<any, string>
	};
	/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserRoles"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		role?: boolean | `@${string}`,
		/** An object relationship */
		roleByRole?: ValueTypes["authRoles"],
		/** An object relationship */
		user?: ValueTypes["users"],
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.user_roles" */
	["authUserRoles_aggregate"]: AliasType<{
		aggregate?: ValueTypes["authUserRoles_aggregate_fields"],
		nodes?: ValueTypes["authUserRoles"],
		__typename?: boolean | `@${string}`
	}>;
	["authUserRoles_aggregate_bool_exp"]: {
		count?: ValueTypes["authUserRoles_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["authUserRoles_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "auth.user_roles" */
	["authUserRoles_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["authUserRoles_max_fields"],
		min?: ValueTypes["authUserRoles_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.user_roles" */
	["authUserRoles_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["authUserRoles_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["authUserRoles_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "auth.user_roles" */
	["authUserRoles_arr_rel_insert_input"]: {
		data: Array<ValueTypes["authUserRoles_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["authUserRoles_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
	["authUserRoles_bool_exp"]: {
		_and?: Array<ValueTypes["authUserRoles_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["authUserRoles_bool_exp"]> | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		role?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		roleByRole?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.user_roles" */
	["authUserRoles_constraint"]: authUserRoles_constraint;
	/** input type for inserting data into table "auth.user_roles" */
	["authUserRoles_insert_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		role?: string | undefined | null | Variable<any, string>,
		roleByRole?: ValueTypes["authRoles_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["authUserRoles_max_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		role?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.user_roles" */
	["authUserRoles_max_order_by"]: {
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		role?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["authUserRoles_min_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		role?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.user_roles" */
	["authUserRoles_min_order_by"]: {
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		role?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "auth.user_roles" */
	["authUserRoles_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["authUserRoles"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.user_roles" */
	["authUserRoles_on_conflict"]: {
		constraint: ValueTypes["authUserRoles_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["authUserRoles_update_column"]> | Variable<any, string>,
		where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.user_roles". */
	["authUserRoles_order_by"]: {
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		role?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		roleByRole?: ValueTypes["authRoles_order_by"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.user_roles */
	["authUserRoles_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "auth.user_roles" */
	["authUserRoles_select_column"]: authUserRoles_select_column;
	/** input type for updating data in table "auth.user_roles" */
	["authUserRoles_set_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		role?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "authUserRoles" */
	["authUserRoles_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["authUserRoles_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["authUserRoles_stream_cursor_value_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		role?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.user_roles" */
	["authUserRoles_update_column"]: authUserRoles_update_column;
	["authUserRoles_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["authUserRoles_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["authUserRoles_bool_exp"] | Variable<any, string>
	};
	/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserSecurityKeys"]: AliasType<{
		counter?: boolean | `@${string}`,
		credentialId?: boolean | `@${string}`,
		credentialPublicKey?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		nickname?: boolean | `@${string}`,
		transports?: boolean | `@${string}`,
		/** An object relationship */
		user?: ValueTypes["users"],
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate"]: AliasType<{
		aggregate?: ValueTypes["authUserSecurityKeys_aggregate_fields"],
		nodes?: ValueTypes["authUserSecurityKeys"],
		__typename?: boolean | `@${string}`
	}>;
	["authUserSecurityKeys_aggregate_bool_exp"]: {
		count?: ValueTypes["authUserSecurityKeys_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["authUserSecurityKeys_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["authUserSecurityKeys_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["authUserSecurityKeys_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["authUserSecurityKeys_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["authUserSecurityKeys_max_fields"],
		min?: ValueTypes["authUserSecurityKeys_min_fields"],
		stddev?: ValueTypes["authUserSecurityKeys_stddev_fields"],
		stddev_pop?: ValueTypes["authUserSecurityKeys_stddev_pop_fields"],
		stddev_samp?: ValueTypes["authUserSecurityKeys_stddev_samp_fields"],
		sum?: ValueTypes["authUserSecurityKeys_sum_fields"],
		var_pop?: ValueTypes["authUserSecurityKeys_var_pop_fields"],
		var_samp?: ValueTypes["authUserSecurityKeys_var_samp_fields"],
		variance?: ValueTypes["authUserSecurityKeys_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate_order_by"]: {
		avg?: ValueTypes["authUserSecurityKeys_avg_order_by"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["authUserSecurityKeys_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["authUserSecurityKeys_min_order_by"] | undefined | null | Variable<any, string>,
		stddev?: ValueTypes["authUserSecurityKeys_stddev_order_by"] | undefined | null | Variable<any, string>,
		stddev_pop?: ValueTypes["authUserSecurityKeys_stddev_pop_order_by"] | undefined | null | Variable<any, string>,
		stddev_samp?: ValueTypes["authUserSecurityKeys_stddev_samp_order_by"] | undefined | null | Variable<any, string>,
		sum?: ValueTypes["authUserSecurityKeys_sum_order_by"] | undefined | null | Variable<any, string>,
		var_pop?: ValueTypes["authUserSecurityKeys_var_pop_order_by"] | undefined | null | Variable<any, string>,
		var_samp?: ValueTypes["authUserSecurityKeys_var_samp_order_by"] | undefined | null | Variable<any, string>,
		variance?: ValueTypes["authUserSecurityKeys_variance_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "auth.user_security_keys" */
	["authUserSecurityKeys_arr_rel_insert_input"]: {
		data: Array<ValueTypes["authUserSecurityKeys_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["authUserSecurityKeys_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["authUserSecurityKeys_avg_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_avg_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
	["authUserSecurityKeys_bool_exp"]: {
		_and?: Array<ValueTypes["authUserSecurityKeys_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["authUserSecurityKeys_bool_exp"]> | undefined | null | Variable<any, string>,
		counter?: ValueTypes["bigint_comparison_exp"] | undefined | null | Variable<any, string>,
		credentialId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		credentialPublicKey?: ValueTypes["bytea_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		nickname?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		transports?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.user_security_keys" */
	["authUserSecurityKeys_constraint"]: authUserSecurityKeys_constraint;
	/** input type for incrementing numeric columns in table "auth.user_security_keys" */
	["authUserSecurityKeys_inc_input"]: {
		counter?: ValueTypes["bigint"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "auth.user_security_keys" */
	["authUserSecurityKeys_insert_input"]: {
		counter?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		credentialId?: string | undefined | null | Variable<any, string>,
		credentialPublicKey?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		nickname?: string | undefined | null | Variable<any, string>,
		transports?: string | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["authUserSecurityKeys_max_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		credentialId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		nickname?: boolean | `@${string}`,
		transports?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_max_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		credentialId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		nickname?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		transports?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["authUserSecurityKeys_min_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		credentialId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		nickname?: boolean | `@${string}`,
		transports?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_min_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		credentialId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		nickname?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		transports?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "auth.user_security_keys" */
	["authUserSecurityKeys_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["authUserSecurityKeys"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.user_security_keys" */
	["authUserSecurityKeys_on_conflict"]: {
		constraint: ValueTypes["authUserSecurityKeys_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["authUserSecurityKeys_update_column"]> | Variable<any, string>,
		where?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.user_security_keys". */
	["authUserSecurityKeys_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		credentialId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		credentialPublicKey?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		nickname?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		transports?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.user_security_keys */
	["authUserSecurityKeys_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_select_column"]: authUserSecurityKeys_select_column;
	/** input type for updating data in table "auth.user_security_keys" */
	["authUserSecurityKeys_set_input"]: {
		counter?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		credentialId?: string | undefined | null | Variable<any, string>,
		credentialPublicKey?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		nickname?: string | undefined | null | Variable<any, string>,
		transports?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["authUserSecurityKeys_stddev_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_pop on columns */
	["authUserSecurityKeys_stddev_pop_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_pop_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_samp on columns */
	["authUserSecurityKeys_stddev_samp_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_samp_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "authUserSecurityKeys" */
	["authUserSecurityKeys_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["authUserSecurityKeys_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["authUserSecurityKeys_stream_cursor_value_input"]: {
		counter?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		credentialId?: string | undefined | null | Variable<any, string>,
		credentialPublicKey?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		nickname?: string | undefined | null | Variable<any, string>,
		transports?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["authUserSecurityKeys_sum_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_sum_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_update_column"]: authUserSecurityKeys_update_column;
	["authUserSecurityKeys_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["authUserSecurityKeys_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["authUserSecurityKeys_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["authUserSecurityKeys_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["authUserSecurityKeys_var_pop_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_var_pop_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate var_samp on columns */
	["authUserSecurityKeys_var_samp_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_var_samp_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate variance on columns */
	["authUserSecurityKeys_variance_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_variance_order_by"]: {
		counter?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	["bigint"]: unknown;
	/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
	["bigint_comparison_exp"]: {
		_eq?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["bigint"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["bigint"]> | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "blog" */
	["blog"]: AliasType<{
		/** An object relationship */
		brand?: ValueTypes["brands"],
		brandId?: boolean | `@${string}`,
		/** An object relationship */
		category?: ValueTypes["blogCategory"],
		categoryId?: boolean | `@${string}`,
		content?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		featuredImageId?: boolean | `@${string}`,
		/** An object relationship */
		file?: ValueTypes["files"],
		id?: boolean | `@${string}`,
		metaDescription?: boolean | `@${string}`,
		/** An object relationship */
		project?: ValueTypes["project"],
		projectId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		slug?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		/** An object relationship */
		user?: ValueTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "blogCategory" */
	["blogCategory"]: AliasType<{
		blogs?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blog_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog"]],
		blogs_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blog_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog_aggregate"]],
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "blogCategory" */
	["blogCategory_aggregate"]: AliasType<{
		aggregate?: ValueTypes["blogCategory_aggregate_fields"],
		nodes?: ValueTypes["blogCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "blogCategory" */
	["blogCategory_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["blogCategory_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["blogCategory_max_fields"],
		min?: ValueTypes["blogCategory_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "blogCategory". All fields are combined with a logical 'AND'. */
	["blogCategory_bool_exp"]: {
		_and?: Array<ValueTypes["blogCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["blogCategory_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["blogCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		blogs?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>,
		blogs_aggregate?: ValueTypes["blog_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "blogCategory" */
	["blogCategory_constraint"]: blogCategory_constraint;
	/** input type for inserting data into table "blogCategory" */
	["blogCategory_insert_input"]: {
		blogs?: ValueTypes["blog_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["blogCategory_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["blogCategory_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "blogCategory" */
	["blogCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["blogCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "blogCategory" */
	["blogCategory_obj_rel_insert_input"]: {
		data: ValueTypes["blogCategory_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["blogCategory_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "blogCategory" */
	["blogCategory_on_conflict"]: {
		constraint: ValueTypes["blogCategory_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["blogCategory_update_column"]> | Variable<any, string>,
		where?: ValueTypes["blogCategory_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "blogCategory". */
	["blogCategory_order_by"]: {
		blogs_aggregate?: ValueTypes["blog_aggregate_order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: blogCategory */
	["blogCategory_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "blogCategory" */
	["blogCategory_select_column"]: blogCategory_select_column;
	/** input type for updating data in table "blogCategory" */
	["blogCategory_set_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "blogCategory" */
	["blogCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["blogCategory_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["blogCategory_stream_cursor_value_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "blogCategory" */
	["blogCategory_update_column"]: blogCategory_update_column;
	["blogCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["blogCategory_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["blogCategory_bool_exp"] | Variable<any, string>
	};
	/** fields of action: "blogPostGeneratorEnhancedForAmp" */
	["blogPostGeneratorEnhancedForAmp"]: AliasType<{
		/** the time at which this action was created */
		created_at?: boolean | `@${string}`,
		/** errors related to the invocation */
		errors?: boolean | `@${string}`,
		/** the unique id of an action */
		id?: boolean | `@${string}`,
		/** the output fields of this action */
		output?: ValueTypes["blogPostGeneratorEnhancedForAmpOutput"],
		__typename?: boolean | `@${string}`
	}>;
	["blogPostGeneratorEnhancedForAmpOutput"]: AliasType<{
		html?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "blog" */
	["blog_aggregate"]: AliasType<{
		aggregate?: ValueTypes["blog_aggregate_fields"],
		nodes?: ValueTypes["blog"],
		__typename?: boolean | `@${string}`
	}>;
	["blog_aggregate_bool_exp"]: {
		count?: ValueTypes["blog_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["blog_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "blog" */
	["blog_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["blog_max_fields"],
		min?: ValueTypes["blog_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "blog" */
	["blog_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["blog_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["blog_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "blog" */
	["blog_arr_rel_insert_input"]: {
		data: Array<ValueTypes["blog_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["blog_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "blog". All fields are combined with a logical 'AND'. */
	["blog_bool_exp"]: {
		_and?: Array<ValueTypes["blog_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["blog_bool_exp"]> | undefined | null | Variable<any, string>,
		brand?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		category?: ValueTypes["blogCategory_bool_exp"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		content?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		featuredImageId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		file?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		metaDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		project?: ValueTypes["project_bool_exp"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		slug?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		title?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "blog" */
	["blog_constraint"]: blog_constraint;
	/** input type for inserting data into table "blog" */
	["blog_insert_input"]: {
		brand?: ValueTypes["brands_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		category?: ValueTypes["blogCategory_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		content?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		featuredImageId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		file?: ValueTypes["files_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		metaDescription?: string | undefined | null | Variable<any, string>,
		project?: ValueTypes["project_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		slug?: string | undefined | null | Variable<any, string>,
		title?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_obj_rel_insert_input"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["blog_max_fields"]: AliasType<{
		brandId?: boolean | `@${string}`,
		categoryId?: boolean | `@${string}`,
		content?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		featuredImageId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		metaDescription?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		slug?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "blog" */
	["blog_max_order_by"]: {
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		content?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		featuredImageId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		metaDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		slug?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		title?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["blog_min_fields"]: AliasType<{
		brandId?: boolean | `@${string}`,
		categoryId?: boolean | `@${string}`,
		content?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		featuredImageId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		metaDescription?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		slug?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "blog" */
	["blog_min_order_by"]: {
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		content?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		featuredImageId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		metaDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		slug?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		title?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "blog" */
	["blog_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["blog"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "blog" */
	["blog_on_conflict"]: {
		constraint: ValueTypes["blog_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["blog_update_column"]> | Variable<any, string>,
		where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "blog". */
	["blog_order_by"]: {
		brand?: ValueTypes["brands_order_by"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		category?: ValueTypes["blogCategory_order_by"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		content?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		featuredImageId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		file?: ValueTypes["files_order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		metaDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		project?: ValueTypes["project_order_by"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		slug?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		title?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: blog */
	["blog_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "blog" */
	["blog_select_column"]: blog_select_column;
	/** input type for updating data in table "blog" */
	["blog_set_input"]: {
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		content?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		featuredImageId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		metaDescription?: string | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		slug?: string | undefined | null | Variable<any, string>,
		title?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "blog" */
	["blog_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["blog_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["blog_stream_cursor_value_input"]: {
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		categoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		content?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		featuredImageId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		metaDescription?: string | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		slug?: string | undefined | null | Variable<any, string>,
		title?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "blog" */
	["blog_update_column"]: blog_update_column;
	["blog_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["blog_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["blog_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "brands" */
	["brands"]: AliasType<{
		addressCity?: boolean | `@${string}`,
		addressComplement?: boolean | `@${string}`,
		addressDistrict?: boolean | `@${string}`,
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		addressNumber?: boolean | `@${string}`,
		addressStreet?: boolean | `@${string}`,
		addressUf?: boolean | `@${string}`,
		addressZipCode?: boolean | `@${string}`,
		archtypeAdherence?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		blogs?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blog_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog"]],
		blogs_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blog_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog_aggregate"]],
		brandName?: boolean | `@${string}`,
		colors?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		facebookUrl?: boolean | `@${string}`,
		fontPrimary?: boolean | `@${string}`,
		fontSecondary?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		instagramUrl?: boolean | `@${string}`,
		isBrand?: boolean | `@${string}`,
		linkedinUrl?: boolean | `@${string}`,
		logoFileId?: boolean | `@${string}`,
		personality?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		products?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["products_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["products_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["products"]],
		products_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["products_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["products_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["products_aggregate"]],
		segment?: boolean | `@${string}`,
		shortAbout?: boolean | `@${string}`,
		slogan?: boolean | `@${string}`,
		tiktokUrl?: boolean | `@${string}`,
		twitterUrl?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		voiceTone?: boolean | `@${string}`,
		whatsappNumber?: boolean | `@${string}`,
		youtubeUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "brands" */
	["brands_aggregate"]: AliasType<{
		aggregate?: ValueTypes["brands_aggregate_fields"],
		nodes?: ValueTypes["brands"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "brands" */
	["brands_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["brands_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["brands_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["brands_max_fields"],
		min?: ValueTypes["brands_min_fields"],
		stddev?: ValueTypes["brands_stddev_fields"],
		stddev_pop?: ValueTypes["brands_stddev_pop_fields"],
		stddev_samp?: ValueTypes["brands_stddev_samp_fields"],
		sum?: ValueTypes["brands_sum_fields"],
		var_pop?: ValueTypes["brands_var_pop_fields"],
		var_samp?: ValueTypes["brands_var_samp_fields"],
		variance?: ValueTypes["brands_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["brands_append_input"]: {
		archtypeAdherence?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		colors?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["brands_avg_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "brands". All fields are combined with a logical 'AND'. */
	["brands_bool_exp"]: {
		_and?: Array<ValueTypes["brands_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["brands_bool_exp"]> | undefined | null | Variable<any, string>,
		addressCity?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		addressComplement?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		addressDistrict?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		addressLatitude?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		addressLongitude?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		addressNumber?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		addressStreet?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		addressUf?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		addressZipCode?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		archtypeAdherence?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		blogs?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>,
		blogs_aggregate?: ValueTypes["blog_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		brandName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		colors?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		facebookUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		fontPrimary?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		fontSecondary?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		instagramUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		isBrand?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		linkedinUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		logoFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		personality?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		phoneNumber?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		productOrService?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		products?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>,
		products_aggregate?: ValueTypes["products_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		segment?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		shortAbout?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		slogan?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		tiktokUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		twitterUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		voiceTone?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		whatsappNumber?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		youtubeUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "brands" */
	["brands_constraint"]: brands_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["brands_delete_at_path_input"]: {
		archtypeAdherence?: Array<string> | undefined | null | Variable<any, string>,
		colors?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["brands_delete_elem_input"]: {
		archtypeAdherence?: number | undefined | null | Variable<any, string>,
		colors?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["brands_delete_key_input"]: {
		archtypeAdherence?: string | undefined | null | Variable<any, string>,
		colors?: string | undefined | null | Variable<any, string>
	};
	/** input type for incrementing numeric columns in table "brands" */
	["brands_inc_input"]: {
		addressLatitude?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		addressLongitude?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "brands" */
	["brands_insert_input"]: {
		addressCity?: string | undefined | null | Variable<any, string>,
		addressComplement?: string | undefined | null | Variable<any, string>,
		addressDistrict?: string | undefined | null | Variable<any, string>,
		addressLatitude?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		addressLongitude?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		addressNumber?: string | undefined | null | Variable<any, string>,
		addressStreet?: string | undefined | null | Variable<any, string>,
		addressUf?: string | undefined | null | Variable<any, string>,
		addressZipCode?: string | undefined | null | Variable<any, string>,
		archtypeAdherence?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		blogs?: ValueTypes["blog_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		colors?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		email?: string | undefined | null | Variable<any, string>,
		facebookUrl?: string | undefined | null | Variable<any, string>,
		fontPrimary?: string | undefined | null | Variable<any, string>,
		fontSecondary?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		instagramUrl?: string | undefined | null | Variable<any, string>,
		isBrand?: boolean | undefined | null | Variable<any, string>,
		linkedinUrl?: string | undefined | null | Variable<any, string>,
		logoFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		personality?: string | undefined | null | Variable<any, string>,
		phoneNumber?: string | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		products?: ValueTypes["products_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		segment?: string | undefined | null | Variable<any, string>,
		shortAbout?: string | undefined | null | Variable<any, string>,
		slogan?: string | undefined | null | Variable<any, string>,
		tiktokUrl?: string | undefined | null | Variable<any, string>,
		twitterUrl?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		voiceTone?: string | undefined | null | Variable<any, string>,
		whatsappNumber?: string | undefined | null | Variable<any, string>,
		youtubeUrl?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["brands_max_fields"]: AliasType<{
		addressCity?: boolean | `@${string}`,
		addressComplement?: boolean | `@${string}`,
		addressDistrict?: boolean | `@${string}`,
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		addressNumber?: boolean | `@${string}`,
		addressStreet?: boolean | `@${string}`,
		addressUf?: boolean | `@${string}`,
		addressZipCode?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		facebookUrl?: boolean | `@${string}`,
		fontPrimary?: boolean | `@${string}`,
		fontSecondary?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		instagramUrl?: boolean | `@${string}`,
		linkedinUrl?: boolean | `@${string}`,
		logoFileId?: boolean | `@${string}`,
		personality?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		shortAbout?: boolean | `@${string}`,
		slogan?: boolean | `@${string}`,
		tiktokUrl?: boolean | `@${string}`,
		twitterUrl?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		voiceTone?: boolean | `@${string}`,
		whatsappNumber?: boolean | `@${string}`,
		youtubeUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["brands_min_fields"]: AliasType<{
		addressCity?: boolean | `@${string}`,
		addressComplement?: boolean | `@${string}`,
		addressDistrict?: boolean | `@${string}`,
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		addressNumber?: boolean | `@${string}`,
		addressStreet?: boolean | `@${string}`,
		addressUf?: boolean | `@${string}`,
		addressZipCode?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		facebookUrl?: boolean | `@${string}`,
		fontPrimary?: boolean | `@${string}`,
		fontSecondary?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		instagramUrl?: boolean | `@${string}`,
		linkedinUrl?: boolean | `@${string}`,
		logoFileId?: boolean | `@${string}`,
		personality?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		shortAbout?: boolean | `@${string}`,
		slogan?: boolean | `@${string}`,
		tiktokUrl?: boolean | `@${string}`,
		twitterUrl?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		voiceTone?: boolean | `@${string}`,
		whatsappNumber?: boolean | `@${string}`,
		youtubeUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "brands" */
	["brands_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["brands"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "brands" */
	["brands_obj_rel_insert_input"]: {
		data: ValueTypes["brands_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["brands_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "brands" */
	["brands_on_conflict"]: {
		constraint: ValueTypes["brands_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["brands_update_column"]> | Variable<any, string>,
		where?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "brands". */
	["brands_order_by"]: {
		addressCity?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		addressComplement?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		addressDistrict?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		addressLatitude?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		addressLongitude?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		addressNumber?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		addressStreet?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		addressUf?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		addressZipCode?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		archtypeAdherence?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		blogs_aggregate?: ValueTypes["blog_aggregate_order_by"] | undefined | null | Variable<any, string>,
		brandName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		colors?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		facebookUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		fontPrimary?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		fontSecondary?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		instagramUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		isBrand?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		linkedinUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		logoFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		personality?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phoneNumber?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		productOrService?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		products_aggregate?: ValueTypes["products_aggregate_order_by"] | undefined | null | Variable<any, string>,
		segment?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		shortAbout?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		slogan?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		tiktokUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		twitterUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		voiceTone?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		whatsappNumber?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		youtubeUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: brands */
	["brands_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["brands_prepend_input"]: {
		archtypeAdherence?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		colors?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "brands" */
	["brands_select_column"]: brands_select_column;
	/** input type for updating data in table "brands" */
	["brands_set_input"]: {
		addressCity?: string | undefined | null | Variable<any, string>,
		addressComplement?: string | undefined | null | Variable<any, string>,
		addressDistrict?: string | undefined | null | Variable<any, string>,
		addressLatitude?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		addressLongitude?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		addressNumber?: string | undefined | null | Variable<any, string>,
		addressStreet?: string | undefined | null | Variable<any, string>,
		addressUf?: string | undefined | null | Variable<any, string>,
		addressZipCode?: string | undefined | null | Variable<any, string>,
		archtypeAdherence?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		colors?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		email?: string | undefined | null | Variable<any, string>,
		facebookUrl?: string | undefined | null | Variable<any, string>,
		fontPrimary?: string | undefined | null | Variable<any, string>,
		fontSecondary?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		instagramUrl?: string | undefined | null | Variable<any, string>,
		isBrand?: boolean | undefined | null | Variable<any, string>,
		linkedinUrl?: string | undefined | null | Variable<any, string>,
		logoFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		personality?: string | undefined | null | Variable<any, string>,
		phoneNumber?: string | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		segment?: string | undefined | null | Variable<any, string>,
		shortAbout?: string | undefined | null | Variable<any, string>,
		slogan?: string | undefined | null | Variable<any, string>,
		tiktokUrl?: string | undefined | null | Variable<any, string>,
		twitterUrl?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		voiceTone?: string | undefined | null | Variable<any, string>,
		whatsappNumber?: string | undefined | null | Variable<any, string>,
		youtubeUrl?: string | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["brands_stddev_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["brands_stddev_pop_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["brands_stddev_samp_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "brands" */
	["brands_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["brands_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["brands_stream_cursor_value_input"]: {
		addressCity?: string | undefined | null | Variable<any, string>,
		addressComplement?: string | undefined | null | Variable<any, string>,
		addressDistrict?: string | undefined | null | Variable<any, string>,
		addressLatitude?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		addressLongitude?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		addressNumber?: string | undefined | null | Variable<any, string>,
		addressStreet?: string | undefined | null | Variable<any, string>,
		addressUf?: string | undefined | null | Variable<any, string>,
		addressZipCode?: string | undefined | null | Variable<any, string>,
		archtypeAdherence?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		colors?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		email?: string | undefined | null | Variable<any, string>,
		facebookUrl?: string | undefined | null | Variable<any, string>,
		fontPrimary?: string | undefined | null | Variable<any, string>,
		fontSecondary?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		instagramUrl?: string | undefined | null | Variable<any, string>,
		isBrand?: boolean | undefined | null | Variable<any, string>,
		linkedinUrl?: string | undefined | null | Variable<any, string>,
		logoFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		personality?: string | undefined | null | Variable<any, string>,
		phoneNumber?: string | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		segment?: string | undefined | null | Variable<any, string>,
		shortAbout?: string | undefined | null | Variable<any, string>,
		slogan?: string | undefined | null | Variable<any, string>,
		tiktokUrl?: string | undefined | null | Variable<any, string>,
		twitterUrl?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		voiceTone?: string | undefined | null | Variable<any, string>,
		whatsappNumber?: string | undefined | null | Variable<any, string>,
		youtubeUrl?: string | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["brands_sum_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "brands" */
	["brands_update_column"]: brands_update_column;
	["brands_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["brands_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["brands_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["brands_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["brands_delete_key_input"] | undefined | null | Variable<any, string>,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["brands_inc_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["brands_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["brands_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["brands_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["brands_var_pop_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["brands_var_samp_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["brands_variance_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "storage.buckets" */
	["buckets"]: AliasType<{
		cacheControl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		downloadExpiration?: boolean | `@${string}`,
		files?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["files_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["files_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["files"]],
		files_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["files_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["files_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["files_aggregate"]],
		id?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		presignedUrlsEnabled?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "storage.buckets" */
	["buckets_aggregate"]: AliasType<{
		aggregate?: ValueTypes["buckets_aggregate_fields"],
		nodes?: ValueTypes["buckets"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "storage.buckets" */
	["buckets_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["buckets_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["buckets_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["buckets_max_fields"],
		min?: ValueTypes["buckets_min_fields"],
		stddev?: ValueTypes["buckets_stddev_fields"],
		stddev_pop?: ValueTypes["buckets_stddev_pop_fields"],
		stddev_samp?: ValueTypes["buckets_stddev_samp_fields"],
		sum?: ValueTypes["buckets_sum_fields"],
		var_pop?: ValueTypes["buckets_var_pop_fields"],
		var_samp?: ValueTypes["buckets_var_samp_fields"],
		variance?: ValueTypes["buckets_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["buckets_avg_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "storage.buckets". All fields are combined with a logical 'AND'. */
	["buckets_bool_exp"]: {
		_and?: Array<ValueTypes["buckets_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["buckets_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["buckets_bool_exp"]> | undefined | null | Variable<any, string>,
		cacheControl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		downloadExpiration?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		files?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>,
		files_aggregate?: ValueTypes["files_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		maxUploadFileSize?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		minUploadFileSize?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		presignedUrlsEnabled?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "storage.buckets" */
	["buckets_constraint"]: buckets_constraint;
	/** input type for incrementing numeric columns in table "storage.buckets" */
	["buckets_inc_input"]: {
		downloadExpiration?: number | undefined | null | Variable<any, string>,
		maxUploadFileSize?: number | undefined | null | Variable<any, string>,
		minUploadFileSize?: number | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "storage.buckets" */
	["buckets_insert_input"]: {
		cacheControl?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		downloadExpiration?: number | undefined | null | Variable<any, string>,
		files?: ValueTypes["files_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		id?: string | undefined | null | Variable<any, string>,
		maxUploadFileSize?: number | undefined | null | Variable<any, string>,
		minUploadFileSize?: number | undefined | null | Variable<any, string>,
		presignedUrlsEnabled?: boolean | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["buckets_max_fields"]: AliasType<{
		cacheControl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		downloadExpiration?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["buckets_min_fields"]: AliasType<{
		cacheControl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		downloadExpiration?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "storage.buckets" */
	["buckets_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["buckets"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "storage.buckets" */
	["buckets_obj_rel_insert_input"]: {
		data: ValueTypes["buckets_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["buckets_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "storage.buckets" */
	["buckets_on_conflict"]: {
		constraint: ValueTypes["buckets_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["buckets_update_column"]> | Variable<any, string>,
		where?: ValueTypes["buckets_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "storage.buckets". */
	["buckets_order_by"]: {
		cacheControl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		downloadExpiration?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		files_aggregate?: ValueTypes["files_aggregate_order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		maxUploadFileSize?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		minUploadFileSize?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		presignedUrlsEnabled?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: storage.buckets */
	["buckets_pk_columns_input"]: {
		id: string | Variable<any, string>
	};
	/** select columns of table "storage.buckets" */
	["buckets_select_column"]: buckets_select_column;
	/** input type for updating data in table "storage.buckets" */
	["buckets_set_input"]: {
		cacheControl?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		downloadExpiration?: number | undefined | null | Variable<any, string>,
		id?: string | undefined | null | Variable<any, string>,
		maxUploadFileSize?: number | undefined | null | Variable<any, string>,
		minUploadFileSize?: number | undefined | null | Variable<any, string>,
		presignedUrlsEnabled?: boolean | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["buckets_stddev_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["buckets_stddev_pop_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["buckets_stddev_samp_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "buckets" */
	["buckets_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["buckets_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["buckets_stream_cursor_value_input"]: {
		cacheControl?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		downloadExpiration?: number | undefined | null | Variable<any, string>,
		id?: string | undefined | null | Variable<any, string>,
		maxUploadFileSize?: number | undefined | null | Variable<any, string>,
		minUploadFileSize?: number | undefined | null | Variable<any, string>,
		presignedUrlsEnabled?: boolean | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["buckets_sum_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "storage.buckets" */
	["buckets_update_column"]: buckets_update_column;
	["buckets_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["buckets_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["buckets_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["buckets_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["buckets_var_pop_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["buckets_var_samp_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["buckets_variance_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["bytea"]: unknown;
	/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
	["bytea_comparison_exp"]: {
		_eq?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["bytea"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["bytea"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["bytea"]> | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "campaign" */
	["campaign"]: AliasType<{
		audiencesIds?: boolean | `@${string}`,
		baseJson?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		brandId?: boolean | `@${string}`,
		campaignObjective?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productIds?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "campaign" */
	["campaign_aggregate"]: AliasType<{
		aggregate?: ValueTypes["campaign_aggregate_fields"],
		nodes?: ValueTypes["campaign"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "campaign" */
	["campaign_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["campaign_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["campaign_max_fields"],
		min?: ValueTypes["campaign_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["campaign_append_input"]: {
		baseJson?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "campaign". All fields are combined with a logical 'AND'. */
	["campaign_bool_exp"]: {
		_and?: Array<ValueTypes["campaign_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["campaign_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["campaign_bool_exp"]> | undefined | null | Variable<any, string>,
		audiencesIds?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		baseJson?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		campaignObjective?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		productIds?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "campaign" */
	["campaign_constraint"]: campaign_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["campaign_delete_at_path_input"]: {
		baseJson?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["campaign_delete_elem_input"]: {
		baseJson?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["campaign_delete_key_input"]: {
		baseJson?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "campaign" */
	["campaign_insert_input"]: {
		audiencesIds?: string | undefined | null | Variable<any, string>,
		baseJson?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		campaignObjective?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		productIds?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["campaign_max_fields"]: AliasType<{
		audiencesIds?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignObjective?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productIds?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["campaign_min_fields"]: AliasType<{
		audiencesIds?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignObjective?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productIds?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "campaign" */
	["campaign_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["campaign"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "campaign" */
	["campaign_on_conflict"]: {
		constraint: ValueTypes["campaign_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["campaign_update_column"]> | Variable<any, string>,
		where?: ValueTypes["campaign_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "campaign". */
	["campaign_order_by"]: {
		audiencesIds?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		baseJson?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		campaignObjective?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		productIds?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: campaign */
	["campaign_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["campaign_prepend_input"]: {
		baseJson?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "campaign" */
	["campaign_select_column"]: campaign_select_column;
	/** input type for updating data in table "campaign" */
	["campaign_set_input"]: {
		audiencesIds?: string | undefined | null | Variable<any, string>,
		baseJson?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		campaignObjective?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		productIds?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "campaign" */
	["campaign_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["campaign_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["campaign_stream_cursor_value_input"]: {
		audiencesIds?: string | undefined | null | Variable<any, string>,
		baseJson?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		campaignObjective?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		productIds?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "campaign" */
	["campaign_update_column"]: campaign_update_column;
	["campaign_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["campaign_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["campaign_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["campaign_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["campaign_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["campaign_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["campaign_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["campaign_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "categorysummaryview" */
	["categorysummaryview"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		count?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "categorysummaryview" */
	["categorysummaryview_aggregate"]: AliasType<{
		aggregate?: ValueTypes["categorysummaryview_aggregate_fields"],
		nodes?: ValueTypes["categorysummaryview"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "categorysummaryview" */
	["categorysummaryview_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["categorysummaryview_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["categorysummaryview_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["categorysummaryview_max_fields"],
		min?: ValueTypes["categorysummaryview_min_fields"],
		stddev?: ValueTypes["categorysummaryview_stddev_fields"],
		stddev_pop?: ValueTypes["categorysummaryview_stddev_pop_fields"],
		stddev_samp?: ValueTypes["categorysummaryview_stddev_samp_fields"],
		sum?: ValueTypes["categorysummaryview_sum_fields"],
		var_pop?: ValueTypes["categorysummaryview_var_pop_fields"],
		var_samp?: ValueTypes["categorysummaryview_var_samp_fields"],
		variance?: ValueTypes["categorysummaryview_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["categorysummaryview_avg_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "categorysummaryview". All fields are combined with a logical 'AND'. */
	["categorysummaryview_bool_exp"]: {
		_and?: Array<ValueTypes["categorysummaryview_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["categorysummaryview_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["categorysummaryview_bool_exp"]> | undefined | null | Variable<any, string>,
		categoryName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["bigint_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["categorysummaryview_max_fields"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		count?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["categorysummaryview_min_fields"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		count?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Ordering options when selecting data from "categorysummaryview". */
	["categorysummaryview_order_by"]: {
		categoryName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "categorysummaryview" */
	["categorysummaryview_select_column"]: categorysummaryview_select_column;
	/** aggregate stddev on columns */
	["categorysummaryview_stddev_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["categorysummaryview_stddev_pop_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["categorysummaryview_stddev_samp_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "categorysummaryview" */
	["categorysummaryview_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["categorysummaryview_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["categorysummaryview_stream_cursor_value_input"]: {
		categoryName?: string | undefined | null | Variable<any, string>,
		count?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["categorysummaryview_sum_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_pop on columns */
	["categorysummaryview_var_pop_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["categorysummaryview_var_samp_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["categorysummaryview_variance_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "channels" */
	["channels"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "channels" */
	["channels_aggregate"]: AliasType<{
		aggregate?: ValueTypes["channels_aggregate_fields"],
		nodes?: ValueTypes["channels"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "channels" */
	["channels_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["channels_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["channels_max_fields"],
		min?: ValueTypes["channels_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
	["channels_bool_exp"]: {
		_and?: Array<ValueTypes["channels_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["channels_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["channels_bool_exp"]> | undefined | null | Variable<any, string>,
		alias?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamp_comparison_exp"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamp_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "channels" */
	["channels_constraint"]: channels_constraint;
	/** input type for inserting data into table "channels" */
	["channels_insert_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		displayName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["channels_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["channels_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "channels" */
	["channels_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["channels"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "channels" */
	["channels_on_conflict"]: {
		constraint: ValueTypes["channels_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["channels_update_column"]> | Variable<any, string>,
		where?: ValueTypes["channels_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "channels". */
	["channels_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: channels */
	["channels_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "channels" */
	["channels_select_column"]: channels_select_column;
	/** input type for updating data in table "channels" */
	["channels_set_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		displayName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "channels" */
	["channels_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["channels_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["channels_stream_cursor_value_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		displayName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "channels" */
	["channels_update_column"]: channels_update_column;
	["channels_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["channels_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["channels_bool_exp"] | Variable<any, string>
	};
	["citext"]: unknown;
	/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
	["citext_comparison_exp"]: {
		_eq?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		/** does the column match the given case-insensitive pattern */
		_ilike?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["citext"]> | undefined | null | Variable<any, string>,
		/** does the column match the given POSIX regular expression, case insensitive */
		_iregex?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		/** does the column match the given pattern */
		_like?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		/** does the column NOT match the given case-insensitive pattern */
		_nilike?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["citext"]> | undefined | null | Variable<any, string>,
		/** does the column NOT match the given POSIX regular expression, case insensitive */
		_niregex?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		/** does the column NOT match the given pattern */
		_nlike?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		/** does the column NOT match the given POSIX regular expression, case sensitive */
		_nregex?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		/** does the column NOT match the given SQL regular expression */
		_nsimilar?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		/** does the column match the given POSIX regular expression, case sensitive */
		_regex?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		/** does the column match the given SQL regular expression */
		_similar?: ValueTypes["citext"] | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "consumptionByChannelView" */
	["consumptionByChannelView"]: AliasType<{
		agendados?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "consumptionByChannelView" */
	["consumptionByChannelView_aggregate"]: AliasType<{
		aggregate?: ValueTypes["consumptionByChannelView_aggregate_fields"],
		nodes?: ValueTypes["consumptionByChannelView"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "consumptionByChannelView" */
	["consumptionByChannelView_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["consumptionByChannelView_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["consumptionByChannelView_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["consumptionByChannelView_max_fields"],
		min?: ValueTypes["consumptionByChannelView_min_fields"],
		stddev?: ValueTypes["consumptionByChannelView_stddev_fields"],
		stddev_pop?: ValueTypes["consumptionByChannelView_stddev_pop_fields"],
		stddev_samp?: ValueTypes["consumptionByChannelView_stddev_samp_fields"],
		sum?: ValueTypes["consumptionByChannelView_sum_fields"],
		var_pop?: ValueTypes["consumptionByChannelView_var_pop_fields"],
		var_samp?: ValueTypes["consumptionByChannelView_var_samp_fields"],
		variance?: ValueTypes["consumptionByChannelView_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["consumptionByChannelView_avg_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "consumptionByChannelView". All fields are combined with a logical 'AND'. */
	["consumptionByChannelView_bool_exp"]: {
		_and?: Array<ValueTypes["consumptionByChannelView_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["consumptionByChannelView_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["consumptionByChannelView_bool_exp"]> | undefined | null | Variable<any, string>,
		agendados?: ValueTypes["bigint_comparison_exp"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		copymagico?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		economia?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		mercado?: ValueTypes["bigint_comparison_exp"] | undefined | null | Variable<any, string>,
		publicados?: ValueTypes["bigint_comparison_exp"] | undefined | null | Variable<any, string>,
		rascunhos?: ValueTypes["bigint_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["consumptionByChannelView_max_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["consumptionByChannelView_min_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Ordering options when selecting data from "consumptionByChannelView". */
	["consumptionByChannelView_order_by"]: {
		agendados?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		copymagico?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		economia?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		mercado?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		publicados?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		rascunhos?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "consumptionByChannelView" */
	["consumptionByChannelView_select_column"]: consumptionByChannelView_select_column;
	/** aggregate stddev on columns */
	["consumptionByChannelView_stddev_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["consumptionByChannelView_stddev_pop_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["consumptionByChannelView_stddev_samp_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "consumptionByChannelView" */
	["consumptionByChannelView_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["consumptionByChannelView_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["consumptionByChannelView_stream_cursor_value_input"]: {
		agendados?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		channel?: string | undefined | null | Variable<any, string>,
		copymagico?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		economia?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		mercado?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		publicados?: ValueTypes["bigint"] | undefined | null | Variable<any, string>,
		rascunhos?: ValueTypes["bigint"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["consumptionByChannelView_sum_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_pop on columns */
	["consumptionByChannelView_var_pop_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["consumptionByChannelView_var_samp_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["consumptionByChannelView_variance_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "costTable" */
	["costTable"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		entityId?: boolean | `@${string}`,
		entityName?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		provider?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "costTable" */
	["costTable_aggregate"]: AliasType<{
		aggregate?: ValueTypes["costTable_aggregate_fields"],
		nodes?: ValueTypes["costTable"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "costTable" */
	["costTable_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["costTable_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["costTable_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["costTable_max_fields"],
		min?: ValueTypes["costTable_min_fields"],
		stddev?: ValueTypes["costTable_stddev_fields"],
		stddev_pop?: ValueTypes["costTable_stddev_pop_fields"],
		stddev_samp?: ValueTypes["costTable_stddev_samp_fields"],
		sum?: ValueTypes["costTable_sum_fields"],
		var_pop?: ValueTypes["costTable_var_pop_fields"],
		var_samp?: ValueTypes["costTable_var_samp_fields"],
		variance?: ValueTypes["costTable_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["costTable_avg_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "costTable". All fields are combined with a logical 'AND'. */
	["costTable_bool_exp"]: {
		_and?: Array<ValueTypes["costTable_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["costTable_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["costTable_bool_exp"]> | undefined | null | Variable<any, string>,
		bannerBear?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		entityId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		entityName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		gptCompletionTokens?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		gptPromptTokens?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		provider?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "costTable" */
	["costTable_constraint"]: costTable_constraint;
	/** input type for incrementing numeric columns in table "costTable" */
	["costTable_inc_input"]: {
		bannerBear?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		gptCompletionTokens?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		gptPromptTokens?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "costTable" */
	["costTable_insert_input"]: {
		bannerBear?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		entityId?: string | undefined | null | Variable<any, string>,
		entityName?: string | undefined | null | Variable<any, string>,
		gptCompletionTokens?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		gptPromptTokens?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		provider?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["costTable_max_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		entityId?: boolean | `@${string}`,
		entityName?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		provider?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["costTable_min_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		entityId?: boolean | `@${string}`,
		entityName?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		provider?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "costTable" */
	["costTable_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["costTable"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "costTable" */
	["costTable_on_conflict"]: {
		constraint: ValueTypes["costTable_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["costTable_update_column"]> | Variable<any, string>,
		where?: ValueTypes["costTable_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "costTable". */
	["costTable_order_by"]: {
		bannerBear?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		entityId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		entityName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		gptCompletionTokens?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		gptPromptTokens?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		provider?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: costTable */
	["costTable_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "costTable" */
	["costTable_select_column"]: costTable_select_column;
	/** input type for updating data in table "costTable" */
	["costTable_set_input"]: {
		bannerBear?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		entityId?: string | undefined | null | Variable<any, string>,
		entityName?: string | undefined | null | Variable<any, string>,
		gptCompletionTokens?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		gptPromptTokens?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		provider?: string | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["costTable_stddev_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["costTable_stddev_pop_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["costTable_stddev_samp_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "costTable" */
	["costTable_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["costTable_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["costTable_stream_cursor_value_input"]: {
		bannerBear?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		entityId?: string | undefined | null | Variable<any, string>,
		entityName?: string | undefined | null | Variable<any, string>,
		gptCompletionTokens?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		gptPromptTokens?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		provider?: string | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["costTable_sum_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "costTable" */
	["costTable_update_column"]: costTable_update_column;
	["costTable_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["costTable_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["costTable_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["costTable_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["costTable_var_pop_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["costTable_var_samp_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["costTable_variance_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** ordering argument of a cursor */
	["cursor_ordering"]: cursor_ordering;
	["date"]: unknown;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
	["date_comparison_exp"]: {
		_eq?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["date"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["date"]> | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "deal" */
	["deal"]: AliasType<{
		created_at?: boolean | `@${string}`,
		/** An object relationship */
		dealStatus?: ValueTypes["dealStatus"],
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		interactions?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["interaction_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["interaction_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction"]],
		interactions_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["interaction_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["interaction_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction_aggregate"]],
		name?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		/** An object relationship */
		product?: ValueTypes["products"],
		productId?: boolean | `@${string}`,
		rating?: boolean | `@${string}`,
		statusId?: boolean | `@${string}`,
		tasks?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["task_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["task_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["task"]],
		tasks_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["task_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["task_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["task_aggregate"]],
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "dealStatus" */
	["dealStatus"]: AliasType<{
		deals?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["deal_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["deal_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal"]],
		deals_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["deal_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["deal_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal_aggregate"]],
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "dealStatus" */
	["dealStatus_aggregate"]: AliasType<{
		aggregate?: ValueTypes["dealStatus_aggregate_fields"],
		nodes?: ValueTypes["dealStatus"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "dealStatus" */
	["dealStatus_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["dealStatus_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["dealStatus_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["dealStatus_max_fields"],
		min?: ValueTypes["dealStatus_min_fields"],
		stddev?: ValueTypes["dealStatus_stddev_fields"],
		stddev_pop?: ValueTypes["dealStatus_stddev_pop_fields"],
		stddev_samp?: ValueTypes["dealStatus_stddev_samp_fields"],
		sum?: ValueTypes["dealStatus_sum_fields"],
		var_pop?: ValueTypes["dealStatus_var_pop_fields"],
		var_samp?: ValueTypes["dealStatus_var_samp_fields"],
		variance?: ValueTypes["dealStatus_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["dealStatus_avg_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "dealStatus". All fields are combined with a logical 'AND'. */
	["dealStatus_bool_exp"]: {
		_and?: Array<ValueTypes["dealStatus_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["dealStatus_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["dealStatus_bool_exp"]> | undefined | null | Variable<any, string>,
		deals?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>,
		deals_aggregate?: ValueTypes["deal_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		order?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "dealStatus" */
	["dealStatus_constraint"]: dealStatus_constraint;
	/** input type for incrementing numeric columns in table "dealStatus" */
	["dealStatus_inc_input"]: {
		order?: number | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "dealStatus" */
	["dealStatus_insert_input"]: {
		deals?: ValueTypes["deal_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		displayName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		order?: number | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["dealStatus_max_fields"]: AliasType<{
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["dealStatus_min_fields"]: AliasType<{
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "dealStatus" */
	["dealStatus_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["dealStatus"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "dealStatus" */
	["dealStatus_obj_rel_insert_input"]: {
		data: ValueTypes["dealStatus_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["dealStatus_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "dealStatus" */
	["dealStatus_on_conflict"]: {
		constraint: ValueTypes["dealStatus_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["dealStatus_update_column"]> | Variable<any, string>,
		where?: ValueTypes["dealStatus_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "dealStatus". */
	["dealStatus_order_by"]: {
		deals_aggregate?: ValueTypes["deal_aggregate_order_by"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		order?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: dealStatus */
	["dealStatus_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "dealStatus" */
	["dealStatus_select_column"]: dealStatus_select_column;
	/** input type for updating data in table "dealStatus" */
	["dealStatus_set_input"]: {
		displayName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		order?: number | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["dealStatus_stddev_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["dealStatus_stddev_pop_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["dealStatus_stddev_samp_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "dealStatus" */
	["dealStatus_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["dealStatus_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["dealStatus_stream_cursor_value_input"]: {
		displayName?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		order?: number | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["dealStatus_sum_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "dealStatus" */
	["dealStatus_update_column"]: dealStatus_update_column;
	["dealStatus_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["dealStatus_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["dealStatus_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["dealStatus_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["dealStatus_var_pop_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["dealStatus_var_samp_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["dealStatus_variance_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "deal" */
	["deal_aggregate"]: AliasType<{
		aggregate?: ValueTypes["deal_aggregate_fields"],
		nodes?: ValueTypes["deal"],
		__typename?: boolean | `@${string}`
	}>;
	["deal_aggregate_bool_exp"]: {
		count?: ValueTypes["deal_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["deal_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["deal_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "deal" */
	["deal_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["deal_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["deal_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["deal_max_fields"],
		min?: ValueTypes["deal_min_fields"],
		stddev?: ValueTypes["deal_stddev_fields"],
		stddev_pop?: ValueTypes["deal_stddev_pop_fields"],
		stddev_samp?: ValueTypes["deal_stddev_samp_fields"],
		sum?: ValueTypes["deal_sum_fields"],
		var_pop?: ValueTypes["deal_var_pop_fields"],
		var_samp?: ValueTypes["deal_var_samp_fields"],
		variance?: ValueTypes["deal_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "deal" */
	["deal_aggregate_order_by"]: {
		avg?: ValueTypes["deal_avg_order_by"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["deal_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["deal_min_order_by"] | undefined | null | Variable<any, string>,
		stddev?: ValueTypes["deal_stddev_order_by"] | undefined | null | Variable<any, string>,
		stddev_pop?: ValueTypes["deal_stddev_pop_order_by"] | undefined | null | Variable<any, string>,
		stddev_samp?: ValueTypes["deal_stddev_samp_order_by"] | undefined | null | Variable<any, string>,
		sum?: ValueTypes["deal_sum_order_by"] | undefined | null | Variable<any, string>,
		var_pop?: ValueTypes["deal_var_pop_order_by"] | undefined | null | Variable<any, string>,
		var_samp?: ValueTypes["deal_var_samp_order_by"] | undefined | null | Variable<any, string>,
		variance?: ValueTypes["deal_variance_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "deal" */
	["deal_arr_rel_insert_input"]: {
		data: Array<ValueTypes["deal_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["deal_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["deal_avg_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "deal" */
	["deal_avg_order_by"]: {
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "deal". All fields are combined with a logical 'AND'. */
	["deal_bool_exp"]: {
		_and?: Array<ValueTypes["deal_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["deal_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		dealStatus?: ValueTypes["dealStatus_bool_exp"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		interactions?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>,
		interactions_aggregate?: ValueTypes["interaction_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		phone?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		product?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		rating?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		statusId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		tasks?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>,
		tasks_aggregate?: ValueTypes["task_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "deal" */
	["deal_constraint"]: deal_constraint;
	/** input type for incrementing numeric columns in table "deal" */
	["deal_inc_input"]: {
		rating?: number | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "deal" */
	["deal_insert_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		dealStatus?: ValueTypes["dealStatus_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		email?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		interactions?: ValueTypes["interaction_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		phone?: string | undefined | null | Variable<any, string>,
		product?: ValueTypes["products_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		rating?: number | undefined | null | Variable<any, string>,
		statusId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		tasks?: ValueTypes["task_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["deal_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		rating?: boolean | `@${string}`,
		statusId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "deal" */
	["deal_max_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phone?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		statusId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["deal_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		rating?: boolean | `@${string}`,
		statusId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "deal" */
	["deal_min_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phone?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		statusId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "deal" */
	["deal_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["deal"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "deal" */
	["deal_obj_rel_insert_input"]: {
		data: ValueTypes["deal_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["deal_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "deal" */
	["deal_on_conflict"]: {
		constraint: ValueTypes["deal_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["deal_update_column"]> | Variable<any, string>,
		where?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "deal". */
	["deal_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		dealStatus?: ValueTypes["dealStatus_order_by"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		interactions_aggregate?: ValueTypes["interaction_aggregate_order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phone?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		product?: ValueTypes["products_order_by"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		statusId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		tasks_aggregate?: ValueTypes["task_aggregate_order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: deal */
	["deal_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "deal" */
	["deal_select_column"]: deal_select_column;
	/** input type for updating data in table "deal" */
	["deal_set_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		email?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		phone?: string | undefined | null | Variable<any, string>,
		productId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		rating?: number | undefined | null | Variable<any, string>,
		statusId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["deal_stddev_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "deal" */
	["deal_stddev_order_by"]: {
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_pop on columns */
	["deal_stddev_pop_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "deal" */
	["deal_stddev_pop_order_by"]: {
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_samp on columns */
	["deal_stddev_samp_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "deal" */
	["deal_stddev_samp_order_by"]: {
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "deal" */
	["deal_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["deal_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["deal_stream_cursor_value_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		email?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		phone?: string | undefined | null | Variable<any, string>,
		productId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		rating?: number | undefined | null | Variable<any, string>,
		statusId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["deal_sum_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "deal" */
	["deal_sum_order_by"]: {
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "deal" */
	["deal_update_column"]: deal_update_column;
	["deal_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["deal_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["deal_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["deal_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["deal_var_pop_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "deal" */
	["deal_var_pop_order_by"]: {
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate var_samp on columns */
	["deal_var_samp_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "deal" */
	["deal_var_samp_order_by"]: {
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate variance on columns */
	["deal_variance_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "deal" */
	["deal_variance_order_by"]: {
		rating?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "displayCategory" */
	["displayCategory"]: AliasType<{
		created_at?: boolean | `@${string}`,
		displayName?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "displayCategory" */
	["displayCategory_aggregate"]: AliasType<{
		aggregate?: ValueTypes["displayCategory_aggregate_fields"],
		nodes?: ValueTypes["displayCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "displayCategory" */
	["displayCategory_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["displayCategory_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["displayCategory_max_fields"],
		min?: ValueTypes["displayCategory_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayCategory_append_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "displayCategory". All fields are combined with a logical 'AND'. */
	["displayCategory_bool_exp"]: {
		_and?: Array<ValueTypes["displayCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["displayCategory_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["displayCategory_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "displayCategory" */
	["displayCategory_constraint"]: displayCategory_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayCategory_delete_at_path_input"]: {
		displayName?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayCategory_delete_elem_input"]: {
		displayName?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayCategory_delete_key_input"]: {
		displayName?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "displayCategory" */
	["displayCategory_insert_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["displayCategory_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["displayCategory_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "displayCategory" */
	["displayCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["displayCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "displayCategory" */
	["displayCategory_on_conflict"]: {
		constraint: ValueTypes["displayCategory_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["displayCategory_update_column"]> | Variable<any, string>,
		where?: ValueTypes["displayCategory_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "displayCategory". */
	["displayCategory_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: displayCategory */
	["displayCategory_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayCategory_prepend_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "displayCategory" */
	["displayCategory_select_column"]: displayCategory_select_column;
	/** input type for updating data in table "displayCategory" */
	["displayCategory_set_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "displayCategory" */
	["displayCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["displayCategory_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["displayCategory_stream_cursor_value_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "displayCategory" */
	["displayCategory_update_column"]: displayCategory_update_column;
	["displayCategory_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["displayCategory_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["displayCategory_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["displayCategory_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["displayCategory_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["displayCategory_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["displayCategory_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["displayCategory_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "displayDimension" */
	["displayDimension"]: AliasType<{
		displayName?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "displayDimension" */
	["displayDimension_aggregate"]: AliasType<{
		aggregate?: ValueTypes["displayDimension_aggregate_fields"],
		nodes?: ValueTypes["displayDimension"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "displayDimension" */
	["displayDimension_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["displayDimension_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["displayDimension_max_fields"],
		min?: ValueTypes["displayDimension_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayDimension_append_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "displayDimension". All fields are combined with a logical 'AND'. */
	["displayDimension_bool_exp"]: {
		_and?: Array<ValueTypes["displayDimension_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["displayDimension_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["displayDimension_bool_exp"]> | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "displayDimension" */
	["displayDimension_constraint"]: displayDimension_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayDimension_delete_at_path_input"]: {
		displayName?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayDimension_delete_elem_input"]: {
		displayName?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayDimension_delete_key_input"]: {
		displayName?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "displayDimension" */
	["displayDimension_insert_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["displayDimension_max_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["displayDimension_min_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "displayDimension" */
	["displayDimension_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["displayDimension"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "displayDimension" */
	["displayDimension_obj_rel_insert_input"]: {
		data: ValueTypes["displayDimension_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["displayDimension_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "displayDimension" */
	["displayDimension_on_conflict"]: {
		constraint: ValueTypes["displayDimension_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["displayDimension_update_column"]> | Variable<any, string>,
		where?: ValueTypes["displayDimension_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "displayDimension". */
	["displayDimension_order_by"]: {
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: displayDimension */
	["displayDimension_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayDimension_prepend_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "displayDimension" */
	["displayDimension_select_column"]: displayDimension_select_column;
	/** input type for updating data in table "displayDimension" */
	["displayDimension_set_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "displayDimension" */
	["displayDimension_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["displayDimension_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["displayDimension_stream_cursor_value_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "displayDimension" */
	["displayDimension_update_column"]: displayDimension_update_column;
	["displayDimension_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["displayDimension_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["displayDimension_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["displayDimension_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["displayDimension_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["displayDimension_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["displayDimension_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["displayDimension_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "displayFormats" */
	["displayFormats"]: AliasType<{
		bannerbearId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayCategoryId?: boolean | `@${string}`,
		/** An object relationship */
		displayDimension?: ValueTypes["displayDimension"],
		displayDimensionId?: boolean | `@${string}`,
		displayName?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		id?: boolean | `@${string}`,
		mapper?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		name?: boolean | `@${string}`,
		newData?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		newTemplate?: boolean | `@${string}`,
		requiresBrand?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "displayFormats" */
	["displayFormats_aggregate"]: AliasType<{
		aggregate?: ValueTypes["displayFormats_aggregate_fields"],
		nodes?: ValueTypes["displayFormats"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "displayFormats" */
	["displayFormats_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["displayFormats_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["displayFormats_max_fields"],
		min?: ValueTypes["displayFormats_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayFormats_append_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		mapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		newData?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "displayFormats". All fields are combined with a logical 'AND'. */
	["displayFormats_bool_exp"]: {
		_and?: Array<ValueTypes["displayFormats_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["displayFormats_bool_exp"]> | undefined | null | Variable<any, string>,
		bannerbearId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		displayCategoryId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		displayDimension?: ValueTypes["displayDimension_bool_exp"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		mapper?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		newData?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		newTemplate?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		requiresBrand?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "displayFormats" */
	["displayFormats_constraint"]: displayFormats_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayFormats_delete_at_path_input"]: {
		displayName?: Array<string> | undefined | null | Variable<any, string>,
		mapper?: Array<string> | undefined | null | Variable<any, string>,
		newData?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayFormats_delete_elem_input"]: {
		displayName?: number | undefined | null | Variable<any, string>,
		mapper?: number | undefined | null | Variable<any, string>,
		newData?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayFormats_delete_key_input"]: {
		displayName?: string | undefined | null | Variable<any, string>,
		mapper?: string | undefined | null | Variable<any, string>,
		newData?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "displayFormats" */
	["displayFormats_insert_input"]: {
		bannerbearId?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		displayCategoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayDimension?: ValueTypes["displayDimension_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		mapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		newData?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		newTemplate?: string | undefined | null | Variable<any, string>,
		requiresBrand?: boolean | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["displayFormats_max_fields"]: AliasType<{
		bannerbearId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayCategoryId?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplate?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["displayFormats_min_fields"]: AliasType<{
		bannerbearId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayCategoryId?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplate?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "displayFormats" */
	["displayFormats_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["displayFormats"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "displayFormats" */
	["displayFormats_obj_rel_insert_input"]: {
		data: ValueTypes["displayFormats_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["displayFormats_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "displayFormats" */
	["displayFormats_on_conflict"]: {
		constraint: ValueTypes["displayFormats_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["displayFormats_update_column"]> | Variable<any, string>,
		where?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "displayFormats". */
	["displayFormats_order_by"]: {
		bannerbearId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayCategoryId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayDimension?: ValueTypes["displayDimension_order_by"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		mapper?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		newData?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		newTemplate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		requiresBrand?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: displayFormats */
	["displayFormats_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayFormats_prepend_input"]: {
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		mapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		newData?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "displayFormats" */
	["displayFormats_select_column"]: displayFormats_select_column;
	/** input type for updating data in table "displayFormats" */
	["displayFormats_set_input"]: {
		bannerbearId?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		displayCategoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		mapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		newData?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		newTemplate?: string | undefined | null | Variable<any, string>,
		requiresBrand?: boolean | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "displayFormats" */
	["displayFormats_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["displayFormats_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["displayFormats_stream_cursor_value_input"]: {
		bannerbearId?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		displayCategoryId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayDimensionId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		mapper?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		newData?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		newTemplate?: string | undefined | null | Variable<any, string>,
		requiresBrand?: boolean | undefined | null | Variable<any, string>
	};
	/** update columns of table "displayFormats" */
	["displayFormats_update_column"]: displayFormats_update_column;
	["displayFormats_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["displayFormats_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["displayFormats_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["displayFormats_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["displayFormats_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["displayFormats_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["displayFormats_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["displayFormats_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "displayTemplate" */
	["displayTemplate"]: AliasType<{
		alias?: boolean | `@${string}`,
		fields?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		id?: boolean | `@${string}`,
		template?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "displayTemplate" */
	["displayTemplate_aggregate"]: AliasType<{
		aggregate?: ValueTypes["displayTemplate_aggregate_fields"],
		nodes?: ValueTypes["displayTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "displayTemplate" */
	["displayTemplate_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["displayTemplate_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["displayTemplate_max_fields"],
		min?: ValueTypes["displayTemplate_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayTemplate_append_input"]: {
		fields?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "displayTemplate". All fields are combined with a logical 'AND'. */
	["displayTemplate_bool_exp"]: {
		_and?: Array<ValueTypes["displayTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["displayTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["displayTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		alias?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		fields?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		template?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "displayTemplate" */
	["displayTemplate_constraint"]: displayTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayTemplate_delete_at_path_input"]: {
		fields?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayTemplate_delete_elem_input"]: {
		fields?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayTemplate_delete_key_input"]: {
		fields?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "displayTemplate" */
	["displayTemplate_insert_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		fields?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		template?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["displayTemplate_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		template?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["displayTemplate_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		template?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "displayTemplate" */
	["displayTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["displayTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "displayTemplate" */
	["displayTemplate_on_conflict"]: {
		constraint: ValueTypes["displayTemplate_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["displayTemplate_update_column"]> | Variable<any, string>,
		where?: ValueTypes["displayTemplate_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "displayTemplate". */
	["displayTemplate_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		fields?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		template?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: displayTemplate */
	["displayTemplate_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayTemplate_prepend_input"]: {
		fields?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "displayTemplate" */
	["displayTemplate_select_column"]: displayTemplate_select_column;
	/** input type for updating data in table "displayTemplate" */
	["displayTemplate_set_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		fields?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		template?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "displayTemplate" */
	["displayTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["displayTemplate_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["displayTemplate_stream_cursor_value_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		fields?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		template?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "displayTemplate" */
	["displayTemplate_update_column"]: displayTemplate_update_column;
	["displayTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["displayTemplate_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["displayTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["displayTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["displayTemplate_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["displayTemplate_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["displayTemplate_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["displayTemplate_bool_exp"] | Variable<any, string>
	};
	["facebookPage"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "storage.files" */
	["files"]: AliasType<{
		/** An object relationship */
		bucket?: ValueTypes["buckets"],
		bucketId?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		etag?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		isUploaded?: boolean | `@${string}`,
		metadata?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		mimeType?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		size?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		uploadedByUserId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "storage.files" */
	["files_aggregate"]: AliasType<{
		aggregate?: ValueTypes["files_aggregate_fields"],
		nodes?: ValueTypes["files"],
		__typename?: boolean | `@${string}`
	}>;
	["files_aggregate_bool_exp"]: {
		bool_and?: ValueTypes["files_aggregate_bool_exp_bool_and"] | undefined | null | Variable<any, string>,
		bool_or?: ValueTypes["files_aggregate_bool_exp_bool_or"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["files_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["files_aggregate_bool_exp_bool_and"]: {
		arguments: ValueTypes["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["files_aggregate_bool_exp_bool_or"]: {
		arguments: ValueTypes["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["files_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["files_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "storage.files" */
	["files_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["files_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["files_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["files_max_fields"],
		min?: ValueTypes["files_min_fields"],
		stddev?: ValueTypes["files_stddev_fields"],
		stddev_pop?: ValueTypes["files_stddev_pop_fields"],
		stddev_samp?: ValueTypes["files_stddev_samp_fields"],
		sum?: ValueTypes["files_sum_fields"],
		var_pop?: ValueTypes["files_var_pop_fields"],
		var_samp?: ValueTypes["files_var_samp_fields"],
		variance?: ValueTypes["files_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "storage.files" */
	["files_aggregate_order_by"]: {
		avg?: ValueTypes["files_avg_order_by"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["files_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["files_min_order_by"] | undefined | null | Variable<any, string>,
		stddev?: ValueTypes["files_stddev_order_by"] | undefined | null | Variable<any, string>,
		stddev_pop?: ValueTypes["files_stddev_pop_order_by"] | undefined | null | Variable<any, string>,
		stddev_samp?: ValueTypes["files_stddev_samp_order_by"] | undefined | null | Variable<any, string>,
		sum?: ValueTypes["files_sum_order_by"] | undefined | null | Variable<any, string>,
		var_pop?: ValueTypes["files_var_pop_order_by"] | undefined | null | Variable<any, string>,
		var_samp?: ValueTypes["files_var_samp_order_by"] | undefined | null | Variable<any, string>,
		variance?: ValueTypes["files_variance_order_by"] | undefined | null | Variable<any, string>
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["files_append_input"]: {
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "storage.files" */
	["files_arr_rel_insert_input"]: {
		data: Array<ValueTypes["files_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["files_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["files_avg_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "storage.files" */
	["files_avg_order_by"]: {
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
	["files_bool_exp"]: {
		_and?: Array<ValueTypes["files_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["files_bool_exp"]> | undefined | null | Variable<any, string>,
		bucket?: ValueTypes["buckets_bool_exp"] | undefined | null | Variable<any, string>,
		bucketId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		etag?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		isUploaded?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		mimeType?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		size?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		uploadedByUserId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "storage.files" */
	["files_constraint"]: files_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["files_delete_at_path_input"]: {
		metadata?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["files_delete_elem_input"]: {
		metadata?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["files_delete_key_input"]: {
		metadata?: string | undefined | null | Variable<any, string>
	};
	/** input type for incrementing numeric columns in table "storage.files" */
	["files_inc_input"]: {
		size?: number | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "storage.files" */
	["files_insert_input"]: {
		bucket?: ValueTypes["buckets_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		bucketId?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		etag?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		isUploaded?: boolean | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		mimeType?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		size?: number | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		uploadedByUserId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["files_max_fields"]: AliasType<{
		bucketId?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		etag?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mimeType?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		size?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		uploadedByUserId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "storage.files" */
	["files_max_order_by"]: {
		bucketId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		etag?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		mimeType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		uploadedByUserId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["files_min_fields"]: AliasType<{
		bucketId?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		etag?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mimeType?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		size?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		uploadedByUserId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "storage.files" */
	["files_min_order_by"]: {
		bucketId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		etag?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		mimeType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		uploadedByUserId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "storage.files" */
	["files_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["files"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "storage.files" */
	["files_obj_rel_insert_input"]: {
		data: ValueTypes["files_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["files_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "storage.files" */
	["files_on_conflict"]: {
		constraint: ValueTypes["files_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["files_update_column"]> | Variable<any, string>,
		where?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "storage.files". */
	["files_order_by"]: {
		bucket?: ValueTypes["buckets_order_by"] | undefined | null | Variable<any, string>,
		bucketId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		etag?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		isUploaded?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		mimeType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		uploadedByUserId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: storage.files */
	["files_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["files_prepend_input"]: {
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "storage.files" */
	["files_select_column"]: files_select_column;
	/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage.files" */
	["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"]: files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage.files" */
	["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"]: files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "storage.files" */
	["files_set_input"]: {
		bucketId?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		etag?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		isUploaded?: boolean | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		mimeType?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		size?: number | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		uploadedByUserId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["files_stddev_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "storage.files" */
	["files_stddev_order_by"]: {
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_pop on columns */
	["files_stddev_pop_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "storage.files" */
	["files_stddev_pop_order_by"]: {
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_samp on columns */
	["files_stddev_samp_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "storage.files" */
	["files_stddev_samp_order_by"]: {
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "files" */
	["files_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["files_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["files_stream_cursor_value_input"]: {
		bucketId?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		etag?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		isUploaded?: boolean | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		mimeType?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		size?: number | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		uploadedByUserId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["files_sum_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "storage.files" */
	["files_sum_order_by"]: {
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "storage.files" */
	["files_update_column"]: files_update_column;
	["files_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["files_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["files_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["files_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["files_delete_key_input"] | undefined | null | Variable<any, string>,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["files_inc_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["files_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["files_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["files_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["files_var_pop_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "storage.files" */
	["files_var_pop_order_by"]: {
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate var_samp on columns */
	["files_var_samp_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "storage.files" */
	["files_var_samp_order_by"]: {
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate variance on columns */
	["files_variance_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "storage.files" */
	["files_variance_order_by"]: {
		size?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** fields of action: "generateNextMonthCalendar" */
	["generateNextMonthCalendar"]: AliasType<{
		/** the time at which this action was created */
		created_at?: boolean | `@${string}`,
		/** errors related to the invocation */
		errors?: boolean | `@${string}`,
		/** the unique id of an action */
		id?: boolean | `@${string}`,
		/** the output fields of this action */
		output?: ValueTypes["GenerateNextMonthCalendarOutput"],
		__typename?: boolean | `@${string}`
	}>;
	["instagramPageAccount"]: AliasType<{
		id?: boolean | `@${string}`,
		instagramBusinessAccountId?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "interaction" */
	["interaction"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		observation?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		/** An object relationship */
		user?: ValueTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "interaction" */
	["interaction_aggregate"]: AliasType<{
		aggregate?: ValueTypes["interaction_aggregate_fields"],
		nodes?: ValueTypes["interaction"],
		__typename?: boolean | `@${string}`
	}>;
	["interaction_aggregate_bool_exp"]: {
		bool_and?: ValueTypes["interaction_aggregate_bool_exp_bool_and"] | undefined | null | Variable<any, string>,
		bool_or?: ValueTypes["interaction_aggregate_bool_exp_bool_or"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["interaction_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["interaction_aggregate_bool_exp_bool_and"]: {
		arguments: ValueTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["interaction_aggregate_bool_exp_bool_or"]: {
		arguments: ValueTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["interaction_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["interaction_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "interaction" */
	["interaction_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["interaction_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["interaction_max_fields"],
		min?: ValueTypes["interaction_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "interaction" */
	["interaction_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["interaction_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["interaction_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "interaction" */
	["interaction_arr_rel_insert_input"]: {
		data: Array<ValueTypes["interaction_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["interaction_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "interaction". All fields are combined with a logical 'AND'. */
	["interaction_bool_exp"]: {
		_and?: Array<ValueTypes["interaction_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["interaction_bool_exp"]> | undefined | null | Variable<any, string>,
		channel?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		observation?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		success?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "interaction" */
	["interaction_constraint"]: interaction_constraint;
	/** input type for inserting data into table "interaction" */
	["interaction_insert_input"]: {
		channel?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		observation?: string | undefined | null | Variable<any, string>,
		success?: boolean | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_obj_rel_insert_input"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["interaction_max_fields"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		observation?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "interaction" */
	["interaction_max_order_by"]: {
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		observation?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["interaction_min_fields"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		observation?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "interaction" */
	["interaction_min_order_by"]: {
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		observation?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "interaction" */
	["interaction_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["interaction"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "interaction" */
	["interaction_on_conflict"]: {
		constraint: ValueTypes["interaction_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["interaction_update_column"]> | Variable<any, string>,
		where?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "interaction". */
	["interaction_order_by"]: {
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		observation?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		success?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: interaction */
	["interaction_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "interaction" */
	["interaction_select_column"]: interaction_select_column;
	/** select "interaction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "interaction" */
	["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"]: interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "interaction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "interaction" */
	["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"]: interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "interaction" */
	["interaction_set_input"]: {
		channel?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		observation?: string | undefined | null | Variable<any, string>,
		success?: boolean | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "interaction" */
	["interaction_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["interaction_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["interaction_stream_cursor_value_input"]: {
		channel?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		observation?: string | undefined | null | Variable<any, string>,
		success?: boolean | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "interaction" */
	["interaction_update_column"]: interaction_update_column;
	["interaction_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["interaction_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["interaction_bool_exp"] | Variable<any, string>
	};
	["json"]: unknown;
	["jsonb"]: unknown;
	["jsonb_cast_exp"]: {
		String?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
	["jsonb_comparison_exp"]: {
		_cast?: ValueTypes["jsonb_cast_exp"] | undefined | null | Variable<any, string>,
		/** is the column contained in the given json value */
		_contained_in?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		/** does the column contain the given json value at the top level */
		_contains?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		_eq?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		/** does the string exist as a top-level key in the column */
		_has_key?: string | undefined | null | Variable<any, string>,
		/** do all of these strings exist as top-level keys in the column */
		_has_keys_all?: Array<string> | undefined | null | Variable<any, string>,
		/** do any of these strings exist as top-level keys in the column */
		_has_keys_any?: Array<string> | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["jsonb"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["jsonb"]> | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "landingPageTemplate" */
	["landingPageTemplate"]: AliasType<{
		created_at?: boolean | `@${string}`,
		htmlTemplate?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "landingPageTemplate" */
	["landingPageTemplate_aggregate"]: AliasType<{
		aggregate?: ValueTypes["landingPageTemplate_aggregate_fields"],
		nodes?: ValueTypes["landingPageTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "landingPageTemplate" */
	["landingPageTemplate_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["landingPageTemplate_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["landingPageTemplate_max_fields"],
		min?: ValueTypes["landingPageTemplate_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "landingPageTemplate". All fields are combined with a logical 'AND'. */
	["landingPageTemplate_bool_exp"]: {
		_and?: Array<ValueTypes["landingPageTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["landingPageTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["landingPageTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		htmlTemplate?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "landingPageTemplate" */
	["landingPageTemplate_constraint"]: landingPageTemplate_constraint;
	/** input type for inserting data into table "landingPageTemplate" */
	["landingPageTemplate_insert_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		htmlTemplate?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["landingPageTemplate_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		htmlTemplate?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["landingPageTemplate_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		htmlTemplate?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "landingPageTemplate" */
	["landingPageTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["landingPageTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "landingPageTemplate" */
	["landingPageTemplate_on_conflict"]: {
		constraint: ValueTypes["landingPageTemplate_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["landingPageTemplate_update_column"]> | Variable<any, string>,
		where?: ValueTypes["landingPageTemplate_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "landingPageTemplate". */
	["landingPageTemplate_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		htmlTemplate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: landingPageTemplate */
	["landingPageTemplate_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "landingPageTemplate" */
	["landingPageTemplate_select_column"]: landingPageTemplate_select_column;
	/** input type for updating data in table "landingPageTemplate" */
	["landingPageTemplate_set_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		htmlTemplate?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "landingPageTemplate" */
	["landingPageTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["landingPageTemplate_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["landingPageTemplate_stream_cursor_value_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		htmlTemplate?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "landingPageTemplate" */
	["landingPageTemplate_update_column"]: landingPageTemplate_update_column;
	["landingPageTemplate_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["landingPageTemplate_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["landingPageTemplate_bool_exp"] | Variable<any, string>
	};
	/** mutation root */
	["mutation_root"]: AliasType<{
		blogPostGeneratorEnhancedForAmp?: [{ brandName?: string | undefined | null | Variable<any, string>, brief?: string | undefined | null | Variable<any, string>, category?: string | undefined | null | Variable<any, string>, format?: string | undefined | null | Variable<any, string>, productName?: string | undefined | null | Variable<any, string>, textStyle?: string | undefined | null | Variable<any, string>, title?: string | undefined | null | Variable<any, string>, voiceTone?: string | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		createAutomaticProject?: [{ artDirectional?: string | undefined | null | Variable<any, string>, audienceId?: string | undefined | null | Variable<any, string>, brandId?: string | undefined | null | Variable<any, string>, displayDimensionId?: string | undefined | null | Variable<any, string>, productId?: string | undefined | null | Variable<any, string>, selectedImage?: string | undefined | null | Variable<any, string>, selectedTemplateId?: string | undefined | null | Variable<any, string> }, ValueTypes["CreateAutomaticProjectOutput"]],
		createImageUsingImagine?: [{ aspectRatio: string | Variable<any, string>, prompt: string | Variable<any, string>, style?: number | undefined | null | Variable<any, string> }, ValueTypes["CreateImageUsingImagineOutput"]],
		deleteAuthProvider?: [{ id: string | Variable<any, string> }, ValueTypes["authProviders"]],
		deleteAuthProviderRequest?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authProviderRequests"]],
		deleteAuthProviderRequests?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["authProviderRequests_bool_exp"] | Variable<any, string>
		}, ValueTypes["authProviderRequests_mutation_response"]],
		deleteAuthProviders?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["authProviders_bool_exp"] | Variable<any, string>
		}, ValueTypes["authProviders_mutation_response"]],
		deleteAuthRefreshToken?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authRefreshTokens"]],
		deleteAuthRefreshTokenType?: [{ value: string | Variable<any, string> }, ValueTypes["authRefreshTokenTypes"]],
		deleteAuthRefreshTokenTypes?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["authRefreshTokenTypes_bool_exp"] | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes_mutation_response"]],
		deleteAuthRefreshTokens?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["authRefreshTokens_bool_exp"] | Variable<any, string>
		}, ValueTypes["authRefreshTokens_mutation_response"]],
		deleteAuthRole?: [{ role: string | Variable<any, string> }, ValueTypes["authRoles"]],
		deleteAuthRoles?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["authRoles_bool_exp"] | Variable<any, string>
		}, ValueTypes["authRoles_mutation_response"]],
		deleteAuthUserProvider?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserProviders"]],
		deleteAuthUserProviders?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["authUserProviders_bool_exp"] | Variable<any, string>
		}, ValueTypes["authUserProviders_mutation_response"]],
		deleteAuthUserRole?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserRoles"]],
		deleteAuthUserRoles?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["authUserRoles_bool_exp"] | Variable<any, string>
		}, ValueTypes["authUserRoles_mutation_response"]],
		deleteAuthUserSecurityKey?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserSecurityKeys"]],
		deleteAuthUserSecurityKeys?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["authUserSecurityKeys_bool_exp"] | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys_mutation_response"]],
		deleteBucket?: [{ id: string | Variable<any, string> }, ValueTypes["buckets"]],
		deleteBuckets?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["buckets_bool_exp"] | Variable<any, string>
		}, ValueTypes["buckets_mutation_response"]],
		deleteFile?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["files"]],
		deleteFiles?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["files_bool_exp"] | Variable<any, string>
		}, ValueTypes["files_mutation_response"]],
		deleteUser?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["users"]],
		deleteUsers?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["users_bool_exp"] | Variable<any, string>
		}, ValueTypes["users_mutation_response"]],
		deleteVirus?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["virus"]],
		deleteViruses?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["virus_bool_exp"] | Variable<any, string>
		}, ValueTypes["virus_mutation_response"]],
		delete_AppIntegration?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["AppIntegration_bool_exp"] | Variable<any, string>
		}, ValueTypes["AppIntegration_mutation_response"]],
		delete_AppIntegration_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["AppIntegration"]],
		delete_Course?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["Course_bool_exp"] | Variable<any, string>
		}, ValueTypes["Course_mutation_response"]],
		delete_CourseCategory?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["CourseCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["CourseCategory_mutation_response"]],
		delete_CourseCategory_by_pk?: [{ id: ValueTypes["bigint"] | Variable<any, string> }, ValueTypes["CourseCategory"]],
		delete_Course_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["Course"]],
		delete_GeneratedPromptByTemplate?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate_mutation_response"]],
		delete_GeneratedPromptByTemplateImage?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage_mutation_response"]],
		delete_GeneratedPromptByTemplateImage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["GeneratedPromptByTemplateImage"]],
		delete_GeneratedPromptByTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["GeneratedPromptByTemplate"]],
		delete_TextGeneratorOption?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["TextGeneratorOption_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextGeneratorOption_mutation_response"]],
		delete_TextGeneratorOptionEntry?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry_mutation_response"]],
		delete_TextGeneratorOptionEntry_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextGeneratorOptionEntry"]],
		delete_TextGeneratorOption_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextGeneratorOption"]],
		delete_TextPromptTemplate?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["TextPromptTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextPromptTemplate_mutation_response"]],
		delete_TextPromptTemplateCategory?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["TextPromptTemplateCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory_mutation_response"]],
		delete_TextPromptTemplateCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextPromptTemplateCategory"]],
		delete_TextPromptTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextPromptTemplate"]],
		delete_TextTemplateClientCategory?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["TextTemplateClientCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory_mutation_response"]],
		delete_TextTemplateClientCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextTemplateClientCategory"]],
		delete_audiences?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["audiences_bool_exp"] | Variable<any, string>
		}, ValueTypes["audiences_mutation_response"]],
		delete_audiences_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["audiences"]],
		delete_blog?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["blog_bool_exp"] | Variable<any, string>
		}, ValueTypes["blog_mutation_response"]],
		delete_blogCategory?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["blogCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["blogCategory_mutation_response"]],
		delete_blogCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["blogCategory"]],
		delete_blog_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["blog"]],
		delete_brands?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["brands_bool_exp"] | Variable<any, string>
		}, ValueTypes["brands_mutation_response"]],
		delete_brands_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["brands"]],
		delete_campaign?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["campaign_bool_exp"] | Variable<any, string>
		}, ValueTypes["campaign_mutation_response"]],
		delete_campaign_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["campaign"]],
		delete_channels?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["channels_bool_exp"] | Variable<any, string>
		}, ValueTypes["channels_mutation_response"]],
		delete_channels_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["channels"]],
		delete_costTable?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["costTable_bool_exp"] | Variable<any, string>
		}, ValueTypes["costTable_mutation_response"]],
		delete_costTable_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["costTable"]],
		delete_deal?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["deal_bool_exp"] | Variable<any, string>
		}, ValueTypes["deal_mutation_response"]],
		delete_dealStatus?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["dealStatus_bool_exp"] | Variable<any, string>
		}, ValueTypes["dealStatus_mutation_response"]],
		delete_dealStatus_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["dealStatus"]],
		delete_deal_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["deal"]],
		delete_displayCategory?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["displayCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["displayCategory_mutation_response"]],
		delete_displayCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayCategory"]],
		delete_displayDimension?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["displayDimension_bool_exp"] | Variable<any, string>
		}, ValueTypes["displayDimension_mutation_response"]],
		delete_displayDimension_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayDimension"]],
		delete_displayFormats?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["displayFormats_bool_exp"] | Variable<any, string>
		}, ValueTypes["displayFormats_mutation_response"]],
		delete_displayFormats_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayFormats"]],
		delete_displayTemplate?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["displayTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["displayTemplate_mutation_response"]],
		delete_displayTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayTemplate"]],
		delete_interaction?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["interaction_bool_exp"] | Variable<any, string>
		}, ValueTypes["interaction_mutation_response"]],
		delete_interaction_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["interaction"]],
		delete_landingPageTemplate?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["landingPageTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["landingPageTemplate_mutation_response"]],
		delete_landingPageTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["landingPageTemplate"]],
		delete_newTemplateType?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["newTemplateType_bool_exp"] | Variable<any, string>
		}, ValueTypes["newTemplateType_mutation_response"]],
		delete_newTemplateType_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["newTemplateType"]],
		delete_openAIApiKey?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["openAIApiKey_bool_exp"] | Variable<any, string>
		}, ValueTypes["openAIApiKey_mutation_response"]],
		delete_openAIApiKey_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["openAIApiKey"]],
		delete_pTemplate?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["pTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["pTemplate_mutation_response"]],
		delete_pTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["pTemplate"]],
		delete_pricingPlan?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["pricingPlan_bool_exp"] | Variable<any, string>
		}, ValueTypes["pricingPlan_mutation_response"]],
		delete_pricingPlan_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["pricingPlan"]],
		delete_products?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["products_bool_exp"] | Variable<any, string>
		}, ValueTypes["products_mutation_response"]],
		delete_products_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["products"]],
		delete_project?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["project_bool_exp"] | Variable<any, string>
		}, ValueTypes["project_mutation_response"]],
		delete_projectImage?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["projectImage_bool_exp"] | Variable<any, string>
		}, ValueTypes["projectImage_mutation_response"]],
		delete_projectImage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["projectImage"]],
		delete_project_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["project"]],
		delete_task?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["task_bool_exp"] | Variable<any, string>
		}, ValueTypes["task_mutation_response"]],
		delete_task_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["task"]],
		delete_textTemplateDisplayFormat?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["textTemplateDisplayFormat_bool_exp"] | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat_mutation_response"]],
		delete_textTemplateDisplayFormat_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["textTemplateDisplayFormat"]],
		delete_userAppIntegration?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userAppIntegration_bool_exp"] | Variable<any, string>
		}, ValueTypes["userAppIntegration_mutation_response"]],
		delete_userAppIntegration_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userAppIntegration"]],
		delete_userCreditUsage?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userCreditUsage_bool_exp"] | Variable<any, string>
		}, ValueTypes["userCreditUsage_mutation_response"]],
		delete_userCreditUsageSummary?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userCreditUsageSummary_bool_exp"] | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary_mutation_response"]],
		delete_userCreditUsageSummary_by_pk?: [{ created_by: ValueTypes["uuid"] | Variable<any, string>, yearMonthDayReference: string | Variable<any, string> }, ValueTypes["userCreditUsageSummary"]],
		delete_userCreditUsage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userCreditUsage"]],
		delete_userCredits?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userCredits_bool_exp"] | Variable<any, string>
		}, ValueTypes["userCredits_mutation_response"]],
		delete_userCredits_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userCredits"]],
		delete_userFeedback?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userFeedback_bool_exp"] | Variable<any, string>
		}, ValueTypes["userFeedback_mutation_response"]],
		delete_userFeedback_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userFeedback"]],
		delete_userMedia?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userMedia_bool_exp"] | Variable<any, string>
		}, ValueTypes["userMedia_mutation_response"]],
		delete_userMedia_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userMedia"]],
		delete_userMetadata?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userMetadata_bool_exp"] | Variable<any, string>
		}, ValueTypes["userMetadata_mutation_response"]],
		delete_userMetadata_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userMetadata"]],
		delete_userOnboarding?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userOnboarding_bool_exp"] | Variable<any, string>
		}, ValueTypes["userOnboarding_mutation_response"]],
		delete_userOnboarding_by_pk?: [{ userId: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userOnboarding"]],
		delete_userTemplateUsage?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["userTemplateUsage_bool_exp"] | Variable<any, string>
		}, ValueTypes["userTemplateUsage_mutation_response"]],
		delete_userTemplateUsage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userTemplateUsage"]],
		delete_website?: [{	/** filter the rows which have to be deleted */
			where: ValueTypes["website_bool_exp"] | Variable<any, string>
		}, ValueTypes["website_mutation_response"]],
		delete_website_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["website"]],
		exportToWordpress?: [{ command: ValueTypes["ExportToWordpressInput"] | Variable<any, string> }, ValueTypes["ExportToWordpressOutput"]],
		facebookAuthCallback?: [{ brandId: string | Variable<any, string>, code: string | Variable<any, string> }, ValueTypes["FacebookAuthCallbackOutput"]],
		finishOnboard?: ValueTypes["FinishOnboardOutput"],
		generateCalendarEx?: [{ audiencesIds?: Array<string | undefined | null> | undefined | null | Variable<any, string>, brandId: string | Variable<any, string>, campaignObjective: string | Variable<any, string>, productIds?: Array<string | undefined | null> | undefined | null | Variable<any, string> }, ValueTypes["GenerateCalendarExOutput"]],
		generateFromTextTemplate?: [{ command: ValueTypes["GenerateFromTextTemplateInput"] | Variable<any, string> }, ValueTypes["GenerateFromTextTemplateOutput"]],
		generateNextMonthCalendar?: [{ command: ValueTypes["GenerateNextMonthCalendarInput"] | Variable<any, string> }, boolean | `@${string}`],
		getInstagramAuthCallback?: [{ code: string | Variable<any, string> }, ValueTypes["GetInstagramAuthCallbackOutput"]],
		get_next_openai_apikey?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["openAIApiKey_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["openAIApiKey_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["openAIApiKey_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["openAIApiKey"]],
		insertAuthProvider?: [{	/** the row to be inserted */
			object: ValueTypes["authProviders_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authProviders_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviders"]],
		insertAuthProviderRequest?: [{	/** the row to be inserted */
			object: ValueTypes["authProviderRequests_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authProviderRequests_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviderRequests"]],
		insertAuthProviderRequests?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["authProviderRequests_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authProviderRequests_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviderRequests_mutation_response"]],
		insertAuthProviders?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["authProviders_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authProviders_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviders_mutation_response"]],
		insertAuthRefreshToken?: [{	/** the row to be inserted */
			object: ValueTypes["authRefreshTokens_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authRefreshTokens_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens"]],
		insertAuthRefreshTokenType?: [{	/** the row to be inserted */
			object: ValueTypes["authRefreshTokenTypes_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authRefreshTokenTypes_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes"]],
		insertAuthRefreshTokenTypes?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["authRefreshTokenTypes_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authRefreshTokenTypes_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes_mutation_response"]],
		insertAuthRefreshTokens?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["authRefreshTokens_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authRefreshTokens_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens_mutation_response"]],
		insertAuthRole?: [{	/** the row to be inserted */
			object: ValueTypes["authRoles_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authRoles_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRoles"]],
		insertAuthRoles?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["authRoles_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authRoles_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRoles_mutation_response"]],
		insertAuthUserProvider?: [{	/** the row to be inserted */
			object: ValueTypes["authUserProviders_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authUserProviders_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders"]],
		insertAuthUserProviders?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["authUserProviders_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authUserProviders_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders_mutation_response"]],
		insertAuthUserRole?: [{	/** the row to be inserted */
			object: ValueTypes["authUserRoles_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authUserRoles_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles"]],
		insertAuthUserRoles?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["authUserRoles_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authUserRoles_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles_mutation_response"]],
		insertAuthUserSecurityKey?: [{	/** the row to be inserted */
			object: ValueTypes["authUserSecurityKeys_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authUserSecurityKeys_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys"]],
		insertAuthUserSecurityKeys?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["authUserSecurityKeys_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["authUserSecurityKeys_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys_mutation_response"]],
		insertBucket?: [{	/** the row to be inserted */
			object: ValueTypes["buckets_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["buckets_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["buckets"]],
		insertBuckets?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["buckets_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["buckets_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["buckets_mutation_response"]],
		insertFile?: [{	/** the row to be inserted */
			object: ValueTypes["files_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["files_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["files"]],
		insertFiles?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["files_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["files_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["files_mutation_response"]],
		insertUser?: [{	/** the row to be inserted */
			object: ValueTypes["users_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["users_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["users"]],
		insertUsers?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["users_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["users_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["users_mutation_response"]],
		insertVirus?: [{	/** the row to be inserted */
			object: ValueTypes["virus_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["virus_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["virus"]],
		insertViruses?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["virus_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["virus_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["virus_mutation_response"]],
		insert_AppIntegration?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["AppIntegration_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["AppIntegration_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["AppIntegration_mutation_response"]],
		insert_AppIntegration_one?: [{	/** the row to be inserted */
			object: ValueTypes["AppIntegration_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["AppIntegration_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["AppIntegration"]],
		insert_Course?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["Course_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["Course_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["Course_mutation_response"]],
		insert_CourseCategory?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["CourseCategory_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["CourseCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["CourseCategory_mutation_response"]],
		insert_CourseCategory_one?: [{	/** the row to be inserted */
			object: ValueTypes["CourseCategory_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["CourseCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["CourseCategory"]],
		insert_Course_one?: [{	/** the row to be inserted */
			object: ValueTypes["Course_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["Course_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["Course"]],
		insert_GeneratedPromptByTemplate?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["GeneratedPromptByTemplate_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["GeneratedPromptByTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate_mutation_response"]],
		insert_GeneratedPromptByTemplateImage?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["GeneratedPromptByTemplateImage_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["GeneratedPromptByTemplateImage_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage_mutation_response"]],
		insert_GeneratedPromptByTemplateImage_one?: [{	/** the row to be inserted */
			object: ValueTypes["GeneratedPromptByTemplateImage_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["GeneratedPromptByTemplateImage_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage"]],
		insert_GeneratedPromptByTemplate_one?: [{	/** the row to be inserted */
			object: ValueTypes["GeneratedPromptByTemplate_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["GeneratedPromptByTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate"]],
		insert_TextGeneratorOption?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["TextGeneratorOption_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextGeneratorOption_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOption_mutation_response"]],
		insert_TextGeneratorOptionEntry?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["TextGeneratorOptionEntry_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextGeneratorOptionEntry_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry_mutation_response"]],
		insert_TextGeneratorOptionEntry_one?: [{	/** the row to be inserted */
			object: ValueTypes["TextGeneratorOptionEntry_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextGeneratorOptionEntry_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry"]],
		insert_TextGeneratorOption_one?: [{	/** the row to be inserted */
			object: ValueTypes["TextGeneratorOption_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextGeneratorOption_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOption"]],
		insert_TextPromptTemplate?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["TextPromptTemplate_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextPromptTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate_mutation_response"]],
		insert_TextPromptTemplateCategory?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["TextPromptTemplateCategory_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextPromptTemplateCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory_mutation_response"]],
		insert_TextPromptTemplateCategory_one?: [{	/** the row to be inserted */
			object: ValueTypes["TextPromptTemplateCategory_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextPromptTemplateCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory"]],
		insert_TextPromptTemplate_one?: [{	/** the row to be inserted */
			object: ValueTypes["TextPromptTemplate_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextPromptTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate"]],
		insert_TextTemplateClientCategory?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["TextTemplateClientCategory_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextTemplateClientCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory_mutation_response"]],
		insert_TextTemplateClientCategory_one?: [{	/** the row to be inserted */
			object: ValueTypes["TextTemplateClientCategory_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["TextTemplateClientCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory"]],
		insert_audiences?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["audiences_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["audiences_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["audiences_mutation_response"]],
		insert_audiences_one?: [{	/** the row to be inserted */
			object: ValueTypes["audiences_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["audiences_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["audiences"]],
		insert_blog?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["blog_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["blog_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog_mutation_response"]],
		insert_blogCategory?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["blogCategory_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["blogCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["blogCategory_mutation_response"]],
		insert_blogCategory_one?: [{	/** the row to be inserted */
			object: ValueTypes["blogCategory_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["blogCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["blogCategory"]],
		insert_blog_one?: [{	/** the row to be inserted */
			object: ValueTypes["blog_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["blog_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog"]],
		insert_brands?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["brands_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["brands_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["brands_mutation_response"]],
		insert_brands_one?: [{	/** the row to be inserted */
			object: ValueTypes["brands_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["brands_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["brands"]],
		insert_campaign?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["campaign_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["campaign_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["campaign_mutation_response"]],
		insert_campaign_one?: [{	/** the row to be inserted */
			object: ValueTypes["campaign_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["campaign_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["campaign"]],
		insert_channels?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["channels_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["channels_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["channels_mutation_response"]],
		insert_channels_one?: [{	/** the row to be inserted */
			object: ValueTypes["channels_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["channels_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["channels"]],
		insert_costTable?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["costTable_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["costTable_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["costTable_mutation_response"]],
		insert_costTable_one?: [{	/** the row to be inserted */
			object: ValueTypes["costTable_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["costTable_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["costTable"]],
		insert_deal?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["deal_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["deal_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal_mutation_response"]],
		insert_dealStatus?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["dealStatus_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["dealStatus_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["dealStatus_mutation_response"]],
		insert_dealStatus_one?: [{	/** the row to be inserted */
			object: ValueTypes["dealStatus_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["dealStatus_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["dealStatus"]],
		insert_deal_one?: [{	/** the row to be inserted */
			object: ValueTypes["deal_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["deal_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal"]],
		insert_displayCategory?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["displayCategory_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["displayCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayCategory_mutation_response"]],
		insert_displayCategory_one?: [{	/** the row to be inserted */
			object: ValueTypes["displayCategory_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["displayCategory_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayCategory"]],
		insert_displayDimension?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["displayDimension_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["displayDimension_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayDimension_mutation_response"]],
		insert_displayDimension_one?: [{	/** the row to be inserted */
			object: ValueTypes["displayDimension_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["displayDimension_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayDimension"]],
		insert_displayFormats?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["displayFormats_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["displayFormats_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayFormats_mutation_response"]],
		insert_displayFormats_one?: [{	/** the row to be inserted */
			object: ValueTypes["displayFormats_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["displayFormats_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayFormats"]],
		insert_displayTemplate?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["displayTemplate_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["displayTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayTemplate_mutation_response"]],
		insert_displayTemplate_one?: [{	/** the row to be inserted */
			object: ValueTypes["displayTemplate_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["displayTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayTemplate"]],
		insert_interaction?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["interaction_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["interaction_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction_mutation_response"]],
		insert_interaction_one?: [{	/** the row to be inserted */
			object: ValueTypes["interaction_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["interaction_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction"]],
		insert_landingPageTemplate?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["landingPageTemplate_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["landingPageTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["landingPageTemplate_mutation_response"]],
		insert_landingPageTemplate_one?: [{	/** the row to be inserted */
			object: ValueTypes["landingPageTemplate_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["landingPageTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["landingPageTemplate"]],
		insert_newTemplateType?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["newTemplateType_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["newTemplateType_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["newTemplateType_mutation_response"]],
		insert_newTemplateType_one?: [{	/** the row to be inserted */
			object: ValueTypes["newTemplateType_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["newTemplateType_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["newTemplateType"]],
		insert_openAIApiKey?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["openAIApiKey_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["openAIApiKey_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["openAIApiKey_mutation_response"]],
		insert_openAIApiKey_one?: [{	/** the row to be inserted */
			object: ValueTypes["openAIApiKey_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["openAIApiKey_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["openAIApiKey"]],
		insert_pTemplate?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["pTemplate_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["pTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["pTemplate_mutation_response"]],
		insert_pTemplate_one?: [{	/** the row to be inserted */
			object: ValueTypes["pTemplate_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["pTemplate_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["pTemplate"]],
		insert_pricingPlan?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["pricingPlan_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["pricingPlan_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["pricingPlan_mutation_response"]],
		insert_pricingPlan_one?: [{	/** the row to be inserted */
			object: ValueTypes["pricingPlan_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["pricingPlan_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["pricingPlan"]],
		insert_products?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["products_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["products_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["products_mutation_response"]],
		insert_products_one?: [{	/** the row to be inserted */
			object: ValueTypes["products_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["products_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["products"]],
		insert_project?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["project_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["project_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["project_mutation_response"]],
		insert_projectImage?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["projectImage_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["projectImage_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["projectImage_mutation_response"]],
		insert_projectImage_one?: [{	/** the row to be inserted */
			object: ValueTypes["projectImage_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["projectImage_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["projectImage"]],
		insert_project_one?: [{	/** the row to be inserted */
			object: ValueTypes["project_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["project_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["project"]],
		insert_task?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["task_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["task_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["task_mutation_response"]],
		insert_task_one?: [{	/** the row to be inserted */
			object: ValueTypes["task_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["task_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["task"]],
		insert_textTemplateDisplayFormat?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["textTemplateDisplayFormat_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["textTemplateDisplayFormat_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat_mutation_response"]],
		insert_textTemplateDisplayFormat_one?: [{	/** the row to be inserted */
			object: ValueTypes["textTemplateDisplayFormat_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["textTemplateDisplayFormat_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat"]],
		insert_userAppIntegration?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userAppIntegration_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userAppIntegration_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userAppIntegration_mutation_response"]],
		insert_userAppIntegration_one?: [{	/** the row to be inserted */
			object: ValueTypes["userAppIntegration_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userAppIntegration_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userAppIntegration"]],
		insert_userCreditUsage?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userCreditUsage_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userCreditUsage_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsage_mutation_response"]],
		insert_userCreditUsageSummary?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userCreditUsageSummary_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userCreditUsageSummary_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary_mutation_response"]],
		insert_userCreditUsageSummary_one?: [{	/** the row to be inserted */
			object: ValueTypes["userCreditUsageSummary_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userCreditUsageSummary_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary"]],
		insert_userCreditUsage_one?: [{	/** the row to be inserted */
			object: ValueTypes["userCreditUsage_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userCreditUsage_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsage"]],
		insert_userCredits?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userCredits_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userCredits_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCredits_mutation_response"]],
		insert_userCredits_one?: [{	/** the row to be inserted */
			object: ValueTypes["userCredits_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userCredits_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCredits"]],
		insert_userFeedback?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userFeedback_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userFeedback_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userFeedback_mutation_response"]],
		insert_userFeedback_one?: [{	/** the row to be inserted */
			object: ValueTypes["userFeedback_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userFeedback_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userFeedback"]],
		insert_userMedia?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userMedia_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userMedia_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMedia_mutation_response"]],
		insert_userMedia_one?: [{	/** the row to be inserted */
			object: ValueTypes["userMedia_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userMedia_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMedia"]],
		insert_userMetadata?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userMetadata_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userMetadata_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata_mutation_response"]],
		insert_userMetadata_one?: [{	/** the row to be inserted */
			object: ValueTypes["userMetadata_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userMetadata_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata"]],
		insert_userOnboarding?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userOnboarding_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userOnboarding_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userOnboarding_mutation_response"]],
		insert_userOnboarding_one?: [{	/** the row to be inserted */
			object: ValueTypes["userOnboarding_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userOnboarding_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userOnboarding"]],
		insert_userTemplateUsage?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["userTemplateUsage_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userTemplateUsage_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userTemplateUsage_mutation_response"]],
		insert_userTemplateUsage_one?: [{	/** the row to be inserted */
			object: ValueTypes["userTemplateUsage_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["userTemplateUsage_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["userTemplateUsage"]],
		insert_website?: [{	/** the rows to be inserted */
			objects: Array<ValueTypes["website_insert_input"]> | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["website_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["website_mutation_response"]],
		insert_website_one?: [{	/** the row to be inserted */
			object: ValueTypes["website_insert_input"] | Variable<any, string>,	/** upsert condition */
			on_conflict?: ValueTypes["website_on_conflict"] | undefined | null | Variable<any, string>
		}, ValueTypes["website"]],
		linkedInTokenCallback?: [{ brandId?: string | undefined | null | Variable<any, string>, code: string | Variable<any, string> }, ValueTypes["LinkedInTokenCallbackOutput"]],
		postCarouselToInstagram?: [{ businessId: string | Variable<any, string>, generatedByTemplateId: string | Variable<any, string>, integrationId: string | Variable<any, string> }, ValueTypes["PostCarouselToInstagramOutput"]],
		postFeedToInstagram?: [{ businessId: string | Variable<any, string>, generatedByTemplateId: string | Variable<any, string>, integrationId: string | Variable<any, string>, scheduledDate?: ValueTypes["date"] | undefined | null | Variable<any, string> }, ValueTypes["PostFeedToInstagramOutput"]],
		postStoryToInstagram?: [{ businessId: string | Variable<any, string>, generatedByTemplateId: string | Variable<any, string>, integrationId: string | Variable<any, string> }, ValueTypes["PostStoryToInstagramOutput"]],
		postToFacebook?: [{ generatedPromptByTemplateId: string | Variable<any, string>, integrationId: string | Variable<any, string>, pageId: string | Variable<any, string>, scheduledDate?: ValueTypes["date"] | undefined | null | Variable<any, string> }, ValueTypes["PostToFacebookOutput"]],
		postToLinkedIn?: [{ generatedPromptByTemplateId: string | Variable<any, string>, integrationId: string | Variable<any, string>, scheduledDate?: ValueTypes["date"] | undefined | null | Variable<any, string> }, ValueTypes["PostToLinkedInOutput"]],
		publishOrScheduleProject?: [{ blogCategory?: number | undefined | null | Variable<any, string>, blogTags?: Array<number | undefined | null> | undefined | null | Variable<any, string>, channel: string | Variable<any, string>, facebookPageId?: string | undefined | null | Variable<any, string>, instagramBusinessId?: string | undefined | null | Variable<any, string>, integrationId: string | Variable<any, string>, projectId: string | Variable<any, string>, scheduledDate?: ValueTypes["date"] | undefined | null | Variable<any, string>, subtype: string | Variable<any, string> }, ValueTypes["PublishOrScheduleProjectOutput"]],
		purePrompt?: [{ prompt: string | Variable<any, string> }, ValueTypes["PromptOutput"]],
		renderAndSaveDisplayImage?: [{ data: string | Variable<any, string>, generatedPromptByTemplateId: string | Variable<any, string>, template: string | Variable<any, string> }, ValueTypes["RenderAndSaveDisplayImageOutput"]],
		saveAndGeneratePolotnoImage?: [{ projectId: string | Variable<any, string> }, ValueTypes["SaveAndGeneratePolotnoImageOutput"]],
		selectNewDisplay?: [{ alias?: string | undefined | null | Variable<any, string>, audienceId?: string | undefined | null | Variable<any, string>, backgroundImageFileId?: string | undefined | null | Variable<any, string>, backgroundImageUrl?: string | undefined | null | Variable<any, string>, brandId?: string | undefined | null | Variable<any, string>, channel?: string | undefined | null | Variable<any, string>, displayDimensionId?: string | undefined | null | Variable<any, string>, displayFormatId?: string | undefined | null | Variable<any, string>, flexText?: string | undefined | null | Variable<any, string>, productId?: string | undefined | null | Variable<any, string>, textPromptTemplateId?: string | undefined | null | Variable<any, string> }, ValueTypes["SelectNewDisplayOutput"]],
		updateAuthProvider?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authProviders_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["authProviders_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["authProviders"]],
		updateAuthProviderRequest?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["authProviderRequests_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["authProviderRequests_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["authProviderRequests_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["authProviderRequests_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["authProviderRequests_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authProviderRequests_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["authProviderRequests_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["authProviderRequests"]],
		updateAuthProviderRequests?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["authProviderRequests_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["authProviderRequests_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["authProviderRequests_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["authProviderRequests_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["authProviderRequests_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authProviderRequests_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["authProviderRequests_bool_exp"] | Variable<any, string>
		}, ValueTypes["authProviderRequests_mutation_response"]],
		updateAuthProviders?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authProviders_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["authProviders_bool_exp"] | Variable<any, string>
		}, ValueTypes["authProviders_mutation_response"]],
		updateAuthRefreshToken?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["authRefreshTokens_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["authRefreshTokens_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["authRefreshTokens_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["authRefreshTokens_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["authRefreshTokens_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authRefreshTokens_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["authRefreshTokens_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["authRefreshTokens"]],
		updateAuthRefreshTokenType?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authRefreshTokenTypes_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["authRefreshTokenTypes_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes"]],
		updateAuthRefreshTokenTypes?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authRefreshTokenTypes_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["authRefreshTokenTypes_bool_exp"] | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes_mutation_response"]],
		updateAuthRefreshTokens?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["authRefreshTokens_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["authRefreshTokens_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["authRefreshTokens_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["authRefreshTokens_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["authRefreshTokens_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authRefreshTokens_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["authRefreshTokens_bool_exp"] | Variable<any, string>
		}, ValueTypes["authRefreshTokens_mutation_response"]],
		updateAuthRole?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authRoles_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["authRoles_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["authRoles"]],
		updateAuthRoles?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authRoles_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["authRoles_bool_exp"] | Variable<any, string>
		}, ValueTypes["authRoles_mutation_response"]],
		updateAuthUserProvider?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authUserProviders_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["authUserProviders_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["authUserProviders"]],
		updateAuthUserProviders?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authUserProviders_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["authUserProviders_bool_exp"] | Variable<any, string>
		}, ValueTypes["authUserProviders_mutation_response"]],
		updateAuthUserRole?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authUserRoles_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["authUserRoles_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["authUserRoles"]],
		updateAuthUserRoles?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authUserRoles_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["authUserRoles_bool_exp"] | Variable<any, string>
		}, ValueTypes["authUserRoles_mutation_response"]],
		updateAuthUserSecurityKey?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["authUserSecurityKeys_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authUserSecurityKeys_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["authUserSecurityKeys_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys"]],
		updateAuthUserSecurityKeys?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["authUserSecurityKeys_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["authUserSecurityKeys_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["authUserSecurityKeys_bool_exp"] | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys_mutation_response"]],
		updateBucket?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["buckets_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["buckets_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["buckets_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["buckets"]],
		updateBuckets?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["buckets_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["buckets_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["buckets_bool_exp"] | Variable<any, string>
		}, ValueTypes["buckets_mutation_response"]],
		updateFile?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["files_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["files_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["files_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["files_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["files_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["files_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["files_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["files_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["files"]],
		updateFiles?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["files_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["files_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["files_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["files_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["files_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["files_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["files_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["files_bool_exp"] | Variable<any, string>
		}, ValueTypes["files_mutation_response"]],
		updateUser?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["users_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["users_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["users_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["users_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["users_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["users_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["users_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["users"]],
		updateUserAvatar?: [{ avatarAsBase64?: string | undefined | null | Variable<any, string> }, ValueTypes["UpdateUserAvatarOutput"]],
		updateUsers?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["users_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["users_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["users_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["users_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["users_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["users_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["users_bool_exp"] | Variable<any, string>
		}, ValueTypes["users_mutation_response"]],
		updateVirus?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["virus_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["virus_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["virus_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["virus_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["virus_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["virus_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["virus_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["virus"]],
		updateViruses?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["virus_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["virus_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["virus_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["virus_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["virus_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["virus_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["virus_bool_exp"] | Variable<any, string>
		}, ValueTypes["virus_mutation_response"]],
		update_AppIntegration?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["AppIntegration_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["AppIntegration_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["AppIntegration_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["AppIntegration_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["AppIntegration_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["AppIntegration_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["AppIntegration_bool_exp"] | Variable<any, string>
		}, ValueTypes["AppIntegration_mutation_response"]],
		update_AppIntegration_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["AppIntegration_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["AppIntegration_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["AppIntegration_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["AppIntegration_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["AppIntegration_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["AppIntegration_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["AppIntegration_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["AppIntegration"]],
		update_AppIntegration_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["AppIntegration_updates"]> | Variable<any, string>
		}, ValueTypes["AppIntegration_mutation_response"]],
		update_Course?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["Course_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["Course_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["Course_bool_exp"] | Variable<any, string>
		}, ValueTypes["Course_mutation_response"]],
		update_CourseCategory?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["CourseCategory_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["CourseCategory_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["CourseCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["CourseCategory_mutation_response"]],
		update_CourseCategory_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["CourseCategory_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["CourseCategory_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["CourseCategory_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["CourseCategory"]],
		update_CourseCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["CourseCategory_updates"]> | Variable<any, string>
		}, ValueTypes["CourseCategory_mutation_response"]],
		update_Course_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["Course_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["Course_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["Course_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["Course"]],
		update_Course_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["Course_updates"]> | Variable<any, string>
		}, ValueTypes["Course_mutation_response"]],
		update_GeneratedPromptByTemplate?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["GeneratedPromptByTemplate_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["GeneratedPromptByTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["GeneratedPromptByTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["GeneratedPromptByTemplate_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["GeneratedPromptByTemplate_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["GeneratedPromptByTemplate_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate_mutation_response"]],
		update_GeneratedPromptByTemplateImage?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["GeneratedPromptByTemplateImage_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage_mutation_response"]],
		update_GeneratedPromptByTemplateImage_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["GeneratedPromptByTemplateImage_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["GeneratedPromptByTemplateImage_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage"]],
		update_GeneratedPromptByTemplateImage_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["GeneratedPromptByTemplateImage_updates"]> | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage_mutation_response"]],
		update_GeneratedPromptByTemplate_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["GeneratedPromptByTemplate_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["GeneratedPromptByTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["GeneratedPromptByTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["GeneratedPromptByTemplate_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["GeneratedPromptByTemplate_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["GeneratedPromptByTemplate_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["GeneratedPromptByTemplate_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate"]],
		update_GeneratedPromptByTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["GeneratedPromptByTemplate_updates"]> | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate_mutation_response"]],
		update_TextGeneratorOption?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["TextGeneratorOption_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["TextGeneratorOption_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["TextGeneratorOption_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["TextGeneratorOption_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["TextGeneratorOption_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextGeneratorOption_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["TextGeneratorOption_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextGeneratorOption_mutation_response"]],
		update_TextGeneratorOptionEntry?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["TextGeneratorOptionEntry_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["TextGeneratorOptionEntry_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["TextGeneratorOptionEntry_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["TextGeneratorOptionEntry_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["TextGeneratorOptionEntry_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextGeneratorOptionEntry_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry_mutation_response"]],
		update_TextGeneratorOptionEntry_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["TextGeneratorOptionEntry_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["TextGeneratorOptionEntry_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["TextGeneratorOptionEntry_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["TextGeneratorOptionEntry_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["TextGeneratorOptionEntry_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextGeneratorOptionEntry_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["TextGeneratorOptionEntry_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry"]],
		update_TextGeneratorOptionEntry_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["TextGeneratorOptionEntry_updates"]> | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry_mutation_response"]],
		update_TextGeneratorOption_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["TextGeneratorOption_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["TextGeneratorOption_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["TextGeneratorOption_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["TextGeneratorOption_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["TextGeneratorOption_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextGeneratorOption_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["TextGeneratorOption_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["TextGeneratorOption"]],
		update_TextGeneratorOption_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["TextGeneratorOption_updates"]> | Variable<any, string>
		}, ValueTypes["TextGeneratorOption_mutation_response"]],
		update_TextPromptTemplate?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["TextPromptTemplate_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["TextPromptTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["TextPromptTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["TextPromptTemplate_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["TextPromptTemplate_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["TextPromptTemplate_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextPromptTemplate_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["TextPromptTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextPromptTemplate_mutation_response"]],
		update_TextPromptTemplateCategory?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextPromptTemplateCategory_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["TextPromptTemplateCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory_mutation_response"]],
		update_TextPromptTemplateCategory_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextPromptTemplateCategory_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["TextPromptTemplateCategory_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory"]],
		update_TextPromptTemplateCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["TextPromptTemplateCategory_updates"]> | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory_mutation_response"]],
		update_TextPromptTemplate_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["TextPromptTemplate_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["TextPromptTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["TextPromptTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["TextPromptTemplate_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["TextPromptTemplate_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["TextPromptTemplate_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextPromptTemplate_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["TextPromptTemplate_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["TextPromptTemplate"]],
		update_TextPromptTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["TextPromptTemplate_updates"]> | Variable<any, string>
		}, ValueTypes["TextPromptTemplate_mutation_response"]],
		update_TextTemplateClientCategory?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextTemplateClientCategory_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["TextTemplateClientCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory_mutation_response"]],
		update_TextTemplateClientCategory_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["TextTemplateClientCategory_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["TextTemplateClientCategory_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory"]],
		update_TextTemplateClientCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["TextTemplateClientCategory_updates"]> | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory_mutation_response"]],
		update_audiences?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["audiences_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["audiences_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["audiences_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["audiences_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["audiences_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["audiences_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["audiences_bool_exp"] | Variable<any, string>
		}, ValueTypes["audiences_mutation_response"]],
		update_audiences_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["audiences_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["audiences_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["audiences_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["audiences_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["audiences_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["audiences_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["audiences_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["audiences"]],
		update_audiences_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["audiences_updates"]> | Variable<any, string>
		}, ValueTypes["audiences_mutation_response"]],
		update_authProviderRequests_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["authProviderRequests_updates"]> | Variable<any, string>
		}, ValueTypes["authProviderRequests_mutation_response"]],
		update_authProviders_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["authProviders_updates"]> | Variable<any, string>
		}, ValueTypes["authProviders_mutation_response"]],
		update_authRefreshTokenTypes_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["authRefreshTokenTypes_updates"]> | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes_mutation_response"]],
		update_authRefreshTokens_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["authRefreshTokens_updates"]> | Variable<any, string>
		}, ValueTypes["authRefreshTokens_mutation_response"]],
		update_authRoles_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["authRoles_updates"]> | Variable<any, string>
		}, ValueTypes["authRoles_mutation_response"]],
		update_authUserProviders_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["authUserProviders_updates"]> | Variable<any, string>
		}, ValueTypes["authUserProviders_mutation_response"]],
		update_authUserRoles_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["authUserRoles_updates"]> | Variable<any, string>
		}, ValueTypes["authUserRoles_mutation_response"]],
		update_authUserSecurityKeys_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["authUserSecurityKeys_updates"]> | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys_mutation_response"]],
		update_blog?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["blog_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["blog_bool_exp"] | Variable<any, string>
		}, ValueTypes["blog_mutation_response"]],
		update_blogCategory?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["blogCategory_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["blogCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["blogCategory_mutation_response"]],
		update_blogCategory_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["blogCategory_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["blogCategory_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["blogCategory"]],
		update_blogCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["blogCategory_updates"]> | Variable<any, string>
		}, ValueTypes["blogCategory_mutation_response"]],
		update_blog_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["blog_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["blog_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["blog"]],
		update_blog_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["blog_updates"]> | Variable<any, string>
		}, ValueTypes["blog_mutation_response"]],
		update_brands?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["brands_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["brands_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["brands_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["brands_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["brands_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["brands_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["brands_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["brands_bool_exp"] | Variable<any, string>
		}, ValueTypes["brands_mutation_response"]],
		update_brands_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["brands_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["brands_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["brands_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["brands_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["brands_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["brands_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["brands_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["brands_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["brands"]],
		update_brands_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["brands_updates"]> | Variable<any, string>
		}, ValueTypes["brands_mutation_response"]],
		update_buckets_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["buckets_updates"]> | Variable<any, string>
		}, ValueTypes["buckets_mutation_response"]],
		update_campaign?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["campaign_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["campaign_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["campaign_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["campaign_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["campaign_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["campaign_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["campaign_bool_exp"] | Variable<any, string>
		}, ValueTypes["campaign_mutation_response"]],
		update_campaign_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["campaign_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["campaign_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["campaign_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["campaign_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["campaign_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["campaign_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["campaign_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["campaign"]],
		update_campaign_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["campaign_updates"]> | Variable<any, string>
		}, ValueTypes["campaign_mutation_response"]],
		update_channels?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["channels_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["channels_bool_exp"] | Variable<any, string>
		}, ValueTypes["channels_mutation_response"]],
		update_channels_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["channels_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["channels_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["channels"]],
		update_channels_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["channels_updates"]> | Variable<any, string>
		}, ValueTypes["channels_mutation_response"]],
		update_costTable?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["costTable_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["costTable_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["costTable_bool_exp"] | Variable<any, string>
		}, ValueTypes["costTable_mutation_response"]],
		update_costTable_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["costTable_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["costTable_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["costTable_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["costTable"]],
		update_costTable_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["costTable_updates"]> | Variable<any, string>
		}, ValueTypes["costTable_mutation_response"]],
		update_deal?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["deal_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["deal_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["deal_bool_exp"] | Variable<any, string>
		}, ValueTypes["deal_mutation_response"]],
		update_dealStatus?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["dealStatus_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["dealStatus_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["dealStatus_bool_exp"] | Variable<any, string>
		}, ValueTypes["dealStatus_mutation_response"]],
		update_dealStatus_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["dealStatus_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["dealStatus_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["dealStatus_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["dealStatus"]],
		update_dealStatus_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["dealStatus_updates"]> | Variable<any, string>
		}, ValueTypes["dealStatus_mutation_response"]],
		update_deal_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["deal_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["deal_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["deal_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["deal"]],
		update_deal_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["deal_updates"]> | Variable<any, string>
		}, ValueTypes["deal_mutation_response"]],
		update_displayCategory?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["displayCategory_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["displayCategory_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["displayCategory_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["displayCategory_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["displayCategory_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["displayCategory_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["displayCategory_bool_exp"] | Variable<any, string>
		}, ValueTypes["displayCategory_mutation_response"]],
		update_displayCategory_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["displayCategory_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["displayCategory_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["displayCategory_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["displayCategory_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["displayCategory_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["displayCategory_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["displayCategory_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["displayCategory"]],
		update_displayCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["displayCategory_updates"]> | Variable<any, string>
		}, ValueTypes["displayCategory_mutation_response"]],
		update_displayDimension?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["displayDimension_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["displayDimension_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["displayDimension_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["displayDimension_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["displayDimension_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["displayDimension_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["displayDimension_bool_exp"] | Variable<any, string>
		}, ValueTypes["displayDimension_mutation_response"]],
		update_displayDimension_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["displayDimension_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["displayDimension_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["displayDimension_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["displayDimension_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["displayDimension_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["displayDimension_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["displayDimension_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["displayDimension"]],
		update_displayDimension_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["displayDimension_updates"]> | Variable<any, string>
		}, ValueTypes["displayDimension_mutation_response"]],
		update_displayFormats?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["displayFormats_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["displayFormats_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["displayFormats_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["displayFormats_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["displayFormats_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["displayFormats_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["displayFormats_bool_exp"] | Variable<any, string>
		}, ValueTypes["displayFormats_mutation_response"]],
		update_displayFormats_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["displayFormats_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["displayFormats_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["displayFormats_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["displayFormats_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["displayFormats_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["displayFormats_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["displayFormats_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["displayFormats"]],
		update_displayFormats_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["displayFormats_updates"]> | Variable<any, string>
		}, ValueTypes["displayFormats_mutation_response"]],
		update_displayTemplate?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["displayTemplate_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["displayTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["displayTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["displayTemplate_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["displayTemplate_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["displayTemplate_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["displayTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["displayTemplate_mutation_response"]],
		update_displayTemplate_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["displayTemplate_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["displayTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["displayTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["displayTemplate_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["displayTemplate_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["displayTemplate_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["displayTemplate_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["displayTemplate"]],
		update_displayTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["displayTemplate_updates"]> | Variable<any, string>
		}, ValueTypes["displayTemplate_mutation_response"]],
		update_files_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["files_updates"]> | Variable<any, string>
		}, ValueTypes["files_mutation_response"]],
		update_interaction?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["interaction_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["interaction_bool_exp"] | Variable<any, string>
		}, ValueTypes["interaction_mutation_response"]],
		update_interaction_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["interaction_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["interaction_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["interaction"]],
		update_interaction_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["interaction_updates"]> | Variable<any, string>
		}, ValueTypes["interaction_mutation_response"]],
		update_landingPageTemplate?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["landingPageTemplate_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["landingPageTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["landingPageTemplate_mutation_response"]],
		update_landingPageTemplate_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["landingPageTemplate_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["landingPageTemplate_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["landingPageTemplate"]],
		update_landingPageTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["landingPageTemplate_updates"]> | Variable<any, string>
		}, ValueTypes["landingPageTemplate_mutation_response"]],
		update_newTemplateType?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["newTemplateType_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["newTemplateType_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["newTemplateType_bool_exp"] | Variable<any, string>
		}, ValueTypes["newTemplateType_mutation_response"]],
		update_newTemplateType_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["newTemplateType_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["newTemplateType_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["newTemplateType_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["newTemplateType"]],
		update_newTemplateType_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["newTemplateType_updates"]> | Variable<any, string>
		}, ValueTypes["newTemplateType_mutation_response"]],
		update_openAIApiKey?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["openAIApiKey_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["openAIApiKey_bool_exp"] | Variable<any, string>
		}, ValueTypes["openAIApiKey_mutation_response"]],
		update_openAIApiKey_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["openAIApiKey_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["openAIApiKey_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["openAIApiKey"]],
		update_openAIApiKey_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["openAIApiKey_updates"]> | Variable<any, string>
		}, ValueTypes["openAIApiKey_mutation_response"]],
		update_pTemplate?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["pTemplate_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["pTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["pTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["pTemplate_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["pTemplate_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["pTemplate_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["pTemplate_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["pTemplate_bool_exp"] | Variable<any, string>
		}, ValueTypes["pTemplate_mutation_response"]],
		update_pTemplate_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["pTemplate_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["pTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["pTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["pTemplate_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["pTemplate_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["pTemplate_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["pTemplate_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["pTemplate_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["pTemplate"]],
		update_pTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["pTemplate_updates"]> | Variable<any, string>
		}, ValueTypes["pTemplate_mutation_response"]],
		update_pricingPlan?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["pricingPlan_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["pricingPlan_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["pricingPlan_bool_exp"] | Variable<any, string>
		}, ValueTypes["pricingPlan_mutation_response"]],
		update_pricingPlan_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["pricingPlan_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["pricingPlan_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["pricingPlan_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["pricingPlan"]],
		update_pricingPlan_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["pricingPlan_updates"]> | Variable<any, string>
		}, ValueTypes["pricingPlan_mutation_response"]],
		update_products?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["products_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["products_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["products_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["products_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["products_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["products_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["products_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["products_bool_exp"] | Variable<any, string>
		}, ValueTypes["products_mutation_response"]],
		update_products_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["products_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["products_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["products_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["products_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["products_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["products_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["products_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["products_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["products"]],
		update_products_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["products_updates"]> | Variable<any, string>
		}, ValueTypes["products_mutation_response"]],
		update_project?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["project_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["project_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["project_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["project_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["project_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["project_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["project_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["project_bool_exp"] | Variable<any, string>
		}, ValueTypes["project_mutation_response"]],
		update_projectImage?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["projectImage_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["projectImage_bool_exp"] | Variable<any, string>
		}, ValueTypes["projectImage_mutation_response"]],
		update_projectImage_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["projectImage_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["projectImage_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["projectImage"]],
		update_projectImage_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["projectImage_updates"]> | Variable<any, string>
		}, ValueTypes["projectImage_mutation_response"]],
		update_project_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["project_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["project_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["project_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["project_delete_key_input"] | undefined | null | Variable<any, string>,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["project_inc_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["project_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["project_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["project_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["project"]],
		update_project_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["project_updates"]> | Variable<any, string>
		}, ValueTypes["project_mutation_response"]],
		update_task?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["task_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["task_bool_exp"] | Variable<any, string>
		}, ValueTypes["task_mutation_response"]],
		update_task_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["task_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["task_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["task"]],
		update_task_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["task_updates"]> | Variable<any, string>
		}, ValueTypes["task_mutation_response"]],
		update_textTemplateDisplayFormat?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["textTemplateDisplayFormat_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["textTemplateDisplayFormat_bool_exp"] | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat_mutation_response"]],
		update_textTemplateDisplayFormat_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["textTemplateDisplayFormat_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["textTemplateDisplayFormat_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat"]],
		update_textTemplateDisplayFormat_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["textTemplateDisplayFormat_updates"]> | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat_mutation_response"]],
		update_userAppIntegration?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["userAppIntegration_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["userAppIntegration_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["userAppIntegration_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["userAppIntegration_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["userAppIntegration_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userAppIntegration_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userAppIntegration_bool_exp"] | Variable<any, string>
		}, ValueTypes["userAppIntegration_mutation_response"]],
		update_userAppIntegration_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["userAppIntegration_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["userAppIntegration_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["userAppIntegration_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["userAppIntegration_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["userAppIntegration_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userAppIntegration_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userAppIntegration_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userAppIntegration"]],
		update_userAppIntegration_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userAppIntegration_updates"]> | Variable<any, string>
		}, ValueTypes["userAppIntegration_mutation_response"]],
		update_userCreditUsage?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userCreditUsage_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userCreditUsage_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userCreditUsage_bool_exp"] | Variable<any, string>
		}, ValueTypes["userCreditUsage_mutation_response"]],
		update_userCreditUsageSummary?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userCreditUsageSummary_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userCreditUsageSummary_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userCreditUsageSummary_bool_exp"] | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary_mutation_response"]],
		update_userCreditUsageSummary_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userCreditUsageSummary_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userCreditUsageSummary_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userCreditUsageSummary_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary"]],
		update_userCreditUsageSummary_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userCreditUsageSummary_updates"]> | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary_mutation_response"]],
		update_userCreditUsage_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userCreditUsage_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userCreditUsage_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userCreditUsage_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userCreditUsage"]],
		update_userCreditUsage_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userCreditUsage_updates"]> | Variable<any, string>
		}, ValueTypes["userCreditUsage_mutation_response"]],
		update_userCredits?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userCredits_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userCredits_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userCredits_bool_exp"] | Variable<any, string>
		}, ValueTypes["userCredits_mutation_response"]],
		update_userCredits_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userCredits_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userCredits_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userCredits_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userCredits"]],
		update_userCredits_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userCredits_updates"]> | Variable<any, string>
		}, ValueTypes["userCredits_mutation_response"]],
		update_userFeedback?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userFeedback_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userFeedback_bool_exp"] | Variable<any, string>
		}, ValueTypes["userFeedback_mutation_response"]],
		update_userFeedback_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userFeedback_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userFeedback_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userFeedback"]],
		update_userFeedback_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userFeedback_updates"]> | Variable<any, string>
		}, ValueTypes["userFeedback_mutation_response"]],
		update_userMedia?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["userMedia_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["userMedia_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["userMedia_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["userMedia_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["userMedia_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userMedia_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userMedia_bool_exp"] | Variable<any, string>
		}, ValueTypes["userMedia_mutation_response"]],
		update_userMedia_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ValueTypes["userMedia_append_input"] | undefined | null | Variable<any, string>,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ValueTypes["userMedia_delete_at_path_input"] | undefined | null | Variable<any, string>,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ValueTypes["userMedia_delete_elem_input"] | undefined | null | Variable<any, string>,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ValueTypes["userMedia_delete_key_input"] | undefined | null | Variable<any, string>,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ValueTypes["userMedia_prepend_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userMedia_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userMedia_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userMedia"]],
		update_userMedia_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userMedia_updates"]> | Variable<any, string>
		}, ValueTypes["userMedia_mutation_response"]],
		update_userMetadata?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userMetadata_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userMetadata_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userMetadata_bool_exp"] | Variable<any, string>
		}, ValueTypes["userMetadata_mutation_response"]],
		update_userMetadata_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userMetadata_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userMetadata_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userMetadata_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userMetadata"]],
		update_userMetadata_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userMetadata_updates"]> | Variable<any, string>
		}, ValueTypes["userMetadata_mutation_response"]],
		update_userOnboarding?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userOnboarding_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userOnboarding_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userOnboarding_bool_exp"] | Variable<any, string>
		}, ValueTypes["userOnboarding_mutation_response"]],
		update_userOnboarding_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ValueTypes["userOnboarding_inc_input"] | undefined | null | Variable<any, string>,	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userOnboarding_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userOnboarding_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userOnboarding"]],
		update_userOnboarding_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userOnboarding_updates"]> | Variable<any, string>
		}, ValueTypes["userOnboarding_mutation_response"]],
		update_userTemplateUsage?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userTemplateUsage_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["userTemplateUsage_bool_exp"] | Variable<any, string>
		}, ValueTypes["userTemplateUsage_mutation_response"]],
		update_userTemplateUsage_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["userTemplateUsage_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["userTemplateUsage_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["userTemplateUsage"]],
		update_userTemplateUsage_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["userTemplateUsage_updates"]> | Variable<any, string>
		}, ValueTypes["userTemplateUsage_mutation_response"]],
		update_users_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["users_updates"]> | Variable<any, string>
		}, ValueTypes["users_mutation_response"]],
		update_virus_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["virus_updates"]> | Variable<any, string>
		}, ValueTypes["virus_mutation_response"]],
		update_website?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["website_set_input"] | undefined | null | Variable<any, string>,	/** filter the rows which have to be updated */
			where: ValueTypes["website_bool_exp"] | Variable<any, string>
		}, ValueTypes["website_mutation_response"]],
		update_website_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ValueTypes["website_set_input"] | undefined | null | Variable<any, string>, pk_columns: ValueTypes["website_pk_columns_input"] | Variable<any, string>
		}, ValueTypes["website"]],
		update_website_many?: [{	/** updates to execute, in order */
			updates: Array<ValueTypes["website_updates"]> | Variable<any, string>
		}, ValueTypes["website_mutation_response"]],
		upsertAudience?: [{ brandId?: string | undefined | null | Variable<any, string>, brandName?: string | undefined | null | Variable<any, string>, id?: string | undefined | null | Variable<any, string>, productOrService?: string | undefined | null | Variable<any, string>, segment?: string | undefined | null | Variable<any, string>, targetAudience?: string | undefined | null | Variable<any, string> }, ValueTypes["UpsertAudienceOutput"]],
		upsertBrand?: [{ brandId?: string | undefined | null | Variable<any, string>, brandName?: string | undefined | null | Variable<any, string>, colors?: string | undefined | null | Variable<any, string>, fontPrimary?: string | undefined | null | Variable<any, string>, fontSecondary?: string | undefined | null | Variable<any, string>, isBrand?: boolean | undefined | null | Variable<any, string>, logoFileId?: string | undefined | null | Variable<any, string>, personality?: string | undefined | null | Variable<any, string>, productOrService?: string | undefined | null | Variable<any, string>, segment?: string | undefined | null | Variable<any, string>, slogan?: string | undefined | null | Variable<any, string>, voiceTone?: string | undefined | null | Variable<any, string> }, ValueTypes["upsertBrandOutput"]],
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "newTemplateType" */
	["newTemplateType"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		thumbnailFileId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "newTemplateType" */
	["newTemplateType_aggregate"]: AliasType<{
		aggregate?: ValueTypes["newTemplateType_aggregate_fields"],
		nodes?: ValueTypes["newTemplateType"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "newTemplateType" */
	["newTemplateType_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["newTemplateType_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["newTemplateType_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["newTemplateType_max_fields"],
		min?: ValueTypes["newTemplateType_min_fields"],
		stddev?: ValueTypes["newTemplateType_stddev_fields"],
		stddev_pop?: ValueTypes["newTemplateType_stddev_pop_fields"],
		stddev_samp?: ValueTypes["newTemplateType_stddev_samp_fields"],
		sum?: ValueTypes["newTemplateType_sum_fields"],
		var_pop?: ValueTypes["newTemplateType_var_pop_fields"],
		var_samp?: ValueTypes["newTemplateType_var_samp_fields"],
		variance?: ValueTypes["newTemplateType_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["newTemplateType_avg_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "newTemplateType". All fields are combined with a logical 'AND'. */
	["newTemplateType_bool_exp"]: {
		_and?: Array<ValueTypes["newTemplateType_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["newTemplateType_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["newTemplateType_bool_exp"]> | undefined | null | Variable<any, string>,
		channel?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		icon?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		thumbnailFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "newTemplateType" */
	["newTemplateType_constraint"]: newTemplateType_constraint;
	/** input type for incrementing numeric columns in table "newTemplateType" */
	["newTemplateType_inc_input"]: {
		creditPrice?: number | undefined | null | Variable<any, string>,
		saleCreditPrice?: number | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "newTemplateType" */
	["newTemplateType_insert_input"]: {
		channel?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditPrice?: number | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		saleCreditPrice?: number | undefined | null | Variable<any, string>,
		thumbnailFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["newTemplateType_max_fields"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		thumbnailFileId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["newTemplateType_min_fields"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		thumbnailFileId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "newTemplateType" */
	["newTemplateType_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["newTemplateType"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "newTemplateType" */
	["newTemplateType_on_conflict"]: {
		constraint: ValueTypes["newTemplateType_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["newTemplateType_update_column"]> | Variable<any, string>,
		where?: ValueTypes["newTemplateType_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "newTemplateType". */
	["newTemplateType_order_by"]: {
		channel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		icon?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		saleCreditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		thumbnailFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: newTemplateType */
	["newTemplateType_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "newTemplateType" */
	["newTemplateType_select_column"]: newTemplateType_select_column;
	/** input type for updating data in table "newTemplateType" */
	["newTemplateType_set_input"]: {
		channel?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditPrice?: number | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		saleCreditPrice?: number | undefined | null | Variable<any, string>,
		thumbnailFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["newTemplateType_stddev_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["newTemplateType_stddev_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["newTemplateType_stddev_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "newTemplateType" */
	["newTemplateType_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["newTemplateType_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["newTemplateType_stream_cursor_value_input"]: {
		channel?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditPrice?: number | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		icon?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		saleCreditPrice?: number | undefined | null | Variable<any, string>,
		thumbnailFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["newTemplateType_sum_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "newTemplateType" */
	["newTemplateType_update_column"]: newTemplateType_update_column;
	["newTemplateType_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["newTemplateType_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["newTemplateType_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["newTemplateType_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["newTemplateType_var_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["newTemplateType_var_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["newTemplateType_variance_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["numeric"]: unknown;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
	["numeric_comparison_exp"]: {
		_eq?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["numeric"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["numeric"]> | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "openAIApiKey" */
	["openAIApiKey"]: AliasType<{
		apiKey?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		last_used?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "openAIApiKey" */
	["openAIApiKey_aggregate"]: AliasType<{
		aggregate?: ValueTypes["openAIApiKey_aggregate_fields"],
		nodes?: ValueTypes["openAIApiKey"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "openAIApiKey" */
	["openAIApiKey_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["openAIApiKey_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["openAIApiKey_max_fields"],
		min?: ValueTypes["openAIApiKey_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "openAIApiKey". All fields are combined with a logical 'AND'. */
	["openAIApiKey_bool_exp"]: {
		_and?: Array<ValueTypes["openAIApiKey_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["openAIApiKey_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["openAIApiKey_bool_exp"]> | undefined | null | Variable<any, string>,
		apiKey?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		last_used?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		status?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "openAIApiKey" */
	["openAIApiKey_constraint"]: openAIApiKey_constraint;
	/** input type for inserting data into table "openAIApiKey" */
	["openAIApiKey_insert_input"]: {
		apiKey?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		last_used?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["openAIApiKey_max_fields"]: AliasType<{
		apiKey?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		last_used?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["openAIApiKey_min_fields"]: AliasType<{
		apiKey?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		last_used?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "openAIApiKey" */
	["openAIApiKey_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["openAIApiKey"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "openAIApiKey" */
	["openAIApiKey_on_conflict"]: {
		constraint: ValueTypes["openAIApiKey_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["openAIApiKey_update_column"]> | Variable<any, string>,
		where?: ValueTypes["openAIApiKey_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "openAIApiKey". */
	["openAIApiKey_order_by"]: {
		apiKey?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		last_used?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		status?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: openAIApiKey */
	["openAIApiKey_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "openAIApiKey" */
	["openAIApiKey_select_column"]: openAIApiKey_select_column;
	/** input type for updating data in table "openAIApiKey" */
	["openAIApiKey_set_input"]: {
		apiKey?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		last_used?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "openAIApiKey" */
	["openAIApiKey_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["openAIApiKey_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["openAIApiKey_stream_cursor_value_input"]: {
		apiKey?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		last_used?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "openAIApiKey" */
	["openAIApiKey_update_column"]: openAIApiKey_update_column;
	["openAIApiKey_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["openAIApiKey_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["openAIApiKey_bool_exp"] | Variable<any, string>
	};
	/** column ordering options */
	["order_by"]: order_by;
	/** columns and relationships of "pTemplate" */
	["pTemplate"]: AliasType<{
		category?: boolean | `@${string}`,
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		deletedAt?: boolean | `@${string}`,
		descriptionMaxLength?: boolean | `@${string}`,
		/** A computed field, executes function "templateheight" */
		height?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		json?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		previewFileId?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		tagScores?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		titleMaxLength?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		visibility?: boolean | `@${string}`,
		/** A computed field, executes function "templatewidth" */
		width?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "pTemplate" */
	["pTemplate_aggregate"]: AliasType<{
		aggregate?: ValueTypes["pTemplate_aggregate_fields"],
		nodes?: ValueTypes["pTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "pTemplate" */
	["pTemplate_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["pTemplate_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["pTemplate_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["pTemplate_max_fields"],
		min?: ValueTypes["pTemplate_min_fields"],
		stddev?: ValueTypes["pTemplate_stddev_fields"],
		stddev_pop?: ValueTypes["pTemplate_stddev_pop_fields"],
		stddev_samp?: ValueTypes["pTemplate_stddev_samp_fields"],
		sum?: ValueTypes["pTemplate_sum_fields"],
		var_pop?: ValueTypes["pTemplate_var_pop_fields"],
		var_samp?: ValueTypes["pTemplate_var_samp_fields"],
		variance?: ValueTypes["pTemplate_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["pTemplate_append_input"]: {
		json?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		tagScores?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["pTemplate_avg_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "pTemplate". All fields are combined with a logical 'AND'. */
	["pTemplate_bool_exp"]: {
		_and?: Array<ValueTypes["pTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["pTemplate_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["pTemplate_bool_exp"]> | undefined | null | Variable<any, string>,
		category?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		createdBy?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		deletedAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		descriptionMaxLength?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		height?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		json?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		previewFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		subtitleMaxLength?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		tagScores?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		titleMaxLength?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		visibility?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		width?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "pTemplate" */
	["pTemplate_constraint"]: pTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["pTemplate_delete_at_path_input"]: {
		json?: Array<string> | undefined | null | Variable<any, string>,
		tagScores?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["pTemplate_delete_elem_input"]: {
		json?: number | undefined | null | Variable<any, string>,
		tagScores?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["pTemplate_delete_key_input"]: {
		json?: string | undefined | null | Variable<any, string>,
		tagScores?: string | undefined | null | Variable<any, string>
	};
	/** input type for incrementing numeric columns in table "pTemplate" */
	["pTemplate_inc_input"]: {
		descriptionMaxLength?: number | undefined | null | Variable<any, string>,
		subtitleMaxLength?: number | undefined | null | Variable<any, string>,
		titleMaxLength?: number | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "pTemplate" */
	["pTemplate_insert_input"]: {
		category?: string | undefined | null | Variable<any, string>,
		createdBy?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		deletedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		descriptionMaxLength?: number | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		json?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		previewFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		subtitleMaxLength?: number | undefined | null | Variable<any, string>,
		tagScores?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		titleMaxLength?: number | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		visibility?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["pTemplate_max_fields"]: AliasType<{
		category?: boolean | `@${string}`,
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		deletedAt?: boolean | `@${string}`,
		descriptionMaxLength?: boolean | `@${string}`,
		/** A computed field, executes function "templateheight" */
		height?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		previewFileId?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		visibility?: boolean | `@${string}`,
		/** A computed field, executes function "templatewidth" */
		width?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["pTemplate_min_fields"]: AliasType<{
		category?: boolean | `@${string}`,
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		deletedAt?: boolean | `@${string}`,
		descriptionMaxLength?: boolean | `@${string}`,
		/** A computed field, executes function "templateheight" */
		height?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		previewFileId?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		visibility?: boolean | `@${string}`,
		/** A computed field, executes function "templatewidth" */
		width?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "pTemplate" */
	["pTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["pTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "pTemplate" */
	["pTemplate_on_conflict"]: {
		constraint: ValueTypes["pTemplate_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["pTemplate_update_column"]> | Variable<any, string>,
		where?: ValueTypes["pTemplate_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "pTemplate". */
	["pTemplate_order_by"]: {
		category?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdBy?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		deletedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		descriptionMaxLength?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		height?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		json?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		previewFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		subtitleMaxLength?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		tagScores?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		titleMaxLength?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		visibility?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		width?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: pTemplate */
	["pTemplate_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["pTemplate_prepend_input"]: {
		json?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		tagScores?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "pTemplate" */
	["pTemplate_select_column"]: pTemplate_select_column;
	/** input type for updating data in table "pTemplate" */
	["pTemplate_set_input"]: {
		category?: string | undefined | null | Variable<any, string>,
		createdBy?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		deletedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		descriptionMaxLength?: number | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		json?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		previewFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		subtitleMaxLength?: number | undefined | null | Variable<any, string>,
		tagScores?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		titleMaxLength?: number | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		visibility?: string | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["pTemplate_stddev_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["pTemplate_stddev_pop_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["pTemplate_stddev_samp_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "pTemplate" */
	["pTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["pTemplate_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["pTemplate_stream_cursor_value_input"]: {
		category?: string | undefined | null | Variable<any, string>,
		createdBy?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		deletedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		descriptionMaxLength?: number | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		json?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		previewFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		subtitleMaxLength?: number | undefined | null | Variable<any, string>,
		tagScores?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		titleMaxLength?: number | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		visibility?: string | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["pTemplate_sum_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "pTemplate" */
	["pTemplate_update_column"]: pTemplate_update_column;
	["pTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["pTemplate_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["pTemplate_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["pTemplate_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["pTemplate_delete_key_input"] | undefined | null | Variable<any, string>,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["pTemplate_inc_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["pTemplate_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["pTemplate_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["pTemplate_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["pTemplate_var_pop_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["pTemplate_var_samp_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["pTemplate_variance_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "pricingPlan" */
	["pricingPlan"]: AliasType<{
		active?: boolean | `@${string}`,
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		htmlFeatureItems?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "pricingPlan" */
	["pricingPlan_aggregate"]: AliasType<{
		aggregate?: ValueTypes["pricingPlan_aggregate_fields"],
		nodes?: ValueTypes["pricingPlan"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "pricingPlan" */
	["pricingPlan_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["pricingPlan_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["pricingPlan_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["pricingPlan_max_fields"],
		min?: ValueTypes["pricingPlan_min_fields"],
		stddev?: ValueTypes["pricingPlan_stddev_fields"],
		stddev_pop?: ValueTypes["pricingPlan_stddev_pop_fields"],
		stddev_samp?: ValueTypes["pricingPlan_stddev_samp_fields"],
		sum?: ValueTypes["pricingPlan_sum_fields"],
		var_pop?: ValueTypes["pricingPlan_var_pop_fields"],
		var_samp?: ValueTypes["pricingPlan_var_samp_fields"],
		variance?: ValueTypes["pricingPlan_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["pricingPlan_avg_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "pricingPlan". All fields are combined with a logical 'AND'. */
	["pricingPlan_bool_exp"]: {
		_and?: Array<ValueTypes["pricingPlan_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["pricingPlan_bool_exp"]> | undefined | null | Variable<any, string>,
		active?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		alias?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		creditUnitDiscount?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		creditsToAdd?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		htmlFeatureItems?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		monthlyPrice?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		order?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		stripePriceId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		stripeProductId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "pricingPlan" */
	["pricingPlan_constraint"]: pricingPlan_constraint;
	/** input type for incrementing numeric columns in table "pricingPlan" */
	["pricingPlan_inc_input"]: {
		creditUnitDiscount?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		creditsToAdd?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		monthlyPrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		order?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "pricingPlan" */
	["pricingPlan_insert_input"]: {
		active?: boolean | undefined | null | Variable<any, string>,
		alias?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditUnitDiscount?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		creditsToAdd?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		htmlFeatureItems?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		monthlyPrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		order?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		stripePriceId?: string | undefined | null | Variable<any, string>,
		stripeProductId?: string | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["pricingPlan_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		htmlFeatureItems?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["pricingPlan_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		htmlFeatureItems?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "pricingPlan" */
	["pricingPlan_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["pricingPlan"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "pricingPlan" */
	["pricingPlan_obj_rel_insert_input"]: {
		data: ValueTypes["pricingPlan_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["pricingPlan_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "pricingPlan" */
	["pricingPlan_on_conflict"]: {
		constraint: ValueTypes["pricingPlan_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["pricingPlan_update_column"]> | Variable<any, string>,
		where?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "pricingPlan". */
	["pricingPlan_order_by"]: {
		active?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditUnitDiscount?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditsToAdd?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		htmlFeatureItems?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		monthlyPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		order?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripePriceId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeProductId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: pricingPlan */
	["pricingPlan_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "pricingPlan" */
	["pricingPlan_select_column"]: pricingPlan_select_column;
	/** input type for updating data in table "pricingPlan" */
	["pricingPlan_set_input"]: {
		active?: boolean | undefined | null | Variable<any, string>,
		alias?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditUnitDiscount?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		creditsToAdd?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		htmlFeatureItems?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		monthlyPrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		order?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		stripePriceId?: string | undefined | null | Variable<any, string>,
		stripeProductId?: string | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["pricingPlan_stddev_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["pricingPlan_stddev_pop_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["pricingPlan_stddev_samp_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "pricingPlan" */
	["pricingPlan_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["pricingPlan_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["pricingPlan_stream_cursor_value_input"]: {
		active?: boolean | undefined | null | Variable<any, string>,
		alias?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditUnitDiscount?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		creditsToAdd?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		htmlFeatureItems?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		monthlyPrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		order?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		stripePriceId?: string | undefined | null | Variable<any, string>,
		stripeProductId?: string | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["pricingPlan_sum_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "pricingPlan" */
	["pricingPlan_update_column"]: pricingPlan_update_column;
	["pricingPlan_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["pricingPlan_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["pricingPlan_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["pricingPlan_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["pricingPlan_var_pop_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["pricingPlan_var_samp_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["pricingPlan_variance_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "products" */
	["products"]: AliasType<{
		alias?: boolean | `@${string}`,
		benefits?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		/** An object relationship */
		brand?: ValueTypes["brands"],
		brandId?: boolean | `@${string}`,
		category?: boolean | `@${string}`,
		characteristics?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageFourId?: boolean | `@${string}`,
		imageOneId?: boolean | `@${string}`,
		imageThreeId?: boolean | `@${string}`,
		imageTwoId?: boolean | `@${string}`,
		linkConversion?: boolean | `@${string}`,
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		testimonials?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		wordKeys?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "products" */
	["products_aggregate"]: AliasType<{
		aggregate?: ValueTypes["products_aggregate_fields"],
		nodes?: ValueTypes["products"],
		__typename?: boolean | `@${string}`
	}>;
	["products_aggregate_bool_exp"]: {
		bool_and?: ValueTypes["products_aggregate_bool_exp_bool_and"] | undefined | null | Variable<any, string>,
		bool_or?: ValueTypes["products_aggregate_bool_exp_bool_or"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["products_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["products_aggregate_bool_exp_bool_and"]: {
		arguments: ValueTypes["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["products_aggregate_bool_exp_bool_or"]: {
		arguments: ValueTypes["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["products_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["products_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "products" */
	["products_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["products_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["products_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["products_max_fields"],
		min?: ValueTypes["products_min_fields"],
		stddev?: ValueTypes["products_stddev_fields"],
		stddev_pop?: ValueTypes["products_stddev_pop_fields"],
		stddev_samp?: ValueTypes["products_stddev_samp_fields"],
		sum?: ValueTypes["products_sum_fields"],
		var_pop?: ValueTypes["products_var_pop_fields"],
		var_samp?: ValueTypes["products_var_samp_fields"],
		variance?: ValueTypes["products_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "products" */
	["products_aggregate_order_by"]: {
		avg?: ValueTypes["products_avg_order_by"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["products_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["products_min_order_by"] | undefined | null | Variable<any, string>,
		stddev?: ValueTypes["products_stddev_order_by"] | undefined | null | Variable<any, string>,
		stddev_pop?: ValueTypes["products_stddev_pop_order_by"] | undefined | null | Variable<any, string>,
		stddev_samp?: ValueTypes["products_stddev_samp_order_by"] | undefined | null | Variable<any, string>,
		sum?: ValueTypes["products_sum_order_by"] | undefined | null | Variable<any, string>,
		var_pop?: ValueTypes["products_var_pop_order_by"] | undefined | null | Variable<any, string>,
		var_samp?: ValueTypes["products_var_samp_order_by"] | undefined | null | Variable<any, string>,
		variance?: ValueTypes["products_variance_order_by"] | undefined | null | Variable<any, string>
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["products_append_input"]: {
		benefits?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		characteristics?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		testimonials?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		wordKeys?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "products" */
	["products_arr_rel_insert_input"]: {
		data: Array<ValueTypes["products_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["products_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["products_avg_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "products" */
	["products_avg_order_by"]: {
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
	["products_bool_exp"]: {
		_and?: Array<ValueTypes["products_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["products_bool_exp"]> | undefined | null | Variable<any, string>,
		alias?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		benefits?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		brand?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		category?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		characteristics?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		imageFourId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		imageOneId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		imageThreeId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		imageTwoId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		linkConversion?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		price?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		testimonials?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		wordKeys?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "products" */
	["products_constraint"]: products_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["products_delete_at_path_input"]: {
		benefits?: Array<string> | undefined | null | Variable<any, string>,
		characteristics?: Array<string> | undefined | null | Variable<any, string>,
		testimonials?: Array<string> | undefined | null | Variable<any, string>,
		wordKeys?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["products_delete_elem_input"]: {
		benefits?: number | undefined | null | Variable<any, string>,
		characteristics?: number | undefined | null | Variable<any, string>,
		testimonials?: number | undefined | null | Variable<any, string>,
		wordKeys?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["products_delete_key_input"]: {
		benefits?: string | undefined | null | Variable<any, string>,
		characteristics?: string | undefined | null | Variable<any, string>,
		testimonials?: string | undefined | null | Variable<any, string>,
		wordKeys?: string | undefined | null | Variable<any, string>
	};
	/** input type for incrementing numeric columns in table "products" */
	["products_inc_input"]: {
		price?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "products" */
	["products_insert_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		benefits?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		brand?: ValueTypes["brands_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		category?: string | undefined | null | Variable<any, string>,
		characteristics?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageFourId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageOneId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageThreeId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageTwoId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		linkConversion?: string | undefined | null | Variable<any, string>,
		price?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		testimonials?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		wordKeys?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["products_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageFourId?: boolean | `@${string}`,
		imageOneId?: boolean | `@${string}`,
		imageThreeId?: boolean | `@${string}`,
		imageTwoId?: boolean | `@${string}`,
		linkConversion?: boolean | `@${string}`,
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "products" */
	["products_max_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		category?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageFourId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageOneId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageThreeId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageTwoId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		linkConversion?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["products_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageFourId?: boolean | `@${string}`,
		imageOneId?: boolean | `@${string}`,
		imageThreeId?: boolean | `@${string}`,
		imageTwoId?: boolean | `@${string}`,
		linkConversion?: boolean | `@${string}`,
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "products" */
	["products_min_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		category?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageFourId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageOneId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageThreeId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageTwoId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		linkConversion?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "products" */
	["products_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["products"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "products" */
	["products_obj_rel_insert_input"]: {
		data: ValueTypes["products_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["products_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "products" */
	["products_on_conflict"]: {
		constraint: ValueTypes["products_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["products_update_column"]> | Variable<any, string>,
		where?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "products". */
	["products_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		benefits?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brand?: ValueTypes["brands_order_by"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		category?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		characteristics?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageFourId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageOneId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageThreeId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		imageTwoId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		linkConversion?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		testimonials?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		wordKeys?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: products */
	["products_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["products_prepend_input"]: {
		benefits?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		characteristics?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		testimonials?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		wordKeys?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "products" */
	["products_select_column"]: products_select_column;
	/** select "products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "products" */
	["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"]: products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "products" */
	["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"]: products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "products" */
	["products_set_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		benefits?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		category?: string | undefined | null | Variable<any, string>,
		characteristics?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageFourId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageOneId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageThreeId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageTwoId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		linkConversion?: string | undefined | null | Variable<any, string>,
		price?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		testimonials?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		wordKeys?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["products_stddev_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "products" */
	["products_stddev_order_by"]: {
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_pop on columns */
	["products_stddev_pop_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "products" */
	["products_stddev_pop_order_by"]: {
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_samp on columns */
	["products_stddev_samp_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "products" */
	["products_stddev_samp_order_by"]: {
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "products" */
	["products_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["products_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["products_stream_cursor_value_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		benefits?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		category?: string | undefined | null | Variable<any, string>,
		characteristics?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageFourId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageOneId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageThreeId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		imageTwoId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		linkConversion?: string | undefined | null | Variable<any, string>,
		price?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		testimonials?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		wordKeys?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["products_sum_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "products" */
	["products_sum_order_by"]: {
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "products" */
	["products_update_column"]: products_update_column;
	["products_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["products_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["products_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["products_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["products_delete_key_input"] | undefined | null | Variable<any, string>,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["products_inc_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["products_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["products_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["products_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["products_var_pop_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "products" */
	["products_var_pop_order_by"]: {
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate var_samp on columns */
	["products_var_samp_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "products" */
	["products_var_samp_order_by"]: {
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate variance on columns */
	["products_variance_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "products" */
	["products_variance_order_by"]: {
		price?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		salePrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "project" */
	["project"]: AliasType<{
		alias?: boolean | `@${string}`,
		audienceId?: boolean | `@${string}`,
		blogContent?: boolean | `@${string}`,
		blogTitle?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignId?: boolean | `@${string}`,
		caption?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		featuredImageFileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		/** An object relationship */
		previewImageFile?: ValueTypes["files"],
		previewImageFileId?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		rawText?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		suggestedScheduledDate?: boolean | `@${string}`,
		template?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "projectImage" */
	["projectImage"]: AliasType<{
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "projectImage" */
	["projectImage_aggregate"]: AliasType<{
		aggregate?: ValueTypes["projectImage_aggregate_fields"],
		nodes?: ValueTypes["projectImage"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "projectImage" */
	["projectImage_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["projectImage_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["projectImage_max_fields"],
		min?: ValueTypes["projectImage_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "projectImage". All fields are combined with a logical 'AND'. */
	["projectImage_bool_exp"]: {
		_and?: Array<ValueTypes["projectImage_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["projectImage_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["projectImage_bool_exp"]> | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "projectImage" */
	["projectImage_constraint"]: projectImage_constraint;
	/** input type for inserting data into table "projectImage" */
	["projectImage_insert_input"]: {
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["projectImage_max_fields"]: AliasType<{
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["projectImage_min_fields"]: AliasType<{
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "projectImage" */
	["projectImage_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["projectImage"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "projectImage" */
	["projectImage_on_conflict"]: {
		constraint: ValueTypes["projectImage_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["projectImage_update_column"]> | Variable<any, string>,
		where?: ValueTypes["projectImage_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "projectImage". */
	["projectImage_order_by"]: {
		fileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: projectImage */
	["projectImage_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "projectImage" */
	["projectImage_select_column"]: projectImage_select_column;
	/** input type for updating data in table "projectImage" */
	["projectImage_set_input"]: {
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "projectImage" */
	["projectImage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["projectImage_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["projectImage_stream_cursor_value_input"]: {
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		projectId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "projectImage" */
	["projectImage_update_column"]: projectImage_update_column;
	["projectImage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["projectImage_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["projectImage_bool_exp"] | Variable<any, string>
	};
	/** aggregated selection of "project" */
	["project_aggregate"]: AliasType<{
		aggregate?: ValueTypes["project_aggregate_fields"],
		nodes?: ValueTypes["project"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "project" */
	["project_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["project_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["project_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["project_max_fields"],
		min?: ValueTypes["project_min_fields"],
		stddev?: ValueTypes["project_stddev_fields"],
		stddev_pop?: ValueTypes["project_stddev_pop_fields"],
		stddev_samp?: ValueTypes["project_stddev_samp_fields"],
		sum?: ValueTypes["project_sum_fields"],
		var_pop?: ValueTypes["project_var_pop_fields"],
		var_samp?: ValueTypes["project_var_samp_fields"],
		variance?: ValueTypes["project_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["project_append_input"]: {
		template?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["project_avg_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
	["project_bool_exp"]: {
		_and?: Array<ValueTypes["project_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["project_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["project_bool_exp"]> | undefined | null | Variable<any, string>,
		alias?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		audienceId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		blogContent?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		blogTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		campaignId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		caption?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		featuredImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		previewImageFile?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>,
		previewImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		rawText?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		status?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		suggestedScheduledDate?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		template?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "project" */
	["project_constraint"]: project_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["project_delete_at_path_input"]: {
		template?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["project_delete_elem_input"]: {
		template?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["project_delete_key_input"]: {
		template?: string | undefined | null | Variable<any, string>
	};
	/** input type for incrementing numeric columns in table "project" */
	["project_inc_input"]: {
		creditPrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "project" */
	["project_insert_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		audienceId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		blogContent?: string | undefined | null | Variable<any, string>,
		blogTitle?: string | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		campaignId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		caption?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		featuredImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		previewImageFile?: ValueTypes["files_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		previewImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		rawText?: string | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>,
		suggestedScheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		template?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["project_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		audienceId?: boolean | `@${string}`,
		blogContent?: boolean | `@${string}`,
		blogTitle?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignId?: boolean | `@${string}`,
		caption?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		featuredImageFileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		previewImageFileId?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		rawText?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		suggestedScheduledDate?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["project_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		audienceId?: boolean | `@${string}`,
		blogContent?: boolean | `@${string}`,
		blogTitle?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignId?: boolean | `@${string}`,
		caption?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		featuredImageFileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		previewImageFileId?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		rawText?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		suggestedScheduledDate?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "project" */
	["project_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["project"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "project" */
	["project_obj_rel_insert_input"]: {
		data: ValueTypes["project_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["project_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "project" */
	["project_on_conflict"]: {
		constraint: ValueTypes["project_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["project_update_column"]> | Variable<any, string>,
		where?: ValueTypes["project_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "project". */
	["project_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		audienceId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		blogContent?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		blogTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		campaignId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		caption?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		featuredImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		previewImageFile?: ValueTypes["files_order_by"] | undefined | null | Variable<any, string>,
		previewImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		rawText?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		status?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		suggestedScheduledDate?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		template?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		type?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: project */
	["project_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["project_prepend_input"]: {
		template?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "project" */
	["project_select_column"]: project_select_column;
	/** input type for updating data in table "project" */
	["project_set_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		audienceId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		blogContent?: string | undefined | null | Variable<any, string>,
		blogTitle?: string | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		campaignId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		caption?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		featuredImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		previewImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		rawText?: string | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>,
		suggestedScheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		template?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["project_stddev_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["project_stddev_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["project_stddev_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "project" */
	["project_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["project_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["project_stream_cursor_value_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		audienceId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		blogContent?: string | undefined | null | Variable<any, string>,
		blogTitle?: string | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		campaignId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		caption?: string | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		creditPrice?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		featuredImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		previewImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		productId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		publishDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		rawText?: string | undefined | null | Variable<any, string>,
		scheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		scheduledDateEventId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		status?: string | undefined | null | Variable<any, string>,
		suggestedScheduledDate?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		template?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		type?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["project_sum_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "project" */
	["project_update_column"]: project_update_column;
	["project_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["project_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["project_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["project_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["project_delete_key_input"] | undefined | null | Variable<any, string>,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["project_inc_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["project_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["project_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["project_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["project_var_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["project_var_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["project_variance_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["query_root"]: AliasType<{
		AppIntegration?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["AppIntegration_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["AppIntegration_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["AppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["AppIntegration"]],
		AppIntegration_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["AppIntegration_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["AppIntegration_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["AppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["AppIntegration_aggregate"]],
		AppIntegration_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["AppIntegration"]],
		Course?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["Course_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["Course_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["Course_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["Course"]],
		CourseCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["CourseCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["CourseCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["CourseCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["CourseCategory"]],
		CourseCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["CourseCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["CourseCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["CourseCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["CourseCategory_aggregate"]],
		CourseCategory_by_pk?: [{ id: ValueTypes["bigint"] | Variable<any, string> }, ValueTypes["CourseCategory"]],
		Course_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["Course_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["Course_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["Course_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["Course_aggregate"]],
		Course_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["Course"]],
		GeneratedPromptByTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate"]],
		GeneratedPromptByTemplateImage?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplateImage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage_aggregate"]],
		GeneratedPromptByTemplateImage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate_aggregate"]],
		GeneratedPromptByTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["GeneratedPromptByTemplate"]],
		TextGeneratorOption?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOption_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOption_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOption_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOption"]],
		TextGeneratorOptionEntry?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOptionEntry_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry_aggregate"]],
		TextGeneratorOptionEntry_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOption_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOption_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOption_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOption_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOption_aggregate"]],
		TextGeneratorOption_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextGeneratorOption"]],
		TextPromptTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate"]],
		TextPromptTemplateCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplateCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplateCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory"]],
		TextPromptTemplateCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplateCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplateCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory_aggregate"]],
		TextPromptTemplateCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextPromptTemplateCategory"]],
		TextPromptTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate_aggregate"]],
		TextPromptTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextPromptTemplate"]],
		TextTemplateClientCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextTemplateClientCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextTemplateClientCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextTemplateClientCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory"]],
		TextTemplateClientCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextTemplateClientCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextTemplateClientCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextTemplateClientCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory_aggregate"]],
		TextTemplateClientCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextTemplateClientCategory"]],
		audiences?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["audiences_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["audiences_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["audiences_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["audiences"]],
		audiences_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["audiences_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["audiences_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["audiences_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["audiences_aggregate"]],
		audiences_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["audiences"]],
		authProvider?: [{ id: string | Variable<any, string> }, ValueTypes["authProviders"]],
		authProviderRequest?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authProviderRequests"]],
		authProviderRequests?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authProviderRequests_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authProviderRequests_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviderRequests_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviderRequests"]],
		authProviderRequestsAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authProviderRequests_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authProviderRequests_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviderRequests_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviderRequests_aggregate"]],
		authProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviders"]],
		authProvidersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviders_aggregate"]],
		authRefreshToken?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authRefreshTokens"]],
		authRefreshTokenType?: [{ value: string | Variable<any, string> }, ValueTypes["authRefreshTokenTypes"]],
		authRefreshTokenTypes?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokenTypes_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokenTypes_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokenTypes_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes"]],
		authRefreshTokenTypesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokenTypes_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokenTypes_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokenTypes_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes_aggregate"]],
		authRefreshTokens?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokens_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens"]],
		authRefreshTokensAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokens_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens_aggregate"]],
		authRole?: [{ role: string | Variable<any, string> }, ValueTypes["authRoles"]],
		authRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRoles"]],
		authRolesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRoles_aggregate"]],
		authUserProvider?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserProviders"]],
		authUserProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders"]],
		authUserProvidersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders_aggregate"]],
		authUserRole?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserRoles"]],
		authUserRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles"]],
		authUserRolesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles_aggregate"]],
		authUserSecurityKey?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserSecurityKeys"]],
		authUserSecurityKeys?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserSecurityKeys_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserSecurityKeys_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys"]],
		authUserSecurityKeysAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserSecurityKeys_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserSecurityKeys_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys_aggregate"]],
		blog?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blog_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog"]],
		blogCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blogCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blogCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blogCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blogCategory"]],
		blogCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blogCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blogCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blogCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blogCategory_aggregate"]],
		blogCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["blogCategory"]],
		blogPostGeneratorEnhancedForAmp?: [{	/** the unique id of an action */
			id: ValueTypes["uuid"] | Variable<any, string>
		}, ValueTypes["blogPostGeneratorEnhancedForAmp"]],
		blog_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blog_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog_aggregate"]],
		blog_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["blog"]],
		brands?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["brands_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["brands_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["brands"]],
		brands_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["brands_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["brands_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["brands_aggregate"]],
		brands_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["brands"]],
		bucket?: [{ id: string | Variable<any, string> }, ValueTypes["buckets"]],
		buckets?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["buckets_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["buckets_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["buckets_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["buckets"]],
		bucketsAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["buckets_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["buckets_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["buckets_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["buckets_aggregate"]],
		campaign?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["campaign_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["campaign_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["campaign_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["campaign"]],
		campaign_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["campaign_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["campaign_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["campaign_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["campaign_aggregate"]],
		campaign_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["campaign"]],
		categorysummaryview?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["categorysummaryview_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["categorysummaryview_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["categorysummaryview_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["categorysummaryview"]],
		categorysummaryview_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["categorysummaryview_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["categorysummaryview_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["categorysummaryview_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["categorysummaryview_aggregate"]],
		channels?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["channels_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["channels_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["channels_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["channels"]],
		channels_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["channels_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["channels_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["channels_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["channels_aggregate"]],
		channels_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["channels"]],
		consumptionByChannelView?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["consumptionByChannelView_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["consumptionByChannelView_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["consumptionByChannelView_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["consumptionByChannelView"]],
		consumptionByChannelView_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["consumptionByChannelView_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["consumptionByChannelView_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["consumptionByChannelView_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["consumptionByChannelView_aggregate"]],
		costTable?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["costTable_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["costTable_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["costTable_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["costTable"]],
		costTable_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["costTable_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["costTable_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["costTable_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["costTable_aggregate"]],
		costTable_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["costTable"]],
		deal?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["deal_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["deal_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal"]],
		dealStatus?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["dealStatus_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["dealStatus_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["dealStatus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["dealStatus"]],
		dealStatus_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["dealStatus_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["dealStatus_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["dealStatus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["dealStatus_aggregate"]],
		dealStatus_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["dealStatus"]],
		deal_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["deal_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["deal_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal_aggregate"]],
		deal_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["deal"]],
		displayCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayCategory"]],
		displayCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayCategory_aggregate"]],
		displayCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayCategory"]],
		displayDimension?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayDimension_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayDimension_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayDimension_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayDimension"]],
		displayDimension_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayDimension_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayDimension_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayDimension_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayDimension_aggregate"]],
		displayDimension_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayDimension"]],
		displayFormats?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayFormats_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayFormats_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayFormats"]],
		displayFormats_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayFormats_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayFormats_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayFormats_aggregate"]],
		displayFormats_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayFormats"]],
		displayTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayTemplate"]],
		displayTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayTemplate_aggregate"]],
		displayTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayTemplate"]],
		file?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["files"]],
		files?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["files_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["files_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["files"]],
		filesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["files_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["files_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["files_aggregate"]],
		generateImageFromPrompt?: [{ command: ValueTypes["GenerateImageFromPromptInput"] | Variable<any, string> }, ValueTypes["GenerateImageFromPromptOutput"]],
		generateNextMonthCalendar?: [{	/** the unique id of an action */
			id: ValueTypes["uuid"] | Variable<any, string>
		}, ValueTypes["generateNextMonthCalendar"]],
		getFacebookPages?: [{ integrationId: string | Variable<any, string> }, ValueTypes["GetFacebookPagesOutput"]],
		getFacebookToken?: ValueTypes["GetFacebookTokenOutput"],
		getImageFromDisplayRenderer?: [{ templateId: string | Variable<any, string> }, ValueTypes["GetImageFromDisplayRendererOutput"]],
		getInstagramPages?: [{ integrationId: string | Variable<any, string> }, ValueTypes["GetInstagramPagesOutput"]],
		getInstagramToken?: ValueTypes["GetInstagramTokenOutput"],
		getPaymentUrl?: [{ command: ValueTypes["GetPaymentUrlCommand"] | Variable<any, string> }, ValueTypes["GetPaymentUrlResponse"]],
		getWordpressCategories?: [{ command?: ValueTypes["GetWordpressCategoriesInput"] | undefined | null | Variable<any, string> }, ValueTypes["GetWordpressCategoriesOutput"]],
		getWordpressTags?: [{ command?: ValueTypes["GetWordpressTagsInput"] | undefined | null | Variable<any, string> }, ValueTypes["GetWordpressTagsOutput"]],
		interaction?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["interaction_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["interaction_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction"]],
		interaction_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["interaction_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["interaction_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction_aggregate"]],
		interaction_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["interaction"]],
		landingPageTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["landingPageTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["landingPageTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["landingPageTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["landingPageTemplate"]],
		landingPageTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["landingPageTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["landingPageTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["landingPageTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["landingPageTemplate_aggregate"]],
		landingPageTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["landingPageTemplate"]],
		manageSubscription?: ValueTypes["ManageSubscriptionOutput"],
		newTemplateType?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["newTemplateType_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["newTemplateType_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["newTemplateType_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["newTemplateType"]],
		newTemplateType_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["newTemplateType_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["newTemplateType_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["newTemplateType_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["newTemplateType_aggregate"]],
		newTemplateType_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["newTemplateType"]],
		openAIApiKey?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["openAIApiKey_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["openAIApiKey_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["openAIApiKey_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["openAIApiKey"]],
		openAIApiKey_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["openAIApiKey_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["openAIApiKey_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["openAIApiKey_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["openAIApiKey_aggregate"]],
		openAIApiKey_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["openAIApiKey"]],
		pTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["pTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["pTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pTemplate"]],
		pTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["pTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["pTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pTemplate_aggregate"]],
		pTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["pTemplate"]],
		pricingPlan?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["pricingPlan_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["pricingPlan_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pricingPlan"]],
		pricingPlan_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["pricingPlan_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["pricingPlan_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pricingPlan_aggregate"]],
		pricingPlan_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["pricingPlan"]],
		products?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["products_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["products_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["products"]],
		products_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["products_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["products_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["products_aggregate"]],
		products_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["products"]],
		project?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["project_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["project_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["project_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["project"]],
		projectImage?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["projectImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["projectImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["projectImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["projectImage"]],
		projectImage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["projectImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["projectImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["projectImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["projectImage_aggregate"]],
		projectImage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["projectImage"]],
		project_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["project_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["project_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["project_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["project_aggregate"]],
		project_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["project"]],
		renderDisplay?: [{ data: string | Variable<any, string>, template: string | Variable<any, string> }, ValueTypes["RenderDisplayOutput"]],
		searchImagesFromPexels?: [{ locale: string | Variable<any, string>, orientation: string | Variable<any, string>, page: number | Variable<any, string>, query: string | Variable<any, string> }, ValueTypes["SearchImagesFromPexelsResponse"]],
		serpStatTest?: [{ command: ValueTypes["SerpStatTestInput"] | Variable<any, string> }, ValueTypes["SerpStatTestOutput"]],
		task?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["task_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["task_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["task"]],
		task_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["task_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["task_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["task_aggregate"]],
		task_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["task"]],
		textTemplateDisplayFormat?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["textTemplateDisplayFormat_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["textTemplateDisplayFormat_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat"]],
		textTemplateDisplayFormat_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["textTemplateDisplayFormat_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["textTemplateDisplayFormat_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat_aggregate"]],
		textTemplateDisplayFormat_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["textTemplateDisplayFormat"]],
		user?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["users"]],
		userAppIntegration?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userAppIntegration_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userAppIntegration_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userAppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userAppIntegration"]],
		userAppIntegration_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userAppIntegration_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userAppIntegration_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userAppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userAppIntegration_aggregate"]],
		userAppIntegration_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userAppIntegration"]],
		userCreditUsage?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCreditUsage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCreditUsage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsage"]],
		userCreditUsageSummary?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCreditUsageSummary_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCreditUsageSummary_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsageSummary_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary"]],
		userCreditUsageSummary_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCreditUsageSummary_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCreditUsageSummary_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsageSummary_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary_aggregate"]],
		userCreditUsageSummary_by_pk?: [{ created_by: ValueTypes["uuid"] | Variable<any, string>, yearMonthDayReference: string | Variable<any, string> }, ValueTypes["userCreditUsageSummary"]],
		userCreditUsage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCreditUsage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCreditUsage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsage_aggregate"]],
		userCreditUsage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userCreditUsage"]],
		userCredits?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCredits_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCredits_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCredits_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCredits"]],
		userCredits_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCredits_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCredits_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCredits_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCredits_aggregate"]],
		userCredits_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userCredits"]],
		userFeedback?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userFeedback_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userFeedback_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userFeedback_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userFeedback"]],
		userFeedback_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userFeedback_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userFeedback_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userFeedback_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userFeedback_aggregate"]],
		userFeedback_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userFeedback"]],
		userMedia?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMedia_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMedia_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMedia_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMedia"]],
		userMedia_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMedia_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMedia_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMedia_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMedia_aggregate"]],
		userMedia_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userMedia"]],
		userMetadata?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMetadata_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMetadata_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata"]],
		userMetadata_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMetadata_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMetadata_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata_aggregate"]],
		userMetadata_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userMetadata"]],
		userOnboarding?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userOnboarding_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userOnboarding_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userOnboarding_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userOnboarding"]],
		userOnboarding_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userOnboarding_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userOnboarding_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userOnboarding_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userOnboarding_aggregate"]],
		userOnboarding_by_pk?: [{ userId: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userOnboarding"]],
		userTemplateUsage?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userTemplateUsage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userTemplateUsage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userTemplateUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userTemplateUsage"]],
		userTemplateUsage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userTemplateUsage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userTemplateUsage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userTemplateUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userTemplateUsage_aggregate"]],
		userTemplateUsage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userTemplateUsage"]],
		users?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["users_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["users_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["users"]],
		usersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["users_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["users_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["users_aggregate"]],
		virus?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["virus"]],
		viruses?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["virus_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["virus_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["virus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["virus"]],
		virusesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["virus_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["virus_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["virus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["virus_aggregate"]],
		website?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["website_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["website_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["website_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["website"]],
		website_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["website_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["website_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["website_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["website_aggregate"]],
		website_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["website"]],
		__typename?: boolean | `@${string}`
	}>;
	["subscription_root"]: AliasType<{
		AppIntegration?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["AppIntegration_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["AppIntegration_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["AppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["AppIntegration"]],
		AppIntegration_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["AppIntegration_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["AppIntegration_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["AppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["AppIntegration_aggregate"]],
		AppIntegration_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["AppIntegration"]],
		AppIntegration_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["AppIntegration_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["AppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["AppIntegration"]],
		Course?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["Course_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["Course_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["Course_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["Course"]],
		CourseCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["CourseCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["CourseCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["CourseCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["CourseCategory"]],
		CourseCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["CourseCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["CourseCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["CourseCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["CourseCategory_aggregate"]],
		CourseCategory_by_pk?: [{ id: ValueTypes["bigint"] | Variable<any, string> }, ValueTypes["CourseCategory"]],
		CourseCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["CourseCategory_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["CourseCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["CourseCategory"]],
		Course_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["Course_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["Course_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["Course_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["Course_aggregate"]],
		Course_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["Course"]],
		Course_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["Course_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["Course_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["Course"]],
		GeneratedPromptByTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate"]],
		GeneratedPromptByTemplateImage?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplateImage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage_aggregate"]],
		GeneratedPromptByTemplateImage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplateImage_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["GeneratedPromptByTemplateImage_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate_aggregate"]],
		GeneratedPromptByTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["GeneratedPromptByTemplate"]],
		GeneratedPromptByTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["GeneratedPromptByTemplate_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["GeneratedPromptByTemplate"]],
		TextGeneratorOption?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOption_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOption_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOption_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOption"]],
		TextGeneratorOptionEntry?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOptionEntry_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry_aggregate"]],
		TextGeneratorOptionEntry_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOptionEntry_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["TextGeneratorOptionEntry_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOption_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextGeneratorOption_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextGeneratorOption_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOption_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOption_aggregate"]],
		TextGeneratorOption_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextGeneratorOption"]],
		TextGeneratorOption_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["TextGeneratorOption_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextGeneratorOption_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextGeneratorOption"]],
		TextPromptTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate"]],
		TextPromptTemplateCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplateCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplateCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory"]],
		TextPromptTemplateCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplateCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplateCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory_aggregate"]],
		TextPromptTemplateCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextPromptTemplateCategory"]],
		TextPromptTemplateCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["TextPromptTemplateCategory_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplateCategory"]],
		TextPromptTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextPromptTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextPromptTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate_aggregate"]],
		TextPromptTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextPromptTemplate"]],
		TextPromptTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["TextPromptTemplate_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextPromptTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextPromptTemplate"]],
		TextTemplateClientCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextTemplateClientCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextTemplateClientCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextTemplateClientCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory"]],
		TextTemplateClientCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["TextTemplateClientCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["TextTemplateClientCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextTemplateClientCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory_aggregate"]],
		TextTemplateClientCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["TextTemplateClientCategory"]],
		TextTemplateClientCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["TextTemplateClientCategory_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["TextTemplateClientCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["TextTemplateClientCategory"]],
		audiences?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["audiences_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["audiences_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["audiences_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["audiences"]],
		audiences_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["audiences_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["audiences_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["audiences_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["audiences_aggregate"]],
		audiences_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["audiences"]],
		audiences_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["audiences_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["audiences_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["audiences"]],
		authProvider?: [{ id: string | Variable<any, string> }, ValueTypes["authProviders"]],
		authProviderRequest?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authProviderRequests"]],
		authProviderRequests?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authProviderRequests_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authProviderRequests_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviderRequests_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviderRequests"]],
		authProviderRequestsAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authProviderRequests_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authProviderRequests_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviderRequests_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviderRequests_aggregate"]],
		authProviderRequests_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["authProviderRequests_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviderRequests_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviderRequests"]],
		authProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviders"]],
		authProvidersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviders_aggregate"]],
		authProviders_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["authProviders_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authProviders"]],
		authRefreshToken?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authRefreshTokens"]],
		authRefreshTokenType?: [{ value: string | Variable<any, string> }, ValueTypes["authRefreshTokenTypes"]],
		authRefreshTokenTypes?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokenTypes_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokenTypes_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokenTypes_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes"]],
		authRefreshTokenTypesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokenTypes_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokenTypes_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokenTypes_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes_aggregate"]],
		authRefreshTokenTypes_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["authRefreshTokenTypes_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokenTypes_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokenTypes"]],
		authRefreshTokens?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokens_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens"]],
		authRefreshTokensAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokens_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens_aggregate"]],
		authRefreshTokens_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["authRefreshTokens_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens"]],
		authRole?: [{ role: string | Variable<any, string> }, ValueTypes["authRoles"]],
		authRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRoles"]],
		authRolesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRoles_aggregate"]],
		authRoles_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["authRoles_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRoles"]],
		authUserProvider?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserProviders"]],
		authUserProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders"]],
		authUserProvidersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders_aggregate"]],
		authUserProviders_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["authUserProviders_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders"]],
		authUserRole?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserRoles"]],
		authUserRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles"]],
		authUserRolesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles_aggregate"]],
		authUserRoles_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["authUserRoles_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles"]],
		authUserSecurityKey?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["authUserSecurityKeys"]],
		authUserSecurityKeys?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserSecurityKeys_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserSecurityKeys_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys"]],
		authUserSecurityKeysAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserSecurityKeys_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserSecurityKeys_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys_aggregate"]],
		authUserSecurityKeys_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["authUserSecurityKeys_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys"]],
		blog?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blog_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog"]],
		blogCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blogCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blogCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blogCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blogCategory"]],
		blogCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blogCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blogCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blogCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blogCategory_aggregate"]],
		blogCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["blogCategory"]],
		blogCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["blogCategory_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blogCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blogCategory"]],
		blogPostGeneratorEnhancedForAmp?: [{	/** the unique id of an action */
			id: ValueTypes["uuid"] | Variable<any, string>
		}, ValueTypes["blogPostGeneratorEnhancedForAmp"]],
		blog_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["blog_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["blog_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog_aggregate"]],
		blog_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["blog"]],
		blog_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["blog_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["blog_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["blog"]],
		brands?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["brands_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["brands_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["brands"]],
		brands_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["brands_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["brands_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["brands_aggregate"]],
		brands_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["brands"]],
		brands_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["brands_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["brands"]],
		bucket?: [{ id: string | Variable<any, string> }, ValueTypes["buckets"]],
		buckets?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["buckets_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["buckets_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["buckets_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["buckets"]],
		bucketsAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["buckets_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["buckets_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["buckets_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["buckets_aggregate"]],
		buckets_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["buckets_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["buckets_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["buckets"]],
		campaign?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["campaign_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["campaign_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["campaign_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["campaign"]],
		campaign_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["campaign_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["campaign_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["campaign_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["campaign_aggregate"]],
		campaign_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["campaign"]],
		campaign_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["campaign_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["campaign_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["campaign"]],
		categorysummaryview?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["categorysummaryview_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["categorysummaryview_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["categorysummaryview_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["categorysummaryview"]],
		categorysummaryview_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["categorysummaryview_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["categorysummaryview_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["categorysummaryview_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["categorysummaryview_aggregate"]],
		categorysummaryview_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["categorysummaryview_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["categorysummaryview_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["categorysummaryview"]],
		channels?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["channels_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["channels_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["channels_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["channels"]],
		channels_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["channels_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["channels_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["channels_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["channels_aggregate"]],
		channels_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["channels"]],
		channels_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["channels_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["channels_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["channels"]],
		consumptionByChannelView?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["consumptionByChannelView_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["consumptionByChannelView_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["consumptionByChannelView_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["consumptionByChannelView"]],
		consumptionByChannelView_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["consumptionByChannelView_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["consumptionByChannelView_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["consumptionByChannelView_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["consumptionByChannelView_aggregate"]],
		consumptionByChannelView_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["consumptionByChannelView_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["consumptionByChannelView_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["consumptionByChannelView"]],
		costTable?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["costTable_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["costTable_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["costTable_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["costTable"]],
		costTable_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["costTable_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["costTable_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["costTable_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["costTable_aggregate"]],
		costTable_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["costTable"]],
		costTable_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["costTable_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["costTable_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["costTable"]],
		deal?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["deal_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["deal_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal"]],
		dealStatus?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["dealStatus_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["dealStatus_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["dealStatus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["dealStatus"]],
		dealStatus_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["dealStatus_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["dealStatus_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["dealStatus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["dealStatus_aggregate"]],
		dealStatus_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["dealStatus"]],
		dealStatus_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["dealStatus_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["dealStatus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["dealStatus"]],
		deal_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["deal_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["deal_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal_aggregate"]],
		deal_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["deal"]],
		deal_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["deal_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["deal"]],
		displayCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayCategory"]],
		displayCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayCategory_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayCategory_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayCategory_aggregate"]],
		displayCategory_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayCategory"]],
		displayCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["displayCategory_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayCategory_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayCategory"]],
		displayDimension?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayDimension_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayDimension_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayDimension_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayDimension"]],
		displayDimension_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayDimension_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayDimension_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayDimension_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayDimension_aggregate"]],
		displayDimension_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayDimension"]],
		displayDimension_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["displayDimension_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayDimension_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayDimension"]],
		displayFormats?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayFormats_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayFormats_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayFormats"]],
		displayFormats_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayFormats_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayFormats_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayFormats_aggregate"]],
		displayFormats_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayFormats"]],
		displayFormats_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["displayFormats_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayFormats"]],
		displayTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayTemplate"]],
		displayTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["displayTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["displayTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayTemplate_aggregate"]],
		displayTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["displayTemplate"]],
		displayTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["displayTemplate_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["displayTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["displayTemplate"]],
		file?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["files"]],
		files?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["files_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["files_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["files"]],
		filesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["files_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["files_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["files_aggregate"]],
		files_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["files_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["files"]],
		generateNextMonthCalendar?: [{	/** the unique id of an action */
			id: ValueTypes["uuid"] | Variable<any, string>
		}, ValueTypes["generateNextMonthCalendar"]],
		interaction?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["interaction_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["interaction_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction"]],
		interaction_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["interaction_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["interaction_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction_aggregate"]],
		interaction_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["interaction"]],
		interaction_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["interaction_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["interaction_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["interaction"]],
		landingPageTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["landingPageTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["landingPageTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["landingPageTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["landingPageTemplate"]],
		landingPageTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["landingPageTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["landingPageTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["landingPageTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["landingPageTemplate_aggregate"]],
		landingPageTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["landingPageTemplate"]],
		landingPageTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["landingPageTemplate_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["landingPageTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["landingPageTemplate"]],
		newTemplateType?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["newTemplateType_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["newTemplateType_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["newTemplateType_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["newTemplateType"]],
		newTemplateType_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["newTemplateType_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["newTemplateType_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["newTemplateType_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["newTemplateType_aggregate"]],
		newTemplateType_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["newTemplateType"]],
		newTemplateType_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["newTemplateType_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["newTemplateType_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["newTemplateType"]],
		openAIApiKey?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["openAIApiKey_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["openAIApiKey_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["openAIApiKey_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["openAIApiKey"]],
		openAIApiKey_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["openAIApiKey_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["openAIApiKey_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["openAIApiKey_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["openAIApiKey_aggregate"]],
		openAIApiKey_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["openAIApiKey"]],
		openAIApiKey_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["openAIApiKey_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["openAIApiKey_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["openAIApiKey"]],
		pTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["pTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["pTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pTemplate"]],
		pTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["pTemplate_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["pTemplate_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pTemplate_aggregate"]],
		pTemplate_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["pTemplate"]],
		pTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["pTemplate_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pTemplate_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pTemplate"]],
		pricingPlan?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["pricingPlan_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["pricingPlan_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pricingPlan"]],
		pricingPlan_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["pricingPlan_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["pricingPlan_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pricingPlan_aggregate"]],
		pricingPlan_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["pricingPlan"]],
		pricingPlan_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["pricingPlan_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["pricingPlan"]],
		products?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["products_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["products_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["products"]],
		products_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["products_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["products_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["products_aggregate"]],
		products_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["products"]],
		products_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["products_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["products_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["products"]],
		project?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["project_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["project_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["project_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["project"]],
		projectImage?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["projectImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["projectImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["projectImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["projectImage"]],
		projectImage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["projectImage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["projectImage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["projectImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["projectImage_aggregate"]],
		projectImage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["projectImage"]],
		projectImage_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["projectImage_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["projectImage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["projectImage"]],
		project_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["project_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["project_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["project_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["project_aggregate"]],
		project_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["project"]],
		project_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["project_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["project_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["project"]],
		task?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["task_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["task_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["task"]],
		task_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["task_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["task_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["task_aggregate"]],
		task_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["task"]],
		task_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["task_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["task"]],
		textTemplateDisplayFormat?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["textTemplateDisplayFormat_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["textTemplateDisplayFormat_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat"]],
		textTemplateDisplayFormat_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["textTemplateDisplayFormat_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["textTemplateDisplayFormat_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat_aggregate"]],
		textTemplateDisplayFormat_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["textTemplateDisplayFormat"]],
		textTemplateDisplayFormat_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["textTemplateDisplayFormat_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["textTemplateDisplayFormat"]],
		user?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["users"]],
		userAppIntegration?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userAppIntegration_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userAppIntegration_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userAppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userAppIntegration"]],
		userAppIntegration_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userAppIntegration_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userAppIntegration_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userAppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userAppIntegration_aggregate"]],
		userAppIntegration_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userAppIntegration"]],
		userAppIntegration_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userAppIntegration_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userAppIntegration_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userAppIntegration"]],
		userCreditUsage?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCreditUsage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCreditUsage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsage"]],
		userCreditUsageSummary?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCreditUsageSummary_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCreditUsageSummary_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsageSummary_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary"]],
		userCreditUsageSummary_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCreditUsageSummary_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCreditUsageSummary_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsageSummary_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary_aggregate"]],
		userCreditUsageSummary_by_pk?: [{ created_by: ValueTypes["uuid"] | Variable<any, string>, yearMonthDayReference: string | Variable<any, string> }, ValueTypes["userCreditUsageSummary"]],
		userCreditUsageSummary_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userCreditUsageSummary_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsageSummary_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsageSummary"]],
		userCreditUsage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCreditUsage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCreditUsage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsage_aggregate"]],
		userCreditUsage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userCreditUsage"]],
		userCreditUsage_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userCreditUsage_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCreditUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCreditUsage"]],
		userCredits?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCredits_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCredits_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCredits_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCredits"]],
		userCredits_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userCredits_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userCredits_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCredits_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCredits_aggregate"]],
		userCredits_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userCredits"]],
		userCredits_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userCredits_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userCredits_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userCredits"]],
		userFeedback?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userFeedback_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userFeedback_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userFeedback_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userFeedback"]],
		userFeedback_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userFeedback_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userFeedback_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userFeedback_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userFeedback_aggregate"]],
		userFeedback_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userFeedback"]],
		userFeedback_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userFeedback_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userFeedback_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userFeedback"]],
		userMedia?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMedia_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMedia_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMedia_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMedia"]],
		userMedia_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMedia_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMedia_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMedia_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMedia_aggregate"]],
		userMedia_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userMedia"]],
		userMedia_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userMedia_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMedia_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMedia"]],
		userMetadata?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMetadata_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMetadata_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata"]],
		userMetadata_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMetadata_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMetadata_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata_aggregate"]],
		userMetadata_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userMetadata"]],
		userMetadata_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userMetadata_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata"]],
		userOnboarding?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userOnboarding_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userOnboarding_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userOnboarding_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userOnboarding"]],
		userOnboarding_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userOnboarding_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userOnboarding_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userOnboarding_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userOnboarding_aggregate"]],
		userOnboarding_by_pk?: [{ userId: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userOnboarding"]],
		userOnboarding_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userOnboarding_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userOnboarding_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userOnboarding"]],
		userTemplateUsage?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userTemplateUsage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userTemplateUsage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userTemplateUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userTemplateUsage"]],
		userTemplateUsage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userTemplateUsage_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userTemplateUsage_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userTemplateUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userTemplateUsage_aggregate"]],
		userTemplateUsage_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["userTemplateUsage"]],
		userTemplateUsage_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["userTemplateUsage_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userTemplateUsage_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userTemplateUsage"]],
		users?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["users_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["users_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["users"]],
		usersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["users_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["users_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["users_aggregate"]],
		users_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["users_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["users"]],
		virus?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["virus"]],
		virus_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["virus_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["virus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["virus"]],
		viruses?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["virus_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["virus_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["virus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["virus"]],
		virusesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["virus_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["virus_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["virus_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["virus_aggregate"]],
		website?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["website_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["website_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["website_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["website"]],
		website_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["website_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["website_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["website_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["website_aggregate"]],
		website_by_pk?: [{ id: ValueTypes["uuid"] | Variable<any, string> }, ValueTypes["website"]],
		website_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number | Variable<any, string>,	/** cursor to stream the results returned by the query */
			cursor: Array<ValueTypes["website_stream_cursor_input"] | undefined | null> | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["website_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["website"]],
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "task" */
	["task"]: AliasType<{
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		/** An object relationship */
		deal?: ValueTypes["deal"],
		dealId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		finished?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		/** An object relationship */
		user?: ValueTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "task" */
	["task_aggregate"]: AliasType<{
		aggregate?: ValueTypes["task_aggregate_fields"],
		nodes?: ValueTypes["task"],
		__typename?: boolean | `@${string}`
	}>;
	["task_aggregate_bool_exp"]: {
		bool_and?: ValueTypes["task_aggregate_bool_exp_bool_and"] | undefined | null | Variable<any, string>,
		bool_or?: ValueTypes["task_aggregate_bool_exp_bool_or"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["task_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["task_aggregate_bool_exp_bool_and"]: {
		arguments: ValueTypes["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["task_aggregate_bool_exp_bool_or"]: {
		arguments: ValueTypes["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["task_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["task_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "task" */
	["task_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["task_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["task_max_fields"],
		min?: ValueTypes["task_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "task" */
	["task_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["task_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["task_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "task" */
	["task_arr_rel_insert_input"]: {
		data: Array<ValueTypes["task_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["task_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
	["task_bool_exp"]: {
		_and?: Array<ValueTypes["task_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["task_bool_exp"]> | undefined | null | Variable<any, string>,
		category?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["timestamp_comparison_exp"] | undefined | null | Variable<any, string>,
		deal?: ValueTypes["deal_bool_exp"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		finished?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		title?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "task" */
	["task_constraint"]: task_constraint;
	/** input type for inserting data into table "task" */
	["task_insert_input"]: {
		category?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		deal?: ValueTypes["deal_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		finished?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		title?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_obj_rel_insert_input"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["task_max_fields"]: AliasType<{
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "task" */
	["task_max_order_by"]: {
		category?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		title?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["task_min_fields"]: AliasType<{
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "task" */
	["task_min_order_by"]: {
		category?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		title?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "task" */
	["task_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["task"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "task" */
	["task_on_conflict"]: {
		constraint: ValueTypes["task_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["task_update_column"]> | Variable<any, string>,
		where?: ValueTypes["task_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "task". */
	["task_order_by"]: {
		category?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		deal?: ValueTypes["deal_order_by"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		description?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		finished?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		title?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: task */
	["task_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "task" */
	["task_select_column"]: task_select_column;
	/** select "task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task" */
	["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"]: task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task" */
	["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"]: task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "task" */
	["task_set_input"]: {
		category?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		finished?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		title?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "task" */
	["task_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["task_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["task_stream_cursor_value_input"]: {
		category?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		date?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		dealId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		description?: string | undefined | null | Variable<any, string>,
		finished?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		title?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "task" */
	["task_update_column"]: task_update_column;
	["task_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["task_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["task_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat"]: AliasType<{
		/** An object relationship */
		displayFormat?: ValueTypes["displayFormats"],
		displayFormatId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate"]: AliasType<{
		aggregate?: ValueTypes["textTemplateDisplayFormat_aggregate_fields"],
		nodes?: ValueTypes["textTemplateDisplayFormat"],
		__typename?: boolean | `@${string}`
	}>;
	["textTemplateDisplayFormat_aggregate_bool_exp"]: {
		count?: ValueTypes["textTemplateDisplayFormat_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["textTemplateDisplayFormat_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["textTemplateDisplayFormat_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["textTemplateDisplayFormat_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["textTemplateDisplayFormat_max_fields"],
		min?: ValueTypes["textTemplateDisplayFormat_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["textTemplateDisplayFormat_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["textTemplateDisplayFormat_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_arr_rel_insert_input"]: {
		data: Array<ValueTypes["textTemplateDisplayFormat_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["textTemplateDisplayFormat_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "textTemplateDisplayFormat". All fields are combined with a logical 'AND'. */
	["textTemplateDisplayFormat_bool_exp"]: {
		_and?: Array<ValueTypes["textTemplateDisplayFormat_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["textTemplateDisplayFormat_bool_exp"]> | undefined | null | Variable<any, string>,
		displayFormat?: ValueTypes["displayFormats_bool_exp"] | undefined | null | Variable<any, string>,
		displayFormatId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		textTemplateId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_constraint"]: textTemplateDisplayFormat_constraint;
	/** input type for inserting data into table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_insert_input"]: {
		displayFormat?: ValueTypes["displayFormats_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		displayFormatId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		textTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["textTemplateDisplayFormat_max_fields"]: AliasType<{
		displayFormatId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_max_order_by"]: {
		displayFormatId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		textTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["textTemplateDisplayFormat_min_fields"]: AliasType<{
		displayFormatId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_min_order_by"]: {
		displayFormatId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		textTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["textTemplateDisplayFormat"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_on_conflict"]: {
		constraint: ValueTypes["textTemplateDisplayFormat_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["textTemplateDisplayFormat_update_column"]> | Variable<any, string>,
		where?: ValueTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "textTemplateDisplayFormat". */
	["textTemplateDisplayFormat_order_by"]: {
		displayFormat?: ValueTypes["displayFormats_order_by"] | undefined | null | Variable<any, string>,
		displayFormatId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		textTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: textTemplateDisplayFormat */
	["textTemplateDisplayFormat_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_select_column"]: textTemplateDisplayFormat_select_column;
	/** input type for updating data in table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_set_input"]: {
		displayFormatId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		textTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["textTemplateDisplayFormat_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["textTemplateDisplayFormat_stream_cursor_value_input"]: {
		displayFormatId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		textTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_update_column"]: textTemplateDisplayFormat_update_column;
	["textTemplateDisplayFormat_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["textTemplateDisplayFormat_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["textTemplateDisplayFormat_bool_exp"] | Variable<any, string>
	};
	["timestamp"]: unknown;
	/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
	["timestamp_comparison_exp"]: {
		_eq?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["timestamp"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["timestamp"]> | undefined | null | Variable<any, string>
	};
	["timestamptz"]: unknown;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
	["timestamptz_comparison_exp"]: {
		_eq?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["timestamptz"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["timestamptz"]> | undefined | null | Variable<any, string>
	};
	["upsertBrandOutput"]: AliasType<{
		archtypeAdherence?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		colors?: boolean | `@${string}`,
		fontPrimary?: boolean | `@${string}`,
		fontSecondary?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		isBrand?: boolean | `@${string}`,
		logoFileId?: boolean | `@${string}`,
		personality?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		slogan?: boolean | `@${string}`,
		voiceTone?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userAppIntegration" */
	["userAppIntegration"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		integrationType?: boolean | `@${string}`,
		params?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userAppIntegration" */
	["userAppIntegration_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userAppIntegration_aggregate_fields"],
		nodes?: ValueTypes["userAppIntegration"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userAppIntegration" */
	["userAppIntegration_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["userAppIntegration_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userAppIntegration_max_fields"],
		min?: ValueTypes["userAppIntegration_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["userAppIntegration_append_input"]: {
		params?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "userAppIntegration". All fields are combined with a logical 'AND'. */
	["userAppIntegration_bool_exp"]: {
		_and?: Array<ValueTypes["userAppIntegration_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userAppIntegration_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userAppIntegration_bool_exp"]> | undefined | null | Variable<any, string>,
		alias?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		integrationType?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		params?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userAppIntegration" */
	["userAppIntegration_constraint"]: userAppIntegration_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["userAppIntegration_delete_at_path_input"]: {
		params?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["userAppIntegration_delete_elem_input"]: {
		params?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["userAppIntegration_delete_key_input"]: {
		params?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "userAppIntegration" */
	["userAppIntegration_insert_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		integrationType?: string | undefined | null | Variable<any, string>,
		params?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userAppIntegration_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		integrationType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userAppIntegration_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		integrationType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userAppIntegration" */
	["userAppIntegration_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userAppIntegration"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userAppIntegration" */
	["userAppIntegration_on_conflict"]: {
		constraint: ValueTypes["userAppIntegration_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userAppIntegration_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userAppIntegration_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userAppIntegration". */
	["userAppIntegration_order_by"]: {
		alias?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		integrationType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		params?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userAppIntegration */
	["userAppIntegration_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["userAppIntegration_prepend_input"]: {
		params?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "userAppIntegration" */
	["userAppIntegration_select_column"]: userAppIntegration_select_column;
	/** input type for updating data in table "userAppIntegration" */
	["userAppIntegration_set_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		integrationType?: string | undefined | null | Variable<any, string>,
		params?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "userAppIntegration" */
	["userAppIntegration_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userAppIntegration_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userAppIntegration_stream_cursor_value_input"]: {
		alias?: string | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		integrationType?: string | undefined | null | Variable<any, string>,
		params?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "userAppIntegration" */
	["userAppIntegration_update_column"]: userAppIntegration_update_column;
	["userAppIntegration_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["userAppIntegration_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["userAppIntegration_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["userAppIntegration_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["userAppIntegration_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["userAppIntegration_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userAppIntegration_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userAppIntegration_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "userCreditUsage" */
	["userCreditUsage"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsed?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userCreditUsageSummary" */
	["userCreditUsageSummary"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsage?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		yearMonthDayReference?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userCreditUsageSummary" */
	["userCreditUsageSummary_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userCreditUsageSummary_aggregate_fields"],
		nodes?: ValueTypes["userCreditUsageSummary"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userCreditUsageSummary" */
	["userCreditUsageSummary_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["userCreditUsageSummary_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["userCreditUsageSummary_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userCreditUsageSummary_max_fields"],
		min?: ValueTypes["userCreditUsageSummary_min_fields"],
		stddev?: ValueTypes["userCreditUsageSummary_stddev_fields"],
		stddev_pop?: ValueTypes["userCreditUsageSummary_stddev_pop_fields"],
		stddev_samp?: ValueTypes["userCreditUsageSummary_stddev_samp_fields"],
		sum?: ValueTypes["userCreditUsageSummary_sum_fields"],
		var_pop?: ValueTypes["userCreditUsageSummary_var_pop_fields"],
		var_samp?: ValueTypes["userCreditUsageSummary_var_samp_fields"],
		variance?: ValueTypes["userCreditUsageSummary_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["userCreditUsageSummary_avg_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userCreditUsageSummary". All fields are combined with a logical 'AND'. */
	["userCreditUsageSummary_bool_exp"]: {
		_and?: Array<ValueTypes["userCreditUsageSummary_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userCreditUsageSummary_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userCreditUsageSummary_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		freeCreditUsage?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		originalVaue?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		yearMonthDayReference?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userCreditUsageSummary" */
	["userCreditUsageSummary_constraint"]: userCreditUsageSummary_constraint;
	/** input type for incrementing numeric columns in table "userCreditUsageSummary" */
	["userCreditUsageSummary_inc_input"]: {
		freeCreditUsage?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		originalVaue?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "userCreditUsageSummary" */
	["userCreditUsageSummary_insert_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		freeCreditUsage?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		originalVaue?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		yearMonthDayReference?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userCreditUsageSummary_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsage?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		yearMonthDayReference?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userCreditUsageSummary_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsage?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		yearMonthDayReference?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userCreditUsageSummary" */
	["userCreditUsageSummary_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userCreditUsageSummary"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userCreditUsageSummary" */
	["userCreditUsageSummary_on_conflict"]: {
		constraint: ValueTypes["userCreditUsageSummary_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userCreditUsageSummary_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userCreditUsageSummary_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userCreditUsageSummary". */
	["userCreditUsageSummary_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		freeCreditUsage?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		originalVaue?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		yearMonthDayReference?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userCreditUsageSummary */
	["userCreditUsageSummary_pk_columns_input"]: {
		created_by: ValueTypes["uuid"] | Variable<any, string>,
		yearMonthDayReference: string | Variable<any, string>
	};
	/** select columns of table "userCreditUsageSummary" */
	["userCreditUsageSummary_select_column"]: userCreditUsageSummary_select_column;
	/** input type for updating data in table "userCreditUsageSummary" */
	["userCreditUsageSummary_set_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		freeCreditUsage?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		originalVaue?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		yearMonthDayReference?: string | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["userCreditUsageSummary_stddev_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["userCreditUsageSummary_stddev_pop_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["userCreditUsageSummary_stddev_samp_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "userCreditUsageSummary" */
	["userCreditUsageSummary_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userCreditUsageSummary_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userCreditUsageSummary_stream_cursor_value_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		freeCreditUsage?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		originalVaue?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		yearMonthDayReference?: string | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["userCreditUsageSummary_sum_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "userCreditUsageSummary" */
	["userCreditUsageSummary_update_column"]: userCreditUsageSummary_update_column;
	["userCreditUsageSummary_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["userCreditUsageSummary_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userCreditUsageSummary_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userCreditUsageSummary_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["userCreditUsageSummary_var_pop_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["userCreditUsageSummary_var_samp_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["userCreditUsageSummary_variance_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userCreditUsage" */
	["userCreditUsage_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userCreditUsage_aggregate_fields"],
		nodes?: ValueTypes["userCreditUsage"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userCreditUsage" */
	["userCreditUsage_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["userCreditUsage_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["userCreditUsage_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userCreditUsage_max_fields"],
		min?: ValueTypes["userCreditUsage_min_fields"],
		stddev?: ValueTypes["userCreditUsage_stddev_fields"],
		stddev_pop?: ValueTypes["userCreditUsage_stddev_pop_fields"],
		stddev_samp?: ValueTypes["userCreditUsage_stddev_samp_fields"],
		sum?: ValueTypes["userCreditUsage_sum_fields"],
		var_pop?: ValueTypes["userCreditUsage_var_pop_fields"],
		var_samp?: ValueTypes["userCreditUsage_var_samp_fields"],
		variance?: ValueTypes["userCreditUsage_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["userCreditUsage_avg_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userCreditUsage". All fields are combined with a logical 'AND'. */
	["userCreditUsage_bool_exp"]: {
		GeneratedPromptByTemplateId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		_and?: Array<ValueTypes["userCreditUsage_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userCreditUsage_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userCreditUsage_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		freeCreditUsed?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		originalValue?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userCreditUsage" */
	["userCreditUsage_constraint"]: userCreditUsage_constraint;
	/** input type for incrementing numeric columns in table "userCreditUsage" */
	["userCreditUsage_inc_input"]: {
		freeCreditUsed?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		originalValue?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "userCreditUsage" */
	["userCreditUsage_insert_input"]: {
		GeneratedPromptByTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		freeCreditUsed?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		originalValue?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userCreditUsage_max_fields"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsed?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userCreditUsage_min_fields"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsed?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userCreditUsage" */
	["userCreditUsage_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userCreditUsage"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userCreditUsage" */
	["userCreditUsage_on_conflict"]: {
		constraint: ValueTypes["userCreditUsage_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userCreditUsage_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userCreditUsage_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userCreditUsage". */
	["userCreditUsage_order_by"]: {
		GeneratedPromptByTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		freeCreditUsed?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		originalValue?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userCreditUsage */
	["userCreditUsage_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "userCreditUsage" */
	["userCreditUsage_select_column"]: userCreditUsage_select_column;
	/** input type for updating data in table "userCreditUsage" */
	["userCreditUsage_set_input"]: {
		GeneratedPromptByTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		freeCreditUsed?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		originalValue?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["userCreditUsage_stddev_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["userCreditUsage_stddev_pop_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["userCreditUsage_stddev_samp_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "userCreditUsage" */
	["userCreditUsage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userCreditUsage_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userCreditUsage_stream_cursor_value_input"]: {
		GeneratedPromptByTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		freeCreditUsed?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		originalValue?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		totalCharged?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["userCreditUsage_sum_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "userCreditUsage" */
	["userCreditUsage_update_column"]: userCreditUsage_update_column;
	["userCreditUsage_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["userCreditUsage_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userCreditUsage_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userCreditUsage_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["userCreditUsage_var_pop_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["userCreditUsage_var_samp_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["userCreditUsage_variance_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userCredits" */
	["userCredits"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		quantity?: boolean | `@${string}`,
		reason?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userCredits" */
	["userCredits_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userCredits_aggregate_fields"],
		nodes?: ValueTypes["userCredits"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userCredits" */
	["userCredits_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["userCredits_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["userCredits_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userCredits_max_fields"],
		min?: ValueTypes["userCredits_min_fields"],
		stddev?: ValueTypes["userCredits_stddev_fields"],
		stddev_pop?: ValueTypes["userCredits_stddev_pop_fields"],
		stddev_samp?: ValueTypes["userCredits_stddev_samp_fields"],
		sum?: ValueTypes["userCredits_sum_fields"],
		var_pop?: ValueTypes["userCredits_var_pop_fields"],
		var_samp?: ValueTypes["userCredits_var_samp_fields"],
		variance?: ValueTypes["userCredits_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["userCredits_avg_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userCredits". All fields are combined with a logical 'AND'. */
	["userCredits_bool_exp"]: {
		_and?: Array<ValueTypes["userCredits_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userCredits_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userCredits_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		quantity?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		reason?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userCredits" */
	["userCredits_constraint"]: userCredits_constraint;
	/** input type for incrementing numeric columns in table "userCredits" */
	["userCredits_inc_input"]: {
		quantity?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "userCredits" */
	["userCredits_insert_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		quantity?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		reason?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userCredits_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		quantity?: boolean | `@${string}`,
		reason?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userCredits_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		quantity?: boolean | `@${string}`,
		reason?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userCredits" */
	["userCredits_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userCredits"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userCredits" */
	["userCredits_on_conflict"]: {
		constraint: ValueTypes["userCredits_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userCredits_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userCredits_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userCredits". */
	["userCredits_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		quantity?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		reason?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userCredits */
	["userCredits_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "userCredits" */
	["userCredits_select_column"]: userCredits_select_column;
	/** input type for updating data in table "userCredits" */
	["userCredits_set_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		quantity?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		reason?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["userCredits_stddev_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["userCredits_stddev_pop_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["userCredits_stddev_samp_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "userCredits" */
	["userCredits_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userCredits_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userCredits_stream_cursor_value_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		quantity?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		reason?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["userCredits_sum_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "userCredits" */
	["userCredits_update_column"]: userCredits_update_column;
	["userCredits_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["userCredits_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userCredits_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userCredits_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["userCredits_var_pop_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["userCredits_var_samp_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["userCredits_variance_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userFeedback" */
	["userFeedback"]: AliasType<{
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		feedbackType?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		message?: boolean | `@${string}`,
		replied_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userFeedback" */
	["userFeedback_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userFeedback_aggregate_fields"],
		nodes?: ValueTypes["userFeedback"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userFeedback" */
	["userFeedback_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["userFeedback_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userFeedback_max_fields"],
		min?: ValueTypes["userFeedback_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userFeedback". All fields are combined with a logical 'AND'. */
	["userFeedback_bool_exp"]: {
		_and?: Array<ValueTypes["userFeedback_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userFeedback_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userFeedback_bool_exp"]> | undefined | null | Variable<any, string>,
		createdBy?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		feedbackType?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		message?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		replied_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userFeedback" */
	["userFeedback_constraint"]: userFeedback_constraint;
	/** input type for inserting data into table "userFeedback" */
	["userFeedback_insert_input"]: {
		createdBy?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		feedbackType?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		message?: string | undefined | null | Variable<any, string>,
		replied_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userFeedback_max_fields"]: AliasType<{
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		feedbackType?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		message?: boolean | `@${string}`,
		replied_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userFeedback_min_fields"]: AliasType<{
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		feedbackType?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		message?: boolean | `@${string}`,
		replied_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userFeedback" */
	["userFeedback_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userFeedback"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userFeedback" */
	["userFeedback_on_conflict"]: {
		constraint: ValueTypes["userFeedback_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userFeedback_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userFeedback_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userFeedback". */
	["userFeedback_order_by"]: {
		createdBy?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		feedbackType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		message?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		replied_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userFeedback */
	["userFeedback_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "userFeedback" */
	["userFeedback_select_column"]: userFeedback_select_column;
	/** input type for updating data in table "userFeedback" */
	["userFeedback_set_input"]: {
		createdBy?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		feedbackType?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		message?: string | undefined | null | Variable<any, string>,
		replied_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "userFeedback" */
	["userFeedback_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userFeedback_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userFeedback_stream_cursor_value_input"]: {
		createdBy?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		feedbackType?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		message?: string | undefined | null | Variable<any, string>,
		replied_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "userFeedback" */
	["userFeedback_update_column"]: userFeedback_update_column;
	["userFeedback_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userFeedback_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userFeedback_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "userMedia" */
	["userMedia"]: AliasType<{
		aiParameters?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		created_at?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mediaType?: boolean | `@${string}`,
		ownerId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		videoFileId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userMedia" */
	["userMedia_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userMedia_aggregate_fields"],
		nodes?: ValueTypes["userMedia"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userMedia" */
	["userMedia_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["userMedia_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userMedia_max_fields"],
		min?: ValueTypes["userMedia_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["userMedia_append_input"]: {
		aiParameters?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "userMedia". All fields are combined with a logical 'AND'. */
	["userMedia_bool_exp"]: {
		_and?: Array<ValueTypes["userMedia_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userMedia_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userMedia_bool_exp"]> | undefined | null | Variable<any, string>,
		aiParameters?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		mediaType?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		ownerId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		videoFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userMedia" */
	["userMedia_constraint"]: userMedia_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["userMedia_delete_at_path_input"]: {
		aiParameters?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["userMedia_delete_elem_input"]: {
		aiParameters?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["userMedia_delete_key_input"]: {
		aiParameters?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "userMedia" */
	["userMedia_insert_input"]: {
		aiParameters?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		mediaType?: string | undefined | null | Variable<any, string>,
		ownerId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		videoFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userMedia_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mediaType?: boolean | `@${string}`,
		ownerId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		videoFileId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userMedia_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mediaType?: boolean | `@${string}`,
		ownerId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		videoFileId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userMedia" */
	["userMedia_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userMedia"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userMedia" */
	["userMedia_on_conflict"]: {
		constraint: ValueTypes["userMedia_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userMedia_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userMedia_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userMedia". */
	["userMedia_order_by"]: {
		aiParameters?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		deleted?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		mediaType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		ownerId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		videoFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userMedia */
	["userMedia_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["userMedia_prepend_input"]: {
		aiParameters?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "userMedia" */
	["userMedia_select_column"]: userMedia_select_column;
	/** input type for updating data in table "userMedia" */
	["userMedia_set_input"]: {
		aiParameters?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		mediaType?: string | undefined | null | Variable<any, string>,
		ownerId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		videoFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "userMedia" */
	["userMedia_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userMedia_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userMedia_stream_cursor_value_input"]: {
		aiParameters?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		deleted?: boolean | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		mediaType?: string | undefined | null | Variable<any, string>,
		ownerId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		videoFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "userMedia" */
	["userMedia_update_column"]: userMedia_update_column;
	["userMedia_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["userMedia_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["userMedia_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["userMedia_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["userMedia_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["userMedia_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userMedia_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userMedia_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "userMetadata" */
	["userMetadata"]: AliasType<{
		created_at?: boolean | `@${string}`,
		creditBalance?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastCreditRefill?: boolean | `@${string}`,
		last_seen?: boolean | `@${string}`,
		onboardCompleted?: boolean | `@${string}`,
		/** An object relationship */
		pricingPlan?: ValueTypes["pricingPlan"],
		stripeCustomerId?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		stripeSubscriptionId?: boolean | `@${string}`,
		stripeSubscriptionItem?: boolean | `@${string}`,
		subscriptionType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		/** An object relationship */
		user?: ValueTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userMetadata" */
	["userMetadata_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userMetadata_aggregate_fields"],
		nodes?: ValueTypes["userMetadata"],
		__typename?: boolean | `@${string}`
	}>;
	["userMetadata_aggregate_bool_exp"]: {
		bool_and?: ValueTypes["userMetadata_aggregate_bool_exp_bool_and"] | undefined | null | Variable<any, string>,
		bool_or?: ValueTypes["userMetadata_aggregate_bool_exp_bool_or"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["userMetadata_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["userMetadata_aggregate_bool_exp_bool_and"]: {
		arguments: ValueTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["userMetadata_aggregate_bool_exp_bool_or"]: {
		arguments: ValueTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["userMetadata_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["userMetadata_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "userMetadata" */
	["userMetadata_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["userMetadata_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["userMetadata_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userMetadata_max_fields"],
		min?: ValueTypes["userMetadata_min_fields"],
		stddev?: ValueTypes["userMetadata_stddev_fields"],
		stddev_pop?: ValueTypes["userMetadata_stddev_pop_fields"],
		stddev_samp?: ValueTypes["userMetadata_stddev_samp_fields"],
		sum?: ValueTypes["userMetadata_sum_fields"],
		var_pop?: ValueTypes["userMetadata_var_pop_fields"],
		var_samp?: ValueTypes["userMetadata_var_samp_fields"],
		variance?: ValueTypes["userMetadata_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "userMetadata" */
	["userMetadata_aggregate_order_by"]: {
		avg?: ValueTypes["userMetadata_avg_order_by"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["userMetadata_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["userMetadata_min_order_by"] | undefined | null | Variable<any, string>,
		stddev?: ValueTypes["userMetadata_stddev_order_by"] | undefined | null | Variable<any, string>,
		stddev_pop?: ValueTypes["userMetadata_stddev_pop_order_by"] | undefined | null | Variable<any, string>,
		stddev_samp?: ValueTypes["userMetadata_stddev_samp_order_by"] | undefined | null | Variable<any, string>,
		sum?: ValueTypes["userMetadata_sum_order_by"] | undefined | null | Variable<any, string>,
		var_pop?: ValueTypes["userMetadata_var_pop_order_by"] | undefined | null | Variable<any, string>,
		var_samp?: ValueTypes["userMetadata_var_samp_order_by"] | undefined | null | Variable<any, string>,
		variance?: ValueTypes["userMetadata_variance_order_by"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "userMetadata" */
	["userMetadata_arr_rel_insert_input"]: {
		data: Array<ValueTypes["userMetadata_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["userMetadata_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** aggregate avg on columns */
	["userMetadata_avg_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "userMetadata" */
	["userMetadata_avg_order_by"]: {
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "userMetadata". All fields are combined with a logical 'AND'. */
	["userMetadata_bool_exp"]: {
		_and?: Array<ValueTypes["userMetadata_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userMetadata_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		creditBalance?: ValueTypes["numeric_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		lastCreditRefill?: ValueTypes["date_comparison_exp"] | undefined | null | Variable<any, string>,
		last_seen?: ValueTypes["timestamp_comparison_exp"] | undefined | null | Variable<any, string>,
		onboardCompleted?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		pricingPlan?: ValueTypes["pricingPlan_bool_exp"] | undefined | null | Variable<any, string>,
		stripeCustomerId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		stripePriceId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		stripeProductId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		stripeSubscriptionId?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		stripeSubscriptionItem?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		subscriptionType?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userMetadata" */
	["userMetadata_constraint"]: userMetadata_constraint;
	/** input type for incrementing numeric columns in table "userMetadata" */
	["userMetadata_inc_input"]: {
		creditBalance?: ValueTypes["numeric"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "userMetadata" */
	["userMetadata_insert_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditBalance?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		lastCreditRefill?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		last_seen?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		onboardCompleted?: boolean | undefined | null | Variable<any, string>,
		pricingPlan?: ValueTypes["pricingPlan_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		stripeCustomerId?: string | undefined | null | Variable<any, string>,
		stripePriceId?: string | undefined | null | Variable<any, string>,
		stripeProductId?: string | undefined | null | Variable<any, string>,
		stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
		stripeSubscriptionItem?: string | undefined | null | Variable<any, string>,
		subscriptionType?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_obj_rel_insert_input"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userMetadata_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		creditBalance?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastCreditRefill?: boolean | `@${string}`,
		last_seen?: boolean | `@${string}`,
		stripeCustomerId?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		stripeSubscriptionId?: boolean | `@${string}`,
		stripeSubscriptionItem?: boolean | `@${string}`,
		subscriptionType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "userMetadata" */
	["userMetadata_max_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		lastCreditRefill?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		last_seen?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeCustomerId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripePriceId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeProductId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeSubscriptionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeSubscriptionItem?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		subscriptionType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["userMetadata_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		creditBalance?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastCreditRefill?: boolean | `@${string}`,
		last_seen?: boolean | `@${string}`,
		stripeCustomerId?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		stripeSubscriptionId?: boolean | `@${string}`,
		stripeSubscriptionItem?: boolean | `@${string}`,
		subscriptionType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "userMetadata" */
	["userMetadata_min_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		lastCreditRefill?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		last_seen?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeCustomerId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripePriceId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeProductId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeSubscriptionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeSubscriptionItem?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		subscriptionType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "userMetadata" */
	["userMetadata_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userMetadata"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userMetadata" */
	["userMetadata_on_conflict"]: {
		constraint: ValueTypes["userMetadata_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userMetadata_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userMetadata". */
	["userMetadata_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		lastCreditRefill?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		last_seen?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		onboardCompleted?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		pricingPlan?: ValueTypes["pricingPlan_order_by"] | undefined | null | Variable<any, string>,
		stripeCustomerId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripePriceId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeProductId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeSubscriptionId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		stripeSubscriptionItem?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		subscriptionType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		user?: ValueTypes["users_order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userMetadata */
	["userMetadata_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "userMetadata" */
	["userMetadata_select_column"]: userMetadata_select_column;
	/** select "userMetadata_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userMetadata" */
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"]: userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "userMetadata_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userMetadata" */
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"]: userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "userMetadata" */
	["userMetadata_set_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditBalance?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		lastCreditRefill?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		last_seen?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		onboardCompleted?: boolean | undefined | null | Variable<any, string>,
		stripeCustomerId?: string | undefined | null | Variable<any, string>,
		stripePriceId?: string | undefined | null | Variable<any, string>,
		stripeProductId?: string | undefined | null | Variable<any, string>,
		stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
		stripeSubscriptionItem?: string | undefined | null | Variable<any, string>,
		subscriptionType?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["userMetadata_stddev_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "userMetadata" */
	["userMetadata_stddev_order_by"]: {
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_pop on columns */
	["userMetadata_stddev_pop_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "userMetadata" */
	["userMetadata_stddev_pop_order_by"]: {
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev_samp on columns */
	["userMetadata_stddev_samp_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "userMetadata" */
	["userMetadata_stddev_samp_order_by"]: {
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "userMetadata" */
	["userMetadata_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userMetadata_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userMetadata_stream_cursor_value_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		creditBalance?: ValueTypes["numeric"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		lastCreditRefill?: ValueTypes["date"] | undefined | null | Variable<any, string>,
		last_seen?: ValueTypes["timestamp"] | undefined | null | Variable<any, string>,
		onboardCompleted?: boolean | undefined | null | Variable<any, string>,
		stripeCustomerId?: string | undefined | null | Variable<any, string>,
		stripePriceId?: string | undefined | null | Variable<any, string>,
		stripeProductId?: string | undefined | null | Variable<any, string>,
		stripeSubscriptionId?: string | undefined | null | Variable<any, string>,
		stripeSubscriptionItem?: string | undefined | null | Variable<any, string>,
		subscriptionType?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["userMetadata_sum_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "userMetadata" */
	["userMetadata_sum_order_by"]: {
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "userMetadata" */
	["userMetadata_update_column"]: userMetadata_update_column;
	["userMetadata_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["userMetadata_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userMetadata_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userMetadata_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["userMetadata_var_pop_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "userMetadata" */
	["userMetadata_var_pop_order_by"]: {
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate var_samp on columns */
	["userMetadata_var_samp_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "userMetadata" */
	["userMetadata_var_samp_order_by"]: {
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate variance on columns */
	["userMetadata_variance_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "userMetadata" */
	["userMetadata_variance_order_by"]: {
		creditBalance?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "userOnboarding" */
	["userOnboarding"]: AliasType<{
		behaviorProfile?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		clientPreference?: boolean | `@${string}`,
		companySegment?: boolean | `@${string}`,
		currentStep?: boolean | `@${string}`,
		done?: boolean | `@${string}`,
		expectation?: boolean | `@${string}`,
		importantSocialNetworkForBusiness?: boolean | `@${string}`,
		investInPaidChannels?: boolean | `@${string}`,
		marketingObjective?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		networkingOnCopyMagico?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		purchaseBehavior?: boolean | `@${string}`,
		purchaseChallenges?: boolean | `@${string}`,
		purchaseInfluencers?: boolean | `@${string}`,
		sellOnCopyMagico?: boolean | `@${string}`,
		siteUrl?: boolean | `@${string}`,
		socioeconomicProfile?: boolean | `@${string}`,
		staffCount?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userOnboarding" */
	["userOnboarding_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userOnboarding_aggregate_fields"],
		nodes?: ValueTypes["userOnboarding"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userOnboarding" */
	["userOnboarding_aggregate_fields"]: AliasType<{
		avg?: ValueTypes["userOnboarding_avg_fields"],
		count?: [{ columns?: Array<ValueTypes["userOnboarding_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userOnboarding_max_fields"],
		min?: ValueTypes["userOnboarding_min_fields"],
		stddev?: ValueTypes["userOnboarding_stddev_fields"],
		stddev_pop?: ValueTypes["userOnboarding_stddev_pop_fields"],
		stddev_samp?: ValueTypes["userOnboarding_stddev_samp_fields"],
		sum?: ValueTypes["userOnboarding_sum_fields"],
		var_pop?: ValueTypes["userOnboarding_var_pop_fields"],
		var_samp?: ValueTypes["userOnboarding_var_samp_fields"],
		variance?: ValueTypes["userOnboarding_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["userOnboarding_avg_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userOnboarding". All fields are combined with a logical 'AND'. */
	["userOnboarding_bool_exp"]: {
		_and?: Array<ValueTypes["userOnboarding_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userOnboarding_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userOnboarding_bool_exp"]> | undefined | null | Variable<any, string>,
		behaviorProfile?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		brandName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		clientPreference?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		companySegment?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		currentStep?: ValueTypes["Int_comparison_exp"] | undefined | null | Variable<any, string>,
		done?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		expectation?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		importantSocialNetworkForBusiness?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		investInPaidChannels?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		marketingObjective?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		networkingOnCopyMagico?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		phone?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		productOrService?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		purchaseBehavior?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		purchaseChallenges?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		purchaseInfluencers?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		sellOnCopyMagico?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		siteUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		socioeconomicProfile?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		staffCount?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userOnboarding" */
	["userOnboarding_constraint"]: userOnboarding_constraint;
	/** input type for incrementing numeric columns in table "userOnboarding" */
	["userOnboarding_inc_input"]: {
		currentStep?: number | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "userOnboarding" */
	["userOnboarding_insert_input"]: {
		behaviorProfile?: string | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		clientPreference?: string | undefined | null | Variable<any, string>,
		companySegment?: string | undefined | null | Variable<any, string>,
		currentStep?: number | undefined | null | Variable<any, string>,
		done?: boolean | undefined | null | Variable<any, string>,
		expectation?: string | undefined | null | Variable<any, string>,
		importantSocialNetworkForBusiness?: string | undefined | null | Variable<any, string>,
		investInPaidChannels?: string | undefined | null | Variable<any, string>,
		marketingObjective?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		networkingOnCopyMagico?: string | undefined | null | Variable<any, string>,
		phone?: string | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		purchaseBehavior?: string | undefined | null | Variable<any, string>,
		purchaseChallenges?: string | undefined | null | Variable<any, string>,
		purchaseInfluencers?: string | undefined | null | Variable<any, string>,
		sellOnCopyMagico?: string | undefined | null | Variable<any, string>,
		siteUrl?: string | undefined | null | Variable<any, string>,
		socioeconomicProfile?: string | undefined | null | Variable<any, string>,
		staffCount?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userOnboarding_max_fields"]: AliasType<{
		behaviorProfile?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		clientPreference?: boolean | `@${string}`,
		companySegment?: boolean | `@${string}`,
		currentStep?: boolean | `@${string}`,
		expectation?: boolean | `@${string}`,
		importantSocialNetworkForBusiness?: boolean | `@${string}`,
		investInPaidChannels?: boolean | `@${string}`,
		marketingObjective?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		networkingOnCopyMagico?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		purchaseBehavior?: boolean | `@${string}`,
		purchaseChallenges?: boolean | `@${string}`,
		purchaseInfluencers?: boolean | `@${string}`,
		sellOnCopyMagico?: boolean | `@${string}`,
		siteUrl?: boolean | `@${string}`,
		socioeconomicProfile?: boolean | `@${string}`,
		staffCount?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userOnboarding_min_fields"]: AliasType<{
		behaviorProfile?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		clientPreference?: boolean | `@${string}`,
		companySegment?: boolean | `@${string}`,
		currentStep?: boolean | `@${string}`,
		expectation?: boolean | `@${string}`,
		importantSocialNetworkForBusiness?: boolean | `@${string}`,
		investInPaidChannels?: boolean | `@${string}`,
		marketingObjective?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		networkingOnCopyMagico?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		purchaseBehavior?: boolean | `@${string}`,
		purchaseChallenges?: boolean | `@${string}`,
		purchaseInfluencers?: boolean | `@${string}`,
		sellOnCopyMagico?: boolean | `@${string}`,
		siteUrl?: boolean | `@${string}`,
		socioeconomicProfile?: boolean | `@${string}`,
		staffCount?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userOnboarding" */
	["userOnboarding_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userOnboarding"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userOnboarding" */
	["userOnboarding_on_conflict"]: {
		constraint: ValueTypes["userOnboarding_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userOnboarding_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userOnboarding_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userOnboarding". */
	["userOnboarding_order_by"]: {
		behaviorProfile?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brandName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		clientPreference?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		companySegment?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		currentStep?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		done?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		expectation?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		importantSocialNetworkForBusiness?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		investInPaidChannels?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		marketingObjective?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		name?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		networkingOnCopyMagico?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phone?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		productOrService?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		purchaseBehavior?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		purchaseChallenges?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		purchaseInfluencers?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		sellOnCopyMagico?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		siteUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		socioeconomicProfile?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		staffCount?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userOnboarding */
	["userOnboarding_pk_columns_input"]: {
		userId: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "userOnboarding" */
	["userOnboarding_select_column"]: userOnboarding_select_column;
	/** input type for updating data in table "userOnboarding" */
	["userOnboarding_set_input"]: {
		behaviorProfile?: string | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		clientPreference?: string | undefined | null | Variable<any, string>,
		companySegment?: string | undefined | null | Variable<any, string>,
		currentStep?: number | undefined | null | Variable<any, string>,
		done?: boolean | undefined | null | Variable<any, string>,
		expectation?: string | undefined | null | Variable<any, string>,
		importantSocialNetworkForBusiness?: string | undefined | null | Variable<any, string>,
		investInPaidChannels?: string | undefined | null | Variable<any, string>,
		marketingObjective?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		networkingOnCopyMagico?: string | undefined | null | Variable<any, string>,
		phone?: string | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		purchaseBehavior?: string | undefined | null | Variable<any, string>,
		purchaseChallenges?: string | undefined | null | Variable<any, string>,
		purchaseInfluencers?: string | undefined | null | Variable<any, string>,
		sellOnCopyMagico?: string | undefined | null | Variable<any, string>,
		siteUrl?: string | undefined | null | Variable<any, string>,
		socioeconomicProfile?: string | undefined | null | Variable<any, string>,
		staffCount?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate stddev on columns */
	["userOnboarding_stddev_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["userOnboarding_stddev_pop_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["userOnboarding_stddev_samp_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "userOnboarding" */
	["userOnboarding_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userOnboarding_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userOnboarding_stream_cursor_value_input"]: {
		behaviorProfile?: string | undefined | null | Variable<any, string>,
		brandName?: string | undefined | null | Variable<any, string>,
		clientPreference?: string | undefined | null | Variable<any, string>,
		companySegment?: string | undefined | null | Variable<any, string>,
		currentStep?: number | undefined | null | Variable<any, string>,
		done?: boolean | undefined | null | Variable<any, string>,
		expectation?: string | undefined | null | Variable<any, string>,
		importantSocialNetworkForBusiness?: string | undefined | null | Variable<any, string>,
		investInPaidChannels?: string | undefined | null | Variable<any, string>,
		marketingObjective?: string | undefined | null | Variable<any, string>,
		name?: string | undefined | null | Variable<any, string>,
		networkingOnCopyMagico?: string | undefined | null | Variable<any, string>,
		phone?: string | undefined | null | Variable<any, string>,
		productOrService?: string | undefined | null | Variable<any, string>,
		purchaseBehavior?: string | undefined | null | Variable<any, string>,
		purchaseChallenges?: string | undefined | null | Variable<any, string>,
		purchaseInfluencers?: string | undefined | null | Variable<any, string>,
		sellOnCopyMagico?: string | undefined | null | Variable<any, string>,
		siteUrl?: string | undefined | null | Variable<any, string>,
		socioeconomicProfile?: string | undefined | null | Variable<any, string>,
		staffCount?: string | undefined | null | Variable<any, string>,
		userId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate sum on columns */
	["userOnboarding_sum_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "userOnboarding" */
	["userOnboarding_update_column"]: userOnboarding_update_column;
	["userOnboarding_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ValueTypes["userOnboarding_inc_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userOnboarding_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userOnboarding_bool_exp"] | Variable<any, string>
	};
	/** aggregate var_pop on columns */
	["userOnboarding_var_pop_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["userOnboarding_var_samp_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["userOnboarding_variance_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userTemplateUsage" */
	["userTemplateUsage"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userTemplateUsage" */
	["userTemplateUsage_aggregate"]: AliasType<{
		aggregate?: ValueTypes["userTemplateUsage_aggregate_fields"],
		nodes?: ValueTypes["userTemplateUsage"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userTemplateUsage" */
	["userTemplateUsage_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["userTemplateUsage_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["userTemplateUsage_max_fields"],
		min?: ValueTypes["userTemplateUsage_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userTemplateUsage". All fields are combined with a logical 'AND'. */
	["userTemplateUsage_bool_exp"]: {
		_and?: Array<ValueTypes["userTemplateUsage_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["userTemplateUsage_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["userTemplateUsage_bool_exp"]> | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "userTemplateUsage" */
	["userTemplateUsage_constraint"]: userTemplateUsage_constraint;
	/** input type for inserting data into table "userTemplateUsage" */
	["userTemplateUsage_insert_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["userTemplateUsage_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userTemplateUsage_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userTemplateUsage" */
	["userTemplateUsage_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["userTemplateUsage"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userTemplateUsage" */
	["userTemplateUsage_on_conflict"]: {
		constraint: ValueTypes["userTemplateUsage_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["userTemplateUsage_update_column"]> | Variable<any, string>,
		where?: ValueTypes["userTemplateUsage_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "userTemplateUsage". */
	["userTemplateUsage_order_by"]: {
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: userTemplateUsage */
	["userTemplateUsage_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "userTemplateUsage" */
	["userTemplateUsage_select_column"]: userTemplateUsage_select_column;
	/** input type for updating data in table "userTemplateUsage" */
	["userTemplateUsage_set_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "userTemplateUsage" */
	["userTemplateUsage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["userTemplateUsage_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["userTemplateUsage_stream_cursor_value_input"]: {
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		created_by?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		textPromptTemplateId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "userTemplateUsage" */
	["userTemplateUsage_update_column"]: userTemplateUsage_update_column;
	["userTemplateUsage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["userTemplateUsage_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["userTemplateUsage_bool_exp"] | Variable<any, string>
	};
	/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["users"]: AliasType<{
		activeMfaType?: boolean | `@${string}`,
		avatarUrl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		currentChallenge?: boolean | `@${string}`,
		defaultRole?: boolean | `@${string}`,
		/** An object relationship */
		defaultRoleByRole?: ValueTypes["authRoles"],
		disabled?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		emailVerified?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		isAnonymous?: boolean | `@${string}`,
		lastSeen?: boolean | `@${string}`,
		locale?: boolean | `@${string}`,
		metadata?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		newEmail?: boolean | `@${string}`,
		otpHash?: boolean | `@${string}`,
		otpHashExpiresAt?: boolean | `@${string}`,
		otpMethodLastUsed?: boolean | `@${string}`,
		passwordHash?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		phoneNumberVerified?: boolean | `@${string}`,
		refreshTokens?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokens_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens"]],
		refreshTokens_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authRefreshTokens_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authRefreshTokens_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authRefreshTokens_aggregate"]],
		roles?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles"]],
		roles_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserRoles_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserRoles_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserRoles_aggregate"]],
		securityKeys?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserSecurityKeys_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserSecurityKeys_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys"]],
		securityKeys_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserSecurityKeys_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserSecurityKeys_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserSecurityKeys_aggregate"]],
		ticket?: boolean | `@${string}`,
		ticketExpiresAt?: boolean | `@${string}`,
		totpSecret?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		userMetadata?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMetadata_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMetadata_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata"]],
		userMetadata_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["userMetadata_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["userMetadata_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["userMetadata_aggregate"]],
		userProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders"]],
		userProviders_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ValueTypes["authUserProviders_select_column"]> | undefined | null | Variable<any, string>,	/** limit the number of rows returned */
			limit?: number | undefined | null | Variable<any, string>,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null | Variable<any, string>,	/** sort the rows by one or more columns */
			order_by?: Array<ValueTypes["authUserProviders_order_by"]> | undefined | null | Variable<any, string>,	/** filter the rows returned */
			where?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>
		}, ValueTypes["authUserProviders_aggregate"]],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.users" */
	["users_aggregate"]: AliasType<{
		aggregate?: ValueTypes["users_aggregate_fields"],
		nodes?: ValueTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	["users_aggregate_bool_exp"]: {
		bool_and?: ValueTypes["users_aggregate_bool_exp_bool_and"] | undefined | null | Variable<any, string>,
		bool_or?: ValueTypes["users_aggregate_bool_exp_bool_or"] | undefined | null | Variable<any, string>,
		count?: ValueTypes["users_aggregate_bool_exp_count"] | undefined | null | Variable<any, string>
	};
	["users_aggregate_bool_exp_bool_and"]: {
		arguments: ValueTypes["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["users_aggregate_bool_exp_bool_or"]: {
		arguments: ValueTypes["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"] | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Boolean_comparison_exp"] | Variable<any, string>
	};
	["users_aggregate_bool_exp_count"]: {
		arguments?: Array<ValueTypes["users_select_column"]> | undefined | null | Variable<any, string>,
		distinct?: boolean | undefined | null | Variable<any, string>,
		filter?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		predicate: ValueTypes["Int_comparison_exp"] | Variable<any, string>
	};
	/** aggregate fields of "auth.users" */
	["users_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["users_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["users_max_fields"],
		min?: ValueTypes["users_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.users" */
	["users_aggregate_order_by"]: {
		count?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		max?: ValueTypes["users_max_order_by"] | undefined | null | Variable<any, string>,
		min?: ValueTypes["users_min_order_by"] | undefined | null | Variable<any, string>
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["users_append_input"]: {
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** input type for inserting array relation for remote table "auth.users" */
	["users_arr_rel_insert_input"]: {
		data: Array<ValueTypes["users_insert_input"]> | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["users_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
	["users_bool_exp"]: {
		_and?: Array<ValueTypes["users_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["users_bool_exp"]> | undefined | null | Variable<any, string>,
		activeMfaType?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		avatarUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		currentChallenge?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		defaultRole?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		defaultRoleByRole?: ValueTypes["authRoles_bool_exp"] | undefined | null | Variable<any, string>,
		disabled?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["citext_comparison_exp"] | undefined | null | Variable<any, string>,
		emailVerified?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		isAnonymous?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		lastSeen?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		locale?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		newEmail?: ValueTypes["citext_comparison_exp"] | undefined | null | Variable<any, string>,
		otpHash?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		otpHashExpiresAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		otpMethodLastUsed?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		passwordHash?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		phoneNumber?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		phoneNumberVerified?: ValueTypes["Boolean_comparison_exp"] | undefined | null | Variable<any, string>,
		refreshTokens?: ValueTypes["authRefreshTokens_bool_exp"] | undefined | null | Variable<any, string>,
		refreshTokens_aggregate?: ValueTypes["authRefreshTokens_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		roles?: ValueTypes["authUserRoles_bool_exp"] | undefined | null | Variable<any, string>,
		roles_aggregate?: ValueTypes["authUserRoles_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		securityKeys?: ValueTypes["authUserSecurityKeys_bool_exp"] | undefined | null | Variable<any, string>,
		securityKeys_aggregate?: ValueTypes["authUserSecurityKeys_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		ticket?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		ticketExpiresAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		totpSecret?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		userMetadata?: ValueTypes["userMetadata_bool_exp"] | undefined | null | Variable<any, string>,
		userMetadata_aggregate?: ValueTypes["userMetadata_aggregate_bool_exp"] | undefined | null | Variable<any, string>,
		userProviders?: ValueTypes["authUserProviders_bool_exp"] | undefined | null | Variable<any, string>,
		userProviders_aggregate?: ValueTypes["authUserProviders_aggregate_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "auth.users" */
	["users_constraint"]: users_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["users_delete_at_path_input"]: {
		metadata?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["users_delete_elem_input"]: {
		metadata?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["users_delete_key_input"]: {
		metadata?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "auth.users" */
	["users_insert_input"]: {
		activeMfaType?: string | undefined | null | Variable<any, string>,
		avatarUrl?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		currentChallenge?: string | undefined | null | Variable<any, string>,
		defaultRole?: string | undefined | null | Variable<any, string>,
		defaultRoleByRole?: ValueTypes["authRoles_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		disabled?: boolean | undefined | null | Variable<any, string>,
		displayName?: string | undefined | null | Variable<any, string>,
		email?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		emailVerified?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		isAnonymous?: boolean | undefined | null | Variable<any, string>,
		lastSeen?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		locale?: string | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		newEmail?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		otpHash?: string | undefined | null | Variable<any, string>,
		otpHashExpiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		otpMethodLastUsed?: string | undefined | null | Variable<any, string>,
		passwordHash?: string | undefined | null | Variable<any, string>,
		phoneNumber?: string | undefined | null | Variable<any, string>,
		phoneNumberVerified?: boolean | undefined | null | Variable<any, string>,
		refreshTokens?: ValueTypes["authRefreshTokens_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		roles?: ValueTypes["authUserRoles_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		securityKeys?: ValueTypes["authUserSecurityKeys_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		ticket?: string | undefined | null | Variable<any, string>,
		ticketExpiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		totpSecret?: string | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		userMetadata?: ValueTypes["userMetadata_arr_rel_insert_input"] | undefined | null | Variable<any, string>,
		userProviders?: ValueTypes["authUserProviders_arr_rel_insert_input"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["users_max_fields"]: AliasType<{
		activeMfaType?: boolean | `@${string}`,
		avatarUrl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		currentChallenge?: boolean | `@${string}`,
		defaultRole?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastSeen?: boolean | `@${string}`,
		locale?: boolean | `@${string}`,
		newEmail?: boolean | `@${string}`,
		otpHash?: boolean | `@${string}`,
		otpHashExpiresAt?: boolean | `@${string}`,
		otpMethodLastUsed?: boolean | `@${string}`,
		passwordHash?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		ticket?: boolean | `@${string}`,
		ticketExpiresAt?: boolean | `@${string}`,
		totpSecret?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.users" */
	["users_max_order_by"]: {
		activeMfaType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		avatarUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		currentChallenge?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		defaultRole?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		lastSeen?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		locale?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		newEmail?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpHashExpiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpMethodLastUsed?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		passwordHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phoneNumber?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		ticket?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		ticketExpiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		totpSecret?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** aggregate min on columns */
	["users_min_fields"]: AliasType<{
		activeMfaType?: boolean | `@${string}`,
		avatarUrl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		currentChallenge?: boolean | `@${string}`,
		defaultRole?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastSeen?: boolean | `@${string}`,
		locale?: boolean | `@${string}`,
		newEmail?: boolean | `@${string}`,
		otpHash?: boolean | `@${string}`,
		otpHashExpiresAt?: boolean | `@${string}`,
		otpMethodLastUsed?: boolean | `@${string}`,
		passwordHash?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		ticket?: boolean | `@${string}`,
		ticketExpiresAt?: boolean | `@${string}`,
		totpSecret?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.users" */
	["users_min_order_by"]: {
		activeMfaType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		avatarUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		currentChallenge?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		defaultRole?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		lastSeen?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		locale?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		newEmail?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpHashExpiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpMethodLastUsed?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		passwordHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phoneNumber?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		ticket?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		ticketExpiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		totpSecret?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** response of any mutation on the table "auth.users" */
	["users_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "auth.users" */
	["users_obj_rel_insert_input"]: {
		data: ValueTypes["users_insert_input"] | Variable<any, string>,
		/** upsert condition */
		on_conflict?: ValueTypes["users_on_conflict"] | undefined | null | Variable<any, string>
	};
	/** on_conflict condition type for table "auth.users" */
	["users_on_conflict"]: {
		constraint: ValueTypes["users_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["users_update_column"]> | Variable<any, string>,
		where?: ValueTypes["users_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "auth.users". */
	["users_order_by"]: {
		activeMfaType?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		avatarUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		currentChallenge?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		defaultRole?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		defaultRoleByRole?: ValueTypes["authRoles_order_by"] | undefined | null | Variable<any, string>,
		disabled?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		displayName?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		email?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		emailVerified?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		isAnonymous?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		lastSeen?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		locale?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		newEmail?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpHashExpiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		otpMethodLastUsed?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		passwordHash?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phoneNumber?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		phoneNumberVerified?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		refreshTokens_aggregate?: ValueTypes["authRefreshTokens_aggregate_order_by"] | undefined | null | Variable<any, string>,
		roles_aggregate?: ValueTypes["authUserRoles_aggregate_order_by"] | undefined | null | Variable<any, string>,
		securityKeys_aggregate?: ValueTypes["authUserSecurityKeys_aggregate_order_by"] | undefined | null | Variable<any, string>,
		ticket?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		ticketExpiresAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		totpSecret?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userMetadata_aggregate?: ValueTypes["userMetadata_aggregate_order_by"] | undefined | null | Variable<any, string>,
		userProviders_aggregate?: ValueTypes["authUserProviders_aggregate_order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: auth.users */
	["users_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["users_prepend_input"]: {
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "auth.users" */
	["users_select_column"]: users_select_column;
	/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
	["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"]: users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
	["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"]: users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "auth.users" */
	["users_set_input"]: {
		activeMfaType?: string | undefined | null | Variable<any, string>,
		avatarUrl?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		currentChallenge?: string | undefined | null | Variable<any, string>,
		defaultRole?: string | undefined | null | Variable<any, string>,
		disabled?: boolean | undefined | null | Variable<any, string>,
		displayName?: string | undefined | null | Variable<any, string>,
		email?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		emailVerified?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		isAnonymous?: boolean | undefined | null | Variable<any, string>,
		lastSeen?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		locale?: string | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		newEmail?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		otpHash?: string | undefined | null | Variable<any, string>,
		otpHashExpiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		otpMethodLastUsed?: string | undefined | null | Variable<any, string>,
		passwordHash?: string | undefined | null | Variable<any, string>,
		phoneNumber?: string | undefined | null | Variable<any, string>,
		phoneNumberVerified?: boolean | undefined | null | Variable<any, string>,
		ticket?: string | undefined | null | Variable<any, string>,
		ticketExpiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		totpSecret?: string | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "users" */
	["users_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["users_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["users_stream_cursor_value_input"]: {
		activeMfaType?: string | undefined | null | Variable<any, string>,
		avatarUrl?: string | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		currentChallenge?: string | undefined | null | Variable<any, string>,
		defaultRole?: string | undefined | null | Variable<any, string>,
		disabled?: boolean | undefined | null | Variable<any, string>,
		displayName?: string | undefined | null | Variable<any, string>,
		email?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		emailVerified?: boolean | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		isAnonymous?: boolean | undefined | null | Variable<any, string>,
		lastSeen?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		locale?: string | undefined | null | Variable<any, string>,
		metadata?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		newEmail?: ValueTypes["citext"] | undefined | null | Variable<any, string>,
		otpHash?: string | undefined | null | Variable<any, string>,
		otpHashExpiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		otpMethodLastUsed?: string | undefined | null | Variable<any, string>,
		passwordHash?: string | undefined | null | Variable<any, string>,
		phoneNumber?: string | undefined | null | Variable<any, string>,
		phoneNumberVerified?: boolean | undefined | null | Variable<any, string>,
		ticket?: string | undefined | null | Variable<any, string>,
		ticketExpiresAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		totpSecret?: string | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "auth.users" */
	["users_update_column"]: users_update_column;
	["users_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["users_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["users_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["users_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["users_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["users_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["users_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["users_bool_exp"] | Variable<any, string>
	};
	["uuid"]: unknown;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
	["uuid_comparison_exp"]: {
		_eq?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		_gt?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		_gte?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		_in?: Array<ValueTypes["uuid"]> | undefined | null | Variable<any, string>,
		_is_null?: boolean | undefined | null | Variable<any, string>,
		_lt?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		_lte?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		_neq?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		_nin?: Array<ValueTypes["uuid"]> | undefined | null | Variable<any, string>
	};
	/** columns and relationships of "storage.virus" */
	["virus"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		/** An object relationship */
		file?: ValueTypes["files"],
		fileId?: boolean | `@${string}`,
		filename?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		userSession?: [{	/** JSON select path */
			path?: string | undefined | null | Variable<any, string>
		}, boolean | `@${string}`],
		virus?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "storage.virus" */
	["virus_aggregate"]: AliasType<{
		aggregate?: ValueTypes["virus_aggregate_fields"],
		nodes?: ValueTypes["virus"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "storage.virus" */
	["virus_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["virus_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["virus_max_fields"],
		min?: ValueTypes["virus_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["virus_append_input"]: {
		userSession?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** Boolean expression to filter rows from the table "storage.virus". All fields are combined with a logical 'AND'. */
	["virus_bool_exp"]: {
		_and?: Array<ValueTypes["virus_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["virus_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["virus_bool_exp"]> | undefined | null | Variable<any, string>,
		createdAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		file?: ValueTypes["files_bool_exp"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		filename?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		userSession?: ValueTypes["jsonb_comparison_exp"] | undefined | null | Variable<any, string>,
		virus?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "storage.virus" */
	["virus_constraint"]: virus_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["virus_delete_at_path_input"]: {
		userSession?: Array<string> | undefined | null | Variable<any, string>
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["virus_delete_elem_input"]: {
		userSession?: number | undefined | null | Variable<any, string>
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["virus_delete_key_input"]: {
		userSession?: string | undefined | null | Variable<any, string>
	};
	/** input type for inserting data into table "storage.virus" */
	["virus_insert_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		file?: ValueTypes["files_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		filename?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		userSession?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		virus?: string | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["virus_max_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		filename?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		virus?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["virus_min_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		filename?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		virus?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "storage.virus" */
	["virus_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["virus"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "storage.virus" */
	["virus_on_conflict"]: {
		constraint: ValueTypes["virus_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["virus_update_column"]> | Variable<any, string>,
		where?: ValueTypes["virus_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "storage.virus". */
	["virus_order_by"]: {
		createdAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		file?: ValueTypes["files_order_by"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		filename?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		userSession?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		virus?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: storage.virus */
	["virus_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["virus_prepend_input"]: {
		userSession?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>
	};
	/** select columns of table "storage.virus" */
	["virus_select_column"]: virus_select_column;
	/** input type for updating data in table "storage.virus" */
	["virus_set_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		filename?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		userSession?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		virus?: string | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "virus" */
	["virus_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["virus_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["virus_stream_cursor_value_input"]: {
		createdAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		fileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		filename?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		updatedAt?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		userSession?: ValueTypes["jsonb"] | undefined | null | Variable<any, string>,
		virus?: string | undefined | null | Variable<any, string>
	};
	/** update columns of table "storage.virus" */
	["virus_update_column"]: virus_update_column;
	["virus_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ValueTypes["virus_append_input"] | undefined | null | Variable<any, string>,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ValueTypes["virus_delete_at_path_input"] | undefined | null | Variable<any, string>,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ValueTypes["virus_delete_elem_input"] | undefined | null | Variable<any, string>,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ValueTypes["virus_delete_key_input"] | undefined | null | Variable<any, string>,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ValueTypes["virus_prepend_input"] | undefined | null | Variable<any, string>,
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["virus_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["virus_bool_exp"] | Variable<any, string>
	};
	/** columns and relationships of "website" */
	["website"]: AliasType<{
		aboutUsContactBannerImageFileId?: boolean | `@${string}`,
		aboutUsContactTitle?: boolean | `@${string}`,
		aboutUsHistoryBannerImageFileId?: boolean | `@${string}`,
		aboutUsHistoryDescription?: boolean | `@${string}`,
		aboutUsHistoryTitle?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleTwo?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleTwo?: boolean | `@${string}`,
		aboutUsStatementBannerImageFileId?: boolean | `@${string}`,
		aboutUsStatementBannerText?: boolean | `@${string}`,
		aboutUsWeAreReadyBannerImageId?: boolean | `@${string}`,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyInnovationDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySocialResponsibilityDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySpecializedSupportDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyTitle?: boolean | `@${string}`,
		aboutUsWeAreReadyTransparencyDescription?: boolean | `@${string}`,
		aboutUsWhatMoveBannerImageFileId?: boolean | `@${string}`,
		aboutUsWhatMoveUsMission?: boolean | `@${string}`,
		aboutUsWhatMoveUsPurpose?: boolean | `@${string}`,
		aboutUsWhatMoveUsTitle?: boolean | `@${string}`,
		aboutUsWhatMoveUsValues?: boolean | `@${string}`,
		aboutUsWhatMoveUsVision?: boolean | `@${string}`,
		accentColor?: boolean | `@${string}`,
		backgroundColor?: boolean | `@${string}`,
		/** An object relationship */
		brand?: ValueTypes["brands"],
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		domain?: boolean | `@${string}`,
		foregroundColor?: boolean | `@${string}`,
		googleAnalyticsKey?: boolean | `@${string}`,
		googleTagManagerKey?: boolean | `@${string}`,
		homeBrandHighlightBannerImageFileId?: boolean | `@${string}`,
		homeBrandHighlightDescription?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointValuePropositionList?: boolean | `@${string}`,
		homeBrandHighlightTitle?: boolean | `@${string}`,
		homeHeroBannerImageFileId?: boolean | `@${string}`,
		homeHeroCallToActionTargetUrl?: boolean | `@${string}`,
		homeHeroCallToActionText?: boolean | `@${string}`,
		homeHeroDescription?: boolean | `@${string}`,
		homeHeroLabel?: boolean | `@${string}`,
		homeHeroTitle?: boolean | `@${string}`,
		homeOurDifferentiatorsBannerImageFileId?: boolean | `@${string}`,
		homeOurDifferentiatorsDescription?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsTitle?: boolean | `@${string}`,
		homeWhatWeDoCallToActionTargetUrl?: boolean | `@${string}`,
		homeWhatWeDoCallToActionText?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoLegend?: boolean | `@${string}`,
		homeWhatWeDoDescription?: boolean | `@${string}`,
		homeWhatWeDoTitle?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		openGraphDescription?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "website" */
	["website_aggregate"]: AliasType<{
		aggregate?: ValueTypes["website_aggregate_fields"],
		nodes?: ValueTypes["website"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "website" */
	["website_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ValueTypes["website_select_column"]> | undefined | null | Variable<any, string>, distinct?: boolean | undefined | null | Variable<any, string> }, boolean | `@${string}`],
		max?: ValueTypes["website_max_fields"],
		min?: ValueTypes["website_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "website". All fields are combined with a logical 'AND'. */
	["website_bool_exp"]: {
		_and?: Array<ValueTypes["website_bool_exp"]> | undefined | null | Variable<any, string>,
		_not?: ValueTypes["website_bool_exp"] | undefined | null | Variable<any, string>,
		_or?: Array<ValueTypes["website_bool_exp"]> | undefined | null | Variable<any, string>,
		aboutUsContactBannerImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsContactTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsHistoryBannerImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsHistoryDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsHistoryTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleOne?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleTwo?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleOne?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleTwo?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsStatementBannerImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsStatementBannerText?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyBannerImageId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyInnovationDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySocialResponsibilityDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySpecializedSupportDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTransparencyDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveBannerImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsMission?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsPurpose?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsValues?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsVision?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		accentColor?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		backgroundColor?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		brand?: ValueTypes["brands_bool_exp"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>,
		domain?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		foregroundColor?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		googleAnalyticsKey?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		googleTagManagerKey?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeBrandHighlightBannerImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		homeBrandHighlightDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleOne?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleTwo?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleOne?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleTwo?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointValuePropositionList?: ValueTypes["String_array_comparison_exp"] | undefined | null | Variable<any, string>,
		homeBrandHighlightTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeHeroBannerImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		homeHeroCallToActionTargetUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeHeroCallToActionText?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeHeroDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeHeroLabel?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeHeroTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsBannerImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleOne?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleTwo?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleOne?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleTwo?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionTargetUrl?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionText?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneLegend?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeLegend?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoImageFileId?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoLegend?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		homeWhatWeDoTitle?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid_comparison_exp"] | undefined | null | Variable<any, string>,
		openGraphDescription?: ValueTypes["String_comparison_exp"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz_comparison_exp"] | undefined | null | Variable<any, string>
	};
	/** unique or primary key constraints on table "website" */
	["website_constraint"]: website_constraint;
	/** input type for inserting data into table "website" */
	["website_insert_input"]: {
		aboutUsContactBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsContactTitle?: string | undefined | null | Variable<any, string>,
		aboutUsHistoryBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsHistoryDescription?: string | undefined | null | Variable<any, string>,
		aboutUsHistoryTitle?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		aboutUsStatementBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsStatementBannerText?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyBannerImageId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyInnovationDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTitle?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsMission?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsPurpose?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsTitle?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsValues?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsVision?: string | undefined | null | Variable<any, string>,
		accentColor?: string | undefined | null | Variable<any, string>,
		backgroundColor?: string | undefined | null | Variable<any, string>,
		brand?: ValueTypes["brands_obj_rel_insert_input"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		domain?: string | undefined | null | Variable<any, string>,
		foregroundColor?: string | undefined | null | Variable<any, string>,
		googleAnalyticsKey?: string | undefined | null | Variable<any, string>,
		googleTagManagerKey?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeBrandHighlightDescription?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined | null | Variable<any, string>,
		homeBrandHighlightTitle?: string | undefined | null | Variable<any, string>,
		homeHeroBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeHeroCallToActionTargetUrl?: string | undefined | null | Variable<any, string>,
		homeHeroCallToActionText?: string | undefined | null | Variable<any, string>,
		homeHeroDescription?: string | undefined | null | Variable<any, string>,
		homeHeroLabel?: string | undefined | null | Variable<any, string>,
		homeHeroTitle?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsDescription?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsTitle?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionText?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoDescription?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoTitle?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		openGraphDescription?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** aggregate max on columns */
	["website_max_fields"]: AliasType<{
		aboutUsContactBannerImageFileId?: boolean | `@${string}`,
		aboutUsContactTitle?: boolean | `@${string}`,
		aboutUsHistoryBannerImageFileId?: boolean | `@${string}`,
		aboutUsHistoryDescription?: boolean | `@${string}`,
		aboutUsHistoryTitle?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleTwo?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleTwo?: boolean | `@${string}`,
		aboutUsStatementBannerImageFileId?: boolean | `@${string}`,
		aboutUsStatementBannerText?: boolean | `@${string}`,
		aboutUsWeAreReadyBannerImageId?: boolean | `@${string}`,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyInnovationDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySocialResponsibilityDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySpecializedSupportDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyTitle?: boolean | `@${string}`,
		aboutUsWeAreReadyTransparencyDescription?: boolean | `@${string}`,
		aboutUsWhatMoveBannerImageFileId?: boolean | `@${string}`,
		aboutUsWhatMoveUsMission?: boolean | `@${string}`,
		aboutUsWhatMoveUsPurpose?: boolean | `@${string}`,
		aboutUsWhatMoveUsTitle?: boolean | `@${string}`,
		aboutUsWhatMoveUsValues?: boolean | `@${string}`,
		aboutUsWhatMoveUsVision?: boolean | `@${string}`,
		accentColor?: boolean | `@${string}`,
		backgroundColor?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		domain?: boolean | `@${string}`,
		foregroundColor?: boolean | `@${string}`,
		googleAnalyticsKey?: boolean | `@${string}`,
		googleTagManagerKey?: boolean | `@${string}`,
		homeBrandHighlightBannerImageFileId?: boolean | `@${string}`,
		homeBrandHighlightDescription?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointValuePropositionList?: boolean | `@${string}`,
		homeBrandHighlightTitle?: boolean | `@${string}`,
		homeHeroBannerImageFileId?: boolean | `@${string}`,
		homeHeroCallToActionTargetUrl?: boolean | `@${string}`,
		homeHeroCallToActionText?: boolean | `@${string}`,
		homeHeroDescription?: boolean | `@${string}`,
		homeHeroLabel?: boolean | `@${string}`,
		homeHeroTitle?: boolean | `@${string}`,
		homeOurDifferentiatorsBannerImageFileId?: boolean | `@${string}`,
		homeOurDifferentiatorsDescription?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsTitle?: boolean | `@${string}`,
		homeWhatWeDoCallToActionTargetUrl?: boolean | `@${string}`,
		homeWhatWeDoCallToActionText?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoLegend?: boolean | `@${string}`,
		homeWhatWeDoDescription?: boolean | `@${string}`,
		homeWhatWeDoTitle?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		openGraphDescription?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["website_min_fields"]: AliasType<{
		aboutUsContactBannerImageFileId?: boolean | `@${string}`,
		aboutUsContactTitle?: boolean | `@${string}`,
		aboutUsHistoryBannerImageFileId?: boolean | `@${string}`,
		aboutUsHistoryDescription?: boolean | `@${string}`,
		aboutUsHistoryTitle?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleTwo?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleTwo?: boolean | `@${string}`,
		aboutUsStatementBannerImageFileId?: boolean | `@${string}`,
		aboutUsStatementBannerText?: boolean | `@${string}`,
		aboutUsWeAreReadyBannerImageId?: boolean | `@${string}`,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyInnovationDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySocialResponsibilityDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySpecializedSupportDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyTitle?: boolean | `@${string}`,
		aboutUsWeAreReadyTransparencyDescription?: boolean | `@${string}`,
		aboutUsWhatMoveBannerImageFileId?: boolean | `@${string}`,
		aboutUsWhatMoveUsMission?: boolean | `@${string}`,
		aboutUsWhatMoveUsPurpose?: boolean | `@${string}`,
		aboutUsWhatMoveUsTitle?: boolean | `@${string}`,
		aboutUsWhatMoveUsValues?: boolean | `@${string}`,
		aboutUsWhatMoveUsVision?: boolean | `@${string}`,
		accentColor?: boolean | `@${string}`,
		backgroundColor?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		domain?: boolean | `@${string}`,
		foregroundColor?: boolean | `@${string}`,
		googleAnalyticsKey?: boolean | `@${string}`,
		googleTagManagerKey?: boolean | `@${string}`,
		homeBrandHighlightBannerImageFileId?: boolean | `@${string}`,
		homeBrandHighlightDescription?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointValuePropositionList?: boolean | `@${string}`,
		homeBrandHighlightTitle?: boolean | `@${string}`,
		homeHeroBannerImageFileId?: boolean | `@${string}`,
		homeHeroCallToActionTargetUrl?: boolean | `@${string}`,
		homeHeroCallToActionText?: boolean | `@${string}`,
		homeHeroDescription?: boolean | `@${string}`,
		homeHeroLabel?: boolean | `@${string}`,
		homeHeroTitle?: boolean | `@${string}`,
		homeOurDifferentiatorsBannerImageFileId?: boolean | `@${string}`,
		homeOurDifferentiatorsDescription?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsTitle?: boolean | `@${string}`,
		homeWhatWeDoCallToActionTargetUrl?: boolean | `@${string}`,
		homeWhatWeDoCallToActionText?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoLegend?: boolean | `@${string}`,
		homeWhatWeDoDescription?: boolean | `@${string}`,
		homeWhatWeDoTitle?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		openGraphDescription?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "website" */
	["website_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ValueTypes["website"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "website" */
	["website_on_conflict"]: {
		constraint: ValueTypes["website_constraint"] | Variable<any, string>,
		update_columns: Array<ValueTypes["website_update_column"]> | Variable<any, string>,
		where?: ValueTypes["website_bool_exp"] | undefined | null | Variable<any, string>
	};
	/** Ordering options when selecting data from "website". */
	["website_order_by"]: {
		aboutUsContactBannerImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsContactTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsHistoryBannerImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsHistoryDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsHistoryTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleOne?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleTwo?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleOne?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleTwo?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsStatementBannerImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsStatementBannerText?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyBannerImageId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyInnovationDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySocialResponsibilityDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySpecializedSupportDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTransparencyDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveBannerImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsMission?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsPurpose?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsValues?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsVision?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		accentColor?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		backgroundColor?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		brand?: ValueTypes["brands_order_by"] | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		domain?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		foregroundColor?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		googleAnalyticsKey?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		googleTagManagerKey?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeBrandHighlightBannerImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeBrandHighlightDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleOne?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleTwo?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleOne?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleTwo?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointValuePropositionList?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeBrandHighlightTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeHeroBannerImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeHeroCallToActionTargetUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeHeroCallToActionText?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeHeroDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeHeroLabel?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeHeroTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsBannerImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleOne?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleTwo?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleOne?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleTwo?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionTargetUrl?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionText?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneLegend?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeLegend?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoImageFileId?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoLegend?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		homeWhatWeDoTitle?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		id?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		openGraphDescription?: ValueTypes["order_by"] | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["order_by"] | undefined | null | Variable<any, string>
	};
	/** primary key columns input for table: website */
	["website_pk_columns_input"]: {
		id: ValueTypes["uuid"] | Variable<any, string>
	};
	/** select columns of table "website" */
	["website_select_column"]: website_select_column;
	/** input type for updating data in table "website" */
	["website_set_input"]: {
		aboutUsContactBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsContactTitle?: string | undefined | null | Variable<any, string>,
		aboutUsHistoryBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsHistoryDescription?: string | undefined | null | Variable<any, string>,
		aboutUsHistoryTitle?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		aboutUsStatementBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsStatementBannerText?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyBannerImageId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyInnovationDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTitle?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsMission?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsPurpose?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsTitle?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsValues?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsVision?: string | undefined | null | Variable<any, string>,
		accentColor?: string | undefined | null | Variable<any, string>,
		backgroundColor?: string | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		domain?: string | undefined | null | Variable<any, string>,
		foregroundColor?: string | undefined | null | Variable<any, string>,
		googleAnalyticsKey?: string | undefined | null | Variable<any, string>,
		googleTagManagerKey?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeBrandHighlightDescription?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined | null | Variable<any, string>,
		homeBrandHighlightTitle?: string | undefined | null | Variable<any, string>,
		homeHeroBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeHeroCallToActionTargetUrl?: string | undefined | null | Variable<any, string>,
		homeHeroCallToActionText?: string | undefined | null | Variable<any, string>,
		homeHeroDescription?: string | undefined | null | Variable<any, string>,
		homeHeroLabel?: string | undefined | null | Variable<any, string>,
		homeHeroTitle?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsDescription?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsTitle?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionText?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoDescription?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoTitle?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		openGraphDescription?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** Streaming cursor of the table "website" */
	["website_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ValueTypes["website_stream_cursor_value_input"] | Variable<any, string>,
		/** cursor ordering */
		ordering?: ValueTypes["cursor_ordering"] | undefined | null | Variable<any, string>
	};
	/** Initial value of the column from where the streaming should start */
	["website_stream_cursor_value_input"]: {
		aboutUsContactBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsContactTitle?: string | undefined | null | Variable<any, string>,
		aboutUsHistoryBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsHistoryDescription?: string | undefined | null | Variable<any, string>,
		aboutUsHistoryTitle?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		aboutUsHistorytKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		aboutUsStatementBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsStatementBannerText?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyBannerImageId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyInnovationDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTitle?: string | undefined | null | Variable<any, string>,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsMission?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsPurpose?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsTitle?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsValues?: string | undefined | null | Variable<any, string>,
		aboutUsWhatMoveUsVision?: string | undefined | null | Variable<any, string>,
		accentColor?: string | undefined | null | Variable<any, string>,
		backgroundColor?: string | undefined | null | Variable<any, string>,
		brandId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		created_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>,
		domain?: string | undefined | null | Variable<any, string>,
		foregroundColor?: string | undefined | null | Variable<any, string>,
		googleAnalyticsKey?: string | undefined | null | Variable<any, string>,
		googleTagManagerKey?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeBrandHighlightDescription?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined | null | Variable<any, string>,
		homeBrandHighlightTitle?: string | undefined | null | Variable<any, string>,
		homeHeroBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeHeroCallToActionTargetUrl?: string | undefined | null | Variable<any, string>,
		homeHeroCallToActionText?: string | undefined | null | Variable<any, string>,
		homeHeroDescription?: string | undefined | null | Variable<any, string>,
		homeHeroLabel?: string | undefined | null | Variable<any, string>,
		homeHeroTitle?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsBannerImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsDescription?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined | null | Variable<any, string>,
		homeOurDifferentiatorsTitle?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToActionText?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoImageFileId?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoDescription?: string | undefined | null | Variable<any, string>,
		homeWhatWeDoTitle?: string | undefined | null | Variable<any, string>,
		id?: ValueTypes["uuid"] | undefined | null | Variable<any, string>,
		openGraphDescription?: string | undefined | null | Variable<any, string>,
		updated_at?: ValueTypes["timestamptz"] | undefined | null | Variable<any, string>
	};
	/** update columns of table "website" */
	["website_update_column"]: website_update_column;
	["website_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ValueTypes["website_set_input"] | undefined | null | Variable<any, string>,
		/** filter the rows which have to be updated */
		where: ValueTypes["website_bool_exp"] | Variable<any, string>
	}
}

export type ResolverInputTypes = {
	["schema"]: AliasType<{
		query?: ResolverInputTypes["query_root"],
		mutation?: ResolverInputTypes["mutation_root"],
		subscription?: ResolverInputTypes["subscription_root"],
		__typename?: boolean | `@${string}`
	}>;
	["AIImageResult"]: AliasType<{
		id?: boolean | `@${string}`,
		url?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "AppIntegration" */
	["AppIntegration"]: AliasType<{
		configParams?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "AppIntegration" */
	["AppIntegration_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["AppIntegration_aggregate_fields"],
		nodes?: ResolverInputTypes["AppIntegration"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "AppIntegration" */
	["AppIntegration_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["AppIntegration_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["AppIntegration_max_fields"],
		min?: ResolverInputTypes["AppIntegration_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["AppIntegration_append_input"]: {
		configParams?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "AppIntegration". All fields are combined with a logical 'AND'. */
	["AppIntegration_bool_exp"]: {
		_and?: Array<ResolverInputTypes["AppIntegration_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["AppIntegration_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["AppIntegration_bool_exp"]> | undefined | null,
		configParams?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		description?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		icon?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		type?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "AppIntegration" */
	["AppIntegration_constraint"]: AppIntegration_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["AppIntegration_delete_at_path_input"]: {
		configParams?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["AppIntegration_delete_elem_input"]: {
		configParams?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["AppIntegration_delete_key_input"]: {
		configParams?: string | undefined | null
	};
	/** input type for inserting data into table "AppIntegration" */
	["AppIntegration_insert_input"]: {
		configParams?: ResolverInputTypes["jsonb"] | undefined | null,
		description?: string | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		type?: string | undefined | null
	};
	/** aggregate max on columns */
	["AppIntegration_max_fields"]: AliasType<{
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["AppIntegration_min_fields"]: AliasType<{
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "AppIntegration" */
	["AppIntegration_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["AppIntegration"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "AppIntegration" */
	["AppIntegration_on_conflict"]: {
		constraint: ResolverInputTypes["AppIntegration_constraint"],
		update_columns: Array<ResolverInputTypes["AppIntegration_update_column"]>,
		where?: ResolverInputTypes["AppIntegration_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "AppIntegration". */
	["AppIntegration_order_by"]: {
		configParams?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		icon?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		type?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: AppIntegration */
	["AppIntegration_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["AppIntegration_prepend_input"]: {
		configParams?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "AppIntegration" */
	["AppIntegration_select_column"]: AppIntegration_select_column;
	/** input type for updating data in table "AppIntegration" */
	["AppIntegration_set_input"]: {
		configParams?: ResolverInputTypes["jsonb"] | undefined | null,
		description?: string | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		type?: string | undefined | null
	};
	/** Streaming cursor of the table "AppIntegration" */
	["AppIntegration_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["AppIntegration_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["AppIntegration_stream_cursor_value_input"]: {
		configParams?: ResolverInputTypes["jsonb"] | undefined | null,
		description?: string | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		type?: string | undefined | null
	};
	/** update columns of table "AppIntegration" */
	["AppIntegration_update_column"]: AppIntegration_update_column;
	["AppIntegration_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["AppIntegration_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["AppIntegration_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["AppIntegration_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["AppIntegration_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["AppIntegration_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["AppIntegration_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["AppIntegration_bool_exp"]
	};
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
	["Boolean_comparison_exp"]: {
		_eq?: boolean | undefined | null,
		_gt?: boolean | undefined | null,
		_gte?: boolean | undefined | null,
		_in?: Array<boolean> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: boolean | undefined | null,
		_lte?: boolean | undefined | null,
		_neq?: boolean | undefined | null,
		_nin?: Array<boolean> | undefined | null
	};
	["Category"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "Course" */
	["Course"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageUrl?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "CourseCategory" */
	["CourseCategory"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "CourseCategory" */
	["CourseCategory_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["CourseCategory_aggregate_fields"],
		nodes?: ResolverInputTypes["CourseCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "CourseCategory" */
	["CourseCategory_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["CourseCategory_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["CourseCategory_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["CourseCategory_max_fields"],
		min?: ResolverInputTypes["CourseCategory_min_fields"],
		stddev?: ResolverInputTypes["CourseCategory_stddev_fields"],
		stddev_pop?: ResolverInputTypes["CourseCategory_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["CourseCategory_stddev_samp_fields"],
		sum?: ResolverInputTypes["CourseCategory_sum_fields"],
		var_pop?: ResolverInputTypes["CourseCategory_var_pop_fields"],
		var_samp?: ResolverInputTypes["CourseCategory_var_samp_fields"],
		variance?: ResolverInputTypes["CourseCategory_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["CourseCategory_avg_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "CourseCategory". All fields are combined with a logical 'AND'. */
	["CourseCategory_bool_exp"]: {
		_and?: Array<ResolverInputTypes["CourseCategory_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["CourseCategory_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["CourseCategory_bool_exp"]> | undefined | null,
		id?: ResolverInputTypes["bigint_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "CourseCategory" */
	["CourseCategory_constraint"]: CourseCategory_constraint;
	/** input type for incrementing numeric columns in table "CourseCategory" */
	["CourseCategory_inc_input"]: {
		id?: ResolverInputTypes["bigint"] | undefined | null
	};
	/** input type for inserting data into table "CourseCategory" */
	["CourseCategory_insert_input"]: {
		id?: ResolverInputTypes["bigint"] | undefined | null,
		name?: string | undefined | null
	};
	/** aggregate max on columns */
	["CourseCategory_max_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["CourseCategory_min_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "CourseCategory" */
	["CourseCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["CourseCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "CourseCategory" */
	["CourseCategory_on_conflict"]: {
		constraint: ResolverInputTypes["CourseCategory_constraint"],
		update_columns: Array<ResolverInputTypes["CourseCategory_update_column"]>,
		where?: ResolverInputTypes["CourseCategory_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "CourseCategory". */
	["CourseCategory_order_by"]: {
		id?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: CourseCategory */
	["CourseCategory_pk_columns_input"]: {
		id: ResolverInputTypes["bigint"]
	};
	/** select columns of table "CourseCategory" */
	["CourseCategory_select_column"]: CourseCategory_select_column;
	/** input type for updating data in table "CourseCategory" */
	["CourseCategory_set_input"]: {
		id?: ResolverInputTypes["bigint"] | undefined | null,
		name?: string | undefined | null
	};
	/** aggregate stddev on columns */
	["CourseCategory_stddev_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["CourseCategory_stddev_pop_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["CourseCategory_stddev_samp_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "CourseCategory" */
	["CourseCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["CourseCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["CourseCategory_stream_cursor_value_input"]: {
		id?: ResolverInputTypes["bigint"] | undefined | null,
		name?: string | undefined | null
	};
	/** aggregate sum on columns */
	["CourseCategory_sum_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "CourseCategory" */
	["CourseCategory_update_column"]: CourseCategory_update_column;
	["CourseCategory_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["CourseCategory_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["CourseCategory_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["CourseCategory_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["CourseCategory_var_pop_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["CourseCategory_var_samp_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["CourseCategory_variance_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "Course" */
	["Course_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["Course_aggregate_fields"],
		nodes?: ResolverInputTypes["Course"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "Course" */
	["Course_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["Course_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["Course_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["Course_max_fields"],
		min?: ResolverInputTypes["Course_min_fields"],
		stddev?: ResolverInputTypes["Course_stddev_fields"],
		stddev_pop?: ResolverInputTypes["Course_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["Course_stddev_samp_fields"],
		sum?: ResolverInputTypes["Course_sum_fields"],
		var_pop?: ResolverInputTypes["Course_var_pop_fields"],
		var_samp?: ResolverInputTypes["Course_var_samp_fields"],
		variance?: ResolverInputTypes["Course_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["Course_avg_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "Course". All fields are combined with a logical 'AND'. */
	["Course_bool_exp"]: {
		_and?: Array<ResolverInputTypes["Course_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["Course_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["Course_bool_exp"]> | undefined | null,
		courseCategoryId?: ResolverInputTypes["bigint_comparison_exp"] | undefined | null,
		description?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		imageUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "Course" */
	["Course_constraint"]: Course_constraint;
	/** input type for incrementing numeric columns in table "Course" */
	["Course_inc_input"]: {
		courseCategoryId?: ResolverInputTypes["bigint"] | undefined | null
	};
	/** input type for inserting data into table "Course" */
	["Course_insert_input"]: {
		courseCategoryId?: ResolverInputTypes["bigint"] | undefined | null,
		description?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		imageUrl?: string | undefined | null,
		name?: string | undefined | null
	};
	/** aggregate max on columns */
	["Course_max_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageUrl?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["Course_min_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageUrl?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "Course" */
	["Course_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["Course"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "Course" */
	["Course_on_conflict"]: {
		constraint: ResolverInputTypes["Course_constraint"],
		update_columns: Array<ResolverInputTypes["Course_update_column"]>,
		where?: ResolverInputTypes["Course_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "Course". */
	["Course_order_by"]: {
		courseCategoryId?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		imageUrl?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: Course */
	["Course_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "Course" */
	["Course_select_column"]: Course_select_column;
	/** input type for updating data in table "Course" */
	["Course_set_input"]: {
		courseCategoryId?: ResolverInputTypes["bigint"] | undefined | null,
		description?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		imageUrl?: string | undefined | null,
		name?: string | undefined | null
	};
	/** aggregate stddev on columns */
	["Course_stddev_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["Course_stddev_pop_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["Course_stddev_samp_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "Course" */
	["Course_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["Course_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["Course_stream_cursor_value_input"]: {
		courseCategoryId?: ResolverInputTypes["bigint"] | undefined | null,
		description?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		imageUrl?: string | undefined | null,
		name?: string | undefined | null
	};
	/** aggregate sum on columns */
	["Course_sum_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "Course" */
	["Course_update_column"]: Course_update_column;
	["Course_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["Course_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["Course_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["Course_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["Course_var_pop_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["Course_var_samp_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["Course_variance_fields"]: AliasType<{
		courseCategoryId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["CreateAutomaticProjectOutput"]: AliasType<{
		projectId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["CreateImageUsingImagineOutput"]: AliasType<{
		errorMessage?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["ExportToWordpressInput"]: {
		category: number,
		generatedTemplateById?: string | undefined | null,
		integrationId: string,
		postTimeType: string,
		scheduledDate?: ResolverInputTypes["date"] | undefined | null,
		tags?: Array<number> | undefined | null
	};
	["ExportToWordpressOutput"]: AliasType<{
		url?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["FacebookAuthCallbackOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["FinishOnboardOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GenerateCalendarExOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GenerateFromTextTemplateInput"]: {
		locale: string,
		selectedParameters?: Array<ResolverInputTypes["StringKeyValuePair"]> | undefined | null,
		templateId: string,
		templateVariables?: Array<ResolverInputTypes["StringKeyValuePair"]> | undefined | null
	};
	["GenerateFromTextTemplateOutput"]: AliasType<{
		text?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GenerateImageFromPromptInput"]: {
		provider: string,
		query: string
	};
	["GenerateImageFromPromptOutput"]: AliasType<{
		images?: ResolverInputTypes["AIImageResult"],
		__typename?: boolean | `@${string}`
	}>;
	["GenerateNextMonthCalendarInput"]: {
		campaignObjective: string
	};
	["GenerateNextMonthCalendarOutput"]: AliasType<{
		created?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate"]: AliasType<{
		GeneratedPromptByTemplateImages?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplateImages_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage_aggregate"]],
		/** An object relationship */
		TextPromptTemplate?: ResolverInputTypes["TextPromptTemplate"],
		active?: boolean | `@${string}`,
		alias?: boolean | `@${string}`,
		backgroundImageFileId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		command?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		/** An object relationship */
		displayFormat?: ResolverInputTypes["displayFormats"],
		displayImageUrl?: boolean | `@${string}`,
		displayMapper?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		generatingDisplayStatus?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		/** An object relationship */
		pricingPlan?: ResolverInputTypes["pricingPlan"],
		pricingPlanId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		result?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		selectedDisplayFormatId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["GeneratedPromptByTemplateImage_aggregate_fields"],
		nodes?: ResolverInputTypes["GeneratedPromptByTemplateImage"],
		__typename?: boolean | `@${string}`
	}>;
	["GeneratedPromptByTemplateImage_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"] | undefined | null
	};
	["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["GeneratedPromptByTemplateImage_max_fields"],
		min?: ResolverInputTypes["GeneratedPromptByTemplateImage_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["GeneratedPromptByTemplateImage_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["GeneratedPromptByTemplateImage_min_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["GeneratedPromptByTemplateImage_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "GeneratedPromptByTemplateImage". All fields are combined with a logical 'AND'. */
	["GeneratedPromptByTemplateImage_bool_exp"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		_and?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"]> | undefined | null,
		fileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_constraint"]: GeneratedPromptByTemplateImage_constraint;
	/** input type for inserting data into table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_insert_input"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["GeneratedPromptByTemplateImage_max_fields"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_max_order_by"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["order_by"] | undefined | null,
		fileId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["GeneratedPromptByTemplateImage_min_fields"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_min_order_by"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["order_by"] | undefined | null,
		fileId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["GeneratedPromptByTemplateImage"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_on_conflict"]: {
		constraint: ResolverInputTypes["GeneratedPromptByTemplateImage_constraint"],
		update_columns: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_update_column"]>,
		where?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "GeneratedPromptByTemplateImage". */
	["GeneratedPromptByTemplateImage_order_by"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["order_by"] | undefined | null,
		fileId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: GeneratedPromptByTemplateImage */
	["GeneratedPromptByTemplateImage_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_select_column"]: GeneratedPromptByTemplateImage_select_column;
	/** input type for updating data in table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_set_input"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["GeneratedPromptByTemplateImage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["GeneratedPromptByTemplateImage_stream_cursor_value_input"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_update_column"]: GeneratedPromptByTemplateImage_update_column;
	["GeneratedPromptByTemplateImage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["GeneratedPromptByTemplateImage_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"]
	};
	/** aggregated selection of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["GeneratedPromptByTemplate_aggregate_fields"],
		nodes?: ResolverInputTypes["GeneratedPromptByTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	["GeneratedPromptByTemplate_aggregate_bool_exp"]: {
		bool_and?: ResolverInputTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"] | undefined | null,
		bool_or?: ResolverInputTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"] | undefined | null,
		count?: ResolverInputTypes["GeneratedPromptByTemplate_aggregate_bool_exp_count"] | undefined | null
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"]: {
		arguments: ResolverInputTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"]: {
		arguments: ResolverInputTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["GeneratedPromptByTemplate_max_fields"],
		min?: ResolverInputTypes["GeneratedPromptByTemplate_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["GeneratedPromptByTemplate_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["GeneratedPromptByTemplate_min_order_by"] | undefined | null
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["GeneratedPromptByTemplate_append_input"]: {
		command?: ResolverInputTypes["jsonb"] | undefined | null,
		displayMapper?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** input type for inserting array relation for remote table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["GeneratedPromptByTemplate_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["GeneratedPromptByTemplate_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "GeneratedPromptByTemplate". All fields are combined with a logical 'AND'. */
	["GeneratedPromptByTemplate_bool_exp"]: {
		GeneratedPromptByTemplateImages?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null,
		GeneratedPromptByTemplateImages_aggregate?: ResolverInputTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp"] | undefined | null,
		TextPromptTemplate?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null,
		_and?: Array<ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"]> | undefined | null,
		active?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		alias?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		backgroundImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		channel?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		command?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		displayFormat?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null,
		displayImageUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		displayMapper?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		generatingDisplayStatus?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		pricingPlan?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null,
		pricingPlanId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		result?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		selectedDisplayFormatId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		status?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_constraint"]: GeneratedPromptByTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["GeneratedPromptByTemplate_delete_at_path_input"]: {
		command?: Array<string> | undefined | null,
		displayMapper?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["GeneratedPromptByTemplate_delete_elem_input"]: {
		command?: number | undefined | null,
		displayMapper?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["GeneratedPromptByTemplate_delete_key_input"]: {
		command?: string | undefined | null,
		displayMapper?: string | undefined | null
	};
	/** input type for inserting data into table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_insert_input"]: {
		GeneratedPromptByTemplateImages?: ResolverInputTypes["GeneratedPromptByTemplateImage_arr_rel_insert_input"] | undefined | null,
		TextPromptTemplate?: ResolverInputTypes["TextPromptTemplate_obj_rel_insert_input"] | undefined | null,
		active?: boolean | undefined | null,
		alias?: string | undefined | null,
		backgroundImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		channel?: string | undefined | null,
		command?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		displayFormat?: ResolverInputTypes["displayFormats_obj_rel_insert_input"] | undefined | null,
		displayImageUrl?: string | undefined | null,
		displayMapper?: ResolverInputTypes["jsonb"] | undefined | null,
		generatingDisplayStatus?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		pricingPlan?: ResolverInputTypes["pricingPlan_obj_rel_insert_input"] | undefined | null,
		pricingPlanId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		result?: string | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["uuid"] | undefined | null,
		selectedDisplayFormatId?: ResolverInputTypes["uuid"] | undefined | null,
		status?: string | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["GeneratedPromptByTemplate_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		backgroundImageFileId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		displayImageUrl?: boolean | `@${string}`,
		generatingDisplayStatus?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		pricingPlanId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		result?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		selectedDisplayFormatId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_max_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		backgroundImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		displayImageUrl?: ResolverInputTypes["order_by"] | undefined | null,
		generatingDisplayStatus?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		pricingPlanId?: ResolverInputTypes["order_by"] | undefined | null,
		publishDate?: ResolverInputTypes["order_by"] | undefined | null,
		result?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDate?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["order_by"] | undefined | null,
		selectedDisplayFormatId?: ResolverInputTypes["order_by"] | undefined | null,
		status?: ResolverInputTypes["order_by"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["GeneratedPromptByTemplate_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		backgroundImageFileId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		displayImageUrl?: boolean | `@${string}`,
		generatingDisplayStatus?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		pricingPlanId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		result?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		selectedDisplayFormatId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_min_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		backgroundImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		displayImageUrl?: ResolverInputTypes["order_by"] | undefined | null,
		generatingDisplayStatus?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		pricingPlanId?: ResolverInputTypes["order_by"] | undefined | null,
		publishDate?: ResolverInputTypes["order_by"] | undefined | null,
		result?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDate?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["order_by"] | undefined | null,
		selectedDisplayFormatId?: ResolverInputTypes["order_by"] | undefined | null,
		status?: ResolverInputTypes["order_by"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["GeneratedPromptByTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_on_conflict"]: {
		constraint: ResolverInputTypes["GeneratedPromptByTemplate_constraint"],
		update_columns: Array<ResolverInputTypes["GeneratedPromptByTemplate_update_column"]>,
		where?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "GeneratedPromptByTemplate". */
	["GeneratedPromptByTemplate_order_by"]: {
		GeneratedPromptByTemplateImages_aggregate?: ResolverInputTypes["GeneratedPromptByTemplateImage_aggregate_order_by"] | undefined | null,
		TextPromptTemplate?: ResolverInputTypes["TextPromptTemplate_order_by"] | undefined | null,
		active?: ResolverInputTypes["order_by"] | undefined | null,
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		backgroundImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		command?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		displayFormat?: ResolverInputTypes["displayFormats_order_by"] | undefined | null,
		displayImageUrl?: ResolverInputTypes["order_by"] | undefined | null,
		displayMapper?: ResolverInputTypes["order_by"] | undefined | null,
		generatingDisplayStatus?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		pricingPlan?: ResolverInputTypes["pricingPlan_order_by"] | undefined | null,
		pricingPlanId?: ResolverInputTypes["order_by"] | undefined | null,
		publishDate?: ResolverInputTypes["order_by"] | undefined | null,
		result?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDate?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["order_by"] | undefined | null,
		selectedDisplayFormatId?: ResolverInputTypes["order_by"] | undefined | null,
		status?: ResolverInputTypes["order_by"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: GeneratedPromptByTemplate */
	["GeneratedPromptByTemplate_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["GeneratedPromptByTemplate_prepend_input"]: {
		command?: ResolverInputTypes["jsonb"] | undefined | null,
		displayMapper?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column"]: GeneratedPromptByTemplate_select_column;
	/** select "GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_set_input"]: {
		active?: boolean | undefined | null,
		alias?: string | undefined | null,
		backgroundImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		channel?: string | undefined | null,
		command?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		displayImageUrl?: string | undefined | null,
		displayMapper?: ResolverInputTypes["jsonb"] | undefined | null,
		generatingDisplayStatus?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		pricingPlanId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		result?: string | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["uuid"] | undefined | null,
		selectedDisplayFormatId?: ResolverInputTypes["uuid"] | undefined | null,
		status?: string | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["GeneratedPromptByTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["GeneratedPromptByTemplate_stream_cursor_value_input"]: {
		active?: boolean | undefined | null,
		alias?: string | undefined | null,
		backgroundImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		channel?: string | undefined | null,
		command?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		displayImageUrl?: string | undefined | null,
		displayMapper?: ResolverInputTypes["jsonb"] | undefined | null,
		generatingDisplayStatus?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		pricingPlanId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		result?: string | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["uuid"] | undefined | null,
		selectedDisplayFormatId?: ResolverInputTypes["uuid"] | undefined | null,
		status?: string | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_update_column"]: GeneratedPromptByTemplate_update_column;
	["GeneratedPromptByTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["GeneratedPromptByTemplate_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["GeneratedPromptByTemplate_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["GeneratedPromptByTemplate_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["GeneratedPromptByTemplate_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["GeneratedPromptByTemplate_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["GeneratedPromptByTemplate_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"]
	};
	["GetFacebookPagesOutput"]: AliasType<{
		pages?: ResolverInputTypes["facebookPage"],
		__typename?: boolean | `@${string}`
	}>;
	["GetFacebookTokenOutput"]: AliasType<{
		loginUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetImageFromDisplayRendererOutput"]: AliasType<{
		image?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetInstagramAuthCallbackOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetInstagramPagesOutput"]: AliasType<{
		accounts?: ResolverInputTypes["instagramPageAccount"],
		__typename?: boolean | `@${string}`
	}>;
	["GetInstagramTokenOutput"]: AliasType<{
		loginUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetPaymentUrlCommand"]: {
		email: string,
		price_id: string,
		user_id: string
	};
	["GetPaymentUrlResponse"]: AliasType<{
		url?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["GetWordpressCategoriesInput"]: {
		integrationId: string
	};
	["GetWordpressCategoriesOutput"]: AliasType<{
		categories?: ResolverInputTypes["Category"],
		__typename?: boolean | `@${string}`
	}>;
	["GetWordpressTagsInput"]: {
		integrationId: string
	};
	["GetWordpressTagsOutput"]: AliasType<{
		tags?: ResolverInputTypes["Tag"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
	["Int_comparison_exp"]: {
		_eq?: number | undefined | null,
		_gt?: number | undefined | null,
		_gte?: number | undefined | null,
		_in?: Array<number> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: number | undefined | null,
		_lte?: number | undefined | null,
		_neq?: number | undefined | null,
		_nin?: Array<number> | undefined | null
	};
	["LinkedInTokenCallbackOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["ManageSubscriptionOutput"]: AliasType<{
		url?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PexelsImageResult"]: AliasType<{
		alt?: boolean | `@${string}`,
		height?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		photographer?: boolean | `@${string}`,
		photographer_url?: boolean | `@${string}`,
		small?: boolean | `@${string}`,
		url?: boolean | `@${string}`,
		width?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostCarouselToInstagramOutput"]: AliasType<{
		error?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostFeedToInstagramOutput"]: AliasType<{
		error?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostStoryToInstagramOutput"]: AliasType<{
		error?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostToFacebookOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PostToLinkedInOutput"]: AliasType<{
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PromptOutput"]: AliasType<{
		text?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["PublishOrScheduleProjectOutput"]: AliasType<{
		errorMessage?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["RenderAndSaveDisplayImageOutput"]: AliasType<{
		image?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["RenderDisplayOutput"]: AliasType<{
		image?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SaveAndGeneratePolotnoImageOutput"]: AliasType<{
		message?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SearchImagesFromPexelsResponse"]: AliasType<{
		images?: ResolverInputTypes["PexelsImageResult"],
		__typename?: boolean | `@${string}`
	}>;
	["SelectNewDisplayOutput"]: AliasType<{
		error?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestInput"]: {
		domain?: string | undefined | null
	};
	["SerpStatTestInputData"]: AliasType<{
		ad_keywords?: boolean | `@${string}`,
		ads?: boolean | `@${string}`,
		ads_dynamic?: boolean | `@${string}`,
		domain?: boolean | `@${string}`,
		down_keywords?: boolean | `@${string}`,
		keywords?: boolean | `@${string}`,
		keywords_dynamic?: boolean | `@${string}`,
		new_keywords?: boolean | `@${string}`,
		out_keywords?: boolean | `@${string}`,
		prev_date?: boolean | `@${string}`,
		rised_keywords?: boolean | `@${string}`,
		traff?: boolean | `@${string}`,
		traff_dynamic?: boolean | `@${string}`,
		visible?: boolean | `@${string}`,
		visible_dynamic?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestInputResult"]: AliasType<{
		data?: ResolverInputTypes["SerpStatTestInputData"],
		summary_info?: ResolverInputTypes["SerpStatTestInputSummaryInfo"],
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestInputSummaryInfo"]: AliasType<{
		left_lines?: boolean | `@${string}`,
		page?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestOutput"]: AliasType<{
		data?: ResolverInputTypes["SerpStatTestOutputData"],
		__typename?: boolean | `@${string}`
	}>;
	["SerpStatTestOutputData"]: AliasType<{
		id?: boolean | `@${string}`,
		result?: ResolverInputTypes["SerpStatTestInputResult"],
		__typename?: boolean | `@${string}`
	}>;
	["StringKeyValuePair"]: {
		key: string,
		value: string
	};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
	["String_array_comparison_exp"]: {
		/** is the array contained in the given array value */
		_contained_in?: Array<string> | undefined | null,
		/** does the array contain the given value */
		_contains?: Array<string> | undefined | null,
		_eq?: Array<string> | undefined | null,
		_gt?: Array<string> | undefined | null,
		_gte?: Array<string> | undefined | null,
		_in?: Array<Array<string> | undefined | null>,
		_is_null?: boolean | undefined | null,
		_lt?: Array<string> | undefined | null,
		_lte?: Array<string> | undefined | null,
		_neq?: Array<string> | undefined | null,
		_nin?: Array<Array<string> | undefined | null>
	};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
	["String_comparison_exp"]: {
		_eq?: string | undefined | null,
		_gt?: string | undefined | null,
		_gte?: string | undefined | null,
		/** does the column match the given case-insensitive pattern */
		_ilike?: string | undefined | null,
		_in?: Array<string> | undefined | null,
		/** does the column match the given POSIX regular expression, case insensitive */
		_iregex?: string | undefined | null,
		_is_null?: boolean | undefined | null,
		/** does the column match the given pattern */
		_like?: string | undefined | null,
		_lt?: string | undefined | null,
		_lte?: string | undefined | null,
		_neq?: string | undefined | null,
		/** does the column NOT match the given case-insensitive pattern */
		_nilike?: string | undefined | null,
		_nin?: Array<string> | undefined | null,
		/** does the column NOT match the given POSIX regular expression, case insensitive */
		_niregex?: string | undefined | null,
		/** does the column NOT match the given pattern */
		_nlike?: string | undefined | null,
		/** does the column NOT match the given POSIX regular expression, case sensitive */
		_nregex?: string | undefined | null,
		/** does the column NOT match the given SQL regular expression */
		_nsimilar?: string | undefined | null,
		/** does the column match the given POSIX regular expression, case sensitive */
		_regex?: string | undefined | null,
		/** does the column match the given SQL regular expression */
		_similar?: string | undefined | null
	};
	["Tag"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "TextGeneratorOption" */
	["TextGeneratorOption"]: AliasType<{
		Label?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		TextGeneratorOptionEntries?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOptionEntries_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry_aggregate"]],
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		popover_tooltip?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry"]: AliasType<{
		Label?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		/** An object relationship */
		TextGeneratorOption?: ResolverInputTypes["TextGeneratorOption"],
		TextGeneratorOptionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["TextGeneratorOptionEntry_aggregate_fields"],
		nodes?: ResolverInputTypes["TextGeneratorOptionEntry"],
		__typename?: boolean | `@${string}`
	}>;
	["TextGeneratorOptionEntry_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["TextGeneratorOptionEntry_aggregate_bool_exp_count"] | undefined | null
	};
	["TextGeneratorOptionEntry_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["TextGeneratorOptionEntry_max_fields"],
		min?: ResolverInputTypes["TextGeneratorOptionEntry_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["TextGeneratorOptionEntry_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["TextGeneratorOptionEntry_min_order_by"] | undefined | null
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOptionEntry_append_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** input type for inserting array relation for remote table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["TextGeneratorOptionEntry_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["TextGeneratorOptionEntry_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "TextGeneratorOptionEntry". All fields are combined with a logical 'AND'. */
	["TextGeneratorOptionEntry_bool_exp"]: {
		Label?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		TextGeneratorOption?: ResolverInputTypes["TextGeneratorOption_bool_exp"] | undefined | null,
		TextGeneratorOptionId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		_and?: Array<ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"]> | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_constraint"]: TextGeneratorOptionEntry_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextGeneratorOptionEntry_delete_at_path_input"]: {
		Label?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextGeneratorOptionEntry_delete_elem_input"]: {
		Label?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextGeneratorOptionEntry_delete_key_input"]: {
		Label?: string | undefined | null
	};
	/** input type for inserting data into table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_insert_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null,
		TextGeneratorOption?: ResolverInputTypes["TextGeneratorOption_obj_rel_insert_input"] | undefined | null,
		TextGeneratorOptionId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["TextGeneratorOptionEntry_max_fields"]: AliasType<{
		TextGeneratorOptionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_max_order_by"]: {
		TextGeneratorOptionId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["TextGeneratorOptionEntry_min_fields"]: AliasType<{
		TextGeneratorOptionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_min_order_by"]: {
		TextGeneratorOptionId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["TextGeneratorOptionEntry"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_on_conflict"]: {
		constraint: ResolverInputTypes["TextGeneratorOptionEntry_constraint"],
		update_columns: Array<ResolverInputTypes["TextGeneratorOptionEntry_update_column"]>,
		where?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "TextGeneratorOptionEntry". */
	["TextGeneratorOptionEntry_order_by"]: {
		Label?: ResolverInputTypes["order_by"] | undefined | null,
		TextGeneratorOption?: ResolverInputTypes["TextGeneratorOption_order_by"] | undefined | null,
		TextGeneratorOptionId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: TextGeneratorOptionEntry */
	["TextGeneratorOptionEntry_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOptionEntry_prepend_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_select_column"]: TextGeneratorOptionEntry_select_column;
	/** input type for updating data in table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_set_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null,
		TextGeneratorOptionId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["TextGeneratorOptionEntry_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["TextGeneratorOptionEntry_stream_cursor_value_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null,
		TextGeneratorOptionId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_update_column"]: TextGeneratorOptionEntry_update_column;
	["TextGeneratorOptionEntry_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["TextGeneratorOptionEntry_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["TextGeneratorOptionEntry_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["TextGeneratorOptionEntry_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["TextGeneratorOptionEntry_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["TextGeneratorOptionEntry_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["TextGeneratorOptionEntry_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"]
	};
	/** aggregated selection of "TextGeneratorOption" */
	["TextGeneratorOption_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["TextGeneratorOption_aggregate_fields"],
		nodes?: ResolverInputTypes["TextGeneratorOption"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "TextGeneratorOption" */
	["TextGeneratorOption_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["TextGeneratorOption_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["TextGeneratorOption_max_fields"],
		min?: ResolverInputTypes["TextGeneratorOption_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOption_append_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null,
		popover_tooltip?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "TextGeneratorOption". All fields are combined with a logical 'AND'. */
	["TextGeneratorOption_bool_exp"]: {
		Label?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		TextGeneratorOptionEntries?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null,
		TextGeneratorOptionEntries_aggregate?: ResolverInputTypes["TextGeneratorOptionEntry_aggregate_bool_exp"] | undefined | null,
		_and?: Array<ResolverInputTypes["TextGeneratorOption_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["TextGeneratorOption_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["TextGeneratorOption_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		popover_tooltip?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "TextGeneratorOption" */
	["TextGeneratorOption_constraint"]: TextGeneratorOption_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextGeneratorOption_delete_at_path_input"]: {
		Label?: Array<string> | undefined | null,
		popover_tooltip?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextGeneratorOption_delete_elem_input"]: {
		Label?: number | undefined | null,
		popover_tooltip?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextGeneratorOption_delete_key_input"]: {
		Label?: string | undefined | null,
		popover_tooltip?: string | undefined | null
	};
	/** input type for inserting data into table "TextGeneratorOption" */
	["TextGeneratorOption_insert_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null,
		TextGeneratorOptionEntries?: ResolverInputTypes["TextGeneratorOptionEntry_arr_rel_insert_input"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		popover_tooltip?: ResolverInputTypes["jsonb"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["TextGeneratorOption_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["TextGeneratorOption_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "TextGeneratorOption" */
	["TextGeneratorOption_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["TextGeneratorOption"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "TextGeneratorOption" */
	["TextGeneratorOption_obj_rel_insert_input"]: {
		data: ResolverInputTypes["TextGeneratorOption_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["TextGeneratorOption_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "TextGeneratorOption" */
	["TextGeneratorOption_on_conflict"]: {
		constraint: ResolverInputTypes["TextGeneratorOption_constraint"],
		update_columns: Array<ResolverInputTypes["TextGeneratorOption_update_column"]>,
		where?: ResolverInputTypes["TextGeneratorOption_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "TextGeneratorOption". */
	["TextGeneratorOption_order_by"]: {
		Label?: ResolverInputTypes["order_by"] | undefined | null,
		TextGeneratorOptionEntries_aggregate?: ResolverInputTypes["TextGeneratorOptionEntry_aggregate_order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		popover_tooltip?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: TextGeneratorOption */
	["TextGeneratorOption_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOption_prepend_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null,
		popover_tooltip?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "TextGeneratorOption" */
	["TextGeneratorOption_select_column"]: TextGeneratorOption_select_column;
	/** input type for updating data in table "TextGeneratorOption" */
	["TextGeneratorOption_set_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		popover_tooltip?: ResolverInputTypes["jsonb"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "TextGeneratorOption" */
	["TextGeneratorOption_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["TextGeneratorOption_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["TextGeneratorOption_stream_cursor_value_input"]: {
		Label?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		popover_tooltip?: ResolverInputTypes["jsonb"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "TextGeneratorOption" */
	["TextGeneratorOption_update_column"]: TextGeneratorOption_update_column;
	["TextGeneratorOption_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["TextGeneratorOption_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["TextGeneratorOption_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["TextGeneratorOption_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["TextGeneratorOption_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["TextGeneratorOption_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["TextGeneratorOption_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["TextGeneratorOption_bool_exp"]
	};
	/** columns and relationships of "TextPromptTemplate" */
	["TextPromptTemplate"]: AliasType<{
		GeneratedPromptByTemplates?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate"]],
		GeneratedPromptByTemplates_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate_aggregate"]],
		/** An object relationship */
		TextPromptTemplateCategory?: ResolverInputTypes["TextPromptTemplateCategory"],
		/** An object relationship */
		TextTemplateClientCategory?: ResolverInputTypes["TextTemplateClientCategory"],
		TextTemplateClientCategoryId?: boolean | `@${string}`,
		availableDisplays?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["textTemplateDisplayFormat_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["textTemplateDisplayFormat_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat"]],
		availableDisplays_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["textTemplateDisplayFormat_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["textTemplateDisplayFormat_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat_aggregate"]],
		categoryId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		definitions?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		description?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		flexible?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplateText?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory"]: AliasType<{
		TextPromptTemplates?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate"]],
		TextPromptTemplates_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate_aggregate"]],
		categoryName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["TextPromptTemplateCategory_aggregate_fields"],
		nodes?: ResolverInputTypes["TextPromptTemplateCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["TextPromptTemplateCategory_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["TextPromptTemplateCategory_max_fields"],
		min?: ResolverInputTypes["TextPromptTemplateCategory_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "TextPromptTemplateCategory". All fields are combined with a logical 'AND'. */
	["TextPromptTemplateCategory_bool_exp"]: {
		TextPromptTemplates?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null,
		TextPromptTemplates_aggregate?: ResolverInputTypes["TextPromptTemplate_aggregate_bool_exp"] | undefined | null,
		_and?: Array<ResolverInputTypes["TextPromptTemplateCategory_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["TextPromptTemplateCategory_bool_exp"]> | undefined | null,
		categoryName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_constraint"]: TextPromptTemplateCategory_constraint;
	/** input type for inserting data into table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_insert_input"]: {
		TextPromptTemplates?: ResolverInputTypes["TextPromptTemplate_arr_rel_insert_input"] | undefined | null,
		categoryName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["TextPromptTemplateCategory_max_fields"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["TextPromptTemplateCategory_min_fields"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["TextPromptTemplateCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_obj_rel_insert_input"]: {
		data: ResolverInputTypes["TextPromptTemplateCategory_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["TextPromptTemplateCategory_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_on_conflict"]: {
		constraint: ResolverInputTypes["TextPromptTemplateCategory_constraint"],
		update_columns: Array<ResolverInputTypes["TextPromptTemplateCategory_update_column"]>,
		where?: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "TextPromptTemplateCategory". */
	["TextPromptTemplateCategory_order_by"]: {
		TextPromptTemplates_aggregate?: ResolverInputTypes["TextPromptTemplate_aggregate_order_by"] | undefined | null,
		categoryName?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: TextPromptTemplateCategory */
	["TextPromptTemplateCategory_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_select_column"]: TextPromptTemplateCategory_select_column;
	/** input type for updating data in table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_set_input"]: {
		categoryName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["TextPromptTemplateCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["TextPromptTemplateCategory_stream_cursor_value_input"]: {
		categoryName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_update_column"]: TextPromptTemplateCategory_update_column;
	["TextPromptTemplateCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["TextPromptTemplateCategory_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"]
	};
	/** aggregated selection of "TextPromptTemplate" */
	["TextPromptTemplate_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["TextPromptTemplate_aggregate_fields"],
		nodes?: ResolverInputTypes["TextPromptTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	["TextPromptTemplate_aggregate_bool_exp"]: {
		bool_and?: ResolverInputTypes["TextPromptTemplate_aggregate_bool_exp_bool_and"] | undefined | null,
		bool_or?: ResolverInputTypes["TextPromptTemplate_aggregate_bool_exp_bool_or"] | undefined | null,
		count?: ResolverInputTypes["TextPromptTemplate_aggregate_bool_exp_count"] | undefined | null
	};
	["TextPromptTemplate_aggregate_bool_exp_bool_and"]: {
		arguments: ResolverInputTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["TextPromptTemplate_aggregate_bool_exp_bool_or"]: {
		arguments: ResolverInputTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["TextPromptTemplate_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["TextPromptTemplate_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "TextPromptTemplate" */
	["TextPromptTemplate_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["TextPromptTemplate_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["TextPromptTemplate_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["TextPromptTemplate_max_fields"],
		min?: ResolverInputTypes["TextPromptTemplate_min_fields"],
		stddev?: ResolverInputTypes["TextPromptTemplate_stddev_fields"],
		stddev_pop?: ResolverInputTypes["TextPromptTemplate_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["TextPromptTemplate_stddev_samp_fields"],
		sum?: ResolverInputTypes["TextPromptTemplate_sum_fields"],
		var_pop?: ResolverInputTypes["TextPromptTemplate_var_pop_fields"],
		var_samp?: ResolverInputTypes["TextPromptTemplate_var_samp_fields"],
		variance?: ResolverInputTypes["TextPromptTemplate_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "TextPromptTemplate" */
	["TextPromptTemplate_aggregate_order_by"]: {
		avg?: ResolverInputTypes["TextPromptTemplate_avg_order_by"] | undefined | null,
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["TextPromptTemplate_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["TextPromptTemplate_min_order_by"] | undefined | null,
		stddev?: ResolverInputTypes["TextPromptTemplate_stddev_order_by"] | undefined | null,
		stddev_pop?: ResolverInputTypes["TextPromptTemplate_stddev_pop_order_by"] | undefined | null,
		stddev_samp?: ResolverInputTypes["TextPromptTemplate_stddev_samp_order_by"] | undefined | null,
		sum?: ResolverInputTypes["TextPromptTemplate_sum_order_by"] | undefined | null,
		var_pop?: ResolverInputTypes["TextPromptTemplate_var_pop_order_by"] | undefined | null,
		var_samp?: ResolverInputTypes["TextPromptTemplate_var_samp_order_by"] | undefined | null,
		variance?: ResolverInputTypes["TextPromptTemplate_variance_order_by"] | undefined | null
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextPromptTemplate_append_input"]: {
		definitions?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** input type for inserting array relation for remote table "TextPromptTemplate" */
	["TextPromptTemplate_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["TextPromptTemplate_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["TextPromptTemplate_on_conflict"] | undefined | null
	};
	/** aggregate avg on columns */
	["TextPromptTemplate_avg_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_avg_order_by"]: {
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "TextPromptTemplate". All fields are combined with a logical 'AND'. */
	["TextPromptTemplate_bool_exp"]: {
		GeneratedPromptByTemplates?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null,
		GeneratedPromptByTemplates_aggregate?: ResolverInputTypes["GeneratedPromptByTemplate_aggregate_bool_exp"] | undefined | null,
		TextPromptTemplateCategory?: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null,
		TextTemplateClientCategory?: ResolverInputTypes["TextTemplateClientCategory_bool_exp"] | undefined | null,
		TextTemplateClientCategoryId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		_and?: Array<ResolverInputTypes["TextPromptTemplate_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["TextPromptTemplate_bool_exp"]> | undefined | null,
		availableDisplays?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null,
		availableDisplays_aggregate?: ResolverInputTypes["textTemplateDisplayFormat_aggregate_bool_exp"] | undefined | null,
		categoryId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		channel?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		creditPrice?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		definitions?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		description?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		flexible?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		icon?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		newTemplateText?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["Int_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "TextPromptTemplate" */
	["TextPromptTemplate_constraint"]: TextPromptTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextPromptTemplate_delete_at_path_input"]: {
		definitions?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextPromptTemplate_delete_elem_input"]: {
		definitions?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextPromptTemplate_delete_key_input"]: {
		definitions?: string | undefined | null
	};
	/** input type for incrementing numeric columns in table "TextPromptTemplate" */
	["TextPromptTemplate_inc_input"]: {
		creditPrice?: number | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["numeric"] | undefined | null,
		saleCreditPrice?: number | undefined | null
	};
	/** input type for inserting data into table "TextPromptTemplate" */
	["TextPromptTemplate_insert_input"]: {
		GeneratedPromptByTemplates?: ResolverInputTypes["GeneratedPromptByTemplate_arr_rel_insert_input"] | undefined | null,
		TextPromptTemplateCategory?: ResolverInputTypes["TextPromptTemplateCategory_obj_rel_insert_input"] | undefined | null,
		TextTemplateClientCategory?: ResolverInputTypes["TextTemplateClientCategory_obj_rel_insert_input"] | undefined | null,
		TextTemplateClientCategoryId?: ResolverInputTypes["uuid"] | undefined | null,
		availableDisplays?: ResolverInputTypes["textTemplateDisplayFormat_arr_rel_insert_input"] | undefined | null,
		categoryId?: ResolverInputTypes["uuid"] | undefined | null,
		channel?: string | undefined | null,
		creditPrice?: number | undefined | null,
		definitions?: ResolverInputTypes["jsonb"] | undefined | null,
		description?: string | undefined | null,
		displayDimensionId?: ResolverInputTypes["uuid"] | undefined | null,
		flexible?: boolean | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["numeric"] | undefined | null,
		name?: string | undefined | null,
		newTemplateText?: string | undefined | null,
		saleCreditPrice?: number | undefined | null
	};
	/** aggregate max on columns */
	["TextPromptTemplate_max_fields"]: AliasType<{
		TextTemplateClientCategoryId?: boolean | `@${string}`,
		categoryId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplateText?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_max_order_by"]: {
		TextTemplateClientCategoryId?: ResolverInputTypes["order_by"] | undefined | null,
		categoryId?: ResolverInputTypes["order_by"] | undefined | null,
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["order_by"] | undefined | null,
		icon?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		newTemplateText?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["TextPromptTemplate_min_fields"]: AliasType<{
		TextTemplateClientCategoryId?: boolean | `@${string}`,
		categoryId?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplateText?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_min_order_by"]: {
		TextTemplateClientCategoryId?: ResolverInputTypes["order_by"] | undefined | null,
		categoryId?: ResolverInputTypes["order_by"] | undefined | null,
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["order_by"] | undefined | null,
		icon?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		newTemplateText?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "TextPromptTemplate" */
	["TextPromptTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["TextPromptTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "TextPromptTemplate" */
	["TextPromptTemplate_obj_rel_insert_input"]: {
		data: ResolverInputTypes["TextPromptTemplate_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["TextPromptTemplate_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "TextPromptTemplate" */
	["TextPromptTemplate_on_conflict"]: {
		constraint: ResolverInputTypes["TextPromptTemplate_constraint"],
		update_columns: Array<ResolverInputTypes["TextPromptTemplate_update_column"]>,
		where?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "TextPromptTemplate". */
	["TextPromptTemplate_order_by"]: {
		GeneratedPromptByTemplates_aggregate?: ResolverInputTypes["GeneratedPromptByTemplate_aggregate_order_by"] | undefined | null,
		TextPromptTemplateCategory?: ResolverInputTypes["TextPromptTemplateCategory_order_by"] | undefined | null,
		TextTemplateClientCategory?: ResolverInputTypes["TextTemplateClientCategory_order_by"] | undefined | null,
		TextTemplateClientCategoryId?: ResolverInputTypes["order_by"] | undefined | null,
		availableDisplays_aggregate?: ResolverInputTypes["textTemplateDisplayFormat_aggregate_order_by"] | undefined | null,
		categoryId?: ResolverInputTypes["order_by"] | undefined | null,
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		definitions?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["order_by"] | undefined | null,
		flexible?: ResolverInputTypes["order_by"] | undefined | null,
		icon?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		newTemplateText?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: TextPromptTemplate */
	["TextPromptTemplate_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextPromptTemplate_prepend_input"]: {
		definitions?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column"]: TextPromptTemplate_select_column;
	/** select "TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "TextPromptTemplate" */
	["TextPromptTemplate_set_input"]: {
		TextTemplateClientCategoryId?: ResolverInputTypes["uuid"] | undefined | null,
		categoryId?: ResolverInputTypes["uuid"] | undefined | null,
		channel?: string | undefined | null,
		creditPrice?: number | undefined | null,
		definitions?: ResolverInputTypes["jsonb"] | undefined | null,
		description?: string | undefined | null,
		displayDimensionId?: ResolverInputTypes["uuid"] | undefined | null,
		flexible?: boolean | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["numeric"] | undefined | null,
		name?: string | undefined | null,
		newTemplateText?: string | undefined | null,
		saleCreditPrice?: number | undefined | null
	};
	/** aggregate stddev on columns */
	["TextPromptTemplate_stddev_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_order_by"]: {
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_pop on columns */
	["TextPromptTemplate_stddev_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_pop_order_by"]: {
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_samp on columns */
	["TextPromptTemplate_stddev_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_samp_order_by"]: {
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Streaming cursor of the table "TextPromptTemplate" */
	["TextPromptTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["TextPromptTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["TextPromptTemplate_stream_cursor_value_input"]: {
		TextTemplateClientCategoryId?: ResolverInputTypes["uuid"] | undefined | null,
		categoryId?: ResolverInputTypes["uuid"] | undefined | null,
		channel?: string | undefined | null,
		creditPrice?: number | undefined | null,
		definitions?: ResolverInputTypes["jsonb"] | undefined | null,
		description?: string | undefined | null,
		displayDimensionId?: ResolverInputTypes["uuid"] | undefined | null,
		flexible?: boolean | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["numeric"] | undefined | null,
		name?: string | undefined | null,
		newTemplateText?: string | undefined | null,
		saleCreditPrice?: number | undefined | null
	};
	/** aggregate sum on columns */
	["TextPromptTemplate_sum_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_sum_order_by"]: {
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** update columns of table "TextPromptTemplate" */
	["TextPromptTemplate_update_column"]: TextPromptTemplate_update_column;
	["TextPromptTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["TextPromptTemplate_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["TextPromptTemplate_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["TextPromptTemplate_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["TextPromptTemplate_delete_key_input"] | undefined | null,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["TextPromptTemplate_inc_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["TextPromptTemplate_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["TextPromptTemplate_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["TextPromptTemplate_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["TextPromptTemplate_var_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_var_pop_order_by"]: {
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate var_samp on columns */
	["TextPromptTemplate_var_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_var_samp_order_by"]: {
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate variance on columns */
	["TextPromptTemplate_variance_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		marketPriceInCredits?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_variance_order_by"]: {
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		marketPriceInCredits?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** columns and relationships of "TextTemplateClientCategory" */
	["TextTemplateClientCategory"]: AliasType<{
		displayName?: boolean | `@${string}`,
		iconClass?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "TextTemplateClientCategory" */
	["TextTemplateClientCategory_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["TextTemplateClientCategory_aggregate_fields"],
		nodes?: ResolverInputTypes["TextTemplateClientCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "TextTemplateClientCategory" */
	["TextTemplateClientCategory_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["TextTemplateClientCategory_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["TextTemplateClientCategory_max_fields"],
		min?: ResolverInputTypes["TextTemplateClientCategory_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "TextTemplateClientCategory". All fields are combined with a logical 'AND'. */
	["TextTemplateClientCategory_bool_exp"]: {
		_and?: Array<ResolverInputTypes["TextTemplateClientCategory_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["TextTemplateClientCategory_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["TextTemplateClientCategory_bool_exp"]> | undefined | null,
		displayName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		iconClass?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_constraint"]: TextTemplateClientCategory_constraint;
	/** input type for inserting data into table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_insert_input"]: {
		displayName?: string | undefined | null,
		iconClass?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["TextTemplateClientCategory_max_fields"]: AliasType<{
		displayName?: boolean | `@${string}`,
		iconClass?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["TextTemplateClientCategory_min_fields"]: AliasType<{
		displayName?: boolean | `@${string}`,
		iconClass?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["TextTemplateClientCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_obj_rel_insert_input"]: {
		data: ResolverInputTypes["TextTemplateClientCategory_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["TextTemplateClientCategory_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_on_conflict"]: {
		constraint: ResolverInputTypes["TextTemplateClientCategory_constraint"],
		update_columns: Array<ResolverInputTypes["TextTemplateClientCategory_update_column"]>,
		where?: ResolverInputTypes["TextTemplateClientCategory_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "TextTemplateClientCategory". */
	["TextTemplateClientCategory_order_by"]: {
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		iconClass?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: TextTemplateClientCategory */
	["TextTemplateClientCategory_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_select_column"]: TextTemplateClientCategory_select_column;
	/** input type for updating data in table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_set_input"]: {
		displayName?: string | undefined | null,
		iconClass?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["TextTemplateClientCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["TextTemplateClientCategory_stream_cursor_value_input"]: {
		displayName?: string | undefined | null,
		iconClass?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_update_column"]: TextTemplateClientCategory_update_column;
	["TextTemplateClientCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["TextTemplateClientCategory_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["TextTemplateClientCategory_bool_exp"]
	};
	["UpdateUserAvatarOutput"]: AliasType<{
		message?: boolean | `@${string}`,
		photoURL?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["UpsertAudienceOutput"]: AliasType<{
		brandName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		personas?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		targetAudience?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "audiences" */
	["audiences"]: AliasType<{
		brandId?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		personas?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		targetAudience?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "audiences" */
	["audiences_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["audiences_aggregate_fields"],
		nodes?: ResolverInputTypes["audiences"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "audiences" */
	["audiences_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["audiences_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["audiences_max_fields"],
		min?: ResolverInputTypes["audiences_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["audiences_append_input"]: {
		personas?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "audiences". All fields are combined with a logical 'AND'. */
	["audiences_bool_exp"]: {
		_and?: Array<ResolverInputTypes["audiences_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["audiences_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["audiences_bool_exp"]> | undefined | null,
		brandId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		brandName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamp_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		deleted?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		personas?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		productOrService?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		segment?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		targetAudience?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "audiences" */
	["audiences_constraint"]: audiences_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["audiences_delete_at_path_input"]: {
		personas?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["audiences_delete_elem_input"]: {
		personas?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["audiences_delete_key_input"]: {
		personas?: string | undefined | null
	};
	/** input type for inserting data into table "audiences" */
	["audiences_insert_input"]: {
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		brandName?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		personas?: ResolverInputTypes["jsonb"] | undefined | null,
		productOrService?: string | undefined | null,
		segment?: string | undefined | null,
		targetAudience?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["audiences_max_fields"]: AliasType<{
		brandId?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		targetAudience?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["audiences_min_fields"]: AliasType<{
		brandId?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		targetAudience?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "audiences" */
	["audiences_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["audiences"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "audiences" */
	["audiences_on_conflict"]: {
		constraint: ResolverInputTypes["audiences_constraint"],
		update_columns: Array<ResolverInputTypes["audiences_update_column"]>,
		where?: ResolverInputTypes["audiences_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "audiences". */
	["audiences_order_by"]: {
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		brandName?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		deleted?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		personas?: ResolverInputTypes["order_by"] | undefined | null,
		productOrService?: ResolverInputTypes["order_by"] | undefined | null,
		segment?: ResolverInputTypes["order_by"] | undefined | null,
		targetAudience?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: audiences */
	["audiences_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["audiences_prepend_input"]: {
		personas?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "audiences" */
	["audiences_select_column"]: audiences_select_column;
	/** input type for updating data in table "audiences" */
	["audiences_set_input"]: {
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		brandName?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		personas?: ResolverInputTypes["jsonb"] | undefined | null,
		productOrService?: string | undefined | null,
		segment?: string | undefined | null,
		targetAudience?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "audiences" */
	["audiences_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["audiences_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["audiences_stream_cursor_value_input"]: {
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		brandName?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		personas?: ResolverInputTypes["jsonb"] | undefined | null,
		productOrService?: string | undefined | null,
		segment?: string | undefined | null,
		targetAudience?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "audiences" */
	["audiences_update_column"]: audiences_update_column;
	["audiences_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["audiences_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["audiences_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["audiences_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["audiences_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["audiences_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["audiences_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["audiences_bool_exp"]
	};
	/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authProviderRequests"]: AliasType<{
		id?: boolean | `@${string}`,
		options?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.provider_requests" */
	["authProviderRequests_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["authProviderRequests_aggregate_fields"],
		nodes?: ResolverInputTypes["authProviderRequests"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "auth.provider_requests" */
	["authProviderRequests_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["authProviderRequests_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["authProviderRequests_max_fields"],
		min?: ResolverInputTypes["authProviderRequests_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["authProviderRequests_append_input"]: {
		options?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
	["authProviderRequests_bool_exp"]: {
		_and?: Array<ResolverInputTypes["authProviderRequests_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["authProviderRequests_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["authProviderRequests_bool_exp"]> | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		options?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.provider_requests" */
	["authProviderRequests_constraint"]: authProviderRequests_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["authProviderRequests_delete_at_path_input"]: {
		options?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["authProviderRequests_delete_elem_input"]: {
		options?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["authProviderRequests_delete_key_input"]: {
		options?: string | undefined | null
	};
	/** input type for inserting data into table "auth.provider_requests" */
	["authProviderRequests_insert_input"]: {
		id?: ResolverInputTypes["uuid"] | undefined | null,
		options?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** aggregate max on columns */
	["authProviderRequests_max_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["authProviderRequests_min_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "auth.provider_requests" */
	["authProviderRequests_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["authProviderRequests"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.provider_requests" */
	["authProviderRequests_on_conflict"]: {
		constraint: ResolverInputTypes["authProviderRequests_constraint"],
		update_columns: Array<ResolverInputTypes["authProviderRequests_update_column"]>,
		where?: ResolverInputTypes["authProviderRequests_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.provider_requests". */
	["authProviderRequests_order_by"]: {
		id?: ResolverInputTypes["order_by"] | undefined | null,
		options?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.provider_requests */
	["authProviderRequests_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["authProviderRequests_prepend_input"]: {
		options?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "auth.provider_requests" */
	["authProviderRequests_select_column"]: authProviderRequests_select_column;
	/** input type for updating data in table "auth.provider_requests" */
	["authProviderRequests_set_input"]: {
		id?: ResolverInputTypes["uuid"] | undefined | null,
		options?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Streaming cursor of the table "authProviderRequests" */
	["authProviderRequests_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["authProviderRequests_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["authProviderRequests_stream_cursor_value_input"]: {
		id?: ResolverInputTypes["uuid"] | undefined | null,
		options?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** update columns of table "auth.provider_requests" */
	["authProviderRequests_update_column"]: authProviderRequests_update_column;
	["authProviderRequests_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["authProviderRequests_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["authProviderRequests_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["authProviderRequests_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["authProviderRequests_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["authProviderRequests_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["authProviderRequests_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["authProviderRequests_bool_exp"]
	};
	/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authProviders"]: AliasType<{
		id?: boolean | `@${string}`,
		userProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders"]],
		userProviders_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders_aggregate"]],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.providers" */
	["authProviders_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["authProviders_aggregate_fields"],
		nodes?: ResolverInputTypes["authProviders"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "auth.providers" */
	["authProviders_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["authProviders_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["authProviders_max_fields"],
		min?: ResolverInputTypes["authProviders_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
	["authProviders_bool_exp"]: {
		_and?: Array<ResolverInputTypes["authProviders_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["authProviders_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["authProviders_bool_exp"]> | undefined | null,
		id?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		userProviders?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null,
		userProviders_aggregate?: ResolverInputTypes["authUserProviders_aggregate_bool_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.providers" */
	["authProviders_constraint"]: authProviders_constraint;
	/** input type for inserting data into table "auth.providers" */
	["authProviders_insert_input"]: {
		id?: string | undefined | null,
		userProviders?: ResolverInputTypes["authUserProviders_arr_rel_insert_input"] | undefined | null
	};
	/** aggregate max on columns */
	["authProviders_max_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["authProviders_min_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "auth.providers" */
	["authProviders_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["authProviders"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "auth.providers" */
	["authProviders_obj_rel_insert_input"]: {
		data: ResolverInputTypes["authProviders_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["authProviders_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "auth.providers" */
	["authProviders_on_conflict"]: {
		constraint: ResolverInputTypes["authProviders_constraint"],
		update_columns: Array<ResolverInputTypes["authProviders_update_column"]>,
		where?: ResolverInputTypes["authProviders_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.providers". */
	["authProviders_order_by"]: {
		id?: ResolverInputTypes["order_by"] | undefined | null,
		userProviders_aggregate?: ResolverInputTypes["authUserProviders_aggregate_order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.providers */
	["authProviders_pk_columns_input"]: {
		id: string
	};
	/** select columns of table "auth.providers" */
	["authProviders_select_column"]: authProviders_select_column;
	/** input type for updating data in table "auth.providers" */
	["authProviders_set_input"]: {
		id?: string | undefined | null
	};
	/** Streaming cursor of the table "authProviders" */
	["authProviders_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["authProviders_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["authProviders_stream_cursor_value_input"]: {
		id?: string | undefined | null
	};
	/** update columns of table "auth.providers" */
	["authProviders_update_column"]: authProviders_update_column;
	["authProviders_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["authProviders_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["authProviders_bool_exp"]
	};
	/** columns and relationships of "auth.refresh_token_types" */
	["authRefreshTokenTypes"]: AliasType<{
		comment?: boolean | `@${string}`,
		refreshTokens?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokens_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens"]],
		refreshTokens_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokens_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens_aggregate"]],
		value?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.refresh_token_types" */
	["authRefreshTokenTypes_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["authRefreshTokenTypes_aggregate_fields"],
		nodes?: ResolverInputTypes["authRefreshTokenTypes"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "auth.refresh_token_types" */
	["authRefreshTokenTypes_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["authRefreshTokenTypes_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["authRefreshTokenTypes_max_fields"],
		min?: ResolverInputTypes["authRefreshTokenTypes_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
	["authRefreshTokenTypes_bool_exp"]: {
		_and?: Array<ResolverInputTypes["authRefreshTokenTypes_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["authRefreshTokenTypes_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["authRefreshTokenTypes_bool_exp"]> | undefined | null,
		comment?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		refreshTokens?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null,
		refreshTokens_aggregate?: ResolverInputTypes["authRefreshTokens_aggregate_bool_exp"] | undefined | null,
		value?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.refresh_token_types" */
	["authRefreshTokenTypes_constraint"]: authRefreshTokenTypes_constraint;
	["authRefreshTokenTypes_enum"]: authRefreshTokenTypes_enum;
	/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
	["authRefreshTokenTypes_enum_comparison_exp"]: {
		_eq?: ResolverInputTypes["authRefreshTokenTypes_enum"] | undefined | null,
		_in?: Array<ResolverInputTypes["authRefreshTokenTypes_enum"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_neq?: ResolverInputTypes["authRefreshTokenTypes_enum"] | undefined | null,
		_nin?: Array<ResolverInputTypes["authRefreshTokenTypes_enum"]> | undefined | null
	};
	/** input type for inserting data into table "auth.refresh_token_types" */
	["authRefreshTokenTypes_insert_input"]: {
		comment?: string | undefined | null,
		refreshTokens?: ResolverInputTypes["authRefreshTokens_arr_rel_insert_input"] | undefined | null,
		value?: string | undefined | null
	};
	/** aggregate max on columns */
	["authRefreshTokenTypes_max_fields"]: AliasType<{
		comment?: boolean | `@${string}`,
		value?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["authRefreshTokenTypes_min_fields"]: AliasType<{
		comment?: boolean | `@${string}`,
		value?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "auth.refresh_token_types" */
	["authRefreshTokenTypes_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["authRefreshTokenTypes"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.refresh_token_types" */
	["authRefreshTokenTypes_on_conflict"]: {
		constraint: ResolverInputTypes["authRefreshTokenTypes_constraint"],
		update_columns: Array<ResolverInputTypes["authRefreshTokenTypes_update_column"]>,
		where?: ResolverInputTypes["authRefreshTokenTypes_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.refresh_token_types". */
	["authRefreshTokenTypes_order_by"]: {
		comment?: ResolverInputTypes["order_by"] | undefined | null,
		refreshTokens_aggregate?: ResolverInputTypes["authRefreshTokens_aggregate_order_by"] | undefined | null,
		value?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.refresh_token_types */
	["authRefreshTokenTypes_pk_columns_input"]: {
		value: string
	};
	/** select columns of table "auth.refresh_token_types" */
	["authRefreshTokenTypes_select_column"]: authRefreshTokenTypes_select_column;
	/** input type for updating data in table "auth.refresh_token_types" */
	["authRefreshTokenTypes_set_input"]: {
		comment?: string | undefined | null,
		value?: string | undefined | null
	};
	/** Streaming cursor of the table "authRefreshTokenTypes" */
	["authRefreshTokenTypes_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["authRefreshTokenTypes_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["authRefreshTokenTypes_stream_cursor_value_input"]: {
		comment?: string | undefined | null,
		value?: string | undefined | null
	};
	/** update columns of table "auth.refresh_token_types" */
	["authRefreshTokenTypes_update_column"]: authRefreshTokenTypes_update_column;
	["authRefreshTokenTypes_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["authRefreshTokenTypes_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["authRefreshTokenTypes_bool_exp"]
	};
	/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authRefreshTokens"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		expiresAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		metadata?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		refreshTokenHash?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		/** An object relationship */
		user?: ResolverInputTypes["users"],
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.refresh_tokens" */
	["authRefreshTokens_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["authRefreshTokens_aggregate_fields"],
		nodes?: ResolverInputTypes["authRefreshTokens"],
		__typename?: boolean | `@${string}`
	}>;
	["authRefreshTokens_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["authRefreshTokens_aggregate_bool_exp_count"] | undefined | null
	};
	["authRefreshTokens_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.refresh_tokens" */
	["authRefreshTokens_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["authRefreshTokens_max_fields"],
		min?: ResolverInputTypes["authRefreshTokens_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.refresh_tokens" */
	["authRefreshTokens_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["authRefreshTokens_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["authRefreshTokens_min_order_by"] | undefined | null
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["authRefreshTokens_append_input"]: {
		metadata?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** input type for inserting array relation for remote table "auth.refresh_tokens" */
	["authRefreshTokens_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["authRefreshTokens_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["authRefreshTokens_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
	["authRefreshTokens_bool_exp"]: {
		_and?: Array<ResolverInputTypes["authRefreshTokens_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["authRefreshTokens_bool_exp"]> | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		expiresAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		metadata?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		refreshTokenHash?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		type?: ResolverInputTypes["authRefreshTokenTypes_enum_comparison_exp"] | undefined | null,
		user?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		userId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.refresh_tokens" */
	["authRefreshTokens_constraint"]: authRefreshTokens_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["authRefreshTokens_delete_at_path_input"]: {
		metadata?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["authRefreshTokens_delete_elem_input"]: {
		metadata?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["authRefreshTokens_delete_key_input"]: {
		metadata?: string | undefined | null
	};
	/** input type for inserting data into table "auth.refresh_tokens" */
	["authRefreshTokens_insert_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		expiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		refreshTokenHash?: string | undefined | null,
		type?: ResolverInputTypes["authRefreshTokenTypes_enum"] | undefined | null,
		user?: ResolverInputTypes["users_obj_rel_insert_input"] | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["authRefreshTokens_max_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		expiresAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		refreshTokenHash?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.refresh_tokens" */
	["authRefreshTokens_max_order_by"]: {
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		expiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		refreshTokenHash?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["authRefreshTokens_min_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		expiresAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		refreshTokenHash?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.refresh_tokens" */
	["authRefreshTokens_min_order_by"]: {
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		expiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		refreshTokenHash?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "auth.refresh_tokens" */
	["authRefreshTokens_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["authRefreshTokens"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.refresh_tokens" */
	["authRefreshTokens_on_conflict"]: {
		constraint: ResolverInputTypes["authRefreshTokens_constraint"],
		update_columns: Array<ResolverInputTypes["authRefreshTokens_update_column"]>,
		where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.refresh_tokens". */
	["authRefreshTokens_order_by"]: {
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		expiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		metadata?: ResolverInputTypes["order_by"] | undefined | null,
		refreshTokenHash?: ResolverInputTypes["order_by"] | undefined | null,
		type?: ResolverInputTypes["order_by"] | undefined | null,
		user?: ResolverInputTypes["users_order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.refresh_tokens */
	["authRefreshTokens_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["authRefreshTokens_prepend_input"]: {
		metadata?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "auth.refresh_tokens" */
	["authRefreshTokens_select_column"]: authRefreshTokens_select_column;
	/** input type for updating data in table "auth.refresh_tokens" */
	["authRefreshTokens_set_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		expiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		refreshTokenHash?: string | undefined | null,
		type?: ResolverInputTypes["authRefreshTokenTypes_enum"] | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "authRefreshTokens" */
	["authRefreshTokens_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["authRefreshTokens_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["authRefreshTokens_stream_cursor_value_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		expiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		refreshTokenHash?: string | undefined | null,
		type?: ResolverInputTypes["authRefreshTokenTypes_enum"] | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "auth.refresh_tokens" */
	["authRefreshTokens_update_column"]: authRefreshTokens_update_column;
	["authRefreshTokens_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["authRefreshTokens_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["authRefreshTokens_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["authRefreshTokens_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["authRefreshTokens_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["authRefreshTokens_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["authRefreshTokens_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["authRefreshTokens_bool_exp"]
	};
	/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authRoles"]: AliasType<{
		role?: boolean | `@${string}`,
		userRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles"]],
		userRoles_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles_aggregate"]],
		usersByDefaultRole?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["users_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["users_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["users_bool_exp"] | undefined | null
		}, ResolverInputTypes["users"]],
		usersByDefaultRole_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["users_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["users_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["users_bool_exp"] | undefined | null
		}, ResolverInputTypes["users_aggregate"]],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.roles" */
	["authRoles_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["authRoles_aggregate_fields"],
		nodes?: ResolverInputTypes["authRoles"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "auth.roles" */
	["authRoles_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["authRoles_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["authRoles_max_fields"],
		min?: ResolverInputTypes["authRoles_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
	["authRoles_bool_exp"]: {
		_and?: Array<ResolverInputTypes["authRoles_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["authRoles_bool_exp"]> | undefined | null,
		role?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		userRoles?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null,
		userRoles_aggregate?: ResolverInputTypes["authUserRoles_aggregate_bool_exp"] | undefined | null,
		usersByDefaultRole?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		usersByDefaultRole_aggregate?: ResolverInputTypes["users_aggregate_bool_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.roles" */
	["authRoles_constraint"]: authRoles_constraint;
	/** input type for inserting data into table "auth.roles" */
	["authRoles_insert_input"]: {
		role?: string | undefined | null,
		userRoles?: ResolverInputTypes["authUserRoles_arr_rel_insert_input"] | undefined | null,
		usersByDefaultRole?: ResolverInputTypes["users_arr_rel_insert_input"] | undefined | null
	};
	/** aggregate max on columns */
	["authRoles_max_fields"]: AliasType<{
		role?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["authRoles_min_fields"]: AliasType<{
		role?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "auth.roles" */
	["authRoles_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["authRoles"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "auth.roles" */
	["authRoles_obj_rel_insert_input"]: {
		data: ResolverInputTypes["authRoles_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["authRoles_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "auth.roles" */
	["authRoles_on_conflict"]: {
		constraint: ResolverInputTypes["authRoles_constraint"],
		update_columns: Array<ResolverInputTypes["authRoles_update_column"]>,
		where?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.roles". */
	["authRoles_order_by"]: {
		role?: ResolverInputTypes["order_by"] | undefined | null,
		userRoles_aggregate?: ResolverInputTypes["authUserRoles_aggregate_order_by"] | undefined | null,
		usersByDefaultRole_aggregate?: ResolverInputTypes["users_aggregate_order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.roles */
	["authRoles_pk_columns_input"]: {
		role: string
	};
	/** select columns of table "auth.roles" */
	["authRoles_select_column"]: authRoles_select_column;
	/** input type for updating data in table "auth.roles" */
	["authRoles_set_input"]: {
		role?: string | undefined | null
	};
	/** Streaming cursor of the table "authRoles" */
	["authRoles_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["authRoles_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["authRoles_stream_cursor_value_input"]: {
		role?: string | undefined | null
	};
	/** update columns of table "auth.roles" */
	["authRoles_update_column"]: authRoles_update_column;
	["authRoles_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["authRoles_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["authRoles_bool_exp"]
	};
	/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserProviders"]: AliasType<{
		accessToken?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		/** An object relationship */
		provider?: ResolverInputTypes["authProviders"],
		providerId?: boolean | `@${string}`,
		providerUserId?: boolean | `@${string}`,
		refreshToken?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		/** An object relationship */
		user?: ResolverInputTypes["users"],
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.user_providers" */
	["authUserProviders_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["authUserProviders_aggregate_fields"],
		nodes?: ResolverInputTypes["authUserProviders"],
		__typename?: boolean | `@${string}`
	}>;
	["authUserProviders_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["authUserProviders_aggregate_bool_exp_count"] | undefined | null
	};
	["authUserProviders_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_providers" */
	["authUserProviders_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["authUserProviders_max_fields"],
		min?: ResolverInputTypes["authUserProviders_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.user_providers" */
	["authUserProviders_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["authUserProviders_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["authUserProviders_min_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "auth.user_providers" */
	["authUserProviders_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["authUserProviders_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["authUserProviders_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
	["authUserProviders_bool_exp"]: {
		_and?: Array<ResolverInputTypes["authUserProviders_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["authUserProviders_bool_exp"]> | undefined | null,
		accessToken?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		provider?: ResolverInputTypes["authProviders_bool_exp"] | undefined | null,
		providerId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		providerUserId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		refreshToken?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		user?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		userId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.user_providers" */
	["authUserProviders_constraint"]: authUserProviders_constraint;
	/** input type for inserting data into table "auth.user_providers" */
	["authUserProviders_insert_input"]: {
		accessToken?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		provider?: ResolverInputTypes["authProviders_obj_rel_insert_input"] | undefined | null,
		providerId?: string | undefined | null,
		providerUserId?: string | undefined | null,
		refreshToken?: string | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		user?: ResolverInputTypes["users_obj_rel_insert_input"] | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["authUserProviders_max_fields"]: AliasType<{
		accessToken?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		providerId?: boolean | `@${string}`,
		providerUserId?: boolean | `@${string}`,
		refreshToken?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.user_providers" */
	["authUserProviders_max_order_by"]: {
		accessToken?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		providerId?: ResolverInputTypes["order_by"] | undefined | null,
		providerUserId?: ResolverInputTypes["order_by"] | undefined | null,
		refreshToken?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["authUserProviders_min_fields"]: AliasType<{
		accessToken?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		providerId?: boolean | `@${string}`,
		providerUserId?: boolean | `@${string}`,
		refreshToken?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.user_providers" */
	["authUserProviders_min_order_by"]: {
		accessToken?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		providerId?: ResolverInputTypes["order_by"] | undefined | null,
		providerUserId?: ResolverInputTypes["order_by"] | undefined | null,
		refreshToken?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "auth.user_providers" */
	["authUserProviders_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["authUserProviders"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.user_providers" */
	["authUserProviders_on_conflict"]: {
		constraint: ResolverInputTypes["authUserProviders_constraint"],
		update_columns: Array<ResolverInputTypes["authUserProviders_update_column"]>,
		where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.user_providers". */
	["authUserProviders_order_by"]: {
		accessToken?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		provider?: ResolverInputTypes["authProviders_order_by"] | undefined | null,
		providerId?: ResolverInputTypes["order_by"] | undefined | null,
		providerUserId?: ResolverInputTypes["order_by"] | undefined | null,
		refreshToken?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null,
		user?: ResolverInputTypes["users_order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.user_providers */
	["authUserProviders_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "auth.user_providers" */
	["authUserProviders_select_column"]: authUserProviders_select_column;
	/** input type for updating data in table "auth.user_providers" */
	["authUserProviders_set_input"]: {
		accessToken?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		providerId?: string | undefined | null,
		providerUserId?: string | undefined | null,
		refreshToken?: string | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "authUserProviders" */
	["authUserProviders_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["authUserProviders_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["authUserProviders_stream_cursor_value_input"]: {
		accessToken?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		providerId?: string | undefined | null,
		providerUserId?: string | undefined | null,
		refreshToken?: string | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "auth.user_providers" */
	["authUserProviders_update_column"]: authUserProviders_update_column;
	["authUserProviders_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["authUserProviders_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["authUserProviders_bool_exp"]
	};
	/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserRoles"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		role?: boolean | `@${string}`,
		/** An object relationship */
		roleByRole?: ResolverInputTypes["authRoles"],
		/** An object relationship */
		user?: ResolverInputTypes["users"],
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.user_roles" */
	["authUserRoles_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["authUserRoles_aggregate_fields"],
		nodes?: ResolverInputTypes["authUserRoles"],
		__typename?: boolean | `@${string}`
	}>;
	["authUserRoles_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["authUserRoles_aggregate_bool_exp_count"] | undefined | null
	};
	["authUserRoles_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_roles" */
	["authUserRoles_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["authUserRoles_max_fields"],
		min?: ResolverInputTypes["authUserRoles_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.user_roles" */
	["authUserRoles_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["authUserRoles_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["authUserRoles_min_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "auth.user_roles" */
	["authUserRoles_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["authUserRoles_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["authUserRoles_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
	["authUserRoles_bool_exp"]: {
		_and?: Array<ResolverInputTypes["authUserRoles_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["authUserRoles_bool_exp"]> | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		role?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		roleByRole?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null,
		user?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		userId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.user_roles" */
	["authUserRoles_constraint"]: authUserRoles_constraint;
	/** input type for inserting data into table "auth.user_roles" */
	["authUserRoles_insert_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		role?: string | undefined | null,
		roleByRole?: ResolverInputTypes["authRoles_obj_rel_insert_input"] | undefined | null,
		user?: ResolverInputTypes["users_obj_rel_insert_input"] | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["authUserRoles_max_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		role?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.user_roles" */
	["authUserRoles_max_order_by"]: {
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		role?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["authUserRoles_min_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		role?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.user_roles" */
	["authUserRoles_min_order_by"]: {
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		role?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "auth.user_roles" */
	["authUserRoles_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["authUserRoles"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.user_roles" */
	["authUserRoles_on_conflict"]: {
		constraint: ResolverInputTypes["authUserRoles_constraint"],
		update_columns: Array<ResolverInputTypes["authUserRoles_update_column"]>,
		where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.user_roles". */
	["authUserRoles_order_by"]: {
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		role?: ResolverInputTypes["order_by"] | undefined | null,
		roleByRole?: ResolverInputTypes["authRoles_order_by"] | undefined | null,
		user?: ResolverInputTypes["users_order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.user_roles */
	["authUserRoles_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "auth.user_roles" */
	["authUserRoles_select_column"]: authUserRoles_select_column;
	/** input type for updating data in table "auth.user_roles" */
	["authUserRoles_set_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		role?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "authUserRoles" */
	["authUserRoles_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["authUserRoles_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["authUserRoles_stream_cursor_value_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		role?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "auth.user_roles" */
	["authUserRoles_update_column"]: authUserRoles_update_column;
	["authUserRoles_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["authUserRoles_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["authUserRoles_bool_exp"]
	};
	/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserSecurityKeys"]: AliasType<{
		counter?: boolean | `@${string}`,
		credentialId?: boolean | `@${string}`,
		credentialPublicKey?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		nickname?: boolean | `@${string}`,
		transports?: boolean | `@${string}`,
		/** An object relationship */
		user?: ResolverInputTypes["users"],
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["authUserSecurityKeys_aggregate_fields"],
		nodes?: ResolverInputTypes["authUserSecurityKeys"],
		__typename?: boolean | `@${string}`
	}>;
	["authUserSecurityKeys_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["authUserSecurityKeys_aggregate_bool_exp_count"] | undefined | null
	};
	["authUserSecurityKeys_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["authUserSecurityKeys_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["authUserSecurityKeys_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["authUserSecurityKeys_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["authUserSecurityKeys_max_fields"],
		min?: ResolverInputTypes["authUserSecurityKeys_min_fields"],
		stddev?: ResolverInputTypes["authUserSecurityKeys_stddev_fields"],
		stddev_pop?: ResolverInputTypes["authUserSecurityKeys_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["authUserSecurityKeys_stddev_samp_fields"],
		sum?: ResolverInputTypes["authUserSecurityKeys_sum_fields"],
		var_pop?: ResolverInputTypes["authUserSecurityKeys_var_pop_fields"],
		var_samp?: ResolverInputTypes["authUserSecurityKeys_var_samp_fields"],
		variance?: ResolverInputTypes["authUserSecurityKeys_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate_order_by"]: {
		avg?: ResolverInputTypes["authUserSecurityKeys_avg_order_by"] | undefined | null,
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["authUserSecurityKeys_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["authUserSecurityKeys_min_order_by"] | undefined | null,
		stddev?: ResolverInputTypes["authUserSecurityKeys_stddev_order_by"] | undefined | null,
		stddev_pop?: ResolverInputTypes["authUserSecurityKeys_stddev_pop_order_by"] | undefined | null,
		stddev_samp?: ResolverInputTypes["authUserSecurityKeys_stddev_samp_order_by"] | undefined | null,
		sum?: ResolverInputTypes["authUserSecurityKeys_sum_order_by"] | undefined | null,
		var_pop?: ResolverInputTypes["authUserSecurityKeys_var_pop_order_by"] | undefined | null,
		var_samp?: ResolverInputTypes["authUserSecurityKeys_var_samp_order_by"] | undefined | null,
		variance?: ResolverInputTypes["authUserSecurityKeys_variance_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "auth.user_security_keys" */
	["authUserSecurityKeys_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["authUserSecurityKeys_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["authUserSecurityKeys_on_conflict"] | undefined | null
	};
	/** aggregate avg on columns */
	["authUserSecurityKeys_avg_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_avg_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
	["authUserSecurityKeys_bool_exp"]: {
		_and?: Array<ResolverInputTypes["authUserSecurityKeys_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["authUserSecurityKeys_bool_exp"]> | undefined | null,
		counter?: ResolverInputTypes["bigint_comparison_exp"] | undefined | null,
		credentialId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		credentialPublicKey?: ResolverInputTypes["bytea_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		nickname?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		transports?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		user?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		userId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.user_security_keys" */
	["authUserSecurityKeys_constraint"]: authUserSecurityKeys_constraint;
	/** input type for incrementing numeric columns in table "auth.user_security_keys" */
	["authUserSecurityKeys_inc_input"]: {
		counter?: ResolverInputTypes["bigint"] | undefined | null
	};
	/** input type for inserting data into table "auth.user_security_keys" */
	["authUserSecurityKeys_insert_input"]: {
		counter?: ResolverInputTypes["bigint"] | undefined | null,
		credentialId?: string | undefined | null,
		credentialPublicKey?: ResolverInputTypes["bytea"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		nickname?: string | undefined | null,
		transports?: string | undefined | null,
		user?: ResolverInputTypes["users_obj_rel_insert_input"] | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["authUserSecurityKeys_max_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		credentialId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		nickname?: boolean | `@${string}`,
		transports?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_max_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null,
		credentialId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		nickname?: ResolverInputTypes["order_by"] | undefined | null,
		transports?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["authUserSecurityKeys_min_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		credentialId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		nickname?: boolean | `@${string}`,
		transports?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_min_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null,
		credentialId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		nickname?: ResolverInputTypes["order_by"] | undefined | null,
		transports?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "auth.user_security_keys" */
	["authUserSecurityKeys_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["authUserSecurityKeys"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "auth.user_security_keys" */
	["authUserSecurityKeys_on_conflict"]: {
		constraint: ResolverInputTypes["authUserSecurityKeys_constraint"],
		update_columns: Array<ResolverInputTypes["authUserSecurityKeys_update_column"]>,
		where?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.user_security_keys". */
	["authUserSecurityKeys_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null,
		credentialId?: ResolverInputTypes["order_by"] | undefined | null,
		credentialPublicKey?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		nickname?: ResolverInputTypes["order_by"] | undefined | null,
		transports?: ResolverInputTypes["order_by"] | undefined | null,
		user?: ResolverInputTypes["users_order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.user_security_keys */
	["authUserSecurityKeys_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_select_column"]: authUserSecurityKeys_select_column;
	/** input type for updating data in table "auth.user_security_keys" */
	["authUserSecurityKeys_set_input"]: {
		counter?: ResolverInputTypes["bigint"] | undefined | null,
		credentialId?: string | undefined | null,
		credentialPublicKey?: ResolverInputTypes["bytea"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		nickname?: string | undefined | null,
		transports?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate stddev on columns */
	["authUserSecurityKeys_stddev_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_pop on columns */
	["authUserSecurityKeys_stddev_pop_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_pop_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_samp on columns */
	["authUserSecurityKeys_stddev_samp_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_samp_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Streaming cursor of the table "authUserSecurityKeys" */
	["authUserSecurityKeys_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["authUserSecurityKeys_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["authUserSecurityKeys_stream_cursor_value_input"]: {
		counter?: ResolverInputTypes["bigint"] | undefined | null,
		credentialId?: string | undefined | null,
		credentialPublicKey?: ResolverInputTypes["bytea"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		nickname?: string | undefined | null,
		transports?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate sum on columns */
	["authUserSecurityKeys_sum_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_sum_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** update columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_update_column"]: authUserSecurityKeys_update_column;
	["authUserSecurityKeys_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["authUserSecurityKeys_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["authUserSecurityKeys_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["authUserSecurityKeys_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["authUserSecurityKeys_var_pop_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_var_pop_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate var_samp on columns */
	["authUserSecurityKeys_var_samp_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_var_samp_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate variance on columns */
	["authUserSecurityKeys_variance_fields"]: AliasType<{
		counter?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_variance_order_by"]: {
		counter?: ResolverInputTypes["order_by"] | undefined | null
	};
	["bigint"]: unknown;
	/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
	["bigint_comparison_exp"]: {
		_eq?: ResolverInputTypes["bigint"] | undefined | null,
		_gt?: ResolverInputTypes["bigint"] | undefined | null,
		_gte?: ResolverInputTypes["bigint"] | undefined | null,
		_in?: Array<ResolverInputTypes["bigint"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: ResolverInputTypes["bigint"] | undefined | null,
		_lte?: ResolverInputTypes["bigint"] | undefined | null,
		_neq?: ResolverInputTypes["bigint"] | undefined | null,
		_nin?: Array<ResolverInputTypes["bigint"]> | undefined | null
	};
	/** columns and relationships of "blog" */
	["blog"]: AliasType<{
		/** An object relationship */
		brand?: ResolverInputTypes["brands"],
		brandId?: boolean | `@${string}`,
		/** An object relationship */
		category?: ResolverInputTypes["blogCategory"],
		categoryId?: boolean | `@${string}`,
		content?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		featuredImageId?: boolean | `@${string}`,
		/** An object relationship */
		file?: ResolverInputTypes["files"],
		id?: boolean | `@${string}`,
		metaDescription?: boolean | `@${string}`,
		/** An object relationship */
		project?: ResolverInputTypes["project"],
		projectId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		slug?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		/** An object relationship */
		user?: ResolverInputTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "blogCategory" */
	["blogCategory"]: AliasType<{
		blogs?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blog_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog"]],
		blogs_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blog_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog_aggregate"]],
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "blogCategory" */
	["blogCategory_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["blogCategory_aggregate_fields"],
		nodes?: ResolverInputTypes["blogCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "blogCategory" */
	["blogCategory_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["blogCategory_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["blogCategory_max_fields"],
		min?: ResolverInputTypes["blogCategory_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "blogCategory". All fields are combined with a logical 'AND'. */
	["blogCategory_bool_exp"]: {
		_and?: Array<ResolverInputTypes["blogCategory_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["blogCategory_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["blogCategory_bool_exp"]> | undefined | null,
		blogs?: ResolverInputTypes["blog_bool_exp"] | undefined | null,
		blogs_aggregate?: ResolverInputTypes["blog_aggregate_bool_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "blogCategory" */
	["blogCategory_constraint"]: blogCategory_constraint;
	/** input type for inserting data into table "blogCategory" */
	["blogCategory_insert_input"]: {
		blogs?: ResolverInputTypes["blog_arr_rel_insert_input"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["blogCategory_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["blogCategory_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "blogCategory" */
	["blogCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["blogCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "blogCategory" */
	["blogCategory_obj_rel_insert_input"]: {
		data: ResolverInputTypes["blogCategory_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["blogCategory_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "blogCategory" */
	["blogCategory_on_conflict"]: {
		constraint: ResolverInputTypes["blogCategory_constraint"],
		update_columns: Array<ResolverInputTypes["blogCategory_update_column"]>,
		where?: ResolverInputTypes["blogCategory_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "blogCategory". */
	["blogCategory_order_by"]: {
		blogs_aggregate?: ResolverInputTypes["blog_aggregate_order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: blogCategory */
	["blogCategory_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "blogCategory" */
	["blogCategory_select_column"]: blogCategory_select_column;
	/** input type for updating data in table "blogCategory" */
	["blogCategory_set_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "blogCategory" */
	["blogCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["blogCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["blogCategory_stream_cursor_value_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "blogCategory" */
	["blogCategory_update_column"]: blogCategory_update_column;
	["blogCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["blogCategory_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["blogCategory_bool_exp"]
	};
	/** fields of action: "blogPostGeneratorEnhancedForAmp" */
	["blogPostGeneratorEnhancedForAmp"]: AliasType<{
		/** the time at which this action was created */
		created_at?: boolean | `@${string}`,
		/** errors related to the invocation */
		errors?: boolean | `@${string}`,
		/** the unique id of an action */
		id?: boolean | `@${string}`,
		/** the output fields of this action */
		output?: ResolverInputTypes["blogPostGeneratorEnhancedForAmpOutput"],
		__typename?: boolean | `@${string}`
	}>;
	["blogPostGeneratorEnhancedForAmpOutput"]: AliasType<{
		html?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "blog" */
	["blog_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["blog_aggregate_fields"],
		nodes?: ResolverInputTypes["blog"],
		__typename?: boolean | `@${string}`
	}>;
	["blog_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["blog_aggregate_bool_exp_count"] | undefined | null
	};
	["blog_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["blog_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "blog" */
	["blog_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["blog_max_fields"],
		min?: ResolverInputTypes["blog_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "blog" */
	["blog_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["blog_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["blog_min_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "blog" */
	["blog_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["blog_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["blog_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "blog". All fields are combined with a logical 'AND'. */
	["blog_bool_exp"]: {
		_and?: Array<ResolverInputTypes["blog_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["blog_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["blog_bool_exp"]> | undefined | null,
		brand?: ResolverInputTypes["brands_bool_exp"] | undefined | null,
		brandId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		category?: ResolverInputTypes["blogCategory_bool_exp"] | undefined | null,
		categoryId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		content?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		featuredImageId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		file?: ResolverInputTypes["files_bool_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		metaDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		project?: ResolverInputTypes["project_bool_exp"] | undefined | null,
		projectId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		slug?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		title?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		user?: ResolverInputTypes["users_bool_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "blog" */
	["blog_constraint"]: blog_constraint;
	/** input type for inserting data into table "blog" */
	["blog_insert_input"]: {
		brand?: ResolverInputTypes["brands_obj_rel_insert_input"] | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		category?: ResolverInputTypes["blogCategory_obj_rel_insert_input"] | undefined | null,
		categoryId?: ResolverInputTypes["uuid"] | undefined | null,
		content?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		featuredImageId?: ResolverInputTypes["uuid"] | undefined | null,
		file?: ResolverInputTypes["files_obj_rel_insert_input"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		metaDescription?: string | undefined | null,
		project?: ResolverInputTypes["project_obj_rel_insert_input"] | undefined | null,
		projectId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		slug?: string | undefined | null,
		title?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		user?: ResolverInputTypes["users_obj_rel_insert_input"] | undefined | null
	};
	/** aggregate max on columns */
	["blog_max_fields"]: AliasType<{
		brandId?: boolean | `@${string}`,
		categoryId?: boolean | `@${string}`,
		content?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		featuredImageId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		metaDescription?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		slug?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "blog" */
	["blog_max_order_by"]: {
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		categoryId?: ResolverInputTypes["order_by"] | undefined | null,
		content?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		featuredImageId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		metaDescription?: ResolverInputTypes["order_by"] | undefined | null,
		projectId?: ResolverInputTypes["order_by"] | undefined | null,
		publishDate?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDate?: ResolverInputTypes["order_by"] | undefined | null,
		slug?: ResolverInputTypes["order_by"] | undefined | null,
		title?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["blog_min_fields"]: AliasType<{
		brandId?: boolean | `@${string}`,
		categoryId?: boolean | `@${string}`,
		content?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		featuredImageId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		metaDescription?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		slug?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "blog" */
	["blog_min_order_by"]: {
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		categoryId?: ResolverInputTypes["order_by"] | undefined | null,
		content?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		featuredImageId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		metaDescription?: ResolverInputTypes["order_by"] | undefined | null,
		projectId?: ResolverInputTypes["order_by"] | undefined | null,
		publishDate?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDate?: ResolverInputTypes["order_by"] | undefined | null,
		slug?: ResolverInputTypes["order_by"] | undefined | null,
		title?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "blog" */
	["blog_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["blog"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "blog" */
	["blog_on_conflict"]: {
		constraint: ResolverInputTypes["blog_constraint"],
		update_columns: Array<ResolverInputTypes["blog_update_column"]>,
		where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "blog". */
	["blog_order_by"]: {
		brand?: ResolverInputTypes["brands_order_by"] | undefined | null,
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		category?: ResolverInputTypes["blogCategory_order_by"] | undefined | null,
		categoryId?: ResolverInputTypes["order_by"] | undefined | null,
		content?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		featuredImageId?: ResolverInputTypes["order_by"] | undefined | null,
		file?: ResolverInputTypes["files_order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		metaDescription?: ResolverInputTypes["order_by"] | undefined | null,
		project?: ResolverInputTypes["project_order_by"] | undefined | null,
		projectId?: ResolverInputTypes["order_by"] | undefined | null,
		publishDate?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDate?: ResolverInputTypes["order_by"] | undefined | null,
		slug?: ResolverInputTypes["order_by"] | undefined | null,
		title?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		user?: ResolverInputTypes["users_order_by"] | undefined | null
	};
	/** primary key columns input for table: blog */
	["blog_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "blog" */
	["blog_select_column"]: blog_select_column;
	/** input type for updating data in table "blog" */
	["blog_set_input"]: {
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		categoryId?: ResolverInputTypes["uuid"] | undefined | null,
		content?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		featuredImageId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		metaDescription?: string | undefined | null,
		projectId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		slug?: string | undefined | null,
		title?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "blog" */
	["blog_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["blog_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["blog_stream_cursor_value_input"]: {
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		categoryId?: ResolverInputTypes["uuid"] | undefined | null,
		content?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		featuredImageId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		metaDescription?: string | undefined | null,
		projectId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		slug?: string | undefined | null,
		title?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "blog" */
	["blog_update_column"]: blog_update_column;
	["blog_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["blog_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["blog_bool_exp"]
	};
	/** columns and relationships of "brands" */
	["brands"]: AliasType<{
		addressCity?: boolean | `@${string}`,
		addressComplement?: boolean | `@${string}`,
		addressDistrict?: boolean | `@${string}`,
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		addressNumber?: boolean | `@${string}`,
		addressStreet?: boolean | `@${string}`,
		addressUf?: boolean | `@${string}`,
		addressZipCode?: boolean | `@${string}`,
		archtypeAdherence?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		blogs?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blog_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog"]],
		blogs_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blog_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog_aggregate"]],
		brandName?: boolean | `@${string}`,
		colors?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		facebookUrl?: boolean | `@${string}`,
		fontPrimary?: boolean | `@${string}`,
		fontSecondary?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		instagramUrl?: boolean | `@${string}`,
		isBrand?: boolean | `@${string}`,
		linkedinUrl?: boolean | `@${string}`,
		logoFileId?: boolean | `@${string}`,
		personality?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		products?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["products_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["products_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["products_bool_exp"] | undefined | null
		}, ResolverInputTypes["products"]],
		products_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["products_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["products_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["products_bool_exp"] | undefined | null
		}, ResolverInputTypes["products_aggregate"]],
		segment?: boolean | `@${string}`,
		shortAbout?: boolean | `@${string}`,
		slogan?: boolean | `@${string}`,
		tiktokUrl?: boolean | `@${string}`,
		twitterUrl?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		voiceTone?: boolean | `@${string}`,
		whatsappNumber?: boolean | `@${string}`,
		youtubeUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "brands" */
	["brands_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["brands_aggregate_fields"],
		nodes?: ResolverInputTypes["brands"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "brands" */
	["brands_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["brands_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["brands_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["brands_max_fields"],
		min?: ResolverInputTypes["brands_min_fields"],
		stddev?: ResolverInputTypes["brands_stddev_fields"],
		stddev_pop?: ResolverInputTypes["brands_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["brands_stddev_samp_fields"],
		sum?: ResolverInputTypes["brands_sum_fields"],
		var_pop?: ResolverInputTypes["brands_var_pop_fields"],
		var_samp?: ResolverInputTypes["brands_var_samp_fields"],
		variance?: ResolverInputTypes["brands_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["brands_append_input"]: {
		archtypeAdherence?: ResolverInputTypes["jsonb"] | undefined | null,
		colors?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** aggregate avg on columns */
	["brands_avg_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "brands". All fields are combined with a logical 'AND'. */
	["brands_bool_exp"]: {
		_and?: Array<ResolverInputTypes["brands_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["brands_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["brands_bool_exp"]> | undefined | null,
		addressCity?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		addressComplement?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		addressDistrict?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		addressLatitude?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		addressLongitude?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		addressNumber?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		addressStreet?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		addressUf?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		addressZipCode?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		archtypeAdherence?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		blogs?: ResolverInputTypes["blog_bool_exp"] | undefined | null,
		blogs_aggregate?: ResolverInputTypes["blog_aggregate_bool_exp"] | undefined | null,
		brandName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		colors?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		deleted?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		email?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		facebookUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		fontPrimary?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		fontSecondary?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		instagramUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		isBrand?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		linkedinUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		logoFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		personality?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		phoneNumber?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		productOrService?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		products?: ResolverInputTypes["products_bool_exp"] | undefined | null,
		products_aggregate?: ResolverInputTypes["products_aggregate_bool_exp"] | undefined | null,
		segment?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		shortAbout?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		slogan?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		tiktokUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		twitterUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		voiceTone?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		whatsappNumber?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		youtubeUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "brands" */
	["brands_constraint"]: brands_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["brands_delete_at_path_input"]: {
		archtypeAdherence?: Array<string> | undefined | null,
		colors?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["brands_delete_elem_input"]: {
		archtypeAdherence?: number | undefined | null,
		colors?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["brands_delete_key_input"]: {
		archtypeAdherence?: string | undefined | null,
		colors?: string | undefined | null
	};
	/** input type for incrementing numeric columns in table "brands" */
	["brands_inc_input"]: {
		addressLatitude?: ResolverInputTypes["numeric"] | undefined | null,
		addressLongitude?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "brands" */
	["brands_insert_input"]: {
		addressCity?: string | undefined | null,
		addressComplement?: string | undefined | null,
		addressDistrict?: string | undefined | null,
		addressLatitude?: ResolverInputTypes["numeric"] | undefined | null,
		addressLongitude?: ResolverInputTypes["numeric"] | undefined | null,
		addressNumber?: string | undefined | null,
		addressStreet?: string | undefined | null,
		addressUf?: string | undefined | null,
		addressZipCode?: string | undefined | null,
		archtypeAdherence?: ResolverInputTypes["jsonb"] | undefined | null,
		blogs?: ResolverInputTypes["blog_arr_rel_insert_input"] | undefined | null,
		brandName?: string | undefined | null,
		colors?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		email?: string | undefined | null,
		facebookUrl?: string | undefined | null,
		fontPrimary?: string | undefined | null,
		fontSecondary?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		instagramUrl?: string | undefined | null,
		isBrand?: boolean | undefined | null,
		linkedinUrl?: string | undefined | null,
		logoFileId?: ResolverInputTypes["uuid"] | undefined | null,
		personality?: string | undefined | null,
		phoneNumber?: string | undefined | null,
		productOrService?: string | undefined | null,
		products?: ResolverInputTypes["products_arr_rel_insert_input"] | undefined | null,
		segment?: string | undefined | null,
		shortAbout?: string | undefined | null,
		slogan?: string | undefined | null,
		tiktokUrl?: string | undefined | null,
		twitterUrl?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		voiceTone?: string | undefined | null,
		whatsappNumber?: string | undefined | null,
		youtubeUrl?: string | undefined | null
	};
	/** aggregate max on columns */
	["brands_max_fields"]: AliasType<{
		addressCity?: boolean | `@${string}`,
		addressComplement?: boolean | `@${string}`,
		addressDistrict?: boolean | `@${string}`,
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		addressNumber?: boolean | `@${string}`,
		addressStreet?: boolean | `@${string}`,
		addressUf?: boolean | `@${string}`,
		addressZipCode?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		facebookUrl?: boolean | `@${string}`,
		fontPrimary?: boolean | `@${string}`,
		fontSecondary?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		instagramUrl?: boolean | `@${string}`,
		linkedinUrl?: boolean | `@${string}`,
		logoFileId?: boolean | `@${string}`,
		personality?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		shortAbout?: boolean | `@${string}`,
		slogan?: boolean | `@${string}`,
		tiktokUrl?: boolean | `@${string}`,
		twitterUrl?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		voiceTone?: boolean | `@${string}`,
		whatsappNumber?: boolean | `@${string}`,
		youtubeUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["brands_min_fields"]: AliasType<{
		addressCity?: boolean | `@${string}`,
		addressComplement?: boolean | `@${string}`,
		addressDistrict?: boolean | `@${string}`,
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		addressNumber?: boolean | `@${string}`,
		addressStreet?: boolean | `@${string}`,
		addressUf?: boolean | `@${string}`,
		addressZipCode?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		facebookUrl?: boolean | `@${string}`,
		fontPrimary?: boolean | `@${string}`,
		fontSecondary?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		instagramUrl?: boolean | `@${string}`,
		linkedinUrl?: boolean | `@${string}`,
		logoFileId?: boolean | `@${string}`,
		personality?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		shortAbout?: boolean | `@${string}`,
		slogan?: boolean | `@${string}`,
		tiktokUrl?: boolean | `@${string}`,
		twitterUrl?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		voiceTone?: boolean | `@${string}`,
		whatsappNumber?: boolean | `@${string}`,
		youtubeUrl?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "brands" */
	["brands_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["brands"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "brands" */
	["brands_obj_rel_insert_input"]: {
		data: ResolverInputTypes["brands_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["brands_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "brands" */
	["brands_on_conflict"]: {
		constraint: ResolverInputTypes["brands_constraint"],
		update_columns: Array<ResolverInputTypes["brands_update_column"]>,
		where?: ResolverInputTypes["brands_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "brands". */
	["brands_order_by"]: {
		addressCity?: ResolverInputTypes["order_by"] | undefined | null,
		addressComplement?: ResolverInputTypes["order_by"] | undefined | null,
		addressDistrict?: ResolverInputTypes["order_by"] | undefined | null,
		addressLatitude?: ResolverInputTypes["order_by"] | undefined | null,
		addressLongitude?: ResolverInputTypes["order_by"] | undefined | null,
		addressNumber?: ResolverInputTypes["order_by"] | undefined | null,
		addressStreet?: ResolverInputTypes["order_by"] | undefined | null,
		addressUf?: ResolverInputTypes["order_by"] | undefined | null,
		addressZipCode?: ResolverInputTypes["order_by"] | undefined | null,
		archtypeAdherence?: ResolverInputTypes["order_by"] | undefined | null,
		blogs_aggregate?: ResolverInputTypes["blog_aggregate_order_by"] | undefined | null,
		brandName?: ResolverInputTypes["order_by"] | undefined | null,
		colors?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		deleted?: ResolverInputTypes["order_by"] | undefined | null,
		email?: ResolverInputTypes["order_by"] | undefined | null,
		facebookUrl?: ResolverInputTypes["order_by"] | undefined | null,
		fontPrimary?: ResolverInputTypes["order_by"] | undefined | null,
		fontSecondary?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		instagramUrl?: ResolverInputTypes["order_by"] | undefined | null,
		isBrand?: ResolverInputTypes["order_by"] | undefined | null,
		linkedinUrl?: ResolverInputTypes["order_by"] | undefined | null,
		logoFileId?: ResolverInputTypes["order_by"] | undefined | null,
		personality?: ResolverInputTypes["order_by"] | undefined | null,
		phoneNumber?: ResolverInputTypes["order_by"] | undefined | null,
		productOrService?: ResolverInputTypes["order_by"] | undefined | null,
		products_aggregate?: ResolverInputTypes["products_aggregate_order_by"] | undefined | null,
		segment?: ResolverInputTypes["order_by"] | undefined | null,
		shortAbout?: ResolverInputTypes["order_by"] | undefined | null,
		slogan?: ResolverInputTypes["order_by"] | undefined | null,
		tiktokUrl?: ResolverInputTypes["order_by"] | undefined | null,
		twitterUrl?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		voiceTone?: ResolverInputTypes["order_by"] | undefined | null,
		whatsappNumber?: ResolverInputTypes["order_by"] | undefined | null,
		youtubeUrl?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: brands */
	["brands_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["brands_prepend_input"]: {
		archtypeAdherence?: ResolverInputTypes["jsonb"] | undefined | null,
		colors?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "brands" */
	["brands_select_column"]: brands_select_column;
	/** input type for updating data in table "brands" */
	["brands_set_input"]: {
		addressCity?: string | undefined | null,
		addressComplement?: string | undefined | null,
		addressDistrict?: string | undefined | null,
		addressLatitude?: ResolverInputTypes["numeric"] | undefined | null,
		addressLongitude?: ResolverInputTypes["numeric"] | undefined | null,
		addressNumber?: string | undefined | null,
		addressStreet?: string | undefined | null,
		addressUf?: string | undefined | null,
		addressZipCode?: string | undefined | null,
		archtypeAdherence?: ResolverInputTypes["jsonb"] | undefined | null,
		brandName?: string | undefined | null,
		colors?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		email?: string | undefined | null,
		facebookUrl?: string | undefined | null,
		fontPrimary?: string | undefined | null,
		fontSecondary?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		instagramUrl?: string | undefined | null,
		isBrand?: boolean | undefined | null,
		linkedinUrl?: string | undefined | null,
		logoFileId?: ResolverInputTypes["uuid"] | undefined | null,
		personality?: string | undefined | null,
		phoneNumber?: string | undefined | null,
		productOrService?: string | undefined | null,
		segment?: string | undefined | null,
		shortAbout?: string | undefined | null,
		slogan?: string | undefined | null,
		tiktokUrl?: string | undefined | null,
		twitterUrl?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		voiceTone?: string | undefined | null,
		whatsappNumber?: string | undefined | null,
		youtubeUrl?: string | undefined | null
	};
	/** aggregate stddev on columns */
	["brands_stddev_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["brands_stddev_pop_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["brands_stddev_samp_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "brands" */
	["brands_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["brands_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["brands_stream_cursor_value_input"]: {
		addressCity?: string | undefined | null,
		addressComplement?: string | undefined | null,
		addressDistrict?: string | undefined | null,
		addressLatitude?: ResolverInputTypes["numeric"] | undefined | null,
		addressLongitude?: ResolverInputTypes["numeric"] | undefined | null,
		addressNumber?: string | undefined | null,
		addressStreet?: string | undefined | null,
		addressUf?: string | undefined | null,
		addressZipCode?: string | undefined | null,
		archtypeAdherence?: ResolverInputTypes["jsonb"] | undefined | null,
		brandName?: string | undefined | null,
		colors?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		email?: string | undefined | null,
		facebookUrl?: string | undefined | null,
		fontPrimary?: string | undefined | null,
		fontSecondary?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		instagramUrl?: string | undefined | null,
		isBrand?: boolean | undefined | null,
		linkedinUrl?: string | undefined | null,
		logoFileId?: ResolverInputTypes["uuid"] | undefined | null,
		personality?: string | undefined | null,
		phoneNumber?: string | undefined | null,
		productOrService?: string | undefined | null,
		segment?: string | undefined | null,
		shortAbout?: string | undefined | null,
		slogan?: string | undefined | null,
		tiktokUrl?: string | undefined | null,
		twitterUrl?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		voiceTone?: string | undefined | null,
		whatsappNumber?: string | undefined | null,
		youtubeUrl?: string | undefined | null
	};
	/** aggregate sum on columns */
	["brands_sum_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "brands" */
	["brands_update_column"]: brands_update_column;
	["brands_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["brands_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["brands_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["brands_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["brands_delete_key_input"] | undefined | null,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["brands_inc_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["brands_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["brands_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["brands_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["brands_var_pop_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["brands_var_samp_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["brands_variance_fields"]: AliasType<{
		addressLatitude?: boolean | `@${string}`,
		addressLongitude?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "storage.buckets" */
	["buckets"]: AliasType<{
		cacheControl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		downloadExpiration?: boolean | `@${string}`,
		files?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["files_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["files_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["files_bool_exp"] | undefined | null
		}, ResolverInputTypes["files"]],
		files_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["files_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["files_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["files_bool_exp"] | undefined | null
		}, ResolverInputTypes["files_aggregate"]],
		id?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		presignedUrlsEnabled?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "storage.buckets" */
	["buckets_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["buckets_aggregate_fields"],
		nodes?: ResolverInputTypes["buckets"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "storage.buckets" */
	["buckets_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["buckets_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["buckets_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["buckets_max_fields"],
		min?: ResolverInputTypes["buckets_min_fields"],
		stddev?: ResolverInputTypes["buckets_stddev_fields"],
		stddev_pop?: ResolverInputTypes["buckets_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["buckets_stddev_samp_fields"],
		sum?: ResolverInputTypes["buckets_sum_fields"],
		var_pop?: ResolverInputTypes["buckets_var_pop_fields"],
		var_samp?: ResolverInputTypes["buckets_var_samp_fields"],
		variance?: ResolverInputTypes["buckets_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["buckets_avg_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "storage.buckets". All fields are combined with a logical 'AND'. */
	["buckets_bool_exp"]: {
		_and?: Array<ResolverInputTypes["buckets_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["buckets_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["buckets_bool_exp"]> | undefined | null,
		cacheControl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		downloadExpiration?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		files?: ResolverInputTypes["files_bool_exp"] | undefined | null,
		files_aggregate?: ResolverInputTypes["files_aggregate_bool_exp"] | undefined | null,
		id?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		maxUploadFileSize?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		minUploadFileSize?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		presignedUrlsEnabled?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "storage.buckets" */
	["buckets_constraint"]: buckets_constraint;
	/** input type for incrementing numeric columns in table "storage.buckets" */
	["buckets_inc_input"]: {
		downloadExpiration?: number | undefined | null,
		maxUploadFileSize?: number | undefined | null,
		minUploadFileSize?: number | undefined | null
	};
	/** input type for inserting data into table "storage.buckets" */
	["buckets_insert_input"]: {
		cacheControl?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		downloadExpiration?: number | undefined | null,
		files?: ResolverInputTypes["files_arr_rel_insert_input"] | undefined | null,
		id?: string | undefined | null,
		maxUploadFileSize?: number | undefined | null,
		minUploadFileSize?: number | undefined | null,
		presignedUrlsEnabled?: boolean | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["buckets_max_fields"]: AliasType<{
		cacheControl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		downloadExpiration?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["buckets_min_fields"]: AliasType<{
		cacheControl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		downloadExpiration?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "storage.buckets" */
	["buckets_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["buckets"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "storage.buckets" */
	["buckets_obj_rel_insert_input"]: {
		data: ResolverInputTypes["buckets_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["buckets_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "storage.buckets" */
	["buckets_on_conflict"]: {
		constraint: ResolverInputTypes["buckets_constraint"],
		update_columns: Array<ResolverInputTypes["buckets_update_column"]>,
		where?: ResolverInputTypes["buckets_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "storage.buckets". */
	["buckets_order_by"]: {
		cacheControl?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		downloadExpiration?: ResolverInputTypes["order_by"] | undefined | null,
		files_aggregate?: ResolverInputTypes["files_aggregate_order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		maxUploadFileSize?: ResolverInputTypes["order_by"] | undefined | null,
		minUploadFileSize?: ResolverInputTypes["order_by"] | undefined | null,
		presignedUrlsEnabled?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: storage.buckets */
	["buckets_pk_columns_input"]: {
		id: string
	};
	/** select columns of table "storage.buckets" */
	["buckets_select_column"]: buckets_select_column;
	/** input type for updating data in table "storage.buckets" */
	["buckets_set_input"]: {
		cacheControl?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		downloadExpiration?: number | undefined | null,
		id?: string | undefined | null,
		maxUploadFileSize?: number | undefined | null,
		minUploadFileSize?: number | undefined | null,
		presignedUrlsEnabled?: boolean | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate stddev on columns */
	["buckets_stddev_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["buckets_stddev_pop_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["buckets_stddev_samp_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "buckets" */
	["buckets_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["buckets_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["buckets_stream_cursor_value_input"]: {
		cacheControl?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		downloadExpiration?: number | undefined | null,
		id?: string | undefined | null,
		maxUploadFileSize?: number | undefined | null,
		minUploadFileSize?: number | undefined | null,
		presignedUrlsEnabled?: boolean | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate sum on columns */
	["buckets_sum_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "storage.buckets" */
	["buckets_update_column"]: buckets_update_column;
	["buckets_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["buckets_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["buckets_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["buckets_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["buckets_var_pop_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["buckets_var_samp_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["buckets_variance_fields"]: AliasType<{
		downloadExpiration?: boolean | `@${string}`,
		maxUploadFileSize?: boolean | `@${string}`,
		minUploadFileSize?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["bytea"]: unknown;
	/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
	["bytea_comparison_exp"]: {
		_eq?: ResolverInputTypes["bytea"] | undefined | null,
		_gt?: ResolverInputTypes["bytea"] | undefined | null,
		_gte?: ResolverInputTypes["bytea"] | undefined | null,
		_in?: Array<ResolverInputTypes["bytea"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: ResolverInputTypes["bytea"] | undefined | null,
		_lte?: ResolverInputTypes["bytea"] | undefined | null,
		_neq?: ResolverInputTypes["bytea"] | undefined | null,
		_nin?: Array<ResolverInputTypes["bytea"]> | undefined | null
	};
	/** columns and relationships of "campaign" */
	["campaign"]: AliasType<{
		audiencesIds?: boolean | `@${string}`,
		baseJson?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		brandId?: boolean | `@${string}`,
		campaignObjective?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productIds?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "campaign" */
	["campaign_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["campaign_aggregate_fields"],
		nodes?: ResolverInputTypes["campaign"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "campaign" */
	["campaign_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["campaign_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["campaign_max_fields"],
		min?: ResolverInputTypes["campaign_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["campaign_append_input"]: {
		baseJson?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "campaign". All fields are combined with a logical 'AND'. */
	["campaign_bool_exp"]: {
		_and?: Array<ResolverInputTypes["campaign_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["campaign_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["campaign_bool_exp"]> | undefined | null,
		audiencesIds?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		baseJson?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		brandId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		campaignObjective?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		productIds?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "campaign" */
	["campaign_constraint"]: campaign_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["campaign_delete_at_path_input"]: {
		baseJson?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["campaign_delete_elem_input"]: {
		baseJson?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["campaign_delete_key_input"]: {
		baseJson?: string | undefined | null
	};
	/** input type for inserting data into table "campaign" */
	["campaign_insert_input"]: {
		audiencesIds?: string | undefined | null,
		baseJson?: ResolverInputTypes["jsonb"] | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		campaignObjective?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		productIds?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["campaign_max_fields"]: AliasType<{
		audiencesIds?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignObjective?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productIds?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["campaign_min_fields"]: AliasType<{
		audiencesIds?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignObjective?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		productIds?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "campaign" */
	["campaign_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["campaign"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "campaign" */
	["campaign_on_conflict"]: {
		constraint: ResolverInputTypes["campaign_constraint"],
		update_columns: Array<ResolverInputTypes["campaign_update_column"]>,
		where?: ResolverInputTypes["campaign_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "campaign". */
	["campaign_order_by"]: {
		audiencesIds?: ResolverInputTypes["order_by"] | undefined | null,
		baseJson?: ResolverInputTypes["order_by"] | undefined | null,
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		campaignObjective?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		productIds?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: campaign */
	["campaign_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["campaign_prepend_input"]: {
		baseJson?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "campaign" */
	["campaign_select_column"]: campaign_select_column;
	/** input type for updating data in table "campaign" */
	["campaign_set_input"]: {
		audiencesIds?: string | undefined | null,
		baseJson?: ResolverInputTypes["jsonb"] | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		campaignObjective?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		productIds?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "campaign" */
	["campaign_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["campaign_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["campaign_stream_cursor_value_input"]: {
		audiencesIds?: string | undefined | null,
		baseJson?: ResolverInputTypes["jsonb"] | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		campaignObjective?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		productIds?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "campaign" */
	["campaign_update_column"]: campaign_update_column;
	["campaign_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["campaign_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["campaign_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["campaign_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["campaign_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["campaign_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["campaign_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["campaign_bool_exp"]
	};
	/** columns and relationships of "categorysummaryview" */
	["categorysummaryview"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		count?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "categorysummaryview" */
	["categorysummaryview_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["categorysummaryview_aggregate_fields"],
		nodes?: ResolverInputTypes["categorysummaryview"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "categorysummaryview" */
	["categorysummaryview_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["categorysummaryview_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["categorysummaryview_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["categorysummaryview_max_fields"],
		min?: ResolverInputTypes["categorysummaryview_min_fields"],
		stddev?: ResolverInputTypes["categorysummaryview_stddev_fields"],
		stddev_pop?: ResolverInputTypes["categorysummaryview_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["categorysummaryview_stddev_samp_fields"],
		sum?: ResolverInputTypes["categorysummaryview_sum_fields"],
		var_pop?: ResolverInputTypes["categorysummaryview_var_pop_fields"],
		var_samp?: ResolverInputTypes["categorysummaryview_var_samp_fields"],
		variance?: ResolverInputTypes["categorysummaryview_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["categorysummaryview_avg_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "categorysummaryview". All fields are combined with a logical 'AND'. */
	["categorysummaryview_bool_exp"]: {
		_and?: Array<ResolverInputTypes["categorysummaryview_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["categorysummaryview_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["categorysummaryview_bool_exp"]> | undefined | null,
		categoryName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		count?: ResolverInputTypes["bigint_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** aggregate max on columns */
	["categorysummaryview_max_fields"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		count?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["categorysummaryview_min_fields"]: AliasType<{
		categoryName?: boolean | `@${string}`,
		count?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Ordering options when selecting data from "categorysummaryview". */
	["categorysummaryview_order_by"]: {
		categoryName?: ResolverInputTypes["order_by"] | undefined | null,
		count?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** select columns of table "categorysummaryview" */
	["categorysummaryview_select_column"]: categorysummaryview_select_column;
	/** aggregate stddev on columns */
	["categorysummaryview_stddev_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["categorysummaryview_stddev_pop_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["categorysummaryview_stddev_samp_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "categorysummaryview" */
	["categorysummaryview_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["categorysummaryview_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["categorysummaryview_stream_cursor_value_input"]: {
		categoryName?: string | undefined | null,
		count?: ResolverInputTypes["bigint"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate sum on columns */
	["categorysummaryview_sum_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_pop on columns */
	["categorysummaryview_var_pop_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["categorysummaryview_var_samp_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["categorysummaryview_variance_fields"]: AliasType<{
		count?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "channels" */
	["channels"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "channels" */
	["channels_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["channels_aggregate_fields"],
		nodes?: ResolverInputTypes["channels"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "channels" */
	["channels_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["channels_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["channels_max_fields"],
		min?: ResolverInputTypes["channels_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
	["channels_bool_exp"]: {
		_and?: Array<ResolverInputTypes["channels_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["channels_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["channels_bool_exp"]> | undefined | null,
		alias?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamp_comparison_exp"] | undefined | null,
		displayName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamp_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "channels" */
	["channels_constraint"]: channels_constraint;
	/** input type for inserting data into table "channels" */
	["channels_insert_input"]: {
		alias?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamp"] | undefined | null,
		displayName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamp"] | undefined | null
	};
	/** aggregate max on columns */
	["channels_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["channels_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "channels" */
	["channels_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["channels"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "channels" */
	["channels_on_conflict"]: {
		constraint: ResolverInputTypes["channels_constraint"],
		update_columns: Array<ResolverInputTypes["channels_update_column"]>,
		where?: ResolverInputTypes["channels_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "channels". */
	["channels_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: channels */
	["channels_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "channels" */
	["channels_select_column"]: channels_select_column;
	/** input type for updating data in table "channels" */
	["channels_set_input"]: {
		alias?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamp"] | undefined | null,
		displayName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamp"] | undefined | null
	};
	/** Streaming cursor of the table "channels" */
	["channels_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["channels_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["channels_stream_cursor_value_input"]: {
		alias?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamp"] | undefined | null,
		displayName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamp"] | undefined | null
	};
	/** update columns of table "channels" */
	["channels_update_column"]: channels_update_column;
	["channels_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["channels_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["channels_bool_exp"]
	};
	["citext"]: unknown;
	/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
	["citext_comparison_exp"]: {
		_eq?: ResolverInputTypes["citext"] | undefined | null,
		_gt?: ResolverInputTypes["citext"] | undefined | null,
		_gte?: ResolverInputTypes["citext"] | undefined | null,
		/** does the column match the given case-insensitive pattern */
		_ilike?: ResolverInputTypes["citext"] | undefined | null,
		_in?: Array<ResolverInputTypes["citext"]> | undefined | null,
		/** does the column match the given POSIX regular expression, case insensitive */
		_iregex?: ResolverInputTypes["citext"] | undefined | null,
		_is_null?: boolean | undefined | null,
		/** does the column match the given pattern */
		_like?: ResolverInputTypes["citext"] | undefined | null,
		_lt?: ResolverInputTypes["citext"] | undefined | null,
		_lte?: ResolverInputTypes["citext"] | undefined | null,
		_neq?: ResolverInputTypes["citext"] | undefined | null,
		/** does the column NOT match the given case-insensitive pattern */
		_nilike?: ResolverInputTypes["citext"] | undefined | null,
		_nin?: Array<ResolverInputTypes["citext"]> | undefined | null,
		/** does the column NOT match the given POSIX regular expression, case insensitive */
		_niregex?: ResolverInputTypes["citext"] | undefined | null,
		/** does the column NOT match the given pattern */
		_nlike?: ResolverInputTypes["citext"] | undefined | null,
		/** does the column NOT match the given POSIX regular expression, case sensitive */
		_nregex?: ResolverInputTypes["citext"] | undefined | null,
		/** does the column NOT match the given SQL regular expression */
		_nsimilar?: ResolverInputTypes["citext"] | undefined | null,
		/** does the column match the given POSIX regular expression, case sensitive */
		_regex?: ResolverInputTypes["citext"] | undefined | null,
		/** does the column match the given SQL regular expression */
		_similar?: ResolverInputTypes["citext"] | undefined | null
	};
	/** columns and relationships of "consumptionByChannelView" */
	["consumptionByChannelView"]: AliasType<{
		agendados?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "consumptionByChannelView" */
	["consumptionByChannelView_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["consumptionByChannelView_aggregate_fields"],
		nodes?: ResolverInputTypes["consumptionByChannelView"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "consumptionByChannelView" */
	["consumptionByChannelView_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["consumptionByChannelView_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["consumptionByChannelView_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["consumptionByChannelView_max_fields"],
		min?: ResolverInputTypes["consumptionByChannelView_min_fields"],
		stddev?: ResolverInputTypes["consumptionByChannelView_stddev_fields"],
		stddev_pop?: ResolverInputTypes["consumptionByChannelView_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["consumptionByChannelView_stddev_samp_fields"],
		sum?: ResolverInputTypes["consumptionByChannelView_sum_fields"],
		var_pop?: ResolverInputTypes["consumptionByChannelView_var_pop_fields"],
		var_samp?: ResolverInputTypes["consumptionByChannelView_var_samp_fields"],
		variance?: ResolverInputTypes["consumptionByChannelView_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["consumptionByChannelView_avg_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "consumptionByChannelView". All fields are combined with a logical 'AND'. */
	["consumptionByChannelView_bool_exp"]: {
		_and?: Array<ResolverInputTypes["consumptionByChannelView_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["consumptionByChannelView_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["consumptionByChannelView_bool_exp"]> | undefined | null,
		agendados?: ResolverInputTypes["bigint_comparison_exp"] | undefined | null,
		channel?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		copymagico?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		economia?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		mercado?: ResolverInputTypes["bigint_comparison_exp"] | undefined | null,
		publicados?: ResolverInputTypes["bigint_comparison_exp"] | undefined | null,
		rascunhos?: ResolverInputTypes["bigint_comparison_exp"] | undefined | null
	};
	/** aggregate max on columns */
	["consumptionByChannelView_max_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["consumptionByChannelView_min_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		channel?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Ordering options when selecting data from "consumptionByChannelView". */
	["consumptionByChannelView_order_by"]: {
		agendados?: ResolverInputTypes["order_by"] | undefined | null,
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		copymagico?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		economia?: ResolverInputTypes["order_by"] | undefined | null,
		mercado?: ResolverInputTypes["order_by"] | undefined | null,
		publicados?: ResolverInputTypes["order_by"] | undefined | null,
		rascunhos?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** select columns of table "consumptionByChannelView" */
	["consumptionByChannelView_select_column"]: consumptionByChannelView_select_column;
	/** aggregate stddev on columns */
	["consumptionByChannelView_stddev_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["consumptionByChannelView_stddev_pop_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["consumptionByChannelView_stddev_samp_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "consumptionByChannelView" */
	["consumptionByChannelView_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["consumptionByChannelView_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["consumptionByChannelView_stream_cursor_value_input"]: {
		agendados?: ResolverInputTypes["bigint"] | undefined | null,
		channel?: string | undefined | null,
		copymagico?: ResolverInputTypes["numeric"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		economia?: ResolverInputTypes["numeric"] | undefined | null,
		mercado?: ResolverInputTypes["bigint"] | undefined | null,
		publicados?: ResolverInputTypes["bigint"] | undefined | null,
		rascunhos?: ResolverInputTypes["bigint"] | undefined | null
	};
	/** aggregate sum on columns */
	["consumptionByChannelView_sum_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_pop on columns */
	["consumptionByChannelView_var_pop_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["consumptionByChannelView_var_samp_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["consumptionByChannelView_variance_fields"]: AliasType<{
		agendados?: boolean | `@${string}`,
		copymagico?: boolean | `@${string}`,
		economia?: boolean | `@${string}`,
		mercado?: boolean | `@${string}`,
		publicados?: boolean | `@${string}`,
		rascunhos?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "costTable" */
	["costTable"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		entityId?: boolean | `@${string}`,
		entityName?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		provider?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "costTable" */
	["costTable_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["costTable_aggregate_fields"],
		nodes?: ResolverInputTypes["costTable"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "costTable" */
	["costTable_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["costTable_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["costTable_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["costTable_max_fields"],
		min?: ResolverInputTypes["costTable_min_fields"],
		stddev?: ResolverInputTypes["costTable_stddev_fields"],
		stddev_pop?: ResolverInputTypes["costTable_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["costTable_stddev_samp_fields"],
		sum?: ResolverInputTypes["costTable_sum_fields"],
		var_pop?: ResolverInputTypes["costTable_var_pop_fields"],
		var_samp?: ResolverInputTypes["costTable_var_samp_fields"],
		variance?: ResolverInputTypes["costTable_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["costTable_avg_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "costTable". All fields are combined with a logical 'AND'. */
	["costTable_bool_exp"]: {
		_and?: Array<ResolverInputTypes["costTable_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["costTable_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["costTable_bool_exp"]> | undefined | null,
		bannerBear?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		entityId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		entityName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		gptCompletionTokens?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		gptPromptTokens?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		provider?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "costTable" */
	["costTable_constraint"]: costTable_constraint;
	/** input type for incrementing numeric columns in table "costTable" */
	["costTable_inc_input"]: {
		bannerBear?: ResolverInputTypes["numeric"] | undefined | null,
		gptCompletionTokens?: ResolverInputTypes["numeric"] | undefined | null,
		gptPromptTokens?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "costTable" */
	["costTable_insert_input"]: {
		bannerBear?: ResolverInputTypes["numeric"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		entityId?: string | undefined | null,
		entityName?: string | undefined | null,
		gptCompletionTokens?: ResolverInputTypes["numeric"] | undefined | null,
		gptPromptTokens?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		provider?: string | undefined | null
	};
	/** aggregate max on columns */
	["costTable_max_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		entityId?: boolean | `@${string}`,
		entityName?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		provider?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["costTable_min_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		entityId?: boolean | `@${string}`,
		entityName?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		provider?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "costTable" */
	["costTable_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["costTable"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "costTable" */
	["costTable_on_conflict"]: {
		constraint: ResolverInputTypes["costTable_constraint"],
		update_columns: Array<ResolverInputTypes["costTable_update_column"]>,
		where?: ResolverInputTypes["costTable_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "costTable". */
	["costTable_order_by"]: {
		bannerBear?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		entityId?: ResolverInputTypes["order_by"] | undefined | null,
		entityName?: ResolverInputTypes["order_by"] | undefined | null,
		gptCompletionTokens?: ResolverInputTypes["order_by"] | undefined | null,
		gptPromptTokens?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		provider?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: costTable */
	["costTable_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "costTable" */
	["costTable_select_column"]: costTable_select_column;
	/** input type for updating data in table "costTable" */
	["costTable_set_input"]: {
		bannerBear?: ResolverInputTypes["numeric"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		entityId?: string | undefined | null,
		entityName?: string | undefined | null,
		gptCompletionTokens?: ResolverInputTypes["numeric"] | undefined | null,
		gptPromptTokens?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		provider?: string | undefined | null
	};
	/** aggregate stddev on columns */
	["costTable_stddev_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["costTable_stddev_pop_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["costTable_stddev_samp_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "costTable" */
	["costTable_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["costTable_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["costTable_stream_cursor_value_input"]: {
		bannerBear?: ResolverInputTypes["numeric"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		entityId?: string | undefined | null,
		entityName?: string | undefined | null,
		gptCompletionTokens?: ResolverInputTypes["numeric"] | undefined | null,
		gptPromptTokens?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		provider?: string | undefined | null
	};
	/** aggregate sum on columns */
	["costTable_sum_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "costTable" */
	["costTable_update_column"]: costTable_update_column;
	["costTable_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["costTable_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["costTable_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["costTable_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["costTable_var_pop_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["costTable_var_samp_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["costTable_variance_fields"]: AliasType<{
		bannerBear?: boolean | `@${string}`,
		gptCompletionTokens?: boolean | `@${string}`,
		gptPromptTokens?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** ordering argument of a cursor */
	["cursor_ordering"]: cursor_ordering;
	["date"]: unknown;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
	["date_comparison_exp"]: {
		_eq?: ResolverInputTypes["date"] | undefined | null,
		_gt?: ResolverInputTypes["date"] | undefined | null,
		_gte?: ResolverInputTypes["date"] | undefined | null,
		_in?: Array<ResolverInputTypes["date"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: ResolverInputTypes["date"] | undefined | null,
		_lte?: ResolverInputTypes["date"] | undefined | null,
		_neq?: ResolverInputTypes["date"] | undefined | null,
		_nin?: Array<ResolverInputTypes["date"]> | undefined | null
	};
	/** columns and relationships of "deal" */
	["deal"]: AliasType<{
		created_at?: boolean | `@${string}`,
		/** An object relationship */
		dealStatus?: ResolverInputTypes["dealStatus"],
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		interactions?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["interaction_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["interaction_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["interaction_bool_exp"] | undefined | null
		}, ResolverInputTypes["interaction"]],
		interactions_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["interaction_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["interaction_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["interaction_bool_exp"] | undefined | null
		}, ResolverInputTypes["interaction_aggregate"]],
		name?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		/** An object relationship */
		product?: ResolverInputTypes["products"],
		productId?: boolean | `@${string}`,
		rating?: boolean | `@${string}`,
		statusId?: boolean | `@${string}`,
		tasks?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["task_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["task_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["task_bool_exp"] | undefined | null
		}, ResolverInputTypes["task"]],
		tasks_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["task_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["task_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["task_bool_exp"] | undefined | null
		}, ResolverInputTypes["task_aggregate"]],
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "dealStatus" */
	["dealStatus"]: AliasType<{
		deals?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["deal_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["deal_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["deal_bool_exp"] | undefined | null
		}, ResolverInputTypes["deal"]],
		deals_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["deal_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["deal_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["deal_bool_exp"] | undefined | null
		}, ResolverInputTypes["deal_aggregate"]],
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "dealStatus" */
	["dealStatus_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["dealStatus_aggregate_fields"],
		nodes?: ResolverInputTypes["dealStatus"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "dealStatus" */
	["dealStatus_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["dealStatus_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["dealStatus_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["dealStatus_max_fields"],
		min?: ResolverInputTypes["dealStatus_min_fields"],
		stddev?: ResolverInputTypes["dealStatus_stddev_fields"],
		stddev_pop?: ResolverInputTypes["dealStatus_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["dealStatus_stddev_samp_fields"],
		sum?: ResolverInputTypes["dealStatus_sum_fields"],
		var_pop?: ResolverInputTypes["dealStatus_var_pop_fields"],
		var_samp?: ResolverInputTypes["dealStatus_var_samp_fields"],
		variance?: ResolverInputTypes["dealStatus_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["dealStatus_avg_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "dealStatus". All fields are combined with a logical 'AND'. */
	["dealStatus_bool_exp"]: {
		_and?: Array<ResolverInputTypes["dealStatus_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["dealStatus_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["dealStatus_bool_exp"]> | undefined | null,
		deals?: ResolverInputTypes["deal_bool_exp"] | undefined | null,
		deals_aggregate?: ResolverInputTypes["deal_aggregate_bool_exp"] | undefined | null,
		displayName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		order?: ResolverInputTypes["Int_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "dealStatus" */
	["dealStatus_constraint"]: dealStatus_constraint;
	/** input type for incrementing numeric columns in table "dealStatus" */
	["dealStatus_inc_input"]: {
		order?: number | undefined | null
	};
	/** input type for inserting data into table "dealStatus" */
	["dealStatus_insert_input"]: {
		deals?: ResolverInputTypes["deal_arr_rel_insert_input"] | undefined | null,
		displayName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		order?: number | undefined | null
	};
	/** aggregate max on columns */
	["dealStatus_max_fields"]: AliasType<{
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["dealStatus_min_fields"]: AliasType<{
		displayName?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "dealStatus" */
	["dealStatus_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["dealStatus"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "dealStatus" */
	["dealStatus_obj_rel_insert_input"]: {
		data: ResolverInputTypes["dealStatus_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["dealStatus_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "dealStatus" */
	["dealStatus_on_conflict"]: {
		constraint: ResolverInputTypes["dealStatus_constraint"],
		update_columns: Array<ResolverInputTypes["dealStatus_update_column"]>,
		where?: ResolverInputTypes["dealStatus_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "dealStatus". */
	["dealStatus_order_by"]: {
		deals_aggregate?: ResolverInputTypes["deal_aggregate_order_by"] | undefined | null,
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		order?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: dealStatus */
	["dealStatus_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "dealStatus" */
	["dealStatus_select_column"]: dealStatus_select_column;
	/** input type for updating data in table "dealStatus" */
	["dealStatus_set_input"]: {
		displayName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		order?: number | undefined | null
	};
	/** aggregate stddev on columns */
	["dealStatus_stddev_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["dealStatus_stddev_pop_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["dealStatus_stddev_samp_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "dealStatus" */
	["dealStatus_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["dealStatus_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["dealStatus_stream_cursor_value_input"]: {
		displayName?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		order?: number | undefined | null
	};
	/** aggregate sum on columns */
	["dealStatus_sum_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "dealStatus" */
	["dealStatus_update_column"]: dealStatus_update_column;
	["dealStatus_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["dealStatus_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["dealStatus_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["dealStatus_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["dealStatus_var_pop_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["dealStatus_var_samp_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["dealStatus_variance_fields"]: AliasType<{
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "deal" */
	["deal_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["deal_aggregate_fields"],
		nodes?: ResolverInputTypes["deal"],
		__typename?: boolean | `@${string}`
	}>;
	["deal_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["deal_aggregate_bool_exp_count"] | undefined | null
	};
	["deal_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["deal_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["deal_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "deal" */
	["deal_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["deal_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["deal_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["deal_max_fields"],
		min?: ResolverInputTypes["deal_min_fields"],
		stddev?: ResolverInputTypes["deal_stddev_fields"],
		stddev_pop?: ResolverInputTypes["deal_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["deal_stddev_samp_fields"],
		sum?: ResolverInputTypes["deal_sum_fields"],
		var_pop?: ResolverInputTypes["deal_var_pop_fields"],
		var_samp?: ResolverInputTypes["deal_var_samp_fields"],
		variance?: ResolverInputTypes["deal_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "deal" */
	["deal_aggregate_order_by"]: {
		avg?: ResolverInputTypes["deal_avg_order_by"] | undefined | null,
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["deal_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["deal_min_order_by"] | undefined | null,
		stddev?: ResolverInputTypes["deal_stddev_order_by"] | undefined | null,
		stddev_pop?: ResolverInputTypes["deal_stddev_pop_order_by"] | undefined | null,
		stddev_samp?: ResolverInputTypes["deal_stddev_samp_order_by"] | undefined | null,
		sum?: ResolverInputTypes["deal_sum_order_by"] | undefined | null,
		var_pop?: ResolverInputTypes["deal_var_pop_order_by"] | undefined | null,
		var_samp?: ResolverInputTypes["deal_var_samp_order_by"] | undefined | null,
		variance?: ResolverInputTypes["deal_variance_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "deal" */
	["deal_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["deal_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["deal_on_conflict"] | undefined | null
	};
	/** aggregate avg on columns */
	["deal_avg_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "deal" */
	["deal_avg_order_by"]: {
		rating?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "deal". All fields are combined with a logical 'AND'. */
	["deal_bool_exp"]: {
		_and?: Array<ResolverInputTypes["deal_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["deal_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["deal_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		dealStatus?: ResolverInputTypes["dealStatus_bool_exp"] | undefined | null,
		email?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		interactions?: ResolverInputTypes["interaction_bool_exp"] | undefined | null,
		interactions_aggregate?: ResolverInputTypes["interaction_aggregate_bool_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		phone?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		product?: ResolverInputTypes["products_bool_exp"] | undefined | null,
		productId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		rating?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		statusId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		tasks?: ResolverInputTypes["task_bool_exp"] | undefined | null,
		tasks_aggregate?: ResolverInputTypes["task_aggregate_bool_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "deal" */
	["deal_constraint"]: deal_constraint;
	/** input type for incrementing numeric columns in table "deal" */
	["deal_inc_input"]: {
		rating?: number | undefined | null
	};
	/** input type for inserting data into table "deal" */
	["deal_insert_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		dealStatus?: ResolverInputTypes["dealStatus_obj_rel_insert_input"] | undefined | null,
		email?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		interactions?: ResolverInputTypes["interaction_arr_rel_insert_input"] | undefined | null,
		name?: string | undefined | null,
		phone?: string | undefined | null,
		product?: ResolverInputTypes["products_obj_rel_insert_input"] | undefined | null,
		productId?: ResolverInputTypes["uuid"] | undefined | null,
		rating?: number | undefined | null,
		statusId?: ResolverInputTypes["uuid"] | undefined | null,
		tasks?: ResolverInputTypes["task_arr_rel_insert_input"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["deal_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		rating?: boolean | `@${string}`,
		statusId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "deal" */
	["deal_max_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		email?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		phone?: ResolverInputTypes["order_by"] | undefined | null,
		productId?: ResolverInputTypes["order_by"] | undefined | null,
		rating?: ResolverInputTypes["order_by"] | undefined | null,
		statusId?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["deal_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		rating?: boolean | `@${string}`,
		statusId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "deal" */
	["deal_min_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		email?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		phone?: ResolverInputTypes["order_by"] | undefined | null,
		productId?: ResolverInputTypes["order_by"] | undefined | null,
		rating?: ResolverInputTypes["order_by"] | undefined | null,
		statusId?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "deal" */
	["deal_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["deal"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "deal" */
	["deal_obj_rel_insert_input"]: {
		data: ResolverInputTypes["deal_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["deal_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "deal" */
	["deal_on_conflict"]: {
		constraint: ResolverInputTypes["deal_constraint"],
		update_columns: Array<ResolverInputTypes["deal_update_column"]>,
		where?: ResolverInputTypes["deal_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "deal". */
	["deal_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		dealStatus?: ResolverInputTypes["dealStatus_order_by"] | undefined | null,
		email?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		interactions_aggregate?: ResolverInputTypes["interaction_aggregate_order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		phone?: ResolverInputTypes["order_by"] | undefined | null,
		product?: ResolverInputTypes["products_order_by"] | undefined | null,
		productId?: ResolverInputTypes["order_by"] | undefined | null,
		rating?: ResolverInputTypes["order_by"] | undefined | null,
		statusId?: ResolverInputTypes["order_by"] | undefined | null,
		tasks_aggregate?: ResolverInputTypes["task_aggregate_order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: deal */
	["deal_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "deal" */
	["deal_select_column"]: deal_select_column;
	/** input type for updating data in table "deal" */
	["deal_set_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		email?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		phone?: string | undefined | null,
		productId?: ResolverInputTypes["uuid"] | undefined | null,
		rating?: number | undefined | null,
		statusId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate stddev on columns */
	["deal_stddev_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "deal" */
	["deal_stddev_order_by"]: {
		rating?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_pop on columns */
	["deal_stddev_pop_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "deal" */
	["deal_stddev_pop_order_by"]: {
		rating?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_samp on columns */
	["deal_stddev_samp_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "deal" */
	["deal_stddev_samp_order_by"]: {
		rating?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Streaming cursor of the table "deal" */
	["deal_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["deal_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["deal_stream_cursor_value_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		email?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		phone?: string | undefined | null,
		productId?: ResolverInputTypes["uuid"] | undefined | null,
		rating?: number | undefined | null,
		statusId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate sum on columns */
	["deal_sum_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "deal" */
	["deal_sum_order_by"]: {
		rating?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** update columns of table "deal" */
	["deal_update_column"]: deal_update_column;
	["deal_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["deal_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["deal_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["deal_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["deal_var_pop_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "deal" */
	["deal_var_pop_order_by"]: {
		rating?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate var_samp on columns */
	["deal_var_samp_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "deal" */
	["deal_var_samp_order_by"]: {
		rating?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate variance on columns */
	["deal_variance_fields"]: AliasType<{
		rating?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "deal" */
	["deal_variance_order_by"]: {
		rating?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** columns and relationships of "displayCategory" */
	["displayCategory"]: AliasType<{
		created_at?: boolean | `@${string}`,
		displayName?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "displayCategory" */
	["displayCategory_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["displayCategory_aggregate_fields"],
		nodes?: ResolverInputTypes["displayCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "displayCategory" */
	["displayCategory_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["displayCategory_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["displayCategory_max_fields"],
		min?: ResolverInputTypes["displayCategory_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayCategory_append_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "displayCategory". All fields are combined with a logical 'AND'. */
	["displayCategory_bool_exp"]: {
		_and?: Array<ResolverInputTypes["displayCategory_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["displayCategory_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["displayCategory_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		displayName?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "displayCategory" */
	["displayCategory_constraint"]: displayCategory_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayCategory_delete_at_path_input"]: {
		displayName?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayCategory_delete_elem_input"]: {
		displayName?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayCategory_delete_key_input"]: {
		displayName?: string | undefined | null
	};
	/** input type for inserting data into table "displayCategory" */
	["displayCategory_insert_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null
	};
	/** aggregate max on columns */
	["displayCategory_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["displayCategory_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "displayCategory" */
	["displayCategory_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["displayCategory"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "displayCategory" */
	["displayCategory_on_conflict"]: {
		constraint: ResolverInputTypes["displayCategory_constraint"],
		update_columns: Array<ResolverInputTypes["displayCategory_update_column"]>,
		where?: ResolverInputTypes["displayCategory_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "displayCategory". */
	["displayCategory_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: displayCategory */
	["displayCategory_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayCategory_prepend_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "displayCategory" */
	["displayCategory_select_column"]: displayCategory_select_column;
	/** input type for updating data in table "displayCategory" */
	["displayCategory_set_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null
	};
	/** Streaming cursor of the table "displayCategory" */
	["displayCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["displayCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["displayCategory_stream_cursor_value_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null
	};
	/** update columns of table "displayCategory" */
	["displayCategory_update_column"]: displayCategory_update_column;
	["displayCategory_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["displayCategory_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["displayCategory_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["displayCategory_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["displayCategory_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["displayCategory_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["displayCategory_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["displayCategory_bool_exp"]
	};
	/** columns and relationships of "displayDimension" */
	["displayDimension"]: AliasType<{
		displayName?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "displayDimension" */
	["displayDimension_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["displayDimension_aggregate_fields"],
		nodes?: ResolverInputTypes["displayDimension"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "displayDimension" */
	["displayDimension_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["displayDimension_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["displayDimension_max_fields"],
		min?: ResolverInputTypes["displayDimension_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayDimension_append_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "displayDimension". All fields are combined with a logical 'AND'. */
	["displayDimension_bool_exp"]: {
		_and?: Array<ResolverInputTypes["displayDimension_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["displayDimension_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["displayDimension_bool_exp"]> | undefined | null,
		displayName?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "displayDimension" */
	["displayDimension_constraint"]: displayDimension_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayDimension_delete_at_path_input"]: {
		displayName?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayDimension_delete_elem_input"]: {
		displayName?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayDimension_delete_key_input"]: {
		displayName?: string | undefined | null
	};
	/** input type for inserting data into table "displayDimension" */
	["displayDimension_insert_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null
	};
	/** aggregate max on columns */
	["displayDimension_max_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["displayDimension_min_fields"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "displayDimension" */
	["displayDimension_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["displayDimension"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "displayDimension" */
	["displayDimension_obj_rel_insert_input"]: {
		data: ResolverInputTypes["displayDimension_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["displayDimension_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "displayDimension" */
	["displayDimension_on_conflict"]: {
		constraint: ResolverInputTypes["displayDimension_constraint"],
		update_columns: Array<ResolverInputTypes["displayDimension_update_column"]>,
		where?: ResolverInputTypes["displayDimension_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "displayDimension". */
	["displayDimension_order_by"]: {
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: displayDimension */
	["displayDimension_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayDimension_prepend_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "displayDimension" */
	["displayDimension_select_column"]: displayDimension_select_column;
	/** input type for updating data in table "displayDimension" */
	["displayDimension_set_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null
	};
	/** Streaming cursor of the table "displayDimension" */
	["displayDimension_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["displayDimension_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["displayDimension_stream_cursor_value_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null
	};
	/** update columns of table "displayDimension" */
	["displayDimension_update_column"]: displayDimension_update_column;
	["displayDimension_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["displayDimension_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["displayDimension_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["displayDimension_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["displayDimension_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["displayDimension_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["displayDimension_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["displayDimension_bool_exp"]
	};
	/** columns and relationships of "displayFormats" */
	["displayFormats"]: AliasType<{
		bannerbearId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayCategoryId?: boolean | `@${string}`,
		/** An object relationship */
		displayDimension?: ResolverInputTypes["displayDimension"],
		displayDimensionId?: boolean | `@${string}`,
		displayName?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		id?: boolean | `@${string}`,
		mapper?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		name?: boolean | `@${string}`,
		newData?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		newTemplate?: boolean | `@${string}`,
		requiresBrand?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "displayFormats" */
	["displayFormats_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["displayFormats_aggregate_fields"],
		nodes?: ResolverInputTypes["displayFormats"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "displayFormats" */
	["displayFormats_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["displayFormats_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["displayFormats_max_fields"],
		min?: ResolverInputTypes["displayFormats_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayFormats_append_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		mapper?: ResolverInputTypes["jsonb"] | undefined | null,
		newData?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "displayFormats". All fields are combined with a logical 'AND'. */
	["displayFormats_bool_exp"]: {
		_and?: Array<ResolverInputTypes["displayFormats_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["displayFormats_bool_exp"]> | undefined | null,
		bannerbearId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		displayCategoryId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		displayDimension?: ResolverInputTypes["displayDimension_bool_exp"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		displayName?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		mapper?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		newData?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		newTemplate?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		requiresBrand?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "displayFormats" */
	["displayFormats_constraint"]: displayFormats_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayFormats_delete_at_path_input"]: {
		displayName?: Array<string> | undefined | null,
		mapper?: Array<string> | undefined | null,
		newData?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayFormats_delete_elem_input"]: {
		displayName?: number | undefined | null,
		mapper?: number | undefined | null,
		newData?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayFormats_delete_key_input"]: {
		displayName?: string | undefined | null,
		mapper?: string | undefined | null,
		newData?: string | undefined | null
	};
	/** input type for inserting data into table "displayFormats" */
	["displayFormats_insert_input"]: {
		bannerbearId?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		displayCategoryId?: ResolverInputTypes["uuid"] | undefined | null,
		displayDimension?: ResolverInputTypes["displayDimension_obj_rel_insert_input"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["uuid"] | undefined | null,
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		mapper?: ResolverInputTypes["jsonb"] | undefined | null,
		name?: string | undefined | null,
		newData?: ResolverInputTypes["jsonb"] | undefined | null,
		newTemplate?: string | undefined | null,
		requiresBrand?: boolean | undefined | null
	};
	/** aggregate max on columns */
	["displayFormats_max_fields"]: AliasType<{
		bannerbearId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayCategoryId?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplate?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["displayFormats_min_fields"]: AliasType<{
		bannerbearId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		displayCategoryId?: boolean | `@${string}`,
		displayDimensionId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		newTemplate?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "displayFormats" */
	["displayFormats_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["displayFormats"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "displayFormats" */
	["displayFormats_obj_rel_insert_input"]: {
		data: ResolverInputTypes["displayFormats_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["displayFormats_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "displayFormats" */
	["displayFormats_on_conflict"]: {
		constraint: ResolverInputTypes["displayFormats_constraint"],
		update_columns: Array<ResolverInputTypes["displayFormats_update_column"]>,
		where?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "displayFormats". */
	["displayFormats_order_by"]: {
		bannerbearId?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		displayCategoryId?: ResolverInputTypes["order_by"] | undefined | null,
		displayDimension?: ResolverInputTypes["displayDimension_order_by"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["order_by"] | undefined | null,
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		mapper?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		newData?: ResolverInputTypes["order_by"] | undefined | null,
		newTemplate?: ResolverInputTypes["order_by"] | undefined | null,
		requiresBrand?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: displayFormats */
	["displayFormats_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayFormats_prepend_input"]: {
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		mapper?: ResolverInputTypes["jsonb"] | undefined | null,
		newData?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "displayFormats" */
	["displayFormats_select_column"]: displayFormats_select_column;
	/** input type for updating data in table "displayFormats" */
	["displayFormats_set_input"]: {
		bannerbearId?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		displayCategoryId?: ResolverInputTypes["uuid"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["uuid"] | undefined | null,
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		mapper?: ResolverInputTypes["jsonb"] | undefined | null,
		name?: string | undefined | null,
		newData?: ResolverInputTypes["jsonb"] | undefined | null,
		newTemplate?: string | undefined | null,
		requiresBrand?: boolean | undefined | null
	};
	/** Streaming cursor of the table "displayFormats" */
	["displayFormats_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["displayFormats_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["displayFormats_stream_cursor_value_input"]: {
		bannerbearId?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		displayCategoryId?: ResolverInputTypes["uuid"] | undefined | null,
		displayDimensionId?: ResolverInputTypes["uuid"] | undefined | null,
		displayName?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		mapper?: ResolverInputTypes["jsonb"] | undefined | null,
		name?: string | undefined | null,
		newData?: ResolverInputTypes["jsonb"] | undefined | null,
		newTemplate?: string | undefined | null,
		requiresBrand?: boolean | undefined | null
	};
	/** update columns of table "displayFormats" */
	["displayFormats_update_column"]: displayFormats_update_column;
	["displayFormats_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["displayFormats_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["displayFormats_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["displayFormats_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["displayFormats_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["displayFormats_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["displayFormats_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["displayFormats_bool_exp"]
	};
	/** columns and relationships of "displayTemplate" */
	["displayTemplate"]: AliasType<{
		alias?: boolean | `@${string}`,
		fields?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		id?: boolean | `@${string}`,
		template?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "displayTemplate" */
	["displayTemplate_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["displayTemplate_aggregate_fields"],
		nodes?: ResolverInputTypes["displayTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "displayTemplate" */
	["displayTemplate_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["displayTemplate_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["displayTemplate_max_fields"],
		min?: ResolverInputTypes["displayTemplate_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayTemplate_append_input"]: {
		fields?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "displayTemplate". All fields are combined with a logical 'AND'. */
	["displayTemplate_bool_exp"]: {
		_and?: Array<ResolverInputTypes["displayTemplate_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["displayTemplate_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["displayTemplate_bool_exp"]> | undefined | null,
		alias?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		fields?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		template?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "displayTemplate" */
	["displayTemplate_constraint"]: displayTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayTemplate_delete_at_path_input"]: {
		fields?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayTemplate_delete_elem_input"]: {
		fields?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayTemplate_delete_key_input"]: {
		fields?: string | undefined | null
	};
	/** input type for inserting data into table "displayTemplate" */
	["displayTemplate_insert_input"]: {
		alias?: string | undefined | null,
		fields?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		template?: string | undefined | null
	};
	/** aggregate max on columns */
	["displayTemplate_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		template?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["displayTemplate_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		template?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "displayTemplate" */
	["displayTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["displayTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "displayTemplate" */
	["displayTemplate_on_conflict"]: {
		constraint: ResolverInputTypes["displayTemplate_constraint"],
		update_columns: Array<ResolverInputTypes["displayTemplate_update_column"]>,
		where?: ResolverInputTypes["displayTemplate_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "displayTemplate". */
	["displayTemplate_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		fields?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		template?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: displayTemplate */
	["displayTemplate_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayTemplate_prepend_input"]: {
		fields?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "displayTemplate" */
	["displayTemplate_select_column"]: displayTemplate_select_column;
	/** input type for updating data in table "displayTemplate" */
	["displayTemplate_set_input"]: {
		alias?: string | undefined | null,
		fields?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		template?: string | undefined | null
	};
	/** Streaming cursor of the table "displayTemplate" */
	["displayTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["displayTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["displayTemplate_stream_cursor_value_input"]: {
		alias?: string | undefined | null,
		fields?: ResolverInputTypes["jsonb"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		template?: string | undefined | null
	};
	/** update columns of table "displayTemplate" */
	["displayTemplate_update_column"]: displayTemplate_update_column;
	["displayTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["displayTemplate_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["displayTemplate_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["displayTemplate_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["displayTemplate_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["displayTemplate_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["displayTemplate_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["displayTemplate_bool_exp"]
	};
	["facebookPage"]: AliasType<{
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "storage.files" */
	["files"]: AliasType<{
		/** An object relationship */
		bucket?: ResolverInputTypes["buckets"],
		bucketId?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		etag?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		isUploaded?: boolean | `@${string}`,
		metadata?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		mimeType?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		size?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		uploadedByUserId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "storage.files" */
	["files_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["files_aggregate_fields"],
		nodes?: ResolverInputTypes["files"],
		__typename?: boolean | `@${string}`
	}>;
	["files_aggregate_bool_exp"]: {
		bool_and?: ResolverInputTypes["files_aggregate_bool_exp_bool_and"] | undefined | null,
		bool_or?: ResolverInputTypes["files_aggregate_bool_exp_bool_or"] | undefined | null,
		count?: ResolverInputTypes["files_aggregate_bool_exp_count"] | undefined | null
	};
	["files_aggregate_bool_exp_bool_and"]: {
		arguments: ResolverInputTypes["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["files_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["files_aggregate_bool_exp_bool_or"]: {
		arguments: ResolverInputTypes["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["files_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["files_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["files_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["files_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "storage.files" */
	["files_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["files_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["files_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["files_max_fields"],
		min?: ResolverInputTypes["files_min_fields"],
		stddev?: ResolverInputTypes["files_stddev_fields"],
		stddev_pop?: ResolverInputTypes["files_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["files_stddev_samp_fields"],
		sum?: ResolverInputTypes["files_sum_fields"],
		var_pop?: ResolverInputTypes["files_var_pop_fields"],
		var_samp?: ResolverInputTypes["files_var_samp_fields"],
		variance?: ResolverInputTypes["files_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "storage.files" */
	["files_aggregate_order_by"]: {
		avg?: ResolverInputTypes["files_avg_order_by"] | undefined | null,
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["files_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["files_min_order_by"] | undefined | null,
		stddev?: ResolverInputTypes["files_stddev_order_by"] | undefined | null,
		stddev_pop?: ResolverInputTypes["files_stddev_pop_order_by"] | undefined | null,
		stddev_samp?: ResolverInputTypes["files_stddev_samp_order_by"] | undefined | null,
		sum?: ResolverInputTypes["files_sum_order_by"] | undefined | null,
		var_pop?: ResolverInputTypes["files_var_pop_order_by"] | undefined | null,
		var_samp?: ResolverInputTypes["files_var_samp_order_by"] | undefined | null,
		variance?: ResolverInputTypes["files_variance_order_by"] | undefined | null
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["files_append_input"]: {
		metadata?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** input type for inserting array relation for remote table "storage.files" */
	["files_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["files_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["files_on_conflict"] | undefined | null
	};
	/** aggregate avg on columns */
	["files_avg_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "storage.files" */
	["files_avg_order_by"]: {
		size?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
	["files_bool_exp"]: {
		_and?: Array<ResolverInputTypes["files_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["files_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["files_bool_exp"]> | undefined | null,
		bucket?: ResolverInputTypes["buckets_bool_exp"] | undefined | null,
		bucketId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		etag?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		isUploaded?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		metadata?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		mimeType?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		size?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		uploadedByUserId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "storage.files" */
	["files_constraint"]: files_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["files_delete_at_path_input"]: {
		metadata?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["files_delete_elem_input"]: {
		metadata?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["files_delete_key_input"]: {
		metadata?: string | undefined | null
	};
	/** input type for incrementing numeric columns in table "storage.files" */
	["files_inc_input"]: {
		size?: number | undefined | null
	};
	/** input type for inserting data into table "storage.files" */
	["files_insert_input"]: {
		bucket?: ResolverInputTypes["buckets_obj_rel_insert_input"] | undefined | null,
		bucketId?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		etag?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		isUploaded?: boolean | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		mimeType?: string | undefined | null,
		name?: string | undefined | null,
		size?: number | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		uploadedByUserId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["files_max_fields"]: AliasType<{
		bucketId?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		etag?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mimeType?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		size?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		uploadedByUserId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "storage.files" */
	["files_max_order_by"]: {
		bucketId?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		etag?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		mimeType?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		size?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null,
		uploadedByUserId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["files_min_fields"]: AliasType<{
		bucketId?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		etag?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mimeType?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		size?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		uploadedByUserId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "storage.files" */
	["files_min_order_by"]: {
		bucketId?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		etag?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		mimeType?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		size?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null,
		uploadedByUserId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "storage.files" */
	["files_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["files"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "storage.files" */
	["files_obj_rel_insert_input"]: {
		data: ResolverInputTypes["files_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["files_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "storage.files" */
	["files_on_conflict"]: {
		constraint: ResolverInputTypes["files_constraint"],
		update_columns: Array<ResolverInputTypes["files_update_column"]>,
		where?: ResolverInputTypes["files_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "storage.files". */
	["files_order_by"]: {
		bucket?: ResolverInputTypes["buckets_order_by"] | undefined | null,
		bucketId?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		etag?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		isUploaded?: ResolverInputTypes["order_by"] | undefined | null,
		metadata?: ResolverInputTypes["order_by"] | undefined | null,
		mimeType?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		size?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null,
		uploadedByUserId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: storage.files */
	["files_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["files_prepend_input"]: {
		metadata?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "storage.files" */
	["files_select_column"]: files_select_column;
	/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage.files" */
	["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"]: files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage.files" */
	["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"]: files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "storage.files" */
	["files_set_input"]: {
		bucketId?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		etag?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		isUploaded?: boolean | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		mimeType?: string | undefined | null,
		name?: string | undefined | null,
		size?: number | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		uploadedByUserId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate stddev on columns */
	["files_stddev_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "storage.files" */
	["files_stddev_order_by"]: {
		size?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_pop on columns */
	["files_stddev_pop_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "storage.files" */
	["files_stddev_pop_order_by"]: {
		size?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_samp on columns */
	["files_stddev_samp_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "storage.files" */
	["files_stddev_samp_order_by"]: {
		size?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Streaming cursor of the table "files" */
	["files_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["files_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["files_stream_cursor_value_input"]: {
		bucketId?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		etag?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		isUploaded?: boolean | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		mimeType?: string | undefined | null,
		name?: string | undefined | null,
		size?: number | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		uploadedByUserId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate sum on columns */
	["files_sum_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "storage.files" */
	["files_sum_order_by"]: {
		size?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** update columns of table "storage.files" */
	["files_update_column"]: files_update_column;
	["files_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["files_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["files_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["files_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["files_delete_key_input"] | undefined | null,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["files_inc_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["files_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["files_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["files_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["files_var_pop_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "storage.files" */
	["files_var_pop_order_by"]: {
		size?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate var_samp on columns */
	["files_var_samp_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "storage.files" */
	["files_var_samp_order_by"]: {
		size?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate variance on columns */
	["files_variance_fields"]: AliasType<{
		size?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "storage.files" */
	["files_variance_order_by"]: {
		size?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** fields of action: "generateNextMonthCalendar" */
	["generateNextMonthCalendar"]: AliasType<{
		/** the time at which this action was created */
		created_at?: boolean | `@${string}`,
		/** errors related to the invocation */
		errors?: boolean | `@${string}`,
		/** the unique id of an action */
		id?: boolean | `@${string}`,
		/** the output fields of this action */
		output?: ResolverInputTypes["GenerateNextMonthCalendarOutput"],
		__typename?: boolean | `@${string}`
	}>;
	["instagramPageAccount"]: AliasType<{
		id?: boolean | `@${string}`,
		instagramBusinessAccountId?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "interaction" */
	["interaction"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		observation?: boolean | `@${string}`,
		success?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		/** An object relationship */
		user?: ResolverInputTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "interaction" */
	["interaction_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["interaction_aggregate_fields"],
		nodes?: ResolverInputTypes["interaction"],
		__typename?: boolean | `@${string}`
	}>;
	["interaction_aggregate_bool_exp"]: {
		bool_and?: ResolverInputTypes["interaction_aggregate_bool_exp_bool_and"] | undefined | null,
		bool_or?: ResolverInputTypes["interaction_aggregate_bool_exp_bool_or"] | undefined | null,
		count?: ResolverInputTypes["interaction_aggregate_bool_exp_count"] | undefined | null
	};
	["interaction_aggregate_bool_exp_bool_and"]: {
		arguments: ResolverInputTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["interaction_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["interaction_aggregate_bool_exp_bool_or"]: {
		arguments: ResolverInputTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["interaction_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["interaction_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["interaction_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["interaction_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "interaction" */
	["interaction_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["interaction_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["interaction_max_fields"],
		min?: ResolverInputTypes["interaction_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "interaction" */
	["interaction_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["interaction_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["interaction_min_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "interaction" */
	["interaction_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["interaction_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["interaction_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "interaction". All fields are combined with a logical 'AND'. */
	["interaction_bool_exp"]: {
		_and?: Array<ResolverInputTypes["interaction_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["interaction_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["interaction_bool_exp"]> | undefined | null,
		channel?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		date?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		dealId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		observation?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		success?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		user?: ResolverInputTypes["users_bool_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "interaction" */
	["interaction_constraint"]: interaction_constraint;
	/** input type for inserting data into table "interaction" */
	["interaction_insert_input"]: {
		channel?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		date?: ResolverInputTypes["timestamptz"] | undefined | null,
		dealId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		observation?: string | undefined | null,
		success?: boolean | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		user?: ResolverInputTypes["users_obj_rel_insert_input"] | undefined | null
	};
	/** aggregate max on columns */
	["interaction_max_fields"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		observation?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "interaction" */
	["interaction_max_order_by"]: {
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		date?: ResolverInputTypes["order_by"] | undefined | null,
		dealId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		observation?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["interaction_min_fields"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		observation?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "interaction" */
	["interaction_min_order_by"]: {
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		date?: ResolverInputTypes["order_by"] | undefined | null,
		dealId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		observation?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "interaction" */
	["interaction_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["interaction"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "interaction" */
	["interaction_on_conflict"]: {
		constraint: ResolverInputTypes["interaction_constraint"],
		update_columns: Array<ResolverInputTypes["interaction_update_column"]>,
		where?: ResolverInputTypes["interaction_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "interaction". */
	["interaction_order_by"]: {
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		date?: ResolverInputTypes["order_by"] | undefined | null,
		dealId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		observation?: ResolverInputTypes["order_by"] | undefined | null,
		success?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		user?: ResolverInputTypes["users_order_by"] | undefined | null
	};
	/** primary key columns input for table: interaction */
	["interaction_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "interaction" */
	["interaction_select_column"]: interaction_select_column;
	/** select "interaction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "interaction" */
	["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"]: interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "interaction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "interaction" */
	["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"]: interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "interaction" */
	["interaction_set_input"]: {
		channel?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		date?: ResolverInputTypes["timestamptz"] | undefined | null,
		dealId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		observation?: string | undefined | null,
		success?: boolean | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "interaction" */
	["interaction_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["interaction_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["interaction_stream_cursor_value_input"]: {
		channel?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		date?: ResolverInputTypes["timestamptz"] | undefined | null,
		dealId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		observation?: string | undefined | null,
		success?: boolean | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "interaction" */
	["interaction_update_column"]: interaction_update_column;
	["interaction_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["interaction_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["interaction_bool_exp"]
	};
	["json"]: unknown;
	["jsonb"]: unknown;
	["jsonb_cast_exp"]: {
		String?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
	["jsonb_comparison_exp"]: {
		_cast?: ResolverInputTypes["jsonb_cast_exp"] | undefined | null,
		/** is the column contained in the given json value */
		_contained_in?: ResolverInputTypes["jsonb"] | undefined | null,
		/** does the column contain the given json value at the top level */
		_contains?: ResolverInputTypes["jsonb"] | undefined | null,
		_eq?: ResolverInputTypes["jsonb"] | undefined | null,
		_gt?: ResolverInputTypes["jsonb"] | undefined | null,
		_gte?: ResolverInputTypes["jsonb"] | undefined | null,
		/** does the string exist as a top-level key in the column */
		_has_key?: string | undefined | null,
		/** do all of these strings exist as top-level keys in the column */
		_has_keys_all?: Array<string> | undefined | null,
		/** do any of these strings exist as top-level keys in the column */
		_has_keys_any?: Array<string> | undefined | null,
		_in?: Array<ResolverInputTypes["jsonb"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: ResolverInputTypes["jsonb"] | undefined | null,
		_lte?: ResolverInputTypes["jsonb"] | undefined | null,
		_neq?: ResolverInputTypes["jsonb"] | undefined | null,
		_nin?: Array<ResolverInputTypes["jsonb"]> | undefined | null
	};
	/** columns and relationships of "landingPageTemplate" */
	["landingPageTemplate"]: AliasType<{
		created_at?: boolean | `@${string}`,
		htmlTemplate?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "landingPageTemplate" */
	["landingPageTemplate_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["landingPageTemplate_aggregate_fields"],
		nodes?: ResolverInputTypes["landingPageTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "landingPageTemplate" */
	["landingPageTemplate_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["landingPageTemplate_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["landingPageTemplate_max_fields"],
		min?: ResolverInputTypes["landingPageTemplate_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "landingPageTemplate". All fields are combined with a logical 'AND'. */
	["landingPageTemplate_bool_exp"]: {
		_and?: Array<ResolverInputTypes["landingPageTemplate_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["landingPageTemplate_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["landingPageTemplate_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		htmlTemplate?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "landingPageTemplate" */
	["landingPageTemplate_constraint"]: landingPageTemplate_constraint;
	/** input type for inserting data into table "landingPageTemplate" */
	["landingPageTemplate_insert_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		htmlTemplate?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["landingPageTemplate_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		htmlTemplate?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["landingPageTemplate_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		htmlTemplate?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "landingPageTemplate" */
	["landingPageTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["landingPageTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "landingPageTemplate" */
	["landingPageTemplate_on_conflict"]: {
		constraint: ResolverInputTypes["landingPageTemplate_constraint"],
		update_columns: Array<ResolverInputTypes["landingPageTemplate_update_column"]>,
		where?: ResolverInputTypes["landingPageTemplate_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "landingPageTemplate". */
	["landingPageTemplate_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		htmlTemplate?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: landingPageTemplate */
	["landingPageTemplate_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "landingPageTemplate" */
	["landingPageTemplate_select_column"]: landingPageTemplate_select_column;
	/** input type for updating data in table "landingPageTemplate" */
	["landingPageTemplate_set_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		htmlTemplate?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "landingPageTemplate" */
	["landingPageTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["landingPageTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["landingPageTemplate_stream_cursor_value_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		htmlTemplate?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "landingPageTemplate" */
	["landingPageTemplate_update_column"]: landingPageTemplate_update_column;
	["landingPageTemplate_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["landingPageTemplate_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["landingPageTemplate_bool_exp"]
	};
	/** mutation root */
	["mutation_root"]: AliasType<{
		blogPostGeneratorEnhancedForAmp?: [{ brandName?: string | undefined | null, brief?: string | undefined | null, category?: string | undefined | null, format?: string | undefined | null, productName?: string | undefined | null, textStyle?: string | undefined | null, title?: string | undefined | null, voiceTone?: string | undefined | null }, boolean | `@${string}`],
		createAutomaticProject?: [{ artDirectional?: string | undefined | null, audienceId?: string | undefined | null, brandId?: string | undefined | null, displayDimensionId?: string | undefined | null, productId?: string | undefined | null, selectedImage?: string | undefined | null, selectedTemplateId?: string | undefined | null }, ResolverInputTypes["CreateAutomaticProjectOutput"]],
		createImageUsingImagine?: [{ aspectRatio: string, prompt: string, style?: number | undefined | null }, ResolverInputTypes["CreateImageUsingImagineOutput"]],
		deleteAuthProvider?: [{ id: string }, ResolverInputTypes["authProviders"]],
		deleteAuthProviderRequest?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authProviderRequests"]],
		deleteAuthProviderRequests?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["authProviderRequests_bool_exp"]
		}, ResolverInputTypes["authProviderRequests_mutation_response"]],
		deleteAuthProviders?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["authProviders_bool_exp"]
		}, ResolverInputTypes["authProviders_mutation_response"]],
		deleteAuthRefreshToken?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authRefreshTokens"]],
		deleteAuthRefreshTokenType?: [{ value: string }, ResolverInputTypes["authRefreshTokenTypes"]],
		deleteAuthRefreshTokenTypes?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["authRefreshTokenTypes_bool_exp"]
		}, ResolverInputTypes["authRefreshTokenTypes_mutation_response"]],
		deleteAuthRefreshTokens?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["authRefreshTokens_bool_exp"]
		}, ResolverInputTypes["authRefreshTokens_mutation_response"]],
		deleteAuthRole?: [{ role: string }, ResolverInputTypes["authRoles"]],
		deleteAuthRoles?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["authRoles_bool_exp"]
		}, ResolverInputTypes["authRoles_mutation_response"]],
		deleteAuthUserProvider?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserProviders"]],
		deleteAuthUserProviders?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["authUserProviders_bool_exp"]
		}, ResolverInputTypes["authUserProviders_mutation_response"]],
		deleteAuthUserRole?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserRoles"]],
		deleteAuthUserRoles?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["authUserRoles_bool_exp"]
		}, ResolverInputTypes["authUserRoles_mutation_response"]],
		deleteAuthUserSecurityKey?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserSecurityKeys"]],
		deleteAuthUserSecurityKeys?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["authUserSecurityKeys_bool_exp"]
		}, ResolverInputTypes["authUserSecurityKeys_mutation_response"]],
		deleteBucket?: [{ id: string }, ResolverInputTypes["buckets"]],
		deleteBuckets?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["buckets_bool_exp"]
		}, ResolverInputTypes["buckets_mutation_response"]],
		deleteFile?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["files"]],
		deleteFiles?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["files_bool_exp"]
		}, ResolverInputTypes["files_mutation_response"]],
		deleteUser?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["users"]],
		deleteUsers?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["users_bool_exp"]
		}, ResolverInputTypes["users_mutation_response"]],
		deleteVirus?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["virus"]],
		deleteViruses?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["virus_bool_exp"]
		}, ResolverInputTypes["virus_mutation_response"]],
		delete_AppIntegration?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["AppIntegration_bool_exp"]
		}, ResolverInputTypes["AppIntegration_mutation_response"]],
		delete_AppIntegration_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["AppIntegration"]],
		delete_Course?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["Course_bool_exp"]
		}, ResolverInputTypes["Course_mutation_response"]],
		delete_CourseCategory?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["CourseCategory_bool_exp"]
		}, ResolverInputTypes["CourseCategory_mutation_response"]],
		delete_CourseCategory_by_pk?: [{ id: ResolverInputTypes["bigint"] }, ResolverInputTypes["CourseCategory"]],
		delete_Course_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["Course"]],
		delete_GeneratedPromptByTemplate?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"]
		}, ResolverInputTypes["GeneratedPromptByTemplate_mutation_response"]],
		delete_GeneratedPromptByTemplateImage?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"]
		}, ResolverInputTypes["GeneratedPromptByTemplateImage_mutation_response"]],
		delete_GeneratedPromptByTemplateImage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		delete_GeneratedPromptByTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["GeneratedPromptByTemplate"]],
		delete_TextGeneratorOption?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["TextGeneratorOption_bool_exp"]
		}, ResolverInputTypes["TextGeneratorOption_mutation_response"]],
		delete_TextGeneratorOptionEntry?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"]
		}, ResolverInputTypes["TextGeneratorOptionEntry_mutation_response"]],
		delete_TextGeneratorOptionEntry_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextGeneratorOptionEntry"]],
		delete_TextGeneratorOption_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextGeneratorOption"]],
		delete_TextPromptTemplate?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["TextPromptTemplate_bool_exp"]
		}, ResolverInputTypes["TextPromptTemplate_mutation_response"]],
		delete_TextPromptTemplateCategory?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"]
		}, ResolverInputTypes["TextPromptTemplateCategory_mutation_response"]],
		delete_TextPromptTemplateCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextPromptTemplateCategory"]],
		delete_TextPromptTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextPromptTemplate"]],
		delete_TextTemplateClientCategory?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["TextTemplateClientCategory_bool_exp"]
		}, ResolverInputTypes["TextTemplateClientCategory_mutation_response"]],
		delete_TextTemplateClientCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextTemplateClientCategory"]],
		delete_audiences?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["audiences_bool_exp"]
		}, ResolverInputTypes["audiences_mutation_response"]],
		delete_audiences_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["audiences"]],
		delete_blog?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["blog_bool_exp"]
		}, ResolverInputTypes["blog_mutation_response"]],
		delete_blogCategory?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["blogCategory_bool_exp"]
		}, ResolverInputTypes["blogCategory_mutation_response"]],
		delete_blogCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["blogCategory"]],
		delete_blog_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["blog"]],
		delete_brands?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["brands_bool_exp"]
		}, ResolverInputTypes["brands_mutation_response"]],
		delete_brands_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["brands"]],
		delete_campaign?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["campaign_bool_exp"]
		}, ResolverInputTypes["campaign_mutation_response"]],
		delete_campaign_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["campaign"]],
		delete_channels?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["channels_bool_exp"]
		}, ResolverInputTypes["channels_mutation_response"]],
		delete_channels_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["channels"]],
		delete_costTable?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["costTable_bool_exp"]
		}, ResolverInputTypes["costTable_mutation_response"]],
		delete_costTable_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["costTable"]],
		delete_deal?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["deal_bool_exp"]
		}, ResolverInputTypes["deal_mutation_response"]],
		delete_dealStatus?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["dealStatus_bool_exp"]
		}, ResolverInputTypes["dealStatus_mutation_response"]],
		delete_dealStatus_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["dealStatus"]],
		delete_deal_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["deal"]],
		delete_displayCategory?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["displayCategory_bool_exp"]
		}, ResolverInputTypes["displayCategory_mutation_response"]],
		delete_displayCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayCategory"]],
		delete_displayDimension?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["displayDimension_bool_exp"]
		}, ResolverInputTypes["displayDimension_mutation_response"]],
		delete_displayDimension_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayDimension"]],
		delete_displayFormats?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["displayFormats_bool_exp"]
		}, ResolverInputTypes["displayFormats_mutation_response"]],
		delete_displayFormats_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayFormats"]],
		delete_displayTemplate?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["displayTemplate_bool_exp"]
		}, ResolverInputTypes["displayTemplate_mutation_response"]],
		delete_displayTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayTemplate"]],
		delete_interaction?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["interaction_bool_exp"]
		}, ResolverInputTypes["interaction_mutation_response"]],
		delete_interaction_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["interaction"]],
		delete_landingPageTemplate?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["landingPageTemplate_bool_exp"]
		}, ResolverInputTypes["landingPageTemplate_mutation_response"]],
		delete_landingPageTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["landingPageTemplate"]],
		delete_newTemplateType?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["newTemplateType_bool_exp"]
		}, ResolverInputTypes["newTemplateType_mutation_response"]],
		delete_newTemplateType_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["newTemplateType"]],
		delete_openAIApiKey?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["openAIApiKey_bool_exp"]
		}, ResolverInputTypes["openAIApiKey_mutation_response"]],
		delete_openAIApiKey_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["openAIApiKey"]],
		delete_pTemplate?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["pTemplate_bool_exp"]
		}, ResolverInputTypes["pTemplate_mutation_response"]],
		delete_pTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["pTemplate"]],
		delete_pricingPlan?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["pricingPlan_bool_exp"]
		}, ResolverInputTypes["pricingPlan_mutation_response"]],
		delete_pricingPlan_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["pricingPlan"]],
		delete_products?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["products_bool_exp"]
		}, ResolverInputTypes["products_mutation_response"]],
		delete_products_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["products"]],
		delete_project?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["project_bool_exp"]
		}, ResolverInputTypes["project_mutation_response"]],
		delete_projectImage?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["projectImage_bool_exp"]
		}, ResolverInputTypes["projectImage_mutation_response"]],
		delete_projectImage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["projectImage"]],
		delete_project_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["project"]],
		delete_task?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["task_bool_exp"]
		}, ResolverInputTypes["task_mutation_response"]],
		delete_task_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["task"]],
		delete_textTemplateDisplayFormat?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"]
		}, ResolverInputTypes["textTemplateDisplayFormat_mutation_response"]],
		delete_textTemplateDisplayFormat_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["textTemplateDisplayFormat"]],
		delete_userAppIntegration?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userAppIntegration_bool_exp"]
		}, ResolverInputTypes["userAppIntegration_mutation_response"]],
		delete_userAppIntegration_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userAppIntegration"]],
		delete_userCreditUsage?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userCreditUsage_bool_exp"]
		}, ResolverInputTypes["userCreditUsage_mutation_response"]],
		delete_userCreditUsageSummary?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userCreditUsageSummary_bool_exp"]
		}, ResolverInputTypes["userCreditUsageSummary_mutation_response"]],
		delete_userCreditUsageSummary_by_pk?: [{ created_by: ResolverInputTypes["uuid"], yearMonthDayReference: string }, ResolverInputTypes["userCreditUsageSummary"]],
		delete_userCreditUsage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userCreditUsage"]],
		delete_userCredits?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userCredits_bool_exp"]
		}, ResolverInputTypes["userCredits_mutation_response"]],
		delete_userCredits_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userCredits"]],
		delete_userFeedback?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userFeedback_bool_exp"]
		}, ResolverInputTypes["userFeedback_mutation_response"]],
		delete_userFeedback_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userFeedback"]],
		delete_userMedia?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userMedia_bool_exp"]
		}, ResolverInputTypes["userMedia_mutation_response"]],
		delete_userMedia_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userMedia"]],
		delete_userMetadata?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userMetadata_bool_exp"]
		}, ResolverInputTypes["userMetadata_mutation_response"]],
		delete_userMetadata_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userMetadata"]],
		delete_userOnboarding?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userOnboarding_bool_exp"]
		}, ResolverInputTypes["userOnboarding_mutation_response"]],
		delete_userOnboarding_by_pk?: [{ userId: ResolverInputTypes["uuid"] }, ResolverInputTypes["userOnboarding"]],
		delete_userTemplateUsage?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["userTemplateUsage_bool_exp"]
		}, ResolverInputTypes["userTemplateUsage_mutation_response"]],
		delete_userTemplateUsage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userTemplateUsage"]],
		delete_website?: [{	/** filter the rows which have to be deleted */
			where: ResolverInputTypes["website_bool_exp"]
		}, ResolverInputTypes["website_mutation_response"]],
		delete_website_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["website"]],
		exportToWordpress?: [{ command: ResolverInputTypes["ExportToWordpressInput"] }, ResolverInputTypes["ExportToWordpressOutput"]],
		facebookAuthCallback?: [{ brandId: string, code: string }, ResolverInputTypes["FacebookAuthCallbackOutput"]],
		finishOnboard?: ResolverInputTypes["FinishOnboardOutput"],
		generateCalendarEx?: [{ audiencesIds?: Array<string | undefined | null> | undefined | null, brandId: string, campaignObjective: string, productIds?: Array<string | undefined | null> | undefined | null }, ResolverInputTypes["GenerateCalendarExOutput"]],
		generateFromTextTemplate?: [{ command: ResolverInputTypes["GenerateFromTextTemplateInput"] }, ResolverInputTypes["GenerateFromTextTemplateOutput"]],
		generateNextMonthCalendar?: [{ command: ResolverInputTypes["GenerateNextMonthCalendarInput"] }, boolean | `@${string}`],
		getInstagramAuthCallback?: [{ code: string }, ResolverInputTypes["GetInstagramAuthCallbackOutput"]],
		get_next_openai_apikey?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["openAIApiKey_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["openAIApiKey_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["openAIApiKey_bool_exp"] | undefined | null
		}, ResolverInputTypes["openAIApiKey"]],
		insertAuthProvider?: [{	/** the row to be inserted */
			object: ResolverInputTypes["authProviders_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["authProviders_on_conflict"] | undefined | null
		}, ResolverInputTypes["authProviders"]],
		insertAuthProviderRequest?: [{	/** the row to be inserted */
			object: ResolverInputTypes["authProviderRequests_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["authProviderRequests_on_conflict"] | undefined | null
		}, ResolverInputTypes["authProviderRequests"]],
		insertAuthProviderRequests?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["authProviderRequests_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["authProviderRequests_on_conflict"] | undefined | null
		}, ResolverInputTypes["authProviderRequests_mutation_response"]],
		insertAuthProviders?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["authProviders_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["authProviders_on_conflict"] | undefined | null
		}, ResolverInputTypes["authProviders_mutation_response"]],
		insertAuthRefreshToken?: [{	/** the row to be inserted */
			object: ResolverInputTypes["authRefreshTokens_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["authRefreshTokens_on_conflict"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens"]],
		insertAuthRefreshTokenType?: [{	/** the row to be inserted */
			object: ResolverInputTypes["authRefreshTokenTypes_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["authRefreshTokenTypes_on_conflict"] | undefined | null
		}, ResolverInputTypes["authRefreshTokenTypes"]],
		insertAuthRefreshTokenTypes?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["authRefreshTokenTypes_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["authRefreshTokenTypes_on_conflict"] | undefined | null
		}, ResolverInputTypes["authRefreshTokenTypes_mutation_response"]],
		insertAuthRefreshTokens?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["authRefreshTokens_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["authRefreshTokens_on_conflict"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens_mutation_response"]],
		insertAuthRole?: [{	/** the row to be inserted */
			object: ResolverInputTypes["authRoles_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["authRoles_on_conflict"] | undefined | null
		}, ResolverInputTypes["authRoles"]],
		insertAuthRoles?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["authRoles_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["authRoles_on_conflict"] | undefined | null
		}, ResolverInputTypes["authRoles_mutation_response"]],
		insertAuthUserProvider?: [{	/** the row to be inserted */
			object: ResolverInputTypes["authUserProviders_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["authUserProviders_on_conflict"] | undefined | null
		}, ResolverInputTypes["authUserProviders"]],
		insertAuthUserProviders?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["authUserProviders_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["authUserProviders_on_conflict"] | undefined | null
		}, ResolverInputTypes["authUserProviders_mutation_response"]],
		insertAuthUserRole?: [{	/** the row to be inserted */
			object: ResolverInputTypes["authUserRoles_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["authUserRoles_on_conflict"] | undefined | null
		}, ResolverInputTypes["authUserRoles"]],
		insertAuthUserRoles?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["authUserRoles_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["authUserRoles_on_conflict"] | undefined | null
		}, ResolverInputTypes["authUserRoles_mutation_response"]],
		insertAuthUserSecurityKey?: [{	/** the row to be inserted */
			object: ResolverInputTypes["authUserSecurityKeys_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["authUserSecurityKeys_on_conflict"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys"]],
		insertAuthUserSecurityKeys?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["authUserSecurityKeys_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["authUserSecurityKeys_on_conflict"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys_mutation_response"]],
		insertBucket?: [{	/** the row to be inserted */
			object: ResolverInputTypes["buckets_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["buckets_on_conflict"] | undefined | null
		}, ResolverInputTypes["buckets"]],
		insertBuckets?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["buckets_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["buckets_on_conflict"] | undefined | null
		}, ResolverInputTypes["buckets_mutation_response"]],
		insertFile?: [{	/** the row to be inserted */
			object: ResolverInputTypes["files_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["files_on_conflict"] | undefined | null
		}, ResolverInputTypes["files"]],
		insertFiles?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["files_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["files_on_conflict"] | undefined | null
		}, ResolverInputTypes["files_mutation_response"]],
		insertUser?: [{	/** the row to be inserted */
			object: ResolverInputTypes["users_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["users_on_conflict"] | undefined | null
		}, ResolverInputTypes["users"]],
		insertUsers?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["users_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["users_on_conflict"] | undefined | null
		}, ResolverInputTypes["users_mutation_response"]],
		insertVirus?: [{	/** the row to be inserted */
			object: ResolverInputTypes["virus_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["virus_on_conflict"] | undefined | null
		}, ResolverInputTypes["virus"]],
		insertViruses?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["virus_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["virus_on_conflict"] | undefined | null
		}, ResolverInputTypes["virus_mutation_response"]],
		insert_AppIntegration?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["AppIntegration_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["AppIntegration_on_conflict"] | undefined | null
		}, ResolverInputTypes["AppIntegration_mutation_response"]],
		insert_AppIntegration_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["AppIntegration_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["AppIntegration_on_conflict"] | undefined | null
		}, ResolverInputTypes["AppIntegration"]],
		insert_Course?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["Course_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["Course_on_conflict"] | undefined | null
		}, ResolverInputTypes["Course_mutation_response"]],
		insert_CourseCategory?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["CourseCategory_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["CourseCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["CourseCategory_mutation_response"]],
		insert_CourseCategory_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["CourseCategory_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["CourseCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["CourseCategory"]],
		insert_Course_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["Course_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["Course_on_conflict"] | undefined | null
		}, ResolverInputTypes["Course"]],
		insert_GeneratedPromptByTemplate?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["GeneratedPromptByTemplate_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["GeneratedPromptByTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate_mutation_response"]],
		insert_GeneratedPromptByTemplateImage?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["GeneratedPromptByTemplateImage_on_conflict"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage_mutation_response"]],
		insert_GeneratedPromptByTemplateImage_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["GeneratedPromptByTemplateImage_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["GeneratedPromptByTemplateImage_on_conflict"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		insert_GeneratedPromptByTemplate_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["GeneratedPromptByTemplate_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["GeneratedPromptByTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate"]],
		insert_TextGeneratorOption?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["TextGeneratorOption_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextGeneratorOption_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOption_mutation_response"]],
		insert_TextGeneratorOptionEntry?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["TextGeneratorOptionEntry_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextGeneratorOptionEntry_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry_mutation_response"]],
		insert_TextGeneratorOptionEntry_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["TextGeneratorOptionEntry_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextGeneratorOptionEntry_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry"]],
		insert_TextGeneratorOption_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["TextGeneratorOption_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextGeneratorOption_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOption"]],
		insert_TextPromptTemplate?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["TextPromptTemplate_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextPromptTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate_mutation_response"]],
		insert_TextPromptTemplateCategory?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["TextPromptTemplateCategory_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextPromptTemplateCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplateCategory_mutation_response"]],
		insert_TextPromptTemplateCategory_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["TextPromptTemplateCategory_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextPromptTemplateCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplateCategory"]],
		insert_TextPromptTemplate_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["TextPromptTemplate_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextPromptTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate"]],
		insert_TextTemplateClientCategory?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["TextTemplateClientCategory_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextTemplateClientCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextTemplateClientCategory_mutation_response"]],
		insert_TextTemplateClientCategory_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["TextTemplateClientCategory_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["TextTemplateClientCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["TextTemplateClientCategory"]],
		insert_audiences?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["audiences_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["audiences_on_conflict"] | undefined | null
		}, ResolverInputTypes["audiences_mutation_response"]],
		insert_audiences_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["audiences_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["audiences_on_conflict"] | undefined | null
		}, ResolverInputTypes["audiences"]],
		insert_blog?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["blog_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["blog_on_conflict"] | undefined | null
		}, ResolverInputTypes["blog_mutation_response"]],
		insert_blogCategory?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["blogCategory_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["blogCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["blogCategory_mutation_response"]],
		insert_blogCategory_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["blogCategory_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["blogCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["blogCategory"]],
		insert_blog_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["blog_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["blog_on_conflict"] | undefined | null
		}, ResolverInputTypes["blog"]],
		insert_brands?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["brands_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["brands_on_conflict"] | undefined | null
		}, ResolverInputTypes["brands_mutation_response"]],
		insert_brands_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["brands_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["brands_on_conflict"] | undefined | null
		}, ResolverInputTypes["brands"]],
		insert_campaign?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["campaign_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["campaign_on_conflict"] | undefined | null
		}, ResolverInputTypes["campaign_mutation_response"]],
		insert_campaign_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["campaign_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["campaign_on_conflict"] | undefined | null
		}, ResolverInputTypes["campaign"]],
		insert_channels?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["channels_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["channels_on_conflict"] | undefined | null
		}, ResolverInputTypes["channels_mutation_response"]],
		insert_channels_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["channels_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["channels_on_conflict"] | undefined | null
		}, ResolverInputTypes["channels"]],
		insert_costTable?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["costTable_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["costTable_on_conflict"] | undefined | null
		}, ResolverInputTypes["costTable_mutation_response"]],
		insert_costTable_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["costTable_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["costTable_on_conflict"] | undefined | null
		}, ResolverInputTypes["costTable"]],
		insert_deal?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["deal_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["deal_on_conflict"] | undefined | null
		}, ResolverInputTypes["deal_mutation_response"]],
		insert_dealStatus?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["dealStatus_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["dealStatus_on_conflict"] | undefined | null
		}, ResolverInputTypes["dealStatus_mutation_response"]],
		insert_dealStatus_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["dealStatus_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["dealStatus_on_conflict"] | undefined | null
		}, ResolverInputTypes["dealStatus"]],
		insert_deal_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["deal_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["deal_on_conflict"] | undefined | null
		}, ResolverInputTypes["deal"]],
		insert_displayCategory?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["displayCategory_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["displayCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["displayCategory_mutation_response"]],
		insert_displayCategory_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["displayCategory_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["displayCategory_on_conflict"] | undefined | null
		}, ResolverInputTypes["displayCategory"]],
		insert_displayDimension?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["displayDimension_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["displayDimension_on_conflict"] | undefined | null
		}, ResolverInputTypes["displayDimension_mutation_response"]],
		insert_displayDimension_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["displayDimension_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["displayDimension_on_conflict"] | undefined | null
		}, ResolverInputTypes["displayDimension"]],
		insert_displayFormats?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["displayFormats_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["displayFormats_on_conflict"] | undefined | null
		}, ResolverInputTypes["displayFormats_mutation_response"]],
		insert_displayFormats_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["displayFormats_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["displayFormats_on_conflict"] | undefined | null
		}, ResolverInputTypes["displayFormats"]],
		insert_displayTemplate?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["displayTemplate_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["displayTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["displayTemplate_mutation_response"]],
		insert_displayTemplate_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["displayTemplate_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["displayTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["displayTemplate"]],
		insert_interaction?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["interaction_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["interaction_on_conflict"] | undefined | null
		}, ResolverInputTypes["interaction_mutation_response"]],
		insert_interaction_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["interaction_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["interaction_on_conflict"] | undefined | null
		}, ResolverInputTypes["interaction"]],
		insert_landingPageTemplate?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["landingPageTemplate_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["landingPageTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["landingPageTemplate_mutation_response"]],
		insert_landingPageTemplate_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["landingPageTemplate_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["landingPageTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["landingPageTemplate"]],
		insert_newTemplateType?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["newTemplateType_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["newTemplateType_on_conflict"] | undefined | null
		}, ResolverInputTypes["newTemplateType_mutation_response"]],
		insert_newTemplateType_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["newTemplateType_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["newTemplateType_on_conflict"] | undefined | null
		}, ResolverInputTypes["newTemplateType"]],
		insert_openAIApiKey?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["openAIApiKey_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["openAIApiKey_on_conflict"] | undefined | null
		}, ResolverInputTypes["openAIApiKey_mutation_response"]],
		insert_openAIApiKey_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["openAIApiKey_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["openAIApiKey_on_conflict"] | undefined | null
		}, ResolverInputTypes["openAIApiKey"]],
		insert_pTemplate?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["pTemplate_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["pTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["pTemplate_mutation_response"]],
		insert_pTemplate_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["pTemplate_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["pTemplate_on_conflict"] | undefined | null
		}, ResolverInputTypes["pTemplate"]],
		insert_pricingPlan?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["pricingPlan_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["pricingPlan_on_conflict"] | undefined | null
		}, ResolverInputTypes["pricingPlan_mutation_response"]],
		insert_pricingPlan_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["pricingPlan_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["pricingPlan_on_conflict"] | undefined | null
		}, ResolverInputTypes["pricingPlan"]],
		insert_products?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["products_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["products_on_conflict"] | undefined | null
		}, ResolverInputTypes["products_mutation_response"]],
		insert_products_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["products_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["products_on_conflict"] | undefined | null
		}, ResolverInputTypes["products"]],
		insert_project?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["project_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["project_on_conflict"] | undefined | null
		}, ResolverInputTypes["project_mutation_response"]],
		insert_projectImage?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["projectImage_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["projectImage_on_conflict"] | undefined | null
		}, ResolverInputTypes["projectImage_mutation_response"]],
		insert_projectImage_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["projectImage_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["projectImage_on_conflict"] | undefined | null
		}, ResolverInputTypes["projectImage"]],
		insert_project_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["project_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["project_on_conflict"] | undefined | null
		}, ResolverInputTypes["project"]],
		insert_task?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["task_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["task_on_conflict"] | undefined | null
		}, ResolverInputTypes["task_mutation_response"]],
		insert_task_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["task_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["task_on_conflict"] | undefined | null
		}, ResolverInputTypes["task"]],
		insert_textTemplateDisplayFormat?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["textTemplateDisplayFormat_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["textTemplateDisplayFormat_on_conflict"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat_mutation_response"]],
		insert_textTemplateDisplayFormat_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["textTemplateDisplayFormat_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["textTemplateDisplayFormat_on_conflict"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat"]],
		insert_userAppIntegration?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userAppIntegration_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userAppIntegration_on_conflict"] | undefined | null
		}, ResolverInputTypes["userAppIntegration_mutation_response"]],
		insert_userAppIntegration_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userAppIntegration_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userAppIntegration_on_conflict"] | undefined | null
		}, ResolverInputTypes["userAppIntegration"]],
		insert_userCreditUsage?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userCreditUsage_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userCreditUsage_on_conflict"] | undefined | null
		}, ResolverInputTypes["userCreditUsage_mutation_response"]],
		insert_userCreditUsageSummary?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userCreditUsageSummary_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userCreditUsageSummary_on_conflict"] | undefined | null
		}, ResolverInputTypes["userCreditUsageSummary_mutation_response"]],
		insert_userCreditUsageSummary_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userCreditUsageSummary_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userCreditUsageSummary_on_conflict"] | undefined | null
		}, ResolverInputTypes["userCreditUsageSummary"]],
		insert_userCreditUsage_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userCreditUsage_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userCreditUsage_on_conflict"] | undefined | null
		}, ResolverInputTypes["userCreditUsage"]],
		insert_userCredits?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userCredits_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userCredits_on_conflict"] | undefined | null
		}, ResolverInputTypes["userCredits_mutation_response"]],
		insert_userCredits_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userCredits_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userCredits_on_conflict"] | undefined | null
		}, ResolverInputTypes["userCredits"]],
		insert_userFeedback?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userFeedback_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userFeedback_on_conflict"] | undefined | null
		}, ResolverInputTypes["userFeedback_mutation_response"]],
		insert_userFeedback_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userFeedback_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userFeedback_on_conflict"] | undefined | null
		}, ResolverInputTypes["userFeedback"]],
		insert_userMedia?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userMedia_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userMedia_on_conflict"] | undefined | null
		}, ResolverInputTypes["userMedia_mutation_response"]],
		insert_userMedia_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userMedia_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userMedia_on_conflict"] | undefined | null
		}, ResolverInputTypes["userMedia"]],
		insert_userMetadata?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userMetadata_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userMetadata_on_conflict"] | undefined | null
		}, ResolverInputTypes["userMetadata_mutation_response"]],
		insert_userMetadata_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userMetadata_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userMetadata_on_conflict"] | undefined | null
		}, ResolverInputTypes["userMetadata"]],
		insert_userOnboarding?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userOnboarding_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userOnboarding_on_conflict"] | undefined | null
		}, ResolverInputTypes["userOnboarding_mutation_response"]],
		insert_userOnboarding_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userOnboarding_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userOnboarding_on_conflict"] | undefined | null
		}, ResolverInputTypes["userOnboarding"]],
		insert_userTemplateUsage?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["userTemplateUsage_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["userTemplateUsage_on_conflict"] | undefined | null
		}, ResolverInputTypes["userTemplateUsage_mutation_response"]],
		insert_userTemplateUsage_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["userTemplateUsage_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["userTemplateUsage_on_conflict"] | undefined | null
		}, ResolverInputTypes["userTemplateUsage"]],
		insert_website?: [{	/** the rows to be inserted */
			objects: Array<ResolverInputTypes["website_insert_input"]>,	/** upsert condition */
			on_conflict?: ResolverInputTypes["website_on_conflict"] | undefined | null
		}, ResolverInputTypes["website_mutation_response"]],
		insert_website_one?: [{	/** the row to be inserted */
			object: ResolverInputTypes["website_insert_input"],	/** upsert condition */
			on_conflict?: ResolverInputTypes["website_on_conflict"] | undefined | null
		}, ResolverInputTypes["website"]],
		linkedInTokenCallback?: [{ brandId?: string | undefined | null, code: string }, ResolverInputTypes["LinkedInTokenCallbackOutput"]],
		postCarouselToInstagram?: [{ businessId: string, generatedByTemplateId: string, integrationId: string }, ResolverInputTypes["PostCarouselToInstagramOutput"]],
		postFeedToInstagram?: [{ businessId: string, generatedByTemplateId: string, integrationId: string, scheduledDate?: ResolverInputTypes["date"] | undefined | null }, ResolverInputTypes["PostFeedToInstagramOutput"]],
		postStoryToInstagram?: [{ businessId: string, generatedByTemplateId: string, integrationId: string }, ResolverInputTypes["PostStoryToInstagramOutput"]],
		postToFacebook?: [{ generatedPromptByTemplateId: string, integrationId: string, pageId: string, scheduledDate?: ResolverInputTypes["date"] | undefined | null }, ResolverInputTypes["PostToFacebookOutput"]],
		postToLinkedIn?: [{ generatedPromptByTemplateId: string, integrationId: string, scheduledDate?: ResolverInputTypes["date"] | undefined | null }, ResolverInputTypes["PostToLinkedInOutput"]],
		publishOrScheduleProject?: [{ blogCategory?: number | undefined | null, blogTags?: Array<number | undefined | null> | undefined | null, channel: string, facebookPageId?: string | undefined | null, instagramBusinessId?: string | undefined | null, integrationId: string, projectId: string, scheduledDate?: ResolverInputTypes["date"] | undefined | null, subtype: string }, ResolverInputTypes["PublishOrScheduleProjectOutput"]],
		purePrompt?: [{ prompt: string }, ResolverInputTypes["PromptOutput"]],
		renderAndSaveDisplayImage?: [{ data: string, generatedPromptByTemplateId: string, template: string }, ResolverInputTypes["RenderAndSaveDisplayImageOutput"]],
		saveAndGeneratePolotnoImage?: [{ projectId: string }, ResolverInputTypes["SaveAndGeneratePolotnoImageOutput"]],
		selectNewDisplay?: [{ alias?: string | undefined | null, audienceId?: string | undefined | null, backgroundImageFileId?: string | undefined | null, backgroundImageUrl?: string | undefined | null, brandId?: string | undefined | null, channel?: string | undefined | null, displayDimensionId?: string | undefined | null, displayFormatId?: string | undefined | null, flexText?: string | undefined | null, productId?: string | undefined | null, textPromptTemplateId?: string | undefined | null }, ResolverInputTypes["SelectNewDisplayOutput"]],
		updateAuthProvider?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authProviders_set_input"] | undefined | null, pk_columns: ResolverInputTypes["authProviders_pk_columns_input"]
		}, ResolverInputTypes["authProviders"]],
		updateAuthProviderRequest?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["authProviderRequests_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["authProviderRequests_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["authProviderRequests_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["authProviderRequests_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["authProviderRequests_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authProviderRequests_set_input"] | undefined | null, pk_columns: ResolverInputTypes["authProviderRequests_pk_columns_input"]
		}, ResolverInputTypes["authProviderRequests"]],
		updateAuthProviderRequests?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["authProviderRequests_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["authProviderRequests_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["authProviderRequests_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["authProviderRequests_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["authProviderRequests_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authProviderRequests_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["authProviderRequests_bool_exp"]
		}, ResolverInputTypes["authProviderRequests_mutation_response"]],
		updateAuthProviders?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authProviders_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["authProviders_bool_exp"]
		}, ResolverInputTypes["authProviders_mutation_response"]],
		updateAuthRefreshToken?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["authRefreshTokens_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["authRefreshTokens_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["authRefreshTokens_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["authRefreshTokens_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["authRefreshTokens_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authRefreshTokens_set_input"] | undefined | null, pk_columns: ResolverInputTypes["authRefreshTokens_pk_columns_input"]
		}, ResolverInputTypes["authRefreshTokens"]],
		updateAuthRefreshTokenType?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authRefreshTokenTypes_set_input"] | undefined | null, pk_columns: ResolverInputTypes["authRefreshTokenTypes_pk_columns_input"]
		}, ResolverInputTypes["authRefreshTokenTypes"]],
		updateAuthRefreshTokenTypes?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authRefreshTokenTypes_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["authRefreshTokenTypes_bool_exp"]
		}, ResolverInputTypes["authRefreshTokenTypes_mutation_response"]],
		updateAuthRefreshTokens?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["authRefreshTokens_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["authRefreshTokens_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["authRefreshTokens_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["authRefreshTokens_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["authRefreshTokens_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authRefreshTokens_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["authRefreshTokens_bool_exp"]
		}, ResolverInputTypes["authRefreshTokens_mutation_response"]],
		updateAuthRole?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authRoles_set_input"] | undefined | null, pk_columns: ResolverInputTypes["authRoles_pk_columns_input"]
		}, ResolverInputTypes["authRoles"]],
		updateAuthRoles?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authRoles_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["authRoles_bool_exp"]
		}, ResolverInputTypes["authRoles_mutation_response"]],
		updateAuthUserProvider?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authUserProviders_set_input"] | undefined | null, pk_columns: ResolverInputTypes["authUserProviders_pk_columns_input"]
		}, ResolverInputTypes["authUserProviders"]],
		updateAuthUserProviders?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authUserProviders_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["authUserProviders_bool_exp"]
		}, ResolverInputTypes["authUserProviders_mutation_response"]],
		updateAuthUserRole?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authUserRoles_set_input"] | undefined | null, pk_columns: ResolverInputTypes["authUserRoles_pk_columns_input"]
		}, ResolverInputTypes["authUserRoles"]],
		updateAuthUserRoles?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authUserRoles_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["authUserRoles_bool_exp"]
		}, ResolverInputTypes["authUserRoles_mutation_response"]],
		updateAuthUserSecurityKey?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["authUserSecurityKeys_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authUserSecurityKeys_set_input"] | undefined | null, pk_columns: ResolverInputTypes["authUserSecurityKeys_pk_columns_input"]
		}, ResolverInputTypes["authUserSecurityKeys"]],
		updateAuthUserSecurityKeys?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["authUserSecurityKeys_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["authUserSecurityKeys_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["authUserSecurityKeys_bool_exp"]
		}, ResolverInputTypes["authUserSecurityKeys_mutation_response"]],
		updateBucket?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["buckets_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["buckets_set_input"] | undefined | null, pk_columns: ResolverInputTypes["buckets_pk_columns_input"]
		}, ResolverInputTypes["buckets"]],
		updateBuckets?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["buckets_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["buckets_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["buckets_bool_exp"]
		}, ResolverInputTypes["buckets_mutation_response"]],
		updateFile?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["files_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["files_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["files_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["files_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["files_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["files_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["files_set_input"] | undefined | null, pk_columns: ResolverInputTypes["files_pk_columns_input"]
		}, ResolverInputTypes["files"]],
		updateFiles?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["files_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["files_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["files_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["files_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["files_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["files_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["files_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["files_bool_exp"]
		}, ResolverInputTypes["files_mutation_response"]],
		updateUser?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["users_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["users_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["users_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["users_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["users_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["users_set_input"] | undefined | null, pk_columns: ResolverInputTypes["users_pk_columns_input"]
		}, ResolverInputTypes["users"]],
		updateUserAvatar?: [{ avatarAsBase64?: string | undefined | null }, ResolverInputTypes["UpdateUserAvatarOutput"]],
		updateUsers?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["users_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["users_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["users_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["users_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["users_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["users_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["users_bool_exp"]
		}, ResolverInputTypes["users_mutation_response"]],
		updateVirus?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["virus_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["virus_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["virus_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["virus_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["virus_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["virus_set_input"] | undefined | null, pk_columns: ResolverInputTypes["virus_pk_columns_input"]
		}, ResolverInputTypes["virus"]],
		updateViruses?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["virus_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["virus_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["virus_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["virus_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["virus_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["virus_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["virus_bool_exp"]
		}, ResolverInputTypes["virus_mutation_response"]],
		update_AppIntegration?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["AppIntegration_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["AppIntegration_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["AppIntegration_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["AppIntegration_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["AppIntegration_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["AppIntegration_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["AppIntegration_bool_exp"]
		}, ResolverInputTypes["AppIntegration_mutation_response"]],
		update_AppIntegration_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["AppIntegration_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["AppIntegration_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["AppIntegration_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["AppIntegration_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["AppIntegration_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["AppIntegration_set_input"] | undefined | null, pk_columns: ResolverInputTypes["AppIntegration_pk_columns_input"]
		}, ResolverInputTypes["AppIntegration"]],
		update_AppIntegration_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["AppIntegration_updates"]>
		}, ResolverInputTypes["AppIntegration_mutation_response"]],
		update_Course?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["Course_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["Course_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["Course_bool_exp"]
		}, ResolverInputTypes["Course_mutation_response"]],
		update_CourseCategory?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["CourseCategory_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["CourseCategory_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["CourseCategory_bool_exp"]
		}, ResolverInputTypes["CourseCategory_mutation_response"]],
		update_CourseCategory_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["CourseCategory_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["CourseCategory_set_input"] | undefined | null, pk_columns: ResolverInputTypes["CourseCategory_pk_columns_input"]
		}, ResolverInputTypes["CourseCategory"]],
		update_CourseCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["CourseCategory_updates"]>
		}, ResolverInputTypes["CourseCategory_mutation_response"]],
		update_Course_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["Course_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["Course_set_input"] | undefined | null, pk_columns: ResolverInputTypes["Course_pk_columns_input"]
		}, ResolverInputTypes["Course"]],
		update_Course_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["Course_updates"]>
		}, ResolverInputTypes["Course_mutation_response"]],
		update_GeneratedPromptByTemplate?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["GeneratedPromptByTemplate_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["GeneratedPromptByTemplate_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["GeneratedPromptByTemplate_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["GeneratedPromptByTemplate_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["GeneratedPromptByTemplate_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["GeneratedPromptByTemplate_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"]
		}, ResolverInputTypes["GeneratedPromptByTemplate_mutation_response"]],
		update_GeneratedPromptByTemplateImage?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["GeneratedPromptByTemplateImage_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"]
		}, ResolverInputTypes["GeneratedPromptByTemplateImage_mutation_response"]],
		update_GeneratedPromptByTemplateImage_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["GeneratedPromptByTemplateImage_set_input"] | undefined | null, pk_columns: ResolverInputTypes["GeneratedPromptByTemplateImage_pk_columns_input"]
		}, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		update_GeneratedPromptByTemplateImage_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_updates"]>
		}, ResolverInputTypes["GeneratedPromptByTemplateImage_mutation_response"]],
		update_GeneratedPromptByTemplate_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["GeneratedPromptByTemplate_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["GeneratedPromptByTemplate_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["GeneratedPromptByTemplate_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["GeneratedPromptByTemplate_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["GeneratedPromptByTemplate_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["GeneratedPromptByTemplate_set_input"] | undefined | null, pk_columns: ResolverInputTypes["GeneratedPromptByTemplate_pk_columns_input"]
		}, ResolverInputTypes["GeneratedPromptByTemplate"]],
		update_GeneratedPromptByTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["GeneratedPromptByTemplate_updates"]>
		}, ResolverInputTypes["GeneratedPromptByTemplate_mutation_response"]],
		update_TextGeneratorOption?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["TextGeneratorOption_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["TextGeneratorOption_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["TextGeneratorOption_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["TextGeneratorOption_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["TextGeneratorOption_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextGeneratorOption_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["TextGeneratorOption_bool_exp"]
		}, ResolverInputTypes["TextGeneratorOption_mutation_response"]],
		update_TextGeneratorOptionEntry?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["TextGeneratorOptionEntry_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["TextGeneratorOptionEntry_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["TextGeneratorOptionEntry_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["TextGeneratorOptionEntry_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["TextGeneratorOptionEntry_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextGeneratorOptionEntry_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"]
		}, ResolverInputTypes["TextGeneratorOptionEntry_mutation_response"]],
		update_TextGeneratorOptionEntry_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["TextGeneratorOptionEntry_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["TextGeneratorOptionEntry_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["TextGeneratorOptionEntry_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["TextGeneratorOptionEntry_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["TextGeneratorOptionEntry_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextGeneratorOptionEntry_set_input"] | undefined | null, pk_columns: ResolverInputTypes["TextGeneratorOptionEntry_pk_columns_input"]
		}, ResolverInputTypes["TextGeneratorOptionEntry"]],
		update_TextGeneratorOptionEntry_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["TextGeneratorOptionEntry_updates"]>
		}, ResolverInputTypes["TextGeneratorOptionEntry_mutation_response"]],
		update_TextGeneratorOption_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["TextGeneratorOption_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["TextGeneratorOption_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["TextGeneratorOption_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["TextGeneratorOption_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["TextGeneratorOption_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextGeneratorOption_set_input"] | undefined | null, pk_columns: ResolverInputTypes["TextGeneratorOption_pk_columns_input"]
		}, ResolverInputTypes["TextGeneratorOption"]],
		update_TextGeneratorOption_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["TextGeneratorOption_updates"]>
		}, ResolverInputTypes["TextGeneratorOption_mutation_response"]],
		update_TextPromptTemplate?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["TextPromptTemplate_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["TextPromptTemplate_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["TextPromptTemplate_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["TextPromptTemplate_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["TextPromptTemplate_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["TextPromptTemplate_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextPromptTemplate_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["TextPromptTemplate_bool_exp"]
		}, ResolverInputTypes["TextPromptTemplate_mutation_response"]],
		update_TextPromptTemplateCategory?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextPromptTemplateCategory_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"]
		}, ResolverInputTypes["TextPromptTemplateCategory_mutation_response"]],
		update_TextPromptTemplateCategory_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextPromptTemplateCategory_set_input"] | undefined | null, pk_columns: ResolverInputTypes["TextPromptTemplateCategory_pk_columns_input"]
		}, ResolverInputTypes["TextPromptTemplateCategory"]],
		update_TextPromptTemplateCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["TextPromptTemplateCategory_updates"]>
		}, ResolverInputTypes["TextPromptTemplateCategory_mutation_response"]],
		update_TextPromptTemplate_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["TextPromptTemplate_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["TextPromptTemplate_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["TextPromptTemplate_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["TextPromptTemplate_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["TextPromptTemplate_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["TextPromptTemplate_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextPromptTemplate_set_input"] | undefined | null, pk_columns: ResolverInputTypes["TextPromptTemplate_pk_columns_input"]
		}, ResolverInputTypes["TextPromptTemplate"]],
		update_TextPromptTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["TextPromptTemplate_updates"]>
		}, ResolverInputTypes["TextPromptTemplate_mutation_response"]],
		update_TextTemplateClientCategory?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextTemplateClientCategory_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["TextTemplateClientCategory_bool_exp"]
		}, ResolverInputTypes["TextTemplateClientCategory_mutation_response"]],
		update_TextTemplateClientCategory_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["TextTemplateClientCategory_set_input"] | undefined | null, pk_columns: ResolverInputTypes["TextTemplateClientCategory_pk_columns_input"]
		}, ResolverInputTypes["TextTemplateClientCategory"]],
		update_TextTemplateClientCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["TextTemplateClientCategory_updates"]>
		}, ResolverInputTypes["TextTemplateClientCategory_mutation_response"]],
		update_audiences?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["audiences_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["audiences_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["audiences_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["audiences_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["audiences_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["audiences_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["audiences_bool_exp"]
		}, ResolverInputTypes["audiences_mutation_response"]],
		update_audiences_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["audiences_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["audiences_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["audiences_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["audiences_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["audiences_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["audiences_set_input"] | undefined | null, pk_columns: ResolverInputTypes["audiences_pk_columns_input"]
		}, ResolverInputTypes["audiences"]],
		update_audiences_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["audiences_updates"]>
		}, ResolverInputTypes["audiences_mutation_response"]],
		update_authProviderRequests_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["authProviderRequests_updates"]>
		}, ResolverInputTypes["authProviderRequests_mutation_response"]],
		update_authProviders_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["authProviders_updates"]>
		}, ResolverInputTypes["authProviders_mutation_response"]],
		update_authRefreshTokenTypes_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["authRefreshTokenTypes_updates"]>
		}, ResolverInputTypes["authRefreshTokenTypes_mutation_response"]],
		update_authRefreshTokens_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["authRefreshTokens_updates"]>
		}, ResolverInputTypes["authRefreshTokens_mutation_response"]],
		update_authRoles_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["authRoles_updates"]>
		}, ResolverInputTypes["authRoles_mutation_response"]],
		update_authUserProviders_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["authUserProviders_updates"]>
		}, ResolverInputTypes["authUserProviders_mutation_response"]],
		update_authUserRoles_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["authUserRoles_updates"]>
		}, ResolverInputTypes["authUserRoles_mutation_response"]],
		update_authUserSecurityKeys_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["authUserSecurityKeys_updates"]>
		}, ResolverInputTypes["authUserSecurityKeys_mutation_response"]],
		update_blog?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["blog_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["blog_bool_exp"]
		}, ResolverInputTypes["blog_mutation_response"]],
		update_blogCategory?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["blogCategory_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["blogCategory_bool_exp"]
		}, ResolverInputTypes["blogCategory_mutation_response"]],
		update_blogCategory_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["blogCategory_set_input"] | undefined | null, pk_columns: ResolverInputTypes["blogCategory_pk_columns_input"]
		}, ResolverInputTypes["blogCategory"]],
		update_blogCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["blogCategory_updates"]>
		}, ResolverInputTypes["blogCategory_mutation_response"]],
		update_blog_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["blog_set_input"] | undefined | null, pk_columns: ResolverInputTypes["blog_pk_columns_input"]
		}, ResolverInputTypes["blog"]],
		update_blog_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["blog_updates"]>
		}, ResolverInputTypes["blog_mutation_response"]],
		update_brands?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["brands_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["brands_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["brands_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["brands_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["brands_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["brands_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["brands_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["brands_bool_exp"]
		}, ResolverInputTypes["brands_mutation_response"]],
		update_brands_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["brands_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["brands_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["brands_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["brands_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["brands_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["brands_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["brands_set_input"] | undefined | null, pk_columns: ResolverInputTypes["brands_pk_columns_input"]
		}, ResolverInputTypes["brands"]],
		update_brands_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["brands_updates"]>
		}, ResolverInputTypes["brands_mutation_response"]],
		update_buckets_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["buckets_updates"]>
		}, ResolverInputTypes["buckets_mutation_response"]],
		update_campaign?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["campaign_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["campaign_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["campaign_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["campaign_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["campaign_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["campaign_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["campaign_bool_exp"]
		}, ResolverInputTypes["campaign_mutation_response"]],
		update_campaign_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["campaign_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["campaign_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["campaign_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["campaign_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["campaign_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["campaign_set_input"] | undefined | null, pk_columns: ResolverInputTypes["campaign_pk_columns_input"]
		}, ResolverInputTypes["campaign"]],
		update_campaign_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["campaign_updates"]>
		}, ResolverInputTypes["campaign_mutation_response"]],
		update_channels?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["channels_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["channels_bool_exp"]
		}, ResolverInputTypes["channels_mutation_response"]],
		update_channels_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["channels_set_input"] | undefined | null, pk_columns: ResolverInputTypes["channels_pk_columns_input"]
		}, ResolverInputTypes["channels"]],
		update_channels_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["channels_updates"]>
		}, ResolverInputTypes["channels_mutation_response"]],
		update_costTable?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["costTable_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["costTable_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["costTable_bool_exp"]
		}, ResolverInputTypes["costTable_mutation_response"]],
		update_costTable_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["costTable_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["costTable_set_input"] | undefined | null, pk_columns: ResolverInputTypes["costTable_pk_columns_input"]
		}, ResolverInputTypes["costTable"]],
		update_costTable_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["costTable_updates"]>
		}, ResolverInputTypes["costTable_mutation_response"]],
		update_deal?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["deal_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["deal_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["deal_bool_exp"]
		}, ResolverInputTypes["deal_mutation_response"]],
		update_dealStatus?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["dealStatus_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["dealStatus_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["dealStatus_bool_exp"]
		}, ResolverInputTypes["dealStatus_mutation_response"]],
		update_dealStatus_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["dealStatus_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["dealStatus_set_input"] | undefined | null, pk_columns: ResolverInputTypes["dealStatus_pk_columns_input"]
		}, ResolverInputTypes["dealStatus"]],
		update_dealStatus_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["dealStatus_updates"]>
		}, ResolverInputTypes["dealStatus_mutation_response"]],
		update_deal_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["deal_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["deal_set_input"] | undefined | null, pk_columns: ResolverInputTypes["deal_pk_columns_input"]
		}, ResolverInputTypes["deal"]],
		update_deal_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["deal_updates"]>
		}, ResolverInputTypes["deal_mutation_response"]],
		update_displayCategory?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["displayCategory_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["displayCategory_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["displayCategory_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["displayCategory_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["displayCategory_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["displayCategory_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["displayCategory_bool_exp"]
		}, ResolverInputTypes["displayCategory_mutation_response"]],
		update_displayCategory_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["displayCategory_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["displayCategory_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["displayCategory_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["displayCategory_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["displayCategory_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["displayCategory_set_input"] | undefined | null, pk_columns: ResolverInputTypes["displayCategory_pk_columns_input"]
		}, ResolverInputTypes["displayCategory"]],
		update_displayCategory_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["displayCategory_updates"]>
		}, ResolverInputTypes["displayCategory_mutation_response"]],
		update_displayDimension?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["displayDimension_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["displayDimension_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["displayDimension_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["displayDimension_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["displayDimension_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["displayDimension_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["displayDimension_bool_exp"]
		}, ResolverInputTypes["displayDimension_mutation_response"]],
		update_displayDimension_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["displayDimension_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["displayDimension_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["displayDimension_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["displayDimension_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["displayDimension_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["displayDimension_set_input"] | undefined | null, pk_columns: ResolverInputTypes["displayDimension_pk_columns_input"]
		}, ResolverInputTypes["displayDimension"]],
		update_displayDimension_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["displayDimension_updates"]>
		}, ResolverInputTypes["displayDimension_mutation_response"]],
		update_displayFormats?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["displayFormats_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["displayFormats_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["displayFormats_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["displayFormats_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["displayFormats_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["displayFormats_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["displayFormats_bool_exp"]
		}, ResolverInputTypes["displayFormats_mutation_response"]],
		update_displayFormats_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["displayFormats_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["displayFormats_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["displayFormats_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["displayFormats_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["displayFormats_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["displayFormats_set_input"] | undefined | null, pk_columns: ResolverInputTypes["displayFormats_pk_columns_input"]
		}, ResolverInputTypes["displayFormats"]],
		update_displayFormats_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["displayFormats_updates"]>
		}, ResolverInputTypes["displayFormats_mutation_response"]],
		update_displayTemplate?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["displayTemplate_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["displayTemplate_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["displayTemplate_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["displayTemplate_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["displayTemplate_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["displayTemplate_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["displayTemplate_bool_exp"]
		}, ResolverInputTypes["displayTemplate_mutation_response"]],
		update_displayTemplate_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["displayTemplate_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["displayTemplate_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["displayTemplate_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["displayTemplate_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["displayTemplate_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["displayTemplate_set_input"] | undefined | null, pk_columns: ResolverInputTypes["displayTemplate_pk_columns_input"]
		}, ResolverInputTypes["displayTemplate"]],
		update_displayTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["displayTemplate_updates"]>
		}, ResolverInputTypes["displayTemplate_mutation_response"]],
		update_files_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["files_updates"]>
		}, ResolverInputTypes["files_mutation_response"]],
		update_interaction?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["interaction_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["interaction_bool_exp"]
		}, ResolverInputTypes["interaction_mutation_response"]],
		update_interaction_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["interaction_set_input"] | undefined | null, pk_columns: ResolverInputTypes["interaction_pk_columns_input"]
		}, ResolverInputTypes["interaction"]],
		update_interaction_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["interaction_updates"]>
		}, ResolverInputTypes["interaction_mutation_response"]],
		update_landingPageTemplate?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["landingPageTemplate_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["landingPageTemplate_bool_exp"]
		}, ResolverInputTypes["landingPageTemplate_mutation_response"]],
		update_landingPageTemplate_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["landingPageTemplate_set_input"] | undefined | null, pk_columns: ResolverInputTypes["landingPageTemplate_pk_columns_input"]
		}, ResolverInputTypes["landingPageTemplate"]],
		update_landingPageTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["landingPageTemplate_updates"]>
		}, ResolverInputTypes["landingPageTemplate_mutation_response"]],
		update_newTemplateType?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["newTemplateType_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["newTemplateType_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["newTemplateType_bool_exp"]
		}, ResolverInputTypes["newTemplateType_mutation_response"]],
		update_newTemplateType_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["newTemplateType_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["newTemplateType_set_input"] | undefined | null, pk_columns: ResolverInputTypes["newTemplateType_pk_columns_input"]
		}, ResolverInputTypes["newTemplateType"]],
		update_newTemplateType_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["newTemplateType_updates"]>
		}, ResolverInputTypes["newTemplateType_mutation_response"]],
		update_openAIApiKey?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["openAIApiKey_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["openAIApiKey_bool_exp"]
		}, ResolverInputTypes["openAIApiKey_mutation_response"]],
		update_openAIApiKey_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["openAIApiKey_set_input"] | undefined | null, pk_columns: ResolverInputTypes["openAIApiKey_pk_columns_input"]
		}, ResolverInputTypes["openAIApiKey"]],
		update_openAIApiKey_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["openAIApiKey_updates"]>
		}, ResolverInputTypes["openAIApiKey_mutation_response"]],
		update_pTemplate?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["pTemplate_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["pTemplate_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["pTemplate_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["pTemplate_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["pTemplate_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["pTemplate_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["pTemplate_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["pTemplate_bool_exp"]
		}, ResolverInputTypes["pTemplate_mutation_response"]],
		update_pTemplate_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["pTemplate_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["pTemplate_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["pTemplate_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["pTemplate_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["pTemplate_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["pTemplate_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["pTemplate_set_input"] | undefined | null, pk_columns: ResolverInputTypes["pTemplate_pk_columns_input"]
		}, ResolverInputTypes["pTemplate"]],
		update_pTemplate_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["pTemplate_updates"]>
		}, ResolverInputTypes["pTemplate_mutation_response"]],
		update_pricingPlan?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["pricingPlan_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["pricingPlan_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["pricingPlan_bool_exp"]
		}, ResolverInputTypes["pricingPlan_mutation_response"]],
		update_pricingPlan_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["pricingPlan_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["pricingPlan_set_input"] | undefined | null, pk_columns: ResolverInputTypes["pricingPlan_pk_columns_input"]
		}, ResolverInputTypes["pricingPlan"]],
		update_pricingPlan_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["pricingPlan_updates"]>
		}, ResolverInputTypes["pricingPlan_mutation_response"]],
		update_products?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["products_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["products_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["products_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["products_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["products_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["products_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["products_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["products_bool_exp"]
		}, ResolverInputTypes["products_mutation_response"]],
		update_products_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["products_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["products_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["products_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["products_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["products_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["products_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["products_set_input"] | undefined | null, pk_columns: ResolverInputTypes["products_pk_columns_input"]
		}, ResolverInputTypes["products"]],
		update_products_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["products_updates"]>
		}, ResolverInputTypes["products_mutation_response"]],
		update_project?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["project_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["project_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["project_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["project_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["project_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["project_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["project_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["project_bool_exp"]
		}, ResolverInputTypes["project_mutation_response"]],
		update_projectImage?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["projectImage_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["projectImage_bool_exp"]
		}, ResolverInputTypes["projectImage_mutation_response"]],
		update_projectImage_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["projectImage_set_input"] | undefined | null, pk_columns: ResolverInputTypes["projectImage_pk_columns_input"]
		}, ResolverInputTypes["projectImage"]],
		update_projectImage_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["projectImage_updates"]>
		}, ResolverInputTypes["projectImage_mutation_response"]],
		update_project_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["project_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["project_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["project_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["project_delete_key_input"] | undefined | null,	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["project_inc_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["project_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["project_set_input"] | undefined | null, pk_columns: ResolverInputTypes["project_pk_columns_input"]
		}, ResolverInputTypes["project"]],
		update_project_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["project_updates"]>
		}, ResolverInputTypes["project_mutation_response"]],
		update_task?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["task_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["task_bool_exp"]
		}, ResolverInputTypes["task_mutation_response"]],
		update_task_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["task_set_input"] | undefined | null, pk_columns: ResolverInputTypes["task_pk_columns_input"]
		}, ResolverInputTypes["task"]],
		update_task_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["task_updates"]>
		}, ResolverInputTypes["task_mutation_response"]],
		update_textTemplateDisplayFormat?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["textTemplateDisplayFormat_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"]
		}, ResolverInputTypes["textTemplateDisplayFormat_mutation_response"]],
		update_textTemplateDisplayFormat_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["textTemplateDisplayFormat_set_input"] | undefined | null, pk_columns: ResolverInputTypes["textTemplateDisplayFormat_pk_columns_input"]
		}, ResolverInputTypes["textTemplateDisplayFormat"]],
		update_textTemplateDisplayFormat_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["textTemplateDisplayFormat_updates"]>
		}, ResolverInputTypes["textTemplateDisplayFormat_mutation_response"]],
		update_userAppIntegration?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["userAppIntegration_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["userAppIntegration_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["userAppIntegration_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["userAppIntegration_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["userAppIntegration_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userAppIntegration_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userAppIntegration_bool_exp"]
		}, ResolverInputTypes["userAppIntegration_mutation_response"]],
		update_userAppIntegration_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["userAppIntegration_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["userAppIntegration_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["userAppIntegration_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["userAppIntegration_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["userAppIntegration_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userAppIntegration_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userAppIntegration_pk_columns_input"]
		}, ResolverInputTypes["userAppIntegration"]],
		update_userAppIntegration_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userAppIntegration_updates"]>
		}, ResolverInputTypes["userAppIntegration_mutation_response"]],
		update_userCreditUsage?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userCreditUsage_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userCreditUsage_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userCreditUsage_bool_exp"]
		}, ResolverInputTypes["userCreditUsage_mutation_response"]],
		update_userCreditUsageSummary?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userCreditUsageSummary_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userCreditUsageSummary_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userCreditUsageSummary_bool_exp"]
		}, ResolverInputTypes["userCreditUsageSummary_mutation_response"]],
		update_userCreditUsageSummary_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userCreditUsageSummary_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userCreditUsageSummary_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userCreditUsageSummary_pk_columns_input"]
		}, ResolverInputTypes["userCreditUsageSummary"]],
		update_userCreditUsageSummary_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userCreditUsageSummary_updates"]>
		}, ResolverInputTypes["userCreditUsageSummary_mutation_response"]],
		update_userCreditUsage_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userCreditUsage_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userCreditUsage_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userCreditUsage_pk_columns_input"]
		}, ResolverInputTypes["userCreditUsage"]],
		update_userCreditUsage_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userCreditUsage_updates"]>
		}, ResolverInputTypes["userCreditUsage_mutation_response"]],
		update_userCredits?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userCredits_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userCredits_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userCredits_bool_exp"]
		}, ResolverInputTypes["userCredits_mutation_response"]],
		update_userCredits_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userCredits_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userCredits_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userCredits_pk_columns_input"]
		}, ResolverInputTypes["userCredits"]],
		update_userCredits_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userCredits_updates"]>
		}, ResolverInputTypes["userCredits_mutation_response"]],
		update_userFeedback?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userFeedback_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userFeedback_bool_exp"]
		}, ResolverInputTypes["userFeedback_mutation_response"]],
		update_userFeedback_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userFeedback_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userFeedback_pk_columns_input"]
		}, ResolverInputTypes["userFeedback"]],
		update_userFeedback_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userFeedback_updates"]>
		}, ResolverInputTypes["userFeedback_mutation_response"]],
		update_userMedia?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["userMedia_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["userMedia_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["userMedia_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["userMedia_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["userMedia_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userMedia_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userMedia_bool_exp"]
		}, ResolverInputTypes["userMedia_mutation_response"]],
		update_userMedia_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
			_append?: ResolverInputTypes["userMedia_append_input"] | undefined | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
			_delete_at_path?: ResolverInputTypes["userMedia_delete_at_path_input"] | undefined | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
			_delete_elem?: ResolverInputTypes["userMedia_delete_elem_input"] | undefined | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
			_delete_key?: ResolverInputTypes["userMedia_delete_key_input"] | undefined | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
			_prepend?: ResolverInputTypes["userMedia_prepend_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userMedia_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userMedia_pk_columns_input"]
		}, ResolverInputTypes["userMedia"]],
		update_userMedia_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userMedia_updates"]>
		}, ResolverInputTypes["userMedia_mutation_response"]],
		update_userMetadata?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userMetadata_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userMetadata_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userMetadata_bool_exp"]
		}, ResolverInputTypes["userMetadata_mutation_response"]],
		update_userMetadata_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userMetadata_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userMetadata_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userMetadata_pk_columns_input"]
		}, ResolverInputTypes["userMetadata"]],
		update_userMetadata_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userMetadata_updates"]>
		}, ResolverInputTypes["userMetadata_mutation_response"]],
		update_userOnboarding?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userOnboarding_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userOnboarding_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userOnboarding_bool_exp"]
		}, ResolverInputTypes["userOnboarding_mutation_response"]],
		update_userOnboarding_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
			_inc?: ResolverInputTypes["userOnboarding_inc_input"] | undefined | null,	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userOnboarding_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userOnboarding_pk_columns_input"]
		}, ResolverInputTypes["userOnboarding"]],
		update_userOnboarding_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userOnboarding_updates"]>
		}, ResolverInputTypes["userOnboarding_mutation_response"]],
		update_userTemplateUsage?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userTemplateUsage_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["userTemplateUsage_bool_exp"]
		}, ResolverInputTypes["userTemplateUsage_mutation_response"]],
		update_userTemplateUsage_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["userTemplateUsage_set_input"] | undefined | null, pk_columns: ResolverInputTypes["userTemplateUsage_pk_columns_input"]
		}, ResolverInputTypes["userTemplateUsage"]],
		update_userTemplateUsage_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["userTemplateUsage_updates"]>
		}, ResolverInputTypes["userTemplateUsage_mutation_response"]],
		update_users_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["users_updates"]>
		}, ResolverInputTypes["users_mutation_response"]],
		update_virus_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["virus_updates"]>
		}, ResolverInputTypes["virus_mutation_response"]],
		update_website?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["website_set_input"] | undefined | null,	/** filter the rows which have to be updated */
			where: ResolverInputTypes["website_bool_exp"]
		}, ResolverInputTypes["website_mutation_response"]],
		update_website_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
			_set?: ResolverInputTypes["website_set_input"] | undefined | null, pk_columns: ResolverInputTypes["website_pk_columns_input"]
		}, ResolverInputTypes["website"]],
		update_website_many?: [{	/** updates to execute, in order */
			updates: Array<ResolverInputTypes["website_updates"]>
		}, ResolverInputTypes["website_mutation_response"]],
		upsertAudience?: [{ brandId?: string | undefined | null, brandName?: string | undefined | null, id?: string | undefined | null, productOrService?: string | undefined | null, segment?: string | undefined | null, targetAudience?: string | undefined | null }, ResolverInputTypes["UpsertAudienceOutput"]],
		upsertBrand?: [{ brandId?: string | undefined | null, brandName?: string | undefined | null, colors?: string | undefined | null, fontPrimary?: string | undefined | null, fontSecondary?: string | undefined | null, isBrand?: boolean | undefined | null, logoFileId?: string | undefined | null, personality?: string | undefined | null, productOrService?: string | undefined | null, segment?: string | undefined | null, slogan?: string | undefined | null, voiceTone?: string | undefined | null }, ResolverInputTypes["upsertBrandOutput"]],
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "newTemplateType" */
	["newTemplateType"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		thumbnailFileId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "newTemplateType" */
	["newTemplateType_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["newTemplateType_aggregate_fields"],
		nodes?: ResolverInputTypes["newTemplateType"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "newTemplateType" */
	["newTemplateType_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["newTemplateType_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["newTemplateType_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["newTemplateType_max_fields"],
		min?: ResolverInputTypes["newTemplateType_min_fields"],
		stddev?: ResolverInputTypes["newTemplateType_stddev_fields"],
		stddev_pop?: ResolverInputTypes["newTemplateType_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["newTemplateType_stddev_samp_fields"],
		sum?: ResolverInputTypes["newTemplateType_sum_fields"],
		var_pop?: ResolverInputTypes["newTemplateType_var_pop_fields"],
		var_samp?: ResolverInputTypes["newTemplateType_var_samp_fields"],
		variance?: ResolverInputTypes["newTemplateType_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["newTemplateType_avg_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "newTemplateType". All fields are combined with a logical 'AND'. */
	["newTemplateType_bool_exp"]: {
		_and?: Array<ResolverInputTypes["newTemplateType_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["newTemplateType_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["newTemplateType_bool_exp"]> | undefined | null,
		channel?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		creditPrice?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		description?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		icon?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		thumbnailFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "newTemplateType" */
	["newTemplateType_constraint"]: newTemplateType_constraint;
	/** input type for incrementing numeric columns in table "newTemplateType" */
	["newTemplateType_inc_input"]: {
		creditPrice?: number | undefined | null,
		saleCreditPrice?: number | undefined | null
	};
	/** input type for inserting data into table "newTemplateType" */
	["newTemplateType_insert_input"]: {
		channel?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditPrice?: number | undefined | null,
		description?: string | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		saleCreditPrice?: number | undefined | null,
		thumbnailFileId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["newTemplateType_max_fields"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		thumbnailFileId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["newTemplateType_min_fields"]: AliasType<{
		channel?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		icon?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		thumbnailFileId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "newTemplateType" */
	["newTemplateType_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["newTemplateType"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "newTemplateType" */
	["newTemplateType_on_conflict"]: {
		constraint: ResolverInputTypes["newTemplateType_constraint"],
		update_columns: Array<ResolverInputTypes["newTemplateType_update_column"]>,
		where?: ResolverInputTypes["newTemplateType_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "newTemplateType". */
	["newTemplateType_order_by"]: {
		channel?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		icon?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		saleCreditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		thumbnailFileId?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: newTemplateType */
	["newTemplateType_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "newTemplateType" */
	["newTemplateType_select_column"]: newTemplateType_select_column;
	/** input type for updating data in table "newTemplateType" */
	["newTemplateType_set_input"]: {
		channel?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditPrice?: number | undefined | null,
		description?: string | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		saleCreditPrice?: number | undefined | null,
		thumbnailFileId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate stddev on columns */
	["newTemplateType_stddev_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["newTemplateType_stddev_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["newTemplateType_stddev_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "newTemplateType" */
	["newTemplateType_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["newTemplateType_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["newTemplateType_stream_cursor_value_input"]: {
		channel?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditPrice?: number | undefined | null,
		description?: string | undefined | null,
		icon?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		name?: string | undefined | null,
		saleCreditPrice?: number | undefined | null,
		thumbnailFileId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate sum on columns */
	["newTemplateType_sum_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "newTemplateType" */
	["newTemplateType_update_column"]: newTemplateType_update_column;
	["newTemplateType_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["newTemplateType_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["newTemplateType_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["newTemplateType_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["newTemplateType_var_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["newTemplateType_var_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["newTemplateType_variance_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		saleCreditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["numeric"]: unknown;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
	["numeric_comparison_exp"]: {
		_eq?: ResolverInputTypes["numeric"] | undefined | null,
		_gt?: ResolverInputTypes["numeric"] | undefined | null,
		_gte?: ResolverInputTypes["numeric"] | undefined | null,
		_in?: Array<ResolverInputTypes["numeric"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: ResolverInputTypes["numeric"] | undefined | null,
		_lte?: ResolverInputTypes["numeric"] | undefined | null,
		_neq?: ResolverInputTypes["numeric"] | undefined | null,
		_nin?: Array<ResolverInputTypes["numeric"]> | undefined | null
	};
	/** columns and relationships of "openAIApiKey" */
	["openAIApiKey"]: AliasType<{
		apiKey?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		last_used?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "openAIApiKey" */
	["openAIApiKey_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["openAIApiKey_aggregate_fields"],
		nodes?: ResolverInputTypes["openAIApiKey"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "openAIApiKey" */
	["openAIApiKey_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["openAIApiKey_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["openAIApiKey_max_fields"],
		min?: ResolverInputTypes["openAIApiKey_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "openAIApiKey". All fields are combined with a logical 'AND'. */
	["openAIApiKey_bool_exp"]: {
		_and?: Array<ResolverInputTypes["openAIApiKey_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["openAIApiKey_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["openAIApiKey_bool_exp"]> | undefined | null,
		apiKey?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		last_used?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		status?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "openAIApiKey" */
	["openAIApiKey_constraint"]: openAIApiKey_constraint;
	/** input type for inserting data into table "openAIApiKey" */
	["openAIApiKey_insert_input"]: {
		apiKey?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		last_used?: ResolverInputTypes["timestamptz"] | undefined | null,
		status?: string | undefined | null
	};
	/** aggregate max on columns */
	["openAIApiKey_max_fields"]: AliasType<{
		apiKey?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		last_used?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["openAIApiKey_min_fields"]: AliasType<{
		apiKey?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		last_used?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "openAIApiKey" */
	["openAIApiKey_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["openAIApiKey"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "openAIApiKey" */
	["openAIApiKey_on_conflict"]: {
		constraint: ResolverInputTypes["openAIApiKey_constraint"],
		update_columns: Array<ResolverInputTypes["openAIApiKey_update_column"]>,
		where?: ResolverInputTypes["openAIApiKey_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "openAIApiKey". */
	["openAIApiKey_order_by"]: {
		apiKey?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		last_used?: ResolverInputTypes["order_by"] | undefined | null,
		status?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: openAIApiKey */
	["openAIApiKey_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "openAIApiKey" */
	["openAIApiKey_select_column"]: openAIApiKey_select_column;
	/** input type for updating data in table "openAIApiKey" */
	["openAIApiKey_set_input"]: {
		apiKey?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		last_used?: ResolverInputTypes["timestamptz"] | undefined | null,
		status?: string | undefined | null
	};
	/** Streaming cursor of the table "openAIApiKey" */
	["openAIApiKey_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["openAIApiKey_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["openAIApiKey_stream_cursor_value_input"]: {
		apiKey?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		last_used?: ResolverInputTypes["timestamptz"] | undefined | null,
		status?: string | undefined | null
	};
	/** update columns of table "openAIApiKey" */
	["openAIApiKey_update_column"]: openAIApiKey_update_column;
	["openAIApiKey_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["openAIApiKey_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["openAIApiKey_bool_exp"]
	};
	/** column ordering options */
	["order_by"]: order_by;
	/** columns and relationships of "pTemplate" */
	["pTemplate"]: AliasType<{
		category?: boolean | `@${string}`,
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		deletedAt?: boolean | `@${string}`,
		descriptionMaxLength?: boolean | `@${string}`,
		/** A computed field, executes function "templateheight" */
		height?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		json?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		previewFileId?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		tagScores?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		titleMaxLength?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		visibility?: boolean | `@${string}`,
		/** A computed field, executes function "templatewidth" */
		width?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "pTemplate" */
	["pTemplate_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["pTemplate_aggregate_fields"],
		nodes?: ResolverInputTypes["pTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "pTemplate" */
	["pTemplate_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["pTemplate_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["pTemplate_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["pTemplate_max_fields"],
		min?: ResolverInputTypes["pTemplate_min_fields"],
		stddev?: ResolverInputTypes["pTemplate_stddev_fields"],
		stddev_pop?: ResolverInputTypes["pTemplate_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["pTemplate_stddev_samp_fields"],
		sum?: ResolverInputTypes["pTemplate_sum_fields"],
		var_pop?: ResolverInputTypes["pTemplate_var_pop_fields"],
		var_samp?: ResolverInputTypes["pTemplate_var_samp_fields"],
		variance?: ResolverInputTypes["pTemplate_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["pTemplate_append_input"]: {
		json?: ResolverInputTypes["jsonb"] | undefined | null,
		tagScores?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** aggregate avg on columns */
	["pTemplate_avg_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "pTemplate". All fields are combined with a logical 'AND'. */
	["pTemplate_bool_exp"]: {
		_and?: Array<ResolverInputTypes["pTemplate_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["pTemplate_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["pTemplate_bool_exp"]> | undefined | null,
		category?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		createdBy?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		deletedAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		descriptionMaxLength?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		height?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		json?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		previewFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		subtitleMaxLength?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		tagScores?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		titleMaxLength?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		visibility?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		width?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "pTemplate" */
	["pTemplate_constraint"]: pTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["pTemplate_delete_at_path_input"]: {
		json?: Array<string> | undefined | null,
		tagScores?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["pTemplate_delete_elem_input"]: {
		json?: number | undefined | null,
		tagScores?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["pTemplate_delete_key_input"]: {
		json?: string | undefined | null,
		tagScores?: string | undefined | null
	};
	/** input type for incrementing numeric columns in table "pTemplate" */
	["pTemplate_inc_input"]: {
		descriptionMaxLength?: number | undefined | null,
		subtitleMaxLength?: number | undefined | null,
		titleMaxLength?: number | undefined | null
	};
	/** input type for inserting data into table "pTemplate" */
	["pTemplate_insert_input"]: {
		category?: string | undefined | null,
		createdBy?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		deletedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		descriptionMaxLength?: number | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		json?: ResolverInputTypes["jsonb"] | undefined | null,
		previewFileId?: ResolverInputTypes["uuid"] | undefined | null,
		subtitleMaxLength?: number | undefined | null,
		tagScores?: ResolverInputTypes["jsonb"] | undefined | null,
		titleMaxLength?: number | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		visibility?: string | undefined | null
	};
	/** aggregate max on columns */
	["pTemplate_max_fields"]: AliasType<{
		category?: boolean | `@${string}`,
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		deletedAt?: boolean | `@${string}`,
		descriptionMaxLength?: boolean | `@${string}`,
		/** A computed field, executes function "templateheight" */
		height?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		previewFileId?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		visibility?: boolean | `@${string}`,
		/** A computed field, executes function "templatewidth" */
		width?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["pTemplate_min_fields"]: AliasType<{
		category?: boolean | `@${string}`,
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		deletedAt?: boolean | `@${string}`,
		descriptionMaxLength?: boolean | `@${string}`,
		/** A computed field, executes function "templateheight" */
		height?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		previewFileId?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		visibility?: boolean | `@${string}`,
		/** A computed field, executes function "templatewidth" */
		width?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "pTemplate" */
	["pTemplate_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["pTemplate"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "pTemplate" */
	["pTemplate_on_conflict"]: {
		constraint: ResolverInputTypes["pTemplate_constraint"],
		update_columns: Array<ResolverInputTypes["pTemplate_update_column"]>,
		where?: ResolverInputTypes["pTemplate_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "pTemplate". */
	["pTemplate_order_by"]: {
		category?: ResolverInputTypes["order_by"] | undefined | null,
		createdBy?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		deletedAt?: ResolverInputTypes["order_by"] | undefined | null,
		descriptionMaxLength?: ResolverInputTypes["order_by"] | undefined | null,
		height?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		json?: ResolverInputTypes["order_by"] | undefined | null,
		previewFileId?: ResolverInputTypes["order_by"] | undefined | null,
		subtitleMaxLength?: ResolverInputTypes["order_by"] | undefined | null,
		tagScores?: ResolverInputTypes["order_by"] | undefined | null,
		titleMaxLength?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		visibility?: ResolverInputTypes["order_by"] | undefined | null,
		width?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: pTemplate */
	["pTemplate_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["pTemplate_prepend_input"]: {
		json?: ResolverInputTypes["jsonb"] | undefined | null,
		tagScores?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "pTemplate" */
	["pTemplate_select_column"]: pTemplate_select_column;
	/** input type for updating data in table "pTemplate" */
	["pTemplate_set_input"]: {
		category?: string | undefined | null,
		createdBy?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		deletedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		descriptionMaxLength?: number | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		json?: ResolverInputTypes["jsonb"] | undefined | null,
		previewFileId?: ResolverInputTypes["uuid"] | undefined | null,
		subtitleMaxLength?: number | undefined | null,
		tagScores?: ResolverInputTypes["jsonb"] | undefined | null,
		titleMaxLength?: number | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		visibility?: string | undefined | null
	};
	/** aggregate stddev on columns */
	["pTemplate_stddev_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["pTemplate_stddev_pop_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["pTemplate_stddev_samp_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "pTemplate" */
	["pTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["pTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["pTemplate_stream_cursor_value_input"]: {
		category?: string | undefined | null,
		createdBy?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		deletedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		descriptionMaxLength?: number | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		json?: ResolverInputTypes["jsonb"] | undefined | null,
		previewFileId?: ResolverInputTypes["uuid"] | undefined | null,
		subtitleMaxLength?: number | undefined | null,
		tagScores?: ResolverInputTypes["jsonb"] | undefined | null,
		titleMaxLength?: number | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		visibility?: string | undefined | null
	};
	/** aggregate sum on columns */
	["pTemplate_sum_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "pTemplate" */
	["pTemplate_update_column"]: pTemplate_update_column;
	["pTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["pTemplate_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["pTemplate_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["pTemplate_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["pTemplate_delete_key_input"] | undefined | null,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["pTemplate_inc_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["pTemplate_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["pTemplate_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["pTemplate_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["pTemplate_var_pop_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["pTemplate_var_samp_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["pTemplate_variance_fields"]: AliasType<{
		descriptionMaxLength?: boolean | `@${string}`,
		subtitleMaxLength?: boolean | `@${string}`,
		titleMaxLength?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "pricingPlan" */
	["pricingPlan"]: AliasType<{
		active?: boolean | `@${string}`,
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		htmlFeatureItems?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "pricingPlan" */
	["pricingPlan_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["pricingPlan_aggregate_fields"],
		nodes?: ResolverInputTypes["pricingPlan"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "pricingPlan" */
	["pricingPlan_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["pricingPlan_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["pricingPlan_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["pricingPlan_max_fields"],
		min?: ResolverInputTypes["pricingPlan_min_fields"],
		stddev?: ResolverInputTypes["pricingPlan_stddev_fields"],
		stddev_pop?: ResolverInputTypes["pricingPlan_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["pricingPlan_stddev_samp_fields"],
		sum?: ResolverInputTypes["pricingPlan_sum_fields"],
		var_pop?: ResolverInputTypes["pricingPlan_var_pop_fields"],
		var_samp?: ResolverInputTypes["pricingPlan_var_samp_fields"],
		variance?: ResolverInputTypes["pricingPlan_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["pricingPlan_avg_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "pricingPlan". All fields are combined with a logical 'AND'. */
	["pricingPlan_bool_exp"]: {
		_and?: Array<ResolverInputTypes["pricingPlan_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["pricingPlan_bool_exp"]> | undefined | null,
		active?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		alias?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		creditUnitDiscount?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		creditsToAdd?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		htmlFeatureItems?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		monthlyPrice?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		order?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		stripePriceId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		stripeProductId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		type?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "pricingPlan" */
	["pricingPlan_constraint"]: pricingPlan_constraint;
	/** input type for incrementing numeric columns in table "pricingPlan" */
	["pricingPlan_inc_input"]: {
		creditUnitDiscount?: ResolverInputTypes["numeric"] | undefined | null,
		creditsToAdd?: ResolverInputTypes["numeric"] | undefined | null,
		monthlyPrice?: ResolverInputTypes["numeric"] | undefined | null,
		order?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "pricingPlan" */
	["pricingPlan_insert_input"]: {
		active?: boolean | undefined | null,
		alias?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditUnitDiscount?: ResolverInputTypes["numeric"] | undefined | null,
		creditsToAdd?: ResolverInputTypes["numeric"] | undefined | null,
		htmlFeatureItems?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		monthlyPrice?: ResolverInputTypes["numeric"] | undefined | null,
		order?: ResolverInputTypes["numeric"] | undefined | null,
		stripePriceId?: string | undefined | null,
		stripeProductId?: string | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["pricingPlan_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		htmlFeatureItems?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["pricingPlan_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		htmlFeatureItems?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "pricingPlan" */
	["pricingPlan_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["pricingPlan"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "pricingPlan" */
	["pricingPlan_obj_rel_insert_input"]: {
		data: ResolverInputTypes["pricingPlan_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["pricingPlan_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "pricingPlan" */
	["pricingPlan_on_conflict"]: {
		constraint: ResolverInputTypes["pricingPlan_constraint"],
		update_columns: Array<ResolverInputTypes["pricingPlan_update_column"]>,
		where?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "pricingPlan". */
	["pricingPlan_order_by"]: {
		active?: ResolverInputTypes["order_by"] | undefined | null,
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		creditUnitDiscount?: ResolverInputTypes["order_by"] | undefined | null,
		creditsToAdd?: ResolverInputTypes["order_by"] | undefined | null,
		htmlFeatureItems?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		monthlyPrice?: ResolverInputTypes["order_by"] | undefined | null,
		order?: ResolverInputTypes["order_by"] | undefined | null,
		stripePriceId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeProductId?: ResolverInputTypes["order_by"] | undefined | null,
		type?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: pricingPlan */
	["pricingPlan_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "pricingPlan" */
	["pricingPlan_select_column"]: pricingPlan_select_column;
	/** input type for updating data in table "pricingPlan" */
	["pricingPlan_set_input"]: {
		active?: boolean | undefined | null,
		alias?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditUnitDiscount?: ResolverInputTypes["numeric"] | undefined | null,
		creditsToAdd?: ResolverInputTypes["numeric"] | undefined | null,
		htmlFeatureItems?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		monthlyPrice?: ResolverInputTypes["numeric"] | undefined | null,
		order?: ResolverInputTypes["numeric"] | undefined | null,
		stripePriceId?: string | undefined | null,
		stripeProductId?: string | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate stddev on columns */
	["pricingPlan_stddev_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["pricingPlan_stddev_pop_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["pricingPlan_stddev_samp_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "pricingPlan" */
	["pricingPlan_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["pricingPlan_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["pricingPlan_stream_cursor_value_input"]: {
		active?: boolean | undefined | null,
		alias?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditUnitDiscount?: ResolverInputTypes["numeric"] | undefined | null,
		creditsToAdd?: ResolverInputTypes["numeric"] | undefined | null,
		htmlFeatureItems?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		monthlyPrice?: ResolverInputTypes["numeric"] | undefined | null,
		order?: ResolverInputTypes["numeric"] | undefined | null,
		stripePriceId?: string | undefined | null,
		stripeProductId?: string | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate sum on columns */
	["pricingPlan_sum_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "pricingPlan" */
	["pricingPlan_update_column"]: pricingPlan_update_column;
	["pricingPlan_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["pricingPlan_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["pricingPlan_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["pricingPlan_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["pricingPlan_var_pop_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["pricingPlan_var_samp_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["pricingPlan_variance_fields"]: AliasType<{
		creditUnitDiscount?: boolean | `@${string}`,
		creditsToAdd?: boolean | `@${string}`,
		monthlyPrice?: boolean | `@${string}`,
		order?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "products" */
	["products"]: AliasType<{
		alias?: boolean | `@${string}`,
		benefits?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		/** An object relationship */
		brand?: ResolverInputTypes["brands"],
		brandId?: boolean | `@${string}`,
		category?: boolean | `@${string}`,
		characteristics?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageFourId?: boolean | `@${string}`,
		imageOneId?: boolean | `@${string}`,
		imageThreeId?: boolean | `@${string}`,
		imageTwoId?: boolean | `@${string}`,
		linkConversion?: boolean | `@${string}`,
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		testimonials?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		wordKeys?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "products" */
	["products_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["products_aggregate_fields"],
		nodes?: ResolverInputTypes["products"],
		__typename?: boolean | `@${string}`
	}>;
	["products_aggregate_bool_exp"]: {
		bool_and?: ResolverInputTypes["products_aggregate_bool_exp_bool_and"] | undefined | null,
		bool_or?: ResolverInputTypes["products_aggregate_bool_exp_bool_or"] | undefined | null,
		count?: ResolverInputTypes["products_aggregate_bool_exp_count"] | undefined | null
	};
	["products_aggregate_bool_exp_bool_and"]: {
		arguments: ResolverInputTypes["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["products_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["products_aggregate_bool_exp_bool_or"]: {
		arguments: ResolverInputTypes["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["products_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["products_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["products_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["products_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "products" */
	["products_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["products_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["products_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["products_max_fields"],
		min?: ResolverInputTypes["products_min_fields"],
		stddev?: ResolverInputTypes["products_stddev_fields"],
		stddev_pop?: ResolverInputTypes["products_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["products_stddev_samp_fields"],
		sum?: ResolverInputTypes["products_sum_fields"],
		var_pop?: ResolverInputTypes["products_var_pop_fields"],
		var_samp?: ResolverInputTypes["products_var_samp_fields"],
		variance?: ResolverInputTypes["products_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "products" */
	["products_aggregate_order_by"]: {
		avg?: ResolverInputTypes["products_avg_order_by"] | undefined | null,
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["products_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["products_min_order_by"] | undefined | null,
		stddev?: ResolverInputTypes["products_stddev_order_by"] | undefined | null,
		stddev_pop?: ResolverInputTypes["products_stddev_pop_order_by"] | undefined | null,
		stddev_samp?: ResolverInputTypes["products_stddev_samp_order_by"] | undefined | null,
		sum?: ResolverInputTypes["products_sum_order_by"] | undefined | null,
		var_pop?: ResolverInputTypes["products_var_pop_order_by"] | undefined | null,
		var_samp?: ResolverInputTypes["products_var_samp_order_by"] | undefined | null,
		variance?: ResolverInputTypes["products_variance_order_by"] | undefined | null
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["products_append_input"]: {
		benefits?: ResolverInputTypes["jsonb"] | undefined | null,
		characteristics?: ResolverInputTypes["jsonb"] | undefined | null,
		testimonials?: ResolverInputTypes["jsonb"] | undefined | null,
		wordKeys?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** input type for inserting array relation for remote table "products" */
	["products_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["products_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["products_on_conflict"] | undefined | null
	};
	/** aggregate avg on columns */
	["products_avg_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "products" */
	["products_avg_order_by"]: {
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
	["products_bool_exp"]: {
		_and?: Array<ResolverInputTypes["products_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["products_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["products_bool_exp"]> | undefined | null,
		alias?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		benefits?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		brand?: ResolverInputTypes["brands_bool_exp"] | undefined | null,
		brandId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		category?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		characteristics?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		deleted?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		description?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		imageFourId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		imageOneId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		imageThreeId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		imageTwoId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		linkConversion?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		price?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		salePrice?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		testimonials?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		type?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		wordKeys?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "products" */
	["products_constraint"]: products_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["products_delete_at_path_input"]: {
		benefits?: Array<string> | undefined | null,
		characteristics?: Array<string> | undefined | null,
		testimonials?: Array<string> | undefined | null,
		wordKeys?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["products_delete_elem_input"]: {
		benefits?: number | undefined | null,
		characteristics?: number | undefined | null,
		testimonials?: number | undefined | null,
		wordKeys?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["products_delete_key_input"]: {
		benefits?: string | undefined | null,
		characteristics?: string | undefined | null,
		testimonials?: string | undefined | null,
		wordKeys?: string | undefined | null
	};
	/** input type for incrementing numeric columns in table "products" */
	["products_inc_input"]: {
		price?: ResolverInputTypes["numeric"] | undefined | null,
		salePrice?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "products" */
	["products_insert_input"]: {
		alias?: string | undefined | null,
		benefits?: ResolverInputTypes["jsonb"] | undefined | null,
		brand?: ResolverInputTypes["brands_obj_rel_insert_input"] | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		category?: string | undefined | null,
		characteristics?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		description?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		imageFourId?: ResolverInputTypes["uuid"] | undefined | null,
		imageOneId?: ResolverInputTypes["uuid"] | undefined | null,
		imageThreeId?: ResolverInputTypes["uuid"] | undefined | null,
		imageTwoId?: ResolverInputTypes["uuid"] | undefined | null,
		linkConversion?: string | undefined | null,
		price?: ResolverInputTypes["numeric"] | undefined | null,
		salePrice?: ResolverInputTypes["numeric"] | undefined | null,
		testimonials?: ResolverInputTypes["jsonb"] | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		wordKeys?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** aggregate max on columns */
	["products_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageFourId?: boolean | `@${string}`,
		imageOneId?: boolean | `@${string}`,
		imageThreeId?: boolean | `@${string}`,
		imageTwoId?: boolean | `@${string}`,
		linkConversion?: boolean | `@${string}`,
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "products" */
	["products_max_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		category?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		imageFourId?: ResolverInputTypes["order_by"] | undefined | null,
		imageOneId?: ResolverInputTypes["order_by"] | undefined | null,
		imageThreeId?: ResolverInputTypes["order_by"] | undefined | null,
		imageTwoId?: ResolverInputTypes["order_by"] | undefined | null,
		linkConversion?: ResolverInputTypes["order_by"] | undefined | null,
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null,
		type?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["products_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		imageFourId?: boolean | `@${string}`,
		imageOneId?: boolean | `@${string}`,
		imageThreeId?: boolean | `@${string}`,
		imageTwoId?: boolean | `@${string}`,
		linkConversion?: boolean | `@${string}`,
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "products" */
	["products_min_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		category?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		imageFourId?: ResolverInputTypes["order_by"] | undefined | null,
		imageOneId?: ResolverInputTypes["order_by"] | undefined | null,
		imageThreeId?: ResolverInputTypes["order_by"] | undefined | null,
		imageTwoId?: ResolverInputTypes["order_by"] | undefined | null,
		linkConversion?: ResolverInputTypes["order_by"] | undefined | null,
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null,
		type?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "products" */
	["products_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["products"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "products" */
	["products_obj_rel_insert_input"]: {
		data: ResolverInputTypes["products_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["products_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "products" */
	["products_on_conflict"]: {
		constraint: ResolverInputTypes["products_constraint"],
		update_columns: Array<ResolverInputTypes["products_update_column"]>,
		where?: ResolverInputTypes["products_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "products". */
	["products_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		benefits?: ResolverInputTypes["order_by"] | undefined | null,
		brand?: ResolverInputTypes["brands_order_by"] | undefined | null,
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		category?: ResolverInputTypes["order_by"] | undefined | null,
		characteristics?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		deleted?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		imageFourId?: ResolverInputTypes["order_by"] | undefined | null,
		imageOneId?: ResolverInputTypes["order_by"] | undefined | null,
		imageThreeId?: ResolverInputTypes["order_by"] | undefined | null,
		imageTwoId?: ResolverInputTypes["order_by"] | undefined | null,
		linkConversion?: ResolverInputTypes["order_by"] | undefined | null,
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null,
		testimonials?: ResolverInputTypes["order_by"] | undefined | null,
		type?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		wordKeys?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: products */
	["products_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["products_prepend_input"]: {
		benefits?: ResolverInputTypes["jsonb"] | undefined | null,
		characteristics?: ResolverInputTypes["jsonb"] | undefined | null,
		testimonials?: ResolverInputTypes["jsonb"] | undefined | null,
		wordKeys?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "products" */
	["products_select_column"]: products_select_column;
	/** select "products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "products" */
	["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"]: products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "products" */
	["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"]: products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "products" */
	["products_set_input"]: {
		alias?: string | undefined | null,
		benefits?: ResolverInputTypes["jsonb"] | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		category?: string | undefined | null,
		characteristics?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		description?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		imageFourId?: ResolverInputTypes["uuid"] | undefined | null,
		imageOneId?: ResolverInputTypes["uuid"] | undefined | null,
		imageThreeId?: ResolverInputTypes["uuid"] | undefined | null,
		imageTwoId?: ResolverInputTypes["uuid"] | undefined | null,
		linkConversion?: string | undefined | null,
		price?: ResolverInputTypes["numeric"] | undefined | null,
		salePrice?: ResolverInputTypes["numeric"] | undefined | null,
		testimonials?: ResolverInputTypes["jsonb"] | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		wordKeys?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** aggregate stddev on columns */
	["products_stddev_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "products" */
	["products_stddev_order_by"]: {
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_pop on columns */
	["products_stddev_pop_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "products" */
	["products_stddev_pop_order_by"]: {
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_samp on columns */
	["products_stddev_samp_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "products" */
	["products_stddev_samp_order_by"]: {
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Streaming cursor of the table "products" */
	["products_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["products_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["products_stream_cursor_value_input"]: {
		alias?: string | undefined | null,
		benefits?: ResolverInputTypes["jsonb"] | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		category?: string | undefined | null,
		characteristics?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		description?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		imageFourId?: ResolverInputTypes["uuid"] | undefined | null,
		imageOneId?: ResolverInputTypes["uuid"] | undefined | null,
		imageThreeId?: ResolverInputTypes["uuid"] | undefined | null,
		imageTwoId?: ResolverInputTypes["uuid"] | undefined | null,
		linkConversion?: string | undefined | null,
		price?: ResolverInputTypes["numeric"] | undefined | null,
		salePrice?: ResolverInputTypes["numeric"] | undefined | null,
		testimonials?: ResolverInputTypes["jsonb"] | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		wordKeys?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** aggregate sum on columns */
	["products_sum_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "products" */
	["products_sum_order_by"]: {
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** update columns of table "products" */
	["products_update_column"]: products_update_column;
	["products_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["products_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["products_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["products_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["products_delete_key_input"] | undefined | null,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["products_inc_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["products_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["products_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["products_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["products_var_pop_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "products" */
	["products_var_pop_order_by"]: {
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate var_samp on columns */
	["products_var_samp_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "products" */
	["products_var_samp_order_by"]: {
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate variance on columns */
	["products_variance_fields"]: AliasType<{
		price?: boolean | `@${string}`,
		salePrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "products" */
	["products_variance_order_by"]: {
		price?: ResolverInputTypes["order_by"] | undefined | null,
		salePrice?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** columns and relationships of "project" */
	["project"]: AliasType<{
		alias?: boolean | `@${string}`,
		audienceId?: boolean | `@${string}`,
		blogContent?: boolean | `@${string}`,
		blogTitle?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignId?: boolean | `@${string}`,
		caption?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		featuredImageFileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		/** An object relationship */
		previewImageFile?: ResolverInputTypes["files"],
		previewImageFileId?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		rawText?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		suggestedScheduledDate?: boolean | `@${string}`,
		template?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "projectImage" */
	["projectImage"]: AliasType<{
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "projectImage" */
	["projectImage_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["projectImage_aggregate_fields"],
		nodes?: ResolverInputTypes["projectImage"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "projectImage" */
	["projectImage_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["projectImage_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["projectImage_max_fields"],
		min?: ResolverInputTypes["projectImage_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "projectImage". All fields are combined with a logical 'AND'. */
	["projectImage_bool_exp"]: {
		_and?: Array<ResolverInputTypes["projectImage_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["projectImage_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["projectImage_bool_exp"]> | undefined | null,
		fileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		projectId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "projectImage" */
	["projectImage_constraint"]: projectImage_constraint;
	/** input type for inserting data into table "projectImage" */
	["projectImage_insert_input"]: {
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		projectId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["projectImage_max_fields"]: AliasType<{
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["projectImage_min_fields"]: AliasType<{
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		projectId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "projectImage" */
	["projectImage_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["projectImage"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "projectImage" */
	["projectImage_on_conflict"]: {
		constraint: ResolverInputTypes["projectImage_constraint"],
		update_columns: Array<ResolverInputTypes["projectImage_update_column"]>,
		where?: ResolverInputTypes["projectImage_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "projectImage". */
	["projectImage_order_by"]: {
		fileId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		projectId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: projectImage */
	["projectImage_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "projectImage" */
	["projectImage_select_column"]: projectImage_select_column;
	/** input type for updating data in table "projectImage" */
	["projectImage_set_input"]: {
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		projectId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "projectImage" */
	["projectImage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["projectImage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["projectImage_stream_cursor_value_input"]: {
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		projectId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "projectImage" */
	["projectImage_update_column"]: projectImage_update_column;
	["projectImage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["projectImage_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["projectImage_bool_exp"]
	};
	/** aggregated selection of "project" */
	["project_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["project_aggregate_fields"],
		nodes?: ResolverInputTypes["project"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "project" */
	["project_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["project_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["project_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["project_max_fields"],
		min?: ResolverInputTypes["project_min_fields"],
		stddev?: ResolverInputTypes["project_stddev_fields"],
		stddev_pop?: ResolverInputTypes["project_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["project_stddev_samp_fields"],
		sum?: ResolverInputTypes["project_sum_fields"],
		var_pop?: ResolverInputTypes["project_var_pop_fields"],
		var_samp?: ResolverInputTypes["project_var_samp_fields"],
		variance?: ResolverInputTypes["project_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["project_append_input"]: {
		template?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** aggregate avg on columns */
	["project_avg_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
	["project_bool_exp"]: {
		_and?: Array<ResolverInputTypes["project_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["project_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["project_bool_exp"]> | undefined | null,
		alias?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		audienceId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		blogContent?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		blogTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		brandId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		campaignId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		caption?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		creditPrice?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		deleted?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		featuredImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		previewImageFile?: ResolverInputTypes["files_bool_exp"] | undefined | null,
		previewImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		productId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		rawText?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		status?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		suggestedScheduledDate?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		template?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		type?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "project" */
	["project_constraint"]: project_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["project_delete_at_path_input"]: {
		template?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["project_delete_elem_input"]: {
		template?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["project_delete_key_input"]: {
		template?: string | undefined | null
	};
	/** input type for incrementing numeric columns in table "project" */
	["project_inc_input"]: {
		creditPrice?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "project" */
	["project_insert_input"]: {
		alias?: string | undefined | null,
		audienceId?: ResolverInputTypes["uuid"] | undefined | null,
		blogContent?: string | undefined | null,
		blogTitle?: string | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		campaignId?: ResolverInputTypes["uuid"] | undefined | null,
		caption?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		creditPrice?: ResolverInputTypes["numeric"] | undefined | null,
		deleted?: boolean | undefined | null,
		featuredImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		previewImageFile?: ResolverInputTypes["files_obj_rel_insert_input"] | undefined | null,
		previewImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		productId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		rawText?: string | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["uuid"] | undefined | null,
		status?: string | undefined | null,
		suggestedScheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		template?: ResolverInputTypes["jsonb"] | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["project_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		audienceId?: boolean | `@${string}`,
		blogContent?: boolean | `@${string}`,
		blogTitle?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignId?: boolean | `@${string}`,
		caption?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		featuredImageFileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		previewImageFileId?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		rawText?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		suggestedScheduledDate?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["project_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		audienceId?: boolean | `@${string}`,
		blogContent?: boolean | `@${string}`,
		blogTitle?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		campaignId?: boolean | `@${string}`,
		caption?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		creditPrice?: boolean | `@${string}`,
		featuredImageFileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		previewImageFileId?: boolean | `@${string}`,
		productId?: boolean | `@${string}`,
		publishDate?: boolean | `@${string}`,
		rawText?: boolean | `@${string}`,
		scheduledDate?: boolean | `@${string}`,
		scheduledDateEventId?: boolean | `@${string}`,
		status?: boolean | `@${string}`,
		suggestedScheduledDate?: boolean | `@${string}`,
		type?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "project" */
	["project_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["project"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "project" */
	["project_obj_rel_insert_input"]: {
		data: ResolverInputTypes["project_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["project_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "project" */
	["project_on_conflict"]: {
		constraint: ResolverInputTypes["project_constraint"],
		update_columns: Array<ResolverInputTypes["project_update_column"]>,
		where?: ResolverInputTypes["project_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "project". */
	["project_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		audienceId?: ResolverInputTypes["order_by"] | undefined | null,
		blogContent?: ResolverInputTypes["order_by"] | undefined | null,
		blogTitle?: ResolverInputTypes["order_by"] | undefined | null,
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		campaignId?: ResolverInputTypes["order_by"] | undefined | null,
		caption?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		creditPrice?: ResolverInputTypes["order_by"] | undefined | null,
		deleted?: ResolverInputTypes["order_by"] | undefined | null,
		featuredImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		previewImageFile?: ResolverInputTypes["files_order_by"] | undefined | null,
		previewImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		productId?: ResolverInputTypes["order_by"] | undefined | null,
		publishDate?: ResolverInputTypes["order_by"] | undefined | null,
		rawText?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDate?: ResolverInputTypes["order_by"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["order_by"] | undefined | null,
		status?: ResolverInputTypes["order_by"] | undefined | null,
		suggestedScheduledDate?: ResolverInputTypes["order_by"] | undefined | null,
		template?: ResolverInputTypes["order_by"] | undefined | null,
		type?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: project */
	["project_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["project_prepend_input"]: {
		template?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "project" */
	["project_select_column"]: project_select_column;
	/** input type for updating data in table "project" */
	["project_set_input"]: {
		alias?: string | undefined | null,
		audienceId?: ResolverInputTypes["uuid"] | undefined | null,
		blogContent?: string | undefined | null,
		blogTitle?: string | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		campaignId?: ResolverInputTypes["uuid"] | undefined | null,
		caption?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		creditPrice?: ResolverInputTypes["numeric"] | undefined | null,
		deleted?: boolean | undefined | null,
		featuredImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		previewImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		productId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		rawText?: string | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["uuid"] | undefined | null,
		status?: string | undefined | null,
		suggestedScheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		template?: ResolverInputTypes["jsonb"] | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate stddev on columns */
	["project_stddev_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["project_stddev_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["project_stddev_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "project" */
	["project_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["project_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["project_stream_cursor_value_input"]: {
		alias?: string | undefined | null,
		audienceId?: ResolverInputTypes["uuid"] | undefined | null,
		blogContent?: string | undefined | null,
		blogTitle?: string | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		campaignId?: ResolverInputTypes["uuid"] | undefined | null,
		caption?: string | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		creditPrice?: ResolverInputTypes["numeric"] | undefined | null,
		deleted?: boolean | undefined | null,
		featuredImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		previewImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		productId?: ResolverInputTypes["uuid"] | undefined | null,
		publishDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		rawText?: string | undefined | null,
		scheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		scheduledDateEventId?: ResolverInputTypes["uuid"] | undefined | null,
		status?: string | undefined | null,
		suggestedScheduledDate?: ResolverInputTypes["timestamptz"] | undefined | null,
		template?: ResolverInputTypes["jsonb"] | undefined | null,
		type?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate sum on columns */
	["project_sum_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "project" */
	["project_update_column"]: project_update_column;
	["project_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["project_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["project_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["project_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["project_delete_key_input"] | undefined | null,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["project_inc_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["project_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["project_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["project_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["project_var_pop_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["project_var_samp_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["project_variance_fields"]: AliasType<{
		creditPrice?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	["query_root"]: AliasType<{
		AppIntegration?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["AppIntegration_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["AppIntegration_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["AppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["AppIntegration"]],
		AppIntegration_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["AppIntegration_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["AppIntegration_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["AppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["AppIntegration_aggregate"]],
		AppIntegration_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["AppIntegration"]],
		Course?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["Course_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["Course_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["Course_bool_exp"] | undefined | null
		}, ResolverInputTypes["Course"]],
		CourseCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["CourseCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["CourseCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["CourseCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["CourseCategory"]],
		CourseCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["CourseCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["CourseCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["CourseCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["CourseCategory_aggregate"]],
		CourseCategory_by_pk?: [{ id: ResolverInputTypes["bigint"] }, ResolverInputTypes["CourseCategory"]],
		Course_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["Course_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["Course_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["Course_bool_exp"] | undefined | null
		}, ResolverInputTypes["Course_aggregate"]],
		Course_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["Course"]],
		GeneratedPromptByTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate"]],
		GeneratedPromptByTemplateImage?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplateImage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage_aggregate"]],
		GeneratedPromptByTemplateImage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate_aggregate"]],
		GeneratedPromptByTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["GeneratedPromptByTemplate"]],
		TextGeneratorOption?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOption_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOption_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOption_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOption"]],
		TextGeneratorOptionEntry?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOptionEntry_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry_aggregate"]],
		TextGeneratorOptionEntry_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOption_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOption_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOption_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOption_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOption_aggregate"]],
		TextGeneratorOption_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextGeneratorOption"]],
		TextPromptTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate"]],
		TextPromptTemplateCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplateCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplateCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplateCategory"]],
		TextPromptTemplateCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplateCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplateCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplateCategory_aggregate"]],
		TextPromptTemplateCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextPromptTemplateCategory"]],
		TextPromptTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate_aggregate"]],
		TextPromptTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextPromptTemplate"]],
		TextTemplateClientCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextTemplateClientCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextTemplateClientCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextTemplateClientCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextTemplateClientCategory"]],
		TextTemplateClientCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextTemplateClientCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextTemplateClientCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextTemplateClientCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextTemplateClientCategory_aggregate"]],
		TextTemplateClientCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextTemplateClientCategory"]],
		audiences?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["audiences_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["audiences_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["audiences_bool_exp"] | undefined | null
		}, ResolverInputTypes["audiences"]],
		audiences_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["audiences_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["audiences_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["audiences_bool_exp"] | undefined | null
		}, ResolverInputTypes["audiences_aggregate"]],
		audiences_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["audiences"]],
		authProvider?: [{ id: string }, ResolverInputTypes["authProviders"]],
		authProviderRequest?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authProviderRequests"]],
		authProviderRequests?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authProviderRequests_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authProviderRequests_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviderRequests_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviderRequests"]],
		authProviderRequestsAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authProviderRequests_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authProviderRequests_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviderRequests_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviderRequests_aggregate"]],
		authProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviders"]],
		authProvidersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviders_aggregate"]],
		authRefreshToken?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authRefreshTokens"]],
		authRefreshTokenType?: [{ value: string }, ResolverInputTypes["authRefreshTokenTypes"]],
		authRefreshTokenTypes?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokenTypes_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokenTypes_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokenTypes_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokenTypes"]],
		authRefreshTokenTypesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokenTypes_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokenTypes_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokenTypes_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokenTypes_aggregate"]],
		authRefreshTokens?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokens_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens"]],
		authRefreshTokensAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokens_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens_aggregate"]],
		authRole?: [{ role: string }, ResolverInputTypes["authRoles"]],
		authRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRoles"]],
		authRolesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRoles_aggregate"]],
		authUserProvider?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserProviders"]],
		authUserProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders"]],
		authUserProvidersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders_aggregate"]],
		authUserRole?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserRoles"]],
		authUserRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles"]],
		authUserRolesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles_aggregate"]],
		authUserSecurityKey?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserSecurityKeys"]],
		authUserSecurityKeys?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserSecurityKeys_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserSecurityKeys_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys"]],
		authUserSecurityKeysAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserSecurityKeys_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserSecurityKeys_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys_aggregate"]],
		blog?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blog_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog"]],
		blogCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blogCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blogCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blogCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["blogCategory"]],
		blogCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blogCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blogCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blogCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["blogCategory_aggregate"]],
		blogCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["blogCategory"]],
		blogPostGeneratorEnhancedForAmp?: [{	/** the unique id of an action */
			id: ResolverInputTypes["uuid"]
		}, ResolverInputTypes["blogPostGeneratorEnhancedForAmp"]],
		blog_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blog_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog_aggregate"]],
		blog_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["blog"]],
		brands?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["brands_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["brands_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["brands_bool_exp"] | undefined | null
		}, ResolverInputTypes["brands"]],
		brands_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["brands_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["brands_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["brands_bool_exp"] | undefined | null
		}, ResolverInputTypes["brands_aggregate"]],
		brands_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["brands"]],
		bucket?: [{ id: string }, ResolverInputTypes["buckets"]],
		buckets?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["buckets_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["buckets_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["buckets_bool_exp"] | undefined | null
		}, ResolverInputTypes["buckets"]],
		bucketsAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["buckets_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["buckets_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["buckets_bool_exp"] | undefined | null
		}, ResolverInputTypes["buckets_aggregate"]],
		campaign?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["campaign_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["campaign_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["campaign_bool_exp"] | undefined | null
		}, ResolverInputTypes["campaign"]],
		campaign_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["campaign_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["campaign_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["campaign_bool_exp"] | undefined | null
		}, ResolverInputTypes["campaign_aggregate"]],
		campaign_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["campaign"]],
		categorysummaryview?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["categorysummaryview_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["categorysummaryview_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["categorysummaryview_bool_exp"] | undefined | null
		}, ResolverInputTypes["categorysummaryview"]],
		categorysummaryview_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["categorysummaryview_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["categorysummaryview_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["categorysummaryview_bool_exp"] | undefined | null
		}, ResolverInputTypes["categorysummaryview_aggregate"]],
		channels?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["channels_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["channels_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["channels_bool_exp"] | undefined | null
		}, ResolverInputTypes["channels"]],
		channels_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["channels_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["channels_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["channels_bool_exp"] | undefined | null
		}, ResolverInputTypes["channels_aggregate"]],
		channels_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["channels"]],
		consumptionByChannelView?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["consumptionByChannelView_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["consumptionByChannelView_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["consumptionByChannelView_bool_exp"] | undefined | null
		}, ResolverInputTypes["consumptionByChannelView"]],
		consumptionByChannelView_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["consumptionByChannelView_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["consumptionByChannelView_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["consumptionByChannelView_bool_exp"] | undefined | null
		}, ResolverInputTypes["consumptionByChannelView_aggregate"]],
		costTable?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["costTable_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["costTable_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["costTable_bool_exp"] | undefined | null
		}, ResolverInputTypes["costTable"]],
		costTable_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["costTable_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["costTable_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["costTable_bool_exp"] | undefined | null
		}, ResolverInputTypes["costTable_aggregate"]],
		costTable_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["costTable"]],
		deal?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["deal_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["deal_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["deal_bool_exp"] | undefined | null
		}, ResolverInputTypes["deal"]],
		dealStatus?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["dealStatus_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["dealStatus_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["dealStatus_bool_exp"] | undefined | null
		}, ResolverInputTypes["dealStatus"]],
		dealStatus_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["dealStatus_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["dealStatus_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["dealStatus_bool_exp"] | undefined | null
		}, ResolverInputTypes["dealStatus_aggregate"]],
		dealStatus_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["dealStatus"]],
		deal_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["deal_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["deal_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["deal_bool_exp"] | undefined | null
		}, ResolverInputTypes["deal_aggregate"]],
		deal_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["deal"]],
		displayCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayCategory"]],
		displayCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayCategory_aggregate"]],
		displayCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayCategory"]],
		displayDimension?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayDimension_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayDimension_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayDimension_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayDimension"]],
		displayDimension_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayDimension_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayDimension_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayDimension_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayDimension_aggregate"]],
		displayDimension_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayDimension"]],
		displayFormats?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayFormats_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayFormats_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayFormats"]],
		displayFormats_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayFormats_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayFormats_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayFormats_aggregate"]],
		displayFormats_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayFormats"]],
		displayTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayTemplate"]],
		displayTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayTemplate_aggregate"]],
		displayTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayTemplate"]],
		file?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["files"]],
		files?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["files_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["files_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["files_bool_exp"] | undefined | null
		}, ResolverInputTypes["files"]],
		filesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["files_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["files_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["files_bool_exp"] | undefined | null
		}, ResolverInputTypes["files_aggregate"]],
		generateImageFromPrompt?: [{ command: ResolverInputTypes["GenerateImageFromPromptInput"] }, ResolverInputTypes["GenerateImageFromPromptOutput"]],
		generateNextMonthCalendar?: [{	/** the unique id of an action */
			id: ResolverInputTypes["uuid"]
		}, ResolverInputTypes["generateNextMonthCalendar"]],
		getFacebookPages?: [{ integrationId: string }, ResolverInputTypes["GetFacebookPagesOutput"]],
		getFacebookToken?: ResolverInputTypes["GetFacebookTokenOutput"],
		getImageFromDisplayRenderer?: [{ templateId: string }, ResolverInputTypes["GetImageFromDisplayRendererOutput"]],
		getInstagramPages?: [{ integrationId: string }, ResolverInputTypes["GetInstagramPagesOutput"]],
		getInstagramToken?: ResolverInputTypes["GetInstagramTokenOutput"],
		getPaymentUrl?: [{ command: ResolverInputTypes["GetPaymentUrlCommand"] }, ResolverInputTypes["GetPaymentUrlResponse"]],
		getWordpressCategories?: [{ command?: ResolverInputTypes["GetWordpressCategoriesInput"] | undefined | null }, ResolverInputTypes["GetWordpressCategoriesOutput"]],
		getWordpressTags?: [{ command?: ResolverInputTypes["GetWordpressTagsInput"] | undefined | null }, ResolverInputTypes["GetWordpressTagsOutput"]],
		interaction?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["interaction_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["interaction_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["interaction_bool_exp"] | undefined | null
		}, ResolverInputTypes["interaction"]],
		interaction_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["interaction_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["interaction_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["interaction_bool_exp"] | undefined | null
		}, ResolverInputTypes["interaction_aggregate"]],
		interaction_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["interaction"]],
		landingPageTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["landingPageTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["landingPageTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["landingPageTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["landingPageTemplate"]],
		landingPageTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["landingPageTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["landingPageTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["landingPageTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["landingPageTemplate_aggregate"]],
		landingPageTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["landingPageTemplate"]],
		manageSubscription?: ResolverInputTypes["ManageSubscriptionOutput"],
		newTemplateType?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["newTemplateType_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["newTemplateType_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["newTemplateType_bool_exp"] | undefined | null
		}, ResolverInputTypes["newTemplateType"]],
		newTemplateType_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["newTemplateType_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["newTemplateType_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["newTemplateType_bool_exp"] | undefined | null
		}, ResolverInputTypes["newTemplateType_aggregate"]],
		newTemplateType_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["newTemplateType"]],
		openAIApiKey?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["openAIApiKey_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["openAIApiKey_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["openAIApiKey_bool_exp"] | undefined | null
		}, ResolverInputTypes["openAIApiKey"]],
		openAIApiKey_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["openAIApiKey_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["openAIApiKey_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["openAIApiKey_bool_exp"] | undefined | null
		}, ResolverInputTypes["openAIApiKey_aggregate"]],
		openAIApiKey_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["openAIApiKey"]],
		pTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["pTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["pTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["pTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["pTemplate"]],
		pTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["pTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["pTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["pTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["pTemplate_aggregate"]],
		pTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["pTemplate"]],
		pricingPlan?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["pricingPlan_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["pricingPlan_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null
		}, ResolverInputTypes["pricingPlan"]],
		pricingPlan_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["pricingPlan_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["pricingPlan_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null
		}, ResolverInputTypes["pricingPlan_aggregate"]],
		pricingPlan_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["pricingPlan"]],
		products?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["products_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["products_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["products_bool_exp"] | undefined | null
		}, ResolverInputTypes["products"]],
		products_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["products_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["products_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["products_bool_exp"] | undefined | null
		}, ResolverInputTypes["products_aggregate"]],
		products_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["products"]],
		project?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["project_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["project_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["project_bool_exp"] | undefined | null
		}, ResolverInputTypes["project"]],
		projectImage?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["projectImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["projectImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["projectImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["projectImage"]],
		projectImage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["projectImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["projectImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["projectImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["projectImage_aggregate"]],
		projectImage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["projectImage"]],
		project_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["project_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["project_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["project_bool_exp"] | undefined | null
		}, ResolverInputTypes["project_aggregate"]],
		project_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["project"]],
		renderDisplay?: [{ data: string, template: string }, ResolverInputTypes["RenderDisplayOutput"]],
		searchImagesFromPexels?: [{ locale: string, orientation: string, page: number, query: string }, ResolverInputTypes["SearchImagesFromPexelsResponse"]],
		serpStatTest?: [{ command: ResolverInputTypes["SerpStatTestInput"] }, ResolverInputTypes["SerpStatTestOutput"]],
		task?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["task_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["task_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["task_bool_exp"] | undefined | null
		}, ResolverInputTypes["task"]],
		task_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["task_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["task_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["task_bool_exp"] | undefined | null
		}, ResolverInputTypes["task_aggregate"]],
		task_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["task"]],
		textTemplateDisplayFormat?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["textTemplateDisplayFormat_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["textTemplateDisplayFormat_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat"]],
		textTemplateDisplayFormat_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["textTemplateDisplayFormat_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["textTemplateDisplayFormat_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat_aggregate"]],
		textTemplateDisplayFormat_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["textTemplateDisplayFormat"]],
		user?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["users"]],
		userAppIntegration?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userAppIntegration_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userAppIntegration_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userAppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["userAppIntegration"]],
		userAppIntegration_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userAppIntegration_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userAppIntegration_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userAppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["userAppIntegration_aggregate"]],
		userAppIntegration_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userAppIntegration"]],
		userCreditUsage?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCreditUsage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCreditUsage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsage"]],
		userCreditUsageSummary?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCreditUsageSummary_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCreditUsageSummary_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsageSummary_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsageSummary"]],
		userCreditUsageSummary_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCreditUsageSummary_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCreditUsageSummary_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsageSummary_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsageSummary_aggregate"]],
		userCreditUsageSummary_by_pk?: [{ created_by: ResolverInputTypes["uuid"], yearMonthDayReference: string }, ResolverInputTypes["userCreditUsageSummary"]],
		userCreditUsage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCreditUsage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCreditUsage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsage_aggregate"]],
		userCreditUsage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userCreditUsage"]],
		userCredits?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCredits_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCredits_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCredits_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCredits"]],
		userCredits_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCredits_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCredits_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCredits_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCredits_aggregate"]],
		userCredits_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userCredits"]],
		userFeedback?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userFeedback_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userFeedback_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userFeedback_bool_exp"] | undefined | null
		}, ResolverInputTypes["userFeedback"]],
		userFeedback_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userFeedback_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userFeedback_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userFeedback_bool_exp"] | undefined | null
		}, ResolverInputTypes["userFeedback_aggregate"]],
		userFeedback_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userFeedback"]],
		userMedia?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMedia_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMedia_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMedia_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMedia"]],
		userMedia_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMedia_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMedia_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMedia_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMedia_aggregate"]],
		userMedia_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userMedia"]],
		userMetadata?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMetadata_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMetadata_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMetadata"]],
		userMetadata_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMetadata_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMetadata_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMetadata_aggregate"]],
		userMetadata_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userMetadata"]],
		userOnboarding?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userOnboarding_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userOnboarding_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userOnboarding_bool_exp"] | undefined | null
		}, ResolverInputTypes["userOnboarding"]],
		userOnboarding_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userOnboarding_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userOnboarding_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userOnboarding_bool_exp"] | undefined | null
		}, ResolverInputTypes["userOnboarding_aggregate"]],
		userOnboarding_by_pk?: [{ userId: ResolverInputTypes["uuid"] }, ResolverInputTypes["userOnboarding"]],
		userTemplateUsage?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userTemplateUsage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userTemplateUsage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userTemplateUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userTemplateUsage"]],
		userTemplateUsage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userTemplateUsage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userTemplateUsage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userTemplateUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userTemplateUsage_aggregate"]],
		userTemplateUsage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userTemplateUsage"]],
		users?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["users_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["users_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["users_bool_exp"] | undefined | null
		}, ResolverInputTypes["users"]],
		usersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["users_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["users_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["users_bool_exp"] | undefined | null
		}, ResolverInputTypes["users_aggregate"]],
		virus?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["virus"]],
		viruses?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["virus_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["virus_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["virus_bool_exp"] | undefined | null
		}, ResolverInputTypes["virus"]],
		virusesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["virus_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["virus_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["virus_bool_exp"] | undefined | null
		}, ResolverInputTypes["virus_aggregate"]],
		website?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["website_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["website_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["website_bool_exp"] | undefined | null
		}, ResolverInputTypes["website"]],
		website_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["website_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["website_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["website_bool_exp"] | undefined | null
		}, ResolverInputTypes["website_aggregate"]],
		website_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["website"]],
		__typename?: boolean | `@${string}`
	}>;
	["subscription_root"]: AliasType<{
		AppIntegration?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["AppIntegration_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["AppIntegration_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["AppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["AppIntegration"]],
		AppIntegration_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["AppIntegration_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["AppIntegration_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["AppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["AppIntegration_aggregate"]],
		AppIntegration_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["AppIntegration"]],
		AppIntegration_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["AppIntegration_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["AppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["AppIntegration"]],
		Course?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["Course_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["Course_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["Course_bool_exp"] | undefined | null
		}, ResolverInputTypes["Course"]],
		CourseCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["CourseCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["CourseCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["CourseCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["CourseCategory"]],
		CourseCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["CourseCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["CourseCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["CourseCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["CourseCategory_aggregate"]],
		CourseCategory_by_pk?: [{ id: ResolverInputTypes["bigint"] }, ResolverInputTypes["CourseCategory"]],
		CourseCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["CourseCategory_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["CourseCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["CourseCategory"]],
		Course_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["Course_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["Course_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["Course_bool_exp"] | undefined | null
		}, ResolverInputTypes["Course_aggregate"]],
		Course_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["Course"]],
		Course_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["Course_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["Course_bool_exp"] | undefined | null
		}, ResolverInputTypes["Course"]],
		GeneratedPromptByTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate"]],
		GeneratedPromptByTemplateImage?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplateImage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage_aggregate"]],
		GeneratedPromptByTemplateImage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplateImage_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["GeneratedPromptByTemplateImage_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplateImage"]],
		GeneratedPromptByTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["GeneratedPromptByTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["GeneratedPromptByTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate_aggregate"]],
		GeneratedPromptByTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["GeneratedPromptByTemplate"]],
		GeneratedPromptByTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["GeneratedPromptByTemplate_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["GeneratedPromptByTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["GeneratedPromptByTemplate"]],
		TextGeneratorOption?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOption_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOption_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOption_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOption"]],
		TextGeneratorOptionEntry?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOptionEntry_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOptionEntry_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOptionEntry_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry_aggregate"]],
		TextGeneratorOptionEntry_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOptionEntry_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["TextGeneratorOptionEntry_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOptionEntry_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOptionEntry"]],
		TextGeneratorOption_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextGeneratorOption_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextGeneratorOption_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOption_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOption_aggregate"]],
		TextGeneratorOption_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextGeneratorOption"]],
		TextGeneratorOption_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["TextGeneratorOption_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["TextGeneratorOption_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextGeneratorOption"]],
		TextPromptTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate"]],
		TextPromptTemplateCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplateCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplateCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplateCategory"]],
		TextPromptTemplateCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplateCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplateCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplateCategory_aggregate"]],
		TextPromptTemplateCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextPromptTemplateCategory"]],
		TextPromptTemplateCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["TextPromptTemplateCategory_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplateCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplateCategory"]],
		TextPromptTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextPromptTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextPromptTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate_aggregate"]],
		TextPromptTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextPromptTemplate"]],
		TextPromptTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["TextPromptTemplate_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["TextPromptTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextPromptTemplate"]],
		TextTemplateClientCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextTemplateClientCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextTemplateClientCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextTemplateClientCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextTemplateClientCategory"]],
		TextTemplateClientCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["TextTemplateClientCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["TextTemplateClientCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["TextTemplateClientCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextTemplateClientCategory_aggregate"]],
		TextTemplateClientCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["TextTemplateClientCategory"]],
		TextTemplateClientCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["TextTemplateClientCategory_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["TextTemplateClientCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["TextTemplateClientCategory"]],
		audiences?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["audiences_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["audiences_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["audiences_bool_exp"] | undefined | null
		}, ResolverInputTypes["audiences"]],
		audiences_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["audiences_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["audiences_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["audiences_bool_exp"] | undefined | null
		}, ResolverInputTypes["audiences_aggregate"]],
		audiences_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["audiences"]],
		audiences_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["audiences_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["audiences_bool_exp"] | undefined | null
		}, ResolverInputTypes["audiences"]],
		authProvider?: [{ id: string }, ResolverInputTypes["authProviders"]],
		authProviderRequest?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authProviderRequests"]],
		authProviderRequests?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authProviderRequests_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authProviderRequests_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviderRequests_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviderRequests"]],
		authProviderRequestsAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authProviderRequests_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authProviderRequests_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviderRequests_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviderRequests_aggregate"]],
		authProviderRequests_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["authProviderRequests_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviderRequests_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviderRequests"]],
		authProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviders"]],
		authProvidersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviders_aggregate"]],
		authProviders_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["authProviders_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["authProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authProviders"]],
		authRefreshToken?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authRefreshTokens"]],
		authRefreshTokenType?: [{ value: string }, ResolverInputTypes["authRefreshTokenTypes"]],
		authRefreshTokenTypes?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokenTypes_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokenTypes_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokenTypes_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokenTypes"]],
		authRefreshTokenTypesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokenTypes_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokenTypes_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokenTypes_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokenTypes_aggregate"]],
		authRefreshTokenTypes_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["authRefreshTokenTypes_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokenTypes_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokenTypes"]],
		authRefreshTokens?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokens_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens"]],
		authRefreshTokensAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokens_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens_aggregate"]],
		authRefreshTokens_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["authRefreshTokens_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens"]],
		authRole?: [{ role: string }, ResolverInputTypes["authRoles"]],
		authRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRoles"]],
		authRolesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRoles_aggregate"]],
		authRoles_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["authRoles_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRoles"]],
		authUserProvider?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserProviders"]],
		authUserProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders"]],
		authUserProvidersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders_aggregate"]],
		authUserProviders_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["authUserProviders_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders"]],
		authUserRole?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserRoles"]],
		authUserRoles?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles"]],
		authUserRolesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles_aggregate"]],
		authUserRoles_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["authUserRoles_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles"]],
		authUserSecurityKey?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["authUserSecurityKeys"]],
		authUserSecurityKeys?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserSecurityKeys_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserSecurityKeys_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys"]],
		authUserSecurityKeysAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserSecurityKeys_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserSecurityKeys_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys_aggregate"]],
		authUserSecurityKeys_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["authUserSecurityKeys_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys"]],
		blog?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blog_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog"]],
		blogCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blogCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blogCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blogCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["blogCategory"]],
		blogCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blogCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blogCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blogCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["blogCategory_aggregate"]],
		blogCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["blogCategory"]],
		blogCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["blogCategory_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["blogCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["blogCategory"]],
		blogPostGeneratorEnhancedForAmp?: [{	/** the unique id of an action */
			id: ResolverInputTypes["uuid"]
		}, ResolverInputTypes["blogPostGeneratorEnhancedForAmp"]],
		blog_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["blog_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["blog_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog_aggregate"]],
		blog_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["blog"]],
		blog_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["blog_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["blog_bool_exp"] | undefined | null
		}, ResolverInputTypes["blog"]],
		brands?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["brands_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["brands_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["brands_bool_exp"] | undefined | null
		}, ResolverInputTypes["brands"]],
		brands_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["brands_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["brands_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["brands_bool_exp"] | undefined | null
		}, ResolverInputTypes["brands_aggregate"]],
		brands_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["brands"]],
		brands_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["brands_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["brands_bool_exp"] | undefined | null
		}, ResolverInputTypes["brands"]],
		bucket?: [{ id: string }, ResolverInputTypes["buckets"]],
		buckets?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["buckets_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["buckets_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["buckets_bool_exp"] | undefined | null
		}, ResolverInputTypes["buckets"]],
		bucketsAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["buckets_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["buckets_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["buckets_bool_exp"] | undefined | null
		}, ResolverInputTypes["buckets_aggregate"]],
		buckets_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["buckets_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["buckets_bool_exp"] | undefined | null
		}, ResolverInputTypes["buckets"]],
		campaign?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["campaign_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["campaign_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["campaign_bool_exp"] | undefined | null
		}, ResolverInputTypes["campaign"]],
		campaign_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["campaign_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["campaign_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["campaign_bool_exp"] | undefined | null
		}, ResolverInputTypes["campaign_aggregate"]],
		campaign_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["campaign"]],
		campaign_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["campaign_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["campaign_bool_exp"] | undefined | null
		}, ResolverInputTypes["campaign"]],
		categorysummaryview?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["categorysummaryview_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["categorysummaryview_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["categorysummaryview_bool_exp"] | undefined | null
		}, ResolverInputTypes["categorysummaryview"]],
		categorysummaryview_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["categorysummaryview_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["categorysummaryview_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["categorysummaryview_bool_exp"] | undefined | null
		}, ResolverInputTypes["categorysummaryview_aggregate"]],
		categorysummaryview_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["categorysummaryview_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["categorysummaryview_bool_exp"] | undefined | null
		}, ResolverInputTypes["categorysummaryview"]],
		channels?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["channels_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["channels_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["channels_bool_exp"] | undefined | null
		}, ResolverInputTypes["channels"]],
		channels_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["channels_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["channels_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["channels_bool_exp"] | undefined | null
		}, ResolverInputTypes["channels_aggregate"]],
		channels_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["channels"]],
		channels_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["channels_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["channels_bool_exp"] | undefined | null
		}, ResolverInputTypes["channels"]],
		consumptionByChannelView?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["consumptionByChannelView_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["consumptionByChannelView_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["consumptionByChannelView_bool_exp"] | undefined | null
		}, ResolverInputTypes["consumptionByChannelView"]],
		consumptionByChannelView_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["consumptionByChannelView_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["consumptionByChannelView_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["consumptionByChannelView_bool_exp"] | undefined | null
		}, ResolverInputTypes["consumptionByChannelView_aggregate"]],
		consumptionByChannelView_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["consumptionByChannelView_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["consumptionByChannelView_bool_exp"] | undefined | null
		}, ResolverInputTypes["consumptionByChannelView"]],
		costTable?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["costTable_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["costTable_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["costTable_bool_exp"] | undefined | null
		}, ResolverInputTypes["costTable"]],
		costTable_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["costTable_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["costTable_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["costTable_bool_exp"] | undefined | null
		}, ResolverInputTypes["costTable_aggregate"]],
		costTable_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["costTable"]],
		costTable_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["costTable_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["costTable_bool_exp"] | undefined | null
		}, ResolverInputTypes["costTable"]],
		deal?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["deal_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["deal_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["deal_bool_exp"] | undefined | null
		}, ResolverInputTypes["deal"]],
		dealStatus?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["dealStatus_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["dealStatus_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["dealStatus_bool_exp"] | undefined | null
		}, ResolverInputTypes["dealStatus"]],
		dealStatus_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["dealStatus_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["dealStatus_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["dealStatus_bool_exp"] | undefined | null
		}, ResolverInputTypes["dealStatus_aggregate"]],
		dealStatus_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["dealStatus"]],
		dealStatus_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["dealStatus_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["dealStatus_bool_exp"] | undefined | null
		}, ResolverInputTypes["dealStatus"]],
		deal_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["deal_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["deal_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["deal_bool_exp"] | undefined | null
		}, ResolverInputTypes["deal_aggregate"]],
		deal_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["deal"]],
		deal_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["deal_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["deal_bool_exp"] | undefined | null
		}, ResolverInputTypes["deal"]],
		displayCategory?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayCategory"]],
		displayCategory_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayCategory_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayCategory_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayCategory_aggregate"]],
		displayCategory_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayCategory"]],
		displayCategory_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["displayCategory_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["displayCategory_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayCategory"]],
		displayDimension?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayDimension_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayDimension_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayDimension_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayDimension"]],
		displayDimension_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayDimension_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayDimension_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayDimension_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayDimension_aggregate"]],
		displayDimension_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayDimension"]],
		displayDimension_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["displayDimension_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["displayDimension_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayDimension"]],
		displayFormats?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayFormats_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayFormats_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayFormats"]],
		displayFormats_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayFormats_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayFormats_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayFormats_aggregate"]],
		displayFormats_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayFormats"]],
		displayFormats_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["displayFormats_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayFormats"]],
		displayTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayTemplate"]],
		displayTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["displayTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["displayTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["displayTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayTemplate_aggregate"]],
		displayTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["displayTemplate"]],
		displayTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["displayTemplate_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["displayTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["displayTemplate"]],
		file?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["files"]],
		files?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["files_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["files_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["files_bool_exp"] | undefined | null
		}, ResolverInputTypes["files"]],
		filesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["files_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["files_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["files_bool_exp"] | undefined | null
		}, ResolverInputTypes["files_aggregate"]],
		files_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["files_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["files_bool_exp"] | undefined | null
		}, ResolverInputTypes["files"]],
		generateNextMonthCalendar?: [{	/** the unique id of an action */
			id: ResolverInputTypes["uuid"]
		}, ResolverInputTypes["generateNextMonthCalendar"]],
		interaction?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["interaction_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["interaction_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["interaction_bool_exp"] | undefined | null
		}, ResolverInputTypes["interaction"]],
		interaction_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["interaction_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["interaction_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["interaction_bool_exp"] | undefined | null
		}, ResolverInputTypes["interaction_aggregate"]],
		interaction_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["interaction"]],
		interaction_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["interaction_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["interaction_bool_exp"] | undefined | null
		}, ResolverInputTypes["interaction"]],
		landingPageTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["landingPageTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["landingPageTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["landingPageTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["landingPageTemplate"]],
		landingPageTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["landingPageTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["landingPageTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["landingPageTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["landingPageTemplate_aggregate"]],
		landingPageTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["landingPageTemplate"]],
		landingPageTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["landingPageTemplate_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["landingPageTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["landingPageTemplate"]],
		newTemplateType?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["newTemplateType_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["newTemplateType_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["newTemplateType_bool_exp"] | undefined | null
		}, ResolverInputTypes["newTemplateType"]],
		newTemplateType_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["newTemplateType_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["newTemplateType_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["newTemplateType_bool_exp"] | undefined | null
		}, ResolverInputTypes["newTemplateType_aggregate"]],
		newTemplateType_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["newTemplateType"]],
		newTemplateType_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["newTemplateType_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["newTemplateType_bool_exp"] | undefined | null
		}, ResolverInputTypes["newTemplateType"]],
		openAIApiKey?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["openAIApiKey_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["openAIApiKey_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["openAIApiKey_bool_exp"] | undefined | null
		}, ResolverInputTypes["openAIApiKey"]],
		openAIApiKey_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["openAIApiKey_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["openAIApiKey_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["openAIApiKey_bool_exp"] | undefined | null
		}, ResolverInputTypes["openAIApiKey_aggregate"]],
		openAIApiKey_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["openAIApiKey"]],
		openAIApiKey_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["openAIApiKey_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["openAIApiKey_bool_exp"] | undefined | null
		}, ResolverInputTypes["openAIApiKey"]],
		pTemplate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["pTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["pTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["pTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["pTemplate"]],
		pTemplate_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["pTemplate_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["pTemplate_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["pTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["pTemplate_aggregate"]],
		pTemplate_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["pTemplate"]],
		pTemplate_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["pTemplate_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["pTemplate_bool_exp"] | undefined | null
		}, ResolverInputTypes["pTemplate"]],
		pricingPlan?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["pricingPlan_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["pricingPlan_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null
		}, ResolverInputTypes["pricingPlan"]],
		pricingPlan_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["pricingPlan_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["pricingPlan_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null
		}, ResolverInputTypes["pricingPlan_aggregate"]],
		pricingPlan_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["pricingPlan"]],
		pricingPlan_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["pricingPlan_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null
		}, ResolverInputTypes["pricingPlan"]],
		products?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["products_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["products_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["products_bool_exp"] | undefined | null
		}, ResolverInputTypes["products"]],
		products_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["products_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["products_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["products_bool_exp"] | undefined | null
		}, ResolverInputTypes["products_aggregate"]],
		products_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["products"]],
		products_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["products_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["products_bool_exp"] | undefined | null
		}, ResolverInputTypes["products"]],
		project?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["project_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["project_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["project_bool_exp"] | undefined | null
		}, ResolverInputTypes["project"]],
		projectImage?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["projectImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["projectImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["projectImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["projectImage"]],
		projectImage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["projectImage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["projectImage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["projectImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["projectImage_aggregate"]],
		projectImage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["projectImage"]],
		projectImage_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["projectImage_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["projectImage_bool_exp"] | undefined | null
		}, ResolverInputTypes["projectImage"]],
		project_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["project_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["project_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["project_bool_exp"] | undefined | null
		}, ResolverInputTypes["project_aggregate"]],
		project_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["project"]],
		project_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["project_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["project_bool_exp"] | undefined | null
		}, ResolverInputTypes["project"]],
		task?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["task_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["task_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["task_bool_exp"] | undefined | null
		}, ResolverInputTypes["task"]],
		task_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["task_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["task_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["task_bool_exp"] | undefined | null
		}, ResolverInputTypes["task_aggregate"]],
		task_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["task"]],
		task_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["task_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["task_bool_exp"] | undefined | null
		}, ResolverInputTypes["task"]],
		textTemplateDisplayFormat?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["textTemplateDisplayFormat_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["textTemplateDisplayFormat_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat"]],
		textTemplateDisplayFormat_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["textTemplateDisplayFormat_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["textTemplateDisplayFormat_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat_aggregate"]],
		textTemplateDisplayFormat_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["textTemplateDisplayFormat"]],
		textTemplateDisplayFormat_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["textTemplateDisplayFormat_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null
		}, ResolverInputTypes["textTemplateDisplayFormat"]],
		user?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["users"]],
		userAppIntegration?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userAppIntegration_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userAppIntegration_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userAppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["userAppIntegration"]],
		userAppIntegration_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userAppIntegration_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userAppIntegration_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userAppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["userAppIntegration_aggregate"]],
		userAppIntegration_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userAppIntegration"]],
		userAppIntegration_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userAppIntegration_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userAppIntegration_bool_exp"] | undefined | null
		}, ResolverInputTypes["userAppIntegration"]],
		userCreditUsage?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCreditUsage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCreditUsage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsage"]],
		userCreditUsageSummary?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCreditUsageSummary_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCreditUsageSummary_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsageSummary_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsageSummary"]],
		userCreditUsageSummary_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCreditUsageSummary_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCreditUsageSummary_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsageSummary_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsageSummary_aggregate"]],
		userCreditUsageSummary_by_pk?: [{ created_by: ResolverInputTypes["uuid"], yearMonthDayReference: string }, ResolverInputTypes["userCreditUsageSummary"]],
		userCreditUsageSummary_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userCreditUsageSummary_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsageSummary_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsageSummary"]],
		userCreditUsage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCreditUsage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCreditUsage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsage_aggregate"]],
		userCreditUsage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userCreditUsage"]],
		userCreditUsage_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userCreditUsage_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userCreditUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCreditUsage"]],
		userCredits?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCredits_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCredits_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCredits_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCredits"]],
		userCredits_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userCredits_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userCredits_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userCredits_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCredits_aggregate"]],
		userCredits_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userCredits"]],
		userCredits_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userCredits_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userCredits_bool_exp"] | undefined | null
		}, ResolverInputTypes["userCredits"]],
		userFeedback?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userFeedback_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userFeedback_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userFeedback_bool_exp"] | undefined | null
		}, ResolverInputTypes["userFeedback"]],
		userFeedback_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userFeedback_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userFeedback_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userFeedback_bool_exp"] | undefined | null
		}, ResolverInputTypes["userFeedback_aggregate"]],
		userFeedback_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userFeedback"]],
		userFeedback_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userFeedback_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userFeedback_bool_exp"] | undefined | null
		}, ResolverInputTypes["userFeedback"]],
		userMedia?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMedia_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMedia_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMedia_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMedia"]],
		userMedia_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMedia_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMedia_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMedia_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMedia_aggregate"]],
		userMedia_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userMedia"]],
		userMedia_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userMedia_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userMedia_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMedia"]],
		userMetadata?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMetadata_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMetadata_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMetadata"]],
		userMetadata_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMetadata_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMetadata_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMetadata_aggregate"]],
		userMetadata_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userMetadata"]],
		userMetadata_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userMetadata_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMetadata"]],
		userOnboarding?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userOnboarding_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userOnboarding_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userOnboarding_bool_exp"] | undefined | null
		}, ResolverInputTypes["userOnboarding"]],
		userOnboarding_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userOnboarding_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userOnboarding_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userOnboarding_bool_exp"] | undefined | null
		}, ResolverInputTypes["userOnboarding_aggregate"]],
		userOnboarding_by_pk?: [{ userId: ResolverInputTypes["uuid"] }, ResolverInputTypes["userOnboarding"]],
		userOnboarding_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userOnboarding_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userOnboarding_bool_exp"] | undefined | null
		}, ResolverInputTypes["userOnboarding"]],
		userTemplateUsage?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userTemplateUsage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userTemplateUsage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userTemplateUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userTemplateUsage"]],
		userTemplateUsage_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userTemplateUsage_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userTemplateUsage_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userTemplateUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userTemplateUsage_aggregate"]],
		userTemplateUsage_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["userTemplateUsage"]],
		userTemplateUsage_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["userTemplateUsage_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["userTemplateUsage_bool_exp"] | undefined | null
		}, ResolverInputTypes["userTemplateUsage"]],
		users?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["users_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["users_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["users_bool_exp"] | undefined | null
		}, ResolverInputTypes["users"]],
		usersAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["users_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["users_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["users_bool_exp"] | undefined | null
		}, ResolverInputTypes["users_aggregate"]],
		users_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["users_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["users_bool_exp"] | undefined | null
		}, ResolverInputTypes["users"]],
		virus?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["virus"]],
		virus_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["virus_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["virus_bool_exp"] | undefined | null
		}, ResolverInputTypes["virus"]],
		viruses?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["virus_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["virus_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["virus_bool_exp"] | undefined | null
		}, ResolverInputTypes["virus"]],
		virusesAggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["virus_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["virus_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["virus_bool_exp"] | undefined | null
		}, ResolverInputTypes["virus_aggregate"]],
		website?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["website_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["website_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["website_bool_exp"] | undefined | null
		}, ResolverInputTypes["website"]],
		website_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["website_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["website_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["website_bool_exp"] | undefined | null
		}, ResolverInputTypes["website_aggregate"]],
		website_by_pk?: [{ id: ResolverInputTypes["uuid"] }, ResolverInputTypes["website"]],
		website_stream?: [{	/** maximum number of rows returned in a single batch */
			batch_size: number,	/** cursor to stream the results returned by the query */
			cursor: Array<ResolverInputTypes["website_stream_cursor_input"] | undefined | null>,	/** filter the rows returned */
			where?: ResolverInputTypes["website_bool_exp"] | undefined | null
		}, ResolverInputTypes["website"]],
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "task" */
	["task"]: AliasType<{
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		/** An object relationship */
		deal?: ResolverInputTypes["deal"],
		dealId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		finished?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		/** An object relationship */
		user?: ResolverInputTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "task" */
	["task_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["task_aggregate_fields"],
		nodes?: ResolverInputTypes["task"],
		__typename?: boolean | `@${string}`
	}>;
	["task_aggregate_bool_exp"]: {
		bool_and?: ResolverInputTypes["task_aggregate_bool_exp_bool_and"] | undefined | null,
		bool_or?: ResolverInputTypes["task_aggregate_bool_exp_bool_or"] | undefined | null,
		count?: ResolverInputTypes["task_aggregate_bool_exp_count"] | undefined | null
	};
	["task_aggregate_bool_exp_bool_and"]: {
		arguments: ResolverInputTypes["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["task_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["task_aggregate_bool_exp_bool_or"]: {
		arguments: ResolverInputTypes["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["task_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["task_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["task_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["task_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "task" */
	["task_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["task_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["task_max_fields"],
		min?: ResolverInputTypes["task_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "task" */
	["task_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["task_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["task_min_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "task" */
	["task_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["task_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["task_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
	["task_bool_exp"]: {
		_and?: Array<ResolverInputTypes["task_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["task_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["task_bool_exp"]> | undefined | null,
		category?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		date?: ResolverInputTypes["timestamp_comparison_exp"] | undefined | null,
		deal?: ResolverInputTypes["deal_bool_exp"] | undefined | null,
		dealId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		description?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		finished?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		title?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		user?: ResolverInputTypes["users_bool_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "task" */
	["task_constraint"]: task_constraint;
	/** input type for inserting data into table "task" */
	["task_insert_input"]: {
		category?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		date?: ResolverInputTypes["timestamp"] | undefined | null,
		deal?: ResolverInputTypes["deal_obj_rel_insert_input"] | undefined | null,
		dealId?: ResolverInputTypes["uuid"] | undefined | null,
		description?: string | undefined | null,
		finished?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		title?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		user?: ResolverInputTypes["users_obj_rel_insert_input"] | undefined | null
	};
	/** aggregate max on columns */
	["task_max_fields"]: AliasType<{
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "task" */
	["task_max_order_by"]: {
		category?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		date?: ResolverInputTypes["order_by"] | undefined | null,
		dealId?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		title?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["task_min_fields"]: AliasType<{
		category?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		date?: boolean | `@${string}`,
		dealId?: boolean | `@${string}`,
		description?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		title?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "task" */
	["task_min_order_by"]: {
		category?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		date?: ResolverInputTypes["order_by"] | undefined | null,
		dealId?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		title?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "task" */
	["task_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["task"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "task" */
	["task_on_conflict"]: {
		constraint: ResolverInputTypes["task_constraint"],
		update_columns: Array<ResolverInputTypes["task_update_column"]>,
		where?: ResolverInputTypes["task_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "task". */
	["task_order_by"]: {
		category?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		date?: ResolverInputTypes["order_by"] | undefined | null,
		deal?: ResolverInputTypes["deal_order_by"] | undefined | null,
		dealId?: ResolverInputTypes["order_by"] | undefined | null,
		description?: ResolverInputTypes["order_by"] | undefined | null,
		finished?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		title?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		user?: ResolverInputTypes["users_order_by"] | undefined | null
	};
	/** primary key columns input for table: task */
	["task_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "task" */
	["task_select_column"]: task_select_column;
	/** select "task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task" */
	["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"]: task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task" */
	["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"]: task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "task" */
	["task_set_input"]: {
		category?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		date?: ResolverInputTypes["timestamp"] | undefined | null,
		dealId?: ResolverInputTypes["uuid"] | undefined | null,
		description?: string | undefined | null,
		finished?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		title?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "task" */
	["task_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["task_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["task_stream_cursor_value_input"]: {
		category?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		date?: ResolverInputTypes["timestamp"] | undefined | null,
		dealId?: ResolverInputTypes["uuid"] | undefined | null,
		description?: string | undefined | null,
		finished?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		title?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "task" */
	["task_update_column"]: task_update_column;
	["task_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["task_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["task_bool_exp"]
	};
	/** columns and relationships of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat"]: AliasType<{
		/** An object relationship */
		displayFormat?: ResolverInputTypes["displayFormats"],
		displayFormatId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["textTemplateDisplayFormat_aggregate_fields"],
		nodes?: ResolverInputTypes["textTemplateDisplayFormat"],
		__typename?: boolean | `@${string}`
	}>;
	["textTemplateDisplayFormat_aggregate_bool_exp"]: {
		count?: ResolverInputTypes["textTemplateDisplayFormat_aggregate_bool_exp_count"] | undefined | null
	};
	["textTemplateDisplayFormat_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["textTemplateDisplayFormat_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["textTemplateDisplayFormat_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["textTemplateDisplayFormat_max_fields"],
		min?: ResolverInputTypes["textTemplateDisplayFormat_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["textTemplateDisplayFormat_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["textTemplateDisplayFormat_min_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["textTemplateDisplayFormat_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["textTemplateDisplayFormat_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "textTemplateDisplayFormat". All fields are combined with a logical 'AND'. */
	["textTemplateDisplayFormat_bool_exp"]: {
		_and?: Array<ResolverInputTypes["textTemplateDisplayFormat_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["textTemplateDisplayFormat_bool_exp"]> | undefined | null,
		displayFormat?: ResolverInputTypes["displayFormats_bool_exp"] | undefined | null,
		displayFormatId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		textTemplateId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_constraint"]: textTemplateDisplayFormat_constraint;
	/** input type for inserting data into table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_insert_input"]: {
		displayFormat?: ResolverInputTypes["displayFormats_obj_rel_insert_input"] | undefined | null,
		displayFormatId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		textTemplateId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["textTemplateDisplayFormat_max_fields"]: AliasType<{
		displayFormatId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_max_order_by"]: {
		displayFormatId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		textTemplateId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["textTemplateDisplayFormat_min_fields"]: AliasType<{
		displayFormatId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_min_order_by"]: {
		displayFormatId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		textTemplateId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["textTemplateDisplayFormat"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_on_conflict"]: {
		constraint: ResolverInputTypes["textTemplateDisplayFormat_constraint"],
		update_columns: Array<ResolverInputTypes["textTemplateDisplayFormat_update_column"]>,
		where?: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "textTemplateDisplayFormat". */
	["textTemplateDisplayFormat_order_by"]: {
		displayFormat?: ResolverInputTypes["displayFormats_order_by"] | undefined | null,
		displayFormatId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		textTemplateId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: textTemplateDisplayFormat */
	["textTemplateDisplayFormat_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_select_column"]: textTemplateDisplayFormat_select_column;
	/** input type for updating data in table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_set_input"]: {
		displayFormatId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		textTemplateId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["textTemplateDisplayFormat_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["textTemplateDisplayFormat_stream_cursor_value_input"]: {
		displayFormatId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		textTemplateId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_update_column"]: textTemplateDisplayFormat_update_column;
	["textTemplateDisplayFormat_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["textTemplateDisplayFormat_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["textTemplateDisplayFormat_bool_exp"]
	};
	["timestamp"]: unknown;
	/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
	["timestamp_comparison_exp"]: {
		_eq?: ResolverInputTypes["timestamp"] | undefined | null,
		_gt?: ResolverInputTypes["timestamp"] | undefined | null,
		_gte?: ResolverInputTypes["timestamp"] | undefined | null,
		_in?: Array<ResolverInputTypes["timestamp"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: ResolverInputTypes["timestamp"] | undefined | null,
		_lte?: ResolverInputTypes["timestamp"] | undefined | null,
		_neq?: ResolverInputTypes["timestamp"] | undefined | null,
		_nin?: Array<ResolverInputTypes["timestamp"]> | undefined | null
	};
	["timestamptz"]: unknown;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
	["timestamptz_comparison_exp"]: {
		_eq?: ResolverInputTypes["timestamptz"] | undefined | null,
		_gt?: ResolverInputTypes["timestamptz"] | undefined | null,
		_gte?: ResolverInputTypes["timestamptz"] | undefined | null,
		_in?: Array<ResolverInputTypes["timestamptz"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: ResolverInputTypes["timestamptz"] | undefined | null,
		_lte?: ResolverInputTypes["timestamptz"] | undefined | null,
		_neq?: ResolverInputTypes["timestamptz"] | undefined | null,
		_nin?: Array<ResolverInputTypes["timestamptz"]> | undefined | null
	};
	["upsertBrandOutput"]: AliasType<{
		archtypeAdherence?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		colors?: boolean | `@${string}`,
		fontPrimary?: boolean | `@${string}`,
		fontSecondary?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		isBrand?: boolean | `@${string}`,
		logoFileId?: boolean | `@${string}`,
		personality?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		segment?: boolean | `@${string}`,
		slogan?: boolean | `@${string}`,
		voiceTone?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userAppIntegration" */
	["userAppIntegration"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		integrationType?: boolean | `@${string}`,
		params?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userAppIntegration" */
	["userAppIntegration_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userAppIntegration_aggregate_fields"],
		nodes?: ResolverInputTypes["userAppIntegration"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userAppIntegration" */
	["userAppIntegration_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["userAppIntegration_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userAppIntegration_max_fields"],
		min?: ResolverInputTypes["userAppIntegration_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["userAppIntegration_append_input"]: {
		params?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "userAppIntegration". All fields are combined with a logical 'AND'. */
	["userAppIntegration_bool_exp"]: {
		_and?: Array<ResolverInputTypes["userAppIntegration_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userAppIntegration_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userAppIntegration_bool_exp"]> | undefined | null,
		alias?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		brandId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		deleted?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		integrationType?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		params?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userAppIntegration" */
	["userAppIntegration_constraint"]: userAppIntegration_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["userAppIntegration_delete_at_path_input"]: {
		params?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["userAppIntegration_delete_elem_input"]: {
		params?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["userAppIntegration_delete_key_input"]: {
		params?: string | undefined | null
	};
	/** input type for inserting data into table "userAppIntegration" */
	["userAppIntegration_insert_input"]: {
		alias?: string | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		integrationType?: string | undefined | null,
		params?: ResolverInputTypes["jsonb"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["userAppIntegration_max_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		integrationType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userAppIntegration_min_fields"]: AliasType<{
		alias?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		integrationType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userAppIntegration" */
	["userAppIntegration_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userAppIntegration"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userAppIntegration" */
	["userAppIntegration_on_conflict"]: {
		constraint: ResolverInputTypes["userAppIntegration_constraint"],
		update_columns: Array<ResolverInputTypes["userAppIntegration_update_column"]>,
		where?: ResolverInputTypes["userAppIntegration_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userAppIntegration". */
	["userAppIntegration_order_by"]: {
		alias?: ResolverInputTypes["order_by"] | undefined | null,
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		deleted?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		integrationType?: ResolverInputTypes["order_by"] | undefined | null,
		params?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: userAppIntegration */
	["userAppIntegration_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["userAppIntegration_prepend_input"]: {
		params?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "userAppIntegration" */
	["userAppIntegration_select_column"]: userAppIntegration_select_column;
	/** input type for updating data in table "userAppIntegration" */
	["userAppIntegration_set_input"]: {
		alias?: string | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		integrationType?: string | undefined | null,
		params?: ResolverInputTypes["jsonb"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "userAppIntegration" */
	["userAppIntegration_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userAppIntegration_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userAppIntegration_stream_cursor_value_input"]: {
		alias?: string | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		deleted?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		integrationType?: string | undefined | null,
		params?: ResolverInputTypes["jsonb"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "userAppIntegration" */
	["userAppIntegration_update_column"]: userAppIntegration_update_column;
	["userAppIntegration_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["userAppIntegration_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["userAppIntegration_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["userAppIntegration_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["userAppIntegration_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["userAppIntegration_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userAppIntegration_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userAppIntegration_bool_exp"]
	};
	/** columns and relationships of "userCreditUsage" */
	["userCreditUsage"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsed?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userCreditUsageSummary" */
	["userCreditUsageSummary"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsage?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		yearMonthDayReference?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userCreditUsageSummary" */
	["userCreditUsageSummary_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userCreditUsageSummary_aggregate_fields"],
		nodes?: ResolverInputTypes["userCreditUsageSummary"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userCreditUsageSummary" */
	["userCreditUsageSummary_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["userCreditUsageSummary_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["userCreditUsageSummary_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userCreditUsageSummary_max_fields"],
		min?: ResolverInputTypes["userCreditUsageSummary_min_fields"],
		stddev?: ResolverInputTypes["userCreditUsageSummary_stddev_fields"],
		stddev_pop?: ResolverInputTypes["userCreditUsageSummary_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["userCreditUsageSummary_stddev_samp_fields"],
		sum?: ResolverInputTypes["userCreditUsageSummary_sum_fields"],
		var_pop?: ResolverInputTypes["userCreditUsageSummary_var_pop_fields"],
		var_samp?: ResolverInputTypes["userCreditUsageSummary_var_samp_fields"],
		variance?: ResolverInputTypes["userCreditUsageSummary_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["userCreditUsageSummary_avg_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userCreditUsageSummary". All fields are combined with a logical 'AND'. */
	["userCreditUsageSummary_bool_exp"]: {
		_and?: Array<ResolverInputTypes["userCreditUsageSummary_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userCreditUsageSummary_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userCreditUsageSummary_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		freeCreditUsage?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		originalVaue?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		yearMonthDayReference?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userCreditUsageSummary" */
	["userCreditUsageSummary_constraint"]: userCreditUsageSummary_constraint;
	/** input type for incrementing numeric columns in table "userCreditUsageSummary" */
	["userCreditUsageSummary_inc_input"]: {
		freeCreditUsage?: ResolverInputTypes["numeric"] | undefined | null,
		originalVaue?: ResolverInputTypes["numeric"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "userCreditUsageSummary" */
	["userCreditUsageSummary_insert_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		freeCreditUsage?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		originalVaue?: ResolverInputTypes["numeric"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		yearMonthDayReference?: string | undefined | null
	};
	/** aggregate max on columns */
	["userCreditUsageSummary_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsage?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		yearMonthDayReference?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userCreditUsageSummary_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsage?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		yearMonthDayReference?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userCreditUsageSummary" */
	["userCreditUsageSummary_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userCreditUsageSummary"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userCreditUsageSummary" */
	["userCreditUsageSummary_on_conflict"]: {
		constraint: ResolverInputTypes["userCreditUsageSummary_constraint"],
		update_columns: Array<ResolverInputTypes["userCreditUsageSummary_update_column"]>,
		where?: ResolverInputTypes["userCreditUsageSummary_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userCreditUsageSummary". */
	["userCreditUsageSummary_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		freeCreditUsage?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		originalVaue?: ResolverInputTypes["order_by"] | undefined | null,
		totalCharged?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		yearMonthDayReference?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: userCreditUsageSummary */
	["userCreditUsageSummary_pk_columns_input"]: {
		created_by: ResolverInputTypes["uuid"],
		yearMonthDayReference: string
	};
	/** select columns of table "userCreditUsageSummary" */
	["userCreditUsageSummary_select_column"]: userCreditUsageSummary_select_column;
	/** input type for updating data in table "userCreditUsageSummary" */
	["userCreditUsageSummary_set_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		freeCreditUsage?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		originalVaue?: ResolverInputTypes["numeric"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		yearMonthDayReference?: string | undefined | null
	};
	/** aggregate stddev on columns */
	["userCreditUsageSummary_stddev_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["userCreditUsageSummary_stddev_pop_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["userCreditUsageSummary_stddev_samp_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "userCreditUsageSummary" */
	["userCreditUsageSummary_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userCreditUsageSummary_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userCreditUsageSummary_stream_cursor_value_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		freeCreditUsage?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		originalVaue?: ResolverInputTypes["numeric"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		yearMonthDayReference?: string | undefined | null
	};
	/** aggregate sum on columns */
	["userCreditUsageSummary_sum_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "userCreditUsageSummary" */
	["userCreditUsageSummary_update_column"]: userCreditUsageSummary_update_column;
	["userCreditUsageSummary_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["userCreditUsageSummary_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userCreditUsageSummary_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userCreditUsageSummary_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCreditUsageSummary_var_pop_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["userCreditUsageSummary_var_samp_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["userCreditUsageSummary_variance_fields"]: AliasType<{
		freeCreditUsage?: boolean | `@${string}`,
		originalVaue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userCreditUsage" */
	["userCreditUsage_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userCreditUsage_aggregate_fields"],
		nodes?: ResolverInputTypes["userCreditUsage"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userCreditUsage" */
	["userCreditUsage_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["userCreditUsage_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["userCreditUsage_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userCreditUsage_max_fields"],
		min?: ResolverInputTypes["userCreditUsage_min_fields"],
		stddev?: ResolverInputTypes["userCreditUsage_stddev_fields"],
		stddev_pop?: ResolverInputTypes["userCreditUsage_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["userCreditUsage_stddev_samp_fields"],
		sum?: ResolverInputTypes["userCreditUsage_sum_fields"],
		var_pop?: ResolverInputTypes["userCreditUsage_var_pop_fields"],
		var_samp?: ResolverInputTypes["userCreditUsage_var_samp_fields"],
		variance?: ResolverInputTypes["userCreditUsage_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["userCreditUsage_avg_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userCreditUsage". All fields are combined with a logical 'AND'. */
	["userCreditUsage_bool_exp"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		_and?: Array<ResolverInputTypes["userCreditUsage_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userCreditUsage_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userCreditUsage_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		freeCreditUsed?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		originalValue?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userCreditUsage" */
	["userCreditUsage_constraint"]: userCreditUsage_constraint;
	/** input type for incrementing numeric columns in table "userCreditUsage" */
	["userCreditUsage_inc_input"]: {
		freeCreditUsed?: ResolverInputTypes["numeric"] | undefined | null,
		originalValue?: ResolverInputTypes["numeric"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "userCreditUsage" */
	["userCreditUsage_insert_input"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		freeCreditUsed?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		originalValue?: ResolverInputTypes["numeric"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** aggregate max on columns */
	["userCreditUsage_max_fields"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsed?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userCreditUsage_min_fields"]: AliasType<{
		GeneratedPromptByTemplateId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		freeCreditUsed?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userCreditUsage" */
	["userCreditUsage_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userCreditUsage"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userCreditUsage" */
	["userCreditUsage_on_conflict"]: {
		constraint: ResolverInputTypes["userCreditUsage_constraint"],
		update_columns: Array<ResolverInputTypes["userCreditUsage_update_column"]>,
		where?: ResolverInputTypes["userCreditUsage_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userCreditUsage". */
	["userCreditUsage_order_by"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		freeCreditUsed?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		originalValue?: ResolverInputTypes["order_by"] | undefined | null,
		totalCharged?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: userCreditUsage */
	["userCreditUsage_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "userCreditUsage" */
	["userCreditUsage_select_column"]: userCreditUsage_select_column;
	/** input type for updating data in table "userCreditUsage" */
	["userCreditUsage_set_input"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		freeCreditUsed?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		originalValue?: ResolverInputTypes["numeric"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** aggregate stddev on columns */
	["userCreditUsage_stddev_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["userCreditUsage_stddev_pop_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["userCreditUsage_stddev_samp_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "userCreditUsage" */
	["userCreditUsage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userCreditUsage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userCreditUsage_stream_cursor_value_input"]: {
		GeneratedPromptByTemplateId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		freeCreditUsed?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		originalValue?: ResolverInputTypes["numeric"] | undefined | null,
		totalCharged?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** aggregate sum on columns */
	["userCreditUsage_sum_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "userCreditUsage" */
	["userCreditUsage_update_column"]: userCreditUsage_update_column;
	["userCreditUsage_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["userCreditUsage_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userCreditUsage_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userCreditUsage_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCreditUsage_var_pop_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["userCreditUsage_var_samp_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["userCreditUsage_variance_fields"]: AliasType<{
		freeCreditUsed?: boolean | `@${string}`,
		originalValue?: boolean | `@${string}`,
		totalCharged?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userCredits" */
	["userCredits"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		quantity?: boolean | `@${string}`,
		reason?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userCredits" */
	["userCredits_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userCredits_aggregate_fields"],
		nodes?: ResolverInputTypes["userCredits"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userCredits" */
	["userCredits_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["userCredits_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["userCredits_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userCredits_max_fields"],
		min?: ResolverInputTypes["userCredits_min_fields"],
		stddev?: ResolverInputTypes["userCredits_stddev_fields"],
		stddev_pop?: ResolverInputTypes["userCredits_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["userCredits_stddev_samp_fields"],
		sum?: ResolverInputTypes["userCredits_sum_fields"],
		var_pop?: ResolverInputTypes["userCredits_var_pop_fields"],
		var_samp?: ResolverInputTypes["userCredits_var_samp_fields"],
		variance?: ResolverInputTypes["userCredits_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["userCredits_avg_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userCredits". All fields are combined with a logical 'AND'. */
	["userCredits_bool_exp"]: {
		_and?: Array<ResolverInputTypes["userCredits_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userCredits_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userCredits_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		quantity?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		reason?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		userId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userCredits" */
	["userCredits_constraint"]: userCredits_constraint;
	/** input type for incrementing numeric columns in table "userCredits" */
	["userCredits_inc_input"]: {
		quantity?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "userCredits" */
	["userCredits_insert_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		quantity?: ResolverInputTypes["numeric"] | undefined | null,
		reason?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["userCredits_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		quantity?: boolean | `@${string}`,
		reason?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userCredits_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		quantity?: boolean | `@${string}`,
		reason?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userCredits" */
	["userCredits_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userCredits"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userCredits" */
	["userCredits_on_conflict"]: {
		constraint: ResolverInputTypes["userCredits_constraint"],
		update_columns: Array<ResolverInputTypes["userCredits_update_column"]>,
		where?: ResolverInputTypes["userCredits_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userCredits". */
	["userCredits_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		quantity?: ResolverInputTypes["order_by"] | undefined | null,
		reason?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: userCredits */
	["userCredits_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "userCredits" */
	["userCredits_select_column"]: userCredits_select_column;
	/** input type for updating data in table "userCredits" */
	["userCredits_set_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		quantity?: ResolverInputTypes["numeric"] | undefined | null,
		reason?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate stddev on columns */
	["userCredits_stddev_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["userCredits_stddev_pop_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["userCredits_stddev_samp_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "userCredits" */
	["userCredits_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userCredits_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userCredits_stream_cursor_value_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		quantity?: ResolverInputTypes["numeric"] | undefined | null,
		reason?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate sum on columns */
	["userCredits_sum_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "userCredits" */
	["userCredits_update_column"]: userCredits_update_column;
	["userCredits_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["userCredits_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userCredits_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userCredits_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCredits_var_pop_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["userCredits_var_samp_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["userCredits_variance_fields"]: AliasType<{
		quantity?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userFeedback" */
	["userFeedback"]: AliasType<{
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		feedbackType?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		message?: boolean | `@${string}`,
		replied_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userFeedback" */
	["userFeedback_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userFeedback_aggregate_fields"],
		nodes?: ResolverInputTypes["userFeedback"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userFeedback" */
	["userFeedback_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["userFeedback_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userFeedback_max_fields"],
		min?: ResolverInputTypes["userFeedback_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userFeedback". All fields are combined with a logical 'AND'. */
	["userFeedback_bool_exp"]: {
		_and?: Array<ResolverInputTypes["userFeedback_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userFeedback_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userFeedback_bool_exp"]> | undefined | null,
		createdBy?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		feedbackType?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		message?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		replied_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userFeedback" */
	["userFeedback_constraint"]: userFeedback_constraint;
	/** input type for inserting data into table "userFeedback" */
	["userFeedback_insert_input"]: {
		createdBy?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		feedbackType?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		message?: string | undefined | null,
		replied_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["userFeedback_max_fields"]: AliasType<{
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		feedbackType?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		message?: boolean | `@${string}`,
		replied_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userFeedback_min_fields"]: AliasType<{
		createdBy?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		feedbackType?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		message?: boolean | `@${string}`,
		replied_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userFeedback" */
	["userFeedback_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userFeedback"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userFeedback" */
	["userFeedback_on_conflict"]: {
		constraint: ResolverInputTypes["userFeedback_constraint"],
		update_columns: Array<ResolverInputTypes["userFeedback_update_column"]>,
		where?: ResolverInputTypes["userFeedback_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userFeedback". */
	["userFeedback_order_by"]: {
		createdBy?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		feedbackType?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		message?: ResolverInputTypes["order_by"] | undefined | null,
		replied_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: userFeedback */
	["userFeedback_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "userFeedback" */
	["userFeedback_select_column"]: userFeedback_select_column;
	/** input type for updating data in table "userFeedback" */
	["userFeedback_set_input"]: {
		createdBy?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		feedbackType?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		message?: string | undefined | null,
		replied_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "userFeedback" */
	["userFeedback_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userFeedback_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userFeedback_stream_cursor_value_input"]: {
		createdBy?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		feedbackType?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		message?: string | undefined | null,
		replied_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "userFeedback" */
	["userFeedback_update_column"]: userFeedback_update_column;
	["userFeedback_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userFeedback_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userFeedback_bool_exp"]
	};
	/** columns and relationships of "userMedia" */
	["userMedia"]: AliasType<{
		aiParameters?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		created_at?: boolean | `@${string}`,
		deleted?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mediaType?: boolean | `@${string}`,
		ownerId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		videoFileId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userMedia" */
	["userMedia_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userMedia_aggregate_fields"],
		nodes?: ResolverInputTypes["userMedia"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userMedia" */
	["userMedia_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["userMedia_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userMedia_max_fields"],
		min?: ResolverInputTypes["userMedia_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["userMedia_append_input"]: {
		aiParameters?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "userMedia". All fields are combined with a logical 'AND'. */
	["userMedia_bool_exp"]: {
		_and?: Array<ResolverInputTypes["userMedia_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userMedia_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userMedia_bool_exp"]> | undefined | null,
		aiParameters?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		deleted?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		fileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		mediaType?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		ownerId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		videoFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userMedia" */
	["userMedia_constraint"]: userMedia_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["userMedia_delete_at_path_input"]: {
		aiParameters?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["userMedia_delete_elem_input"]: {
		aiParameters?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["userMedia_delete_key_input"]: {
		aiParameters?: string | undefined | null
	};
	/** input type for inserting data into table "userMedia" */
	["userMedia_insert_input"]: {
		aiParameters?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		deleted?: boolean | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		mediaType?: string | undefined | null,
		ownerId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		videoFileId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["userMedia_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mediaType?: boolean | `@${string}`,
		ownerId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		videoFileId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userMedia_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		mediaType?: boolean | `@${string}`,
		ownerId?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		videoFileId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userMedia" */
	["userMedia_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userMedia"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userMedia" */
	["userMedia_on_conflict"]: {
		constraint: ResolverInputTypes["userMedia_constraint"],
		update_columns: Array<ResolverInputTypes["userMedia_update_column"]>,
		where?: ResolverInputTypes["userMedia_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userMedia". */
	["userMedia_order_by"]: {
		aiParameters?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		deleted?: ResolverInputTypes["order_by"] | undefined | null,
		fileId?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		mediaType?: ResolverInputTypes["order_by"] | undefined | null,
		ownerId?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		videoFileId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: userMedia */
	["userMedia_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["userMedia_prepend_input"]: {
		aiParameters?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "userMedia" */
	["userMedia_select_column"]: userMedia_select_column;
	/** input type for updating data in table "userMedia" */
	["userMedia_set_input"]: {
		aiParameters?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		deleted?: boolean | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		mediaType?: string | undefined | null,
		ownerId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		videoFileId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "userMedia" */
	["userMedia_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userMedia_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userMedia_stream_cursor_value_input"]: {
		aiParameters?: ResolverInputTypes["jsonb"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		deleted?: boolean | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		mediaType?: string | undefined | null,
		ownerId?: ResolverInputTypes["uuid"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		videoFileId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "userMedia" */
	["userMedia_update_column"]: userMedia_update_column;
	["userMedia_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["userMedia_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["userMedia_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["userMedia_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["userMedia_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["userMedia_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userMedia_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userMedia_bool_exp"]
	};
	/** columns and relationships of "userMetadata" */
	["userMetadata"]: AliasType<{
		created_at?: boolean | `@${string}`,
		creditBalance?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastCreditRefill?: boolean | `@${string}`,
		last_seen?: boolean | `@${string}`,
		onboardCompleted?: boolean | `@${string}`,
		/** An object relationship */
		pricingPlan?: ResolverInputTypes["pricingPlan"],
		stripeCustomerId?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		stripeSubscriptionId?: boolean | `@${string}`,
		stripeSubscriptionItem?: boolean | `@${string}`,
		subscriptionType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		/** An object relationship */
		user?: ResolverInputTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userMetadata" */
	["userMetadata_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userMetadata_aggregate_fields"],
		nodes?: ResolverInputTypes["userMetadata"],
		__typename?: boolean | `@${string}`
	}>;
	["userMetadata_aggregate_bool_exp"]: {
		bool_and?: ResolverInputTypes["userMetadata_aggregate_bool_exp_bool_and"] | undefined | null,
		bool_or?: ResolverInputTypes["userMetadata_aggregate_bool_exp_bool_or"] | undefined | null,
		count?: ResolverInputTypes["userMetadata_aggregate_bool_exp_count"] | undefined | null
	};
	["userMetadata_aggregate_bool_exp_bool_and"]: {
		arguments: ResolverInputTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["userMetadata_aggregate_bool_exp_bool_or"]: {
		arguments: ResolverInputTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["userMetadata_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["userMetadata_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "userMetadata" */
	["userMetadata_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["userMetadata_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["userMetadata_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userMetadata_max_fields"],
		min?: ResolverInputTypes["userMetadata_min_fields"],
		stddev?: ResolverInputTypes["userMetadata_stddev_fields"],
		stddev_pop?: ResolverInputTypes["userMetadata_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["userMetadata_stddev_samp_fields"],
		sum?: ResolverInputTypes["userMetadata_sum_fields"],
		var_pop?: ResolverInputTypes["userMetadata_var_pop_fields"],
		var_samp?: ResolverInputTypes["userMetadata_var_samp_fields"],
		variance?: ResolverInputTypes["userMetadata_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "userMetadata" */
	["userMetadata_aggregate_order_by"]: {
		avg?: ResolverInputTypes["userMetadata_avg_order_by"] | undefined | null,
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["userMetadata_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["userMetadata_min_order_by"] | undefined | null,
		stddev?: ResolverInputTypes["userMetadata_stddev_order_by"] | undefined | null,
		stddev_pop?: ResolverInputTypes["userMetadata_stddev_pop_order_by"] | undefined | null,
		stddev_samp?: ResolverInputTypes["userMetadata_stddev_samp_order_by"] | undefined | null,
		sum?: ResolverInputTypes["userMetadata_sum_order_by"] | undefined | null,
		var_pop?: ResolverInputTypes["userMetadata_var_pop_order_by"] | undefined | null,
		var_samp?: ResolverInputTypes["userMetadata_var_samp_order_by"] | undefined | null,
		variance?: ResolverInputTypes["userMetadata_variance_order_by"] | undefined | null
	};
	/** input type for inserting array relation for remote table "userMetadata" */
	["userMetadata_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["userMetadata_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["userMetadata_on_conflict"] | undefined | null
	};
	/** aggregate avg on columns */
	["userMetadata_avg_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by avg() on columns of table "userMetadata" */
	["userMetadata_avg_order_by"]: {
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "userMetadata". All fields are combined with a logical 'AND'. */
	["userMetadata_bool_exp"]: {
		_and?: Array<ResolverInputTypes["userMetadata_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userMetadata_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		creditBalance?: ResolverInputTypes["numeric_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		lastCreditRefill?: ResolverInputTypes["date_comparison_exp"] | undefined | null,
		last_seen?: ResolverInputTypes["timestamp_comparison_exp"] | undefined | null,
		onboardCompleted?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		pricingPlan?: ResolverInputTypes["pricingPlan_bool_exp"] | undefined | null,
		stripeCustomerId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		stripePriceId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		stripeProductId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		stripeSubscriptionId?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		stripeSubscriptionItem?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		subscriptionType?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		user?: ResolverInputTypes["users_bool_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userMetadata" */
	["userMetadata_constraint"]: userMetadata_constraint;
	/** input type for incrementing numeric columns in table "userMetadata" */
	["userMetadata_inc_input"]: {
		creditBalance?: ResolverInputTypes["numeric"] | undefined | null
	};
	/** input type for inserting data into table "userMetadata" */
	["userMetadata_insert_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditBalance?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		lastCreditRefill?: ResolverInputTypes["date"] | undefined | null,
		last_seen?: ResolverInputTypes["timestamp"] | undefined | null,
		onboardCompleted?: boolean | undefined | null,
		pricingPlan?: ResolverInputTypes["pricingPlan_obj_rel_insert_input"] | undefined | null,
		stripeCustomerId?: string | undefined | null,
		stripePriceId?: string | undefined | null,
		stripeProductId?: string | undefined | null,
		stripeSubscriptionId?: string | undefined | null,
		stripeSubscriptionItem?: string | undefined | null,
		subscriptionType?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		user?: ResolverInputTypes["users_obj_rel_insert_input"] | undefined | null
	};
	/** aggregate max on columns */
	["userMetadata_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		creditBalance?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastCreditRefill?: boolean | `@${string}`,
		last_seen?: boolean | `@${string}`,
		stripeCustomerId?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		stripeSubscriptionId?: boolean | `@${string}`,
		stripeSubscriptionItem?: boolean | `@${string}`,
		subscriptionType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "userMetadata" */
	["userMetadata_max_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		lastCreditRefill?: ResolverInputTypes["order_by"] | undefined | null,
		last_seen?: ResolverInputTypes["order_by"] | undefined | null,
		stripeCustomerId?: ResolverInputTypes["order_by"] | undefined | null,
		stripePriceId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeProductId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeSubscriptionId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeSubscriptionItem?: ResolverInputTypes["order_by"] | undefined | null,
		subscriptionType?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["userMetadata_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		creditBalance?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastCreditRefill?: boolean | `@${string}`,
		last_seen?: boolean | `@${string}`,
		stripeCustomerId?: boolean | `@${string}`,
		stripePriceId?: boolean | `@${string}`,
		stripeProductId?: boolean | `@${string}`,
		stripeSubscriptionId?: boolean | `@${string}`,
		stripeSubscriptionItem?: boolean | `@${string}`,
		subscriptionType?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "userMetadata" */
	["userMetadata_min_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		lastCreditRefill?: ResolverInputTypes["order_by"] | undefined | null,
		last_seen?: ResolverInputTypes["order_by"] | undefined | null,
		stripeCustomerId?: ResolverInputTypes["order_by"] | undefined | null,
		stripePriceId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeProductId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeSubscriptionId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeSubscriptionItem?: ResolverInputTypes["order_by"] | undefined | null,
		subscriptionType?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "userMetadata" */
	["userMetadata_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userMetadata"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userMetadata" */
	["userMetadata_on_conflict"]: {
		constraint: ResolverInputTypes["userMetadata_constraint"],
		update_columns: Array<ResolverInputTypes["userMetadata_update_column"]>,
		where?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userMetadata". */
	["userMetadata_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		lastCreditRefill?: ResolverInputTypes["order_by"] | undefined | null,
		last_seen?: ResolverInputTypes["order_by"] | undefined | null,
		onboardCompleted?: ResolverInputTypes["order_by"] | undefined | null,
		pricingPlan?: ResolverInputTypes["pricingPlan_order_by"] | undefined | null,
		stripeCustomerId?: ResolverInputTypes["order_by"] | undefined | null,
		stripePriceId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeProductId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeSubscriptionId?: ResolverInputTypes["order_by"] | undefined | null,
		stripeSubscriptionItem?: ResolverInputTypes["order_by"] | undefined | null,
		subscriptionType?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null,
		user?: ResolverInputTypes["users_order_by"] | undefined | null
	};
	/** primary key columns input for table: userMetadata */
	["userMetadata_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "userMetadata" */
	["userMetadata_select_column"]: userMetadata_select_column;
	/** select "userMetadata_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userMetadata" */
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"]: userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "userMetadata_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userMetadata" */
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"]: userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "userMetadata" */
	["userMetadata_set_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditBalance?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		lastCreditRefill?: ResolverInputTypes["date"] | undefined | null,
		last_seen?: ResolverInputTypes["timestamp"] | undefined | null,
		onboardCompleted?: boolean | undefined | null,
		stripeCustomerId?: string | undefined | null,
		stripePriceId?: string | undefined | null,
		stripeProductId?: string | undefined | null,
		stripeSubscriptionId?: string | undefined | null,
		stripeSubscriptionItem?: string | undefined | null,
		subscriptionType?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate stddev on columns */
	["userMetadata_stddev_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev() on columns of table "userMetadata" */
	["userMetadata_stddev_order_by"]: {
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_pop on columns */
	["userMetadata_stddev_pop_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_pop() on columns of table "userMetadata" */
	["userMetadata_stddev_pop_order_by"]: {
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate stddev_samp on columns */
	["userMetadata_stddev_samp_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by stddev_samp() on columns of table "userMetadata" */
	["userMetadata_stddev_samp_order_by"]: {
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** Streaming cursor of the table "userMetadata" */
	["userMetadata_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userMetadata_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userMetadata_stream_cursor_value_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		creditBalance?: ResolverInputTypes["numeric"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		lastCreditRefill?: ResolverInputTypes["date"] | undefined | null,
		last_seen?: ResolverInputTypes["timestamp"] | undefined | null,
		onboardCompleted?: boolean | undefined | null,
		stripeCustomerId?: string | undefined | null,
		stripePriceId?: string | undefined | null,
		stripeProductId?: string | undefined | null,
		stripeSubscriptionId?: string | undefined | null,
		stripeSubscriptionItem?: string | undefined | null,
		subscriptionType?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate sum on columns */
	["userMetadata_sum_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by sum() on columns of table "userMetadata" */
	["userMetadata_sum_order_by"]: {
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** update columns of table "userMetadata" */
	["userMetadata_update_column"]: userMetadata_update_column;
	["userMetadata_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["userMetadata_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userMetadata_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userMetadata_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userMetadata_var_pop_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_pop() on columns of table "userMetadata" */
	["userMetadata_var_pop_order_by"]: {
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate var_samp on columns */
	["userMetadata_var_samp_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by var_samp() on columns of table "userMetadata" */
	["userMetadata_var_samp_order_by"]: {
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate variance on columns */
	["userMetadata_variance_fields"]: AliasType<{
		creditBalance?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by variance() on columns of table "userMetadata" */
	["userMetadata_variance_order_by"]: {
		creditBalance?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** columns and relationships of "userOnboarding" */
	["userOnboarding"]: AliasType<{
		behaviorProfile?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		clientPreference?: boolean | `@${string}`,
		companySegment?: boolean | `@${string}`,
		currentStep?: boolean | `@${string}`,
		done?: boolean | `@${string}`,
		expectation?: boolean | `@${string}`,
		importantSocialNetworkForBusiness?: boolean | `@${string}`,
		investInPaidChannels?: boolean | `@${string}`,
		marketingObjective?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		networkingOnCopyMagico?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		purchaseBehavior?: boolean | `@${string}`,
		purchaseChallenges?: boolean | `@${string}`,
		purchaseInfluencers?: boolean | `@${string}`,
		sellOnCopyMagico?: boolean | `@${string}`,
		siteUrl?: boolean | `@${string}`,
		socioeconomicProfile?: boolean | `@${string}`,
		staffCount?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userOnboarding" */
	["userOnboarding_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userOnboarding_aggregate_fields"],
		nodes?: ResolverInputTypes["userOnboarding"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userOnboarding" */
	["userOnboarding_aggregate_fields"]: AliasType<{
		avg?: ResolverInputTypes["userOnboarding_avg_fields"],
		count?: [{ columns?: Array<ResolverInputTypes["userOnboarding_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userOnboarding_max_fields"],
		min?: ResolverInputTypes["userOnboarding_min_fields"],
		stddev?: ResolverInputTypes["userOnboarding_stddev_fields"],
		stddev_pop?: ResolverInputTypes["userOnboarding_stddev_pop_fields"],
		stddev_samp?: ResolverInputTypes["userOnboarding_stddev_samp_fields"],
		sum?: ResolverInputTypes["userOnboarding_sum_fields"],
		var_pop?: ResolverInputTypes["userOnboarding_var_pop_fields"],
		var_samp?: ResolverInputTypes["userOnboarding_var_samp_fields"],
		variance?: ResolverInputTypes["userOnboarding_variance_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate avg on columns */
	["userOnboarding_avg_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userOnboarding". All fields are combined with a logical 'AND'. */
	["userOnboarding_bool_exp"]: {
		_and?: Array<ResolverInputTypes["userOnboarding_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userOnboarding_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userOnboarding_bool_exp"]> | undefined | null,
		behaviorProfile?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		brandName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		clientPreference?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		companySegment?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		currentStep?: ResolverInputTypes["Int_comparison_exp"] | undefined | null,
		done?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		expectation?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		importantSocialNetworkForBusiness?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		investInPaidChannels?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		marketingObjective?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		name?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		networkingOnCopyMagico?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		phone?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		productOrService?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		purchaseBehavior?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		purchaseChallenges?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		purchaseInfluencers?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		sellOnCopyMagico?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		siteUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		socioeconomicProfile?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		staffCount?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		userId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userOnboarding" */
	["userOnboarding_constraint"]: userOnboarding_constraint;
	/** input type for incrementing numeric columns in table "userOnboarding" */
	["userOnboarding_inc_input"]: {
		currentStep?: number | undefined | null
	};
	/** input type for inserting data into table "userOnboarding" */
	["userOnboarding_insert_input"]: {
		behaviorProfile?: string | undefined | null,
		brandName?: string | undefined | null,
		clientPreference?: string | undefined | null,
		companySegment?: string | undefined | null,
		currentStep?: number | undefined | null,
		done?: boolean | undefined | null,
		expectation?: string | undefined | null,
		importantSocialNetworkForBusiness?: string | undefined | null,
		investInPaidChannels?: string | undefined | null,
		marketingObjective?: string | undefined | null,
		name?: string | undefined | null,
		networkingOnCopyMagico?: string | undefined | null,
		phone?: string | undefined | null,
		productOrService?: string | undefined | null,
		purchaseBehavior?: string | undefined | null,
		purchaseChallenges?: string | undefined | null,
		purchaseInfluencers?: string | undefined | null,
		sellOnCopyMagico?: string | undefined | null,
		siteUrl?: string | undefined | null,
		socioeconomicProfile?: string | undefined | null,
		staffCount?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["userOnboarding_max_fields"]: AliasType<{
		behaviorProfile?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		clientPreference?: boolean | `@${string}`,
		companySegment?: boolean | `@${string}`,
		currentStep?: boolean | `@${string}`,
		expectation?: boolean | `@${string}`,
		importantSocialNetworkForBusiness?: boolean | `@${string}`,
		investInPaidChannels?: boolean | `@${string}`,
		marketingObjective?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		networkingOnCopyMagico?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		purchaseBehavior?: boolean | `@${string}`,
		purchaseChallenges?: boolean | `@${string}`,
		purchaseInfluencers?: boolean | `@${string}`,
		sellOnCopyMagico?: boolean | `@${string}`,
		siteUrl?: boolean | `@${string}`,
		socioeconomicProfile?: boolean | `@${string}`,
		staffCount?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userOnboarding_min_fields"]: AliasType<{
		behaviorProfile?: boolean | `@${string}`,
		brandName?: boolean | `@${string}`,
		clientPreference?: boolean | `@${string}`,
		companySegment?: boolean | `@${string}`,
		currentStep?: boolean | `@${string}`,
		expectation?: boolean | `@${string}`,
		importantSocialNetworkForBusiness?: boolean | `@${string}`,
		investInPaidChannels?: boolean | `@${string}`,
		marketingObjective?: boolean | `@${string}`,
		name?: boolean | `@${string}`,
		networkingOnCopyMagico?: boolean | `@${string}`,
		phone?: boolean | `@${string}`,
		productOrService?: boolean | `@${string}`,
		purchaseBehavior?: boolean | `@${string}`,
		purchaseChallenges?: boolean | `@${string}`,
		purchaseInfluencers?: boolean | `@${string}`,
		sellOnCopyMagico?: boolean | `@${string}`,
		siteUrl?: boolean | `@${string}`,
		socioeconomicProfile?: boolean | `@${string}`,
		staffCount?: boolean | `@${string}`,
		userId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userOnboarding" */
	["userOnboarding_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userOnboarding"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userOnboarding" */
	["userOnboarding_on_conflict"]: {
		constraint: ResolverInputTypes["userOnboarding_constraint"],
		update_columns: Array<ResolverInputTypes["userOnboarding_update_column"]>,
		where?: ResolverInputTypes["userOnboarding_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userOnboarding". */
	["userOnboarding_order_by"]: {
		behaviorProfile?: ResolverInputTypes["order_by"] | undefined | null,
		brandName?: ResolverInputTypes["order_by"] | undefined | null,
		clientPreference?: ResolverInputTypes["order_by"] | undefined | null,
		companySegment?: ResolverInputTypes["order_by"] | undefined | null,
		currentStep?: ResolverInputTypes["order_by"] | undefined | null,
		done?: ResolverInputTypes["order_by"] | undefined | null,
		expectation?: ResolverInputTypes["order_by"] | undefined | null,
		importantSocialNetworkForBusiness?: ResolverInputTypes["order_by"] | undefined | null,
		investInPaidChannels?: ResolverInputTypes["order_by"] | undefined | null,
		marketingObjective?: ResolverInputTypes["order_by"] | undefined | null,
		name?: ResolverInputTypes["order_by"] | undefined | null,
		networkingOnCopyMagico?: ResolverInputTypes["order_by"] | undefined | null,
		phone?: ResolverInputTypes["order_by"] | undefined | null,
		productOrService?: ResolverInputTypes["order_by"] | undefined | null,
		purchaseBehavior?: ResolverInputTypes["order_by"] | undefined | null,
		purchaseChallenges?: ResolverInputTypes["order_by"] | undefined | null,
		purchaseInfluencers?: ResolverInputTypes["order_by"] | undefined | null,
		sellOnCopyMagico?: ResolverInputTypes["order_by"] | undefined | null,
		siteUrl?: ResolverInputTypes["order_by"] | undefined | null,
		socioeconomicProfile?: ResolverInputTypes["order_by"] | undefined | null,
		staffCount?: ResolverInputTypes["order_by"] | undefined | null,
		userId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: userOnboarding */
	["userOnboarding_pk_columns_input"]: {
		userId: ResolverInputTypes["uuid"]
	};
	/** select columns of table "userOnboarding" */
	["userOnboarding_select_column"]: userOnboarding_select_column;
	/** input type for updating data in table "userOnboarding" */
	["userOnboarding_set_input"]: {
		behaviorProfile?: string | undefined | null,
		brandName?: string | undefined | null,
		clientPreference?: string | undefined | null,
		companySegment?: string | undefined | null,
		currentStep?: number | undefined | null,
		done?: boolean | undefined | null,
		expectation?: string | undefined | null,
		importantSocialNetworkForBusiness?: string | undefined | null,
		investInPaidChannels?: string | undefined | null,
		marketingObjective?: string | undefined | null,
		name?: string | undefined | null,
		networkingOnCopyMagico?: string | undefined | null,
		phone?: string | undefined | null,
		productOrService?: string | undefined | null,
		purchaseBehavior?: string | undefined | null,
		purchaseChallenges?: string | undefined | null,
		purchaseInfluencers?: string | undefined | null,
		sellOnCopyMagico?: string | undefined | null,
		siteUrl?: string | undefined | null,
		socioeconomicProfile?: string | undefined | null,
		staffCount?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate stddev on columns */
	["userOnboarding_stddev_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_pop on columns */
	["userOnboarding_stddev_pop_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate stddev_samp on columns */
	["userOnboarding_stddev_samp_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** Streaming cursor of the table "userOnboarding" */
	["userOnboarding_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userOnboarding_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userOnboarding_stream_cursor_value_input"]: {
		behaviorProfile?: string | undefined | null,
		brandName?: string | undefined | null,
		clientPreference?: string | undefined | null,
		companySegment?: string | undefined | null,
		currentStep?: number | undefined | null,
		done?: boolean | undefined | null,
		expectation?: string | undefined | null,
		importantSocialNetworkForBusiness?: string | undefined | null,
		investInPaidChannels?: string | undefined | null,
		marketingObjective?: string | undefined | null,
		name?: string | undefined | null,
		networkingOnCopyMagico?: string | undefined | null,
		phone?: string | undefined | null,
		productOrService?: string | undefined | null,
		purchaseBehavior?: string | undefined | null,
		purchaseChallenges?: string | undefined | null,
		purchaseInfluencers?: string | undefined | null,
		sellOnCopyMagico?: string | undefined | null,
		siteUrl?: string | undefined | null,
		socioeconomicProfile?: string | undefined | null,
		staffCount?: string | undefined | null,
		userId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate sum on columns */
	["userOnboarding_sum_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** update columns of table "userOnboarding" */
	["userOnboarding_update_column"]: userOnboarding_update_column;
	["userOnboarding_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ResolverInputTypes["userOnboarding_inc_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userOnboarding_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userOnboarding_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userOnboarding_var_pop_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate var_samp on columns */
	["userOnboarding_var_samp_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate variance on columns */
	["userOnboarding_variance_fields"]: AliasType<{
		currentStep?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** columns and relationships of "userTemplateUsage" */
	["userTemplateUsage"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "userTemplateUsage" */
	["userTemplateUsage_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["userTemplateUsage_aggregate_fields"],
		nodes?: ResolverInputTypes["userTemplateUsage"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "userTemplateUsage" */
	["userTemplateUsage_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["userTemplateUsage_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["userTemplateUsage_max_fields"],
		min?: ResolverInputTypes["userTemplateUsage_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "userTemplateUsage". All fields are combined with a logical 'AND'. */
	["userTemplateUsage_bool_exp"]: {
		_and?: Array<ResolverInputTypes["userTemplateUsage_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["userTemplateUsage_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["userTemplateUsage_bool_exp"]> | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		created_by?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "userTemplateUsage" */
	["userTemplateUsage_constraint"]: userTemplateUsage_constraint;
	/** input type for inserting data into table "userTemplateUsage" */
	["userTemplateUsage_insert_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** aggregate max on columns */
	["userTemplateUsage_max_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["userTemplateUsage_min_fields"]: AliasType<{
		created_at?: boolean | `@${string}`,
		created_by?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		textPromptTemplateId?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "userTemplateUsage" */
	["userTemplateUsage_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["userTemplateUsage"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "userTemplateUsage" */
	["userTemplateUsage_on_conflict"]: {
		constraint: ResolverInputTypes["userTemplateUsage_constraint"],
		update_columns: Array<ResolverInputTypes["userTemplateUsage_update_column"]>,
		where?: ResolverInputTypes["userTemplateUsage_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "userTemplateUsage". */
	["userTemplateUsage_order_by"]: {
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		created_by?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: userTemplateUsage */
	["userTemplateUsage_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "userTemplateUsage" */
	["userTemplateUsage_select_column"]: userTemplateUsage_select_column;
	/** input type for updating data in table "userTemplateUsage" */
	["userTemplateUsage_set_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** Streaming cursor of the table "userTemplateUsage" */
	["userTemplateUsage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["userTemplateUsage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["userTemplateUsage_stream_cursor_value_input"]: {
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		created_by?: ResolverInputTypes["uuid"] | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		textPromptTemplateId?: ResolverInputTypes["uuid"] | undefined | null
	};
	/** update columns of table "userTemplateUsage" */
	["userTemplateUsage_update_column"]: userTemplateUsage_update_column;
	["userTemplateUsage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["userTemplateUsage_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["userTemplateUsage_bool_exp"]
	};
	/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["users"]: AliasType<{
		activeMfaType?: boolean | `@${string}`,
		avatarUrl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		currentChallenge?: boolean | `@${string}`,
		defaultRole?: boolean | `@${string}`,
		/** An object relationship */
		defaultRoleByRole?: ResolverInputTypes["authRoles"],
		disabled?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		emailVerified?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		isAnonymous?: boolean | `@${string}`,
		lastSeen?: boolean | `@${string}`,
		locale?: boolean | `@${string}`,
		metadata?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		newEmail?: boolean | `@${string}`,
		otpHash?: boolean | `@${string}`,
		otpHashExpiresAt?: boolean | `@${string}`,
		otpMethodLastUsed?: boolean | `@${string}`,
		passwordHash?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		phoneNumberVerified?: boolean | `@${string}`,
		refreshTokens?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokens_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens"]],
		refreshTokens_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authRefreshTokens_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authRefreshTokens_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null
		}, ResolverInputTypes["authRefreshTokens_aggregate"]],
		roles?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles"]],
		roles_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserRoles_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserRoles_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserRoles_aggregate"]],
		securityKeys?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserSecurityKeys_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserSecurityKeys_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys"]],
		securityKeys_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserSecurityKeys_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserSecurityKeys_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserSecurityKeys_aggregate"]],
		ticket?: boolean | `@${string}`,
		ticketExpiresAt?: boolean | `@${string}`,
		totpSecret?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		userMetadata?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMetadata_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMetadata_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMetadata"]],
		userMetadata_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["userMetadata_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["userMetadata_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null
		}, ResolverInputTypes["userMetadata_aggregate"]],
		userProviders?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders"]],
		userProviders_aggregate?: [{	/** distinct select on columns */
			distinct_on?: Array<ResolverInputTypes["authUserProviders_select_column"]> | undefined | null,	/** limit the number of rows returned */
			limit?: number | undefined | null,	/** skip the first n rows. Use only with order_by */
			offset?: number | undefined | null,	/** sort the rows by one or more columns */
			order_by?: Array<ResolverInputTypes["authUserProviders_order_by"]> | undefined | null,	/** filter the rows returned */
			where?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null
		}, ResolverInputTypes["authUserProviders_aggregate"]],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "auth.users" */
	["users_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["users_aggregate_fields"],
		nodes?: ResolverInputTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	["users_aggregate_bool_exp"]: {
		bool_and?: ResolverInputTypes["users_aggregate_bool_exp_bool_and"] | undefined | null,
		bool_or?: ResolverInputTypes["users_aggregate_bool_exp_bool_or"] | undefined | null,
		count?: ResolverInputTypes["users_aggregate_bool_exp_count"] | undefined | null
	};
	["users_aggregate_bool_exp_bool_and"]: {
		arguments: ResolverInputTypes["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["users_aggregate_bool_exp_bool_or"]: {
		arguments: ResolverInputTypes["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Boolean_comparison_exp"]
	};
	["users_aggregate_bool_exp_count"]: {
		arguments?: Array<ResolverInputTypes["users_select_column"]> | undefined | null,
		distinct?: boolean | undefined | null,
		filter?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		predicate: ResolverInputTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.users" */
	["users_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["users_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["users_max_fields"],
		min?: ResolverInputTypes["users_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** order by aggregate values of table "auth.users" */
	["users_aggregate_order_by"]: {
		count?: ResolverInputTypes["order_by"] | undefined | null,
		max?: ResolverInputTypes["users_max_order_by"] | undefined | null,
		min?: ResolverInputTypes["users_min_order_by"] | undefined | null
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["users_append_input"]: {
		metadata?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** input type for inserting array relation for remote table "auth.users" */
	["users_arr_rel_insert_input"]: {
		data: Array<ResolverInputTypes["users_insert_input"]>,
		/** upsert condition */
		on_conflict?: ResolverInputTypes["users_on_conflict"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
	["users_bool_exp"]: {
		_and?: Array<ResolverInputTypes["users_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["users_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["users_bool_exp"]> | undefined | null,
		activeMfaType?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		avatarUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		currentChallenge?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		defaultRole?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		defaultRoleByRole?: ResolverInputTypes["authRoles_bool_exp"] | undefined | null,
		disabled?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		displayName?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		email?: ResolverInputTypes["citext_comparison_exp"] | undefined | null,
		emailVerified?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		isAnonymous?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		lastSeen?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		locale?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		metadata?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		newEmail?: ResolverInputTypes["citext_comparison_exp"] | undefined | null,
		otpHash?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		otpHashExpiresAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		otpMethodLastUsed?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		passwordHash?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		phoneNumber?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		phoneNumberVerified?: ResolverInputTypes["Boolean_comparison_exp"] | undefined | null,
		refreshTokens?: ResolverInputTypes["authRefreshTokens_bool_exp"] | undefined | null,
		refreshTokens_aggregate?: ResolverInputTypes["authRefreshTokens_aggregate_bool_exp"] | undefined | null,
		roles?: ResolverInputTypes["authUserRoles_bool_exp"] | undefined | null,
		roles_aggregate?: ResolverInputTypes["authUserRoles_aggregate_bool_exp"] | undefined | null,
		securityKeys?: ResolverInputTypes["authUserSecurityKeys_bool_exp"] | undefined | null,
		securityKeys_aggregate?: ResolverInputTypes["authUserSecurityKeys_aggregate_bool_exp"] | undefined | null,
		ticket?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		ticketExpiresAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		totpSecret?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		userMetadata?: ResolverInputTypes["userMetadata_bool_exp"] | undefined | null,
		userMetadata_aggregate?: ResolverInputTypes["userMetadata_aggregate_bool_exp"] | undefined | null,
		userProviders?: ResolverInputTypes["authUserProviders_bool_exp"] | undefined | null,
		userProviders_aggregate?: ResolverInputTypes["authUserProviders_aggregate_bool_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "auth.users" */
	["users_constraint"]: users_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["users_delete_at_path_input"]: {
		metadata?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["users_delete_elem_input"]: {
		metadata?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["users_delete_key_input"]: {
		metadata?: string | undefined | null
	};
	/** input type for inserting data into table "auth.users" */
	["users_insert_input"]: {
		activeMfaType?: string | undefined | null,
		avatarUrl?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		currentChallenge?: string | undefined | null,
		defaultRole?: string | undefined | null,
		defaultRoleByRole?: ResolverInputTypes["authRoles_obj_rel_insert_input"] | undefined | null,
		disabled?: boolean | undefined | null,
		displayName?: string | undefined | null,
		email?: ResolverInputTypes["citext"] | undefined | null,
		emailVerified?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		isAnonymous?: boolean | undefined | null,
		lastSeen?: ResolverInputTypes["timestamptz"] | undefined | null,
		locale?: string | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		newEmail?: ResolverInputTypes["citext"] | undefined | null,
		otpHash?: string | undefined | null,
		otpHashExpiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		otpMethodLastUsed?: string | undefined | null,
		passwordHash?: string | undefined | null,
		phoneNumber?: string | undefined | null,
		phoneNumberVerified?: boolean | undefined | null,
		refreshTokens?: ResolverInputTypes["authRefreshTokens_arr_rel_insert_input"] | undefined | null,
		roles?: ResolverInputTypes["authUserRoles_arr_rel_insert_input"] | undefined | null,
		securityKeys?: ResolverInputTypes["authUserSecurityKeys_arr_rel_insert_input"] | undefined | null,
		ticket?: string | undefined | null,
		ticketExpiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		totpSecret?: string | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		userMetadata?: ResolverInputTypes["userMetadata_arr_rel_insert_input"] | undefined | null,
		userProviders?: ResolverInputTypes["authUserProviders_arr_rel_insert_input"] | undefined | null
	};
	/** aggregate max on columns */
	["users_max_fields"]: AliasType<{
		activeMfaType?: boolean | `@${string}`,
		avatarUrl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		currentChallenge?: boolean | `@${string}`,
		defaultRole?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastSeen?: boolean | `@${string}`,
		locale?: boolean | `@${string}`,
		newEmail?: boolean | `@${string}`,
		otpHash?: boolean | `@${string}`,
		otpHashExpiresAt?: boolean | `@${string}`,
		otpMethodLastUsed?: boolean | `@${string}`,
		passwordHash?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		ticket?: boolean | `@${string}`,
		ticketExpiresAt?: boolean | `@${string}`,
		totpSecret?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by max() on columns of table "auth.users" */
	["users_max_order_by"]: {
		activeMfaType?: ResolverInputTypes["order_by"] | undefined | null,
		avatarUrl?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		currentChallenge?: ResolverInputTypes["order_by"] | undefined | null,
		defaultRole?: ResolverInputTypes["order_by"] | undefined | null,
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		email?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		lastSeen?: ResolverInputTypes["order_by"] | undefined | null,
		locale?: ResolverInputTypes["order_by"] | undefined | null,
		newEmail?: ResolverInputTypes["order_by"] | undefined | null,
		otpHash?: ResolverInputTypes["order_by"] | undefined | null,
		otpHashExpiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		otpMethodLastUsed?: ResolverInputTypes["order_by"] | undefined | null,
		passwordHash?: ResolverInputTypes["order_by"] | undefined | null,
		phoneNumber?: ResolverInputTypes["order_by"] | undefined | null,
		ticket?: ResolverInputTypes["order_by"] | undefined | null,
		ticketExpiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		totpSecret?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** aggregate min on columns */
	["users_min_fields"]: AliasType<{
		activeMfaType?: boolean | `@${string}`,
		avatarUrl?: boolean | `@${string}`,
		createdAt?: boolean | `@${string}`,
		currentChallenge?: boolean | `@${string}`,
		defaultRole?: boolean | `@${string}`,
		displayName?: boolean | `@${string}`,
		email?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		lastSeen?: boolean | `@${string}`,
		locale?: boolean | `@${string}`,
		newEmail?: boolean | `@${string}`,
		otpHash?: boolean | `@${string}`,
		otpHashExpiresAt?: boolean | `@${string}`,
		otpMethodLastUsed?: boolean | `@${string}`,
		passwordHash?: boolean | `@${string}`,
		phoneNumber?: boolean | `@${string}`,
		ticket?: boolean | `@${string}`,
		ticketExpiresAt?: boolean | `@${string}`,
		totpSecret?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** order by min() on columns of table "auth.users" */
	["users_min_order_by"]: {
		activeMfaType?: ResolverInputTypes["order_by"] | undefined | null,
		avatarUrl?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		currentChallenge?: ResolverInputTypes["order_by"] | undefined | null,
		defaultRole?: ResolverInputTypes["order_by"] | undefined | null,
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		email?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		lastSeen?: ResolverInputTypes["order_by"] | undefined | null,
		locale?: ResolverInputTypes["order_by"] | undefined | null,
		newEmail?: ResolverInputTypes["order_by"] | undefined | null,
		otpHash?: ResolverInputTypes["order_by"] | undefined | null,
		otpHashExpiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		otpMethodLastUsed?: ResolverInputTypes["order_by"] | undefined | null,
		passwordHash?: ResolverInputTypes["order_by"] | undefined | null,
		phoneNumber?: ResolverInputTypes["order_by"] | undefined | null,
		ticket?: ResolverInputTypes["order_by"] | undefined | null,
		ticketExpiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		totpSecret?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** response of any mutation on the table "auth.users" */
	["users_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["users"],
		__typename?: boolean | `@${string}`
	}>;
	/** input type for inserting object relation for remote table "auth.users" */
	["users_obj_rel_insert_input"]: {
		data: ResolverInputTypes["users_insert_input"],
		/** upsert condition */
		on_conflict?: ResolverInputTypes["users_on_conflict"] | undefined | null
	};
	/** on_conflict condition type for table "auth.users" */
	["users_on_conflict"]: {
		constraint: ResolverInputTypes["users_constraint"],
		update_columns: Array<ResolverInputTypes["users_update_column"]>,
		where?: ResolverInputTypes["users_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "auth.users". */
	["users_order_by"]: {
		activeMfaType?: ResolverInputTypes["order_by"] | undefined | null,
		avatarUrl?: ResolverInputTypes["order_by"] | undefined | null,
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		currentChallenge?: ResolverInputTypes["order_by"] | undefined | null,
		defaultRole?: ResolverInputTypes["order_by"] | undefined | null,
		defaultRoleByRole?: ResolverInputTypes["authRoles_order_by"] | undefined | null,
		disabled?: ResolverInputTypes["order_by"] | undefined | null,
		displayName?: ResolverInputTypes["order_by"] | undefined | null,
		email?: ResolverInputTypes["order_by"] | undefined | null,
		emailVerified?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		isAnonymous?: ResolverInputTypes["order_by"] | undefined | null,
		lastSeen?: ResolverInputTypes["order_by"] | undefined | null,
		locale?: ResolverInputTypes["order_by"] | undefined | null,
		metadata?: ResolverInputTypes["order_by"] | undefined | null,
		newEmail?: ResolverInputTypes["order_by"] | undefined | null,
		otpHash?: ResolverInputTypes["order_by"] | undefined | null,
		otpHashExpiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		otpMethodLastUsed?: ResolverInputTypes["order_by"] | undefined | null,
		passwordHash?: ResolverInputTypes["order_by"] | undefined | null,
		phoneNumber?: ResolverInputTypes["order_by"] | undefined | null,
		phoneNumberVerified?: ResolverInputTypes["order_by"] | undefined | null,
		refreshTokens_aggregate?: ResolverInputTypes["authRefreshTokens_aggregate_order_by"] | undefined | null,
		roles_aggregate?: ResolverInputTypes["authUserRoles_aggregate_order_by"] | undefined | null,
		securityKeys_aggregate?: ResolverInputTypes["authUserSecurityKeys_aggregate_order_by"] | undefined | null,
		ticket?: ResolverInputTypes["order_by"] | undefined | null,
		ticketExpiresAt?: ResolverInputTypes["order_by"] | undefined | null,
		totpSecret?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null,
		userMetadata_aggregate?: ResolverInputTypes["userMetadata_aggregate_order_by"] | undefined | null,
		userProviders_aggregate?: ResolverInputTypes["authUserProviders_aggregate_order_by"] | undefined | null
	};
	/** primary key columns input for table: auth.users */
	["users_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["users_prepend_input"]: {
		metadata?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "auth.users" */
	["users_select_column"]: users_select_column;
	/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
	["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"]: users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
	["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"]: users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "auth.users" */
	["users_set_input"]: {
		activeMfaType?: string | undefined | null,
		avatarUrl?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		currentChallenge?: string | undefined | null,
		defaultRole?: string | undefined | null,
		disabled?: boolean | undefined | null,
		displayName?: string | undefined | null,
		email?: ResolverInputTypes["citext"] | undefined | null,
		emailVerified?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		isAnonymous?: boolean | undefined | null,
		lastSeen?: ResolverInputTypes["timestamptz"] | undefined | null,
		locale?: string | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		newEmail?: ResolverInputTypes["citext"] | undefined | null,
		otpHash?: string | undefined | null,
		otpHashExpiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		otpMethodLastUsed?: string | undefined | null,
		passwordHash?: string | undefined | null,
		phoneNumber?: string | undefined | null,
		phoneNumberVerified?: boolean | undefined | null,
		ticket?: string | undefined | null,
		ticketExpiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		totpSecret?: string | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "users" */
	["users_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["users_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["users_stream_cursor_value_input"]: {
		activeMfaType?: string | undefined | null,
		avatarUrl?: string | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		currentChallenge?: string | undefined | null,
		defaultRole?: string | undefined | null,
		disabled?: boolean | undefined | null,
		displayName?: string | undefined | null,
		email?: ResolverInputTypes["citext"] | undefined | null,
		emailVerified?: boolean | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		isAnonymous?: boolean | undefined | null,
		lastSeen?: ResolverInputTypes["timestamptz"] | undefined | null,
		locale?: string | undefined | null,
		metadata?: ResolverInputTypes["jsonb"] | undefined | null,
		newEmail?: ResolverInputTypes["citext"] | undefined | null,
		otpHash?: string | undefined | null,
		otpHashExpiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		otpMethodLastUsed?: string | undefined | null,
		passwordHash?: string | undefined | null,
		phoneNumber?: string | undefined | null,
		phoneNumberVerified?: boolean | undefined | null,
		ticket?: string | undefined | null,
		ticketExpiresAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		totpSecret?: string | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "auth.users" */
	["users_update_column"]: users_update_column;
	["users_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["users_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["users_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["users_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["users_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["users_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["users_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["users_bool_exp"]
	};
	["uuid"]: unknown;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
	["uuid_comparison_exp"]: {
		_eq?: ResolverInputTypes["uuid"] | undefined | null,
		_gt?: ResolverInputTypes["uuid"] | undefined | null,
		_gte?: ResolverInputTypes["uuid"] | undefined | null,
		_in?: Array<ResolverInputTypes["uuid"]> | undefined | null,
		_is_null?: boolean | undefined | null,
		_lt?: ResolverInputTypes["uuid"] | undefined | null,
		_lte?: ResolverInputTypes["uuid"] | undefined | null,
		_neq?: ResolverInputTypes["uuid"] | undefined | null,
		_nin?: Array<ResolverInputTypes["uuid"]> | undefined | null
	};
	/** columns and relationships of "storage.virus" */
	["virus"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		/** An object relationship */
		file?: ResolverInputTypes["files"],
		fileId?: boolean | `@${string}`,
		filename?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		userSession?: [{	/** JSON select path */
			path?: string | undefined | null
		}, boolean | `@${string}`],
		virus?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "storage.virus" */
	["virus_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["virus_aggregate_fields"],
		nodes?: ResolverInputTypes["virus"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "storage.virus" */
	["virus_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["virus_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["virus_max_fields"],
		min?: ResolverInputTypes["virus_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
	["virus_append_input"]: {
		userSession?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** Boolean expression to filter rows from the table "storage.virus". All fields are combined with a logical 'AND'. */
	["virus_bool_exp"]: {
		_and?: Array<ResolverInputTypes["virus_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["virus_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["virus_bool_exp"]> | undefined | null,
		createdAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		file?: ResolverInputTypes["files_bool_exp"] | undefined | null,
		fileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		filename?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		userSession?: ResolverInputTypes["jsonb_comparison_exp"] | undefined | null,
		virus?: ResolverInputTypes["String_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "storage.virus" */
	["virus_constraint"]: virus_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["virus_delete_at_path_input"]: {
		userSession?: Array<string> | undefined | null
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["virus_delete_elem_input"]: {
		userSession?: number | undefined | null
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["virus_delete_key_input"]: {
		userSession?: string | undefined | null
	};
	/** input type for inserting data into table "storage.virus" */
	["virus_insert_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		file?: ResolverInputTypes["files_obj_rel_insert_input"] | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		filename?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		userSession?: ResolverInputTypes["jsonb"] | undefined | null,
		virus?: string | undefined | null
	};
	/** aggregate max on columns */
	["virus_max_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		filename?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		virus?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["virus_min_fields"]: AliasType<{
		createdAt?: boolean | `@${string}`,
		fileId?: boolean | `@${string}`,
		filename?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		updatedAt?: boolean | `@${string}`,
		virus?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "storage.virus" */
	["virus_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["virus"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "storage.virus" */
	["virus_on_conflict"]: {
		constraint: ResolverInputTypes["virus_constraint"],
		update_columns: Array<ResolverInputTypes["virus_update_column"]>,
		where?: ResolverInputTypes["virus_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "storage.virus". */
	["virus_order_by"]: {
		createdAt?: ResolverInputTypes["order_by"] | undefined | null,
		file?: ResolverInputTypes["files_order_by"] | undefined | null,
		fileId?: ResolverInputTypes["order_by"] | undefined | null,
		filename?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		updatedAt?: ResolverInputTypes["order_by"] | undefined | null,
		userSession?: ResolverInputTypes["order_by"] | undefined | null,
		virus?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: storage.virus */
	["virus_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["virus_prepend_input"]: {
		userSession?: ResolverInputTypes["jsonb"] | undefined | null
	};
	/** select columns of table "storage.virus" */
	["virus_select_column"]: virus_select_column;
	/** input type for updating data in table "storage.virus" */
	["virus_set_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		filename?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		userSession?: ResolverInputTypes["jsonb"] | undefined | null,
		virus?: string | undefined | null
	};
	/** Streaming cursor of the table "virus" */
	["virus_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["virus_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["virus_stream_cursor_value_input"]: {
		createdAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		fileId?: ResolverInputTypes["uuid"] | undefined | null,
		filename?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		updatedAt?: ResolverInputTypes["timestamptz"] | undefined | null,
		userSession?: ResolverInputTypes["jsonb"] | undefined | null,
		virus?: string | undefined | null
	};
	/** update columns of table "storage.virus" */
	["virus_update_column"]: virus_update_column;
	["virus_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ResolverInputTypes["virus_append_input"] | undefined | null,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ResolverInputTypes["virus_delete_at_path_input"] | undefined | null,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ResolverInputTypes["virus_delete_elem_input"] | undefined | null,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ResolverInputTypes["virus_delete_key_input"] | undefined | null,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ResolverInputTypes["virus_prepend_input"] | undefined | null,
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["virus_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["virus_bool_exp"]
	};
	/** columns and relationships of "website" */
	["website"]: AliasType<{
		aboutUsContactBannerImageFileId?: boolean | `@${string}`,
		aboutUsContactTitle?: boolean | `@${string}`,
		aboutUsHistoryBannerImageFileId?: boolean | `@${string}`,
		aboutUsHistoryDescription?: boolean | `@${string}`,
		aboutUsHistoryTitle?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleTwo?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleTwo?: boolean | `@${string}`,
		aboutUsStatementBannerImageFileId?: boolean | `@${string}`,
		aboutUsStatementBannerText?: boolean | `@${string}`,
		aboutUsWeAreReadyBannerImageId?: boolean | `@${string}`,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyInnovationDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySocialResponsibilityDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySpecializedSupportDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyTitle?: boolean | `@${string}`,
		aboutUsWeAreReadyTransparencyDescription?: boolean | `@${string}`,
		aboutUsWhatMoveBannerImageFileId?: boolean | `@${string}`,
		aboutUsWhatMoveUsMission?: boolean | `@${string}`,
		aboutUsWhatMoveUsPurpose?: boolean | `@${string}`,
		aboutUsWhatMoveUsTitle?: boolean | `@${string}`,
		aboutUsWhatMoveUsValues?: boolean | `@${string}`,
		aboutUsWhatMoveUsVision?: boolean | `@${string}`,
		accentColor?: boolean | `@${string}`,
		backgroundColor?: boolean | `@${string}`,
		/** An object relationship */
		brand?: ResolverInputTypes["brands"],
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		domain?: boolean | `@${string}`,
		foregroundColor?: boolean | `@${string}`,
		googleAnalyticsKey?: boolean | `@${string}`,
		googleTagManagerKey?: boolean | `@${string}`,
		homeBrandHighlightBannerImageFileId?: boolean | `@${string}`,
		homeBrandHighlightDescription?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointValuePropositionList?: boolean | `@${string}`,
		homeBrandHighlightTitle?: boolean | `@${string}`,
		homeHeroBannerImageFileId?: boolean | `@${string}`,
		homeHeroCallToActionTargetUrl?: boolean | `@${string}`,
		homeHeroCallToActionText?: boolean | `@${string}`,
		homeHeroDescription?: boolean | `@${string}`,
		homeHeroLabel?: boolean | `@${string}`,
		homeHeroTitle?: boolean | `@${string}`,
		homeOurDifferentiatorsBannerImageFileId?: boolean | `@${string}`,
		homeOurDifferentiatorsDescription?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsTitle?: boolean | `@${string}`,
		homeWhatWeDoCallToActionTargetUrl?: boolean | `@${string}`,
		homeWhatWeDoCallToActionText?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoLegend?: boolean | `@${string}`,
		homeWhatWeDoDescription?: boolean | `@${string}`,
		homeWhatWeDoTitle?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		openGraphDescription?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregated selection of "website" */
	["website_aggregate"]: AliasType<{
		aggregate?: ResolverInputTypes["website_aggregate_fields"],
		nodes?: ResolverInputTypes["website"],
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate fields of "website" */
	["website_aggregate_fields"]: AliasType<{
		count?: [{ columns?: Array<ResolverInputTypes["website_select_column"]> | undefined | null, distinct?: boolean | undefined | null }, boolean | `@${string}`],
		max?: ResolverInputTypes["website_max_fields"],
		min?: ResolverInputTypes["website_min_fields"],
		__typename?: boolean | `@${string}`
	}>;
	/** Boolean expression to filter rows from the table "website". All fields are combined with a logical 'AND'. */
	["website_bool_exp"]: {
		_and?: Array<ResolverInputTypes["website_bool_exp"]> | undefined | null,
		_not?: ResolverInputTypes["website_bool_exp"] | undefined | null,
		_or?: Array<ResolverInputTypes["website_bool_exp"]> | undefined | null,
		aboutUsContactBannerImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		aboutUsContactTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsHistoryBannerImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		aboutUsHistoryDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsHistoryTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsHistorytKeypointSubtitleOne?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsHistorytKeypointSubtitleTwo?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsHistorytKeypointTitleOne?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsHistorytKeypointTitleTwo?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsStatementBannerImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		aboutUsStatementBannerText?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWeAreReadyBannerImageId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWeAreReadyInnovationDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWeAreReadySocialResponsibilityDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWeAreReadySpecializedSupportDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWeAreReadyTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWeAreReadyTransparencyDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWhatMoveBannerImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		aboutUsWhatMoveUsMission?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWhatMoveUsPurpose?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWhatMoveUsTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWhatMoveUsValues?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		aboutUsWhatMoveUsVision?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		accentColor?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		backgroundColor?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		brand?: ResolverInputTypes["brands_bool_exp"] | undefined | null,
		brandId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null,
		domain?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		foregroundColor?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		googleAnalyticsKey?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		googleTagManagerKey?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeBrandHighlightBannerImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		homeBrandHighlightDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeBrandHighlightKeypointSubtitleOne?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeBrandHighlightKeypointSubtitleTwo?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeBrandHighlightKeypointTitleOne?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeBrandHighlightKeypointTitleTwo?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeBrandHighlightKeypointValuePropositionList?: ResolverInputTypes["String_array_comparison_exp"] | undefined | null,
		homeBrandHighlightTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeHeroBannerImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		homeHeroCallToActionTargetUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeHeroCallToActionText?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeHeroDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeHeroLabel?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeHeroTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeOurDifferentiatorsBannerImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		homeOurDifferentiatorsDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleOne?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleTwo?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeOurDifferentiatorsKeypointTitleOne?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeOurDifferentiatorsKeypointTitleTwo?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeOurDifferentiatorsTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeWhatWeDoCallToActionTargetUrl?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeWhatWeDoCallToActionText?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeWhatWeDoCallToBannerOneImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		homeWhatWeDoCallToBannerOneLegend?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeWhatWeDoCallToBannerThreeImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		homeWhatWeDoCallToBannerThreeLegend?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeWhatWeDoCallToBannerTwoImageFileId?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		homeWhatWeDoCallToBannerTwoLegend?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeWhatWeDoDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		homeWhatWeDoTitle?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		id?: ResolverInputTypes["uuid_comparison_exp"] | undefined | null,
		openGraphDescription?: ResolverInputTypes["String_comparison_exp"] | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz_comparison_exp"] | undefined | null
	};
	/** unique or primary key constraints on table "website" */
	["website_constraint"]: website_constraint;
	/** input type for inserting data into table "website" */
	["website_insert_input"]: {
		aboutUsContactBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsContactTitle?: string | undefined | null,
		aboutUsHistoryBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsHistoryDescription?: string | undefined | null,
		aboutUsHistoryTitle?: string | undefined | null,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined | null,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined | null,
		aboutUsHistorytKeypointTitleOne?: string | undefined | null,
		aboutUsHistorytKeypointTitleTwo?: string | undefined | null,
		aboutUsStatementBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsStatementBannerText?: string | undefined | null,
		aboutUsWeAreReadyBannerImageId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined | null,
		aboutUsWeAreReadyInnovationDescription?: string | undefined | null,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined | null,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined | null,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined | null,
		aboutUsWeAreReadyTitle?: string | undefined | null,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined | null,
		aboutUsWhatMoveBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsWhatMoveUsMission?: string | undefined | null,
		aboutUsWhatMoveUsPurpose?: string | undefined | null,
		aboutUsWhatMoveUsTitle?: string | undefined | null,
		aboutUsWhatMoveUsValues?: string | undefined | null,
		aboutUsWhatMoveUsVision?: string | undefined | null,
		accentColor?: string | undefined | null,
		backgroundColor?: string | undefined | null,
		brand?: ResolverInputTypes["brands_obj_rel_insert_input"] | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		domain?: string | undefined | null,
		foregroundColor?: string | undefined | null,
		googleAnalyticsKey?: string | undefined | null,
		googleTagManagerKey?: string | undefined | null,
		homeBrandHighlightBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeBrandHighlightDescription?: string | undefined | null,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined | null,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined | null,
		homeBrandHighlightKeypointTitleOne?: string | undefined | null,
		homeBrandHighlightKeypointTitleTwo?: string | undefined | null,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined | null,
		homeBrandHighlightTitle?: string | undefined | null,
		homeHeroBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeHeroCallToActionTargetUrl?: string | undefined | null,
		homeHeroCallToActionText?: string | undefined | null,
		homeHeroDescription?: string | undefined | null,
		homeHeroLabel?: string | undefined | null,
		homeHeroTitle?: string | undefined | null,
		homeOurDifferentiatorsBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeOurDifferentiatorsDescription?: string | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined | null,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined | null,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined | null,
		homeOurDifferentiatorsTitle?: string | undefined | null,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined | null,
		homeWhatWeDoCallToActionText?: string | undefined | null,
		homeWhatWeDoCallToBannerOneImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined | null,
		homeWhatWeDoCallToBannerThreeImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined | null,
		homeWhatWeDoCallToBannerTwoImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined | null,
		homeWhatWeDoDescription?: string | undefined | null,
		homeWhatWeDoTitle?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		openGraphDescription?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** aggregate max on columns */
	["website_max_fields"]: AliasType<{
		aboutUsContactBannerImageFileId?: boolean | `@${string}`,
		aboutUsContactTitle?: boolean | `@${string}`,
		aboutUsHistoryBannerImageFileId?: boolean | `@${string}`,
		aboutUsHistoryDescription?: boolean | `@${string}`,
		aboutUsHistoryTitle?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleTwo?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleTwo?: boolean | `@${string}`,
		aboutUsStatementBannerImageFileId?: boolean | `@${string}`,
		aboutUsStatementBannerText?: boolean | `@${string}`,
		aboutUsWeAreReadyBannerImageId?: boolean | `@${string}`,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyInnovationDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySocialResponsibilityDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySpecializedSupportDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyTitle?: boolean | `@${string}`,
		aboutUsWeAreReadyTransparencyDescription?: boolean | `@${string}`,
		aboutUsWhatMoveBannerImageFileId?: boolean | `@${string}`,
		aboutUsWhatMoveUsMission?: boolean | `@${string}`,
		aboutUsWhatMoveUsPurpose?: boolean | `@${string}`,
		aboutUsWhatMoveUsTitle?: boolean | `@${string}`,
		aboutUsWhatMoveUsValues?: boolean | `@${string}`,
		aboutUsWhatMoveUsVision?: boolean | `@${string}`,
		accentColor?: boolean | `@${string}`,
		backgroundColor?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		domain?: boolean | `@${string}`,
		foregroundColor?: boolean | `@${string}`,
		googleAnalyticsKey?: boolean | `@${string}`,
		googleTagManagerKey?: boolean | `@${string}`,
		homeBrandHighlightBannerImageFileId?: boolean | `@${string}`,
		homeBrandHighlightDescription?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointValuePropositionList?: boolean | `@${string}`,
		homeBrandHighlightTitle?: boolean | `@${string}`,
		homeHeroBannerImageFileId?: boolean | `@${string}`,
		homeHeroCallToActionTargetUrl?: boolean | `@${string}`,
		homeHeroCallToActionText?: boolean | `@${string}`,
		homeHeroDescription?: boolean | `@${string}`,
		homeHeroLabel?: boolean | `@${string}`,
		homeHeroTitle?: boolean | `@${string}`,
		homeOurDifferentiatorsBannerImageFileId?: boolean | `@${string}`,
		homeOurDifferentiatorsDescription?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsTitle?: boolean | `@${string}`,
		homeWhatWeDoCallToActionTargetUrl?: boolean | `@${string}`,
		homeWhatWeDoCallToActionText?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoLegend?: boolean | `@${string}`,
		homeWhatWeDoDescription?: boolean | `@${string}`,
		homeWhatWeDoTitle?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		openGraphDescription?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** aggregate min on columns */
	["website_min_fields"]: AliasType<{
		aboutUsContactBannerImageFileId?: boolean | `@${string}`,
		aboutUsContactTitle?: boolean | `@${string}`,
		aboutUsHistoryBannerImageFileId?: boolean | `@${string}`,
		aboutUsHistoryDescription?: boolean | `@${string}`,
		aboutUsHistoryTitle?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointSubtitleTwo?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleOne?: boolean | `@${string}`,
		aboutUsHistorytKeypointTitleTwo?: boolean | `@${string}`,
		aboutUsStatementBannerImageFileId?: boolean | `@${string}`,
		aboutUsStatementBannerText?: boolean | `@${string}`,
		aboutUsWeAreReadyBannerImageId?: boolean | `@${string}`,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyInnovationDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySocialResponsibilityDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySpecializedSupportDescription?: boolean | `@${string}`,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: boolean | `@${string}`,
		aboutUsWeAreReadyTitle?: boolean | `@${string}`,
		aboutUsWeAreReadyTransparencyDescription?: boolean | `@${string}`,
		aboutUsWhatMoveBannerImageFileId?: boolean | `@${string}`,
		aboutUsWhatMoveUsMission?: boolean | `@${string}`,
		aboutUsWhatMoveUsPurpose?: boolean | `@${string}`,
		aboutUsWhatMoveUsTitle?: boolean | `@${string}`,
		aboutUsWhatMoveUsValues?: boolean | `@${string}`,
		aboutUsWhatMoveUsVision?: boolean | `@${string}`,
		accentColor?: boolean | `@${string}`,
		backgroundColor?: boolean | `@${string}`,
		brandId?: boolean | `@${string}`,
		created_at?: boolean | `@${string}`,
		domain?: boolean | `@${string}`,
		foregroundColor?: boolean | `@${string}`,
		googleAnalyticsKey?: boolean | `@${string}`,
		googleTagManagerKey?: boolean | `@${string}`,
		homeBrandHighlightBannerImageFileId?: boolean | `@${string}`,
		homeBrandHighlightDescription?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointSubtitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleOne?: boolean | `@${string}`,
		homeBrandHighlightKeypointTitleTwo?: boolean | `@${string}`,
		homeBrandHighlightKeypointValuePropositionList?: boolean | `@${string}`,
		homeBrandHighlightTitle?: boolean | `@${string}`,
		homeHeroBannerImageFileId?: boolean | `@${string}`,
		homeHeroCallToActionTargetUrl?: boolean | `@${string}`,
		homeHeroCallToActionText?: boolean | `@${string}`,
		homeHeroDescription?: boolean | `@${string}`,
		homeHeroLabel?: boolean | `@${string}`,
		homeHeroTitle?: boolean | `@${string}`,
		homeOurDifferentiatorsBannerImageFileId?: boolean | `@${string}`,
		homeOurDifferentiatorsDescription?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointSubtitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleOne?: boolean | `@${string}`,
		homeOurDifferentiatorsKeypointTitleTwo?: boolean | `@${string}`,
		homeOurDifferentiatorsTitle?: boolean | `@${string}`,
		homeWhatWeDoCallToActionTargetUrl?: boolean | `@${string}`,
		homeWhatWeDoCallToActionText?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerOneLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerThreeLegend?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoImageFileId?: boolean | `@${string}`,
		homeWhatWeDoCallToBannerTwoLegend?: boolean | `@${string}`,
		homeWhatWeDoDescription?: boolean | `@${string}`,
		homeWhatWeDoTitle?: boolean | `@${string}`,
		id?: boolean | `@${string}`,
		openGraphDescription?: boolean | `@${string}`,
		updated_at?: boolean | `@${string}`,
		__typename?: boolean | `@${string}`
	}>;
	/** response of any mutation on the table "website" */
	["website_mutation_response"]: AliasType<{
		/** number of rows affected by the mutation */
		affected_rows?: boolean | `@${string}`,
		/** data from the rows affected by the mutation */
		returning?: ResolverInputTypes["website"],
		__typename?: boolean | `@${string}`
	}>;
	/** on_conflict condition type for table "website" */
	["website_on_conflict"]: {
		constraint: ResolverInputTypes["website_constraint"],
		update_columns: Array<ResolverInputTypes["website_update_column"]>,
		where?: ResolverInputTypes["website_bool_exp"] | undefined | null
	};
	/** Ordering options when selecting data from "website". */
	["website_order_by"]: {
		aboutUsContactBannerImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsContactTitle?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsHistoryBannerImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsHistoryDescription?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsHistoryTitle?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsHistorytKeypointSubtitleOne?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsHistorytKeypointSubtitleTwo?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsHistorytKeypointTitleOne?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsHistorytKeypointTitleTwo?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsStatementBannerImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsStatementBannerText?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWeAreReadyBannerImageId?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWeAreReadyInnovationDescription?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWeAreReadySocialResponsibilityDescription?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWeAreReadySpecializedSupportDescription?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWeAreReadyTitle?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWeAreReadyTransparencyDescription?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWhatMoveBannerImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWhatMoveUsMission?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWhatMoveUsPurpose?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWhatMoveUsTitle?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWhatMoveUsValues?: ResolverInputTypes["order_by"] | undefined | null,
		aboutUsWhatMoveUsVision?: ResolverInputTypes["order_by"] | undefined | null,
		accentColor?: ResolverInputTypes["order_by"] | undefined | null,
		backgroundColor?: ResolverInputTypes["order_by"] | undefined | null,
		brand?: ResolverInputTypes["brands_order_by"] | undefined | null,
		brandId?: ResolverInputTypes["order_by"] | undefined | null,
		created_at?: ResolverInputTypes["order_by"] | undefined | null,
		domain?: ResolverInputTypes["order_by"] | undefined | null,
		foregroundColor?: ResolverInputTypes["order_by"] | undefined | null,
		googleAnalyticsKey?: ResolverInputTypes["order_by"] | undefined | null,
		googleTagManagerKey?: ResolverInputTypes["order_by"] | undefined | null,
		homeBrandHighlightBannerImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		homeBrandHighlightDescription?: ResolverInputTypes["order_by"] | undefined | null,
		homeBrandHighlightKeypointSubtitleOne?: ResolverInputTypes["order_by"] | undefined | null,
		homeBrandHighlightKeypointSubtitleTwo?: ResolverInputTypes["order_by"] | undefined | null,
		homeBrandHighlightKeypointTitleOne?: ResolverInputTypes["order_by"] | undefined | null,
		homeBrandHighlightKeypointTitleTwo?: ResolverInputTypes["order_by"] | undefined | null,
		homeBrandHighlightKeypointValuePropositionList?: ResolverInputTypes["order_by"] | undefined | null,
		homeBrandHighlightTitle?: ResolverInputTypes["order_by"] | undefined | null,
		homeHeroBannerImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		homeHeroCallToActionTargetUrl?: ResolverInputTypes["order_by"] | undefined | null,
		homeHeroCallToActionText?: ResolverInputTypes["order_by"] | undefined | null,
		homeHeroDescription?: ResolverInputTypes["order_by"] | undefined | null,
		homeHeroLabel?: ResolverInputTypes["order_by"] | undefined | null,
		homeHeroTitle?: ResolverInputTypes["order_by"] | undefined | null,
		homeOurDifferentiatorsBannerImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		homeOurDifferentiatorsDescription?: ResolverInputTypes["order_by"] | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleOne?: ResolverInputTypes["order_by"] | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleTwo?: ResolverInputTypes["order_by"] | undefined | null,
		homeOurDifferentiatorsKeypointTitleOne?: ResolverInputTypes["order_by"] | undefined | null,
		homeOurDifferentiatorsKeypointTitleTwo?: ResolverInputTypes["order_by"] | undefined | null,
		homeOurDifferentiatorsTitle?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoCallToActionTargetUrl?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoCallToActionText?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoCallToBannerOneImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoCallToBannerOneLegend?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoCallToBannerThreeImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoCallToBannerThreeLegend?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoCallToBannerTwoImageFileId?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoCallToBannerTwoLegend?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoDescription?: ResolverInputTypes["order_by"] | undefined | null,
		homeWhatWeDoTitle?: ResolverInputTypes["order_by"] | undefined | null,
		id?: ResolverInputTypes["order_by"] | undefined | null,
		openGraphDescription?: ResolverInputTypes["order_by"] | undefined | null,
		updated_at?: ResolverInputTypes["order_by"] | undefined | null
	};
	/** primary key columns input for table: website */
	["website_pk_columns_input"]: {
		id: ResolverInputTypes["uuid"]
	};
	/** select columns of table "website" */
	["website_select_column"]: website_select_column;
	/** input type for updating data in table "website" */
	["website_set_input"]: {
		aboutUsContactBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsContactTitle?: string | undefined | null,
		aboutUsHistoryBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsHistoryDescription?: string | undefined | null,
		aboutUsHistoryTitle?: string | undefined | null,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined | null,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined | null,
		aboutUsHistorytKeypointTitleOne?: string | undefined | null,
		aboutUsHistorytKeypointTitleTwo?: string | undefined | null,
		aboutUsStatementBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsStatementBannerText?: string | undefined | null,
		aboutUsWeAreReadyBannerImageId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined | null,
		aboutUsWeAreReadyInnovationDescription?: string | undefined | null,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined | null,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined | null,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined | null,
		aboutUsWeAreReadyTitle?: string | undefined | null,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined | null,
		aboutUsWhatMoveBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsWhatMoveUsMission?: string | undefined | null,
		aboutUsWhatMoveUsPurpose?: string | undefined | null,
		aboutUsWhatMoveUsTitle?: string | undefined | null,
		aboutUsWhatMoveUsValues?: string | undefined | null,
		aboutUsWhatMoveUsVision?: string | undefined | null,
		accentColor?: string | undefined | null,
		backgroundColor?: string | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		domain?: string | undefined | null,
		foregroundColor?: string | undefined | null,
		googleAnalyticsKey?: string | undefined | null,
		googleTagManagerKey?: string | undefined | null,
		homeBrandHighlightBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeBrandHighlightDescription?: string | undefined | null,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined | null,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined | null,
		homeBrandHighlightKeypointTitleOne?: string | undefined | null,
		homeBrandHighlightKeypointTitleTwo?: string | undefined | null,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined | null,
		homeBrandHighlightTitle?: string | undefined | null,
		homeHeroBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeHeroCallToActionTargetUrl?: string | undefined | null,
		homeHeroCallToActionText?: string | undefined | null,
		homeHeroDescription?: string | undefined | null,
		homeHeroLabel?: string | undefined | null,
		homeHeroTitle?: string | undefined | null,
		homeOurDifferentiatorsBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeOurDifferentiatorsDescription?: string | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined | null,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined | null,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined | null,
		homeOurDifferentiatorsTitle?: string | undefined | null,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined | null,
		homeWhatWeDoCallToActionText?: string | undefined | null,
		homeWhatWeDoCallToBannerOneImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined | null,
		homeWhatWeDoCallToBannerThreeImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined | null,
		homeWhatWeDoCallToBannerTwoImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined | null,
		homeWhatWeDoDescription?: string | undefined | null,
		homeWhatWeDoTitle?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		openGraphDescription?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** Streaming cursor of the table "website" */
	["website_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ResolverInputTypes["website_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ResolverInputTypes["cursor_ordering"] | undefined | null
	};
	/** Initial value of the column from where the streaming should start */
	["website_stream_cursor_value_input"]: {
		aboutUsContactBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsContactTitle?: string | undefined | null,
		aboutUsHistoryBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsHistoryDescription?: string | undefined | null,
		aboutUsHistoryTitle?: string | undefined | null,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined | null,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined | null,
		aboutUsHistorytKeypointTitleOne?: string | undefined | null,
		aboutUsHistorytKeypointTitleTwo?: string | undefined | null,
		aboutUsStatementBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsStatementBannerText?: string | undefined | null,
		aboutUsWeAreReadyBannerImageId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined | null,
		aboutUsWeAreReadyInnovationDescription?: string | undefined | null,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined | null,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined | null,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined | null,
		aboutUsWeAreReadyTitle?: string | undefined | null,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined | null,
		aboutUsWhatMoveBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		aboutUsWhatMoveUsMission?: string | undefined | null,
		aboutUsWhatMoveUsPurpose?: string | undefined | null,
		aboutUsWhatMoveUsTitle?: string | undefined | null,
		aboutUsWhatMoveUsValues?: string | undefined | null,
		aboutUsWhatMoveUsVision?: string | undefined | null,
		accentColor?: string | undefined | null,
		backgroundColor?: string | undefined | null,
		brandId?: ResolverInputTypes["uuid"] | undefined | null,
		created_at?: ResolverInputTypes["timestamptz"] | undefined | null,
		domain?: string | undefined | null,
		foregroundColor?: string | undefined | null,
		googleAnalyticsKey?: string | undefined | null,
		googleTagManagerKey?: string | undefined | null,
		homeBrandHighlightBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeBrandHighlightDescription?: string | undefined | null,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined | null,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined | null,
		homeBrandHighlightKeypointTitleOne?: string | undefined | null,
		homeBrandHighlightKeypointTitleTwo?: string | undefined | null,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined | null,
		homeBrandHighlightTitle?: string | undefined | null,
		homeHeroBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeHeroCallToActionTargetUrl?: string | undefined | null,
		homeHeroCallToActionText?: string | undefined | null,
		homeHeroDescription?: string | undefined | null,
		homeHeroLabel?: string | undefined | null,
		homeHeroTitle?: string | undefined | null,
		homeOurDifferentiatorsBannerImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeOurDifferentiatorsDescription?: string | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined | null,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined | null,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined | null,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined | null,
		homeOurDifferentiatorsTitle?: string | undefined | null,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined | null,
		homeWhatWeDoCallToActionText?: string | undefined | null,
		homeWhatWeDoCallToBannerOneImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined | null,
		homeWhatWeDoCallToBannerThreeImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined | null,
		homeWhatWeDoCallToBannerTwoImageFileId?: ResolverInputTypes["uuid"] | undefined | null,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined | null,
		homeWhatWeDoDescription?: string | undefined | null,
		homeWhatWeDoTitle?: string | undefined | null,
		id?: ResolverInputTypes["uuid"] | undefined | null,
		openGraphDescription?: string | undefined | null,
		updated_at?: ResolverInputTypes["timestamptz"] | undefined | null
	};
	/** update columns of table "website" */
	["website_update_column"]: website_update_column;
	["website_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ResolverInputTypes["website_set_input"] | undefined | null,
		/** filter the rows which have to be updated */
		where: ResolverInputTypes["website_bool_exp"]
	}
}

export type ModelTypes = {
	["schema"]: {
		query?: ModelTypes["query_root"] | undefined,
		mutation?: ModelTypes["mutation_root"] | undefined,
		subscription?: ModelTypes["subscription_root"] | undefined
	};
	["AIImageResult"]: {
		id: string,
		url: string
	};
	/** columns and relationships of "AppIntegration" */
	["AppIntegration"]: {
		configParams: ModelTypes["jsonb"],
		description: string,
		icon?: string | undefined,
		id: ModelTypes["uuid"],
		name: string,
		type: string
	};
	/** aggregated selection of "AppIntegration" */
	["AppIntegration_aggregate"]: {
		aggregate?: ModelTypes["AppIntegration_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["AppIntegration"]>
	};
	/** aggregate fields of "AppIntegration" */
	["AppIntegration_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["AppIntegration_max_fields"] | undefined,
		min?: ModelTypes["AppIntegration_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["AppIntegration_append_input"]: {
		configParams?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "AppIntegration". All fields are combined with a logical 'AND'. */
	["AppIntegration_bool_exp"]: {
		_and?: Array<ModelTypes["AppIntegration_bool_exp"]> | undefined,
		_not?: ModelTypes["AppIntegration_bool_exp"] | undefined,
		_or?: Array<ModelTypes["AppIntegration_bool_exp"]> | undefined,
		configParams?: ModelTypes["jsonb_comparison_exp"] | undefined,
		description?: ModelTypes["String_comparison_exp"] | undefined,
		icon?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined,
		type?: ModelTypes["String_comparison_exp"] | undefined
	};
	["AppIntegration_constraint"]: AppIntegration_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["AppIntegration_delete_at_path_input"]: {
		configParams?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["AppIntegration_delete_elem_input"]: {
		configParams?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["AppIntegration_delete_key_input"]: {
		configParams?: string | undefined
	};
	/** input type for inserting data into table "AppIntegration" */
	["AppIntegration_insert_input"]: {
		configParams?: ModelTypes["jsonb"] | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** aggregate max on columns */
	["AppIntegration_max_fields"]: {
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** aggregate min on columns */
	["AppIntegration_min_fields"]: {
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** response of any mutation on the table "AppIntegration" */
	["AppIntegration_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["AppIntegration"]>
	};
	/** on_conflict condition type for table "AppIntegration" */
	["AppIntegration_on_conflict"]: {
		constraint: ModelTypes["AppIntegration_constraint"],
		update_columns: Array<ModelTypes["AppIntegration_update_column"]>,
		where?: ModelTypes["AppIntegration_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "AppIntegration". */
	["AppIntegration_order_by"]: {
		configParams?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		icon?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		type?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: AppIntegration */
	["AppIntegration_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["AppIntegration_prepend_input"]: {
		configParams?: ModelTypes["jsonb"] | undefined
	};
	["AppIntegration_select_column"]: AppIntegration_select_column;
	/** input type for updating data in table "AppIntegration" */
	["AppIntegration_set_input"]: {
		configParams?: ModelTypes["jsonb"] | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** Streaming cursor of the table "AppIntegration" */
	["AppIntegration_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["AppIntegration_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["AppIntegration_stream_cursor_value_input"]: {
		configParams?: ModelTypes["jsonb"] | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	["AppIntegration_update_column"]: AppIntegration_update_column;
	["AppIntegration_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["AppIntegration_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["AppIntegration_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["AppIntegration_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["AppIntegration_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["AppIntegration_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["AppIntegration_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["AppIntegration_bool_exp"]
	};
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
	["Boolean_comparison_exp"]: {
		_eq?: boolean | undefined,
		_gt?: boolean | undefined,
		_gte?: boolean | undefined,
		_in?: Array<boolean> | undefined,
		_is_null?: boolean | undefined,
		_lt?: boolean | undefined,
		_lte?: boolean | undefined,
		_neq?: boolean | undefined,
		_nin?: Array<boolean> | undefined
	};
	["Category"]: {
		id: string,
		name: string
	};
	/** columns and relationships of "Course" */
	["Course"]: {
		courseCategoryId?: ModelTypes["bigint"] | undefined,
		description?: string | undefined,
		id: ModelTypes["uuid"],
		imageUrl?: string | undefined,
		name: string
	};
	/** columns and relationships of "CourseCategory" */
	["CourseCategory"]: {
		id: ModelTypes["bigint"],
		name: string
	};
	/** aggregated selection of "CourseCategory" */
	["CourseCategory_aggregate"]: {
		aggregate?: ModelTypes["CourseCategory_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["CourseCategory"]>
	};
	/** aggregate fields of "CourseCategory" */
	["CourseCategory_aggregate_fields"]: {
		avg?: ModelTypes["CourseCategory_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["CourseCategory_max_fields"] | undefined,
		min?: ModelTypes["CourseCategory_min_fields"] | undefined,
		stddev?: ModelTypes["CourseCategory_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["CourseCategory_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["CourseCategory_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["CourseCategory_sum_fields"] | undefined,
		var_pop?: ModelTypes["CourseCategory_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["CourseCategory_var_samp_fields"] | undefined,
		variance?: ModelTypes["CourseCategory_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["CourseCategory_avg_fields"]: {
		id?: number | undefined
	};
	/** Boolean expression to filter rows from the table "CourseCategory". All fields are combined with a logical 'AND'. */
	["CourseCategory_bool_exp"]: {
		_and?: Array<ModelTypes["CourseCategory_bool_exp"]> | undefined,
		_not?: ModelTypes["CourseCategory_bool_exp"] | undefined,
		_or?: Array<ModelTypes["CourseCategory_bool_exp"]> | undefined,
		id?: ModelTypes["bigint_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined
	};
	["CourseCategory_constraint"]: CourseCategory_constraint;
	/** input type for incrementing numeric columns in table "CourseCategory" */
	["CourseCategory_inc_input"]: {
		id?: ModelTypes["bigint"] | undefined
	};
	/** input type for inserting data into table "CourseCategory" */
	["CourseCategory_insert_input"]: {
		id?: ModelTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** aggregate max on columns */
	["CourseCategory_max_fields"]: {
		id?: ModelTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** aggregate min on columns */
	["CourseCategory_min_fields"]: {
		id?: ModelTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** response of any mutation on the table "CourseCategory" */
	["CourseCategory_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["CourseCategory"]>
	};
	/** on_conflict condition type for table "CourseCategory" */
	["CourseCategory_on_conflict"]: {
		constraint: ModelTypes["CourseCategory_constraint"],
		update_columns: Array<ModelTypes["CourseCategory_update_column"]>,
		where?: ModelTypes["CourseCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "CourseCategory". */
	["CourseCategory_order_by"]: {
		id?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: CourseCategory */
	["CourseCategory_pk_columns_input"]: {
		id: ModelTypes["bigint"]
	};
	["CourseCategory_select_column"]: CourseCategory_select_column;
	/** input type for updating data in table "CourseCategory" */
	["CourseCategory_set_input"]: {
		id?: ModelTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** aggregate stddev on columns */
	["CourseCategory_stddev_fields"]: {
		id?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["CourseCategory_stddev_pop_fields"]: {
		id?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["CourseCategory_stddev_samp_fields"]: {
		id?: number | undefined
	};
	/** Streaming cursor of the table "CourseCategory" */
	["CourseCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["CourseCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["CourseCategory_stream_cursor_value_input"]: {
		id?: ModelTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** aggregate sum on columns */
	["CourseCategory_sum_fields"]: {
		id?: ModelTypes["bigint"] | undefined
	};
	["CourseCategory_update_column"]: CourseCategory_update_column;
	["CourseCategory_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["CourseCategory_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["CourseCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["CourseCategory_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["CourseCategory_var_pop_fields"]: {
		id?: number | undefined
	};
	/** aggregate var_samp on columns */
	["CourseCategory_var_samp_fields"]: {
		id?: number | undefined
	};
	/** aggregate variance on columns */
	["CourseCategory_variance_fields"]: {
		id?: number | undefined
	};
	/** aggregated selection of "Course" */
	["Course_aggregate"]: {
		aggregate?: ModelTypes["Course_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["Course"]>
	};
	/** aggregate fields of "Course" */
	["Course_aggregate_fields"]: {
		avg?: ModelTypes["Course_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["Course_max_fields"] | undefined,
		min?: ModelTypes["Course_min_fields"] | undefined,
		stddev?: ModelTypes["Course_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["Course_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["Course_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["Course_sum_fields"] | undefined,
		var_pop?: ModelTypes["Course_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["Course_var_samp_fields"] | undefined,
		variance?: ModelTypes["Course_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["Course_avg_fields"]: {
		courseCategoryId?: number | undefined
	};
	/** Boolean expression to filter rows from the table "Course". All fields are combined with a logical 'AND'. */
	["Course_bool_exp"]: {
		_and?: Array<ModelTypes["Course_bool_exp"]> | undefined,
		_not?: ModelTypes["Course_bool_exp"] | undefined,
		_or?: Array<ModelTypes["Course_bool_exp"]> | undefined,
		courseCategoryId?: ModelTypes["bigint_comparison_exp"] | undefined,
		description?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		imageUrl?: ModelTypes["String_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined
	};
	["Course_constraint"]: Course_constraint;
	/** input type for incrementing numeric columns in table "Course" */
	["Course_inc_input"]: {
		courseCategoryId?: ModelTypes["bigint"] | undefined
	};
	/** input type for inserting data into table "Course" */
	["Course_insert_input"]: {
		courseCategoryId?: ModelTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** aggregate max on columns */
	["Course_max_fields"]: {
		courseCategoryId?: ModelTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** aggregate min on columns */
	["Course_min_fields"]: {
		courseCategoryId?: ModelTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** response of any mutation on the table "Course" */
	["Course_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["Course"]>
	};
	/** on_conflict condition type for table "Course" */
	["Course_on_conflict"]: {
		constraint: ModelTypes["Course_constraint"],
		update_columns: Array<ModelTypes["Course_update_column"]>,
		where?: ModelTypes["Course_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "Course". */
	["Course_order_by"]: {
		courseCategoryId?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		imageUrl?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: Course */
	["Course_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["Course_select_column"]: Course_select_column;
	/** input type for updating data in table "Course" */
	["Course_set_input"]: {
		courseCategoryId?: ModelTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** aggregate stddev on columns */
	["Course_stddev_fields"]: {
		courseCategoryId?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["Course_stddev_pop_fields"]: {
		courseCategoryId?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["Course_stddev_samp_fields"]: {
		courseCategoryId?: number | undefined
	};
	/** Streaming cursor of the table "Course" */
	["Course_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["Course_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["Course_stream_cursor_value_input"]: {
		courseCategoryId?: ModelTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** aggregate sum on columns */
	["Course_sum_fields"]: {
		courseCategoryId?: ModelTypes["bigint"] | undefined
	};
	["Course_update_column"]: Course_update_column;
	["Course_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["Course_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["Course_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["Course_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["Course_var_pop_fields"]: {
		courseCategoryId?: number | undefined
	};
	/** aggregate var_samp on columns */
	["Course_var_samp_fields"]: {
		courseCategoryId?: number | undefined
	};
	/** aggregate variance on columns */
	["Course_variance_fields"]: {
		courseCategoryId?: number | undefined
	};
	["CreateAutomaticProjectOutput"]: {
		projectId?: string | undefined
	};
	["CreateImageUsingImagineOutput"]: {
		errorMessage?: string | undefined,
		fileId?: string | undefined,
		success: boolean
	};
	["ExportToWordpressInput"]: {
		category: number,
		generatedTemplateById?: string | undefined,
		integrationId: string,
		postTimeType: string,
		scheduledDate?: ModelTypes["date"] | undefined,
		tags?: Array<number> | undefined
	};
	["ExportToWordpressOutput"]: {
		url: string
	};
	["FacebookAuthCallbackOutput"]: {
		success: boolean
	};
	["FinishOnboardOutput"]: {
		success: boolean
	};
	["GenerateCalendarExOutput"]: {
		success: boolean
	};
	["GenerateFromTextTemplateInput"]: {
		locale: string,
		selectedParameters?: Array<ModelTypes["StringKeyValuePair"]> | undefined,
		templateId: string,
		templateVariables?: Array<ModelTypes["StringKeyValuePair"]> | undefined
	};
	["GenerateFromTextTemplateOutput"]: {
		text?: string | undefined
	};
	["GenerateImageFromPromptInput"]: {
		provider: string,
		query: string
	};
	["GenerateImageFromPromptOutput"]: {
		images?: Array<ModelTypes["AIImageResult"] | undefined> | undefined
	};
	["GenerateNextMonthCalendarInput"]: {
		campaignObjective: string
	};
	["GenerateNextMonthCalendarOutput"]: {
		created: boolean
	};
	/** columns and relationships of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate"]: {
		/** An array relationship */
		GeneratedPromptByTemplateImages: Array<ModelTypes["GeneratedPromptByTemplateImage"]>,
		/** An aggregate relationship */
		GeneratedPromptByTemplateImages_aggregate: ModelTypes["GeneratedPromptByTemplateImage_aggregate"],
		/** An object relationship */
		TextPromptTemplate?: ModelTypes["TextPromptTemplate"] | undefined,
		active: boolean,
		alias?: string | undefined,
		backgroundImageFileId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		command?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by: ModelTypes["uuid"],
		/** An object relationship */
		displayFormat?: ModelTypes["displayFormats"] | undefined,
		displayImageUrl?: string | undefined,
		displayMapper?: ModelTypes["jsonb"] | undefined,
		generatingDisplayStatus?: string | undefined,
		id: ModelTypes["uuid"],
		/** An object relationship */
		pricingPlan?: ModelTypes["pricingPlan"] | undefined,
		pricingPlanId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		selectedDisplayFormatId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** columns and relationships of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage"]: {
		GeneratedPromptByTemplateId: ModelTypes["uuid"],
		fileId: ModelTypes["uuid"],
		id: ModelTypes["uuid"]
	};
	/** aggregated selection of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate"]: {
		aggregate?: ModelTypes["GeneratedPromptByTemplateImage_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["GeneratedPromptByTemplateImage"]>
	};
	["GeneratedPromptByTemplateImage_aggregate_bool_exp"]: {
		count?: ModelTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"] | undefined
	};
	["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["GeneratedPromptByTemplateImage_max_fields"] | undefined,
		min?: ModelTypes["GeneratedPromptByTemplateImage_min_fields"] | undefined
	};
	/** order by aggregate values of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["GeneratedPromptByTemplateImage_max_order_by"] | undefined,
		min?: ModelTypes["GeneratedPromptByTemplateImage_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_arr_rel_insert_input"]: {
		data: Array<ModelTypes["GeneratedPromptByTemplateImage_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["GeneratedPromptByTemplateImage_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "GeneratedPromptByTemplateImage". All fields are combined with a logical 'AND'. */
	["GeneratedPromptByTemplateImage_bool_exp"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid_comparison_exp"] | undefined,
		_and?: Array<ModelTypes["GeneratedPromptByTemplateImage_bool_exp"]> | undefined,
		_not?: ModelTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined,
		_or?: Array<ModelTypes["GeneratedPromptByTemplateImage_bool_exp"]> | undefined,
		fileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["GeneratedPromptByTemplateImage_constraint"]: GeneratedPromptByTemplateImage_constraint;
	/** input type for inserting data into table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_insert_input"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["GeneratedPromptByTemplateImage_max_fields"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_max_order_by"]: {
		GeneratedPromptByTemplateId?: ModelTypes["order_by"] | undefined,
		fileId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["GeneratedPromptByTemplateImage_min_fields"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_min_order_by"]: {
		GeneratedPromptByTemplateId?: ModelTypes["order_by"] | undefined,
		fileId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["GeneratedPromptByTemplateImage"]>
	};
	/** on_conflict condition type for table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_on_conflict"]: {
		constraint: ModelTypes["GeneratedPromptByTemplateImage_constraint"],
		update_columns: Array<ModelTypes["GeneratedPromptByTemplateImage_update_column"]>,
		where?: ModelTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "GeneratedPromptByTemplateImage". */
	["GeneratedPromptByTemplateImage_order_by"]: {
		GeneratedPromptByTemplateId?: ModelTypes["order_by"] | undefined,
		fileId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: GeneratedPromptByTemplateImage */
	["GeneratedPromptByTemplateImage_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["GeneratedPromptByTemplateImage_select_column"]: GeneratedPromptByTemplateImage_select_column;
	/** input type for updating data in table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_set_input"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["GeneratedPromptByTemplateImage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["GeneratedPromptByTemplateImage_stream_cursor_value_input"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	["GeneratedPromptByTemplateImage_update_column"]: GeneratedPromptByTemplateImage_update_column;
	["GeneratedPromptByTemplateImage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["GeneratedPromptByTemplateImage_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["GeneratedPromptByTemplateImage_bool_exp"]
	};
	/** aggregated selection of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate"]: {
		aggregate?: ModelTypes["GeneratedPromptByTemplate_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["GeneratedPromptByTemplate"]>
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp"]: {
		bool_and?: ModelTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: ModelTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"] | undefined,
		count?: ModelTypes["GeneratedPromptByTemplate_aggregate_bool_exp_count"] | undefined
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"]: {
		arguments: ModelTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"]: {
		arguments: ModelTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["GeneratedPromptByTemplate_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["GeneratedPromptByTemplate_max_fields"] | undefined,
		min?: ModelTypes["GeneratedPromptByTemplate_min_fields"] | undefined
	};
	/** order by aggregate values of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["GeneratedPromptByTemplate_max_order_by"] | undefined,
		min?: ModelTypes["GeneratedPromptByTemplate_min_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["GeneratedPromptByTemplate_append_input"]: {
		command?: ModelTypes["jsonb"] | undefined,
		displayMapper?: ModelTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_arr_rel_insert_input"]: {
		data: Array<ModelTypes["GeneratedPromptByTemplate_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["GeneratedPromptByTemplate_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "GeneratedPromptByTemplate". All fields are combined with a logical 'AND'. */
	["GeneratedPromptByTemplate_bool_exp"]: {
		GeneratedPromptByTemplateImages?: ModelTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined,
		GeneratedPromptByTemplateImages_aggregate?: ModelTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp"] | undefined,
		TextPromptTemplate?: ModelTypes["TextPromptTemplate_bool_exp"] | undefined,
		_and?: Array<ModelTypes["GeneratedPromptByTemplate_bool_exp"]> | undefined,
		_not?: ModelTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		_or?: Array<ModelTypes["GeneratedPromptByTemplate_bool_exp"]> | undefined,
		active?: ModelTypes["Boolean_comparison_exp"] | undefined,
		alias?: ModelTypes["String_comparison_exp"] | undefined,
		backgroundImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		channel?: ModelTypes["String_comparison_exp"] | undefined,
		command?: ModelTypes["jsonb_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		displayFormat?: ModelTypes["displayFormats_bool_exp"] | undefined,
		displayImageUrl?: ModelTypes["String_comparison_exp"] | undefined,
		displayMapper?: ModelTypes["jsonb_comparison_exp"] | undefined,
		generatingDisplayStatus?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		pricingPlan?: ModelTypes["pricingPlan_bool_exp"] | undefined,
		pricingPlanId?: ModelTypes["uuid_comparison_exp"] | undefined,
		publishDate?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		result?: ModelTypes["String_comparison_exp"] | undefined,
		scheduledDate?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid_comparison_exp"] | undefined,
		selectedDisplayFormatId?: ModelTypes["uuid_comparison_exp"] | undefined,
		status?: ModelTypes["String_comparison_exp"] | undefined,
		textPromptTemplateId?: ModelTypes["uuid_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["GeneratedPromptByTemplate_constraint"]: GeneratedPromptByTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["GeneratedPromptByTemplate_delete_at_path_input"]: {
		command?: Array<string> | undefined,
		displayMapper?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["GeneratedPromptByTemplate_delete_elem_input"]: {
		command?: number | undefined,
		displayMapper?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["GeneratedPromptByTemplate_delete_key_input"]: {
		command?: string | undefined,
		displayMapper?: string | undefined
	};
	/** input type for inserting data into table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_insert_input"]: {
		GeneratedPromptByTemplateImages?: ModelTypes["GeneratedPromptByTemplateImage_arr_rel_insert_input"] | undefined,
		TextPromptTemplate?: ModelTypes["TextPromptTemplate_obj_rel_insert_input"] | undefined,
		active?: boolean | undefined,
		alias?: string | undefined,
		backgroundImageFileId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		command?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		displayFormat?: ModelTypes["displayFormats_obj_rel_insert_input"] | undefined,
		displayImageUrl?: string | undefined,
		displayMapper?: ModelTypes["jsonb"] | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		pricingPlan?: ModelTypes["pricingPlan_obj_rel_insert_input"] | undefined,
		pricingPlanId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		selectedDisplayFormatId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["GeneratedPromptByTemplate_max_fields"]: {
		alias?: string | undefined,
		backgroundImageFileId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		displayImageUrl?: string | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		pricingPlanId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		selectedDisplayFormatId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_max_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		backgroundImageFileId?: ModelTypes["order_by"] | undefined,
		channel?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		displayImageUrl?: ModelTypes["order_by"] | undefined,
		generatingDisplayStatus?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		pricingPlanId?: ModelTypes["order_by"] | undefined,
		publishDate?: ModelTypes["order_by"] | undefined,
		result?: ModelTypes["order_by"] | undefined,
		scheduledDate?: ModelTypes["order_by"] | undefined,
		scheduledDateEventId?: ModelTypes["order_by"] | undefined,
		selectedDisplayFormatId?: ModelTypes["order_by"] | undefined,
		status?: ModelTypes["order_by"] | undefined,
		textPromptTemplateId?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["GeneratedPromptByTemplate_min_fields"]: {
		alias?: string | undefined,
		backgroundImageFileId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		displayImageUrl?: string | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		pricingPlanId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		selectedDisplayFormatId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_min_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		backgroundImageFileId?: ModelTypes["order_by"] | undefined,
		channel?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		displayImageUrl?: ModelTypes["order_by"] | undefined,
		generatingDisplayStatus?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		pricingPlanId?: ModelTypes["order_by"] | undefined,
		publishDate?: ModelTypes["order_by"] | undefined,
		result?: ModelTypes["order_by"] | undefined,
		scheduledDate?: ModelTypes["order_by"] | undefined,
		scheduledDateEventId?: ModelTypes["order_by"] | undefined,
		selectedDisplayFormatId?: ModelTypes["order_by"] | undefined,
		status?: ModelTypes["order_by"] | undefined,
		textPromptTemplateId?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["GeneratedPromptByTemplate"]>
	};
	/** on_conflict condition type for table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_on_conflict"]: {
		constraint: ModelTypes["GeneratedPromptByTemplate_constraint"],
		update_columns: Array<ModelTypes["GeneratedPromptByTemplate_update_column"]>,
		where?: ModelTypes["GeneratedPromptByTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "GeneratedPromptByTemplate". */
	["GeneratedPromptByTemplate_order_by"]: {
		GeneratedPromptByTemplateImages_aggregate?: ModelTypes["GeneratedPromptByTemplateImage_aggregate_order_by"] | undefined,
		TextPromptTemplate?: ModelTypes["TextPromptTemplate_order_by"] | undefined,
		active?: ModelTypes["order_by"] | undefined,
		alias?: ModelTypes["order_by"] | undefined,
		backgroundImageFileId?: ModelTypes["order_by"] | undefined,
		channel?: ModelTypes["order_by"] | undefined,
		command?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		displayFormat?: ModelTypes["displayFormats_order_by"] | undefined,
		displayImageUrl?: ModelTypes["order_by"] | undefined,
		displayMapper?: ModelTypes["order_by"] | undefined,
		generatingDisplayStatus?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		pricingPlan?: ModelTypes["pricingPlan_order_by"] | undefined,
		pricingPlanId?: ModelTypes["order_by"] | undefined,
		publishDate?: ModelTypes["order_by"] | undefined,
		result?: ModelTypes["order_by"] | undefined,
		scheduledDate?: ModelTypes["order_by"] | undefined,
		scheduledDateEventId?: ModelTypes["order_by"] | undefined,
		selectedDisplayFormatId?: ModelTypes["order_by"] | undefined,
		status?: ModelTypes["order_by"] | undefined,
		textPromptTemplateId?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: GeneratedPromptByTemplate */
	["GeneratedPromptByTemplate_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["GeneratedPromptByTemplate_prepend_input"]: {
		command?: ModelTypes["jsonb"] | undefined,
		displayMapper?: ModelTypes["jsonb"] | undefined
	};
	["GeneratedPromptByTemplate_select_column"]: GeneratedPromptByTemplate_select_column;
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns;
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_set_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		backgroundImageFileId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		command?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		displayImageUrl?: string | undefined,
		displayMapper?: ModelTypes["jsonb"] | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		pricingPlanId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		selectedDisplayFormatId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["GeneratedPromptByTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["GeneratedPromptByTemplate_stream_cursor_value_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		backgroundImageFileId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		command?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		displayImageUrl?: string | undefined,
		displayMapper?: ModelTypes["jsonb"] | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		pricingPlanId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		selectedDisplayFormatId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["GeneratedPromptByTemplate_update_column"]: GeneratedPromptByTemplate_update_column;
	["GeneratedPromptByTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["GeneratedPromptByTemplate_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["GeneratedPromptByTemplate_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["GeneratedPromptByTemplate_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["GeneratedPromptByTemplate_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["GeneratedPromptByTemplate_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["GeneratedPromptByTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["GeneratedPromptByTemplate_bool_exp"]
	};
	["GetFacebookPagesOutput"]: {
		pages: Array<ModelTypes["facebookPage"] | undefined>
	};
	["GetFacebookTokenOutput"]: {
		loginUrl: string
	};
	["GetImageFromDisplayRendererOutput"]: {
		image: string
	};
	["GetInstagramAuthCallbackOutput"]: {
		success: boolean
	};
	["GetInstagramPagesOutput"]: {
		accounts: Array<ModelTypes["instagramPageAccount"] | undefined>
	};
	["GetInstagramTokenOutput"]: {
		loginUrl: string
	};
	["GetPaymentUrlCommand"]: {
		email: string,
		price_id: string,
		user_id: string
	};
	["GetPaymentUrlResponse"]: {
		url: string
	};
	["GetWordpressCategoriesInput"]: {
		integrationId: string
	};
	["GetWordpressCategoriesOutput"]: {
		categories?: Array<ModelTypes["Category"]> | undefined
	};
	["GetWordpressTagsInput"]: {
		integrationId: string
	};
	["GetWordpressTagsOutput"]: {
		tags?: Array<ModelTypes["Tag"]> | undefined
	};
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
	["Int_comparison_exp"]: {
		_eq?: number | undefined,
		_gt?: number | undefined,
		_gte?: number | undefined,
		_in?: Array<number> | undefined,
		_is_null?: boolean | undefined,
		_lt?: number | undefined,
		_lte?: number | undefined,
		_neq?: number | undefined,
		_nin?: Array<number> | undefined
	};
	["LinkedInTokenCallbackOutput"]: {
		success: boolean
	};
	["ManageSubscriptionOutput"]: {
		url: string
	};
	["PexelsImageResult"]: {
		alt: string,
		height: string,
		id: string,
		photographer: string,
		photographer_url: string,
		small: string,
		url: string,
		width: string
	};
	["PostCarouselToInstagramOutput"]: {
		error: string,
		success: boolean
	};
	["PostFeedToInstagramOutput"]: {
		error?: string | undefined,
		success: boolean
	};
	["PostStoryToInstagramOutput"]: {
		error: string,
		success: boolean
	};
	["PostToFacebookOutput"]: {
		success: boolean
	};
	["PostToLinkedInOutput"]: {
		success: boolean
	};
	["PromptOutput"]: {
		text: string
	};
	["PublishOrScheduleProjectOutput"]: {
		errorMessage?: string | undefined,
		success: boolean
	};
	["RenderAndSaveDisplayImageOutput"]: {
		image: string
	};
	["RenderDisplayOutput"]: {
		image: string
	};
	["SaveAndGeneratePolotnoImageOutput"]: {
		message?: string | undefined,
		success: boolean
	};
	["SearchImagesFromPexelsResponse"]: {
		images?: Array<ModelTypes["PexelsImageResult"] | undefined> | undefined
	};
	["SelectNewDisplayOutput"]: {
		error?: string | undefined,
		id?: string | undefined
	};
	["SerpStatTestInput"]: {
		domain?: string | undefined
	};
	["SerpStatTestInputData"]: {
		ad_keywords: number,
		ads: number,
		ads_dynamic: number,
		domain: string,
		down_keywords: number,
		keywords: number,
		keywords_dynamic: number,
		new_keywords: number,
		out_keywords: number,
		prev_date: string,
		rised_keywords: number,
		traff: number,
		traff_dynamic: number,
		visible: number,
		visible_dynamic: number
	};
	["SerpStatTestInputResult"]: {
		data?: Array<ModelTypes["SerpStatTestInputData"]> | undefined,
		summary_info: ModelTypes["SerpStatTestInputSummaryInfo"]
	};
	["SerpStatTestInputSummaryInfo"]: {
		left_lines: number,
		page: number
	};
	["SerpStatTestOutput"]: {
		data: ModelTypes["SerpStatTestOutputData"]
	};
	["SerpStatTestOutputData"]: {
		id: string,
		result: ModelTypes["SerpStatTestInputResult"]
	};
	["StringKeyValuePair"]: {
		key: string,
		value: string
	};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
	["String_array_comparison_exp"]: {
		/** is the array contained in the given array value */
		_contained_in?: Array<string> | undefined,
		/** does the array contain the given value */
		_contains?: Array<string> | undefined,
		_eq?: Array<string> | undefined,
		_gt?: Array<string> | undefined,
		_gte?: Array<string> | undefined,
		_in?: Array<Array<string> | undefined>,
		_is_null?: boolean | undefined,
		_lt?: Array<string> | undefined,
		_lte?: Array<string> | undefined,
		_neq?: Array<string> | undefined,
		_nin?: Array<Array<string> | undefined>
	};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
	["String_comparison_exp"]: {
		_eq?: string | undefined,
		_gt?: string | undefined,
		_gte?: string | undefined,
		/** does the column match the given case-insensitive pattern */
		_ilike?: string | undefined,
		_in?: Array<string> | undefined,
		/** does the column match the given POSIX regular expression, case insensitive */
		_iregex?: string | undefined,
		_is_null?: boolean | undefined,
		/** does the column match the given pattern */
		_like?: string | undefined,
		_lt?: string | undefined,
		_lte?: string | undefined,
		_neq?: string | undefined,
		/** does the column NOT match the given case-insensitive pattern */
		_nilike?: string | undefined,
		_nin?: Array<string> | undefined,
		/** does the column NOT match the given POSIX regular expression, case insensitive */
		_niregex?: string | undefined,
		/** does the column NOT match the given pattern */
		_nlike?: string | undefined,
		/** does the column NOT match the given POSIX regular expression, case sensitive */
		_nregex?: string | undefined,
		/** does the column NOT match the given SQL regular expression */
		_nsimilar?: string | undefined,
		/** does the column match the given POSIX regular expression, case sensitive */
		_regex?: string | undefined,
		/** does the column match the given SQL regular expression */
		_similar?: string | undefined
	};
	["Tag"]: {
		id: string,
		name: string
	};
	/** columns and relationships of "TextGeneratorOption" */
	["TextGeneratorOption"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		/** An array relationship */
		TextGeneratorOptionEntries: Array<ModelTypes["TextGeneratorOptionEntry"]>,
		/** An aggregate relationship */
		TextGeneratorOptionEntries_aggregate: ModelTypes["TextGeneratorOptionEntry_aggregate"],
		created_at: ModelTypes["timestamptz"],
		id: ModelTypes["uuid"],
		popover_tooltip?: ModelTypes["jsonb"] | undefined,
		updated_at: ModelTypes["timestamptz"]
	};
	/** columns and relationships of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		/** An object relationship */
		TextGeneratorOption: ModelTypes["TextGeneratorOption"],
		TextGeneratorOptionId: ModelTypes["uuid"],
		id: ModelTypes["uuid"]
	};
	/** aggregated selection of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate"]: {
		aggregate?: ModelTypes["TextGeneratorOptionEntry_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["TextGeneratorOptionEntry"]>
	};
	["TextGeneratorOptionEntry_aggregate_bool_exp"]: {
		count?: ModelTypes["TextGeneratorOptionEntry_aggregate_bool_exp_count"] | undefined
	};
	["TextGeneratorOptionEntry_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["TextGeneratorOptionEntry_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["TextGeneratorOptionEntry_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["TextGeneratorOptionEntry_max_fields"] | undefined,
		min?: ModelTypes["TextGeneratorOptionEntry_min_fields"] | undefined
	};
	/** order by aggregate values of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["TextGeneratorOptionEntry_max_order_by"] | undefined,
		min?: ModelTypes["TextGeneratorOptionEntry_min_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOptionEntry_append_input"]: {
		Label?: ModelTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_arr_rel_insert_input"]: {
		data: Array<ModelTypes["TextGeneratorOptionEntry_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["TextGeneratorOptionEntry_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextGeneratorOptionEntry". All fields are combined with a logical 'AND'. */
	["TextGeneratorOptionEntry_bool_exp"]: {
		Label?: ModelTypes["jsonb_comparison_exp"] | undefined,
		TextGeneratorOption?: ModelTypes["TextGeneratorOption_bool_exp"] | undefined,
		TextGeneratorOptionId?: ModelTypes["uuid_comparison_exp"] | undefined,
		_and?: Array<ModelTypes["TextGeneratorOptionEntry_bool_exp"]> | undefined,
		_not?: ModelTypes["TextGeneratorOptionEntry_bool_exp"] | undefined,
		_or?: Array<ModelTypes["TextGeneratorOptionEntry_bool_exp"]> | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["TextGeneratorOptionEntry_constraint"]: TextGeneratorOptionEntry_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextGeneratorOptionEntry_delete_at_path_input"]: {
		Label?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextGeneratorOptionEntry_delete_elem_input"]: {
		Label?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextGeneratorOptionEntry_delete_key_input"]: {
		Label?: string | undefined
	};
	/** input type for inserting data into table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_insert_input"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		TextGeneratorOption?: ModelTypes["TextGeneratorOption_obj_rel_insert_input"] | undefined,
		TextGeneratorOptionId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["TextGeneratorOptionEntry_max_fields"]: {
		TextGeneratorOptionId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_max_order_by"]: {
		TextGeneratorOptionId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["TextGeneratorOptionEntry_min_fields"]: {
		TextGeneratorOptionId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_min_order_by"]: {
		TextGeneratorOptionId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["TextGeneratorOptionEntry"]>
	};
	/** on_conflict condition type for table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_on_conflict"]: {
		constraint: ModelTypes["TextGeneratorOptionEntry_constraint"],
		update_columns: Array<ModelTypes["TextGeneratorOptionEntry_update_column"]>,
		where?: ModelTypes["TextGeneratorOptionEntry_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextGeneratorOptionEntry". */
	["TextGeneratorOptionEntry_order_by"]: {
		Label?: ModelTypes["order_by"] | undefined,
		TextGeneratorOption?: ModelTypes["TextGeneratorOption_order_by"] | undefined,
		TextGeneratorOptionId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextGeneratorOptionEntry */
	["TextGeneratorOptionEntry_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOptionEntry_prepend_input"]: {
		Label?: ModelTypes["jsonb"] | undefined
	};
	["TextGeneratorOptionEntry_select_column"]: TextGeneratorOptionEntry_select_column;
	/** input type for updating data in table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_set_input"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		TextGeneratorOptionId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["TextGeneratorOptionEntry_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextGeneratorOptionEntry_stream_cursor_value_input"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		TextGeneratorOptionId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	["TextGeneratorOptionEntry_update_column"]: TextGeneratorOptionEntry_update_column;
	["TextGeneratorOptionEntry_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["TextGeneratorOptionEntry_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["TextGeneratorOptionEntry_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["TextGeneratorOptionEntry_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["TextGeneratorOptionEntry_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["TextGeneratorOptionEntry_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["TextGeneratorOptionEntry_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["TextGeneratorOptionEntry_bool_exp"]
	};
	/** aggregated selection of "TextGeneratorOption" */
	["TextGeneratorOption_aggregate"]: {
		aggregate?: ModelTypes["TextGeneratorOption_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["TextGeneratorOption"]>
	};
	/** aggregate fields of "TextGeneratorOption" */
	["TextGeneratorOption_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["TextGeneratorOption_max_fields"] | undefined,
		min?: ModelTypes["TextGeneratorOption_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOption_append_input"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		popover_tooltip?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextGeneratorOption". All fields are combined with a logical 'AND'. */
	["TextGeneratorOption_bool_exp"]: {
		Label?: ModelTypes["jsonb_comparison_exp"] | undefined,
		TextGeneratorOptionEntries?: ModelTypes["TextGeneratorOptionEntry_bool_exp"] | undefined,
		TextGeneratorOptionEntries_aggregate?: ModelTypes["TextGeneratorOptionEntry_aggregate_bool_exp"] | undefined,
		_and?: Array<ModelTypes["TextGeneratorOption_bool_exp"]> | undefined,
		_not?: ModelTypes["TextGeneratorOption_bool_exp"] | undefined,
		_or?: Array<ModelTypes["TextGeneratorOption_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		popover_tooltip?: ModelTypes["jsonb_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["TextGeneratorOption_constraint"]: TextGeneratorOption_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextGeneratorOption_delete_at_path_input"]: {
		Label?: Array<string> | undefined,
		popover_tooltip?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextGeneratorOption_delete_elem_input"]: {
		Label?: number | undefined,
		popover_tooltip?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextGeneratorOption_delete_key_input"]: {
		Label?: string | undefined,
		popover_tooltip?: string | undefined
	};
	/** input type for inserting data into table "TextGeneratorOption" */
	["TextGeneratorOption_insert_input"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		TextGeneratorOptionEntries?: ModelTypes["TextGeneratorOptionEntry_arr_rel_insert_input"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		popover_tooltip?: ModelTypes["jsonb"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["TextGeneratorOption_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["TextGeneratorOption_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "TextGeneratorOption" */
	["TextGeneratorOption_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["TextGeneratorOption"]>
	};
	/** input type for inserting object relation for remote table "TextGeneratorOption" */
	["TextGeneratorOption_obj_rel_insert_input"]: {
		data: ModelTypes["TextGeneratorOption_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["TextGeneratorOption_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "TextGeneratorOption" */
	["TextGeneratorOption_on_conflict"]: {
		constraint: ModelTypes["TextGeneratorOption_constraint"],
		update_columns: Array<ModelTypes["TextGeneratorOption_update_column"]>,
		where?: ModelTypes["TextGeneratorOption_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextGeneratorOption". */
	["TextGeneratorOption_order_by"]: {
		Label?: ModelTypes["order_by"] | undefined,
		TextGeneratorOptionEntries_aggregate?: ModelTypes["TextGeneratorOptionEntry_aggregate_order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		popover_tooltip?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextGeneratorOption */
	["TextGeneratorOption_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOption_prepend_input"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		popover_tooltip?: ModelTypes["jsonb"] | undefined
	};
	["TextGeneratorOption_select_column"]: TextGeneratorOption_select_column;
	/** input type for updating data in table "TextGeneratorOption" */
	["TextGeneratorOption_set_input"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		popover_tooltip?: ModelTypes["jsonb"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "TextGeneratorOption" */
	["TextGeneratorOption_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["TextGeneratorOption_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextGeneratorOption_stream_cursor_value_input"]: {
		Label?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		popover_tooltip?: ModelTypes["jsonb"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["TextGeneratorOption_update_column"]: TextGeneratorOption_update_column;
	["TextGeneratorOption_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["TextGeneratorOption_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["TextGeneratorOption_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["TextGeneratorOption_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["TextGeneratorOption_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["TextGeneratorOption_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["TextGeneratorOption_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["TextGeneratorOption_bool_exp"]
	};
	/** columns and relationships of "TextPromptTemplate" */
	["TextPromptTemplate"]: {
		/** An array relationship */
		GeneratedPromptByTemplates: Array<ModelTypes["GeneratedPromptByTemplate"]>,
		/** An aggregate relationship */
		GeneratedPromptByTemplates_aggregate: ModelTypes["GeneratedPromptByTemplate_aggregate"],
		/** An object relationship */
		TextPromptTemplateCategory?: ModelTypes["TextPromptTemplateCategory"] | undefined,
		/** An object relationship */
		TextTemplateClientCategory?: ModelTypes["TextTemplateClientCategory"] | undefined,
		TextTemplateClientCategoryId?: ModelTypes["uuid"] | undefined,
		/** An array relationship */
		availableDisplays: Array<ModelTypes["textTemplateDisplayFormat"]>,
		/** An aggregate relationship */
		availableDisplays_aggregate: ModelTypes["textTemplateDisplayFormat_aggregate"],
		categoryId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		definitions: ModelTypes["jsonb"],
		description: string,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		flexible: boolean,
		icon: string,
		id: ModelTypes["uuid"],
		marketPriceInCredits?: ModelTypes["numeric"] | undefined,
		name: string,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** columns and relationships of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory"]: {
		/** An array relationship */
		TextPromptTemplates: Array<ModelTypes["TextPromptTemplate"]>,
		/** An aggregate relationship */
		TextPromptTemplates_aggregate: ModelTypes["TextPromptTemplate_aggregate"],
		categoryName: string,
		id: ModelTypes["uuid"]
	};
	/** aggregated selection of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_aggregate"]: {
		aggregate?: ModelTypes["TextPromptTemplateCategory_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["TextPromptTemplateCategory"]>
	};
	/** aggregate fields of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["TextPromptTemplateCategory_max_fields"] | undefined,
		min?: ModelTypes["TextPromptTemplateCategory_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextPromptTemplateCategory". All fields are combined with a logical 'AND'. */
	["TextPromptTemplateCategory_bool_exp"]: {
		TextPromptTemplates?: ModelTypes["TextPromptTemplate_bool_exp"] | undefined,
		TextPromptTemplates_aggregate?: ModelTypes["TextPromptTemplate_aggregate_bool_exp"] | undefined,
		_and?: Array<ModelTypes["TextPromptTemplateCategory_bool_exp"]> | undefined,
		_not?: ModelTypes["TextPromptTemplateCategory_bool_exp"] | undefined,
		_or?: Array<ModelTypes["TextPromptTemplateCategory_bool_exp"]> | undefined,
		categoryName?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["TextPromptTemplateCategory_constraint"]: TextPromptTemplateCategory_constraint;
	/** input type for inserting data into table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_insert_input"]: {
		TextPromptTemplates?: ModelTypes["TextPromptTemplate_arr_rel_insert_input"] | undefined,
		categoryName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["TextPromptTemplateCategory_max_fields"]: {
		categoryName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["TextPromptTemplateCategory_min_fields"]: {
		categoryName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["TextPromptTemplateCategory"]>
	};
	/** input type for inserting object relation for remote table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_obj_rel_insert_input"]: {
		data: ModelTypes["TextPromptTemplateCategory_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["TextPromptTemplateCategory_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_on_conflict"]: {
		constraint: ModelTypes["TextPromptTemplateCategory_constraint"],
		update_columns: Array<ModelTypes["TextPromptTemplateCategory_update_column"]>,
		where?: ModelTypes["TextPromptTemplateCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextPromptTemplateCategory". */
	["TextPromptTemplateCategory_order_by"]: {
		TextPromptTemplates_aggregate?: ModelTypes["TextPromptTemplate_aggregate_order_by"] | undefined,
		categoryName?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextPromptTemplateCategory */
	["TextPromptTemplateCategory_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["TextPromptTemplateCategory_select_column"]: TextPromptTemplateCategory_select_column;
	/** input type for updating data in table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_set_input"]: {
		categoryName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["TextPromptTemplateCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextPromptTemplateCategory_stream_cursor_value_input"]: {
		categoryName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	["TextPromptTemplateCategory_update_column"]: TextPromptTemplateCategory_update_column;
	["TextPromptTemplateCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["TextPromptTemplateCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["TextPromptTemplateCategory_bool_exp"]
	};
	/** aggregated selection of "TextPromptTemplate" */
	["TextPromptTemplate_aggregate"]: {
		aggregate?: ModelTypes["TextPromptTemplate_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["TextPromptTemplate"]>
	};
	["TextPromptTemplate_aggregate_bool_exp"]: {
		bool_and?: ModelTypes["TextPromptTemplate_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: ModelTypes["TextPromptTemplate_aggregate_bool_exp_bool_or"] | undefined,
		count?: ModelTypes["TextPromptTemplate_aggregate_bool_exp_count"] | undefined
	};
	["TextPromptTemplate_aggregate_bool_exp_bool_and"]: {
		arguments: ModelTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["TextPromptTemplate_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["TextPromptTemplate_aggregate_bool_exp_bool_or"]: {
		arguments: ModelTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["TextPromptTemplate_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["TextPromptTemplate_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["TextPromptTemplate_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["TextPromptTemplate_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "TextPromptTemplate" */
	["TextPromptTemplate_aggregate_fields"]: {
		avg?: ModelTypes["TextPromptTemplate_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["TextPromptTemplate_max_fields"] | undefined,
		min?: ModelTypes["TextPromptTemplate_min_fields"] | undefined,
		stddev?: ModelTypes["TextPromptTemplate_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["TextPromptTemplate_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["TextPromptTemplate_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["TextPromptTemplate_sum_fields"] | undefined,
		var_pop?: ModelTypes["TextPromptTemplate_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["TextPromptTemplate_var_samp_fields"] | undefined,
		variance?: ModelTypes["TextPromptTemplate_variance_fields"] | undefined
	};
	/** order by aggregate values of table "TextPromptTemplate" */
	["TextPromptTemplate_aggregate_order_by"]: {
		avg?: ModelTypes["TextPromptTemplate_avg_order_by"] | undefined,
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["TextPromptTemplate_max_order_by"] | undefined,
		min?: ModelTypes["TextPromptTemplate_min_order_by"] | undefined,
		stddev?: ModelTypes["TextPromptTemplate_stddev_order_by"] | undefined,
		stddev_pop?: ModelTypes["TextPromptTemplate_stddev_pop_order_by"] | undefined,
		stddev_samp?: ModelTypes["TextPromptTemplate_stddev_samp_order_by"] | undefined,
		sum?: ModelTypes["TextPromptTemplate_sum_order_by"] | undefined,
		var_pop?: ModelTypes["TextPromptTemplate_var_pop_order_by"] | undefined,
		var_samp?: ModelTypes["TextPromptTemplate_var_samp_order_by"] | undefined,
		variance?: ModelTypes["TextPromptTemplate_variance_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextPromptTemplate_append_input"]: {
		definitions?: ModelTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "TextPromptTemplate" */
	["TextPromptTemplate_arr_rel_insert_input"]: {
		data: Array<ModelTypes["TextPromptTemplate_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["TextPromptTemplate_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["TextPromptTemplate_avg_fields"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by avg() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_avg_order_by"]: {
		creditPrice?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextPromptTemplate". All fields are combined with a logical 'AND'. */
	["TextPromptTemplate_bool_exp"]: {
		GeneratedPromptByTemplates?: ModelTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		GeneratedPromptByTemplates_aggregate?: ModelTypes["GeneratedPromptByTemplate_aggregate_bool_exp"] | undefined,
		TextPromptTemplateCategory?: ModelTypes["TextPromptTemplateCategory_bool_exp"] | undefined,
		TextTemplateClientCategory?: ModelTypes["TextTemplateClientCategory_bool_exp"] | undefined,
		TextTemplateClientCategoryId?: ModelTypes["uuid_comparison_exp"] | undefined,
		_and?: Array<ModelTypes["TextPromptTemplate_bool_exp"]> | undefined,
		_not?: ModelTypes["TextPromptTemplate_bool_exp"] | undefined,
		_or?: Array<ModelTypes["TextPromptTemplate_bool_exp"]> | undefined,
		availableDisplays?: ModelTypes["textTemplateDisplayFormat_bool_exp"] | undefined,
		availableDisplays_aggregate?: ModelTypes["textTemplateDisplayFormat_aggregate_bool_exp"] | undefined,
		categoryId?: ModelTypes["uuid_comparison_exp"] | undefined,
		channel?: ModelTypes["String_comparison_exp"] | undefined,
		creditPrice?: ModelTypes["Int_comparison_exp"] | undefined,
		definitions?: ModelTypes["jsonb_comparison_exp"] | undefined,
		description?: ModelTypes["String_comparison_exp"] | undefined,
		displayDimensionId?: ModelTypes["uuid_comparison_exp"] | undefined,
		flexible?: ModelTypes["Boolean_comparison_exp"] | undefined,
		icon?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		marketPriceInCredits?: ModelTypes["numeric_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined,
		newTemplateText?: ModelTypes["String_comparison_exp"] | undefined,
		saleCreditPrice?: ModelTypes["Int_comparison_exp"] | undefined
	};
	["TextPromptTemplate_constraint"]: TextPromptTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextPromptTemplate_delete_at_path_input"]: {
		definitions?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextPromptTemplate_delete_elem_input"]: {
		definitions?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextPromptTemplate_delete_key_input"]: {
		definitions?: string | undefined
	};
	/** input type for incrementing numeric columns in table "TextPromptTemplate" */
	["TextPromptTemplate_inc_input"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: ModelTypes["numeric"] | undefined,
		saleCreditPrice?: number | undefined
	};
	/** input type for inserting data into table "TextPromptTemplate" */
	["TextPromptTemplate_insert_input"]: {
		GeneratedPromptByTemplates?: ModelTypes["GeneratedPromptByTemplate_arr_rel_insert_input"] | undefined,
		TextPromptTemplateCategory?: ModelTypes["TextPromptTemplateCategory_obj_rel_insert_input"] | undefined,
		TextTemplateClientCategory?: ModelTypes["TextTemplateClientCategory_obj_rel_insert_input"] | undefined,
		TextTemplateClientCategoryId?: ModelTypes["uuid"] | undefined,
		availableDisplays?: ModelTypes["textTemplateDisplayFormat_arr_rel_insert_input"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		definitions?: ModelTypes["jsonb"] | undefined,
		description?: string | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		flexible?: boolean | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		marketPriceInCredits?: ModelTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate max on columns */
	["TextPromptTemplate_max_fields"]: {
		TextTemplateClientCategoryId?: ModelTypes["uuid"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		marketPriceInCredits?: ModelTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by max() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_max_order_by"]: {
		TextTemplateClientCategoryId?: ModelTypes["order_by"] | undefined,
		categoryId?: ModelTypes["order_by"] | undefined,
		channel?: ModelTypes["order_by"] | undefined,
		creditPrice?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		displayDimensionId?: ModelTypes["order_by"] | undefined,
		icon?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		newTemplateText?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["TextPromptTemplate_min_fields"]: {
		TextTemplateClientCategoryId?: ModelTypes["uuid"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		marketPriceInCredits?: ModelTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by min() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_min_order_by"]: {
		TextTemplateClientCategoryId?: ModelTypes["order_by"] | undefined,
		categoryId?: ModelTypes["order_by"] | undefined,
		channel?: ModelTypes["order_by"] | undefined,
		creditPrice?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		displayDimensionId?: ModelTypes["order_by"] | undefined,
		icon?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		newTemplateText?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "TextPromptTemplate" */
	["TextPromptTemplate_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["TextPromptTemplate"]>
	};
	/** input type for inserting object relation for remote table "TextPromptTemplate" */
	["TextPromptTemplate_obj_rel_insert_input"]: {
		data: ModelTypes["TextPromptTemplate_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["TextPromptTemplate_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "TextPromptTemplate" */
	["TextPromptTemplate_on_conflict"]: {
		constraint: ModelTypes["TextPromptTemplate_constraint"],
		update_columns: Array<ModelTypes["TextPromptTemplate_update_column"]>,
		where?: ModelTypes["TextPromptTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextPromptTemplate". */
	["TextPromptTemplate_order_by"]: {
		GeneratedPromptByTemplates_aggregate?: ModelTypes["GeneratedPromptByTemplate_aggregate_order_by"] | undefined,
		TextPromptTemplateCategory?: ModelTypes["TextPromptTemplateCategory_order_by"] | undefined,
		TextTemplateClientCategory?: ModelTypes["TextTemplateClientCategory_order_by"] | undefined,
		TextTemplateClientCategoryId?: ModelTypes["order_by"] | undefined,
		availableDisplays_aggregate?: ModelTypes["textTemplateDisplayFormat_aggregate_order_by"] | undefined,
		categoryId?: ModelTypes["order_by"] | undefined,
		channel?: ModelTypes["order_by"] | undefined,
		creditPrice?: ModelTypes["order_by"] | undefined,
		definitions?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		displayDimensionId?: ModelTypes["order_by"] | undefined,
		flexible?: ModelTypes["order_by"] | undefined,
		icon?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		newTemplateText?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextPromptTemplate */
	["TextPromptTemplate_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextPromptTemplate_prepend_input"]: {
		definitions?: ModelTypes["jsonb"] | undefined
	};
	["TextPromptTemplate_select_column"]: TextPromptTemplate_select_column;
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns;
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "TextPromptTemplate" */
	["TextPromptTemplate_set_input"]: {
		TextTemplateClientCategoryId?: ModelTypes["uuid"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		definitions?: ModelTypes["jsonb"] | undefined,
		description?: string | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		flexible?: boolean | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		marketPriceInCredits?: ModelTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate stddev on columns */
	["TextPromptTemplate_stddev_fields"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by stddev() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_order_by"]: {
		creditPrice?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["TextPromptTemplate_stddev_pop_fields"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by stddev_pop() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_pop_order_by"]: {
		creditPrice?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["TextPromptTemplate_stddev_samp_fields"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by stddev_samp() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_samp_order_by"]: {
		creditPrice?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "TextPromptTemplate" */
	["TextPromptTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["TextPromptTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextPromptTemplate_stream_cursor_value_input"]: {
		TextTemplateClientCategoryId?: ModelTypes["uuid"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		definitions?: ModelTypes["jsonb"] | undefined,
		description?: string | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		flexible?: boolean | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		marketPriceInCredits?: ModelTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate sum on columns */
	["TextPromptTemplate_sum_fields"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: ModelTypes["numeric"] | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by sum() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_sum_order_by"]: {
		creditPrice?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	["TextPromptTemplate_update_column"]: TextPromptTemplate_update_column;
	["TextPromptTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["TextPromptTemplate_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["TextPromptTemplate_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["TextPromptTemplate_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["TextPromptTemplate_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["TextPromptTemplate_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["TextPromptTemplate_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["TextPromptTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["TextPromptTemplate_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["TextPromptTemplate_var_pop_fields"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by var_pop() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_var_pop_order_by"]: {
		creditPrice?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["TextPromptTemplate_var_samp_fields"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by var_samp() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_var_samp_order_by"]: {
		creditPrice?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["TextPromptTemplate_variance_fields"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by variance() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_variance_order_by"]: {
		creditPrice?: ModelTypes["order_by"] | undefined,
		marketPriceInCredits?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined
	};
	/** columns and relationships of "TextTemplateClientCategory" */
	["TextTemplateClientCategory"]: {
		displayName: string,
		iconClass: string,
		id: ModelTypes["uuid"]
	};
	/** aggregated selection of "TextTemplateClientCategory" */
	["TextTemplateClientCategory_aggregate"]: {
		aggregate?: ModelTypes["TextTemplateClientCategory_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["TextTemplateClientCategory"]>
	};
	/** aggregate fields of "TextTemplateClientCategory" */
	["TextTemplateClientCategory_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["TextTemplateClientCategory_max_fields"] | undefined,
		min?: ModelTypes["TextTemplateClientCategory_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextTemplateClientCategory". All fields are combined with a logical 'AND'. */
	["TextTemplateClientCategory_bool_exp"]: {
		_and?: Array<ModelTypes["TextTemplateClientCategory_bool_exp"]> | undefined,
		_not?: ModelTypes["TextTemplateClientCategory_bool_exp"] | undefined,
		_or?: Array<ModelTypes["TextTemplateClientCategory_bool_exp"]> | undefined,
		displayName?: ModelTypes["String_comparison_exp"] | undefined,
		iconClass?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["TextTemplateClientCategory_constraint"]: TextTemplateClientCategory_constraint;
	/** input type for inserting data into table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_insert_input"]: {
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["TextTemplateClientCategory_max_fields"]: {
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["TextTemplateClientCategory_min_fields"]: {
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["TextTemplateClientCategory"]>
	};
	/** input type for inserting object relation for remote table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_obj_rel_insert_input"]: {
		data: ModelTypes["TextTemplateClientCategory_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["TextTemplateClientCategory_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_on_conflict"]: {
		constraint: ModelTypes["TextTemplateClientCategory_constraint"],
		update_columns: Array<ModelTypes["TextTemplateClientCategory_update_column"]>,
		where?: ModelTypes["TextTemplateClientCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextTemplateClientCategory". */
	["TextTemplateClientCategory_order_by"]: {
		displayName?: ModelTypes["order_by"] | undefined,
		iconClass?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextTemplateClientCategory */
	["TextTemplateClientCategory_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["TextTemplateClientCategory_select_column"]: TextTemplateClientCategory_select_column;
	/** input type for updating data in table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_set_input"]: {
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["TextTemplateClientCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextTemplateClientCategory_stream_cursor_value_input"]: {
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	["TextTemplateClientCategory_update_column"]: TextTemplateClientCategory_update_column;
	["TextTemplateClientCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["TextTemplateClientCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["TextTemplateClientCategory_bool_exp"]
	};
	["UpdateUserAvatarOutput"]: {
		message?: string | undefined,
		photoURL?: string | undefined,
		success: boolean
	};
	["UpsertAudienceOutput"]: {
		brandName?: string | undefined,
		id: string,
		personas?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined
	};
	/** columns and relationships of "audiences" */
	["audiences"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		brandName: string,
		created_at: ModelTypes["timestamp"],
		created_by: ModelTypes["uuid"],
		deleted: boolean,
		id: ModelTypes["uuid"],
		personas?: ModelTypes["jsonb"] | undefined,
		productOrService: string,
		segment: string,
		targetAudience: string,
		updated_at: ModelTypes["timestamptz"]
	};
	/** aggregated selection of "audiences" */
	["audiences_aggregate"]: {
		aggregate?: ModelTypes["audiences_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["audiences"]>
	};
	/** aggregate fields of "audiences" */
	["audiences_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["audiences_max_fields"] | undefined,
		min?: ModelTypes["audiences_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["audiences_append_input"]: {
		personas?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "audiences". All fields are combined with a logical 'AND'. */
	["audiences_bool_exp"]: {
		_and?: Array<ModelTypes["audiences_bool_exp"]> | undefined,
		_not?: ModelTypes["audiences_bool_exp"] | undefined,
		_or?: Array<ModelTypes["audiences_bool_exp"]> | undefined,
		brandId?: ModelTypes["uuid_comparison_exp"] | undefined,
		brandName?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamp_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		deleted?: ModelTypes["Boolean_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		personas?: ModelTypes["jsonb_comparison_exp"] | undefined,
		productOrService?: ModelTypes["String_comparison_exp"] | undefined,
		segment?: ModelTypes["String_comparison_exp"] | undefined,
		targetAudience?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["audiences_constraint"]: audiences_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["audiences_delete_at_path_input"]: {
		personas?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["audiences_delete_elem_input"]: {
		personas?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["audiences_delete_key_input"]: {
		personas?: string | undefined
	};
	/** input type for inserting data into table "audiences" */
	["audiences_insert_input"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		personas?: ModelTypes["jsonb"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["audiences_max_fields"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["audiences_min_fields"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "audiences" */
	["audiences_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["audiences"]>
	};
	/** on_conflict condition type for table "audiences" */
	["audiences_on_conflict"]: {
		constraint: ModelTypes["audiences_constraint"],
		update_columns: Array<ModelTypes["audiences_update_column"]>,
		where?: ModelTypes["audiences_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "audiences". */
	["audiences_order_by"]: {
		brandId?: ModelTypes["order_by"] | undefined,
		brandName?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		deleted?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		personas?: ModelTypes["order_by"] | undefined,
		productOrService?: ModelTypes["order_by"] | undefined,
		segment?: ModelTypes["order_by"] | undefined,
		targetAudience?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: audiences */
	["audiences_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["audiences_prepend_input"]: {
		personas?: ModelTypes["jsonb"] | undefined
	};
	["audiences_select_column"]: audiences_select_column;
	/** input type for updating data in table "audiences" */
	["audiences_set_input"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		personas?: ModelTypes["jsonb"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "audiences" */
	["audiences_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["audiences_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["audiences_stream_cursor_value_input"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		personas?: ModelTypes["jsonb"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["audiences_update_column"]: audiences_update_column;
	["audiences_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["audiences_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["audiences_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["audiences_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["audiences_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["audiences_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["audiences_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["audiences_bool_exp"]
	};
	/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authProviderRequests"]: {
		id: ModelTypes["uuid"],
		options?: ModelTypes["jsonb"] | undefined
	};
	/** aggregated selection of "auth.provider_requests" */
	["authProviderRequests_aggregate"]: {
		aggregate?: ModelTypes["authProviderRequests_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["authProviderRequests"]>
	};
	/** aggregate fields of "auth.provider_requests" */
	["authProviderRequests_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["authProviderRequests_max_fields"] | undefined,
		min?: ModelTypes["authProviderRequests_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["authProviderRequests_append_input"]: {
		options?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
	["authProviderRequests_bool_exp"]: {
		_and?: Array<ModelTypes["authProviderRequests_bool_exp"]> | undefined,
		_not?: ModelTypes["authProviderRequests_bool_exp"] | undefined,
		_or?: Array<ModelTypes["authProviderRequests_bool_exp"]> | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		options?: ModelTypes["jsonb_comparison_exp"] | undefined
	};
	["authProviderRequests_constraint"]: authProviderRequests_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["authProviderRequests_delete_at_path_input"]: {
		options?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["authProviderRequests_delete_elem_input"]: {
		options?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["authProviderRequests_delete_key_input"]: {
		options?: string | undefined
	};
	/** input type for inserting data into table "auth.provider_requests" */
	["authProviderRequests_insert_input"]: {
		id?: ModelTypes["uuid"] | undefined,
		options?: ModelTypes["jsonb"] | undefined
	};
	/** aggregate max on columns */
	["authProviderRequests_max_fields"]: {
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["authProviderRequests_min_fields"]: {
		id?: ModelTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "auth.provider_requests" */
	["authProviderRequests_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["authProviderRequests"]>
	};
	/** on_conflict condition type for table "auth.provider_requests" */
	["authProviderRequests_on_conflict"]: {
		constraint: ModelTypes["authProviderRequests_constraint"],
		update_columns: Array<ModelTypes["authProviderRequests_update_column"]>,
		where?: ModelTypes["authProviderRequests_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.provider_requests". */
	["authProviderRequests_order_by"]: {
		id?: ModelTypes["order_by"] | undefined,
		options?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.provider_requests */
	["authProviderRequests_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["authProviderRequests_prepend_input"]: {
		options?: ModelTypes["jsonb"] | undefined
	};
	["authProviderRequests_select_column"]: authProviderRequests_select_column;
	/** input type for updating data in table "auth.provider_requests" */
	["authProviderRequests_set_input"]: {
		id?: ModelTypes["uuid"] | undefined,
		options?: ModelTypes["jsonb"] | undefined
	};
	/** Streaming cursor of the table "authProviderRequests" */
	["authProviderRequests_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["authProviderRequests_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authProviderRequests_stream_cursor_value_input"]: {
		id?: ModelTypes["uuid"] | undefined,
		options?: ModelTypes["jsonb"] | undefined
	};
	["authProviderRequests_update_column"]: authProviderRequests_update_column;
	["authProviderRequests_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["authProviderRequests_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["authProviderRequests_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["authProviderRequests_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["authProviderRequests_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["authProviderRequests_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["authProviderRequests_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["authProviderRequests_bool_exp"]
	};
	/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authProviders"]: {
		id: string,
		/** An array relationship */
		userProviders: Array<ModelTypes["authUserProviders"]>,
		/** An aggregate relationship */
		userProviders_aggregate: ModelTypes["authUserProviders_aggregate"]
	};
	/** aggregated selection of "auth.providers" */
	["authProviders_aggregate"]: {
		aggregate?: ModelTypes["authProviders_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["authProviders"]>
	};
	/** aggregate fields of "auth.providers" */
	["authProviders_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["authProviders_max_fields"] | undefined,
		min?: ModelTypes["authProviders_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
	["authProviders_bool_exp"]: {
		_and?: Array<ModelTypes["authProviders_bool_exp"]> | undefined,
		_not?: ModelTypes["authProviders_bool_exp"] | undefined,
		_or?: Array<ModelTypes["authProviders_bool_exp"]> | undefined,
		id?: ModelTypes["String_comparison_exp"] | undefined,
		userProviders?: ModelTypes["authUserProviders_bool_exp"] | undefined,
		userProviders_aggregate?: ModelTypes["authUserProviders_aggregate_bool_exp"] | undefined
	};
	["authProviders_constraint"]: authProviders_constraint;
	/** input type for inserting data into table "auth.providers" */
	["authProviders_insert_input"]: {
		id?: string | undefined,
		userProviders?: ModelTypes["authUserProviders_arr_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["authProviders_max_fields"]: {
		id?: string | undefined
	};
	/** aggregate min on columns */
	["authProviders_min_fields"]: {
		id?: string | undefined
	};
	/** response of any mutation on the table "auth.providers" */
	["authProviders_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["authProviders"]>
	};
	/** input type for inserting object relation for remote table "auth.providers" */
	["authProviders_obj_rel_insert_input"]: {
		data: ModelTypes["authProviders_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["authProviders_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "auth.providers" */
	["authProviders_on_conflict"]: {
		constraint: ModelTypes["authProviders_constraint"],
		update_columns: Array<ModelTypes["authProviders_update_column"]>,
		where?: ModelTypes["authProviders_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.providers". */
	["authProviders_order_by"]: {
		id?: ModelTypes["order_by"] | undefined,
		userProviders_aggregate?: ModelTypes["authUserProviders_aggregate_order_by"] | undefined
	};
	/** primary key columns input for table: auth.providers */
	["authProviders_pk_columns_input"]: {
		id: string
	};
	["authProviders_select_column"]: authProviders_select_column;
	/** input type for updating data in table "auth.providers" */
	["authProviders_set_input"]: {
		id?: string | undefined
	};
	/** Streaming cursor of the table "authProviders" */
	["authProviders_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["authProviders_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authProviders_stream_cursor_value_input"]: {
		id?: string | undefined
	};
	["authProviders_update_column"]: authProviders_update_column;
	["authProviders_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["authProviders_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["authProviders_bool_exp"]
	};
	/** columns and relationships of "auth.refresh_token_types" */
	["authRefreshTokenTypes"]: {
		comment?: string | undefined,
		/** An array relationship */
		refreshTokens: Array<ModelTypes["authRefreshTokens"]>,
		/** An aggregate relationship */
		refreshTokens_aggregate: ModelTypes["authRefreshTokens_aggregate"],
		value: string
	};
	/** aggregated selection of "auth.refresh_token_types" */
	["authRefreshTokenTypes_aggregate"]: {
		aggregate?: ModelTypes["authRefreshTokenTypes_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["authRefreshTokenTypes"]>
	};
	/** aggregate fields of "auth.refresh_token_types" */
	["authRefreshTokenTypes_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["authRefreshTokenTypes_max_fields"] | undefined,
		min?: ModelTypes["authRefreshTokenTypes_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
	["authRefreshTokenTypes_bool_exp"]: {
		_and?: Array<ModelTypes["authRefreshTokenTypes_bool_exp"]> | undefined,
		_not?: ModelTypes["authRefreshTokenTypes_bool_exp"] | undefined,
		_or?: Array<ModelTypes["authRefreshTokenTypes_bool_exp"]> | undefined,
		comment?: ModelTypes["String_comparison_exp"] | undefined,
		refreshTokens?: ModelTypes["authRefreshTokens_bool_exp"] | undefined,
		refreshTokens_aggregate?: ModelTypes["authRefreshTokens_aggregate_bool_exp"] | undefined,
		value?: ModelTypes["String_comparison_exp"] | undefined
	};
	["authRefreshTokenTypes_constraint"]: authRefreshTokenTypes_constraint;
	["authRefreshTokenTypes_enum"]: authRefreshTokenTypes_enum;
	/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
	["authRefreshTokenTypes_enum_comparison_exp"]: {
		_eq?: ModelTypes["authRefreshTokenTypes_enum"] | undefined,
		_in?: Array<ModelTypes["authRefreshTokenTypes_enum"]> | undefined,
		_is_null?: boolean | undefined,
		_neq?: ModelTypes["authRefreshTokenTypes_enum"] | undefined,
		_nin?: Array<ModelTypes["authRefreshTokenTypes_enum"]> | undefined
	};
	/** input type for inserting data into table "auth.refresh_token_types" */
	["authRefreshTokenTypes_insert_input"]: {
		comment?: string | undefined,
		refreshTokens?: ModelTypes["authRefreshTokens_arr_rel_insert_input"] | undefined,
		value?: string | undefined
	};
	/** aggregate max on columns */
	["authRefreshTokenTypes_max_fields"]: {
		comment?: string | undefined,
		value?: string | undefined
	};
	/** aggregate min on columns */
	["authRefreshTokenTypes_min_fields"]: {
		comment?: string | undefined,
		value?: string | undefined
	};
	/** response of any mutation on the table "auth.refresh_token_types" */
	["authRefreshTokenTypes_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["authRefreshTokenTypes"]>
	};
	/** on_conflict condition type for table "auth.refresh_token_types" */
	["authRefreshTokenTypes_on_conflict"]: {
		constraint: ModelTypes["authRefreshTokenTypes_constraint"],
		update_columns: Array<ModelTypes["authRefreshTokenTypes_update_column"]>,
		where?: ModelTypes["authRefreshTokenTypes_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.refresh_token_types". */
	["authRefreshTokenTypes_order_by"]: {
		comment?: ModelTypes["order_by"] | undefined,
		refreshTokens_aggregate?: ModelTypes["authRefreshTokens_aggregate_order_by"] | undefined,
		value?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.refresh_token_types */
	["authRefreshTokenTypes_pk_columns_input"]: {
		value: string
	};
	["authRefreshTokenTypes_select_column"]: authRefreshTokenTypes_select_column;
	/** input type for updating data in table "auth.refresh_token_types" */
	["authRefreshTokenTypes_set_input"]: {
		comment?: string | undefined,
		value?: string | undefined
	};
	/** Streaming cursor of the table "authRefreshTokenTypes" */
	["authRefreshTokenTypes_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["authRefreshTokenTypes_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authRefreshTokenTypes_stream_cursor_value_input"]: {
		comment?: string | undefined,
		value?: string | undefined
	};
	["authRefreshTokenTypes_update_column"]: authRefreshTokenTypes_update_column;
	["authRefreshTokenTypes_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["authRefreshTokenTypes_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["authRefreshTokenTypes_bool_exp"]
	};
	/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authRefreshTokens"]: {
		createdAt: ModelTypes["timestamptz"],
		expiresAt: ModelTypes["timestamptz"],
		id: ModelTypes["uuid"],
		metadata?: ModelTypes["jsonb"] | undefined,
		refreshTokenHash?: string | undefined,
		type: ModelTypes["authRefreshTokenTypes_enum"],
		/** An object relationship */
		user: ModelTypes["users"],
		userId: ModelTypes["uuid"]
	};
	/** aggregated selection of "auth.refresh_tokens" */
	["authRefreshTokens_aggregate"]: {
		aggregate?: ModelTypes["authRefreshTokens_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["authRefreshTokens"]>
	};
	["authRefreshTokens_aggregate_bool_exp"]: {
		count?: ModelTypes["authRefreshTokens_aggregate_bool_exp_count"] | undefined
	};
	["authRefreshTokens_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["authRefreshTokens_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["authRefreshTokens_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.refresh_tokens" */
	["authRefreshTokens_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["authRefreshTokens_max_fields"] | undefined,
		min?: ModelTypes["authRefreshTokens_min_fields"] | undefined
	};
	/** order by aggregate values of table "auth.refresh_tokens" */
	["authRefreshTokens_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["authRefreshTokens_max_order_by"] | undefined,
		min?: ModelTypes["authRefreshTokens_min_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["authRefreshTokens_append_input"]: {
		metadata?: ModelTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.refresh_tokens" */
	["authRefreshTokens_arr_rel_insert_input"]: {
		data: Array<ModelTypes["authRefreshTokens_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["authRefreshTokens_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
	["authRefreshTokens_bool_exp"]: {
		_and?: Array<ModelTypes["authRefreshTokens_bool_exp"]> | undefined,
		_not?: ModelTypes["authRefreshTokens_bool_exp"] | undefined,
		_or?: Array<ModelTypes["authRefreshTokens_bool_exp"]> | undefined,
		createdAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		expiresAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		metadata?: ModelTypes["jsonb_comparison_exp"] | undefined,
		refreshTokenHash?: ModelTypes["String_comparison_exp"] | undefined,
		type?: ModelTypes["authRefreshTokenTypes_enum_comparison_exp"] | undefined,
		user?: ModelTypes["users_bool_exp"] | undefined,
		userId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["authRefreshTokens_constraint"]: authRefreshTokens_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["authRefreshTokens_delete_at_path_input"]: {
		metadata?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["authRefreshTokens_delete_elem_input"]: {
		metadata?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["authRefreshTokens_delete_key_input"]: {
		metadata?: string | undefined
	};
	/** input type for inserting data into table "auth.refresh_tokens" */
	["authRefreshTokens_insert_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		expiresAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		refreshTokenHash?: string | undefined,
		type?: ModelTypes["authRefreshTokenTypes_enum"] | undefined,
		user?: ModelTypes["users_obj_rel_insert_input"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["authRefreshTokens_max_fields"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		expiresAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		refreshTokenHash?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "auth.refresh_tokens" */
	["authRefreshTokens_max_order_by"]: {
		createdAt?: ModelTypes["order_by"] | undefined,
		expiresAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		refreshTokenHash?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["authRefreshTokens_min_fields"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		expiresAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		refreshTokenHash?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "auth.refresh_tokens" */
	["authRefreshTokens_min_order_by"]: {
		createdAt?: ModelTypes["order_by"] | undefined,
		expiresAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		refreshTokenHash?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.refresh_tokens" */
	["authRefreshTokens_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["authRefreshTokens"]>
	};
	/** on_conflict condition type for table "auth.refresh_tokens" */
	["authRefreshTokens_on_conflict"]: {
		constraint: ModelTypes["authRefreshTokens_constraint"],
		update_columns: Array<ModelTypes["authRefreshTokens_update_column"]>,
		where?: ModelTypes["authRefreshTokens_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.refresh_tokens". */
	["authRefreshTokens_order_by"]: {
		createdAt?: ModelTypes["order_by"] | undefined,
		expiresAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		metadata?: ModelTypes["order_by"] | undefined,
		refreshTokenHash?: ModelTypes["order_by"] | undefined,
		type?: ModelTypes["order_by"] | undefined,
		user?: ModelTypes["users_order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.refresh_tokens */
	["authRefreshTokens_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["authRefreshTokens_prepend_input"]: {
		metadata?: ModelTypes["jsonb"] | undefined
	};
	["authRefreshTokens_select_column"]: authRefreshTokens_select_column;
	/** input type for updating data in table "auth.refresh_tokens" */
	["authRefreshTokens_set_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		expiresAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		refreshTokenHash?: string | undefined,
		type?: ModelTypes["authRefreshTokenTypes_enum"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "authRefreshTokens" */
	["authRefreshTokens_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["authRefreshTokens_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authRefreshTokens_stream_cursor_value_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		expiresAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		refreshTokenHash?: string | undefined,
		type?: ModelTypes["authRefreshTokenTypes_enum"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	["authRefreshTokens_update_column"]: authRefreshTokens_update_column;
	["authRefreshTokens_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["authRefreshTokens_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["authRefreshTokens_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["authRefreshTokens_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["authRefreshTokens_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["authRefreshTokens_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["authRefreshTokens_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["authRefreshTokens_bool_exp"]
	};
	/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authRoles"]: {
		role: string,
		/** An array relationship */
		userRoles: Array<ModelTypes["authUserRoles"]>,
		/** An aggregate relationship */
		userRoles_aggregate: ModelTypes["authUserRoles_aggregate"],
		/** An array relationship */
		usersByDefaultRole: Array<ModelTypes["users"]>,
		/** An aggregate relationship */
		usersByDefaultRole_aggregate: ModelTypes["users_aggregate"]
	};
	/** aggregated selection of "auth.roles" */
	["authRoles_aggregate"]: {
		aggregate?: ModelTypes["authRoles_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["authRoles"]>
	};
	/** aggregate fields of "auth.roles" */
	["authRoles_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["authRoles_max_fields"] | undefined,
		min?: ModelTypes["authRoles_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
	["authRoles_bool_exp"]: {
		_and?: Array<ModelTypes["authRoles_bool_exp"]> | undefined,
		_not?: ModelTypes["authRoles_bool_exp"] | undefined,
		_or?: Array<ModelTypes["authRoles_bool_exp"]> | undefined,
		role?: ModelTypes["String_comparison_exp"] | undefined,
		userRoles?: ModelTypes["authUserRoles_bool_exp"] | undefined,
		userRoles_aggregate?: ModelTypes["authUserRoles_aggregate_bool_exp"] | undefined,
		usersByDefaultRole?: ModelTypes["users_bool_exp"] | undefined,
		usersByDefaultRole_aggregate?: ModelTypes["users_aggregate_bool_exp"] | undefined
	};
	["authRoles_constraint"]: authRoles_constraint;
	/** input type for inserting data into table "auth.roles" */
	["authRoles_insert_input"]: {
		role?: string | undefined,
		userRoles?: ModelTypes["authUserRoles_arr_rel_insert_input"] | undefined,
		usersByDefaultRole?: ModelTypes["users_arr_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["authRoles_max_fields"]: {
		role?: string | undefined
	};
	/** aggregate min on columns */
	["authRoles_min_fields"]: {
		role?: string | undefined
	};
	/** response of any mutation on the table "auth.roles" */
	["authRoles_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["authRoles"]>
	};
	/** input type for inserting object relation for remote table "auth.roles" */
	["authRoles_obj_rel_insert_input"]: {
		data: ModelTypes["authRoles_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["authRoles_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "auth.roles" */
	["authRoles_on_conflict"]: {
		constraint: ModelTypes["authRoles_constraint"],
		update_columns: Array<ModelTypes["authRoles_update_column"]>,
		where?: ModelTypes["authRoles_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.roles". */
	["authRoles_order_by"]: {
		role?: ModelTypes["order_by"] | undefined,
		userRoles_aggregate?: ModelTypes["authUserRoles_aggregate_order_by"] | undefined,
		usersByDefaultRole_aggregate?: ModelTypes["users_aggregate_order_by"] | undefined
	};
	/** primary key columns input for table: auth.roles */
	["authRoles_pk_columns_input"]: {
		role: string
	};
	["authRoles_select_column"]: authRoles_select_column;
	/** input type for updating data in table "auth.roles" */
	["authRoles_set_input"]: {
		role?: string | undefined
	};
	/** Streaming cursor of the table "authRoles" */
	["authRoles_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["authRoles_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authRoles_stream_cursor_value_input"]: {
		role?: string | undefined
	};
	["authRoles_update_column"]: authRoles_update_column;
	["authRoles_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["authRoles_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["authRoles_bool_exp"]
	};
	/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserProviders"]: {
		accessToken: string,
		createdAt: ModelTypes["timestamptz"],
		id: ModelTypes["uuid"],
		/** An object relationship */
		provider: ModelTypes["authProviders"],
		providerId: string,
		providerUserId: string,
		refreshToken?: string | undefined,
		updatedAt: ModelTypes["timestamptz"],
		/** An object relationship */
		user: ModelTypes["users"],
		userId: ModelTypes["uuid"]
	};
	/** aggregated selection of "auth.user_providers" */
	["authUserProviders_aggregate"]: {
		aggregate?: ModelTypes["authUserProviders_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["authUserProviders"]>
	};
	["authUserProviders_aggregate_bool_exp"]: {
		count?: ModelTypes["authUserProviders_aggregate_bool_exp_count"] | undefined
	};
	["authUserProviders_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["authUserProviders_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["authUserProviders_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_providers" */
	["authUserProviders_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["authUserProviders_max_fields"] | undefined,
		min?: ModelTypes["authUserProviders_min_fields"] | undefined
	};
	/** order by aggregate values of table "auth.user_providers" */
	["authUserProviders_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["authUserProviders_max_order_by"] | undefined,
		min?: ModelTypes["authUserProviders_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.user_providers" */
	["authUserProviders_arr_rel_insert_input"]: {
		data: Array<ModelTypes["authUserProviders_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["authUserProviders_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
	["authUserProviders_bool_exp"]: {
		_and?: Array<ModelTypes["authUserProviders_bool_exp"]> | undefined,
		_not?: ModelTypes["authUserProviders_bool_exp"] | undefined,
		_or?: Array<ModelTypes["authUserProviders_bool_exp"]> | undefined,
		accessToken?: ModelTypes["String_comparison_exp"] | undefined,
		createdAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		provider?: ModelTypes["authProviders_bool_exp"] | undefined,
		providerId?: ModelTypes["String_comparison_exp"] | undefined,
		providerUserId?: ModelTypes["String_comparison_exp"] | undefined,
		refreshToken?: ModelTypes["String_comparison_exp"] | undefined,
		updatedAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		user?: ModelTypes["users_bool_exp"] | undefined,
		userId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["authUserProviders_constraint"]: authUserProviders_constraint;
	/** input type for inserting data into table "auth.user_providers" */
	["authUserProviders_insert_input"]: {
		accessToken?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		provider?: ModelTypes["authProviders_obj_rel_insert_input"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		user?: ModelTypes["users_obj_rel_insert_input"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["authUserProviders_max_fields"]: {
		accessToken?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "auth.user_providers" */
	["authUserProviders_max_order_by"]: {
		accessToken?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		providerId?: ModelTypes["order_by"] | undefined,
		providerUserId?: ModelTypes["order_by"] | undefined,
		refreshToken?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["authUserProviders_min_fields"]: {
		accessToken?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "auth.user_providers" */
	["authUserProviders_min_order_by"]: {
		accessToken?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		providerId?: ModelTypes["order_by"] | undefined,
		providerUserId?: ModelTypes["order_by"] | undefined,
		refreshToken?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.user_providers" */
	["authUserProviders_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["authUserProviders"]>
	};
	/** on_conflict condition type for table "auth.user_providers" */
	["authUserProviders_on_conflict"]: {
		constraint: ModelTypes["authUserProviders_constraint"],
		update_columns: Array<ModelTypes["authUserProviders_update_column"]>,
		where?: ModelTypes["authUserProviders_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.user_providers". */
	["authUserProviders_order_by"]: {
		accessToken?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		provider?: ModelTypes["authProviders_order_by"] | undefined,
		providerId?: ModelTypes["order_by"] | undefined,
		providerUserId?: ModelTypes["order_by"] | undefined,
		refreshToken?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined,
		user?: ModelTypes["users_order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.user_providers */
	["authUserProviders_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["authUserProviders_select_column"]: authUserProviders_select_column;
	/** input type for updating data in table "auth.user_providers" */
	["authUserProviders_set_input"]: {
		accessToken?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "authUserProviders" */
	["authUserProviders_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["authUserProviders_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authUserProviders_stream_cursor_value_input"]: {
		accessToken?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	["authUserProviders_update_column"]: authUserProviders_update_column;
	["authUserProviders_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["authUserProviders_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["authUserProviders_bool_exp"]
	};
	/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserRoles"]: {
		createdAt: ModelTypes["timestamptz"],
		id: ModelTypes["uuid"],
		role: string,
		/** An object relationship */
		roleByRole: ModelTypes["authRoles"],
		/** An object relationship */
		user: ModelTypes["users"],
		userId: ModelTypes["uuid"]
	};
	/** aggregated selection of "auth.user_roles" */
	["authUserRoles_aggregate"]: {
		aggregate?: ModelTypes["authUserRoles_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["authUserRoles"]>
	};
	["authUserRoles_aggregate_bool_exp"]: {
		count?: ModelTypes["authUserRoles_aggregate_bool_exp_count"] | undefined
	};
	["authUserRoles_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["authUserRoles_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["authUserRoles_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_roles" */
	["authUserRoles_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["authUserRoles_max_fields"] | undefined,
		min?: ModelTypes["authUserRoles_min_fields"] | undefined
	};
	/** order by aggregate values of table "auth.user_roles" */
	["authUserRoles_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["authUserRoles_max_order_by"] | undefined,
		min?: ModelTypes["authUserRoles_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.user_roles" */
	["authUserRoles_arr_rel_insert_input"]: {
		data: Array<ModelTypes["authUserRoles_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["authUserRoles_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
	["authUserRoles_bool_exp"]: {
		_and?: Array<ModelTypes["authUserRoles_bool_exp"]> | undefined,
		_not?: ModelTypes["authUserRoles_bool_exp"] | undefined,
		_or?: Array<ModelTypes["authUserRoles_bool_exp"]> | undefined,
		createdAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		role?: ModelTypes["String_comparison_exp"] | undefined,
		roleByRole?: ModelTypes["authRoles_bool_exp"] | undefined,
		user?: ModelTypes["users_bool_exp"] | undefined,
		userId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["authUserRoles_constraint"]: authUserRoles_constraint;
	/** input type for inserting data into table "auth.user_roles" */
	["authUserRoles_insert_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		role?: string | undefined,
		roleByRole?: ModelTypes["authRoles_obj_rel_insert_input"] | undefined,
		user?: ModelTypes["users_obj_rel_insert_input"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["authUserRoles_max_fields"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		role?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "auth.user_roles" */
	["authUserRoles_max_order_by"]: {
		createdAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		role?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["authUserRoles_min_fields"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		role?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "auth.user_roles" */
	["authUserRoles_min_order_by"]: {
		createdAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		role?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.user_roles" */
	["authUserRoles_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["authUserRoles"]>
	};
	/** on_conflict condition type for table "auth.user_roles" */
	["authUserRoles_on_conflict"]: {
		constraint: ModelTypes["authUserRoles_constraint"],
		update_columns: Array<ModelTypes["authUserRoles_update_column"]>,
		where?: ModelTypes["authUserRoles_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.user_roles". */
	["authUserRoles_order_by"]: {
		createdAt?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		role?: ModelTypes["order_by"] | undefined,
		roleByRole?: ModelTypes["authRoles_order_by"] | undefined,
		user?: ModelTypes["users_order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.user_roles */
	["authUserRoles_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["authUserRoles_select_column"]: authUserRoles_select_column;
	/** input type for updating data in table "auth.user_roles" */
	["authUserRoles_set_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		role?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "authUserRoles" */
	["authUserRoles_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["authUserRoles_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authUserRoles_stream_cursor_value_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		role?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	["authUserRoles_update_column"]: authUserRoles_update_column;
	["authUserRoles_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["authUserRoles_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["authUserRoles_bool_exp"]
	};
	/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserSecurityKeys"]: {
		counter: ModelTypes["bigint"],
		credentialId: string,
		credentialPublicKey?: ModelTypes["bytea"] | undefined,
		id: ModelTypes["uuid"],
		nickname?: string | undefined,
		transports: string,
		/** An object relationship */
		user: ModelTypes["users"],
		userId: ModelTypes["uuid"]
	};
	/** aggregated selection of "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate"]: {
		aggregate?: ModelTypes["authUserSecurityKeys_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["authUserSecurityKeys"]>
	};
	["authUserSecurityKeys_aggregate_bool_exp"]: {
		count?: ModelTypes["authUserSecurityKeys_aggregate_bool_exp_count"] | undefined
	};
	["authUserSecurityKeys_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["authUserSecurityKeys_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["authUserSecurityKeys_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate_fields"]: {
		avg?: ModelTypes["authUserSecurityKeys_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["authUserSecurityKeys_max_fields"] | undefined,
		min?: ModelTypes["authUserSecurityKeys_min_fields"] | undefined,
		stddev?: ModelTypes["authUserSecurityKeys_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["authUserSecurityKeys_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["authUserSecurityKeys_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["authUserSecurityKeys_sum_fields"] | undefined,
		var_pop?: ModelTypes["authUserSecurityKeys_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["authUserSecurityKeys_var_samp_fields"] | undefined,
		variance?: ModelTypes["authUserSecurityKeys_variance_fields"] | undefined
	};
	/** order by aggregate values of table "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate_order_by"]: {
		avg?: ModelTypes["authUserSecurityKeys_avg_order_by"] | undefined,
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["authUserSecurityKeys_max_order_by"] | undefined,
		min?: ModelTypes["authUserSecurityKeys_min_order_by"] | undefined,
		stddev?: ModelTypes["authUserSecurityKeys_stddev_order_by"] | undefined,
		stddev_pop?: ModelTypes["authUserSecurityKeys_stddev_pop_order_by"] | undefined,
		stddev_samp?: ModelTypes["authUserSecurityKeys_stddev_samp_order_by"] | undefined,
		sum?: ModelTypes["authUserSecurityKeys_sum_order_by"] | undefined,
		var_pop?: ModelTypes["authUserSecurityKeys_var_pop_order_by"] | undefined,
		var_samp?: ModelTypes["authUserSecurityKeys_var_samp_order_by"] | undefined,
		variance?: ModelTypes["authUserSecurityKeys_variance_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.user_security_keys" */
	["authUserSecurityKeys_arr_rel_insert_input"]: {
		data: Array<ModelTypes["authUserSecurityKeys_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["authUserSecurityKeys_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["authUserSecurityKeys_avg_fields"]: {
		counter?: number | undefined
	};
	/** order by avg() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_avg_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
	["authUserSecurityKeys_bool_exp"]: {
		_and?: Array<ModelTypes["authUserSecurityKeys_bool_exp"]> | undefined,
		_not?: ModelTypes["authUserSecurityKeys_bool_exp"] | undefined,
		_or?: Array<ModelTypes["authUserSecurityKeys_bool_exp"]> | undefined,
		counter?: ModelTypes["bigint_comparison_exp"] | undefined,
		credentialId?: ModelTypes["String_comparison_exp"] | undefined,
		credentialPublicKey?: ModelTypes["bytea_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		nickname?: ModelTypes["String_comparison_exp"] | undefined,
		transports?: ModelTypes["String_comparison_exp"] | undefined,
		user?: ModelTypes["users_bool_exp"] | undefined,
		userId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["authUserSecurityKeys_constraint"]: authUserSecurityKeys_constraint;
	/** input type for incrementing numeric columns in table "auth.user_security_keys" */
	["authUserSecurityKeys_inc_input"]: {
		counter?: ModelTypes["bigint"] | undefined
	};
	/** input type for inserting data into table "auth.user_security_keys" */
	["authUserSecurityKeys_insert_input"]: {
		counter?: ModelTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		credentialPublicKey?: ModelTypes["bytea"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		user?: ModelTypes["users_obj_rel_insert_input"] | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["authUserSecurityKeys_max_fields"]: {
		counter?: ModelTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_max_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined,
		credentialId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		nickname?: ModelTypes["order_by"] | undefined,
		transports?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["authUserSecurityKeys_min_fields"]: {
		counter?: ModelTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_min_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined,
		credentialId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		nickname?: ModelTypes["order_by"] | undefined,
		transports?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.user_security_keys" */
	["authUserSecurityKeys_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["authUserSecurityKeys"]>
	};
	/** on_conflict condition type for table "auth.user_security_keys" */
	["authUserSecurityKeys_on_conflict"]: {
		constraint: ModelTypes["authUserSecurityKeys_constraint"],
		update_columns: Array<ModelTypes["authUserSecurityKeys_update_column"]>,
		where?: ModelTypes["authUserSecurityKeys_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.user_security_keys". */
	["authUserSecurityKeys_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined,
		credentialId?: ModelTypes["order_by"] | undefined,
		credentialPublicKey?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		nickname?: ModelTypes["order_by"] | undefined,
		transports?: ModelTypes["order_by"] | undefined,
		user?: ModelTypes["users_order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.user_security_keys */
	["authUserSecurityKeys_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["authUserSecurityKeys_select_column"]: authUserSecurityKeys_select_column;
	/** input type for updating data in table "auth.user_security_keys" */
	["authUserSecurityKeys_set_input"]: {
		counter?: ModelTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		credentialPublicKey?: ModelTypes["bytea"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate stddev on columns */
	["authUserSecurityKeys_stddev_fields"]: {
		counter?: number | undefined
	};
	/** order by stddev() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["authUserSecurityKeys_stddev_pop_fields"]: {
		counter?: number | undefined
	};
	/** order by stddev_pop() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_pop_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["authUserSecurityKeys_stddev_samp_fields"]: {
		counter?: number | undefined
	};
	/** order by stddev_samp() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_samp_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "authUserSecurityKeys" */
	["authUserSecurityKeys_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["authUserSecurityKeys_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authUserSecurityKeys_stream_cursor_value_input"]: {
		counter?: ModelTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		credentialPublicKey?: ModelTypes["bytea"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["authUserSecurityKeys_sum_fields"]: {
		counter?: ModelTypes["bigint"] | undefined
	};
	/** order by sum() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_sum_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined
	};
	["authUserSecurityKeys_update_column"]: authUserSecurityKeys_update_column;
	["authUserSecurityKeys_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["authUserSecurityKeys_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["authUserSecurityKeys_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["authUserSecurityKeys_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["authUserSecurityKeys_var_pop_fields"]: {
		counter?: number | undefined
	};
	/** order by var_pop() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_var_pop_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["authUserSecurityKeys_var_samp_fields"]: {
		counter?: number | undefined
	};
	/** order by var_samp() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_var_samp_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["authUserSecurityKeys_variance_fields"]: {
		counter?: number | undefined
	};
	/** order by variance() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_variance_order_by"]: {
		counter?: ModelTypes["order_by"] | undefined
	};
	["bigint"]: any;
	/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
	["bigint_comparison_exp"]: {
		_eq?: ModelTypes["bigint"] | undefined,
		_gt?: ModelTypes["bigint"] | undefined,
		_gte?: ModelTypes["bigint"] | undefined,
		_in?: Array<ModelTypes["bigint"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: ModelTypes["bigint"] | undefined,
		_lte?: ModelTypes["bigint"] | undefined,
		_neq?: ModelTypes["bigint"] | undefined,
		_nin?: Array<ModelTypes["bigint"]> | undefined
	};
	/** columns and relationships of "blog" */
	["blog"]: {
		/** An object relationship */
		brand: ModelTypes["brands"],
		brandId: ModelTypes["uuid"],
		/** An object relationship */
		category: ModelTypes["blogCategory"],
		categoryId: ModelTypes["uuid"],
		content: string,
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		featuredImageId: ModelTypes["uuid"],
		/** An object relationship */
		file: ModelTypes["files"],
		id: ModelTypes["uuid"],
		metaDescription: string,
		/** An object relationship */
		project: ModelTypes["project"],
		projectId: ModelTypes["uuid"],
		publishDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		slug: string,
		title: string,
		updated_at: ModelTypes["timestamptz"],
		/** An object relationship */
		user: ModelTypes["users"]
	};
	/** columns and relationships of "blogCategory" */
	["blogCategory"]: {
		/** An array relationship */
		blogs: Array<ModelTypes["blog"]>,
		/** An aggregate relationship */
		blogs_aggregate: ModelTypes["blog_aggregate"],
		created_at: ModelTypes["timestamptz"],
		id: ModelTypes["uuid"],
		name: string,
		updated_at: ModelTypes["timestamptz"]
	};
	/** aggregated selection of "blogCategory" */
	["blogCategory_aggregate"]: {
		aggregate?: ModelTypes["blogCategory_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["blogCategory"]>
	};
	/** aggregate fields of "blogCategory" */
	["blogCategory_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["blogCategory_max_fields"] | undefined,
		min?: ModelTypes["blogCategory_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "blogCategory". All fields are combined with a logical 'AND'. */
	["blogCategory_bool_exp"]: {
		_and?: Array<ModelTypes["blogCategory_bool_exp"]> | undefined,
		_not?: ModelTypes["blogCategory_bool_exp"] | undefined,
		_or?: Array<ModelTypes["blogCategory_bool_exp"]> | undefined,
		blogs?: ModelTypes["blog_bool_exp"] | undefined,
		blogs_aggregate?: ModelTypes["blog_aggregate_bool_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["blogCategory_constraint"]: blogCategory_constraint;
	/** input type for inserting data into table "blogCategory" */
	["blogCategory_insert_input"]: {
		blogs?: ModelTypes["blog_arr_rel_insert_input"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["blogCategory_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["blogCategory_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "blogCategory" */
	["blogCategory_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["blogCategory"]>
	};
	/** input type for inserting object relation for remote table "blogCategory" */
	["blogCategory_obj_rel_insert_input"]: {
		data: ModelTypes["blogCategory_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["blogCategory_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "blogCategory" */
	["blogCategory_on_conflict"]: {
		constraint: ModelTypes["blogCategory_constraint"],
		update_columns: Array<ModelTypes["blogCategory_update_column"]>,
		where?: ModelTypes["blogCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "blogCategory". */
	["blogCategory_order_by"]: {
		blogs_aggregate?: ModelTypes["blog_aggregate_order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: blogCategory */
	["blogCategory_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["blogCategory_select_column"]: blogCategory_select_column;
	/** input type for updating data in table "blogCategory" */
	["blogCategory_set_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "blogCategory" */
	["blogCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["blogCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["blogCategory_stream_cursor_value_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["blogCategory_update_column"]: blogCategory_update_column;
	["blogCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["blogCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["blogCategory_bool_exp"]
	};
	/** fields of action: "blogPostGeneratorEnhancedForAmp" */
	["blogPostGeneratorEnhancedForAmp"]: {
		/** the time at which this action was created */
		created_at: ModelTypes["timestamptz"],
		/** errors related to the invocation */
		errors?: ModelTypes["json"] | undefined,
		/** the unique id of an action */
		id: ModelTypes["uuid"],
		/** the output fields of this action */
		output?: ModelTypes["blogPostGeneratorEnhancedForAmpOutput"] | undefined
	};
	["blogPostGeneratorEnhancedForAmpOutput"]: {
		html?: string | undefined
	};
	/** aggregated selection of "blog" */
	["blog_aggregate"]: {
		aggregate?: ModelTypes["blog_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["blog"]>
	};
	["blog_aggregate_bool_exp"]: {
		count?: ModelTypes["blog_aggregate_bool_exp_count"] | undefined
	};
	["blog_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["blog_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["blog_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "blog" */
	["blog_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["blog_max_fields"] | undefined,
		min?: ModelTypes["blog_min_fields"] | undefined
	};
	/** order by aggregate values of table "blog" */
	["blog_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["blog_max_order_by"] | undefined,
		min?: ModelTypes["blog_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "blog" */
	["blog_arr_rel_insert_input"]: {
		data: Array<ModelTypes["blog_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["blog_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "blog". All fields are combined with a logical 'AND'. */
	["blog_bool_exp"]: {
		_and?: Array<ModelTypes["blog_bool_exp"]> | undefined,
		_not?: ModelTypes["blog_bool_exp"] | undefined,
		_or?: Array<ModelTypes["blog_bool_exp"]> | undefined,
		brand?: ModelTypes["brands_bool_exp"] | undefined,
		brandId?: ModelTypes["uuid_comparison_exp"] | undefined,
		category?: ModelTypes["blogCategory_bool_exp"] | undefined,
		categoryId?: ModelTypes["uuid_comparison_exp"] | undefined,
		content?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		featuredImageId?: ModelTypes["uuid_comparison_exp"] | undefined,
		file?: ModelTypes["files_bool_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		metaDescription?: ModelTypes["String_comparison_exp"] | undefined,
		project?: ModelTypes["project_bool_exp"] | undefined,
		projectId?: ModelTypes["uuid_comparison_exp"] | undefined,
		publishDate?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		scheduledDate?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		slug?: ModelTypes["String_comparison_exp"] | undefined,
		title?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		user?: ModelTypes["users_bool_exp"] | undefined
	};
	["blog_constraint"]: blog_constraint;
	/** input type for inserting data into table "blog" */
	["blog_insert_input"]: {
		brand?: ModelTypes["brands_obj_rel_insert_input"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		category?: ModelTypes["blogCategory_obj_rel_insert_input"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		featuredImageId?: ModelTypes["uuid"] | undefined,
		file?: ModelTypes["files_obj_rel_insert_input"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		project?: ModelTypes["project_obj_rel_insert_input"] | undefined,
		projectId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		user?: ModelTypes["users_obj_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["blog_max_fields"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		featuredImageId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		projectId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "blog" */
	["blog_max_order_by"]: {
		brandId?: ModelTypes["order_by"] | undefined,
		categoryId?: ModelTypes["order_by"] | undefined,
		content?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		featuredImageId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		metaDescription?: ModelTypes["order_by"] | undefined,
		projectId?: ModelTypes["order_by"] | undefined,
		publishDate?: ModelTypes["order_by"] | undefined,
		scheduledDate?: ModelTypes["order_by"] | undefined,
		slug?: ModelTypes["order_by"] | undefined,
		title?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["blog_min_fields"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		featuredImageId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		projectId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "blog" */
	["blog_min_order_by"]: {
		brandId?: ModelTypes["order_by"] | undefined,
		categoryId?: ModelTypes["order_by"] | undefined,
		content?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		featuredImageId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		metaDescription?: ModelTypes["order_by"] | undefined,
		projectId?: ModelTypes["order_by"] | undefined,
		publishDate?: ModelTypes["order_by"] | undefined,
		scheduledDate?: ModelTypes["order_by"] | undefined,
		slug?: ModelTypes["order_by"] | undefined,
		title?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "blog" */
	["blog_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["blog"]>
	};
	/** on_conflict condition type for table "blog" */
	["blog_on_conflict"]: {
		constraint: ModelTypes["blog_constraint"],
		update_columns: Array<ModelTypes["blog_update_column"]>,
		where?: ModelTypes["blog_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "blog". */
	["blog_order_by"]: {
		brand?: ModelTypes["brands_order_by"] | undefined,
		brandId?: ModelTypes["order_by"] | undefined,
		category?: ModelTypes["blogCategory_order_by"] | undefined,
		categoryId?: ModelTypes["order_by"] | undefined,
		content?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		featuredImageId?: ModelTypes["order_by"] | undefined,
		file?: ModelTypes["files_order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		metaDescription?: ModelTypes["order_by"] | undefined,
		project?: ModelTypes["project_order_by"] | undefined,
		projectId?: ModelTypes["order_by"] | undefined,
		publishDate?: ModelTypes["order_by"] | undefined,
		scheduledDate?: ModelTypes["order_by"] | undefined,
		slug?: ModelTypes["order_by"] | undefined,
		title?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		user?: ModelTypes["users_order_by"] | undefined
	};
	/** primary key columns input for table: blog */
	["blog_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["blog_select_column"]: blog_select_column;
	/** input type for updating data in table "blog" */
	["blog_set_input"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		featuredImageId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		projectId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "blog" */
	["blog_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["blog_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["blog_stream_cursor_value_input"]: {
		brandId?: ModelTypes["uuid"] | undefined,
		categoryId?: ModelTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		featuredImageId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		projectId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["blog_update_column"]: blog_update_column;
	["blog_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["blog_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["blog_bool_exp"]
	};
	/** columns and relationships of "brands" */
	["brands"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: ModelTypes["numeric"] | undefined,
		addressLongitude?: ModelTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		archtypeAdherence?: ModelTypes["jsonb"] | undefined,
		/** An array relationship */
		blogs: Array<ModelTypes["blog"]>,
		/** An aggregate relationship */
		blogs_aggregate: ModelTypes["blog_aggregate"],
		brandName: string,
		colors?: ModelTypes["jsonb"] | undefined,
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		deleted: boolean,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id: ModelTypes["uuid"],
		instagramUrl?: string | undefined,
		isBrand?: boolean | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: ModelTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		/** An array relationship */
		products: Array<ModelTypes["products"]>,
		/** An aggregate relationship */
		products_aggregate: ModelTypes["products_aggregate"],
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at: ModelTypes["timestamptz"],
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregated selection of "brands" */
	["brands_aggregate"]: {
		aggregate?: ModelTypes["brands_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["brands"]>
	};
	/** aggregate fields of "brands" */
	["brands_aggregate_fields"]: {
		avg?: ModelTypes["brands_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["brands_max_fields"] | undefined,
		min?: ModelTypes["brands_min_fields"] | undefined,
		stddev?: ModelTypes["brands_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["brands_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["brands_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["brands_sum_fields"] | undefined,
		var_pop?: ModelTypes["brands_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["brands_var_samp_fields"] | undefined,
		variance?: ModelTypes["brands_variance_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["brands_append_input"]: {
		archtypeAdherence?: ModelTypes["jsonb"] | undefined,
		colors?: ModelTypes["jsonb"] | undefined
	};
	/** aggregate avg on columns */
	["brands_avg_fields"]: {
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** Boolean expression to filter rows from the table "brands". All fields are combined with a logical 'AND'. */
	["brands_bool_exp"]: {
		_and?: Array<ModelTypes["brands_bool_exp"]> | undefined,
		_not?: ModelTypes["brands_bool_exp"] | undefined,
		_or?: Array<ModelTypes["brands_bool_exp"]> | undefined,
		addressCity?: ModelTypes["String_comparison_exp"] | undefined,
		addressComplement?: ModelTypes["String_comparison_exp"] | undefined,
		addressDistrict?: ModelTypes["String_comparison_exp"] | undefined,
		addressLatitude?: ModelTypes["numeric_comparison_exp"] | undefined,
		addressLongitude?: ModelTypes["numeric_comparison_exp"] | undefined,
		addressNumber?: ModelTypes["String_comparison_exp"] | undefined,
		addressStreet?: ModelTypes["String_comparison_exp"] | undefined,
		addressUf?: ModelTypes["String_comparison_exp"] | undefined,
		addressZipCode?: ModelTypes["String_comparison_exp"] | undefined,
		archtypeAdherence?: ModelTypes["jsonb_comparison_exp"] | undefined,
		blogs?: ModelTypes["blog_bool_exp"] | undefined,
		blogs_aggregate?: ModelTypes["blog_aggregate_bool_exp"] | undefined,
		brandName?: ModelTypes["String_comparison_exp"] | undefined,
		colors?: ModelTypes["jsonb_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		deleted?: ModelTypes["Boolean_comparison_exp"] | undefined,
		email?: ModelTypes["String_comparison_exp"] | undefined,
		facebookUrl?: ModelTypes["String_comparison_exp"] | undefined,
		fontPrimary?: ModelTypes["String_comparison_exp"] | undefined,
		fontSecondary?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		instagramUrl?: ModelTypes["String_comparison_exp"] | undefined,
		isBrand?: ModelTypes["Boolean_comparison_exp"] | undefined,
		linkedinUrl?: ModelTypes["String_comparison_exp"] | undefined,
		logoFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		personality?: ModelTypes["String_comparison_exp"] | undefined,
		phoneNumber?: ModelTypes["String_comparison_exp"] | undefined,
		productOrService?: ModelTypes["String_comparison_exp"] | undefined,
		products?: ModelTypes["products_bool_exp"] | undefined,
		products_aggregate?: ModelTypes["products_aggregate_bool_exp"] | undefined,
		segment?: ModelTypes["String_comparison_exp"] | undefined,
		shortAbout?: ModelTypes["String_comparison_exp"] | undefined,
		slogan?: ModelTypes["String_comparison_exp"] | undefined,
		tiktokUrl?: ModelTypes["String_comparison_exp"] | undefined,
		twitterUrl?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		voiceTone?: ModelTypes["String_comparison_exp"] | undefined,
		whatsappNumber?: ModelTypes["String_comparison_exp"] | undefined,
		youtubeUrl?: ModelTypes["String_comparison_exp"] | undefined
	};
	["brands_constraint"]: brands_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["brands_delete_at_path_input"]: {
		archtypeAdherence?: Array<string> | undefined,
		colors?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["brands_delete_elem_input"]: {
		archtypeAdherence?: number | undefined,
		colors?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["brands_delete_key_input"]: {
		archtypeAdherence?: string | undefined,
		colors?: string | undefined
	};
	/** input type for incrementing numeric columns in table "brands" */
	["brands_inc_input"]: {
		addressLatitude?: ModelTypes["numeric"] | undefined,
		addressLongitude?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "brands" */
	["brands_insert_input"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: ModelTypes["numeric"] | undefined,
		addressLongitude?: ModelTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		archtypeAdherence?: ModelTypes["jsonb"] | undefined,
		blogs?: ModelTypes["blog_arr_rel_insert_input"] | undefined,
		brandName?: string | undefined,
		colors?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		isBrand?: boolean | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: ModelTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		products?: ModelTypes["products_arr_rel_insert_input"] | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregate max on columns */
	["brands_max_fields"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: ModelTypes["numeric"] | undefined,
		addressLongitude?: ModelTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		brandName?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: ModelTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregate min on columns */
	["brands_min_fields"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: ModelTypes["numeric"] | undefined,
		addressLongitude?: ModelTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		brandName?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: ModelTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** response of any mutation on the table "brands" */
	["brands_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["brands"]>
	};
	/** input type for inserting object relation for remote table "brands" */
	["brands_obj_rel_insert_input"]: {
		data: ModelTypes["brands_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["brands_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "brands" */
	["brands_on_conflict"]: {
		constraint: ModelTypes["brands_constraint"],
		update_columns: Array<ModelTypes["brands_update_column"]>,
		where?: ModelTypes["brands_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "brands". */
	["brands_order_by"]: {
		addressCity?: ModelTypes["order_by"] | undefined,
		addressComplement?: ModelTypes["order_by"] | undefined,
		addressDistrict?: ModelTypes["order_by"] | undefined,
		addressLatitude?: ModelTypes["order_by"] | undefined,
		addressLongitude?: ModelTypes["order_by"] | undefined,
		addressNumber?: ModelTypes["order_by"] | undefined,
		addressStreet?: ModelTypes["order_by"] | undefined,
		addressUf?: ModelTypes["order_by"] | undefined,
		addressZipCode?: ModelTypes["order_by"] | undefined,
		archtypeAdherence?: ModelTypes["order_by"] | undefined,
		blogs_aggregate?: ModelTypes["blog_aggregate_order_by"] | undefined,
		brandName?: ModelTypes["order_by"] | undefined,
		colors?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		deleted?: ModelTypes["order_by"] | undefined,
		email?: ModelTypes["order_by"] | undefined,
		facebookUrl?: ModelTypes["order_by"] | undefined,
		fontPrimary?: ModelTypes["order_by"] | undefined,
		fontSecondary?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		instagramUrl?: ModelTypes["order_by"] | undefined,
		isBrand?: ModelTypes["order_by"] | undefined,
		linkedinUrl?: ModelTypes["order_by"] | undefined,
		logoFileId?: ModelTypes["order_by"] | undefined,
		personality?: ModelTypes["order_by"] | undefined,
		phoneNumber?: ModelTypes["order_by"] | undefined,
		productOrService?: ModelTypes["order_by"] | undefined,
		products_aggregate?: ModelTypes["products_aggregate_order_by"] | undefined,
		segment?: ModelTypes["order_by"] | undefined,
		shortAbout?: ModelTypes["order_by"] | undefined,
		slogan?: ModelTypes["order_by"] | undefined,
		tiktokUrl?: ModelTypes["order_by"] | undefined,
		twitterUrl?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		voiceTone?: ModelTypes["order_by"] | undefined,
		whatsappNumber?: ModelTypes["order_by"] | undefined,
		youtubeUrl?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: brands */
	["brands_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["brands_prepend_input"]: {
		archtypeAdherence?: ModelTypes["jsonb"] | undefined,
		colors?: ModelTypes["jsonb"] | undefined
	};
	["brands_select_column"]: brands_select_column;
	/** input type for updating data in table "brands" */
	["brands_set_input"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: ModelTypes["numeric"] | undefined,
		addressLongitude?: ModelTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		archtypeAdherence?: ModelTypes["jsonb"] | undefined,
		brandName?: string | undefined,
		colors?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		isBrand?: boolean | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: ModelTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregate stddev on columns */
	["brands_stddev_fields"]: {
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["brands_stddev_pop_fields"]: {
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["brands_stddev_samp_fields"]: {
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** Streaming cursor of the table "brands" */
	["brands_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["brands_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["brands_stream_cursor_value_input"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: ModelTypes["numeric"] | undefined,
		addressLongitude?: ModelTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		archtypeAdherence?: ModelTypes["jsonb"] | undefined,
		brandName?: string | undefined,
		colors?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		isBrand?: boolean | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: ModelTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregate sum on columns */
	["brands_sum_fields"]: {
		addressLatitude?: ModelTypes["numeric"] | undefined,
		addressLongitude?: ModelTypes["numeric"] | undefined
	};
	["brands_update_column"]: brands_update_column;
	["brands_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["brands_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["brands_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["brands_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["brands_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["brands_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["brands_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["brands_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["brands_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["brands_var_pop_fields"]: {
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** aggregate var_samp on columns */
	["brands_var_samp_fields"]: {
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** aggregate variance on columns */
	["brands_variance_fields"]: {
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** columns and relationships of "storage.buckets" */
	["buckets"]: {
		cacheControl?: string | undefined,
		createdAt: ModelTypes["timestamptz"],
		downloadExpiration: number,
		/** An array relationship */
		files: Array<ModelTypes["files"]>,
		/** An aggregate relationship */
		files_aggregate: ModelTypes["files_aggregate"],
		id: string,
		maxUploadFileSize: number,
		minUploadFileSize: number,
		presignedUrlsEnabled: boolean,
		updatedAt: ModelTypes["timestamptz"]
	};
	/** aggregated selection of "storage.buckets" */
	["buckets_aggregate"]: {
		aggregate?: ModelTypes["buckets_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["buckets"]>
	};
	/** aggregate fields of "storage.buckets" */
	["buckets_aggregate_fields"]: {
		avg?: ModelTypes["buckets_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["buckets_max_fields"] | undefined,
		min?: ModelTypes["buckets_min_fields"] | undefined,
		stddev?: ModelTypes["buckets_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["buckets_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["buckets_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["buckets_sum_fields"] | undefined,
		var_pop?: ModelTypes["buckets_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["buckets_var_samp_fields"] | undefined,
		variance?: ModelTypes["buckets_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["buckets_avg_fields"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** Boolean expression to filter rows from the table "storage.buckets". All fields are combined with a logical 'AND'. */
	["buckets_bool_exp"]: {
		_and?: Array<ModelTypes["buckets_bool_exp"]> | undefined,
		_not?: ModelTypes["buckets_bool_exp"] | undefined,
		_or?: Array<ModelTypes["buckets_bool_exp"]> | undefined,
		cacheControl?: ModelTypes["String_comparison_exp"] | undefined,
		createdAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		downloadExpiration?: ModelTypes["Int_comparison_exp"] | undefined,
		files?: ModelTypes["files_bool_exp"] | undefined,
		files_aggregate?: ModelTypes["files_aggregate_bool_exp"] | undefined,
		id?: ModelTypes["String_comparison_exp"] | undefined,
		maxUploadFileSize?: ModelTypes["Int_comparison_exp"] | undefined,
		minUploadFileSize?: ModelTypes["Int_comparison_exp"] | undefined,
		presignedUrlsEnabled?: ModelTypes["Boolean_comparison_exp"] | undefined,
		updatedAt?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["buckets_constraint"]: buckets_constraint;
	/** input type for incrementing numeric columns in table "storage.buckets" */
	["buckets_inc_input"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** input type for inserting data into table "storage.buckets" */
	["buckets_insert_input"]: {
		cacheControl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		files?: ModelTypes["files_arr_rel_insert_input"] | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		presignedUrlsEnabled?: boolean | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["buckets_max_fields"]: {
		cacheControl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["buckets_min_fields"]: {
		cacheControl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "storage.buckets" */
	["buckets_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["buckets"]>
	};
	/** input type for inserting object relation for remote table "storage.buckets" */
	["buckets_obj_rel_insert_input"]: {
		data: ModelTypes["buckets_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["buckets_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "storage.buckets" */
	["buckets_on_conflict"]: {
		constraint: ModelTypes["buckets_constraint"],
		update_columns: Array<ModelTypes["buckets_update_column"]>,
		where?: ModelTypes["buckets_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "storage.buckets". */
	["buckets_order_by"]: {
		cacheControl?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		downloadExpiration?: ModelTypes["order_by"] | undefined,
		files_aggregate?: ModelTypes["files_aggregate_order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		maxUploadFileSize?: ModelTypes["order_by"] | undefined,
		minUploadFileSize?: ModelTypes["order_by"] | undefined,
		presignedUrlsEnabled?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: storage.buckets */
	["buckets_pk_columns_input"]: {
		id: string
	};
	["buckets_select_column"]: buckets_select_column;
	/** input type for updating data in table "storage.buckets" */
	["buckets_set_input"]: {
		cacheControl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		presignedUrlsEnabled?: boolean | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["buckets_stddev_fields"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["buckets_stddev_pop_fields"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["buckets_stddev_samp_fields"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** Streaming cursor of the table "buckets" */
	["buckets_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["buckets_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["buckets_stream_cursor_value_input"]: {
		cacheControl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		presignedUrlsEnabled?: boolean | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["buckets_sum_fields"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	["buckets_update_column"]: buckets_update_column;
	["buckets_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["buckets_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["buckets_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["buckets_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["buckets_var_pop_fields"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** aggregate var_samp on columns */
	["buckets_var_samp_fields"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** aggregate variance on columns */
	["buckets_variance_fields"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	["bytea"]: any;
	/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
	["bytea_comparison_exp"]: {
		_eq?: ModelTypes["bytea"] | undefined,
		_gt?: ModelTypes["bytea"] | undefined,
		_gte?: ModelTypes["bytea"] | undefined,
		_in?: Array<ModelTypes["bytea"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: ModelTypes["bytea"] | undefined,
		_lte?: ModelTypes["bytea"] | undefined,
		_neq?: ModelTypes["bytea"] | undefined,
		_nin?: Array<ModelTypes["bytea"]> | undefined
	};
	/** columns and relationships of "campaign" */
	["campaign"]: {
		audiencesIds?: string | undefined,
		baseJson: ModelTypes["jsonb"],
		brandId?: ModelTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		id: ModelTypes["uuid"],
		productIds?: string | undefined,
		updated_at: ModelTypes["timestamptz"]
	};
	/** aggregated selection of "campaign" */
	["campaign_aggregate"]: {
		aggregate?: ModelTypes["campaign_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["campaign"]>
	};
	/** aggregate fields of "campaign" */
	["campaign_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["campaign_max_fields"] | undefined,
		min?: ModelTypes["campaign_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["campaign_append_input"]: {
		baseJson?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "campaign". All fields are combined with a logical 'AND'. */
	["campaign_bool_exp"]: {
		_and?: Array<ModelTypes["campaign_bool_exp"]> | undefined,
		_not?: ModelTypes["campaign_bool_exp"] | undefined,
		_or?: Array<ModelTypes["campaign_bool_exp"]> | undefined,
		audiencesIds?: ModelTypes["String_comparison_exp"] | undefined,
		baseJson?: ModelTypes["jsonb_comparison_exp"] | undefined,
		brandId?: ModelTypes["uuid_comparison_exp"] | undefined,
		campaignObjective?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		productIds?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["campaign_constraint"]: campaign_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["campaign_delete_at_path_input"]: {
		baseJson?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["campaign_delete_elem_input"]: {
		baseJson?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["campaign_delete_key_input"]: {
		baseJson?: string | undefined
	};
	/** input type for inserting data into table "campaign" */
	["campaign_insert_input"]: {
		audiencesIds?: string | undefined,
		baseJson?: ModelTypes["jsonb"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["campaign_max_fields"]: {
		audiencesIds?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["campaign_min_fields"]: {
		audiencesIds?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "campaign" */
	["campaign_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["campaign"]>
	};
	/** on_conflict condition type for table "campaign" */
	["campaign_on_conflict"]: {
		constraint: ModelTypes["campaign_constraint"],
		update_columns: Array<ModelTypes["campaign_update_column"]>,
		where?: ModelTypes["campaign_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "campaign". */
	["campaign_order_by"]: {
		audiencesIds?: ModelTypes["order_by"] | undefined,
		baseJson?: ModelTypes["order_by"] | undefined,
		brandId?: ModelTypes["order_by"] | undefined,
		campaignObjective?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		productIds?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: campaign */
	["campaign_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["campaign_prepend_input"]: {
		baseJson?: ModelTypes["jsonb"] | undefined
	};
	["campaign_select_column"]: campaign_select_column;
	/** input type for updating data in table "campaign" */
	["campaign_set_input"]: {
		audiencesIds?: string | undefined,
		baseJson?: ModelTypes["jsonb"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "campaign" */
	["campaign_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["campaign_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["campaign_stream_cursor_value_input"]: {
		audiencesIds?: string | undefined,
		baseJson?: ModelTypes["jsonb"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["campaign_update_column"]: campaign_update_column;
	["campaign_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["campaign_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["campaign_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["campaign_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["campaign_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["campaign_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["campaign_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["campaign_bool_exp"]
	};
	/** columns and relationships of "categorysummaryview" */
	["categorysummaryview"]: {
		categoryName?: string | undefined,
		count?: ModelTypes["bigint"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregated selection of "categorysummaryview" */
	["categorysummaryview_aggregate"]: {
		aggregate?: ModelTypes["categorysummaryview_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["categorysummaryview"]>
	};
	/** aggregate fields of "categorysummaryview" */
	["categorysummaryview_aggregate_fields"]: {
		avg?: ModelTypes["categorysummaryview_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["categorysummaryview_max_fields"] | undefined,
		min?: ModelTypes["categorysummaryview_min_fields"] | undefined,
		stddev?: ModelTypes["categorysummaryview_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["categorysummaryview_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["categorysummaryview_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["categorysummaryview_sum_fields"] | undefined,
		var_pop?: ModelTypes["categorysummaryview_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["categorysummaryview_var_samp_fields"] | undefined,
		variance?: ModelTypes["categorysummaryview_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["categorysummaryview_avg_fields"]: {
		count?: number | undefined
	};
	/** Boolean expression to filter rows from the table "categorysummaryview". All fields are combined with a logical 'AND'. */
	["categorysummaryview_bool_exp"]: {
		_and?: Array<ModelTypes["categorysummaryview_bool_exp"]> | undefined,
		_not?: ModelTypes["categorysummaryview_bool_exp"] | undefined,
		_or?: Array<ModelTypes["categorysummaryview_bool_exp"]> | undefined,
		categoryName?: ModelTypes["String_comparison_exp"] | undefined,
		count?: ModelTypes["bigint_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	/** aggregate max on columns */
	["categorysummaryview_max_fields"]: {
		categoryName?: string | undefined,
		count?: ModelTypes["bigint"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["categorysummaryview_min_fields"]: {
		categoryName?: string | undefined,
		count?: ModelTypes["bigint"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** Ordering options when selecting data from "categorysummaryview". */
	["categorysummaryview_order_by"]: {
		categoryName?: ModelTypes["order_by"] | undefined,
		count?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined
	};
	["categorysummaryview_select_column"]: categorysummaryview_select_column;
	/** aggregate stddev on columns */
	["categorysummaryview_stddev_fields"]: {
		count?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["categorysummaryview_stddev_pop_fields"]: {
		count?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["categorysummaryview_stddev_samp_fields"]: {
		count?: number | undefined
	};
	/** Streaming cursor of the table "categorysummaryview" */
	["categorysummaryview_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["categorysummaryview_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["categorysummaryview_stream_cursor_value_input"]: {
		categoryName?: string | undefined,
		count?: ModelTypes["bigint"] | undefined,
		id?: ModelTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["categorysummaryview_sum_fields"]: {
		count?: ModelTypes["bigint"] | undefined
	};
	/** aggregate var_pop on columns */
	["categorysummaryview_var_pop_fields"]: {
		count?: number | undefined
	};
	/** aggregate var_samp on columns */
	["categorysummaryview_var_samp_fields"]: {
		count?: number | undefined
	};
	/** aggregate variance on columns */
	["categorysummaryview_variance_fields"]: {
		count?: number | undefined
	};
	/** columns and relationships of "channels" */
	["channels"]: {
		alias: string,
		created_at: ModelTypes["timestamp"],
		displayName: string,
		id: ModelTypes["uuid"],
		updated_at: ModelTypes["timestamp"]
	};
	/** aggregated selection of "channels" */
	["channels_aggregate"]: {
		aggregate?: ModelTypes["channels_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["channels"]>
	};
	/** aggregate fields of "channels" */
	["channels_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["channels_max_fields"] | undefined,
		min?: ModelTypes["channels_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
	["channels_bool_exp"]: {
		_and?: Array<ModelTypes["channels_bool_exp"]> | undefined,
		_not?: ModelTypes["channels_bool_exp"] | undefined,
		_or?: Array<ModelTypes["channels_bool_exp"]> | undefined,
		alias?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamp_comparison_exp"] | undefined,
		displayName?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamp_comparison_exp"] | undefined
	};
	["channels_constraint"]: channels_constraint;
	/** input type for inserting data into table "channels" */
	["channels_insert_input"]: {
		alias?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamp"] | undefined
	};
	/** aggregate max on columns */
	["channels_max_fields"]: {
		alias?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamp"] | undefined
	};
	/** aggregate min on columns */
	["channels_min_fields"]: {
		alias?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamp"] | undefined
	};
	/** response of any mutation on the table "channels" */
	["channels_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["channels"]>
	};
	/** on_conflict condition type for table "channels" */
	["channels_on_conflict"]: {
		constraint: ModelTypes["channels_constraint"],
		update_columns: Array<ModelTypes["channels_update_column"]>,
		where?: ModelTypes["channels_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "channels". */
	["channels_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		displayName?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: channels */
	["channels_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["channels_select_column"]: channels_select_column;
	/** input type for updating data in table "channels" */
	["channels_set_input"]: {
		alias?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamp"] | undefined
	};
	/** Streaming cursor of the table "channels" */
	["channels_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["channels_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["channels_stream_cursor_value_input"]: {
		alias?: string | undefined,
		created_at?: ModelTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamp"] | undefined
	};
	["channels_update_column"]: channels_update_column;
	["channels_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["channels_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["channels_bool_exp"]
	};
	["citext"]: any;
	/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
	["citext_comparison_exp"]: {
		_eq?: ModelTypes["citext"] | undefined,
		_gt?: ModelTypes["citext"] | undefined,
		_gte?: ModelTypes["citext"] | undefined,
		/** does the column match the given case-insensitive pattern */
		_ilike?: ModelTypes["citext"] | undefined,
		_in?: Array<ModelTypes["citext"]> | undefined,
		/** does the column match the given POSIX regular expression, case insensitive */
		_iregex?: ModelTypes["citext"] | undefined,
		_is_null?: boolean | undefined,
		/** does the column match the given pattern */
		_like?: ModelTypes["citext"] | undefined,
		_lt?: ModelTypes["citext"] | undefined,
		_lte?: ModelTypes["citext"] | undefined,
		_neq?: ModelTypes["citext"] | undefined,
		/** does the column NOT match the given case-insensitive pattern */
		_nilike?: ModelTypes["citext"] | undefined,
		_nin?: Array<ModelTypes["citext"]> | undefined,
		/** does the column NOT match the given POSIX regular expression, case insensitive */
		_niregex?: ModelTypes["citext"] | undefined,
		/** does the column NOT match the given pattern */
		_nlike?: ModelTypes["citext"] | undefined,
		/** does the column NOT match the given POSIX regular expression, case sensitive */
		_nregex?: ModelTypes["citext"] | undefined,
		/** does the column NOT match the given SQL regular expression */
		_nsimilar?: ModelTypes["citext"] | undefined,
		/** does the column match the given POSIX regular expression, case sensitive */
		_regex?: ModelTypes["citext"] | undefined,
		/** does the column match the given SQL regular expression */
		_similar?: ModelTypes["citext"] | undefined
	};
	/** columns and relationships of "consumptionByChannelView" */
	["consumptionByChannelView"]: {
		agendados?: ModelTypes["bigint"] | undefined,
		channel?: string | undefined,
		copymagico?: ModelTypes["numeric"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		economia?: ModelTypes["numeric"] | undefined,
		mercado?: ModelTypes["bigint"] | undefined,
		publicados?: ModelTypes["bigint"] | undefined,
		rascunhos?: ModelTypes["bigint"] | undefined
	};
	/** aggregated selection of "consumptionByChannelView" */
	["consumptionByChannelView_aggregate"]: {
		aggregate?: ModelTypes["consumptionByChannelView_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["consumptionByChannelView"]>
	};
	/** aggregate fields of "consumptionByChannelView" */
	["consumptionByChannelView_aggregate_fields"]: {
		avg?: ModelTypes["consumptionByChannelView_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["consumptionByChannelView_max_fields"] | undefined,
		min?: ModelTypes["consumptionByChannelView_min_fields"] | undefined,
		stddev?: ModelTypes["consumptionByChannelView_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["consumptionByChannelView_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["consumptionByChannelView_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["consumptionByChannelView_sum_fields"] | undefined,
		var_pop?: ModelTypes["consumptionByChannelView_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["consumptionByChannelView_var_samp_fields"] | undefined,
		variance?: ModelTypes["consumptionByChannelView_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["consumptionByChannelView_avg_fields"]: {
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** Boolean expression to filter rows from the table "consumptionByChannelView". All fields are combined with a logical 'AND'. */
	["consumptionByChannelView_bool_exp"]: {
		_and?: Array<ModelTypes["consumptionByChannelView_bool_exp"]> | undefined,
		_not?: ModelTypes["consumptionByChannelView_bool_exp"] | undefined,
		_or?: Array<ModelTypes["consumptionByChannelView_bool_exp"]> | undefined,
		agendados?: ModelTypes["bigint_comparison_exp"] | undefined,
		channel?: ModelTypes["String_comparison_exp"] | undefined,
		copymagico?: ModelTypes["numeric_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		economia?: ModelTypes["numeric_comparison_exp"] | undefined,
		mercado?: ModelTypes["bigint_comparison_exp"] | undefined,
		publicados?: ModelTypes["bigint_comparison_exp"] | undefined,
		rascunhos?: ModelTypes["bigint_comparison_exp"] | undefined
	};
	/** aggregate max on columns */
	["consumptionByChannelView_max_fields"]: {
		agendados?: ModelTypes["bigint"] | undefined,
		channel?: string | undefined,
		copymagico?: ModelTypes["numeric"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		economia?: ModelTypes["numeric"] | undefined,
		mercado?: ModelTypes["bigint"] | undefined,
		publicados?: ModelTypes["bigint"] | undefined,
		rascunhos?: ModelTypes["bigint"] | undefined
	};
	/** aggregate min on columns */
	["consumptionByChannelView_min_fields"]: {
		agendados?: ModelTypes["bigint"] | undefined,
		channel?: string | undefined,
		copymagico?: ModelTypes["numeric"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		economia?: ModelTypes["numeric"] | undefined,
		mercado?: ModelTypes["bigint"] | undefined,
		publicados?: ModelTypes["bigint"] | undefined,
		rascunhos?: ModelTypes["bigint"] | undefined
	};
	/** Ordering options when selecting data from "consumptionByChannelView". */
	["consumptionByChannelView_order_by"]: {
		agendados?: ModelTypes["order_by"] | undefined,
		channel?: ModelTypes["order_by"] | undefined,
		copymagico?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		economia?: ModelTypes["order_by"] | undefined,
		mercado?: ModelTypes["order_by"] | undefined,
		publicados?: ModelTypes["order_by"] | undefined,
		rascunhos?: ModelTypes["order_by"] | undefined
	};
	["consumptionByChannelView_select_column"]: consumptionByChannelView_select_column;
	/** aggregate stddev on columns */
	["consumptionByChannelView_stddev_fields"]: {
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["consumptionByChannelView_stddev_pop_fields"]: {
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["consumptionByChannelView_stddev_samp_fields"]: {
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** Streaming cursor of the table "consumptionByChannelView" */
	["consumptionByChannelView_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["consumptionByChannelView_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["consumptionByChannelView_stream_cursor_value_input"]: {
		agendados?: ModelTypes["bigint"] | undefined,
		channel?: string | undefined,
		copymagico?: ModelTypes["numeric"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		economia?: ModelTypes["numeric"] | undefined,
		mercado?: ModelTypes["bigint"] | undefined,
		publicados?: ModelTypes["bigint"] | undefined,
		rascunhos?: ModelTypes["bigint"] | undefined
	};
	/** aggregate sum on columns */
	["consumptionByChannelView_sum_fields"]: {
		agendados?: ModelTypes["bigint"] | undefined,
		copymagico?: ModelTypes["numeric"] | undefined,
		economia?: ModelTypes["numeric"] | undefined,
		mercado?: ModelTypes["bigint"] | undefined,
		publicados?: ModelTypes["bigint"] | undefined,
		rascunhos?: ModelTypes["bigint"] | undefined
	};
	/** aggregate var_pop on columns */
	["consumptionByChannelView_var_pop_fields"]: {
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** aggregate var_samp on columns */
	["consumptionByChannelView_var_samp_fields"]: {
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** aggregate variance on columns */
	["consumptionByChannelView_variance_fields"]: {
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** columns and relationships of "costTable" */
	["costTable"]: {
		bannerBear?: ModelTypes["numeric"] | undefined,
		created_at: ModelTypes["timestamptz"],
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: ModelTypes["numeric"] | undefined,
		gptPromptTokens?: ModelTypes["numeric"] | undefined,
		id: ModelTypes["uuid"],
		provider: string
	};
	/** aggregated selection of "costTable" */
	["costTable_aggregate"]: {
		aggregate?: ModelTypes["costTable_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["costTable"]>
	};
	/** aggregate fields of "costTable" */
	["costTable_aggregate_fields"]: {
		avg?: ModelTypes["costTable_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["costTable_max_fields"] | undefined,
		min?: ModelTypes["costTable_min_fields"] | undefined,
		stddev?: ModelTypes["costTable_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["costTable_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["costTable_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["costTable_sum_fields"] | undefined,
		var_pop?: ModelTypes["costTable_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["costTable_var_samp_fields"] | undefined,
		variance?: ModelTypes["costTable_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["costTable_avg_fields"]: {
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** Boolean expression to filter rows from the table "costTable". All fields are combined with a logical 'AND'. */
	["costTable_bool_exp"]: {
		_and?: Array<ModelTypes["costTable_bool_exp"]> | undefined,
		_not?: ModelTypes["costTable_bool_exp"] | undefined,
		_or?: Array<ModelTypes["costTable_bool_exp"]> | undefined,
		bannerBear?: ModelTypes["numeric_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		entityId?: ModelTypes["String_comparison_exp"] | undefined,
		entityName?: ModelTypes["String_comparison_exp"] | undefined,
		gptCompletionTokens?: ModelTypes["numeric_comparison_exp"] | undefined,
		gptPromptTokens?: ModelTypes["numeric_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		provider?: ModelTypes["String_comparison_exp"] | undefined
	};
	["costTable_constraint"]: costTable_constraint;
	/** input type for incrementing numeric columns in table "costTable" */
	["costTable_inc_input"]: {
		bannerBear?: ModelTypes["numeric"] | undefined,
		gptCompletionTokens?: ModelTypes["numeric"] | undefined,
		gptPromptTokens?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "costTable" */
	["costTable_insert_input"]: {
		bannerBear?: ModelTypes["numeric"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: ModelTypes["numeric"] | undefined,
		gptPromptTokens?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** aggregate max on columns */
	["costTable_max_fields"]: {
		bannerBear?: ModelTypes["numeric"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: ModelTypes["numeric"] | undefined,
		gptPromptTokens?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** aggregate min on columns */
	["costTable_min_fields"]: {
		bannerBear?: ModelTypes["numeric"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: ModelTypes["numeric"] | undefined,
		gptPromptTokens?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** response of any mutation on the table "costTable" */
	["costTable_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["costTable"]>
	};
	/** on_conflict condition type for table "costTable" */
	["costTable_on_conflict"]: {
		constraint: ModelTypes["costTable_constraint"],
		update_columns: Array<ModelTypes["costTable_update_column"]>,
		where?: ModelTypes["costTable_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "costTable". */
	["costTable_order_by"]: {
		bannerBear?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		entityId?: ModelTypes["order_by"] | undefined,
		entityName?: ModelTypes["order_by"] | undefined,
		gptCompletionTokens?: ModelTypes["order_by"] | undefined,
		gptPromptTokens?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		provider?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: costTable */
	["costTable_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["costTable_select_column"]: costTable_select_column;
	/** input type for updating data in table "costTable" */
	["costTable_set_input"]: {
		bannerBear?: ModelTypes["numeric"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: ModelTypes["numeric"] | undefined,
		gptPromptTokens?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** aggregate stddev on columns */
	["costTable_stddev_fields"]: {
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["costTable_stddev_pop_fields"]: {
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["costTable_stddev_samp_fields"]: {
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** Streaming cursor of the table "costTable" */
	["costTable_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["costTable_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["costTable_stream_cursor_value_input"]: {
		bannerBear?: ModelTypes["numeric"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: ModelTypes["numeric"] | undefined,
		gptPromptTokens?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** aggregate sum on columns */
	["costTable_sum_fields"]: {
		bannerBear?: ModelTypes["numeric"] | undefined,
		gptCompletionTokens?: ModelTypes["numeric"] | undefined,
		gptPromptTokens?: ModelTypes["numeric"] | undefined
	};
	["costTable_update_column"]: costTable_update_column;
	["costTable_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["costTable_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["costTable_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["costTable_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["costTable_var_pop_fields"]: {
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** aggregate var_samp on columns */
	["costTable_var_samp_fields"]: {
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** aggregate variance on columns */
	["costTable_variance_fields"]: {
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	["cursor_ordering"]: cursor_ordering;
	["date"]: any;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
	["date_comparison_exp"]: {
		_eq?: ModelTypes["date"] | undefined,
		_gt?: ModelTypes["date"] | undefined,
		_gte?: ModelTypes["date"] | undefined,
		_in?: Array<ModelTypes["date"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: ModelTypes["date"] | undefined,
		_lte?: ModelTypes["date"] | undefined,
		_neq?: ModelTypes["date"] | undefined,
		_nin?: Array<ModelTypes["date"]> | undefined
	};
	/** columns and relationships of "deal" */
	["deal"]: {
		created_at: ModelTypes["timestamptz"],
		/** An object relationship */
		dealStatus: ModelTypes["dealStatus"],
		email: string,
		id: ModelTypes["uuid"],
		/** An array relationship */
		interactions: Array<ModelTypes["interaction"]>,
		/** An aggregate relationship */
		interactions_aggregate: ModelTypes["interaction_aggregate"],
		name: string,
		phone: string,
		/** An object relationship */
		product?: ModelTypes["products"] | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		rating: number,
		statusId: ModelTypes["uuid"],
		/** An array relationship */
		tasks: Array<ModelTypes["task"]>,
		/** An aggregate relationship */
		tasks_aggregate: ModelTypes["task_aggregate"],
		updated_at: ModelTypes["timestamptz"]
	};
	/** columns and relationships of "dealStatus" */
	["dealStatus"]: {
		/** An array relationship */
		deals: Array<ModelTypes["deal"]>,
		/** An aggregate relationship */
		deals_aggregate: ModelTypes["deal_aggregate"],
		displayName: string,
		id: ModelTypes["uuid"],
		order: number
	};
	/** aggregated selection of "dealStatus" */
	["dealStatus_aggregate"]: {
		aggregate?: ModelTypes["dealStatus_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["dealStatus"]>
	};
	/** aggregate fields of "dealStatus" */
	["dealStatus_aggregate_fields"]: {
		avg?: ModelTypes["dealStatus_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["dealStatus_max_fields"] | undefined,
		min?: ModelTypes["dealStatus_min_fields"] | undefined,
		stddev?: ModelTypes["dealStatus_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["dealStatus_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["dealStatus_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["dealStatus_sum_fields"] | undefined,
		var_pop?: ModelTypes["dealStatus_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["dealStatus_var_samp_fields"] | undefined,
		variance?: ModelTypes["dealStatus_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["dealStatus_avg_fields"]: {
		order?: number | undefined
	};
	/** Boolean expression to filter rows from the table "dealStatus". All fields are combined with a logical 'AND'. */
	["dealStatus_bool_exp"]: {
		_and?: Array<ModelTypes["dealStatus_bool_exp"]> | undefined,
		_not?: ModelTypes["dealStatus_bool_exp"] | undefined,
		_or?: Array<ModelTypes["dealStatus_bool_exp"]> | undefined,
		deals?: ModelTypes["deal_bool_exp"] | undefined,
		deals_aggregate?: ModelTypes["deal_aggregate_bool_exp"] | undefined,
		displayName?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		order?: ModelTypes["Int_comparison_exp"] | undefined
	};
	["dealStatus_constraint"]: dealStatus_constraint;
	/** input type for incrementing numeric columns in table "dealStatus" */
	["dealStatus_inc_input"]: {
		order?: number | undefined
	};
	/** input type for inserting data into table "dealStatus" */
	["dealStatus_insert_input"]: {
		deals?: ModelTypes["deal_arr_rel_insert_input"] | undefined,
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** aggregate max on columns */
	["dealStatus_max_fields"]: {
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** aggregate min on columns */
	["dealStatus_min_fields"]: {
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** response of any mutation on the table "dealStatus" */
	["dealStatus_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["dealStatus"]>
	};
	/** input type for inserting object relation for remote table "dealStatus" */
	["dealStatus_obj_rel_insert_input"]: {
		data: ModelTypes["dealStatus_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["dealStatus_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "dealStatus" */
	["dealStatus_on_conflict"]: {
		constraint: ModelTypes["dealStatus_constraint"],
		update_columns: Array<ModelTypes["dealStatus_update_column"]>,
		where?: ModelTypes["dealStatus_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "dealStatus". */
	["dealStatus_order_by"]: {
		deals_aggregate?: ModelTypes["deal_aggregate_order_by"] | undefined,
		displayName?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		order?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: dealStatus */
	["dealStatus_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["dealStatus_select_column"]: dealStatus_select_column;
	/** input type for updating data in table "dealStatus" */
	["dealStatus_set_input"]: {
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** aggregate stddev on columns */
	["dealStatus_stddev_fields"]: {
		order?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["dealStatus_stddev_pop_fields"]: {
		order?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["dealStatus_stddev_samp_fields"]: {
		order?: number | undefined
	};
	/** Streaming cursor of the table "dealStatus" */
	["dealStatus_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["dealStatus_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["dealStatus_stream_cursor_value_input"]: {
		displayName?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** aggregate sum on columns */
	["dealStatus_sum_fields"]: {
		order?: number | undefined
	};
	["dealStatus_update_column"]: dealStatus_update_column;
	["dealStatus_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["dealStatus_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["dealStatus_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["dealStatus_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["dealStatus_var_pop_fields"]: {
		order?: number | undefined
	};
	/** aggregate var_samp on columns */
	["dealStatus_var_samp_fields"]: {
		order?: number | undefined
	};
	/** aggregate variance on columns */
	["dealStatus_variance_fields"]: {
		order?: number | undefined
	};
	/** aggregated selection of "deal" */
	["deal_aggregate"]: {
		aggregate?: ModelTypes["deal_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["deal"]>
	};
	["deal_aggregate_bool_exp"]: {
		count?: ModelTypes["deal_aggregate_bool_exp_count"] | undefined
	};
	["deal_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["deal_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["deal_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "deal" */
	["deal_aggregate_fields"]: {
		avg?: ModelTypes["deal_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["deal_max_fields"] | undefined,
		min?: ModelTypes["deal_min_fields"] | undefined,
		stddev?: ModelTypes["deal_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["deal_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["deal_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["deal_sum_fields"] | undefined,
		var_pop?: ModelTypes["deal_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["deal_var_samp_fields"] | undefined,
		variance?: ModelTypes["deal_variance_fields"] | undefined
	};
	/** order by aggregate values of table "deal" */
	["deal_aggregate_order_by"]: {
		avg?: ModelTypes["deal_avg_order_by"] | undefined,
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["deal_max_order_by"] | undefined,
		min?: ModelTypes["deal_min_order_by"] | undefined,
		stddev?: ModelTypes["deal_stddev_order_by"] | undefined,
		stddev_pop?: ModelTypes["deal_stddev_pop_order_by"] | undefined,
		stddev_samp?: ModelTypes["deal_stddev_samp_order_by"] | undefined,
		sum?: ModelTypes["deal_sum_order_by"] | undefined,
		var_pop?: ModelTypes["deal_var_pop_order_by"] | undefined,
		var_samp?: ModelTypes["deal_var_samp_order_by"] | undefined,
		variance?: ModelTypes["deal_variance_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "deal" */
	["deal_arr_rel_insert_input"]: {
		data: Array<ModelTypes["deal_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["deal_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["deal_avg_fields"]: {
		rating?: number | undefined
	};
	/** order by avg() on columns of table "deal" */
	["deal_avg_order_by"]: {
		rating?: ModelTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "deal". All fields are combined with a logical 'AND'. */
	["deal_bool_exp"]: {
		_and?: Array<ModelTypes["deal_bool_exp"]> | undefined,
		_not?: ModelTypes["deal_bool_exp"] | undefined,
		_or?: Array<ModelTypes["deal_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		dealStatus?: ModelTypes["dealStatus_bool_exp"] | undefined,
		email?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		interactions?: ModelTypes["interaction_bool_exp"] | undefined,
		interactions_aggregate?: ModelTypes["interaction_aggregate_bool_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined,
		phone?: ModelTypes["String_comparison_exp"] | undefined,
		product?: ModelTypes["products_bool_exp"] | undefined,
		productId?: ModelTypes["uuid_comparison_exp"] | undefined,
		rating?: ModelTypes["Int_comparison_exp"] | undefined,
		statusId?: ModelTypes["uuid_comparison_exp"] | undefined,
		tasks?: ModelTypes["task_bool_exp"] | undefined,
		tasks_aggregate?: ModelTypes["task_aggregate_bool_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["deal_constraint"]: deal_constraint;
	/** input type for incrementing numeric columns in table "deal" */
	["deal_inc_input"]: {
		rating?: number | undefined
	};
	/** input type for inserting data into table "deal" */
	["deal_insert_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		dealStatus?: ModelTypes["dealStatus_obj_rel_insert_input"] | undefined,
		email?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		interactions?: ModelTypes["interaction_arr_rel_insert_input"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		product?: ModelTypes["products_obj_rel_insert_input"] | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: ModelTypes["uuid"] | undefined,
		tasks?: ModelTypes["task_arr_rel_insert_input"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["deal_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		email?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "deal" */
	["deal_max_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		email?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		phone?: ModelTypes["order_by"] | undefined,
		productId?: ModelTypes["order_by"] | undefined,
		rating?: ModelTypes["order_by"] | undefined,
		statusId?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["deal_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		email?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "deal" */
	["deal_min_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		email?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		phone?: ModelTypes["order_by"] | undefined,
		productId?: ModelTypes["order_by"] | undefined,
		rating?: ModelTypes["order_by"] | undefined,
		statusId?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "deal" */
	["deal_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["deal"]>
	};
	/** input type for inserting object relation for remote table "deal" */
	["deal_obj_rel_insert_input"]: {
		data: ModelTypes["deal_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["deal_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "deal" */
	["deal_on_conflict"]: {
		constraint: ModelTypes["deal_constraint"],
		update_columns: Array<ModelTypes["deal_update_column"]>,
		where?: ModelTypes["deal_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "deal". */
	["deal_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		dealStatus?: ModelTypes["dealStatus_order_by"] | undefined,
		email?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		interactions_aggregate?: ModelTypes["interaction_aggregate_order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		phone?: ModelTypes["order_by"] | undefined,
		product?: ModelTypes["products_order_by"] | undefined,
		productId?: ModelTypes["order_by"] | undefined,
		rating?: ModelTypes["order_by"] | undefined,
		statusId?: ModelTypes["order_by"] | undefined,
		tasks_aggregate?: ModelTypes["task_aggregate_order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: deal */
	["deal_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["deal_select_column"]: deal_select_column;
	/** input type for updating data in table "deal" */
	["deal_set_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		email?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["deal_stddev_fields"]: {
		rating?: number | undefined
	};
	/** order by stddev() on columns of table "deal" */
	["deal_stddev_order_by"]: {
		rating?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["deal_stddev_pop_fields"]: {
		rating?: number | undefined
	};
	/** order by stddev_pop() on columns of table "deal" */
	["deal_stddev_pop_order_by"]: {
		rating?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["deal_stddev_samp_fields"]: {
		rating?: number | undefined
	};
	/** order by stddev_samp() on columns of table "deal" */
	["deal_stddev_samp_order_by"]: {
		rating?: ModelTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "deal" */
	["deal_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["deal_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["deal_stream_cursor_value_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		email?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["deal_sum_fields"]: {
		rating?: number | undefined
	};
	/** order by sum() on columns of table "deal" */
	["deal_sum_order_by"]: {
		rating?: ModelTypes["order_by"] | undefined
	};
	["deal_update_column"]: deal_update_column;
	["deal_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["deal_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["deal_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["deal_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["deal_var_pop_fields"]: {
		rating?: number | undefined
	};
	/** order by var_pop() on columns of table "deal" */
	["deal_var_pop_order_by"]: {
		rating?: ModelTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["deal_var_samp_fields"]: {
		rating?: number | undefined
	};
	/** order by var_samp() on columns of table "deal" */
	["deal_var_samp_order_by"]: {
		rating?: ModelTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["deal_variance_fields"]: {
		rating?: number | undefined
	};
	/** order by variance() on columns of table "deal" */
	["deal_variance_order_by"]: {
		rating?: ModelTypes["order_by"] | undefined
	};
	/** columns and relationships of "displayCategory" */
	["displayCategory"]: {
		created_at: ModelTypes["timestamptz"],
		displayName: ModelTypes["jsonb"],
		id: ModelTypes["uuid"],
		name: string
	};
	/** aggregated selection of "displayCategory" */
	["displayCategory_aggregate"]: {
		aggregate?: ModelTypes["displayCategory_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["displayCategory"]>
	};
	/** aggregate fields of "displayCategory" */
	["displayCategory_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["displayCategory_max_fields"] | undefined,
		min?: ModelTypes["displayCategory_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayCategory_append_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "displayCategory". All fields are combined with a logical 'AND'. */
	["displayCategory_bool_exp"]: {
		_and?: Array<ModelTypes["displayCategory_bool_exp"]> | undefined,
		_not?: ModelTypes["displayCategory_bool_exp"] | undefined,
		_or?: Array<ModelTypes["displayCategory_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		displayName?: ModelTypes["jsonb_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined
	};
	["displayCategory_constraint"]: displayCategory_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayCategory_delete_at_path_input"]: {
		displayName?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayCategory_delete_elem_input"]: {
		displayName?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayCategory_delete_key_input"]: {
		displayName?: string | undefined
	};
	/** input type for inserting data into table "displayCategory" */
	["displayCategory_insert_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** aggregate max on columns */
	["displayCategory_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** aggregate min on columns */
	["displayCategory_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** response of any mutation on the table "displayCategory" */
	["displayCategory_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["displayCategory"]>
	};
	/** on_conflict condition type for table "displayCategory" */
	["displayCategory_on_conflict"]: {
		constraint: ModelTypes["displayCategory_constraint"],
		update_columns: Array<ModelTypes["displayCategory_update_column"]>,
		where?: ModelTypes["displayCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "displayCategory". */
	["displayCategory_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		displayName?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: displayCategory */
	["displayCategory_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayCategory_prepend_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined
	};
	["displayCategory_select_column"]: displayCategory_select_column;
	/** input type for updating data in table "displayCategory" */
	["displayCategory_set_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** Streaming cursor of the table "displayCategory" */
	["displayCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["displayCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["displayCategory_stream_cursor_value_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	["displayCategory_update_column"]: displayCategory_update_column;
	["displayCategory_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["displayCategory_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["displayCategory_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["displayCategory_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["displayCategory_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["displayCategory_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["displayCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["displayCategory_bool_exp"]
	};
	/** columns and relationships of "displayDimension" */
	["displayDimension"]: {
		displayName: ModelTypes["jsonb"],
		id: ModelTypes["uuid"],
		name: string
	};
	/** aggregated selection of "displayDimension" */
	["displayDimension_aggregate"]: {
		aggregate?: ModelTypes["displayDimension_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["displayDimension"]>
	};
	/** aggregate fields of "displayDimension" */
	["displayDimension_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["displayDimension_max_fields"] | undefined,
		min?: ModelTypes["displayDimension_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayDimension_append_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "displayDimension". All fields are combined with a logical 'AND'. */
	["displayDimension_bool_exp"]: {
		_and?: Array<ModelTypes["displayDimension_bool_exp"]> | undefined,
		_not?: ModelTypes["displayDimension_bool_exp"] | undefined,
		_or?: Array<ModelTypes["displayDimension_bool_exp"]> | undefined,
		displayName?: ModelTypes["jsonb_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined
	};
	["displayDimension_constraint"]: displayDimension_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayDimension_delete_at_path_input"]: {
		displayName?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayDimension_delete_elem_input"]: {
		displayName?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayDimension_delete_key_input"]: {
		displayName?: string | undefined
	};
	/** input type for inserting data into table "displayDimension" */
	["displayDimension_insert_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** aggregate max on columns */
	["displayDimension_max_fields"]: {
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** aggregate min on columns */
	["displayDimension_min_fields"]: {
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** response of any mutation on the table "displayDimension" */
	["displayDimension_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["displayDimension"]>
	};
	/** input type for inserting object relation for remote table "displayDimension" */
	["displayDimension_obj_rel_insert_input"]: {
		data: ModelTypes["displayDimension_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["displayDimension_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "displayDimension" */
	["displayDimension_on_conflict"]: {
		constraint: ModelTypes["displayDimension_constraint"],
		update_columns: Array<ModelTypes["displayDimension_update_column"]>,
		where?: ModelTypes["displayDimension_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "displayDimension". */
	["displayDimension_order_by"]: {
		displayName?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: displayDimension */
	["displayDimension_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayDimension_prepend_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined
	};
	["displayDimension_select_column"]: displayDimension_select_column;
	/** input type for updating data in table "displayDimension" */
	["displayDimension_set_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** Streaming cursor of the table "displayDimension" */
	["displayDimension_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["displayDimension_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["displayDimension_stream_cursor_value_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined
	};
	["displayDimension_update_column"]: displayDimension_update_column;
	["displayDimension_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["displayDimension_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["displayDimension_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["displayDimension_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["displayDimension_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["displayDimension_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["displayDimension_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["displayDimension_bool_exp"]
	};
	/** columns and relationships of "displayFormats" */
	["displayFormats"]: {
		bannerbearId: string,
		created_at: ModelTypes["timestamptz"],
		displayCategoryId: ModelTypes["uuid"],
		/** An object relationship */
		displayDimension?: ModelTypes["displayDimension"] | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		displayName: ModelTypes["jsonb"],
		id: ModelTypes["uuid"],
		mapper: ModelTypes["jsonb"],
		name: string,
		newData?: ModelTypes["jsonb"] | undefined,
		newTemplate?: string | undefined,
		requiresBrand: boolean
	};
	/** aggregated selection of "displayFormats" */
	["displayFormats_aggregate"]: {
		aggregate?: ModelTypes["displayFormats_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["displayFormats"]>
	};
	/** aggregate fields of "displayFormats" */
	["displayFormats_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["displayFormats_max_fields"] | undefined,
		min?: ModelTypes["displayFormats_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayFormats_append_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined,
		mapper?: ModelTypes["jsonb"] | undefined,
		newData?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "displayFormats". All fields are combined with a logical 'AND'. */
	["displayFormats_bool_exp"]: {
		_and?: Array<ModelTypes["displayFormats_bool_exp"]> | undefined,
		_not?: ModelTypes["displayFormats_bool_exp"] | undefined,
		_or?: Array<ModelTypes["displayFormats_bool_exp"]> | undefined,
		bannerbearId?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		displayCategoryId?: ModelTypes["uuid_comparison_exp"] | undefined,
		displayDimension?: ModelTypes["displayDimension_bool_exp"] | undefined,
		displayDimensionId?: ModelTypes["uuid_comparison_exp"] | undefined,
		displayName?: ModelTypes["jsonb_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		mapper?: ModelTypes["jsonb_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined,
		newData?: ModelTypes["jsonb_comparison_exp"] | undefined,
		newTemplate?: ModelTypes["String_comparison_exp"] | undefined,
		requiresBrand?: ModelTypes["Boolean_comparison_exp"] | undefined
	};
	["displayFormats_constraint"]: displayFormats_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayFormats_delete_at_path_input"]: {
		displayName?: Array<string> | undefined,
		mapper?: Array<string> | undefined,
		newData?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayFormats_delete_elem_input"]: {
		displayName?: number | undefined,
		mapper?: number | undefined,
		newData?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayFormats_delete_key_input"]: {
		displayName?: string | undefined,
		mapper?: string | undefined,
		newData?: string | undefined
	};
	/** input type for inserting data into table "displayFormats" */
	["displayFormats_insert_input"]: {
		bannerbearId?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		displayCategoryId?: ModelTypes["uuid"] | undefined,
		displayDimension?: ModelTypes["displayDimension_obj_rel_insert_input"] | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mapper?: ModelTypes["jsonb"] | undefined,
		name?: string | undefined,
		newData?: ModelTypes["jsonb"] | undefined,
		newTemplate?: string | undefined,
		requiresBrand?: boolean | undefined
	};
	/** aggregate max on columns */
	["displayFormats_max_fields"]: {
		bannerbearId?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		displayCategoryId?: ModelTypes["uuid"] | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		newTemplate?: string | undefined
	};
	/** aggregate min on columns */
	["displayFormats_min_fields"]: {
		bannerbearId?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		displayCategoryId?: ModelTypes["uuid"] | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		newTemplate?: string | undefined
	};
	/** response of any mutation on the table "displayFormats" */
	["displayFormats_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["displayFormats"]>
	};
	/** input type for inserting object relation for remote table "displayFormats" */
	["displayFormats_obj_rel_insert_input"]: {
		data: ModelTypes["displayFormats_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["displayFormats_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "displayFormats" */
	["displayFormats_on_conflict"]: {
		constraint: ModelTypes["displayFormats_constraint"],
		update_columns: Array<ModelTypes["displayFormats_update_column"]>,
		where?: ModelTypes["displayFormats_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "displayFormats". */
	["displayFormats_order_by"]: {
		bannerbearId?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		displayCategoryId?: ModelTypes["order_by"] | undefined,
		displayDimension?: ModelTypes["displayDimension_order_by"] | undefined,
		displayDimensionId?: ModelTypes["order_by"] | undefined,
		displayName?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		mapper?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		newData?: ModelTypes["order_by"] | undefined,
		newTemplate?: ModelTypes["order_by"] | undefined,
		requiresBrand?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: displayFormats */
	["displayFormats_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayFormats_prepend_input"]: {
		displayName?: ModelTypes["jsonb"] | undefined,
		mapper?: ModelTypes["jsonb"] | undefined,
		newData?: ModelTypes["jsonb"] | undefined
	};
	["displayFormats_select_column"]: displayFormats_select_column;
	/** input type for updating data in table "displayFormats" */
	["displayFormats_set_input"]: {
		bannerbearId?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		displayCategoryId?: ModelTypes["uuid"] | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mapper?: ModelTypes["jsonb"] | undefined,
		name?: string | undefined,
		newData?: ModelTypes["jsonb"] | undefined,
		newTemplate?: string | undefined,
		requiresBrand?: boolean | undefined
	};
	/** Streaming cursor of the table "displayFormats" */
	["displayFormats_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["displayFormats_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["displayFormats_stream_cursor_value_input"]: {
		bannerbearId?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		displayCategoryId?: ModelTypes["uuid"] | undefined,
		displayDimensionId?: ModelTypes["uuid"] | undefined,
		displayName?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mapper?: ModelTypes["jsonb"] | undefined,
		name?: string | undefined,
		newData?: ModelTypes["jsonb"] | undefined,
		newTemplate?: string | undefined,
		requiresBrand?: boolean | undefined
	};
	["displayFormats_update_column"]: displayFormats_update_column;
	["displayFormats_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["displayFormats_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["displayFormats_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["displayFormats_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["displayFormats_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["displayFormats_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["displayFormats_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["displayFormats_bool_exp"]
	};
	/** columns and relationships of "displayTemplate" */
	["displayTemplate"]: {
		alias: string,
		fields: ModelTypes["jsonb"],
		id: ModelTypes["uuid"],
		template: string
	};
	/** aggregated selection of "displayTemplate" */
	["displayTemplate_aggregate"]: {
		aggregate?: ModelTypes["displayTemplate_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["displayTemplate"]>
	};
	/** aggregate fields of "displayTemplate" */
	["displayTemplate_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["displayTemplate_max_fields"] | undefined,
		min?: ModelTypes["displayTemplate_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayTemplate_append_input"]: {
		fields?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "displayTemplate". All fields are combined with a logical 'AND'. */
	["displayTemplate_bool_exp"]: {
		_and?: Array<ModelTypes["displayTemplate_bool_exp"]> | undefined,
		_not?: ModelTypes["displayTemplate_bool_exp"] | undefined,
		_or?: Array<ModelTypes["displayTemplate_bool_exp"]> | undefined,
		alias?: ModelTypes["String_comparison_exp"] | undefined,
		fields?: ModelTypes["jsonb_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		template?: ModelTypes["String_comparison_exp"] | undefined
	};
	["displayTemplate_constraint"]: displayTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayTemplate_delete_at_path_input"]: {
		fields?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayTemplate_delete_elem_input"]: {
		fields?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayTemplate_delete_key_input"]: {
		fields?: string | undefined
	};
	/** input type for inserting data into table "displayTemplate" */
	["displayTemplate_insert_input"]: {
		alias?: string | undefined,
		fields?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** aggregate max on columns */
	["displayTemplate_max_fields"]: {
		alias?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** aggregate min on columns */
	["displayTemplate_min_fields"]: {
		alias?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** response of any mutation on the table "displayTemplate" */
	["displayTemplate_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["displayTemplate"]>
	};
	/** on_conflict condition type for table "displayTemplate" */
	["displayTemplate_on_conflict"]: {
		constraint: ModelTypes["displayTemplate_constraint"],
		update_columns: Array<ModelTypes["displayTemplate_update_column"]>,
		where?: ModelTypes["displayTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "displayTemplate". */
	["displayTemplate_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		fields?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		template?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: displayTemplate */
	["displayTemplate_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayTemplate_prepend_input"]: {
		fields?: ModelTypes["jsonb"] | undefined
	};
	["displayTemplate_select_column"]: displayTemplate_select_column;
	/** input type for updating data in table "displayTemplate" */
	["displayTemplate_set_input"]: {
		alias?: string | undefined,
		fields?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** Streaming cursor of the table "displayTemplate" */
	["displayTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["displayTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["displayTemplate_stream_cursor_value_input"]: {
		alias?: string | undefined,
		fields?: ModelTypes["jsonb"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		template?: string | undefined
	};
	["displayTemplate_update_column"]: displayTemplate_update_column;
	["displayTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["displayTemplate_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["displayTemplate_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["displayTemplate_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["displayTemplate_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["displayTemplate_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["displayTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["displayTemplate_bool_exp"]
	};
	["facebookPage"]: {
		id: string,
		name: string
	};
	/** columns and relationships of "storage.files" */
	["files"]: {
		/** An object relationship */
		bucket: ModelTypes["buckets"],
		bucketId: string,
		createdAt: ModelTypes["timestamptz"],
		etag?: string | undefined,
		id: ModelTypes["uuid"],
		isUploaded?: boolean | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt: ModelTypes["timestamptz"],
		uploadedByUserId?: ModelTypes["uuid"] | undefined
	};
	/** aggregated selection of "storage.files" */
	["files_aggregate"]: {
		aggregate?: ModelTypes["files_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["files"]>
	};
	["files_aggregate_bool_exp"]: {
		bool_and?: ModelTypes["files_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: ModelTypes["files_aggregate_bool_exp_bool_or"] | undefined,
		count?: ModelTypes["files_aggregate_bool_exp_count"] | undefined
	};
	["files_aggregate_bool_exp_bool_and"]: {
		arguments: ModelTypes["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["files_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["files_aggregate_bool_exp_bool_or"]: {
		arguments: ModelTypes["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["files_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["files_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["files_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["files_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "storage.files" */
	["files_aggregate_fields"]: {
		avg?: ModelTypes["files_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["files_max_fields"] | undefined,
		min?: ModelTypes["files_min_fields"] | undefined,
		stddev?: ModelTypes["files_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["files_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["files_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["files_sum_fields"] | undefined,
		var_pop?: ModelTypes["files_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["files_var_samp_fields"] | undefined,
		variance?: ModelTypes["files_variance_fields"] | undefined
	};
	/** order by aggregate values of table "storage.files" */
	["files_aggregate_order_by"]: {
		avg?: ModelTypes["files_avg_order_by"] | undefined,
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["files_max_order_by"] | undefined,
		min?: ModelTypes["files_min_order_by"] | undefined,
		stddev?: ModelTypes["files_stddev_order_by"] | undefined,
		stddev_pop?: ModelTypes["files_stddev_pop_order_by"] | undefined,
		stddev_samp?: ModelTypes["files_stddev_samp_order_by"] | undefined,
		sum?: ModelTypes["files_sum_order_by"] | undefined,
		var_pop?: ModelTypes["files_var_pop_order_by"] | undefined,
		var_samp?: ModelTypes["files_var_samp_order_by"] | undefined,
		variance?: ModelTypes["files_variance_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["files_append_input"]: {
		metadata?: ModelTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "storage.files" */
	["files_arr_rel_insert_input"]: {
		data: Array<ModelTypes["files_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["files_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["files_avg_fields"]: {
		size?: number | undefined
	};
	/** order by avg() on columns of table "storage.files" */
	["files_avg_order_by"]: {
		size?: ModelTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
	["files_bool_exp"]: {
		_and?: Array<ModelTypes["files_bool_exp"]> | undefined,
		_not?: ModelTypes["files_bool_exp"] | undefined,
		_or?: Array<ModelTypes["files_bool_exp"]> | undefined,
		bucket?: ModelTypes["buckets_bool_exp"] | undefined,
		bucketId?: ModelTypes["String_comparison_exp"] | undefined,
		createdAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		etag?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		isUploaded?: ModelTypes["Boolean_comparison_exp"] | undefined,
		metadata?: ModelTypes["jsonb_comparison_exp"] | undefined,
		mimeType?: ModelTypes["String_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined,
		size?: ModelTypes["Int_comparison_exp"] | undefined,
		updatedAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		uploadedByUserId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["files_constraint"]: files_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["files_delete_at_path_input"]: {
		metadata?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["files_delete_elem_input"]: {
		metadata?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["files_delete_key_input"]: {
		metadata?: string | undefined
	};
	/** input type for incrementing numeric columns in table "storage.files" */
	["files_inc_input"]: {
		size?: number | undefined
	};
	/** input type for inserting data into table "storage.files" */
	["files_insert_input"]: {
		bucket?: ModelTypes["buckets_obj_rel_insert_input"] | undefined,
		bucketId?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		isUploaded?: boolean | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		uploadedByUserId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["files_max_fields"]: {
		bucketId?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		uploadedByUserId?: ModelTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "storage.files" */
	["files_max_order_by"]: {
		bucketId?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		etag?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		mimeType?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		size?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined,
		uploadedByUserId?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["files_min_fields"]: {
		bucketId?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		uploadedByUserId?: ModelTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "storage.files" */
	["files_min_order_by"]: {
		bucketId?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		etag?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		mimeType?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		size?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined,
		uploadedByUserId?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "storage.files" */
	["files_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["files"]>
	};
	/** input type for inserting object relation for remote table "storage.files" */
	["files_obj_rel_insert_input"]: {
		data: ModelTypes["files_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["files_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "storage.files" */
	["files_on_conflict"]: {
		constraint: ModelTypes["files_constraint"],
		update_columns: Array<ModelTypes["files_update_column"]>,
		where?: ModelTypes["files_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "storage.files". */
	["files_order_by"]: {
		bucket?: ModelTypes["buckets_order_by"] | undefined,
		bucketId?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		etag?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		isUploaded?: ModelTypes["order_by"] | undefined,
		metadata?: ModelTypes["order_by"] | undefined,
		mimeType?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		size?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined,
		uploadedByUserId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: storage.files */
	["files_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["files_prepend_input"]: {
		metadata?: ModelTypes["jsonb"] | undefined
	};
	["files_select_column"]: files_select_column;
	["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"]: files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns;
	["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"]: files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "storage.files" */
	["files_set_input"]: {
		bucketId?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		isUploaded?: boolean | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		uploadedByUserId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate stddev on columns */
	["files_stddev_fields"]: {
		size?: number | undefined
	};
	/** order by stddev() on columns of table "storage.files" */
	["files_stddev_order_by"]: {
		size?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["files_stddev_pop_fields"]: {
		size?: number | undefined
	};
	/** order by stddev_pop() on columns of table "storage.files" */
	["files_stddev_pop_order_by"]: {
		size?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["files_stddev_samp_fields"]: {
		size?: number | undefined
	};
	/** order by stddev_samp() on columns of table "storage.files" */
	["files_stddev_samp_order_by"]: {
		size?: ModelTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "files" */
	["files_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["files_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["files_stream_cursor_value_input"]: {
		bucketId?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		isUploaded?: boolean | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		uploadedByUserId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["files_sum_fields"]: {
		size?: number | undefined
	};
	/** order by sum() on columns of table "storage.files" */
	["files_sum_order_by"]: {
		size?: ModelTypes["order_by"] | undefined
	};
	["files_update_column"]: files_update_column;
	["files_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["files_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["files_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["files_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["files_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["files_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["files_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["files_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["files_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["files_var_pop_fields"]: {
		size?: number | undefined
	};
	/** order by var_pop() on columns of table "storage.files" */
	["files_var_pop_order_by"]: {
		size?: ModelTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["files_var_samp_fields"]: {
		size?: number | undefined
	};
	/** order by var_samp() on columns of table "storage.files" */
	["files_var_samp_order_by"]: {
		size?: ModelTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["files_variance_fields"]: {
		size?: number | undefined
	};
	/** order by variance() on columns of table "storage.files" */
	["files_variance_order_by"]: {
		size?: ModelTypes["order_by"] | undefined
	};
	/** fields of action: "generateNextMonthCalendar" */
	["generateNextMonthCalendar"]: {
		/** the time at which this action was created */
		created_at: ModelTypes["timestamptz"],
		/** errors related to the invocation */
		errors?: ModelTypes["json"] | undefined,
		/** the unique id of an action */
		id: ModelTypes["uuid"],
		/** the output fields of this action */
		output?: ModelTypes["GenerateNextMonthCalendarOutput"] | undefined
	};
	["instagramPageAccount"]: {
		id: string,
		instagramBusinessAccountId?: string | undefined,
		name: string
	};
	/** columns and relationships of "interaction" */
	["interaction"]: {
		channel: ModelTypes["uuid"],
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		date: ModelTypes["timestamptz"],
		dealId: ModelTypes["uuid"],
		id: ModelTypes["uuid"],
		observation: string,
		success: boolean,
		updated_at: ModelTypes["timestamptz"],
		/** An object relationship */
		user: ModelTypes["users"]
	};
	/** aggregated selection of "interaction" */
	["interaction_aggregate"]: {
		aggregate?: ModelTypes["interaction_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["interaction"]>
	};
	["interaction_aggregate_bool_exp"]: {
		bool_and?: ModelTypes["interaction_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: ModelTypes["interaction_aggregate_bool_exp_bool_or"] | undefined,
		count?: ModelTypes["interaction_aggregate_bool_exp_count"] | undefined
	};
	["interaction_aggregate_bool_exp_bool_and"]: {
		arguments: ModelTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["interaction_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["interaction_aggregate_bool_exp_bool_or"]: {
		arguments: ModelTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["interaction_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["interaction_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["interaction_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["interaction_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "interaction" */
	["interaction_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["interaction_max_fields"] | undefined,
		min?: ModelTypes["interaction_min_fields"] | undefined
	};
	/** order by aggregate values of table "interaction" */
	["interaction_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["interaction_max_order_by"] | undefined,
		min?: ModelTypes["interaction_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "interaction" */
	["interaction_arr_rel_insert_input"]: {
		data: Array<ModelTypes["interaction_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["interaction_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "interaction". All fields are combined with a logical 'AND'. */
	["interaction_bool_exp"]: {
		_and?: Array<ModelTypes["interaction_bool_exp"]> | undefined,
		_not?: ModelTypes["interaction_bool_exp"] | undefined,
		_or?: Array<ModelTypes["interaction_bool_exp"]> | undefined,
		channel?: ModelTypes["uuid_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		date?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		dealId?: ModelTypes["uuid_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		observation?: ModelTypes["String_comparison_exp"] | undefined,
		success?: ModelTypes["Boolean_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		user?: ModelTypes["users_bool_exp"] | undefined
	};
	["interaction_constraint"]: interaction_constraint;
	/** input type for inserting data into table "interaction" */
	["interaction_insert_input"]: {
		channel?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamptz"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		observation?: string | undefined,
		success?: boolean | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		user?: ModelTypes["users_obj_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["interaction_max_fields"]: {
		channel?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamptz"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		observation?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "interaction" */
	["interaction_max_order_by"]: {
		channel?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		date?: ModelTypes["order_by"] | undefined,
		dealId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		observation?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["interaction_min_fields"]: {
		channel?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamptz"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		observation?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "interaction" */
	["interaction_min_order_by"]: {
		channel?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		date?: ModelTypes["order_by"] | undefined,
		dealId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		observation?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "interaction" */
	["interaction_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["interaction"]>
	};
	/** on_conflict condition type for table "interaction" */
	["interaction_on_conflict"]: {
		constraint: ModelTypes["interaction_constraint"],
		update_columns: Array<ModelTypes["interaction_update_column"]>,
		where?: ModelTypes["interaction_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "interaction". */
	["interaction_order_by"]: {
		channel?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		date?: ModelTypes["order_by"] | undefined,
		dealId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		observation?: ModelTypes["order_by"] | undefined,
		success?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		user?: ModelTypes["users_order_by"] | undefined
	};
	/** primary key columns input for table: interaction */
	["interaction_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["interaction_select_column"]: interaction_select_column;
	["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"]: interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns;
	["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"]: interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "interaction" */
	["interaction_set_input"]: {
		channel?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamptz"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		observation?: string | undefined,
		success?: boolean | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "interaction" */
	["interaction_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["interaction_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["interaction_stream_cursor_value_input"]: {
		channel?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamptz"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		observation?: string | undefined,
		success?: boolean | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["interaction_update_column"]: interaction_update_column;
	["interaction_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["interaction_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["interaction_bool_exp"]
	};
	["json"]: any;
	["jsonb"]: any;
	["jsonb_cast_exp"]: {
		String?: ModelTypes["String_comparison_exp"] | undefined
	};
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
	["jsonb_comparison_exp"]: {
		_cast?: ModelTypes["jsonb_cast_exp"] | undefined,
		/** is the column contained in the given json value */
		_contained_in?: ModelTypes["jsonb"] | undefined,
		/** does the column contain the given json value at the top level */
		_contains?: ModelTypes["jsonb"] | undefined,
		_eq?: ModelTypes["jsonb"] | undefined,
		_gt?: ModelTypes["jsonb"] | undefined,
		_gte?: ModelTypes["jsonb"] | undefined,
		/** does the string exist as a top-level key in the column */
		_has_key?: string | undefined,
		/** do all of these strings exist as top-level keys in the column */
		_has_keys_all?: Array<string> | undefined,
		/** do any of these strings exist as top-level keys in the column */
		_has_keys_any?: Array<string> | undefined,
		_in?: Array<ModelTypes["jsonb"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: ModelTypes["jsonb"] | undefined,
		_lte?: ModelTypes["jsonb"] | undefined,
		_neq?: ModelTypes["jsonb"] | undefined,
		_nin?: Array<ModelTypes["jsonb"]> | undefined
	};
	/** columns and relationships of "landingPageTemplate" */
	["landingPageTemplate"]: {
		created_at: ModelTypes["timestamptz"],
		htmlTemplate: string,
		id: ModelTypes["uuid"],
		updated_at: ModelTypes["timestamptz"]
	};
	/** aggregated selection of "landingPageTemplate" */
	["landingPageTemplate_aggregate"]: {
		aggregate?: ModelTypes["landingPageTemplate_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["landingPageTemplate"]>
	};
	/** aggregate fields of "landingPageTemplate" */
	["landingPageTemplate_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["landingPageTemplate_max_fields"] | undefined,
		min?: ModelTypes["landingPageTemplate_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "landingPageTemplate". All fields are combined with a logical 'AND'. */
	["landingPageTemplate_bool_exp"]: {
		_and?: Array<ModelTypes["landingPageTemplate_bool_exp"]> | undefined,
		_not?: ModelTypes["landingPageTemplate_bool_exp"] | undefined,
		_or?: Array<ModelTypes["landingPageTemplate_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		htmlTemplate?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["landingPageTemplate_constraint"]: landingPageTemplate_constraint;
	/** input type for inserting data into table "landingPageTemplate" */
	["landingPageTemplate_insert_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["landingPageTemplate_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["landingPageTemplate_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "landingPageTemplate" */
	["landingPageTemplate_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["landingPageTemplate"]>
	};
	/** on_conflict condition type for table "landingPageTemplate" */
	["landingPageTemplate_on_conflict"]: {
		constraint: ModelTypes["landingPageTemplate_constraint"],
		update_columns: Array<ModelTypes["landingPageTemplate_update_column"]>,
		where?: ModelTypes["landingPageTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "landingPageTemplate". */
	["landingPageTemplate_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		htmlTemplate?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: landingPageTemplate */
	["landingPageTemplate_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["landingPageTemplate_select_column"]: landingPageTemplate_select_column;
	/** input type for updating data in table "landingPageTemplate" */
	["landingPageTemplate_set_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "landingPageTemplate" */
	["landingPageTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["landingPageTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["landingPageTemplate_stream_cursor_value_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["landingPageTemplate_update_column"]: landingPageTemplate_update_column;
	["landingPageTemplate_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["landingPageTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["landingPageTemplate_bool_exp"]
	};
	/** mutation root */
	["mutation_root"]: {
		blogPostGeneratorEnhancedForAmp: ModelTypes["uuid"],
		createAutomaticProject?: ModelTypes["CreateAutomaticProjectOutput"] | undefined,
		createImageUsingImagine?: ModelTypes["CreateImageUsingImagineOutput"] | undefined,
		/** delete single row from the table: "auth.providers" */
		deleteAuthProvider?: ModelTypes["authProviders"] | undefined,
		/** delete single row from the table: "auth.provider_requests" */
		deleteAuthProviderRequest?: ModelTypes["authProviderRequests"] | undefined,
		/** delete data from the table: "auth.provider_requests" */
		deleteAuthProviderRequests?: ModelTypes["authProviderRequests_mutation_response"] | undefined,
		/** delete data from the table: "auth.providers" */
		deleteAuthProviders?: ModelTypes["authProviders_mutation_response"] | undefined,
		/** delete single row from the table: "auth.refresh_tokens" */
		deleteAuthRefreshToken?: ModelTypes["authRefreshTokens"] | undefined,
		/** delete single row from the table: "auth.refresh_token_types" */
		deleteAuthRefreshTokenType?: ModelTypes["authRefreshTokenTypes"] | undefined,
		/** delete data from the table: "auth.refresh_token_types" */
		deleteAuthRefreshTokenTypes?: ModelTypes["authRefreshTokenTypes_mutation_response"] | undefined,
		/** delete data from the table: "auth.refresh_tokens" */
		deleteAuthRefreshTokens?: ModelTypes["authRefreshTokens_mutation_response"] | undefined,
		/** delete single row from the table: "auth.roles" */
		deleteAuthRole?: ModelTypes["authRoles"] | undefined,
		/** delete data from the table: "auth.roles" */
		deleteAuthRoles?: ModelTypes["authRoles_mutation_response"] | undefined,
		/** delete single row from the table: "auth.user_providers" */
		deleteAuthUserProvider?: ModelTypes["authUserProviders"] | undefined,
		/** delete data from the table: "auth.user_providers" */
		deleteAuthUserProviders?: ModelTypes["authUserProviders_mutation_response"] | undefined,
		/** delete single row from the table: "auth.user_roles" */
		deleteAuthUserRole?: ModelTypes["authUserRoles"] | undefined,
		/** delete data from the table: "auth.user_roles" */
		deleteAuthUserRoles?: ModelTypes["authUserRoles_mutation_response"] | undefined,
		/** delete single row from the table: "auth.user_security_keys" */
		deleteAuthUserSecurityKey?: ModelTypes["authUserSecurityKeys"] | undefined,
		/** delete data from the table: "auth.user_security_keys" */
		deleteAuthUserSecurityKeys?: ModelTypes["authUserSecurityKeys_mutation_response"] | undefined,
		/** delete single row from the table: "storage.buckets" */
		deleteBucket?: ModelTypes["buckets"] | undefined,
		/** delete data from the table: "storage.buckets" */
		deleteBuckets?: ModelTypes["buckets_mutation_response"] | undefined,
		/** delete single row from the table: "storage.files" */
		deleteFile?: ModelTypes["files"] | undefined,
		/** delete data from the table: "storage.files" */
		deleteFiles?: ModelTypes["files_mutation_response"] | undefined,
		/** delete single row from the table: "auth.users" */
		deleteUser?: ModelTypes["users"] | undefined,
		/** delete data from the table: "auth.users" */
		deleteUsers?: ModelTypes["users_mutation_response"] | undefined,
		/** delete single row from the table: "storage.virus" */
		deleteVirus?: ModelTypes["virus"] | undefined,
		/** delete data from the table: "storage.virus" */
		deleteViruses?: ModelTypes["virus_mutation_response"] | undefined,
		/** delete data from the table: "AppIntegration" */
		delete_AppIntegration?: ModelTypes["AppIntegration_mutation_response"] | undefined,
		/** delete single row from the table: "AppIntegration" */
		delete_AppIntegration_by_pk?: ModelTypes["AppIntegration"] | undefined,
		/** delete data from the table: "Course" */
		delete_Course?: ModelTypes["Course_mutation_response"] | undefined,
		/** delete data from the table: "CourseCategory" */
		delete_CourseCategory?: ModelTypes["CourseCategory_mutation_response"] | undefined,
		/** delete single row from the table: "CourseCategory" */
		delete_CourseCategory_by_pk?: ModelTypes["CourseCategory"] | undefined,
		/** delete single row from the table: "Course" */
		delete_Course_by_pk?: ModelTypes["Course"] | undefined,
		/** delete data from the table: "GeneratedPromptByTemplate" */
		delete_GeneratedPromptByTemplate?: ModelTypes["GeneratedPromptByTemplate_mutation_response"] | undefined,
		/** delete data from the table: "GeneratedPromptByTemplateImage" */
		delete_GeneratedPromptByTemplateImage?: ModelTypes["GeneratedPromptByTemplateImage_mutation_response"] | undefined,
		/** delete single row from the table: "GeneratedPromptByTemplateImage" */
		delete_GeneratedPromptByTemplateImage_by_pk?: ModelTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** delete single row from the table: "GeneratedPromptByTemplate" */
		delete_GeneratedPromptByTemplate_by_pk?: ModelTypes["GeneratedPromptByTemplate"] | undefined,
		/** delete data from the table: "TextGeneratorOption" */
		delete_TextGeneratorOption?: ModelTypes["TextGeneratorOption_mutation_response"] | undefined,
		/** delete data from the table: "TextGeneratorOptionEntry" */
		delete_TextGeneratorOptionEntry?: ModelTypes["TextGeneratorOptionEntry_mutation_response"] | undefined,
		/** delete single row from the table: "TextGeneratorOptionEntry" */
		delete_TextGeneratorOptionEntry_by_pk?: ModelTypes["TextGeneratorOptionEntry"] | undefined,
		/** delete single row from the table: "TextGeneratorOption" */
		delete_TextGeneratorOption_by_pk?: ModelTypes["TextGeneratorOption"] | undefined,
		/** delete data from the table: "TextPromptTemplate" */
		delete_TextPromptTemplate?: ModelTypes["TextPromptTemplate_mutation_response"] | undefined,
		/** delete data from the table: "TextPromptTemplateCategory" */
		delete_TextPromptTemplateCategory?: ModelTypes["TextPromptTemplateCategory_mutation_response"] | undefined,
		/** delete single row from the table: "TextPromptTemplateCategory" */
		delete_TextPromptTemplateCategory_by_pk?: ModelTypes["TextPromptTemplateCategory"] | undefined,
		/** delete single row from the table: "TextPromptTemplate" */
		delete_TextPromptTemplate_by_pk?: ModelTypes["TextPromptTemplate"] | undefined,
		/** delete data from the table: "TextTemplateClientCategory" */
		delete_TextTemplateClientCategory?: ModelTypes["TextTemplateClientCategory_mutation_response"] | undefined,
		/** delete single row from the table: "TextTemplateClientCategory" */
		delete_TextTemplateClientCategory_by_pk?: ModelTypes["TextTemplateClientCategory"] | undefined,
		/** delete data from the table: "audiences" */
		delete_audiences?: ModelTypes["audiences_mutation_response"] | undefined,
		/** delete single row from the table: "audiences" */
		delete_audiences_by_pk?: ModelTypes["audiences"] | undefined,
		/** delete data from the table: "blog" */
		delete_blog?: ModelTypes["blog_mutation_response"] | undefined,
		/** delete data from the table: "blogCategory" */
		delete_blogCategory?: ModelTypes["blogCategory_mutation_response"] | undefined,
		/** delete single row from the table: "blogCategory" */
		delete_blogCategory_by_pk?: ModelTypes["blogCategory"] | undefined,
		/** delete single row from the table: "blog" */
		delete_blog_by_pk?: ModelTypes["blog"] | undefined,
		/** delete data from the table: "brands" */
		delete_brands?: ModelTypes["brands_mutation_response"] | undefined,
		/** delete single row from the table: "brands" */
		delete_brands_by_pk?: ModelTypes["brands"] | undefined,
		/** delete data from the table: "campaign" */
		delete_campaign?: ModelTypes["campaign_mutation_response"] | undefined,
		/** delete single row from the table: "campaign" */
		delete_campaign_by_pk?: ModelTypes["campaign"] | undefined,
		/** delete data from the table: "channels" */
		delete_channels?: ModelTypes["channels_mutation_response"] | undefined,
		/** delete single row from the table: "channels" */
		delete_channels_by_pk?: ModelTypes["channels"] | undefined,
		/** delete data from the table: "costTable" */
		delete_costTable?: ModelTypes["costTable_mutation_response"] | undefined,
		/** delete single row from the table: "costTable" */
		delete_costTable_by_pk?: ModelTypes["costTable"] | undefined,
		/** delete data from the table: "deal" */
		delete_deal?: ModelTypes["deal_mutation_response"] | undefined,
		/** delete data from the table: "dealStatus" */
		delete_dealStatus?: ModelTypes["dealStatus_mutation_response"] | undefined,
		/** delete single row from the table: "dealStatus" */
		delete_dealStatus_by_pk?: ModelTypes["dealStatus"] | undefined,
		/** delete single row from the table: "deal" */
		delete_deal_by_pk?: ModelTypes["deal"] | undefined,
		/** delete data from the table: "displayCategory" */
		delete_displayCategory?: ModelTypes["displayCategory_mutation_response"] | undefined,
		/** delete single row from the table: "displayCategory" */
		delete_displayCategory_by_pk?: ModelTypes["displayCategory"] | undefined,
		/** delete data from the table: "displayDimension" */
		delete_displayDimension?: ModelTypes["displayDimension_mutation_response"] | undefined,
		/** delete single row from the table: "displayDimension" */
		delete_displayDimension_by_pk?: ModelTypes["displayDimension"] | undefined,
		/** delete data from the table: "displayFormats" */
		delete_displayFormats?: ModelTypes["displayFormats_mutation_response"] | undefined,
		/** delete single row from the table: "displayFormats" */
		delete_displayFormats_by_pk?: ModelTypes["displayFormats"] | undefined,
		/** delete data from the table: "displayTemplate" */
		delete_displayTemplate?: ModelTypes["displayTemplate_mutation_response"] | undefined,
		/** delete single row from the table: "displayTemplate" */
		delete_displayTemplate_by_pk?: ModelTypes["displayTemplate"] | undefined,
		/** delete data from the table: "interaction" */
		delete_interaction?: ModelTypes["interaction_mutation_response"] | undefined,
		/** delete single row from the table: "interaction" */
		delete_interaction_by_pk?: ModelTypes["interaction"] | undefined,
		/** delete data from the table: "landingPageTemplate" */
		delete_landingPageTemplate?: ModelTypes["landingPageTemplate_mutation_response"] | undefined,
		/** delete single row from the table: "landingPageTemplate" */
		delete_landingPageTemplate_by_pk?: ModelTypes["landingPageTemplate"] | undefined,
		/** delete data from the table: "newTemplateType" */
		delete_newTemplateType?: ModelTypes["newTemplateType_mutation_response"] | undefined,
		/** delete single row from the table: "newTemplateType" */
		delete_newTemplateType_by_pk?: ModelTypes["newTemplateType"] | undefined,
		/** delete data from the table: "openAIApiKey" */
		delete_openAIApiKey?: ModelTypes["openAIApiKey_mutation_response"] | undefined,
		/** delete single row from the table: "openAIApiKey" */
		delete_openAIApiKey_by_pk?: ModelTypes["openAIApiKey"] | undefined,
		/** delete data from the table: "pTemplate" */
		delete_pTemplate?: ModelTypes["pTemplate_mutation_response"] | undefined,
		/** delete single row from the table: "pTemplate" */
		delete_pTemplate_by_pk?: ModelTypes["pTemplate"] | undefined,
		/** delete data from the table: "pricingPlan" */
		delete_pricingPlan?: ModelTypes["pricingPlan_mutation_response"] | undefined,
		/** delete single row from the table: "pricingPlan" */
		delete_pricingPlan_by_pk?: ModelTypes["pricingPlan"] | undefined,
		/** delete data from the table: "products" */
		delete_products?: ModelTypes["products_mutation_response"] | undefined,
		/** delete single row from the table: "products" */
		delete_products_by_pk?: ModelTypes["products"] | undefined,
		/** delete data from the table: "project" */
		delete_project?: ModelTypes["project_mutation_response"] | undefined,
		/** delete data from the table: "projectImage" */
		delete_projectImage?: ModelTypes["projectImage_mutation_response"] | undefined,
		/** delete single row from the table: "projectImage" */
		delete_projectImage_by_pk?: ModelTypes["projectImage"] | undefined,
		/** delete single row from the table: "project" */
		delete_project_by_pk?: ModelTypes["project"] | undefined,
		/** delete data from the table: "task" */
		delete_task?: ModelTypes["task_mutation_response"] | undefined,
		/** delete single row from the table: "task" */
		delete_task_by_pk?: ModelTypes["task"] | undefined,
		/** delete data from the table: "textTemplateDisplayFormat" */
		delete_textTemplateDisplayFormat?: ModelTypes["textTemplateDisplayFormat_mutation_response"] | undefined,
		/** delete single row from the table: "textTemplateDisplayFormat" */
		delete_textTemplateDisplayFormat_by_pk?: ModelTypes["textTemplateDisplayFormat"] | undefined,
		/** delete data from the table: "userAppIntegration" */
		delete_userAppIntegration?: ModelTypes["userAppIntegration_mutation_response"] | undefined,
		/** delete single row from the table: "userAppIntegration" */
		delete_userAppIntegration_by_pk?: ModelTypes["userAppIntegration"] | undefined,
		/** delete data from the table: "userCreditUsage" */
		delete_userCreditUsage?: ModelTypes["userCreditUsage_mutation_response"] | undefined,
		/** delete data from the table: "userCreditUsageSummary" */
		delete_userCreditUsageSummary?: ModelTypes["userCreditUsageSummary_mutation_response"] | undefined,
		/** delete single row from the table: "userCreditUsageSummary" */
		delete_userCreditUsageSummary_by_pk?: ModelTypes["userCreditUsageSummary"] | undefined,
		/** delete single row from the table: "userCreditUsage" */
		delete_userCreditUsage_by_pk?: ModelTypes["userCreditUsage"] | undefined,
		/** delete data from the table: "userCredits" */
		delete_userCredits?: ModelTypes["userCredits_mutation_response"] | undefined,
		/** delete single row from the table: "userCredits" */
		delete_userCredits_by_pk?: ModelTypes["userCredits"] | undefined,
		/** delete data from the table: "userFeedback" */
		delete_userFeedback?: ModelTypes["userFeedback_mutation_response"] | undefined,
		/** delete single row from the table: "userFeedback" */
		delete_userFeedback_by_pk?: ModelTypes["userFeedback"] | undefined,
		/** delete data from the table: "userMedia" */
		delete_userMedia?: ModelTypes["userMedia_mutation_response"] | undefined,
		/** delete single row from the table: "userMedia" */
		delete_userMedia_by_pk?: ModelTypes["userMedia"] | undefined,
		/** delete data from the table: "userMetadata" */
		delete_userMetadata?: ModelTypes["userMetadata_mutation_response"] | undefined,
		/** delete single row from the table: "userMetadata" */
		delete_userMetadata_by_pk?: ModelTypes["userMetadata"] | undefined,
		/** delete data from the table: "userOnboarding" */
		delete_userOnboarding?: ModelTypes["userOnboarding_mutation_response"] | undefined,
		/** delete single row from the table: "userOnboarding" */
		delete_userOnboarding_by_pk?: ModelTypes["userOnboarding"] | undefined,
		/** delete data from the table: "userTemplateUsage" */
		delete_userTemplateUsage?: ModelTypes["userTemplateUsage_mutation_response"] | undefined,
		/** delete single row from the table: "userTemplateUsage" */
		delete_userTemplateUsage_by_pk?: ModelTypes["userTemplateUsage"] | undefined,
		/** delete data from the table: "website" */
		delete_website?: ModelTypes["website_mutation_response"] | undefined,
		/** delete single row from the table: "website" */
		delete_website_by_pk?: ModelTypes["website"] | undefined,
		exportToWordpress?: ModelTypes["ExportToWordpressOutput"] | undefined,
		facebookAuthCallback?: ModelTypes["FacebookAuthCallbackOutput"] | undefined,
		finishOnboard?: ModelTypes["FinishOnboardOutput"] | undefined,
		generateCalendarEx?: ModelTypes["GenerateCalendarExOutput"] | undefined,
		/** generateFromTextTemplate */
		generateFromTextTemplate?: ModelTypes["GenerateFromTextTemplateOutput"] | undefined,
		generateNextMonthCalendar: ModelTypes["uuid"],
		getInstagramAuthCallback?: ModelTypes["GetInstagramAuthCallbackOutput"] | undefined,
		/** execute VOLATILE function "get_next_openai_apikey" which returns "openAIApiKey" */
		get_next_openai_apikey: Array<ModelTypes["openAIApiKey"]>,
		/** insert a single row into the table: "auth.providers" */
		insertAuthProvider?: ModelTypes["authProviders"] | undefined,
		/** insert a single row into the table: "auth.provider_requests" */
		insertAuthProviderRequest?: ModelTypes["authProviderRequests"] | undefined,
		/** insert data into the table: "auth.provider_requests" */
		insertAuthProviderRequests?: ModelTypes["authProviderRequests_mutation_response"] | undefined,
		/** insert data into the table: "auth.providers" */
		insertAuthProviders?: ModelTypes["authProviders_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.refresh_tokens" */
		insertAuthRefreshToken?: ModelTypes["authRefreshTokens"] | undefined,
		/** insert a single row into the table: "auth.refresh_token_types" */
		insertAuthRefreshTokenType?: ModelTypes["authRefreshTokenTypes"] | undefined,
		/** insert data into the table: "auth.refresh_token_types" */
		insertAuthRefreshTokenTypes?: ModelTypes["authRefreshTokenTypes_mutation_response"] | undefined,
		/** insert data into the table: "auth.refresh_tokens" */
		insertAuthRefreshTokens?: ModelTypes["authRefreshTokens_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.roles" */
		insertAuthRole?: ModelTypes["authRoles"] | undefined,
		/** insert data into the table: "auth.roles" */
		insertAuthRoles?: ModelTypes["authRoles_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.user_providers" */
		insertAuthUserProvider?: ModelTypes["authUserProviders"] | undefined,
		/** insert data into the table: "auth.user_providers" */
		insertAuthUserProviders?: ModelTypes["authUserProviders_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.user_roles" */
		insertAuthUserRole?: ModelTypes["authUserRoles"] | undefined,
		/** insert data into the table: "auth.user_roles" */
		insertAuthUserRoles?: ModelTypes["authUserRoles_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.user_security_keys" */
		insertAuthUserSecurityKey?: ModelTypes["authUserSecurityKeys"] | undefined,
		/** insert data into the table: "auth.user_security_keys" */
		insertAuthUserSecurityKeys?: ModelTypes["authUserSecurityKeys_mutation_response"] | undefined,
		/** insert a single row into the table: "storage.buckets" */
		insertBucket?: ModelTypes["buckets"] | undefined,
		/** insert data into the table: "storage.buckets" */
		insertBuckets?: ModelTypes["buckets_mutation_response"] | undefined,
		/** insert a single row into the table: "storage.files" */
		insertFile?: ModelTypes["files"] | undefined,
		/** insert data into the table: "storage.files" */
		insertFiles?: ModelTypes["files_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.users" */
		insertUser?: ModelTypes["users"] | undefined,
		/** insert data into the table: "auth.users" */
		insertUsers?: ModelTypes["users_mutation_response"] | undefined,
		/** insert a single row into the table: "storage.virus" */
		insertVirus?: ModelTypes["virus"] | undefined,
		/** insert data into the table: "storage.virus" */
		insertViruses?: ModelTypes["virus_mutation_response"] | undefined,
		/** insert data into the table: "AppIntegration" */
		insert_AppIntegration?: ModelTypes["AppIntegration_mutation_response"] | undefined,
		/** insert a single row into the table: "AppIntegration" */
		insert_AppIntegration_one?: ModelTypes["AppIntegration"] | undefined,
		/** insert data into the table: "Course" */
		insert_Course?: ModelTypes["Course_mutation_response"] | undefined,
		/** insert data into the table: "CourseCategory" */
		insert_CourseCategory?: ModelTypes["CourseCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "CourseCategory" */
		insert_CourseCategory_one?: ModelTypes["CourseCategory"] | undefined,
		/** insert a single row into the table: "Course" */
		insert_Course_one?: ModelTypes["Course"] | undefined,
		/** insert data into the table: "GeneratedPromptByTemplate" */
		insert_GeneratedPromptByTemplate?: ModelTypes["GeneratedPromptByTemplate_mutation_response"] | undefined,
		/** insert data into the table: "GeneratedPromptByTemplateImage" */
		insert_GeneratedPromptByTemplateImage?: ModelTypes["GeneratedPromptByTemplateImage_mutation_response"] | undefined,
		/** insert a single row into the table: "GeneratedPromptByTemplateImage" */
		insert_GeneratedPromptByTemplateImage_one?: ModelTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** insert a single row into the table: "GeneratedPromptByTemplate" */
		insert_GeneratedPromptByTemplate_one?: ModelTypes["GeneratedPromptByTemplate"] | undefined,
		/** insert data into the table: "TextGeneratorOption" */
		insert_TextGeneratorOption?: ModelTypes["TextGeneratorOption_mutation_response"] | undefined,
		/** insert data into the table: "TextGeneratorOptionEntry" */
		insert_TextGeneratorOptionEntry?: ModelTypes["TextGeneratorOptionEntry_mutation_response"] | undefined,
		/** insert a single row into the table: "TextGeneratorOptionEntry" */
		insert_TextGeneratorOptionEntry_one?: ModelTypes["TextGeneratorOptionEntry"] | undefined,
		/** insert a single row into the table: "TextGeneratorOption" */
		insert_TextGeneratorOption_one?: ModelTypes["TextGeneratorOption"] | undefined,
		/** insert data into the table: "TextPromptTemplate" */
		insert_TextPromptTemplate?: ModelTypes["TextPromptTemplate_mutation_response"] | undefined,
		/** insert data into the table: "TextPromptTemplateCategory" */
		insert_TextPromptTemplateCategory?: ModelTypes["TextPromptTemplateCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "TextPromptTemplateCategory" */
		insert_TextPromptTemplateCategory_one?: ModelTypes["TextPromptTemplateCategory"] | undefined,
		/** insert a single row into the table: "TextPromptTemplate" */
		insert_TextPromptTemplate_one?: ModelTypes["TextPromptTemplate"] | undefined,
		/** insert data into the table: "TextTemplateClientCategory" */
		insert_TextTemplateClientCategory?: ModelTypes["TextTemplateClientCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "TextTemplateClientCategory" */
		insert_TextTemplateClientCategory_one?: ModelTypes["TextTemplateClientCategory"] | undefined,
		/** insert data into the table: "audiences" */
		insert_audiences?: ModelTypes["audiences_mutation_response"] | undefined,
		/** insert a single row into the table: "audiences" */
		insert_audiences_one?: ModelTypes["audiences"] | undefined,
		/** insert data into the table: "blog" */
		insert_blog?: ModelTypes["blog_mutation_response"] | undefined,
		/** insert data into the table: "blogCategory" */
		insert_blogCategory?: ModelTypes["blogCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "blogCategory" */
		insert_blogCategory_one?: ModelTypes["blogCategory"] | undefined,
		/** insert a single row into the table: "blog" */
		insert_blog_one?: ModelTypes["blog"] | undefined,
		/** insert data into the table: "brands" */
		insert_brands?: ModelTypes["brands_mutation_response"] | undefined,
		/** insert a single row into the table: "brands" */
		insert_brands_one?: ModelTypes["brands"] | undefined,
		/** insert data into the table: "campaign" */
		insert_campaign?: ModelTypes["campaign_mutation_response"] | undefined,
		/** insert a single row into the table: "campaign" */
		insert_campaign_one?: ModelTypes["campaign"] | undefined,
		/** insert data into the table: "channels" */
		insert_channels?: ModelTypes["channels_mutation_response"] | undefined,
		/** insert a single row into the table: "channels" */
		insert_channels_one?: ModelTypes["channels"] | undefined,
		/** insert data into the table: "costTable" */
		insert_costTable?: ModelTypes["costTable_mutation_response"] | undefined,
		/** insert a single row into the table: "costTable" */
		insert_costTable_one?: ModelTypes["costTable"] | undefined,
		/** insert data into the table: "deal" */
		insert_deal?: ModelTypes["deal_mutation_response"] | undefined,
		/** insert data into the table: "dealStatus" */
		insert_dealStatus?: ModelTypes["dealStatus_mutation_response"] | undefined,
		/** insert a single row into the table: "dealStatus" */
		insert_dealStatus_one?: ModelTypes["dealStatus"] | undefined,
		/** insert a single row into the table: "deal" */
		insert_deal_one?: ModelTypes["deal"] | undefined,
		/** insert data into the table: "displayCategory" */
		insert_displayCategory?: ModelTypes["displayCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "displayCategory" */
		insert_displayCategory_one?: ModelTypes["displayCategory"] | undefined,
		/** insert data into the table: "displayDimension" */
		insert_displayDimension?: ModelTypes["displayDimension_mutation_response"] | undefined,
		/** insert a single row into the table: "displayDimension" */
		insert_displayDimension_one?: ModelTypes["displayDimension"] | undefined,
		/** insert data into the table: "displayFormats" */
		insert_displayFormats?: ModelTypes["displayFormats_mutation_response"] | undefined,
		/** insert a single row into the table: "displayFormats" */
		insert_displayFormats_one?: ModelTypes["displayFormats"] | undefined,
		/** insert data into the table: "displayTemplate" */
		insert_displayTemplate?: ModelTypes["displayTemplate_mutation_response"] | undefined,
		/** insert a single row into the table: "displayTemplate" */
		insert_displayTemplate_one?: ModelTypes["displayTemplate"] | undefined,
		/** insert data into the table: "interaction" */
		insert_interaction?: ModelTypes["interaction_mutation_response"] | undefined,
		/** insert a single row into the table: "interaction" */
		insert_interaction_one?: ModelTypes["interaction"] | undefined,
		/** insert data into the table: "landingPageTemplate" */
		insert_landingPageTemplate?: ModelTypes["landingPageTemplate_mutation_response"] | undefined,
		/** insert a single row into the table: "landingPageTemplate" */
		insert_landingPageTemplate_one?: ModelTypes["landingPageTemplate"] | undefined,
		/** insert data into the table: "newTemplateType" */
		insert_newTemplateType?: ModelTypes["newTemplateType_mutation_response"] | undefined,
		/** insert a single row into the table: "newTemplateType" */
		insert_newTemplateType_one?: ModelTypes["newTemplateType"] | undefined,
		/** insert data into the table: "openAIApiKey" */
		insert_openAIApiKey?: ModelTypes["openAIApiKey_mutation_response"] | undefined,
		/** insert a single row into the table: "openAIApiKey" */
		insert_openAIApiKey_one?: ModelTypes["openAIApiKey"] | undefined,
		/** insert data into the table: "pTemplate" */
		insert_pTemplate?: ModelTypes["pTemplate_mutation_response"] | undefined,
		/** insert a single row into the table: "pTemplate" */
		insert_pTemplate_one?: ModelTypes["pTemplate"] | undefined,
		/** insert data into the table: "pricingPlan" */
		insert_pricingPlan?: ModelTypes["pricingPlan_mutation_response"] | undefined,
		/** insert a single row into the table: "pricingPlan" */
		insert_pricingPlan_one?: ModelTypes["pricingPlan"] | undefined,
		/** insert data into the table: "products" */
		insert_products?: ModelTypes["products_mutation_response"] | undefined,
		/** insert a single row into the table: "products" */
		insert_products_one?: ModelTypes["products"] | undefined,
		/** insert data into the table: "project" */
		insert_project?: ModelTypes["project_mutation_response"] | undefined,
		/** insert data into the table: "projectImage" */
		insert_projectImage?: ModelTypes["projectImage_mutation_response"] | undefined,
		/** insert a single row into the table: "projectImage" */
		insert_projectImage_one?: ModelTypes["projectImage"] | undefined,
		/** insert a single row into the table: "project" */
		insert_project_one?: ModelTypes["project"] | undefined,
		/** insert data into the table: "task" */
		insert_task?: ModelTypes["task_mutation_response"] | undefined,
		/** insert a single row into the table: "task" */
		insert_task_one?: ModelTypes["task"] | undefined,
		/** insert data into the table: "textTemplateDisplayFormat" */
		insert_textTemplateDisplayFormat?: ModelTypes["textTemplateDisplayFormat_mutation_response"] | undefined,
		/** insert a single row into the table: "textTemplateDisplayFormat" */
		insert_textTemplateDisplayFormat_one?: ModelTypes["textTemplateDisplayFormat"] | undefined,
		/** insert data into the table: "userAppIntegration" */
		insert_userAppIntegration?: ModelTypes["userAppIntegration_mutation_response"] | undefined,
		/** insert a single row into the table: "userAppIntegration" */
		insert_userAppIntegration_one?: ModelTypes["userAppIntegration"] | undefined,
		/** insert data into the table: "userCreditUsage" */
		insert_userCreditUsage?: ModelTypes["userCreditUsage_mutation_response"] | undefined,
		/** insert data into the table: "userCreditUsageSummary" */
		insert_userCreditUsageSummary?: ModelTypes["userCreditUsageSummary_mutation_response"] | undefined,
		/** insert a single row into the table: "userCreditUsageSummary" */
		insert_userCreditUsageSummary_one?: ModelTypes["userCreditUsageSummary"] | undefined,
		/** insert a single row into the table: "userCreditUsage" */
		insert_userCreditUsage_one?: ModelTypes["userCreditUsage"] | undefined,
		/** insert data into the table: "userCredits" */
		insert_userCredits?: ModelTypes["userCredits_mutation_response"] | undefined,
		/** insert a single row into the table: "userCredits" */
		insert_userCredits_one?: ModelTypes["userCredits"] | undefined,
		/** insert data into the table: "userFeedback" */
		insert_userFeedback?: ModelTypes["userFeedback_mutation_response"] | undefined,
		/** insert a single row into the table: "userFeedback" */
		insert_userFeedback_one?: ModelTypes["userFeedback"] | undefined,
		/** insert data into the table: "userMedia" */
		insert_userMedia?: ModelTypes["userMedia_mutation_response"] | undefined,
		/** insert a single row into the table: "userMedia" */
		insert_userMedia_one?: ModelTypes["userMedia"] | undefined,
		/** insert data into the table: "userMetadata" */
		insert_userMetadata?: ModelTypes["userMetadata_mutation_response"] | undefined,
		/** insert a single row into the table: "userMetadata" */
		insert_userMetadata_one?: ModelTypes["userMetadata"] | undefined,
		/** insert data into the table: "userOnboarding" */
		insert_userOnboarding?: ModelTypes["userOnboarding_mutation_response"] | undefined,
		/** insert a single row into the table: "userOnboarding" */
		insert_userOnboarding_one?: ModelTypes["userOnboarding"] | undefined,
		/** insert data into the table: "userTemplateUsage" */
		insert_userTemplateUsage?: ModelTypes["userTemplateUsage_mutation_response"] | undefined,
		/** insert a single row into the table: "userTemplateUsage" */
		insert_userTemplateUsage_one?: ModelTypes["userTemplateUsage"] | undefined,
		/** insert data into the table: "website" */
		insert_website?: ModelTypes["website_mutation_response"] | undefined,
		/** insert a single row into the table: "website" */
		insert_website_one?: ModelTypes["website"] | undefined,
		linkedInTokenCallback?: ModelTypes["LinkedInTokenCallbackOutput"] | undefined,
		postCarouselToInstagram?: ModelTypes["PostCarouselToInstagramOutput"] | undefined,
		postFeedToInstagram?: ModelTypes["PostFeedToInstagramOutput"] | undefined,
		postStoryToInstagram?: ModelTypes["PostStoryToInstagramOutput"] | undefined,
		postToFacebook?: ModelTypes["PostToFacebookOutput"] | undefined,
		postToLinkedIn?: ModelTypes["PostToLinkedInOutput"] | undefined,
		publishOrScheduleProject?: ModelTypes["PublishOrScheduleProjectOutput"] | undefined,
		/** pureprompt */
		purePrompt?: ModelTypes["PromptOutput"] | undefined,
		renderAndSaveDisplayImage?: ModelTypes["RenderAndSaveDisplayImageOutput"] | undefined,
		saveAndGeneratePolotnoImage?: ModelTypes["SaveAndGeneratePolotnoImageOutput"] | undefined,
		selectNewDisplay?: ModelTypes["SelectNewDisplayOutput"] | undefined,
		/** update single row of the table: "auth.providers" */
		updateAuthProvider?: ModelTypes["authProviders"] | undefined,
		/** update single row of the table: "auth.provider_requests" */
		updateAuthProviderRequest?: ModelTypes["authProviderRequests"] | undefined,
		/** update data of the table: "auth.provider_requests" */
		updateAuthProviderRequests?: ModelTypes["authProviderRequests_mutation_response"] | undefined,
		/** update data of the table: "auth.providers" */
		updateAuthProviders?: ModelTypes["authProviders_mutation_response"] | undefined,
		/** update single row of the table: "auth.refresh_tokens" */
		updateAuthRefreshToken?: ModelTypes["authRefreshTokens"] | undefined,
		/** update single row of the table: "auth.refresh_token_types" */
		updateAuthRefreshTokenType?: ModelTypes["authRefreshTokenTypes"] | undefined,
		/** update data of the table: "auth.refresh_token_types" */
		updateAuthRefreshTokenTypes?: ModelTypes["authRefreshTokenTypes_mutation_response"] | undefined,
		/** update data of the table: "auth.refresh_tokens" */
		updateAuthRefreshTokens?: ModelTypes["authRefreshTokens_mutation_response"] | undefined,
		/** update single row of the table: "auth.roles" */
		updateAuthRole?: ModelTypes["authRoles"] | undefined,
		/** update data of the table: "auth.roles" */
		updateAuthRoles?: ModelTypes["authRoles_mutation_response"] | undefined,
		/** update single row of the table: "auth.user_providers" */
		updateAuthUserProvider?: ModelTypes["authUserProviders"] | undefined,
		/** update data of the table: "auth.user_providers" */
		updateAuthUserProviders?: ModelTypes["authUserProviders_mutation_response"] | undefined,
		/** update single row of the table: "auth.user_roles" */
		updateAuthUserRole?: ModelTypes["authUserRoles"] | undefined,
		/** update data of the table: "auth.user_roles" */
		updateAuthUserRoles?: ModelTypes["authUserRoles_mutation_response"] | undefined,
		/** update single row of the table: "auth.user_security_keys" */
		updateAuthUserSecurityKey?: ModelTypes["authUserSecurityKeys"] | undefined,
		/** update data of the table: "auth.user_security_keys" */
		updateAuthUserSecurityKeys?: ModelTypes["authUserSecurityKeys_mutation_response"] | undefined,
		/** update single row of the table: "storage.buckets" */
		updateBucket?: ModelTypes["buckets"] | undefined,
		/** update data of the table: "storage.buckets" */
		updateBuckets?: ModelTypes["buckets_mutation_response"] | undefined,
		/** update single row of the table: "storage.files" */
		updateFile?: ModelTypes["files"] | undefined,
		/** update data of the table: "storage.files" */
		updateFiles?: ModelTypes["files_mutation_response"] | undefined,
		/** update single row of the table: "auth.users" */
		updateUser?: ModelTypes["users"] | undefined,
		updateUserAvatar?: ModelTypes["UpdateUserAvatarOutput"] | undefined,
		/** update data of the table: "auth.users" */
		updateUsers?: ModelTypes["users_mutation_response"] | undefined,
		/** update single row of the table: "storage.virus" */
		updateVirus?: ModelTypes["virus"] | undefined,
		/** update data of the table: "storage.virus" */
		updateViruses?: ModelTypes["virus_mutation_response"] | undefined,
		/** update data of the table: "AppIntegration" */
		update_AppIntegration?: ModelTypes["AppIntegration_mutation_response"] | undefined,
		/** update single row of the table: "AppIntegration" */
		update_AppIntegration_by_pk?: ModelTypes["AppIntegration"] | undefined,
		/** update multiples rows of table: "AppIntegration" */
		update_AppIntegration_many?: Array<ModelTypes["AppIntegration_mutation_response"] | undefined> | undefined,
		/** update data of the table: "Course" */
		update_Course?: ModelTypes["Course_mutation_response"] | undefined,
		/** update data of the table: "CourseCategory" */
		update_CourseCategory?: ModelTypes["CourseCategory_mutation_response"] | undefined,
		/** update single row of the table: "CourseCategory" */
		update_CourseCategory_by_pk?: ModelTypes["CourseCategory"] | undefined,
		/** update multiples rows of table: "CourseCategory" */
		update_CourseCategory_many?: Array<ModelTypes["CourseCategory_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "Course" */
		update_Course_by_pk?: ModelTypes["Course"] | undefined,
		/** update multiples rows of table: "Course" */
		update_Course_many?: Array<ModelTypes["Course_mutation_response"] | undefined> | undefined,
		/** update data of the table: "GeneratedPromptByTemplate" */
		update_GeneratedPromptByTemplate?: ModelTypes["GeneratedPromptByTemplate_mutation_response"] | undefined,
		/** update data of the table: "GeneratedPromptByTemplateImage" */
		update_GeneratedPromptByTemplateImage?: ModelTypes["GeneratedPromptByTemplateImage_mutation_response"] | undefined,
		/** update single row of the table: "GeneratedPromptByTemplateImage" */
		update_GeneratedPromptByTemplateImage_by_pk?: ModelTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** update multiples rows of table: "GeneratedPromptByTemplateImage" */
		update_GeneratedPromptByTemplateImage_many?: Array<ModelTypes["GeneratedPromptByTemplateImage_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "GeneratedPromptByTemplate" */
		update_GeneratedPromptByTemplate_by_pk?: ModelTypes["GeneratedPromptByTemplate"] | undefined,
		/** update multiples rows of table: "GeneratedPromptByTemplate" */
		update_GeneratedPromptByTemplate_many?: Array<ModelTypes["GeneratedPromptByTemplate_mutation_response"] | undefined> | undefined,
		/** update data of the table: "TextGeneratorOption" */
		update_TextGeneratorOption?: ModelTypes["TextGeneratorOption_mutation_response"] | undefined,
		/** update data of the table: "TextGeneratorOptionEntry" */
		update_TextGeneratorOptionEntry?: ModelTypes["TextGeneratorOptionEntry_mutation_response"] | undefined,
		/** update single row of the table: "TextGeneratorOptionEntry" */
		update_TextGeneratorOptionEntry_by_pk?: ModelTypes["TextGeneratorOptionEntry"] | undefined,
		/** update multiples rows of table: "TextGeneratorOptionEntry" */
		update_TextGeneratorOptionEntry_many?: Array<ModelTypes["TextGeneratorOptionEntry_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "TextGeneratorOption" */
		update_TextGeneratorOption_by_pk?: ModelTypes["TextGeneratorOption"] | undefined,
		/** update multiples rows of table: "TextGeneratorOption" */
		update_TextGeneratorOption_many?: Array<ModelTypes["TextGeneratorOption_mutation_response"] | undefined> | undefined,
		/** update data of the table: "TextPromptTemplate" */
		update_TextPromptTemplate?: ModelTypes["TextPromptTemplate_mutation_response"] | undefined,
		/** update data of the table: "TextPromptTemplateCategory" */
		update_TextPromptTemplateCategory?: ModelTypes["TextPromptTemplateCategory_mutation_response"] | undefined,
		/** update single row of the table: "TextPromptTemplateCategory" */
		update_TextPromptTemplateCategory_by_pk?: ModelTypes["TextPromptTemplateCategory"] | undefined,
		/** update multiples rows of table: "TextPromptTemplateCategory" */
		update_TextPromptTemplateCategory_many?: Array<ModelTypes["TextPromptTemplateCategory_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "TextPromptTemplate" */
		update_TextPromptTemplate_by_pk?: ModelTypes["TextPromptTemplate"] | undefined,
		/** update multiples rows of table: "TextPromptTemplate" */
		update_TextPromptTemplate_many?: Array<ModelTypes["TextPromptTemplate_mutation_response"] | undefined> | undefined,
		/** update data of the table: "TextTemplateClientCategory" */
		update_TextTemplateClientCategory?: ModelTypes["TextTemplateClientCategory_mutation_response"] | undefined,
		/** update single row of the table: "TextTemplateClientCategory" */
		update_TextTemplateClientCategory_by_pk?: ModelTypes["TextTemplateClientCategory"] | undefined,
		/** update multiples rows of table: "TextTemplateClientCategory" */
		update_TextTemplateClientCategory_many?: Array<ModelTypes["TextTemplateClientCategory_mutation_response"] | undefined> | undefined,
		/** update data of the table: "audiences" */
		update_audiences?: ModelTypes["audiences_mutation_response"] | undefined,
		/** update single row of the table: "audiences" */
		update_audiences_by_pk?: ModelTypes["audiences"] | undefined,
		/** update multiples rows of table: "audiences" */
		update_audiences_many?: Array<ModelTypes["audiences_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.provider_requests" */
		update_authProviderRequests_many?: Array<ModelTypes["authProviderRequests_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.providers" */
		update_authProviders_many?: Array<ModelTypes["authProviders_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.refresh_token_types" */
		update_authRefreshTokenTypes_many?: Array<ModelTypes["authRefreshTokenTypes_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.refresh_tokens" */
		update_authRefreshTokens_many?: Array<ModelTypes["authRefreshTokens_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.roles" */
		update_authRoles_many?: Array<ModelTypes["authRoles_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.user_providers" */
		update_authUserProviders_many?: Array<ModelTypes["authUserProviders_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.user_roles" */
		update_authUserRoles_many?: Array<ModelTypes["authUserRoles_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.user_security_keys" */
		update_authUserSecurityKeys_many?: Array<ModelTypes["authUserSecurityKeys_mutation_response"] | undefined> | undefined,
		/** update data of the table: "blog" */
		update_blog?: ModelTypes["blog_mutation_response"] | undefined,
		/** update data of the table: "blogCategory" */
		update_blogCategory?: ModelTypes["blogCategory_mutation_response"] | undefined,
		/** update single row of the table: "blogCategory" */
		update_blogCategory_by_pk?: ModelTypes["blogCategory"] | undefined,
		/** update multiples rows of table: "blogCategory" */
		update_blogCategory_many?: Array<ModelTypes["blogCategory_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "blog" */
		update_blog_by_pk?: ModelTypes["blog"] | undefined,
		/** update multiples rows of table: "blog" */
		update_blog_many?: Array<ModelTypes["blog_mutation_response"] | undefined> | undefined,
		/** update data of the table: "brands" */
		update_brands?: ModelTypes["brands_mutation_response"] | undefined,
		/** update single row of the table: "brands" */
		update_brands_by_pk?: ModelTypes["brands"] | undefined,
		/** update multiples rows of table: "brands" */
		update_brands_many?: Array<ModelTypes["brands_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "storage.buckets" */
		update_buckets_many?: Array<ModelTypes["buckets_mutation_response"] | undefined> | undefined,
		/** update data of the table: "campaign" */
		update_campaign?: ModelTypes["campaign_mutation_response"] | undefined,
		/** update single row of the table: "campaign" */
		update_campaign_by_pk?: ModelTypes["campaign"] | undefined,
		/** update multiples rows of table: "campaign" */
		update_campaign_many?: Array<ModelTypes["campaign_mutation_response"] | undefined> | undefined,
		/** update data of the table: "channels" */
		update_channels?: ModelTypes["channels_mutation_response"] | undefined,
		/** update single row of the table: "channels" */
		update_channels_by_pk?: ModelTypes["channels"] | undefined,
		/** update multiples rows of table: "channels" */
		update_channels_many?: Array<ModelTypes["channels_mutation_response"] | undefined> | undefined,
		/** update data of the table: "costTable" */
		update_costTable?: ModelTypes["costTable_mutation_response"] | undefined,
		/** update single row of the table: "costTable" */
		update_costTable_by_pk?: ModelTypes["costTable"] | undefined,
		/** update multiples rows of table: "costTable" */
		update_costTable_many?: Array<ModelTypes["costTable_mutation_response"] | undefined> | undefined,
		/** update data of the table: "deal" */
		update_deal?: ModelTypes["deal_mutation_response"] | undefined,
		/** update data of the table: "dealStatus" */
		update_dealStatus?: ModelTypes["dealStatus_mutation_response"] | undefined,
		/** update single row of the table: "dealStatus" */
		update_dealStatus_by_pk?: ModelTypes["dealStatus"] | undefined,
		/** update multiples rows of table: "dealStatus" */
		update_dealStatus_many?: Array<ModelTypes["dealStatus_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "deal" */
		update_deal_by_pk?: ModelTypes["deal"] | undefined,
		/** update multiples rows of table: "deal" */
		update_deal_many?: Array<ModelTypes["deal_mutation_response"] | undefined> | undefined,
		/** update data of the table: "displayCategory" */
		update_displayCategory?: ModelTypes["displayCategory_mutation_response"] | undefined,
		/** update single row of the table: "displayCategory" */
		update_displayCategory_by_pk?: ModelTypes["displayCategory"] | undefined,
		/** update multiples rows of table: "displayCategory" */
		update_displayCategory_many?: Array<ModelTypes["displayCategory_mutation_response"] | undefined> | undefined,
		/** update data of the table: "displayDimension" */
		update_displayDimension?: ModelTypes["displayDimension_mutation_response"] | undefined,
		/** update single row of the table: "displayDimension" */
		update_displayDimension_by_pk?: ModelTypes["displayDimension"] | undefined,
		/** update multiples rows of table: "displayDimension" */
		update_displayDimension_many?: Array<ModelTypes["displayDimension_mutation_response"] | undefined> | undefined,
		/** update data of the table: "displayFormats" */
		update_displayFormats?: ModelTypes["displayFormats_mutation_response"] | undefined,
		/** update single row of the table: "displayFormats" */
		update_displayFormats_by_pk?: ModelTypes["displayFormats"] | undefined,
		/** update multiples rows of table: "displayFormats" */
		update_displayFormats_many?: Array<ModelTypes["displayFormats_mutation_response"] | undefined> | undefined,
		/** update data of the table: "displayTemplate" */
		update_displayTemplate?: ModelTypes["displayTemplate_mutation_response"] | undefined,
		/** update single row of the table: "displayTemplate" */
		update_displayTemplate_by_pk?: ModelTypes["displayTemplate"] | undefined,
		/** update multiples rows of table: "displayTemplate" */
		update_displayTemplate_many?: Array<ModelTypes["displayTemplate_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "storage.files" */
		update_files_many?: Array<ModelTypes["files_mutation_response"] | undefined> | undefined,
		/** update data of the table: "interaction" */
		update_interaction?: ModelTypes["interaction_mutation_response"] | undefined,
		/** update single row of the table: "interaction" */
		update_interaction_by_pk?: ModelTypes["interaction"] | undefined,
		/** update multiples rows of table: "interaction" */
		update_interaction_many?: Array<ModelTypes["interaction_mutation_response"] | undefined> | undefined,
		/** update data of the table: "landingPageTemplate" */
		update_landingPageTemplate?: ModelTypes["landingPageTemplate_mutation_response"] | undefined,
		/** update single row of the table: "landingPageTemplate" */
		update_landingPageTemplate_by_pk?: ModelTypes["landingPageTemplate"] | undefined,
		/** update multiples rows of table: "landingPageTemplate" */
		update_landingPageTemplate_many?: Array<ModelTypes["landingPageTemplate_mutation_response"] | undefined> | undefined,
		/** update data of the table: "newTemplateType" */
		update_newTemplateType?: ModelTypes["newTemplateType_mutation_response"] | undefined,
		/** update single row of the table: "newTemplateType" */
		update_newTemplateType_by_pk?: ModelTypes["newTemplateType"] | undefined,
		/** update multiples rows of table: "newTemplateType" */
		update_newTemplateType_many?: Array<ModelTypes["newTemplateType_mutation_response"] | undefined> | undefined,
		/** update data of the table: "openAIApiKey" */
		update_openAIApiKey?: ModelTypes["openAIApiKey_mutation_response"] | undefined,
		/** update single row of the table: "openAIApiKey" */
		update_openAIApiKey_by_pk?: ModelTypes["openAIApiKey"] | undefined,
		/** update multiples rows of table: "openAIApiKey" */
		update_openAIApiKey_many?: Array<ModelTypes["openAIApiKey_mutation_response"] | undefined> | undefined,
		/** update data of the table: "pTemplate" */
		update_pTemplate?: ModelTypes["pTemplate_mutation_response"] | undefined,
		/** update single row of the table: "pTemplate" */
		update_pTemplate_by_pk?: ModelTypes["pTemplate"] | undefined,
		/** update multiples rows of table: "pTemplate" */
		update_pTemplate_many?: Array<ModelTypes["pTemplate_mutation_response"] | undefined> | undefined,
		/** update data of the table: "pricingPlan" */
		update_pricingPlan?: ModelTypes["pricingPlan_mutation_response"] | undefined,
		/** update single row of the table: "pricingPlan" */
		update_pricingPlan_by_pk?: ModelTypes["pricingPlan"] | undefined,
		/** update multiples rows of table: "pricingPlan" */
		update_pricingPlan_many?: Array<ModelTypes["pricingPlan_mutation_response"] | undefined> | undefined,
		/** update data of the table: "products" */
		update_products?: ModelTypes["products_mutation_response"] | undefined,
		/** update single row of the table: "products" */
		update_products_by_pk?: ModelTypes["products"] | undefined,
		/** update multiples rows of table: "products" */
		update_products_many?: Array<ModelTypes["products_mutation_response"] | undefined> | undefined,
		/** update data of the table: "project" */
		update_project?: ModelTypes["project_mutation_response"] | undefined,
		/** update data of the table: "projectImage" */
		update_projectImage?: ModelTypes["projectImage_mutation_response"] | undefined,
		/** update single row of the table: "projectImage" */
		update_projectImage_by_pk?: ModelTypes["projectImage"] | undefined,
		/** update multiples rows of table: "projectImage" */
		update_projectImage_many?: Array<ModelTypes["projectImage_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "project" */
		update_project_by_pk?: ModelTypes["project"] | undefined,
		/** update multiples rows of table: "project" */
		update_project_many?: Array<ModelTypes["project_mutation_response"] | undefined> | undefined,
		/** update data of the table: "task" */
		update_task?: ModelTypes["task_mutation_response"] | undefined,
		/** update single row of the table: "task" */
		update_task_by_pk?: ModelTypes["task"] | undefined,
		/** update multiples rows of table: "task" */
		update_task_many?: Array<ModelTypes["task_mutation_response"] | undefined> | undefined,
		/** update data of the table: "textTemplateDisplayFormat" */
		update_textTemplateDisplayFormat?: ModelTypes["textTemplateDisplayFormat_mutation_response"] | undefined,
		/** update single row of the table: "textTemplateDisplayFormat" */
		update_textTemplateDisplayFormat_by_pk?: ModelTypes["textTemplateDisplayFormat"] | undefined,
		/** update multiples rows of table: "textTemplateDisplayFormat" */
		update_textTemplateDisplayFormat_many?: Array<ModelTypes["textTemplateDisplayFormat_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userAppIntegration" */
		update_userAppIntegration?: ModelTypes["userAppIntegration_mutation_response"] | undefined,
		/** update single row of the table: "userAppIntegration" */
		update_userAppIntegration_by_pk?: ModelTypes["userAppIntegration"] | undefined,
		/** update multiples rows of table: "userAppIntegration" */
		update_userAppIntegration_many?: Array<ModelTypes["userAppIntegration_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userCreditUsage" */
		update_userCreditUsage?: ModelTypes["userCreditUsage_mutation_response"] | undefined,
		/** update data of the table: "userCreditUsageSummary" */
		update_userCreditUsageSummary?: ModelTypes["userCreditUsageSummary_mutation_response"] | undefined,
		/** update single row of the table: "userCreditUsageSummary" */
		update_userCreditUsageSummary_by_pk?: ModelTypes["userCreditUsageSummary"] | undefined,
		/** update multiples rows of table: "userCreditUsageSummary" */
		update_userCreditUsageSummary_many?: Array<ModelTypes["userCreditUsageSummary_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "userCreditUsage" */
		update_userCreditUsage_by_pk?: ModelTypes["userCreditUsage"] | undefined,
		/** update multiples rows of table: "userCreditUsage" */
		update_userCreditUsage_many?: Array<ModelTypes["userCreditUsage_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userCredits" */
		update_userCredits?: ModelTypes["userCredits_mutation_response"] | undefined,
		/** update single row of the table: "userCredits" */
		update_userCredits_by_pk?: ModelTypes["userCredits"] | undefined,
		/** update multiples rows of table: "userCredits" */
		update_userCredits_many?: Array<ModelTypes["userCredits_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userFeedback" */
		update_userFeedback?: ModelTypes["userFeedback_mutation_response"] | undefined,
		/** update single row of the table: "userFeedback" */
		update_userFeedback_by_pk?: ModelTypes["userFeedback"] | undefined,
		/** update multiples rows of table: "userFeedback" */
		update_userFeedback_many?: Array<ModelTypes["userFeedback_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userMedia" */
		update_userMedia?: ModelTypes["userMedia_mutation_response"] | undefined,
		/** update single row of the table: "userMedia" */
		update_userMedia_by_pk?: ModelTypes["userMedia"] | undefined,
		/** update multiples rows of table: "userMedia" */
		update_userMedia_many?: Array<ModelTypes["userMedia_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userMetadata" */
		update_userMetadata?: ModelTypes["userMetadata_mutation_response"] | undefined,
		/** update single row of the table: "userMetadata" */
		update_userMetadata_by_pk?: ModelTypes["userMetadata"] | undefined,
		/** update multiples rows of table: "userMetadata" */
		update_userMetadata_many?: Array<ModelTypes["userMetadata_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userOnboarding" */
		update_userOnboarding?: ModelTypes["userOnboarding_mutation_response"] | undefined,
		/** update single row of the table: "userOnboarding" */
		update_userOnboarding_by_pk?: ModelTypes["userOnboarding"] | undefined,
		/** update multiples rows of table: "userOnboarding" */
		update_userOnboarding_many?: Array<ModelTypes["userOnboarding_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userTemplateUsage" */
		update_userTemplateUsage?: ModelTypes["userTemplateUsage_mutation_response"] | undefined,
		/** update single row of the table: "userTemplateUsage" */
		update_userTemplateUsage_by_pk?: ModelTypes["userTemplateUsage"] | undefined,
		/** update multiples rows of table: "userTemplateUsage" */
		update_userTemplateUsage_many?: Array<ModelTypes["userTemplateUsage_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.users" */
		update_users_many?: Array<ModelTypes["users_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "storage.virus" */
		update_virus_many?: Array<ModelTypes["virus_mutation_response"] | undefined> | undefined,
		/** update data of the table: "website" */
		update_website?: ModelTypes["website_mutation_response"] | undefined,
		/** update single row of the table: "website" */
		update_website_by_pk?: ModelTypes["website"] | undefined,
		/** update multiples rows of table: "website" */
		update_website_many?: Array<ModelTypes["website_mutation_response"] | undefined> | undefined,
		upsertAudience?: ModelTypes["UpsertAudienceOutput"] | undefined,
		upsertBrand?: ModelTypes["upsertBrandOutput"] | undefined
	};
	/** columns and relationships of "newTemplateType" */
	["newTemplateType"]: {
		channel: string,
		created_at: ModelTypes["timestamptz"],
		creditPrice: number,
		description: string,
		icon: string,
		id: ModelTypes["uuid"],
		name: string,
		saleCreditPrice: number,
		thumbnailFileId?: ModelTypes["uuid"] | undefined,
		updated_at: ModelTypes["timestamptz"]
	};
	/** aggregated selection of "newTemplateType" */
	["newTemplateType_aggregate"]: {
		aggregate?: ModelTypes["newTemplateType_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["newTemplateType"]>
	};
	/** aggregate fields of "newTemplateType" */
	["newTemplateType_aggregate_fields"]: {
		avg?: ModelTypes["newTemplateType_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["newTemplateType_max_fields"] | undefined,
		min?: ModelTypes["newTemplateType_min_fields"] | undefined,
		stddev?: ModelTypes["newTemplateType_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["newTemplateType_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["newTemplateType_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["newTemplateType_sum_fields"] | undefined,
		var_pop?: ModelTypes["newTemplateType_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["newTemplateType_var_samp_fields"] | undefined,
		variance?: ModelTypes["newTemplateType_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["newTemplateType_avg_fields"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** Boolean expression to filter rows from the table "newTemplateType". All fields are combined with a logical 'AND'. */
	["newTemplateType_bool_exp"]: {
		_and?: Array<ModelTypes["newTemplateType_bool_exp"]> | undefined,
		_not?: ModelTypes["newTemplateType_bool_exp"] | undefined,
		_or?: Array<ModelTypes["newTemplateType_bool_exp"]> | undefined,
		channel?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		creditPrice?: ModelTypes["Int_comparison_exp"] | undefined,
		description?: ModelTypes["String_comparison_exp"] | undefined,
		icon?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined,
		saleCreditPrice?: ModelTypes["Int_comparison_exp"] | undefined,
		thumbnailFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["newTemplateType_constraint"]: newTemplateType_constraint;
	/** input type for incrementing numeric columns in table "newTemplateType" */
	["newTemplateType_inc_input"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** input type for inserting data into table "newTemplateType" */
	["newTemplateType_insert_input"]: {
		channel?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["newTemplateType_max_fields"]: {
		channel?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["newTemplateType_min_fields"]: {
		channel?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "newTemplateType" */
	["newTemplateType_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["newTemplateType"]>
	};
	/** on_conflict condition type for table "newTemplateType" */
	["newTemplateType_on_conflict"]: {
		constraint: ModelTypes["newTemplateType_constraint"],
		update_columns: Array<ModelTypes["newTemplateType_update_column"]>,
		where?: ModelTypes["newTemplateType_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "newTemplateType". */
	["newTemplateType_order_by"]: {
		channel?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		creditPrice?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		icon?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		saleCreditPrice?: ModelTypes["order_by"] | undefined,
		thumbnailFileId?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: newTemplateType */
	["newTemplateType_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["newTemplateType_select_column"]: newTemplateType_select_column;
	/** input type for updating data in table "newTemplateType" */
	["newTemplateType_set_input"]: {
		channel?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["newTemplateType_stddev_fields"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["newTemplateType_stddev_pop_fields"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["newTemplateType_stddev_samp_fields"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** Streaming cursor of the table "newTemplateType" */
	["newTemplateType_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["newTemplateType_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["newTemplateType_stream_cursor_value_input"]: {
		channel?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["newTemplateType_sum_fields"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	["newTemplateType_update_column"]: newTemplateType_update_column;
	["newTemplateType_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["newTemplateType_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["newTemplateType_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["newTemplateType_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["newTemplateType_var_pop_fields"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate var_samp on columns */
	["newTemplateType_var_samp_fields"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate variance on columns */
	["newTemplateType_variance_fields"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	["numeric"]: any;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
	["numeric_comparison_exp"]: {
		_eq?: ModelTypes["numeric"] | undefined,
		_gt?: ModelTypes["numeric"] | undefined,
		_gte?: ModelTypes["numeric"] | undefined,
		_in?: Array<ModelTypes["numeric"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: ModelTypes["numeric"] | undefined,
		_lte?: ModelTypes["numeric"] | undefined,
		_neq?: ModelTypes["numeric"] | undefined,
		_nin?: Array<ModelTypes["numeric"]> | undefined
	};
	/** columns and relationships of "openAIApiKey" */
	["openAIApiKey"]: {
		apiKey: string,
		created_at: ModelTypes["timestamptz"],
		id: ModelTypes["uuid"],
		last_used: ModelTypes["timestamptz"],
		status?: string | undefined
	};
	/** aggregated selection of "openAIApiKey" */
	["openAIApiKey_aggregate"]: {
		aggregate?: ModelTypes["openAIApiKey_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["openAIApiKey"]>
	};
	/** aggregate fields of "openAIApiKey" */
	["openAIApiKey_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["openAIApiKey_max_fields"] | undefined,
		min?: ModelTypes["openAIApiKey_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "openAIApiKey". All fields are combined with a logical 'AND'. */
	["openAIApiKey_bool_exp"]: {
		_and?: Array<ModelTypes["openAIApiKey_bool_exp"]> | undefined,
		_not?: ModelTypes["openAIApiKey_bool_exp"] | undefined,
		_or?: Array<ModelTypes["openAIApiKey_bool_exp"]> | undefined,
		apiKey?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		last_used?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		status?: ModelTypes["String_comparison_exp"] | undefined
	};
	["openAIApiKey_constraint"]: openAIApiKey_constraint;
	/** input type for inserting data into table "openAIApiKey" */
	["openAIApiKey_insert_input"]: {
		apiKey?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		last_used?: ModelTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** aggregate max on columns */
	["openAIApiKey_max_fields"]: {
		apiKey?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		last_used?: ModelTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** aggregate min on columns */
	["openAIApiKey_min_fields"]: {
		apiKey?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		last_used?: ModelTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** response of any mutation on the table "openAIApiKey" */
	["openAIApiKey_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["openAIApiKey"]>
	};
	/** on_conflict condition type for table "openAIApiKey" */
	["openAIApiKey_on_conflict"]: {
		constraint: ModelTypes["openAIApiKey_constraint"],
		update_columns: Array<ModelTypes["openAIApiKey_update_column"]>,
		where?: ModelTypes["openAIApiKey_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "openAIApiKey". */
	["openAIApiKey_order_by"]: {
		apiKey?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		last_used?: ModelTypes["order_by"] | undefined,
		status?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: openAIApiKey */
	["openAIApiKey_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["openAIApiKey_select_column"]: openAIApiKey_select_column;
	/** input type for updating data in table "openAIApiKey" */
	["openAIApiKey_set_input"]: {
		apiKey?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		last_used?: ModelTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** Streaming cursor of the table "openAIApiKey" */
	["openAIApiKey_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["openAIApiKey_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["openAIApiKey_stream_cursor_value_input"]: {
		apiKey?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		last_used?: ModelTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	["openAIApiKey_update_column"]: openAIApiKey_update_column;
	["openAIApiKey_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["openAIApiKey_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["openAIApiKey_bool_exp"]
	};
	["order_by"]: order_by;
	/** columns and relationships of "pTemplate" */
	["pTemplate"]: {
		category?: string | undefined,
		createdBy: ModelTypes["uuid"],
		created_at: ModelTypes["timestamptz"],
		deletedAt?: ModelTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		/** A computed field, executes function "templateheight" */
		height?: string | undefined,
		id: ModelTypes["uuid"],
		json: ModelTypes["jsonb"],
		previewFileId?: ModelTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		tagScores?: ModelTypes["jsonb"] | undefined,
		titleMaxLength?: number | undefined,
		updated_at: ModelTypes["timestamptz"],
		visibility: string,
		/** A computed field, executes function "templatewidth" */
		width?: string | undefined
	};
	/** aggregated selection of "pTemplate" */
	["pTemplate_aggregate"]: {
		aggregate?: ModelTypes["pTemplate_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["pTemplate"]>
	};
	/** aggregate fields of "pTemplate" */
	["pTemplate_aggregate_fields"]: {
		avg?: ModelTypes["pTemplate_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["pTemplate_max_fields"] | undefined,
		min?: ModelTypes["pTemplate_min_fields"] | undefined,
		stddev?: ModelTypes["pTemplate_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["pTemplate_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["pTemplate_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["pTemplate_sum_fields"] | undefined,
		var_pop?: ModelTypes["pTemplate_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["pTemplate_var_samp_fields"] | undefined,
		variance?: ModelTypes["pTemplate_variance_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["pTemplate_append_input"]: {
		json?: ModelTypes["jsonb"] | undefined,
		tagScores?: ModelTypes["jsonb"] | undefined
	};
	/** aggregate avg on columns */
	["pTemplate_avg_fields"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** Boolean expression to filter rows from the table "pTemplate". All fields are combined with a logical 'AND'. */
	["pTemplate_bool_exp"]: {
		_and?: Array<ModelTypes["pTemplate_bool_exp"]> | undefined,
		_not?: ModelTypes["pTemplate_bool_exp"] | undefined,
		_or?: Array<ModelTypes["pTemplate_bool_exp"]> | undefined,
		category?: ModelTypes["String_comparison_exp"] | undefined,
		createdBy?: ModelTypes["uuid_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		deletedAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		descriptionMaxLength?: ModelTypes["Int_comparison_exp"] | undefined,
		height?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		json?: ModelTypes["jsonb_comparison_exp"] | undefined,
		previewFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		subtitleMaxLength?: ModelTypes["Int_comparison_exp"] | undefined,
		tagScores?: ModelTypes["jsonb_comparison_exp"] | undefined,
		titleMaxLength?: ModelTypes["Int_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		visibility?: ModelTypes["String_comparison_exp"] | undefined,
		width?: ModelTypes["String_comparison_exp"] | undefined
	};
	["pTemplate_constraint"]: pTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["pTemplate_delete_at_path_input"]: {
		json?: Array<string> | undefined,
		tagScores?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["pTemplate_delete_elem_input"]: {
		json?: number | undefined,
		tagScores?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["pTemplate_delete_key_input"]: {
		json?: string | undefined,
		tagScores?: string | undefined
	};
	/** input type for incrementing numeric columns in table "pTemplate" */
	["pTemplate_inc_input"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** input type for inserting data into table "pTemplate" */
	["pTemplate_insert_input"]: {
		category?: string | undefined,
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		deletedAt?: ModelTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		id?: ModelTypes["uuid"] | undefined,
		json?: ModelTypes["jsonb"] | undefined,
		previewFileId?: ModelTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		tagScores?: ModelTypes["jsonb"] | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		visibility?: string | undefined
	};
	/** aggregate max on columns */
	["pTemplate_max_fields"]: {
		category?: string | undefined,
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		deletedAt?: ModelTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		/** A computed field, executes function "templateheight" */
		height?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		previewFileId?: ModelTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		visibility?: string | undefined,
		/** A computed field, executes function "templatewidth" */
		width?: string | undefined
	};
	/** aggregate min on columns */
	["pTemplate_min_fields"]: {
		category?: string | undefined,
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		deletedAt?: ModelTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		/** A computed field, executes function "templateheight" */
		height?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		previewFileId?: ModelTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		visibility?: string | undefined,
		/** A computed field, executes function "templatewidth" */
		width?: string | undefined
	};
	/** response of any mutation on the table "pTemplate" */
	["pTemplate_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["pTemplate"]>
	};
	/** on_conflict condition type for table "pTemplate" */
	["pTemplate_on_conflict"]: {
		constraint: ModelTypes["pTemplate_constraint"],
		update_columns: Array<ModelTypes["pTemplate_update_column"]>,
		where?: ModelTypes["pTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "pTemplate". */
	["pTemplate_order_by"]: {
		category?: ModelTypes["order_by"] | undefined,
		createdBy?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		deletedAt?: ModelTypes["order_by"] | undefined,
		descriptionMaxLength?: ModelTypes["order_by"] | undefined,
		height?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		json?: ModelTypes["order_by"] | undefined,
		previewFileId?: ModelTypes["order_by"] | undefined,
		subtitleMaxLength?: ModelTypes["order_by"] | undefined,
		tagScores?: ModelTypes["order_by"] | undefined,
		titleMaxLength?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		visibility?: ModelTypes["order_by"] | undefined,
		width?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: pTemplate */
	["pTemplate_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["pTemplate_prepend_input"]: {
		json?: ModelTypes["jsonb"] | undefined,
		tagScores?: ModelTypes["jsonb"] | undefined
	};
	["pTemplate_select_column"]: pTemplate_select_column;
	/** input type for updating data in table "pTemplate" */
	["pTemplate_set_input"]: {
		category?: string | undefined,
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		deletedAt?: ModelTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		id?: ModelTypes["uuid"] | undefined,
		json?: ModelTypes["jsonb"] | undefined,
		previewFileId?: ModelTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		tagScores?: ModelTypes["jsonb"] | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		visibility?: string | undefined
	};
	/** aggregate stddev on columns */
	["pTemplate_stddev_fields"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["pTemplate_stddev_pop_fields"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["pTemplate_stddev_samp_fields"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** Streaming cursor of the table "pTemplate" */
	["pTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["pTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["pTemplate_stream_cursor_value_input"]: {
		category?: string | undefined,
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		deletedAt?: ModelTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		id?: ModelTypes["uuid"] | undefined,
		json?: ModelTypes["jsonb"] | undefined,
		previewFileId?: ModelTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		tagScores?: ModelTypes["jsonb"] | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		visibility?: string | undefined
	};
	/** aggregate sum on columns */
	["pTemplate_sum_fields"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	["pTemplate_update_column"]: pTemplate_update_column;
	["pTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["pTemplate_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["pTemplate_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["pTemplate_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["pTemplate_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["pTemplate_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["pTemplate_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["pTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["pTemplate_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["pTemplate_var_pop_fields"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** aggregate var_samp on columns */
	["pTemplate_var_samp_fields"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** aggregate variance on columns */
	["pTemplate_variance_fields"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** columns and relationships of "pricingPlan" */
	["pricingPlan"]: {
		active: boolean,
		alias?: string | undefined,
		created_at: ModelTypes["timestamptz"],
		creditUnitDiscount: ModelTypes["numeric"],
		creditsToAdd?: ModelTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id: ModelTypes["uuid"],
		monthlyPrice?: ModelTypes["numeric"] | undefined,
		order?: ModelTypes["numeric"] | undefined,
		stripePriceId: string,
		stripeProductId: string,
		type?: string | undefined,
		updated_at: ModelTypes["timestamptz"]
	};
	/** aggregated selection of "pricingPlan" */
	["pricingPlan_aggregate"]: {
		aggregate?: ModelTypes["pricingPlan_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["pricingPlan"]>
	};
	/** aggregate fields of "pricingPlan" */
	["pricingPlan_aggregate_fields"]: {
		avg?: ModelTypes["pricingPlan_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["pricingPlan_max_fields"] | undefined,
		min?: ModelTypes["pricingPlan_min_fields"] | undefined,
		stddev?: ModelTypes["pricingPlan_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["pricingPlan_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["pricingPlan_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["pricingPlan_sum_fields"] | undefined,
		var_pop?: ModelTypes["pricingPlan_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["pricingPlan_var_samp_fields"] | undefined,
		variance?: ModelTypes["pricingPlan_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["pricingPlan_avg_fields"]: {
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** Boolean expression to filter rows from the table "pricingPlan". All fields are combined with a logical 'AND'. */
	["pricingPlan_bool_exp"]: {
		_and?: Array<ModelTypes["pricingPlan_bool_exp"]> | undefined,
		_not?: ModelTypes["pricingPlan_bool_exp"] | undefined,
		_or?: Array<ModelTypes["pricingPlan_bool_exp"]> | undefined,
		active?: ModelTypes["Boolean_comparison_exp"] | undefined,
		alias?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		creditUnitDiscount?: ModelTypes["numeric_comparison_exp"] | undefined,
		creditsToAdd?: ModelTypes["numeric_comparison_exp"] | undefined,
		htmlFeatureItems?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		monthlyPrice?: ModelTypes["numeric_comparison_exp"] | undefined,
		order?: ModelTypes["numeric_comparison_exp"] | undefined,
		stripePriceId?: ModelTypes["String_comparison_exp"] | undefined,
		stripeProductId?: ModelTypes["String_comparison_exp"] | undefined,
		type?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["pricingPlan_constraint"]: pricingPlan_constraint;
	/** input type for incrementing numeric columns in table "pricingPlan" */
	["pricingPlan_inc_input"]: {
		creditUnitDiscount?: ModelTypes["numeric"] | undefined,
		creditsToAdd?: ModelTypes["numeric"] | undefined,
		monthlyPrice?: ModelTypes["numeric"] | undefined,
		order?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "pricingPlan" */
	["pricingPlan_insert_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditUnitDiscount?: ModelTypes["numeric"] | undefined,
		creditsToAdd?: ModelTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		monthlyPrice?: ModelTypes["numeric"] | undefined,
		order?: ModelTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["pricingPlan_max_fields"]: {
		alias?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditUnitDiscount?: ModelTypes["numeric"] | undefined,
		creditsToAdd?: ModelTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		monthlyPrice?: ModelTypes["numeric"] | undefined,
		order?: ModelTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["pricingPlan_min_fields"]: {
		alias?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditUnitDiscount?: ModelTypes["numeric"] | undefined,
		creditsToAdd?: ModelTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		monthlyPrice?: ModelTypes["numeric"] | undefined,
		order?: ModelTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "pricingPlan" */
	["pricingPlan_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["pricingPlan"]>
	};
	/** input type for inserting object relation for remote table "pricingPlan" */
	["pricingPlan_obj_rel_insert_input"]: {
		data: ModelTypes["pricingPlan_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["pricingPlan_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "pricingPlan" */
	["pricingPlan_on_conflict"]: {
		constraint: ModelTypes["pricingPlan_constraint"],
		update_columns: Array<ModelTypes["pricingPlan_update_column"]>,
		where?: ModelTypes["pricingPlan_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "pricingPlan". */
	["pricingPlan_order_by"]: {
		active?: ModelTypes["order_by"] | undefined,
		alias?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		creditUnitDiscount?: ModelTypes["order_by"] | undefined,
		creditsToAdd?: ModelTypes["order_by"] | undefined,
		htmlFeatureItems?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		monthlyPrice?: ModelTypes["order_by"] | undefined,
		order?: ModelTypes["order_by"] | undefined,
		stripePriceId?: ModelTypes["order_by"] | undefined,
		stripeProductId?: ModelTypes["order_by"] | undefined,
		type?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: pricingPlan */
	["pricingPlan_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["pricingPlan_select_column"]: pricingPlan_select_column;
	/** input type for updating data in table "pricingPlan" */
	["pricingPlan_set_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditUnitDiscount?: ModelTypes["numeric"] | undefined,
		creditsToAdd?: ModelTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		monthlyPrice?: ModelTypes["numeric"] | undefined,
		order?: ModelTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["pricingPlan_stddev_fields"]: {
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["pricingPlan_stddev_pop_fields"]: {
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["pricingPlan_stddev_samp_fields"]: {
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** Streaming cursor of the table "pricingPlan" */
	["pricingPlan_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["pricingPlan_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["pricingPlan_stream_cursor_value_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditUnitDiscount?: ModelTypes["numeric"] | undefined,
		creditsToAdd?: ModelTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		monthlyPrice?: ModelTypes["numeric"] | undefined,
		order?: ModelTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["pricingPlan_sum_fields"]: {
		creditUnitDiscount?: ModelTypes["numeric"] | undefined,
		creditsToAdd?: ModelTypes["numeric"] | undefined,
		monthlyPrice?: ModelTypes["numeric"] | undefined,
		order?: ModelTypes["numeric"] | undefined
	};
	["pricingPlan_update_column"]: pricingPlan_update_column;
	["pricingPlan_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["pricingPlan_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["pricingPlan_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["pricingPlan_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["pricingPlan_var_pop_fields"]: {
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** aggregate var_samp on columns */
	["pricingPlan_var_samp_fields"]: {
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** aggregate variance on columns */
	["pricingPlan_variance_fields"]: {
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** columns and relationships of "products" */
	["products"]: {
		alias: string,
		benefits?: ModelTypes["jsonb"] | undefined,
		/** An object relationship */
		brand?: ModelTypes["brands"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		category?: string | undefined,
		characteristics?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by: ModelTypes["uuid"],
		deleted: boolean,
		description?: string | undefined,
		id: ModelTypes["uuid"],
		imageFourId?: ModelTypes["uuid"] | undefined,
		imageOneId?: ModelTypes["uuid"] | undefined,
		imageThreeId?: ModelTypes["uuid"] | undefined,
		imageTwoId?: ModelTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: ModelTypes["numeric"] | undefined,
		salePrice?: ModelTypes["numeric"] | undefined,
		testimonials?: ModelTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		wordKeys?: ModelTypes["jsonb"] | undefined
	};
	/** aggregated selection of "products" */
	["products_aggregate"]: {
		aggregate?: ModelTypes["products_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["products"]>
	};
	["products_aggregate_bool_exp"]: {
		bool_and?: ModelTypes["products_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: ModelTypes["products_aggregate_bool_exp_bool_or"] | undefined,
		count?: ModelTypes["products_aggregate_bool_exp_count"] | undefined
	};
	["products_aggregate_bool_exp_bool_and"]: {
		arguments: ModelTypes["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["products_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["products_aggregate_bool_exp_bool_or"]: {
		arguments: ModelTypes["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["products_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["products_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["products_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["products_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "products" */
	["products_aggregate_fields"]: {
		avg?: ModelTypes["products_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["products_max_fields"] | undefined,
		min?: ModelTypes["products_min_fields"] | undefined,
		stddev?: ModelTypes["products_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["products_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["products_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["products_sum_fields"] | undefined,
		var_pop?: ModelTypes["products_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["products_var_samp_fields"] | undefined,
		variance?: ModelTypes["products_variance_fields"] | undefined
	};
	/** order by aggregate values of table "products" */
	["products_aggregate_order_by"]: {
		avg?: ModelTypes["products_avg_order_by"] | undefined,
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["products_max_order_by"] | undefined,
		min?: ModelTypes["products_min_order_by"] | undefined,
		stddev?: ModelTypes["products_stddev_order_by"] | undefined,
		stddev_pop?: ModelTypes["products_stddev_pop_order_by"] | undefined,
		stddev_samp?: ModelTypes["products_stddev_samp_order_by"] | undefined,
		sum?: ModelTypes["products_sum_order_by"] | undefined,
		var_pop?: ModelTypes["products_var_pop_order_by"] | undefined,
		var_samp?: ModelTypes["products_var_samp_order_by"] | undefined,
		variance?: ModelTypes["products_variance_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["products_append_input"]: {
		benefits?: ModelTypes["jsonb"] | undefined,
		characteristics?: ModelTypes["jsonb"] | undefined,
		testimonials?: ModelTypes["jsonb"] | undefined,
		wordKeys?: ModelTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "products" */
	["products_arr_rel_insert_input"]: {
		data: Array<ModelTypes["products_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["products_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["products_avg_fields"]: {
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by avg() on columns of table "products" */
	["products_avg_order_by"]: {
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
	["products_bool_exp"]: {
		_and?: Array<ModelTypes["products_bool_exp"]> | undefined,
		_not?: ModelTypes["products_bool_exp"] | undefined,
		_or?: Array<ModelTypes["products_bool_exp"]> | undefined,
		alias?: ModelTypes["String_comparison_exp"] | undefined,
		benefits?: ModelTypes["jsonb_comparison_exp"] | undefined,
		brand?: ModelTypes["brands_bool_exp"] | undefined,
		brandId?: ModelTypes["uuid_comparison_exp"] | undefined,
		category?: ModelTypes["String_comparison_exp"] | undefined,
		characteristics?: ModelTypes["jsonb_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		deleted?: ModelTypes["Boolean_comparison_exp"] | undefined,
		description?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		imageFourId?: ModelTypes["uuid_comparison_exp"] | undefined,
		imageOneId?: ModelTypes["uuid_comparison_exp"] | undefined,
		imageThreeId?: ModelTypes["uuid_comparison_exp"] | undefined,
		imageTwoId?: ModelTypes["uuid_comparison_exp"] | undefined,
		linkConversion?: ModelTypes["String_comparison_exp"] | undefined,
		price?: ModelTypes["numeric_comparison_exp"] | undefined,
		salePrice?: ModelTypes["numeric_comparison_exp"] | undefined,
		testimonials?: ModelTypes["jsonb_comparison_exp"] | undefined,
		type?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		wordKeys?: ModelTypes["jsonb_comparison_exp"] | undefined
	};
	["products_constraint"]: products_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["products_delete_at_path_input"]: {
		benefits?: Array<string> | undefined,
		characteristics?: Array<string> | undefined,
		testimonials?: Array<string> | undefined,
		wordKeys?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["products_delete_elem_input"]: {
		benefits?: number | undefined,
		characteristics?: number | undefined,
		testimonials?: number | undefined,
		wordKeys?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["products_delete_key_input"]: {
		benefits?: string | undefined,
		characteristics?: string | undefined,
		testimonials?: string | undefined,
		wordKeys?: string | undefined
	};
	/** input type for incrementing numeric columns in table "products" */
	["products_inc_input"]: {
		price?: ModelTypes["numeric"] | undefined,
		salePrice?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "products" */
	["products_insert_input"]: {
		alias?: string | undefined,
		benefits?: ModelTypes["jsonb"] | undefined,
		brand?: ModelTypes["brands_obj_rel_insert_input"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		category?: string | undefined,
		characteristics?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageFourId?: ModelTypes["uuid"] | undefined,
		imageOneId?: ModelTypes["uuid"] | undefined,
		imageThreeId?: ModelTypes["uuid"] | undefined,
		imageTwoId?: ModelTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: ModelTypes["numeric"] | undefined,
		salePrice?: ModelTypes["numeric"] | undefined,
		testimonials?: ModelTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		wordKeys?: ModelTypes["jsonb"] | undefined
	};
	/** aggregate max on columns */
	["products_max_fields"]: {
		alias?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		category?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageFourId?: ModelTypes["uuid"] | undefined,
		imageOneId?: ModelTypes["uuid"] | undefined,
		imageThreeId?: ModelTypes["uuid"] | undefined,
		imageTwoId?: ModelTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: ModelTypes["numeric"] | undefined,
		salePrice?: ModelTypes["numeric"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "products" */
	["products_max_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		brandId?: ModelTypes["order_by"] | undefined,
		category?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		imageFourId?: ModelTypes["order_by"] | undefined,
		imageOneId?: ModelTypes["order_by"] | undefined,
		imageThreeId?: ModelTypes["order_by"] | undefined,
		imageTwoId?: ModelTypes["order_by"] | undefined,
		linkConversion?: ModelTypes["order_by"] | undefined,
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined,
		type?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["products_min_fields"]: {
		alias?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		category?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageFourId?: ModelTypes["uuid"] | undefined,
		imageOneId?: ModelTypes["uuid"] | undefined,
		imageThreeId?: ModelTypes["uuid"] | undefined,
		imageTwoId?: ModelTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: ModelTypes["numeric"] | undefined,
		salePrice?: ModelTypes["numeric"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "products" */
	["products_min_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		brandId?: ModelTypes["order_by"] | undefined,
		category?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		imageFourId?: ModelTypes["order_by"] | undefined,
		imageOneId?: ModelTypes["order_by"] | undefined,
		imageThreeId?: ModelTypes["order_by"] | undefined,
		imageTwoId?: ModelTypes["order_by"] | undefined,
		linkConversion?: ModelTypes["order_by"] | undefined,
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined,
		type?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "products" */
	["products_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["products"]>
	};
	/** input type for inserting object relation for remote table "products" */
	["products_obj_rel_insert_input"]: {
		data: ModelTypes["products_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["products_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "products" */
	["products_on_conflict"]: {
		constraint: ModelTypes["products_constraint"],
		update_columns: Array<ModelTypes["products_update_column"]>,
		where?: ModelTypes["products_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "products". */
	["products_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		benefits?: ModelTypes["order_by"] | undefined,
		brand?: ModelTypes["brands_order_by"] | undefined,
		brandId?: ModelTypes["order_by"] | undefined,
		category?: ModelTypes["order_by"] | undefined,
		characteristics?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		deleted?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		imageFourId?: ModelTypes["order_by"] | undefined,
		imageOneId?: ModelTypes["order_by"] | undefined,
		imageThreeId?: ModelTypes["order_by"] | undefined,
		imageTwoId?: ModelTypes["order_by"] | undefined,
		linkConversion?: ModelTypes["order_by"] | undefined,
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined,
		testimonials?: ModelTypes["order_by"] | undefined,
		type?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		wordKeys?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: products */
	["products_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["products_prepend_input"]: {
		benefits?: ModelTypes["jsonb"] | undefined,
		characteristics?: ModelTypes["jsonb"] | undefined,
		testimonials?: ModelTypes["jsonb"] | undefined,
		wordKeys?: ModelTypes["jsonb"] | undefined
	};
	["products_select_column"]: products_select_column;
	["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"]: products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns;
	["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"]: products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "products" */
	["products_set_input"]: {
		alias?: string | undefined,
		benefits?: ModelTypes["jsonb"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		category?: string | undefined,
		characteristics?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageFourId?: ModelTypes["uuid"] | undefined,
		imageOneId?: ModelTypes["uuid"] | undefined,
		imageThreeId?: ModelTypes["uuid"] | undefined,
		imageTwoId?: ModelTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: ModelTypes["numeric"] | undefined,
		salePrice?: ModelTypes["numeric"] | undefined,
		testimonials?: ModelTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		wordKeys?: ModelTypes["jsonb"] | undefined
	};
	/** aggregate stddev on columns */
	["products_stddev_fields"]: {
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by stddev() on columns of table "products" */
	["products_stddev_order_by"]: {
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["products_stddev_pop_fields"]: {
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by stddev_pop() on columns of table "products" */
	["products_stddev_pop_order_by"]: {
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["products_stddev_samp_fields"]: {
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by stddev_samp() on columns of table "products" */
	["products_stddev_samp_order_by"]: {
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "products" */
	["products_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["products_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["products_stream_cursor_value_input"]: {
		alias?: string | undefined,
		benefits?: ModelTypes["jsonb"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		category?: string | undefined,
		characteristics?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		imageFourId?: ModelTypes["uuid"] | undefined,
		imageOneId?: ModelTypes["uuid"] | undefined,
		imageThreeId?: ModelTypes["uuid"] | undefined,
		imageTwoId?: ModelTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: ModelTypes["numeric"] | undefined,
		salePrice?: ModelTypes["numeric"] | undefined,
		testimonials?: ModelTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		wordKeys?: ModelTypes["jsonb"] | undefined
	};
	/** aggregate sum on columns */
	["products_sum_fields"]: {
		price?: ModelTypes["numeric"] | undefined,
		salePrice?: ModelTypes["numeric"] | undefined
	};
	/** order by sum() on columns of table "products" */
	["products_sum_order_by"]: {
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined
	};
	["products_update_column"]: products_update_column;
	["products_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["products_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["products_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["products_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["products_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["products_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["products_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["products_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["products_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["products_var_pop_fields"]: {
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by var_pop() on columns of table "products" */
	["products_var_pop_order_by"]: {
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["products_var_samp_fields"]: {
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by var_samp() on columns of table "products" */
	["products_var_samp_order_by"]: {
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["products_variance_fields"]: {
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by variance() on columns of table "products" */
	["products_variance_order_by"]: {
		price?: ModelTypes["order_by"] | undefined,
		salePrice?: ModelTypes["order_by"] | undefined
	};
	/** columns and relationships of "project" */
	["project"]: {
		alias: string,
		audienceId?: ModelTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignId?: ModelTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		creditPrice: ModelTypes["numeric"],
		deleted: boolean,
		featuredImageFileId?: ModelTypes["uuid"] | undefined,
		id: ModelTypes["uuid"],
		/** An object relationship */
		previewImageFile?: ModelTypes["files"] | undefined,
		previewImageFileId?: ModelTypes["uuid"] | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		rawText: string,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		status: string,
		suggestedScheduledDate?: ModelTypes["timestamptz"] | undefined,
		template?: ModelTypes["jsonb"] | undefined,
		type: string,
		updated_at: ModelTypes["timestamptz"]
	};
	/** columns and relationships of "projectImage" */
	["projectImage"]: {
		fileId: ModelTypes["uuid"],
		id: ModelTypes["uuid"],
		projectId: ModelTypes["uuid"]
	};
	/** aggregated selection of "projectImage" */
	["projectImage_aggregate"]: {
		aggregate?: ModelTypes["projectImage_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["projectImage"]>
	};
	/** aggregate fields of "projectImage" */
	["projectImage_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["projectImage_max_fields"] | undefined,
		min?: ModelTypes["projectImage_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "projectImage". All fields are combined with a logical 'AND'. */
	["projectImage_bool_exp"]: {
		_and?: Array<ModelTypes["projectImage_bool_exp"]> | undefined,
		_not?: ModelTypes["projectImage_bool_exp"] | undefined,
		_or?: Array<ModelTypes["projectImage_bool_exp"]> | undefined,
		fileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		projectId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["projectImage_constraint"]: projectImage_constraint;
	/** input type for inserting data into table "projectImage" */
	["projectImage_insert_input"]: {
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		projectId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["projectImage_max_fields"]: {
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		projectId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["projectImage_min_fields"]: {
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		projectId?: ModelTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "projectImage" */
	["projectImage_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["projectImage"]>
	};
	/** on_conflict condition type for table "projectImage" */
	["projectImage_on_conflict"]: {
		constraint: ModelTypes["projectImage_constraint"],
		update_columns: Array<ModelTypes["projectImage_update_column"]>,
		where?: ModelTypes["projectImage_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "projectImage". */
	["projectImage_order_by"]: {
		fileId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		projectId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: projectImage */
	["projectImage_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["projectImage_select_column"]: projectImage_select_column;
	/** input type for updating data in table "projectImage" */
	["projectImage_set_input"]: {
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		projectId?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "projectImage" */
	["projectImage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["projectImage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["projectImage_stream_cursor_value_input"]: {
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		projectId?: ModelTypes["uuid"] | undefined
	};
	["projectImage_update_column"]: projectImage_update_column;
	["projectImage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["projectImage_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["projectImage_bool_exp"]
	};
	/** aggregated selection of "project" */
	["project_aggregate"]: {
		aggregate?: ModelTypes["project_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["project"]>
	};
	/** aggregate fields of "project" */
	["project_aggregate_fields"]: {
		avg?: ModelTypes["project_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["project_max_fields"] | undefined,
		min?: ModelTypes["project_min_fields"] | undefined,
		stddev?: ModelTypes["project_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["project_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["project_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["project_sum_fields"] | undefined,
		var_pop?: ModelTypes["project_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["project_var_samp_fields"] | undefined,
		variance?: ModelTypes["project_variance_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["project_append_input"]: {
		template?: ModelTypes["jsonb"] | undefined
	};
	/** aggregate avg on columns */
	["project_avg_fields"]: {
		creditPrice?: number | undefined
	};
	/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
	["project_bool_exp"]: {
		_and?: Array<ModelTypes["project_bool_exp"]> | undefined,
		_not?: ModelTypes["project_bool_exp"] | undefined,
		_or?: Array<ModelTypes["project_bool_exp"]> | undefined,
		alias?: ModelTypes["String_comparison_exp"] | undefined,
		audienceId?: ModelTypes["uuid_comparison_exp"] | undefined,
		blogContent?: ModelTypes["String_comparison_exp"] | undefined,
		blogTitle?: ModelTypes["String_comparison_exp"] | undefined,
		brandId?: ModelTypes["uuid_comparison_exp"] | undefined,
		campaignId?: ModelTypes["uuid_comparison_exp"] | undefined,
		caption?: ModelTypes["String_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		creditPrice?: ModelTypes["numeric_comparison_exp"] | undefined,
		deleted?: ModelTypes["Boolean_comparison_exp"] | undefined,
		featuredImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		previewImageFile?: ModelTypes["files_bool_exp"] | undefined,
		previewImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		productId?: ModelTypes["uuid_comparison_exp"] | undefined,
		publishDate?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		rawText?: ModelTypes["String_comparison_exp"] | undefined,
		scheduledDate?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid_comparison_exp"] | undefined,
		status?: ModelTypes["String_comparison_exp"] | undefined,
		suggestedScheduledDate?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		template?: ModelTypes["jsonb_comparison_exp"] | undefined,
		type?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["project_constraint"]: project_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["project_delete_at_path_input"]: {
		template?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["project_delete_elem_input"]: {
		template?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["project_delete_key_input"]: {
		template?: string | undefined
	};
	/** input type for incrementing numeric columns in table "project" */
	["project_inc_input"]: {
		creditPrice?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "project" */
	["project_insert_input"]: {
		alias?: string | undefined,
		audienceId?: ModelTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignId?: ModelTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		creditPrice?: ModelTypes["numeric"] | undefined,
		deleted?: boolean | undefined,
		featuredImageFileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		previewImageFile?: ModelTypes["files_obj_rel_insert_input"] | undefined,
		previewImageFileId?: ModelTypes["uuid"] | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: ModelTypes["timestamptz"] | undefined,
		template?: ModelTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["project_max_fields"]: {
		alias?: string | undefined,
		audienceId?: ModelTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignId?: ModelTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		creditPrice?: ModelTypes["numeric"] | undefined,
		featuredImageFileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		previewImageFileId?: ModelTypes["uuid"] | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: ModelTypes["timestamptz"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["project_min_fields"]: {
		alias?: string | undefined,
		audienceId?: ModelTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignId?: ModelTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		creditPrice?: ModelTypes["numeric"] | undefined,
		featuredImageFileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		previewImageFileId?: ModelTypes["uuid"] | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: ModelTypes["timestamptz"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "project" */
	["project_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["project"]>
	};
	/** input type for inserting object relation for remote table "project" */
	["project_obj_rel_insert_input"]: {
		data: ModelTypes["project_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["project_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "project" */
	["project_on_conflict"]: {
		constraint: ModelTypes["project_constraint"],
		update_columns: Array<ModelTypes["project_update_column"]>,
		where?: ModelTypes["project_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "project". */
	["project_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		audienceId?: ModelTypes["order_by"] | undefined,
		blogContent?: ModelTypes["order_by"] | undefined,
		blogTitle?: ModelTypes["order_by"] | undefined,
		brandId?: ModelTypes["order_by"] | undefined,
		campaignId?: ModelTypes["order_by"] | undefined,
		caption?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		creditPrice?: ModelTypes["order_by"] | undefined,
		deleted?: ModelTypes["order_by"] | undefined,
		featuredImageFileId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		previewImageFile?: ModelTypes["files_order_by"] | undefined,
		previewImageFileId?: ModelTypes["order_by"] | undefined,
		productId?: ModelTypes["order_by"] | undefined,
		publishDate?: ModelTypes["order_by"] | undefined,
		rawText?: ModelTypes["order_by"] | undefined,
		scheduledDate?: ModelTypes["order_by"] | undefined,
		scheduledDateEventId?: ModelTypes["order_by"] | undefined,
		status?: ModelTypes["order_by"] | undefined,
		suggestedScheduledDate?: ModelTypes["order_by"] | undefined,
		template?: ModelTypes["order_by"] | undefined,
		type?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: project */
	["project_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["project_prepend_input"]: {
		template?: ModelTypes["jsonb"] | undefined
	};
	["project_select_column"]: project_select_column;
	/** input type for updating data in table "project" */
	["project_set_input"]: {
		alias?: string | undefined,
		audienceId?: ModelTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignId?: ModelTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		creditPrice?: ModelTypes["numeric"] | undefined,
		deleted?: boolean | undefined,
		featuredImageFileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		previewImageFileId?: ModelTypes["uuid"] | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: ModelTypes["timestamptz"] | undefined,
		template?: ModelTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["project_stddev_fields"]: {
		creditPrice?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["project_stddev_pop_fields"]: {
		creditPrice?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["project_stddev_samp_fields"]: {
		creditPrice?: number | undefined
	};
	/** Streaming cursor of the table "project" */
	["project_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["project_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["project_stream_cursor_value_input"]: {
		alias?: string | undefined,
		audienceId?: ModelTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		campaignId?: ModelTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		creditPrice?: ModelTypes["numeric"] | undefined,
		deleted?: boolean | undefined,
		featuredImageFileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		previewImageFileId?: ModelTypes["uuid"] | undefined,
		productId?: ModelTypes["uuid"] | undefined,
		publishDate?: ModelTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: ModelTypes["timestamptz"] | undefined,
		scheduledDateEventId?: ModelTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: ModelTypes["timestamptz"] | undefined,
		template?: ModelTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["project_sum_fields"]: {
		creditPrice?: ModelTypes["numeric"] | undefined
	};
	["project_update_column"]: project_update_column;
	["project_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["project_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["project_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["project_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["project_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["project_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["project_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["project_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["project_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["project_var_pop_fields"]: {
		creditPrice?: number | undefined
	};
	/** aggregate var_samp on columns */
	["project_var_samp_fields"]: {
		creditPrice?: number | undefined
	};
	/** aggregate variance on columns */
	["project_variance_fields"]: {
		creditPrice?: number | undefined
	};
	["query_root"]: {
		/** fetch data from the table: "AppIntegration" */
		AppIntegration: Array<ModelTypes["AppIntegration"]>,
		/** fetch aggregated fields from the table: "AppIntegration" */
		AppIntegration_aggregate: ModelTypes["AppIntegration_aggregate"],
		/** fetch data from the table: "AppIntegration" using primary key columns */
		AppIntegration_by_pk?: ModelTypes["AppIntegration"] | undefined,
		/** fetch data from the table: "Course" */
		Course: Array<ModelTypes["Course"]>,
		/** fetch data from the table: "CourseCategory" */
		CourseCategory: Array<ModelTypes["CourseCategory"]>,
		/** fetch aggregated fields from the table: "CourseCategory" */
		CourseCategory_aggregate: ModelTypes["CourseCategory_aggregate"],
		/** fetch data from the table: "CourseCategory" using primary key columns */
		CourseCategory_by_pk?: ModelTypes["CourseCategory"] | undefined,
		/** fetch aggregated fields from the table: "Course" */
		Course_aggregate: ModelTypes["Course_aggregate"],
		/** fetch data from the table: "Course" using primary key columns */
		Course_by_pk?: ModelTypes["Course"] | undefined,
		/** fetch data from the table: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate: Array<ModelTypes["GeneratedPromptByTemplate"]>,
		/** fetch data from the table: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage: Array<ModelTypes["GeneratedPromptByTemplateImage"]>,
		/** fetch aggregated fields from the table: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage_aggregate: ModelTypes["GeneratedPromptByTemplateImage_aggregate"],
		/** fetch data from the table: "GeneratedPromptByTemplateImage" using primary key columns */
		GeneratedPromptByTemplateImage_by_pk?: ModelTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** fetch aggregated fields from the table: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate_aggregate: ModelTypes["GeneratedPromptByTemplate_aggregate"],
		/** fetch data from the table: "GeneratedPromptByTemplate" using primary key columns */
		GeneratedPromptByTemplate_by_pk?: ModelTypes["GeneratedPromptByTemplate"] | undefined,
		/** fetch data from the table: "TextGeneratorOption" */
		TextGeneratorOption: Array<ModelTypes["TextGeneratorOption"]>,
		/** fetch data from the table: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry: Array<ModelTypes["TextGeneratorOptionEntry"]>,
		/** fetch aggregated fields from the table: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry_aggregate: ModelTypes["TextGeneratorOptionEntry_aggregate"],
		/** fetch data from the table: "TextGeneratorOptionEntry" using primary key columns */
		TextGeneratorOptionEntry_by_pk?: ModelTypes["TextGeneratorOptionEntry"] | undefined,
		/** fetch aggregated fields from the table: "TextGeneratorOption" */
		TextGeneratorOption_aggregate: ModelTypes["TextGeneratorOption_aggregate"],
		/** fetch data from the table: "TextGeneratorOption" using primary key columns */
		TextGeneratorOption_by_pk?: ModelTypes["TextGeneratorOption"] | undefined,
		/** fetch data from the table: "TextPromptTemplate" */
		TextPromptTemplate: Array<ModelTypes["TextPromptTemplate"]>,
		/** fetch data from the table: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory: Array<ModelTypes["TextPromptTemplateCategory"]>,
		/** fetch aggregated fields from the table: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory_aggregate: ModelTypes["TextPromptTemplateCategory_aggregate"],
		/** fetch data from the table: "TextPromptTemplateCategory" using primary key columns */
		TextPromptTemplateCategory_by_pk?: ModelTypes["TextPromptTemplateCategory"] | undefined,
		/** fetch aggregated fields from the table: "TextPromptTemplate" */
		TextPromptTemplate_aggregate: ModelTypes["TextPromptTemplate_aggregate"],
		/** fetch data from the table: "TextPromptTemplate" using primary key columns */
		TextPromptTemplate_by_pk?: ModelTypes["TextPromptTemplate"] | undefined,
		/** fetch data from the table: "TextTemplateClientCategory" */
		TextTemplateClientCategory: Array<ModelTypes["TextTemplateClientCategory"]>,
		/** fetch aggregated fields from the table: "TextTemplateClientCategory" */
		TextTemplateClientCategory_aggregate: ModelTypes["TextTemplateClientCategory_aggregate"],
		/** fetch data from the table: "TextTemplateClientCategory" using primary key columns */
		TextTemplateClientCategory_by_pk?: ModelTypes["TextTemplateClientCategory"] | undefined,
		/** fetch data from the table: "audiences" */
		audiences: Array<ModelTypes["audiences"]>,
		/** fetch aggregated fields from the table: "audiences" */
		audiences_aggregate: ModelTypes["audiences_aggregate"],
		/** fetch data from the table: "audiences" using primary key columns */
		audiences_by_pk?: ModelTypes["audiences"] | undefined,
		/** fetch data from the table: "auth.providers" using primary key columns */
		authProvider?: ModelTypes["authProviders"] | undefined,
		/** fetch data from the table: "auth.provider_requests" using primary key columns */
		authProviderRequest?: ModelTypes["authProviderRequests"] | undefined,
		/** fetch data from the table: "auth.provider_requests" */
		authProviderRequests: Array<ModelTypes["authProviderRequests"]>,
		/** fetch aggregated fields from the table: "auth.provider_requests" */
		authProviderRequestsAggregate: ModelTypes["authProviderRequests_aggregate"],
		/** fetch data from the table: "auth.providers" */
		authProviders: Array<ModelTypes["authProviders"]>,
		/** fetch aggregated fields from the table: "auth.providers" */
		authProvidersAggregate: ModelTypes["authProviders_aggregate"],
		/** fetch data from the table: "auth.refresh_tokens" using primary key columns */
		authRefreshToken?: ModelTypes["authRefreshTokens"] | undefined,
		/** fetch data from the table: "auth.refresh_token_types" using primary key columns */
		authRefreshTokenType?: ModelTypes["authRefreshTokenTypes"] | undefined,
		/** fetch data from the table: "auth.refresh_token_types" */
		authRefreshTokenTypes: Array<ModelTypes["authRefreshTokenTypes"]>,
		/** fetch aggregated fields from the table: "auth.refresh_token_types" */
		authRefreshTokenTypesAggregate: ModelTypes["authRefreshTokenTypes_aggregate"],
		/** fetch data from the table: "auth.refresh_tokens" */
		authRefreshTokens: Array<ModelTypes["authRefreshTokens"]>,
		/** fetch aggregated fields from the table: "auth.refresh_tokens" */
		authRefreshTokensAggregate: ModelTypes["authRefreshTokens_aggregate"],
		/** fetch data from the table: "auth.roles" using primary key columns */
		authRole?: ModelTypes["authRoles"] | undefined,
		/** fetch data from the table: "auth.roles" */
		authRoles: Array<ModelTypes["authRoles"]>,
		/** fetch aggregated fields from the table: "auth.roles" */
		authRolesAggregate: ModelTypes["authRoles_aggregate"],
		/** fetch data from the table: "auth.user_providers" using primary key columns */
		authUserProvider?: ModelTypes["authUserProviders"] | undefined,
		/** fetch data from the table: "auth.user_providers" */
		authUserProviders: Array<ModelTypes["authUserProviders"]>,
		/** fetch aggregated fields from the table: "auth.user_providers" */
		authUserProvidersAggregate: ModelTypes["authUserProviders_aggregate"],
		/** fetch data from the table: "auth.user_roles" using primary key columns */
		authUserRole?: ModelTypes["authUserRoles"] | undefined,
		/** fetch data from the table: "auth.user_roles" */
		authUserRoles: Array<ModelTypes["authUserRoles"]>,
		/** fetch aggregated fields from the table: "auth.user_roles" */
		authUserRolesAggregate: ModelTypes["authUserRoles_aggregate"],
		/** fetch data from the table: "auth.user_security_keys" using primary key columns */
		authUserSecurityKey?: ModelTypes["authUserSecurityKeys"] | undefined,
		/** fetch data from the table: "auth.user_security_keys" */
		authUserSecurityKeys: Array<ModelTypes["authUserSecurityKeys"]>,
		/** fetch aggregated fields from the table: "auth.user_security_keys" */
		authUserSecurityKeysAggregate: ModelTypes["authUserSecurityKeys_aggregate"],
		/** fetch data from the table: "blog" */
		blog: Array<ModelTypes["blog"]>,
		/** fetch data from the table: "blogCategory" */
		blogCategory: Array<ModelTypes["blogCategory"]>,
		/** fetch aggregated fields from the table: "blogCategory" */
		blogCategory_aggregate: ModelTypes["blogCategory_aggregate"],
		/** fetch data from the table: "blogCategory" using primary key columns */
		blogCategory_by_pk?: ModelTypes["blogCategory"] | undefined,
		blogPostGeneratorEnhancedForAmp?: ModelTypes["blogPostGeneratorEnhancedForAmp"] | undefined,
		/** fetch aggregated fields from the table: "blog" */
		blog_aggregate: ModelTypes["blog_aggregate"],
		/** fetch data from the table: "blog" using primary key columns */
		blog_by_pk?: ModelTypes["blog"] | undefined,
		/** fetch data from the table: "brands" */
		brands: Array<ModelTypes["brands"]>,
		/** fetch aggregated fields from the table: "brands" */
		brands_aggregate: ModelTypes["brands_aggregate"],
		/** fetch data from the table: "brands" using primary key columns */
		brands_by_pk?: ModelTypes["brands"] | undefined,
		/** fetch data from the table: "storage.buckets" using primary key columns */
		bucket?: ModelTypes["buckets"] | undefined,
		/** fetch data from the table: "storage.buckets" */
		buckets: Array<ModelTypes["buckets"]>,
		/** fetch aggregated fields from the table: "storage.buckets" */
		bucketsAggregate: ModelTypes["buckets_aggregate"],
		/** fetch data from the table: "campaign" */
		campaign: Array<ModelTypes["campaign"]>,
		/** fetch aggregated fields from the table: "campaign" */
		campaign_aggregate: ModelTypes["campaign_aggregate"],
		/** fetch data from the table: "campaign" using primary key columns */
		campaign_by_pk?: ModelTypes["campaign"] | undefined,
		/** fetch data from the table: "categorysummaryview" */
		categorysummaryview: Array<ModelTypes["categorysummaryview"]>,
		/** fetch aggregated fields from the table: "categorysummaryview" */
		categorysummaryview_aggregate: ModelTypes["categorysummaryview_aggregate"],
		/** fetch data from the table: "channels" */
		channels: Array<ModelTypes["channels"]>,
		/** fetch aggregated fields from the table: "channels" */
		channels_aggregate: ModelTypes["channels_aggregate"],
		/** fetch data from the table: "channels" using primary key columns */
		channels_by_pk?: ModelTypes["channels"] | undefined,
		/** fetch data from the table: "consumptionByChannelView" */
		consumptionByChannelView: Array<ModelTypes["consumptionByChannelView"]>,
		/** fetch aggregated fields from the table: "consumptionByChannelView" */
		consumptionByChannelView_aggregate: ModelTypes["consumptionByChannelView_aggregate"],
		/** fetch data from the table: "costTable" */
		costTable: Array<ModelTypes["costTable"]>,
		/** fetch aggregated fields from the table: "costTable" */
		costTable_aggregate: ModelTypes["costTable_aggregate"],
		/** fetch data from the table: "costTable" using primary key columns */
		costTable_by_pk?: ModelTypes["costTable"] | undefined,
		/** fetch data from the table: "deal" */
		deal: Array<ModelTypes["deal"]>,
		/** fetch data from the table: "dealStatus" */
		dealStatus: Array<ModelTypes["dealStatus"]>,
		/** fetch aggregated fields from the table: "dealStatus" */
		dealStatus_aggregate: ModelTypes["dealStatus_aggregate"],
		/** fetch data from the table: "dealStatus" using primary key columns */
		dealStatus_by_pk?: ModelTypes["dealStatus"] | undefined,
		/** fetch aggregated fields from the table: "deal" */
		deal_aggregate: ModelTypes["deal_aggregate"],
		/** fetch data from the table: "deal" using primary key columns */
		deal_by_pk?: ModelTypes["deal"] | undefined,
		/** fetch data from the table: "displayCategory" */
		displayCategory: Array<ModelTypes["displayCategory"]>,
		/** fetch aggregated fields from the table: "displayCategory" */
		displayCategory_aggregate: ModelTypes["displayCategory_aggregate"],
		/** fetch data from the table: "displayCategory" using primary key columns */
		displayCategory_by_pk?: ModelTypes["displayCategory"] | undefined,
		/** fetch data from the table: "displayDimension" */
		displayDimension: Array<ModelTypes["displayDimension"]>,
		/** fetch aggregated fields from the table: "displayDimension" */
		displayDimension_aggregate: ModelTypes["displayDimension_aggregate"],
		/** fetch data from the table: "displayDimension" using primary key columns */
		displayDimension_by_pk?: ModelTypes["displayDimension"] | undefined,
		/** fetch data from the table: "displayFormats" */
		displayFormats: Array<ModelTypes["displayFormats"]>,
		/** fetch aggregated fields from the table: "displayFormats" */
		displayFormats_aggregate: ModelTypes["displayFormats_aggregate"],
		/** fetch data from the table: "displayFormats" using primary key columns */
		displayFormats_by_pk?: ModelTypes["displayFormats"] | undefined,
		/** fetch data from the table: "displayTemplate" */
		displayTemplate: Array<ModelTypes["displayTemplate"]>,
		/** fetch aggregated fields from the table: "displayTemplate" */
		displayTemplate_aggregate: ModelTypes["displayTemplate_aggregate"],
		/** fetch data from the table: "displayTemplate" using primary key columns */
		displayTemplate_by_pk?: ModelTypes["displayTemplate"] | undefined,
		/** fetch data from the table: "storage.files" using primary key columns */
		file?: ModelTypes["files"] | undefined,
		/** An array relationship */
		files: Array<ModelTypes["files"]>,
		/** fetch aggregated fields from the table: "storage.files" */
		filesAggregate: ModelTypes["files_aggregate"],
		generateImageFromPrompt?: ModelTypes["GenerateImageFromPromptOutput"] | undefined,
		generateNextMonthCalendar?: ModelTypes["generateNextMonthCalendar"] | undefined,
		getFacebookPages?: ModelTypes["GetFacebookPagesOutput"] | undefined,
		getFacebookToken?: ModelTypes["GetFacebookTokenOutput"] | undefined,
		getImageFromDisplayRenderer?: ModelTypes["GetImageFromDisplayRendererOutput"] | undefined,
		getInstagramPages?: ModelTypes["GetInstagramPagesOutput"] | undefined,
		getInstagramToken?: ModelTypes["GetInstagramTokenOutput"] | undefined,
		getPaymentUrl?: ModelTypes["GetPaymentUrlResponse"] | undefined,
		getWordpressCategories?: ModelTypes["GetWordpressCategoriesOutput"] | undefined,
		getWordpressTags?: ModelTypes["GetWordpressTagsOutput"] | undefined,
		/** fetch data from the table: "interaction" */
		interaction: Array<ModelTypes["interaction"]>,
		/** fetch aggregated fields from the table: "interaction" */
		interaction_aggregate: ModelTypes["interaction_aggregate"],
		/** fetch data from the table: "interaction" using primary key columns */
		interaction_by_pk?: ModelTypes["interaction"] | undefined,
		/** fetch data from the table: "landingPageTemplate" */
		landingPageTemplate: Array<ModelTypes["landingPageTemplate"]>,
		/** fetch aggregated fields from the table: "landingPageTemplate" */
		landingPageTemplate_aggregate: ModelTypes["landingPageTemplate_aggregate"],
		/** fetch data from the table: "landingPageTemplate" using primary key columns */
		landingPageTemplate_by_pk?: ModelTypes["landingPageTemplate"] | undefined,
		manageSubscription?: ModelTypes["ManageSubscriptionOutput"] | undefined,
		/** fetch data from the table: "newTemplateType" */
		newTemplateType: Array<ModelTypes["newTemplateType"]>,
		/** fetch aggregated fields from the table: "newTemplateType" */
		newTemplateType_aggregate: ModelTypes["newTemplateType_aggregate"],
		/** fetch data from the table: "newTemplateType" using primary key columns */
		newTemplateType_by_pk?: ModelTypes["newTemplateType"] | undefined,
		/** fetch data from the table: "openAIApiKey" */
		openAIApiKey: Array<ModelTypes["openAIApiKey"]>,
		/** fetch aggregated fields from the table: "openAIApiKey" */
		openAIApiKey_aggregate: ModelTypes["openAIApiKey_aggregate"],
		/** fetch data from the table: "openAIApiKey" using primary key columns */
		openAIApiKey_by_pk?: ModelTypes["openAIApiKey"] | undefined,
		/** fetch data from the table: "pTemplate" */
		pTemplate: Array<ModelTypes["pTemplate"]>,
		/** fetch aggregated fields from the table: "pTemplate" */
		pTemplate_aggregate: ModelTypes["pTemplate_aggregate"],
		/** fetch data from the table: "pTemplate" using primary key columns */
		pTemplate_by_pk?: ModelTypes["pTemplate"] | undefined,
		/** fetch data from the table: "pricingPlan" */
		pricingPlan: Array<ModelTypes["pricingPlan"]>,
		/** fetch aggregated fields from the table: "pricingPlan" */
		pricingPlan_aggregate: ModelTypes["pricingPlan_aggregate"],
		/** fetch data from the table: "pricingPlan" using primary key columns */
		pricingPlan_by_pk?: ModelTypes["pricingPlan"] | undefined,
		/** An array relationship */
		products: Array<ModelTypes["products"]>,
		/** An aggregate relationship */
		products_aggregate: ModelTypes["products_aggregate"],
		/** fetch data from the table: "products" using primary key columns */
		products_by_pk?: ModelTypes["products"] | undefined,
		/** fetch data from the table: "project" */
		project: Array<ModelTypes["project"]>,
		/** fetch data from the table: "projectImage" */
		projectImage: Array<ModelTypes["projectImage"]>,
		/** fetch aggregated fields from the table: "projectImage" */
		projectImage_aggregate: ModelTypes["projectImage_aggregate"],
		/** fetch data from the table: "projectImage" using primary key columns */
		projectImage_by_pk?: ModelTypes["projectImage"] | undefined,
		/** fetch aggregated fields from the table: "project" */
		project_aggregate: ModelTypes["project_aggregate"],
		/** fetch data from the table: "project" using primary key columns */
		project_by_pk?: ModelTypes["project"] | undefined,
		renderDisplay?: ModelTypes["RenderDisplayOutput"] | undefined,
		searchImagesFromPexels?: ModelTypes["SearchImagesFromPexelsResponse"] | undefined,
		serpStatTest?: ModelTypes["SerpStatTestOutput"] | undefined,
		/** fetch data from the table: "task" */
		task: Array<ModelTypes["task"]>,
		/** fetch aggregated fields from the table: "task" */
		task_aggregate: ModelTypes["task_aggregate"],
		/** fetch data from the table: "task" using primary key columns */
		task_by_pk?: ModelTypes["task"] | undefined,
		/** fetch data from the table: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat: Array<ModelTypes["textTemplateDisplayFormat"]>,
		/** fetch aggregated fields from the table: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat_aggregate: ModelTypes["textTemplateDisplayFormat_aggregate"],
		/** fetch data from the table: "textTemplateDisplayFormat" using primary key columns */
		textTemplateDisplayFormat_by_pk?: ModelTypes["textTemplateDisplayFormat"] | undefined,
		/** fetch data from the table: "auth.users" using primary key columns */
		user?: ModelTypes["users"] | undefined,
		/** fetch data from the table: "userAppIntegration" */
		userAppIntegration: Array<ModelTypes["userAppIntegration"]>,
		/** fetch aggregated fields from the table: "userAppIntegration" */
		userAppIntegration_aggregate: ModelTypes["userAppIntegration_aggregate"],
		/** fetch data from the table: "userAppIntegration" using primary key columns */
		userAppIntegration_by_pk?: ModelTypes["userAppIntegration"] | undefined,
		/** fetch data from the table: "userCreditUsage" */
		userCreditUsage: Array<ModelTypes["userCreditUsage"]>,
		/** fetch data from the table: "userCreditUsageSummary" */
		userCreditUsageSummary: Array<ModelTypes["userCreditUsageSummary"]>,
		/** fetch aggregated fields from the table: "userCreditUsageSummary" */
		userCreditUsageSummary_aggregate: ModelTypes["userCreditUsageSummary_aggregate"],
		/** fetch data from the table: "userCreditUsageSummary" using primary key columns */
		userCreditUsageSummary_by_pk?: ModelTypes["userCreditUsageSummary"] | undefined,
		/** fetch aggregated fields from the table: "userCreditUsage" */
		userCreditUsage_aggregate: ModelTypes["userCreditUsage_aggregate"],
		/** fetch data from the table: "userCreditUsage" using primary key columns */
		userCreditUsage_by_pk?: ModelTypes["userCreditUsage"] | undefined,
		/** fetch data from the table: "userCredits" */
		userCredits: Array<ModelTypes["userCredits"]>,
		/** fetch aggregated fields from the table: "userCredits" */
		userCredits_aggregate: ModelTypes["userCredits_aggregate"],
		/** fetch data from the table: "userCredits" using primary key columns */
		userCredits_by_pk?: ModelTypes["userCredits"] | undefined,
		/** fetch data from the table: "userFeedback" */
		userFeedback: Array<ModelTypes["userFeedback"]>,
		/** fetch aggregated fields from the table: "userFeedback" */
		userFeedback_aggregate: ModelTypes["userFeedback_aggregate"],
		/** fetch data from the table: "userFeedback" using primary key columns */
		userFeedback_by_pk?: ModelTypes["userFeedback"] | undefined,
		/** fetch data from the table: "userMedia" */
		userMedia: Array<ModelTypes["userMedia"]>,
		/** fetch aggregated fields from the table: "userMedia" */
		userMedia_aggregate: ModelTypes["userMedia_aggregate"],
		/** fetch data from the table: "userMedia" using primary key columns */
		userMedia_by_pk?: ModelTypes["userMedia"] | undefined,
		/** An array relationship */
		userMetadata: Array<ModelTypes["userMetadata"]>,
		/** An aggregate relationship */
		userMetadata_aggregate: ModelTypes["userMetadata_aggregate"],
		/** fetch data from the table: "userMetadata" using primary key columns */
		userMetadata_by_pk?: ModelTypes["userMetadata"] | undefined,
		/** fetch data from the table: "userOnboarding" */
		userOnboarding: Array<ModelTypes["userOnboarding"]>,
		/** fetch aggregated fields from the table: "userOnboarding" */
		userOnboarding_aggregate: ModelTypes["userOnboarding_aggregate"],
		/** fetch data from the table: "userOnboarding" using primary key columns */
		userOnboarding_by_pk?: ModelTypes["userOnboarding"] | undefined,
		/** fetch data from the table: "userTemplateUsage" */
		userTemplateUsage: Array<ModelTypes["userTemplateUsage"]>,
		/** fetch aggregated fields from the table: "userTemplateUsage" */
		userTemplateUsage_aggregate: ModelTypes["userTemplateUsage_aggregate"],
		/** fetch data from the table: "userTemplateUsage" using primary key columns */
		userTemplateUsage_by_pk?: ModelTypes["userTemplateUsage"] | undefined,
		/** fetch data from the table: "auth.users" */
		users: Array<ModelTypes["users"]>,
		/** fetch aggregated fields from the table: "auth.users" */
		usersAggregate: ModelTypes["users_aggregate"],
		/** fetch data from the table: "storage.virus" using primary key columns */
		virus?: ModelTypes["virus"] | undefined,
		/** fetch data from the table: "storage.virus" */
		viruses: Array<ModelTypes["virus"]>,
		/** fetch aggregated fields from the table: "storage.virus" */
		virusesAggregate: ModelTypes["virus_aggregate"],
		/** fetch data from the table: "website" */
		website: Array<ModelTypes["website"]>,
		/** fetch aggregated fields from the table: "website" */
		website_aggregate: ModelTypes["website_aggregate"],
		/** fetch data from the table: "website" using primary key columns */
		website_by_pk?: ModelTypes["website"] | undefined
	};
	["subscription_root"]: {
		/** fetch data from the table: "AppIntegration" */
		AppIntegration: Array<ModelTypes["AppIntegration"]>,
		/** fetch aggregated fields from the table: "AppIntegration" */
		AppIntegration_aggregate: ModelTypes["AppIntegration_aggregate"],
		/** fetch data from the table: "AppIntegration" using primary key columns */
		AppIntegration_by_pk?: ModelTypes["AppIntegration"] | undefined,
		/** fetch data from the table in a streaming manner: "AppIntegration" */
		AppIntegration_stream: Array<ModelTypes["AppIntegration"]>,
		/** fetch data from the table: "Course" */
		Course: Array<ModelTypes["Course"]>,
		/** fetch data from the table: "CourseCategory" */
		CourseCategory: Array<ModelTypes["CourseCategory"]>,
		/** fetch aggregated fields from the table: "CourseCategory" */
		CourseCategory_aggregate: ModelTypes["CourseCategory_aggregate"],
		/** fetch data from the table: "CourseCategory" using primary key columns */
		CourseCategory_by_pk?: ModelTypes["CourseCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "CourseCategory" */
		CourseCategory_stream: Array<ModelTypes["CourseCategory"]>,
		/** fetch aggregated fields from the table: "Course" */
		Course_aggregate: ModelTypes["Course_aggregate"],
		/** fetch data from the table: "Course" using primary key columns */
		Course_by_pk?: ModelTypes["Course"] | undefined,
		/** fetch data from the table in a streaming manner: "Course" */
		Course_stream: Array<ModelTypes["Course"]>,
		/** fetch data from the table: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate: Array<ModelTypes["GeneratedPromptByTemplate"]>,
		/** fetch data from the table: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage: Array<ModelTypes["GeneratedPromptByTemplateImage"]>,
		/** fetch aggregated fields from the table: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage_aggregate: ModelTypes["GeneratedPromptByTemplateImage_aggregate"],
		/** fetch data from the table: "GeneratedPromptByTemplateImage" using primary key columns */
		GeneratedPromptByTemplateImage_by_pk?: ModelTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** fetch data from the table in a streaming manner: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage_stream: Array<ModelTypes["GeneratedPromptByTemplateImage"]>,
		/** fetch aggregated fields from the table: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate_aggregate: ModelTypes["GeneratedPromptByTemplate_aggregate"],
		/** fetch data from the table: "GeneratedPromptByTemplate" using primary key columns */
		GeneratedPromptByTemplate_by_pk?: ModelTypes["GeneratedPromptByTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate_stream: Array<ModelTypes["GeneratedPromptByTemplate"]>,
		/** fetch data from the table: "TextGeneratorOption" */
		TextGeneratorOption: Array<ModelTypes["TextGeneratorOption"]>,
		/** fetch data from the table: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry: Array<ModelTypes["TextGeneratorOptionEntry"]>,
		/** fetch aggregated fields from the table: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry_aggregate: ModelTypes["TextGeneratorOptionEntry_aggregate"],
		/** fetch data from the table: "TextGeneratorOptionEntry" using primary key columns */
		TextGeneratorOptionEntry_by_pk?: ModelTypes["TextGeneratorOptionEntry"] | undefined,
		/** fetch data from the table in a streaming manner: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry_stream: Array<ModelTypes["TextGeneratorOptionEntry"]>,
		/** fetch aggregated fields from the table: "TextGeneratorOption" */
		TextGeneratorOption_aggregate: ModelTypes["TextGeneratorOption_aggregate"],
		/** fetch data from the table: "TextGeneratorOption" using primary key columns */
		TextGeneratorOption_by_pk?: ModelTypes["TextGeneratorOption"] | undefined,
		/** fetch data from the table in a streaming manner: "TextGeneratorOption" */
		TextGeneratorOption_stream: Array<ModelTypes["TextGeneratorOption"]>,
		/** fetch data from the table: "TextPromptTemplate" */
		TextPromptTemplate: Array<ModelTypes["TextPromptTemplate"]>,
		/** fetch data from the table: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory: Array<ModelTypes["TextPromptTemplateCategory"]>,
		/** fetch aggregated fields from the table: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory_aggregate: ModelTypes["TextPromptTemplateCategory_aggregate"],
		/** fetch data from the table: "TextPromptTemplateCategory" using primary key columns */
		TextPromptTemplateCategory_by_pk?: ModelTypes["TextPromptTemplateCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory_stream: Array<ModelTypes["TextPromptTemplateCategory"]>,
		/** fetch aggregated fields from the table: "TextPromptTemplate" */
		TextPromptTemplate_aggregate: ModelTypes["TextPromptTemplate_aggregate"],
		/** fetch data from the table: "TextPromptTemplate" using primary key columns */
		TextPromptTemplate_by_pk?: ModelTypes["TextPromptTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "TextPromptTemplate" */
		TextPromptTemplate_stream: Array<ModelTypes["TextPromptTemplate"]>,
		/** fetch data from the table: "TextTemplateClientCategory" */
		TextTemplateClientCategory: Array<ModelTypes["TextTemplateClientCategory"]>,
		/** fetch aggregated fields from the table: "TextTemplateClientCategory" */
		TextTemplateClientCategory_aggregate: ModelTypes["TextTemplateClientCategory_aggregate"],
		/** fetch data from the table: "TextTemplateClientCategory" using primary key columns */
		TextTemplateClientCategory_by_pk?: ModelTypes["TextTemplateClientCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "TextTemplateClientCategory" */
		TextTemplateClientCategory_stream: Array<ModelTypes["TextTemplateClientCategory"]>,
		/** fetch data from the table: "audiences" */
		audiences: Array<ModelTypes["audiences"]>,
		/** fetch aggregated fields from the table: "audiences" */
		audiences_aggregate: ModelTypes["audiences_aggregate"],
		/** fetch data from the table: "audiences" using primary key columns */
		audiences_by_pk?: ModelTypes["audiences"] | undefined,
		/** fetch data from the table in a streaming manner: "audiences" */
		audiences_stream: Array<ModelTypes["audiences"]>,
		/** fetch data from the table: "auth.providers" using primary key columns */
		authProvider?: ModelTypes["authProviders"] | undefined,
		/** fetch data from the table: "auth.provider_requests" using primary key columns */
		authProviderRequest?: ModelTypes["authProviderRequests"] | undefined,
		/** fetch data from the table: "auth.provider_requests" */
		authProviderRequests: Array<ModelTypes["authProviderRequests"]>,
		/** fetch aggregated fields from the table: "auth.provider_requests" */
		authProviderRequestsAggregate: ModelTypes["authProviderRequests_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.provider_requests" */
		authProviderRequests_stream: Array<ModelTypes["authProviderRequests"]>,
		/** fetch data from the table: "auth.providers" */
		authProviders: Array<ModelTypes["authProviders"]>,
		/** fetch aggregated fields from the table: "auth.providers" */
		authProvidersAggregate: ModelTypes["authProviders_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.providers" */
		authProviders_stream: Array<ModelTypes["authProviders"]>,
		/** fetch data from the table: "auth.refresh_tokens" using primary key columns */
		authRefreshToken?: ModelTypes["authRefreshTokens"] | undefined,
		/** fetch data from the table: "auth.refresh_token_types" using primary key columns */
		authRefreshTokenType?: ModelTypes["authRefreshTokenTypes"] | undefined,
		/** fetch data from the table: "auth.refresh_token_types" */
		authRefreshTokenTypes: Array<ModelTypes["authRefreshTokenTypes"]>,
		/** fetch aggregated fields from the table: "auth.refresh_token_types" */
		authRefreshTokenTypesAggregate: ModelTypes["authRefreshTokenTypes_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.refresh_token_types" */
		authRefreshTokenTypes_stream: Array<ModelTypes["authRefreshTokenTypes"]>,
		/** fetch data from the table: "auth.refresh_tokens" */
		authRefreshTokens: Array<ModelTypes["authRefreshTokens"]>,
		/** fetch aggregated fields from the table: "auth.refresh_tokens" */
		authRefreshTokensAggregate: ModelTypes["authRefreshTokens_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
		authRefreshTokens_stream: Array<ModelTypes["authRefreshTokens"]>,
		/** fetch data from the table: "auth.roles" using primary key columns */
		authRole?: ModelTypes["authRoles"] | undefined,
		/** fetch data from the table: "auth.roles" */
		authRoles: Array<ModelTypes["authRoles"]>,
		/** fetch aggregated fields from the table: "auth.roles" */
		authRolesAggregate: ModelTypes["authRoles_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.roles" */
		authRoles_stream: Array<ModelTypes["authRoles"]>,
		/** fetch data from the table: "auth.user_providers" using primary key columns */
		authUserProvider?: ModelTypes["authUserProviders"] | undefined,
		/** fetch data from the table: "auth.user_providers" */
		authUserProviders: Array<ModelTypes["authUserProviders"]>,
		/** fetch aggregated fields from the table: "auth.user_providers" */
		authUserProvidersAggregate: ModelTypes["authUserProviders_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.user_providers" */
		authUserProviders_stream: Array<ModelTypes["authUserProviders"]>,
		/** fetch data from the table: "auth.user_roles" using primary key columns */
		authUserRole?: ModelTypes["authUserRoles"] | undefined,
		/** fetch data from the table: "auth.user_roles" */
		authUserRoles: Array<ModelTypes["authUserRoles"]>,
		/** fetch aggregated fields from the table: "auth.user_roles" */
		authUserRolesAggregate: ModelTypes["authUserRoles_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.user_roles" */
		authUserRoles_stream: Array<ModelTypes["authUserRoles"]>,
		/** fetch data from the table: "auth.user_security_keys" using primary key columns */
		authUserSecurityKey?: ModelTypes["authUserSecurityKeys"] | undefined,
		/** fetch data from the table: "auth.user_security_keys" */
		authUserSecurityKeys: Array<ModelTypes["authUserSecurityKeys"]>,
		/** fetch aggregated fields from the table: "auth.user_security_keys" */
		authUserSecurityKeysAggregate: ModelTypes["authUserSecurityKeys_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.user_security_keys" */
		authUserSecurityKeys_stream: Array<ModelTypes["authUserSecurityKeys"]>,
		/** fetch data from the table: "blog" */
		blog: Array<ModelTypes["blog"]>,
		/** fetch data from the table: "blogCategory" */
		blogCategory: Array<ModelTypes["blogCategory"]>,
		/** fetch aggregated fields from the table: "blogCategory" */
		blogCategory_aggregate: ModelTypes["blogCategory_aggregate"],
		/** fetch data from the table: "blogCategory" using primary key columns */
		blogCategory_by_pk?: ModelTypes["blogCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "blogCategory" */
		blogCategory_stream: Array<ModelTypes["blogCategory"]>,
		blogPostGeneratorEnhancedForAmp?: ModelTypes["blogPostGeneratorEnhancedForAmp"] | undefined,
		/** fetch aggregated fields from the table: "blog" */
		blog_aggregate: ModelTypes["blog_aggregate"],
		/** fetch data from the table: "blog" using primary key columns */
		blog_by_pk?: ModelTypes["blog"] | undefined,
		/** fetch data from the table in a streaming manner: "blog" */
		blog_stream: Array<ModelTypes["blog"]>,
		/** fetch data from the table: "brands" */
		brands: Array<ModelTypes["brands"]>,
		/** fetch aggregated fields from the table: "brands" */
		brands_aggregate: ModelTypes["brands_aggregate"],
		/** fetch data from the table: "brands" using primary key columns */
		brands_by_pk?: ModelTypes["brands"] | undefined,
		/** fetch data from the table in a streaming manner: "brands" */
		brands_stream: Array<ModelTypes["brands"]>,
		/** fetch data from the table: "storage.buckets" using primary key columns */
		bucket?: ModelTypes["buckets"] | undefined,
		/** fetch data from the table: "storage.buckets" */
		buckets: Array<ModelTypes["buckets"]>,
		/** fetch aggregated fields from the table: "storage.buckets" */
		bucketsAggregate: ModelTypes["buckets_aggregate"],
		/** fetch data from the table in a streaming manner: "storage.buckets" */
		buckets_stream: Array<ModelTypes["buckets"]>,
		/** fetch data from the table: "campaign" */
		campaign: Array<ModelTypes["campaign"]>,
		/** fetch aggregated fields from the table: "campaign" */
		campaign_aggregate: ModelTypes["campaign_aggregate"],
		/** fetch data from the table: "campaign" using primary key columns */
		campaign_by_pk?: ModelTypes["campaign"] | undefined,
		/** fetch data from the table in a streaming manner: "campaign" */
		campaign_stream: Array<ModelTypes["campaign"]>,
		/** fetch data from the table: "categorysummaryview" */
		categorysummaryview: Array<ModelTypes["categorysummaryview"]>,
		/** fetch aggregated fields from the table: "categorysummaryview" */
		categorysummaryview_aggregate: ModelTypes["categorysummaryview_aggregate"],
		/** fetch data from the table in a streaming manner: "categorysummaryview" */
		categorysummaryview_stream: Array<ModelTypes["categorysummaryview"]>,
		/** fetch data from the table: "channels" */
		channels: Array<ModelTypes["channels"]>,
		/** fetch aggregated fields from the table: "channels" */
		channels_aggregate: ModelTypes["channels_aggregate"],
		/** fetch data from the table: "channels" using primary key columns */
		channels_by_pk?: ModelTypes["channels"] | undefined,
		/** fetch data from the table in a streaming manner: "channels" */
		channels_stream: Array<ModelTypes["channels"]>,
		/** fetch data from the table: "consumptionByChannelView" */
		consumptionByChannelView: Array<ModelTypes["consumptionByChannelView"]>,
		/** fetch aggregated fields from the table: "consumptionByChannelView" */
		consumptionByChannelView_aggregate: ModelTypes["consumptionByChannelView_aggregate"],
		/** fetch data from the table in a streaming manner: "consumptionByChannelView" */
		consumptionByChannelView_stream: Array<ModelTypes["consumptionByChannelView"]>,
		/** fetch data from the table: "costTable" */
		costTable: Array<ModelTypes["costTable"]>,
		/** fetch aggregated fields from the table: "costTable" */
		costTable_aggregate: ModelTypes["costTable_aggregate"],
		/** fetch data from the table: "costTable" using primary key columns */
		costTable_by_pk?: ModelTypes["costTable"] | undefined,
		/** fetch data from the table in a streaming manner: "costTable" */
		costTable_stream: Array<ModelTypes["costTable"]>,
		/** fetch data from the table: "deal" */
		deal: Array<ModelTypes["deal"]>,
		/** fetch data from the table: "dealStatus" */
		dealStatus: Array<ModelTypes["dealStatus"]>,
		/** fetch aggregated fields from the table: "dealStatus" */
		dealStatus_aggregate: ModelTypes["dealStatus_aggregate"],
		/** fetch data from the table: "dealStatus" using primary key columns */
		dealStatus_by_pk?: ModelTypes["dealStatus"] | undefined,
		/** fetch data from the table in a streaming manner: "dealStatus" */
		dealStatus_stream: Array<ModelTypes["dealStatus"]>,
		/** fetch aggregated fields from the table: "deal" */
		deal_aggregate: ModelTypes["deal_aggregate"],
		/** fetch data from the table: "deal" using primary key columns */
		deal_by_pk?: ModelTypes["deal"] | undefined,
		/** fetch data from the table in a streaming manner: "deal" */
		deal_stream: Array<ModelTypes["deal"]>,
		/** fetch data from the table: "displayCategory" */
		displayCategory: Array<ModelTypes["displayCategory"]>,
		/** fetch aggregated fields from the table: "displayCategory" */
		displayCategory_aggregate: ModelTypes["displayCategory_aggregate"],
		/** fetch data from the table: "displayCategory" using primary key columns */
		displayCategory_by_pk?: ModelTypes["displayCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "displayCategory" */
		displayCategory_stream: Array<ModelTypes["displayCategory"]>,
		/** fetch data from the table: "displayDimension" */
		displayDimension: Array<ModelTypes["displayDimension"]>,
		/** fetch aggregated fields from the table: "displayDimension" */
		displayDimension_aggregate: ModelTypes["displayDimension_aggregate"],
		/** fetch data from the table: "displayDimension" using primary key columns */
		displayDimension_by_pk?: ModelTypes["displayDimension"] | undefined,
		/** fetch data from the table in a streaming manner: "displayDimension" */
		displayDimension_stream: Array<ModelTypes["displayDimension"]>,
		/** fetch data from the table: "displayFormats" */
		displayFormats: Array<ModelTypes["displayFormats"]>,
		/** fetch aggregated fields from the table: "displayFormats" */
		displayFormats_aggregate: ModelTypes["displayFormats_aggregate"],
		/** fetch data from the table: "displayFormats" using primary key columns */
		displayFormats_by_pk?: ModelTypes["displayFormats"] | undefined,
		/** fetch data from the table in a streaming manner: "displayFormats" */
		displayFormats_stream: Array<ModelTypes["displayFormats"]>,
		/** fetch data from the table: "displayTemplate" */
		displayTemplate: Array<ModelTypes["displayTemplate"]>,
		/** fetch aggregated fields from the table: "displayTemplate" */
		displayTemplate_aggregate: ModelTypes["displayTemplate_aggregate"],
		/** fetch data from the table: "displayTemplate" using primary key columns */
		displayTemplate_by_pk?: ModelTypes["displayTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "displayTemplate" */
		displayTemplate_stream: Array<ModelTypes["displayTemplate"]>,
		/** fetch data from the table: "storage.files" using primary key columns */
		file?: ModelTypes["files"] | undefined,
		/** An array relationship */
		files: Array<ModelTypes["files"]>,
		/** fetch aggregated fields from the table: "storage.files" */
		filesAggregate: ModelTypes["files_aggregate"],
		/** fetch data from the table in a streaming manner: "storage.files" */
		files_stream: Array<ModelTypes["files"]>,
		generateNextMonthCalendar?: ModelTypes["generateNextMonthCalendar"] | undefined,
		/** fetch data from the table: "interaction" */
		interaction: Array<ModelTypes["interaction"]>,
		/** fetch aggregated fields from the table: "interaction" */
		interaction_aggregate: ModelTypes["interaction_aggregate"],
		/** fetch data from the table: "interaction" using primary key columns */
		interaction_by_pk?: ModelTypes["interaction"] | undefined,
		/** fetch data from the table in a streaming manner: "interaction" */
		interaction_stream: Array<ModelTypes["interaction"]>,
		/** fetch data from the table: "landingPageTemplate" */
		landingPageTemplate: Array<ModelTypes["landingPageTemplate"]>,
		/** fetch aggregated fields from the table: "landingPageTemplate" */
		landingPageTemplate_aggregate: ModelTypes["landingPageTemplate_aggregate"],
		/** fetch data from the table: "landingPageTemplate" using primary key columns */
		landingPageTemplate_by_pk?: ModelTypes["landingPageTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "landingPageTemplate" */
		landingPageTemplate_stream: Array<ModelTypes["landingPageTemplate"]>,
		/** fetch data from the table: "newTemplateType" */
		newTemplateType: Array<ModelTypes["newTemplateType"]>,
		/** fetch aggregated fields from the table: "newTemplateType" */
		newTemplateType_aggregate: ModelTypes["newTemplateType_aggregate"],
		/** fetch data from the table: "newTemplateType" using primary key columns */
		newTemplateType_by_pk?: ModelTypes["newTemplateType"] | undefined,
		/** fetch data from the table in a streaming manner: "newTemplateType" */
		newTemplateType_stream: Array<ModelTypes["newTemplateType"]>,
		/** fetch data from the table: "openAIApiKey" */
		openAIApiKey: Array<ModelTypes["openAIApiKey"]>,
		/** fetch aggregated fields from the table: "openAIApiKey" */
		openAIApiKey_aggregate: ModelTypes["openAIApiKey_aggregate"],
		/** fetch data from the table: "openAIApiKey" using primary key columns */
		openAIApiKey_by_pk?: ModelTypes["openAIApiKey"] | undefined,
		/** fetch data from the table in a streaming manner: "openAIApiKey" */
		openAIApiKey_stream: Array<ModelTypes["openAIApiKey"]>,
		/** fetch data from the table: "pTemplate" */
		pTemplate: Array<ModelTypes["pTemplate"]>,
		/** fetch aggregated fields from the table: "pTemplate" */
		pTemplate_aggregate: ModelTypes["pTemplate_aggregate"],
		/** fetch data from the table: "pTemplate" using primary key columns */
		pTemplate_by_pk?: ModelTypes["pTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "pTemplate" */
		pTemplate_stream: Array<ModelTypes["pTemplate"]>,
		/** fetch data from the table: "pricingPlan" */
		pricingPlan: Array<ModelTypes["pricingPlan"]>,
		/** fetch aggregated fields from the table: "pricingPlan" */
		pricingPlan_aggregate: ModelTypes["pricingPlan_aggregate"],
		/** fetch data from the table: "pricingPlan" using primary key columns */
		pricingPlan_by_pk?: ModelTypes["pricingPlan"] | undefined,
		/** fetch data from the table in a streaming manner: "pricingPlan" */
		pricingPlan_stream: Array<ModelTypes["pricingPlan"]>,
		/** An array relationship */
		products: Array<ModelTypes["products"]>,
		/** An aggregate relationship */
		products_aggregate: ModelTypes["products_aggregate"],
		/** fetch data from the table: "products" using primary key columns */
		products_by_pk?: ModelTypes["products"] | undefined,
		/** fetch data from the table in a streaming manner: "products" */
		products_stream: Array<ModelTypes["products"]>,
		/** fetch data from the table: "project" */
		project: Array<ModelTypes["project"]>,
		/** fetch data from the table: "projectImage" */
		projectImage: Array<ModelTypes["projectImage"]>,
		/** fetch aggregated fields from the table: "projectImage" */
		projectImage_aggregate: ModelTypes["projectImage_aggregate"],
		/** fetch data from the table: "projectImage" using primary key columns */
		projectImage_by_pk?: ModelTypes["projectImage"] | undefined,
		/** fetch data from the table in a streaming manner: "projectImage" */
		projectImage_stream: Array<ModelTypes["projectImage"]>,
		/** fetch aggregated fields from the table: "project" */
		project_aggregate: ModelTypes["project_aggregate"],
		/** fetch data from the table: "project" using primary key columns */
		project_by_pk?: ModelTypes["project"] | undefined,
		/** fetch data from the table in a streaming manner: "project" */
		project_stream: Array<ModelTypes["project"]>,
		/** fetch data from the table: "task" */
		task: Array<ModelTypes["task"]>,
		/** fetch aggregated fields from the table: "task" */
		task_aggregate: ModelTypes["task_aggregate"],
		/** fetch data from the table: "task" using primary key columns */
		task_by_pk?: ModelTypes["task"] | undefined,
		/** fetch data from the table in a streaming manner: "task" */
		task_stream: Array<ModelTypes["task"]>,
		/** fetch data from the table: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat: Array<ModelTypes["textTemplateDisplayFormat"]>,
		/** fetch aggregated fields from the table: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat_aggregate: ModelTypes["textTemplateDisplayFormat_aggregate"],
		/** fetch data from the table: "textTemplateDisplayFormat" using primary key columns */
		textTemplateDisplayFormat_by_pk?: ModelTypes["textTemplateDisplayFormat"] | undefined,
		/** fetch data from the table in a streaming manner: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat_stream: Array<ModelTypes["textTemplateDisplayFormat"]>,
		/** fetch data from the table: "auth.users" using primary key columns */
		user?: ModelTypes["users"] | undefined,
		/** fetch data from the table: "userAppIntegration" */
		userAppIntegration: Array<ModelTypes["userAppIntegration"]>,
		/** fetch aggregated fields from the table: "userAppIntegration" */
		userAppIntegration_aggregate: ModelTypes["userAppIntegration_aggregate"],
		/** fetch data from the table: "userAppIntegration" using primary key columns */
		userAppIntegration_by_pk?: ModelTypes["userAppIntegration"] | undefined,
		/** fetch data from the table in a streaming manner: "userAppIntegration" */
		userAppIntegration_stream: Array<ModelTypes["userAppIntegration"]>,
		/** fetch data from the table: "userCreditUsage" */
		userCreditUsage: Array<ModelTypes["userCreditUsage"]>,
		/** fetch data from the table: "userCreditUsageSummary" */
		userCreditUsageSummary: Array<ModelTypes["userCreditUsageSummary"]>,
		/** fetch aggregated fields from the table: "userCreditUsageSummary" */
		userCreditUsageSummary_aggregate: ModelTypes["userCreditUsageSummary_aggregate"],
		/** fetch data from the table: "userCreditUsageSummary" using primary key columns */
		userCreditUsageSummary_by_pk?: ModelTypes["userCreditUsageSummary"] | undefined,
		/** fetch data from the table in a streaming manner: "userCreditUsageSummary" */
		userCreditUsageSummary_stream: Array<ModelTypes["userCreditUsageSummary"]>,
		/** fetch aggregated fields from the table: "userCreditUsage" */
		userCreditUsage_aggregate: ModelTypes["userCreditUsage_aggregate"],
		/** fetch data from the table: "userCreditUsage" using primary key columns */
		userCreditUsage_by_pk?: ModelTypes["userCreditUsage"] | undefined,
		/** fetch data from the table in a streaming manner: "userCreditUsage" */
		userCreditUsage_stream: Array<ModelTypes["userCreditUsage"]>,
		/** fetch data from the table: "userCredits" */
		userCredits: Array<ModelTypes["userCredits"]>,
		/** fetch aggregated fields from the table: "userCredits" */
		userCredits_aggregate: ModelTypes["userCredits_aggregate"],
		/** fetch data from the table: "userCredits" using primary key columns */
		userCredits_by_pk?: ModelTypes["userCredits"] | undefined,
		/** fetch data from the table in a streaming manner: "userCredits" */
		userCredits_stream: Array<ModelTypes["userCredits"]>,
		/** fetch data from the table: "userFeedback" */
		userFeedback: Array<ModelTypes["userFeedback"]>,
		/** fetch aggregated fields from the table: "userFeedback" */
		userFeedback_aggregate: ModelTypes["userFeedback_aggregate"],
		/** fetch data from the table: "userFeedback" using primary key columns */
		userFeedback_by_pk?: ModelTypes["userFeedback"] | undefined,
		/** fetch data from the table in a streaming manner: "userFeedback" */
		userFeedback_stream: Array<ModelTypes["userFeedback"]>,
		/** fetch data from the table: "userMedia" */
		userMedia: Array<ModelTypes["userMedia"]>,
		/** fetch aggregated fields from the table: "userMedia" */
		userMedia_aggregate: ModelTypes["userMedia_aggregate"],
		/** fetch data from the table: "userMedia" using primary key columns */
		userMedia_by_pk?: ModelTypes["userMedia"] | undefined,
		/** fetch data from the table in a streaming manner: "userMedia" */
		userMedia_stream: Array<ModelTypes["userMedia"]>,
		/** An array relationship */
		userMetadata: Array<ModelTypes["userMetadata"]>,
		/** An aggregate relationship */
		userMetadata_aggregate: ModelTypes["userMetadata_aggregate"],
		/** fetch data from the table: "userMetadata" using primary key columns */
		userMetadata_by_pk?: ModelTypes["userMetadata"] | undefined,
		/** fetch data from the table in a streaming manner: "userMetadata" */
		userMetadata_stream: Array<ModelTypes["userMetadata"]>,
		/** fetch data from the table: "userOnboarding" */
		userOnboarding: Array<ModelTypes["userOnboarding"]>,
		/** fetch aggregated fields from the table: "userOnboarding" */
		userOnboarding_aggregate: ModelTypes["userOnboarding_aggregate"],
		/** fetch data from the table: "userOnboarding" using primary key columns */
		userOnboarding_by_pk?: ModelTypes["userOnboarding"] | undefined,
		/** fetch data from the table in a streaming manner: "userOnboarding" */
		userOnboarding_stream: Array<ModelTypes["userOnboarding"]>,
		/** fetch data from the table: "userTemplateUsage" */
		userTemplateUsage: Array<ModelTypes["userTemplateUsage"]>,
		/** fetch aggregated fields from the table: "userTemplateUsage" */
		userTemplateUsage_aggregate: ModelTypes["userTemplateUsage_aggregate"],
		/** fetch data from the table: "userTemplateUsage" using primary key columns */
		userTemplateUsage_by_pk?: ModelTypes["userTemplateUsage"] | undefined,
		/** fetch data from the table in a streaming manner: "userTemplateUsage" */
		userTemplateUsage_stream: Array<ModelTypes["userTemplateUsage"]>,
		/** fetch data from the table: "auth.users" */
		users: Array<ModelTypes["users"]>,
		/** fetch aggregated fields from the table: "auth.users" */
		usersAggregate: ModelTypes["users_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.users" */
		users_stream: Array<ModelTypes["users"]>,
		/** fetch data from the table: "storage.virus" using primary key columns */
		virus?: ModelTypes["virus"] | undefined,
		/** fetch data from the table in a streaming manner: "storage.virus" */
		virus_stream: Array<ModelTypes["virus"]>,
		/** fetch data from the table: "storage.virus" */
		viruses: Array<ModelTypes["virus"]>,
		/** fetch aggregated fields from the table: "storage.virus" */
		virusesAggregate: ModelTypes["virus_aggregate"],
		/** fetch data from the table: "website" */
		website: Array<ModelTypes["website"]>,
		/** fetch aggregated fields from the table: "website" */
		website_aggregate: ModelTypes["website_aggregate"],
		/** fetch data from the table: "website" using primary key columns */
		website_by_pk?: ModelTypes["website"] | undefined,
		/** fetch data from the table in a streaming manner: "website" */
		website_stream: Array<ModelTypes["website"]>
	};
	/** columns and relationships of "task" */
	["task"]: {
		category: ModelTypes["uuid"],
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		date: ModelTypes["timestamp"],
		/** An object relationship */
		deal: ModelTypes["deal"],
		dealId: ModelTypes["uuid"],
		description: string,
		finished: boolean,
		id: ModelTypes["uuid"],
		title: string,
		updated_at: ModelTypes["timestamptz"],
		/** An object relationship */
		user: ModelTypes["users"]
	};
	/** aggregated selection of "task" */
	["task_aggregate"]: {
		aggregate?: ModelTypes["task_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["task"]>
	};
	["task_aggregate_bool_exp"]: {
		bool_and?: ModelTypes["task_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: ModelTypes["task_aggregate_bool_exp_bool_or"] | undefined,
		count?: ModelTypes["task_aggregate_bool_exp_count"] | undefined
	};
	["task_aggregate_bool_exp_bool_and"]: {
		arguments: ModelTypes["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["task_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["task_aggregate_bool_exp_bool_or"]: {
		arguments: ModelTypes["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["task_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["task_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["task_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["task_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "task" */
	["task_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["task_max_fields"] | undefined,
		min?: ModelTypes["task_min_fields"] | undefined
	};
	/** order by aggregate values of table "task" */
	["task_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["task_max_order_by"] | undefined,
		min?: ModelTypes["task_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "task" */
	["task_arr_rel_insert_input"]: {
		data: Array<ModelTypes["task_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["task_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
	["task_bool_exp"]: {
		_and?: Array<ModelTypes["task_bool_exp"]> | undefined,
		_not?: ModelTypes["task_bool_exp"] | undefined,
		_or?: Array<ModelTypes["task_bool_exp"]> | undefined,
		category?: ModelTypes["uuid_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		date?: ModelTypes["timestamp_comparison_exp"] | undefined,
		deal?: ModelTypes["deal_bool_exp"] | undefined,
		dealId?: ModelTypes["uuid_comparison_exp"] | undefined,
		description?: ModelTypes["String_comparison_exp"] | undefined,
		finished?: ModelTypes["Boolean_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		title?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		user?: ModelTypes["users_bool_exp"] | undefined
	};
	["task_constraint"]: task_constraint;
	/** input type for inserting data into table "task" */
	["task_insert_input"]: {
		category?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamp"] | undefined,
		deal?: ModelTypes["deal_obj_rel_insert_input"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		description?: string | undefined,
		finished?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		user?: ModelTypes["users_obj_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["task_max_fields"]: {
		category?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamp"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "task" */
	["task_max_order_by"]: {
		category?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		date?: ModelTypes["order_by"] | undefined,
		dealId?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		title?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["task_min_fields"]: {
		category?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamp"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		description?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "task" */
	["task_min_order_by"]: {
		category?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		date?: ModelTypes["order_by"] | undefined,
		dealId?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		title?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "task" */
	["task_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["task"]>
	};
	/** on_conflict condition type for table "task" */
	["task_on_conflict"]: {
		constraint: ModelTypes["task_constraint"],
		update_columns: Array<ModelTypes["task_update_column"]>,
		where?: ModelTypes["task_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "task". */
	["task_order_by"]: {
		category?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		date?: ModelTypes["order_by"] | undefined,
		deal?: ModelTypes["deal_order_by"] | undefined,
		dealId?: ModelTypes["order_by"] | undefined,
		description?: ModelTypes["order_by"] | undefined,
		finished?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		title?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		user?: ModelTypes["users_order_by"] | undefined
	};
	/** primary key columns input for table: task */
	["task_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["task_select_column"]: task_select_column;
	["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"]: task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns;
	["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"]: task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "task" */
	["task_set_input"]: {
		category?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamp"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		description?: string | undefined,
		finished?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "task" */
	["task_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["task_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["task_stream_cursor_value_input"]: {
		category?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		date?: ModelTypes["timestamp"] | undefined,
		dealId?: ModelTypes["uuid"] | undefined,
		description?: string | undefined,
		finished?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["task_update_column"]: task_update_column;
	["task_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["task_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["task_bool_exp"]
	};
	/** columns and relationships of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat"]: {
		/** An object relationship */
		displayFormat: ModelTypes["displayFormats"],
		displayFormatId: ModelTypes["uuid"],
		id: ModelTypes["uuid"],
		textTemplateId: ModelTypes["uuid"]
	};
	/** aggregated selection of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate"]: {
		aggregate?: ModelTypes["textTemplateDisplayFormat_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["textTemplateDisplayFormat"]>
	};
	["textTemplateDisplayFormat_aggregate_bool_exp"]: {
		count?: ModelTypes["textTemplateDisplayFormat_aggregate_bool_exp_count"] | undefined
	};
	["textTemplateDisplayFormat_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["textTemplateDisplayFormat_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["textTemplateDisplayFormat_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["textTemplateDisplayFormat_max_fields"] | undefined,
		min?: ModelTypes["textTemplateDisplayFormat_min_fields"] | undefined
	};
	/** order by aggregate values of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["textTemplateDisplayFormat_max_order_by"] | undefined,
		min?: ModelTypes["textTemplateDisplayFormat_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_arr_rel_insert_input"]: {
		data: Array<ModelTypes["textTemplateDisplayFormat_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["textTemplateDisplayFormat_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "textTemplateDisplayFormat". All fields are combined with a logical 'AND'. */
	["textTemplateDisplayFormat_bool_exp"]: {
		_and?: Array<ModelTypes["textTemplateDisplayFormat_bool_exp"]> | undefined,
		_not?: ModelTypes["textTemplateDisplayFormat_bool_exp"] | undefined,
		_or?: Array<ModelTypes["textTemplateDisplayFormat_bool_exp"]> | undefined,
		displayFormat?: ModelTypes["displayFormats_bool_exp"] | undefined,
		displayFormatId?: ModelTypes["uuid_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		textTemplateId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["textTemplateDisplayFormat_constraint"]: textTemplateDisplayFormat_constraint;
	/** input type for inserting data into table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_insert_input"]: {
		displayFormat?: ModelTypes["displayFormats_obj_rel_insert_input"] | undefined,
		displayFormatId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textTemplateId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["textTemplateDisplayFormat_max_fields"]: {
		displayFormatId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textTemplateId?: ModelTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_max_order_by"]: {
		displayFormatId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		textTemplateId?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["textTemplateDisplayFormat_min_fields"]: {
		displayFormatId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textTemplateId?: ModelTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_min_order_by"]: {
		displayFormatId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		textTemplateId?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["textTemplateDisplayFormat"]>
	};
	/** on_conflict condition type for table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_on_conflict"]: {
		constraint: ModelTypes["textTemplateDisplayFormat_constraint"],
		update_columns: Array<ModelTypes["textTemplateDisplayFormat_update_column"]>,
		where?: ModelTypes["textTemplateDisplayFormat_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "textTemplateDisplayFormat". */
	["textTemplateDisplayFormat_order_by"]: {
		displayFormat?: ModelTypes["displayFormats_order_by"] | undefined,
		displayFormatId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		textTemplateId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: textTemplateDisplayFormat */
	["textTemplateDisplayFormat_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["textTemplateDisplayFormat_select_column"]: textTemplateDisplayFormat_select_column;
	/** input type for updating data in table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_set_input"]: {
		displayFormatId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textTemplateId?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["textTemplateDisplayFormat_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["textTemplateDisplayFormat_stream_cursor_value_input"]: {
		displayFormatId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textTemplateId?: ModelTypes["uuid"] | undefined
	};
	["textTemplateDisplayFormat_update_column"]: textTemplateDisplayFormat_update_column;
	["textTemplateDisplayFormat_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["textTemplateDisplayFormat_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["textTemplateDisplayFormat_bool_exp"]
	};
	["timestamp"]: any;
	/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
	["timestamp_comparison_exp"]: {
		_eq?: ModelTypes["timestamp"] | undefined,
		_gt?: ModelTypes["timestamp"] | undefined,
		_gte?: ModelTypes["timestamp"] | undefined,
		_in?: Array<ModelTypes["timestamp"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: ModelTypes["timestamp"] | undefined,
		_lte?: ModelTypes["timestamp"] | undefined,
		_neq?: ModelTypes["timestamp"] | undefined,
		_nin?: Array<ModelTypes["timestamp"]> | undefined
	};
	["timestamptz"]: any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
	["timestamptz_comparison_exp"]: {
		_eq?: ModelTypes["timestamptz"] | undefined,
		_gt?: ModelTypes["timestamptz"] | undefined,
		_gte?: ModelTypes["timestamptz"] | undefined,
		_in?: Array<ModelTypes["timestamptz"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: ModelTypes["timestamptz"] | undefined,
		_lte?: ModelTypes["timestamptz"] | undefined,
		_neq?: ModelTypes["timestamptz"] | undefined,
		_nin?: Array<ModelTypes["timestamptz"]> | undefined
	};
	["upsertBrandOutput"]: {
		archtypeAdherence?: string | undefined,
		brandName?: string | undefined,
		colors?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: string | undefined,
		isBrand?: boolean | undefined,
		logoFileId?: string | undefined,
		personality?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		slogan?: string | undefined,
		voiceTone?: string | undefined
	};
	/** columns and relationships of "userAppIntegration" */
	["userAppIntegration"]: {
		alias?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by: ModelTypes["uuid"],
		deleted: boolean,
		id: ModelTypes["uuid"],
		integrationType: string,
		params: ModelTypes["jsonb"],
		updated_at: ModelTypes["timestamptz"]
	};
	/** aggregated selection of "userAppIntegration" */
	["userAppIntegration_aggregate"]: {
		aggregate?: ModelTypes["userAppIntegration_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userAppIntegration"]>
	};
	/** aggregate fields of "userAppIntegration" */
	["userAppIntegration_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["userAppIntegration_max_fields"] | undefined,
		min?: ModelTypes["userAppIntegration_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["userAppIntegration_append_input"]: {
		params?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "userAppIntegration". All fields are combined with a logical 'AND'. */
	["userAppIntegration_bool_exp"]: {
		_and?: Array<ModelTypes["userAppIntegration_bool_exp"]> | undefined,
		_not?: ModelTypes["userAppIntegration_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userAppIntegration_bool_exp"]> | undefined,
		alias?: ModelTypes["String_comparison_exp"] | undefined,
		brandId?: ModelTypes["uuid_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		deleted?: ModelTypes["Boolean_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		integrationType?: ModelTypes["String_comparison_exp"] | undefined,
		params?: ModelTypes["jsonb_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["userAppIntegration_constraint"]: userAppIntegration_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["userAppIntegration_delete_at_path_input"]: {
		params?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["userAppIntegration_delete_elem_input"]: {
		params?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["userAppIntegration_delete_key_input"]: {
		params?: string | undefined
	};
	/** input type for inserting data into table "userAppIntegration" */
	["userAppIntegration_insert_input"]: {
		alias?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		params?: ModelTypes["jsonb"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["userAppIntegration_max_fields"]: {
		alias?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["userAppIntegration_min_fields"]: {
		alias?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "userAppIntegration" */
	["userAppIntegration_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userAppIntegration"]>
	};
	/** on_conflict condition type for table "userAppIntegration" */
	["userAppIntegration_on_conflict"]: {
		constraint: ModelTypes["userAppIntegration_constraint"],
		update_columns: Array<ModelTypes["userAppIntegration_update_column"]>,
		where?: ModelTypes["userAppIntegration_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userAppIntegration". */
	["userAppIntegration_order_by"]: {
		alias?: ModelTypes["order_by"] | undefined,
		brandId?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		deleted?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		integrationType?: ModelTypes["order_by"] | undefined,
		params?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userAppIntegration */
	["userAppIntegration_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["userAppIntegration_prepend_input"]: {
		params?: ModelTypes["jsonb"] | undefined
	};
	["userAppIntegration_select_column"]: userAppIntegration_select_column;
	/** input type for updating data in table "userAppIntegration" */
	["userAppIntegration_set_input"]: {
		alias?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		params?: ModelTypes["jsonb"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "userAppIntegration" */
	["userAppIntegration_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userAppIntegration_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userAppIntegration_stream_cursor_value_input"]: {
		alias?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		params?: ModelTypes["jsonb"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["userAppIntegration_update_column"]: userAppIntegration_update_column;
	["userAppIntegration_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["userAppIntegration_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["userAppIntegration_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["userAppIntegration_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["userAppIntegration_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["userAppIntegration_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userAppIntegration_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userAppIntegration_bool_exp"]
	};
	/** columns and relationships of "userCreditUsage" */
	["userCreditUsage"]: {
		GeneratedPromptByTemplateId: ModelTypes["uuid"],
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		freeCreditUsed?: ModelTypes["numeric"] | undefined,
		id: ModelTypes["uuid"],
		originalValue: ModelTypes["numeric"],
		totalCharged: ModelTypes["numeric"]
	};
	/** columns and relationships of "userCreditUsageSummary" */
	["userCreditUsageSummary"]: {
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		freeCreditUsage: ModelTypes["numeric"],
		id: ModelTypes["uuid"],
		originalVaue: ModelTypes["numeric"],
		totalCharged: ModelTypes["numeric"],
		updated_at: ModelTypes["timestamptz"],
		yearMonthDayReference: string
	};
	/** aggregated selection of "userCreditUsageSummary" */
	["userCreditUsageSummary_aggregate"]: {
		aggregate?: ModelTypes["userCreditUsageSummary_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userCreditUsageSummary"]>
	};
	/** aggregate fields of "userCreditUsageSummary" */
	["userCreditUsageSummary_aggregate_fields"]: {
		avg?: ModelTypes["userCreditUsageSummary_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["userCreditUsageSummary_max_fields"] | undefined,
		min?: ModelTypes["userCreditUsageSummary_min_fields"] | undefined,
		stddev?: ModelTypes["userCreditUsageSummary_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["userCreditUsageSummary_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["userCreditUsageSummary_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["userCreditUsageSummary_sum_fields"] | undefined,
		var_pop?: ModelTypes["userCreditUsageSummary_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["userCreditUsageSummary_var_samp_fields"] | undefined,
		variance?: ModelTypes["userCreditUsageSummary_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["userCreditUsageSummary_avg_fields"]: {
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** Boolean expression to filter rows from the table "userCreditUsageSummary". All fields are combined with a logical 'AND'. */
	["userCreditUsageSummary_bool_exp"]: {
		_and?: Array<ModelTypes["userCreditUsageSummary_bool_exp"]> | undefined,
		_not?: ModelTypes["userCreditUsageSummary_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userCreditUsageSummary_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		freeCreditUsage?: ModelTypes["numeric_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		originalVaue?: ModelTypes["numeric_comparison_exp"] | undefined,
		totalCharged?: ModelTypes["numeric_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		yearMonthDayReference?: ModelTypes["String_comparison_exp"] | undefined
	};
	["userCreditUsageSummary_constraint"]: userCreditUsageSummary_constraint;
	/** input type for incrementing numeric columns in table "userCreditUsageSummary" */
	["userCreditUsageSummary_inc_input"]: {
		freeCreditUsage?: ModelTypes["numeric"] | undefined,
		originalVaue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "userCreditUsageSummary" */
	["userCreditUsageSummary_insert_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsage?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalVaue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** aggregate max on columns */
	["userCreditUsageSummary_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsage?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalVaue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** aggregate min on columns */
	["userCreditUsageSummary_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsage?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalVaue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** response of any mutation on the table "userCreditUsageSummary" */
	["userCreditUsageSummary_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userCreditUsageSummary"]>
	};
	/** on_conflict condition type for table "userCreditUsageSummary" */
	["userCreditUsageSummary_on_conflict"]: {
		constraint: ModelTypes["userCreditUsageSummary_constraint"],
		update_columns: Array<ModelTypes["userCreditUsageSummary_update_column"]>,
		where?: ModelTypes["userCreditUsageSummary_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userCreditUsageSummary". */
	["userCreditUsageSummary_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		freeCreditUsage?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		originalVaue?: ModelTypes["order_by"] | undefined,
		totalCharged?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		yearMonthDayReference?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userCreditUsageSummary */
	["userCreditUsageSummary_pk_columns_input"]: {
		created_by: ModelTypes["uuid"],
		yearMonthDayReference: string
	};
	["userCreditUsageSummary_select_column"]: userCreditUsageSummary_select_column;
	/** input type for updating data in table "userCreditUsageSummary" */
	["userCreditUsageSummary_set_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsage?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalVaue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** aggregate stddev on columns */
	["userCreditUsageSummary_stddev_fields"]: {
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["userCreditUsageSummary_stddev_pop_fields"]: {
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["userCreditUsageSummary_stddev_samp_fields"]: {
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** Streaming cursor of the table "userCreditUsageSummary" */
	["userCreditUsageSummary_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userCreditUsageSummary_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userCreditUsageSummary_stream_cursor_value_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsage?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalVaue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** aggregate sum on columns */
	["userCreditUsageSummary_sum_fields"]: {
		freeCreditUsage?: ModelTypes["numeric"] | undefined,
		originalVaue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	["userCreditUsageSummary_update_column"]: userCreditUsageSummary_update_column;
	["userCreditUsageSummary_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["userCreditUsageSummary_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userCreditUsageSummary_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userCreditUsageSummary_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCreditUsageSummary_var_pop_fields"]: {
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate var_samp on columns */
	["userCreditUsageSummary_var_samp_fields"]: {
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate variance on columns */
	["userCreditUsageSummary_variance_fields"]: {
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregated selection of "userCreditUsage" */
	["userCreditUsage_aggregate"]: {
		aggregate?: ModelTypes["userCreditUsage_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userCreditUsage"]>
	};
	/** aggregate fields of "userCreditUsage" */
	["userCreditUsage_aggregate_fields"]: {
		avg?: ModelTypes["userCreditUsage_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["userCreditUsage_max_fields"] | undefined,
		min?: ModelTypes["userCreditUsage_min_fields"] | undefined,
		stddev?: ModelTypes["userCreditUsage_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["userCreditUsage_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["userCreditUsage_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["userCreditUsage_sum_fields"] | undefined,
		var_pop?: ModelTypes["userCreditUsage_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["userCreditUsage_var_samp_fields"] | undefined,
		variance?: ModelTypes["userCreditUsage_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["userCreditUsage_avg_fields"]: {
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** Boolean expression to filter rows from the table "userCreditUsage". All fields are combined with a logical 'AND'. */
	["userCreditUsage_bool_exp"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid_comparison_exp"] | undefined,
		_and?: Array<ModelTypes["userCreditUsage_bool_exp"]> | undefined,
		_not?: ModelTypes["userCreditUsage_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userCreditUsage_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		freeCreditUsed?: ModelTypes["numeric_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		originalValue?: ModelTypes["numeric_comparison_exp"] | undefined,
		totalCharged?: ModelTypes["numeric_comparison_exp"] | undefined
	};
	["userCreditUsage_constraint"]: userCreditUsage_constraint;
	/** input type for incrementing numeric columns in table "userCreditUsage" */
	["userCreditUsage_inc_input"]: {
		freeCreditUsed?: ModelTypes["numeric"] | undefined,
		originalValue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "userCreditUsage" */
	["userCreditUsage_insert_input"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsed?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalValue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	/** aggregate max on columns */
	["userCreditUsage_max_fields"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsed?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalValue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	/** aggregate min on columns */
	["userCreditUsage_min_fields"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsed?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalValue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	/** response of any mutation on the table "userCreditUsage" */
	["userCreditUsage_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userCreditUsage"]>
	};
	/** on_conflict condition type for table "userCreditUsage" */
	["userCreditUsage_on_conflict"]: {
		constraint: ModelTypes["userCreditUsage_constraint"],
		update_columns: Array<ModelTypes["userCreditUsage_update_column"]>,
		where?: ModelTypes["userCreditUsage_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userCreditUsage". */
	["userCreditUsage_order_by"]: {
		GeneratedPromptByTemplateId?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		freeCreditUsed?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		originalValue?: ModelTypes["order_by"] | undefined,
		totalCharged?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userCreditUsage */
	["userCreditUsage_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["userCreditUsage_select_column"]: userCreditUsage_select_column;
	/** input type for updating data in table "userCreditUsage" */
	["userCreditUsage_set_input"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsed?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalValue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	/** aggregate stddev on columns */
	["userCreditUsage_stddev_fields"]: {
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["userCreditUsage_stddev_pop_fields"]: {
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["userCreditUsage_stddev_samp_fields"]: {
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** Streaming cursor of the table "userCreditUsage" */
	["userCreditUsage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userCreditUsage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userCreditUsage_stream_cursor_value_input"]: {
		GeneratedPromptByTemplateId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		freeCreditUsed?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		originalValue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	/** aggregate sum on columns */
	["userCreditUsage_sum_fields"]: {
		freeCreditUsed?: ModelTypes["numeric"] | undefined,
		originalValue?: ModelTypes["numeric"] | undefined,
		totalCharged?: ModelTypes["numeric"] | undefined
	};
	["userCreditUsage_update_column"]: userCreditUsage_update_column;
	["userCreditUsage_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["userCreditUsage_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userCreditUsage_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userCreditUsage_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCreditUsage_var_pop_fields"]: {
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate var_samp on columns */
	["userCreditUsage_var_samp_fields"]: {
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate variance on columns */
	["userCreditUsage_variance_fields"]: {
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** columns and relationships of "userCredits" */
	["userCredits"]: {
		created_at: ModelTypes["timestamptz"],
		id: ModelTypes["uuid"],
		quantity: ModelTypes["numeric"],
		reason: string,
		userId: ModelTypes["uuid"]
	};
	/** aggregated selection of "userCredits" */
	["userCredits_aggregate"]: {
		aggregate?: ModelTypes["userCredits_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userCredits"]>
	};
	/** aggregate fields of "userCredits" */
	["userCredits_aggregate_fields"]: {
		avg?: ModelTypes["userCredits_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["userCredits_max_fields"] | undefined,
		min?: ModelTypes["userCredits_min_fields"] | undefined,
		stddev?: ModelTypes["userCredits_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["userCredits_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["userCredits_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["userCredits_sum_fields"] | undefined,
		var_pop?: ModelTypes["userCredits_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["userCredits_var_samp_fields"] | undefined,
		variance?: ModelTypes["userCredits_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["userCredits_avg_fields"]: {
		quantity?: number | undefined
	};
	/** Boolean expression to filter rows from the table "userCredits". All fields are combined with a logical 'AND'. */
	["userCredits_bool_exp"]: {
		_and?: Array<ModelTypes["userCredits_bool_exp"]> | undefined,
		_not?: ModelTypes["userCredits_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userCredits_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		quantity?: ModelTypes["numeric_comparison_exp"] | undefined,
		reason?: ModelTypes["String_comparison_exp"] | undefined,
		userId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["userCredits_constraint"]: userCredits_constraint;
	/** input type for incrementing numeric columns in table "userCredits" */
	["userCredits_inc_input"]: {
		quantity?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "userCredits" */
	["userCredits_insert_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		quantity?: ModelTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["userCredits_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		quantity?: ModelTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["userCredits_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		quantity?: ModelTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "userCredits" */
	["userCredits_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userCredits"]>
	};
	/** on_conflict condition type for table "userCredits" */
	["userCredits_on_conflict"]: {
		constraint: ModelTypes["userCredits_constraint"],
		update_columns: Array<ModelTypes["userCredits_update_column"]>,
		where?: ModelTypes["userCredits_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userCredits". */
	["userCredits_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		quantity?: ModelTypes["order_by"] | undefined,
		reason?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userCredits */
	["userCredits_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["userCredits_select_column"]: userCredits_select_column;
	/** input type for updating data in table "userCredits" */
	["userCredits_set_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		quantity?: ModelTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate stddev on columns */
	["userCredits_stddev_fields"]: {
		quantity?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["userCredits_stddev_pop_fields"]: {
		quantity?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["userCredits_stddev_samp_fields"]: {
		quantity?: number | undefined
	};
	/** Streaming cursor of the table "userCredits" */
	["userCredits_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userCredits_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userCredits_stream_cursor_value_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		quantity?: ModelTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["userCredits_sum_fields"]: {
		quantity?: ModelTypes["numeric"] | undefined
	};
	["userCredits_update_column"]: userCredits_update_column;
	["userCredits_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["userCredits_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userCredits_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userCredits_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCredits_var_pop_fields"]: {
		quantity?: number | undefined
	};
	/** aggregate var_samp on columns */
	["userCredits_var_samp_fields"]: {
		quantity?: number | undefined
	};
	/** aggregate variance on columns */
	["userCredits_variance_fields"]: {
		quantity?: number | undefined
	};
	/** columns and relationships of "userFeedback" */
	["userFeedback"]: {
		createdBy: ModelTypes["uuid"],
		created_at: ModelTypes["timestamptz"],
		feedbackType: string,
		id: ModelTypes["uuid"],
		message: string,
		replied_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregated selection of "userFeedback" */
	["userFeedback_aggregate"]: {
		aggregate?: ModelTypes["userFeedback_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userFeedback"]>
	};
	/** aggregate fields of "userFeedback" */
	["userFeedback_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["userFeedback_max_fields"] | undefined,
		min?: ModelTypes["userFeedback_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "userFeedback". All fields are combined with a logical 'AND'. */
	["userFeedback_bool_exp"]: {
		_and?: Array<ModelTypes["userFeedback_bool_exp"]> | undefined,
		_not?: ModelTypes["userFeedback_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userFeedback_bool_exp"]> | undefined,
		createdBy?: ModelTypes["uuid_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		feedbackType?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		message?: ModelTypes["String_comparison_exp"] | undefined,
		replied_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["userFeedback_constraint"]: userFeedback_constraint;
	/** input type for inserting data into table "userFeedback" */
	["userFeedback_insert_input"]: {
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["userFeedback_max_fields"]: {
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["userFeedback_min_fields"]: {
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "userFeedback" */
	["userFeedback_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userFeedback"]>
	};
	/** on_conflict condition type for table "userFeedback" */
	["userFeedback_on_conflict"]: {
		constraint: ModelTypes["userFeedback_constraint"],
		update_columns: Array<ModelTypes["userFeedback_update_column"]>,
		where?: ModelTypes["userFeedback_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userFeedback". */
	["userFeedback_order_by"]: {
		createdBy?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		feedbackType?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		message?: ModelTypes["order_by"] | undefined,
		replied_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userFeedback */
	["userFeedback_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["userFeedback_select_column"]: userFeedback_select_column;
	/** input type for updating data in table "userFeedback" */
	["userFeedback_set_input"]: {
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "userFeedback" */
	["userFeedback_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userFeedback_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userFeedback_stream_cursor_value_input"]: {
		createdBy?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: ModelTypes["timestamptz"] | undefined
	};
	["userFeedback_update_column"]: userFeedback_update_column;
	["userFeedback_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userFeedback_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userFeedback_bool_exp"]
	};
	/** columns and relationships of "userMedia" */
	["userMedia"]: {
		aiParameters?: ModelTypes["jsonb"] | undefined,
		created_at: ModelTypes["timestamptz"],
		deleted: boolean,
		fileId: ModelTypes["uuid"],
		id: ModelTypes["uuid"],
		mediaType: string,
		ownerId: ModelTypes["uuid"],
		updated_at: ModelTypes["timestamptz"],
		videoFileId?: ModelTypes["uuid"] | undefined
	};
	/** aggregated selection of "userMedia" */
	["userMedia_aggregate"]: {
		aggregate?: ModelTypes["userMedia_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userMedia"]>
	};
	/** aggregate fields of "userMedia" */
	["userMedia_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["userMedia_max_fields"] | undefined,
		min?: ModelTypes["userMedia_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["userMedia_append_input"]: {
		aiParameters?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "userMedia". All fields are combined with a logical 'AND'. */
	["userMedia_bool_exp"]: {
		_and?: Array<ModelTypes["userMedia_bool_exp"]> | undefined,
		_not?: ModelTypes["userMedia_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userMedia_bool_exp"]> | undefined,
		aiParameters?: ModelTypes["jsonb_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		deleted?: ModelTypes["Boolean_comparison_exp"] | undefined,
		fileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		mediaType?: ModelTypes["String_comparison_exp"] | undefined,
		ownerId?: ModelTypes["uuid_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		videoFileId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["userMedia_constraint"]: userMedia_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["userMedia_delete_at_path_input"]: {
		aiParameters?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["userMedia_delete_elem_input"]: {
		aiParameters?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["userMedia_delete_key_input"]: {
		aiParameters?: string | undefined
	};
	/** input type for inserting data into table "userMedia" */
	["userMedia_insert_input"]: {
		aiParameters?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		deleted?: boolean | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		videoFileId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["userMedia_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		videoFileId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["userMedia_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		videoFileId?: ModelTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "userMedia" */
	["userMedia_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userMedia"]>
	};
	/** on_conflict condition type for table "userMedia" */
	["userMedia_on_conflict"]: {
		constraint: ModelTypes["userMedia_constraint"],
		update_columns: Array<ModelTypes["userMedia_update_column"]>,
		where?: ModelTypes["userMedia_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userMedia". */
	["userMedia_order_by"]: {
		aiParameters?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		deleted?: ModelTypes["order_by"] | undefined,
		fileId?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		mediaType?: ModelTypes["order_by"] | undefined,
		ownerId?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		videoFileId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userMedia */
	["userMedia_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["userMedia_prepend_input"]: {
		aiParameters?: ModelTypes["jsonb"] | undefined
	};
	["userMedia_select_column"]: userMedia_select_column;
	/** input type for updating data in table "userMedia" */
	["userMedia_set_input"]: {
		aiParameters?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		deleted?: boolean | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		videoFileId?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "userMedia" */
	["userMedia_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userMedia_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userMedia_stream_cursor_value_input"]: {
		aiParameters?: ModelTypes["jsonb"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		deleted?: boolean | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: ModelTypes["uuid"] | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		videoFileId?: ModelTypes["uuid"] | undefined
	};
	["userMedia_update_column"]: userMedia_update_column;
	["userMedia_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["userMedia_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["userMedia_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["userMedia_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["userMedia_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["userMedia_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userMedia_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userMedia_bool_exp"]
	};
	/** columns and relationships of "userMetadata" */
	["userMetadata"]: {
		created_at: ModelTypes["timestamptz"],
		creditBalance?: ModelTypes["numeric"] | undefined,
		id: ModelTypes["uuid"],
		lastCreditRefill?: ModelTypes["date"] | undefined,
		last_seen: ModelTypes["timestamp"],
		onboardCompleted: boolean,
		/** An object relationship */
		pricingPlan?: ModelTypes["pricingPlan"] | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at: ModelTypes["timestamptz"],
		/** An object relationship */
		user: ModelTypes["users"]
	};
	/** aggregated selection of "userMetadata" */
	["userMetadata_aggregate"]: {
		aggregate?: ModelTypes["userMetadata_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userMetadata"]>
	};
	["userMetadata_aggregate_bool_exp"]: {
		bool_and?: ModelTypes["userMetadata_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: ModelTypes["userMetadata_aggregate_bool_exp_bool_or"] | undefined,
		count?: ModelTypes["userMetadata_aggregate_bool_exp_count"] | undefined
	};
	["userMetadata_aggregate_bool_exp_bool_and"]: {
		arguments: ModelTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["userMetadata_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["userMetadata_aggregate_bool_exp_bool_or"]: {
		arguments: ModelTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["userMetadata_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["userMetadata_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["userMetadata_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["userMetadata_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "userMetadata" */
	["userMetadata_aggregate_fields"]: {
		avg?: ModelTypes["userMetadata_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["userMetadata_max_fields"] | undefined,
		min?: ModelTypes["userMetadata_min_fields"] | undefined,
		stddev?: ModelTypes["userMetadata_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["userMetadata_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["userMetadata_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["userMetadata_sum_fields"] | undefined,
		var_pop?: ModelTypes["userMetadata_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["userMetadata_var_samp_fields"] | undefined,
		variance?: ModelTypes["userMetadata_variance_fields"] | undefined
	};
	/** order by aggregate values of table "userMetadata" */
	["userMetadata_aggregate_order_by"]: {
		avg?: ModelTypes["userMetadata_avg_order_by"] | undefined,
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["userMetadata_max_order_by"] | undefined,
		min?: ModelTypes["userMetadata_min_order_by"] | undefined,
		stddev?: ModelTypes["userMetadata_stddev_order_by"] | undefined,
		stddev_pop?: ModelTypes["userMetadata_stddev_pop_order_by"] | undefined,
		stddev_samp?: ModelTypes["userMetadata_stddev_samp_order_by"] | undefined,
		sum?: ModelTypes["userMetadata_sum_order_by"] | undefined,
		var_pop?: ModelTypes["userMetadata_var_pop_order_by"] | undefined,
		var_samp?: ModelTypes["userMetadata_var_samp_order_by"] | undefined,
		variance?: ModelTypes["userMetadata_variance_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "userMetadata" */
	["userMetadata_arr_rel_insert_input"]: {
		data: Array<ModelTypes["userMetadata_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["userMetadata_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["userMetadata_avg_fields"]: {
		creditBalance?: number | undefined
	};
	/** order by avg() on columns of table "userMetadata" */
	["userMetadata_avg_order_by"]: {
		creditBalance?: ModelTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "userMetadata". All fields are combined with a logical 'AND'. */
	["userMetadata_bool_exp"]: {
		_and?: Array<ModelTypes["userMetadata_bool_exp"]> | undefined,
		_not?: ModelTypes["userMetadata_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userMetadata_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		creditBalance?: ModelTypes["numeric_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		lastCreditRefill?: ModelTypes["date_comparison_exp"] | undefined,
		last_seen?: ModelTypes["timestamp_comparison_exp"] | undefined,
		onboardCompleted?: ModelTypes["Boolean_comparison_exp"] | undefined,
		pricingPlan?: ModelTypes["pricingPlan_bool_exp"] | undefined,
		stripeCustomerId?: ModelTypes["String_comparison_exp"] | undefined,
		stripePriceId?: ModelTypes["String_comparison_exp"] | undefined,
		stripeProductId?: ModelTypes["String_comparison_exp"] | undefined,
		stripeSubscriptionId?: ModelTypes["String_comparison_exp"] | undefined,
		stripeSubscriptionItem?: ModelTypes["String_comparison_exp"] | undefined,
		subscriptionType?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		user?: ModelTypes["users_bool_exp"] | undefined
	};
	["userMetadata_constraint"]: userMetadata_constraint;
	/** input type for incrementing numeric columns in table "userMetadata" */
	["userMetadata_inc_input"]: {
		creditBalance?: ModelTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "userMetadata" */
	["userMetadata_insert_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditBalance?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		lastCreditRefill?: ModelTypes["date"] | undefined,
		last_seen?: ModelTypes["timestamp"] | undefined,
		onboardCompleted?: boolean | undefined,
		pricingPlan?: ModelTypes["pricingPlan_obj_rel_insert_input"] | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined,
		user?: ModelTypes["users_obj_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["userMetadata_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditBalance?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		lastCreditRefill?: ModelTypes["date"] | undefined,
		last_seen?: ModelTypes["timestamp"] | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "userMetadata" */
	["userMetadata_max_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		creditBalance?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		lastCreditRefill?: ModelTypes["order_by"] | undefined,
		last_seen?: ModelTypes["order_by"] | undefined,
		stripeCustomerId?: ModelTypes["order_by"] | undefined,
		stripePriceId?: ModelTypes["order_by"] | undefined,
		stripeProductId?: ModelTypes["order_by"] | undefined,
		stripeSubscriptionId?: ModelTypes["order_by"] | undefined,
		stripeSubscriptionItem?: ModelTypes["order_by"] | undefined,
		subscriptionType?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["userMetadata_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditBalance?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		lastCreditRefill?: ModelTypes["date"] | undefined,
		last_seen?: ModelTypes["timestamp"] | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "userMetadata" */
	["userMetadata_min_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		creditBalance?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		lastCreditRefill?: ModelTypes["order_by"] | undefined,
		last_seen?: ModelTypes["order_by"] | undefined,
		stripeCustomerId?: ModelTypes["order_by"] | undefined,
		stripePriceId?: ModelTypes["order_by"] | undefined,
		stripeProductId?: ModelTypes["order_by"] | undefined,
		stripeSubscriptionId?: ModelTypes["order_by"] | undefined,
		stripeSubscriptionItem?: ModelTypes["order_by"] | undefined,
		subscriptionType?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "userMetadata" */
	["userMetadata_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userMetadata"]>
	};
	/** on_conflict condition type for table "userMetadata" */
	["userMetadata_on_conflict"]: {
		constraint: ModelTypes["userMetadata_constraint"],
		update_columns: Array<ModelTypes["userMetadata_update_column"]>,
		where?: ModelTypes["userMetadata_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userMetadata". */
	["userMetadata_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		creditBalance?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		lastCreditRefill?: ModelTypes["order_by"] | undefined,
		last_seen?: ModelTypes["order_by"] | undefined,
		onboardCompleted?: ModelTypes["order_by"] | undefined,
		pricingPlan?: ModelTypes["pricingPlan_order_by"] | undefined,
		stripeCustomerId?: ModelTypes["order_by"] | undefined,
		stripePriceId?: ModelTypes["order_by"] | undefined,
		stripeProductId?: ModelTypes["order_by"] | undefined,
		stripeSubscriptionId?: ModelTypes["order_by"] | undefined,
		stripeSubscriptionItem?: ModelTypes["order_by"] | undefined,
		subscriptionType?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined,
		user?: ModelTypes["users_order_by"] | undefined
	};
	/** primary key columns input for table: userMetadata */
	["userMetadata_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["userMetadata_select_column"]: userMetadata_select_column;
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"]: userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns;
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"]: userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "userMetadata" */
	["userMetadata_set_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditBalance?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		lastCreditRefill?: ModelTypes["date"] | undefined,
		last_seen?: ModelTypes["timestamp"] | undefined,
		onboardCompleted?: boolean | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["userMetadata_stddev_fields"]: {
		creditBalance?: number | undefined
	};
	/** order by stddev() on columns of table "userMetadata" */
	["userMetadata_stddev_order_by"]: {
		creditBalance?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["userMetadata_stddev_pop_fields"]: {
		creditBalance?: number | undefined
	};
	/** order by stddev_pop() on columns of table "userMetadata" */
	["userMetadata_stddev_pop_order_by"]: {
		creditBalance?: ModelTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["userMetadata_stddev_samp_fields"]: {
		creditBalance?: number | undefined
	};
	/** order by stddev_samp() on columns of table "userMetadata" */
	["userMetadata_stddev_samp_order_by"]: {
		creditBalance?: ModelTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "userMetadata" */
	["userMetadata_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userMetadata_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userMetadata_stream_cursor_value_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		creditBalance?: ModelTypes["numeric"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		lastCreditRefill?: ModelTypes["date"] | undefined,
		last_seen?: ModelTypes["timestamp"] | undefined,
		onboardCompleted?: boolean | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["userMetadata_sum_fields"]: {
		creditBalance?: ModelTypes["numeric"] | undefined
	};
	/** order by sum() on columns of table "userMetadata" */
	["userMetadata_sum_order_by"]: {
		creditBalance?: ModelTypes["order_by"] | undefined
	};
	["userMetadata_update_column"]: userMetadata_update_column;
	["userMetadata_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["userMetadata_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userMetadata_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userMetadata_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userMetadata_var_pop_fields"]: {
		creditBalance?: number | undefined
	};
	/** order by var_pop() on columns of table "userMetadata" */
	["userMetadata_var_pop_order_by"]: {
		creditBalance?: ModelTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["userMetadata_var_samp_fields"]: {
		creditBalance?: number | undefined
	};
	/** order by var_samp() on columns of table "userMetadata" */
	["userMetadata_var_samp_order_by"]: {
		creditBalance?: ModelTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["userMetadata_variance_fields"]: {
		creditBalance?: number | undefined
	};
	/** order by variance() on columns of table "userMetadata" */
	["userMetadata_variance_order_by"]: {
		creditBalance?: ModelTypes["order_by"] | undefined
	};
	/** columns and relationships of "userOnboarding" */
	["userOnboarding"]: {
		behaviorProfile?: string | undefined,
		brandName: string,
		clientPreference?: string | undefined,
		companySegment: string,
		currentStep: number,
		done: boolean,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name: string,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService: string,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount: string,
		userId: ModelTypes["uuid"]
	};
	/** aggregated selection of "userOnboarding" */
	["userOnboarding_aggregate"]: {
		aggregate?: ModelTypes["userOnboarding_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userOnboarding"]>
	};
	/** aggregate fields of "userOnboarding" */
	["userOnboarding_aggregate_fields"]: {
		avg?: ModelTypes["userOnboarding_avg_fields"] | undefined,
		count: number,
		max?: ModelTypes["userOnboarding_max_fields"] | undefined,
		min?: ModelTypes["userOnboarding_min_fields"] | undefined,
		stddev?: ModelTypes["userOnboarding_stddev_fields"] | undefined,
		stddev_pop?: ModelTypes["userOnboarding_stddev_pop_fields"] | undefined,
		stddev_samp?: ModelTypes["userOnboarding_stddev_samp_fields"] | undefined,
		sum?: ModelTypes["userOnboarding_sum_fields"] | undefined,
		var_pop?: ModelTypes["userOnboarding_var_pop_fields"] | undefined,
		var_samp?: ModelTypes["userOnboarding_var_samp_fields"] | undefined,
		variance?: ModelTypes["userOnboarding_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["userOnboarding_avg_fields"]: {
		currentStep?: number | undefined
	};
	/** Boolean expression to filter rows from the table "userOnboarding". All fields are combined with a logical 'AND'. */
	["userOnboarding_bool_exp"]: {
		_and?: Array<ModelTypes["userOnboarding_bool_exp"]> | undefined,
		_not?: ModelTypes["userOnboarding_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userOnboarding_bool_exp"]> | undefined,
		behaviorProfile?: ModelTypes["String_comparison_exp"] | undefined,
		brandName?: ModelTypes["String_comparison_exp"] | undefined,
		clientPreference?: ModelTypes["String_comparison_exp"] | undefined,
		companySegment?: ModelTypes["String_comparison_exp"] | undefined,
		currentStep?: ModelTypes["Int_comparison_exp"] | undefined,
		done?: ModelTypes["Boolean_comparison_exp"] | undefined,
		expectation?: ModelTypes["String_comparison_exp"] | undefined,
		importantSocialNetworkForBusiness?: ModelTypes["String_comparison_exp"] | undefined,
		investInPaidChannels?: ModelTypes["String_comparison_exp"] | undefined,
		marketingObjective?: ModelTypes["String_comparison_exp"] | undefined,
		name?: ModelTypes["String_comparison_exp"] | undefined,
		networkingOnCopyMagico?: ModelTypes["String_comparison_exp"] | undefined,
		phone?: ModelTypes["String_comparison_exp"] | undefined,
		productOrService?: ModelTypes["String_comparison_exp"] | undefined,
		purchaseBehavior?: ModelTypes["String_comparison_exp"] | undefined,
		purchaseChallenges?: ModelTypes["String_comparison_exp"] | undefined,
		purchaseInfluencers?: ModelTypes["String_comparison_exp"] | undefined,
		sellOnCopyMagico?: ModelTypes["String_comparison_exp"] | undefined,
		siteUrl?: ModelTypes["String_comparison_exp"] | undefined,
		socioeconomicProfile?: ModelTypes["String_comparison_exp"] | undefined,
		staffCount?: ModelTypes["String_comparison_exp"] | undefined,
		userId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["userOnboarding_constraint"]: userOnboarding_constraint;
	/** input type for incrementing numeric columns in table "userOnboarding" */
	["userOnboarding_inc_input"]: {
		currentStep?: number | undefined
	};
	/** input type for inserting data into table "userOnboarding" */
	["userOnboarding_insert_input"]: {
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		done?: boolean | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["userOnboarding_max_fields"]: {
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["userOnboarding_min_fields"]: {
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "userOnboarding" */
	["userOnboarding_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userOnboarding"]>
	};
	/** on_conflict condition type for table "userOnboarding" */
	["userOnboarding_on_conflict"]: {
		constraint: ModelTypes["userOnboarding_constraint"],
		update_columns: Array<ModelTypes["userOnboarding_update_column"]>,
		where?: ModelTypes["userOnboarding_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userOnboarding". */
	["userOnboarding_order_by"]: {
		behaviorProfile?: ModelTypes["order_by"] | undefined,
		brandName?: ModelTypes["order_by"] | undefined,
		clientPreference?: ModelTypes["order_by"] | undefined,
		companySegment?: ModelTypes["order_by"] | undefined,
		currentStep?: ModelTypes["order_by"] | undefined,
		done?: ModelTypes["order_by"] | undefined,
		expectation?: ModelTypes["order_by"] | undefined,
		importantSocialNetworkForBusiness?: ModelTypes["order_by"] | undefined,
		investInPaidChannels?: ModelTypes["order_by"] | undefined,
		marketingObjective?: ModelTypes["order_by"] | undefined,
		name?: ModelTypes["order_by"] | undefined,
		networkingOnCopyMagico?: ModelTypes["order_by"] | undefined,
		phone?: ModelTypes["order_by"] | undefined,
		productOrService?: ModelTypes["order_by"] | undefined,
		purchaseBehavior?: ModelTypes["order_by"] | undefined,
		purchaseChallenges?: ModelTypes["order_by"] | undefined,
		purchaseInfluencers?: ModelTypes["order_by"] | undefined,
		sellOnCopyMagico?: ModelTypes["order_by"] | undefined,
		siteUrl?: ModelTypes["order_by"] | undefined,
		socioeconomicProfile?: ModelTypes["order_by"] | undefined,
		staffCount?: ModelTypes["order_by"] | undefined,
		userId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userOnboarding */
	["userOnboarding_pk_columns_input"]: {
		userId: ModelTypes["uuid"]
	};
	["userOnboarding_select_column"]: userOnboarding_select_column;
	/** input type for updating data in table "userOnboarding" */
	["userOnboarding_set_input"]: {
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		done?: boolean | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate stddev on columns */
	["userOnboarding_stddev_fields"]: {
		currentStep?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["userOnboarding_stddev_pop_fields"]: {
		currentStep?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["userOnboarding_stddev_samp_fields"]: {
		currentStep?: number | undefined
	};
	/** Streaming cursor of the table "userOnboarding" */
	["userOnboarding_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userOnboarding_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userOnboarding_stream_cursor_value_input"]: {
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		done?: boolean | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["userOnboarding_sum_fields"]: {
		currentStep?: number | undefined
	};
	["userOnboarding_update_column"]: userOnboarding_update_column;
	["userOnboarding_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: ModelTypes["userOnboarding_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userOnboarding_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userOnboarding_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userOnboarding_var_pop_fields"]: {
		currentStep?: number | undefined
	};
	/** aggregate var_samp on columns */
	["userOnboarding_var_samp_fields"]: {
		currentStep?: number | undefined
	};
	/** aggregate variance on columns */
	["userOnboarding_variance_fields"]: {
		currentStep?: number | undefined
	};
	/** columns and relationships of "userTemplateUsage" */
	["userTemplateUsage"]: {
		created_at: ModelTypes["timestamptz"],
		created_by: ModelTypes["uuid"],
		id: ModelTypes["uuid"],
		textPromptTemplateId: ModelTypes["uuid"]
	};
	/** aggregated selection of "userTemplateUsage" */
	["userTemplateUsage_aggregate"]: {
		aggregate?: ModelTypes["userTemplateUsage_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["userTemplateUsage"]>
	};
	/** aggregate fields of "userTemplateUsage" */
	["userTemplateUsage_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["userTemplateUsage_max_fields"] | undefined,
		min?: ModelTypes["userTemplateUsage_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "userTemplateUsage". All fields are combined with a logical 'AND'. */
	["userTemplateUsage_bool_exp"]: {
		_and?: Array<ModelTypes["userTemplateUsage_bool_exp"]> | undefined,
		_not?: ModelTypes["userTemplateUsage_bool_exp"] | undefined,
		_or?: Array<ModelTypes["userTemplateUsage_bool_exp"]> | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: ModelTypes["uuid_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		textPromptTemplateId?: ModelTypes["uuid_comparison_exp"] | undefined
	};
	["userTemplateUsage_constraint"]: userTemplateUsage_constraint;
	/** input type for inserting data into table "userTemplateUsage" */
	["userTemplateUsage_insert_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["userTemplateUsage_max_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["userTemplateUsage_min_fields"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "userTemplateUsage" */
	["userTemplateUsage_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["userTemplateUsage"]>
	};
	/** on_conflict condition type for table "userTemplateUsage" */
	["userTemplateUsage_on_conflict"]: {
		constraint: ModelTypes["userTemplateUsage_constraint"],
		update_columns: Array<ModelTypes["userTemplateUsage_update_column"]>,
		where?: ModelTypes["userTemplateUsage_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userTemplateUsage". */
	["userTemplateUsage_order_by"]: {
		created_at?: ModelTypes["order_by"] | undefined,
		created_by?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		textPromptTemplateId?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userTemplateUsage */
	["userTemplateUsage_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["userTemplateUsage_select_column"]: userTemplateUsage_select_column;
	/** input type for updating data in table "userTemplateUsage" */
	["userTemplateUsage_set_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "userTemplateUsage" */
	["userTemplateUsage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["userTemplateUsage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userTemplateUsage_stream_cursor_value_input"]: {
		created_at?: ModelTypes["timestamptz"] | undefined,
		created_by?: ModelTypes["uuid"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		textPromptTemplateId?: ModelTypes["uuid"] | undefined
	};
	["userTemplateUsage_update_column"]: userTemplateUsage_update_column;
	["userTemplateUsage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["userTemplateUsage_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["userTemplateUsage_bool_exp"]
	};
	/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["users"]: {
		activeMfaType?: string | undefined,
		avatarUrl: string,
		createdAt: ModelTypes["timestamptz"],
		currentChallenge?: string | undefined,
		defaultRole: string,
		/** An object relationship */
		defaultRoleByRole: ModelTypes["authRoles"],
		disabled: boolean,
		displayName: string,
		email?: ModelTypes["citext"] | undefined,
		emailVerified: boolean,
		id: ModelTypes["uuid"],
		isAnonymous: boolean,
		lastSeen?: ModelTypes["timestamptz"] | undefined,
		locale: string,
		metadata?: ModelTypes["jsonb"] | undefined,
		newEmail?: ModelTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt: ModelTypes["timestamptz"],
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		phoneNumberVerified: boolean,
		/** An array relationship */
		refreshTokens: Array<ModelTypes["authRefreshTokens"]>,
		/** An aggregate relationship */
		refreshTokens_aggregate: ModelTypes["authRefreshTokens_aggregate"],
		/** An array relationship */
		roles: Array<ModelTypes["authUserRoles"]>,
		/** An aggregate relationship */
		roles_aggregate: ModelTypes["authUserRoles_aggregate"],
		/** An array relationship */
		securityKeys: Array<ModelTypes["authUserSecurityKeys"]>,
		/** An aggregate relationship */
		securityKeys_aggregate: ModelTypes["authUserSecurityKeys_aggregate"],
		ticket?: string | undefined,
		ticketExpiresAt: ModelTypes["timestamptz"],
		totpSecret?: string | undefined,
		updatedAt: ModelTypes["timestamptz"],
		/** An array relationship */
		userMetadata: Array<ModelTypes["userMetadata"]>,
		/** An aggregate relationship */
		userMetadata_aggregate: ModelTypes["userMetadata_aggregate"],
		/** An array relationship */
		userProviders: Array<ModelTypes["authUserProviders"]>,
		/** An aggregate relationship */
		userProviders_aggregate: ModelTypes["authUserProviders_aggregate"]
	};
	/** aggregated selection of "auth.users" */
	["users_aggregate"]: {
		aggregate?: ModelTypes["users_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["users"]>
	};
	["users_aggregate_bool_exp"]: {
		bool_and?: ModelTypes["users_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: ModelTypes["users_aggregate_bool_exp_bool_or"] | undefined,
		count?: ModelTypes["users_aggregate_bool_exp_count"] | undefined
	};
	["users_aggregate_bool_exp_bool_and"]: {
		arguments: ModelTypes["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["users_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["users_aggregate_bool_exp_bool_or"]: {
		arguments: ModelTypes["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: ModelTypes["users_bool_exp"] | undefined,
		predicate: ModelTypes["Boolean_comparison_exp"]
	};
	["users_aggregate_bool_exp_count"]: {
		arguments?: Array<ModelTypes["users_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: ModelTypes["users_bool_exp"] | undefined,
		predicate: ModelTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.users" */
	["users_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["users_max_fields"] | undefined,
		min?: ModelTypes["users_min_fields"] | undefined
	};
	/** order by aggregate values of table "auth.users" */
	["users_aggregate_order_by"]: {
		count?: ModelTypes["order_by"] | undefined,
		max?: ModelTypes["users_max_order_by"] | undefined,
		min?: ModelTypes["users_min_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["users_append_input"]: {
		metadata?: ModelTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.users" */
	["users_arr_rel_insert_input"]: {
		data: Array<ModelTypes["users_insert_input"]>,
		/** upsert condition */
		on_conflict?: ModelTypes["users_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
	["users_bool_exp"]: {
		_and?: Array<ModelTypes["users_bool_exp"]> | undefined,
		_not?: ModelTypes["users_bool_exp"] | undefined,
		_or?: Array<ModelTypes["users_bool_exp"]> | undefined,
		activeMfaType?: ModelTypes["String_comparison_exp"] | undefined,
		avatarUrl?: ModelTypes["String_comparison_exp"] | undefined,
		createdAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		currentChallenge?: ModelTypes["String_comparison_exp"] | undefined,
		defaultRole?: ModelTypes["String_comparison_exp"] | undefined,
		defaultRoleByRole?: ModelTypes["authRoles_bool_exp"] | undefined,
		disabled?: ModelTypes["Boolean_comparison_exp"] | undefined,
		displayName?: ModelTypes["String_comparison_exp"] | undefined,
		email?: ModelTypes["citext_comparison_exp"] | undefined,
		emailVerified?: ModelTypes["Boolean_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		isAnonymous?: ModelTypes["Boolean_comparison_exp"] | undefined,
		lastSeen?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		locale?: ModelTypes["String_comparison_exp"] | undefined,
		metadata?: ModelTypes["jsonb_comparison_exp"] | undefined,
		newEmail?: ModelTypes["citext_comparison_exp"] | undefined,
		otpHash?: ModelTypes["String_comparison_exp"] | undefined,
		otpHashExpiresAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		otpMethodLastUsed?: ModelTypes["String_comparison_exp"] | undefined,
		passwordHash?: ModelTypes["String_comparison_exp"] | undefined,
		phoneNumber?: ModelTypes["String_comparison_exp"] | undefined,
		phoneNumberVerified?: ModelTypes["Boolean_comparison_exp"] | undefined,
		refreshTokens?: ModelTypes["authRefreshTokens_bool_exp"] | undefined,
		refreshTokens_aggregate?: ModelTypes["authRefreshTokens_aggregate_bool_exp"] | undefined,
		roles?: ModelTypes["authUserRoles_bool_exp"] | undefined,
		roles_aggregate?: ModelTypes["authUserRoles_aggregate_bool_exp"] | undefined,
		securityKeys?: ModelTypes["authUserSecurityKeys_bool_exp"] | undefined,
		securityKeys_aggregate?: ModelTypes["authUserSecurityKeys_aggregate_bool_exp"] | undefined,
		ticket?: ModelTypes["String_comparison_exp"] | undefined,
		ticketExpiresAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		totpSecret?: ModelTypes["String_comparison_exp"] | undefined,
		updatedAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		userMetadata?: ModelTypes["userMetadata_bool_exp"] | undefined,
		userMetadata_aggregate?: ModelTypes["userMetadata_aggregate_bool_exp"] | undefined,
		userProviders?: ModelTypes["authUserProviders_bool_exp"] | undefined,
		userProviders_aggregate?: ModelTypes["authUserProviders_aggregate_bool_exp"] | undefined
	};
	["users_constraint"]: users_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["users_delete_at_path_input"]: {
		metadata?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["users_delete_elem_input"]: {
		metadata?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["users_delete_key_input"]: {
		metadata?: string | undefined
	};
	/** input type for inserting data into table "auth.users" */
	["users_insert_input"]: {
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		defaultRoleByRole?: ModelTypes["authRoles_obj_rel_insert_input"] | undefined,
		disabled?: boolean | undefined,
		displayName?: string | undefined,
		email?: ModelTypes["citext"] | undefined,
		emailVerified?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		isAnonymous?: boolean | undefined,
		lastSeen?: ModelTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		newEmail?: ModelTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: ModelTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		phoneNumberVerified?: boolean | undefined,
		refreshTokens?: ModelTypes["authRefreshTokens_arr_rel_insert_input"] | undefined,
		roles?: ModelTypes["authUserRoles_arr_rel_insert_input"] | undefined,
		securityKeys?: ModelTypes["authUserSecurityKeys_arr_rel_insert_input"] | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: ModelTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		userMetadata?: ModelTypes["userMetadata_arr_rel_insert_input"] | undefined,
		userProviders?: ModelTypes["authUserProviders_arr_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["users_max_fields"]: {
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		displayName?: string | undefined,
		email?: ModelTypes["citext"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		lastSeen?: ModelTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		newEmail?: ModelTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: ModelTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: ModelTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "auth.users" */
	["users_max_order_by"]: {
		activeMfaType?: ModelTypes["order_by"] | undefined,
		avatarUrl?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		currentChallenge?: ModelTypes["order_by"] | undefined,
		defaultRole?: ModelTypes["order_by"] | undefined,
		displayName?: ModelTypes["order_by"] | undefined,
		email?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		lastSeen?: ModelTypes["order_by"] | undefined,
		locale?: ModelTypes["order_by"] | undefined,
		newEmail?: ModelTypes["order_by"] | undefined,
		otpHash?: ModelTypes["order_by"] | undefined,
		otpHashExpiresAt?: ModelTypes["order_by"] | undefined,
		otpMethodLastUsed?: ModelTypes["order_by"] | undefined,
		passwordHash?: ModelTypes["order_by"] | undefined,
		phoneNumber?: ModelTypes["order_by"] | undefined,
		ticket?: ModelTypes["order_by"] | undefined,
		ticketExpiresAt?: ModelTypes["order_by"] | undefined,
		totpSecret?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["users_min_fields"]: {
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		displayName?: string | undefined,
		email?: ModelTypes["citext"] | undefined,
		id?: ModelTypes["uuid"] | undefined,
		lastSeen?: ModelTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		newEmail?: ModelTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: ModelTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: ModelTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "auth.users" */
	["users_min_order_by"]: {
		activeMfaType?: ModelTypes["order_by"] | undefined,
		avatarUrl?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		currentChallenge?: ModelTypes["order_by"] | undefined,
		defaultRole?: ModelTypes["order_by"] | undefined,
		displayName?: ModelTypes["order_by"] | undefined,
		email?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		lastSeen?: ModelTypes["order_by"] | undefined,
		locale?: ModelTypes["order_by"] | undefined,
		newEmail?: ModelTypes["order_by"] | undefined,
		otpHash?: ModelTypes["order_by"] | undefined,
		otpHashExpiresAt?: ModelTypes["order_by"] | undefined,
		otpMethodLastUsed?: ModelTypes["order_by"] | undefined,
		passwordHash?: ModelTypes["order_by"] | undefined,
		phoneNumber?: ModelTypes["order_by"] | undefined,
		ticket?: ModelTypes["order_by"] | undefined,
		ticketExpiresAt?: ModelTypes["order_by"] | undefined,
		totpSecret?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.users" */
	["users_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["users"]>
	};
	/** input type for inserting object relation for remote table "auth.users" */
	["users_obj_rel_insert_input"]: {
		data: ModelTypes["users_insert_input"],
		/** upsert condition */
		on_conflict?: ModelTypes["users_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "auth.users" */
	["users_on_conflict"]: {
		constraint: ModelTypes["users_constraint"],
		update_columns: Array<ModelTypes["users_update_column"]>,
		where?: ModelTypes["users_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.users". */
	["users_order_by"]: {
		activeMfaType?: ModelTypes["order_by"] | undefined,
		avatarUrl?: ModelTypes["order_by"] | undefined,
		createdAt?: ModelTypes["order_by"] | undefined,
		currentChallenge?: ModelTypes["order_by"] | undefined,
		defaultRole?: ModelTypes["order_by"] | undefined,
		defaultRoleByRole?: ModelTypes["authRoles_order_by"] | undefined,
		disabled?: ModelTypes["order_by"] | undefined,
		displayName?: ModelTypes["order_by"] | undefined,
		email?: ModelTypes["order_by"] | undefined,
		emailVerified?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		isAnonymous?: ModelTypes["order_by"] | undefined,
		lastSeen?: ModelTypes["order_by"] | undefined,
		locale?: ModelTypes["order_by"] | undefined,
		metadata?: ModelTypes["order_by"] | undefined,
		newEmail?: ModelTypes["order_by"] | undefined,
		otpHash?: ModelTypes["order_by"] | undefined,
		otpHashExpiresAt?: ModelTypes["order_by"] | undefined,
		otpMethodLastUsed?: ModelTypes["order_by"] | undefined,
		passwordHash?: ModelTypes["order_by"] | undefined,
		phoneNumber?: ModelTypes["order_by"] | undefined,
		phoneNumberVerified?: ModelTypes["order_by"] | undefined,
		refreshTokens_aggregate?: ModelTypes["authRefreshTokens_aggregate_order_by"] | undefined,
		roles_aggregate?: ModelTypes["authUserRoles_aggregate_order_by"] | undefined,
		securityKeys_aggregate?: ModelTypes["authUserSecurityKeys_aggregate_order_by"] | undefined,
		ticket?: ModelTypes["order_by"] | undefined,
		ticketExpiresAt?: ModelTypes["order_by"] | undefined,
		totpSecret?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined,
		userMetadata_aggregate?: ModelTypes["userMetadata_aggregate_order_by"] | undefined,
		userProviders_aggregate?: ModelTypes["authUserProviders_aggregate_order_by"] | undefined
	};
	/** primary key columns input for table: auth.users */
	["users_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["users_prepend_input"]: {
		metadata?: ModelTypes["jsonb"] | undefined
	};
	["users_select_column"]: users_select_column;
	["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"]: users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns;
	["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"]: users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "auth.users" */
	["users_set_input"]: {
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		disabled?: boolean | undefined,
		displayName?: string | undefined,
		email?: ModelTypes["citext"] | undefined,
		emailVerified?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		isAnonymous?: boolean | undefined,
		lastSeen?: ModelTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		newEmail?: ModelTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: ModelTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		phoneNumberVerified?: boolean | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: ModelTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "users" */
	["users_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["users_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["users_stream_cursor_value_input"]: {
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: ModelTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		disabled?: boolean | undefined,
		displayName?: string | undefined,
		email?: ModelTypes["citext"] | undefined,
		emailVerified?: boolean | undefined,
		id?: ModelTypes["uuid"] | undefined,
		isAnonymous?: boolean | undefined,
		lastSeen?: ModelTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		metadata?: ModelTypes["jsonb"] | undefined,
		newEmail?: ModelTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: ModelTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		phoneNumberVerified?: boolean | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: ModelTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined
	};
	["users_update_column"]: users_update_column;
	["users_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["users_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["users_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["users_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["users_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["users_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["users_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["users_bool_exp"]
	};
	["uuid"]: any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
	["uuid_comparison_exp"]: {
		_eq?: ModelTypes["uuid"] | undefined,
		_gt?: ModelTypes["uuid"] | undefined,
		_gte?: ModelTypes["uuid"] | undefined,
		_in?: Array<ModelTypes["uuid"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: ModelTypes["uuid"] | undefined,
		_lte?: ModelTypes["uuid"] | undefined,
		_neq?: ModelTypes["uuid"] | undefined,
		_nin?: Array<ModelTypes["uuid"]> | undefined
	};
	/** columns and relationships of "storage.virus" */
	["virus"]: {
		createdAt: ModelTypes["timestamptz"],
		/** An object relationship */
		file: ModelTypes["files"],
		fileId: ModelTypes["uuid"],
		filename: string,
		id: ModelTypes["uuid"],
		updatedAt: ModelTypes["timestamptz"],
		userSession: ModelTypes["jsonb"],
		virus: string
	};
	/** aggregated selection of "storage.virus" */
	["virus_aggregate"]: {
		aggregate?: ModelTypes["virus_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["virus"]>
	};
	/** aggregate fields of "storage.virus" */
	["virus_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["virus_max_fields"] | undefined,
		min?: ModelTypes["virus_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["virus_append_input"]: {
		userSession?: ModelTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "storage.virus". All fields are combined with a logical 'AND'. */
	["virus_bool_exp"]: {
		_and?: Array<ModelTypes["virus_bool_exp"]> | undefined,
		_not?: ModelTypes["virus_bool_exp"] | undefined,
		_or?: Array<ModelTypes["virus_bool_exp"]> | undefined,
		createdAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		file?: ModelTypes["files_bool_exp"] | undefined,
		fileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		filename?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		updatedAt?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		userSession?: ModelTypes["jsonb_comparison_exp"] | undefined,
		virus?: ModelTypes["String_comparison_exp"] | undefined
	};
	["virus_constraint"]: virus_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["virus_delete_at_path_input"]: {
		userSession?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["virus_delete_elem_input"]: {
		userSession?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["virus_delete_key_input"]: {
		userSession?: string | undefined
	};
	/** input type for inserting data into table "storage.virus" */
	["virus_insert_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		file?: ModelTypes["files_obj_rel_insert_input"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		userSession?: ModelTypes["jsonb"] | undefined,
		virus?: string | undefined
	};
	/** aggregate max on columns */
	["virus_max_fields"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		virus?: string | undefined
	};
	/** aggregate min on columns */
	["virus_min_fields"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		virus?: string | undefined
	};
	/** response of any mutation on the table "storage.virus" */
	["virus_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["virus"]>
	};
	/** on_conflict condition type for table "storage.virus" */
	["virus_on_conflict"]: {
		constraint: ModelTypes["virus_constraint"],
		update_columns: Array<ModelTypes["virus_update_column"]>,
		where?: ModelTypes["virus_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "storage.virus". */
	["virus_order_by"]: {
		createdAt?: ModelTypes["order_by"] | undefined,
		file?: ModelTypes["files_order_by"] | undefined,
		fileId?: ModelTypes["order_by"] | undefined,
		filename?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		updatedAt?: ModelTypes["order_by"] | undefined,
		userSession?: ModelTypes["order_by"] | undefined,
		virus?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: storage.virus */
	["virus_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["virus_prepend_input"]: {
		userSession?: ModelTypes["jsonb"] | undefined
	};
	["virus_select_column"]: virus_select_column;
	/** input type for updating data in table "storage.virus" */
	["virus_set_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		userSession?: ModelTypes["jsonb"] | undefined,
		virus?: string | undefined
	};
	/** Streaming cursor of the table "virus" */
	["virus_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["virus_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["virus_stream_cursor_value_input"]: {
		createdAt?: ModelTypes["timestamptz"] | undefined,
		fileId?: ModelTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		updatedAt?: ModelTypes["timestamptz"] | undefined,
		userSession?: ModelTypes["jsonb"] | undefined,
		virus?: string | undefined
	};
	["virus_update_column"]: virus_update_column;
	["virus_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: ModelTypes["virus_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: ModelTypes["virus_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: ModelTypes["virus_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: ModelTypes["virus_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: ModelTypes["virus_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["virus_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["virus_bool_exp"]
	};
	/** columns and relationships of "website" */
	["website"]: {
		aboutUsContactBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: ModelTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor: string,
		backgroundColor: string,
		/** An object relationship */
		brand: ModelTypes["brands"],
		brandId: ModelTypes["uuid"],
		created_at?: ModelTypes["timestamptz"] | undefined,
		domain: string,
		foregroundColor: string,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id: ModelTypes["uuid"],
		openGraphDescription?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregated selection of "website" */
	["website_aggregate"]: {
		aggregate?: ModelTypes["website_aggregate_fields"] | undefined,
		nodes: Array<ModelTypes["website"]>
	};
	/** aggregate fields of "website" */
	["website_aggregate_fields"]: {
		count: number,
		max?: ModelTypes["website_max_fields"] | undefined,
		min?: ModelTypes["website_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "website". All fields are combined with a logical 'AND'. */
	["website_bool_exp"]: {
		_and?: Array<ModelTypes["website_bool_exp"]> | undefined,
		_not?: ModelTypes["website_bool_exp"] | undefined,
		_or?: Array<ModelTypes["website_bool_exp"]> | undefined,
		aboutUsContactBannerImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		aboutUsContactTitle?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsHistoryBannerImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		aboutUsHistoryDescription?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsHistoryTitle?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsHistorytKeypointSubtitleOne?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsHistorytKeypointTitleOne?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsHistorytKeypointTitleTwo?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsStatementBannerImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		aboutUsStatementBannerText?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadyBannerImageId?: ModelTypes["uuid_comparison_exp"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadyInnovationDescription?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadyTitle?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadyTransparencyDescription?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveBannerImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		aboutUsWhatMoveUsMission?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveUsPurpose?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveUsTitle?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveUsValues?: ModelTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveUsVision?: ModelTypes["String_comparison_exp"] | undefined,
		accentColor?: ModelTypes["String_comparison_exp"] | undefined,
		backgroundColor?: ModelTypes["String_comparison_exp"] | undefined,
		brand?: ModelTypes["brands_bool_exp"] | undefined,
		brandId?: ModelTypes["uuid_comparison_exp"] | undefined,
		created_at?: ModelTypes["timestamptz_comparison_exp"] | undefined,
		domain?: ModelTypes["String_comparison_exp"] | undefined,
		foregroundColor?: ModelTypes["String_comparison_exp"] | undefined,
		googleAnalyticsKey?: ModelTypes["String_comparison_exp"] | undefined,
		googleTagManagerKey?: ModelTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightBannerImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		homeBrandHighlightDescription?: ModelTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointSubtitleOne?: ModelTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: ModelTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointTitleOne?: ModelTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointTitleTwo?: ModelTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointValuePropositionList?: ModelTypes["String_array_comparison_exp"] | undefined,
		homeBrandHighlightTitle?: ModelTypes["String_comparison_exp"] | undefined,
		homeHeroBannerImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		homeHeroCallToActionTargetUrl?: ModelTypes["String_comparison_exp"] | undefined,
		homeHeroCallToActionText?: ModelTypes["String_comparison_exp"] | undefined,
		homeHeroDescription?: ModelTypes["String_comparison_exp"] | undefined,
		homeHeroLabel?: ModelTypes["String_comparison_exp"] | undefined,
		homeHeroTitle?: ModelTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsBannerImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		homeOurDifferentiatorsDescription?: ModelTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: ModelTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: ModelTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: ModelTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: ModelTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsTitle?: ModelTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToActionTargetUrl?: ModelTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToActionText?: ModelTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: ModelTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: ModelTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: ModelTypes["uuid_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: ModelTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoDescription?: ModelTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoTitle?: ModelTypes["String_comparison_exp"] | undefined,
		id?: ModelTypes["uuid_comparison_exp"] | undefined,
		openGraphDescription?: ModelTypes["String_comparison_exp"] | undefined,
		updated_at?: ModelTypes["timestamptz_comparison_exp"] | undefined
	};
	["website_constraint"]: website_constraint;
	/** input type for inserting data into table "website" */
	["website_insert_input"]: {
		aboutUsContactBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: ModelTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brand?: ModelTypes["brands_obj_rel_insert_input"] | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["website_max_fields"]: {
		aboutUsContactBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: ModelTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["website_min_fields"]: {
		aboutUsContactBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: ModelTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "website" */
	["website_mutation_response"]: {
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<ModelTypes["website"]>
	};
	/** on_conflict condition type for table "website" */
	["website_on_conflict"]: {
		constraint: ModelTypes["website_constraint"],
		update_columns: Array<ModelTypes["website_update_column"]>,
		where?: ModelTypes["website_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "website". */
	["website_order_by"]: {
		aboutUsContactBannerImageFileId?: ModelTypes["order_by"] | undefined,
		aboutUsContactTitle?: ModelTypes["order_by"] | undefined,
		aboutUsHistoryBannerImageFileId?: ModelTypes["order_by"] | undefined,
		aboutUsHistoryDescription?: ModelTypes["order_by"] | undefined,
		aboutUsHistoryTitle?: ModelTypes["order_by"] | undefined,
		aboutUsHistorytKeypointSubtitleOne?: ModelTypes["order_by"] | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: ModelTypes["order_by"] | undefined,
		aboutUsHistorytKeypointTitleOne?: ModelTypes["order_by"] | undefined,
		aboutUsHistorytKeypointTitleTwo?: ModelTypes["order_by"] | undefined,
		aboutUsStatementBannerImageFileId?: ModelTypes["order_by"] | undefined,
		aboutUsStatementBannerText?: ModelTypes["order_by"] | undefined,
		aboutUsWeAreReadyBannerImageId?: ModelTypes["order_by"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: ModelTypes["order_by"] | undefined,
		aboutUsWeAreReadyInnovationDescription?: ModelTypes["order_by"] | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: ModelTypes["order_by"] | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: ModelTypes["order_by"] | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: ModelTypes["order_by"] | undefined,
		aboutUsWeAreReadyTitle?: ModelTypes["order_by"] | undefined,
		aboutUsWeAreReadyTransparencyDescription?: ModelTypes["order_by"] | undefined,
		aboutUsWhatMoveBannerImageFileId?: ModelTypes["order_by"] | undefined,
		aboutUsWhatMoveUsMission?: ModelTypes["order_by"] | undefined,
		aboutUsWhatMoveUsPurpose?: ModelTypes["order_by"] | undefined,
		aboutUsWhatMoveUsTitle?: ModelTypes["order_by"] | undefined,
		aboutUsWhatMoveUsValues?: ModelTypes["order_by"] | undefined,
		aboutUsWhatMoveUsVision?: ModelTypes["order_by"] | undefined,
		accentColor?: ModelTypes["order_by"] | undefined,
		backgroundColor?: ModelTypes["order_by"] | undefined,
		brand?: ModelTypes["brands_order_by"] | undefined,
		brandId?: ModelTypes["order_by"] | undefined,
		created_at?: ModelTypes["order_by"] | undefined,
		domain?: ModelTypes["order_by"] | undefined,
		foregroundColor?: ModelTypes["order_by"] | undefined,
		googleAnalyticsKey?: ModelTypes["order_by"] | undefined,
		googleTagManagerKey?: ModelTypes["order_by"] | undefined,
		homeBrandHighlightBannerImageFileId?: ModelTypes["order_by"] | undefined,
		homeBrandHighlightDescription?: ModelTypes["order_by"] | undefined,
		homeBrandHighlightKeypointSubtitleOne?: ModelTypes["order_by"] | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: ModelTypes["order_by"] | undefined,
		homeBrandHighlightKeypointTitleOne?: ModelTypes["order_by"] | undefined,
		homeBrandHighlightKeypointTitleTwo?: ModelTypes["order_by"] | undefined,
		homeBrandHighlightKeypointValuePropositionList?: ModelTypes["order_by"] | undefined,
		homeBrandHighlightTitle?: ModelTypes["order_by"] | undefined,
		homeHeroBannerImageFileId?: ModelTypes["order_by"] | undefined,
		homeHeroCallToActionTargetUrl?: ModelTypes["order_by"] | undefined,
		homeHeroCallToActionText?: ModelTypes["order_by"] | undefined,
		homeHeroDescription?: ModelTypes["order_by"] | undefined,
		homeHeroLabel?: ModelTypes["order_by"] | undefined,
		homeHeroTitle?: ModelTypes["order_by"] | undefined,
		homeOurDifferentiatorsBannerImageFileId?: ModelTypes["order_by"] | undefined,
		homeOurDifferentiatorsDescription?: ModelTypes["order_by"] | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: ModelTypes["order_by"] | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: ModelTypes["order_by"] | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: ModelTypes["order_by"] | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: ModelTypes["order_by"] | undefined,
		homeOurDifferentiatorsTitle?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoCallToActionTargetUrl?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoCallToActionText?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoDescription?: ModelTypes["order_by"] | undefined,
		homeWhatWeDoTitle?: ModelTypes["order_by"] | undefined,
		id?: ModelTypes["order_by"] | undefined,
		openGraphDescription?: ModelTypes["order_by"] | undefined,
		updated_at?: ModelTypes["order_by"] | undefined
	};
	/** primary key columns input for table: website */
	["website_pk_columns_input"]: {
		id: ModelTypes["uuid"]
	};
	["website_select_column"]: website_select_column;
	/** input type for updating data in table "website" */
	["website_set_input"]: {
		aboutUsContactBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: ModelTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "website" */
	["website_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: ModelTypes["website_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: ModelTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["website_stream_cursor_value_input"]: {
		aboutUsContactBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: ModelTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: ModelTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brandId?: ModelTypes["uuid"] | undefined,
		created_at?: ModelTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: ModelTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: ModelTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: ModelTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: ModelTypes["timestamptz"] | undefined
	};
	["website_update_column"]: website_update_column;
	["website_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: ModelTypes["website_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: ModelTypes["website_bool_exp"]
	}
}

export type GraphQLTypes = {
	["AIImageResult"]: {
		__typename: "AIImageResult",
		id: string,
		url: string
	};
	/** columns and relationships of "AppIntegration" */
	["AppIntegration"]: {
		__typename: "AppIntegration",
		configParams: GraphQLTypes["jsonb"],
		description: string,
		icon?: string | undefined,
		id: GraphQLTypes["uuid"],
		name: string,
		type: string
	};
	/** aggregated selection of "AppIntegration" */
	["AppIntegration_aggregate"]: {
		__typename: "AppIntegration_aggregate",
		aggregate?: GraphQLTypes["AppIntegration_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["AppIntegration"]>
	};
	/** aggregate fields of "AppIntegration" */
	["AppIntegration_aggregate_fields"]: {
		__typename: "AppIntegration_aggregate_fields",
		count: number,
		max?: GraphQLTypes["AppIntegration_max_fields"] | undefined,
		min?: GraphQLTypes["AppIntegration_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["AppIntegration_append_input"]: {
		configParams?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "AppIntegration". All fields are combined with a logical 'AND'. */
	["AppIntegration_bool_exp"]: {
		_and?: Array<GraphQLTypes["AppIntegration_bool_exp"]> | undefined,
		_not?: GraphQLTypes["AppIntegration_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["AppIntegration_bool_exp"]> | undefined,
		configParams?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		description?: GraphQLTypes["String_comparison_exp"] | undefined,
		icon?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined,
		type?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "AppIntegration" */
	["AppIntegration_constraint"]: AppIntegration_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["AppIntegration_delete_at_path_input"]: {
		configParams?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["AppIntegration_delete_elem_input"]: {
		configParams?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["AppIntegration_delete_key_input"]: {
		configParams?: string | undefined
	};
	/** input type for inserting data into table "AppIntegration" */
	["AppIntegration_insert_input"]: {
		configParams?: GraphQLTypes["jsonb"] | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** aggregate max on columns */
	["AppIntegration_max_fields"]: {
		__typename: "AppIntegration_max_fields",
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** aggregate min on columns */
	["AppIntegration_min_fields"]: {
		__typename: "AppIntegration_min_fields",
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** response of any mutation on the table "AppIntegration" */
	["AppIntegration_mutation_response"]: {
		__typename: "AppIntegration_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["AppIntegration"]>
	};
	/** on_conflict condition type for table "AppIntegration" */
	["AppIntegration_on_conflict"]: {
		constraint: GraphQLTypes["AppIntegration_constraint"],
		update_columns: Array<GraphQLTypes["AppIntegration_update_column"]>,
		where?: GraphQLTypes["AppIntegration_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "AppIntegration". */
	["AppIntegration_order_by"]: {
		configParams?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		icon?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		type?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: AppIntegration */
	["AppIntegration_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["AppIntegration_prepend_input"]: {
		configParams?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "AppIntegration" */
	["AppIntegration_select_column"]: AppIntegration_select_column;
	/** input type for updating data in table "AppIntegration" */
	["AppIntegration_set_input"]: {
		configParams?: GraphQLTypes["jsonb"] | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** Streaming cursor of the table "AppIntegration" */
	["AppIntegration_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["AppIntegration_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["AppIntegration_stream_cursor_value_input"]: {
		configParams?: GraphQLTypes["jsonb"] | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		type?: string | undefined
	};
	/** update columns of table "AppIntegration" */
	["AppIntegration_update_column"]: AppIntegration_update_column;
	["AppIntegration_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["AppIntegration_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["AppIntegration_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["AppIntegration_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["AppIntegration_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["AppIntegration_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["AppIntegration_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["AppIntegration_bool_exp"]
	};
	/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
	["Boolean_comparison_exp"]: {
		_eq?: boolean | undefined,
		_gt?: boolean | undefined,
		_gte?: boolean | undefined,
		_in?: Array<boolean> | undefined,
		_is_null?: boolean | undefined,
		_lt?: boolean | undefined,
		_lte?: boolean | undefined,
		_neq?: boolean | undefined,
		_nin?: Array<boolean> | undefined
	};
	["Category"]: {
		__typename: "Category",
		id: string,
		name: string
	};
	/** columns and relationships of "Course" */
	["Course"]: {
		__typename: "Course",
		courseCategoryId?: GraphQLTypes["bigint"] | undefined,
		description?: string | undefined,
		id: GraphQLTypes["uuid"],
		imageUrl?: string | undefined,
		name: string
	};
	/** columns and relationships of "CourseCategory" */
	["CourseCategory"]: {
		__typename: "CourseCategory",
		id: GraphQLTypes["bigint"],
		name: string
	};
	/** aggregated selection of "CourseCategory" */
	["CourseCategory_aggregate"]: {
		__typename: "CourseCategory_aggregate",
		aggregate?: GraphQLTypes["CourseCategory_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["CourseCategory"]>
	};
	/** aggregate fields of "CourseCategory" */
	["CourseCategory_aggregate_fields"]: {
		__typename: "CourseCategory_aggregate_fields",
		avg?: GraphQLTypes["CourseCategory_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["CourseCategory_max_fields"] | undefined,
		min?: GraphQLTypes["CourseCategory_min_fields"] | undefined,
		stddev?: GraphQLTypes["CourseCategory_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["CourseCategory_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["CourseCategory_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["CourseCategory_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["CourseCategory_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["CourseCategory_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["CourseCategory_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["CourseCategory_avg_fields"]: {
		__typename: "CourseCategory_avg_fields",
		id?: number | undefined
	};
	/** Boolean expression to filter rows from the table "CourseCategory". All fields are combined with a logical 'AND'. */
	["CourseCategory_bool_exp"]: {
		_and?: Array<GraphQLTypes["CourseCategory_bool_exp"]> | undefined,
		_not?: GraphQLTypes["CourseCategory_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["CourseCategory_bool_exp"]> | undefined,
		id?: GraphQLTypes["bigint_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "CourseCategory" */
	["CourseCategory_constraint"]: CourseCategory_constraint;
	/** input type for incrementing numeric columns in table "CourseCategory" */
	["CourseCategory_inc_input"]: {
		id?: GraphQLTypes["bigint"] | undefined
	};
	/** input type for inserting data into table "CourseCategory" */
	["CourseCategory_insert_input"]: {
		id?: GraphQLTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** aggregate max on columns */
	["CourseCategory_max_fields"]: {
		__typename: "CourseCategory_max_fields",
		id?: GraphQLTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** aggregate min on columns */
	["CourseCategory_min_fields"]: {
		__typename: "CourseCategory_min_fields",
		id?: GraphQLTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** response of any mutation on the table "CourseCategory" */
	["CourseCategory_mutation_response"]: {
		__typename: "CourseCategory_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["CourseCategory"]>
	};
	/** on_conflict condition type for table "CourseCategory" */
	["CourseCategory_on_conflict"]: {
		constraint: GraphQLTypes["CourseCategory_constraint"],
		update_columns: Array<GraphQLTypes["CourseCategory_update_column"]>,
		where?: GraphQLTypes["CourseCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "CourseCategory". */
	["CourseCategory_order_by"]: {
		id?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: CourseCategory */
	["CourseCategory_pk_columns_input"]: {
		id: GraphQLTypes["bigint"]
	};
	/** select columns of table "CourseCategory" */
	["CourseCategory_select_column"]: CourseCategory_select_column;
	/** input type for updating data in table "CourseCategory" */
	["CourseCategory_set_input"]: {
		id?: GraphQLTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** aggregate stddev on columns */
	["CourseCategory_stddev_fields"]: {
		__typename: "CourseCategory_stddev_fields",
		id?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["CourseCategory_stddev_pop_fields"]: {
		__typename: "CourseCategory_stddev_pop_fields",
		id?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["CourseCategory_stddev_samp_fields"]: {
		__typename: "CourseCategory_stddev_samp_fields",
		id?: number | undefined
	};
	/** Streaming cursor of the table "CourseCategory" */
	["CourseCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["CourseCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["CourseCategory_stream_cursor_value_input"]: {
		id?: GraphQLTypes["bigint"] | undefined,
		name?: string | undefined
	};
	/** aggregate sum on columns */
	["CourseCategory_sum_fields"]: {
		__typename: "CourseCategory_sum_fields",
		id?: GraphQLTypes["bigint"] | undefined
	};
	/** update columns of table "CourseCategory" */
	["CourseCategory_update_column"]: CourseCategory_update_column;
	["CourseCategory_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["CourseCategory_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["CourseCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["CourseCategory_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["CourseCategory_var_pop_fields"]: {
		__typename: "CourseCategory_var_pop_fields",
		id?: number | undefined
	};
	/** aggregate var_samp on columns */
	["CourseCategory_var_samp_fields"]: {
		__typename: "CourseCategory_var_samp_fields",
		id?: number | undefined
	};
	/** aggregate variance on columns */
	["CourseCategory_variance_fields"]: {
		__typename: "CourseCategory_variance_fields",
		id?: number | undefined
	};
	/** aggregated selection of "Course" */
	["Course_aggregate"]: {
		__typename: "Course_aggregate",
		aggregate?: GraphQLTypes["Course_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["Course"]>
	};
	/** aggregate fields of "Course" */
	["Course_aggregate_fields"]: {
		__typename: "Course_aggregate_fields",
		avg?: GraphQLTypes["Course_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["Course_max_fields"] | undefined,
		min?: GraphQLTypes["Course_min_fields"] | undefined,
		stddev?: GraphQLTypes["Course_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["Course_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["Course_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["Course_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["Course_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["Course_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["Course_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["Course_avg_fields"]: {
		__typename: "Course_avg_fields",
		courseCategoryId?: number | undefined
	};
	/** Boolean expression to filter rows from the table "Course". All fields are combined with a logical 'AND'. */
	["Course_bool_exp"]: {
		_and?: Array<GraphQLTypes["Course_bool_exp"]> | undefined,
		_not?: GraphQLTypes["Course_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["Course_bool_exp"]> | undefined,
		courseCategoryId?: GraphQLTypes["bigint_comparison_exp"] | undefined,
		description?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		imageUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "Course" */
	["Course_constraint"]: Course_constraint;
	/** input type for incrementing numeric columns in table "Course" */
	["Course_inc_input"]: {
		courseCategoryId?: GraphQLTypes["bigint"] | undefined
	};
	/** input type for inserting data into table "Course" */
	["Course_insert_input"]: {
		courseCategoryId?: GraphQLTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** aggregate max on columns */
	["Course_max_fields"]: {
		__typename: "Course_max_fields",
		courseCategoryId?: GraphQLTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** aggregate min on columns */
	["Course_min_fields"]: {
		__typename: "Course_min_fields",
		courseCategoryId?: GraphQLTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** response of any mutation on the table "Course" */
	["Course_mutation_response"]: {
		__typename: "Course_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["Course"]>
	};
	/** on_conflict condition type for table "Course" */
	["Course_on_conflict"]: {
		constraint: GraphQLTypes["Course_constraint"],
		update_columns: Array<GraphQLTypes["Course_update_column"]>,
		where?: GraphQLTypes["Course_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "Course". */
	["Course_order_by"]: {
		courseCategoryId?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		imageUrl?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: Course */
	["Course_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "Course" */
	["Course_select_column"]: Course_select_column;
	/** input type for updating data in table "Course" */
	["Course_set_input"]: {
		courseCategoryId?: GraphQLTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** aggregate stddev on columns */
	["Course_stddev_fields"]: {
		__typename: "Course_stddev_fields",
		courseCategoryId?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["Course_stddev_pop_fields"]: {
		__typename: "Course_stddev_pop_fields",
		courseCategoryId?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["Course_stddev_samp_fields"]: {
		__typename: "Course_stddev_samp_fields",
		courseCategoryId?: number | undefined
	};
	/** Streaming cursor of the table "Course" */
	["Course_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["Course_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["Course_stream_cursor_value_input"]: {
		courseCategoryId?: GraphQLTypes["bigint"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageUrl?: string | undefined,
		name?: string | undefined
	};
	/** aggregate sum on columns */
	["Course_sum_fields"]: {
		__typename: "Course_sum_fields",
		courseCategoryId?: GraphQLTypes["bigint"] | undefined
	};
	/** update columns of table "Course" */
	["Course_update_column"]: Course_update_column;
	["Course_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["Course_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["Course_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["Course_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["Course_var_pop_fields"]: {
		__typename: "Course_var_pop_fields",
		courseCategoryId?: number | undefined
	};
	/** aggregate var_samp on columns */
	["Course_var_samp_fields"]: {
		__typename: "Course_var_samp_fields",
		courseCategoryId?: number | undefined
	};
	/** aggregate variance on columns */
	["Course_variance_fields"]: {
		__typename: "Course_variance_fields",
		courseCategoryId?: number | undefined
	};
	["CreateAutomaticProjectOutput"]: {
		__typename: "CreateAutomaticProjectOutput",
		projectId?: string | undefined
	};
	["CreateImageUsingImagineOutput"]: {
		__typename: "CreateImageUsingImagineOutput",
		errorMessage?: string | undefined,
		fileId?: string | undefined,
		success: boolean
	};
	["ExportToWordpressInput"]: {
		category: number,
		generatedTemplateById?: string | undefined,
		integrationId: string,
		postTimeType: string,
		scheduledDate?: GraphQLTypes["date"] | undefined,
		tags?: Array<number> | undefined
	};
	["ExportToWordpressOutput"]: {
		__typename: "ExportToWordpressOutput",
		url: string
	};
	["FacebookAuthCallbackOutput"]: {
		__typename: "FacebookAuthCallbackOutput",
		success: boolean
	};
	["FinishOnboardOutput"]: {
		__typename: "FinishOnboardOutput",
		success: boolean
	};
	["GenerateCalendarExOutput"]: {
		__typename: "GenerateCalendarExOutput",
		success: boolean
	};
	["GenerateFromTextTemplateInput"]: {
		locale: string,
		selectedParameters?: Array<GraphQLTypes["StringKeyValuePair"]> | undefined,
		templateId: string,
		templateVariables?: Array<GraphQLTypes["StringKeyValuePair"]> | undefined
	};
	["GenerateFromTextTemplateOutput"]: {
		__typename: "GenerateFromTextTemplateOutput",
		text?: string | undefined
	};
	["GenerateImageFromPromptInput"]: {
		provider: string,
		query: string
	};
	["GenerateImageFromPromptOutput"]: {
		__typename: "GenerateImageFromPromptOutput",
		images?: Array<GraphQLTypes["AIImageResult"] | undefined> | undefined
	};
	["GenerateNextMonthCalendarInput"]: {
		campaignObjective: string
	};
	["GenerateNextMonthCalendarOutput"]: {
		__typename: "GenerateNextMonthCalendarOutput",
		created: boolean
	};
	/** columns and relationships of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate"]: {
		__typename: "GeneratedPromptByTemplate",
		/** An array relationship */
		GeneratedPromptByTemplateImages: Array<GraphQLTypes["GeneratedPromptByTemplateImage"]>,
		/** An aggregate relationship */
		GeneratedPromptByTemplateImages_aggregate: GraphQLTypes["GeneratedPromptByTemplateImage_aggregate"],
		/** An object relationship */
		TextPromptTemplate?: GraphQLTypes["TextPromptTemplate"] | undefined,
		active: boolean,
		alias?: string | undefined,
		backgroundImageFileId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		command?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by: GraphQLTypes["uuid"],
		/** An object relationship */
		displayFormat?: GraphQLTypes["displayFormats"] | undefined,
		displayImageUrl?: string | undefined,
		displayMapper?: GraphQLTypes["jsonb"] | undefined,
		generatingDisplayStatus?: string | undefined,
		id: GraphQLTypes["uuid"],
		/** An object relationship */
		pricingPlan?: GraphQLTypes["pricingPlan"] | undefined,
		pricingPlanId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** columns and relationships of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage"]: {
		__typename: "GeneratedPromptByTemplateImage",
		GeneratedPromptByTemplateId: GraphQLTypes["uuid"],
		fileId: GraphQLTypes["uuid"],
		id: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate"]: {
		__typename: "GeneratedPromptByTemplateImage_aggregate",
		aggregate?: GraphQLTypes["GeneratedPromptByTemplateImage_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["GeneratedPromptByTemplateImage"]>
	};
	["GeneratedPromptByTemplateImage_aggregate_bool_exp"]: {
		count?: GraphQLTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"] | undefined
	};
	["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["GeneratedPromptByTemplateImage_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate_fields"]: {
		__typename: "GeneratedPromptByTemplateImage_aggregate_fields",
		count: number,
		max?: GraphQLTypes["GeneratedPromptByTemplateImage_max_fields"] | undefined,
		min?: GraphQLTypes["GeneratedPromptByTemplateImage_min_fields"] | undefined
	};
	/** order by aggregate values of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["GeneratedPromptByTemplateImage_max_order_by"] | undefined,
		min?: GraphQLTypes["GeneratedPromptByTemplateImage_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["GeneratedPromptByTemplateImage_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["GeneratedPromptByTemplateImage_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "GeneratedPromptByTemplateImage". All fields are combined with a logical 'AND'. */
	["GeneratedPromptByTemplateImage_bool_exp"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		_and?: Array<GraphQLTypes["GeneratedPromptByTemplateImage_bool_exp"]> | undefined,
		_not?: GraphQLTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["GeneratedPromptByTemplateImage_bool_exp"]> | undefined,
		fileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_constraint"]: GeneratedPromptByTemplateImage_constraint;
	/** input type for inserting data into table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_insert_input"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["GeneratedPromptByTemplateImage_max_fields"]: {
		__typename: "GeneratedPromptByTemplateImage_max_fields",
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_max_order_by"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["order_by"] | undefined,
		fileId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["GeneratedPromptByTemplateImage_min_fields"]: {
		__typename: "GeneratedPromptByTemplateImage_min_fields",
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_min_order_by"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["order_by"] | undefined,
		fileId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_mutation_response"]: {
		__typename: "GeneratedPromptByTemplateImage_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["GeneratedPromptByTemplateImage"]>
	};
	/** on_conflict condition type for table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_on_conflict"]: {
		constraint: GraphQLTypes["GeneratedPromptByTemplateImage_constraint"],
		update_columns: Array<GraphQLTypes["GeneratedPromptByTemplateImage_update_column"]>,
		where?: GraphQLTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "GeneratedPromptByTemplateImage". */
	["GeneratedPromptByTemplateImage_order_by"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["order_by"] | undefined,
		fileId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: GeneratedPromptByTemplateImage */
	["GeneratedPromptByTemplateImage_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_select_column"]: GeneratedPromptByTemplateImage_select_column;
	/** input type for updating data in table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_set_input"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["GeneratedPromptByTemplateImage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["GeneratedPromptByTemplateImage_stream_cursor_value_input"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "GeneratedPromptByTemplateImage" */
	["GeneratedPromptByTemplateImage_update_column"]: GeneratedPromptByTemplateImage_update_column;
	["GeneratedPromptByTemplateImage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["GeneratedPromptByTemplateImage_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["GeneratedPromptByTemplateImage_bool_exp"]
	};
	/** aggregated selection of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate"]: {
		__typename: "GeneratedPromptByTemplate_aggregate",
		aggregate?: GraphQLTypes["GeneratedPromptByTemplate_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["GeneratedPromptByTemplate"]>
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: GraphQLTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"] | undefined,
		count?: GraphQLTypes["GeneratedPromptByTemplate_aggregate_bool_exp_count"] | undefined
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["GeneratedPromptByTemplate_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["GeneratedPromptByTemplate_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate_fields"]: {
		__typename: "GeneratedPromptByTemplate_aggregate_fields",
		count: number,
		max?: GraphQLTypes["GeneratedPromptByTemplate_max_fields"] | undefined,
		min?: GraphQLTypes["GeneratedPromptByTemplate_min_fields"] | undefined
	};
	/** order by aggregate values of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["GeneratedPromptByTemplate_max_order_by"] | undefined,
		min?: GraphQLTypes["GeneratedPromptByTemplate_min_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["GeneratedPromptByTemplate_append_input"]: {
		command?: GraphQLTypes["jsonb"] | undefined,
		displayMapper?: GraphQLTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["GeneratedPromptByTemplate_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["GeneratedPromptByTemplate_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "GeneratedPromptByTemplate". All fields are combined with a logical 'AND'. */
	["GeneratedPromptByTemplate_bool_exp"]: {
		GeneratedPromptByTemplateImages?: GraphQLTypes["GeneratedPromptByTemplateImage_bool_exp"] | undefined,
		GeneratedPromptByTemplateImages_aggregate?: GraphQLTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp"] | undefined,
		TextPromptTemplate?: GraphQLTypes["TextPromptTemplate_bool_exp"] | undefined,
		_and?: Array<GraphQLTypes["GeneratedPromptByTemplate_bool_exp"]> | undefined,
		_not?: GraphQLTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["GeneratedPromptByTemplate_bool_exp"]> | undefined,
		active?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		alias?: GraphQLTypes["String_comparison_exp"] | undefined,
		backgroundImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		channel?: GraphQLTypes["String_comparison_exp"] | undefined,
		command?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		displayFormat?: GraphQLTypes["displayFormats_bool_exp"] | undefined,
		displayImageUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		displayMapper?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		generatingDisplayStatus?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		pricingPlan?: GraphQLTypes["pricingPlan_bool_exp"] | undefined,
		pricingPlanId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		publishDate?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		result?: GraphQLTypes["String_comparison_exp"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		status?: GraphQLTypes["String_comparison_exp"] | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_constraint"]: GeneratedPromptByTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["GeneratedPromptByTemplate_delete_at_path_input"]: {
		command?: Array<string> | undefined,
		displayMapper?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["GeneratedPromptByTemplate_delete_elem_input"]: {
		command?: number | undefined,
		displayMapper?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["GeneratedPromptByTemplate_delete_key_input"]: {
		command?: string | undefined,
		displayMapper?: string | undefined
	};
	/** input type for inserting data into table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_insert_input"]: {
		GeneratedPromptByTemplateImages?: GraphQLTypes["GeneratedPromptByTemplateImage_arr_rel_insert_input"] | undefined,
		TextPromptTemplate?: GraphQLTypes["TextPromptTemplate_obj_rel_insert_input"] | undefined,
		active?: boolean | undefined,
		alias?: string | undefined,
		backgroundImageFileId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		command?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		displayFormat?: GraphQLTypes["displayFormats_obj_rel_insert_input"] | undefined,
		displayImageUrl?: string | undefined,
		displayMapper?: GraphQLTypes["jsonb"] | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		pricingPlan?: GraphQLTypes["pricingPlan_obj_rel_insert_input"] | undefined,
		pricingPlanId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["GeneratedPromptByTemplate_max_fields"]: {
		__typename: "GeneratedPromptByTemplate_max_fields",
		alias?: string | undefined,
		backgroundImageFileId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		displayImageUrl?: string | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		pricingPlanId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_max_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		backgroundImageFileId?: GraphQLTypes["order_by"] | undefined,
		channel?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		displayImageUrl?: GraphQLTypes["order_by"] | undefined,
		generatingDisplayStatus?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		pricingPlanId?: GraphQLTypes["order_by"] | undefined,
		publishDate?: GraphQLTypes["order_by"] | undefined,
		result?: GraphQLTypes["order_by"] | undefined,
		scheduledDate?: GraphQLTypes["order_by"] | undefined,
		scheduledDateEventId?: GraphQLTypes["order_by"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["order_by"] | undefined,
		status?: GraphQLTypes["order_by"] | undefined,
		textPromptTemplateId?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["GeneratedPromptByTemplate_min_fields"]: {
		__typename: "GeneratedPromptByTemplate_min_fields",
		alias?: string | undefined,
		backgroundImageFileId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		displayImageUrl?: string | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		pricingPlanId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_min_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		backgroundImageFileId?: GraphQLTypes["order_by"] | undefined,
		channel?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		displayImageUrl?: GraphQLTypes["order_by"] | undefined,
		generatingDisplayStatus?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		pricingPlanId?: GraphQLTypes["order_by"] | undefined,
		publishDate?: GraphQLTypes["order_by"] | undefined,
		result?: GraphQLTypes["order_by"] | undefined,
		scheduledDate?: GraphQLTypes["order_by"] | undefined,
		scheduledDateEventId?: GraphQLTypes["order_by"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["order_by"] | undefined,
		status?: GraphQLTypes["order_by"] | undefined,
		textPromptTemplateId?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_mutation_response"]: {
		__typename: "GeneratedPromptByTemplate_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["GeneratedPromptByTemplate"]>
	};
	/** on_conflict condition type for table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_on_conflict"]: {
		constraint: GraphQLTypes["GeneratedPromptByTemplate_constraint"],
		update_columns: Array<GraphQLTypes["GeneratedPromptByTemplate_update_column"]>,
		where?: GraphQLTypes["GeneratedPromptByTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "GeneratedPromptByTemplate". */
	["GeneratedPromptByTemplate_order_by"]: {
		GeneratedPromptByTemplateImages_aggregate?: GraphQLTypes["GeneratedPromptByTemplateImage_aggregate_order_by"] | undefined,
		TextPromptTemplate?: GraphQLTypes["TextPromptTemplate_order_by"] | undefined,
		active?: GraphQLTypes["order_by"] | undefined,
		alias?: GraphQLTypes["order_by"] | undefined,
		backgroundImageFileId?: GraphQLTypes["order_by"] | undefined,
		channel?: GraphQLTypes["order_by"] | undefined,
		command?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		displayFormat?: GraphQLTypes["displayFormats_order_by"] | undefined,
		displayImageUrl?: GraphQLTypes["order_by"] | undefined,
		displayMapper?: GraphQLTypes["order_by"] | undefined,
		generatingDisplayStatus?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		pricingPlan?: GraphQLTypes["pricingPlan_order_by"] | undefined,
		pricingPlanId?: GraphQLTypes["order_by"] | undefined,
		publishDate?: GraphQLTypes["order_by"] | undefined,
		result?: GraphQLTypes["order_by"] | undefined,
		scheduledDate?: GraphQLTypes["order_by"] | undefined,
		scheduledDateEventId?: GraphQLTypes["order_by"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["order_by"] | undefined,
		status?: GraphQLTypes["order_by"] | undefined,
		textPromptTemplateId?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: GeneratedPromptByTemplate */
	["GeneratedPromptByTemplate_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["GeneratedPromptByTemplate_prepend_input"]: {
		command?: GraphQLTypes["jsonb"] | undefined,
		displayMapper?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column"]: GeneratedPromptByTemplate_select_column;
	/** select "GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_set_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		backgroundImageFileId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		command?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		displayImageUrl?: string | undefined,
		displayMapper?: GraphQLTypes["jsonb"] | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		pricingPlanId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["GeneratedPromptByTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["GeneratedPromptByTemplate_stream_cursor_value_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		backgroundImageFileId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		command?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		displayImageUrl?: string | undefined,
		displayMapper?: GraphQLTypes["jsonb"] | undefined,
		generatingDisplayStatus?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		pricingPlanId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		result?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		selectedDisplayFormatId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "GeneratedPromptByTemplate" */
	["GeneratedPromptByTemplate_update_column"]: GeneratedPromptByTemplate_update_column;
	["GeneratedPromptByTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["GeneratedPromptByTemplate_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["GeneratedPromptByTemplate_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["GeneratedPromptByTemplate_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["GeneratedPromptByTemplate_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["GeneratedPromptByTemplate_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["GeneratedPromptByTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["GeneratedPromptByTemplate_bool_exp"]
	};
	["GetFacebookPagesOutput"]: {
		__typename: "GetFacebookPagesOutput",
		pages: Array<GraphQLTypes["facebookPage"] | undefined>
	};
	["GetFacebookTokenOutput"]: {
		__typename: "GetFacebookTokenOutput",
		loginUrl: string
	};
	["GetImageFromDisplayRendererOutput"]: {
		__typename: "GetImageFromDisplayRendererOutput",
		image: string
	};
	["GetInstagramAuthCallbackOutput"]: {
		__typename: "GetInstagramAuthCallbackOutput",
		success: boolean
	};
	["GetInstagramPagesOutput"]: {
		__typename: "GetInstagramPagesOutput",
		accounts: Array<GraphQLTypes["instagramPageAccount"] | undefined>
	};
	["GetInstagramTokenOutput"]: {
		__typename: "GetInstagramTokenOutput",
		loginUrl: string
	};
	["GetPaymentUrlCommand"]: {
		email: string,
		price_id: string,
		user_id: string
	};
	["GetPaymentUrlResponse"]: {
		__typename: "GetPaymentUrlResponse",
		url: string
	};
	["GetWordpressCategoriesInput"]: {
		integrationId: string
	};
	["GetWordpressCategoriesOutput"]: {
		__typename: "GetWordpressCategoriesOutput",
		categories?: Array<GraphQLTypes["Category"]> | undefined
	};
	["GetWordpressTagsInput"]: {
		integrationId: string
	};
	["GetWordpressTagsOutput"]: {
		__typename: "GetWordpressTagsOutput",
		tags?: Array<GraphQLTypes["Tag"]> | undefined
	};
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
	["Int_comparison_exp"]: {
		_eq?: number | undefined,
		_gt?: number | undefined,
		_gte?: number | undefined,
		_in?: Array<number> | undefined,
		_is_null?: boolean | undefined,
		_lt?: number | undefined,
		_lte?: number | undefined,
		_neq?: number | undefined,
		_nin?: Array<number> | undefined
	};
	["LinkedInTokenCallbackOutput"]: {
		__typename: "LinkedInTokenCallbackOutput",
		success: boolean
	};
	["ManageSubscriptionOutput"]: {
		__typename: "ManageSubscriptionOutput",
		url: string
	};
	["PexelsImageResult"]: {
		__typename: "PexelsImageResult",
		alt: string,
		height: string,
		id: string,
		photographer: string,
		photographer_url: string,
		small: string,
		url: string,
		width: string
	};
	["PostCarouselToInstagramOutput"]: {
		__typename: "PostCarouselToInstagramOutput",
		error: string,
		success: boolean
	};
	["PostFeedToInstagramOutput"]: {
		__typename: "PostFeedToInstagramOutput",
		error?: string | undefined,
		success: boolean
	};
	["PostStoryToInstagramOutput"]: {
		__typename: "PostStoryToInstagramOutput",
		error: string,
		success: boolean
	};
	["PostToFacebookOutput"]: {
		__typename: "PostToFacebookOutput",
		success: boolean
	};
	["PostToLinkedInOutput"]: {
		__typename: "PostToLinkedInOutput",
		success: boolean
	};
	["PromptOutput"]: {
		__typename: "PromptOutput",
		text: string
	};
	["PublishOrScheduleProjectOutput"]: {
		__typename: "PublishOrScheduleProjectOutput",
		errorMessage?: string | undefined,
		success: boolean
	};
	["RenderAndSaveDisplayImageOutput"]: {
		__typename: "RenderAndSaveDisplayImageOutput",
		image: string
	};
	["RenderDisplayOutput"]: {
		__typename: "RenderDisplayOutput",
		image: string
	};
	["SaveAndGeneratePolotnoImageOutput"]: {
		__typename: "SaveAndGeneratePolotnoImageOutput",
		message?: string | undefined,
		success: boolean
	};
	["SearchImagesFromPexelsResponse"]: {
		__typename: "SearchImagesFromPexelsResponse",
		images?: Array<GraphQLTypes["PexelsImageResult"] | undefined> | undefined
	};
	["SelectNewDisplayOutput"]: {
		__typename: "SelectNewDisplayOutput",
		error?: string | undefined,
		id?: string | undefined
	};
	["SerpStatTestInput"]: {
		domain?: string | undefined
	};
	["SerpStatTestInputData"]: {
		__typename: "SerpStatTestInputData",
		ad_keywords: number,
		ads: number,
		ads_dynamic: number,
		domain: string,
		down_keywords: number,
		keywords: number,
		keywords_dynamic: number,
		new_keywords: number,
		out_keywords: number,
		prev_date: string,
		rised_keywords: number,
		traff: number,
		traff_dynamic: number,
		visible: number,
		visible_dynamic: number
	};
	["SerpStatTestInputResult"]: {
		__typename: "SerpStatTestInputResult",
		data?: Array<GraphQLTypes["SerpStatTestInputData"]> | undefined,
		summary_info: GraphQLTypes["SerpStatTestInputSummaryInfo"]
	};
	["SerpStatTestInputSummaryInfo"]: {
		__typename: "SerpStatTestInputSummaryInfo",
		left_lines: number,
		page: number
	};
	["SerpStatTestOutput"]: {
		__typename: "SerpStatTestOutput",
		data: GraphQLTypes["SerpStatTestOutputData"]
	};
	["SerpStatTestOutputData"]: {
		__typename: "SerpStatTestOutputData",
		id: string,
		result: GraphQLTypes["SerpStatTestInputResult"]
	};
	["StringKeyValuePair"]: {
		key: string,
		value: string
	};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
	["String_array_comparison_exp"]: {
		/** is the array contained in the given array value */
		_contained_in?: Array<string> | undefined,
		/** does the array contain the given value */
		_contains?: Array<string> | undefined,
		_eq?: Array<string> | undefined,
		_gt?: Array<string> | undefined,
		_gte?: Array<string> | undefined,
		_in?: Array<Array<string> | undefined>,
		_is_null?: boolean | undefined,
		_lt?: Array<string> | undefined,
		_lte?: Array<string> | undefined,
		_neq?: Array<string> | undefined,
		_nin?: Array<Array<string> | undefined>
	};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
	["String_comparison_exp"]: {
		_eq?: string | undefined,
		_gt?: string | undefined,
		_gte?: string | undefined,
		/** does the column match the given case-insensitive pattern */
		_ilike?: string | undefined,
		_in?: Array<string> | undefined,
		/** does the column match the given POSIX regular expression, case insensitive */
		_iregex?: string | undefined,
		_is_null?: boolean | undefined,
		/** does the column match the given pattern */
		_like?: string | undefined,
		_lt?: string | undefined,
		_lte?: string | undefined,
		_neq?: string | undefined,
		/** does the column NOT match the given case-insensitive pattern */
		_nilike?: string | undefined,
		_nin?: Array<string> | undefined,
		/** does the column NOT match the given POSIX regular expression, case insensitive */
		_niregex?: string | undefined,
		/** does the column NOT match the given pattern */
		_nlike?: string | undefined,
		/** does the column NOT match the given POSIX regular expression, case sensitive */
		_nregex?: string | undefined,
		/** does the column NOT match the given SQL regular expression */
		_nsimilar?: string | undefined,
		/** does the column match the given POSIX regular expression, case sensitive */
		_regex?: string | undefined,
		/** does the column match the given SQL regular expression */
		_similar?: string | undefined
	};
	["Tag"]: {
		__typename: "Tag",
		id: string,
		name: string
	};
	/** columns and relationships of "TextGeneratorOption" */
	["TextGeneratorOption"]: {
		__typename: "TextGeneratorOption",
		Label?: GraphQLTypes["jsonb"] | undefined,
		/** An array relationship */
		TextGeneratorOptionEntries: Array<GraphQLTypes["TextGeneratorOptionEntry"]>,
		/** An aggregate relationship */
		TextGeneratorOptionEntries_aggregate: GraphQLTypes["TextGeneratorOptionEntry_aggregate"],
		created_at: GraphQLTypes["timestamptz"],
		id: GraphQLTypes["uuid"],
		popover_tooltip?: GraphQLTypes["jsonb"] | undefined,
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** columns and relationships of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry"]: {
		__typename: "TextGeneratorOptionEntry",
		Label?: GraphQLTypes["jsonb"] | undefined,
		/** An object relationship */
		TextGeneratorOption: GraphQLTypes["TextGeneratorOption"],
		TextGeneratorOptionId: GraphQLTypes["uuid"],
		id: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate"]: {
		__typename: "TextGeneratorOptionEntry_aggregate",
		aggregate?: GraphQLTypes["TextGeneratorOptionEntry_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["TextGeneratorOptionEntry"]>
	};
	["TextGeneratorOptionEntry_aggregate_bool_exp"]: {
		count?: GraphQLTypes["TextGeneratorOptionEntry_aggregate_bool_exp_count"] | undefined
	};
	["TextGeneratorOptionEntry_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["TextGeneratorOptionEntry_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["TextGeneratorOptionEntry_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate_fields"]: {
		__typename: "TextGeneratorOptionEntry_aggregate_fields",
		count: number,
		max?: GraphQLTypes["TextGeneratorOptionEntry_max_fields"] | undefined,
		min?: GraphQLTypes["TextGeneratorOptionEntry_min_fields"] | undefined
	};
	/** order by aggregate values of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["TextGeneratorOptionEntry_max_order_by"] | undefined,
		min?: GraphQLTypes["TextGeneratorOptionEntry_min_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOptionEntry_append_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["TextGeneratorOptionEntry_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["TextGeneratorOptionEntry_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextGeneratorOptionEntry". All fields are combined with a logical 'AND'. */
	["TextGeneratorOptionEntry_bool_exp"]: {
		Label?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		TextGeneratorOption?: GraphQLTypes["TextGeneratorOption_bool_exp"] | undefined,
		TextGeneratorOptionId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		_and?: Array<GraphQLTypes["TextGeneratorOptionEntry_bool_exp"]> | undefined,
		_not?: GraphQLTypes["TextGeneratorOptionEntry_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["TextGeneratorOptionEntry_bool_exp"]> | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_constraint"]: TextGeneratorOptionEntry_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextGeneratorOptionEntry_delete_at_path_input"]: {
		Label?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextGeneratorOptionEntry_delete_elem_input"]: {
		Label?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextGeneratorOptionEntry_delete_key_input"]: {
		Label?: string | undefined
	};
	/** input type for inserting data into table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_insert_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined,
		TextGeneratorOption?: GraphQLTypes["TextGeneratorOption_obj_rel_insert_input"] | undefined,
		TextGeneratorOptionId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["TextGeneratorOptionEntry_max_fields"]: {
		__typename: "TextGeneratorOptionEntry_max_fields",
		TextGeneratorOptionId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_max_order_by"]: {
		TextGeneratorOptionId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["TextGeneratorOptionEntry_min_fields"]: {
		__typename: "TextGeneratorOptionEntry_min_fields",
		TextGeneratorOptionId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_min_order_by"]: {
		TextGeneratorOptionId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_mutation_response"]: {
		__typename: "TextGeneratorOptionEntry_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["TextGeneratorOptionEntry"]>
	};
	/** on_conflict condition type for table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_on_conflict"]: {
		constraint: GraphQLTypes["TextGeneratorOptionEntry_constraint"],
		update_columns: Array<GraphQLTypes["TextGeneratorOptionEntry_update_column"]>,
		where?: GraphQLTypes["TextGeneratorOptionEntry_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextGeneratorOptionEntry". */
	["TextGeneratorOptionEntry_order_by"]: {
		Label?: GraphQLTypes["order_by"] | undefined,
		TextGeneratorOption?: GraphQLTypes["TextGeneratorOption_order_by"] | undefined,
		TextGeneratorOptionId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextGeneratorOptionEntry */
	["TextGeneratorOptionEntry_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOptionEntry_prepend_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_select_column"]: TextGeneratorOptionEntry_select_column;
	/** input type for updating data in table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_set_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined,
		TextGeneratorOptionId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["TextGeneratorOptionEntry_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextGeneratorOptionEntry_stream_cursor_value_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined,
		TextGeneratorOptionId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "TextGeneratorOptionEntry" */
	["TextGeneratorOptionEntry_update_column"]: TextGeneratorOptionEntry_update_column;
	["TextGeneratorOptionEntry_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["TextGeneratorOptionEntry_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["TextGeneratorOptionEntry_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["TextGeneratorOptionEntry_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["TextGeneratorOptionEntry_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["TextGeneratorOptionEntry_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["TextGeneratorOptionEntry_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["TextGeneratorOptionEntry_bool_exp"]
	};
	/** aggregated selection of "TextGeneratorOption" */
	["TextGeneratorOption_aggregate"]: {
		__typename: "TextGeneratorOption_aggregate",
		aggregate?: GraphQLTypes["TextGeneratorOption_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["TextGeneratorOption"]>
	};
	/** aggregate fields of "TextGeneratorOption" */
	["TextGeneratorOption_aggregate_fields"]: {
		__typename: "TextGeneratorOption_aggregate_fields",
		count: number,
		max?: GraphQLTypes["TextGeneratorOption_max_fields"] | undefined,
		min?: GraphQLTypes["TextGeneratorOption_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOption_append_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined,
		popover_tooltip?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextGeneratorOption". All fields are combined with a logical 'AND'. */
	["TextGeneratorOption_bool_exp"]: {
		Label?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		TextGeneratorOptionEntries?: GraphQLTypes["TextGeneratorOptionEntry_bool_exp"] | undefined,
		TextGeneratorOptionEntries_aggregate?: GraphQLTypes["TextGeneratorOptionEntry_aggregate_bool_exp"] | undefined,
		_and?: Array<GraphQLTypes["TextGeneratorOption_bool_exp"]> | undefined,
		_not?: GraphQLTypes["TextGeneratorOption_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["TextGeneratorOption_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		popover_tooltip?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "TextGeneratorOption" */
	["TextGeneratorOption_constraint"]: TextGeneratorOption_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextGeneratorOption_delete_at_path_input"]: {
		Label?: Array<string> | undefined,
		popover_tooltip?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextGeneratorOption_delete_elem_input"]: {
		Label?: number | undefined,
		popover_tooltip?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextGeneratorOption_delete_key_input"]: {
		Label?: string | undefined,
		popover_tooltip?: string | undefined
	};
	/** input type for inserting data into table "TextGeneratorOption" */
	["TextGeneratorOption_insert_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined,
		TextGeneratorOptionEntries?: GraphQLTypes["TextGeneratorOptionEntry_arr_rel_insert_input"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		popover_tooltip?: GraphQLTypes["jsonb"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["TextGeneratorOption_max_fields"]: {
		__typename: "TextGeneratorOption_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["TextGeneratorOption_min_fields"]: {
		__typename: "TextGeneratorOption_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "TextGeneratorOption" */
	["TextGeneratorOption_mutation_response"]: {
		__typename: "TextGeneratorOption_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["TextGeneratorOption"]>
	};
	/** input type for inserting object relation for remote table "TextGeneratorOption" */
	["TextGeneratorOption_obj_rel_insert_input"]: {
		data: GraphQLTypes["TextGeneratorOption_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["TextGeneratorOption_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "TextGeneratorOption" */
	["TextGeneratorOption_on_conflict"]: {
		constraint: GraphQLTypes["TextGeneratorOption_constraint"],
		update_columns: Array<GraphQLTypes["TextGeneratorOption_update_column"]>,
		where?: GraphQLTypes["TextGeneratorOption_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextGeneratorOption". */
	["TextGeneratorOption_order_by"]: {
		Label?: GraphQLTypes["order_by"] | undefined,
		TextGeneratorOptionEntries_aggregate?: GraphQLTypes["TextGeneratorOptionEntry_aggregate_order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		popover_tooltip?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextGeneratorOption */
	["TextGeneratorOption_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextGeneratorOption_prepend_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined,
		popover_tooltip?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "TextGeneratorOption" */
	["TextGeneratorOption_select_column"]: TextGeneratorOption_select_column;
	/** input type for updating data in table "TextGeneratorOption" */
	["TextGeneratorOption_set_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		popover_tooltip?: GraphQLTypes["jsonb"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "TextGeneratorOption" */
	["TextGeneratorOption_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["TextGeneratorOption_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextGeneratorOption_stream_cursor_value_input"]: {
		Label?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		popover_tooltip?: GraphQLTypes["jsonb"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "TextGeneratorOption" */
	["TextGeneratorOption_update_column"]: TextGeneratorOption_update_column;
	["TextGeneratorOption_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["TextGeneratorOption_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["TextGeneratorOption_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["TextGeneratorOption_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["TextGeneratorOption_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["TextGeneratorOption_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["TextGeneratorOption_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["TextGeneratorOption_bool_exp"]
	};
	/** columns and relationships of "TextPromptTemplate" */
	["TextPromptTemplate"]: {
		__typename: "TextPromptTemplate",
		/** An array relationship */
		GeneratedPromptByTemplates: Array<GraphQLTypes["GeneratedPromptByTemplate"]>,
		/** An aggregate relationship */
		GeneratedPromptByTemplates_aggregate: GraphQLTypes["GeneratedPromptByTemplate_aggregate"],
		/** An object relationship */
		TextPromptTemplateCategory?: GraphQLTypes["TextPromptTemplateCategory"] | undefined,
		/** An object relationship */
		TextTemplateClientCategory?: GraphQLTypes["TextTemplateClientCategory"] | undefined,
		TextTemplateClientCategoryId?: GraphQLTypes["uuid"] | undefined,
		/** An array relationship */
		availableDisplays: Array<GraphQLTypes["textTemplateDisplayFormat"]>,
		/** An aggregate relationship */
		availableDisplays_aggregate: GraphQLTypes["textTemplateDisplayFormat_aggregate"],
		categoryId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		definitions: GraphQLTypes["jsonb"],
		description: string,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		flexible: boolean,
		icon: string,
		id: GraphQLTypes["uuid"],
		marketPriceInCredits?: GraphQLTypes["numeric"] | undefined,
		name: string,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** columns and relationships of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory"]: {
		__typename: "TextPromptTemplateCategory",
		/** An array relationship */
		TextPromptTemplates: Array<GraphQLTypes["TextPromptTemplate"]>,
		/** An aggregate relationship */
		TextPromptTemplates_aggregate: GraphQLTypes["TextPromptTemplate_aggregate"],
		categoryName: string,
		id: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_aggregate"]: {
		__typename: "TextPromptTemplateCategory_aggregate",
		aggregate?: GraphQLTypes["TextPromptTemplateCategory_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["TextPromptTemplateCategory"]>
	};
	/** aggregate fields of "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_aggregate_fields"]: {
		__typename: "TextPromptTemplateCategory_aggregate_fields",
		count: number,
		max?: GraphQLTypes["TextPromptTemplateCategory_max_fields"] | undefined,
		min?: GraphQLTypes["TextPromptTemplateCategory_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextPromptTemplateCategory". All fields are combined with a logical 'AND'. */
	["TextPromptTemplateCategory_bool_exp"]: {
		TextPromptTemplates?: GraphQLTypes["TextPromptTemplate_bool_exp"] | undefined,
		TextPromptTemplates_aggregate?: GraphQLTypes["TextPromptTemplate_aggregate_bool_exp"] | undefined,
		_and?: Array<GraphQLTypes["TextPromptTemplateCategory_bool_exp"]> | undefined,
		_not?: GraphQLTypes["TextPromptTemplateCategory_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["TextPromptTemplateCategory_bool_exp"]> | undefined,
		categoryName?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_constraint"]: TextPromptTemplateCategory_constraint;
	/** input type for inserting data into table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_insert_input"]: {
		TextPromptTemplates?: GraphQLTypes["TextPromptTemplate_arr_rel_insert_input"] | undefined,
		categoryName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["TextPromptTemplateCategory_max_fields"]: {
		__typename: "TextPromptTemplateCategory_max_fields",
		categoryName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["TextPromptTemplateCategory_min_fields"]: {
		__typename: "TextPromptTemplateCategory_min_fields",
		categoryName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_mutation_response"]: {
		__typename: "TextPromptTemplateCategory_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["TextPromptTemplateCategory"]>
	};
	/** input type for inserting object relation for remote table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_obj_rel_insert_input"]: {
		data: GraphQLTypes["TextPromptTemplateCategory_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["TextPromptTemplateCategory_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_on_conflict"]: {
		constraint: GraphQLTypes["TextPromptTemplateCategory_constraint"],
		update_columns: Array<GraphQLTypes["TextPromptTemplateCategory_update_column"]>,
		where?: GraphQLTypes["TextPromptTemplateCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextPromptTemplateCategory". */
	["TextPromptTemplateCategory_order_by"]: {
		TextPromptTemplates_aggregate?: GraphQLTypes["TextPromptTemplate_aggregate_order_by"] | undefined,
		categoryName?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextPromptTemplateCategory */
	["TextPromptTemplateCategory_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_select_column"]: TextPromptTemplateCategory_select_column;
	/** input type for updating data in table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_set_input"]: {
		categoryName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["TextPromptTemplateCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextPromptTemplateCategory_stream_cursor_value_input"]: {
		categoryName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "TextPromptTemplateCategory" */
	["TextPromptTemplateCategory_update_column"]: TextPromptTemplateCategory_update_column;
	["TextPromptTemplateCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["TextPromptTemplateCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["TextPromptTemplateCategory_bool_exp"]
	};
	/** aggregated selection of "TextPromptTemplate" */
	["TextPromptTemplate_aggregate"]: {
		__typename: "TextPromptTemplate_aggregate",
		aggregate?: GraphQLTypes["TextPromptTemplate_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["TextPromptTemplate"]>
	};
	["TextPromptTemplate_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["TextPromptTemplate_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: GraphQLTypes["TextPromptTemplate_aggregate_bool_exp_bool_or"] | undefined,
		count?: GraphQLTypes["TextPromptTemplate_aggregate_bool_exp_count"] | undefined
	};
	["TextPromptTemplate_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["TextPromptTemplate_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["TextPromptTemplate_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["TextPromptTemplate_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["TextPromptTemplate_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["TextPromptTemplate_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["TextPromptTemplate_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "TextPromptTemplate" */
	["TextPromptTemplate_aggregate_fields"]: {
		__typename: "TextPromptTemplate_aggregate_fields",
		avg?: GraphQLTypes["TextPromptTemplate_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["TextPromptTemplate_max_fields"] | undefined,
		min?: GraphQLTypes["TextPromptTemplate_min_fields"] | undefined,
		stddev?: GraphQLTypes["TextPromptTemplate_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["TextPromptTemplate_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["TextPromptTemplate_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["TextPromptTemplate_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["TextPromptTemplate_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["TextPromptTemplate_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["TextPromptTemplate_variance_fields"] | undefined
	};
	/** order by aggregate values of table "TextPromptTemplate" */
	["TextPromptTemplate_aggregate_order_by"]: {
		avg?: GraphQLTypes["TextPromptTemplate_avg_order_by"] | undefined,
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["TextPromptTemplate_max_order_by"] | undefined,
		min?: GraphQLTypes["TextPromptTemplate_min_order_by"] | undefined,
		stddev?: GraphQLTypes["TextPromptTemplate_stddev_order_by"] | undefined,
		stddev_pop?: GraphQLTypes["TextPromptTemplate_stddev_pop_order_by"] | undefined,
		stddev_samp?: GraphQLTypes["TextPromptTemplate_stddev_samp_order_by"] | undefined,
		sum?: GraphQLTypes["TextPromptTemplate_sum_order_by"] | undefined,
		var_pop?: GraphQLTypes["TextPromptTemplate_var_pop_order_by"] | undefined,
		var_samp?: GraphQLTypes["TextPromptTemplate_var_samp_order_by"] | undefined,
		variance?: GraphQLTypes["TextPromptTemplate_variance_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["TextPromptTemplate_append_input"]: {
		definitions?: GraphQLTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "TextPromptTemplate" */
	["TextPromptTemplate_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["TextPromptTemplate_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["TextPromptTemplate_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["TextPromptTemplate_avg_fields"]: {
		__typename: "TextPromptTemplate_avg_fields",
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by avg() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_avg_order_by"]: {
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextPromptTemplate". All fields are combined with a logical 'AND'. */
	["TextPromptTemplate_bool_exp"]: {
		GeneratedPromptByTemplates?: GraphQLTypes["GeneratedPromptByTemplate_bool_exp"] | undefined,
		GeneratedPromptByTemplates_aggregate?: GraphQLTypes["GeneratedPromptByTemplate_aggregate_bool_exp"] | undefined,
		TextPromptTemplateCategory?: GraphQLTypes["TextPromptTemplateCategory_bool_exp"] | undefined,
		TextTemplateClientCategory?: GraphQLTypes["TextTemplateClientCategory_bool_exp"] | undefined,
		TextTemplateClientCategoryId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		_and?: Array<GraphQLTypes["TextPromptTemplate_bool_exp"]> | undefined,
		_not?: GraphQLTypes["TextPromptTemplate_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["TextPromptTemplate_bool_exp"]> | undefined,
		availableDisplays?: GraphQLTypes["textTemplateDisplayFormat_bool_exp"] | undefined,
		availableDisplays_aggregate?: GraphQLTypes["textTemplateDisplayFormat_aggregate_bool_exp"] | undefined,
		categoryId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		channel?: GraphQLTypes["String_comparison_exp"] | undefined,
		creditPrice?: GraphQLTypes["Int_comparison_exp"] | undefined,
		definitions?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		description?: GraphQLTypes["String_comparison_exp"] | undefined,
		displayDimensionId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		flexible?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		icon?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		marketPriceInCredits?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined,
		newTemplateText?: GraphQLTypes["String_comparison_exp"] | undefined,
		saleCreditPrice?: GraphQLTypes["Int_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "TextPromptTemplate" */
	["TextPromptTemplate_constraint"]: TextPromptTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["TextPromptTemplate_delete_at_path_input"]: {
		definitions?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["TextPromptTemplate_delete_elem_input"]: {
		definitions?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["TextPromptTemplate_delete_key_input"]: {
		definitions?: string | undefined
	};
	/** input type for incrementing numeric columns in table "TextPromptTemplate" */
	["TextPromptTemplate_inc_input"]: {
		creditPrice?: number | undefined,
		marketPriceInCredits?: GraphQLTypes["numeric"] | undefined,
		saleCreditPrice?: number | undefined
	};
	/** input type for inserting data into table "TextPromptTemplate" */
	["TextPromptTemplate_insert_input"]: {
		GeneratedPromptByTemplates?: GraphQLTypes["GeneratedPromptByTemplate_arr_rel_insert_input"] | undefined,
		TextPromptTemplateCategory?: GraphQLTypes["TextPromptTemplateCategory_obj_rel_insert_input"] | undefined,
		TextTemplateClientCategory?: GraphQLTypes["TextTemplateClientCategory_obj_rel_insert_input"] | undefined,
		TextTemplateClientCategoryId?: GraphQLTypes["uuid"] | undefined,
		availableDisplays?: GraphQLTypes["textTemplateDisplayFormat_arr_rel_insert_input"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		definitions?: GraphQLTypes["jsonb"] | undefined,
		description?: string | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		flexible?: boolean | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		marketPriceInCredits?: GraphQLTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate max on columns */
	["TextPromptTemplate_max_fields"]: {
		__typename: "TextPromptTemplate_max_fields",
		TextTemplateClientCategoryId?: GraphQLTypes["uuid"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		marketPriceInCredits?: GraphQLTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by max() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_max_order_by"]: {
		TextTemplateClientCategoryId?: GraphQLTypes["order_by"] | undefined,
		categoryId?: GraphQLTypes["order_by"] | undefined,
		channel?: GraphQLTypes["order_by"] | undefined,
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		displayDimensionId?: GraphQLTypes["order_by"] | undefined,
		icon?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		newTemplateText?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["TextPromptTemplate_min_fields"]: {
		__typename: "TextPromptTemplate_min_fields",
		TextTemplateClientCategoryId?: GraphQLTypes["uuid"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		marketPriceInCredits?: GraphQLTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by min() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_min_order_by"]: {
		TextTemplateClientCategoryId?: GraphQLTypes["order_by"] | undefined,
		categoryId?: GraphQLTypes["order_by"] | undefined,
		channel?: GraphQLTypes["order_by"] | undefined,
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		displayDimensionId?: GraphQLTypes["order_by"] | undefined,
		icon?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		newTemplateText?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "TextPromptTemplate" */
	["TextPromptTemplate_mutation_response"]: {
		__typename: "TextPromptTemplate_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["TextPromptTemplate"]>
	};
	/** input type for inserting object relation for remote table "TextPromptTemplate" */
	["TextPromptTemplate_obj_rel_insert_input"]: {
		data: GraphQLTypes["TextPromptTemplate_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["TextPromptTemplate_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "TextPromptTemplate" */
	["TextPromptTemplate_on_conflict"]: {
		constraint: GraphQLTypes["TextPromptTemplate_constraint"],
		update_columns: Array<GraphQLTypes["TextPromptTemplate_update_column"]>,
		where?: GraphQLTypes["TextPromptTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextPromptTemplate". */
	["TextPromptTemplate_order_by"]: {
		GeneratedPromptByTemplates_aggregate?: GraphQLTypes["GeneratedPromptByTemplate_aggregate_order_by"] | undefined,
		TextPromptTemplateCategory?: GraphQLTypes["TextPromptTemplateCategory_order_by"] | undefined,
		TextTemplateClientCategory?: GraphQLTypes["TextTemplateClientCategory_order_by"] | undefined,
		TextTemplateClientCategoryId?: GraphQLTypes["order_by"] | undefined,
		availableDisplays_aggregate?: GraphQLTypes["textTemplateDisplayFormat_aggregate_order_by"] | undefined,
		categoryId?: GraphQLTypes["order_by"] | undefined,
		channel?: GraphQLTypes["order_by"] | undefined,
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		definitions?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		displayDimensionId?: GraphQLTypes["order_by"] | undefined,
		flexible?: GraphQLTypes["order_by"] | undefined,
		icon?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		newTemplateText?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextPromptTemplate */
	["TextPromptTemplate_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["TextPromptTemplate_prepend_input"]: {
		definitions?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column"]: TextPromptTemplate_select_column;
	/** select "TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TextPromptTemplate" */
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "TextPromptTemplate" */
	["TextPromptTemplate_set_input"]: {
		TextTemplateClientCategoryId?: GraphQLTypes["uuid"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		definitions?: GraphQLTypes["jsonb"] | undefined,
		description?: string | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		flexible?: boolean | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		marketPriceInCredits?: GraphQLTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate stddev on columns */
	["TextPromptTemplate_stddev_fields"]: {
		__typename: "TextPromptTemplate_stddev_fields",
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by stddev() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_order_by"]: {
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["TextPromptTemplate_stddev_pop_fields"]: {
		__typename: "TextPromptTemplate_stddev_pop_fields",
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by stddev_pop() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_pop_order_by"]: {
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["TextPromptTemplate_stddev_samp_fields"]: {
		__typename: "TextPromptTemplate_stddev_samp_fields",
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by stddev_samp() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_stddev_samp_order_by"]: {
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "TextPromptTemplate" */
	["TextPromptTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["TextPromptTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextPromptTemplate_stream_cursor_value_input"]: {
		TextTemplateClientCategoryId?: GraphQLTypes["uuid"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		channel?: string | undefined,
		creditPrice?: number | undefined,
		definitions?: GraphQLTypes["jsonb"] | undefined,
		description?: string | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		flexible?: boolean | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		marketPriceInCredits?: GraphQLTypes["numeric"] | undefined,
		name?: string | undefined,
		newTemplateText?: string | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate sum on columns */
	["TextPromptTemplate_sum_fields"]: {
		__typename: "TextPromptTemplate_sum_fields",
		creditPrice?: number | undefined,
		marketPriceInCredits?: GraphQLTypes["numeric"] | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by sum() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_sum_order_by"]: {
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** update columns of table "TextPromptTemplate" */
	["TextPromptTemplate_update_column"]: TextPromptTemplate_update_column;
	["TextPromptTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["TextPromptTemplate_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["TextPromptTemplate_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["TextPromptTemplate_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["TextPromptTemplate_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["TextPromptTemplate_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["TextPromptTemplate_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["TextPromptTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["TextPromptTemplate_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["TextPromptTemplate_var_pop_fields"]: {
		__typename: "TextPromptTemplate_var_pop_fields",
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by var_pop() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_var_pop_order_by"]: {
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["TextPromptTemplate_var_samp_fields"]: {
		__typename: "TextPromptTemplate_var_samp_fields",
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by var_samp() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_var_samp_order_by"]: {
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["TextPromptTemplate_variance_fields"]: {
		__typename: "TextPromptTemplate_variance_fields",
		creditPrice?: number | undefined,
		marketPriceInCredits?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** order by variance() on columns of table "TextPromptTemplate" */
	["TextPromptTemplate_variance_order_by"]: {
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		marketPriceInCredits?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined
	};
	/** columns and relationships of "TextTemplateClientCategory" */
	["TextTemplateClientCategory"]: {
		__typename: "TextTemplateClientCategory",
		displayName: string,
		iconClass: string,
		id: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "TextTemplateClientCategory" */
	["TextTemplateClientCategory_aggregate"]: {
		__typename: "TextTemplateClientCategory_aggregate",
		aggregate?: GraphQLTypes["TextTemplateClientCategory_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["TextTemplateClientCategory"]>
	};
	/** aggregate fields of "TextTemplateClientCategory" */
	["TextTemplateClientCategory_aggregate_fields"]: {
		__typename: "TextTemplateClientCategory_aggregate_fields",
		count: number,
		max?: GraphQLTypes["TextTemplateClientCategory_max_fields"] | undefined,
		min?: GraphQLTypes["TextTemplateClientCategory_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "TextTemplateClientCategory". All fields are combined with a logical 'AND'. */
	["TextTemplateClientCategory_bool_exp"]: {
		_and?: Array<GraphQLTypes["TextTemplateClientCategory_bool_exp"]> | undefined,
		_not?: GraphQLTypes["TextTemplateClientCategory_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["TextTemplateClientCategory_bool_exp"]> | undefined,
		displayName?: GraphQLTypes["String_comparison_exp"] | undefined,
		iconClass?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_constraint"]: TextTemplateClientCategory_constraint;
	/** input type for inserting data into table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_insert_input"]: {
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["TextTemplateClientCategory_max_fields"]: {
		__typename: "TextTemplateClientCategory_max_fields",
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["TextTemplateClientCategory_min_fields"]: {
		__typename: "TextTemplateClientCategory_min_fields",
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_mutation_response"]: {
		__typename: "TextTemplateClientCategory_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["TextTemplateClientCategory"]>
	};
	/** input type for inserting object relation for remote table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_obj_rel_insert_input"]: {
		data: GraphQLTypes["TextTemplateClientCategory_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["TextTemplateClientCategory_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_on_conflict"]: {
		constraint: GraphQLTypes["TextTemplateClientCategory_constraint"],
		update_columns: Array<GraphQLTypes["TextTemplateClientCategory_update_column"]>,
		where?: GraphQLTypes["TextTemplateClientCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "TextTemplateClientCategory". */
	["TextTemplateClientCategory_order_by"]: {
		displayName?: GraphQLTypes["order_by"] | undefined,
		iconClass?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: TextTemplateClientCategory */
	["TextTemplateClientCategory_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_select_column"]: TextTemplateClientCategory_select_column;
	/** input type for updating data in table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_set_input"]: {
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["TextTemplateClientCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["TextTemplateClientCategory_stream_cursor_value_input"]: {
		displayName?: string | undefined,
		iconClass?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "TextTemplateClientCategory" */
	["TextTemplateClientCategory_update_column"]: TextTemplateClientCategory_update_column;
	["TextTemplateClientCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["TextTemplateClientCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["TextTemplateClientCategory_bool_exp"]
	};
	["UpdateUserAvatarOutput"]: {
		__typename: "UpdateUserAvatarOutput",
		message?: string | undefined,
		photoURL?: string | undefined,
		success: boolean
	};
	["UpsertAudienceOutput"]: {
		__typename: "UpsertAudienceOutput",
		brandName?: string | undefined,
		id: string,
		personas?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined
	};
	/** columns and relationships of "audiences" */
	["audiences"]: {
		__typename: "audiences",
		brandId?: GraphQLTypes["uuid"] | undefined,
		brandName: string,
		created_at: GraphQLTypes["timestamp"],
		created_by: GraphQLTypes["uuid"],
		deleted: boolean,
		id: GraphQLTypes["uuid"],
		personas?: GraphQLTypes["jsonb"] | undefined,
		productOrService: string,
		segment: string,
		targetAudience: string,
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** aggregated selection of "audiences" */
	["audiences_aggregate"]: {
		__typename: "audiences_aggregate",
		aggregate?: GraphQLTypes["audiences_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["audiences"]>
	};
	/** aggregate fields of "audiences" */
	["audiences_aggregate_fields"]: {
		__typename: "audiences_aggregate_fields",
		count: number,
		max?: GraphQLTypes["audiences_max_fields"] | undefined,
		min?: GraphQLTypes["audiences_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["audiences_append_input"]: {
		personas?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "audiences". All fields are combined with a logical 'AND'. */
	["audiences_bool_exp"]: {
		_and?: Array<GraphQLTypes["audiences_bool_exp"]> | undefined,
		_not?: GraphQLTypes["audiences_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["audiences_bool_exp"]> | undefined,
		brandId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		brandName?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamp_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		deleted?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		personas?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		productOrService?: GraphQLTypes["String_comparison_exp"] | undefined,
		segment?: GraphQLTypes["String_comparison_exp"] | undefined,
		targetAudience?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "audiences" */
	["audiences_constraint"]: audiences_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["audiences_delete_at_path_input"]: {
		personas?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["audiences_delete_elem_input"]: {
		personas?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["audiences_delete_key_input"]: {
		personas?: string | undefined
	};
	/** input type for inserting data into table "audiences" */
	["audiences_insert_input"]: {
		brandId?: GraphQLTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		personas?: GraphQLTypes["jsonb"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["audiences_max_fields"]: {
		__typename: "audiences_max_fields",
		brandId?: GraphQLTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["audiences_min_fields"]: {
		__typename: "audiences_min_fields",
		brandId?: GraphQLTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "audiences" */
	["audiences_mutation_response"]: {
		__typename: "audiences_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["audiences"]>
	};
	/** on_conflict condition type for table "audiences" */
	["audiences_on_conflict"]: {
		constraint: GraphQLTypes["audiences_constraint"],
		update_columns: Array<GraphQLTypes["audiences_update_column"]>,
		where?: GraphQLTypes["audiences_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "audiences". */
	["audiences_order_by"]: {
		brandId?: GraphQLTypes["order_by"] | undefined,
		brandName?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		deleted?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		personas?: GraphQLTypes["order_by"] | undefined,
		productOrService?: GraphQLTypes["order_by"] | undefined,
		segment?: GraphQLTypes["order_by"] | undefined,
		targetAudience?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: audiences */
	["audiences_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["audiences_prepend_input"]: {
		personas?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "audiences" */
	["audiences_select_column"]: audiences_select_column;
	/** input type for updating data in table "audiences" */
	["audiences_set_input"]: {
		brandId?: GraphQLTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		personas?: GraphQLTypes["jsonb"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "audiences" */
	["audiences_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["audiences_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["audiences_stream_cursor_value_input"]: {
		brandId?: GraphQLTypes["uuid"] | undefined,
		brandName?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		personas?: GraphQLTypes["jsonb"] | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		targetAudience?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "audiences" */
	["audiences_update_column"]: audiences_update_column;
	["audiences_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["audiences_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["audiences_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["audiences_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["audiences_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["audiences_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["audiences_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["audiences_bool_exp"]
	};
	/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authProviderRequests"]: {
		__typename: "authProviderRequests",
		id: GraphQLTypes["uuid"],
		options?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregated selection of "auth.provider_requests" */
	["authProviderRequests_aggregate"]: {
		__typename: "authProviderRequests_aggregate",
		aggregate?: GraphQLTypes["authProviderRequests_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["authProviderRequests"]>
	};
	/** aggregate fields of "auth.provider_requests" */
	["authProviderRequests_aggregate_fields"]: {
		__typename: "authProviderRequests_aggregate_fields",
		count: number,
		max?: GraphQLTypes["authProviderRequests_max_fields"] | undefined,
		min?: GraphQLTypes["authProviderRequests_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["authProviderRequests_append_input"]: {
		options?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
	["authProviderRequests_bool_exp"]: {
		_and?: Array<GraphQLTypes["authProviderRequests_bool_exp"]> | undefined,
		_not?: GraphQLTypes["authProviderRequests_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["authProviderRequests_bool_exp"]> | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		options?: GraphQLTypes["jsonb_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.provider_requests" */
	["authProviderRequests_constraint"]: authProviderRequests_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["authProviderRequests_delete_at_path_input"]: {
		options?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["authProviderRequests_delete_elem_input"]: {
		options?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["authProviderRequests_delete_key_input"]: {
		options?: string | undefined
	};
	/** input type for inserting data into table "auth.provider_requests" */
	["authProviderRequests_insert_input"]: {
		id?: GraphQLTypes["uuid"] | undefined,
		options?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregate max on columns */
	["authProviderRequests_max_fields"]: {
		__typename: "authProviderRequests_max_fields",
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["authProviderRequests_min_fields"]: {
		__typename: "authProviderRequests_min_fields",
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "auth.provider_requests" */
	["authProviderRequests_mutation_response"]: {
		__typename: "authProviderRequests_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["authProviderRequests"]>
	};
	/** on_conflict condition type for table "auth.provider_requests" */
	["authProviderRequests_on_conflict"]: {
		constraint: GraphQLTypes["authProviderRequests_constraint"],
		update_columns: Array<GraphQLTypes["authProviderRequests_update_column"]>,
		where?: GraphQLTypes["authProviderRequests_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.provider_requests". */
	["authProviderRequests_order_by"]: {
		id?: GraphQLTypes["order_by"] | undefined,
		options?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.provider_requests */
	["authProviderRequests_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["authProviderRequests_prepend_input"]: {
		options?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "auth.provider_requests" */
	["authProviderRequests_select_column"]: authProviderRequests_select_column;
	/** input type for updating data in table "auth.provider_requests" */
	["authProviderRequests_set_input"]: {
		id?: GraphQLTypes["uuid"] | undefined,
		options?: GraphQLTypes["jsonb"] | undefined
	};
	/** Streaming cursor of the table "authProviderRequests" */
	["authProviderRequests_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["authProviderRequests_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authProviderRequests_stream_cursor_value_input"]: {
		id?: GraphQLTypes["uuid"] | undefined,
		options?: GraphQLTypes["jsonb"] | undefined
	};
	/** update columns of table "auth.provider_requests" */
	["authProviderRequests_update_column"]: authProviderRequests_update_column;
	["authProviderRequests_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["authProviderRequests_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["authProviderRequests_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["authProviderRequests_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["authProviderRequests_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["authProviderRequests_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["authProviderRequests_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["authProviderRequests_bool_exp"]
	};
	/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authProviders"]: {
		__typename: "authProviders",
		id: string,
		/** An array relationship */
		userProviders: Array<GraphQLTypes["authUserProviders"]>,
		/** An aggregate relationship */
		userProviders_aggregate: GraphQLTypes["authUserProviders_aggregate"]
	};
	/** aggregated selection of "auth.providers" */
	["authProviders_aggregate"]: {
		__typename: "authProviders_aggregate",
		aggregate?: GraphQLTypes["authProviders_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["authProviders"]>
	};
	/** aggregate fields of "auth.providers" */
	["authProviders_aggregate_fields"]: {
		__typename: "authProviders_aggregate_fields",
		count: number,
		max?: GraphQLTypes["authProviders_max_fields"] | undefined,
		min?: GraphQLTypes["authProviders_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
	["authProviders_bool_exp"]: {
		_and?: Array<GraphQLTypes["authProviders_bool_exp"]> | undefined,
		_not?: GraphQLTypes["authProviders_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["authProviders_bool_exp"]> | undefined,
		id?: GraphQLTypes["String_comparison_exp"] | undefined,
		userProviders?: GraphQLTypes["authUserProviders_bool_exp"] | undefined,
		userProviders_aggregate?: GraphQLTypes["authUserProviders_aggregate_bool_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.providers" */
	["authProviders_constraint"]: authProviders_constraint;
	/** input type for inserting data into table "auth.providers" */
	["authProviders_insert_input"]: {
		id?: string | undefined,
		userProviders?: GraphQLTypes["authUserProviders_arr_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["authProviders_max_fields"]: {
		__typename: "authProviders_max_fields",
		id?: string | undefined
	};
	/** aggregate min on columns */
	["authProviders_min_fields"]: {
		__typename: "authProviders_min_fields",
		id?: string | undefined
	};
	/** response of any mutation on the table "auth.providers" */
	["authProviders_mutation_response"]: {
		__typename: "authProviders_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["authProviders"]>
	};
	/** input type for inserting object relation for remote table "auth.providers" */
	["authProviders_obj_rel_insert_input"]: {
		data: GraphQLTypes["authProviders_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["authProviders_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "auth.providers" */
	["authProviders_on_conflict"]: {
		constraint: GraphQLTypes["authProviders_constraint"],
		update_columns: Array<GraphQLTypes["authProviders_update_column"]>,
		where?: GraphQLTypes["authProviders_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.providers". */
	["authProviders_order_by"]: {
		id?: GraphQLTypes["order_by"] | undefined,
		userProviders_aggregate?: GraphQLTypes["authUserProviders_aggregate_order_by"] | undefined
	};
	/** primary key columns input for table: auth.providers */
	["authProviders_pk_columns_input"]: {
		id: string
	};
	/** select columns of table "auth.providers" */
	["authProviders_select_column"]: authProviders_select_column;
	/** input type for updating data in table "auth.providers" */
	["authProviders_set_input"]: {
		id?: string | undefined
	};
	/** Streaming cursor of the table "authProviders" */
	["authProviders_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["authProviders_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authProviders_stream_cursor_value_input"]: {
		id?: string | undefined
	};
	/** update columns of table "auth.providers" */
	["authProviders_update_column"]: authProviders_update_column;
	["authProviders_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["authProviders_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["authProviders_bool_exp"]
	};
	/** columns and relationships of "auth.refresh_token_types" */
	["authRefreshTokenTypes"]: {
		__typename: "authRefreshTokenTypes",
		comment?: string | undefined,
		/** An array relationship */
		refreshTokens: Array<GraphQLTypes["authRefreshTokens"]>,
		/** An aggregate relationship */
		refreshTokens_aggregate: GraphQLTypes["authRefreshTokens_aggregate"],
		value: string
	};
	/** aggregated selection of "auth.refresh_token_types" */
	["authRefreshTokenTypes_aggregate"]: {
		__typename: "authRefreshTokenTypes_aggregate",
		aggregate?: GraphQLTypes["authRefreshTokenTypes_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["authRefreshTokenTypes"]>
	};
	/** aggregate fields of "auth.refresh_token_types" */
	["authRefreshTokenTypes_aggregate_fields"]: {
		__typename: "authRefreshTokenTypes_aggregate_fields",
		count: number,
		max?: GraphQLTypes["authRefreshTokenTypes_max_fields"] | undefined,
		min?: GraphQLTypes["authRefreshTokenTypes_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
	["authRefreshTokenTypes_bool_exp"]: {
		_and?: Array<GraphQLTypes["authRefreshTokenTypes_bool_exp"]> | undefined,
		_not?: GraphQLTypes["authRefreshTokenTypes_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["authRefreshTokenTypes_bool_exp"]> | undefined,
		comment?: GraphQLTypes["String_comparison_exp"] | undefined,
		refreshTokens?: GraphQLTypes["authRefreshTokens_bool_exp"] | undefined,
		refreshTokens_aggregate?: GraphQLTypes["authRefreshTokens_aggregate_bool_exp"] | undefined,
		value?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.refresh_token_types" */
	["authRefreshTokenTypes_constraint"]: authRefreshTokenTypes_constraint;
	["authRefreshTokenTypes_enum"]: authRefreshTokenTypes_enum;
	/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
	["authRefreshTokenTypes_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["authRefreshTokenTypes_enum"] | undefined,
		_in?: Array<GraphQLTypes["authRefreshTokenTypes_enum"]> | undefined,
		_is_null?: boolean | undefined,
		_neq?: GraphQLTypes["authRefreshTokenTypes_enum"] | undefined,
		_nin?: Array<GraphQLTypes["authRefreshTokenTypes_enum"]> | undefined
	};
	/** input type for inserting data into table "auth.refresh_token_types" */
	["authRefreshTokenTypes_insert_input"]: {
		comment?: string | undefined,
		refreshTokens?: GraphQLTypes["authRefreshTokens_arr_rel_insert_input"] | undefined,
		value?: string | undefined
	};
	/** aggregate max on columns */
	["authRefreshTokenTypes_max_fields"]: {
		__typename: "authRefreshTokenTypes_max_fields",
		comment?: string | undefined,
		value?: string | undefined
	};
	/** aggregate min on columns */
	["authRefreshTokenTypes_min_fields"]: {
		__typename: "authRefreshTokenTypes_min_fields",
		comment?: string | undefined,
		value?: string | undefined
	};
	/** response of any mutation on the table "auth.refresh_token_types" */
	["authRefreshTokenTypes_mutation_response"]: {
		__typename: "authRefreshTokenTypes_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["authRefreshTokenTypes"]>
	};
	/** on_conflict condition type for table "auth.refresh_token_types" */
	["authRefreshTokenTypes_on_conflict"]: {
		constraint: GraphQLTypes["authRefreshTokenTypes_constraint"],
		update_columns: Array<GraphQLTypes["authRefreshTokenTypes_update_column"]>,
		where?: GraphQLTypes["authRefreshTokenTypes_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.refresh_token_types". */
	["authRefreshTokenTypes_order_by"]: {
		comment?: GraphQLTypes["order_by"] | undefined,
		refreshTokens_aggregate?: GraphQLTypes["authRefreshTokens_aggregate_order_by"] | undefined,
		value?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.refresh_token_types */
	["authRefreshTokenTypes_pk_columns_input"]: {
		value: string
	};
	/** select columns of table "auth.refresh_token_types" */
	["authRefreshTokenTypes_select_column"]: authRefreshTokenTypes_select_column;
	/** input type for updating data in table "auth.refresh_token_types" */
	["authRefreshTokenTypes_set_input"]: {
		comment?: string | undefined,
		value?: string | undefined
	};
	/** Streaming cursor of the table "authRefreshTokenTypes" */
	["authRefreshTokenTypes_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["authRefreshTokenTypes_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authRefreshTokenTypes_stream_cursor_value_input"]: {
		comment?: string | undefined,
		value?: string | undefined
	};
	/** update columns of table "auth.refresh_token_types" */
	["authRefreshTokenTypes_update_column"]: authRefreshTokenTypes_update_column;
	["authRefreshTokenTypes_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["authRefreshTokenTypes_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["authRefreshTokenTypes_bool_exp"]
	};
	/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authRefreshTokens"]: {
		__typename: "authRefreshTokens",
		createdAt: GraphQLTypes["timestamptz"],
		expiresAt: GraphQLTypes["timestamptz"],
		id: GraphQLTypes["uuid"],
		metadata?: GraphQLTypes["jsonb"] | undefined,
		refreshTokenHash?: string | undefined,
		type: GraphQLTypes["authRefreshTokenTypes_enum"],
		/** An object relationship */
		user: GraphQLTypes["users"],
		userId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "auth.refresh_tokens" */
	["authRefreshTokens_aggregate"]: {
		__typename: "authRefreshTokens_aggregate",
		aggregate?: GraphQLTypes["authRefreshTokens_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["authRefreshTokens"]>
	};
	["authRefreshTokens_aggregate_bool_exp"]: {
		count?: GraphQLTypes["authRefreshTokens_aggregate_bool_exp_count"] | undefined
	};
	["authRefreshTokens_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["authRefreshTokens_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["authRefreshTokens_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.refresh_tokens" */
	["authRefreshTokens_aggregate_fields"]: {
		__typename: "authRefreshTokens_aggregate_fields",
		count: number,
		max?: GraphQLTypes["authRefreshTokens_max_fields"] | undefined,
		min?: GraphQLTypes["authRefreshTokens_min_fields"] | undefined
	};
	/** order by aggregate values of table "auth.refresh_tokens" */
	["authRefreshTokens_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["authRefreshTokens_max_order_by"] | undefined,
		min?: GraphQLTypes["authRefreshTokens_min_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["authRefreshTokens_append_input"]: {
		metadata?: GraphQLTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.refresh_tokens" */
	["authRefreshTokens_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["authRefreshTokens_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["authRefreshTokens_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
	["authRefreshTokens_bool_exp"]: {
		_and?: Array<GraphQLTypes["authRefreshTokens_bool_exp"]> | undefined,
		_not?: GraphQLTypes["authRefreshTokens_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["authRefreshTokens_bool_exp"]> | undefined,
		createdAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		expiresAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		metadata?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		refreshTokenHash?: GraphQLTypes["String_comparison_exp"] | undefined,
		type?: GraphQLTypes["authRefreshTokenTypes_enum_comparison_exp"] | undefined,
		user?: GraphQLTypes["users_bool_exp"] | undefined,
		userId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.refresh_tokens" */
	["authRefreshTokens_constraint"]: authRefreshTokens_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["authRefreshTokens_delete_at_path_input"]: {
		metadata?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["authRefreshTokens_delete_elem_input"]: {
		metadata?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["authRefreshTokens_delete_key_input"]: {
		metadata?: string | undefined
	};
	/** input type for inserting data into table "auth.refresh_tokens" */
	["authRefreshTokens_insert_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		expiresAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		refreshTokenHash?: string | undefined,
		type?: GraphQLTypes["authRefreshTokenTypes_enum"] | undefined,
		user?: GraphQLTypes["users_obj_rel_insert_input"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["authRefreshTokens_max_fields"]: {
		__typename: "authRefreshTokens_max_fields",
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		expiresAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		refreshTokenHash?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "auth.refresh_tokens" */
	["authRefreshTokens_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"] | undefined,
		expiresAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		refreshTokenHash?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["authRefreshTokens_min_fields"]: {
		__typename: "authRefreshTokens_min_fields",
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		expiresAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		refreshTokenHash?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "auth.refresh_tokens" */
	["authRefreshTokens_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"] | undefined,
		expiresAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		refreshTokenHash?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.refresh_tokens" */
	["authRefreshTokens_mutation_response"]: {
		__typename: "authRefreshTokens_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["authRefreshTokens"]>
	};
	/** on_conflict condition type for table "auth.refresh_tokens" */
	["authRefreshTokens_on_conflict"]: {
		constraint: GraphQLTypes["authRefreshTokens_constraint"],
		update_columns: Array<GraphQLTypes["authRefreshTokens_update_column"]>,
		where?: GraphQLTypes["authRefreshTokens_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.refresh_tokens". */
	["authRefreshTokens_order_by"]: {
		createdAt?: GraphQLTypes["order_by"] | undefined,
		expiresAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		metadata?: GraphQLTypes["order_by"] | undefined,
		refreshTokenHash?: GraphQLTypes["order_by"] | undefined,
		type?: GraphQLTypes["order_by"] | undefined,
		user?: GraphQLTypes["users_order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.refresh_tokens */
	["authRefreshTokens_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["authRefreshTokens_prepend_input"]: {
		metadata?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "auth.refresh_tokens" */
	["authRefreshTokens_select_column"]: authRefreshTokens_select_column;
	/** input type for updating data in table "auth.refresh_tokens" */
	["authRefreshTokens_set_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		expiresAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		refreshTokenHash?: string | undefined,
		type?: GraphQLTypes["authRefreshTokenTypes_enum"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "authRefreshTokens" */
	["authRefreshTokens_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["authRefreshTokens_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authRefreshTokens_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		expiresAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		refreshTokenHash?: string | undefined,
		type?: GraphQLTypes["authRefreshTokenTypes_enum"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "auth.refresh_tokens" */
	["authRefreshTokens_update_column"]: authRefreshTokens_update_column;
	["authRefreshTokens_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["authRefreshTokens_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["authRefreshTokens_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["authRefreshTokens_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["authRefreshTokens_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["authRefreshTokens_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["authRefreshTokens_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["authRefreshTokens_bool_exp"]
	};
	/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authRoles"]: {
		__typename: "authRoles",
		role: string,
		/** An array relationship */
		userRoles: Array<GraphQLTypes["authUserRoles"]>,
		/** An aggregate relationship */
		userRoles_aggregate: GraphQLTypes["authUserRoles_aggregate"],
		/** An array relationship */
		usersByDefaultRole: Array<GraphQLTypes["users"]>,
		/** An aggregate relationship */
		usersByDefaultRole_aggregate: GraphQLTypes["users_aggregate"]
	};
	/** aggregated selection of "auth.roles" */
	["authRoles_aggregate"]: {
		__typename: "authRoles_aggregate",
		aggregate?: GraphQLTypes["authRoles_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["authRoles"]>
	};
	/** aggregate fields of "auth.roles" */
	["authRoles_aggregate_fields"]: {
		__typename: "authRoles_aggregate_fields",
		count: number,
		max?: GraphQLTypes["authRoles_max_fields"] | undefined,
		min?: GraphQLTypes["authRoles_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
	["authRoles_bool_exp"]: {
		_and?: Array<GraphQLTypes["authRoles_bool_exp"]> | undefined,
		_not?: GraphQLTypes["authRoles_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["authRoles_bool_exp"]> | undefined,
		role?: GraphQLTypes["String_comparison_exp"] | undefined,
		userRoles?: GraphQLTypes["authUserRoles_bool_exp"] | undefined,
		userRoles_aggregate?: GraphQLTypes["authUserRoles_aggregate_bool_exp"] | undefined,
		usersByDefaultRole?: GraphQLTypes["users_bool_exp"] | undefined,
		usersByDefaultRole_aggregate?: GraphQLTypes["users_aggregate_bool_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.roles" */
	["authRoles_constraint"]: authRoles_constraint;
	/** input type for inserting data into table "auth.roles" */
	["authRoles_insert_input"]: {
		role?: string | undefined,
		userRoles?: GraphQLTypes["authUserRoles_arr_rel_insert_input"] | undefined,
		usersByDefaultRole?: GraphQLTypes["users_arr_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["authRoles_max_fields"]: {
		__typename: "authRoles_max_fields",
		role?: string | undefined
	};
	/** aggregate min on columns */
	["authRoles_min_fields"]: {
		__typename: "authRoles_min_fields",
		role?: string | undefined
	};
	/** response of any mutation on the table "auth.roles" */
	["authRoles_mutation_response"]: {
		__typename: "authRoles_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["authRoles"]>
	};
	/** input type for inserting object relation for remote table "auth.roles" */
	["authRoles_obj_rel_insert_input"]: {
		data: GraphQLTypes["authRoles_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["authRoles_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "auth.roles" */
	["authRoles_on_conflict"]: {
		constraint: GraphQLTypes["authRoles_constraint"],
		update_columns: Array<GraphQLTypes["authRoles_update_column"]>,
		where?: GraphQLTypes["authRoles_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.roles". */
	["authRoles_order_by"]: {
		role?: GraphQLTypes["order_by"] | undefined,
		userRoles_aggregate?: GraphQLTypes["authUserRoles_aggregate_order_by"] | undefined,
		usersByDefaultRole_aggregate?: GraphQLTypes["users_aggregate_order_by"] | undefined
	};
	/** primary key columns input for table: auth.roles */
	["authRoles_pk_columns_input"]: {
		role: string
	};
	/** select columns of table "auth.roles" */
	["authRoles_select_column"]: authRoles_select_column;
	/** input type for updating data in table "auth.roles" */
	["authRoles_set_input"]: {
		role?: string | undefined
	};
	/** Streaming cursor of the table "authRoles" */
	["authRoles_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["authRoles_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authRoles_stream_cursor_value_input"]: {
		role?: string | undefined
	};
	/** update columns of table "auth.roles" */
	["authRoles_update_column"]: authRoles_update_column;
	["authRoles_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["authRoles_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["authRoles_bool_exp"]
	};
	/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserProviders"]: {
		__typename: "authUserProviders",
		accessToken: string,
		createdAt: GraphQLTypes["timestamptz"],
		id: GraphQLTypes["uuid"],
		/** An object relationship */
		provider: GraphQLTypes["authProviders"],
		providerId: string,
		providerUserId: string,
		refreshToken?: string | undefined,
		updatedAt: GraphQLTypes["timestamptz"],
		/** An object relationship */
		user: GraphQLTypes["users"],
		userId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "auth.user_providers" */
	["authUserProviders_aggregate"]: {
		__typename: "authUserProviders_aggregate",
		aggregate?: GraphQLTypes["authUserProviders_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["authUserProviders"]>
	};
	["authUserProviders_aggregate_bool_exp"]: {
		count?: GraphQLTypes["authUserProviders_aggregate_bool_exp_count"] | undefined
	};
	["authUserProviders_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["authUserProviders_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["authUserProviders_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_providers" */
	["authUserProviders_aggregate_fields"]: {
		__typename: "authUserProviders_aggregate_fields",
		count: number,
		max?: GraphQLTypes["authUserProviders_max_fields"] | undefined,
		min?: GraphQLTypes["authUserProviders_min_fields"] | undefined
	};
	/** order by aggregate values of table "auth.user_providers" */
	["authUserProviders_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["authUserProviders_max_order_by"] | undefined,
		min?: GraphQLTypes["authUserProviders_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.user_providers" */
	["authUserProviders_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["authUserProviders_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["authUserProviders_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
	["authUserProviders_bool_exp"]: {
		_and?: Array<GraphQLTypes["authUserProviders_bool_exp"]> | undefined,
		_not?: GraphQLTypes["authUserProviders_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["authUserProviders_bool_exp"]> | undefined,
		accessToken?: GraphQLTypes["String_comparison_exp"] | undefined,
		createdAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		provider?: GraphQLTypes["authProviders_bool_exp"] | undefined,
		providerId?: GraphQLTypes["String_comparison_exp"] | undefined,
		providerUserId?: GraphQLTypes["String_comparison_exp"] | undefined,
		refreshToken?: GraphQLTypes["String_comparison_exp"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		user?: GraphQLTypes["users_bool_exp"] | undefined,
		userId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.user_providers" */
	["authUserProviders_constraint"]: authUserProviders_constraint;
	/** input type for inserting data into table "auth.user_providers" */
	["authUserProviders_insert_input"]: {
		accessToken?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		provider?: GraphQLTypes["authProviders_obj_rel_insert_input"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		user?: GraphQLTypes["users_obj_rel_insert_input"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["authUserProviders_max_fields"]: {
		__typename: "authUserProviders_max_fields",
		accessToken?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "auth.user_providers" */
	["authUserProviders_max_order_by"]: {
		accessToken?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		providerId?: GraphQLTypes["order_by"] | undefined,
		providerUserId?: GraphQLTypes["order_by"] | undefined,
		refreshToken?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["authUserProviders_min_fields"]: {
		__typename: "authUserProviders_min_fields",
		accessToken?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "auth.user_providers" */
	["authUserProviders_min_order_by"]: {
		accessToken?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		providerId?: GraphQLTypes["order_by"] | undefined,
		providerUserId?: GraphQLTypes["order_by"] | undefined,
		refreshToken?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.user_providers" */
	["authUserProviders_mutation_response"]: {
		__typename: "authUserProviders_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["authUserProviders"]>
	};
	/** on_conflict condition type for table "auth.user_providers" */
	["authUserProviders_on_conflict"]: {
		constraint: GraphQLTypes["authUserProviders_constraint"],
		update_columns: Array<GraphQLTypes["authUserProviders_update_column"]>,
		where?: GraphQLTypes["authUserProviders_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.user_providers". */
	["authUserProviders_order_by"]: {
		accessToken?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		provider?: GraphQLTypes["authProviders_order_by"] | undefined,
		providerId?: GraphQLTypes["order_by"] | undefined,
		providerUserId?: GraphQLTypes["order_by"] | undefined,
		refreshToken?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined,
		user?: GraphQLTypes["users_order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.user_providers */
	["authUserProviders_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "auth.user_providers" */
	["authUserProviders_select_column"]: authUserProviders_select_column;
	/** input type for updating data in table "auth.user_providers" */
	["authUserProviders_set_input"]: {
		accessToken?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "authUserProviders" */
	["authUserProviders_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["authUserProviders_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authUserProviders_stream_cursor_value_input"]: {
		accessToken?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		providerId?: string | undefined,
		providerUserId?: string | undefined,
		refreshToken?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "auth.user_providers" */
	["authUserProviders_update_column"]: authUserProviders_update_column;
	["authUserProviders_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["authUserProviders_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["authUserProviders_bool_exp"]
	};
	/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserRoles"]: {
		__typename: "authUserRoles",
		createdAt: GraphQLTypes["timestamptz"],
		id: GraphQLTypes["uuid"],
		role: string,
		/** An object relationship */
		roleByRole: GraphQLTypes["authRoles"],
		/** An object relationship */
		user: GraphQLTypes["users"],
		userId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "auth.user_roles" */
	["authUserRoles_aggregate"]: {
		__typename: "authUserRoles_aggregate",
		aggregate?: GraphQLTypes["authUserRoles_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["authUserRoles"]>
	};
	["authUserRoles_aggregate_bool_exp"]: {
		count?: GraphQLTypes["authUserRoles_aggregate_bool_exp_count"] | undefined
	};
	["authUserRoles_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["authUserRoles_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["authUserRoles_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_roles" */
	["authUserRoles_aggregate_fields"]: {
		__typename: "authUserRoles_aggregate_fields",
		count: number,
		max?: GraphQLTypes["authUserRoles_max_fields"] | undefined,
		min?: GraphQLTypes["authUserRoles_min_fields"] | undefined
	};
	/** order by aggregate values of table "auth.user_roles" */
	["authUserRoles_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["authUserRoles_max_order_by"] | undefined,
		min?: GraphQLTypes["authUserRoles_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.user_roles" */
	["authUserRoles_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["authUserRoles_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["authUserRoles_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
	["authUserRoles_bool_exp"]: {
		_and?: Array<GraphQLTypes["authUserRoles_bool_exp"]> | undefined,
		_not?: GraphQLTypes["authUserRoles_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["authUserRoles_bool_exp"]> | undefined,
		createdAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		role?: GraphQLTypes["String_comparison_exp"] | undefined,
		roleByRole?: GraphQLTypes["authRoles_bool_exp"] | undefined,
		user?: GraphQLTypes["users_bool_exp"] | undefined,
		userId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.user_roles" */
	["authUserRoles_constraint"]: authUserRoles_constraint;
	/** input type for inserting data into table "auth.user_roles" */
	["authUserRoles_insert_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		role?: string | undefined,
		roleByRole?: GraphQLTypes["authRoles_obj_rel_insert_input"] | undefined,
		user?: GraphQLTypes["users_obj_rel_insert_input"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["authUserRoles_max_fields"]: {
		__typename: "authUserRoles_max_fields",
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		role?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "auth.user_roles" */
	["authUserRoles_max_order_by"]: {
		createdAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		role?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["authUserRoles_min_fields"]: {
		__typename: "authUserRoles_min_fields",
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		role?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "auth.user_roles" */
	["authUserRoles_min_order_by"]: {
		createdAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		role?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.user_roles" */
	["authUserRoles_mutation_response"]: {
		__typename: "authUserRoles_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["authUserRoles"]>
	};
	/** on_conflict condition type for table "auth.user_roles" */
	["authUserRoles_on_conflict"]: {
		constraint: GraphQLTypes["authUserRoles_constraint"],
		update_columns: Array<GraphQLTypes["authUserRoles_update_column"]>,
		where?: GraphQLTypes["authUserRoles_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.user_roles". */
	["authUserRoles_order_by"]: {
		createdAt?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		role?: GraphQLTypes["order_by"] | undefined,
		roleByRole?: GraphQLTypes["authRoles_order_by"] | undefined,
		user?: GraphQLTypes["users_order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.user_roles */
	["authUserRoles_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "auth.user_roles" */
	["authUserRoles_select_column"]: authUserRoles_select_column;
	/** input type for updating data in table "auth.user_roles" */
	["authUserRoles_set_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		role?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "authUserRoles" */
	["authUserRoles_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["authUserRoles_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authUserRoles_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		role?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "auth.user_roles" */
	["authUserRoles_update_column"]: authUserRoles_update_column;
	["authUserRoles_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["authUserRoles_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["authUserRoles_bool_exp"]
	};
	/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["authUserSecurityKeys"]: {
		__typename: "authUserSecurityKeys",
		counter: GraphQLTypes["bigint"],
		credentialId: string,
		credentialPublicKey?: GraphQLTypes["bytea"] | undefined,
		id: GraphQLTypes["uuid"],
		nickname?: string | undefined,
		transports: string,
		/** An object relationship */
		user: GraphQLTypes["users"],
		userId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate"]: {
		__typename: "authUserSecurityKeys_aggregate",
		aggregate?: GraphQLTypes["authUserSecurityKeys_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["authUserSecurityKeys"]>
	};
	["authUserSecurityKeys_aggregate_bool_exp"]: {
		count?: GraphQLTypes["authUserSecurityKeys_aggregate_bool_exp_count"] | undefined
	};
	["authUserSecurityKeys_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["authUserSecurityKeys_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["authUserSecurityKeys_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate_fields"]: {
		__typename: "authUserSecurityKeys_aggregate_fields",
		avg?: GraphQLTypes["authUserSecurityKeys_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["authUserSecurityKeys_max_fields"] | undefined,
		min?: GraphQLTypes["authUserSecurityKeys_min_fields"] | undefined,
		stddev?: GraphQLTypes["authUserSecurityKeys_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["authUserSecurityKeys_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["authUserSecurityKeys_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["authUserSecurityKeys_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["authUserSecurityKeys_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["authUserSecurityKeys_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["authUserSecurityKeys_variance_fields"] | undefined
	};
	/** order by aggregate values of table "auth.user_security_keys" */
	["authUserSecurityKeys_aggregate_order_by"]: {
		avg?: GraphQLTypes["authUserSecurityKeys_avg_order_by"] | undefined,
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["authUserSecurityKeys_max_order_by"] | undefined,
		min?: GraphQLTypes["authUserSecurityKeys_min_order_by"] | undefined,
		stddev?: GraphQLTypes["authUserSecurityKeys_stddev_order_by"] | undefined,
		stddev_pop?: GraphQLTypes["authUserSecurityKeys_stddev_pop_order_by"] | undefined,
		stddev_samp?: GraphQLTypes["authUserSecurityKeys_stddev_samp_order_by"] | undefined,
		sum?: GraphQLTypes["authUserSecurityKeys_sum_order_by"] | undefined,
		var_pop?: GraphQLTypes["authUserSecurityKeys_var_pop_order_by"] | undefined,
		var_samp?: GraphQLTypes["authUserSecurityKeys_var_samp_order_by"] | undefined,
		variance?: GraphQLTypes["authUserSecurityKeys_variance_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.user_security_keys" */
	["authUserSecurityKeys_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["authUserSecurityKeys_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["authUserSecurityKeys_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["authUserSecurityKeys_avg_fields"]: {
		__typename: "authUserSecurityKeys_avg_fields",
		counter?: number | undefined
	};
	/** order by avg() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_avg_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
	["authUserSecurityKeys_bool_exp"]: {
		_and?: Array<GraphQLTypes["authUserSecurityKeys_bool_exp"]> | undefined,
		_not?: GraphQLTypes["authUserSecurityKeys_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["authUserSecurityKeys_bool_exp"]> | undefined,
		counter?: GraphQLTypes["bigint_comparison_exp"] | undefined,
		credentialId?: GraphQLTypes["String_comparison_exp"] | undefined,
		credentialPublicKey?: GraphQLTypes["bytea_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		nickname?: GraphQLTypes["String_comparison_exp"] | undefined,
		transports?: GraphQLTypes["String_comparison_exp"] | undefined,
		user?: GraphQLTypes["users_bool_exp"] | undefined,
		userId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.user_security_keys" */
	["authUserSecurityKeys_constraint"]: authUserSecurityKeys_constraint;
	/** input type for incrementing numeric columns in table "auth.user_security_keys" */
	["authUserSecurityKeys_inc_input"]: {
		counter?: GraphQLTypes["bigint"] | undefined
	};
	/** input type for inserting data into table "auth.user_security_keys" */
	["authUserSecurityKeys_insert_input"]: {
		counter?: GraphQLTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		credentialPublicKey?: GraphQLTypes["bytea"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		user?: GraphQLTypes["users_obj_rel_insert_input"] | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["authUserSecurityKeys_max_fields"]: {
		__typename: "authUserSecurityKeys_max_fields",
		counter?: GraphQLTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_max_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined,
		credentialId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		nickname?: GraphQLTypes["order_by"] | undefined,
		transports?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["authUserSecurityKeys_min_fields"]: {
		__typename: "authUserSecurityKeys_min_fields",
		counter?: GraphQLTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_min_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined,
		credentialId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		nickname?: GraphQLTypes["order_by"] | undefined,
		transports?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.user_security_keys" */
	["authUserSecurityKeys_mutation_response"]: {
		__typename: "authUserSecurityKeys_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["authUserSecurityKeys"]>
	};
	/** on_conflict condition type for table "auth.user_security_keys" */
	["authUserSecurityKeys_on_conflict"]: {
		constraint: GraphQLTypes["authUserSecurityKeys_constraint"],
		update_columns: Array<GraphQLTypes["authUserSecurityKeys_update_column"]>,
		where?: GraphQLTypes["authUserSecurityKeys_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.user_security_keys". */
	["authUserSecurityKeys_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined,
		credentialId?: GraphQLTypes["order_by"] | undefined,
		credentialPublicKey?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		nickname?: GraphQLTypes["order_by"] | undefined,
		transports?: GraphQLTypes["order_by"] | undefined,
		user?: GraphQLTypes["users_order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: auth.user_security_keys */
	["authUserSecurityKeys_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_select_column"]: authUserSecurityKeys_select_column;
	/** input type for updating data in table "auth.user_security_keys" */
	["authUserSecurityKeys_set_input"]: {
		counter?: GraphQLTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		credentialPublicKey?: GraphQLTypes["bytea"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate stddev on columns */
	["authUserSecurityKeys_stddev_fields"]: {
		__typename: "authUserSecurityKeys_stddev_fields",
		counter?: number | undefined
	};
	/** order by stddev() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["authUserSecurityKeys_stddev_pop_fields"]: {
		__typename: "authUserSecurityKeys_stddev_pop_fields",
		counter?: number | undefined
	};
	/** order by stddev_pop() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_pop_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["authUserSecurityKeys_stddev_samp_fields"]: {
		__typename: "authUserSecurityKeys_stddev_samp_fields",
		counter?: number | undefined
	};
	/** order by stddev_samp() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_stddev_samp_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "authUserSecurityKeys" */
	["authUserSecurityKeys_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["authUserSecurityKeys_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["authUserSecurityKeys_stream_cursor_value_input"]: {
		counter?: GraphQLTypes["bigint"] | undefined,
		credentialId?: string | undefined,
		credentialPublicKey?: GraphQLTypes["bytea"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		nickname?: string | undefined,
		transports?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["authUserSecurityKeys_sum_fields"]: {
		__typename: "authUserSecurityKeys_sum_fields",
		counter?: GraphQLTypes["bigint"] | undefined
	};
	/** order by sum() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_sum_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined
	};
	/** update columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_update_column"]: authUserSecurityKeys_update_column;
	["authUserSecurityKeys_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["authUserSecurityKeys_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["authUserSecurityKeys_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["authUserSecurityKeys_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["authUserSecurityKeys_var_pop_fields"]: {
		__typename: "authUserSecurityKeys_var_pop_fields",
		counter?: number | undefined
	};
	/** order by var_pop() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_var_pop_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["authUserSecurityKeys_var_samp_fields"]: {
		__typename: "authUserSecurityKeys_var_samp_fields",
		counter?: number | undefined
	};
	/** order by var_samp() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_var_samp_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["authUserSecurityKeys_variance_fields"]: {
		__typename: "authUserSecurityKeys_variance_fields",
		counter?: number | undefined
	};
	/** order by variance() on columns of table "auth.user_security_keys" */
	["authUserSecurityKeys_variance_order_by"]: {
		counter?: GraphQLTypes["order_by"] | undefined
	};
	["bigint"]: "scalar" & { name: "bigint" };
	/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
	["bigint_comparison_exp"]: {
		_eq?: GraphQLTypes["bigint"] | undefined,
		_gt?: GraphQLTypes["bigint"] | undefined,
		_gte?: GraphQLTypes["bigint"] | undefined,
		_in?: Array<GraphQLTypes["bigint"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: GraphQLTypes["bigint"] | undefined,
		_lte?: GraphQLTypes["bigint"] | undefined,
		_neq?: GraphQLTypes["bigint"] | undefined,
		_nin?: Array<GraphQLTypes["bigint"]> | undefined
	};
	/** columns and relationships of "blog" */
	["blog"]: {
		__typename: "blog",
		/** An object relationship */
		brand: GraphQLTypes["brands"],
		brandId: GraphQLTypes["uuid"],
		/** An object relationship */
		category: GraphQLTypes["blogCategory"],
		categoryId: GraphQLTypes["uuid"],
		content: string,
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		featuredImageId: GraphQLTypes["uuid"],
		/** An object relationship */
		file: GraphQLTypes["files"],
		id: GraphQLTypes["uuid"],
		metaDescription: string,
		/** An object relationship */
		project: GraphQLTypes["project"],
		projectId: GraphQLTypes["uuid"],
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		slug: string,
		title: string,
		updated_at: GraphQLTypes["timestamptz"],
		/** An object relationship */
		user: GraphQLTypes["users"]
	};
	/** columns and relationships of "blogCategory" */
	["blogCategory"]: {
		__typename: "blogCategory",
		/** An array relationship */
		blogs: Array<GraphQLTypes["blog"]>,
		/** An aggregate relationship */
		blogs_aggregate: GraphQLTypes["blog_aggregate"],
		created_at: GraphQLTypes["timestamptz"],
		id: GraphQLTypes["uuid"],
		name: string,
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** aggregated selection of "blogCategory" */
	["blogCategory_aggregate"]: {
		__typename: "blogCategory_aggregate",
		aggregate?: GraphQLTypes["blogCategory_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["blogCategory"]>
	};
	/** aggregate fields of "blogCategory" */
	["blogCategory_aggregate_fields"]: {
		__typename: "blogCategory_aggregate_fields",
		count: number,
		max?: GraphQLTypes["blogCategory_max_fields"] | undefined,
		min?: GraphQLTypes["blogCategory_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "blogCategory". All fields are combined with a logical 'AND'. */
	["blogCategory_bool_exp"]: {
		_and?: Array<GraphQLTypes["blogCategory_bool_exp"]> | undefined,
		_not?: GraphQLTypes["blogCategory_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["blogCategory_bool_exp"]> | undefined,
		blogs?: GraphQLTypes["blog_bool_exp"] | undefined,
		blogs_aggregate?: GraphQLTypes["blog_aggregate_bool_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "blogCategory" */
	["blogCategory_constraint"]: blogCategory_constraint;
	/** input type for inserting data into table "blogCategory" */
	["blogCategory_insert_input"]: {
		blogs?: GraphQLTypes["blog_arr_rel_insert_input"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["blogCategory_max_fields"]: {
		__typename: "blogCategory_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["blogCategory_min_fields"]: {
		__typename: "blogCategory_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "blogCategory" */
	["blogCategory_mutation_response"]: {
		__typename: "blogCategory_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["blogCategory"]>
	};
	/** input type for inserting object relation for remote table "blogCategory" */
	["blogCategory_obj_rel_insert_input"]: {
		data: GraphQLTypes["blogCategory_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["blogCategory_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "blogCategory" */
	["blogCategory_on_conflict"]: {
		constraint: GraphQLTypes["blogCategory_constraint"],
		update_columns: Array<GraphQLTypes["blogCategory_update_column"]>,
		where?: GraphQLTypes["blogCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "blogCategory". */
	["blogCategory_order_by"]: {
		blogs_aggregate?: GraphQLTypes["blog_aggregate_order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: blogCategory */
	["blogCategory_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "blogCategory" */
	["blogCategory_select_column"]: blogCategory_select_column;
	/** input type for updating data in table "blogCategory" */
	["blogCategory_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "blogCategory" */
	["blogCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["blogCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["blogCategory_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "blogCategory" */
	["blogCategory_update_column"]: blogCategory_update_column;
	["blogCategory_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["blogCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["blogCategory_bool_exp"]
	};
	/** fields of action: "blogPostGeneratorEnhancedForAmp" */
	["blogPostGeneratorEnhancedForAmp"]: {
		__typename: "blogPostGeneratorEnhancedForAmp",
		/** the time at which this action was created */
		created_at: GraphQLTypes["timestamptz"],
		/** errors related to the invocation */
		errors?: GraphQLTypes["json"] | undefined,
		/** the unique id of an action */
		id: GraphQLTypes["uuid"],
		/** the output fields of this action */
		output?: GraphQLTypes["blogPostGeneratorEnhancedForAmpOutput"] | undefined
	};
	["blogPostGeneratorEnhancedForAmpOutput"]: {
		__typename: "blogPostGeneratorEnhancedForAmpOutput",
		html?: string | undefined
	};
	/** aggregated selection of "blog" */
	["blog_aggregate"]: {
		__typename: "blog_aggregate",
		aggregate?: GraphQLTypes["blog_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["blog"]>
	};
	["blog_aggregate_bool_exp"]: {
		count?: GraphQLTypes["blog_aggregate_bool_exp_count"] | undefined
	};
	["blog_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["blog_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["blog_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "blog" */
	["blog_aggregate_fields"]: {
		__typename: "blog_aggregate_fields",
		count: number,
		max?: GraphQLTypes["blog_max_fields"] | undefined,
		min?: GraphQLTypes["blog_min_fields"] | undefined
	};
	/** order by aggregate values of table "blog" */
	["blog_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["blog_max_order_by"] | undefined,
		min?: GraphQLTypes["blog_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "blog" */
	["blog_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["blog_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["blog_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "blog". All fields are combined with a logical 'AND'. */
	["blog_bool_exp"]: {
		_and?: Array<GraphQLTypes["blog_bool_exp"]> | undefined,
		_not?: GraphQLTypes["blog_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["blog_bool_exp"]> | undefined,
		brand?: GraphQLTypes["brands_bool_exp"] | undefined,
		brandId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		category?: GraphQLTypes["blogCategory_bool_exp"] | undefined,
		categoryId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		content?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		featuredImageId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		file?: GraphQLTypes["files_bool_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		metaDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		project?: GraphQLTypes["project_bool_exp"] | undefined,
		projectId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		publishDate?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		slug?: GraphQLTypes["String_comparison_exp"] | undefined,
		title?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		user?: GraphQLTypes["users_bool_exp"] | undefined
	};
	/** unique or primary key constraints on table "blog" */
	["blog_constraint"]: blog_constraint;
	/** input type for inserting data into table "blog" */
	["blog_insert_input"]: {
		brand?: GraphQLTypes["brands_obj_rel_insert_input"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		category?: GraphQLTypes["blogCategory_obj_rel_insert_input"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		featuredImageId?: GraphQLTypes["uuid"] | undefined,
		file?: GraphQLTypes["files_obj_rel_insert_input"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		project?: GraphQLTypes["project_obj_rel_insert_input"] | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		user?: GraphQLTypes["users_obj_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["blog_max_fields"]: {
		__typename: "blog_max_fields",
		brandId?: GraphQLTypes["uuid"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		featuredImageId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "blog" */
	["blog_max_order_by"]: {
		brandId?: GraphQLTypes["order_by"] | undefined,
		categoryId?: GraphQLTypes["order_by"] | undefined,
		content?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		featuredImageId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		metaDescription?: GraphQLTypes["order_by"] | undefined,
		projectId?: GraphQLTypes["order_by"] | undefined,
		publishDate?: GraphQLTypes["order_by"] | undefined,
		scheduledDate?: GraphQLTypes["order_by"] | undefined,
		slug?: GraphQLTypes["order_by"] | undefined,
		title?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["blog_min_fields"]: {
		__typename: "blog_min_fields",
		brandId?: GraphQLTypes["uuid"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		featuredImageId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "blog" */
	["blog_min_order_by"]: {
		brandId?: GraphQLTypes["order_by"] | undefined,
		categoryId?: GraphQLTypes["order_by"] | undefined,
		content?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		featuredImageId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		metaDescription?: GraphQLTypes["order_by"] | undefined,
		projectId?: GraphQLTypes["order_by"] | undefined,
		publishDate?: GraphQLTypes["order_by"] | undefined,
		scheduledDate?: GraphQLTypes["order_by"] | undefined,
		slug?: GraphQLTypes["order_by"] | undefined,
		title?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "blog" */
	["blog_mutation_response"]: {
		__typename: "blog_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["blog"]>
	};
	/** on_conflict condition type for table "blog" */
	["blog_on_conflict"]: {
		constraint: GraphQLTypes["blog_constraint"],
		update_columns: Array<GraphQLTypes["blog_update_column"]>,
		where?: GraphQLTypes["blog_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "blog". */
	["blog_order_by"]: {
		brand?: GraphQLTypes["brands_order_by"] | undefined,
		brandId?: GraphQLTypes["order_by"] | undefined,
		category?: GraphQLTypes["blogCategory_order_by"] | undefined,
		categoryId?: GraphQLTypes["order_by"] | undefined,
		content?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		featuredImageId?: GraphQLTypes["order_by"] | undefined,
		file?: GraphQLTypes["files_order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		metaDescription?: GraphQLTypes["order_by"] | undefined,
		project?: GraphQLTypes["project_order_by"] | undefined,
		projectId?: GraphQLTypes["order_by"] | undefined,
		publishDate?: GraphQLTypes["order_by"] | undefined,
		scheduledDate?: GraphQLTypes["order_by"] | undefined,
		slug?: GraphQLTypes["order_by"] | undefined,
		title?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		user?: GraphQLTypes["users_order_by"] | undefined
	};
	/** primary key columns input for table: blog */
	["blog_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "blog" */
	["blog_select_column"]: blog_select_column;
	/** input type for updating data in table "blog" */
	["blog_set_input"]: {
		brandId?: GraphQLTypes["uuid"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		featuredImageId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "blog" */
	["blog_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["blog_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["blog_stream_cursor_value_input"]: {
		brandId?: GraphQLTypes["uuid"] | undefined,
		categoryId?: GraphQLTypes["uuid"] | undefined,
		content?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		featuredImageId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		metaDescription?: string | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		slug?: string | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "blog" */
	["blog_update_column"]: blog_update_column;
	["blog_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["blog_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["blog_bool_exp"]
	};
	/** columns and relationships of "brands" */
	["brands"]: {
		__typename: "brands",
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: GraphQLTypes["numeric"] | undefined,
		addressLongitude?: GraphQLTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		archtypeAdherence?: GraphQLTypes["jsonb"] | undefined,
		/** An array relationship */
		blogs: Array<GraphQLTypes["blog"]>,
		/** An aggregate relationship */
		blogs_aggregate: GraphQLTypes["blog_aggregate"],
		brandName: string,
		colors?: GraphQLTypes["jsonb"] | undefined,
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		deleted: boolean,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id: GraphQLTypes["uuid"],
		instagramUrl?: string | undefined,
		isBrand?: boolean | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: GraphQLTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		/** An array relationship */
		products: Array<GraphQLTypes["products"]>,
		/** An aggregate relationship */
		products_aggregate: GraphQLTypes["products_aggregate"],
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at: GraphQLTypes["timestamptz"],
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregated selection of "brands" */
	["brands_aggregate"]: {
		__typename: "brands_aggregate",
		aggregate?: GraphQLTypes["brands_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["brands"]>
	};
	/** aggregate fields of "brands" */
	["brands_aggregate_fields"]: {
		__typename: "brands_aggregate_fields",
		avg?: GraphQLTypes["brands_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["brands_max_fields"] | undefined,
		min?: GraphQLTypes["brands_min_fields"] | undefined,
		stddev?: GraphQLTypes["brands_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["brands_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["brands_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["brands_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["brands_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["brands_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["brands_variance_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["brands_append_input"]: {
		archtypeAdherence?: GraphQLTypes["jsonb"] | undefined,
		colors?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregate avg on columns */
	["brands_avg_fields"]: {
		__typename: "brands_avg_fields",
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** Boolean expression to filter rows from the table "brands". All fields are combined with a logical 'AND'. */
	["brands_bool_exp"]: {
		_and?: Array<GraphQLTypes["brands_bool_exp"]> | undefined,
		_not?: GraphQLTypes["brands_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["brands_bool_exp"]> | undefined,
		addressCity?: GraphQLTypes["String_comparison_exp"] | undefined,
		addressComplement?: GraphQLTypes["String_comparison_exp"] | undefined,
		addressDistrict?: GraphQLTypes["String_comparison_exp"] | undefined,
		addressLatitude?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		addressLongitude?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		addressNumber?: GraphQLTypes["String_comparison_exp"] | undefined,
		addressStreet?: GraphQLTypes["String_comparison_exp"] | undefined,
		addressUf?: GraphQLTypes["String_comparison_exp"] | undefined,
		addressZipCode?: GraphQLTypes["String_comparison_exp"] | undefined,
		archtypeAdherence?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		blogs?: GraphQLTypes["blog_bool_exp"] | undefined,
		blogs_aggregate?: GraphQLTypes["blog_aggregate_bool_exp"] | undefined,
		brandName?: GraphQLTypes["String_comparison_exp"] | undefined,
		colors?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		deleted?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		email?: GraphQLTypes["String_comparison_exp"] | undefined,
		facebookUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		fontPrimary?: GraphQLTypes["String_comparison_exp"] | undefined,
		fontSecondary?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		instagramUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		isBrand?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		linkedinUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		logoFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		personality?: GraphQLTypes["String_comparison_exp"] | undefined,
		phoneNumber?: GraphQLTypes["String_comparison_exp"] | undefined,
		productOrService?: GraphQLTypes["String_comparison_exp"] | undefined,
		products?: GraphQLTypes["products_bool_exp"] | undefined,
		products_aggregate?: GraphQLTypes["products_aggregate_bool_exp"] | undefined,
		segment?: GraphQLTypes["String_comparison_exp"] | undefined,
		shortAbout?: GraphQLTypes["String_comparison_exp"] | undefined,
		slogan?: GraphQLTypes["String_comparison_exp"] | undefined,
		tiktokUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		twitterUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		voiceTone?: GraphQLTypes["String_comparison_exp"] | undefined,
		whatsappNumber?: GraphQLTypes["String_comparison_exp"] | undefined,
		youtubeUrl?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "brands" */
	["brands_constraint"]: brands_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["brands_delete_at_path_input"]: {
		archtypeAdherence?: Array<string> | undefined,
		colors?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["brands_delete_elem_input"]: {
		archtypeAdherence?: number | undefined,
		colors?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["brands_delete_key_input"]: {
		archtypeAdherence?: string | undefined,
		colors?: string | undefined
	};
	/** input type for incrementing numeric columns in table "brands" */
	["brands_inc_input"]: {
		addressLatitude?: GraphQLTypes["numeric"] | undefined,
		addressLongitude?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "brands" */
	["brands_insert_input"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: GraphQLTypes["numeric"] | undefined,
		addressLongitude?: GraphQLTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		archtypeAdherence?: GraphQLTypes["jsonb"] | undefined,
		blogs?: GraphQLTypes["blog_arr_rel_insert_input"] | undefined,
		brandName?: string | undefined,
		colors?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		isBrand?: boolean | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: GraphQLTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		products?: GraphQLTypes["products_arr_rel_insert_input"] | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregate max on columns */
	["brands_max_fields"]: {
		__typename: "brands_max_fields",
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: GraphQLTypes["numeric"] | undefined,
		addressLongitude?: GraphQLTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		brandName?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: GraphQLTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregate min on columns */
	["brands_min_fields"]: {
		__typename: "brands_min_fields",
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: GraphQLTypes["numeric"] | undefined,
		addressLongitude?: GraphQLTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		brandName?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: GraphQLTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** response of any mutation on the table "brands" */
	["brands_mutation_response"]: {
		__typename: "brands_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["brands"]>
	};
	/** input type for inserting object relation for remote table "brands" */
	["brands_obj_rel_insert_input"]: {
		data: GraphQLTypes["brands_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["brands_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "brands" */
	["brands_on_conflict"]: {
		constraint: GraphQLTypes["brands_constraint"],
		update_columns: Array<GraphQLTypes["brands_update_column"]>,
		where?: GraphQLTypes["brands_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "brands". */
	["brands_order_by"]: {
		addressCity?: GraphQLTypes["order_by"] | undefined,
		addressComplement?: GraphQLTypes["order_by"] | undefined,
		addressDistrict?: GraphQLTypes["order_by"] | undefined,
		addressLatitude?: GraphQLTypes["order_by"] | undefined,
		addressLongitude?: GraphQLTypes["order_by"] | undefined,
		addressNumber?: GraphQLTypes["order_by"] | undefined,
		addressStreet?: GraphQLTypes["order_by"] | undefined,
		addressUf?: GraphQLTypes["order_by"] | undefined,
		addressZipCode?: GraphQLTypes["order_by"] | undefined,
		archtypeAdherence?: GraphQLTypes["order_by"] | undefined,
		blogs_aggregate?: GraphQLTypes["blog_aggregate_order_by"] | undefined,
		brandName?: GraphQLTypes["order_by"] | undefined,
		colors?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		deleted?: GraphQLTypes["order_by"] | undefined,
		email?: GraphQLTypes["order_by"] | undefined,
		facebookUrl?: GraphQLTypes["order_by"] | undefined,
		fontPrimary?: GraphQLTypes["order_by"] | undefined,
		fontSecondary?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		instagramUrl?: GraphQLTypes["order_by"] | undefined,
		isBrand?: GraphQLTypes["order_by"] | undefined,
		linkedinUrl?: GraphQLTypes["order_by"] | undefined,
		logoFileId?: GraphQLTypes["order_by"] | undefined,
		personality?: GraphQLTypes["order_by"] | undefined,
		phoneNumber?: GraphQLTypes["order_by"] | undefined,
		productOrService?: GraphQLTypes["order_by"] | undefined,
		products_aggregate?: GraphQLTypes["products_aggregate_order_by"] | undefined,
		segment?: GraphQLTypes["order_by"] | undefined,
		shortAbout?: GraphQLTypes["order_by"] | undefined,
		slogan?: GraphQLTypes["order_by"] | undefined,
		tiktokUrl?: GraphQLTypes["order_by"] | undefined,
		twitterUrl?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		voiceTone?: GraphQLTypes["order_by"] | undefined,
		whatsappNumber?: GraphQLTypes["order_by"] | undefined,
		youtubeUrl?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: brands */
	["brands_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["brands_prepend_input"]: {
		archtypeAdherence?: GraphQLTypes["jsonb"] | undefined,
		colors?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "brands" */
	["brands_select_column"]: brands_select_column;
	/** input type for updating data in table "brands" */
	["brands_set_input"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: GraphQLTypes["numeric"] | undefined,
		addressLongitude?: GraphQLTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		archtypeAdherence?: GraphQLTypes["jsonb"] | undefined,
		brandName?: string | undefined,
		colors?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		isBrand?: boolean | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: GraphQLTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregate stddev on columns */
	["brands_stddev_fields"]: {
		__typename: "brands_stddev_fields",
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["brands_stddev_pop_fields"]: {
		__typename: "brands_stddev_pop_fields",
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["brands_stddev_samp_fields"]: {
		__typename: "brands_stddev_samp_fields",
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** Streaming cursor of the table "brands" */
	["brands_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["brands_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["brands_stream_cursor_value_input"]: {
		addressCity?: string | undefined,
		addressComplement?: string | undefined,
		addressDistrict?: string | undefined,
		addressLatitude?: GraphQLTypes["numeric"] | undefined,
		addressLongitude?: GraphQLTypes["numeric"] | undefined,
		addressNumber?: string | undefined,
		addressStreet?: string | undefined,
		addressUf?: string | undefined,
		addressZipCode?: string | undefined,
		archtypeAdherence?: GraphQLTypes["jsonb"] | undefined,
		brandName?: string | undefined,
		colors?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		email?: string | undefined,
		facebookUrl?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		instagramUrl?: string | undefined,
		isBrand?: boolean | undefined,
		linkedinUrl?: string | undefined,
		logoFileId?: GraphQLTypes["uuid"] | undefined,
		personality?: string | undefined,
		phoneNumber?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		shortAbout?: string | undefined,
		slogan?: string | undefined,
		tiktokUrl?: string | undefined,
		twitterUrl?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		voiceTone?: string | undefined,
		whatsappNumber?: string | undefined,
		youtubeUrl?: string | undefined
	};
	/** aggregate sum on columns */
	["brands_sum_fields"]: {
		__typename: "brands_sum_fields",
		addressLatitude?: GraphQLTypes["numeric"] | undefined,
		addressLongitude?: GraphQLTypes["numeric"] | undefined
	};
	/** update columns of table "brands" */
	["brands_update_column"]: brands_update_column;
	["brands_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["brands_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["brands_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["brands_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["brands_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["brands_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["brands_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["brands_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["brands_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["brands_var_pop_fields"]: {
		__typename: "brands_var_pop_fields",
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** aggregate var_samp on columns */
	["brands_var_samp_fields"]: {
		__typename: "brands_var_samp_fields",
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** aggregate variance on columns */
	["brands_variance_fields"]: {
		__typename: "brands_variance_fields",
		addressLatitude?: number | undefined,
		addressLongitude?: number | undefined
	};
	/** columns and relationships of "storage.buckets" */
	["buckets"]: {
		__typename: "buckets",
		cacheControl?: string | undefined,
		createdAt: GraphQLTypes["timestamptz"],
		downloadExpiration: number,
		/** An array relationship */
		files: Array<GraphQLTypes["files"]>,
		/** An aggregate relationship */
		files_aggregate: GraphQLTypes["files_aggregate"],
		id: string,
		maxUploadFileSize: number,
		minUploadFileSize: number,
		presignedUrlsEnabled: boolean,
		updatedAt: GraphQLTypes["timestamptz"]
	};
	/** aggregated selection of "storage.buckets" */
	["buckets_aggregate"]: {
		__typename: "buckets_aggregate",
		aggregate?: GraphQLTypes["buckets_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["buckets"]>
	};
	/** aggregate fields of "storage.buckets" */
	["buckets_aggregate_fields"]: {
		__typename: "buckets_aggregate_fields",
		avg?: GraphQLTypes["buckets_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["buckets_max_fields"] | undefined,
		min?: GraphQLTypes["buckets_min_fields"] | undefined,
		stddev?: GraphQLTypes["buckets_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["buckets_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["buckets_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["buckets_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["buckets_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["buckets_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["buckets_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["buckets_avg_fields"]: {
		__typename: "buckets_avg_fields",
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** Boolean expression to filter rows from the table "storage.buckets". All fields are combined with a logical 'AND'. */
	["buckets_bool_exp"]: {
		_and?: Array<GraphQLTypes["buckets_bool_exp"]> | undefined,
		_not?: GraphQLTypes["buckets_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["buckets_bool_exp"]> | undefined,
		cacheControl?: GraphQLTypes["String_comparison_exp"] | undefined,
		createdAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		downloadExpiration?: GraphQLTypes["Int_comparison_exp"] | undefined,
		files?: GraphQLTypes["files_bool_exp"] | undefined,
		files_aggregate?: GraphQLTypes["files_aggregate_bool_exp"] | undefined,
		id?: GraphQLTypes["String_comparison_exp"] | undefined,
		maxUploadFileSize?: GraphQLTypes["Int_comparison_exp"] | undefined,
		minUploadFileSize?: GraphQLTypes["Int_comparison_exp"] | undefined,
		presignedUrlsEnabled?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "storage.buckets" */
	["buckets_constraint"]: buckets_constraint;
	/** input type for incrementing numeric columns in table "storage.buckets" */
	["buckets_inc_input"]: {
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** input type for inserting data into table "storage.buckets" */
	["buckets_insert_input"]: {
		cacheControl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		files?: GraphQLTypes["files_arr_rel_insert_input"] | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		presignedUrlsEnabled?: boolean | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["buckets_max_fields"]: {
		__typename: "buckets_max_fields",
		cacheControl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["buckets_min_fields"]: {
		__typename: "buckets_min_fields",
		cacheControl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "storage.buckets" */
	["buckets_mutation_response"]: {
		__typename: "buckets_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["buckets"]>
	};
	/** input type for inserting object relation for remote table "storage.buckets" */
	["buckets_obj_rel_insert_input"]: {
		data: GraphQLTypes["buckets_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["buckets_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "storage.buckets" */
	["buckets_on_conflict"]: {
		constraint: GraphQLTypes["buckets_constraint"],
		update_columns: Array<GraphQLTypes["buckets_update_column"]>,
		where?: GraphQLTypes["buckets_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "storage.buckets". */
	["buckets_order_by"]: {
		cacheControl?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		downloadExpiration?: GraphQLTypes["order_by"] | undefined,
		files_aggregate?: GraphQLTypes["files_aggregate_order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		maxUploadFileSize?: GraphQLTypes["order_by"] | undefined,
		minUploadFileSize?: GraphQLTypes["order_by"] | undefined,
		presignedUrlsEnabled?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: storage.buckets */
	["buckets_pk_columns_input"]: {
		id: string
	};
	/** select columns of table "storage.buckets" */
	["buckets_select_column"]: buckets_select_column;
	/** input type for updating data in table "storage.buckets" */
	["buckets_set_input"]: {
		cacheControl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		presignedUrlsEnabled?: boolean | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["buckets_stddev_fields"]: {
		__typename: "buckets_stddev_fields",
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["buckets_stddev_pop_fields"]: {
		__typename: "buckets_stddev_pop_fields",
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["buckets_stddev_samp_fields"]: {
		__typename: "buckets_stddev_samp_fields",
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** Streaming cursor of the table "buckets" */
	["buckets_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["buckets_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["buckets_stream_cursor_value_input"]: {
		cacheControl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		downloadExpiration?: number | undefined,
		id?: string | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined,
		presignedUrlsEnabled?: boolean | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["buckets_sum_fields"]: {
		__typename: "buckets_sum_fields",
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** update columns of table "storage.buckets" */
	["buckets_update_column"]: buckets_update_column;
	["buckets_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["buckets_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["buckets_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["buckets_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["buckets_var_pop_fields"]: {
		__typename: "buckets_var_pop_fields",
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** aggregate var_samp on columns */
	["buckets_var_samp_fields"]: {
		__typename: "buckets_var_samp_fields",
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	/** aggregate variance on columns */
	["buckets_variance_fields"]: {
		__typename: "buckets_variance_fields",
		downloadExpiration?: number | undefined,
		maxUploadFileSize?: number | undefined,
		minUploadFileSize?: number | undefined
	};
	["bytea"]: "scalar" & { name: "bytea" };
	/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
	["bytea_comparison_exp"]: {
		_eq?: GraphQLTypes["bytea"] | undefined,
		_gt?: GraphQLTypes["bytea"] | undefined,
		_gte?: GraphQLTypes["bytea"] | undefined,
		_in?: Array<GraphQLTypes["bytea"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: GraphQLTypes["bytea"] | undefined,
		_lte?: GraphQLTypes["bytea"] | undefined,
		_neq?: GraphQLTypes["bytea"] | undefined,
		_nin?: Array<GraphQLTypes["bytea"]> | undefined
	};
	/** columns and relationships of "campaign" */
	["campaign"]: {
		__typename: "campaign",
		audiencesIds?: string | undefined,
		baseJson: GraphQLTypes["jsonb"],
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		id: GraphQLTypes["uuid"],
		productIds?: string | undefined,
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** aggregated selection of "campaign" */
	["campaign_aggregate"]: {
		__typename: "campaign_aggregate",
		aggregate?: GraphQLTypes["campaign_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["campaign"]>
	};
	/** aggregate fields of "campaign" */
	["campaign_aggregate_fields"]: {
		__typename: "campaign_aggregate_fields",
		count: number,
		max?: GraphQLTypes["campaign_max_fields"] | undefined,
		min?: GraphQLTypes["campaign_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["campaign_append_input"]: {
		baseJson?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "campaign". All fields are combined with a logical 'AND'. */
	["campaign_bool_exp"]: {
		_and?: Array<GraphQLTypes["campaign_bool_exp"]> | undefined,
		_not?: GraphQLTypes["campaign_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["campaign_bool_exp"]> | undefined,
		audiencesIds?: GraphQLTypes["String_comparison_exp"] | undefined,
		baseJson?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		brandId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		campaignObjective?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		productIds?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "campaign" */
	["campaign_constraint"]: campaign_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["campaign_delete_at_path_input"]: {
		baseJson?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["campaign_delete_elem_input"]: {
		baseJson?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["campaign_delete_key_input"]: {
		baseJson?: string | undefined
	};
	/** input type for inserting data into table "campaign" */
	["campaign_insert_input"]: {
		audiencesIds?: string | undefined,
		baseJson?: GraphQLTypes["jsonb"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["campaign_max_fields"]: {
		__typename: "campaign_max_fields",
		audiencesIds?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["campaign_min_fields"]: {
		__typename: "campaign_min_fields",
		audiencesIds?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "campaign" */
	["campaign_mutation_response"]: {
		__typename: "campaign_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["campaign"]>
	};
	/** on_conflict condition type for table "campaign" */
	["campaign_on_conflict"]: {
		constraint: GraphQLTypes["campaign_constraint"],
		update_columns: Array<GraphQLTypes["campaign_update_column"]>,
		where?: GraphQLTypes["campaign_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "campaign". */
	["campaign_order_by"]: {
		audiencesIds?: GraphQLTypes["order_by"] | undefined,
		baseJson?: GraphQLTypes["order_by"] | undefined,
		brandId?: GraphQLTypes["order_by"] | undefined,
		campaignObjective?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		productIds?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: campaign */
	["campaign_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["campaign_prepend_input"]: {
		baseJson?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "campaign" */
	["campaign_select_column"]: campaign_select_column;
	/** input type for updating data in table "campaign" */
	["campaign_set_input"]: {
		audiencesIds?: string | undefined,
		baseJson?: GraphQLTypes["jsonb"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "campaign" */
	["campaign_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["campaign_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["campaign_stream_cursor_value_input"]: {
		audiencesIds?: string | undefined,
		baseJson?: GraphQLTypes["jsonb"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignObjective?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		productIds?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "campaign" */
	["campaign_update_column"]: campaign_update_column;
	["campaign_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["campaign_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["campaign_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["campaign_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["campaign_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["campaign_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["campaign_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["campaign_bool_exp"]
	};
	/** columns and relationships of "categorysummaryview" */
	["categorysummaryview"]: {
		__typename: "categorysummaryview",
		categoryName?: string | undefined,
		count?: GraphQLTypes["bigint"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregated selection of "categorysummaryview" */
	["categorysummaryview_aggregate"]: {
		__typename: "categorysummaryview_aggregate",
		aggregate?: GraphQLTypes["categorysummaryview_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["categorysummaryview"]>
	};
	/** aggregate fields of "categorysummaryview" */
	["categorysummaryview_aggregate_fields"]: {
		__typename: "categorysummaryview_aggregate_fields",
		avg?: GraphQLTypes["categorysummaryview_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["categorysummaryview_max_fields"] | undefined,
		min?: GraphQLTypes["categorysummaryview_min_fields"] | undefined,
		stddev?: GraphQLTypes["categorysummaryview_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["categorysummaryview_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["categorysummaryview_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["categorysummaryview_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["categorysummaryview_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["categorysummaryview_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["categorysummaryview_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["categorysummaryview_avg_fields"]: {
		__typename: "categorysummaryview_avg_fields",
		count?: number | undefined
	};
	/** Boolean expression to filter rows from the table "categorysummaryview". All fields are combined with a logical 'AND'. */
	["categorysummaryview_bool_exp"]: {
		_and?: Array<GraphQLTypes["categorysummaryview_bool_exp"]> | undefined,
		_not?: GraphQLTypes["categorysummaryview_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["categorysummaryview_bool_exp"]> | undefined,
		categoryName?: GraphQLTypes["String_comparison_exp"] | undefined,
		count?: GraphQLTypes["bigint_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** aggregate max on columns */
	["categorysummaryview_max_fields"]: {
		__typename: "categorysummaryview_max_fields",
		categoryName?: string | undefined,
		count?: GraphQLTypes["bigint"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["categorysummaryview_min_fields"]: {
		__typename: "categorysummaryview_min_fields",
		categoryName?: string | undefined,
		count?: GraphQLTypes["bigint"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** Ordering options when selecting data from "categorysummaryview". */
	["categorysummaryview_order_by"]: {
		categoryName?: GraphQLTypes["order_by"] | undefined,
		count?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined
	};
	/** select columns of table "categorysummaryview" */
	["categorysummaryview_select_column"]: categorysummaryview_select_column;
	/** aggregate stddev on columns */
	["categorysummaryview_stddev_fields"]: {
		__typename: "categorysummaryview_stddev_fields",
		count?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["categorysummaryview_stddev_pop_fields"]: {
		__typename: "categorysummaryview_stddev_pop_fields",
		count?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["categorysummaryview_stddev_samp_fields"]: {
		__typename: "categorysummaryview_stddev_samp_fields",
		count?: number | undefined
	};
	/** Streaming cursor of the table "categorysummaryview" */
	["categorysummaryview_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["categorysummaryview_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["categorysummaryview_stream_cursor_value_input"]: {
		categoryName?: string | undefined,
		count?: GraphQLTypes["bigint"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["categorysummaryview_sum_fields"]: {
		__typename: "categorysummaryview_sum_fields",
		count?: GraphQLTypes["bigint"] | undefined
	};
	/** aggregate var_pop on columns */
	["categorysummaryview_var_pop_fields"]: {
		__typename: "categorysummaryview_var_pop_fields",
		count?: number | undefined
	};
	/** aggregate var_samp on columns */
	["categorysummaryview_var_samp_fields"]: {
		__typename: "categorysummaryview_var_samp_fields",
		count?: number | undefined
	};
	/** aggregate variance on columns */
	["categorysummaryview_variance_fields"]: {
		__typename: "categorysummaryview_variance_fields",
		count?: number | undefined
	};
	/** columns and relationships of "channels" */
	["channels"]: {
		__typename: "channels",
		alias: string,
		created_at: GraphQLTypes["timestamp"],
		displayName: string,
		id: GraphQLTypes["uuid"],
		updated_at: GraphQLTypes["timestamp"]
	};
	/** aggregated selection of "channels" */
	["channels_aggregate"]: {
		__typename: "channels_aggregate",
		aggregate?: GraphQLTypes["channels_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["channels"]>
	};
	/** aggregate fields of "channels" */
	["channels_aggregate_fields"]: {
		__typename: "channels_aggregate_fields",
		count: number,
		max?: GraphQLTypes["channels_max_fields"] | undefined,
		min?: GraphQLTypes["channels_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
	["channels_bool_exp"]: {
		_and?: Array<GraphQLTypes["channels_bool_exp"]> | undefined,
		_not?: GraphQLTypes["channels_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["channels_bool_exp"]> | undefined,
		alias?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamp_comparison_exp"] | undefined,
		displayName?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamp_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "channels" */
	["channels_constraint"]: channels_constraint;
	/** input type for inserting data into table "channels" */
	["channels_insert_input"]: {
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamp"] | undefined
	};
	/** aggregate max on columns */
	["channels_max_fields"]: {
		__typename: "channels_max_fields",
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamp"] | undefined
	};
	/** aggregate min on columns */
	["channels_min_fields"]: {
		__typename: "channels_min_fields",
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamp"] | undefined
	};
	/** response of any mutation on the table "channels" */
	["channels_mutation_response"]: {
		__typename: "channels_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["channels"]>
	};
	/** on_conflict condition type for table "channels" */
	["channels_on_conflict"]: {
		constraint: GraphQLTypes["channels_constraint"],
		update_columns: Array<GraphQLTypes["channels_update_column"]>,
		where?: GraphQLTypes["channels_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "channels". */
	["channels_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		displayName?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: channels */
	["channels_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "channels" */
	["channels_select_column"]: channels_select_column;
	/** input type for updating data in table "channels" */
	["channels_set_input"]: {
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamp"] | undefined
	};
	/** Streaming cursor of the table "channels" */
	["channels_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["channels_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["channels_stream_cursor_value_input"]: {
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamp"] | undefined,
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamp"] | undefined
	};
	/** update columns of table "channels" */
	["channels_update_column"]: channels_update_column;
	["channels_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["channels_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["channels_bool_exp"]
	};
	["citext"]: "scalar" & { name: "citext" };
	/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
	["citext_comparison_exp"]: {
		_eq?: GraphQLTypes["citext"] | undefined,
		_gt?: GraphQLTypes["citext"] | undefined,
		_gte?: GraphQLTypes["citext"] | undefined,
		/** does the column match the given case-insensitive pattern */
		_ilike?: GraphQLTypes["citext"] | undefined,
		_in?: Array<GraphQLTypes["citext"]> | undefined,
		/** does the column match the given POSIX regular expression, case insensitive */
		_iregex?: GraphQLTypes["citext"] | undefined,
		_is_null?: boolean | undefined,
		/** does the column match the given pattern */
		_like?: GraphQLTypes["citext"] | undefined,
		_lt?: GraphQLTypes["citext"] | undefined,
		_lte?: GraphQLTypes["citext"] | undefined,
		_neq?: GraphQLTypes["citext"] | undefined,
		/** does the column NOT match the given case-insensitive pattern */
		_nilike?: GraphQLTypes["citext"] | undefined,
		_nin?: Array<GraphQLTypes["citext"]> | undefined,
		/** does the column NOT match the given POSIX regular expression, case insensitive */
		_niregex?: GraphQLTypes["citext"] | undefined,
		/** does the column NOT match the given pattern */
		_nlike?: GraphQLTypes["citext"] | undefined,
		/** does the column NOT match the given POSIX regular expression, case sensitive */
		_nregex?: GraphQLTypes["citext"] | undefined,
		/** does the column NOT match the given SQL regular expression */
		_nsimilar?: GraphQLTypes["citext"] | undefined,
		/** does the column match the given POSIX regular expression, case sensitive */
		_regex?: GraphQLTypes["citext"] | undefined,
		/** does the column match the given SQL regular expression */
		_similar?: GraphQLTypes["citext"] | undefined
	};
	/** columns and relationships of "consumptionByChannelView" */
	["consumptionByChannelView"]: {
		__typename: "consumptionByChannelView",
		agendados?: GraphQLTypes["bigint"] | undefined,
		channel?: string | undefined,
		copymagico?: GraphQLTypes["numeric"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		economia?: GraphQLTypes["numeric"] | undefined,
		mercado?: GraphQLTypes["bigint"] | undefined,
		publicados?: GraphQLTypes["bigint"] | undefined,
		rascunhos?: GraphQLTypes["bigint"] | undefined
	};
	/** aggregated selection of "consumptionByChannelView" */
	["consumptionByChannelView_aggregate"]: {
		__typename: "consumptionByChannelView_aggregate",
		aggregate?: GraphQLTypes["consumptionByChannelView_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["consumptionByChannelView"]>
	};
	/** aggregate fields of "consumptionByChannelView" */
	["consumptionByChannelView_aggregate_fields"]: {
		__typename: "consumptionByChannelView_aggregate_fields",
		avg?: GraphQLTypes["consumptionByChannelView_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["consumptionByChannelView_max_fields"] | undefined,
		min?: GraphQLTypes["consumptionByChannelView_min_fields"] | undefined,
		stddev?: GraphQLTypes["consumptionByChannelView_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["consumptionByChannelView_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["consumptionByChannelView_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["consumptionByChannelView_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["consumptionByChannelView_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["consumptionByChannelView_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["consumptionByChannelView_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["consumptionByChannelView_avg_fields"]: {
		__typename: "consumptionByChannelView_avg_fields",
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** Boolean expression to filter rows from the table "consumptionByChannelView". All fields are combined with a logical 'AND'. */
	["consumptionByChannelView_bool_exp"]: {
		_and?: Array<GraphQLTypes["consumptionByChannelView_bool_exp"]> | undefined,
		_not?: GraphQLTypes["consumptionByChannelView_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["consumptionByChannelView_bool_exp"]> | undefined,
		agendados?: GraphQLTypes["bigint_comparison_exp"] | undefined,
		channel?: GraphQLTypes["String_comparison_exp"] | undefined,
		copymagico?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		economia?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		mercado?: GraphQLTypes["bigint_comparison_exp"] | undefined,
		publicados?: GraphQLTypes["bigint_comparison_exp"] | undefined,
		rascunhos?: GraphQLTypes["bigint_comparison_exp"] | undefined
	};
	/** aggregate max on columns */
	["consumptionByChannelView_max_fields"]: {
		__typename: "consumptionByChannelView_max_fields",
		agendados?: GraphQLTypes["bigint"] | undefined,
		channel?: string | undefined,
		copymagico?: GraphQLTypes["numeric"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		economia?: GraphQLTypes["numeric"] | undefined,
		mercado?: GraphQLTypes["bigint"] | undefined,
		publicados?: GraphQLTypes["bigint"] | undefined,
		rascunhos?: GraphQLTypes["bigint"] | undefined
	};
	/** aggregate min on columns */
	["consumptionByChannelView_min_fields"]: {
		__typename: "consumptionByChannelView_min_fields",
		agendados?: GraphQLTypes["bigint"] | undefined,
		channel?: string | undefined,
		copymagico?: GraphQLTypes["numeric"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		economia?: GraphQLTypes["numeric"] | undefined,
		mercado?: GraphQLTypes["bigint"] | undefined,
		publicados?: GraphQLTypes["bigint"] | undefined,
		rascunhos?: GraphQLTypes["bigint"] | undefined
	};
	/** Ordering options when selecting data from "consumptionByChannelView". */
	["consumptionByChannelView_order_by"]: {
		agendados?: GraphQLTypes["order_by"] | undefined,
		channel?: GraphQLTypes["order_by"] | undefined,
		copymagico?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		economia?: GraphQLTypes["order_by"] | undefined,
		mercado?: GraphQLTypes["order_by"] | undefined,
		publicados?: GraphQLTypes["order_by"] | undefined,
		rascunhos?: GraphQLTypes["order_by"] | undefined
	};
	/** select columns of table "consumptionByChannelView" */
	["consumptionByChannelView_select_column"]: consumptionByChannelView_select_column;
	/** aggregate stddev on columns */
	["consumptionByChannelView_stddev_fields"]: {
		__typename: "consumptionByChannelView_stddev_fields",
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["consumptionByChannelView_stddev_pop_fields"]: {
		__typename: "consumptionByChannelView_stddev_pop_fields",
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["consumptionByChannelView_stddev_samp_fields"]: {
		__typename: "consumptionByChannelView_stddev_samp_fields",
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** Streaming cursor of the table "consumptionByChannelView" */
	["consumptionByChannelView_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["consumptionByChannelView_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["consumptionByChannelView_stream_cursor_value_input"]: {
		agendados?: GraphQLTypes["bigint"] | undefined,
		channel?: string | undefined,
		copymagico?: GraphQLTypes["numeric"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		economia?: GraphQLTypes["numeric"] | undefined,
		mercado?: GraphQLTypes["bigint"] | undefined,
		publicados?: GraphQLTypes["bigint"] | undefined,
		rascunhos?: GraphQLTypes["bigint"] | undefined
	};
	/** aggregate sum on columns */
	["consumptionByChannelView_sum_fields"]: {
		__typename: "consumptionByChannelView_sum_fields",
		agendados?: GraphQLTypes["bigint"] | undefined,
		copymagico?: GraphQLTypes["numeric"] | undefined,
		economia?: GraphQLTypes["numeric"] | undefined,
		mercado?: GraphQLTypes["bigint"] | undefined,
		publicados?: GraphQLTypes["bigint"] | undefined,
		rascunhos?: GraphQLTypes["bigint"] | undefined
	};
	/** aggregate var_pop on columns */
	["consumptionByChannelView_var_pop_fields"]: {
		__typename: "consumptionByChannelView_var_pop_fields",
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** aggregate var_samp on columns */
	["consumptionByChannelView_var_samp_fields"]: {
		__typename: "consumptionByChannelView_var_samp_fields",
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** aggregate variance on columns */
	["consumptionByChannelView_variance_fields"]: {
		__typename: "consumptionByChannelView_variance_fields",
		agendados?: number | undefined,
		copymagico?: number | undefined,
		economia?: number | undefined,
		mercado?: number | undefined,
		publicados?: number | undefined,
		rascunhos?: number | undefined
	};
	/** columns and relationships of "costTable" */
	["costTable"]: {
		__typename: "costTable",
		bannerBear?: GraphQLTypes["numeric"] | undefined,
		created_at: GraphQLTypes["timestamptz"],
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric"] | undefined,
		id: GraphQLTypes["uuid"],
		provider: string
	};
	/** aggregated selection of "costTable" */
	["costTable_aggregate"]: {
		__typename: "costTable_aggregate",
		aggregate?: GraphQLTypes["costTable_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["costTable"]>
	};
	/** aggregate fields of "costTable" */
	["costTable_aggregate_fields"]: {
		__typename: "costTable_aggregate_fields",
		avg?: GraphQLTypes["costTable_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["costTable_max_fields"] | undefined,
		min?: GraphQLTypes["costTable_min_fields"] | undefined,
		stddev?: GraphQLTypes["costTable_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["costTable_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["costTable_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["costTable_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["costTable_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["costTable_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["costTable_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["costTable_avg_fields"]: {
		__typename: "costTable_avg_fields",
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** Boolean expression to filter rows from the table "costTable". All fields are combined with a logical 'AND'. */
	["costTable_bool_exp"]: {
		_and?: Array<GraphQLTypes["costTable_bool_exp"]> | undefined,
		_not?: GraphQLTypes["costTable_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["costTable_bool_exp"]> | undefined,
		bannerBear?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		entityId?: GraphQLTypes["String_comparison_exp"] | undefined,
		entityName?: GraphQLTypes["String_comparison_exp"] | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		provider?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "costTable" */
	["costTable_constraint"]: costTable_constraint;
	/** input type for incrementing numeric columns in table "costTable" */
	["costTable_inc_input"]: {
		bannerBear?: GraphQLTypes["numeric"] | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "costTable" */
	["costTable_insert_input"]: {
		bannerBear?: GraphQLTypes["numeric"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** aggregate max on columns */
	["costTable_max_fields"]: {
		__typename: "costTable_max_fields",
		bannerBear?: GraphQLTypes["numeric"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** aggregate min on columns */
	["costTable_min_fields"]: {
		__typename: "costTable_min_fields",
		bannerBear?: GraphQLTypes["numeric"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** response of any mutation on the table "costTable" */
	["costTable_mutation_response"]: {
		__typename: "costTable_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["costTable"]>
	};
	/** on_conflict condition type for table "costTable" */
	["costTable_on_conflict"]: {
		constraint: GraphQLTypes["costTable_constraint"],
		update_columns: Array<GraphQLTypes["costTable_update_column"]>,
		where?: GraphQLTypes["costTable_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "costTable". */
	["costTable_order_by"]: {
		bannerBear?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		entityId?: GraphQLTypes["order_by"] | undefined,
		entityName?: GraphQLTypes["order_by"] | undefined,
		gptCompletionTokens?: GraphQLTypes["order_by"] | undefined,
		gptPromptTokens?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		provider?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: costTable */
	["costTable_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "costTable" */
	["costTable_select_column"]: costTable_select_column;
	/** input type for updating data in table "costTable" */
	["costTable_set_input"]: {
		bannerBear?: GraphQLTypes["numeric"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** aggregate stddev on columns */
	["costTable_stddev_fields"]: {
		__typename: "costTable_stddev_fields",
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["costTable_stddev_pop_fields"]: {
		__typename: "costTable_stddev_pop_fields",
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["costTable_stddev_samp_fields"]: {
		__typename: "costTable_stddev_samp_fields",
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** Streaming cursor of the table "costTable" */
	["costTable_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["costTable_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["costTable_stream_cursor_value_input"]: {
		bannerBear?: GraphQLTypes["numeric"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		entityId?: string | undefined,
		entityName?: string | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		provider?: string | undefined
	};
	/** aggregate sum on columns */
	["costTable_sum_fields"]: {
		__typename: "costTable_sum_fields",
		bannerBear?: GraphQLTypes["numeric"] | undefined,
		gptCompletionTokens?: GraphQLTypes["numeric"] | undefined,
		gptPromptTokens?: GraphQLTypes["numeric"] | undefined
	};
	/** update columns of table "costTable" */
	["costTable_update_column"]: costTable_update_column;
	["costTable_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["costTable_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["costTable_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["costTable_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["costTable_var_pop_fields"]: {
		__typename: "costTable_var_pop_fields",
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** aggregate var_samp on columns */
	["costTable_var_samp_fields"]: {
		__typename: "costTable_var_samp_fields",
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** aggregate variance on columns */
	["costTable_variance_fields"]: {
		__typename: "costTable_variance_fields",
		bannerBear?: number | undefined,
		gptCompletionTokens?: number | undefined,
		gptPromptTokens?: number | undefined
	};
	/** ordering argument of a cursor */
	["cursor_ordering"]: cursor_ordering;
	["date"]: "scalar" & { name: "date" };
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
	["date_comparison_exp"]: {
		_eq?: GraphQLTypes["date"] | undefined,
		_gt?: GraphQLTypes["date"] | undefined,
		_gte?: GraphQLTypes["date"] | undefined,
		_in?: Array<GraphQLTypes["date"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: GraphQLTypes["date"] | undefined,
		_lte?: GraphQLTypes["date"] | undefined,
		_neq?: GraphQLTypes["date"] | undefined,
		_nin?: Array<GraphQLTypes["date"]> | undefined
	};
	/** columns and relationships of "deal" */
	["deal"]: {
		__typename: "deal",
		created_at: GraphQLTypes["timestamptz"],
		/** An object relationship */
		dealStatus: GraphQLTypes["dealStatus"],
		email: string,
		id: GraphQLTypes["uuid"],
		/** An array relationship */
		interactions: Array<GraphQLTypes["interaction"]>,
		/** An aggregate relationship */
		interactions_aggregate: GraphQLTypes["interaction_aggregate"],
		name: string,
		phone: string,
		/** An object relationship */
		product?: GraphQLTypes["products"] | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		rating: number,
		statusId: GraphQLTypes["uuid"],
		/** An array relationship */
		tasks: Array<GraphQLTypes["task"]>,
		/** An aggregate relationship */
		tasks_aggregate: GraphQLTypes["task_aggregate"],
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** columns and relationships of "dealStatus" */
	["dealStatus"]: {
		__typename: "dealStatus",
		/** An array relationship */
		deals: Array<GraphQLTypes["deal"]>,
		/** An aggregate relationship */
		deals_aggregate: GraphQLTypes["deal_aggregate"],
		displayName: string,
		id: GraphQLTypes["uuid"],
		order: number
	};
	/** aggregated selection of "dealStatus" */
	["dealStatus_aggregate"]: {
		__typename: "dealStatus_aggregate",
		aggregate?: GraphQLTypes["dealStatus_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["dealStatus"]>
	};
	/** aggregate fields of "dealStatus" */
	["dealStatus_aggregate_fields"]: {
		__typename: "dealStatus_aggregate_fields",
		avg?: GraphQLTypes["dealStatus_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["dealStatus_max_fields"] | undefined,
		min?: GraphQLTypes["dealStatus_min_fields"] | undefined,
		stddev?: GraphQLTypes["dealStatus_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["dealStatus_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["dealStatus_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["dealStatus_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["dealStatus_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["dealStatus_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["dealStatus_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["dealStatus_avg_fields"]: {
		__typename: "dealStatus_avg_fields",
		order?: number | undefined
	};
	/** Boolean expression to filter rows from the table "dealStatus". All fields are combined with a logical 'AND'. */
	["dealStatus_bool_exp"]: {
		_and?: Array<GraphQLTypes["dealStatus_bool_exp"]> | undefined,
		_not?: GraphQLTypes["dealStatus_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["dealStatus_bool_exp"]> | undefined,
		deals?: GraphQLTypes["deal_bool_exp"] | undefined,
		deals_aggregate?: GraphQLTypes["deal_aggregate_bool_exp"] | undefined,
		displayName?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		order?: GraphQLTypes["Int_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "dealStatus" */
	["dealStatus_constraint"]: dealStatus_constraint;
	/** input type for incrementing numeric columns in table "dealStatus" */
	["dealStatus_inc_input"]: {
		order?: number | undefined
	};
	/** input type for inserting data into table "dealStatus" */
	["dealStatus_insert_input"]: {
		deals?: GraphQLTypes["deal_arr_rel_insert_input"] | undefined,
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** aggregate max on columns */
	["dealStatus_max_fields"]: {
		__typename: "dealStatus_max_fields",
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** aggregate min on columns */
	["dealStatus_min_fields"]: {
		__typename: "dealStatus_min_fields",
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** response of any mutation on the table "dealStatus" */
	["dealStatus_mutation_response"]: {
		__typename: "dealStatus_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["dealStatus"]>
	};
	/** input type for inserting object relation for remote table "dealStatus" */
	["dealStatus_obj_rel_insert_input"]: {
		data: GraphQLTypes["dealStatus_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["dealStatus_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "dealStatus" */
	["dealStatus_on_conflict"]: {
		constraint: GraphQLTypes["dealStatus_constraint"],
		update_columns: Array<GraphQLTypes["dealStatus_update_column"]>,
		where?: GraphQLTypes["dealStatus_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "dealStatus". */
	["dealStatus_order_by"]: {
		deals_aggregate?: GraphQLTypes["deal_aggregate_order_by"] | undefined,
		displayName?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		order?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: dealStatus */
	["dealStatus_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "dealStatus" */
	["dealStatus_select_column"]: dealStatus_select_column;
	/** input type for updating data in table "dealStatus" */
	["dealStatus_set_input"]: {
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** aggregate stddev on columns */
	["dealStatus_stddev_fields"]: {
		__typename: "dealStatus_stddev_fields",
		order?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["dealStatus_stddev_pop_fields"]: {
		__typename: "dealStatus_stddev_pop_fields",
		order?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["dealStatus_stddev_samp_fields"]: {
		__typename: "dealStatus_stddev_samp_fields",
		order?: number | undefined
	};
	/** Streaming cursor of the table "dealStatus" */
	["dealStatus_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["dealStatus_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["dealStatus_stream_cursor_value_input"]: {
		displayName?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		order?: number | undefined
	};
	/** aggregate sum on columns */
	["dealStatus_sum_fields"]: {
		__typename: "dealStatus_sum_fields",
		order?: number | undefined
	};
	/** update columns of table "dealStatus" */
	["dealStatus_update_column"]: dealStatus_update_column;
	["dealStatus_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["dealStatus_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["dealStatus_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["dealStatus_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["dealStatus_var_pop_fields"]: {
		__typename: "dealStatus_var_pop_fields",
		order?: number | undefined
	};
	/** aggregate var_samp on columns */
	["dealStatus_var_samp_fields"]: {
		__typename: "dealStatus_var_samp_fields",
		order?: number | undefined
	};
	/** aggregate variance on columns */
	["dealStatus_variance_fields"]: {
		__typename: "dealStatus_variance_fields",
		order?: number | undefined
	};
	/** aggregated selection of "deal" */
	["deal_aggregate"]: {
		__typename: "deal_aggregate",
		aggregate?: GraphQLTypes["deal_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["deal"]>
	};
	["deal_aggregate_bool_exp"]: {
		count?: GraphQLTypes["deal_aggregate_bool_exp_count"] | undefined
	};
	["deal_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["deal_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["deal_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "deal" */
	["deal_aggregate_fields"]: {
		__typename: "deal_aggregate_fields",
		avg?: GraphQLTypes["deal_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["deal_max_fields"] | undefined,
		min?: GraphQLTypes["deal_min_fields"] | undefined,
		stddev?: GraphQLTypes["deal_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["deal_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["deal_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["deal_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["deal_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["deal_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["deal_variance_fields"] | undefined
	};
	/** order by aggregate values of table "deal" */
	["deal_aggregate_order_by"]: {
		avg?: GraphQLTypes["deal_avg_order_by"] | undefined,
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["deal_max_order_by"] | undefined,
		min?: GraphQLTypes["deal_min_order_by"] | undefined,
		stddev?: GraphQLTypes["deal_stddev_order_by"] | undefined,
		stddev_pop?: GraphQLTypes["deal_stddev_pop_order_by"] | undefined,
		stddev_samp?: GraphQLTypes["deal_stddev_samp_order_by"] | undefined,
		sum?: GraphQLTypes["deal_sum_order_by"] | undefined,
		var_pop?: GraphQLTypes["deal_var_pop_order_by"] | undefined,
		var_samp?: GraphQLTypes["deal_var_samp_order_by"] | undefined,
		variance?: GraphQLTypes["deal_variance_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "deal" */
	["deal_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["deal_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["deal_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["deal_avg_fields"]: {
		__typename: "deal_avg_fields",
		rating?: number | undefined
	};
	/** order by avg() on columns of table "deal" */
	["deal_avg_order_by"]: {
		rating?: GraphQLTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "deal". All fields are combined with a logical 'AND'. */
	["deal_bool_exp"]: {
		_and?: Array<GraphQLTypes["deal_bool_exp"]> | undefined,
		_not?: GraphQLTypes["deal_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["deal_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		dealStatus?: GraphQLTypes["dealStatus_bool_exp"] | undefined,
		email?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		interactions?: GraphQLTypes["interaction_bool_exp"] | undefined,
		interactions_aggregate?: GraphQLTypes["interaction_aggregate_bool_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined,
		phone?: GraphQLTypes["String_comparison_exp"] | undefined,
		product?: GraphQLTypes["products_bool_exp"] | undefined,
		productId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		rating?: GraphQLTypes["Int_comparison_exp"] | undefined,
		statusId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		tasks?: GraphQLTypes["task_bool_exp"] | undefined,
		tasks_aggregate?: GraphQLTypes["task_aggregate_bool_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "deal" */
	["deal_constraint"]: deal_constraint;
	/** input type for incrementing numeric columns in table "deal" */
	["deal_inc_input"]: {
		rating?: number | undefined
	};
	/** input type for inserting data into table "deal" */
	["deal_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		dealStatus?: GraphQLTypes["dealStatus_obj_rel_insert_input"] | undefined,
		email?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		interactions?: GraphQLTypes["interaction_arr_rel_insert_input"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		product?: GraphQLTypes["products_obj_rel_insert_input"] | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: GraphQLTypes["uuid"] | undefined,
		tasks?: GraphQLTypes["task_arr_rel_insert_input"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["deal_max_fields"]: {
		__typename: "deal_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		email?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "deal" */
	["deal_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		email?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		phone?: GraphQLTypes["order_by"] | undefined,
		productId?: GraphQLTypes["order_by"] | undefined,
		rating?: GraphQLTypes["order_by"] | undefined,
		statusId?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["deal_min_fields"]: {
		__typename: "deal_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		email?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "deal" */
	["deal_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		email?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		phone?: GraphQLTypes["order_by"] | undefined,
		productId?: GraphQLTypes["order_by"] | undefined,
		rating?: GraphQLTypes["order_by"] | undefined,
		statusId?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "deal" */
	["deal_mutation_response"]: {
		__typename: "deal_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["deal"]>
	};
	/** input type for inserting object relation for remote table "deal" */
	["deal_obj_rel_insert_input"]: {
		data: GraphQLTypes["deal_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["deal_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "deal" */
	["deal_on_conflict"]: {
		constraint: GraphQLTypes["deal_constraint"],
		update_columns: Array<GraphQLTypes["deal_update_column"]>,
		where?: GraphQLTypes["deal_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "deal". */
	["deal_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		dealStatus?: GraphQLTypes["dealStatus_order_by"] | undefined,
		email?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		interactions_aggregate?: GraphQLTypes["interaction_aggregate_order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		phone?: GraphQLTypes["order_by"] | undefined,
		product?: GraphQLTypes["products_order_by"] | undefined,
		productId?: GraphQLTypes["order_by"] | undefined,
		rating?: GraphQLTypes["order_by"] | undefined,
		statusId?: GraphQLTypes["order_by"] | undefined,
		tasks_aggregate?: GraphQLTypes["task_aggregate_order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: deal */
	["deal_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "deal" */
	["deal_select_column"]: deal_select_column;
	/** input type for updating data in table "deal" */
	["deal_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		email?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["deal_stddev_fields"]: {
		__typename: "deal_stddev_fields",
		rating?: number | undefined
	};
	/** order by stddev() on columns of table "deal" */
	["deal_stddev_order_by"]: {
		rating?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["deal_stddev_pop_fields"]: {
		__typename: "deal_stddev_pop_fields",
		rating?: number | undefined
	};
	/** order by stddev_pop() on columns of table "deal" */
	["deal_stddev_pop_order_by"]: {
		rating?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["deal_stddev_samp_fields"]: {
		__typename: "deal_stddev_samp_fields",
		rating?: number | undefined
	};
	/** order by stddev_samp() on columns of table "deal" */
	["deal_stddev_samp_order_by"]: {
		rating?: GraphQLTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "deal" */
	["deal_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["deal_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["deal_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		email?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		phone?: string | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		rating?: number | undefined,
		statusId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["deal_sum_fields"]: {
		__typename: "deal_sum_fields",
		rating?: number | undefined
	};
	/** order by sum() on columns of table "deal" */
	["deal_sum_order_by"]: {
		rating?: GraphQLTypes["order_by"] | undefined
	};
	/** update columns of table "deal" */
	["deal_update_column"]: deal_update_column;
	["deal_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["deal_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["deal_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["deal_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["deal_var_pop_fields"]: {
		__typename: "deal_var_pop_fields",
		rating?: number | undefined
	};
	/** order by var_pop() on columns of table "deal" */
	["deal_var_pop_order_by"]: {
		rating?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["deal_var_samp_fields"]: {
		__typename: "deal_var_samp_fields",
		rating?: number | undefined
	};
	/** order by var_samp() on columns of table "deal" */
	["deal_var_samp_order_by"]: {
		rating?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["deal_variance_fields"]: {
		__typename: "deal_variance_fields",
		rating?: number | undefined
	};
	/** order by variance() on columns of table "deal" */
	["deal_variance_order_by"]: {
		rating?: GraphQLTypes["order_by"] | undefined
	};
	/** columns and relationships of "displayCategory" */
	["displayCategory"]: {
		__typename: "displayCategory",
		created_at: GraphQLTypes["timestamptz"],
		displayName: GraphQLTypes["jsonb"],
		id: GraphQLTypes["uuid"],
		name: string
	};
	/** aggregated selection of "displayCategory" */
	["displayCategory_aggregate"]: {
		__typename: "displayCategory_aggregate",
		aggregate?: GraphQLTypes["displayCategory_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["displayCategory"]>
	};
	/** aggregate fields of "displayCategory" */
	["displayCategory_aggregate_fields"]: {
		__typename: "displayCategory_aggregate_fields",
		count: number,
		max?: GraphQLTypes["displayCategory_max_fields"] | undefined,
		min?: GraphQLTypes["displayCategory_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayCategory_append_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "displayCategory". All fields are combined with a logical 'AND'. */
	["displayCategory_bool_exp"]: {
		_and?: Array<GraphQLTypes["displayCategory_bool_exp"]> | undefined,
		_not?: GraphQLTypes["displayCategory_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["displayCategory_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		displayName?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "displayCategory" */
	["displayCategory_constraint"]: displayCategory_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayCategory_delete_at_path_input"]: {
		displayName?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayCategory_delete_elem_input"]: {
		displayName?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayCategory_delete_key_input"]: {
		displayName?: string | undefined
	};
	/** input type for inserting data into table "displayCategory" */
	["displayCategory_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** aggregate max on columns */
	["displayCategory_max_fields"]: {
		__typename: "displayCategory_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** aggregate min on columns */
	["displayCategory_min_fields"]: {
		__typename: "displayCategory_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** response of any mutation on the table "displayCategory" */
	["displayCategory_mutation_response"]: {
		__typename: "displayCategory_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["displayCategory"]>
	};
	/** on_conflict condition type for table "displayCategory" */
	["displayCategory_on_conflict"]: {
		constraint: GraphQLTypes["displayCategory_constraint"],
		update_columns: Array<GraphQLTypes["displayCategory_update_column"]>,
		where?: GraphQLTypes["displayCategory_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "displayCategory". */
	["displayCategory_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		displayName?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: displayCategory */
	["displayCategory_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayCategory_prepend_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "displayCategory" */
	["displayCategory_select_column"]: displayCategory_select_column;
	/** input type for updating data in table "displayCategory" */
	["displayCategory_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** Streaming cursor of the table "displayCategory" */
	["displayCategory_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["displayCategory_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["displayCategory_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** update columns of table "displayCategory" */
	["displayCategory_update_column"]: displayCategory_update_column;
	["displayCategory_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["displayCategory_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["displayCategory_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["displayCategory_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["displayCategory_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["displayCategory_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["displayCategory_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["displayCategory_bool_exp"]
	};
	/** columns and relationships of "displayDimension" */
	["displayDimension"]: {
		__typename: "displayDimension",
		displayName: GraphQLTypes["jsonb"],
		id: GraphQLTypes["uuid"],
		name: string
	};
	/** aggregated selection of "displayDimension" */
	["displayDimension_aggregate"]: {
		__typename: "displayDimension_aggregate",
		aggregate?: GraphQLTypes["displayDimension_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["displayDimension"]>
	};
	/** aggregate fields of "displayDimension" */
	["displayDimension_aggregate_fields"]: {
		__typename: "displayDimension_aggregate_fields",
		count: number,
		max?: GraphQLTypes["displayDimension_max_fields"] | undefined,
		min?: GraphQLTypes["displayDimension_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayDimension_append_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "displayDimension". All fields are combined with a logical 'AND'. */
	["displayDimension_bool_exp"]: {
		_and?: Array<GraphQLTypes["displayDimension_bool_exp"]> | undefined,
		_not?: GraphQLTypes["displayDimension_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["displayDimension_bool_exp"]> | undefined,
		displayName?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "displayDimension" */
	["displayDimension_constraint"]: displayDimension_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayDimension_delete_at_path_input"]: {
		displayName?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayDimension_delete_elem_input"]: {
		displayName?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayDimension_delete_key_input"]: {
		displayName?: string | undefined
	};
	/** input type for inserting data into table "displayDimension" */
	["displayDimension_insert_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** aggregate max on columns */
	["displayDimension_max_fields"]: {
		__typename: "displayDimension_max_fields",
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** aggregate min on columns */
	["displayDimension_min_fields"]: {
		__typename: "displayDimension_min_fields",
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** response of any mutation on the table "displayDimension" */
	["displayDimension_mutation_response"]: {
		__typename: "displayDimension_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["displayDimension"]>
	};
	/** input type for inserting object relation for remote table "displayDimension" */
	["displayDimension_obj_rel_insert_input"]: {
		data: GraphQLTypes["displayDimension_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["displayDimension_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "displayDimension" */
	["displayDimension_on_conflict"]: {
		constraint: GraphQLTypes["displayDimension_constraint"],
		update_columns: Array<GraphQLTypes["displayDimension_update_column"]>,
		where?: GraphQLTypes["displayDimension_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "displayDimension". */
	["displayDimension_order_by"]: {
		displayName?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: displayDimension */
	["displayDimension_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayDimension_prepend_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "displayDimension" */
	["displayDimension_select_column"]: displayDimension_select_column;
	/** input type for updating data in table "displayDimension" */
	["displayDimension_set_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** Streaming cursor of the table "displayDimension" */
	["displayDimension_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["displayDimension_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["displayDimension_stream_cursor_value_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined
	};
	/** update columns of table "displayDimension" */
	["displayDimension_update_column"]: displayDimension_update_column;
	["displayDimension_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["displayDimension_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["displayDimension_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["displayDimension_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["displayDimension_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["displayDimension_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["displayDimension_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["displayDimension_bool_exp"]
	};
	/** columns and relationships of "displayFormats" */
	["displayFormats"]: {
		__typename: "displayFormats",
		bannerbearId: string,
		created_at: GraphQLTypes["timestamptz"],
		displayCategoryId: GraphQLTypes["uuid"],
		/** An object relationship */
		displayDimension?: GraphQLTypes["displayDimension"] | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		displayName: GraphQLTypes["jsonb"],
		id: GraphQLTypes["uuid"],
		mapper: GraphQLTypes["jsonb"],
		name: string,
		newData?: GraphQLTypes["jsonb"] | undefined,
		newTemplate?: string | undefined,
		requiresBrand: boolean
	};
	/** aggregated selection of "displayFormats" */
	["displayFormats_aggregate"]: {
		__typename: "displayFormats_aggregate",
		aggregate?: GraphQLTypes["displayFormats_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["displayFormats"]>
	};
	/** aggregate fields of "displayFormats" */
	["displayFormats_aggregate_fields"]: {
		__typename: "displayFormats_aggregate_fields",
		count: number,
		max?: GraphQLTypes["displayFormats_max_fields"] | undefined,
		min?: GraphQLTypes["displayFormats_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayFormats_append_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined,
		mapper?: GraphQLTypes["jsonb"] | undefined,
		newData?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "displayFormats". All fields are combined with a logical 'AND'. */
	["displayFormats_bool_exp"]: {
		_and?: Array<GraphQLTypes["displayFormats_bool_exp"]> | undefined,
		_not?: GraphQLTypes["displayFormats_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["displayFormats_bool_exp"]> | undefined,
		bannerbearId?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		displayCategoryId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		displayDimension?: GraphQLTypes["displayDimension_bool_exp"] | undefined,
		displayDimensionId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		displayName?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		mapper?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined,
		newData?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		newTemplate?: GraphQLTypes["String_comparison_exp"] | undefined,
		requiresBrand?: GraphQLTypes["Boolean_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "displayFormats" */
	["displayFormats_constraint"]: displayFormats_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayFormats_delete_at_path_input"]: {
		displayName?: Array<string> | undefined,
		mapper?: Array<string> | undefined,
		newData?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayFormats_delete_elem_input"]: {
		displayName?: number | undefined,
		mapper?: number | undefined,
		newData?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayFormats_delete_key_input"]: {
		displayName?: string | undefined,
		mapper?: string | undefined,
		newData?: string | undefined
	};
	/** input type for inserting data into table "displayFormats" */
	["displayFormats_insert_input"]: {
		bannerbearId?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		displayCategoryId?: GraphQLTypes["uuid"] | undefined,
		displayDimension?: GraphQLTypes["displayDimension_obj_rel_insert_input"] | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mapper?: GraphQLTypes["jsonb"] | undefined,
		name?: string | undefined,
		newData?: GraphQLTypes["jsonb"] | undefined,
		newTemplate?: string | undefined,
		requiresBrand?: boolean | undefined
	};
	/** aggregate max on columns */
	["displayFormats_max_fields"]: {
		__typename: "displayFormats_max_fields",
		bannerbearId?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		displayCategoryId?: GraphQLTypes["uuid"] | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		newTemplate?: string | undefined
	};
	/** aggregate min on columns */
	["displayFormats_min_fields"]: {
		__typename: "displayFormats_min_fields",
		bannerbearId?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		displayCategoryId?: GraphQLTypes["uuid"] | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		newTemplate?: string | undefined
	};
	/** response of any mutation on the table "displayFormats" */
	["displayFormats_mutation_response"]: {
		__typename: "displayFormats_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["displayFormats"]>
	};
	/** input type for inserting object relation for remote table "displayFormats" */
	["displayFormats_obj_rel_insert_input"]: {
		data: GraphQLTypes["displayFormats_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["displayFormats_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "displayFormats" */
	["displayFormats_on_conflict"]: {
		constraint: GraphQLTypes["displayFormats_constraint"],
		update_columns: Array<GraphQLTypes["displayFormats_update_column"]>,
		where?: GraphQLTypes["displayFormats_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "displayFormats". */
	["displayFormats_order_by"]: {
		bannerbearId?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		displayCategoryId?: GraphQLTypes["order_by"] | undefined,
		displayDimension?: GraphQLTypes["displayDimension_order_by"] | undefined,
		displayDimensionId?: GraphQLTypes["order_by"] | undefined,
		displayName?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		mapper?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		newData?: GraphQLTypes["order_by"] | undefined,
		newTemplate?: GraphQLTypes["order_by"] | undefined,
		requiresBrand?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: displayFormats */
	["displayFormats_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayFormats_prepend_input"]: {
		displayName?: GraphQLTypes["jsonb"] | undefined,
		mapper?: GraphQLTypes["jsonb"] | undefined,
		newData?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "displayFormats" */
	["displayFormats_select_column"]: displayFormats_select_column;
	/** input type for updating data in table "displayFormats" */
	["displayFormats_set_input"]: {
		bannerbearId?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		displayCategoryId?: GraphQLTypes["uuid"] | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mapper?: GraphQLTypes["jsonb"] | undefined,
		name?: string | undefined,
		newData?: GraphQLTypes["jsonb"] | undefined,
		newTemplate?: string | undefined,
		requiresBrand?: boolean | undefined
	};
	/** Streaming cursor of the table "displayFormats" */
	["displayFormats_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["displayFormats_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["displayFormats_stream_cursor_value_input"]: {
		bannerbearId?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		displayCategoryId?: GraphQLTypes["uuid"] | undefined,
		displayDimensionId?: GraphQLTypes["uuid"] | undefined,
		displayName?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mapper?: GraphQLTypes["jsonb"] | undefined,
		name?: string | undefined,
		newData?: GraphQLTypes["jsonb"] | undefined,
		newTemplate?: string | undefined,
		requiresBrand?: boolean | undefined
	};
	/** update columns of table "displayFormats" */
	["displayFormats_update_column"]: displayFormats_update_column;
	["displayFormats_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["displayFormats_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["displayFormats_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["displayFormats_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["displayFormats_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["displayFormats_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["displayFormats_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["displayFormats_bool_exp"]
	};
	/** columns and relationships of "displayTemplate" */
	["displayTemplate"]: {
		__typename: "displayTemplate",
		alias: string,
		fields: GraphQLTypes["jsonb"],
		id: GraphQLTypes["uuid"],
		template: string
	};
	/** aggregated selection of "displayTemplate" */
	["displayTemplate_aggregate"]: {
		__typename: "displayTemplate_aggregate",
		aggregate?: GraphQLTypes["displayTemplate_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["displayTemplate"]>
	};
	/** aggregate fields of "displayTemplate" */
	["displayTemplate_aggregate_fields"]: {
		__typename: "displayTemplate_aggregate_fields",
		count: number,
		max?: GraphQLTypes["displayTemplate_max_fields"] | undefined,
		min?: GraphQLTypes["displayTemplate_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["displayTemplate_append_input"]: {
		fields?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "displayTemplate". All fields are combined with a logical 'AND'. */
	["displayTemplate_bool_exp"]: {
		_and?: Array<GraphQLTypes["displayTemplate_bool_exp"]> | undefined,
		_not?: GraphQLTypes["displayTemplate_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["displayTemplate_bool_exp"]> | undefined,
		alias?: GraphQLTypes["String_comparison_exp"] | undefined,
		fields?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		template?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "displayTemplate" */
	["displayTemplate_constraint"]: displayTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["displayTemplate_delete_at_path_input"]: {
		fields?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["displayTemplate_delete_elem_input"]: {
		fields?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["displayTemplate_delete_key_input"]: {
		fields?: string | undefined
	};
	/** input type for inserting data into table "displayTemplate" */
	["displayTemplate_insert_input"]: {
		alias?: string | undefined,
		fields?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** aggregate max on columns */
	["displayTemplate_max_fields"]: {
		__typename: "displayTemplate_max_fields",
		alias?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** aggregate min on columns */
	["displayTemplate_min_fields"]: {
		__typename: "displayTemplate_min_fields",
		alias?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** response of any mutation on the table "displayTemplate" */
	["displayTemplate_mutation_response"]: {
		__typename: "displayTemplate_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["displayTemplate"]>
	};
	/** on_conflict condition type for table "displayTemplate" */
	["displayTemplate_on_conflict"]: {
		constraint: GraphQLTypes["displayTemplate_constraint"],
		update_columns: Array<GraphQLTypes["displayTemplate_update_column"]>,
		where?: GraphQLTypes["displayTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "displayTemplate". */
	["displayTemplate_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		fields?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		template?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: displayTemplate */
	["displayTemplate_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["displayTemplate_prepend_input"]: {
		fields?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "displayTemplate" */
	["displayTemplate_select_column"]: displayTemplate_select_column;
	/** input type for updating data in table "displayTemplate" */
	["displayTemplate_set_input"]: {
		alias?: string | undefined,
		fields?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** Streaming cursor of the table "displayTemplate" */
	["displayTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["displayTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["displayTemplate_stream_cursor_value_input"]: {
		alias?: string | undefined,
		fields?: GraphQLTypes["jsonb"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		template?: string | undefined
	};
	/** update columns of table "displayTemplate" */
	["displayTemplate_update_column"]: displayTemplate_update_column;
	["displayTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["displayTemplate_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["displayTemplate_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["displayTemplate_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["displayTemplate_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["displayTemplate_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["displayTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["displayTemplate_bool_exp"]
	};
	["facebookPage"]: {
		__typename: "facebookPage",
		id: string,
		name: string
	};
	/** columns and relationships of "storage.files" */
	["files"]: {
		__typename: "files",
		/** An object relationship */
		bucket: GraphQLTypes["buckets"],
		bucketId: string,
		createdAt: GraphQLTypes["timestamptz"],
		etag?: string | undefined,
		id: GraphQLTypes["uuid"],
		isUploaded?: boolean | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt: GraphQLTypes["timestamptz"],
		uploadedByUserId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregated selection of "storage.files" */
	["files_aggregate"]: {
		__typename: "files_aggregate",
		aggregate?: GraphQLTypes["files_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["files"]>
	};
	["files_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["files_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: GraphQLTypes["files_aggregate_bool_exp_bool_or"] | undefined,
		count?: GraphQLTypes["files_aggregate_bool_exp_count"] | undefined
	};
	["files_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["files_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["files_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["files_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["files_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["files_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["files_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "storage.files" */
	["files_aggregate_fields"]: {
		__typename: "files_aggregate_fields",
		avg?: GraphQLTypes["files_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["files_max_fields"] | undefined,
		min?: GraphQLTypes["files_min_fields"] | undefined,
		stddev?: GraphQLTypes["files_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["files_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["files_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["files_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["files_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["files_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["files_variance_fields"] | undefined
	};
	/** order by aggregate values of table "storage.files" */
	["files_aggregate_order_by"]: {
		avg?: GraphQLTypes["files_avg_order_by"] | undefined,
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["files_max_order_by"] | undefined,
		min?: GraphQLTypes["files_min_order_by"] | undefined,
		stddev?: GraphQLTypes["files_stddev_order_by"] | undefined,
		stddev_pop?: GraphQLTypes["files_stddev_pop_order_by"] | undefined,
		stddev_samp?: GraphQLTypes["files_stddev_samp_order_by"] | undefined,
		sum?: GraphQLTypes["files_sum_order_by"] | undefined,
		var_pop?: GraphQLTypes["files_var_pop_order_by"] | undefined,
		var_samp?: GraphQLTypes["files_var_samp_order_by"] | undefined,
		variance?: GraphQLTypes["files_variance_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["files_append_input"]: {
		metadata?: GraphQLTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "storage.files" */
	["files_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["files_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["files_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["files_avg_fields"]: {
		__typename: "files_avg_fields",
		size?: number | undefined
	};
	/** order by avg() on columns of table "storage.files" */
	["files_avg_order_by"]: {
		size?: GraphQLTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
	["files_bool_exp"]: {
		_and?: Array<GraphQLTypes["files_bool_exp"]> | undefined,
		_not?: GraphQLTypes["files_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["files_bool_exp"]> | undefined,
		bucket?: GraphQLTypes["buckets_bool_exp"] | undefined,
		bucketId?: GraphQLTypes["String_comparison_exp"] | undefined,
		createdAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		etag?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		isUploaded?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		metadata?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		mimeType?: GraphQLTypes["String_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined,
		size?: GraphQLTypes["Int_comparison_exp"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		uploadedByUserId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "storage.files" */
	["files_constraint"]: files_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["files_delete_at_path_input"]: {
		metadata?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["files_delete_elem_input"]: {
		metadata?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["files_delete_key_input"]: {
		metadata?: string | undefined
	};
	/** input type for incrementing numeric columns in table "storage.files" */
	["files_inc_input"]: {
		size?: number | undefined
	};
	/** input type for inserting data into table "storage.files" */
	["files_insert_input"]: {
		bucket?: GraphQLTypes["buckets_obj_rel_insert_input"] | undefined,
		bucketId?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		isUploaded?: boolean | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		uploadedByUserId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["files_max_fields"]: {
		__typename: "files_max_fields",
		bucketId?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		uploadedByUserId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "storage.files" */
	["files_max_order_by"]: {
		bucketId?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		etag?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		mimeType?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		size?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined,
		uploadedByUserId?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["files_min_fields"]: {
		__typename: "files_min_fields",
		bucketId?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		uploadedByUserId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "storage.files" */
	["files_min_order_by"]: {
		bucketId?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		etag?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		mimeType?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		size?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined,
		uploadedByUserId?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "storage.files" */
	["files_mutation_response"]: {
		__typename: "files_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["files"]>
	};
	/** input type for inserting object relation for remote table "storage.files" */
	["files_obj_rel_insert_input"]: {
		data: GraphQLTypes["files_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["files_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "storage.files" */
	["files_on_conflict"]: {
		constraint: GraphQLTypes["files_constraint"],
		update_columns: Array<GraphQLTypes["files_update_column"]>,
		where?: GraphQLTypes["files_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "storage.files". */
	["files_order_by"]: {
		bucket?: GraphQLTypes["buckets_order_by"] | undefined,
		bucketId?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		etag?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		isUploaded?: GraphQLTypes["order_by"] | undefined,
		metadata?: GraphQLTypes["order_by"] | undefined,
		mimeType?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		size?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined,
		uploadedByUserId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: storage.files */
	["files_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["files_prepend_input"]: {
		metadata?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "storage.files" */
	["files_select_column"]: files_select_column;
	/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage.files" */
	["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"]: files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage.files" */
	["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"]: files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "storage.files" */
	["files_set_input"]: {
		bucketId?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		isUploaded?: boolean | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		uploadedByUserId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate stddev on columns */
	["files_stddev_fields"]: {
		__typename: "files_stddev_fields",
		size?: number | undefined
	};
	/** order by stddev() on columns of table "storage.files" */
	["files_stddev_order_by"]: {
		size?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["files_stddev_pop_fields"]: {
		__typename: "files_stddev_pop_fields",
		size?: number | undefined
	};
	/** order by stddev_pop() on columns of table "storage.files" */
	["files_stddev_pop_order_by"]: {
		size?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["files_stddev_samp_fields"]: {
		__typename: "files_stddev_samp_fields",
		size?: number | undefined
	};
	/** order by stddev_samp() on columns of table "storage.files" */
	["files_stddev_samp_order_by"]: {
		size?: GraphQLTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "files" */
	["files_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["files_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["files_stream_cursor_value_input"]: {
		bucketId?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		etag?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		isUploaded?: boolean | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		mimeType?: string | undefined,
		name?: string | undefined,
		size?: number | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		uploadedByUserId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["files_sum_fields"]: {
		__typename: "files_sum_fields",
		size?: number | undefined
	};
	/** order by sum() on columns of table "storage.files" */
	["files_sum_order_by"]: {
		size?: GraphQLTypes["order_by"] | undefined
	};
	/** update columns of table "storage.files" */
	["files_update_column"]: files_update_column;
	["files_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["files_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["files_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["files_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["files_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["files_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["files_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["files_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["files_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["files_var_pop_fields"]: {
		__typename: "files_var_pop_fields",
		size?: number | undefined
	};
	/** order by var_pop() on columns of table "storage.files" */
	["files_var_pop_order_by"]: {
		size?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["files_var_samp_fields"]: {
		__typename: "files_var_samp_fields",
		size?: number | undefined
	};
	/** order by var_samp() on columns of table "storage.files" */
	["files_var_samp_order_by"]: {
		size?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["files_variance_fields"]: {
		__typename: "files_variance_fields",
		size?: number | undefined
	};
	/** order by variance() on columns of table "storage.files" */
	["files_variance_order_by"]: {
		size?: GraphQLTypes["order_by"] | undefined
	};
	/** fields of action: "generateNextMonthCalendar" */
	["generateNextMonthCalendar"]: {
		__typename: "generateNextMonthCalendar",
		/** the time at which this action was created */
		created_at: GraphQLTypes["timestamptz"],
		/** errors related to the invocation */
		errors?: GraphQLTypes["json"] | undefined,
		/** the unique id of an action */
		id: GraphQLTypes["uuid"],
		/** the output fields of this action */
		output?: GraphQLTypes["GenerateNextMonthCalendarOutput"] | undefined
	};
	["instagramPageAccount"]: {
		__typename: "instagramPageAccount",
		id: string,
		instagramBusinessAccountId?: string | undefined,
		name: string
	};
	/** columns and relationships of "interaction" */
	["interaction"]: {
		__typename: "interaction",
		channel: GraphQLTypes["uuid"],
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		date: GraphQLTypes["timestamptz"],
		dealId: GraphQLTypes["uuid"],
		id: GraphQLTypes["uuid"],
		observation: string,
		success: boolean,
		updated_at: GraphQLTypes["timestamptz"],
		/** An object relationship */
		user: GraphQLTypes["users"]
	};
	/** aggregated selection of "interaction" */
	["interaction_aggregate"]: {
		__typename: "interaction_aggregate",
		aggregate?: GraphQLTypes["interaction_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["interaction"]>
	};
	["interaction_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["interaction_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: GraphQLTypes["interaction_aggregate_bool_exp_bool_or"] | undefined,
		count?: GraphQLTypes["interaction_aggregate_bool_exp_count"] | undefined
	};
	["interaction_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["interaction_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["interaction_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["interaction_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["interaction_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["interaction_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["interaction_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "interaction" */
	["interaction_aggregate_fields"]: {
		__typename: "interaction_aggregate_fields",
		count: number,
		max?: GraphQLTypes["interaction_max_fields"] | undefined,
		min?: GraphQLTypes["interaction_min_fields"] | undefined
	};
	/** order by aggregate values of table "interaction" */
	["interaction_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["interaction_max_order_by"] | undefined,
		min?: GraphQLTypes["interaction_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "interaction" */
	["interaction_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["interaction_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["interaction_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "interaction". All fields are combined with a logical 'AND'. */
	["interaction_bool_exp"]: {
		_and?: Array<GraphQLTypes["interaction_bool_exp"]> | undefined,
		_not?: GraphQLTypes["interaction_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["interaction_bool_exp"]> | undefined,
		channel?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		date?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		dealId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		observation?: GraphQLTypes["String_comparison_exp"] | undefined,
		success?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		user?: GraphQLTypes["users_bool_exp"] | undefined
	};
	/** unique or primary key constraints on table "interaction" */
	["interaction_constraint"]: interaction_constraint;
	/** input type for inserting data into table "interaction" */
	["interaction_insert_input"]: {
		channel?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamptz"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		observation?: string | undefined,
		success?: boolean | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		user?: GraphQLTypes["users_obj_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["interaction_max_fields"]: {
		__typename: "interaction_max_fields",
		channel?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamptz"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		observation?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "interaction" */
	["interaction_max_order_by"]: {
		channel?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		date?: GraphQLTypes["order_by"] | undefined,
		dealId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		observation?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["interaction_min_fields"]: {
		__typename: "interaction_min_fields",
		channel?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamptz"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		observation?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "interaction" */
	["interaction_min_order_by"]: {
		channel?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		date?: GraphQLTypes["order_by"] | undefined,
		dealId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		observation?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "interaction" */
	["interaction_mutation_response"]: {
		__typename: "interaction_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["interaction"]>
	};
	/** on_conflict condition type for table "interaction" */
	["interaction_on_conflict"]: {
		constraint: GraphQLTypes["interaction_constraint"],
		update_columns: Array<GraphQLTypes["interaction_update_column"]>,
		where?: GraphQLTypes["interaction_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "interaction". */
	["interaction_order_by"]: {
		channel?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		date?: GraphQLTypes["order_by"] | undefined,
		dealId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		observation?: GraphQLTypes["order_by"] | undefined,
		success?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		user?: GraphQLTypes["users_order_by"] | undefined
	};
	/** primary key columns input for table: interaction */
	["interaction_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "interaction" */
	["interaction_select_column"]: interaction_select_column;
	/** select "interaction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "interaction" */
	["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"]: interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "interaction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "interaction" */
	["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"]: interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "interaction" */
	["interaction_set_input"]: {
		channel?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamptz"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		observation?: string | undefined,
		success?: boolean | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "interaction" */
	["interaction_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["interaction_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["interaction_stream_cursor_value_input"]: {
		channel?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamptz"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		observation?: string | undefined,
		success?: boolean | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "interaction" */
	["interaction_update_column"]: interaction_update_column;
	["interaction_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["interaction_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["interaction_bool_exp"]
	};
	["json"]: "scalar" & { name: "json" };
	["jsonb"]: "scalar" & { name: "jsonb" };
	["jsonb_cast_exp"]: {
		String?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
	["jsonb_comparison_exp"]: {
		_cast?: GraphQLTypes["jsonb_cast_exp"] | undefined,
		/** is the column contained in the given json value */
		_contained_in?: GraphQLTypes["jsonb"] | undefined,
		/** does the column contain the given json value at the top level */
		_contains?: GraphQLTypes["jsonb"] | undefined,
		_eq?: GraphQLTypes["jsonb"] | undefined,
		_gt?: GraphQLTypes["jsonb"] | undefined,
		_gte?: GraphQLTypes["jsonb"] | undefined,
		/** does the string exist as a top-level key in the column */
		_has_key?: string | undefined,
		/** do all of these strings exist as top-level keys in the column */
		_has_keys_all?: Array<string> | undefined,
		/** do any of these strings exist as top-level keys in the column */
		_has_keys_any?: Array<string> | undefined,
		_in?: Array<GraphQLTypes["jsonb"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: GraphQLTypes["jsonb"] | undefined,
		_lte?: GraphQLTypes["jsonb"] | undefined,
		_neq?: GraphQLTypes["jsonb"] | undefined,
		_nin?: Array<GraphQLTypes["jsonb"]> | undefined
	};
	/** columns and relationships of "landingPageTemplate" */
	["landingPageTemplate"]: {
		__typename: "landingPageTemplate",
		created_at: GraphQLTypes["timestamptz"],
		htmlTemplate: string,
		id: GraphQLTypes["uuid"],
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** aggregated selection of "landingPageTemplate" */
	["landingPageTemplate_aggregate"]: {
		__typename: "landingPageTemplate_aggregate",
		aggregate?: GraphQLTypes["landingPageTemplate_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["landingPageTemplate"]>
	};
	/** aggregate fields of "landingPageTemplate" */
	["landingPageTemplate_aggregate_fields"]: {
		__typename: "landingPageTemplate_aggregate_fields",
		count: number,
		max?: GraphQLTypes["landingPageTemplate_max_fields"] | undefined,
		min?: GraphQLTypes["landingPageTemplate_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "landingPageTemplate". All fields are combined with a logical 'AND'. */
	["landingPageTemplate_bool_exp"]: {
		_and?: Array<GraphQLTypes["landingPageTemplate_bool_exp"]> | undefined,
		_not?: GraphQLTypes["landingPageTemplate_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["landingPageTemplate_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		htmlTemplate?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "landingPageTemplate" */
	["landingPageTemplate_constraint"]: landingPageTemplate_constraint;
	/** input type for inserting data into table "landingPageTemplate" */
	["landingPageTemplate_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["landingPageTemplate_max_fields"]: {
		__typename: "landingPageTemplate_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["landingPageTemplate_min_fields"]: {
		__typename: "landingPageTemplate_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "landingPageTemplate" */
	["landingPageTemplate_mutation_response"]: {
		__typename: "landingPageTemplate_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["landingPageTemplate"]>
	};
	/** on_conflict condition type for table "landingPageTemplate" */
	["landingPageTemplate_on_conflict"]: {
		constraint: GraphQLTypes["landingPageTemplate_constraint"],
		update_columns: Array<GraphQLTypes["landingPageTemplate_update_column"]>,
		where?: GraphQLTypes["landingPageTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "landingPageTemplate". */
	["landingPageTemplate_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		htmlTemplate?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: landingPageTemplate */
	["landingPageTemplate_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "landingPageTemplate" */
	["landingPageTemplate_select_column"]: landingPageTemplate_select_column;
	/** input type for updating data in table "landingPageTemplate" */
	["landingPageTemplate_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "landingPageTemplate" */
	["landingPageTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["landingPageTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["landingPageTemplate_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		htmlTemplate?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "landingPageTemplate" */
	["landingPageTemplate_update_column"]: landingPageTemplate_update_column;
	["landingPageTemplate_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["landingPageTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["landingPageTemplate_bool_exp"]
	};
	/** mutation root */
	["mutation_root"]: {
		__typename: "mutation_root",
		blogPostGeneratorEnhancedForAmp: GraphQLTypes["uuid"],
		createAutomaticProject?: GraphQLTypes["CreateAutomaticProjectOutput"] | undefined,
		createImageUsingImagine?: GraphQLTypes["CreateImageUsingImagineOutput"] | undefined,
		/** delete single row from the table: "auth.providers" */
		deleteAuthProvider?: GraphQLTypes["authProviders"] | undefined,
		/** delete single row from the table: "auth.provider_requests" */
		deleteAuthProviderRequest?: GraphQLTypes["authProviderRequests"] | undefined,
		/** delete data from the table: "auth.provider_requests" */
		deleteAuthProviderRequests?: GraphQLTypes["authProviderRequests_mutation_response"] | undefined,
		/** delete data from the table: "auth.providers" */
		deleteAuthProviders?: GraphQLTypes["authProviders_mutation_response"] | undefined,
		/** delete single row from the table: "auth.refresh_tokens" */
		deleteAuthRefreshToken?: GraphQLTypes["authRefreshTokens"] | undefined,
		/** delete single row from the table: "auth.refresh_token_types" */
		deleteAuthRefreshTokenType?: GraphQLTypes["authRefreshTokenTypes"] | undefined,
		/** delete data from the table: "auth.refresh_token_types" */
		deleteAuthRefreshTokenTypes?: GraphQLTypes["authRefreshTokenTypes_mutation_response"] | undefined,
		/** delete data from the table: "auth.refresh_tokens" */
		deleteAuthRefreshTokens?: GraphQLTypes["authRefreshTokens_mutation_response"] | undefined,
		/** delete single row from the table: "auth.roles" */
		deleteAuthRole?: GraphQLTypes["authRoles"] | undefined,
		/** delete data from the table: "auth.roles" */
		deleteAuthRoles?: GraphQLTypes["authRoles_mutation_response"] | undefined,
		/** delete single row from the table: "auth.user_providers" */
		deleteAuthUserProvider?: GraphQLTypes["authUserProviders"] | undefined,
		/** delete data from the table: "auth.user_providers" */
		deleteAuthUserProviders?: GraphQLTypes["authUserProviders_mutation_response"] | undefined,
		/** delete single row from the table: "auth.user_roles" */
		deleteAuthUserRole?: GraphQLTypes["authUserRoles"] | undefined,
		/** delete data from the table: "auth.user_roles" */
		deleteAuthUserRoles?: GraphQLTypes["authUserRoles_mutation_response"] | undefined,
		/** delete single row from the table: "auth.user_security_keys" */
		deleteAuthUserSecurityKey?: GraphQLTypes["authUserSecurityKeys"] | undefined,
		/** delete data from the table: "auth.user_security_keys" */
		deleteAuthUserSecurityKeys?: GraphQLTypes["authUserSecurityKeys_mutation_response"] | undefined,
		/** delete single row from the table: "storage.buckets" */
		deleteBucket?: GraphQLTypes["buckets"] | undefined,
		/** delete data from the table: "storage.buckets" */
		deleteBuckets?: GraphQLTypes["buckets_mutation_response"] | undefined,
		/** delete single row from the table: "storage.files" */
		deleteFile?: GraphQLTypes["files"] | undefined,
		/** delete data from the table: "storage.files" */
		deleteFiles?: GraphQLTypes["files_mutation_response"] | undefined,
		/** delete single row from the table: "auth.users" */
		deleteUser?: GraphQLTypes["users"] | undefined,
		/** delete data from the table: "auth.users" */
		deleteUsers?: GraphQLTypes["users_mutation_response"] | undefined,
		/** delete single row from the table: "storage.virus" */
		deleteVirus?: GraphQLTypes["virus"] | undefined,
		/** delete data from the table: "storage.virus" */
		deleteViruses?: GraphQLTypes["virus_mutation_response"] | undefined,
		/** delete data from the table: "AppIntegration" */
		delete_AppIntegration?: GraphQLTypes["AppIntegration_mutation_response"] | undefined,
		/** delete single row from the table: "AppIntegration" */
		delete_AppIntegration_by_pk?: GraphQLTypes["AppIntegration"] | undefined,
		/** delete data from the table: "Course" */
		delete_Course?: GraphQLTypes["Course_mutation_response"] | undefined,
		/** delete data from the table: "CourseCategory" */
		delete_CourseCategory?: GraphQLTypes["CourseCategory_mutation_response"] | undefined,
		/** delete single row from the table: "CourseCategory" */
		delete_CourseCategory_by_pk?: GraphQLTypes["CourseCategory"] | undefined,
		/** delete single row from the table: "Course" */
		delete_Course_by_pk?: GraphQLTypes["Course"] | undefined,
		/** delete data from the table: "GeneratedPromptByTemplate" */
		delete_GeneratedPromptByTemplate?: GraphQLTypes["GeneratedPromptByTemplate_mutation_response"] | undefined,
		/** delete data from the table: "GeneratedPromptByTemplateImage" */
		delete_GeneratedPromptByTemplateImage?: GraphQLTypes["GeneratedPromptByTemplateImage_mutation_response"] | undefined,
		/** delete single row from the table: "GeneratedPromptByTemplateImage" */
		delete_GeneratedPromptByTemplateImage_by_pk?: GraphQLTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** delete single row from the table: "GeneratedPromptByTemplate" */
		delete_GeneratedPromptByTemplate_by_pk?: GraphQLTypes["GeneratedPromptByTemplate"] | undefined,
		/** delete data from the table: "TextGeneratorOption" */
		delete_TextGeneratorOption?: GraphQLTypes["TextGeneratorOption_mutation_response"] | undefined,
		/** delete data from the table: "TextGeneratorOptionEntry" */
		delete_TextGeneratorOptionEntry?: GraphQLTypes["TextGeneratorOptionEntry_mutation_response"] | undefined,
		/** delete single row from the table: "TextGeneratorOptionEntry" */
		delete_TextGeneratorOptionEntry_by_pk?: GraphQLTypes["TextGeneratorOptionEntry"] | undefined,
		/** delete single row from the table: "TextGeneratorOption" */
		delete_TextGeneratorOption_by_pk?: GraphQLTypes["TextGeneratorOption"] | undefined,
		/** delete data from the table: "TextPromptTemplate" */
		delete_TextPromptTemplate?: GraphQLTypes["TextPromptTemplate_mutation_response"] | undefined,
		/** delete data from the table: "TextPromptTemplateCategory" */
		delete_TextPromptTemplateCategory?: GraphQLTypes["TextPromptTemplateCategory_mutation_response"] | undefined,
		/** delete single row from the table: "TextPromptTemplateCategory" */
		delete_TextPromptTemplateCategory_by_pk?: GraphQLTypes["TextPromptTemplateCategory"] | undefined,
		/** delete single row from the table: "TextPromptTemplate" */
		delete_TextPromptTemplate_by_pk?: GraphQLTypes["TextPromptTemplate"] | undefined,
		/** delete data from the table: "TextTemplateClientCategory" */
		delete_TextTemplateClientCategory?: GraphQLTypes["TextTemplateClientCategory_mutation_response"] | undefined,
		/** delete single row from the table: "TextTemplateClientCategory" */
		delete_TextTemplateClientCategory_by_pk?: GraphQLTypes["TextTemplateClientCategory"] | undefined,
		/** delete data from the table: "audiences" */
		delete_audiences?: GraphQLTypes["audiences_mutation_response"] | undefined,
		/** delete single row from the table: "audiences" */
		delete_audiences_by_pk?: GraphQLTypes["audiences"] | undefined,
		/** delete data from the table: "blog" */
		delete_blog?: GraphQLTypes["blog_mutation_response"] | undefined,
		/** delete data from the table: "blogCategory" */
		delete_blogCategory?: GraphQLTypes["blogCategory_mutation_response"] | undefined,
		/** delete single row from the table: "blogCategory" */
		delete_blogCategory_by_pk?: GraphQLTypes["blogCategory"] | undefined,
		/** delete single row from the table: "blog" */
		delete_blog_by_pk?: GraphQLTypes["blog"] | undefined,
		/** delete data from the table: "brands" */
		delete_brands?: GraphQLTypes["brands_mutation_response"] | undefined,
		/** delete single row from the table: "brands" */
		delete_brands_by_pk?: GraphQLTypes["brands"] | undefined,
		/** delete data from the table: "campaign" */
		delete_campaign?: GraphQLTypes["campaign_mutation_response"] | undefined,
		/** delete single row from the table: "campaign" */
		delete_campaign_by_pk?: GraphQLTypes["campaign"] | undefined,
		/** delete data from the table: "channels" */
		delete_channels?: GraphQLTypes["channels_mutation_response"] | undefined,
		/** delete single row from the table: "channels" */
		delete_channels_by_pk?: GraphQLTypes["channels"] | undefined,
		/** delete data from the table: "costTable" */
		delete_costTable?: GraphQLTypes["costTable_mutation_response"] | undefined,
		/** delete single row from the table: "costTable" */
		delete_costTable_by_pk?: GraphQLTypes["costTable"] | undefined,
		/** delete data from the table: "deal" */
		delete_deal?: GraphQLTypes["deal_mutation_response"] | undefined,
		/** delete data from the table: "dealStatus" */
		delete_dealStatus?: GraphQLTypes["dealStatus_mutation_response"] | undefined,
		/** delete single row from the table: "dealStatus" */
		delete_dealStatus_by_pk?: GraphQLTypes["dealStatus"] | undefined,
		/** delete single row from the table: "deal" */
		delete_deal_by_pk?: GraphQLTypes["deal"] | undefined,
		/** delete data from the table: "displayCategory" */
		delete_displayCategory?: GraphQLTypes["displayCategory_mutation_response"] | undefined,
		/** delete single row from the table: "displayCategory" */
		delete_displayCategory_by_pk?: GraphQLTypes["displayCategory"] | undefined,
		/** delete data from the table: "displayDimension" */
		delete_displayDimension?: GraphQLTypes["displayDimension_mutation_response"] | undefined,
		/** delete single row from the table: "displayDimension" */
		delete_displayDimension_by_pk?: GraphQLTypes["displayDimension"] | undefined,
		/** delete data from the table: "displayFormats" */
		delete_displayFormats?: GraphQLTypes["displayFormats_mutation_response"] | undefined,
		/** delete single row from the table: "displayFormats" */
		delete_displayFormats_by_pk?: GraphQLTypes["displayFormats"] | undefined,
		/** delete data from the table: "displayTemplate" */
		delete_displayTemplate?: GraphQLTypes["displayTemplate_mutation_response"] | undefined,
		/** delete single row from the table: "displayTemplate" */
		delete_displayTemplate_by_pk?: GraphQLTypes["displayTemplate"] | undefined,
		/** delete data from the table: "interaction" */
		delete_interaction?: GraphQLTypes["interaction_mutation_response"] | undefined,
		/** delete single row from the table: "interaction" */
		delete_interaction_by_pk?: GraphQLTypes["interaction"] | undefined,
		/** delete data from the table: "landingPageTemplate" */
		delete_landingPageTemplate?: GraphQLTypes["landingPageTemplate_mutation_response"] | undefined,
		/** delete single row from the table: "landingPageTemplate" */
		delete_landingPageTemplate_by_pk?: GraphQLTypes["landingPageTemplate"] | undefined,
		/** delete data from the table: "newTemplateType" */
		delete_newTemplateType?: GraphQLTypes["newTemplateType_mutation_response"] | undefined,
		/** delete single row from the table: "newTemplateType" */
		delete_newTemplateType_by_pk?: GraphQLTypes["newTemplateType"] | undefined,
		/** delete data from the table: "openAIApiKey" */
		delete_openAIApiKey?: GraphQLTypes["openAIApiKey_mutation_response"] | undefined,
		/** delete single row from the table: "openAIApiKey" */
		delete_openAIApiKey_by_pk?: GraphQLTypes["openAIApiKey"] | undefined,
		/** delete data from the table: "pTemplate" */
		delete_pTemplate?: GraphQLTypes["pTemplate_mutation_response"] | undefined,
		/** delete single row from the table: "pTemplate" */
		delete_pTemplate_by_pk?: GraphQLTypes["pTemplate"] | undefined,
		/** delete data from the table: "pricingPlan" */
		delete_pricingPlan?: GraphQLTypes["pricingPlan_mutation_response"] | undefined,
		/** delete single row from the table: "pricingPlan" */
		delete_pricingPlan_by_pk?: GraphQLTypes["pricingPlan"] | undefined,
		/** delete data from the table: "products" */
		delete_products?: GraphQLTypes["products_mutation_response"] | undefined,
		/** delete single row from the table: "products" */
		delete_products_by_pk?: GraphQLTypes["products"] | undefined,
		/** delete data from the table: "project" */
		delete_project?: GraphQLTypes["project_mutation_response"] | undefined,
		/** delete data from the table: "projectImage" */
		delete_projectImage?: GraphQLTypes["projectImage_mutation_response"] | undefined,
		/** delete single row from the table: "projectImage" */
		delete_projectImage_by_pk?: GraphQLTypes["projectImage"] | undefined,
		/** delete single row from the table: "project" */
		delete_project_by_pk?: GraphQLTypes["project"] | undefined,
		/** delete data from the table: "task" */
		delete_task?: GraphQLTypes["task_mutation_response"] | undefined,
		/** delete single row from the table: "task" */
		delete_task_by_pk?: GraphQLTypes["task"] | undefined,
		/** delete data from the table: "textTemplateDisplayFormat" */
		delete_textTemplateDisplayFormat?: GraphQLTypes["textTemplateDisplayFormat_mutation_response"] | undefined,
		/** delete single row from the table: "textTemplateDisplayFormat" */
		delete_textTemplateDisplayFormat_by_pk?: GraphQLTypes["textTemplateDisplayFormat"] | undefined,
		/** delete data from the table: "userAppIntegration" */
		delete_userAppIntegration?: GraphQLTypes["userAppIntegration_mutation_response"] | undefined,
		/** delete single row from the table: "userAppIntegration" */
		delete_userAppIntegration_by_pk?: GraphQLTypes["userAppIntegration"] | undefined,
		/** delete data from the table: "userCreditUsage" */
		delete_userCreditUsage?: GraphQLTypes["userCreditUsage_mutation_response"] | undefined,
		/** delete data from the table: "userCreditUsageSummary" */
		delete_userCreditUsageSummary?: GraphQLTypes["userCreditUsageSummary_mutation_response"] | undefined,
		/** delete single row from the table: "userCreditUsageSummary" */
		delete_userCreditUsageSummary_by_pk?: GraphQLTypes["userCreditUsageSummary"] | undefined,
		/** delete single row from the table: "userCreditUsage" */
		delete_userCreditUsage_by_pk?: GraphQLTypes["userCreditUsage"] | undefined,
		/** delete data from the table: "userCredits" */
		delete_userCredits?: GraphQLTypes["userCredits_mutation_response"] | undefined,
		/** delete single row from the table: "userCredits" */
		delete_userCredits_by_pk?: GraphQLTypes["userCredits"] | undefined,
		/** delete data from the table: "userFeedback" */
		delete_userFeedback?: GraphQLTypes["userFeedback_mutation_response"] | undefined,
		/** delete single row from the table: "userFeedback" */
		delete_userFeedback_by_pk?: GraphQLTypes["userFeedback"] | undefined,
		/** delete data from the table: "userMedia" */
		delete_userMedia?: GraphQLTypes["userMedia_mutation_response"] | undefined,
		/** delete single row from the table: "userMedia" */
		delete_userMedia_by_pk?: GraphQLTypes["userMedia"] | undefined,
		/** delete data from the table: "userMetadata" */
		delete_userMetadata?: GraphQLTypes["userMetadata_mutation_response"] | undefined,
		/** delete single row from the table: "userMetadata" */
		delete_userMetadata_by_pk?: GraphQLTypes["userMetadata"] | undefined,
		/** delete data from the table: "userOnboarding" */
		delete_userOnboarding?: GraphQLTypes["userOnboarding_mutation_response"] | undefined,
		/** delete single row from the table: "userOnboarding" */
		delete_userOnboarding_by_pk?: GraphQLTypes["userOnboarding"] | undefined,
		/** delete data from the table: "userTemplateUsage" */
		delete_userTemplateUsage?: GraphQLTypes["userTemplateUsage_mutation_response"] | undefined,
		/** delete single row from the table: "userTemplateUsage" */
		delete_userTemplateUsage_by_pk?: GraphQLTypes["userTemplateUsage"] | undefined,
		/** delete data from the table: "website" */
		delete_website?: GraphQLTypes["website_mutation_response"] | undefined,
		/** delete single row from the table: "website" */
		delete_website_by_pk?: GraphQLTypes["website"] | undefined,
		exportToWordpress?: GraphQLTypes["ExportToWordpressOutput"] | undefined,
		facebookAuthCallback?: GraphQLTypes["FacebookAuthCallbackOutput"] | undefined,
		finishOnboard?: GraphQLTypes["FinishOnboardOutput"] | undefined,
		generateCalendarEx?: GraphQLTypes["GenerateCalendarExOutput"] | undefined,
		/** generateFromTextTemplate */
		generateFromTextTemplate?: GraphQLTypes["GenerateFromTextTemplateOutput"] | undefined,
		generateNextMonthCalendar: GraphQLTypes["uuid"],
		getInstagramAuthCallback?: GraphQLTypes["GetInstagramAuthCallbackOutput"] | undefined,
		/** execute VOLATILE function "get_next_openai_apikey" which returns "openAIApiKey" */
		get_next_openai_apikey: Array<GraphQLTypes["openAIApiKey"]>,
		/** insert a single row into the table: "auth.providers" */
		insertAuthProvider?: GraphQLTypes["authProviders"] | undefined,
		/** insert a single row into the table: "auth.provider_requests" */
		insertAuthProviderRequest?: GraphQLTypes["authProviderRequests"] | undefined,
		/** insert data into the table: "auth.provider_requests" */
		insertAuthProviderRequests?: GraphQLTypes["authProviderRequests_mutation_response"] | undefined,
		/** insert data into the table: "auth.providers" */
		insertAuthProviders?: GraphQLTypes["authProviders_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.refresh_tokens" */
		insertAuthRefreshToken?: GraphQLTypes["authRefreshTokens"] | undefined,
		/** insert a single row into the table: "auth.refresh_token_types" */
		insertAuthRefreshTokenType?: GraphQLTypes["authRefreshTokenTypes"] | undefined,
		/** insert data into the table: "auth.refresh_token_types" */
		insertAuthRefreshTokenTypes?: GraphQLTypes["authRefreshTokenTypes_mutation_response"] | undefined,
		/** insert data into the table: "auth.refresh_tokens" */
		insertAuthRefreshTokens?: GraphQLTypes["authRefreshTokens_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.roles" */
		insertAuthRole?: GraphQLTypes["authRoles"] | undefined,
		/** insert data into the table: "auth.roles" */
		insertAuthRoles?: GraphQLTypes["authRoles_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.user_providers" */
		insertAuthUserProvider?: GraphQLTypes["authUserProviders"] | undefined,
		/** insert data into the table: "auth.user_providers" */
		insertAuthUserProviders?: GraphQLTypes["authUserProviders_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.user_roles" */
		insertAuthUserRole?: GraphQLTypes["authUserRoles"] | undefined,
		/** insert data into the table: "auth.user_roles" */
		insertAuthUserRoles?: GraphQLTypes["authUserRoles_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.user_security_keys" */
		insertAuthUserSecurityKey?: GraphQLTypes["authUserSecurityKeys"] | undefined,
		/** insert data into the table: "auth.user_security_keys" */
		insertAuthUserSecurityKeys?: GraphQLTypes["authUserSecurityKeys_mutation_response"] | undefined,
		/** insert a single row into the table: "storage.buckets" */
		insertBucket?: GraphQLTypes["buckets"] | undefined,
		/** insert data into the table: "storage.buckets" */
		insertBuckets?: GraphQLTypes["buckets_mutation_response"] | undefined,
		/** insert a single row into the table: "storage.files" */
		insertFile?: GraphQLTypes["files"] | undefined,
		/** insert data into the table: "storage.files" */
		insertFiles?: GraphQLTypes["files_mutation_response"] | undefined,
		/** insert a single row into the table: "auth.users" */
		insertUser?: GraphQLTypes["users"] | undefined,
		/** insert data into the table: "auth.users" */
		insertUsers?: GraphQLTypes["users_mutation_response"] | undefined,
		/** insert a single row into the table: "storage.virus" */
		insertVirus?: GraphQLTypes["virus"] | undefined,
		/** insert data into the table: "storage.virus" */
		insertViruses?: GraphQLTypes["virus_mutation_response"] | undefined,
		/** insert data into the table: "AppIntegration" */
		insert_AppIntegration?: GraphQLTypes["AppIntegration_mutation_response"] | undefined,
		/** insert a single row into the table: "AppIntegration" */
		insert_AppIntegration_one?: GraphQLTypes["AppIntegration"] | undefined,
		/** insert data into the table: "Course" */
		insert_Course?: GraphQLTypes["Course_mutation_response"] | undefined,
		/** insert data into the table: "CourseCategory" */
		insert_CourseCategory?: GraphQLTypes["CourseCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "CourseCategory" */
		insert_CourseCategory_one?: GraphQLTypes["CourseCategory"] | undefined,
		/** insert a single row into the table: "Course" */
		insert_Course_one?: GraphQLTypes["Course"] | undefined,
		/** insert data into the table: "GeneratedPromptByTemplate" */
		insert_GeneratedPromptByTemplate?: GraphQLTypes["GeneratedPromptByTemplate_mutation_response"] | undefined,
		/** insert data into the table: "GeneratedPromptByTemplateImage" */
		insert_GeneratedPromptByTemplateImage?: GraphQLTypes["GeneratedPromptByTemplateImage_mutation_response"] | undefined,
		/** insert a single row into the table: "GeneratedPromptByTemplateImage" */
		insert_GeneratedPromptByTemplateImage_one?: GraphQLTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** insert a single row into the table: "GeneratedPromptByTemplate" */
		insert_GeneratedPromptByTemplate_one?: GraphQLTypes["GeneratedPromptByTemplate"] | undefined,
		/** insert data into the table: "TextGeneratorOption" */
		insert_TextGeneratorOption?: GraphQLTypes["TextGeneratorOption_mutation_response"] | undefined,
		/** insert data into the table: "TextGeneratorOptionEntry" */
		insert_TextGeneratorOptionEntry?: GraphQLTypes["TextGeneratorOptionEntry_mutation_response"] | undefined,
		/** insert a single row into the table: "TextGeneratorOptionEntry" */
		insert_TextGeneratorOptionEntry_one?: GraphQLTypes["TextGeneratorOptionEntry"] | undefined,
		/** insert a single row into the table: "TextGeneratorOption" */
		insert_TextGeneratorOption_one?: GraphQLTypes["TextGeneratorOption"] | undefined,
		/** insert data into the table: "TextPromptTemplate" */
		insert_TextPromptTemplate?: GraphQLTypes["TextPromptTemplate_mutation_response"] | undefined,
		/** insert data into the table: "TextPromptTemplateCategory" */
		insert_TextPromptTemplateCategory?: GraphQLTypes["TextPromptTemplateCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "TextPromptTemplateCategory" */
		insert_TextPromptTemplateCategory_one?: GraphQLTypes["TextPromptTemplateCategory"] | undefined,
		/** insert a single row into the table: "TextPromptTemplate" */
		insert_TextPromptTemplate_one?: GraphQLTypes["TextPromptTemplate"] | undefined,
		/** insert data into the table: "TextTemplateClientCategory" */
		insert_TextTemplateClientCategory?: GraphQLTypes["TextTemplateClientCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "TextTemplateClientCategory" */
		insert_TextTemplateClientCategory_one?: GraphQLTypes["TextTemplateClientCategory"] | undefined,
		/** insert data into the table: "audiences" */
		insert_audiences?: GraphQLTypes["audiences_mutation_response"] | undefined,
		/** insert a single row into the table: "audiences" */
		insert_audiences_one?: GraphQLTypes["audiences"] | undefined,
		/** insert data into the table: "blog" */
		insert_blog?: GraphQLTypes["blog_mutation_response"] | undefined,
		/** insert data into the table: "blogCategory" */
		insert_blogCategory?: GraphQLTypes["blogCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "blogCategory" */
		insert_blogCategory_one?: GraphQLTypes["blogCategory"] | undefined,
		/** insert a single row into the table: "blog" */
		insert_blog_one?: GraphQLTypes["blog"] | undefined,
		/** insert data into the table: "brands" */
		insert_brands?: GraphQLTypes["brands_mutation_response"] | undefined,
		/** insert a single row into the table: "brands" */
		insert_brands_one?: GraphQLTypes["brands"] | undefined,
		/** insert data into the table: "campaign" */
		insert_campaign?: GraphQLTypes["campaign_mutation_response"] | undefined,
		/** insert a single row into the table: "campaign" */
		insert_campaign_one?: GraphQLTypes["campaign"] | undefined,
		/** insert data into the table: "channels" */
		insert_channels?: GraphQLTypes["channels_mutation_response"] | undefined,
		/** insert a single row into the table: "channels" */
		insert_channels_one?: GraphQLTypes["channels"] | undefined,
		/** insert data into the table: "costTable" */
		insert_costTable?: GraphQLTypes["costTable_mutation_response"] | undefined,
		/** insert a single row into the table: "costTable" */
		insert_costTable_one?: GraphQLTypes["costTable"] | undefined,
		/** insert data into the table: "deal" */
		insert_deal?: GraphQLTypes["deal_mutation_response"] | undefined,
		/** insert data into the table: "dealStatus" */
		insert_dealStatus?: GraphQLTypes["dealStatus_mutation_response"] | undefined,
		/** insert a single row into the table: "dealStatus" */
		insert_dealStatus_one?: GraphQLTypes["dealStatus"] | undefined,
		/** insert a single row into the table: "deal" */
		insert_deal_one?: GraphQLTypes["deal"] | undefined,
		/** insert data into the table: "displayCategory" */
		insert_displayCategory?: GraphQLTypes["displayCategory_mutation_response"] | undefined,
		/** insert a single row into the table: "displayCategory" */
		insert_displayCategory_one?: GraphQLTypes["displayCategory"] | undefined,
		/** insert data into the table: "displayDimension" */
		insert_displayDimension?: GraphQLTypes["displayDimension_mutation_response"] | undefined,
		/** insert a single row into the table: "displayDimension" */
		insert_displayDimension_one?: GraphQLTypes["displayDimension"] | undefined,
		/** insert data into the table: "displayFormats" */
		insert_displayFormats?: GraphQLTypes["displayFormats_mutation_response"] | undefined,
		/** insert a single row into the table: "displayFormats" */
		insert_displayFormats_one?: GraphQLTypes["displayFormats"] | undefined,
		/** insert data into the table: "displayTemplate" */
		insert_displayTemplate?: GraphQLTypes["displayTemplate_mutation_response"] | undefined,
		/** insert a single row into the table: "displayTemplate" */
		insert_displayTemplate_one?: GraphQLTypes["displayTemplate"] | undefined,
		/** insert data into the table: "interaction" */
		insert_interaction?: GraphQLTypes["interaction_mutation_response"] | undefined,
		/** insert a single row into the table: "interaction" */
		insert_interaction_one?: GraphQLTypes["interaction"] | undefined,
		/** insert data into the table: "landingPageTemplate" */
		insert_landingPageTemplate?: GraphQLTypes["landingPageTemplate_mutation_response"] | undefined,
		/** insert a single row into the table: "landingPageTemplate" */
		insert_landingPageTemplate_one?: GraphQLTypes["landingPageTemplate"] | undefined,
		/** insert data into the table: "newTemplateType" */
		insert_newTemplateType?: GraphQLTypes["newTemplateType_mutation_response"] | undefined,
		/** insert a single row into the table: "newTemplateType" */
		insert_newTemplateType_one?: GraphQLTypes["newTemplateType"] | undefined,
		/** insert data into the table: "openAIApiKey" */
		insert_openAIApiKey?: GraphQLTypes["openAIApiKey_mutation_response"] | undefined,
		/** insert a single row into the table: "openAIApiKey" */
		insert_openAIApiKey_one?: GraphQLTypes["openAIApiKey"] | undefined,
		/** insert data into the table: "pTemplate" */
		insert_pTemplate?: GraphQLTypes["pTemplate_mutation_response"] | undefined,
		/** insert a single row into the table: "pTemplate" */
		insert_pTemplate_one?: GraphQLTypes["pTemplate"] | undefined,
		/** insert data into the table: "pricingPlan" */
		insert_pricingPlan?: GraphQLTypes["pricingPlan_mutation_response"] | undefined,
		/** insert a single row into the table: "pricingPlan" */
		insert_pricingPlan_one?: GraphQLTypes["pricingPlan"] | undefined,
		/** insert data into the table: "products" */
		insert_products?: GraphQLTypes["products_mutation_response"] | undefined,
		/** insert a single row into the table: "products" */
		insert_products_one?: GraphQLTypes["products"] | undefined,
		/** insert data into the table: "project" */
		insert_project?: GraphQLTypes["project_mutation_response"] | undefined,
		/** insert data into the table: "projectImage" */
		insert_projectImage?: GraphQLTypes["projectImage_mutation_response"] | undefined,
		/** insert a single row into the table: "projectImage" */
		insert_projectImage_one?: GraphQLTypes["projectImage"] | undefined,
		/** insert a single row into the table: "project" */
		insert_project_one?: GraphQLTypes["project"] | undefined,
		/** insert data into the table: "task" */
		insert_task?: GraphQLTypes["task_mutation_response"] | undefined,
		/** insert a single row into the table: "task" */
		insert_task_one?: GraphQLTypes["task"] | undefined,
		/** insert data into the table: "textTemplateDisplayFormat" */
		insert_textTemplateDisplayFormat?: GraphQLTypes["textTemplateDisplayFormat_mutation_response"] | undefined,
		/** insert a single row into the table: "textTemplateDisplayFormat" */
		insert_textTemplateDisplayFormat_one?: GraphQLTypes["textTemplateDisplayFormat"] | undefined,
		/** insert data into the table: "userAppIntegration" */
		insert_userAppIntegration?: GraphQLTypes["userAppIntegration_mutation_response"] | undefined,
		/** insert a single row into the table: "userAppIntegration" */
		insert_userAppIntegration_one?: GraphQLTypes["userAppIntegration"] | undefined,
		/** insert data into the table: "userCreditUsage" */
		insert_userCreditUsage?: GraphQLTypes["userCreditUsage_mutation_response"] | undefined,
		/** insert data into the table: "userCreditUsageSummary" */
		insert_userCreditUsageSummary?: GraphQLTypes["userCreditUsageSummary_mutation_response"] | undefined,
		/** insert a single row into the table: "userCreditUsageSummary" */
		insert_userCreditUsageSummary_one?: GraphQLTypes["userCreditUsageSummary"] | undefined,
		/** insert a single row into the table: "userCreditUsage" */
		insert_userCreditUsage_one?: GraphQLTypes["userCreditUsage"] | undefined,
		/** insert data into the table: "userCredits" */
		insert_userCredits?: GraphQLTypes["userCredits_mutation_response"] | undefined,
		/** insert a single row into the table: "userCredits" */
		insert_userCredits_one?: GraphQLTypes["userCredits"] | undefined,
		/** insert data into the table: "userFeedback" */
		insert_userFeedback?: GraphQLTypes["userFeedback_mutation_response"] | undefined,
		/** insert a single row into the table: "userFeedback" */
		insert_userFeedback_one?: GraphQLTypes["userFeedback"] | undefined,
		/** insert data into the table: "userMedia" */
		insert_userMedia?: GraphQLTypes["userMedia_mutation_response"] | undefined,
		/** insert a single row into the table: "userMedia" */
		insert_userMedia_one?: GraphQLTypes["userMedia"] | undefined,
		/** insert data into the table: "userMetadata" */
		insert_userMetadata?: GraphQLTypes["userMetadata_mutation_response"] | undefined,
		/** insert a single row into the table: "userMetadata" */
		insert_userMetadata_one?: GraphQLTypes["userMetadata"] | undefined,
		/** insert data into the table: "userOnboarding" */
		insert_userOnboarding?: GraphQLTypes["userOnboarding_mutation_response"] | undefined,
		/** insert a single row into the table: "userOnboarding" */
		insert_userOnboarding_one?: GraphQLTypes["userOnboarding"] | undefined,
		/** insert data into the table: "userTemplateUsage" */
		insert_userTemplateUsage?: GraphQLTypes["userTemplateUsage_mutation_response"] | undefined,
		/** insert a single row into the table: "userTemplateUsage" */
		insert_userTemplateUsage_one?: GraphQLTypes["userTemplateUsage"] | undefined,
		/** insert data into the table: "website" */
		insert_website?: GraphQLTypes["website_mutation_response"] | undefined,
		/** insert a single row into the table: "website" */
		insert_website_one?: GraphQLTypes["website"] | undefined,
		linkedInTokenCallback?: GraphQLTypes["LinkedInTokenCallbackOutput"] | undefined,
		postCarouselToInstagram?: GraphQLTypes["PostCarouselToInstagramOutput"] | undefined,
		postFeedToInstagram?: GraphQLTypes["PostFeedToInstagramOutput"] | undefined,
		postStoryToInstagram?: GraphQLTypes["PostStoryToInstagramOutput"] | undefined,
		postToFacebook?: GraphQLTypes["PostToFacebookOutput"] | undefined,
		postToLinkedIn?: GraphQLTypes["PostToLinkedInOutput"] | undefined,
		publishOrScheduleProject?: GraphQLTypes["PublishOrScheduleProjectOutput"] | undefined,
		/** pureprompt */
		purePrompt?: GraphQLTypes["PromptOutput"] | undefined,
		renderAndSaveDisplayImage?: GraphQLTypes["RenderAndSaveDisplayImageOutput"] | undefined,
		saveAndGeneratePolotnoImage?: GraphQLTypes["SaveAndGeneratePolotnoImageOutput"] | undefined,
		selectNewDisplay?: GraphQLTypes["SelectNewDisplayOutput"] | undefined,
		/** update single row of the table: "auth.providers" */
		updateAuthProvider?: GraphQLTypes["authProviders"] | undefined,
		/** update single row of the table: "auth.provider_requests" */
		updateAuthProviderRequest?: GraphQLTypes["authProviderRequests"] | undefined,
		/** update data of the table: "auth.provider_requests" */
		updateAuthProviderRequests?: GraphQLTypes["authProviderRequests_mutation_response"] | undefined,
		/** update data of the table: "auth.providers" */
		updateAuthProviders?: GraphQLTypes["authProviders_mutation_response"] | undefined,
		/** update single row of the table: "auth.refresh_tokens" */
		updateAuthRefreshToken?: GraphQLTypes["authRefreshTokens"] | undefined,
		/** update single row of the table: "auth.refresh_token_types" */
		updateAuthRefreshTokenType?: GraphQLTypes["authRefreshTokenTypes"] | undefined,
		/** update data of the table: "auth.refresh_token_types" */
		updateAuthRefreshTokenTypes?: GraphQLTypes["authRefreshTokenTypes_mutation_response"] | undefined,
		/** update data of the table: "auth.refresh_tokens" */
		updateAuthRefreshTokens?: GraphQLTypes["authRefreshTokens_mutation_response"] | undefined,
		/** update single row of the table: "auth.roles" */
		updateAuthRole?: GraphQLTypes["authRoles"] | undefined,
		/** update data of the table: "auth.roles" */
		updateAuthRoles?: GraphQLTypes["authRoles_mutation_response"] | undefined,
		/** update single row of the table: "auth.user_providers" */
		updateAuthUserProvider?: GraphQLTypes["authUserProviders"] | undefined,
		/** update data of the table: "auth.user_providers" */
		updateAuthUserProviders?: GraphQLTypes["authUserProviders_mutation_response"] | undefined,
		/** update single row of the table: "auth.user_roles" */
		updateAuthUserRole?: GraphQLTypes["authUserRoles"] | undefined,
		/** update data of the table: "auth.user_roles" */
		updateAuthUserRoles?: GraphQLTypes["authUserRoles_mutation_response"] | undefined,
		/** update single row of the table: "auth.user_security_keys" */
		updateAuthUserSecurityKey?: GraphQLTypes["authUserSecurityKeys"] | undefined,
		/** update data of the table: "auth.user_security_keys" */
		updateAuthUserSecurityKeys?: GraphQLTypes["authUserSecurityKeys_mutation_response"] | undefined,
		/** update single row of the table: "storage.buckets" */
		updateBucket?: GraphQLTypes["buckets"] | undefined,
		/** update data of the table: "storage.buckets" */
		updateBuckets?: GraphQLTypes["buckets_mutation_response"] | undefined,
		/** update single row of the table: "storage.files" */
		updateFile?: GraphQLTypes["files"] | undefined,
		/** update data of the table: "storage.files" */
		updateFiles?: GraphQLTypes["files_mutation_response"] | undefined,
		/** update single row of the table: "auth.users" */
		updateUser?: GraphQLTypes["users"] | undefined,
		updateUserAvatar?: GraphQLTypes["UpdateUserAvatarOutput"] | undefined,
		/** update data of the table: "auth.users" */
		updateUsers?: GraphQLTypes["users_mutation_response"] | undefined,
		/** update single row of the table: "storage.virus" */
		updateVirus?: GraphQLTypes["virus"] | undefined,
		/** update data of the table: "storage.virus" */
		updateViruses?: GraphQLTypes["virus_mutation_response"] | undefined,
		/** update data of the table: "AppIntegration" */
		update_AppIntegration?: GraphQLTypes["AppIntegration_mutation_response"] | undefined,
		/** update single row of the table: "AppIntegration" */
		update_AppIntegration_by_pk?: GraphQLTypes["AppIntegration"] | undefined,
		/** update multiples rows of table: "AppIntegration" */
		update_AppIntegration_many?: Array<GraphQLTypes["AppIntegration_mutation_response"] | undefined> | undefined,
		/** update data of the table: "Course" */
		update_Course?: GraphQLTypes["Course_mutation_response"] | undefined,
		/** update data of the table: "CourseCategory" */
		update_CourseCategory?: GraphQLTypes["CourseCategory_mutation_response"] | undefined,
		/** update single row of the table: "CourseCategory" */
		update_CourseCategory_by_pk?: GraphQLTypes["CourseCategory"] | undefined,
		/** update multiples rows of table: "CourseCategory" */
		update_CourseCategory_many?: Array<GraphQLTypes["CourseCategory_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "Course" */
		update_Course_by_pk?: GraphQLTypes["Course"] | undefined,
		/** update multiples rows of table: "Course" */
		update_Course_many?: Array<GraphQLTypes["Course_mutation_response"] | undefined> | undefined,
		/** update data of the table: "GeneratedPromptByTemplate" */
		update_GeneratedPromptByTemplate?: GraphQLTypes["GeneratedPromptByTemplate_mutation_response"] | undefined,
		/** update data of the table: "GeneratedPromptByTemplateImage" */
		update_GeneratedPromptByTemplateImage?: GraphQLTypes["GeneratedPromptByTemplateImage_mutation_response"] | undefined,
		/** update single row of the table: "GeneratedPromptByTemplateImage" */
		update_GeneratedPromptByTemplateImage_by_pk?: GraphQLTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** update multiples rows of table: "GeneratedPromptByTemplateImage" */
		update_GeneratedPromptByTemplateImage_many?: Array<GraphQLTypes["GeneratedPromptByTemplateImage_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "GeneratedPromptByTemplate" */
		update_GeneratedPromptByTemplate_by_pk?: GraphQLTypes["GeneratedPromptByTemplate"] | undefined,
		/** update multiples rows of table: "GeneratedPromptByTemplate" */
		update_GeneratedPromptByTemplate_many?: Array<GraphQLTypes["GeneratedPromptByTemplate_mutation_response"] | undefined> | undefined,
		/** update data of the table: "TextGeneratorOption" */
		update_TextGeneratorOption?: GraphQLTypes["TextGeneratorOption_mutation_response"] | undefined,
		/** update data of the table: "TextGeneratorOptionEntry" */
		update_TextGeneratorOptionEntry?: GraphQLTypes["TextGeneratorOptionEntry_mutation_response"] | undefined,
		/** update single row of the table: "TextGeneratorOptionEntry" */
		update_TextGeneratorOptionEntry_by_pk?: GraphQLTypes["TextGeneratorOptionEntry"] | undefined,
		/** update multiples rows of table: "TextGeneratorOptionEntry" */
		update_TextGeneratorOptionEntry_many?: Array<GraphQLTypes["TextGeneratorOptionEntry_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "TextGeneratorOption" */
		update_TextGeneratorOption_by_pk?: GraphQLTypes["TextGeneratorOption"] | undefined,
		/** update multiples rows of table: "TextGeneratorOption" */
		update_TextGeneratorOption_many?: Array<GraphQLTypes["TextGeneratorOption_mutation_response"] | undefined> | undefined,
		/** update data of the table: "TextPromptTemplate" */
		update_TextPromptTemplate?: GraphQLTypes["TextPromptTemplate_mutation_response"] | undefined,
		/** update data of the table: "TextPromptTemplateCategory" */
		update_TextPromptTemplateCategory?: GraphQLTypes["TextPromptTemplateCategory_mutation_response"] | undefined,
		/** update single row of the table: "TextPromptTemplateCategory" */
		update_TextPromptTemplateCategory_by_pk?: GraphQLTypes["TextPromptTemplateCategory"] | undefined,
		/** update multiples rows of table: "TextPromptTemplateCategory" */
		update_TextPromptTemplateCategory_many?: Array<GraphQLTypes["TextPromptTemplateCategory_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "TextPromptTemplate" */
		update_TextPromptTemplate_by_pk?: GraphQLTypes["TextPromptTemplate"] | undefined,
		/** update multiples rows of table: "TextPromptTemplate" */
		update_TextPromptTemplate_many?: Array<GraphQLTypes["TextPromptTemplate_mutation_response"] | undefined> | undefined,
		/** update data of the table: "TextTemplateClientCategory" */
		update_TextTemplateClientCategory?: GraphQLTypes["TextTemplateClientCategory_mutation_response"] | undefined,
		/** update single row of the table: "TextTemplateClientCategory" */
		update_TextTemplateClientCategory_by_pk?: GraphQLTypes["TextTemplateClientCategory"] | undefined,
		/** update multiples rows of table: "TextTemplateClientCategory" */
		update_TextTemplateClientCategory_many?: Array<GraphQLTypes["TextTemplateClientCategory_mutation_response"] | undefined> | undefined,
		/** update data of the table: "audiences" */
		update_audiences?: GraphQLTypes["audiences_mutation_response"] | undefined,
		/** update single row of the table: "audiences" */
		update_audiences_by_pk?: GraphQLTypes["audiences"] | undefined,
		/** update multiples rows of table: "audiences" */
		update_audiences_many?: Array<GraphQLTypes["audiences_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.provider_requests" */
		update_authProviderRequests_many?: Array<GraphQLTypes["authProviderRequests_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.providers" */
		update_authProviders_many?: Array<GraphQLTypes["authProviders_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.refresh_token_types" */
		update_authRefreshTokenTypes_many?: Array<GraphQLTypes["authRefreshTokenTypes_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.refresh_tokens" */
		update_authRefreshTokens_many?: Array<GraphQLTypes["authRefreshTokens_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.roles" */
		update_authRoles_many?: Array<GraphQLTypes["authRoles_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.user_providers" */
		update_authUserProviders_many?: Array<GraphQLTypes["authUserProviders_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.user_roles" */
		update_authUserRoles_many?: Array<GraphQLTypes["authUserRoles_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.user_security_keys" */
		update_authUserSecurityKeys_many?: Array<GraphQLTypes["authUserSecurityKeys_mutation_response"] | undefined> | undefined,
		/** update data of the table: "blog" */
		update_blog?: GraphQLTypes["blog_mutation_response"] | undefined,
		/** update data of the table: "blogCategory" */
		update_blogCategory?: GraphQLTypes["blogCategory_mutation_response"] | undefined,
		/** update single row of the table: "blogCategory" */
		update_blogCategory_by_pk?: GraphQLTypes["blogCategory"] | undefined,
		/** update multiples rows of table: "blogCategory" */
		update_blogCategory_many?: Array<GraphQLTypes["blogCategory_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "blog" */
		update_blog_by_pk?: GraphQLTypes["blog"] | undefined,
		/** update multiples rows of table: "blog" */
		update_blog_many?: Array<GraphQLTypes["blog_mutation_response"] | undefined> | undefined,
		/** update data of the table: "brands" */
		update_brands?: GraphQLTypes["brands_mutation_response"] | undefined,
		/** update single row of the table: "brands" */
		update_brands_by_pk?: GraphQLTypes["brands"] | undefined,
		/** update multiples rows of table: "brands" */
		update_brands_many?: Array<GraphQLTypes["brands_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "storage.buckets" */
		update_buckets_many?: Array<GraphQLTypes["buckets_mutation_response"] | undefined> | undefined,
		/** update data of the table: "campaign" */
		update_campaign?: GraphQLTypes["campaign_mutation_response"] | undefined,
		/** update single row of the table: "campaign" */
		update_campaign_by_pk?: GraphQLTypes["campaign"] | undefined,
		/** update multiples rows of table: "campaign" */
		update_campaign_many?: Array<GraphQLTypes["campaign_mutation_response"] | undefined> | undefined,
		/** update data of the table: "channels" */
		update_channels?: GraphQLTypes["channels_mutation_response"] | undefined,
		/** update single row of the table: "channels" */
		update_channels_by_pk?: GraphQLTypes["channels"] | undefined,
		/** update multiples rows of table: "channels" */
		update_channels_many?: Array<GraphQLTypes["channels_mutation_response"] | undefined> | undefined,
		/** update data of the table: "costTable" */
		update_costTable?: GraphQLTypes["costTable_mutation_response"] | undefined,
		/** update single row of the table: "costTable" */
		update_costTable_by_pk?: GraphQLTypes["costTable"] | undefined,
		/** update multiples rows of table: "costTable" */
		update_costTable_many?: Array<GraphQLTypes["costTable_mutation_response"] | undefined> | undefined,
		/** update data of the table: "deal" */
		update_deal?: GraphQLTypes["deal_mutation_response"] | undefined,
		/** update data of the table: "dealStatus" */
		update_dealStatus?: GraphQLTypes["dealStatus_mutation_response"] | undefined,
		/** update single row of the table: "dealStatus" */
		update_dealStatus_by_pk?: GraphQLTypes["dealStatus"] | undefined,
		/** update multiples rows of table: "dealStatus" */
		update_dealStatus_many?: Array<GraphQLTypes["dealStatus_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "deal" */
		update_deal_by_pk?: GraphQLTypes["deal"] | undefined,
		/** update multiples rows of table: "deal" */
		update_deal_many?: Array<GraphQLTypes["deal_mutation_response"] | undefined> | undefined,
		/** update data of the table: "displayCategory" */
		update_displayCategory?: GraphQLTypes["displayCategory_mutation_response"] | undefined,
		/** update single row of the table: "displayCategory" */
		update_displayCategory_by_pk?: GraphQLTypes["displayCategory"] | undefined,
		/** update multiples rows of table: "displayCategory" */
		update_displayCategory_many?: Array<GraphQLTypes["displayCategory_mutation_response"] | undefined> | undefined,
		/** update data of the table: "displayDimension" */
		update_displayDimension?: GraphQLTypes["displayDimension_mutation_response"] | undefined,
		/** update single row of the table: "displayDimension" */
		update_displayDimension_by_pk?: GraphQLTypes["displayDimension"] | undefined,
		/** update multiples rows of table: "displayDimension" */
		update_displayDimension_many?: Array<GraphQLTypes["displayDimension_mutation_response"] | undefined> | undefined,
		/** update data of the table: "displayFormats" */
		update_displayFormats?: GraphQLTypes["displayFormats_mutation_response"] | undefined,
		/** update single row of the table: "displayFormats" */
		update_displayFormats_by_pk?: GraphQLTypes["displayFormats"] | undefined,
		/** update multiples rows of table: "displayFormats" */
		update_displayFormats_many?: Array<GraphQLTypes["displayFormats_mutation_response"] | undefined> | undefined,
		/** update data of the table: "displayTemplate" */
		update_displayTemplate?: GraphQLTypes["displayTemplate_mutation_response"] | undefined,
		/** update single row of the table: "displayTemplate" */
		update_displayTemplate_by_pk?: GraphQLTypes["displayTemplate"] | undefined,
		/** update multiples rows of table: "displayTemplate" */
		update_displayTemplate_many?: Array<GraphQLTypes["displayTemplate_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "storage.files" */
		update_files_many?: Array<GraphQLTypes["files_mutation_response"] | undefined> | undefined,
		/** update data of the table: "interaction" */
		update_interaction?: GraphQLTypes["interaction_mutation_response"] | undefined,
		/** update single row of the table: "interaction" */
		update_interaction_by_pk?: GraphQLTypes["interaction"] | undefined,
		/** update multiples rows of table: "interaction" */
		update_interaction_many?: Array<GraphQLTypes["interaction_mutation_response"] | undefined> | undefined,
		/** update data of the table: "landingPageTemplate" */
		update_landingPageTemplate?: GraphQLTypes["landingPageTemplate_mutation_response"] | undefined,
		/** update single row of the table: "landingPageTemplate" */
		update_landingPageTemplate_by_pk?: GraphQLTypes["landingPageTemplate"] | undefined,
		/** update multiples rows of table: "landingPageTemplate" */
		update_landingPageTemplate_many?: Array<GraphQLTypes["landingPageTemplate_mutation_response"] | undefined> | undefined,
		/** update data of the table: "newTemplateType" */
		update_newTemplateType?: GraphQLTypes["newTemplateType_mutation_response"] | undefined,
		/** update single row of the table: "newTemplateType" */
		update_newTemplateType_by_pk?: GraphQLTypes["newTemplateType"] | undefined,
		/** update multiples rows of table: "newTemplateType" */
		update_newTemplateType_many?: Array<GraphQLTypes["newTemplateType_mutation_response"] | undefined> | undefined,
		/** update data of the table: "openAIApiKey" */
		update_openAIApiKey?: GraphQLTypes["openAIApiKey_mutation_response"] | undefined,
		/** update single row of the table: "openAIApiKey" */
		update_openAIApiKey_by_pk?: GraphQLTypes["openAIApiKey"] | undefined,
		/** update multiples rows of table: "openAIApiKey" */
		update_openAIApiKey_many?: Array<GraphQLTypes["openAIApiKey_mutation_response"] | undefined> | undefined,
		/** update data of the table: "pTemplate" */
		update_pTemplate?: GraphQLTypes["pTemplate_mutation_response"] | undefined,
		/** update single row of the table: "pTemplate" */
		update_pTemplate_by_pk?: GraphQLTypes["pTemplate"] | undefined,
		/** update multiples rows of table: "pTemplate" */
		update_pTemplate_many?: Array<GraphQLTypes["pTemplate_mutation_response"] | undefined> | undefined,
		/** update data of the table: "pricingPlan" */
		update_pricingPlan?: GraphQLTypes["pricingPlan_mutation_response"] | undefined,
		/** update single row of the table: "pricingPlan" */
		update_pricingPlan_by_pk?: GraphQLTypes["pricingPlan"] | undefined,
		/** update multiples rows of table: "pricingPlan" */
		update_pricingPlan_many?: Array<GraphQLTypes["pricingPlan_mutation_response"] | undefined> | undefined,
		/** update data of the table: "products" */
		update_products?: GraphQLTypes["products_mutation_response"] | undefined,
		/** update single row of the table: "products" */
		update_products_by_pk?: GraphQLTypes["products"] | undefined,
		/** update multiples rows of table: "products" */
		update_products_many?: Array<GraphQLTypes["products_mutation_response"] | undefined> | undefined,
		/** update data of the table: "project" */
		update_project?: GraphQLTypes["project_mutation_response"] | undefined,
		/** update data of the table: "projectImage" */
		update_projectImage?: GraphQLTypes["projectImage_mutation_response"] | undefined,
		/** update single row of the table: "projectImage" */
		update_projectImage_by_pk?: GraphQLTypes["projectImage"] | undefined,
		/** update multiples rows of table: "projectImage" */
		update_projectImage_many?: Array<GraphQLTypes["projectImage_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "project" */
		update_project_by_pk?: GraphQLTypes["project"] | undefined,
		/** update multiples rows of table: "project" */
		update_project_many?: Array<GraphQLTypes["project_mutation_response"] | undefined> | undefined,
		/** update data of the table: "task" */
		update_task?: GraphQLTypes["task_mutation_response"] | undefined,
		/** update single row of the table: "task" */
		update_task_by_pk?: GraphQLTypes["task"] | undefined,
		/** update multiples rows of table: "task" */
		update_task_many?: Array<GraphQLTypes["task_mutation_response"] | undefined> | undefined,
		/** update data of the table: "textTemplateDisplayFormat" */
		update_textTemplateDisplayFormat?: GraphQLTypes["textTemplateDisplayFormat_mutation_response"] | undefined,
		/** update single row of the table: "textTemplateDisplayFormat" */
		update_textTemplateDisplayFormat_by_pk?: GraphQLTypes["textTemplateDisplayFormat"] | undefined,
		/** update multiples rows of table: "textTemplateDisplayFormat" */
		update_textTemplateDisplayFormat_many?: Array<GraphQLTypes["textTemplateDisplayFormat_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userAppIntegration" */
		update_userAppIntegration?: GraphQLTypes["userAppIntegration_mutation_response"] | undefined,
		/** update single row of the table: "userAppIntegration" */
		update_userAppIntegration_by_pk?: GraphQLTypes["userAppIntegration"] | undefined,
		/** update multiples rows of table: "userAppIntegration" */
		update_userAppIntegration_many?: Array<GraphQLTypes["userAppIntegration_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userCreditUsage" */
		update_userCreditUsage?: GraphQLTypes["userCreditUsage_mutation_response"] | undefined,
		/** update data of the table: "userCreditUsageSummary" */
		update_userCreditUsageSummary?: GraphQLTypes["userCreditUsageSummary_mutation_response"] | undefined,
		/** update single row of the table: "userCreditUsageSummary" */
		update_userCreditUsageSummary_by_pk?: GraphQLTypes["userCreditUsageSummary"] | undefined,
		/** update multiples rows of table: "userCreditUsageSummary" */
		update_userCreditUsageSummary_many?: Array<GraphQLTypes["userCreditUsageSummary_mutation_response"] | undefined> | undefined,
		/** update single row of the table: "userCreditUsage" */
		update_userCreditUsage_by_pk?: GraphQLTypes["userCreditUsage"] | undefined,
		/** update multiples rows of table: "userCreditUsage" */
		update_userCreditUsage_many?: Array<GraphQLTypes["userCreditUsage_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userCredits" */
		update_userCredits?: GraphQLTypes["userCredits_mutation_response"] | undefined,
		/** update single row of the table: "userCredits" */
		update_userCredits_by_pk?: GraphQLTypes["userCredits"] | undefined,
		/** update multiples rows of table: "userCredits" */
		update_userCredits_many?: Array<GraphQLTypes["userCredits_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userFeedback" */
		update_userFeedback?: GraphQLTypes["userFeedback_mutation_response"] | undefined,
		/** update single row of the table: "userFeedback" */
		update_userFeedback_by_pk?: GraphQLTypes["userFeedback"] | undefined,
		/** update multiples rows of table: "userFeedback" */
		update_userFeedback_many?: Array<GraphQLTypes["userFeedback_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userMedia" */
		update_userMedia?: GraphQLTypes["userMedia_mutation_response"] | undefined,
		/** update single row of the table: "userMedia" */
		update_userMedia_by_pk?: GraphQLTypes["userMedia"] | undefined,
		/** update multiples rows of table: "userMedia" */
		update_userMedia_many?: Array<GraphQLTypes["userMedia_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userMetadata" */
		update_userMetadata?: GraphQLTypes["userMetadata_mutation_response"] | undefined,
		/** update single row of the table: "userMetadata" */
		update_userMetadata_by_pk?: GraphQLTypes["userMetadata"] | undefined,
		/** update multiples rows of table: "userMetadata" */
		update_userMetadata_many?: Array<GraphQLTypes["userMetadata_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userOnboarding" */
		update_userOnboarding?: GraphQLTypes["userOnboarding_mutation_response"] | undefined,
		/** update single row of the table: "userOnboarding" */
		update_userOnboarding_by_pk?: GraphQLTypes["userOnboarding"] | undefined,
		/** update multiples rows of table: "userOnboarding" */
		update_userOnboarding_many?: Array<GraphQLTypes["userOnboarding_mutation_response"] | undefined> | undefined,
		/** update data of the table: "userTemplateUsage" */
		update_userTemplateUsage?: GraphQLTypes["userTemplateUsage_mutation_response"] | undefined,
		/** update single row of the table: "userTemplateUsage" */
		update_userTemplateUsage_by_pk?: GraphQLTypes["userTemplateUsage"] | undefined,
		/** update multiples rows of table: "userTemplateUsage" */
		update_userTemplateUsage_many?: Array<GraphQLTypes["userTemplateUsage_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "auth.users" */
		update_users_many?: Array<GraphQLTypes["users_mutation_response"] | undefined> | undefined,
		/** update multiples rows of table: "storage.virus" */
		update_virus_many?: Array<GraphQLTypes["virus_mutation_response"] | undefined> | undefined,
		/** update data of the table: "website" */
		update_website?: GraphQLTypes["website_mutation_response"] | undefined,
		/** update single row of the table: "website" */
		update_website_by_pk?: GraphQLTypes["website"] | undefined,
		/** update multiples rows of table: "website" */
		update_website_many?: Array<GraphQLTypes["website_mutation_response"] | undefined> | undefined,
		upsertAudience?: GraphQLTypes["UpsertAudienceOutput"] | undefined,
		upsertBrand?: GraphQLTypes["upsertBrandOutput"] | undefined
	};
	/** columns and relationships of "newTemplateType" */
	["newTemplateType"]: {
		__typename: "newTemplateType",
		channel: string,
		created_at: GraphQLTypes["timestamptz"],
		creditPrice: number,
		description: string,
		icon: string,
		id: GraphQLTypes["uuid"],
		name: string,
		saleCreditPrice: number,
		thumbnailFileId?: GraphQLTypes["uuid"] | undefined,
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** aggregated selection of "newTemplateType" */
	["newTemplateType_aggregate"]: {
		__typename: "newTemplateType_aggregate",
		aggregate?: GraphQLTypes["newTemplateType_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["newTemplateType"]>
	};
	/** aggregate fields of "newTemplateType" */
	["newTemplateType_aggregate_fields"]: {
		__typename: "newTemplateType_aggregate_fields",
		avg?: GraphQLTypes["newTemplateType_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["newTemplateType_max_fields"] | undefined,
		min?: GraphQLTypes["newTemplateType_min_fields"] | undefined,
		stddev?: GraphQLTypes["newTemplateType_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["newTemplateType_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["newTemplateType_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["newTemplateType_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["newTemplateType_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["newTemplateType_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["newTemplateType_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["newTemplateType_avg_fields"]: {
		__typename: "newTemplateType_avg_fields",
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** Boolean expression to filter rows from the table "newTemplateType". All fields are combined with a logical 'AND'. */
	["newTemplateType_bool_exp"]: {
		_and?: Array<GraphQLTypes["newTemplateType_bool_exp"]> | undefined,
		_not?: GraphQLTypes["newTemplateType_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["newTemplateType_bool_exp"]> | undefined,
		channel?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		creditPrice?: GraphQLTypes["Int_comparison_exp"] | undefined,
		description?: GraphQLTypes["String_comparison_exp"] | undefined,
		icon?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined,
		saleCreditPrice?: GraphQLTypes["Int_comparison_exp"] | undefined,
		thumbnailFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "newTemplateType" */
	["newTemplateType_constraint"]: newTemplateType_constraint;
	/** input type for incrementing numeric columns in table "newTemplateType" */
	["newTemplateType_inc_input"]: {
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** input type for inserting data into table "newTemplateType" */
	["newTemplateType_insert_input"]: {
		channel?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["newTemplateType_max_fields"]: {
		__typename: "newTemplateType_max_fields",
		channel?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["newTemplateType_min_fields"]: {
		__typename: "newTemplateType_min_fields",
		channel?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "newTemplateType" */
	["newTemplateType_mutation_response"]: {
		__typename: "newTemplateType_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["newTemplateType"]>
	};
	/** on_conflict condition type for table "newTemplateType" */
	["newTemplateType_on_conflict"]: {
		constraint: GraphQLTypes["newTemplateType_constraint"],
		update_columns: Array<GraphQLTypes["newTemplateType_update_column"]>,
		where?: GraphQLTypes["newTemplateType_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "newTemplateType". */
	["newTemplateType_order_by"]: {
		channel?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		icon?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		saleCreditPrice?: GraphQLTypes["order_by"] | undefined,
		thumbnailFileId?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: newTemplateType */
	["newTemplateType_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "newTemplateType" */
	["newTemplateType_select_column"]: newTemplateType_select_column;
	/** input type for updating data in table "newTemplateType" */
	["newTemplateType_set_input"]: {
		channel?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["newTemplateType_stddev_fields"]: {
		__typename: "newTemplateType_stddev_fields",
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["newTemplateType_stddev_pop_fields"]: {
		__typename: "newTemplateType_stddev_pop_fields",
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["newTemplateType_stddev_samp_fields"]: {
		__typename: "newTemplateType_stddev_samp_fields",
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** Streaming cursor of the table "newTemplateType" */
	["newTemplateType_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["newTemplateType_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["newTemplateType_stream_cursor_value_input"]: {
		channel?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditPrice?: number | undefined,
		description?: string | undefined,
		icon?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		name?: string | undefined,
		saleCreditPrice?: number | undefined,
		thumbnailFileId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["newTemplateType_sum_fields"]: {
		__typename: "newTemplateType_sum_fields",
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** update columns of table "newTemplateType" */
	["newTemplateType_update_column"]: newTemplateType_update_column;
	["newTemplateType_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["newTemplateType_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["newTemplateType_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["newTemplateType_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["newTemplateType_var_pop_fields"]: {
		__typename: "newTemplateType_var_pop_fields",
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate var_samp on columns */
	["newTemplateType_var_samp_fields"]: {
		__typename: "newTemplateType_var_samp_fields",
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	/** aggregate variance on columns */
	["newTemplateType_variance_fields"]: {
		__typename: "newTemplateType_variance_fields",
		creditPrice?: number | undefined,
		saleCreditPrice?: number | undefined
	};
	["numeric"]: "scalar" & { name: "numeric" };
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
	["numeric_comparison_exp"]: {
		_eq?: GraphQLTypes["numeric"] | undefined,
		_gt?: GraphQLTypes["numeric"] | undefined,
		_gte?: GraphQLTypes["numeric"] | undefined,
		_in?: Array<GraphQLTypes["numeric"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: GraphQLTypes["numeric"] | undefined,
		_lte?: GraphQLTypes["numeric"] | undefined,
		_neq?: GraphQLTypes["numeric"] | undefined,
		_nin?: Array<GraphQLTypes["numeric"]> | undefined
	};
	/** columns and relationships of "openAIApiKey" */
	["openAIApiKey"]: {
		__typename: "openAIApiKey",
		apiKey: string,
		created_at: GraphQLTypes["timestamptz"],
		id: GraphQLTypes["uuid"],
		last_used: GraphQLTypes["timestamptz"],
		status?: string | undefined
	};
	/** aggregated selection of "openAIApiKey" */
	["openAIApiKey_aggregate"]: {
		__typename: "openAIApiKey_aggregate",
		aggregate?: GraphQLTypes["openAIApiKey_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["openAIApiKey"]>
	};
	/** aggregate fields of "openAIApiKey" */
	["openAIApiKey_aggregate_fields"]: {
		__typename: "openAIApiKey_aggregate_fields",
		count: number,
		max?: GraphQLTypes["openAIApiKey_max_fields"] | undefined,
		min?: GraphQLTypes["openAIApiKey_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "openAIApiKey". All fields are combined with a logical 'AND'. */
	["openAIApiKey_bool_exp"]: {
		_and?: Array<GraphQLTypes["openAIApiKey_bool_exp"]> | undefined,
		_not?: GraphQLTypes["openAIApiKey_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["openAIApiKey_bool_exp"]> | undefined,
		apiKey?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		last_used?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		status?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "openAIApiKey" */
	["openAIApiKey_constraint"]: openAIApiKey_constraint;
	/** input type for inserting data into table "openAIApiKey" */
	["openAIApiKey_insert_input"]: {
		apiKey?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		last_used?: GraphQLTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** aggregate max on columns */
	["openAIApiKey_max_fields"]: {
		__typename: "openAIApiKey_max_fields",
		apiKey?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		last_used?: GraphQLTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** aggregate min on columns */
	["openAIApiKey_min_fields"]: {
		__typename: "openAIApiKey_min_fields",
		apiKey?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		last_used?: GraphQLTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** response of any mutation on the table "openAIApiKey" */
	["openAIApiKey_mutation_response"]: {
		__typename: "openAIApiKey_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["openAIApiKey"]>
	};
	/** on_conflict condition type for table "openAIApiKey" */
	["openAIApiKey_on_conflict"]: {
		constraint: GraphQLTypes["openAIApiKey_constraint"],
		update_columns: Array<GraphQLTypes["openAIApiKey_update_column"]>,
		where?: GraphQLTypes["openAIApiKey_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "openAIApiKey". */
	["openAIApiKey_order_by"]: {
		apiKey?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		last_used?: GraphQLTypes["order_by"] | undefined,
		status?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: openAIApiKey */
	["openAIApiKey_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "openAIApiKey" */
	["openAIApiKey_select_column"]: openAIApiKey_select_column;
	/** input type for updating data in table "openAIApiKey" */
	["openAIApiKey_set_input"]: {
		apiKey?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		last_used?: GraphQLTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** Streaming cursor of the table "openAIApiKey" */
	["openAIApiKey_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["openAIApiKey_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["openAIApiKey_stream_cursor_value_input"]: {
		apiKey?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		last_used?: GraphQLTypes["timestamptz"] | undefined,
		status?: string | undefined
	};
	/** update columns of table "openAIApiKey" */
	["openAIApiKey_update_column"]: openAIApiKey_update_column;
	["openAIApiKey_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["openAIApiKey_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["openAIApiKey_bool_exp"]
	};
	/** column ordering options */
	["order_by"]: order_by;
	/** columns and relationships of "pTemplate" */
	["pTemplate"]: {
		__typename: "pTemplate",
		category?: string | undefined,
		createdBy: GraphQLTypes["uuid"],
		created_at: GraphQLTypes["timestamptz"],
		deletedAt?: GraphQLTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		/** A computed field, executes function "templateheight" */
		height?: string | undefined,
		id: GraphQLTypes["uuid"],
		json: GraphQLTypes["jsonb"],
		previewFileId?: GraphQLTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		tagScores?: GraphQLTypes["jsonb"] | undefined,
		titleMaxLength?: number | undefined,
		updated_at: GraphQLTypes["timestamptz"],
		visibility: string,
		/** A computed field, executes function "templatewidth" */
		width?: string | undefined
	};
	/** aggregated selection of "pTemplate" */
	["pTemplate_aggregate"]: {
		__typename: "pTemplate_aggregate",
		aggregate?: GraphQLTypes["pTemplate_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["pTemplate"]>
	};
	/** aggregate fields of "pTemplate" */
	["pTemplate_aggregate_fields"]: {
		__typename: "pTemplate_aggregate_fields",
		avg?: GraphQLTypes["pTemplate_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["pTemplate_max_fields"] | undefined,
		min?: GraphQLTypes["pTemplate_min_fields"] | undefined,
		stddev?: GraphQLTypes["pTemplate_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["pTemplate_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["pTemplate_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["pTemplate_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["pTemplate_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["pTemplate_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["pTemplate_variance_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["pTemplate_append_input"]: {
		json?: GraphQLTypes["jsonb"] | undefined,
		tagScores?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregate avg on columns */
	["pTemplate_avg_fields"]: {
		__typename: "pTemplate_avg_fields",
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** Boolean expression to filter rows from the table "pTemplate". All fields are combined with a logical 'AND'. */
	["pTemplate_bool_exp"]: {
		_and?: Array<GraphQLTypes["pTemplate_bool_exp"]> | undefined,
		_not?: GraphQLTypes["pTemplate_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["pTemplate_bool_exp"]> | undefined,
		category?: GraphQLTypes["String_comparison_exp"] | undefined,
		createdBy?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		deletedAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		descriptionMaxLength?: GraphQLTypes["Int_comparison_exp"] | undefined,
		height?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		json?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		previewFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		subtitleMaxLength?: GraphQLTypes["Int_comparison_exp"] | undefined,
		tagScores?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		titleMaxLength?: GraphQLTypes["Int_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		visibility?: GraphQLTypes["String_comparison_exp"] | undefined,
		width?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "pTemplate" */
	["pTemplate_constraint"]: pTemplate_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["pTemplate_delete_at_path_input"]: {
		json?: Array<string> | undefined,
		tagScores?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["pTemplate_delete_elem_input"]: {
		json?: number | undefined,
		tagScores?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["pTemplate_delete_key_input"]: {
		json?: string | undefined,
		tagScores?: string | undefined
	};
	/** input type for incrementing numeric columns in table "pTemplate" */
	["pTemplate_inc_input"]: {
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** input type for inserting data into table "pTemplate" */
	["pTemplate_insert_input"]: {
		category?: string | undefined,
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		deletedAt?: GraphQLTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		json?: GraphQLTypes["jsonb"] | undefined,
		previewFileId?: GraphQLTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		tagScores?: GraphQLTypes["jsonb"] | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		visibility?: string | undefined
	};
	/** aggregate max on columns */
	["pTemplate_max_fields"]: {
		__typename: "pTemplate_max_fields",
		category?: string | undefined,
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		deletedAt?: GraphQLTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		/** A computed field, executes function "templateheight" */
		height?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		previewFileId?: GraphQLTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		visibility?: string | undefined,
		/** A computed field, executes function "templatewidth" */
		width?: string | undefined
	};
	/** aggregate min on columns */
	["pTemplate_min_fields"]: {
		__typename: "pTemplate_min_fields",
		category?: string | undefined,
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		deletedAt?: GraphQLTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		/** A computed field, executes function "templateheight" */
		height?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		previewFileId?: GraphQLTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		visibility?: string | undefined,
		/** A computed field, executes function "templatewidth" */
		width?: string | undefined
	};
	/** response of any mutation on the table "pTemplate" */
	["pTemplate_mutation_response"]: {
		__typename: "pTemplate_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["pTemplate"]>
	};
	/** on_conflict condition type for table "pTemplate" */
	["pTemplate_on_conflict"]: {
		constraint: GraphQLTypes["pTemplate_constraint"],
		update_columns: Array<GraphQLTypes["pTemplate_update_column"]>,
		where?: GraphQLTypes["pTemplate_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "pTemplate". */
	["pTemplate_order_by"]: {
		category?: GraphQLTypes["order_by"] | undefined,
		createdBy?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		deletedAt?: GraphQLTypes["order_by"] | undefined,
		descriptionMaxLength?: GraphQLTypes["order_by"] | undefined,
		height?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		json?: GraphQLTypes["order_by"] | undefined,
		previewFileId?: GraphQLTypes["order_by"] | undefined,
		subtitleMaxLength?: GraphQLTypes["order_by"] | undefined,
		tagScores?: GraphQLTypes["order_by"] | undefined,
		titleMaxLength?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		visibility?: GraphQLTypes["order_by"] | undefined,
		width?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: pTemplate */
	["pTemplate_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["pTemplate_prepend_input"]: {
		json?: GraphQLTypes["jsonb"] | undefined,
		tagScores?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "pTemplate" */
	["pTemplate_select_column"]: pTemplate_select_column;
	/** input type for updating data in table "pTemplate" */
	["pTemplate_set_input"]: {
		category?: string | undefined,
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		deletedAt?: GraphQLTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		json?: GraphQLTypes["jsonb"] | undefined,
		previewFileId?: GraphQLTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		tagScores?: GraphQLTypes["jsonb"] | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		visibility?: string | undefined
	};
	/** aggregate stddev on columns */
	["pTemplate_stddev_fields"]: {
		__typename: "pTemplate_stddev_fields",
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["pTemplate_stddev_pop_fields"]: {
		__typename: "pTemplate_stddev_pop_fields",
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["pTemplate_stddev_samp_fields"]: {
		__typename: "pTemplate_stddev_samp_fields",
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** Streaming cursor of the table "pTemplate" */
	["pTemplate_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["pTemplate_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["pTemplate_stream_cursor_value_input"]: {
		category?: string | undefined,
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		deletedAt?: GraphQLTypes["timestamptz"] | undefined,
		descriptionMaxLength?: number | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		json?: GraphQLTypes["jsonb"] | undefined,
		previewFileId?: GraphQLTypes["uuid"] | undefined,
		subtitleMaxLength?: number | undefined,
		tagScores?: GraphQLTypes["jsonb"] | undefined,
		titleMaxLength?: number | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		visibility?: string | undefined
	};
	/** aggregate sum on columns */
	["pTemplate_sum_fields"]: {
		__typename: "pTemplate_sum_fields",
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** update columns of table "pTemplate" */
	["pTemplate_update_column"]: pTemplate_update_column;
	["pTemplate_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["pTemplate_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["pTemplate_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["pTemplate_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["pTemplate_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["pTemplate_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["pTemplate_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["pTemplate_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["pTemplate_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["pTemplate_var_pop_fields"]: {
		__typename: "pTemplate_var_pop_fields",
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** aggregate var_samp on columns */
	["pTemplate_var_samp_fields"]: {
		__typename: "pTemplate_var_samp_fields",
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** aggregate variance on columns */
	["pTemplate_variance_fields"]: {
		__typename: "pTemplate_variance_fields",
		descriptionMaxLength?: number | undefined,
		subtitleMaxLength?: number | undefined,
		titleMaxLength?: number | undefined
	};
	/** columns and relationships of "pricingPlan" */
	["pricingPlan"]: {
		__typename: "pricingPlan",
		active: boolean,
		alias?: string | undefined,
		created_at: GraphQLTypes["timestamptz"],
		creditUnitDiscount: GraphQLTypes["numeric"],
		creditsToAdd?: GraphQLTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id: GraphQLTypes["uuid"],
		monthlyPrice?: GraphQLTypes["numeric"] | undefined,
		order?: GraphQLTypes["numeric"] | undefined,
		stripePriceId: string,
		stripeProductId: string,
		type?: string | undefined,
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** aggregated selection of "pricingPlan" */
	["pricingPlan_aggregate"]: {
		__typename: "pricingPlan_aggregate",
		aggregate?: GraphQLTypes["pricingPlan_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["pricingPlan"]>
	};
	/** aggregate fields of "pricingPlan" */
	["pricingPlan_aggregate_fields"]: {
		__typename: "pricingPlan_aggregate_fields",
		avg?: GraphQLTypes["pricingPlan_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["pricingPlan_max_fields"] | undefined,
		min?: GraphQLTypes["pricingPlan_min_fields"] | undefined,
		stddev?: GraphQLTypes["pricingPlan_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["pricingPlan_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["pricingPlan_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["pricingPlan_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["pricingPlan_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["pricingPlan_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["pricingPlan_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["pricingPlan_avg_fields"]: {
		__typename: "pricingPlan_avg_fields",
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** Boolean expression to filter rows from the table "pricingPlan". All fields are combined with a logical 'AND'. */
	["pricingPlan_bool_exp"]: {
		_and?: Array<GraphQLTypes["pricingPlan_bool_exp"]> | undefined,
		_not?: GraphQLTypes["pricingPlan_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["pricingPlan_bool_exp"]> | undefined,
		active?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		alias?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		creditUnitDiscount?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		creditsToAdd?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		htmlFeatureItems?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		monthlyPrice?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		order?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		stripePriceId?: GraphQLTypes["String_comparison_exp"] | undefined,
		stripeProductId?: GraphQLTypes["String_comparison_exp"] | undefined,
		type?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "pricingPlan" */
	["pricingPlan_constraint"]: pricingPlan_constraint;
	/** input type for incrementing numeric columns in table "pricingPlan" */
	["pricingPlan_inc_input"]: {
		creditUnitDiscount?: GraphQLTypes["numeric"] | undefined,
		creditsToAdd?: GraphQLTypes["numeric"] | undefined,
		monthlyPrice?: GraphQLTypes["numeric"] | undefined,
		order?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "pricingPlan" */
	["pricingPlan_insert_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditUnitDiscount?: GraphQLTypes["numeric"] | undefined,
		creditsToAdd?: GraphQLTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		monthlyPrice?: GraphQLTypes["numeric"] | undefined,
		order?: GraphQLTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["pricingPlan_max_fields"]: {
		__typename: "pricingPlan_max_fields",
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditUnitDiscount?: GraphQLTypes["numeric"] | undefined,
		creditsToAdd?: GraphQLTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		monthlyPrice?: GraphQLTypes["numeric"] | undefined,
		order?: GraphQLTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["pricingPlan_min_fields"]: {
		__typename: "pricingPlan_min_fields",
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditUnitDiscount?: GraphQLTypes["numeric"] | undefined,
		creditsToAdd?: GraphQLTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		monthlyPrice?: GraphQLTypes["numeric"] | undefined,
		order?: GraphQLTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "pricingPlan" */
	["pricingPlan_mutation_response"]: {
		__typename: "pricingPlan_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["pricingPlan"]>
	};
	/** input type for inserting object relation for remote table "pricingPlan" */
	["pricingPlan_obj_rel_insert_input"]: {
		data: GraphQLTypes["pricingPlan_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["pricingPlan_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "pricingPlan" */
	["pricingPlan_on_conflict"]: {
		constraint: GraphQLTypes["pricingPlan_constraint"],
		update_columns: Array<GraphQLTypes["pricingPlan_update_column"]>,
		where?: GraphQLTypes["pricingPlan_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "pricingPlan". */
	["pricingPlan_order_by"]: {
		active?: GraphQLTypes["order_by"] | undefined,
		alias?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		creditUnitDiscount?: GraphQLTypes["order_by"] | undefined,
		creditsToAdd?: GraphQLTypes["order_by"] | undefined,
		htmlFeatureItems?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		monthlyPrice?: GraphQLTypes["order_by"] | undefined,
		order?: GraphQLTypes["order_by"] | undefined,
		stripePriceId?: GraphQLTypes["order_by"] | undefined,
		stripeProductId?: GraphQLTypes["order_by"] | undefined,
		type?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: pricingPlan */
	["pricingPlan_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "pricingPlan" */
	["pricingPlan_select_column"]: pricingPlan_select_column;
	/** input type for updating data in table "pricingPlan" */
	["pricingPlan_set_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditUnitDiscount?: GraphQLTypes["numeric"] | undefined,
		creditsToAdd?: GraphQLTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		monthlyPrice?: GraphQLTypes["numeric"] | undefined,
		order?: GraphQLTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["pricingPlan_stddev_fields"]: {
		__typename: "pricingPlan_stddev_fields",
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["pricingPlan_stddev_pop_fields"]: {
		__typename: "pricingPlan_stddev_pop_fields",
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["pricingPlan_stddev_samp_fields"]: {
		__typename: "pricingPlan_stddev_samp_fields",
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** Streaming cursor of the table "pricingPlan" */
	["pricingPlan_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["pricingPlan_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["pricingPlan_stream_cursor_value_input"]: {
		active?: boolean | undefined,
		alias?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditUnitDiscount?: GraphQLTypes["numeric"] | undefined,
		creditsToAdd?: GraphQLTypes["numeric"] | undefined,
		htmlFeatureItems?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		monthlyPrice?: GraphQLTypes["numeric"] | undefined,
		order?: GraphQLTypes["numeric"] | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["pricingPlan_sum_fields"]: {
		__typename: "pricingPlan_sum_fields",
		creditUnitDiscount?: GraphQLTypes["numeric"] | undefined,
		creditsToAdd?: GraphQLTypes["numeric"] | undefined,
		monthlyPrice?: GraphQLTypes["numeric"] | undefined,
		order?: GraphQLTypes["numeric"] | undefined
	};
	/** update columns of table "pricingPlan" */
	["pricingPlan_update_column"]: pricingPlan_update_column;
	["pricingPlan_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["pricingPlan_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["pricingPlan_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["pricingPlan_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["pricingPlan_var_pop_fields"]: {
		__typename: "pricingPlan_var_pop_fields",
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** aggregate var_samp on columns */
	["pricingPlan_var_samp_fields"]: {
		__typename: "pricingPlan_var_samp_fields",
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** aggregate variance on columns */
	["pricingPlan_variance_fields"]: {
		__typename: "pricingPlan_variance_fields",
		creditUnitDiscount?: number | undefined,
		creditsToAdd?: number | undefined,
		monthlyPrice?: number | undefined,
		order?: number | undefined
	};
	/** columns and relationships of "products" */
	["products"]: {
		__typename: "products",
		alias: string,
		benefits?: GraphQLTypes["jsonb"] | undefined,
		/** An object relationship */
		brand?: GraphQLTypes["brands"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		category?: string | undefined,
		characteristics?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by: GraphQLTypes["uuid"],
		deleted: boolean,
		description?: string | undefined,
		id: GraphQLTypes["uuid"],
		imageFourId?: GraphQLTypes["uuid"] | undefined,
		imageOneId?: GraphQLTypes["uuid"] | undefined,
		imageThreeId?: GraphQLTypes["uuid"] | undefined,
		imageTwoId?: GraphQLTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: GraphQLTypes["numeric"] | undefined,
		salePrice?: GraphQLTypes["numeric"] | undefined,
		testimonials?: GraphQLTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		wordKeys?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregated selection of "products" */
	["products_aggregate"]: {
		__typename: "products_aggregate",
		aggregate?: GraphQLTypes["products_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["products"]>
	};
	["products_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["products_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: GraphQLTypes["products_aggregate_bool_exp_bool_or"] | undefined,
		count?: GraphQLTypes["products_aggregate_bool_exp_count"] | undefined
	};
	["products_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["products_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["products_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["products_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["products_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["products_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["products_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "products" */
	["products_aggregate_fields"]: {
		__typename: "products_aggregate_fields",
		avg?: GraphQLTypes["products_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["products_max_fields"] | undefined,
		min?: GraphQLTypes["products_min_fields"] | undefined,
		stddev?: GraphQLTypes["products_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["products_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["products_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["products_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["products_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["products_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["products_variance_fields"] | undefined
	};
	/** order by aggregate values of table "products" */
	["products_aggregate_order_by"]: {
		avg?: GraphQLTypes["products_avg_order_by"] | undefined,
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["products_max_order_by"] | undefined,
		min?: GraphQLTypes["products_min_order_by"] | undefined,
		stddev?: GraphQLTypes["products_stddev_order_by"] | undefined,
		stddev_pop?: GraphQLTypes["products_stddev_pop_order_by"] | undefined,
		stddev_samp?: GraphQLTypes["products_stddev_samp_order_by"] | undefined,
		sum?: GraphQLTypes["products_sum_order_by"] | undefined,
		var_pop?: GraphQLTypes["products_var_pop_order_by"] | undefined,
		var_samp?: GraphQLTypes["products_var_samp_order_by"] | undefined,
		variance?: GraphQLTypes["products_variance_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["products_append_input"]: {
		benefits?: GraphQLTypes["jsonb"] | undefined,
		characteristics?: GraphQLTypes["jsonb"] | undefined,
		testimonials?: GraphQLTypes["jsonb"] | undefined,
		wordKeys?: GraphQLTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "products" */
	["products_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["products_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["products_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["products_avg_fields"]: {
		__typename: "products_avg_fields",
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by avg() on columns of table "products" */
	["products_avg_order_by"]: {
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
	["products_bool_exp"]: {
		_and?: Array<GraphQLTypes["products_bool_exp"]> | undefined,
		_not?: GraphQLTypes["products_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["products_bool_exp"]> | undefined,
		alias?: GraphQLTypes["String_comparison_exp"] | undefined,
		benefits?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		brand?: GraphQLTypes["brands_bool_exp"] | undefined,
		brandId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		category?: GraphQLTypes["String_comparison_exp"] | undefined,
		characteristics?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		deleted?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		description?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		imageFourId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		imageOneId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		imageThreeId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		imageTwoId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		linkConversion?: GraphQLTypes["String_comparison_exp"] | undefined,
		price?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		salePrice?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		testimonials?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		type?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		wordKeys?: GraphQLTypes["jsonb_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "products" */
	["products_constraint"]: products_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["products_delete_at_path_input"]: {
		benefits?: Array<string> | undefined,
		characteristics?: Array<string> | undefined,
		testimonials?: Array<string> | undefined,
		wordKeys?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["products_delete_elem_input"]: {
		benefits?: number | undefined,
		characteristics?: number | undefined,
		testimonials?: number | undefined,
		wordKeys?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["products_delete_key_input"]: {
		benefits?: string | undefined,
		characteristics?: string | undefined,
		testimonials?: string | undefined,
		wordKeys?: string | undefined
	};
	/** input type for incrementing numeric columns in table "products" */
	["products_inc_input"]: {
		price?: GraphQLTypes["numeric"] | undefined,
		salePrice?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "products" */
	["products_insert_input"]: {
		alias?: string | undefined,
		benefits?: GraphQLTypes["jsonb"] | undefined,
		brand?: GraphQLTypes["brands_obj_rel_insert_input"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		category?: string | undefined,
		characteristics?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageFourId?: GraphQLTypes["uuid"] | undefined,
		imageOneId?: GraphQLTypes["uuid"] | undefined,
		imageThreeId?: GraphQLTypes["uuid"] | undefined,
		imageTwoId?: GraphQLTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: GraphQLTypes["numeric"] | undefined,
		salePrice?: GraphQLTypes["numeric"] | undefined,
		testimonials?: GraphQLTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		wordKeys?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregate max on columns */
	["products_max_fields"]: {
		__typename: "products_max_fields",
		alias?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		category?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageFourId?: GraphQLTypes["uuid"] | undefined,
		imageOneId?: GraphQLTypes["uuid"] | undefined,
		imageThreeId?: GraphQLTypes["uuid"] | undefined,
		imageTwoId?: GraphQLTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: GraphQLTypes["numeric"] | undefined,
		salePrice?: GraphQLTypes["numeric"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "products" */
	["products_max_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		brandId?: GraphQLTypes["order_by"] | undefined,
		category?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		imageFourId?: GraphQLTypes["order_by"] | undefined,
		imageOneId?: GraphQLTypes["order_by"] | undefined,
		imageThreeId?: GraphQLTypes["order_by"] | undefined,
		imageTwoId?: GraphQLTypes["order_by"] | undefined,
		linkConversion?: GraphQLTypes["order_by"] | undefined,
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined,
		type?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["products_min_fields"]: {
		__typename: "products_min_fields",
		alias?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		category?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageFourId?: GraphQLTypes["uuid"] | undefined,
		imageOneId?: GraphQLTypes["uuid"] | undefined,
		imageThreeId?: GraphQLTypes["uuid"] | undefined,
		imageTwoId?: GraphQLTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: GraphQLTypes["numeric"] | undefined,
		salePrice?: GraphQLTypes["numeric"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "products" */
	["products_min_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		brandId?: GraphQLTypes["order_by"] | undefined,
		category?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		imageFourId?: GraphQLTypes["order_by"] | undefined,
		imageOneId?: GraphQLTypes["order_by"] | undefined,
		imageThreeId?: GraphQLTypes["order_by"] | undefined,
		imageTwoId?: GraphQLTypes["order_by"] | undefined,
		linkConversion?: GraphQLTypes["order_by"] | undefined,
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined,
		type?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "products" */
	["products_mutation_response"]: {
		__typename: "products_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["products"]>
	};
	/** input type for inserting object relation for remote table "products" */
	["products_obj_rel_insert_input"]: {
		data: GraphQLTypes["products_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["products_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "products" */
	["products_on_conflict"]: {
		constraint: GraphQLTypes["products_constraint"],
		update_columns: Array<GraphQLTypes["products_update_column"]>,
		where?: GraphQLTypes["products_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "products". */
	["products_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		benefits?: GraphQLTypes["order_by"] | undefined,
		brand?: GraphQLTypes["brands_order_by"] | undefined,
		brandId?: GraphQLTypes["order_by"] | undefined,
		category?: GraphQLTypes["order_by"] | undefined,
		characteristics?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		deleted?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		imageFourId?: GraphQLTypes["order_by"] | undefined,
		imageOneId?: GraphQLTypes["order_by"] | undefined,
		imageThreeId?: GraphQLTypes["order_by"] | undefined,
		imageTwoId?: GraphQLTypes["order_by"] | undefined,
		linkConversion?: GraphQLTypes["order_by"] | undefined,
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined,
		testimonials?: GraphQLTypes["order_by"] | undefined,
		type?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		wordKeys?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: products */
	["products_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["products_prepend_input"]: {
		benefits?: GraphQLTypes["jsonb"] | undefined,
		characteristics?: GraphQLTypes["jsonb"] | undefined,
		testimonials?: GraphQLTypes["jsonb"] | undefined,
		wordKeys?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "products" */
	["products_select_column"]: products_select_column;
	/** select "products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "products" */
	["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"]: products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "products" */
	["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"]: products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "products" */
	["products_set_input"]: {
		alias?: string | undefined,
		benefits?: GraphQLTypes["jsonb"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		category?: string | undefined,
		characteristics?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageFourId?: GraphQLTypes["uuid"] | undefined,
		imageOneId?: GraphQLTypes["uuid"] | undefined,
		imageThreeId?: GraphQLTypes["uuid"] | undefined,
		imageTwoId?: GraphQLTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: GraphQLTypes["numeric"] | undefined,
		salePrice?: GraphQLTypes["numeric"] | undefined,
		testimonials?: GraphQLTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		wordKeys?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregate stddev on columns */
	["products_stddev_fields"]: {
		__typename: "products_stddev_fields",
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by stddev() on columns of table "products" */
	["products_stddev_order_by"]: {
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["products_stddev_pop_fields"]: {
		__typename: "products_stddev_pop_fields",
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by stddev_pop() on columns of table "products" */
	["products_stddev_pop_order_by"]: {
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["products_stddev_samp_fields"]: {
		__typename: "products_stddev_samp_fields",
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by stddev_samp() on columns of table "products" */
	["products_stddev_samp_order_by"]: {
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "products" */
	["products_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["products_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["products_stream_cursor_value_input"]: {
		alias?: string | undefined,
		benefits?: GraphQLTypes["jsonb"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		category?: string | undefined,
		characteristics?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		imageFourId?: GraphQLTypes["uuid"] | undefined,
		imageOneId?: GraphQLTypes["uuid"] | undefined,
		imageThreeId?: GraphQLTypes["uuid"] | undefined,
		imageTwoId?: GraphQLTypes["uuid"] | undefined,
		linkConversion?: string | undefined,
		price?: GraphQLTypes["numeric"] | undefined,
		salePrice?: GraphQLTypes["numeric"] | undefined,
		testimonials?: GraphQLTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		wordKeys?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregate sum on columns */
	["products_sum_fields"]: {
		__typename: "products_sum_fields",
		price?: GraphQLTypes["numeric"] | undefined,
		salePrice?: GraphQLTypes["numeric"] | undefined
	};
	/** order by sum() on columns of table "products" */
	["products_sum_order_by"]: {
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined
	};
	/** update columns of table "products" */
	["products_update_column"]: products_update_column;
	["products_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["products_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["products_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["products_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["products_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["products_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["products_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["products_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["products_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["products_var_pop_fields"]: {
		__typename: "products_var_pop_fields",
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by var_pop() on columns of table "products" */
	["products_var_pop_order_by"]: {
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["products_var_samp_fields"]: {
		__typename: "products_var_samp_fields",
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by var_samp() on columns of table "products" */
	["products_var_samp_order_by"]: {
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["products_variance_fields"]: {
		__typename: "products_variance_fields",
		price?: number | undefined,
		salePrice?: number | undefined
	};
	/** order by variance() on columns of table "products" */
	["products_variance_order_by"]: {
		price?: GraphQLTypes["order_by"] | undefined,
		salePrice?: GraphQLTypes["order_by"] | undefined
	};
	/** columns and relationships of "project" */
	["project"]: {
		__typename: "project",
		alias: string,
		audienceId?: GraphQLTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignId?: GraphQLTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		creditPrice: GraphQLTypes["numeric"],
		deleted: boolean,
		featuredImageFileId?: GraphQLTypes["uuid"] | undefined,
		id: GraphQLTypes["uuid"],
		/** An object relationship */
		previewImageFile?: GraphQLTypes["files"] | undefined,
		previewImageFileId?: GraphQLTypes["uuid"] | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		rawText: string,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		status: string,
		suggestedScheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		template?: GraphQLTypes["jsonb"] | undefined,
		type: string,
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** columns and relationships of "projectImage" */
	["projectImage"]: {
		__typename: "projectImage",
		fileId: GraphQLTypes["uuid"],
		id: GraphQLTypes["uuid"],
		projectId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "projectImage" */
	["projectImage_aggregate"]: {
		__typename: "projectImage_aggregate",
		aggregate?: GraphQLTypes["projectImage_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["projectImage"]>
	};
	/** aggregate fields of "projectImage" */
	["projectImage_aggregate_fields"]: {
		__typename: "projectImage_aggregate_fields",
		count: number,
		max?: GraphQLTypes["projectImage_max_fields"] | undefined,
		min?: GraphQLTypes["projectImage_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "projectImage". All fields are combined with a logical 'AND'. */
	["projectImage_bool_exp"]: {
		_and?: Array<GraphQLTypes["projectImage_bool_exp"]> | undefined,
		_not?: GraphQLTypes["projectImage_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["projectImage_bool_exp"]> | undefined,
		fileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		projectId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "projectImage" */
	["projectImage_constraint"]: projectImage_constraint;
	/** input type for inserting data into table "projectImage" */
	["projectImage_insert_input"]: {
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["projectImage_max_fields"]: {
		__typename: "projectImage_max_fields",
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["projectImage_min_fields"]: {
		__typename: "projectImage_min_fields",
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "projectImage" */
	["projectImage_mutation_response"]: {
		__typename: "projectImage_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["projectImage"]>
	};
	/** on_conflict condition type for table "projectImage" */
	["projectImage_on_conflict"]: {
		constraint: GraphQLTypes["projectImage_constraint"],
		update_columns: Array<GraphQLTypes["projectImage_update_column"]>,
		where?: GraphQLTypes["projectImage_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "projectImage". */
	["projectImage_order_by"]: {
		fileId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		projectId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: projectImage */
	["projectImage_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "projectImage" */
	["projectImage_select_column"]: projectImage_select_column;
	/** input type for updating data in table "projectImage" */
	["projectImage_set_input"]: {
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "projectImage" */
	["projectImage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["projectImage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["projectImage_stream_cursor_value_input"]: {
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		projectId?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "projectImage" */
	["projectImage_update_column"]: projectImage_update_column;
	["projectImage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["projectImage_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["projectImage_bool_exp"]
	};
	/** aggregated selection of "project" */
	["project_aggregate"]: {
		__typename: "project_aggregate",
		aggregate?: GraphQLTypes["project_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["project"]>
	};
	/** aggregate fields of "project" */
	["project_aggregate_fields"]: {
		__typename: "project_aggregate_fields",
		avg?: GraphQLTypes["project_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["project_max_fields"] | undefined,
		min?: GraphQLTypes["project_min_fields"] | undefined,
		stddev?: GraphQLTypes["project_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["project_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["project_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["project_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["project_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["project_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["project_variance_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["project_append_input"]: {
		template?: GraphQLTypes["jsonb"] | undefined
	};
	/** aggregate avg on columns */
	["project_avg_fields"]: {
		__typename: "project_avg_fields",
		creditPrice?: number | undefined
	};
	/** Boolean expression to filter rows from the table "project". All fields are combined with a logical 'AND'. */
	["project_bool_exp"]: {
		_and?: Array<GraphQLTypes["project_bool_exp"]> | undefined,
		_not?: GraphQLTypes["project_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["project_bool_exp"]> | undefined,
		alias?: GraphQLTypes["String_comparison_exp"] | undefined,
		audienceId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		blogContent?: GraphQLTypes["String_comparison_exp"] | undefined,
		blogTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		brandId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		campaignId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		caption?: GraphQLTypes["String_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		creditPrice?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		deleted?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		featuredImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		previewImageFile?: GraphQLTypes["files_bool_exp"] | undefined,
		previewImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		productId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		publishDate?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		rawText?: GraphQLTypes["String_comparison_exp"] | undefined,
		scheduledDate?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		status?: GraphQLTypes["String_comparison_exp"] | undefined,
		suggestedScheduledDate?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		template?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		type?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "project" */
	["project_constraint"]: project_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["project_delete_at_path_input"]: {
		template?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["project_delete_elem_input"]: {
		template?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["project_delete_key_input"]: {
		template?: string | undefined
	};
	/** input type for incrementing numeric columns in table "project" */
	["project_inc_input"]: {
		creditPrice?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "project" */
	["project_insert_input"]: {
		alias?: string | undefined,
		audienceId?: GraphQLTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignId?: GraphQLTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		creditPrice?: GraphQLTypes["numeric"] | undefined,
		deleted?: boolean | undefined,
		featuredImageFileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		previewImageFile?: GraphQLTypes["files_obj_rel_insert_input"] | undefined,
		previewImageFileId?: GraphQLTypes["uuid"] | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		template?: GraphQLTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["project_max_fields"]: {
		__typename: "project_max_fields",
		alias?: string | undefined,
		audienceId?: GraphQLTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignId?: GraphQLTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		creditPrice?: GraphQLTypes["numeric"] | undefined,
		featuredImageFileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		previewImageFileId?: GraphQLTypes["uuid"] | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["project_min_fields"]: {
		__typename: "project_min_fields",
		alias?: string | undefined,
		audienceId?: GraphQLTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignId?: GraphQLTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		creditPrice?: GraphQLTypes["numeric"] | undefined,
		featuredImageFileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		previewImageFileId?: GraphQLTypes["uuid"] | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "project" */
	["project_mutation_response"]: {
		__typename: "project_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["project"]>
	};
	/** input type for inserting object relation for remote table "project" */
	["project_obj_rel_insert_input"]: {
		data: GraphQLTypes["project_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["project_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "project" */
	["project_on_conflict"]: {
		constraint: GraphQLTypes["project_constraint"],
		update_columns: Array<GraphQLTypes["project_update_column"]>,
		where?: GraphQLTypes["project_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "project". */
	["project_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		audienceId?: GraphQLTypes["order_by"] | undefined,
		blogContent?: GraphQLTypes["order_by"] | undefined,
		blogTitle?: GraphQLTypes["order_by"] | undefined,
		brandId?: GraphQLTypes["order_by"] | undefined,
		campaignId?: GraphQLTypes["order_by"] | undefined,
		caption?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		creditPrice?: GraphQLTypes["order_by"] | undefined,
		deleted?: GraphQLTypes["order_by"] | undefined,
		featuredImageFileId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		previewImageFile?: GraphQLTypes["files_order_by"] | undefined,
		previewImageFileId?: GraphQLTypes["order_by"] | undefined,
		productId?: GraphQLTypes["order_by"] | undefined,
		publishDate?: GraphQLTypes["order_by"] | undefined,
		rawText?: GraphQLTypes["order_by"] | undefined,
		scheduledDate?: GraphQLTypes["order_by"] | undefined,
		scheduledDateEventId?: GraphQLTypes["order_by"] | undefined,
		status?: GraphQLTypes["order_by"] | undefined,
		suggestedScheduledDate?: GraphQLTypes["order_by"] | undefined,
		template?: GraphQLTypes["order_by"] | undefined,
		type?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: project */
	["project_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["project_prepend_input"]: {
		template?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "project" */
	["project_select_column"]: project_select_column;
	/** input type for updating data in table "project" */
	["project_set_input"]: {
		alias?: string | undefined,
		audienceId?: GraphQLTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignId?: GraphQLTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		creditPrice?: GraphQLTypes["numeric"] | undefined,
		deleted?: boolean | undefined,
		featuredImageFileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		previewImageFileId?: GraphQLTypes["uuid"] | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		template?: GraphQLTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["project_stddev_fields"]: {
		__typename: "project_stddev_fields",
		creditPrice?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["project_stddev_pop_fields"]: {
		__typename: "project_stddev_pop_fields",
		creditPrice?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["project_stddev_samp_fields"]: {
		__typename: "project_stddev_samp_fields",
		creditPrice?: number | undefined
	};
	/** Streaming cursor of the table "project" */
	["project_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["project_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["project_stream_cursor_value_input"]: {
		alias?: string | undefined,
		audienceId?: GraphQLTypes["uuid"] | undefined,
		blogContent?: string | undefined,
		blogTitle?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		campaignId?: GraphQLTypes["uuid"] | undefined,
		caption?: string | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		creditPrice?: GraphQLTypes["numeric"] | undefined,
		deleted?: boolean | undefined,
		featuredImageFileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		previewImageFileId?: GraphQLTypes["uuid"] | undefined,
		productId?: GraphQLTypes["uuid"] | undefined,
		publishDate?: GraphQLTypes["timestamptz"] | undefined,
		rawText?: string | undefined,
		scheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		scheduledDateEventId?: GraphQLTypes["uuid"] | undefined,
		status?: string | undefined,
		suggestedScheduledDate?: GraphQLTypes["timestamptz"] | undefined,
		template?: GraphQLTypes["jsonb"] | undefined,
		type?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["project_sum_fields"]: {
		__typename: "project_sum_fields",
		creditPrice?: GraphQLTypes["numeric"] | undefined
	};
	/** update columns of table "project" */
	["project_update_column"]: project_update_column;
	["project_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["project_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["project_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["project_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["project_delete_key_input"] | undefined,
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["project_inc_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["project_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["project_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["project_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["project_var_pop_fields"]: {
		__typename: "project_var_pop_fields",
		creditPrice?: number | undefined
	};
	/** aggregate var_samp on columns */
	["project_var_samp_fields"]: {
		__typename: "project_var_samp_fields",
		creditPrice?: number | undefined
	};
	/** aggregate variance on columns */
	["project_variance_fields"]: {
		__typename: "project_variance_fields",
		creditPrice?: number | undefined
	};
	["query_root"]: {
		__typename: "query_root",
		/** fetch data from the table: "AppIntegration" */
		AppIntegration: Array<GraphQLTypes["AppIntegration"]>,
		/** fetch aggregated fields from the table: "AppIntegration" */
		AppIntegration_aggregate: GraphQLTypes["AppIntegration_aggregate"],
		/** fetch data from the table: "AppIntegration" using primary key columns */
		AppIntegration_by_pk?: GraphQLTypes["AppIntegration"] | undefined,
		/** fetch data from the table: "Course" */
		Course: Array<GraphQLTypes["Course"]>,
		/** fetch data from the table: "CourseCategory" */
		CourseCategory: Array<GraphQLTypes["CourseCategory"]>,
		/** fetch aggregated fields from the table: "CourseCategory" */
		CourseCategory_aggregate: GraphQLTypes["CourseCategory_aggregate"],
		/** fetch data from the table: "CourseCategory" using primary key columns */
		CourseCategory_by_pk?: GraphQLTypes["CourseCategory"] | undefined,
		/** fetch aggregated fields from the table: "Course" */
		Course_aggregate: GraphQLTypes["Course_aggregate"],
		/** fetch data from the table: "Course" using primary key columns */
		Course_by_pk?: GraphQLTypes["Course"] | undefined,
		/** fetch data from the table: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate: Array<GraphQLTypes["GeneratedPromptByTemplate"]>,
		/** fetch data from the table: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage: Array<GraphQLTypes["GeneratedPromptByTemplateImage"]>,
		/** fetch aggregated fields from the table: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage_aggregate: GraphQLTypes["GeneratedPromptByTemplateImage_aggregate"],
		/** fetch data from the table: "GeneratedPromptByTemplateImage" using primary key columns */
		GeneratedPromptByTemplateImage_by_pk?: GraphQLTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** fetch aggregated fields from the table: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate_aggregate: GraphQLTypes["GeneratedPromptByTemplate_aggregate"],
		/** fetch data from the table: "GeneratedPromptByTemplate" using primary key columns */
		GeneratedPromptByTemplate_by_pk?: GraphQLTypes["GeneratedPromptByTemplate"] | undefined,
		/** fetch data from the table: "TextGeneratorOption" */
		TextGeneratorOption: Array<GraphQLTypes["TextGeneratorOption"]>,
		/** fetch data from the table: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry: Array<GraphQLTypes["TextGeneratorOptionEntry"]>,
		/** fetch aggregated fields from the table: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry_aggregate: GraphQLTypes["TextGeneratorOptionEntry_aggregate"],
		/** fetch data from the table: "TextGeneratorOptionEntry" using primary key columns */
		TextGeneratorOptionEntry_by_pk?: GraphQLTypes["TextGeneratorOptionEntry"] | undefined,
		/** fetch aggregated fields from the table: "TextGeneratorOption" */
		TextGeneratorOption_aggregate: GraphQLTypes["TextGeneratorOption_aggregate"],
		/** fetch data from the table: "TextGeneratorOption" using primary key columns */
		TextGeneratorOption_by_pk?: GraphQLTypes["TextGeneratorOption"] | undefined,
		/** fetch data from the table: "TextPromptTemplate" */
		TextPromptTemplate: Array<GraphQLTypes["TextPromptTemplate"]>,
		/** fetch data from the table: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory: Array<GraphQLTypes["TextPromptTemplateCategory"]>,
		/** fetch aggregated fields from the table: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory_aggregate: GraphQLTypes["TextPromptTemplateCategory_aggregate"],
		/** fetch data from the table: "TextPromptTemplateCategory" using primary key columns */
		TextPromptTemplateCategory_by_pk?: GraphQLTypes["TextPromptTemplateCategory"] | undefined,
		/** fetch aggregated fields from the table: "TextPromptTemplate" */
		TextPromptTemplate_aggregate: GraphQLTypes["TextPromptTemplate_aggregate"],
		/** fetch data from the table: "TextPromptTemplate" using primary key columns */
		TextPromptTemplate_by_pk?: GraphQLTypes["TextPromptTemplate"] | undefined,
		/** fetch data from the table: "TextTemplateClientCategory" */
		TextTemplateClientCategory: Array<GraphQLTypes["TextTemplateClientCategory"]>,
		/** fetch aggregated fields from the table: "TextTemplateClientCategory" */
		TextTemplateClientCategory_aggregate: GraphQLTypes["TextTemplateClientCategory_aggregate"],
		/** fetch data from the table: "TextTemplateClientCategory" using primary key columns */
		TextTemplateClientCategory_by_pk?: GraphQLTypes["TextTemplateClientCategory"] | undefined,
		/** fetch data from the table: "audiences" */
		audiences: Array<GraphQLTypes["audiences"]>,
		/** fetch aggregated fields from the table: "audiences" */
		audiences_aggregate: GraphQLTypes["audiences_aggregate"],
		/** fetch data from the table: "audiences" using primary key columns */
		audiences_by_pk?: GraphQLTypes["audiences"] | undefined,
		/** fetch data from the table: "auth.providers" using primary key columns */
		authProvider?: GraphQLTypes["authProviders"] | undefined,
		/** fetch data from the table: "auth.provider_requests" using primary key columns */
		authProviderRequest?: GraphQLTypes["authProviderRequests"] | undefined,
		/** fetch data from the table: "auth.provider_requests" */
		authProviderRequests: Array<GraphQLTypes["authProviderRequests"]>,
		/** fetch aggregated fields from the table: "auth.provider_requests" */
		authProviderRequestsAggregate: GraphQLTypes["authProviderRequests_aggregate"],
		/** fetch data from the table: "auth.providers" */
		authProviders: Array<GraphQLTypes["authProviders"]>,
		/** fetch aggregated fields from the table: "auth.providers" */
		authProvidersAggregate: GraphQLTypes["authProviders_aggregate"],
		/** fetch data from the table: "auth.refresh_tokens" using primary key columns */
		authRefreshToken?: GraphQLTypes["authRefreshTokens"] | undefined,
		/** fetch data from the table: "auth.refresh_token_types" using primary key columns */
		authRefreshTokenType?: GraphQLTypes["authRefreshTokenTypes"] | undefined,
		/** fetch data from the table: "auth.refresh_token_types" */
		authRefreshTokenTypes: Array<GraphQLTypes["authRefreshTokenTypes"]>,
		/** fetch aggregated fields from the table: "auth.refresh_token_types" */
		authRefreshTokenTypesAggregate: GraphQLTypes["authRefreshTokenTypes_aggregate"],
		/** fetch data from the table: "auth.refresh_tokens" */
		authRefreshTokens: Array<GraphQLTypes["authRefreshTokens"]>,
		/** fetch aggregated fields from the table: "auth.refresh_tokens" */
		authRefreshTokensAggregate: GraphQLTypes["authRefreshTokens_aggregate"],
		/** fetch data from the table: "auth.roles" using primary key columns */
		authRole?: GraphQLTypes["authRoles"] | undefined,
		/** fetch data from the table: "auth.roles" */
		authRoles: Array<GraphQLTypes["authRoles"]>,
		/** fetch aggregated fields from the table: "auth.roles" */
		authRolesAggregate: GraphQLTypes["authRoles_aggregate"],
		/** fetch data from the table: "auth.user_providers" using primary key columns */
		authUserProvider?: GraphQLTypes["authUserProviders"] | undefined,
		/** fetch data from the table: "auth.user_providers" */
		authUserProviders: Array<GraphQLTypes["authUserProviders"]>,
		/** fetch aggregated fields from the table: "auth.user_providers" */
		authUserProvidersAggregate: GraphQLTypes["authUserProviders_aggregate"],
		/** fetch data from the table: "auth.user_roles" using primary key columns */
		authUserRole?: GraphQLTypes["authUserRoles"] | undefined,
		/** fetch data from the table: "auth.user_roles" */
		authUserRoles: Array<GraphQLTypes["authUserRoles"]>,
		/** fetch aggregated fields from the table: "auth.user_roles" */
		authUserRolesAggregate: GraphQLTypes["authUserRoles_aggregate"],
		/** fetch data from the table: "auth.user_security_keys" using primary key columns */
		authUserSecurityKey?: GraphQLTypes["authUserSecurityKeys"] | undefined,
		/** fetch data from the table: "auth.user_security_keys" */
		authUserSecurityKeys: Array<GraphQLTypes["authUserSecurityKeys"]>,
		/** fetch aggregated fields from the table: "auth.user_security_keys" */
		authUserSecurityKeysAggregate: GraphQLTypes["authUserSecurityKeys_aggregate"],
		/** fetch data from the table: "blog" */
		blog: Array<GraphQLTypes["blog"]>,
		/** fetch data from the table: "blogCategory" */
		blogCategory: Array<GraphQLTypes["blogCategory"]>,
		/** fetch aggregated fields from the table: "blogCategory" */
		blogCategory_aggregate: GraphQLTypes["blogCategory_aggregate"],
		/** fetch data from the table: "blogCategory" using primary key columns */
		blogCategory_by_pk?: GraphQLTypes["blogCategory"] | undefined,
		blogPostGeneratorEnhancedForAmp?: GraphQLTypes["blogPostGeneratorEnhancedForAmp"] | undefined,
		/** fetch aggregated fields from the table: "blog" */
		blog_aggregate: GraphQLTypes["blog_aggregate"],
		/** fetch data from the table: "blog" using primary key columns */
		blog_by_pk?: GraphQLTypes["blog"] | undefined,
		/** fetch data from the table: "brands" */
		brands: Array<GraphQLTypes["brands"]>,
		/** fetch aggregated fields from the table: "brands" */
		brands_aggregate: GraphQLTypes["brands_aggregate"],
		/** fetch data from the table: "brands" using primary key columns */
		brands_by_pk?: GraphQLTypes["brands"] | undefined,
		/** fetch data from the table: "storage.buckets" using primary key columns */
		bucket?: GraphQLTypes["buckets"] | undefined,
		/** fetch data from the table: "storage.buckets" */
		buckets: Array<GraphQLTypes["buckets"]>,
		/** fetch aggregated fields from the table: "storage.buckets" */
		bucketsAggregate: GraphQLTypes["buckets_aggregate"],
		/** fetch data from the table: "campaign" */
		campaign: Array<GraphQLTypes["campaign"]>,
		/** fetch aggregated fields from the table: "campaign" */
		campaign_aggregate: GraphQLTypes["campaign_aggregate"],
		/** fetch data from the table: "campaign" using primary key columns */
		campaign_by_pk?: GraphQLTypes["campaign"] | undefined,
		/** fetch data from the table: "categorysummaryview" */
		categorysummaryview: Array<GraphQLTypes["categorysummaryview"]>,
		/** fetch aggregated fields from the table: "categorysummaryview" */
		categorysummaryview_aggregate: GraphQLTypes["categorysummaryview_aggregate"],
		/** fetch data from the table: "channels" */
		channels: Array<GraphQLTypes["channels"]>,
		/** fetch aggregated fields from the table: "channels" */
		channels_aggregate: GraphQLTypes["channels_aggregate"],
		/** fetch data from the table: "channels" using primary key columns */
		channels_by_pk?: GraphQLTypes["channels"] | undefined,
		/** fetch data from the table: "consumptionByChannelView" */
		consumptionByChannelView: Array<GraphQLTypes["consumptionByChannelView"]>,
		/** fetch aggregated fields from the table: "consumptionByChannelView" */
		consumptionByChannelView_aggregate: GraphQLTypes["consumptionByChannelView_aggregate"],
		/** fetch data from the table: "costTable" */
		costTable: Array<GraphQLTypes["costTable"]>,
		/** fetch aggregated fields from the table: "costTable" */
		costTable_aggregate: GraphQLTypes["costTable_aggregate"],
		/** fetch data from the table: "costTable" using primary key columns */
		costTable_by_pk?: GraphQLTypes["costTable"] | undefined,
		/** fetch data from the table: "deal" */
		deal: Array<GraphQLTypes["deal"]>,
		/** fetch data from the table: "dealStatus" */
		dealStatus: Array<GraphQLTypes["dealStatus"]>,
		/** fetch aggregated fields from the table: "dealStatus" */
		dealStatus_aggregate: GraphQLTypes["dealStatus_aggregate"],
		/** fetch data from the table: "dealStatus" using primary key columns */
		dealStatus_by_pk?: GraphQLTypes["dealStatus"] | undefined,
		/** fetch aggregated fields from the table: "deal" */
		deal_aggregate: GraphQLTypes["deal_aggregate"],
		/** fetch data from the table: "deal" using primary key columns */
		deal_by_pk?: GraphQLTypes["deal"] | undefined,
		/** fetch data from the table: "displayCategory" */
		displayCategory: Array<GraphQLTypes["displayCategory"]>,
		/** fetch aggregated fields from the table: "displayCategory" */
		displayCategory_aggregate: GraphQLTypes["displayCategory_aggregate"],
		/** fetch data from the table: "displayCategory" using primary key columns */
		displayCategory_by_pk?: GraphQLTypes["displayCategory"] | undefined,
		/** fetch data from the table: "displayDimension" */
		displayDimension: Array<GraphQLTypes["displayDimension"]>,
		/** fetch aggregated fields from the table: "displayDimension" */
		displayDimension_aggregate: GraphQLTypes["displayDimension_aggregate"],
		/** fetch data from the table: "displayDimension" using primary key columns */
		displayDimension_by_pk?: GraphQLTypes["displayDimension"] | undefined,
		/** fetch data from the table: "displayFormats" */
		displayFormats: Array<GraphQLTypes["displayFormats"]>,
		/** fetch aggregated fields from the table: "displayFormats" */
		displayFormats_aggregate: GraphQLTypes["displayFormats_aggregate"],
		/** fetch data from the table: "displayFormats" using primary key columns */
		displayFormats_by_pk?: GraphQLTypes["displayFormats"] | undefined,
		/** fetch data from the table: "displayTemplate" */
		displayTemplate: Array<GraphQLTypes["displayTemplate"]>,
		/** fetch aggregated fields from the table: "displayTemplate" */
		displayTemplate_aggregate: GraphQLTypes["displayTemplate_aggregate"],
		/** fetch data from the table: "displayTemplate" using primary key columns */
		displayTemplate_by_pk?: GraphQLTypes["displayTemplate"] | undefined,
		/** fetch data from the table: "storage.files" using primary key columns */
		file?: GraphQLTypes["files"] | undefined,
		/** An array relationship */
		files: Array<GraphQLTypes["files"]>,
		/** fetch aggregated fields from the table: "storage.files" */
		filesAggregate: GraphQLTypes["files_aggregate"],
		generateImageFromPrompt?: GraphQLTypes["GenerateImageFromPromptOutput"] | undefined,
		generateNextMonthCalendar?: GraphQLTypes["generateNextMonthCalendar"] | undefined,
		getFacebookPages?: GraphQLTypes["GetFacebookPagesOutput"] | undefined,
		getFacebookToken?: GraphQLTypes["GetFacebookTokenOutput"] | undefined,
		getImageFromDisplayRenderer?: GraphQLTypes["GetImageFromDisplayRendererOutput"] | undefined,
		getInstagramPages?: GraphQLTypes["GetInstagramPagesOutput"] | undefined,
		getInstagramToken?: GraphQLTypes["GetInstagramTokenOutput"] | undefined,
		getPaymentUrl?: GraphQLTypes["GetPaymentUrlResponse"] | undefined,
		getWordpressCategories?: GraphQLTypes["GetWordpressCategoriesOutput"] | undefined,
		getWordpressTags?: GraphQLTypes["GetWordpressTagsOutput"] | undefined,
		/** fetch data from the table: "interaction" */
		interaction: Array<GraphQLTypes["interaction"]>,
		/** fetch aggregated fields from the table: "interaction" */
		interaction_aggregate: GraphQLTypes["interaction_aggregate"],
		/** fetch data from the table: "interaction" using primary key columns */
		interaction_by_pk?: GraphQLTypes["interaction"] | undefined,
		/** fetch data from the table: "landingPageTemplate" */
		landingPageTemplate: Array<GraphQLTypes["landingPageTemplate"]>,
		/** fetch aggregated fields from the table: "landingPageTemplate" */
		landingPageTemplate_aggregate: GraphQLTypes["landingPageTemplate_aggregate"],
		/** fetch data from the table: "landingPageTemplate" using primary key columns */
		landingPageTemplate_by_pk?: GraphQLTypes["landingPageTemplate"] | undefined,
		manageSubscription?: GraphQLTypes["ManageSubscriptionOutput"] | undefined,
		/** fetch data from the table: "newTemplateType" */
		newTemplateType: Array<GraphQLTypes["newTemplateType"]>,
		/** fetch aggregated fields from the table: "newTemplateType" */
		newTemplateType_aggregate: GraphQLTypes["newTemplateType_aggregate"],
		/** fetch data from the table: "newTemplateType" using primary key columns */
		newTemplateType_by_pk?: GraphQLTypes["newTemplateType"] | undefined,
		/** fetch data from the table: "openAIApiKey" */
		openAIApiKey: Array<GraphQLTypes["openAIApiKey"]>,
		/** fetch aggregated fields from the table: "openAIApiKey" */
		openAIApiKey_aggregate: GraphQLTypes["openAIApiKey_aggregate"],
		/** fetch data from the table: "openAIApiKey" using primary key columns */
		openAIApiKey_by_pk?: GraphQLTypes["openAIApiKey"] | undefined,
		/** fetch data from the table: "pTemplate" */
		pTemplate: Array<GraphQLTypes["pTemplate"]>,
		/** fetch aggregated fields from the table: "pTemplate" */
		pTemplate_aggregate: GraphQLTypes["pTemplate_aggregate"],
		/** fetch data from the table: "pTemplate" using primary key columns */
		pTemplate_by_pk?: GraphQLTypes["pTemplate"] | undefined,
		/** fetch data from the table: "pricingPlan" */
		pricingPlan: Array<GraphQLTypes["pricingPlan"]>,
		/** fetch aggregated fields from the table: "pricingPlan" */
		pricingPlan_aggregate: GraphQLTypes["pricingPlan_aggregate"],
		/** fetch data from the table: "pricingPlan" using primary key columns */
		pricingPlan_by_pk?: GraphQLTypes["pricingPlan"] | undefined,
		/** An array relationship */
		products: Array<GraphQLTypes["products"]>,
		/** An aggregate relationship */
		products_aggregate: GraphQLTypes["products_aggregate"],
		/** fetch data from the table: "products" using primary key columns */
		products_by_pk?: GraphQLTypes["products"] | undefined,
		/** fetch data from the table: "project" */
		project: Array<GraphQLTypes["project"]>,
		/** fetch data from the table: "projectImage" */
		projectImage: Array<GraphQLTypes["projectImage"]>,
		/** fetch aggregated fields from the table: "projectImage" */
		projectImage_aggregate: GraphQLTypes["projectImage_aggregate"],
		/** fetch data from the table: "projectImage" using primary key columns */
		projectImage_by_pk?: GraphQLTypes["projectImage"] | undefined,
		/** fetch aggregated fields from the table: "project" */
		project_aggregate: GraphQLTypes["project_aggregate"],
		/** fetch data from the table: "project" using primary key columns */
		project_by_pk?: GraphQLTypes["project"] | undefined,
		renderDisplay?: GraphQLTypes["RenderDisplayOutput"] | undefined,
		searchImagesFromPexels?: GraphQLTypes["SearchImagesFromPexelsResponse"] | undefined,
		serpStatTest?: GraphQLTypes["SerpStatTestOutput"] | undefined,
		/** fetch data from the table: "task" */
		task: Array<GraphQLTypes["task"]>,
		/** fetch aggregated fields from the table: "task" */
		task_aggregate: GraphQLTypes["task_aggregate"],
		/** fetch data from the table: "task" using primary key columns */
		task_by_pk?: GraphQLTypes["task"] | undefined,
		/** fetch data from the table: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat: Array<GraphQLTypes["textTemplateDisplayFormat"]>,
		/** fetch aggregated fields from the table: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat_aggregate: GraphQLTypes["textTemplateDisplayFormat_aggregate"],
		/** fetch data from the table: "textTemplateDisplayFormat" using primary key columns */
		textTemplateDisplayFormat_by_pk?: GraphQLTypes["textTemplateDisplayFormat"] | undefined,
		/** fetch data from the table: "auth.users" using primary key columns */
		user?: GraphQLTypes["users"] | undefined,
		/** fetch data from the table: "userAppIntegration" */
		userAppIntegration: Array<GraphQLTypes["userAppIntegration"]>,
		/** fetch aggregated fields from the table: "userAppIntegration" */
		userAppIntegration_aggregate: GraphQLTypes["userAppIntegration_aggregate"],
		/** fetch data from the table: "userAppIntegration" using primary key columns */
		userAppIntegration_by_pk?: GraphQLTypes["userAppIntegration"] | undefined,
		/** fetch data from the table: "userCreditUsage" */
		userCreditUsage: Array<GraphQLTypes["userCreditUsage"]>,
		/** fetch data from the table: "userCreditUsageSummary" */
		userCreditUsageSummary: Array<GraphQLTypes["userCreditUsageSummary"]>,
		/** fetch aggregated fields from the table: "userCreditUsageSummary" */
		userCreditUsageSummary_aggregate: GraphQLTypes["userCreditUsageSummary_aggregate"],
		/** fetch data from the table: "userCreditUsageSummary" using primary key columns */
		userCreditUsageSummary_by_pk?: GraphQLTypes["userCreditUsageSummary"] | undefined,
		/** fetch aggregated fields from the table: "userCreditUsage" */
		userCreditUsage_aggregate: GraphQLTypes["userCreditUsage_aggregate"],
		/** fetch data from the table: "userCreditUsage" using primary key columns */
		userCreditUsage_by_pk?: GraphQLTypes["userCreditUsage"] | undefined,
		/** fetch data from the table: "userCredits" */
		userCredits: Array<GraphQLTypes["userCredits"]>,
		/** fetch aggregated fields from the table: "userCredits" */
		userCredits_aggregate: GraphQLTypes["userCredits_aggregate"],
		/** fetch data from the table: "userCredits" using primary key columns */
		userCredits_by_pk?: GraphQLTypes["userCredits"] | undefined,
		/** fetch data from the table: "userFeedback" */
		userFeedback: Array<GraphQLTypes["userFeedback"]>,
		/** fetch aggregated fields from the table: "userFeedback" */
		userFeedback_aggregate: GraphQLTypes["userFeedback_aggregate"],
		/** fetch data from the table: "userFeedback" using primary key columns */
		userFeedback_by_pk?: GraphQLTypes["userFeedback"] | undefined,
		/** fetch data from the table: "userMedia" */
		userMedia: Array<GraphQLTypes["userMedia"]>,
		/** fetch aggregated fields from the table: "userMedia" */
		userMedia_aggregate: GraphQLTypes["userMedia_aggregate"],
		/** fetch data from the table: "userMedia" using primary key columns */
		userMedia_by_pk?: GraphQLTypes["userMedia"] | undefined,
		/** An array relationship */
		userMetadata: Array<GraphQLTypes["userMetadata"]>,
		/** An aggregate relationship */
		userMetadata_aggregate: GraphQLTypes["userMetadata_aggregate"],
		/** fetch data from the table: "userMetadata" using primary key columns */
		userMetadata_by_pk?: GraphQLTypes["userMetadata"] | undefined,
		/** fetch data from the table: "userOnboarding" */
		userOnboarding: Array<GraphQLTypes["userOnboarding"]>,
		/** fetch aggregated fields from the table: "userOnboarding" */
		userOnboarding_aggregate: GraphQLTypes["userOnboarding_aggregate"],
		/** fetch data from the table: "userOnboarding" using primary key columns */
		userOnboarding_by_pk?: GraphQLTypes["userOnboarding"] | undefined,
		/** fetch data from the table: "userTemplateUsage" */
		userTemplateUsage: Array<GraphQLTypes["userTemplateUsage"]>,
		/** fetch aggregated fields from the table: "userTemplateUsage" */
		userTemplateUsage_aggregate: GraphQLTypes["userTemplateUsage_aggregate"],
		/** fetch data from the table: "userTemplateUsage" using primary key columns */
		userTemplateUsage_by_pk?: GraphQLTypes["userTemplateUsage"] | undefined,
		/** fetch data from the table: "auth.users" */
		users: Array<GraphQLTypes["users"]>,
		/** fetch aggregated fields from the table: "auth.users" */
		usersAggregate: GraphQLTypes["users_aggregate"],
		/** fetch data from the table: "storage.virus" using primary key columns */
		virus?: GraphQLTypes["virus"] | undefined,
		/** fetch data from the table: "storage.virus" */
		viruses: Array<GraphQLTypes["virus"]>,
		/** fetch aggregated fields from the table: "storage.virus" */
		virusesAggregate: GraphQLTypes["virus_aggregate"],
		/** fetch data from the table: "website" */
		website: Array<GraphQLTypes["website"]>,
		/** fetch aggregated fields from the table: "website" */
		website_aggregate: GraphQLTypes["website_aggregate"],
		/** fetch data from the table: "website" using primary key columns */
		website_by_pk?: GraphQLTypes["website"] | undefined
	};
	["subscription_root"]: {
		__typename: "subscription_root",
		/** fetch data from the table: "AppIntegration" */
		AppIntegration: Array<GraphQLTypes["AppIntegration"]>,
		/** fetch aggregated fields from the table: "AppIntegration" */
		AppIntegration_aggregate: GraphQLTypes["AppIntegration_aggregate"],
		/** fetch data from the table: "AppIntegration" using primary key columns */
		AppIntegration_by_pk?: GraphQLTypes["AppIntegration"] | undefined,
		/** fetch data from the table in a streaming manner: "AppIntegration" */
		AppIntegration_stream: Array<GraphQLTypes["AppIntegration"]>,
		/** fetch data from the table: "Course" */
		Course: Array<GraphQLTypes["Course"]>,
		/** fetch data from the table: "CourseCategory" */
		CourseCategory: Array<GraphQLTypes["CourseCategory"]>,
		/** fetch aggregated fields from the table: "CourseCategory" */
		CourseCategory_aggregate: GraphQLTypes["CourseCategory_aggregate"],
		/** fetch data from the table: "CourseCategory" using primary key columns */
		CourseCategory_by_pk?: GraphQLTypes["CourseCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "CourseCategory" */
		CourseCategory_stream: Array<GraphQLTypes["CourseCategory"]>,
		/** fetch aggregated fields from the table: "Course" */
		Course_aggregate: GraphQLTypes["Course_aggregate"],
		/** fetch data from the table: "Course" using primary key columns */
		Course_by_pk?: GraphQLTypes["Course"] | undefined,
		/** fetch data from the table in a streaming manner: "Course" */
		Course_stream: Array<GraphQLTypes["Course"]>,
		/** fetch data from the table: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate: Array<GraphQLTypes["GeneratedPromptByTemplate"]>,
		/** fetch data from the table: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage: Array<GraphQLTypes["GeneratedPromptByTemplateImage"]>,
		/** fetch aggregated fields from the table: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage_aggregate: GraphQLTypes["GeneratedPromptByTemplateImage_aggregate"],
		/** fetch data from the table: "GeneratedPromptByTemplateImage" using primary key columns */
		GeneratedPromptByTemplateImage_by_pk?: GraphQLTypes["GeneratedPromptByTemplateImage"] | undefined,
		/** fetch data from the table in a streaming manner: "GeneratedPromptByTemplateImage" */
		GeneratedPromptByTemplateImage_stream: Array<GraphQLTypes["GeneratedPromptByTemplateImage"]>,
		/** fetch aggregated fields from the table: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate_aggregate: GraphQLTypes["GeneratedPromptByTemplate_aggregate"],
		/** fetch data from the table: "GeneratedPromptByTemplate" using primary key columns */
		GeneratedPromptByTemplate_by_pk?: GraphQLTypes["GeneratedPromptByTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "GeneratedPromptByTemplate" */
		GeneratedPromptByTemplate_stream: Array<GraphQLTypes["GeneratedPromptByTemplate"]>,
		/** fetch data from the table: "TextGeneratorOption" */
		TextGeneratorOption: Array<GraphQLTypes["TextGeneratorOption"]>,
		/** fetch data from the table: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry: Array<GraphQLTypes["TextGeneratorOptionEntry"]>,
		/** fetch aggregated fields from the table: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry_aggregate: GraphQLTypes["TextGeneratorOptionEntry_aggregate"],
		/** fetch data from the table: "TextGeneratorOptionEntry" using primary key columns */
		TextGeneratorOptionEntry_by_pk?: GraphQLTypes["TextGeneratorOptionEntry"] | undefined,
		/** fetch data from the table in a streaming manner: "TextGeneratorOptionEntry" */
		TextGeneratorOptionEntry_stream: Array<GraphQLTypes["TextGeneratorOptionEntry"]>,
		/** fetch aggregated fields from the table: "TextGeneratorOption" */
		TextGeneratorOption_aggregate: GraphQLTypes["TextGeneratorOption_aggregate"],
		/** fetch data from the table: "TextGeneratorOption" using primary key columns */
		TextGeneratorOption_by_pk?: GraphQLTypes["TextGeneratorOption"] | undefined,
		/** fetch data from the table in a streaming manner: "TextGeneratorOption" */
		TextGeneratorOption_stream: Array<GraphQLTypes["TextGeneratorOption"]>,
		/** fetch data from the table: "TextPromptTemplate" */
		TextPromptTemplate: Array<GraphQLTypes["TextPromptTemplate"]>,
		/** fetch data from the table: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory: Array<GraphQLTypes["TextPromptTemplateCategory"]>,
		/** fetch aggregated fields from the table: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory_aggregate: GraphQLTypes["TextPromptTemplateCategory_aggregate"],
		/** fetch data from the table: "TextPromptTemplateCategory" using primary key columns */
		TextPromptTemplateCategory_by_pk?: GraphQLTypes["TextPromptTemplateCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "TextPromptTemplateCategory" */
		TextPromptTemplateCategory_stream: Array<GraphQLTypes["TextPromptTemplateCategory"]>,
		/** fetch aggregated fields from the table: "TextPromptTemplate" */
		TextPromptTemplate_aggregate: GraphQLTypes["TextPromptTemplate_aggregate"],
		/** fetch data from the table: "TextPromptTemplate" using primary key columns */
		TextPromptTemplate_by_pk?: GraphQLTypes["TextPromptTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "TextPromptTemplate" */
		TextPromptTemplate_stream: Array<GraphQLTypes["TextPromptTemplate"]>,
		/** fetch data from the table: "TextTemplateClientCategory" */
		TextTemplateClientCategory: Array<GraphQLTypes["TextTemplateClientCategory"]>,
		/** fetch aggregated fields from the table: "TextTemplateClientCategory" */
		TextTemplateClientCategory_aggregate: GraphQLTypes["TextTemplateClientCategory_aggregate"],
		/** fetch data from the table: "TextTemplateClientCategory" using primary key columns */
		TextTemplateClientCategory_by_pk?: GraphQLTypes["TextTemplateClientCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "TextTemplateClientCategory" */
		TextTemplateClientCategory_stream: Array<GraphQLTypes["TextTemplateClientCategory"]>,
		/** fetch data from the table: "audiences" */
		audiences: Array<GraphQLTypes["audiences"]>,
		/** fetch aggregated fields from the table: "audiences" */
		audiences_aggregate: GraphQLTypes["audiences_aggregate"],
		/** fetch data from the table: "audiences" using primary key columns */
		audiences_by_pk?: GraphQLTypes["audiences"] | undefined,
		/** fetch data from the table in a streaming manner: "audiences" */
		audiences_stream: Array<GraphQLTypes["audiences"]>,
		/** fetch data from the table: "auth.providers" using primary key columns */
		authProvider?: GraphQLTypes["authProviders"] | undefined,
		/** fetch data from the table: "auth.provider_requests" using primary key columns */
		authProviderRequest?: GraphQLTypes["authProviderRequests"] | undefined,
		/** fetch data from the table: "auth.provider_requests" */
		authProviderRequests: Array<GraphQLTypes["authProviderRequests"]>,
		/** fetch aggregated fields from the table: "auth.provider_requests" */
		authProviderRequestsAggregate: GraphQLTypes["authProviderRequests_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.provider_requests" */
		authProviderRequests_stream: Array<GraphQLTypes["authProviderRequests"]>,
		/** fetch data from the table: "auth.providers" */
		authProviders: Array<GraphQLTypes["authProviders"]>,
		/** fetch aggregated fields from the table: "auth.providers" */
		authProvidersAggregate: GraphQLTypes["authProviders_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.providers" */
		authProviders_stream: Array<GraphQLTypes["authProviders"]>,
		/** fetch data from the table: "auth.refresh_tokens" using primary key columns */
		authRefreshToken?: GraphQLTypes["authRefreshTokens"] | undefined,
		/** fetch data from the table: "auth.refresh_token_types" using primary key columns */
		authRefreshTokenType?: GraphQLTypes["authRefreshTokenTypes"] | undefined,
		/** fetch data from the table: "auth.refresh_token_types" */
		authRefreshTokenTypes: Array<GraphQLTypes["authRefreshTokenTypes"]>,
		/** fetch aggregated fields from the table: "auth.refresh_token_types" */
		authRefreshTokenTypesAggregate: GraphQLTypes["authRefreshTokenTypes_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.refresh_token_types" */
		authRefreshTokenTypes_stream: Array<GraphQLTypes["authRefreshTokenTypes"]>,
		/** fetch data from the table: "auth.refresh_tokens" */
		authRefreshTokens: Array<GraphQLTypes["authRefreshTokens"]>,
		/** fetch aggregated fields from the table: "auth.refresh_tokens" */
		authRefreshTokensAggregate: GraphQLTypes["authRefreshTokens_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
		authRefreshTokens_stream: Array<GraphQLTypes["authRefreshTokens"]>,
		/** fetch data from the table: "auth.roles" using primary key columns */
		authRole?: GraphQLTypes["authRoles"] | undefined,
		/** fetch data from the table: "auth.roles" */
		authRoles: Array<GraphQLTypes["authRoles"]>,
		/** fetch aggregated fields from the table: "auth.roles" */
		authRolesAggregate: GraphQLTypes["authRoles_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.roles" */
		authRoles_stream: Array<GraphQLTypes["authRoles"]>,
		/** fetch data from the table: "auth.user_providers" using primary key columns */
		authUserProvider?: GraphQLTypes["authUserProviders"] | undefined,
		/** fetch data from the table: "auth.user_providers" */
		authUserProviders: Array<GraphQLTypes["authUserProviders"]>,
		/** fetch aggregated fields from the table: "auth.user_providers" */
		authUserProvidersAggregate: GraphQLTypes["authUserProviders_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.user_providers" */
		authUserProviders_stream: Array<GraphQLTypes["authUserProviders"]>,
		/** fetch data from the table: "auth.user_roles" using primary key columns */
		authUserRole?: GraphQLTypes["authUserRoles"] | undefined,
		/** fetch data from the table: "auth.user_roles" */
		authUserRoles: Array<GraphQLTypes["authUserRoles"]>,
		/** fetch aggregated fields from the table: "auth.user_roles" */
		authUserRolesAggregate: GraphQLTypes["authUserRoles_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.user_roles" */
		authUserRoles_stream: Array<GraphQLTypes["authUserRoles"]>,
		/** fetch data from the table: "auth.user_security_keys" using primary key columns */
		authUserSecurityKey?: GraphQLTypes["authUserSecurityKeys"] | undefined,
		/** fetch data from the table: "auth.user_security_keys" */
		authUserSecurityKeys: Array<GraphQLTypes["authUserSecurityKeys"]>,
		/** fetch aggregated fields from the table: "auth.user_security_keys" */
		authUserSecurityKeysAggregate: GraphQLTypes["authUserSecurityKeys_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.user_security_keys" */
		authUserSecurityKeys_stream: Array<GraphQLTypes["authUserSecurityKeys"]>,
		/** fetch data from the table: "blog" */
		blog: Array<GraphQLTypes["blog"]>,
		/** fetch data from the table: "blogCategory" */
		blogCategory: Array<GraphQLTypes["blogCategory"]>,
		/** fetch aggregated fields from the table: "blogCategory" */
		blogCategory_aggregate: GraphQLTypes["blogCategory_aggregate"],
		/** fetch data from the table: "blogCategory" using primary key columns */
		blogCategory_by_pk?: GraphQLTypes["blogCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "blogCategory" */
		blogCategory_stream: Array<GraphQLTypes["blogCategory"]>,
		blogPostGeneratorEnhancedForAmp?: GraphQLTypes["blogPostGeneratorEnhancedForAmp"] | undefined,
		/** fetch aggregated fields from the table: "blog" */
		blog_aggregate: GraphQLTypes["blog_aggregate"],
		/** fetch data from the table: "blog" using primary key columns */
		blog_by_pk?: GraphQLTypes["blog"] | undefined,
		/** fetch data from the table in a streaming manner: "blog" */
		blog_stream: Array<GraphQLTypes["blog"]>,
		/** fetch data from the table: "brands" */
		brands: Array<GraphQLTypes["brands"]>,
		/** fetch aggregated fields from the table: "brands" */
		brands_aggregate: GraphQLTypes["brands_aggregate"],
		/** fetch data from the table: "brands" using primary key columns */
		brands_by_pk?: GraphQLTypes["brands"] | undefined,
		/** fetch data from the table in a streaming manner: "brands" */
		brands_stream: Array<GraphQLTypes["brands"]>,
		/** fetch data from the table: "storage.buckets" using primary key columns */
		bucket?: GraphQLTypes["buckets"] | undefined,
		/** fetch data from the table: "storage.buckets" */
		buckets: Array<GraphQLTypes["buckets"]>,
		/** fetch aggregated fields from the table: "storage.buckets" */
		bucketsAggregate: GraphQLTypes["buckets_aggregate"],
		/** fetch data from the table in a streaming manner: "storage.buckets" */
		buckets_stream: Array<GraphQLTypes["buckets"]>,
		/** fetch data from the table: "campaign" */
		campaign: Array<GraphQLTypes["campaign"]>,
		/** fetch aggregated fields from the table: "campaign" */
		campaign_aggregate: GraphQLTypes["campaign_aggregate"],
		/** fetch data from the table: "campaign" using primary key columns */
		campaign_by_pk?: GraphQLTypes["campaign"] | undefined,
		/** fetch data from the table in a streaming manner: "campaign" */
		campaign_stream: Array<GraphQLTypes["campaign"]>,
		/** fetch data from the table: "categorysummaryview" */
		categorysummaryview: Array<GraphQLTypes["categorysummaryview"]>,
		/** fetch aggregated fields from the table: "categorysummaryview" */
		categorysummaryview_aggregate: GraphQLTypes["categorysummaryview_aggregate"],
		/** fetch data from the table in a streaming manner: "categorysummaryview" */
		categorysummaryview_stream: Array<GraphQLTypes["categorysummaryview"]>,
		/** fetch data from the table: "channels" */
		channels: Array<GraphQLTypes["channels"]>,
		/** fetch aggregated fields from the table: "channels" */
		channels_aggregate: GraphQLTypes["channels_aggregate"],
		/** fetch data from the table: "channels" using primary key columns */
		channels_by_pk?: GraphQLTypes["channels"] | undefined,
		/** fetch data from the table in a streaming manner: "channels" */
		channels_stream: Array<GraphQLTypes["channels"]>,
		/** fetch data from the table: "consumptionByChannelView" */
		consumptionByChannelView: Array<GraphQLTypes["consumptionByChannelView"]>,
		/** fetch aggregated fields from the table: "consumptionByChannelView" */
		consumptionByChannelView_aggregate: GraphQLTypes["consumptionByChannelView_aggregate"],
		/** fetch data from the table in a streaming manner: "consumptionByChannelView" */
		consumptionByChannelView_stream: Array<GraphQLTypes["consumptionByChannelView"]>,
		/** fetch data from the table: "costTable" */
		costTable: Array<GraphQLTypes["costTable"]>,
		/** fetch aggregated fields from the table: "costTable" */
		costTable_aggregate: GraphQLTypes["costTable_aggregate"],
		/** fetch data from the table: "costTable" using primary key columns */
		costTable_by_pk?: GraphQLTypes["costTable"] | undefined,
		/** fetch data from the table in a streaming manner: "costTable" */
		costTable_stream: Array<GraphQLTypes["costTable"]>,
		/** fetch data from the table: "deal" */
		deal: Array<GraphQLTypes["deal"]>,
		/** fetch data from the table: "dealStatus" */
		dealStatus: Array<GraphQLTypes["dealStatus"]>,
		/** fetch aggregated fields from the table: "dealStatus" */
		dealStatus_aggregate: GraphQLTypes["dealStatus_aggregate"],
		/** fetch data from the table: "dealStatus" using primary key columns */
		dealStatus_by_pk?: GraphQLTypes["dealStatus"] | undefined,
		/** fetch data from the table in a streaming manner: "dealStatus" */
		dealStatus_stream: Array<GraphQLTypes["dealStatus"]>,
		/** fetch aggregated fields from the table: "deal" */
		deal_aggregate: GraphQLTypes["deal_aggregate"],
		/** fetch data from the table: "deal" using primary key columns */
		deal_by_pk?: GraphQLTypes["deal"] | undefined,
		/** fetch data from the table in a streaming manner: "deal" */
		deal_stream: Array<GraphQLTypes["deal"]>,
		/** fetch data from the table: "displayCategory" */
		displayCategory: Array<GraphQLTypes["displayCategory"]>,
		/** fetch aggregated fields from the table: "displayCategory" */
		displayCategory_aggregate: GraphQLTypes["displayCategory_aggregate"],
		/** fetch data from the table: "displayCategory" using primary key columns */
		displayCategory_by_pk?: GraphQLTypes["displayCategory"] | undefined,
		/** fetch data from the table in a streaming manner: "displayCategory" */
		displayCategory_stream: Array<GraphQLTypes["displayCategory"]>,
		/** fetch data from the table: "displayDimension" */
		displayDimension: Array<GraphQLTypes["displayDimension"]>,
		/** fetch aggregated fields from the table: "displayDimension" */
		displayDimension_aggregate: GraphQLTypes["displayDimension_aggregate"],
		/** fetch data from the table: "displayDimension" using primary key columns */
		displayDimension_by_pk?: GraphQLTypes["displayDimension"] | undefined,
		/** fetch data from the table in a streaming manner: "displayDimension" */
		displayDimension_stream: Array<GraphQLTypes["displayDimension"]>,
		/** fetch data from the table: "displayFormats" */
		displayFormats: Array<GraphQLTypes["displayFormats"]>,
		/** fetch aggregated fields from the table: "displayFormats" */
		displayFormats_aggregate: GraphQLTypes["displayFormats_aggregate"],
		/** fetch data from the table: "displayFormats" using primary key columns */
		displayFormats_by_pk?: GraphQLTypes["displayFormats"] | undefined,
		/** fetch data from the table in a streaming manner: "displayFormats" */
		displayFormats_stream: Array<GraphQLTypes["displayFormats"]>,
		/** fetch data from the table: "displayTemplate" */
		displayTemplate: Array<GraphQLTypes["displayTemplate"]>,
		/** fetch aggregated fields from the table: "displayTemplate" */
		displayTemplate_aggregate: GraphQLTypes["displayTemplate_aggregate"],
		/** fetch data from the table: "displayTemplate" using primary key columns */
		displayTemplate_by_pk?: GraphQLTypes["displayTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "displayTemplate" */
		displayTemplate_stream: Array<GraphQLTypes["displayTemplate"]>,
		/** fetch data from the table: "storage.files" using primary key columns */
		file?: GraphQLTypes["files"] | undefined,
		/** An array relationship */
		files: Array<GraphQLTypes["files"]>,
		/** fetch aggregated fields from the table: "storage.files" */
		filesAggregate: GraphQLTypes["files_aggregate"],
		/** fetch data from the table in a streaming manner: "storage.files" */
		files_stream: Array<GraphQLTypes["files"]>,
		generateNextMonthCalendar?: GraphQLTypes["generateNextMonthCalendar"] | undefined,
		/** fetch data from the table: "interaction" */
		interaction: Array<GraphQLTypes["interaction"]>,
		/** fetch aggregated fields from the table: "interaction" */
		interaction_aggregate: GraphQLTypes["interaction_aggregate"],
		/** fetch data from the table: "interaction" using primary key columns */
		interaction_by_pk?: GraphQLTypes["interaction"] | undefined,
		/** fetch data from the table in a streaming manner: "interaction" */
		interaction_stream: Array<GraphQLTypes["interaction"]>,
		/** fetch data from the table: "landingPageTemplate" */
		landingPageTemplate: Array<GraphQLTypes["landingPageTemplate"]>,
		/** fetch aggregated fields from the table: "landingPageTemplate" */
		landingPageTemplate_aggregate: GraphQLTypes["landingPageTemplate_aggregate"],
		/** fetch data from the table: "landingPageTemplate" using primary key columns */
		landingPageTemplate_by_pk?: GraphQLTypes["landingPageTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "landingPageTemplate" */
		landingPageTemplate_stream: Array<GraphQLTypes["landingPageTemplate"]>,
		/** fetch data from the table: "newTemplateType" */
		newTemplateType: Array<GraphQLTypes["newTemplateType"]>,
		/** fetch aggregated fields from the table: "newTemplateType" */
		newTemplateType_aggregate: GraphQLTypes["newTemplateType_aggregate"],
		/** fetch data from the table: "newTemplateType" using primary key columns */
		newTemplateType_by_pk?: GraphQLTypes["newTemplateType"] | undefined,
		/** fetch data from the table in a streaming manner: "newTemplateType" */
		newTemplateType_stream: Array<GraphQLTypes["newTemplateType"]>,
		/** fetch data from the table: "openAIApiKey" */
		openAIApiKey: Array<GraphQLTypes["openAIApiKey"]>,
		/** fetch aggregated fields from the table: "openAIApiKey" */
		openAIApiKey_aggregate: GraphQLTypes["openAIApiKey_aggregate"],
		/** fetch data from the table: "openAIApiKey" using primary key columns */
		openAIApiKey_by_pk?: GraphQLTypes["openAIApiKey"] | undefined,
		/** fetch data from the table in a streaming manner: "openAIApiKey" */
		openAIApiKey_stream: Array<GraphQLTypes["openAIApiKey"]>,
		/** fetch data from the table: "pTemplate" */
		pTemplate: Array<GraphQLTypes["pTemplate"]>,
		/** fetch aggregated fields from the table: "pTemplate" */
		pTemplate_aggregate: GraphQLTypes["pTemplate_aggregate"],
		/** fetch data from the table: "pTemplate" using primary key columns */
		pTemplate_by_pk?: GraphQLTypes["pTemplate"] | undefined,
		/** fetch data from the table in a streaming manner: "pTemplate" */
		pTemplate_stream: Array<GraphQLTypes["pTemplate"]>,
		/** fetch data from the table: "pricingPlan" */
		pricingPlan: Array<GraphQLTypes["pricingPlan"]>,
		/** fetch aggregated fields from the table: "pricingPlan" */
		pricingPlan_aggregate: GraphQLTypes["pricingPlan_aggregate"],
		/** fetch data from the table: "pricingPlan" using primary key columns */
		pricingPlan_by_pk?: GraphQLTypes["pricingPlan"] | undefined,
		/** fetch data from the table in a streaming manner: "pricingPlan" */
		pricingPlan_stream: Array<GraphQLTypes["pricingPlan"]>,
		/** An array relationship */
		products: Array<GraphQLTypes["products"]>,
		/** An aggregate relationship */
		products_aggregate: GraphQLTypes["products_aggregate"],
		/** fetch data from the table: "products" using primary key columns */
		products_by_pk?: GraphQLTypes["products"] | undefined,
		/** fetch data from the table in a streaming manner: "products" */
		products_stream: Array<GraphQLTypes["products"]>,
		/** fetch data from the table: "project" */
		project: Array<GraphQLTypes["project"]>,
		/** fetch data from the table: "projectImage" */
		projectImage: Array<GraphQLTypes["projectImage"]>,
		/** fetch aggregated fields from the table: "projectImage" */
		projectImage_aggregate: GraphQLTypes["projectImage_aggregate"],
		/** fetch data from the table: "projectImage" using primary key columns */
		projectImage_by_pk?: GraphQLTypes["projectImage"] | undefined,
		/** fetch data from the table in a streaming manner: "projectImage" */
		projectImage_stream: Array<GraphQLTypes["projectImage"]>,
		/** fetch aggregated fields from the table: "project" */
		project_aggregate: GraphQLTypes["project_aggregate"],
		/** fetch data from the table: "project" using primary key columns */
		project_by_pk?: GraphQLTypes["project"] | undefined,
		/** fetch data from the table in a streaming manner: "project" */
		project_stream: Array<GraphQLTypes["project"]>,
		/** fetch data from the table: "task" */
		task: Array<GraphQLTypes["task"]>,
		/** fetch aggregated fields from the table: "task" */
		task_aggregate: GraphQLTypes["task_aggregate"],
		/** fetch data from the table: "task" using primary key columns */
		task_by_pk?: GraphQLTypes["task"] | undefined,
		/** fetch data from the table in a streaming manner: "task" */
		task_stream: Array<GraphQLTypes["task"]>,
		/** fetch data from the table: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat: Array<GraphQLTypes["textTemplateDisplayFormat"]>,
		/** fetch aggregated fields from the table: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat_aggregate: GraphQLTypes["textTemplateDisplayFormat_aggregate"],
		/** fetch data from the table: "textTemplateDisplayFormat" using primary key columns */
		textTemplateDisplayFormat_by_pk?: GraphQLTypes["textTemplateDisplayFormat"] | undefined,
		/** fetch data from the table in a streaming manner: "textTemplateDisplayFormat" */
		textTemplateDisplayFormat_stream: Array<GraphQLTypes["textTemplateDisplayFormat"]>,
		/** fetch data from the table: "auth.users" using primary key columns */
		user?: GraphQLTypes["users"] | undefined,
		/** fetch data from the table: "userAppIntegration" */
		userAppIntegration: Array<GraphQLTypes["userAppIntegration"]>,
		/** fetch aggregated fields from the table: "userAppIntegration" */
		userAppIntegration_aggregate: GraphQLTypes["userAppIntegration_aggregate"],
		/** fetch data from the table: "userAppIntegration" using primary key columns */
		userAppIntegration_by_pk?: GraphQLTypes["userAppIntegration"] | undefined,
		/** fetch data from the table in a streaming manner: "userAppIntegration" */
		userAppIntegration_stream: Array<GraphQLTypes["userAppIntegration"]>,
		/** fetch data from the table: "userCreditUsage" */
		userCreditUsage: Array<GraphQLTypes["userCreditUsage"]>,
		/** fetch data from the table: "userCreditUsageSummary" */
		userCreditUsageSummary: Array<GraphQLTypes["userCreditUsageSummary"]>,
		/** fetch aggregated fields from the table: "userCreditUsageSummary" */
		userCreditUsageSummary_aggregate: GraphQLTypes["userCreditUsageSummary_aggregate"],
		/** fetch data from the table: "userCreditUsageSummary" using primary key columns */
		userCreditUsageSummary_by_pk?: GraphQLTypes["userCreditUsageSummary"] | undefined,
		/** fetch data from the table in a streaming manner: "userCreditUsageSummary" */
		userCreditUsageSummary_stream: Array<GraphQLTypes["userCreditUsageSummary"]>,
		/** fetch aggregated fields from the table: "userCreditUsage" */
		userCreditUsage_aggregate: GraphQLTypes["userCreditUsage_aggregate"],
		/** fetch data from the table: "userCreditUsage" using primary key columns */
		userCreditUsage_by_pk?: GraphQLTypes["userCreditUsage"] | undefined,
		/** fetch data from the table in a streaming manner: "userCreditUsage" */
		userCreditUsage_stream: Array<GraphQLTypes["userCreditUsage"]>,
		/** fetch data from the table: "userCredits" */
		userCredits: Array<GraphQLTypes["userCredits"]>,
		/** fetch aggregated fields from the table: "userCredits" */
		userCredits_aggregate: GraphQLTypes["userCredits_aggregate"],
		/** fetch data from the table: "userCredits" using primary key columns */
		userCredits_by_pk?: GraphQLTypes["userCredits"] | undefined,
		/** fetch data from the table in a streaming manner: "userCredits" */
		userCredits_stream: Array<GraphQLTypes["userCredits"]>,
		/** fetch data from the table: "userFeedback" */
		userFeedback: Array<GraphQLTypes["userFeedback"]>,
		/** fetch aggregated fields from the table: "userFeedback" */
		userFeedback_aggregate: GraphQLTypes["userFeedback_aggregate"],
		/** fetch data from the table: "userFeedback" using primary key columns */
		userFeedback_by_pk?: GraphQLTypes["userFeedback"] | undefined,
		/** fetch data from the table in a streaming manner: "userFeedback" */
		userFeedback_stream: Array<GraphQLTypes["userFeedback"]>,
		/** fetch data from the table: "userMedia" */
		userMedia: Array<GraphQLTypes["userMedia"]>,
		/** fetch aggregated fields from the table: "userMedia" */
		userMedia_aggregate: GraphQLTypes["userMedia_aggregate"],
		/** fetch data from the table: "userMedia" using primary key columns */
		userMedia_by_pk?: GraphQLTypes["userMedia"] | undefined,
		/** fetch data from the table in a streaming manner: "userMedia" */
		userMedia_stream: Array<GraphQLTypes["userMedia"]>,
		/** An array relationship */
		userMetadata: Array<GraphQLTypes["userMetadata"]>,
		/** An aggregate relationship */
		userMetadata_aggregate: GraphQLTypes["userMetadata_aggregate"],
		/** fetch data from the table: "userMetadata" using primary key columns */
		userMetadata_by_pk?: GraphQLTypes["userMetadata"] | undefined,
		/** fetch data from the table in a streaming manner: "userMetadata" */
		userMetadata_stream: Array<GraphQLTypes["userMetadata"]>,
		/** fetch data from the table: "userOnboarding" */
		userOnboarding: Array<GraphQLTypes["userOnboarding"]>,
		/** fetch aggregated fields from the table: "userOnboarding" */
		userOnboarding_aggregate: GraphQLTypes["userOnboarding_aggregate"],
		/** fetch data from the table: "userOnboarding" using primary key columns */
		userOnboarding_by_pk?: GraphQLTypes["userOnboarding"] | undefined,
		/** fetch data from the table in a streaming manner: "userOnboarding" */
		userOnboarding_stream: Array<GraphQLTypes["userOnboarding"]>,
		/** fetch data from the table: "userTemplateUsage" */
		userTemplateUsage: Array<GraphQLTypes["userTemplateUsage"]>,
		/** fetch aggregated fields from the table: "userTemplateUsage" */
		userTemplateUsage_aggregate: GraphQLTypes["userTemplateUsage_aggregate"],
		/** fetch data from the table: "userTemplateUsage" using primary key columns */
		userTemplateUsage_by_pk?: GraphQLTypes["userTemplateUsage"] | undefined,
		/** fetch data from the table in a streaming manner: "userTemplateUsage" */
		userTemplateUsage_stream: Array<GraphQLTypes["userTemplateUsage"]>,
		/** fetch data from the table: "auth.users" */
		users: Array<GraphQLTypes["users"]>,
		/** fetch aggregated fields from the table: "auth.users" */
		usersAggregate: GraphQLTypes["users_aggregate"],
		/** fetch data from the table in a streaming manner: "auth.users" */
		users_stream: Array<GraphQLTypes["users"]>,
		/** fetch data from the table: "storage.virus" using primary key columns */
		virus?: GraphQLTypes["virus"] | undefined,
		/** fetch data from the table in a streaming manner: "storage.virus" */
		virus_stream: Array<GraphQLTypes["virus"]>,
		/** fetch data from the table: "storage.virus" */
		viruses: Array<GraphQLTypes["virus"]>,
		/** fetch aggregated fields from the table: "storage.virus" */
		virusesAggregate: GraphQLTypes["virus_aggregate"],
		/** fetch data from the table: "website" */
		website: Array<GraphQLTypes["website"]>,
		/** fetch aggregated fields from the table: "website" */
		website_aggregate: GraphQLTypes["website_aggregate"],
		/** fetch data from the table: "website" using primary key columns */
		website_by_pk?: GraphQLTypes["website"] | undefined,
		/** fetch data from the table in a streaming manner: "website" */
		website_stream: Array<GraphQLTypes["website"]>
	};
	/** columns and relationships of "task" */
	["task"]: {
		__typename: "task",
		category: GraphQLTypes["uuid"],
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		date: GraphQLTypes["timestamp"],
		/** An object relationship */
		deal: GraphQLTypes["deal"],
		dealId: GraphQLTypes["uuid"],
		description: string,
		finished: boolean,
		id: GraphQLTypes["uuid"],
		title: string,
		updated_at: GraphQLTypes["timestamptz"],
		/** An object relationship */
		user: GraphQLTypes["users"]
	};
	/** aggregated selection of "task" */
	["task_aggregate"]: {
		__typename: "task_aggregate",
		aggregate?: GraphQLTypes["task_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["task"]>
	};
	["task_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["task_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: GraphQLTypes["task_aggregate_bool_exp_bool_or"] | undefined,
		count?: GraphQLTypes["task_aggregate_bool_exp_count"] | undefined
	};
	["task_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["task_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["task_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["task_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["task_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["task_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["task_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "task" */
	["task_aggregate_fields"]: {
		__typename: "task_aggregate_fields",
		count: number,
		max?: GraphQLTypes["task_max_fields"] | undefined,
		min?: GraphQLTypes["task_min_fields"] | undefined
	};
	/** order by aggregate values of table "task" */
	["task_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["task_max_order_by"] | undefined,
		min?: GraphQLTypes["task_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "task" */
	["task_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["task_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["task_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "task". All fields are combined with a logical 'AND'. */
	["task_bool_exp"]: {
		_and?: Array<GraphQLTypes["task_bool_exp"]> | undefined,
		_not?: GraphQLTypes["task_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["task_bool_exp"]> | undefined,
		category?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		date?: GraphQLTypes["timestamp_comparison_exp"] | undefined,
		deal?: GraphQLTypes["deal_bool_exp"] | undefined,
		dealId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		description?: GraphQLTypes["String_comparison_exp"] | undefined,
		finished?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		title?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		user?: GraphQLTypes["users_bool_exp"] | undefined
	};
	/** unique or primary key constraints on table "task" */
	["task_constraint"]: task_constraint;
	/** input type for inserting data into table "task" */
	["task_insert_input"]: {
		category?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamp"] | undefined,
		deal?: GraphQLTypes["deal_obj_rel_insert_input"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		description?: string | undefined,
		finished?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		user?: GraphQLTypes["users_obj_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["task_max_fields"]: {
		__typename: "task_max_fields",
		category?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamp"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "task" */
	["task_max_order_by"]: {
		category?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		date?: GraphQLTypes["order_by"] | undefined,
		dealId?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		title?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["task_min_fields"]: {
		__typename: "task_min_fields",
		category?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamp"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		description?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "task" */
	["task_min_order_by"]: {
		category?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		date?: GraphQLTypes["order_by"] | undefined,
		dealId?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		title?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "task" */
	["task_mutation_response"]: {
		__typename: "task_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["task"]>
	};
	/** on_conflict condition type for table "task" */
	["task_on_conflict"]: {
		constraint: GraphQLTypes["task_constraint"],
		update_columns: Array<GraphQLTypes["task_update_column"]>,
		where?: GraphQLTypes["task_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "task". */
	["task_order_by"]: {
		category?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		date?: GraphQLTypes["order_by"] | undefined,
		deal?: GraphQLTypes["deal_order_by"] | undefined,
		dealId?: GraphQLTypes["order_by"] | undefined,
		description?: GraphQLTypes["order_by"] | undefined,
		finished?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		title?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		user?: GraphQLTypes["users_order_by"] | undefined
	};
	/** primary key columns input for table: task */
	["task_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "task" */
	["task_select_column"]: task_select_column;
	/** select "task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task" */
	["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"]: task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task" */
	["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"]: task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "task" */
	["task_set_input"]: {
		category?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamp"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		description?: string | undefined,
		finished?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "task" */
	["task_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["task_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["task_stream_cursor_value_input"]: {
		category?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		date?: GraphQLTypes["timestamp"] | undefined,
		dealId?: GraphQLTypes["uuid"] | undefined,
		description?: string | undefined,
		finished?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		title?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "task" */
	["task_update_column"]: task_update_column;
	["task_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["task_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["task_bool_exp"]
	};
	/** columns and relationships of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat"]: {
		__typename: "textTemplateDisplayFormat",
		/** An object relationship */
		displayFormat: GraphQLTypes["displayFormats"],
		displayFormatId: GraphQLTypes["uuid"],
		id: GraphQLTypes["uuid"],
		textTemplateId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate"]: {
		__typename: "textTemplateDisplayFormat_aggregate",
		aggregate?: GraphQLTypes["textTemplateDisplayFormat_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["textTemplateDisplayFormat"]>
	};
	["textTemplateDisplayFormat_aggregate_bool_exp"]: {
		count?: GraphQLTypes["textTemplateDisplayFormat_aggregate_bool_exp_count"] | undefined
	};
	["textTemplateDisplayFormat_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["textTemplateDisplayFormat_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["textTemplateDisplayFormat_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate_fields"]: {
		__typename: "textTemplateDisplayFormat_aggregate_fields",
		count: number,
		max?: GraphQLTypes["textTemplateDisplayFormat_max_fields"] | undefined,
		min?: GraphQLTypes["textTemplateDisplayFormat_min_fields"] | undefined
	};
	/** order by aggregate values of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["textTemplateDisplayFormat_max_order_by"] | undefined,
		min?: GraphQLTypes["textTemplateDisplayFormat_min_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["textTemplateDisplayFormat_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["textTemplateDisplayFormat_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "textTemplateDisplayFormat". All fields are combined with a logical 'AND'. */
	["textTemplateDisplayFormat_bool_exp"]: {
		_and?: Array<GraphQLTypes["textTemplateDisplayFormat_bool_exp"]> | undefined,
		_not?: GraphQLTypes["textTemplateDisplayFormat_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["textTemplateDisplayFormat_bool_exp"]> | undefined,
		displayFormat?: GraphQLTypes["displayFormats_bool_exp"] | undefined,
		displayFormatId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		textTemplateId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_constraint"]: textTemplateDisplayFormat_constraint;
	/** input type for inserting data into table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_insert_input"]: {
		displayFormat?: GraphQLTypes["displayFormats_obj_rel_insert_input"] | undefined,
		displayFormatId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["textTemplateDisplayFormat_max_fields"]: {
		__typename: "textTemplateDisplayFormat_max_fields",
		displayFormatId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by max() on columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_max_order_by"]: {
		displayFormatId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		textTemplateId?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["textTemplateDisplayFormat_min_fields"]: {
		__typename: "textTemplateDisplayFormat_min_fields",
		displayFormatId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** order by min() on columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_min_order_by"]: {
		displayFormatId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		textTemplateId?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_mutation_response"]: {
		__typename: "textTemplateDisplayFormat_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["textTemplateDisplayFormat"]>
	};
	/** on_conflict condition type for table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_on_conflict"]: {
		constraint: GraphQLTypes["textTemplateDisplayFormat_constraint"],
		update_columns: Array<GraphQLTypes["textTemplateDisplayFormat_update_column"]>,
		where?: GraphQLTypes["textTemplateDisplayFormat_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "textTemplateDisplayFormat". */
	["textTemplateDisplayFormat_order_by"]: {
		displayFormat?: GraphQLTypes["displayFormats_order_by"] | undefined,
		displayFormatId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		textTemplateId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: textTemplateDisplayFormat */
	["textTemplateDisplayFormat_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_select_column"]: textTemplateDisplayFormat_select_column;
	/** input type for updating data in table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_set_input"]: {
		displayFormatId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["textTemplateDisplayFormat_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["textTemplateDisplayFormat_stream_cursor_value_input"]: {
		displayFormatId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "textTemplateDisplayFormat" */
	["textTemplateDisplayFormat_update_column"]: textTemplateDisplayFormat_update_column;
	["textTemplateDisplayFormat_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["textTemplateDisplayFormat_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["textTemplateDisplayFormat_bool_exp"]
	};
	["timestamp"]: "scalar" & { name: "timestamp" };
	/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
	["timestamp_comparison_exp"]: {
		_eq?: GraphQLTypes["timestamp"] | undefined,
		_gt?: GraphQLTypes["timestamp"] | undefined,
		_gte?: GraphQLTypes["timestamp"] | undefined,
		_in?: Array<GraphQLTypes["timestamp"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: GraphQLTypes["timestamp"] | undefined,
		_lte?: GraphQLTypes["timestamp"] | undefined,
		_neq?: GraphQLTypes["timestamp"] | undefined,
		_nin?: Array<GraphQLTypes["timestamp"]> | undefined
	};
	["timestamptz"]: "scalar" & { name: "timestamptz" };
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
	["timestamptz_comparison_exp"]: {
		_eq?: GraphQLTypes["timestamptz"] | undefined,
		_gt?: GraphQLTypes["timestamptz"] | undefined,
		_gte?: GraphQLTypes["timestamptz"] | undefined,
		_in?: Array<GraphQLTypes["timestamptz"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: GraphQLTypes["timestamptz"] | undefined,
		_lte?: GraphQLTypes["timestamptz"] | undefined,
		_neq?: GraphQLTypes["timestamptz"] | undefined,
		_nin?: Array<GraphQLTypes["timestamptz"]> | undefined
	};
	["upsertBrandOutput"]: {
		__typename: "upsertBrandOutput",
		archtypeAdherence?: string | undefined,
		brandName?: string | undefined,
		colors?: string | undefined,
		fontPrimary?: string | undefined,
		fontSecondary?: string | undefined,
		id?: string | undefined,
		isBrand?: boolean | undefined,
		logoFileId?: string | undefined,
		personality?: string | undefined,
		productOrService?: string | undefined,
		segment?: string | undefined,
		slogan?: string | undefined,
		voiceTone?: string | undefined
	};
	/** columns and relationships of "userAppIntegration" */
	["userAppIntegration"]: {
		__typename: "userAppIntegration",
		alias?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by: GraphQLTypes["uuid"],
		deleted: boolean,
		id: GraphQLTypes["uuid"],
		integrationType: string,
		params: GraphQLTypes["jsonb"],
		updated_at: GraphQLTypes["timestamptz"]
	};
	/** aggregated selection of "userAppIntegration" */
	["userAppIntegration_aggregate"]: {
		__typename: "userAppIntegration_aggregate",
		aggregate?: GraphQLTypes["userAppIntegration_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userAppIntegration"]>
	};
	/** aggregate fields of "userAppIntegration" */
	["userAppIntegration_aggregate_fields"]: {
		__typename: "userAppIntegration_aggregate_fields",
		count: number,
		max?: GraphQLTypes["userAppIntegration_max_fields"] | undefined,
		min?: GraphQLTypes["userAppIntegration_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["userAppIntegration_append_input"]: {
		params?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "userAppIntegration". All fields are combined with a logical 'AND'. */
	["userAppIntegration_bool_exp"]: {
		_and?: Array<GraphQLTypes["userAppIntegration_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userAppIntegration_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userAppIntegration_bool_exp"]> | undefined,
		alias?: GraphQLTypes["String_comparison_exp"] | undefined,
		brandId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		deleted?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		integrationType?: GraphQLTypes["String_comparison_exp"] | undefined,
		params?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "userAppIntegration" */
	["userAppIntegration_constraint"]: userAppIntegration_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["userAppIntegration_delete_at_path_input"]: {
		params?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["userAppIntegration_delete_elem_input"]: {
		params?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["userAppIntegration_delete_key_input"]: {
		params?: string | undefined
	};
	/** input type for inserting data into table "userAppIntegration" */
	["userAppIntegration_insert_input"]: {
		alias?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		params?: GraphQLTypes["jsonb"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["userAppIntegration_max_fields"]: {
		__typename: "userAppIntegration_max_fields",
		alias?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["userAppIntegration_min_fields"]: {
		__typename: "userAppIntegration_min_fields",
		alias?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "userAppIntegration" */
	["userAppIntegration_mutation_response"]: {
		__typename: "userAppIntegration_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userAppIntegration"]>
	};
	/** on_conflict condition type for table "userAppIntegration" */
	["userAppIntegration_on_conflict"]: {
		constraint: GraphQLTypes["userAppIntegration_constraint"],
		update_columns: Array<GraphQLTypes["userAppIntegration_update_column"]>,
		where?: GraphQLTypes["userAppIntegration_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userAppIntegration". */
	["userAppIntegration_order_by"]: {
		alias?: GraphQLTypes["order_by"] | undefined,
		brandId?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		deleted?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		integrationType?: GraphQLTypes["order_by"] | undefined,
		params?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userAppIntegration */
	["userAppIntegration_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["userAppIntegration_prepend_input"]: {
		params?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "userAppIntegration" */
	["userAppIntegration_select_column"]: userAppIntegration_select_column;
	/** input type for updating data in table "userAppIntegration" */
	["userAppIntegration_set_input"]: {
		alias?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		params?: GraphQLTypes["jsonb"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "userAppIntegration" */
	["userAppIntegration_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userAppIntegration_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userAppIntegration_stream_cursor_value_input"]: {
		alias?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		deleted?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		integrationType?: string | undefined,
		params?: GraphQLTypes["jsonb"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "userAppIntegration" */
	["userAppIntegration_update_column"]: userAppIntegration_update_column;
	["userAppIntegration_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["userAppIntegration_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["userAppIntegration_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["userAppIntegration_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["userAppIntegration_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["userAppIntegration_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userAppIntegration_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userAppIntegration_bool_exp"]
	};
	/** columns and relationships of "userCreditUsage" */
	["userCreditUsage"]: {
		__typename: "userCreditUsage",
		GeneratedPromptByTemplateId: GraphQLTypes["uuid"],
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		freeCreditUsed?: GraphQLTypes["numeric"] | undefined,
		id: GraphQLTypes["uuid"],
		originalValue: GraphQLTypes["numeric"],
		totalCharged: GraphQLTypes["numeric"]
	};
	/** columns and relationships of "userCreditUsageSummary" */
	["userCreditUsageSummary"]: {
		__typename: "userCreditUsageSummary",
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		freeCreditUsage: GraphQLTypes["numeric"],
		id: GraphQLTypes["uuid"],
		originalVaue: GraphQLTypes["numeric"],
		totalCharged: GraphQLTypes["numeric"],
		updated_at: GraphQLTypes["timestamptz"],
		yearMonthDayReference: string
	};
	/** aggregated selection of "userCreditUsageSummary" */
	["userCreditUsageSummary_aggregate"]: {
		__typename: "userCreditUsageSummary_aggregate",
		aggregate?: GraphQLTypes["userCreditUsageSummary_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userCreditUsageSummary"]>
	};
	/** aggregate fields of "userCreditUsageSummary" */
	["userCreditUsageSummary_aggregate_fields"]: {
		__typename: "userCreditUsageSummary_aggregate_fields",
		avg?: GraphQLTypes["userCreditUsageSummary_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["userCreditUsageSummary_max_fields"] | undefined,
		min?: GraphQLTypes["userCreditUsageSummary_min_fields"] | undefined,
		stddev?: GraphQLTypes["userCreditUsageSummary_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["userCreditUsageSummary_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["userCreditUsageSummary_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["userCreditUsageSummary_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["userCreditUsageSummary_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["userCreditUsageSummary_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["userCreditUsageSummary_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["userCreditUsageSummary_avg_fields"]: {
		__typename: "userCreditUsageSummary_avg_fields",
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** Boolean expression to filter rows from the table "userCreditUsageSummary". All fields are combined with a logical 'AND'. */
	["userCreditUsageSummary_bool_exp"]: {
		_and?: Array<GraphQLTypes["userCreditUsageSummary_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userCreditUsageSummary_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userCreditUsageSummary_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		freeCreditUsage?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		originalVaue?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		totalCharged?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		yearMonthDayReference?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "userCreditUsageSummary" */
	["userCreditUsageSummary_constraint"]: userCreditUsageSummary_constraint;
	/** input type for incrementing numeric columns in table "userCreditUsageSummary" */
	["userCreditUsageSummary_inc_input"]: {
		freeCreditUsage?: GraphQLTypes["numeric"] | undefined,
		originalVaue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "userCreditUsageSummary" */
	["userCreditUsageSummary_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsage?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalVaue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** aggregate max on columns */
	["userCreditUsageSummary_max_fields"]: {
		__typename: "userCreditUsageSummary_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsage?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalVaue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** aggregate min on columns */
	["userCreditUsageSummary_min_fields"]: {
		__typename: "userCreditUsageSummary_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsage?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalVaue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** response of any mutation on the table "userCreditUsageSummary" */
	["userCreditUsageSummary_mutation_response"]: {
		__typename: "userCreditUsageSummary_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userCreditUsageSummary"]>
	};
	/** on_conflict condition type for table "userCreditUsageSummary" */
	["userCreditUsageSummary_on_conflict"]: {
		constraint: GraphQLTypes["userCreditUsageSummary_constraint"],
		update_columns: Array<GraphQLTypes["userCreditUsageSummary_update_column"]>,
		where?: GraphQLTypes["userCreditUsageSummary_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userCreditUsageSummary". */
	["userCreditUsageSummary_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		freeCreditUsage?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		originalVaue?: GraphQLTypes["order_by"] | undefined,
		totalCharged?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		yearMonthDayReference?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userCreditUsageSummary */
	["userCreditUsageSummary_pk_columns_input"]: {
		created_by: GraphQLTypes["uuid"],
		yearMonthDayReference: string
	};
	/** select columns of table "userCreditUsageSummary" */
	["userCreditUsageSummary_select_column"]: userCreditUsageSummary_select_column;
	/** input type for updating data in table "userCreditUsageSummary" */
	["userCreditUsageSummary_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsage?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalVaue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** aggregate stddev on columns */
	["userCreditUsageSummary_stddev_fields"]: {
		__typename: "userCreditUsageSummary_stddev_fields",
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["userCreditUsageSummary_stddev_pop_fields"]: {
		__typename: "userCreditUsageSummary_stddev_pop_fields",
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["userCreditUsageSummary_stddev_samp_fields"]: {
		__typename: "userCreditUsageSummary_stddev_samp_fields",
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** Streaming cursor of the table "userCreditUsageSummary" */
	["userCreditUsageSummary_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userCreditUsageSummary_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userCreditUsageSummary_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsage?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalVaue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		yearMonthDayReference?: string | undefined
	};
	/** aggregate sum on columns */
	["userCreditUsageSummary_sum_fields"]: {
		__typename: "userCreditUsageSummary_sum_fields",
		freeCreditUsage?: GraphQLTypes["numeric"] | undefined,
		originalVaue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** update columns of table "userCreditUsageSummary" */
	["userCreditUsageSummary_update_column"]: userCreditUsageSummary_update_column;
	["userCreditUsageSummary_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["userCreditUsageSummary_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userCreditUsageSummary_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userCreditUsageSummary_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCreditUsageSummary_var_pop_fields"]: {
		__typename: "userCreditUsageSummary_var_pop_fields",
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate var_samp on columns */
	["userCreditUsageSummary_var_samp_fields"]: {
		__typename: "userCreditUsageSummary_var_samp_fields",
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate variance on columns */
	["userCreditUsageSummary_variance_fields"]: {
		__typename: "userCreditUsageSummary_variance_fields",
		freeCreditUsage?: number | undefined,
		originalVaue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregated selection of "userCreditUsage" */
	["userCreditUsage_aggregate"]: {
		__typename: "userCreditUsage_aggregate",
		aggregate?: GraphQLTypes["userCreditUsage_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userCreditUsage"]>
	};
	/** aggregate fields of "userCreditUsage" */
	["userCreditUsage_aggregate_fields"]: {
		__typename: "userCreditUsage_aggregate_fields",
		avg?: GraphQLTypes["userCreditUsage_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["userCreditUsage_max_fields"] | undefined,
		min?: GraphQLTypes["userCreditUsage_min_fields"] | undefined,
		stddev?: GraphQLTypes["userCreditUsage_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["userCreditUsage_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["userCreditUsage_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["userCreditUsage_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["userCreditUsage_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["userCreditUsage_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["userCreditUsage_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["userCreditUsage_avg_fields"]: {
		__typename: "userCreditUsage_avg_fields",
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** Boolean expression to filter rows from the table "userCreditUsage". All fields are combined with a logical 'AND'. */
	["userCreditUsage_bool_exp"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		_and?: Array<GraphQLTypes["userCreditUsage_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userCreditUsage_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userCreditUsage_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		freeCreditUsed?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		originalValue?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		totalCharged?: GraphQLTypes["numeric_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "userCreditUsage" */
	["userCreditUsage_constraint"]: userCreditUsage_constraint;
	/** input type for incrementing numeric columns in table "userCreditUsage" */
	["userCreditUsage_inc_input"]: {
		freeCreditUsed?: GraphQLTypes["numeric"] | undefined,
		originalValue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "userCreditUsage" */
	["userCreditUsage_insert_input"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsed?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalValue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** aggregate max on columns */
	["userCreditUsage_max_fields"]: {
		__typename: "userCreditUsage_max_fields",
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsed?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalValue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** aggregate min on columns */
	["userCreditUsage_min_fields"]: {
		__typename: "userCreditUsage_min_fields",
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsed?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalValue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** response of any mutation on the table "userCreditUsage" */
	["userCreditUsage_mutation_response"]: {
		__typename: "userCreditUsage_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userCreditUsage"]>
	};
	/** on_conflict condition type for table "userCreditUsage" */
	["userCreditUsage_on_conflict"]: {
		constraint: GraphQLTypes["userCreditUsage_constraint"],
		update_columns: Array<GraphQLTypes["userCreditUsage_update_column"]>,
		where?: GraphQLTypes["userCreditUsage_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userCreditUsage". */
	["userCreditUsage_order_by"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		freeCreditUsed?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		originalValue?: GraphQLTypes["order_by"] | undefined,
		totalCharged?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userCreditUsage */
	["userCreditUsage_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "userCreditUsage" */
	["userCreditUsage_select_column"]: userCreditUsage_select_column;
	/** input type for updating data in table "userCreditUsage" */
	["userCreditUsage_set_input"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsed?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalValue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** aggregate stddev on columns */
	["userCreditUsage_stddev_fields"]: {
		__typename: "userCreditUsage_stddev_fields",
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["userCreditUsage_stddev_pop_fields"]: {
		__typename: "userCreditUsage_stddev_pop_fields",
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["userCreditUsage_stddev_samp_fields"]: {
		__typename: "userCreditUsage_stddev_samp_fields",
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** Streaming cursor of the table "userCreditUsage" */
	["userCreditUsage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userCreditUsage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userCreditUsage_stream_cursor_value_input"]: {
		GeneratedPromptByTemplateId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		freeCreditUsed?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		originalValue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** aggregate sum on columns */
	["userCreditUsage_sum_fields"]: {
		__typename: "userCreditUsage_sum_fields",
		freeCreditUsed?: GraphQLTypes["numeric"] | undefined,
		originalValue?: GraphQLTypes["numeric"] | undefined,
		totalCharged?: GraphQLTypes["numeric"] | undefined
	};
	/** update columns of table "userCreditUsage" */
	["userCreditUsage_update_column"]: userCreditUsage_update_column;
	["userCreditUsage_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["userCreditUsage_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userCreditUsage_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userCreditUsage_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCreditUsage_var_pop_fields"]: {
		__typename: "userCreditUsage_var_pop_fields",
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate var_samp on columns */
	["userCreditUsage_var_samp_fields"]: {
		__typename: "userCreditUsage_var_samp_fields",
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** aggregate variance on columns */
	["userCreditUsage_variance_fields"]: {
		__typename: "userCreditUsage_variance_fields",
		freeCreditUsed?: number | undefined,
		originalValue?: number | undefined,
		totalCharged?: number | undefined
	};
	/** columns and relationships of "userCredits" */
	["userCredits"]: {
		__typename: "userCredits",
		created_at: GraphQLTypes["timestamptz"],
		id: GraphQLTypes["uuid"],
		quantity: GraphQLTypes["numeric"],
		reason: string,
		userId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "userCredits" */
	["userCredits_aggregate"]: {
		__typename: "userCredits_aggregate",
		aggregate?: GraphQLTypes["userCredits_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userCredits"]>
	};
	/** aggregate fields of "userCredits" */
	["userCredits_aggregate_fields"]: {
		__typename: "userCredits_aggregate_fields",
		avg?: GraphQLTypes["userCredits_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["userCredits_max_fields"] | undefined,
		min?: GraphQLTypes["userCredits_min_fields"] | undefined,
		stddev?: GraphQLTypes["userCredits_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["userCredits_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["userCredits_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["userCredits_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["userCredits_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["userCredits_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["userCredits_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["userCredits_avg_fields"]: {
		__typename: "userCredits_avg_fields",
		quantity?: number | undefined
	};
	/** Boolean expression to filter rows from the table "userCredits". All fields are combined with a logical 'AND'. */
	["userCredits_bool_exp"]: {
		_and?: Array<GraphQLTypes["userCredits_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userCredits_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userCredits_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		quantity?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		reason?: GraphQLTypes["String_comparison_exp"] | undefined,
		userId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "userCredits" */
	["userCredits_constraint"]: userCredits_constraint;
	/** input type for incrementing numeric columns in table "userCredits" */
	["userCredits_inc_input"]: {
		quantity?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "userCredits" */
	["userCredits_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		quantity?: GraphQLTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["userCredits_max_fields"]: {
		__typename: "userCredits_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		quantity?: GraphQLTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["userCredits_min_fields"]: {
		__typename: "userCredits_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		quantity?: GraphQLTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "userCredits" */
	["userCredits_mutation_response"]: {
		__typename: "userCredits_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userCredits"]>
	};
	/** on_conflict condition type for table "userCredits" */
	["userCredits_on_conflict"]: {
		constraint: GraphQLTypes["userCredits_constraint"],
		update_columns: Array<GraphQLTypes["userCredits_update_column"]>,
		where?: GraphQLTypes["userCredits_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userCredits". */
	["userCredits_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		quantity?: GraphQLTypes["order_by"] | undefined,
		reason?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userCredits */
	["userCredits_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "userCredits" */
	["userCredits_select_column"]: userCredits_select_column;
	/** input type for updating data in table "userCredits" */
	["userCredits_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		quantity?: GraphQLTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate stddev on columns */
	["userCredits_stddev_fields"]: {
		__typename: "userCredits_stddev_fields",
		quantity?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["userCredits_stddev_pop_fields"]: {
		__typename: "userCredits_stddev_pop_fields",
		quantity?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["userCredits_stddev_samp_fields"]: {
		__typename: "userCredits_stddev_samp_fields",
		quantity?: number | undefined
	};
	/** Streaming cursor of the table "userCredits" */
	["userCredits_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userCredits_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userCredits_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		quantity?: GraphQLTypes["numeric"] | undefined,
		reason?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["userCredits_sum_fields"]: {
		__typename: "userCredits_sum_fields",
		quantity?: GraphQLTypes["numeric"] | undefined
	};
	/** update columns of table "userCredits" */
	["userCredits_update_column"]: userCredits_update_column;
	["userCredits_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["userCredits_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userCredits_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userCredits_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userCredits_var_pop_fields"]: {
		__typename: "userCredits_var_pop_fields",
		quantity?: number | undefined
	};
	/** aggregate var_samp on columns */
	["userCredits_var_samp_fields"]: {
		__typename: "userCredits_var_samp_fields",
		quantity?: number | undefined
	};
	/** aggregate variance on columns */
	["userCredits_variance_fields"]: {
		__typename: "userCredits_variance_fields",
		quantity?: number | undefined
	};
	/** columns and relationships of "userFeedback" */
	["userFeedback"]: {
		__typename: "userFeedback",
		createdBy: GraphQLTypes["uuid"],
		created_at: GraphQLTypes["timestamptz"],
		feedbackType: string,
		id: GraphQLTypes["uuid"],
		message: string,
		replied_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregated selection of "userFeedback" */
	["userFeedback_aggregate"]: {
		__typename: "userFeedback_aggregate",
		aggregate?: GraphQLTypes["userFeedback_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userFeedback"]>
	};
	/** aggregate fields of "userFeedback" */
	["userFeedback_aggregate_fields"]: {
		__typename: "userFeedback_aggregate_fields",
		count: number,
		max?: GraphQLTypes["userFeedback_max_fields"] | undefined,
		min?: GraphQLTypes["userFeedback_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "userFeedback". All fields are combined with a logical 'AND'. */
	["userFeedback_bool_exp"]: {
		_and?: Array<GraphQLTypes["userFeedback_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userFeedback_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userFeedback_bool_exp"]> | undefined,
		createdBy?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		feedbackType?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		message?: GraphQLTypes["String_comparison_exp"] | undefined,
		replied_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "userFeedback" */
	["userFeedback_constraint"]: userFeedback_constraint;
	/** input type for inserting data into table "userFeedback" */
	["userFeedback_insert_input"]: {
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["userFeedback_max_fields"]: {
		__typename: "userFeedback_max_fields",
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["userFeedback_min_fields"]: {
		__typename: "userFeedback_min_fields",
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "userFeedback" */
	["userFeedback_mutation_response"]: {
		__typename: "userFeedback_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userFeedback"]>
	};
	/** on_conflict condition type for table "userFeedback" */
	["userFeedback_on_conflict"]: {
		constraint: GraphQLTypes["userFeedback_constraint"],
		update_columns: Array<GraphQLTypes["userFeedback_update_column"]>,
		where?: GraphQLTypes["userFeedback_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userFeedback". */
	["userFeedback_order_by"]: {
		createdBy?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		feedbackType?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		message?: GraphQLTypes["order_by"] | undefined,
		replied_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userFeedback */
	["userFeedback_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "userFeedback" */
	["userFeedback_select_column"]: userFeedback_select_column;
	/** input type for updating data in table "userFeedback" */
	["userFeedback_set_input"]: {
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "userFeedback" */
	["userFeedback_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userFeedback_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userFeedback_stream_cursor_value_input"]: {
		createdBy?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		feedbackType?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		message?: string | undefined,
		replied_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "userFeedback" */
	["userFeedback_update_column"]: userFeedback_update_column;
	["userFeedback_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userFeedback_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userFeedback_bool_exp"]
	};
	/** columns and relationships of "userMedia" */
	["userMedia"]: {
		__typename: "userMedia",
		aiParameters?: GraphQLTypes["jsonb"] | undefined,
		created_at: GraphQLTypes["timestamptz"],
		deleted: boolean,
		fileId: GraphQLTypes["uuid"],
		id: GraphQLTypes["uuid"],
		mediaType: string,
		ownerId: GraphQLTypes["uuid"],
		updated_at: GraphQLTypes["timestamptz"],
		videoFileId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregated selection of "userMedia" */
	["userMedia_aggregate"]: {
		__typename: "userMedia_aggregate",
		aggregate?: GraphQLTypes["userMedia_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userMedia"]>
	};
	/** aggregate fields of "userMedia" */
	["userMedia_aggregate_fields"]: {
		__typename: "userMedia_aggregate_fields",
		count: number,
		max?: GraphQLTypes["userMedia_max_fields"] | undefined,
		min?: GraphQLTypes["userMedia_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["userMedia_append_input"]: {
		aiParameters?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "userMedia". All fields are combined with a logical 'AND'. */
	["userMedia_bool_exp"]: {
		_and?: Array<GraphQLTypes["userMedia_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userMedia_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userMedia_bool_exp"]> | undefined,
		aiParameters?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		deleted?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		fileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		mediaType?: GraphQLTypes["String_comparison_exp"] | undefined,
		ownerId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		videoFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "userMedia" */
	["userMedia_constraint"]: userMedia_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["userMedia_delete_at_path_input"]: {
		aiParameters?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["userMedia_delete_elem_input"]: {
		aiParameters?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["userMedia_delete_key_input"]: {
		aiParameters?: string | undefined
	};
	/** input type for inserting data into table "userMedia" */
	["userMedia_insert_input"]: {
		aiParameters?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		deleted?: boolean | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		videoFileId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["userMedia_max_fields"]: {
		__typename: "userMedia_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		videoFileId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["userMedia_min_fields"]: {
		__typename: "userMedia_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		videoFileId?: GraphQLTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "userMedia" */
	["userMedia_mutation_response"]: {
		__typename: "userMedia_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userMedia"]>
	};
	/** on_conflict condition type for table "userMedia" */
	["userMedia_on_conflict"]: {
		constraint: GraphQLTypes["userMedia_constraint"],
		update_columns: Array<GraphQLTypes["userMedia_update_column"]>,
		where?: GraphQLTypes["userMedia_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userMedia". */
	["userMedia_order_by"]: {
		aiParameters?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		deleted?: GraphQLTypes["order_by"] | undefined,
		fileId?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		mediaType?: GraphQLTypes["order_by"] | undefined,
		ownerId?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		videoFileId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userMedia */
	["userMedia_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["userMedia_prepend_input"]: {
		aiParameters?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "userMedia" */
	["userMedia_select_column"]: userMedia_select_column;
	/** input type for updating data in table "userMedia" */
	["userMedia_set_input"]: {
		aiParameters?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		deleted?: boolean | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		videoFileId?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "userMedia" */
	["userMedia_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userMedia_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userMedia_stream_cursor_value_input"]: {
		aiParameters?: GraphQLTypes["jsonb"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		deleted?: boolean | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		mediaType?: string | undefined,
		ownerId?: GraphQLTypes["uuid"] | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		videoFileId?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "userMedia" */
	["userMedia_update_column"]: userMedia_update_column;
	["userMedia_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["userMedia_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["userMedia_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["userMedia_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["userMedia_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["userMedia_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userMedia_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userMedia_bool_exp"]
	};
	/** columns and relationships of "userMetadata" */
	["userMetadata"]: {
		__typename: "userMetadata",
		created_at: GraphQLTypes["timestamptz"],
		creditBalance?: GraphQLTypes["numeric"] | undefined,
		id: GraphQLTypes["uuid"],
		lastCreditRefill?: GraphQLTypes["date"] | undefined,
		last_seen: GraphQLTypes["timestamp"],
		onboardCompleted: boolean,
		/** An object relationship */
		pricingPlan?: GraphQLTypes["pricingPlan"] | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at: GraphQLTypes["timestamptz"],
		/** An object relationship */
		user: GraphQLTypes["users"]
	};
	/** aggregated selection of "userMetadata" */
	["userMetadata_aggregate"]: {
		__typename: "userMetadata_aggregate",
		aggregate?: GraphQLTypes["userMetadata_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userMetadata"]>
	};
	["userMetadata_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["userMetadata_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: GraphQLTypes["userMetadata_aggregate_bool_exp_bool_or"] | undefined,
		count?: GraphQLTypes["userMetadata_aggregate_bool_exp_count"] | undefined
	};
	["userMetadata_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["userMetadata_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["userMetadata_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["userMetadata_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["userMetadata_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["userMetadata_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["userMetadata_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "userMetadata" */
	["userMetadata_aggregate_fields"]: {
		__typename: "userMetadata_aggregate_fields",
		avg?: GraphQLTypes["userMetadata_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["userMetadata_max_fields"] | undefined,
		min?: GraphQLTypes["userMetadata_min_fields"] | undefined,
		stddev?: GraphQLTypes["userMetadata_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["userMetadata_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["userMetadata_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["userMetadata_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["userMetadata_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["userMetadata_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["userMetadata_variance_fields"] | undefined
	};
	/** order by aggregate values of table "userMetadata" */
	["userMetadata_aggregate_order_by"]: {
		avg?: GraphQLTypes["userMetadata_avg_order_by"] | undefined,
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["userMetadata_max_order_by"] | undefined,
		min?: GraphQLTypes["userMetadata_min_order_by"] | undefined,
		stddev?: GraphQLTypes["userMetadata_stddev_order_by"] | undefined,
		stddev_pop?: GraphQLTypes["userMetadata_stddev_pop_order_by"] | undefined,
		stddev_samp?: GraphQLTypes["userMetadata_stddev_samp_order_by"] | undefined,
		sum?: GraphQLTypes["userMetadata_sum_order_by"] | undefined,
		var_pop?: GraphQLTypes["userMetadata_var_pop_order_by"] | undefined,
		var_samp?: GraphQLTypes["userMetadata_var_samp_order_by"] | undefined,
		variance?: GraphQLTypes["userMetadata_variance_order_by"] | undefined
	};
	/** input type for inserting array relation for remote table "userMetadata" */
	["userMetadata_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["userMetadata_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["userMetadata_on_conflict"] | undefined
	};
	/** aggregate avg on columns */
	["userMetadata_avg_fields"]: {
		__typename: "userMetadata_avg_fields",
		creditBalance?: number | undefined
	};
	/** order by avg() on columns of table "userMetadata" */
	["userMetadata_avg_order_by"]: {
		creditBalance?: GraphQLTypes["order_by"] | undefined
	};
	/** Boolean expression to filter rows from the table "userMetadata". All fields are combined with a logical 'AND'. */
	["userMetadata_bool_exp"]: {
		_and?: Array<GraphQLTypes["userMetadata_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userMetadata_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userMetadata_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		creditBalance?: GraphQLTypes["numeric_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		lastCreditRefill?: GraphQLTypes["date_comparison_exp"] | undefined,
		last_seen?: GraphQLTypes["timestamp_comparison_exp"] | undefined,
		onboardCompleted?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		pricingPlan?: GraphQLTypes["pricingPlan_bool_exp"] | undefined,
		stripeCustomerId?: GraphQLTypes["String_comparison_exp"] | undefined,
		stripePriceId?: GraphQLTypes["String_comparison_exp"] | undefined,
		stripeProductId?: GraphQLTypes["String_comparison_exp"] | undefined,
		stripeSubscriptionId?: GraphQLTypes["String_comparison_exp"] | undefined,
		stripeSubscriptionItem?: GraphQLTypes["String_comparison_exp"] | undefined,
		subscriptionType?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		user?: GraphQLTypes["users_bool_exp"] | undefined
	};
	/** unique or primary key constraints on table "userMetadata" */
	["userMetadata_constraint"]: userMetadata_constraint;
	/** input type for incrementing numeric columns in table "userMetadata" */
	["userMetadata_inc_input"]: {
		creditBalance?: GraphQLTypes["numeric"] | undefined
	};
	/** input type for inserting data into table "userMetadata" */
	["userMetadata_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditBalance?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		lastCreditRefill?: GraphQLTypes["date"] | undefined,
		last_seen?: GraphQLTypes["timestamp"] | undefined,
		onboardCompleted?: boolean | undefined,
		pricingPlan?: GraphQLTypes["pricingPlan_obj_rel_insert_input"] | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined,
		user?: GraphQLTypes["users_obj_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["userMetadata_max_fields"]: {
		__typename: "userMetadata_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditBalance?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		lastCreditRefill?: GraphQLTypes["date"] | undefined,
		last_seen?: GraphQLTypes["timestamp"] | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "userMetadata" */
	["userMetadata_max_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		creditBalance?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		lastCreditRefill?: GraphQLTypes["order_by"] | undefined,
		last_seen?: GraphQLTypes["order_by"] | undefined,
		stripeCustomerId?: GraphQLTypes["order_by"] | undefined,
		stripePriceId?: GraphQLTypes["order_by"] | undefined,
		stripeProductId?: GraphQLTypes["order_by"] | undefined,
		stripeSubscriptionId?: GraphQLTypes["order_by"] | undefined,
		stripeSubscriptionItem?: GraphQLTypes["order_by"] | undefined,
		subscriptionType?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["userMetadata_min_fields"]: {
		__typename: "userMetadata_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditBalance?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		lastCreditRefill?: GraphQLTypes["date"] | undefined,
		last_seen?: GraphQLTypes["timestamp"] | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "userMetadata" */
	["userMetadata_min_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		creditBalance?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		lastCreditRefill?: GraphQLTypes["order_by"] | undefined,
		last_seen?: GraphQLTypes["order_by"] | undefined,
		stripeCustomerId?: GraphQLTypes["order_by"] | undefined,
		stripePriceId?: GraphQLTypes["order_by"] | undefined,
		stripeProductId?: GraphQLTypes["order_by"] | undefined,
		stripeSubscriptionId?: GraphQLTypes["order_by"] | undefined,
		stripeSubscriptionItem?: GraphQLTypes["order_by"] | undefined,
		subscriptionType?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "userMetadata" */
	["userMetadata_mutation_response"]: {
		__typename: "userMetadata_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userMetadata"]>
	};
	/** on_conflict condition type for table "userMetadata" */
	["userMetadata_on_conflict"]: {
		constraint: GraphQLTypes["userMetadata_constraint"],
		update_columns: Array<GraphQLTypes["userMetadata_update_column"]>,
		where?: GraphQLTypes["userMetadata_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userMetadata". */
	["userMetadata_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		creditBalance?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		lastCreditRefill?: GraphQLTypes["order_by"] | undefined,
		last_seen?: GraphQLTypes["order_by"] | undefined,
		onboardCompleted?: GraphQLTypes["order_by"] | undefined,
		pricingPlan?: GraphQLTypes["pricingPlan_order_by"] | undefined,
		stripeCustomerId?: GraphQLTypes["order_by"] | undefined,
		stripePriceId?: GraphQLTypes["order_by"] | undefined,
		stripeProductId?: GraphQLTypes["order_by"] | undefined,
		stripeSubscriptionId?: GraphQLTypes["order_by"] | undefined,
		stripeSubscriptionItem?: GraphQLTypes["order_by"] | undefined,
		subscriptionType?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined,
		user?: GraphQLTypes["users_order_by"] | undefined
	};
	/** primary key columns input for table: userMetadata */
	["userMetadata_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "userMetadata" */
	["userMetadata_select_column"]: userMetadata_select_column;
	/** select "userMetadata_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userMetadata" */
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"]: userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "userMetadata_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userMetadata" */
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"]: userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "userMetadata" */
	["userMetadata_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditBalance?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		lastCreditRefill?: GraphQLTypes["date"] | undefined,
		last_seen?: GraphQLTypes["timestamp"] | undefined,
		onboardCompleted?: boolean | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate stddev on columns */
	["userMetadata_stddev_fields"]: {
		__typename: "userMetadata_stddev_fields",
		creditBalance?: number | undefined
	};
	/** order by stddev() on columns of table "userMetadata" */
	["userMetadata_stddev_order_by"]: {
		creditBalance?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_pop on columns */
	["userMetadata_stddev_pop_fields"]: {
		__typename: "userMetadata_stddev_pop_fields",
		creditBalance?: number | undefined
	};
	/** order by stddev_pop() on columns of table "userMetadata" */
	["userMetadata_stddev_pop_order_by"]: {
		creditBalance?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate stddev_samp on columns */
	["userMetadata_stddev_samp_fields"]: {
		__typename: "userMetadata_stddev_samp_fields",
		creditBalance?: number | undefined
	};
	/** order by stddev_samp() on columns of table "userMetadata" */
	["userMetadata_stddev_samp_order_by"]: {
		creditBalance?: GraphQLTypes["order_by"] | undefined
	};
	/** Streaming cursor of the table "userMetadata" */
	["userMetadata_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userMetadata_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userMetadata_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		creditBalance?: GraphQLTypes["numeric"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		lastCreditRefill?: GraphQLTypes["date"] | undefined,
		last_seen?: GraphQLTypes["timestamp"] | undefined,
		onboardCompleted?: boolean | undefined,
		stripeCustomerId?: string | undefined,
		stripePriceId?: string | undefined,
		stripeProductId?: string | undefined,
		stripeSubscriptionId?: string | undefined,
		stripeSubscriptionItem?: string | undefined,
		subscriptionType?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate sum on columns */
	["userMetadata_sum_fields"]: {
		__typename: "userMetadata_sum_fields",
		creditBalance?: GraphQLTypes["numeric"] | undefined
	};
	/** order by sum() on columns of table "userMetadata" */
	["userMetadata_sum_order_by"]: {
		creditBalance?: GraphQLTypes["order_by"] | undefined
	};
	/** update columns of table "userMetadata" */
	["userMetadata_update_column"]: userMetadata_update_column;
	["userMetadata_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["userMetadata_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userMetadata_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userMetadata_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userMetadata_var_pop_fields"]: {
		__typename: "userMetadata_var_pop_fields",
		creditBalance?: number | undefined
	};
	/** order by var_pop() on columns of table "userMetadata" */
	["userMetadata_var_pop_order_by"]: {
		creditBalance?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate var_samp on columns */
	["userMetadata_var_samp_fields"]: {
		__typename: "userMetadata_var_samp_fields",
		creditBalance?: number | undefined
	};
	/** order by var_samp() on columns of table "userMetadata" */
	["userMetadata_var_samp_order_by"]: {
		creditBalance?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate variance on columns */
	["userMetadata_variance_fields"]: {
		__typename: "userMetadata_variance_fields",
		creditBalance?: number | undefined
	};
	/** order by variance() on columns of table "userMetadata" */
	["userMetadata_variance_order_by"]: {
		creditBalance?: GraphQLTypes["order_by"] | undefined
	};
	/** columns and relationships of "userOnboarding" */
	["userOnboarding"]: {
		__typename: "userOnboarding",
		behaviorProfile?: string | undefined,
		brandName: string,
		clientPreference?: string | undefined,
		companySegment: string,
		currentStep: number,
		done: boolean,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name: string,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService: string,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount: string,
		userId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "userOnboarding" */
	["userOnboarding_aggregate"]: {
		__typename: "userOnboarding_aggregate",
		aggregate?: GraphQLTypes["userOnboarding_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userOnboarding"]>
	};
	/** aggregate fields of "userOnboarding" */
	["userOnboarding_aggregate_fields"]: {
		__typename: "userOnboarding_aggregate_fields",
		avg?: GraphQLTypes["userOnboarding_avg_fields"] | undefined,
		count: number,
		max?: GraphQLTypes["userOnboarding_max_fields"] | undefined,
		min?: GraphQLTypes["userOnboarding_min_fields"] | undefined,
		stddev?: GraphQLTypes["userOnboarding_stddev_fields"] | undefined,
		stddev_pop?: GraphQLTypes["userOnboarding_stddev_pop_fields"] | undefined,
		stddev_samp?: GraphQLTypes["userOnboarding_stddev_samp_fields"] | undefined,
		sum?: GraphQLTypes["userOnboarding_sum_fields"] | undefined,
		var_pop?: GraphQLTypes["userOnboarding_var_pop_fields"] | undefined,
		var_samp?: GraphQLTypes["userOnboarding_var_samp_fields"] | undefined,
		variance?: GraphQLTypes["userOnboarding_variance_fields"] | undefined
	};
	/** aggregate avg on columns */
	["userOnboarding_avg_fields"]: {
		__typename: "userOnboarding_avg_fields",
		currentStep?: number | undefined
	};
	/** Boolean expression to filter rows from the table "userOnboarding". All fields are combined with a logical 'AND'. */
	["userOnboarding_bool_exp"]: {
		_and?: Array<GraphQLTypes["userOnboarding_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userOnboarding_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userOnboarding_bool_exp"]> | undefined,
		behaviorProfile?: GraphQLTypes["String_comparison_exp"] | undefined,
		brandName?: GraphQLTypes["String_comparison_exp"] | undefined,
		clientPreference?: GraphQLTypes["String_comparison_exp"] | undefined,
		companySegment?: GraphQLTypes["String_comparison_exp"] | undefined,
		currentStep?: GraphQLTypes["Int_comparison_exp"] | undefined,
		done?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		expectation?: GraphQLTypes["String_comparison_exp"] | undefined,
		importantSocialNetworkForBusiness?: GraphQLTypes["String_comparison_exp"] | undefined,
		investInPaidChannels?: GraphQLTypes["String_comparison_exp"] | undefined,
		marketingObjective?: GraphQLTypes["String_comparison_exp"] | undefined,
		name?: GraphQLTypes["String_comparison_exp"] | undefined,
		networkingOnCopyMagico?: GraphQLTypes["String_comparison_exp"] | undefined,
		phone?: GraphQLTypes["String_comparison_exp"] | undefined,
		productOrService?: GraphQLTypes["String_comparison_exp"] | undefined,
		purchaseBehavior?: GraphQLTypes["String_comparison_exp"] | undefined,
		purchaseChallenges?: GraphQLTypes["String_comparison_exp"] | undefined,
		purchaseInfluencers?: GraphQLTypes["String_comparison_exp"] | undefined,
		sellOnCopyMagico?: GraphQLTypes["String_comparison_exp"] | undefined,
		siteUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		socioeconomicProfile?: GraphQLTypes["String_comparison_exp"] | undefined,
		staffCount?: GraphQLTypes["String_comparison_exp"] | undefined,
		userId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "userOnboarding" */
	["userOnboarding_constraint"]: userOnboarding_constraint;
	/** input type for incrementing numeric columns in table "userOnboarding" */
	["userOnboarding_inc_input"]: {
		currentStep?: number | undefined
	};
	/** input type for inserting data into table "userOnboarding" */
	["userOnboarding_insert_input"]: {
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		done?: boolean | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["userOnboarding_max_fields"]: {
		__typename: "userOnboarding_max_fields",
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["userOnboarding_min_fields"]: {
		__typename: "userOnboarding_min_fields",
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "userOnboarding" */
	["userOnboarding_mutation_response"]: {
		__typename: "userOnboarding_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userOnboarding"]>
	};
	/** on_conflict condition type for table "userOnboarding" */
	["userOnboarding_on_conflict"]: {
		constraint: GraphQLTypes["userOnboarding_constraint"],
		update_columns: Array<GraphQLTypes["userOnboarding_update_column"]>,
		where?: GraphQLTypes["userOnboarding_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userOnboarding". */
	["userOnboarding_order_by"]: {
		behaviorProfile?: GraphQLTypes["order_by"] | undefined,
		brandName?: GraphQLTypes["order_by"] | undefined,
		clientPreference?: GraphQLTypes["order_by"] | undefined,
		companySegment?: GraphQLTypes["order_by"] | undefined,
		currentStep?: GraphQLTypes["order_by"] | undefined,
		done?: GraphQLTypes["order_by"] | undefined,
		expectation?: GraphQLTypes["order_by"] | undefined,
		importantSocialNetworkForBusiness?: GraphQLTypes["order_by"] | undefined,
		investInPaidChannels?: GraphQLTypes["order_by"] | undefined,
		marketingObjective?: GraphQLTypes["order_by"] | undefined,
		name?: GraphQLTypes["order_by"] | undefined,
		networkingOnCopyMagico?: GraphQLTypes["order_by"] | undefined,
		phone?: GraphQLTypes["order_by"] | undefined,
		productOrService?: GraphQLTypes["order_by"] | undefined,
		purchaseBehavior?: GraphQLTypes["order_by"] | undefined,
		purchaseChallenges?: GraphQLTypes["order_by"] | undefined,
		purchaseInfluencers?: GraphQLTypes["order_by"] | undefined,
		sellOnCopyMagico?: GraphQLTypes["order_by"] | undefined,
		siteUrl?: GraphQLTypes["order_by"] | undefined,
		socioeconomicProfile?: GraphQLTypes["order_by"] | undefined,
		staffCount?: GraphQLTypes["order_by"] | undefined,
		userId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userOnboarding */
	["userOnboarding_pk_columns_input"]: {
		userId: GraphQLTypes["uuid"]
	};
	/** select columns of table "userOnboarding" */
	["userOnboarding_select_column"]: userOnboarding_select_column;
	/** input type for updating data in table "userOnboarding" */
	["userOnboarding_set_input"]: {
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		done?: boolean | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate stddev on columns */
	["userOnboarding_stddev_fields"]: {
		__typename: "userOnboarding_stddev_fields",
		currentStep?: number | undefined
	};
	/** aggregate stddev_pop on columns */
	["userOnboarding_stddev_pop_fields"]: {
		__typename: "userOnboarding_stddev_pop_fields",
		currentStep?: number | undefined
	};
	/** aggregate stddev_samp on columns */
	["userOnboarding_stddev_samp_fields"]: {
		__typename: "userOnboarding_stddev_samp_fields",
		currentStep?: number | undefined
	};
	/** Streaming cursor of the table "userOnboarding" */
	["userOnboarding_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userOnboarding_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userOnboarding_stream_cursor_value_input"]: {
		behaviorProfile?: string | undefined,
		brandName?: string | undefined,
		clientPreference?: string | undefined,
		companySegment?: string | undefined,
		currentStep?: number | undefined,
		done?: boolean | undefined,
		expectation?: string | undefined,
		importantSocialNetworkForBusiness?: string | undefined,
		investInPaidChannels?: string | undefined,
		marketingObjective?: string | undefined,
		name?: string | undefined,
		networkingOnCopyMagico?: string | undefined,
		phone?: string | undefined,
		productOrService?: string | undefined,
		purchaseBehavior?: string | undefined,
		purchaseChallenges?: string | undefined,
		purchaseInfluencers?: string | undefined,
		sellOnCopyMagico?: string | undefined,
		siteUrl?: string | undefined,
		socioeconomicProfile?: string | undefined,
		staffCount?: string | undefined,
		userId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate sum on columns */
	["userOnboarding_sum_fields"]: {
		__typename: "userOnboarding_sum_fields",
		currentStep?: number | undefined
	};
	/** update columns of table "userOnboarding" */
	["userOnboarding_update_column"]: userOnboarding_update_column;
	["userOnboarding_updates"]: {
		/** increments the numeric columns with given value of the filtered values */
		_inc?: GraphQLTypes["userOnboarding_inc_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userOnboarding_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userOnboarding_bool_exp"]
	};
	/** aggregate var_pop on columns */
	["userOnboarding_var_pop_fields"]: {
		__typename: "userOnboarding_var_pop_fields",
		currentStep?: number | undefined
	};
	/** aggregate var_samp on columns */
	["userOnboarding_var_samp_fields"]: {
		__typename: "userOnboarding_var_samp_fields",
		currentStep?: number | undefined
	};
	/** aggregate variance on columns */
	["userOnboarding_variance_fields"]: {
		__typename: "userOnboarding_variance_fields",
		currentStep?: number | undefined
	};
	/** columns and relationships of "userTemplateUsage" */
	["userTemplateUsage"]: {
		__typename: "userTemplateUsage",
		created_at: GraphQLTypes["timestamptz"],
		created_by: GraphQLTypes["uuid"],
		id: GraphQLTypes["uuid"],
		textPromptTemplateId: GraphQLTypes["uuid"]
	};
	/** aggregated selection of "userTemplateUsage" */
	["userTemplateUsage_aggregate"]: {
		__typename: "userTemplateUsage_aggregate",
		aggregate?: GraphQLTypes["userTemplateUsage_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["userTemplateUsage"]>
	};
	/** aggregate fields of "userTemplateUsage" */
	["userTemplateUsage_aggregate_fields"]: {
		__typename: "userTemplateUsage_aggregate_fields",
		count: number,
		max?: GraphQLTypes["userTemplateUsage_max_fields"] | undefined,
		min?: GraphQLTypes["userTemplateUsage_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "userTemplateUsage". All fields are combined with a logical 'AND'. */
	["userTemplateUsage_bool_exp"]: {
		_and?: Array<GraphQLTypes["userTemplateUsage_bool_exp"]> | undefined,
		_not?: GraphQLTypes["userTemplateUsage_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["userTemplateUsage_bool_exp"]> | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		created_by?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "userTemplateUsage" */
	["userTemplateUsage_constraint"]: userTemplateUsage_constraint;
	/** input type for inserting data into table "userTemplateUsage" */
	["userTemplateUsage_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate max on columns */
	["userTemplateUsage_max_fields"]: {
		__typename: "userTemplateUsage_max_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** aggregate min on columns */
	["userTemplateUsage_min_fields"]: {
		__typename: "userTemplateUsage_min_fields",
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** response of any mutation on the table "userTemplateUsage" */
	["userTemplateUsage_mutation_response"]: {
		__typename: "userTemplateUsage_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["userTemplateUsage"]>
	};
	/** on_conflict condition type for table "userTemplateUsage" */
	["userTemplateUsage_on_conflict"]: {
		constraint: GraphQLTypes["userTemplateUsage_constraint"],
		update_columns: Array<GraphQLTypes["userTemplateUsage_update_column"]>,
		where?: GraphQLTypes["userTemplateUsage_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "userTemplateUsage". */
	["userTemplateUsage_order_by"]: {
		created_at?: GraphQLTypes["order_by"] | undefined,
		created_by?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		textPromptTemplateId?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: userTemplateUsage */
	["userTemplateUsage_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "userTemplateUsage" */
	["userTemplateUsage_select_column"]: userTemplateUsage_select_column;
	/** input type for updating data in table "userTemplateUsage" */
	["userTemplateUsage_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** Streaming cursor of the table "userTemplateUsage" */
	["userTemplateUsage_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["userTemplateUsage_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["userTemplateUsage_stream_cursor_value_input"]: {
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		created_by?: GraphQLTypes["uuid"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		textPromptTemplateId?: GraphQLTypes["uuid"] | undefined
	};
	/** update columns of table "userTemplateUsage" */
	["userTemplateUsage_update_column"]: userTemplateUsage_update_column;
	["userTemplateUsage_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["userTemplateUsage_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["userTemplateUsage_bool_exp"]
	};
	/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
	["users"]: {
		__typename: "users",
		activeMfaType?: string | undefined,
		avatarUrl: string,
		createdAt: GraphQLTypes["timestamptz"],
		currentChallenge?: string | undefined,
		defaultRole: string,
		/** An object relationship */
		defaultRoleByRole: GraphQLTypes["authRoles"],
		disabled: boolean,
		displayName: string,
		email?: GraphQLTypes["citext"] | undefined,
		emailVerified: boolean,
		id: GraphQLTypes["uuid"],
		isAnonymous: boolean,
		lastSeen?: GraphQLTypes["timestamptz"] | undefined,
		locale: string,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		newEmail?: GraphQLTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt: GraphQLTypes["timestamptz"],
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		phoneNumberVerified: boolean,
		/** An array relationship */
		refreshTokens: Array<GraphQLTypes["authRefreshTokens"]>,
		/** An aggregate relationship */
		refreshTokens_aggregate: GraphQLTypes["authRefreshTokens_aggregate"],
		/** An array relationship */
		roles: Array<GraphQLTypes["authUserRoles"]>,
		/** An aggregate relationship */
		roles_aggregate: GraphQLTypes["authUserRoles_aggregate"],
		/** An array relationship */
		securityKeys: Array<GraphQLTypes["authUserSecurityKeys"]>,
		/** An aggregate relationship */
		securityKeys_aggregate: GraphQLTypes["authUserSecurityKeys_aggregate"],
		ticket?: string | undefined,
		ticketExpiresAt: GraphQLTypes["timestamptz"],
		totpSecret?: string | undefined,
		updatedAt: GraphQLTypes["timestamptz"],
		/** An array relationship */
		userMetadata: Array<GraphQLTypes["userMetadata"]>,
		/** An aggregate relationship */
		userMetadata_aggregate: GraphQLTypes["userMetadata_aggregate"],
		/** An array relationship */
		userProviders: Array<GraphQLTypes["authUserProviders"]>,
		/** An aggregate relationship */
		userProviders_aggregate: GraphQLTypes["authUserProviders_aggregate"]
	};
	/** aggregated selection of "auth.users" */
	["users_aggregate"]: {
		__typename: "users_aggregate",
		aggregate?: GraphQLTypes["users_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["users"]>
	};
	["users_aggregate_bool_exp"]: {
		bool_and?: GraphQLTypes["users_aggregate_bool_exp_bool_and"] | undefined,
		bool_or?: GraphQLTypes["users_aggregate_bool_exp_bool_or"] | undefined,
		count?: GraphQLTypes["users_aggregate_bool_exp_count"] | undefined
	};
	["users_aggregate_bool_exp_bool_and"]: {
		arguments: GraphQLTypes["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["users_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["users_aggregate_bool_exp_bool_or"]: {
		arguments: GraphQLTypes["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"],
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["users_bool_exp"] | undefined,
		predicate: GraphQLTypes["Boolean_comparison_exp"]
	};
	["users_aggregate_bool_exp_count"]: {
		arguments?: Array<GraphQLTypes["users_select_column"]> | undefined,
		distinct?: boolean | undefined,
		filter?: GraphQLTypes["users_bool_exp"] | undefined,
		predicate: GraphQLTypes["Int_comparison_exp"]
	};
	/** aggregate fields of "auth.users" */
	["users_aggregate_fields"]: {
		__typename: "users_aggregate_fields",
		count: number,
		max?: GraphQLTypes["users_max_fields"] | undefined,
		min?: GraphQLTypes["users_min_fields"] | undefined
	};
	/** order by aggregate values of table "auth.users" */
	["users_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"] | undefined,
		max?: GraphQLTypes["users_max_order_by"] | undefined,
		min?: GraphQLTypes["users_min_order_by"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["users_append_input"]: {
		metadata?: GraphQLTypes["jsonb"] | undefined
	};
	/** input type for inserting array relation for remote table "auth.users" */
	["users_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["users_insert_input"]>,
		/** upsert condition */
		on_conflict?: GraphQLTypes["users_on_conflict"] | undefined
	};
	/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
	["users_bool_exp"]: {
		_and?: Array<GraphQLTypes["users_bool_exp"]> | undefined,
		_not?: GraphQLTypes["users_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["users_bool_exp"]> | undefined,
		activeMfaType?: GraphQLTypes["String_comparison_exp"] | undefined,
		avatarUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		createdAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		currentChallenge?: GraphQLTypes["String_comparison_exp"] | undefined,
		defaultRole?: GraphQLTypes["String_comparison_exp"] | undefined,
		defaultRoleByRole?: GraphQLTypes["authRoles_bool_exp"] | undefined,
		disabled?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		displayName?: GraphQLTypes["String_comparison_exp"] | undefined,
		email?: GraphQLTypes["citext_comparison_exp"] | undefined,
		emailVerified?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		isAnonymous?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		lastSeen?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		locale?: GraphQLTypes["String_comparison_exp"] | undefined,
		metadata?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		newEmail?: GraphQLTypes["citext_comparison_exp"] | undefined,
		otpHash?: GraphQLTypes["String_comparison_exp"] | undefined,
		otpHashExpiresAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		otpMethodLastUsed?: GraphQLTypes["String_comparison_exp"] | undefined,
		passwordHash?: GraphQLTypes["String_comparison_exp"] | undefined,
		phoneNumber?: GraphQLTypes["String_comparison_exp"] | undefined,
		phoneNumberVerified?: GraphQLTypes["Boolean_comparison_exp"] | undefined,
		refreshTokens?: GraphQLTypes["authRefreshTokens_bool_exp"] | undefined,
		refreshTokens_aggregate?: GraphQLTypes["authRefreshTokens_aggregate_bool_exp"] | undefined,
		roles?: GraphQLTypes["authUserRoles_bool_exp"] | undefined,
		roles_aggregate?: GraphQLTypes["authUserRoles_aggregate_bool_exp"] | undefined,
		securityKeys?: GraphQLTypes["authUserSecurityKeys_bool_exp"] | undefined,
		securityKeys_aggregate?: GraphQLTypes["authUserSecurityKeys_aggregate_bool_exp"] | undefined,
		ticket?: GraphQLTypes["String_comparison_exp"] | undefined,
		ticketExpiresAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		totpSecret?: GraphQLTypes["String_comparison_exp"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		userMetadata?: GraphQLTypes["userMetadata_bool_exp"] | undefined,
		userMetadata_aggregate?: GraphQLTypes["userMetadata_aggregate_bool_exp"] | undefined,
		userProviders?: GraphQLTypes["authUserProviders_bool_exp"] | undefined,
		userProviders_aggregate?: GraphQLTypes["authUserProviders_aggregate_bool_exp"] | undefined
	};
	/** unique or primary key constraints on table "auth.users" */
	["users_constraint"]: users_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["users_delete_at_path_input"]: {
		metadata?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["users_delete_elem_input"]: {
		metadata?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["users_delete_key_input"]: {
		metadata?: string | undefined
	};
	/** input type for inserting data into table "auth.users" */
	["users_insert_input"]: {
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		defaultRoleByRole?: GraphQLTypes["authRoles_obj_rel_insert_input"] | undefined,
		disabled?: boolean | undefined,
		displayName?: string | undefined,
		email?: GraphQLTypes["citext"] | undefined,
		emailVerified?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		isAnonymous?: boolean | undefined,
		lastSeen?: GraphQLTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		newEmail?: GraphQLTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		phoneNumberVerified?: boolean | undefined,
		refreshTokens?: GraphQLTypes["authRefreshTokens_arr_rel_insert_input"] | undefined,
		roles?: GraphQLTypes["authUserRoles_arr_rel_insert_input"] | undefined,
		securityKeys?: GraphQLTypes["authUserSecurityKeys_arr_rel_insert_input"] | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		userMetadata?: GraphQLTypes["userMetadata_arr_rel_insert_input"] | undefined,
		userProviders?: GraphQLTypes["authUserProviders_arr_rel_insert_input"] | undefined
	};
	/** aggregate max on columns */
	["users_max_fields"]: {
		__typename: "users_max_fields",
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		displayName?: string | undefined,
		email?: GraphQLTypes["citext"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		lastSeen?: GraphQLTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		newEmail?: GraphQLTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by max() on columns of table "auth.users" */
	["users_max_order_by"]: {
		activeMfaType?: GraphQLTypes["order_by"] | undefined,
		avatarUrl?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		currentChallenge?: GraphQLTypes["order_by"] | undefined,
		defaultRole?: GraphQLTypes["order_by"] | undefined,
		displayName?: GraphQLTypes["order_by"] | undefined,
		email?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		lastSeen?: GraphQLTypes["order_by"] | undefined,
		locale?: GraphQLTypes["order_by"] | undefined,
		newEmail?: GraphQLTypes["order_by"] | undefined,
		otpHash?: GraphQLTypes["order_by"] | undefined,
		otpHashExpiresAt?: GraphQLTypes["order_by"] | undefined,
		otpMethodLastUsed?: GraphQLTypes["order_by"] | undefined,
		passwordHash?: GraphQLTypes["order_by"] | undefined,
		phoneNumber?: GraphQLTypes["order_by"] | undefined,
		ticket?: GraphQLTypes["order_by"] | undefined,
		ticketExpiresAt?: GraphQLTypes["order_by"] | undefined,
		totpSecret?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined
	};
	/** aggregate min on columns */
	["users_min_fields"]: {
		__typename: "users_min_fields",
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		displayName?: string | undefined,
		email?: GraphQLTypes["citext"] | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		lastSeen?: GraphQLTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		newEmail?: GraphQLTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** order by min() on columns of table "auth.users" */
	["users_min_order_by"]: {
		activeMfaType?: GraphQLTypes["order_by"] | undefined,
		avatarUrl?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		currentChallenge?: GraphQLTypes["order_by"] | undefined,
		defaultRole?: GraphQLTypes["order_by"] | undefined,
		displayName?: GraphQLTypes["order_by"] | undefined,
		email?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		lastSeen?: GraphQLTypes["order_by"] | undefined,
		locale?: GraphQLTypes["order_by"] | undefined,
		newEmail?: GraphQLTypes["order_by"] | undefined,
		otpHash?: GraphQLTypes["order_by"] | undefined,
		otpHashExpiresAt?: GraphQLTypes["order_by"] | undefined,
		otpMethodLastUsed?: GraphQLTypes["order_by"] | undefined,
		passwordHash?: GraphQLTypes["order_by"] | undefined,
		phoneNumber?: GraphQLTypes["order_by"] | undefined,
		ticket?: GraphQLTypes["order_by"] | undefined,
		ticketExpiresAt?: GraphQLTypes["order_by"] | undefined,
		totpSecret?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined
	};
	/** response of any mutation on the table "auth.users" */
	["users_mutation_response"]: {
		__typename: "users_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["users"]>
	};
	/** input type for inserting object relation for remote table "auth.users" */
	["users_obj_rel_insert_input"]: {
		data: GraphQLTypes["users_insert_input"],
		/** upsert condition */
		on_conflict?: GraphQLTypes["users_on_conflict"] | undefined
	};
	/** on_conflict condition type for table "auth.users" */
	["users_on_conflict"]: {
		constraint: GraphQLTypes["users_constraint"],
		update_columns: Array<GraphQLTypes["users_update_column"]>,
		where?: GraphQLTypes["users_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "auth.users". */
	["users_order_by"]: {
		activeMfaType?: GraphQLTypes["order_by"] | undefined,
		avatarUrl?: GraphQLTypes["order_by"] | undefined,
		createdAt?: GraphQLTypes["order_by"] | undefined,
		currentChallenge?: GraphQLTypes["order_by"] | undefined,
		defaultRole?: GraphQLTypes["order_by"] | undefined,
		defaultRoleByRole?: GraphQLTypes["authRoles_order_by"] | undefined,
		disabled?: GraphQLTypes["order_by"] | undefined,
		displayName?: GraphQLTypes["order_by"] | undefined,
		email?: GraphQLTypes["order_by"] | undefined,
		emailVerified?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		isAnonymous?: GraphQLTypes["order_by"] | undefined,
		lastSeen?: GraphQLTypes["order_by"] | undefined,
		locale?: GraphQLTypes["order_by"] | undefined,
		metadata?: GraphQLTypes["order_by"] | undefined,
		newEmail?: GraphQLTypes["order_by"] | undefined,
		otpHash?: GraphQLTypes["order_by"] | undefined,
		otpHashExpiresAt?: GraphQLTypes["order_by"] | undefined,
		otpMethodLastUsed?: GraphQLTypes["order_by"] | undefined,
		passwordHash?: GraphQLTypes["order_by"] | undefined,
		phoneNumber?: GraphQLTypes["order_by"] | undefined,
		phoneNumberVerified?: GraphQLTypes["order_by"] | undefined,
		refreshTokens_aggregate?: GraphQLTypes["authRefreshTokens_aggregate_order_by"] | undefined,
		roles_aggregate?: GraphQLTypes["authUserRoles_aggregate_order_by"] | undefined,
		securityKeys_aggregate?: GraphQLTypes["authUserSecurityKeys_aggregate_order_by"] | undefined,
		ticket?: GraphQLTypes["order_by"] | undefined,
		ticketExpiresAt?: GraphQLTypes["order_by"] | undefined,
		totpSecret?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined,
		userMetadata_aggregate?: GraphQLTypes["userMetadata_aggregate_order_by"] | undefined,
		userProviders_aggregate?: GraphQLTypes["authUserProviders_aggregate_order_by"] | undefined
	};
	/** primary key columns input for table: auth.users */
	["users_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["users_prepend_input"]: {
		metadata?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "auth.users" */
	["users_select_column"]: users_select_column;
	/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
	["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"]: users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns;
	/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
	["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"]: users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns;
	/** input type for updating data in table "auth.users" */
	["users_set_input"]: {
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		disabled?: boolean | undefined,
		displayName?: string | undefined,
		email?: GraphQLTypes["citext"] | undefined,
		emailVerified?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		isAnonymous?: boolean | undefined,
		lastSeen?: GraphQLTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		newEmail?: GraphQLTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		phoneNumberVerified?: boolean | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "users" */
	["users_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["users_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["users_stream_cursor_value_input"]: {
		activeMfaType?: string | undefined,
		avatarUrl?: string | undefined,
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		currentChallenge?: string | undefined,
		defaultRole?: string | undefined,
		disabled?: boolean | undefined,
		displayName?: string | undefined,
		email?: GraphQLTypes["citext"] | undefined,
		emailVerified?: boolean | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		isAnonymous?: boolean | undefined,
		lastSeen?: GraphQLTypes["timestamptz"] | undefined,
		locale?: string | undefined,
		metadata?: GraphQLTypes["jsonb"] | undefined,
		newEmail?: GraphQLTypes["citext"] | undefined,
		otpHash?: string | undefined,
		otpHashExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		otpMethodLastUsed?: string | undefined,
		passwordHash?: string | undefined,
		phoneNumber?: string | undefined,
		phoneNumberVerified?: boolean | undefined,
		ticket?: string | undefined,
		ticketExpiresAt?: GraphQLTypes["timestamptz"] | undefined,
		totpSecret?: string | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "auth.users" */
	["users_update_column"]: users_update_column;
	["users_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["users_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["users_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["users_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["users_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["users_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["users_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["users_bool_exp"]
	};
	["uuid"]: "scalar" & { name: "uuid" };
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
	["uuid_comparison_exp"]: {
		_eq?: GraphQLTypes["uuid"] | undefined,
		_gt?: GraphQLTypes["uuid"] | undefined,
		_gte?: GraphQLTypes["uuid"] | undefined,
		_in?: Array<GraphQLTypes["uuid"]> | undefined,
		_is_null?: boolean | undefined,
		_lt?: GraphQLTypes["uuid"] | undefined,
		_lte?: GraphQLTypes["uuid"] | undefined,
		_neq?: GraphQLTypes["uuid"] | undefined,
		_nin?: Array<GraphQLTypes["uuid"]> | undefined
	};
	/** columns and relationships of "storage.virus" */
	["virus"]: {
		__typename: "virus",
		createdAt: GraphQLTypes["timestamptz"],
		/** An object relationship */
		file: GraphQLTypes["files"],
		fileId: GraphQLTypes["uuid"],
		filename: string,
		id: GraphQLTypes["uuid"],
		updatedAt: GraphQLTypes["timestamptz"],
		userSession: GraphQLTypes["jsonb"],
		virus: string
	};
	/** aggregated selection of "storage.virus" */
	["virus_aggregate"]: {
		__typename: "virus_aggregate",
		aggregate?: GraphQLTypes["virus_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["virus"]>
	};
	/** aggregate fields of "storage.virus" */
	["virus_aggregate_fields"]: {
		__typename: "virus_aggregate_fields",
		count: number,
		max?: GraphQLTypes["virus_max_fields"] | undefined,
		min?: GraphQLTypes["virus_min_fields"] | undefined
	};
	/** append existing jsonb value of filtered columns with new jsonb value */
	["virus_append_input"]: {
		userSession?: GraphQLTypes["jsonb"] | undefined
	};
	/** Boolean expression to filter rows from the table "storage.virus". All fields are combined with a logical 'AND'. */
	["virus_bool_exp"]: {
		_and?: Array<GraphQLTypes["virus_bool_exp"]> | undefined,
		_not?: GraphQLTypes["virus_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["virus_bool_exp"]> | undefined,
		createdAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		file?: GraphQLTypes["files_bool_exp"] | undefined,
		fileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		filename?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		userSession?: GraphQLTypes["jsonb_comparison_exp"] | undefined,
		virus?: GraphQLTypes["String_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "storage.virus" */
	["virus_constraint"]: virus_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	["virus_delete_at_path_input"]: {
		userSession?: Array<string> | undefined
	};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	["virus_delete_elem_input"]: {
		userSession?: number | undefined
	};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	["virus_delete_key_input"]: {
		userSession?: string | undefined
	};
	/** input type for inserting data into table "storage.virus" */
	["virus_insert_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		file?: GraphQLTypes["files_obj_rel_insert_input"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		userSession?: GraphQLTypes["jsonb"] | undefined,
		virus?: string | undefined
	};
	/** aggregate max on columns */
	["virus_max_fields"]: {
		__typename: "virus_max_fields",
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		virus?: string | undefined
	};
	/** aggregate min on columns */
	["virus_min_fields"]: {
		__typename: "virus_min_fields",
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		virus?: string | undefined
	};
	/** response of any mutation on the table "storage.virus" */
	["virus_mutation_response"]: {
		__typename: "virus_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["virus"]>
	};
	/** on_conflict condition type for table "storage.virus" */
	["virus_on_conflict"]: {
		constraint: GraphQLTypes["virus_constraint"],
		update_columns: Array<GraphQLTypes["virus_update_column"]>,
		where?: GraphQLTypes["virus_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "storage.virus". */
	["virus_order_by"]: {
		createdAt?: GraphQLTypes["order_by"] | undefined,
		file?: GraphQLTypes["files_order_by"] | undefined,
		fileId?: GraphQLTypes["order_by"] | undefined,
		filename?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		updatedAt?: GraphQLTypes["order_by"] | undefined,
		userSession?: GraphQLTypes["order_by"] | undefined,
		virus?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: storage.virus */
	["virus_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
	["virus_prepend_input"]: {
		userSession?: GraphQLTypes["jsonb"] | undefined
	};
	/** select columns of table "storage.virus" */
	["virus_select_column"]: virus_select_column;
	/** input type for updating data in table "storage.virus" */
	["virus_set_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		userSession?: GraphQLTypes["jsonb"] | undefined,
		virus?: string | undefined
	};
	/** Streaming cursor of the table "virus" */
	["virus_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["virus_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["virus_stream_cursor_value_input"]: {
		createdAt?: GraphQLTypes["timestamptz"] | undefined,
		fileId?: GraphQLTypes["uuid"] | undefined,
		filename?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		updatedAt?: GraphQLTypes["timestamptz"] | undefined,
		userSession?: GraphQLTypes["jsonb"] | undefined,
		virus?: string | undefined
	};
	/** update columns of table "storage.virus" */
	["virus_update_column"]: virus_update_column;
	["virus_updates"]: {
		/** append existing jsonb value of filtered columns with new jsonb value */
		_append?: GraphQLTypes["virus_append_input"] | undefined,
		/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
		_delete_at_path?: GraphQLTypes["virus_delete_at_path_input"] | undefined,
		/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
		_delete_elem?: GraphQLTypes["virus_delete_elem_input"] | undefined,
		/** delete key/value pair or string element. key/value pairs are matched based on their key value */
		_delete_key?: GraphQLTypes["virus_delete_key_input"] | undefined,
		/** prepend existing jsonb value of filtered columns with new jsonb value */
		_prepend?: GraphQLTypes["virus_prepend_input"] | undefined,
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["virus_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["virus_bool_exp"]
	};
	/** columns and relationships of "website" */
	["website"]: {
		__typename: "website",
		aboutUsContactBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor: string,
		backgroundColor: string,
		/** An object relationship */
		brand: GraphQLTypes["brands"],
		brandId: GraphQLTypes["uuid"],
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		domain: string,
		foregroundColor: string,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id: GraphQLTypes["uuid"],
		openGraphDescription?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregated selection of "website" */
	["website_aggregate"]: {
		__typename: "website_aggregate",
		aggregate?: GraphQLTypes["website_aggregate_fields"] | undefined,
		nodes: Array<GraphQLTypes["website"]>
	};
	/** aggregate fields of "website" */
	["website_aggregate_fields"]: {
		__typename: "website_aggregate_fields",
		count: number,
		max?: GraphQLTypes["website_max_fields"] | undefined,
		min?: GraphQLTypes["website_min_fields"] | undefined
	};
	/** Boolean expression to filter rows from the table "website". All fields are combined with a logical 'AND'. */
	["website_bool_exp"]: {
		_and?: Array<GraphQLTypes["website_bool_exp"]> | undefined,
		_not?: GraphQLTypes["website_bool_exp"] | undefined,
		_or?: Array<GraphQLTypes["website_bool_exp"]> | undefined,
		aboutUsContactBannerImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		aboutUsContactTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsHistoryBannerImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		aboutUsHistoryDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsHistoryTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsHistorytKeypointSubtitleOne?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsHistorytKeypointTitleOne?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsHistorytKeypointTitleTwo?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsStatementBannerImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		aboutUsStatementBannerText?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadyBannerImageId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadyInnovationDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadyTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWeAreReadyTransparencyDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveBannerImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		aboutUsWhatMoveUsMission?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveUsPurpose?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveUsTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveUsValues?: GraphQLTypes["String_comparison_exp"] | undefined,
		aboutUsWhatMoveUsVision?: GraphQLTypes["String_comparison_exp"] | undefined,
		accentColor?: GraphQLTypes["String_comparison_exp"] | undefined,
		backgroundColor?: GraphQLTypes["String_comparison_exp"] | undefined,
		brand?: GraphQLTypes["brands_bool_exp"] | undefined,
		brandId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		created_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined,
		domain?: GraphQLTypes["String_comparison_exp"] | undefined,
		foregroundColor?: GraphQLTypes["String_comparison_exp"] | undefined,
		googleAnalyticsKey?: GraphQLTypes["String_comparison_exp"] | undefined,
		googleTagManagerKey?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightBannerImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		homeBrandHighlightDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointSubtitleOne?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointTitleOne?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointTitleTwo?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeBrandHighlightKeypointValuePropositionList?: GraphQLTypes["String_array_comparison_exp"] | undefined,
		homeBrandHighlightTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeHeroBannerImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		homeHeroCallToActionTargetUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeHeroCallToActionText?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeHeroDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeHeroLabel?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeHeroTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsBannerImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		homeOurDifferentiatorsDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeOurDifferentiatorsTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToActionTargetUrl?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToActionText?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		homeWhatWeDoTitle?: GraphQLTypes["String_comparison_exp"] | undefined,
		id?: GraphQLTypes["uuid_comparison_exp"] | undefined,
		openGraphDescription?: GraphQLTypes["String_comparison_exp"] | undefined,
		updated_at?: GraphQLTypes["timestamptz_comparison_exp"] | undefined
	};
	/** unique or primary key constraints on table "website" */
	["website_constraint"]: website_constraint;
	/** input type for inserting data into table "website" */
	["website_insert_input"]: {
		aboutUsContactBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brand?: GraphQLTypes["brands_obj_rel_insert_input"] | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate max on columns */
	["website_max_fields"]: {
		__typename: "website_max_fields",
		aboutUsContactBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** aggregate min on columns */
	["website_min_fields"]: {
		__typename: "website_min_fields",
		aboutUsContactBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** response of any mutation on the table "website" */
	["website_mutation_response"]: {
		__typename: "website_mutation_response",
		/** number of rows affected by the mutation */
		affected_rows: number,
		/** data from the rows affected by the mutation */
		returning: Array<GraphQLTypes["website"]>
	};
	/** on_conflict condition type for table "website" */
	["website_on_conflict"]: {
		constraint: GraphQLTypes["website_constraint"],
		update_columns: Array<GraphQLTypes["website_update_column"]>,
		where?: GraphQLTypes["website_bool_exp"] | undefined
	};
	/** Ordering options when selecting data from "website". */
	["website_order_by"]: {
		aboutUsContactBannerImageFileId?: GraphQLTypes["order_by"] | undefined,
		aboutUsContactTitle?: GraphQLTypes["order_by"] | undefined,
		aboutUsHistoryBannerImageFileId?: GraphQLTypes["order_by"] | undefined,
		aboutUsHistoryDescription?: GraphQLTypes["order_by"] | undefined,
		aboutUsHistoryTitle?: GraphQLTypes["order_by"] | undefined,
		aboutUsHistorytKeypointSubtitleOne?: GraphQLTypes["order_by"] | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: GraphQLTypes["order_by"] | undefined,
		aboutUsHistorytKeypointTitleOne?: GraphQLTypes["order_by"] | undefined,
		aboutUsHistorytKeypointTitleTwo?: GraphQLTypes["order_by"] | undefined,
		aboutUsStatementBannerImageFileId?: GraphQLTypes["order_by"] | undefined,
		aboutUsStatementBannerText?: GraphQLTypes["order_by"] | undefined,
		aboutUsWeAreReadyBannerImageId?: GraphQLTypes["order_by"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: GraphQLTypes["order_by"] | undefined,
		aboutUsWeAreReadyInnovationDescription?: GraphQLTypes["order_by"] | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: GraphQLTypes["order_by"] | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: GraphQLTypes["order_by"] | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: GraphQLTypes["order_by"] | undefined,
		aboutUsWeAreReadyTitle?: GraphQLTypes["order_by"] | undefined,
		aboutUsWeAreReadyTransparencyDescription?: GraphQLTypes["order_by"] | undefined,
		aboutUsWhatMoveBannerImageFileId?: GraphQLTypes["order_by"] | undefined,
		aboutUsWhatMoveUsMission?: GraphQLTypes["order_by"] | undefined,
		aboutUsWhatMoveUsPurpose?: GraphQLTypes["order_by"] | undefined,
		aboutUsWhatMoveUsTitle?: GraphQLTypes["order_by"] | undefined,
		aboutUsWhatMoveUsValues?: GraphQLTypes["order_by"] | undefined,
		aboutUsWhatMoveUsVision?: GraphQLTypes["order_by"] | undefined,
		accentColor?: GraphQLTypes["order_by"] | undefined,
		backgroundColor?: GraphQLTypes["order_by"] | undefined,
		brand?: GraphQLTypes["brands_order_by"] | undefined,
		brandId?: GraphQLTypes["order_by"] | undefined,
		created_at?: GraphQLTypes["order_by"] | undefined,
		domain?: GraphQLTypes["order_by"] | undefined,
		foregroundColor?: GraphQLTypes["order_by"] | undefined,
		googleAnalyticsKey?: GraphQLTypes["order_by"] | undefined,
		googleTagManagerKey?: GraphQLTypes["order_by"] | undefined,
		homeBrandHighlightBannerImageFileId?: GraphQLTypes["order_by"] | undefined,
		homeBrandHighlightDescription?: GraphQLTypes["order_by"] | undefined,
		homeBrandHighlightKeypointSubtitleOne?: GraphQLTypes["order_by"] | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: GraphQLTypes["order_by"] | undefined,
		homeBrandHighlightKeypointTitleOne?: GraphQLTypes["order_by"] | undefined,
		homeBrandHighlightKeypointTitleTwo?: GraphQLTypes["order_by"] | undefined,
		homeBrandHighlightKeypointValuePropositionList?: GraphQLTypes["order_by"] | undefined,
		homeBrandHighlightTitle?: GraphQLTypes["order_by"] | undefined,
		homeHeroBannerImageFileId?: GraphQLTypes["order_by"] | undefined,
		homeHeroCallToActionTargetUrl?: GraphQLTypes["order_by"] | undefined,
		homeHeroCallToActionText?: GraphQLTypes["order_by"] | undefined,
		homeHeroDescription?: GraphQLTypes["order_by"] | undefined,
		homeHeroLabel?: GraphQLTypes["order_by"] | undefined,
		homeHeroTitle?: GraphQLTypes["order_by"] | undefined,
		homeOurDifferentiatorsBannerImageFileId?: GraphQLTypes["order_by"] | undefined,
		homeOurDifferentiatorsDescription?: GraphQLTypes["order_by"] | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: GraphQLTypes["order_by"] | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: GraphQLTypes["order_by"] | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: GraphQLTypes["order_by"] | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: GraphQLTypes["order_by"] | undefined,
		homeOurDifferentiatorsTitle?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoCallToActionTargetUrl?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoCallToActionText?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoDescription?: GraphQLTypes["order_by"] | undefined,
		homeWhatWeDoTitle?: GraphQLTypes["order_by"] | undefined,
		id?: GraphQLTypes["order_by"] | undefined,
		openGraphDescription?: GraphQLTypes["order_by"] | undefined,
		updated_at?: GraphQLTypes["order_by"] | undefined
	};
	/** primary key columns input for table: website */
	["website_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
	};
	/** select columns of table "website" */
	["website_select_column"]: website_select_column;
	/** input type for updating data in table "website" */
	["website_set_input"]: {
		aboutUsContactBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** Streaming cursor of the table "website" */
	["website_stream_cursor_input"]: {
		/** Stream column input with initial value */
		initial_value: GraphQLTypes["website_stream_cursor_value_input"],
		/** cursor ordering */
		ordering?: GraphQLTypes["cursor_ordering"] | undefined
	};
	/** Initial value of the column from where the streaming should start */
	["website_stream_cursor_value_input"]: {
		aboutUsContactBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsContactTitle?: string | undefined,
		aboutUsHistoryBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsHistoryDescription?: string | undefined,
		aboutUsHistoryTitle?: string | undefined,
		aboutUsHistorytKeypointSubtitleOne?: string | undefined,
		aboutUsHistorytKeypointSubtitleTwo?: string | undefined,
		aboutUsHistorytKeypointTitleOne?: string | undefined,
		aboutUsHistorytKeypointTitleTwo?: string | undefined,
		aboutUsStatementBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsStatementBannerText?: string | undefined,
		aboutUsWeAreReadyBannerImageId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWeAreReadyCustomerSatisfactionDescription?: string | undefined,
		aboutUsWeAreReadyInnovationDescription?: string | undefined,
		aboutUsWeAreReadySocialResponsibilityDescription?: string | undefined,
		aboutUsWeAreReadySpecializedSupportDescription?: string | undefined,
		aboutUsWeAreReadySubjectMatterExpertiseDescription?: string | undefined,
		aboutUsWeAreReadyTitle?: string | undefined,
		aboutUsWeAreReadyTransparencyDescription?: string | undefined,
		aboutUsWhatMoveBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		aboutUsWhatMoveUsMission?: string | undefined,
		aboutUsWhatMoveUsPurpose?: string | undefined,
		aboutUsWhatMoveUsTitle?: string | undefined,
		aboutUsWhatMoveUsValues?: string | undefined,
		aboutUsWhatMoveUsVision?: string | undefined,
		accentColor?: string | undefined,
		backgroundColor?: string | undefined,
		brandId?: GraphQLTypes["uuid"] | undefined,
		created_at?: GraphQLTypes["timestamptz"] | undefined,
		domain?: string | undefined,
		foregroundColor?: string | undefined,
		googleAnalyticsKey?: string | undefined,
		googleTagManagerKey?: string | undefined,
		homeBrandHighlightBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeBrandHighlightDescription?: string | undefined,
		homeBrandHighlightKeypointSubtitleOne?: string | undefined,
		homeBrandHighlightKeypointSubtitleTwo?: string | undefined,
		homeBrandHighlightKeypointTitleOne?: string | undefined,
		homeBrandHighlightKeypointTitleTwo?: string | undefined,
		homeBrandHighlightKeypointValuePropositionList?: Array<string> | undefined,
		homeBrandHighlightTitle?: string | undefined,
		homeHeroBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeHeroCallToActionTargetUrl?: string | undefined,
		homeHeroCallToActionText?: string | undefined,
		homeHeroDescription?: string | undefined,
		homeHeroLabel?: string | undefined,
		homeHeroTitle?: string | undefined,
		homeOurDifferentiatorsBannerImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeOurDifferentiatorsDescription?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointSubtitleTwo?: string | undefined,
		homeOurDifferentiatorsKeypointTitleOne?: string | undefined,
		homeOurDifferentiatorsKeypointTitleTwo?: string | undefined,
		homeOurDifferentiatorsTitle?: string | undefined,
		homeWhatWeDoCallToActionTargetUrl?: string | undefined,
		homeWhatWeDoCallToActionText?: string | undefined,
		homeWhatWeDoCallToBannerOneImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerOneLegend?: string | undefined,
		homeWhatWeDoCallToBannerThreeImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerThreeLegend?: string | undefined,
		homeWhatWeDoCallToBannerTwoImageFileId?: GraphQLTypes["uuid"] | undefined,
		homeWhatWeDoCallToBannerTwoLegend?: string | undefined,
		homeWhatWeDoDescription?: string | undefined,
		homeWhatWeDoTitle?: string | undefined,
		id?: GraphQLTypes["uuid"] | undefined,
		openGraphDescription?: string | undefined,
		updated_at?: GraphQLTypes["timestamptz"] | undefined
	};
	/** update columns of table "website" */
	["website_update_column"]: website_update_column;
	["website_updates"]: {
		/** sets the columns of the filtered rows to the given values */
		_set?: GraphQLTypes["website_set_input"] | undefined,
		/** filter the rows which have to be updated */
		where: GraphQLTypes["website_bool_exp"]
	}
}
/** unique or primary key constraints on table "AppIntegration" */
export const enum AppIntegration_constraint {
	AppIntegration_pkey = "AppIntegration_pkey"
}
/** select columns of table "AppIntegration" */
export const enum AppIntegration_select_column {
	configParams = "configParams",
	description = "description",
	icon = "icon",
	id = "id",
	name = "name",
	type = "type"
}
/** update columns of table "AppIntegration" */
export const enum AppIntegration_update_column {
	configParams = "configParams",
	description = "description",
	icon = "icon",
	id = "id",
	name = "name",
	type = "type"
}
/** unique or primary key constraints on table "CourseCategory" */
export const enum CourseCategory_constraint {
	CourseCategory_pkey = "CourseCategory_pkey"
}
/** select columns of table "CourseCategory" */
export const enum CourseCategory_select_column {
	id = "id",
	name = "name"
}
/** update columns of table "CourseCategory" */
export const enum CourseCategory_update_column {
	id = "id",
	name = "name"
}
/** unique or primary key constraints on table "Course" */
export const enum Course_constraint {
	Course_pkey = "Course_pkey"
}
/** select columns of table "Course" */
export const enum Course_select_column {
	courseCategoryId = "courseCategoryId",
	description = "description",
	id = "id",
	imageUrl = "imageUrl",
	name = "name"
}
/** update columns of table "Course" */
export const enum Course_update_column {
	courseCategoryId = "courseCategoryId",
	description = "description",
	id = "id",
	imageUrl = "imageUrl",
	name = "name"
}
/** unique or primary key constraints on table "GeneratedPromptByTemplateImage" */
export const enum GeneratedPromptByTemplateImage_constraint {
	GeneratedPromptByTemplateImage_pkey = "GeneratedPromptByTemplateImage_pkey"
}
/** select columns of table "GeneratedPromptByTemplateImage" */
export const enum GeneratedPromptByTemplateImage_select_column {
	GeneratedPromptByTemplateId = "GeneratedPromptByTemplateId",
	fileId = "fileId",
	id = "id"
}
/** update columns of table "GeneratedPromptByTemplateImage" */
export const enum GeneratedPromptByTemplateImage_update_column {
	GeneratedPromptByTemplateId = "GeneratedPromptByTemplateId",
	fileId = "fileId",
	id = "id"
}
/** unique or primary key constraints on table "GeneratedPromptByTemplate" */
export const enum GeneratedPromptByTemplate_constraint {
	GeneratedPromptByTemplate_pkey = "GeneratedPromptByTemplate_pkey"
}
/** select columns of table "GeneratedPromptByTemplate" */
export const enum GeneratedPromptByTemplate_select_column {
	active = "active",
	alias = "alias",
	backgroundImageFileId = "backgroundImageFileId",
	channel = "channel",
	command = "command",
	created_at = "created_at",
	created_by = "created_by",
	displayImageUrl = "displayImageUrl",
	displayMapper = "displayMapper",
	generatingDisplayStatus = "generatingDisplayStatus",
	id = "id",
	pricingPlanId = "pricingPlanId",
	publishDate = "publishDate",
	result = "result",
	scheduledDate = "scheduledDate",
	scheduledDateEventId = "scheduledDateEventId",
	selectedDisplayFormatId = "selectedDisplayFormatId",
	status = "status",
	textPromptTemplateId = "textPromptTemplateId",
	updated_at = "updated_at"
}
/** select "GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "GeneratedPromptByTemplate" */
export const enum GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns {
	active = "active"
}
/** select "GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "GeneratedPromptByTemplate" */
export const enum GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns {
	active = "active"
}
/** update columns of table "GeneratedPromptByTemplate" */
export const enum GeneratedPromptByTemplate_update_column {
	active = "active",
	alias = "alias",
	backgroundImageFileId = "backgroundImageFileId",
	channel = "channel",
	command = "command",
	created_at = "created_at",
	created_by = "created_by",
	displayImageUrl = "displayImageUrl",
	displayMapper = "displayMapper",
	generatingDisplayStatus = "generatingDisplayStatus",
	id = "id",
	pricingPlanId = "pricingPlanId",
	publishDate = "publishDate",
	result = "result",
	scheduledDate = "scheduledDate",
	scheduledDateEventId = "scheduledDateEventId",
	selectedDisplayFormatId = "selectedDisplayFormatId",
	status = "status",
	textPromptTemplateId = "textPromptTemplateId",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "TextGeneratorOptionEntry" */
export const enum TextGeneratorOptionEntry_constraint {
	TextGeneratorOptionEntry_pkey = "TextGeneratorOptionEntry_pkey"
}
/** select columns of table "TextGeneratorOptionEntry" */
export const enum TextGeneratorOptionEntry_select_column {
	Label = "Label",
	TextGeneratorOptionId = "TextGeneratorOptionId",
	id = "id"
}
/** update columns of table "TextGeneratorOptionEntry" */
export const enum TextGeneratorOptionEntry_update_column {
	Label = "Label",
	TextGeneratorOptionId = "TextGeneratorOptionId",
	id = "id"
}
/** unique or primary key constraints on table "TextGeneratorOption" */
export const enum TextGeneratorOption_constraint {
	TextGeneratorOption_pkey = "TextGeneratorOption_pkey"
}
/** select columns of table "TextGeneratorOption" */
export const enum TextGeneratorOption_select_column {
	Label = "Label",
	created_at = "created_at",
	id = "id",
	popover_tooltip = "popover_tooltip",
	updated_at = "updated_at"
}
/** update columns of table "TextGeneratorOption" */
export const enum TextGeneratorOption_update_column {
	Label = "Label",
	created_at = "created_at",
	id = "id",
	popover_tooltip = "popover_tooltip",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "TextPromptTemplateCategory" */
export const enum TextPromptTemplateCategory_constraint {
	TextPromptTemplateCategory_pkey = "TextPromptTemplateCategory_pkey"
}
/** select columns of table "TextPromptTemplateCategory" */
export const enum TextPromptTemplateCategory_select_column {
	categoryName = "categoryName",
	id = "id"
}
/** update columns of table "TextPromptTemplateCategory" */
export const enum TextPromptTemplateCategory_update_column {
	categoryName = "categoryName",
	id = "id"
}
/** unique or primary key constraints on table "TextPromptTemplate" */
export const enum TextPromptTemplate_constraint {
	TextPromptTemplate_pkey = "TextPromptTemplate_pkey"
}
/** select columns of table "TextPromptTemplate" */
export const enum TextPromptTemplate_select_column {
	TextTemplateClientCategoryId = "TextTemplateClientCategoryId",
	categoryId = "categoryId",
	channel = "channel",
	creditPrice = "creditPrice",
	definitions = "definitions",
	description = "description",
	displayDimensionId = "displayDimensionId",
	flexible = "flexible",
	icon = "icon",
	id = "id",
	marketPriceInCredits = "marketPriceInCredits",
	name = "name",
	newTemplateText = "newTemplateText",
	saleCreditPrice = "saleCreditPrice"
}
/** select "TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "TextPromptTemplate" */
export const enum TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns {
	flexible = "flexible"
}
/** select "TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "TextPromptTemplate" */
export const enum TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns {
	flexible = "flexible"
}
/** update columns of table "TextPromptTemplate" */
export const enum TextPromptTemplate_update_column {
	TextTemplateClientCategoryId = "TextTemplateClientCategoryId",
	categoryId = "categoryId",
	channel = "channel",
	creditPrice = "creditPrice",
	definitions = "definitions",
	description = "description",
	displayDimensionId = "displayDimensionId",
	flexible = "flexible",
	icon = "icon",
	id = "id",
	marketPriceInCredits = "marketPriceInCredits",
	name = "name",
	newTemplateText = "newTemplateText",
	saleCreditPrice = "saleCreditPrice"
}
/** unique or primary key constraints on table "TextTemplateClientCategory" */
export const enum TextTemplateClientCategory_constraint {
	TextTemplateClientCategory_pkey = "TextTemplateClientCategory_pkey"
}
/** select columns of table "TextTemplateClientCategory" */
export const enum TextTemplateClientCategory_select_column {
	displayName = "displayName",
	iconClass = "iconClass",
	id = "id"
}
/** update columns of table "TextTemplateClientCategory" */
export const enum TextTemplateClientCategory_update_column {
	displayName = "displayName",
	iconClass = "iconClass",
	id = "id"
}
/** unique or primary key constraints on table "audiences" */
export const enum audiences_constraint {
	audiences_pkey = "audiences_pkey"
}
/** select columns of table "audiences" */
export const enum audiences_select_column {
	brandId = "brandId",
	brandName = "brandName",
	created_at = "created_at",
	created_by = "created_by",
	deleted = "deleted",
	id = "id",
	personas = "personas",
	productOrService = "productOrService",
	segment = "segment",
	targetAudience = "targetAudience",
	updated_at = "updated_at"
}
/** update columns of table "audiences" */
export const enum audiences_update_column {
	brandId = "brandId",
	brandName = "brandName",
	created_at = "created_at",
	created_by = "created_by",
	deleted = "deleted",
	id = "id",
	personas = "personas",
	productOrService = "productOrService",
	segment = "segment",
	targetAudience = "targetAudience",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "auth.provider_requests" */
export const enum authProviderRequests_constraint {
	provider_requests_pkey = "provider_requests_pkey"
}
/** select columns of table "auth.provider_requests" */
export const enum authProviderRequests_select_column {
	id = "id",
	options = "options"
}
/** update columns of table "auth.provider_requests" */
export const enum authProviderRequests_update_column {
	id = "id",
	options = "options"
}
/** unique or primary key constraints on table "auth.providers" */
export const enum authProviders_constraint {
	providers_pkey = "providers_pkey"
}
/** select columns of table "auth.providers" */
export const enum authProviders_select_column {
	id = "id"
}
/** update columns of table "auth.providers" */
export const enum authProviders_update_column {
	id = "id"
}
/** unique or primary key constraints on table "auth.refresh_token_types" */
export const enum authRefreshTokenTypes_constraint {
	refresh_token_types_pkey = "refresh_token_types_pkey"
}
export const enum authRefreshTokenTypes_enum {
	pat = "pat",
	regular = "regular"
}
/** select columns of table "auth.refresh_token_types" */
export const enum authRefreshTokenTypes_select_column {
	comment = "comment",
	value = "value"
}
/** update columns of table "auth.refresh_token_types" */
export const enum authRefreshTokenTypes_update_column {
	comment = "comment",
	value = "value"
}
/** unique or primary key constraints on table "auth.refresh_tokens" */
export const enum authRefreshTokens_constraint {
	refresh_tokens_pkey = "refresh_tokens_pkey"
}
/** select columns of table "auth.refresh_tokens" */
export const enum authRefreshTokens_select_column {
	createdAt = "createdAt",
	expiresAt = "expiresAt",
	id = "id",
	metadata = "metadata",
	refreshTokenHash = "refreshTokenHash",
	type = "type",
	userId = "userId"
}
/** update columns of table "auth.refresh_tokens" */
export const enum authRefreshTokens_update_column {
	createdAt = "createdAt",
	expiresAt = "expiresAt",
	id = "id",
	metadata = "metadata",
	refreshTokenHash = "refreshTokenHash",
	type = "type",
	userId = "userId"
}
/** unique or primary key constraints on table "auth.roles" */
export const enum authRoles_constraint {
	roles_pkey = "roles_pkey"
}
/** select columns of table "auth.roles" */
export const enum authRoles_select_column {
	role = "role"
}
/** update columns of table "auth.roles" */
export const enum authRoles_update_column {
	role = "role"
}
/** unique or primary key constraints on table "auth.user_providers" */
export const enum authUserProviders_constraint {
	user_providers_pkey = "user_providers_pkey",
	user_providers_provider_id_provider_user_id_key = "user_providers_provider_id_provider_user_id_key",
	user_providers_user_id_provider_id_key = "user_providers_user_id_provider_id_key"
}
/** select columns of table "auth.user_providers" */
export const enum authUserProviders_select_column {
	accessToken = "accessToken",
	createdAt = "createdAt",
	id = "id",
	providerId = "providerId",
	providerUserId = "providerUserId",
	refreshToken = "refreshToken",
	updatedAt = "updatedAt",
	userId = "userId"
}
/** update columns of table "auth.user_providers" */
export const enum authUserProviders_update_column {
	accessToken = "accessToken",
	createdAt = "createdAt",
	id = "id",
	providerId = "providerId",
	providerUserId = "providerUserId",
	refreshToken = "refreshToken",
	updatedAt = "updatedAt",
	userId = "userId"
}
/** unique or primary key constraints on table "auth.user_roles" */
export const enum authUserRoles_constraint {
	user_roles_pkey = "user_roles_pkey",
	user_roles_user_id_role_key = "user_roles_user_id_role_key"
}
/** select columns of table "auth.user_roles" */
export const enum authUserRoles_select_column {
	createdAt = "createdAt",
	id = "id",
	role = "role",
	userId = "userId"
}
/** update columns of table "auth.user_roles" */
export const enum authUserRoles_update_column {
	createdAt = "createdAt",
	id = "id",
	role = "role",
	userId = "userId"
}
/** unique or primary key constraints on table "auth.user_security_keys" */
export const enum authUserSecurityKeys_constraint {
	user_security_key_credential_id_key = "user_security_key_credential_id_key",
	user_security_keys_pkey = "user_security_keys_pkey"
}
/** select columns of table "auth.user_security_keys" */
export const enum authUserSecurityKeys_select_column {
	counter = "counter",
	credentialId = "credentialId",
	credentialPublicKey = "credentialPublicKey",
	id = "id",
	nickname = "nickname",
	transports = "transports",
	userId = "userId"
}
/** update columns of table "auth.user_security_keys" */
export const enum authUserSecurityKeys_update_column {
	counter = "counter",
	credentialId = "credentialId",
	credentialPublicKey = "credentialPublicKey",
	id = "id",
	nickname = "nickname",
	transports = "transports",
	userId = "userId"
}
/** unique or primary key constraints on table "blogCategory" */
export const enum blogCategory_constraint {
	blogCategory_pkey = "blogCategory_pkey"
}
/** select columns of table "blogCategory" */
export const enum blogCategory_select_column {
	created_at = "created_at",
	id = "id",
	name = "name",
	updated_at = "updated_at"
}
/** update columns of table "blogCategory" */
export const enum blogCategory_update_column {
	created_at = "created_at",
	id = "id",
	name = "name",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "blog" */
export const enum blog_constraint {
	blog_pkey = "blog_pkey"
}
/** select columns of table "blog" */
export const enum blog_select_column {
	brandId = "brandId",
	categoryId = "categoryId",
	content = "content",
	created_at = "created_at",
	created_by = "created_by",
	featuredImageId = "featuredImageId",
	id = "id",
	metaDescription = "metaDescription",
	projectId = "projectId",
	publishDate = "publishDate",
	scheduledDate = "scheduledDate",
	slug = "slug",
	title = "title",
	updated_at = "updated_at"
}
/** update columns of table "blog" */
export const enum blog_update_column {
	brandId = "brandId",
	categoryId = "categoryId",
	content = "content",
	created_at = "created_at",
	created_by = "created_by",
	featuredImageId = "featuredImageId",
	id = "id",
	metaDescription = "metaDescription",
	projectId = "projectId",
	publishDate = "publishDate",
	scheduledDate = "scheduledDate",
	slug = "slug",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "brands" */
export const enum brands_constraint {
	brands_pkey = "brands_pkey"
}
/** select columns of table "brands" */
export const enum brands_select_column {
	addressCity = "addressCity",
	addressComplement = "addressComplement",
	addressDistrict = "addressDistrict",
	addressLatitude = "addressLatitude",
	addressLongitude = "addressLongitude",
	addressNumber = "addressNumber",
	addressStreet = "addressStreet",
	addressUf = "addressUf",
	addressZipCode = "addressZipCode",
	archtypeAdherence = "archtypeAdherence",
	brandName = "brandName",
	colors = "colors",
	created_at = "created_at",
	created_by = "created_by",
	deleted = "deleted",
	email = "email",
	facebookUrl = "facebookUrl",
	fontPrimary = "fontPrimary",
	fontSecondary = "fontSecondary",
	id = "id",
	instagramUrl = "instagramUrl",
	isBrand = "isBrand",
	linkedinUrl = "linkedinUrl",
	logoFileId = "logoFileId",
	personality = "personality",
	phoneNumber = "phoneNumber",
	productOrService = "productOrService",
	segment = "segment",
	shortAbout = "shortAbout",
	slogan = "slogan",
	tiktokUrl = "tiktokUrl",
	twitterUrl = "twitterUrl",
	updated_at = "updated_at",
	voiceTone = "voiceTone",
	whatsappNumber = "whatsappNumber",
	youtubeUrl = "youtubeUrl"
}
/** update columns of table "brands" */
export const enum brands_update_column {
	addressCity = "addressCity",
	addressComplement = "addressComplement",
	addressDistrict = "addressDistrict",
	addressLatitude = "addressLatitude",
	addressLongitude = "addressLongitude",
	addressNumber = "addressNumber",
	addressStreet = "addressStreet",
	addressUf = "addressUf",
	addressZipCode = "addressZipCode",
	archtypeAdherence = "archtypeAdherence",
	brandName = "brandName",
	colors = "colors",
	created_at = "created_at",
	created_by = "created_by",
	deleted = "deleted",
	email = "email",
	facebookUrl = "facebookUrl",
	fontPrimary = "fontPrimary",
	fontSecondary = "fontSecondary",
	id = "id",
	instagramUrl = "instagramUrl",
	isBrand = "isBrand",
	linkedinUrl = "linkedinUrl",
	logoFileId = "logoFileId",
	personality = "personality",
	phoneNumber = "phoneNumber",
	productOrService = "productOrService",
	segment = "segment",
	shortAbout = "shortAbout",
	slogan = "slogan",
	tiktokUrl = "tiktokUrl",
	twitterUrl = "twitterUrl",
	updated_at = "updated_at",
	voiceTone = "voiceTone",
	whatsappNumber = "whatsappNumber",
	youtubeUrl = "youtubeUrl"
}
/** unique or primary key constraints on table "storage.buckets" */
export const enum buckets_constraint {
	buckets_pkey = "buckets_pkey"
}
/** select columns of table "storage.buckets" */
export const enum buckets_select_column {
	cacheControl = "cacheControl",
	createdAt = "createdAt",
	downloadExpiration = "downloadExpiration",
	id = "id",
	maxUploadFileSize = "maxUploadFileSize",
	minUploadFileSize = "minUploadFileSize",
	presignedUrlsEnabled = "presignedUrlsEnabled",
	updatedAt = "updatedAt"
}
/** update columns of table "storage.buckets" */
export const enum buckets_update_column {
	cacheControl = "cacheControl",
	createdAt = "createdAt",
	downloadExpiration = "downloadExpiration",
	id = "id",
	maxUploadFileSize = "maxUploadFileSize",
	minUploadFileSize = "minUploadFileSize",
	presignedUrlsEnabled = "presignedUrlsEnabled",
	updatedAt = "updatedAt"
}
/** unique or primary key constraints on table "campaign" */
export const enum campaign_constraint {
	campaign_pkey = "campaign_pkey"
}
/** select columns of table "campaign" */
export const enum campaign_select_column {
	audiencesIds = "audiencesIds",
	baseJson = "baseJson",
	brandId = "brandId",
	campaignObjective = "campaignObjective",
	created_at = "created_at",
	created_by = "created_by",
	id = "id",
	productIds = "productIds",
	updated_at = "updated_at"
}
/** update columns of table "campaign" */
export const enum campaign_update_column {
	audiencesIds = "audiencesIds",
	baseJson = "baseJson",
	brandId = "brandId",
	campaignObjective = "campaignObjective",
	created_at = "created_at",
	created_by = "created_by",
	id = "id",
	productIds = "productIds",
	updated_at = "updated_at"
}
/** select columns of table "categorysummaryview" */
export const enum categorysummaryview_select_column {
	categoryName = "categoryName",
	count = "count",
	id = "id"
}
/** unique or primary key constraints on table "channels" */
export const enum channels_constraint {
	channels_alias_key = "channels_alias_key",
	channels_displayName_key = "channels_displayName_key",
	channels_pkey = "channels_pkey"
}
/** select columns of table "channels" */
export const enum channels_select_column {
	alias = "alias",
	created_at = "created_at",
	displayName = "displayName",
	id = "id",
	updated_at = "updated_at"
}
/** update columns of table "channels" */
export const enum channels_update_column {
	alias = "alias",
	created_at = "created_at",
	displayName = "displayName",
	id = "id",
	updated_at = "updated_at"
}
/** select columns of table "consumptionByChannelView" */
export const enum consumptionByChannelView_select_column {
	agendados = "agendados",
	channel = "channel",
	copymagico = "copymagico",
	created_by = "created_by",
	economia = "economia",
	mercado = "mercado",
	publicados = "publicados",
	rascunhos = "rascunhos"
}
/** unique or primary key constraints on table "costTable" */
export const enum costTable_constraint {
	costTable_pkey = "costTable_pkey"
}
/** select columns of table "costTable" */
export const enum costTable_select_column {
	bannerBear = "bannerBear",
	created_at = "created_at",
	entityId = "entityId",
	entityName = "entityName",
	gptCompletionTokens = "gptCompletionTokens",
	gptPromptTokens = "gptPromptTokens",
	id = "id",
	provider = "provider"
}
/** update columns of table "costTable" */
export const enum costTable_update_column {
	bannerBear = "bannerBear",
	created_at = "created_at",
	entityId = "entityId",
	entityName = "entityName",
	gptCompletionTokens = "gptCompletionTokens",
	gptPromptTokens = "gptPromptTokens",
	id = "id",
	provider = "provider"
}
/** ordering argument of a cursor */
export const enum cursor_ordering {
	ASC = "ASC",
	DESC = "DESC"
}
/** unique or primary key constraints on table "dealStatus" */
export const enum dealStatus_constraint {
	dealStatus_pkey = "dealStatus_pkey"
}
/** select columns of table "dealStatus" */
export const enum dealStatus_select_column {
	displayName = "displayName",
	id = "id",
	order = "order"
}
/** update columns of table "dealStatus" */
export const enum dealStatus_update_column {
	displayName = "displayName",
	id = "id",
	order = "order"
}
/** unique or primary key constraints on table "deal" */
export const enum deal_constraint {
	deal_pkey = "deal_pkey"
}
/** select columns of table "deal" */
export const enum deal_select_column {
	created_at = "created_at",
	email = "email",
	id = "id",
	name = "name",
	phone = "phone",
	productId = "productId",
	rating = "rating",
	statusId = "statusId",
	updated_at = "updated_at"
}
/** update columns of table "deal" */
export const enum deal_update_column {
	created_at = "created_at",
	email = "email",
	id = "id",
	name = "name",
	phone = "phone",
	productId = "productId",
	rating = "rating",
	statusId = "statusId",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "displayCategory" */
export const enum displayCategory_constraint {
	displayCategory_pkey = "displayCategory_pkey"
}
/** select columns of table "displayCategory" */
export const enum displayCategory_select_column {
	created_at = "created_at",
	displayName = "displayName",
	id = "id",
	name = "name"
}
/** update columns of table "displayCategory" */
export const enum displayCategory_update_column {
	created_at = "created_at",
	displayName = "displayName",
	id = "id",
	name = "name"
}
/** unique or primary key constraints on table "displayDimension" */
export const enum displayDimension_constraint {
	displayDimension_pkey = "displayDimension_pkey"
}
/** select columns of table "displayDimension" */
export const enum displayDimension_select_column {
	displayName = "displayName",
	id = "id",
	name = "name"
}
/** update columns of table "displayDimension" */
export const enum displayDimension_update_column {
	displayName = "displayName",
	id = "id",
	name = "name"
}
/** unique or primary key constraints on table "displayFormats" */
export const enum displayFormats_constraint {
	displayFormats_pkey = "displayFormats_pkey"
}
/** select columns of table "displayFormats" */
export const enum displayFormats_select_column {
	bannerbearId = "bannerbearId",
	created_at = "created_at",
	displayCategoryId = "displayCategoryId",
	displayDimensionId = "displayDimensionId",
	displayName = "displayName",
	id = "id",
	mapper = "mapper",
	name = "name",
	newData = "newData",
	newTemplate = "newTemplate",
	requiresBrand = "requiresBrand"
}
/** update columns of table "displayFormats" */
export const enum displayFormats_update_column {
	bannerbearId = "bannerbearId",
	created_at = "created_at",
	displayCategoryId = "displayCategoryId",
	displayDimensionId = "displayDimensionId",
	displayName = "displayName",
	id = "id",
	mapper = "mapper",
	name = "name",
	newData = "newData",
	newTemplate = "newTemplate",
	requiresBrand = "requiresBrand"
}
/** unique or primary key constraints on table "displayTemplate" */
export const enum displayTemplate_constraint {
	displayTemplate_pkey = "displayTemplate_pkey"
}
/** select columns of table "displayTemplate" */
export const enum displayTemplate_select_column {
	alias = "alias",
	fields = "fields",
	id = "id",
	template = "template"
}
/** update columns of table "displayTemplate" */
export const enum displayTemplate_update_column {
	alias = "alias",
	fields = "fields",
	id = "id",
	template = "template"
}
/** unique or primary key constraints on table "storage.files" */
export const enum files_constraint {
	files_pkey = "files_pkey"
}
/** select columns of table "storage.files" */
export const enum files_select_column {
	bucketId = "bucketId",
	createdAt = "createdAt",
	etag = "etag",
	id = "id",
	isUploaded = "isUploaded",
	metadata = "metadata",
	mimeType = "mimeType",
	name = "name",
	size = "size",
	updatedAt = "updatedAt",
	uploadedByUserId = "uploadedByUserId"
}
/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage.files" */
export const enum files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns {
	isUploaded = "isUploaded"
}
/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage.files" */
export const enum files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns {
	isUploaded = "isUploaded"
}
/** update columns of table "storage.files" */
export const enum files_update_column {
	bucketId = "bucketId",
	createdAt = "createdAt",
	etag = "etag",
	id = "id",
	isUploaded = "isUploaded",
	metadata = "metadata",
	mimeType = "mimeType",
	name = "name",
	size = "size",
	updatedAt = "updatedAt",
	uploadedByUserId = "uploadedByUserId"
}
/** unique or primary key constraints on table "interaction" */
export const enum interaction_constraint {
	interaction_pkey = "interaction_pkey"
}
/** select columns of table "interaction" */
export const enum interaction_select_column {
	channel = "channel",
	created_at = "created_at",
	created_by = "created_by",
	date = "date",
	dealId = "dealId",
	id = "id",
	observation = "observation",
	success = "success",
	updated_at = "updated_at"
}
/** select "interaction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "interaction" */
export const enum interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns {
	success = "success"
}
/** select "interaction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "interaction" */
export const enum interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns {
	success = "success"
}
/** update columns of table "interaction" */
export const enum interaction_update_column {
	channel = "channel",
	created_at = "created_at",
	created_by = "created_by",
	date = "date",
	dealId = "dealId",
	id = "id",
	observation = "observation",
	success = "success",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "landingPageTemplate" */
export const enum landingPageTemplate_constraint {
	landingPageTemplate_pkey = "landingPageTemplate_pkey"
}
/** select columns of table "landingPageTemplate" */
export const enum landingPageTemplate_select_column {
	created_at = "created_at",
	htmlTemplate = "htmlTemplate",
	id = "id",
	updated_at = "updated_at"
}
/** update columns of table "landingPageTemplate" */
export const enum landingPageTemplate_update_column {
	created_at = "created_at",
	htmlTemplate = "htmlTemplate",
	id = "id",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "newTemplateType" */
export const enum newTemplateType_constraint {
	newTemplateType_pkey = "newTemplateType_pkey"
}
/** select columns of table "newTemplateType" */
export const enum newTemplateType_select_column {
	channel = "channel",
	created_at = "created_at",
	creditPrice = "creditPrice",
	description = "description",
	icon = "icon",
	id = "id",
	name = "name",
	saleCreditPrice = "saleCreditPrice",
	thumbnailFileId = "thumbnailFileId",
	updated_at = "updated_at"
}
/** update columns of table "newTemplateType" */
export const enum newTemplateType_update_column {
	channel = "channel",
	created_at = "created_at",
	creditPrice = "creditPrice",
	description = "description",
	icon = "icon",
	id = "id",
	name = "name",
	saleCreditPrice = "saleCreditPrice",
	thumbnailFileId = "thumbnailFileId",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "openAIApiKey" */
export const enum openAIApiKey_constraint {
	openAIApiKey_pkey = "openAIApiKey_pkey"
}
/** select columns of table "openAIApiKey" */
export const enum openAIApiKey_select_column {
	apiKey = "apiKey",
	created_at = "created_at",
	id = "id",
	last_used = "last_used",
	status = "status"
}
/** update columns of table "openAIApiKey" */
export const enum openAIApiKey_update_column {
	apiKey = "apiKey",
	created_at = "created_at",
	id = "id",
	last_used = "last_used",
	status = "status"
}
/** column ordering options */
export const enum order_by {
	asc = "asc",
	asc_nulls_first = "asc_nulls_first",
	asc_nulls_last = "asc_nulls_last",
	desc = "desc",
	desc_nulls_first = "desc_nulls_first",
	desc_nulls_last = "desc_nulls_last"
}
/** unique or primary key constraints on table "pTemplate" */
export const enum pTemplate_constraint {
	pTemplate_pkey = "pTemplate_pkey"
}
/** select columns of table "pTemplate" */
export const enum pTemplate_select_column {
	category = "category",
	createdBy = "createdBy",
	created_at = "created_at",
	deletedAt = "deletedAt",
	descriptionMaxLength = "descriptionMaxLength",
	id = "id",
	json = "json",
	previewFileId = "previewFileId",
	subtitleMaxLength = "subtitleMaxLength",
	tagScores = "tagScores",
	titleMaxLength = "titleMaxLength",
	updated_at = "updated_at",
	visibility = "visibility"
}
/** update columns of table "pTemplate" */
export const enum pTemplate_update_column {
	category = "category",
	createdBy = "createdBy",
	created_at = "created_at",
	deletedAt = "deletedAt",
	descriptionMaxLength = "descriptionMaxLength",
	id = "id",
	json = "json",
	previewFileId = "previewFileId",
	subtitleMaxLength = "subtitleMaxLength",
	tagScores = "tagScores",
	titleMaxLength = "titleMaxLength",
	updated_at = "updated_at",
	visibility = "visibility"
}
/** unique or primary key constraints on table "pricingPlan" */
export const enum pricingPlan_constraint {
	pricingPlan_pkey = "pricingPlan_pkey",
	pricingPlan_stripeProductId_key = "pricingPlan_stripeProductId_key"
}
/** select columns of table "pricingPlan" */
export const enum pricingPlan_select_column {
	active = "active",
	alias = "alias",
	created_at = "created_at",
	creditUnitDiscount = "creditUnitDiscount",
	creditsToAdd = "creditsToAdd",
	htmlFeatureItems = "htmlFeatureItems",
	id = "id",
	monthlyPrice = "monthlyPrice",
	order = "order",
	stripePriceId = "stripePriceId",
	stripeProductId = "stripeProductId",
	type = "type",
	updated_at = "updated_at"
}
/** update columns of table "pricingPlan" */
export const enum pricingPlan_update_column {
	active = "active",
	alias = "alias",
	created_at = "created_at",
	creditUnitDiscount = "creditUnitDiscount",
	creditsToAdd = "creditsToAdd",
	htmlFeatureItems = "htmlFeatureItems",
	id = "id",
	monthlyPrice = "monthlyPrice",
	order = "order",
	stripePriceId = "stripePriceId",
	stripeProductId = "stripeProductId",
	type = "type",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "products" */
export const enum products_constraint {
	projects_pkey = "projects_pkey"
}
/** select columns of table "products" */
export const enum products_select_column {
	alias = "alias",
	benefits = "benefits",
	brandId = "brandId",
	category = "category",
	characteristics = "characteristics",
	created_at = "created_at",
	created_by = "created_by",
	deleted = "deleted",
	description = "description",
	id = "id",
	imageFourId = "imageFourId",
	imageOneId = "imageOneId",
	imageThreeId = "imageThreeId",
	imageTwoId = "imageTwoId",
	linkConversion = "linkConversion",
	price = "price",
	salePrice = "salePrice",
	testimonials = "testimonials",
	type = "type",
	updated_at = "updated_at",
	wordKeys = "wordKeys"
}
/** select "products_aggregate_bool_exp_bool_and_arguments_columns" columns of table "products" */
export const enum products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns {
	deleted = "deleted"
}
/** select "products_aggregate_bool_exp_bool_or_arguments_columns" columns of table "products" */
export const enum products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns {
	deleted = "deleted"
}
/** update columns of table "products" */
export const enum products_update_column {
	alias = "alias",
	benefits = "benefits",
	brandId = "brandId",
	category = "category",
	characteristics = "characteristics",
	created_at = "created_at",
	created_by = "created_by",
	deleted = "deleted",
	description = "description",
	id = "id",
	imageFourId = "imageFourId",
	imageOneId = "imageOneId",
	imageThreeId = "imageThreeId",
	imageTwoId = "imageTwoId",
	linkConversion = "linkConversion",
	price = "price",
	salePrice = "salePrice",
	testimonials = "testimonials",
	type = "type",
	updated_at = "updated_at",
	wordKeys = "wordKeys"
}
/** unique or primary key constraints on table "projectImage" */
export const enum projectImage_constraint {
	projectImage_pkey = "projectImage_pkey"
}
/** select columns of table "projectImage" */
export const enum projectImage_select_column {
	fileId = "fileId",
	id = "id",
	projectId = "projectId"
}
/** update columns of table "projectImage" */
export const enum projectImage_update_column {
	fileId = "fileId",
	id = "id",
	projectId = "projectId"
}
/** unique or primary key constraints on table "project" */
export const enum project_constraint {
	project_pkey = "project_pkey"
}
/** select columns of table "project" */
export const enum project_select_column {
	alias = "alias",
	audienceId = "audienceId",
	blogContent = "blogContent",
	blogTitle = "blogTitle",
	brandId = "brandId",
	campaignId = "campaignId",
	caption = "caption",
	created_at = "created_at",
	created_by = "created_by",
	creditPrice = "creditPrice",
	deleted = "deleted",
	featuredImageFileId = "featuredImageFileId",
	id = "id",
	previewImageFileId = "previewImageFileId",
	productId = "productId",
	publishDate = "publishDate",
	rawText = "rawText",
	scheduledDate = "scheduledDate",
	scheduledDateEventId = "scheduledDateEventId",
	status = "status",
	suggestedScheduledDate = "suggestedScheduledDate",
	template = "template",
	type = "type",
	updated_at = "updated_at"
}
/** update columns of table "project" */
export const enum project_update_column {
	alias = "alias",
	audienceId = "audienceId",
	blogContent = "blogContent",
	blogTitle = "blogTitle",
	brandId = "brandId",
	campaignId = "campaignId",
	caption = "caption",
	created_at = "created_at",
	created_by = "created_by",
	creditPrice = "creditPrice",
	deleted = "deleted",
	featuredImageFileId = "featuredImageFileId",
	id = "id",
	previewImageFileId = "previewImageFileId",
	productId = "productId",
	publishDate = "publishDate",
	rawText = "rawText",
	scheduledDate = "scheduledDate",
	scheduledDateEventId = "scheduledDateEventId",
	status = "status",
	suggestedScheduledDate = "suggestedScheduledDate",
	template = "template",
	type = "type",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "task" */
export const enum task_constraint {
	task_pkey = "task_pkey"
}
/** select columns of table "task" */
export const enum task_select_column {
	category = "category",
	created_at = "created_at",
	created_by = "created_by",
	date = "date",
	dealId = "dealId",
	description = "description",
	finished = "finished",
	id = "id",
	title = "title",
	updated_at = "updated_at"
}
/** select "task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "task" */
export const enum task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns {
	finished = "finished"
}
/** select "task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "task" */
export const enum task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns {
	finished = "finished"
}
/** update columns of table "task" */
export const enum task_update_column {
	category = "category",
	created_at = "created_at",
	created_by = "created_by",
	date = "date",
	dealId = "dealId",
	description = "description",
	finished = "finished",
	id = "id",
	title = "title",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "textTemplateDisplayFormat" */
export const enum textTemplateDisplayFormat_constraint {
	textTemplateDisplayFormat_pkey = "textTemplateDisplayFormat_pkey"
}
/** select columns of table "textTemplateDisplayFormat" */
export const enum textTemplateDisplayFormat_select_column {
	displayFormatId = "displayFormatId",
	id = "id",
	textTemplateId = "textTemplateId"
}
/** update columns of table "textTemplateDisplayFormat" */
export const enum textTemplateDisplayFormat_update_column {
	displayFormatId = "displayFormatId",
	id = "id",
	textTemplateId = "textTemplateId"
}
/** unique or primary key constraints on table "userAppIntegration" */
export const enum userAppIntegration_constraint {
	userAppIntegration_pkey = "userAppIntegration_pkey"
}
/** select columns of table "userAppIntegration" */
export const enum userAppIntegration_select_column {
	alias = "alias",
	brandId = "brandId",
	created_at = "created_at",
	created_by = "created_by",
	deleted = "deleted",
	id = "id",
	integrationType = "integrationType",
	params = "params",
	updated_at = "updated_at"
}
/** update columns of table "userAppIntegration" */
export const enum userAppIntegration_update_column {
	alias = "alias",
	brandId = "brandId",
	created_at = "created_at",
	created_by = "created_by",
	deleted = "deleted",
	id = "id",
	integrationType = "integrationType",
	params = "params",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "userCreditUsageSummary" */
export const enum userCreditUsageSummary_constraint {
	userCreditUsageSummary_pkey = "userCreditUsageSummary_pkey"
}
/** select columns of table "userCreditUsageSummary" */
export const enum userCreditUsageSummary_select_column {
	created_at = "created_at",
	created_by = "created_by",
	freeCreditUsage = "freeCreditUsage",
	id = "id",
	originalVaue = "originalVaue",
	totalCharged = "totalCharged",
	updated_at = "updated_at",
	yearMonthDayReference = "yearMonthDayReference"
}
/** update columns of table "userCreditUsageSummary" */
export const enum userCreditUsageSummary_update_column {
	created_at = "created_at",
	created_by = "created_by",
	freeCreditUsage = "freeCreditUsage",
	id = "id",
	originalVaue = "originalVaue",
	totalCharged = "totalCharged",
	updated_at = "updated_at",
	yearMonthDayReference = "yearMonthDayReference"
}
/** unique or primary key constraints on table "userCreditUsage" */
export const enum userCreditUsage_constraint {
	userCreditUsage_pkey = "userCreditUsage_pkey"
}
/** select columns of table "userCreditUsage" */
export const enum userCreditUsage_select_column {
	GeneratedPromptByTemplateId = "GeneratedPromptByTemplateId",
	created_at = "created_at",
	created_by = "created_by",
	freeCreditUsed = "freeCreditUsed",
	id = "id",
	originalValue = "originalValue",
	totalCharged = "totalCharged"
}
/** update columns of table "userCreditUsage" */
export const enum userCreditUsage_update_column {
	GeneratedPromptByTemplateId = "GeneratedPromptByTemplateId",
	created_at = "created_at",
	created_by = "created_by",
	freeCreditUsed = "freeCreditUsed",
	id = "id",
	originalValue = "originalValue",
	totalCharged = "totalCharged"
}
/** unique or primary key constraints on table "userCredits" */
export const enum userCredits_constraint {
	userCredits_pkey = "userCredits_pkey"
}
/** select columns of table "userCredits" */
export const enum userCredits_select_column {
	created_at = "created_at",
	id = "id",
	quantity = "quantity",
	reason = "reason",
	userId = "userId"
}
/** update columns of table "userCredits" */
export const enum userCredits_update_column {
	created_at = "created_at",
	id = "id",
	quantity = "quantity",
	reason = "reason",
	userId = "userId"
}
/** unique or primary key constraints on table "userFeedback" */
export const enum userFeedback_constraint {
	userFeedback_pkey = "userFeedback_pkey"
}
/** select columns of table "userFeedback" */
export const enum userFeedback_select_column {
	createdBy = "createdBy",
	created_at = "created_at",
	feedbackType = "feedbackType",
	id = "id",
	message = "message",
	replied_at = "replied_at"
}
/** update columns of table "userFeedback" */
export const enum userFeedback_update_column {
	createdBy = "createdBy",
	created_at = "created_at",
	feedbackType = "feedbackType",
	id = "id",
	message = "message",
	replied_at = "replied_at"
}
/** unique or primary key constraints on table "userMedia" */
export const enum userMedia_constraint {
	uploadedImage_pkey = "uploadedImage_pkey"
}
/** select columns of table "userMedia" */
export const enum userMedia_select_column {
	aiParameters = "aiParameters",
	created_at = "created_at",
	deleted = "deleted",
	fileId = "fileId",
	id = "id",
	mediaType = "mediaType",
	ownerId = "ownerId",
	updated_at = "updated_at",
	videoFileId = "videoFileId"
}
/** update columns of table "userMedia" */
export const enum userMedia_update_column {
	aiParameters = "aiParameters",
	created_at = "created_at",
	deleted = "deleted",
	fileId = "fileId",
	id = "id",
	mediaType = "mediaType",
	ownerId = "ownerId",
	updated_at = "updated_at",
	videoFileId = "videoFileId"
}
/** unique or primary key constraints on table "userMetadata" */
export const enum userMetadata_constraint {
	userMetadata_pkey = "userMetadata_pkey"
}
/** select columns of table "userMetadata" */
export const enum userMetadata_select_column {
	created_at = "created_at",
	creditBalance = "creditBalance",
	id = "id",
	lastCreditRefill = "lastCreditRefill",
	last_seen = "last_seen",
	onboardCompleted = "onboardCompleted",
	stripeCustomerId = "stripeCustomerId",
	stripePriceId = "stripePriceId",
	stripeProductId = "stripeProductId",
	stripeSubscriptionId = "stripeSubscriptionId",
	stripeSubscriptionItem = "stripeSubscriptionItem",
	subscriptionType = "subscriptionType",
	updated_at = "updated_at"
}
/** select "userMetadata_aggregate_bool_exp_bool_and_arguments_columns" columns of table "userMetadata" */
export const enum userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns {
	onboardCompleted = "onboardCompleted"
}
/** select "userMetadata_aggregate_bool_exp_bool_or_arguments_columns" columns of table "userMetadata" */
export const enum userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns {
	onboardCompleted = "onboardCompleted"
}
/** update columns of table "userMetadata" */
export const enum userMetadata_update_column {
	created_at = "created_at",
	creditBalance = "creditBalance",
	id = "id",
	lastCreditRefill = "lastCreditRefill",
	last_seen = "last_seen",
	onboardCompleted = "onboardCompleted",
	stripeCustomerId = "stripeCustomerId",
	stripePriceId = "stripePriceId",
	stripeProductId = "stripeProductId",
	stripeSubscriptionId = "stripeSubscriptionId",
	stripeSubscriptionItem = "stripeSubscriptionItem",
	subscriptionType = "subscriptionType",
	updated_at = "updated_at"
}
/** unique or primary key constraints on table "userOnboarding" */
export const enum userOnboarding_constraint {
	userOnboarding_pkey = "userOnboarding_pkey"
}
/** select columns of table "userOnboarding" */
export const enum userOnboarding_select_column {
	behaviorProfile = "behaviorProfile",
	brandName = "brandName",
	clientPreference = "clientPreference",
	companySegment = "companySegment",
	currentStep = "currentStep",
	done = "done",
	expectation = "expectation",
	importantSocialNetworkForBusiness = "importantSocialNetworkForBusiness",
	investInPaidChannels = "investInPaidChannels",
	marketingObjective = "marketingObjective",
	name = "name",
	networkingOnCopyMagico = "networkingOnCopyMagico",
	phone = "phone",
	productOrService = "productOrService",
	purchaseBehavior = "purchaseBehavior",
	purchaseChallenges = "purchaseChallenges",
	purchaseInfluencers = "purchaseInfluencers",
	sellOnCopyMagico = "sellOnCopyMagico",
	siteUrl = "siteUrl",
	socioeconomicProfile = "socioeconomicProfile",
	staffCount = "staffCount",
	userId = "userId"
}
/** update columns of table "userOnboarding" */
export const enum userOnboarding_update_column {
	behaviorProfile = "behaviorProfile",
	brandName = "brandName",
	clientPreference = "clientPreference",
	companySegment = "companySegment",
	currentStep = "currentStep",
	done = "done",
	expectation = "expectation",
	importantSocialNetworkForBusiness = "importantSocialNetworkForBusiness",
	investInPaidChannels = "investInPaidChannels",
	marketingObjective = "marketingObjective",
	name = "name",
	networkingOnCopyMagico = "networkingOnCopyMagico",
	phone = "phone",
	productOrService = "productOrService",
	purchaseBehavior = "purchaseBehavior",
	purchaseChallenges = "purchaseChallenges",
	purchaseInfluencers = "purchaseInfluencers",
	sellOnCopyMagico = "sellOnCopyMagico",
	siteUrl = "siteUrl",
	socioeconomicProfile = "socioeconomicProfile",
	staffCount = "staffCount",
	userId = "userId"
}
/** unique or primary key constraints on table "userTemplateUsage" */
export const enum userTemplateUsage_constraint {
	userTemplateUsage_pkey = "userTemplateUsage_pkey"
}
/** select columns of table "userTemplateUsage" */
export const enum userTemplateUsage_select_column {
	created_at = "created_at",
	created_by = "created_by",
	id = "id",
	textPromptTemplateId = "textPromptTemplateId"
}
/** update columns of table "userTemplateUsage" */
export const enum userTemplateUsage_update_column {
	created_at = "created_at",
	created_by = "created_by",
	id = "id",
	textPromptTemplateId = "textPromptTemplateId"
}
/** unique or primary key constraints on table "auth.users" */
export const enum users_constraint {
	users_email_key = "users_email_key",
	users_phone_number_key = "users_phone_number_key",
	users_pkey = "users_pkey"
}
/** select columns of table "auth.users" */
export const enum users_select_column {
	activeMfaType = "activeMfaType",
	avatarUrl = "avatarUrl",
	createdAt = "createdAt",
	currentChallenge = "currentChallenge",
	defaultRole = "defaultRole",
	disabled = "disabled",
	displayName = "displayName",
	email = "email",
	emailVerified = "emailVerified",
	id = "id",
	isAnonymous = "isAnonymous",
	lastSeen = "lastSeen",
	locale = "locale",
	metadata = "metadata",
	newEmail = "newEmail",
	otpHash = "otpHash",
	otpHashExpiresAt = "otpHashExpiresAt",
	otpMethodLastUsed = "otpMethodLastUsed",
	passwordHash = "passwordHash",
	phoneNumber = "phoneNumber",
	phoneNumberVerified = "phoneNumberVerified",
	ticket = "ticket",
	ticketExpiresAt = "ticketExpiresAt",
	totpSecret = "totpSecret",
	updatedAt = "updatedAt"
}
/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
export const enum users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns {
	disabled = "disabled",
	emailVerified = "emailVerified",
	isAnonymous = "isAnonymous",
	phoneNumberVerified = "phoneNumberVerified"
}
/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
export const enum users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns {
	disabled = "disabled",
	emailVerified = "emailVerified",
	isAnonymous = "isAnonymous",
	phoneNumberVerified = "phoneNumberVerified"
}
/** update columns of table "auth.users" */
export const enum users_update_column {
	activeMfaType = "activeMfaType",
	avatarUrl = "avatarUrl",
	createdAt = "createdAt",
	currentChallenge = "currentChallenge",
	defaultRole = "defaultRole",
	disabled = "disabled",
	displayName = "displayName",
	email = "email",
	emailVerified = "emailVerified",
	id = "id",
	isAnonymous = "isAnonymous",
	lastSeen = "lastSeen",
	locale = "locale",
	metadata = "metadata",
	newEmail = "newEmail",
	otpHash = "otpHash",
	otpHashExpiresAt = "otpHashExpiresAt",
	otpMethodLastUsed = "otpMethodLastUsed",
	passwordHash = "passwordHash",
	phoneNumber = "phoneNumber",
	phoneNumberVerified = "phoneNumberVerified",
	ticket = "ticket",
	ticketExpiresAt = "ticketExpiresAt",
	totpSecret = "totpSecret",
	updatedAt = "updatedAt"
}
/** unique or primary key constraints on table "storage.virus" */
export const enum virus_constraint {
	virus_pkey = "virus_pkey"
}
/** select columns of table "storage.virus" */
export const enum virus_select_column {
	createdAt = "createdAt",
	fileId = "fileId",
	filename = "filename",
	id = "id",
	updatedAt = "updatedAt",
	userSession = "userSession",
	virus = "virus"
}
/** update columns of table "storage.virus" */
export const enum virus_update_column {
	createdAt = "createdAt",
	fileId = "fileId",
	filename = "filename",
	id = "id",
	updatedAt = "updatedAt",
	userSession = "userSession",
	virus = "virus"
}
/** unique or primary key constraints on table "website" */
export const enum website_constraint {
	website_domain_key = "website_domain_key",
	website_pkey = "website_pkey"
}
/** select columns of table "website" */
export const enum website_select_column {
	aboutUsContactBannerImageFileId = "aboutUsContactBannerImageFileId",
	aboutUsContactTitle = "aboutUsContactTitle",
	aboutUsHistoryBannerImageFileId = "aboutUsHistoryBannerImageFileId",
	aboutUsHistoryDescription = "aboutUsHistoryDescription",
	aboutUsHistoryTitle = "aboutUsHistoryTitle",
	aboutUsHistorytKeypointSubtitleOne = "aboutUsHistorytKeypointSubtitleOne",
	aboutUsHistorytKeypointSubtitleTwo = "aboutUsHistorytKeypointSubtitleTwo",
	aboutUsHistorytKeypointTitleOne = "aboutUsHistorytKeypointTitleOne",
	aboutUsHistorytKeypointTitleTwo = "aboutUsHistorytKeypointTitleTwo",
	aboutUsStatementBannerImageFileId = "aboutUsStatementBannerImageFileId",
	aboutUsStatementBannerText = "aboutUsStatementBannerText",
	aboutUsWeAreReadyBannerImageId = "aboutUsWeAreReadyBannerImageId",
	aboutUsWeAreReadyCustomerSatisfactionDescription = "aboutUsWeAreReadyCustomerSatisfactionDescription",
	aboutUsWeAreReadyInnovationDescription = "aboutUsWeAreReadyInnovationDescription",
	aboutUsWeAreReadySocialResponsibilityDescription = "aboutUsWeAreReadySocialResponsibilityDescription",
	aboutUsWeAreReadySpecializedSupportDescription = "aboutUsWeAreReadySpecializedSupportDescription",
	aboutUsWeAreReadySubjectMatterExpertiseDescription = "aboutUsWeAreReadySubjectMatterExpertiseDescription",
	aboutUsWeAreReadyTitle = "aboutUsWeAreReadyTitle",
	aboutUsWeAreReadyTransparencyDescription = "aboutUsWeAreReadyTransparencyDescription",
	aboutUsWhatMoveBannerImageFileId = "aboutUsWhatMoveBannerImageFileId",
	aboutUsWhatMoveUsMission = "aboutUsWhatMoveUsMission",
	aboutUsWhatMoveUsPurpose = "aboutUsWhatMoveUsPurpose",
	aboutUsWhatMoveUsTitle = "aboutUsWhatMoveUsTitle",
	aboutUsWhatMoveUsValues = "aboutUsWhatMoveUsValues",
	aboutUsWhatMoveUsVision = "aboutUsWhatMoveUsVision",
	accentColor = "accentColor",
	backgroundColor = "backgroundColor",
	brandId = "brandId",
	created_at = "created_at",
	domain = "domain",
	foregroundColor = "foregroundColor",
	googleAnalyticsKey = "googleAnalyticsKey",
	googleTagManagerKey = "googleTagManagerKey",
	homeBrandHighlightBannerImageFileId = "homeBrandHighlightBannerImageFileId",
	homeBrandHighlightDescription = "homeBrandHighlightDescription",
	homeBrandHighlightKeypointSubtitleOne = "homeBrandHighlightKeypointSubtitleOne",
	homeBrandHighlightKeypointSubtitleTwo = "homeBrandHighlightKeypointSubtitleTwo",
	homeBrandHighlightKeypointTitleOne = "homeBrandHighlightKeypointTitleOne",
	homeBrandHighlightKeypointTitleTwo = "homeBrandHighlightKeypointTitleTwo",
	homeBrandHighlightKeypointValuePropositionList = "homeBrandHighlightKeypointValuePropositionList",
	homeBrandHighlightTitle = "homeBrandHighlightTitle",
	homeHeroBannerImageFileId = "homeHeroBannerImageFileId",
	homeHeroCallToActionTargetUrl = "homeHeroCallToActionTargetUrl",
	homeHeroCallToActionText = "homeHeroCallToActionText",
	homeHeroDescription = "homeHeroDescription",
	homeHeroLabel = "homeHeroLabel",
	homeHeroTitle = "homeHeroTitle",
	homeOurDifferentiatorsBannerImageFileId = "homeOurDifferentiatorsBannerImageFileId",
	homeOurDifferentiatorsDescription = "homeOurDifferentiatorsDescription",
	homeOurDifferentiatorsKeypointSubtitleOne = "homeOurDifferentiatorsKeypointSubtitleOne",
	homeOurDifferentiatorsKeypointSubtitleTwo = "homeOurDifferentiatorsKeypointSubtitleTwo",
	homeOurDifferentiatorsKeypointTitleOne = "homeOurDifferentiatorsKeypointTitleOne",
	homeOurDifferentiatorsKeypointTitleTwo = "homeOurDifferentiatorsKeypointTitleTwo",
	homeOurDifferentiatorsTitle = "homeOurDifferentiatorsTitle",
	homeWhatWeDoCallToActionTargetUrl = "homeWhatWeDoCallToActionTargetUrl",
	homeWhatWeDoCallToActionText = "homeWhatWeDoCallToActionText",
	homeWhatWeDoCallToBannerOneImageFileId = "homeWhatWeDoCallToBannerOneImageFileId",
	homeWhatWeDoCallToBannerOneLegend = "homeWhatWeDoCallToBannerOneLegend",
	homeWhatWeDoCallToBannerThreeImageFileId = "homeWhatWeDoCallToBannerThreeImageFileId",
	homeWhatWeDoCallToBannerThreeLegend = "homeWhatWeDoCallToBannerThreeLegend",
	homeWhatWeDoCallToBannerTwoImageFileId = "homeWhatWeDoCallToBannerTwoImageFileId",
	homeWhatWeDoCallToBannerTwoLegend = "homeWhatWeDoCallToBannerTwoLegend",
	homeWhatWeDoDescription = "homeWhatWeDoDescription",
	homeWhatWeDoTitle = "homeWhatWeDoTitle",
	id = "id",
	openGraphDescription = "openGraphDescription",
	updated_at = "updated_at"
}
/** update columns of table "website" */
export const enum website_update_column {
	aboutUsContactBannerImageFileId = "aboutUsContactBannerImageFileId",
	aboutUsContactTitle = "aboutUsContactTitle",
	aboutUsHistoryBannerImageFileId = "aboutUsHistoryBannerImageFileId",
	aboutUsHistoryDescription = "aboutUsHistoryDescription",
	aboutUsHistoryTitle = "aboutUsHistoryTitle",
	aboutUsHistorytKeypointSubtitleOne = "aboutUsHistorytKeypointSubtitleOne",
	aboutUsHistorytKeypointSubtitleTwo = "aboutUsHistorytKeypointSubtitleTwo",
	aboutUsHistorytKeypointTitleOne = "aboutUsHistorytKeypointTitleOne",
	aboutUsHistorytKeypointTitleTwo = "aboutUsHistorytKeypointTitleTwo",
	aboutUsStatementBannerImageFileId = "aboutUsStatementBannerImageFileId",
	aboutUsStatementBannerText = "aboutUsStatementBannerText",
	aboutUsWeAreReadyBannerImageId = "aboutUsWeAreReadyBannerImageId",
	aboutUsWeAreReadyCustomerSatisfactionDescription = "aboutUsWeAreReadyCustomerSatisfactionDescription",
	aboutUsWeAreReadyInnovationDescription = "aboutUsWeAreReadyInnovationDescription",
	aboutUsWeAreReadySocialResponsibilityDescription = "aboutUsWeAreReadySocialResponsibilityDescription",
	aboutUsWeAreReadySpecializedSupportDescription = "aboutUsWeAreReadySpecializedSupportDescription",
	aboutUsWeAreReadySubjectMatterExpertiseDescription = "aboutUsWeAreReadySubjectMatterExpertiseDescription",
	aboutUsWeAreReadyTitle = "aboutUsWeAreReadyTitle",
	aboutUsWeAreReadyTransparencyDescription = "aboutUsWeAreReadyTransparencyDescription",
	aboutUsWhatMoveBannerImageFileId = "aboutUsWhatMoveBannerImageFileId",
	aboutUsWhatMoveUsMission = "aboutUsWhatMoveUsMission",
	aboutUsWhatMoveUsPurpose = "aboutUsWhatMoveUsPurpose",
	aboutUsWhatMoveUsTitle = "aboutUsWhatMoveUsTitle",
	aboutUsWhatMoveUsValues = "aboutUsWhatMoveUsValues",
	aboutUsWhatMoveUsVision = "aboutUsWhatMoveUsVision",
	accentColor = "accentColor",
	backgroundColor = "backgroundColor",
	brandId = "brandId",
	created_at = "created_at",
	domain = "domain",
	foregroundColor = "foregroundColor",
	googleAnalyticsKey = "googleAnalyticsKey",
	googleTagManagerKey = "googleTagManagerKey",
	homeBrandHighlightBannerImageFileId = "homeBrandHighlightBannerImageFileId",
	homeBrandHighlightDescription = "homeBrandHighlightDescription",
	homeBrandHighlightKeypointSubtitleOne = "homeBrandHighlightKeypointSubtitleOne",
	homeBrandHighlightKeypointSubtitleTwo = "homeBrandHighlightKeypointSubtitleTwo",
	homeBrandHighlightKeypointTitleOne = "homeBrandHighlightKeypointTitleOne",
	homeBrandHighlightKeypointTitleTwo = "homeBrandHighlightKeypointTitleTwo",
	homeBrandHighlightKeypointValuePropositionList = "homeBrandHighlightKeypointValuePropositionList",
	homeBrandHighlightTitle = "homeBrandHighlightTitle",
	homeHeroBannerImageFileId = "homeHeroBannerImageFileId",
	homeHeroCallToActionTargetUrl = "homeHeroCallToActionTargetUrl",
	homeHeroCallToActionText = "homeHeroCallToActionText",
	homeHeroDescription = "homeHeroDescription",
	homeHeroLabel = "homeHeroLabel",
	homeHeroTitle = "homeHeroTitle",
	homeOurDifferentiatorsBannerImageFileId = "homeOurDifferentiatorsBannerImageFileId",
	homeOurDifferentiatorsDescription = "homeOurDifferentiatorsDescription",
	homeOurDifferentiatorsKeypointSubtitleOne = "homeOurDifferentiatorsKeypointSubtitleOne",
	homeOurDifferentiatorsKeypointSubtitleTwo = "homeOurDifferentiatorsKeypointSubtitleTwo",
	homeOurDifferentiatorsKeypointTitleOne = "homeOurDifferentiatorsKeypointTitleOne",
	homeOurDifferentiatorsKeypointTitleTwo = "homeOurDifferentiatorsKeypointTitleTwo",
	homeOurDifferentiatorsTitle = "homeOurDifferentiatorsTitle",
	homeWhatWeDoCallToActionTargetUrl = "homeWhatWeDoCallToActionTargetUrl",
	homeWhatWeDoCallToActionText = "homeWhatWeDoCallToActionText",
	homeWhatWeDoCallToBannerOneImageFileId = "homeWhatWeDoCallToBannerOneImageFileId",
	homeWhatWeDoCallToBannerOneLegend = "homeWhatWeDoCallToBannerOneLegend",
	homeWhatWeDoCallToBannerThreeImageFileId = "homeWhatWeDoCallToBannerThreeImageFileId",
	homeWhatWeDoCallToBannerThreeLegend = "homeWhatWeDoCallToBannerThreeLegend",
	homeWhatWeDoCallToBannerTwoImageFileId = "homeWhatWeDoCallToBannerTwoImageFileId",
	homeWhatWeDoCallToBannerTwoLegend = "homeWhatWeDoCallToBannerTwoLegend",
	homeWhatWeDoDescription = "homeWhatWeDoDescription",
	homeWhatWeDoTitle = "homeWhatWeDoTitle",
	id = "id",
	openGraphDescription = "openGraphDescription",
	updated_at = "updated_at"
}

type ZEUS_VARIABLES = {
	["AppIntegration_append_input"]: ValueTypes["AppIntegration_append_input"];
	["AppIntegration_bool_exp"]: ValueTypes["AppIntegration_bool_exp"];
	["AppIntegration_constraint"]: ValueTypes["AppIntegration_constraint"];
	["AppIntegration_delete_at_path_input"]: ValueTypes["AppIntegration_delete_at_path_input"];
	["AppIntegration_delete_elem_input"]: ValueTypes["AppIntegration_delete_elem_input"];
	["AppIntegration_delete_key_input"]: ValueTypes["AppIntegration_delete_key_input"];
	["AppIntegration_insert_input"]: ValueTypes["AppIntegration_insert_input"];
	["AppIntegration_on_conflict"]: ValueTypes["AppIntegration_on_conflict"];
	["AppIntegration_order_by"]: ValueTypes["AppIntegration_order_by"];
	["AppIntegration_pk_columns_input"]: ValueTypes["AppIntegration_pk_columns_input"];
	["AppIntegration_prepend_input"]: ValueTypes["AppIntegration_prepend_input"];
	["AppIntegration_select_column"]: ValueTypes["AppIntegration_select_column"];
	["AppIntegration_set_input"]: ValueTypes["AppIntegration_set_input"];
	["AppIntegration_stream_cursor_input"]: ValueTypes["AppIntegration_stream_cursor_input"];
	["AppIntegration_stream_cursor_value_input"]: ValueTypes["AppIntegration_stream_cursor_value_input"];
	["AppIntegration_update_column"]: ValueTypes["AppIntegration_update_column"];
	["AppIntegration_updates"]: ValueTypes["AppIntegration_updates"];
	["Boolean_comparison_exp"]: ValueTypes["Boolean_comparison_exp"];
	["CourseCategory_bool_exp"]: ValueTypes["CourseCategory_bool_exp"];
	["CourseCategory_constraint"]: ValueTypes["CourseCategory_constraint"];
	["CourseCategory_inc_input"]: ValueTypes["CourseCategory_inc_input"];
	["CourseCategory_insert_input"]: ValueTypes["CourseCategory_insert_input"];
	["CourseCategory_on_conflict"]: ValueTypes["CourseCategory_on_conflict"];
	["CourseCategory_order_by"]: ValueTypes["CourseCategory_order_by"];
	["CourseCategory_pk_columns_input"]: ValueTypes["CourseCategory_pk_columns_input"];
	["CourseCategory_select_column"]: ValueTypes["CourseCategory_select_column"];
	["CourseCategory_set_input"]: ValueTypes["CourseCategory_set_input"];
	["CourseCategory_stream_cursor_input"]: ValueTypes["CourseCategory_stream_cursor_input"];
	["CourseCategory_stream_cursor_value_input"]: ValueTypes["CourseCategory_stream_cursor_value_input"];
	["CourseCategory_update_column"]: ValueTypes["CourseCategory_update_column"];
	["CourseCategory_updates"]: ValueTypes["CourseCategory_updates"];
	["Course_bool_exp"]: ValueTypes["Course_bool_exp"];
	["Course_constraint"]: ValueTypes["Course_constraint"];
	["Course_inc_input"]: ValueTypes["Course_inc_input"];
	["Course_insert_input"]: ValueTypes["Course_insert_input"];
	["Course_on_conflict"]: ValueTypes["Course_on_conflict"];
	["Course_order_by"]: ValueTypes["Course_order_by"];
	["Course_pk_columns_input"]: ValueTypes["Course_pk_columns_input"];
	["Course_select_column"]: ValueTypes["Course_select_column"];
	["Course_set_input"]: ValueTypes["Course_set_input"];
	["Course_stream_cursor_input"]: ValueTypes["Course_stream_cursor_input"];
	["Course_stream_cursor_value_input"]: ValueTypes["Course_stream_cursor_value_input"];
	["Course_update_column"]: ValueTypes["Course_update_column"];
	["Course_updates"]: ValueTypes["Course_updates"];
	["ExportToWordpressInput"]: ValueTypes["ExportToWordpressInput"];
	["GenerateFromTextTemplateInput"]: ValueTypes["GenerateFromTextTemplateInput"];
	["GenerateImageFromPromptInput"]: ValueTypes["GenerateImageFromPromptInput"];
	["GenerateNextMonthCalendarInput"]: ValueTypes["GenerateNextMonthCalendarInput"];
	["GeneratedPromptByTemplateImage_aggregate_bool_exp"]: ValueTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp"];
	["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"]: ValueTypes["GeneratedPromptByTemplateImage_aggregate_bool_exp_count"];
	["GeneratedPromptByTemplateImage_aggregate_order_by"]: ValueTypes["GeneratedPromptByTemplateImage_aggregate_order_by"];
	["GeneratedPromptByTemplateImage_arr_rel_insert_input"]: ValueTypes["GeneratedPromptByTemplateImage_arr_rel_insert_input"];
	["GeneratedPromptByTemplateImage_bool_exp"]: ValueTypes["GeneratedPromptByTemplateImage_bool_exp"];
	["GeneratedPromptByTemplateImage_constraint"]: ValueTypes["GeneratedPromptByTemplateImage_constraint"];
	["GeneratedPromptByTemplateImage_insert_input"]: ValueTypes["GeneratedPromptByTemplateImage_insert_input"];
	["GeneratedPromptByTemplateImage_max_order_by"]: ValueTypes["GeneratedPromptByTemplateImage_max_order_by"];
	["GeneratedPromptByTemplateImage_min_order_by"]: ValueTypes["GeneratedPromptByTemplateImage_min_order_by"];
	["GeneratedPromptByTemplateImage_on_conflict"]: ValueTypes["GeneratedPromptByTemplateImage_on_conflict"];
	["GeneratedPromptByTemplateImage_order_by"]: ValueTypes["GeneratedPromptByTemplateImage_order_by"];
	["GeneratedPromptByTemplateImage_pk_columns_input"]: ValueTypes["GeneratedPromptByTemplateImage_pk_columns_input"];
	["GeneratedPromptByTemplateImage_select_column"]: ValueTypes["GeneratedPromptByTemplateImage_select_column"];
	["GeneratedPromptByTemplateImage_set_input"]: ValueTypes["GeneratedPromptByTemplateImage_set_input"];
	["GeneratedPromptByTemplateImage_stream_cursor_input"]: ValueTypes["GeneratedPromptByTemplateImage_stream_cursor_input"];
	["GeneratedPromptByTemplateImage_stream_cursor_value_input"]: ValueTypes["GeneratedPromptByTemplateImage_stream_cursor_value_input"];
	["GeneratedPromptByTemplateImage_update_column"]: ValueTypes["GeneratedPromptByTemplateImage_update_column"];
	["GeneratedPromptByTemplateImage_updates"]: ValueTypes["GeneratedPromptByTemplateImage_updates"];
	["GeneratedPromptByTemplate_aggregate_bool_exp"]: ValueTypes["GeneratedPromptByTemplate_aggregate_bool_exp"];
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"]: ValueTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_and"];
	["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"]: ValueTypes["GeneratedPromptByTemplate_aggregate_bool_exp_bool_or"];
	["GeneratedPromptByTemplate_aggregate_bool_exp_count"]: ValueTypes["GeneratedPromptByTemplate_aggregate_bool_exp_count"];
	["GeneratedPromptByTemplate_aggregate_order_by"]: ValueTypes["GeneratedPromptByTemplate_aggregate_order_by"];
	["GeneratedPromptByTemplate_append_input"]: ValueTypes["GeneratedPromptByTemplate_append_input"];
	["GeneratedPromptByTemplate_arr_rel_insert_input"]: ValueTypes["GeneratedPromptByTemplate_arr_rel_insert_input"];
	["GeneratedPromptByTemplate_bool_exp"]: ValueTypes["GeneratedPromptByTemplate_bool_exp"];
	["GeneratedPromptByTemplate_constraint"]: ValueTypes["GeneratedPromptByTemplate_constraint"];
	["GeneratedPromptByTemplate_delete_at_path_input"]: ValueTypes["GeneratedPromptByTemplate_delete_at_path_input"];
	["GeneratedPromptByTemplate_delete_elem_input"]: ValueTypes["GeneratedPromptByTemplate_delete_elem_input"];
	["GeneratedPromptByTemplate_delete_key_input"]: ValueTypes["GeneratedPromptByTemplate_delete_key_input"];
	["GeneratedPromptByTemplate_insert_input"]: ValueTypes["GeneratedPromptByTemplate_insert_input"];
	["GeneratedPromptByTemplate_max_order_by"]: ValueTypes["GeneratedPromptByTemplate_max_order_by"];
	["GeneratedPromptByTemplate_min_order_by"]: ValueTypes["GeneratedPromptByTemplate_min_order_by"];
	["GeneratedPromptByTemplate_on_conflict"]: ValueTypes["GeneratedPromptByTemplate_on_conflict"];
	["GeneratedPromptByTemplate_order_by"]: ValueTypes["GeneratedPromptByTemplate_order_by"];
	["GeneratedPromptByTemplate_pk_columns_input"]: ValueTypes["GeneratedPromptByTemplate_pk_columns_input"];
	["GeneratedPromptByTemplate_prepend_input"]: ValueTypes["GeneratedPromptByTemplate_prepend_input"];
	["GeneratedPromptByTemplate_select_column"]: ValueTypes["GeneratedPromptByTemplate_select_column"];
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: ValueTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_and_arguments_columns"];
	["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: ValueTypes["GeneratedPromptByTemplate_select_column_GeneratedPromptByTemplate_aggregate_bool_exp_bool_or_arguments_columns"];
	["GeneratedPromptByTemplate_set_input"]: ValueTypes["GeneratedPromptByTemplate_set_input"];
	["GeneratedPromptByTemplate_stream_cursor_input"]: ValueTypes["GeneratedPromptByTemplate_stream_cursor_input"];
	["GeneratedPromptByTemplate_stream_cursor_value_input"]: ValueTypes["GeneratedPromptByTemplate_stream_cursor_value_input"];
	["GeneratedPromptByTemplate_update_column"]: ValueTypes["GeneratedPromptByTemplate_update_column"];
	["GeneratedPromptByTemplate_updates"]: ValueTypes["GeneratedPromptByTemplate_updates"];
	["GetPaymentUrlCommand"]: ValueTypes["GetPaymentUrlCommand"];
	["GetWordpressCategoriesInput"]: ValueTypes["GetWordpressCategoriesInput"];
	["GetWordpressTagsInput"]: ValueTypes["GetWordpressTagsInput"];
	["Int_comparison_exp"]: ValueTypes["Int_comparison_exp"];
	["SerpStatTestInput"]: ValueTypes["SerpStatTestInput"];
	["StringKeyValuePair"]: ValueTypes["StringKeyValuePair"];
	["String_array_comparison_exp"]: ValueTypes["String_array_comparison_exp"];
	["String_comparison_exp"]: ValueTypes["String_comparison_exp"];
	["TextGeneratorOptionEntry_aggregate_bool_exp"]: ValueTypes["TextGeneratorOptionEntry_aggregate_bool_exp"];
	["TextGeneratorOptionEntry_aggregate_bool_exp_count"]: ValueTypes["TextGeneratorOptionEntry_aggregate_bool_exp_count"];
	["TextGeneratorOptionEntry_aggregate_order_by"]: ValueTypes["TextGeneratorOptionEntry_aggregate_order_by"];
	["TextGeneratorOptionEntry_append_input"]: ValueTypes["TextGeneratorOptionEntry_append_input"];
	["TextGeneratorOptionEntry_arr_rel_insert_input"]: ValueTypes["TextGeneratorOptionEntry_arr_rel_insert_input"];
	["TextGeneratorOptionEntry_bool_exp"]: ValueTypes["TextGeneratorOptionEntry_bool_exp"];
	["TextGeneratorOptionEntry_constraint"]: ValueTypes["TextGeneratorOptionEntry_constraint"];
	["TextGeneratorOptionEntry_delete_at_path_input"]: ValueTypes["TextGeneratorOptionEntry_delete_at_path_input"];
	["TextGeneratorOptionEntry_delete_elem_input"]: ValueTypes["TextGeneratorOptionEntry_delete_elem_input"];
	["TextGeneratorOptionEntry_delete_key_input"]: ValueTypes["TextGeneratorOptionEntry_delete_key_input"];
	["TextGeneratorOptionEntry_insert_input"]: ValueTypes["TextGeneratorOptionEntry_insert_input"];
	["TextGeneratorOptionEntry_max_order_by"]: ValueTypes["TextGeneratorOptionEntry_max_order_by"];
	["TextGeneratorOptionEntry_min_order_by"]: ValueTypes["TextGeneratorOptionEntry_min_order_by"];
	["TextGeneratorOptionEntry_on_conflict"]: ValueTypes["TextGeneratorOptionEntry_on_conflict"];
	["TextGeneratorOptionEntry_order_by"]: ValueTypes["TextGeneratorOptionEntry_order_by"];
	["TextGeneratorOptionEntry_pk_columns_input"]: ValueTypes["TextGeneratorOptionEntry_pk_columns_input"];
	["TextGeneratorOptionEntry_prepend_input"]: ValueTypes["TextGeneratorOptionEntry_prepend_input"];
	["TextGeneratorOptionEntry_select_column"]: ValueTypes["TextGeneratorOptionEntry_select_column"];
	["TextGeneratorOptionEntry_set_input"]: ValueTypes["TextGeneratorOptionEntry_set_input"];
	["TextGeneratorOptionEntry_stream_cursor_input"]: ValueTypes["TextGeneratorOptionEntry_stream_cursor_input"];
	["TextGeneratorOptionEntry_stream_cursor_value_input"]: ValueTypes["TextGeneratorOptionEntry_stream_cursor_value_input"];
	["TextGeneratorOptionEntry_update_column"]: ValueTypes["TextGeneratorOptionEntry_update_column"];
	["TextGeneratorOptionEntry_updates"]: ValueTypes["TextGeneratorOptionEntry_updates"];
	["TextGeneratorOption_append_input"]: ValueTypes["TextGeneratorOption_append_input"];
	["TextGeneratorOption_bool_exp"]: ValueTypes["TextGeneratorOption_bool_exp"];
	["TextGeneratorOption_constraint"]: ValueTypes["TextGeneratorOption_constraint"];
	["TextGeneratorOption_delete_at_path_input"]: ValueTypes["TextGeneratorOption_delete_at_path_input"];
	["TextGeneratorOption_delete_elem_input"]: ValueTypes["TextGeneratorOption_delete_elem_input"];
	["TextGeneratorOption_delete_key_input"]: ValueTypes["TextGeneratorOption_delete_key_input"];
	["TextGeneratorOption_insert_input"]: ValueTypes["TextGeneratorOption_insert_input"];
	["TextGeneratorOption_obj_rel_insert_input"]: ValueTypes["TextGeneratorOption_obj_rel_insert_input"];
	["TextGeneratorOption_on_conflict"]: ValueTypes["TextGeneratorOption_on_conflict"];
	["TextGeneratorOption_order_by"]: ValueTypes["TextGeneratorOption_order_by"];
	["TextGeneratorOption_pk_columns_input"]: ValueTypes["TextGeneratorOption_pk_columns_input"];
	["TextGeneratorOption_prepend_input"]: ValueTypes["TextGeneratorOption_prepend_input"];
	["TextGeneratorOption_select_column"]: ValueTypes["TextGeneratorOption_select_column"];
	["TextGeneratorOption_set_input"]: ValueTypes["TextGeneratorOption_set_input"];
	["TextGeneratorOption_stream_cursor_input"]: ValueTypes["TextGeneratorOption_stream_cursor_input"];
	["TextGeneratorOption_stream_cursor_value_input"]: ValueTypes["TextGeneratorOption_stream_cursor_value_input"];
	["TextGeneratorOption_update_column"]: ValueTypes["TextGeneratorOption_update_column"];
	["TextGeneratorOption_updates"]: ValueTypes["TextGeneratorOption_updates"];
	["TextPromptTemplateCategory_bool_exp"]: ValueTypes["TextPromptTemplateCategory_bool_exp"];
	["TextPromptTemplateCategory_constraint"]: ValueTypes["TextPromptTemplateCategory_constraint"];
	["TextPromptTemplateCategory_insert_input"]: ValueTypes["TextPromptTemplateCategory_insert_input"];
	["TextPromptTemplateCategory_obj_rel_insert_input"]: ValueTypes["TextPromptTemplateCategory_obj_rel_insert_input"];
	["TextPromptTemplateCategory_on_conflict"]: ValueTypes["TextPromptTemplateCategory_on_conflict"];
	["TextPromptTemplateCategory_order_by"]: ValueTypes["TextPromptTemplateCategory_order_by"];
	["TextPromptTemplateCategory_pk_columns_input"]: ValueTypes["TextPromptTemplateCategory_pk_columns_input"];
	["TextPromptTemplateCategory_select_column"]: ValueTypes["TextPromptTemplateCategory_select_column"];
	["TextPromptTemplateCategory_set_input"]: ValueTypes["TextPromptTemplateCategory_set_input"];
	["TextPromptTemplateCategory_stream_cursor_input"]: ValueTypes["TextPromptTemplateCategory_stream_cursor_input"];
	["TextPromptTemplateCategory_stream_cursor_value_input"]: ValueTypes["TextPromptTemplateCategory_stream_cursor_value_input"];
	["TextPromptTemplateCategory_update_column"]: ValueTypes["TextPromptTemplateCategory_update_column"];
	["TextPromptTemplateCategory_updates"]: ValueTypes["TextPromptTemplateCategory_updates"];
	["TextPromptTemplate_aggregate_bool_exp"]: ValueTypes["TextPromptTemplate_aggregate_bool_exp"];
	["TextPromptTemplate_aggregate_bool_exp_bool_and"]: ValueTypes["TextPromptTemplate_aggregate_bool_exp_bool_and"];
	["TextPromptTemplate_aggregate_bool_exp_bool_or"]: ValueTypes["TextPromptTemplate_aggregate_bool_exp_bool_or"];
	["TextPromptTemplate_aggregate_bool_exp_count"]: ValueTypes["TextPromptTemplate_aggregate_bool_exp_count"];
	["TextPromptTemplate_aggregate_order_by"]: ValueTypes["TextPromptTemplate_aggregate_order_by"];
	["TextPromptTemplate_append_input"]: ValueTypes["TextPromptTemplate_append_input"];
	["TextPromptTemplate_arr_rel_insert_input"]: ValueTypes["TextPromptTemplate_arr_rel_insert_input"];
	["TextPromptTemplate_avg_order_by"]: ValueTypes["TextPromptTemplate_avg_order_by"];
	["TextPromptTemplate_bool_exp"]: ValueTypes["TextPromptTemplate_bool_exp"];
	["TextPromptTemplate_constraint"]: ValueTypes["TextPromptTemplate_constraint"];
	["TextPromptTemplate_delete_at_path_input"]: ValueTypes["TextPromptTemplate_delete_at_path_input"];
	["TextPromptTemplate_delete_elem_input"]: ValueTypes["TextPromptTemplate_delete_elem_input"];
	["TextPromptTemplate_delete_key_input"]: ValueTypes["TextPromptTemplate_delete_key_input"];
	["TextPromptTemplate_inc_input"]: ValueTypes["TextPromptTemplate_inc_input"];
	["TextPromptTemplate_insert_input"]: ValueTypes["TextPromptTemplate_insert_input"];
	["TextPromptTemplate_max_order_by"]: ValueTypes["TextPromptTemplate_max_order_by"];
	["TextPromptTemplate_min_order_by"]: ValueTypes["TextPromptTemplate_min_order_by"];
	["TextPromptTemplate_obj_rel_insert_input"]: ValueTypes["TextPromptTemplate_obj_rel_insert_input"];
	["TextPromptTemplate_on_conflict"]: ValueTypes["TextPromptTemplate_on_conflict"];
	["TextPromptTemplate_order_by"]: ValueTypes["TextPromptTemplate_order_by"];
	["TextPromptTemplate_pk_columns_input"]: ValueTypes["TextPromptTemplate_pk_columns_input"];
	["TextPromptTemplate_prepend_input"]: ValueTypes["TextPromptTemplate_prepend_input"];
	["TextPromptTemplate_select_column"]: ValueTypes["TextPromptTemplate_select_column"];
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"]: ValueTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_and_arguments_columns"];
	["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"]: ValueTypes["TextPromptTemplate_select_column_TextPromptTemplate_aggregate_bool_exp_bool_or_arguments_columns"];
	["TextPromptTemplate_set_input"]: ValueTypes["TextPromptTemplate_set_input"];
	["TextPromptTemplate_stddev_order_by"]: ValueTypes["TextPromptTemplate_stddev_order_by"];
	["TextPromptTemplate_stddev_pop_order_by"]: ValueTypes["TextPromptTemplate_stddev_pop_order_by"];
	["TextPromptTemplate_stddev_samp_order_by"]: ValueTypes["TextPromptTemplate_stddev_samp_order_by"];
	["TextPromptTemplate_stream_cursor_input"]: ValueTypes["TextPromptTemplate_stream_cursor_input"];
	["TextPromptTemplate_stream_cursor_value_input"]: ValueTypes["TextPromptTemplate_stream_cursor_value_input"];
	["TextPromptTemplate_sum_order_by"]: ValueTypes["TextPromptTemplate_sum_order_by"];
	["TextPromptTemplate_update_column"]: ValueTypes["TextPromptTemplate_update_column"];
	["TextPromptTemplate_updates"]: ValueTypes["TextPromptTemplate_updates"];
	["TextPromptTemplate_var_pop_order_by"]: ValueTypes["TextPromptTemplate_var_pop_order_by"];
	["TextPromptTemplate_var_samp_order_by"]: ValueTypes["TextPromptTemplate_var_samp_order_by"];
	["TextPromptTemplate_variance_order_by"]: ValueTypes["TextPromptTemplate_variance_order_by"];
	["TextTemplateClientCategory_bool_exp"]: ValueTypes["TextTemplateClientCategory_bool_exp"];
	["TextTemplateClientCategory_constraint"]: ValueTypes["TextTemplateClientCategory_constraint"];
	["TextTemplateClientCategory_insert_input"]: ValueTypes["TextTemplateClientCategory_insert_input"];
	["TextTemplateClientCategory_obj_rel_insert_input"]: ValueTypes["TextTemplateClientCategory_obj_rel_insert_input"];
	["TextTemplateClientCategory_on_conflict"]: ValueTypes["TextTemplateClientCategory_on_conflict"];
	["TextTemplateClientCategory_order_by"]: ValueTypes["TextTemplateClientCategory_order_by"];
	["TextTemplateClientCategory_pk_columns_input"]: ValueTypes["TextTemplateClientCategory_pk_columns_input"];
	["TextTemplateClientCategory_select_column"]: ValueTypes["TextTemplateClientCategory_select_column"];
	["TextTemplateClientCategory_set_input"]: ValueTypes["TextTemplateClientCategory_set_input"];
	["TextTemplateClientCategory_stream_cursor_input"]: ValueTypes["TextTemplateClientCategory_stream_cursor_input"];
	["TextTemplateClientCategory_stream_cursor_value_input"]: ValueTypes["TextTemplateClientCategory_stream_cursor_value_input"];
	["TextTemplateClientCategory_update_column"]: ValueTypes["TextTemplateClientCategory_update_column"];
	["TextTemplateClientCategory_updates"]: ValueTypes["TextTemplateClientCategory_updates"];
	["audiences_append_input"]: ValueTypes["audiences_append_input"];
	["audiences_bool_exp"]: ValueTypes["audiences_bool_exp"];
	["audiences_constraint"]: ValueTypes["audiences_constraint"];
	["audiences_delete_at_path_input"]: ValueTypes["audiences_delete_at_path_input"];
	["audiences_delete_elem_input"]: ValueTypes["audiences_delete_elem_input"];
	["audiences_delete_key_input"]: ValueTypes["audiences_delete_key_input"];
	["audiences_insert_input"]: ValueTypes["audiences_insert_input"];
	["audiences_on_conflict"]: ValueTypes["audiences_on_conflict"];
	["audiences_order_by"]: ValueTypes["audiences_order_by"];
	["audiences_pk_columns_input"]: ValueTypes["audiences_pk_columns_input"];
	["audiences_prepend_input"]: ValueTypes["audiences_prepend_input"];
	["audiences_select_column"]: ValueTypes["audiences_select_column"];
	["audiences_set_input"]: ValueTypes["audiences_set_input"];
	["audiences_stream_cursor_input"]: ValueTypes["audiences_stream_cursor_input"];
	["audiences_stream_cursor_value_input"]: ValueTypes["audiences_stream_cursor_value_input"];
	["audiences_update_column"]: ValueTypes["audiences_update_column"];
	["audiences_updates"]: ValueTypes["audiences_updates"];
	["authProviderRequests_append_input"]: ValueTypes["authProviderRequests_append_input"];
	["authProviderRequests_bool_exp"]: ValueTypes["authProviderRequests_bool_exp"];
	["authProviderRequests_constraint"]: ValueTypes["authProviderRequests_constraint"];
	["authProviderRequests_delete_at_path_input"]: ValueTypes["authProviderRequests_delete_at_path_input"];
	["authProviderRequests_delete_elem_input"]: ValueTypes["authProviderRequests_delete_elem_input"];
	["authProviderRequests_delete_key_input"]: ValueTypes["authProviderRequests_delete_key_input"];
	["authProviderRequests_insert_input"]: ValueTypes["authProviderRequests_insert_input"];
	["authProviderRequests_on_conflict"]: ValueTypes["authProviderRequests_on_conflict"];
	["authProviderRequests_order_by"]: ValueTypes["authProviderRequests_order_by"];
	["authProviderRequests_pk_columns_input"]: ValueTypes["authProviderRequests_pk_columns_input"];
	["authProviderRequests_prepend_input"]: ValueTypes["authProviderRequests_prepend_input"];
	["authProviderRequests_select_column"]: ValueTypes["authProviderRequests_select_column"];
	["authProviderRequests_set_input"]: ValueTypes["authProviderRequests_set_input"];
	["authProviderRequests_stream_cursor_input"]: ValueTypes["authProviderRequests_stream_cursor_input"];
	["authProviderRequests_stream_cursor_value_input"]: ValueTypes["authProviderRequests_stream_cursor_value_input"];
	["authProviderRequests_update_column"]: ValueTypes["authProviderRequests_update_column"];
	["authProviderRequests_updates"]: ValueTypes["authProviderRequests_updates"];
	["authProviders_bool_exp"]: ValueTypes["authProviders_bool_exp"];
	["authProviders_constraint"]: ValueTypes["authProviders_constraint"];
	["authProviders_insert_input"]: ValueTypes["authProviders_insert_input"];
	["authProviders_obj_rel_insert_input"]: ValueTypes["authProviders_obj_rel_insert_input"];
	["authProviders_on_conflict"]: ValueTypes["authProviders_on_conflict"];
	["authProviders_order_by"]: ValueTypes["authProviders_order_by"];
	["authProviders_pk_columns_input"]: ValueTypes["authProviders_pk_columns_input"];
	["authProviders_select_column"]: ValueTypes["authProviders_select_column"];
	["authProviders_set_input"]: ValueTypes["authProviders_set_input"];
	["authProviders_stream_cursor_input"]: ValueTypes["authProviders_stream_cursor_input"];
	["authProviders_stream_cursor_value_input"]: ValueTypes["authProviders_stream_cursor_value_input"];
	["authProviders_update_column"]: ValueTypes["authProviders_update_column"];
	["authProviders_updates"]: ValueTypes["authProviders_updates"];
	["authRefreshTokenTypes_bool_exp"]: ValueTypes["authRefreshTokenTypes_bool_exp"];
	["authRefreshTokenTypes_constraint"]: ValueTypes["authRefreshTokenTypes_constraint"];
	["authRefreshTokenTypes_enum"]: ValueTypes["authRefreshTokenTypes_enum"];
	["authRefreshTokenTypes_enum_comparison_exp"]: ValueTypes["authRefreshTokenTypes_enum_comparison_exp"];
	["authRefreshTokenTypes_insert_input"]: ValueTypes["authRefreshTokenTypes_insert_input"];
	["authRefreshTokenTypes_on_conflict"]: ValueTypes["authRefreshTokenTypes_on_conflict"];
	["authRefreshTokenTypes_order_by"]: ValueTypes["authRefreshTokenTypes_order_by"];
	["authRefreshTokenTypes_pk_columns_input"]: ValueTypes["authRefreshTokenTypes_pk_columns_input"];
	["authRefreshTokenTypes_select_column"]: ValueTypes["authRefreshTokenTypes_select_column"];
	["authRefreshTokenTypes_set_input"]: ValueTypes["authRefreshTokenTypes_set_input"];
	["authRefreshTokenTypes_stream_cursor_input"]: ValueTypes["authRefreshTokenTypes_stream_cursor_input"];
	["authRefreshTokenTypes_stream_cursor_value_input"]: ValueTypes["authRefreshTokenTypes_stream_cursor_value_input"];
	["authRefreshTokenTypes_update_column"]: ValueTypes["authRefreshTokenTypes_update_column"];
	["authRefreshTokenTypes_updates"]: ValueTypes["authRefreshTokenTypes_updates"];
	["authRefreshTokens_aggregate_bool_exp"]: ValueTypes["authRefreshTokens_aggregate_bool_exp"];
	["authRefreshTokens_aggregate_bool_exp_count"]: ValueTypes["authRefreshTokens_aggregate_bool_exp_count"];
	["authRefreshTokens_aggregate_order_by"]: ValueTypes["authRefreshTokens_aggregate_order_by"];
	["authRefreshTokens_append_input"]: ValueTypes["authRefreshTokens_append_input"];
	["authRefreshTokens_arr_rel_insert_input"]: ValueTypes["authRefreshTokens_arr_rel_insert_input"];
	["authRefreshTokens_bool_exp"]: ValueTypes["authRefreshTokens_bool_exp"];
	["authRefreshTokens_constraint"]: ValueTypes["authRefreshTokens_constraint"];
	["authRefreshTokens_delete_at_path_input"]: ValueTypes["authRefreshTokens_delete_at_path_input"];
	["authRefreshTokens_delete_elem_input"]: ValueTypes["authRefreshTokens_delete_elem_input"];
	["authRefreshTokens_delete_key_input"]: ValueTypes["authRefreshTokens_delete_key_input"];
	["authRefreshTokens_insert_input"]: ValueTypes["authRefreshTokens_insert_input"];
	["authRefreshTokens_max_order_by"]: ValueTypes["authRefreshTokens_max_order_by"];
	["authRefreshTokens_min_order_by"]: ValueTypes["authRefreshTokens_min_order_by"];
	["authRefreshTokens_on_conflict"]: ValueTypes["authRefreshTokens_on_conflict"];
	["authRefreshTokens_order_by"]: ValueTypes["authRefreshTokens_order_by"];
	["authRefreshTokens_pk_columns_input"]: ValueTypes["authRefreshTokens_pk_columns_input"];
	["authRefreshTokens_prepend_input"]: ValueTypes["authRefreshTokens_prepend_input"];
	["authRefreshTokens_select_column"]: ValueTypes["authRefreshTokens_select_column"];
	["authRefreshTokens_set_input"]: ValueTypes["authRefreshTokens_set_input"];
	["authRefreshTokens_stream_cursor_input"]: ValueTypes["authRefreshTokens_stream_cursor_input"];
	["authRefreshTokens_stream_cursor_value_input"]: ValueTypes["authRefreshTokens_stream_cursor_value_input"];
	["authRefreshTokens_update_column"]: ValueTypes["authRefreshTokens_update_column"];
	["authRefreshTokens_updates"]: ValueTypes["authRefreshTokens_updates"];
	["authRoles_bool_exp"]: ValueTypes["authRoles_bool_exp"];
	["authRoles_constraint"]: ValueTypes["authRoles_constraint"];
	["authRoles_insert_input"]: ValueTypes["authRoles_insert_input"];
	["authRoles_obj_rel_insert_input"]: ValueTypes["authRoles_obj_rel_insert_input"];
	["authRoles_on_conflict"]: ValueTypes["authRoles_on_conflict"];
	["authRoles_order_by"]: ValueTypes["authRoles_order_by"];
	["authRoles_pk_columns_input"]: ValueTypes["authRoles_pk_columns_input"];
	["authRoles_select_column"]: ValueTypes["authRoles_select_column"];
	["authRoles_set_input"]: ValueTypes["authRoles_set_input"];
	["authRoles_stream_cursor_input"]: ValueTypes["authRoles_stream_cursor_input"];
	["authRoles_stream_cursor_value_input"]: ValueTypes["authRoles_stream_cursor_value_input"];
	["authRoles_update_column"]: ValueTypes["authRoles_update_column"];
	["authRoles_updates"]: ValueTypes["authRoles_updates"];
	["authUserProviders_aggregate_bool_exp"]: ValueTypes["authUserProviders_aggregate_bool_exp"];
	["authUserProviders_aggregate_bool_exp_count"]: ValueTypes["authUserProviders_aggregate_bool_exp_count"];
	["authUserProviders_aggregate_order_by"]: ValueTypes["authUserProviders_aggregate_order_by"];
	["authUserProviders_arr_rel_insert_input"]: ValueTypes["authUserProviders_arr_rel_insert_input"];
	["authUserProviders_bool_exp"]: ValueTypes["authUserProviders_bool_exp"];
	["authUserProviders_constraint"]: ValueTypes["authUserProviders_constraint"];
	["authUserProviders_insert_input"]: ValueTypes["authUserProviders_insert_input"];
	["authUserProviders_max_order_by"]: ValueTypes["authUserProviders_max_order_by"];
	["authUserProviders_min_order_by"]: ValueTypes["authUserProviders_min_order_by"];
	["authUserProviders_on_conflict"]: ValueTypes["authUserProviders_on_conflict"];
	["authUserProviders_order_by"]: ValueTypes["authUserProviders_order_by"];
	["authUserProviders_pk_columns_input"]: ValueTypes["authUserProviders_pk_columns_input"];
	["authUserProviders_select_column"]: ValueTypes["authUserProviders_select_column"];
	["authUserProviders_set_input"]: ValueTypes["authUserProviders_set_input"];
	["authUserProviders_stream_cursor_input"]: ValueTypes["authUserProviders_stream_cursor_input"];
	["authUserProviders_stream_cursor_value_input"]: ValueTypes["authUserProviders_stream_cursor_value_input"];
	["authUserProviders_update_column"]: ValueTypes["authUserProviders_update_column"];
	["authUserProviders_updates"]: ValueTypes["authUserProviders_updates"];
	["authUserRoles_aggregate_bool_exp"]: ValueTypes["authUserRoles_aggregate_bool_exp"];
	["authUserRoles_aggregate_bool_exp_count"]: ValueTypes["authUserRoles_aggregate_bool_exp_count"];
	["authUserRoles_aggregate_order_by"]: ValueTypes["authUserRoles_aggregate_order_by"];
	["authUserRoles_arr_rel_insert_input"]: ValueTypes["authUserRoles_arr_rel_insert_input"];
	["authUserRoles_bool_exp"]: ValueTypes["authUserRoles_bool_exp"];
	["authUserRoles_constraint"]: ValueTypes["authUserRoles_constraint"];
	["authUserRoles_insert_input"]: ValueTypes["authUserRoles_insert_input"];
	["authUserRoles_max_order_by"]: ValueTypes["authUserRoles_max_order_by"];
	["authUserRoles_min_order_by"]: ValueTypes["authUserRoles_min_order_by"];
	["authUserRoles_on_conflict"]: ValueTypes["authUserRoles_on_conflict"];
	["authUserRoles_order_by"]: ValueTypes["authUserRoles_order_by"];
	["authUserRoles_pk_columns_input"]: ValueTypes["authUserRoles_pk_columns_input"];
	["authUserRoles_select_column"]: ValueTypes["authUserRoles_select_column"];
	["authUserRoles_set_input"]: ValueTypes["authUserRoles_set_input"];
	["authUserRoles_stream_cursor_input"]: ValueTypes["authUserRoles_stream_cursor_input"];
	["authUserRoles_stream_cursor_value_input"]: ValueTypes["authUserRoles_stream_cursor_value_input"];
	["authUserRoles_update_column"]: ValueTypes["authUserRoles_update_column"];
	["authUserRoles_updates"]: ValueTypes["authUserRoles_updates"];
	["authUserSecurityKeys_aggregate_bool_exp"]: ValueTypes["authUserSecurityKeys_aggregate_bool_exp"];
	["authUserSecurityKeys_aggregate_bool_exp_count"]: ValueTypes["authUserSecurityKeys_aggregate_bool_exp_count"];
	["authUserSecurityKeys_aggregate_order_by"]: ValueTypes["authUserSecurityKeys_aggregate_order_by"];
	["authUserSecurityKeys_arr_rel_insert_input"]: ValueTypes["authUserSecurityKeys_arr_rel_insert_input"];
	["authUserSecurityKeys_avg_order_by"]: ValueTypes["authUserSecurityKeys_avg_order_by"];
	["authUserSecurityKeys_bool_exp"]: ValueTypes["authUserSecurityKeys_bool_exp"];
	["authUserSecurityKeys_constraint"]: ValueTypes["authUserSecurityKeys_constraint"];
	["authUserSecurityKeys_inc_input"]: ValueTypes["authUserSecurityKeys_inc_input"];
	["authUserSecurityKeys_insert_input"]: ValueTypes["authUserSecurityKeys_insert_input"];
	["authUserSecurityKeys_max_order_by"]: ValueTypes["authUserSecurityKeys_max_order_by"];
	["authUserSecurityKeys_min_order_by"]: ValueTypes["authUserSecurityKeys_min_order_by"];
	["authUserSecurityKeys_on_conflict"]: ValueTypes["authUserSecurityKeys_on_conflict"];
	["authUserSecurityKeys_order_by"]: ValueTypes["authUserSecurityKeys_order_by"];
	["authUserSecurityKeys_pk_columns_input"]: ValueTypes["authUserSecurityKeys_pk_columns_input"];
	["authUserSecurityKeys_select_column"]: ValueTypes["authUserSecurityKeys_select_column"];
	["authUserSecurityKeys_set_input"]: ValueTypes["authUserSecurityKeys_set_input"];
	["authUserSecurityKeys_stddev_order_by"]: ValueTypes["authUserSecurityKeys_stddev_order_by"];
	["authUserSecurityKeys_stddev_pop_order_by"]: ValueTypes["authUserSecurityKeys_stddev_pop_order_by"];
	["authUserSecurityKeys_stddev_samp_order_by"]: ValueTypes["authUserSecurityKeys_stddev_samp_order_by"];
	["authUserSecurityKeys_stream_cursor_input"]: ValueTypes["authUserSecurityKeys_stream_cursor_input"];
	["authUserSecurityKeys_stream_cursor_value_input"]: ValueTypes["authUserSecurityKeys_stream_cursor_value_input"];
	["authUserSecurityKeys_sum_order_by"]: ValueTypes["authUserSecurityKeys_sum_order_by"];
	["authUserSecurityKeys_update_column"]: ValueTypes["authUserSecurityKeys_update_column"];
	["authUserSecurityKeys_updates"]: ValueTypes["authUserSecurityKeys_updates"];
	["authUserSecurityKeys_var_pop_order_by"]: ValueTypes["authUserSecurityKeys_var_pop_order_by"];
	["authUserSecurityKeys_var_samp_order_by"]: ValueTypes["authUserSecurityKeys_var_samp_order_by"];
	["authUserSecurityKeys_variance_order_by"]: ValueTypes["authUserSecurityKeys_variance_order_by"];
	["bigint"]: ValueTypes["bigint"];
	["bigint_comparison_exp"]: ValueTypes["bigint_comparison_exp"];
	["blogCategory_bool_exp"]: ValueTypes["blogCategory_bool_exp"];
	["blogCategory_constraint"]: ValueTypes["blogCategory_constraint"];
	["blogCategory_insert_input"]: ValueTypes["blogCategory_insert_input"];
	["blogCategory_obj_rel_insert_input"]: ValueTypes["blogCategory_obj_rel_insert_input"];
	["blogCategory_on_conflict"]: ValueTypes["blogCategory_on_conflict"];
	["blogCategory_order_by"]: ValueTypes["blogCategory_order_by"];
	["blogCategory_pk_columns_input"]: ValueTypes["blogCategory_pk_columns_input"];
	["blogCategory_select_column"]: ValueTypes["blogCategory_select_column"];
	["blogCategory_set_input"]: ValueTypes["blogCategory_set_input"];
	["blogCategory_stream_cursor_input"]: ValueTypes["blogCategory_stream_cursor_input"];
	["blogCategory_stream_cursor_value_input"]: ValueTypes["blogCategory_stream_cursor_value_input"];
	["blogCategory_update_column"]: ValueTypes["blogCategory_update_column"];
	["blogCategory_updates"]: ValueTypes["blogCategory_updates"];
	["blog_aggregate_bool_exp"]: ValueTypes["blog_aggregate_bool_exp"];
	["blog_aggregate_bool_exp_count"]: ValueTypes["blog_aggregate_bool_exp_count"];
	["blog_aggregate_order_by"]: ValueTypes["blog_aggregate_order_by"];
	["blog_arr_rel_insert_input"]: ValueTypes["blog_arr_rel_insert_input"];
	["blog_bool_exp"]: ValueTypes["blog_bool_exp"];
	["blog_constraint"]: ValueTypes["blog_constraint"];
	["blog_insert_input"]: ValueTypes["blog_insert_input"];
	["blog_max_order_by"]: ValueTypes["blog_max_order_by"];
	["blog_min_order_by"]: ValueTypes["blog_min_order_by"];
	["blog_on_conflict"]: ValueTypes["blog_on_conflict"];
	["blog_order_by"]: ValueTypes["blog_order_by"];
	["blog_pk_columns_input"]: ValueTypes["blog_pk_columns_input"];
	["blog_select_column"]: ValueTypes["blog_select_column"];
	["blog_set_input"]: ValueTypes["blog_set_input"];
	["blog_stream_cursor_input"]: ValueTypes["blog_stream_cursor_input"];
	["blog_stream_cursor_value_input"]: ValueTypes["blog_stream_cursor_value_input"];
	["blog_update_column"]: ValueTypes["blog_update_column"];
	["blog_updates"]: ValueTypes["blog_updates"];
	["brands_append_input"]: ValueTypes["brands_append_input"];
	["brands_bool_exp"]: ValueTypes["brands_bool_exp"];
	["brands_constraint"]: ValueTypes["brands_constraint"];
	["brands_delete_at_path_input"]: ValueTypes["brands_delete_at_path_input"];
	["brands_delete_elem_input"]: ValueTypes["brands_delete_elem_input"];
	["brands_delete_key_input"]: ValueTypes["brands_delete_key_input"];
	["brands_inc_input"]: ValueTypes["brands_inc_input"];
	["brands_insert_input"]: ValueTypes["brands_insert_input"];
	["brands_obj_rel_insert_input"]: ValueTypes["brands_obj_rel_insert_input"];
	["brands_on_conflict"]: ValueTypes["brands_on_conflict"];
	["brands_order_by"]: ValueTypes["brands_order_by"];
	["brands_pk_columns_input"]: ValueTypes["brands_pk_columns_input"];
	["brands_prepend_input"]: ValueTypes["brands_prepend_input"];
	["brands_select_column"]: ValueTypes["brands_select_column"];
	["brands_set_input"]: ValueTypes["brands_set_input"];
	["brands_stream_cursor_input"]: ValueTypes["brands_stream_cursor_input"];
	["brands_stream_cursor_value_input"]: ValueTypes["brands_stream_cursor_value_input"];
	["brands_update_column"]: ValueTypes["brands_update_column"];
	["brands_updates"]: ValueTypes["brands_updates"];
	["buckets_bool_exp"]: ValueTypes["buckets_bool_exp"];
	["buckets_constraint"]: ValueTypes["buckets_constraint"];
	["buckets_inc_input"]: ValueTypes["buckets_inc_input"];
	["buckets_insert_input"]: ValueTypes["buckets_insert_input"];
	["buckets_obj_rel_insert_input"]: ValueTypes["buckets_obj_rel_insert_input"];
	["buckets_on_conflict"]: ValueTypes["buckets_on_conflict"];
	["buckets_order_by"]: ValueTypes["buckets_order_by"];
	["buckets_pk_columns_input"]: ValueTypes["buckets_pk_columns_input"];
	["buckets_select_column"]: ValueTypes["buckets_select_column"];
	["buckets_set_input"]: ValueTypes["buckets_set_input"];
	["buckets_stream_cursor_input"]: ValueTypes["buckets_stream_cursor_input"];
	["buckets_stream_cursor_value_input"]: ValueTypes["buckets_stream_cursor_value_input"];
	["buckets_update_column"]: ValueTypes["buckets_update_column"];
	["buckets_updates"]: ValueTypes["buckets_updates"];
	["bytea"]: ValueTypes["bytea"];
	["bytea_comparison_exp"]: ValueTypes["bytea_comparison_exp"];
	["campaign_append_input"]: ValueTypes["campaign_append_input"];
	["campaign_bool_exp"]: ValueTypes["campaign_bool_exp"];
	["campaign_constraint"]: ValueTypes["campaign_constraint"];
	["campaign_delete_at_path_input"]: ValueTypes["campaign_delete_at_path_input"];
	["campaign_delete_elem_input"]: ValueTypes["campaign_delete_elem_input"];
	["campaign_delete_key_input"]: ValueTypes["campaign_delete_key_input"];
	["campaign_insert_input"]: ValueTypes["campaign_insert_input"];
	["campaign_on_conflict"]: ValueTypes["campaign_on_conflict"];
	["campaign_order_by"]: ValueTypes["campaign_order_by"];
	["campaign_pk_columns_input"]: ValueTypes["campaign_pk_columns_input"];
	["campaign_prepend_input"]: ValueTypes["campaign_prepend_input"];
	["campaign_select_column"]: ValueTypes["campaign_select_column"];
	["campaign_set_input"]: ValueTypes["campaign_set_input"];
	["campaign_stream_cursor_input"]: ValueTypes["campaign_stream_cursor_input"];
	["campaign_stream_cursor_value_input"]: ValueTypes["campaign_stream_cursor_value_input"];
	["campaign_update_column"]: ValueTypes["campaign_update_column"];
	["campaign_updates"]: ValueTypes["campaign_updates"];
	["categorysummaryview_bool_exp"]: ValueTypes["categorysummaryview_bool_exp"];
	["categorysummaryview_order_by"]: ValueTypes["categorysummaryview_order_by"];
	["categorysummaryview_select_column"]: ValueTypes["categorysummaryview_select_column"];
	["categorysummaryview_stream_cursor_input"]: ValueTypes["categorysummaryview_stream_cursor_input"];
	["categorysummaryview_stream_cursor_value_input"]: ValueTypes["categorysummaryview_stream_cursor_value_input"];
	["channels_bool_exp"]: ValueTypes["channels_bool_exp"];
	["channels_constraint"]: ValueTypes["channels_constraint"];
	["channels_insert_input"]: ValueTypes["channels_insert_input"];
	["channels_on_conflict"]: ValueTypes["channels_on_conflict"];
	["channels_order_by"]: ValueTypes["channels_order_by"];
	["channels_pk_columns_input"]: ValueTypes["channels_pk_columns_input"];
	["channels_select_column"]: ValueTypes["channels_select_column"];
	["channels_set_input"]: ValueTypes["channels_set_input"];
	["channels_stream_cursor_input"]: ValueTypes["channels_stream_cursor_input"];
	["channels_stream_cursor_value_input"]: ValueTypes["channels_stream_cursor_value_input"];
	["channels_update_column"]: ValueTypes["channels_update_column"];
	["channels_updates"]: ValueTypes["channels_updates"];
	["citext"]: ValueTypes["citext"];
	["citext_comparison_exp"]: ValueTypes["citext_comparison_exp"];
	["consumptionByChannelView_bool_exp"]: ValueTypes["consumptionByChannelView_bool_exp"];
	["consumptionByChannelView_order_by"]: ValueTypes["consumptionByChannelView_order_by"];
	["consumptionByChannelView_select_column"]: ValueTypes["consumptionByChannelView_select_column"];
	["consumptionByChannelView_stream_cursor_input"]: ValueTypes["consumptionByChannelView_stream_cursor_input"];
	["consumptionByChannelView_stream_cursor_value_input"]: ValueTypes["consumptionByChannelView_stream_cursor_value_input"];
	["costTable_bool_exp"]: ValueTypes["costTable_bool_exp"];
	["costTable_constraint"]: ValueTypes["costTable_constraint"];
	["costTable_inc_input"]: ValueTypes["costTable_inc_input"];
	["costTable_insert_input"]: ValueTypes["costTable_insert_input"];
	["costTable_on_conflict"]: ValueTypes["costTable_on_conflict"];
	["costTable_order_by"]: ValueTypes["costTable_order_by"];
	["costTable_pk_columns_input"]: ValueTypes["costTable_pk_columns_input"];
	["costTable_select_column"]: ValueTypes["costTable_select_column"];
	["costTable_set_input"]: ValueTypes["costTable_set_input"];
	["costTable_stream_cursor_input"]: ValueTypes["costTable_stream_cursor_input"];
	["costTable_stream_cursor_value_input"]: ValueTypes["costTable_stream_cursor_value_input"];
	["costTable_update_column"]: ValueTypes["costTable_update_column"];
	["costTable_updates"]: ValueTypes["costTable_updates"];
	["cursor_ordering"]: ValueTypes["cursor_ordering"];
	["date"]: ValueTypes["date"];
	["date_comparison_exp"]: ValueTypes["date_comparison_exp"];
	["dealStatus_bool_exp"]: ValueTypes["dealStatus_bool_exp"];
	["dealStatus_constraint"]: ValueTypes["dealStatus_constraint"];
	["dealStatus_inc_input"]: ValueTypes["dealStatus_inc_input"];
	["dealStatus_insert_input"]: ValueTypes["dealStatus_insert_input"];
	["dealStatus_obj_rel_insert_input"]: ValueTypes["dealStatus_obj_rel_insert_input"];
	["dealStatus_on_conflict"]: ValueTypes["dealStatus_on_conflict"];
	["dealStatus_order_by"]: ValueTypes["dealStatus_order_by"];
	["dealStatus_pk_columns_input"]: ValueTypes["dealStatus_pk_columns_input"];
	["dealStatus_select_column"]: ValueTypes["dealStatus_select_column"];
	["dealStatus_set_input"]: ValueTypes["dealStatus_set_input"];
	["dealStatus_stream_cursor_input"]: ValueTypes["dealStatus_stream_cursor_input"];
	["dealStatus_stream_cursor_value_input"]: ValueTypes["dealStatus_stream_cursor_value_input"];
	["dealStatus_update_column"]: ValueTypes["dealStatus_update_column"];
	["dealStatus_updates"]: ValueTypes["dealStatus_updates"];
	["deal_aggregate_bool_exp"]: ValueTypes["deal_aggregate_bool_exp"];
	["deal_aggregate_bool_exp_count"]: ValueTypes["deal_aggregate_bool_exp_count"];
	["deal_aggregate_order_by"]: ValueTypes["deal_aggregate_order_by"];
	["deal_arr_rel_insert_input"]: ValueTypes["deal_arr_rel_insert_input"];
	["deal_avg_order_by"]: ValueTypes["deal_avg_order_by"];
	["deal_bool_exp"]: ValueTypes["deal_bool_exp"];
	["deal_constraint"]: ValueTypes["deal_constraint"];
	["deal_inc_input"]: ValueTypes["deal_inc_input"];
	["deal_insert_input"]: ValueTypes["deal_insert_input"];
	["deal_max_order_by"]: ValueTypes["deal_max_order_by"];
	["deal_min_order_by"]: ValueTypes["deal_min_order_by"];
	["deal_obj_rel_insert_input"]: ValueTypes["deal_obj_rel_insert_input"];
	["deal_on_conflict"]: ValueTypes["deal_on_conflict"];
	["deal_order_by"]: ValueTypes["deal_order_by"];
	["deal_pk_columns_input"]: ValueTypes["deal_pk_columns_input"];
	["deal_select_column"]: ValueTypes["deal_select_column"];
	["deal_set_input"]: ValueTypes["deal_set_input"];
	["deal_stddev_order_by"]: ValueTypes["deal_stddev_order_by"];
	["deal_stddev_pop_order_by"]: ValueTypes["deal_stddev_pop_order_by"];
	["deal_stddev_samp_order_by"]: ValueTypes["deal_stddev_samp_order_by"];
	["deal_stream_cursor_input"]: ValueTypes["deal_stream_cursor_input"];
	["deal_stream_cursor_value_input"]: ValueTypes["deal_stream_cursor_value_input"];
	["deal_sum_order_by"]: ValueTypes["deal_sum_order_by"];
	["deal_update_column"]: ValueTypes["deal_update_column"];
	["deal_updates"]: ValueTypes["deal_updates"];
	["deal_var_pop_order_by"]: ValueTypes["deal_var_pop_order_by"];
	["deal_var_samp_order_by"]: ValueTypes["deal_var_samp_order_by"];
	["deal_variance_order_by"]: ValueTypes["deal_variance_order_by"];
	["displayCategory_append_input"]: ValueTypes["displayCategory_append_input"];
	["displayCategory_bool_exp"]: ValueTypes["displayCategory_bool_exp"];
	["displayCategory_constraint"]: ValueTypes["displayCategory_constraint"];
	["displayCategory_delete_at_path_input"]: ValueTypes["displayCategory_delete_at_path_input"];
	["displayCategory_delete_elem_input"]: ValueTypes["displayCategory_delete_elem_input"];
	["displayCategory_delete_key_input"]: ValueTypes["displayCategory_delete_key_input"];
	["displayCategory_insert_input"]: ValueTypes["displayCategory_insert_input"];
	["displayCategory_on_conflict"]: ValueTypes["displayCategory_on_conflict"];
	["displayCategory_order_by"]: ValueTypes["displayCategory_order_by"];
	["displayCategory_pk_columns_input"]: ValueTypes["displayCategory_pk_columns_input"];
	["displayCategory_prepend_input"]: ValueTypes["displayCategory_prepend_input"];
	["displayCategory_select_column"]: ValueTypes["displayCategory_select_column"];
	["displayCategory_set_input"]: ValueTypes["displayCategory_set_input"];
	["displayCategory_stream_cursor_input"]: ValueTypes["displayCategory_stream_cursor_input"];
	["displayCategory_stream_cursor_value_input"]: ValueTypes["displayCategory_stream_cursor_value_input"];
	["displayCategory_update_column"]: ValueTypes["displayCategory_update_column"];
	["displayCategory_updates"]: ValueTypes["displayCategory_updates"];
	["displayDimension_append_input"]: ValueTypes["displayDimension_append_input"];
	["displayDimension_bool_exp"]: ValueTypes["displayDimension_bool_exp"];
	["displayDimension_constraint"]: ValueTypes["displayDimension_constraint"];
	["displayDimension_delete_at_path_input"]: ValueTypes["displayDimension_delete_at_path_input"];
	["displayDimension_delete_elem_input"]: ValueTypes["displayDimension_delete_elem_input"];
	["displayDimension_delete_key_input"]: ValueTypes["displayDimension_delete_key_input"];
	["displayDimension_insert_input"]: ValueTypes["displayDimension_insert_input"];
	["displayDimension_obj_rel_insert_input"]: ValueTypes["displayDimension_obj_rel_insert_input"];
	["displayDimension_on_conflict"]: ValueTypes["displayDimension_on_conflict"];
	["displayDimension_order_by"]: ValueTypes["displayDimension_order_by"];
	["displayDimension_pk_columns_input"]: ValueTypes["displayDimension_pk_columns_input"];
	["displayDimension_prepend_input"]: ValueTypes["displayDimension_prepend_input"];
	["displayDimension_select_column"]: ValueTypes["displayDimension_select_column"];
	["displayDimension_set_input"]: ValueTypes["displayDimension_set_input"];
	["displayDimension_stream_cursor_input"]: ValueTypes["displayDimension_stream_cursor_input"];
	["displayDimension_stream_cursor_value_input"]: ValueTypes["displayDimension_stream_cursor_value_input"];
	["displayDimension_update_column"]: ValueTypes["displayDimension_update_column"];
	["displayDimension_updates"]: ValueTypes["displayDimension_updates"];
	["displayFormats_append_input"]: ValueTypes["displayFormats_append_input"];
	["displayFormats_bool_exp"]: ValueTypes["displayFormats_bool_exp"];
	["displayFormats_constraint"]: ValueTypes["displayFormats_constraint"];
	["displayFormats_delete_at_path_input"]: ValueTypes["displayFormats_delete_at_path_input"];
	["displayFormats_delete_elem_input"]: ValueTypes["displayFormats_delete_elem_input"];
	["displayFormats_delete_key_input"]: ValueTypes["displayFormats_delete_key_input"];
	["displayFormats_insert_input"]: ValueTypes["displayFormats_insert_input"];
	["displayFormats_obj_rel_insert_input"]: ValueTypes["displayFormats_obj_rel_insert_input"];
	["displayFormats_on_conflict"]: ValueTypes["displayFormats_on_conflict"];
	["displayFormats_order_by"]: ValueTypes["displayFormats_order_by"];
	["displayFormats_pk_columns_input"]: ValueTypes["displayFormats_pk_columns_input"];
	["displayFormats_prepend_input"]: ValueTypes["displayFormats_prepend_input"];
	["displayFormats_select_column"]: ValueTypes["displayFormats_select_column"];
	["displayFormats_set_input"]: ValueTypes["displayFormats_set_input"];
	["displayFormats_stream_cursor_input"]: ValueTypes["displayFormats_stream_cursor_input"];
	["displayFormats_stream_cursor_value_input"]: ValueTypes["displayFormats_stream_cursor_value_input"];
	["displayFormats_update_column"]: ValueTypes["displayFormats_update_column"];
	["displayFormats_updates"]: ValueTypes["displayFormats_updates"];
	["displayTemplate_append_input"]: ValueTypes["displayTemplate_append_input"];
	["displayTemplate_bool_exp"]: ValueTypes["displayTemplate_bool_exp"];
	["displayTemplate_constraint"]: ValueTypes["displayTemplate_constraint"];
	["displayTemplate_delete_at_path_input"]: ValueTypes["displayTemplate_delete_at_path_input"];
	["displayTemplate_delete_elem_input"]: ValueTypes["displayTemplate_delete_elem_input"];
	["displayTemplate_delete_key_input"]: ValueTypes["displayTemplate_delete_key_input"];
	["displayTemplate_insert_input"]: ValueTypes["displayTemplate_insert_input"];
	["displayTemplate_on_conflict"]: ValueTypes["displayTemplate_on_conflict"];
	["displayTemplate_order_by"]: ValueTypes["displayTemplate_order_by"];
	["displayTemplate_pk_columns_input"]: ValueTypes["displayTemplate_pk_columns_input"];
	["displayTemplate_prepend_input"]: ValueTypes["displayTemplate_prepend_input"];
	["displayTemplate_select_column"]: ValueTypes["displayTemplate_select_column"];
	["displayTemplate_set_input"]: ValueTypes["displayTemplate_set_input"];
	["displayTemplate_stream_cursor_input"]: ValueTypes["displayTemplate_stream_cursor_input"];
	["displayTemplate_stream_cursor_value_input"]: ValueTypes["displayTemplate_stream_cursor_value_input"];
	["displayTemplate_update_column"]: ValueTypes["displayTemplate_update_column"];
	["displayTemplate_updates"]: ValueTypes["displayTemplate_updates"];
	["files_aggregate_bool_exp"]: ValueTypes["files_aggregate_bool_exp"];
	["files_aggregate_bool_exp_bool_and"]: ValueTypes["files_aggregate_bool_exp_bool_and"];
	["files_aggregate_bool_exp_bool_or"]: ValueTypes["files_aggregate_bool_exp_bool_or"];
	["files_aggregate_bool_exp_count"]: ValueTypes["files_aggregate_bool_exp_count"];
	["files_aggregate_order_by"]: ValueTypes["files_aggregate_order_by"];
	["files_append_input"]: ValueTypes["files_append_input"];
	["files_arr_rel_insert_input"]: ValueTypes["files_arr_rel_insert_input"];
	["files_avg_order_by"]: ValueTypes["files_avg_order_by"];
	["files_bool_exp"]: ValueTypes["files_bool_exp"];
	["files_constraint"]: ValueTypes["files_constraint"];
	["files_delete_at_path_input"]: ValueTypes["files_delete_at_path_input"];
	["files_delete_elem_input"]: ValueTypes["files_delete_elem_input"];
	["files_delete_key_input"]: ValueTypes["files_delete_key_input"];
	["files_inc_input"]: ValueTypes["files_inc_input"];
	["files_insert_input"]: ValueTypes["files_insert_input"];
	["files_max_order_by"]: ValueTypes["files_max_order_by"];
	["files_min_order_by"]: ValueTypes["files_min_order_by"];
	["files_obj_rel_insert_input"]: ValueTypes["files_obj_rel_insert_input"];
	["files_on_conflict"]: ValueTypes["files_on_conflict"];
	["files_order_by"]: ValueTypes["files_order_by"];
	["files_pk_columns_input"]: ValueTypes["files_pk_columns_input"];
	["files_prepend_input"]: ValueTypes["files_prepend_input"];
	["files_select_column"]: ValueTypes["files_select_column"];
	["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"]: ValueTypes["files_select_column_files_aggregate_bool_exp_bool_and_arguments_columns"];
	["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"]: ValueTypes["files_select_column_files_aggregate_bool_exp_bool_or_arguments_columns"];
	["files_set_input"]: ValueTypes["files_set_input"];
	["files_stddev_order_by"]: ValueTypes["files_stddev_order_by"];
	["files_stddev_pop_order_by"]: ValueTypes["files_stddev_pop_order_by"];
	["files_stddev_samp_order_by"]: ValueTypes["files_stddev_samp_order_by"];
	["files_stream_cursor_input"]: ValueTypes["files_stream_cursor_input"];
	["files_stream_cursor_value_input"]: ValueTypes["files_stream_cursor_value_input"];
	["files_sum_order_by"]: ValueTypes["files_sum_order_by"];
	["files_update_column"]: ValueTypes["files_update_column"];
	["files_updates"]: ValueTypes["files_updates"];
	["files_var_pop_order_by"]: ValueTypes["files_var_pop_order_by"];
	["files_var_samp_order_by"]: ValueTypes["files_var_samp_order_by"];
	["files_variance_order_by"]: ValueTypes["files_variance_order_by"];
	["interaction_aggregate_bool_exp"]: ValueTypes["interaction_aggregate_bool_exp"];
	["interaction_aggregate_bool_exp_bool_and"]: ValueTypes["interaction_aggregate_bool_exp_bool_and"];
	["interaction_aggregate_bool_exp_bool_or"]: ValueTypes["interaction_aggregate_bool_exp_bool_or"];
	["interaction_aggregate_bool_exp_count"]: ValueTypes["interaction_aggregate_bool_exp_count"];
	["interaction_aggregate_order_by"]: ValueTypes["interaction_aggregate_order_by"];
	["interaction_arr_rel_insert_input"]: ValueTypes["interaction_arr_rel_insert_input"];
	["interaction_bool_exp"]: ValueTypes["interaction_bool_exp"];
	["interaction_constraint"]: ValueTypes["interaction_constraint"];
	["interaction_insert_input"]: ValueTypes["interaction_insert_input"];
	["interaction_max_order_by"]: ValueTypes["interaction_max_order_by"];
	["interaction_min_order_by"]: ValueTypes["interaction_min_order_by"];
	["interaction_on_conflict"]: ValueTypes["interaction_on_conflict"];
	["interaction_order_by"]: ValueTypes["interaction_order_by"];
	["interaction_pk_columns_input"]: ValueTypes["interaction_pk_columns_input"];
	["interaction_select_column"]: ValueTypes["interaction_select_column"];
	["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"]: ValueTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_and_arguments_columns"];
	["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"]: ValueTypes["interaction_select_column_interaction_aggregate_bool_exp_bool_or_arguments_columns"];
	["interaction_set_input"]: ValueTypes["interaction_set_input"];
	["interaction_stream_cursor_input"]: ValueTypes["interaction_stream_cursor_input"];
	["interaction_stream_cursor_value_input"]: ValueTypes["interaction_stream_cursor_value_input"];
	["interaction_update_column"]: ValueTypes["interaction_update_column"];
	["interaction_updates"]: ValueTypes["interaction_updates"];
	["json"]: ValueTypes["json"];
	["jsonb"]: ValueTypes["jsonb"];
	["jsonb_cast_exp"]: ValueTypes["jsonb_cast_exp"];
	["jsonb_comparison_exp"]: ValueTypes["jsonb_comparison_exp"];
	["landingPageTemplate_bool_exp"]: ValueTypes["landingPageTemplate_bool_exp"];
	["landingPageTemplate_constraint"]: ValueTypes["landingPageTemplate_constraint"];
	["landingPageTemplate_insert_input"]: ValueTypes["landingPageTemplate_insert_input"];
	["landingPageTemplate_on_conflict"]: ValueTypes["landingPageTemplate_on_conflict"];
	["landingPageTemplate_order_by"]: ValueTypes["landingPageTemplate_order_by"];
	["landingPageTemplate_pk_columns_input"]: ValueTypes["landingPageTemplate_pk_columns_input"];
	["landingPageTemplate_select_column"]: ValueTypes["landingPageTemplate_select_column"];
	["landingPageTemplate_set_input"]: ValueTypes["landingPageTemplate_set_input"];
	["landingPageTemplate_stream_cursor_input"]: ValueTypes["landingPageTemplate_stream_cursor_input"];
	["landingPageTemplate_stream_cursor_value_input"]: ValueTypes["landingPageTemplate_stream_cursor_value_input"];
	["landingPageTemplate_update_column"]: ValueTypes["landingPageTemplate_update_column"];
	["landingPageTemplate_updates"]: ValueTypes["landingPageTemplate_updates"];
	["newTemplateType_bool_exp"]: ValueTypes["newTemplateType_bool_exp"];
	["newTemplateType_constraint"]: ValueTypes["newTemplateType_constraint"];
	["newTemplateType_inc_input"]: ValueTypes["newTemplateType_inc_input"];
	["newTemplateType_insert_input"]: ValueTypes["newTemplateType_insert_input"];
	["newTemplateType_on_conflict"]: ValueTypes["newTemplateType_on_conflict"];
	["newTemplateType_order_by"]: ValueTypes["newTemplateType_order_by"];
	["newTemplateType_pk_columns_input"]: ValueTypes["newTemplateType_pk_columns_input"];
	["newTemplateType_select_column"]: ValueTypes["newTemplateType_select_column"];
	["newTemplateType_set_input"]: ValueTypes["newTemplateType_set_input"];
	["newTemplateType_stream_cursor_input"]: ValueTypes["newTemplateType_stream_cursor_input"];
	["newTemplateType_stream_cursor_value_input"]: ValueTypes["newTemplateType_stream_cursor_value_input"];
	["newTemplateType_update_column"]: ValueTypes["newTemplateType_update_column"];
	["newTemplateType_updates"]: ValueTypes["newTemplateType_updates"];
	["numeric"]: ValueTypes["numeric"];
	["numeric_comparison_exp"]: ValueTypes["numeric_comparison_exp"];
	["openAIApiKey_bool_exp"]: ValueTypes["openAIApiKey_bool_exp"];
	["openAIApiKey_constraint"]: ValueTypes["openAIApiKey_constraint"];
	["openAIApiKey_insert_input"]: ValueTypes["openAIApiKey_insert_input"];
	["openAIApiKey_on_conflict"]: ValueTypes["openAIApiKey_on_conflict"];
	["openAIApiKey_order_by"]: ValueTypes["openAIApiKey_order_by"];
	["openAIApiKey_pk_columns_input"]: ValueTypes["openAIApiKey_pk_columns_input"];
	["openAIApiKey_select_column"]: ValueTypes["openAIApiKey_select_column"];
	["openAIApiKey_set_input"]: ValueTypes["openAIApiKey_set_input"];
	["openAIApiKey_stream_cursor_input"]: ValueTypes["openAIApiKey_stream_cursor_input"];
	["openAIApiKey_stream_cursor_value_input"]: ValueTypes["openAIApiKey_stream_cursor_value_input"];
	["openAIApiKey_update_column"]: ValueTypes["openAIApiKey_update_column"];
	["openAIApiKey_updates"]: ValueTypes["openAIApiKey_updates"];
	["order_by"]: ValueTypes["order_by"];
	["pTemplate_append_input"]: ValueTypes["pTemplate_append_input"];
	["pTemplate_bool_exp"]: ValueTypes["pTemplate_bool_exp"];
	["pTemplate_constraint"]: ValueTypes["pTemplate_constraint"];
	["pTemplate_delete_at_path_input"]: ValueTypes["pTemplate_delete_at_path_input"];
	["pTemplate_delete_elem_input"]: ValueTypes["pTemplate_delete_elem_input"];
	["pTemplate_delete_key_input"]: ValueTypes["pTemplate_delete_key_input"];
	["pTemplate_inc_input"]: ValueTypes["pTemplate_inc_input"];
	["pTemplate_insert_input"]: ValueTypes["pTemplate_insert_input"];
	["pTemplate_on_conflict"]: ValueTypes["pTemplate_on_conflict"];
	["pTemplate_order_by"]: ValueTypes["pTemplate_order_by"];
	["pTemplate_pk_columns_input"]: ValueTypes["pTemplate_pk_columns_input"];
	["pTemplate_prepend_input"]: ValueTypes["pTemplate_prepend_input"];
	["pTemplate_select_column"]: ValueTypes["pTemplate_select_column"];
	["pTemplate_set_input"]: ValueTypes["pTemplate_set_input"];
	["pTemplate_stream_cursor_input"]: ValueTypes["pTemplate_stream_cursor_input"];
	["pTemplate_stream_cursor_value_input"]: ValueTypes["pTemplate_stream_cursor_value_input"];
	["pTemplate_update_column"]: ValueTypes["pTemplate_update_column"];
	["pTemplate_updates"]: ValueTypes["pTemplate_updates"];
	["pricingPlan_bool_exp"]: ValueTypes["pricingPlan_bool_exp"];
	["pricingPlan_constraint"]: ValueTypes["pricingPlan_constraint"];
	["pricingPlan_inc_input"]: ValueTypes["pricingPlan_inc_input"];
	["pricingPlan_insert_input"]: ValueTypes["pricingPlan_insert_input"];
	["pricingPlan_obj_rel_insert_input"]: ValueTypes["pricingPlan_obj_rel_insert_input"];
	["pricingPlan_on_conflict"]: ValueTypes["pricingPlan_on_conflict"];
	["pricingPlan_order_by"]: ValueTypes["pricingPlan_order_by"];
	["pricingPlan_pk_columns_input"]: ValueTypes["pricingPlan_pk_columns_input"];
	["pricingPlan_select_column"]: ValueTypes["pricingPlan_select_column"];
	["pricingPlan_set_input"]: ValueTypes["pricingPlan_set_input"];
	["pricingPlan_stream_cursor_input"]: ValueTypes["pricingPlan_stream_cursor_input"];
	["pricingPlan_stream_cursor_value_input"]: ValueTypes["pricingPlan_stream_cursor_value_input"];
	["pricingPlan_update_column"]: ValueTypes["pricingPlan_update_column"];
	["pricingPlan_updates"]: ValueTypes["pricingPlan_updates"];
	["products_aggregate_bool_exp"]: ValueTypes["products_aggregate_bool_exp"];
	["products_aggregate_bool_exp_bool_and"]: ValueTypes["products_aggregate_bool_exp_bool_and"];
	["products_aggregate_bool_exp_bool_or"]: ValueTypes["products_aggregate_bool_exp_bool_or"];
	["products_aggregate_bool_exp_count"]: ValueTypes["products_aggregate_bool_exp_count"];
	["products_aggregate_order_by"]: ValueTypes["products_aggregate_order_by"];
	["products_append_input"]: ValueTypes["products_append_input"];
	["products_arr_rel_insert_input"]: ValueTypes["products_arr_rel_insert_input"];
	["products_avg_order_by"]: ValueTypes["products_avg_order_by"];
	["products_bool_exp"]: ValueTypes["products_bool_exp"];
	["products_constraint"]: ValueTypes["products_constraint"];
	["products_delete_at_path_input"]: ValueTypes["products_delete_at_path_input"];
	["products_delete_elem_input"]: ValueTypes["products_delete_elem_input"];
	["products_delete_key_input"]: ValueTypes["products_delete_key_input"];
	["products_inc_input"]: ValueTypes["products_inc_input"];
	["products_insert_input"]: ValueTypes["products_insert_input"];
	["products_max_order_by"]: ValueTypes["products_max_order_by"];
	["products_min_order_by"]: ValueTypes["products_min_order_by"];
	["products_obj_rel_insert_input"]: ValueTypes["products_obj_rel_insert_input"];
	["products_on_conflict"]: ValueTypes["products_on_conflict"];
	["products_order_by"]: ValueTypes["products_order_by"];
	["products_pk_columns_input"]: ValueTypes["products_pk_columns_input"];
	["products_prepend_input"]: ValueTypes["products_prepend_input"];
	["products_select_column"]: ValueTypes["products_select_column"];
	["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"]: ValueTypes["products_select_column_products_aggregate_bool_exp_bool_and_arguments_columns"];
	["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"]: ValueTypes["products_select_column_products_aggregate_bool_exp_bool_or_arguments_columns"];
	["products_set_input"]: ValueTypes["products_set_input"];
	["products_stddev_order_by"]: ValueTypes["products_stddev_order_by"];
	["products_stddev_pop_order_by"]: ValueTypes["products_stddev_pop_order_by"];
	["products_stddev_samp_order_by"]: ValueTypes["products_stddev_samp_order_by"];
	["products_stream_cursor_input"]: ValueTypes["products_stream_cursor_input"];
	["products_stream_cursor_value_input"]: ValueTypes["products_stream_cursor_value_input"];
	["products_sum_order_by"]: ValueTypes["products_sum_order_by"];
	["products_update_column"]: ValueTypes["products_update_column"];
	["products_updates"]: ValueTypes["products_updates"];
	["products_var_pop_order_by"]: ValueTypes["products_var_pop_order_by"];
	["products_var_samp_order_by"]: ValueTypes["products_var_samp_order_by"];
	["products_variance_order_by"]: ValueTypes["products_variance_order_by"];
	["projectImage_bool_exp"]: ValueTypes["projectImage_bool_exp"];
	["projectImage_constraint"]: ValueTypes["projectImage_constraint"];
	["projectImage_insert_input"]: ValueTypes["projectImage_insert_input"];
	["projectImage_on_conflict"]: ValueTypes["projectImage_on_conflict"];
	["projectImage_order_by"]: ValueTypes["projectImage_order_by"];
	["projectImage_pk_columns_input"]: ValueTypes["projectImage_pk_columns_input"];
	["projectImage_select_column"]: ValueTypes["projectImage_select_column"];
	["projectImage_set_input"]: ValueTypes["projectImage_set_input"];
	["projectImage_stream_cursor_input"]: ValueTypes["projectImage_stream_cursor_input"];
	["projectImage_stream_cursor_value_input"]: ValueTypes["projectImage_stream_cursor_value_input"];
	["projectImage_update_column"]: ValueTypes["projectImage_update_column"];
	["projectImage_updates"]: ValueTypes["projectImage_updates"];
	["project_append_input"]: ValueTypes["project_append_input"];
	["project_bool_exp"]: ValueTypes["project_bool_exp"];
	["project_constraint"]: ValueTypes["project_constraint"];
	["project_delete_at_path_input"]: ValueTypes["project_delete_at_path_input"];
	["project_delete_elem_input"]: ValueTypes["project_delete_elem_input"];
	["project_delete_key_input"]: ValueTypes["project_delete_key_input"];
	["project_inc_input"]: ValueTypes["project_inc_input"];
	["project_insert_input"]: ValueTypes["project_insert_input"];
	["project_obj_rel_insert_input"]: ValueTypes["project_obj_rel_insert_input"];
	["project_on_conflict"]: ValueTypes["project_on_conflict"];
	["project_order_by"]: ValueTypes["project_order_by"];
	["project_pk_columns_input"]: ValueTypes["project_pk_columns_input"];
	["project_prepend_input"]: ValueTypes["project_prepend_input"];
	["project_select_column"]: ValueTypes["project_select_column"];
	["project_set_input"]: ValueTypes["project_set_input"];
	["project_stream_cursor_input"]: ValueTypes["project_stream_cursor_input"];
	["project_stream_cursor_value_input"]: ValueTypes["project_stream_cursor_value_input"];
	["project_update_column"]: ValueTypes["project_update_column"];
	["project_updates"]: ValueTypes["project_updates"];
	["task_aggregate_bool_exp"]: ValueTypes["task_aggregate_bool_exp"];
	["task_aggregate_bool_exp_bool_and"]: ValueTypes["task_aggregate_bool_exp_bool_and"];
	["task_aggregate_bool_exp_bool_or"]: ValueTypes["task_aggregate_bool_exp_bool_or"];
	["task_aggregate_bool_exp_count"]: ValueTypes["task_aggregate_bool_exp_count"];
	["task_aggregate_order_by"]: ValueTypes["task_aggregate_order_by"];
	["task_arr_rel_insert_input"]: ValueTypes["task_arr_rel_insert_input"];
	["task_bool_exp"]: ValueTypes["task_bool_exp"];
	["task_constraint"]: ValueTypes["task_constraint"];
	["task_insert_input"]: ValueTypes["task_insert_input"];
	["task_max_order_by"]: ValueTypes["task_max_order_by"];
	["task_min_order_by"]: ValueTypes["task_min_order_by"];
	["task_on_conflict"]: ValueTypes["task_on_conflict"];
	["task_order_by"]: ValueTypes["task_order_by"];
	["task_pk_columns_input"]: ValueTypes["task_pk_columns_input"];
	["task_select_column"]: ValueTypes["task_select_column"];
	["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"]: ValueTypes["task_select_column_task_aggregate_bool_exp_bool_and_arguments_columns"];
	["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"]: ValueTypes["task_select_column_task_aggregate_bool_exp_bool_or_arguments_columns"];
	["task_set_input"]: ValueTypes["task_set_input"];
	["task_stream_cursor_input"]: ValueTypes["task_stream_cursor_input"];
	["task_stream_cursor_value_input"]: ValueTypes["task_stream_cursor_value_input"];
	["task_update_column"]: ValueTypes["task_update_column"];
	["task_updates"]: ValueTypes["task_updates"];
	["textTemplateDisplayFormat_aggregate_bool_exp"]: ValueTypes["textTemplateDisplayFormat_aggregate_bool_exp"];
	["textTemplateDisplayFormat_aggregate_bool_exp_count"]: ValueTypes["textTemplateDisplayFormat_aggregate_bool_exp_count"];
	["textTemplateDisplayFormat_aggregate_order_by"]: ValueTypes["textTemplateDisplayFormat_aggregate_order_by"];
	["textTemplateDisplayFormat_arr_rel_insert_input"]: ValueTypes["textTemplateDisplayFormat_arr_rel_insert_input"];
	["textTemplateDisplayFormat_bool_exp"]: ValueTypes["textTemplateDisplayFormat_bool_exp"];
	["textTemplateDisplayFormat_constraint"]: ValueTypes["textTemplateDisplayFormat_constraint"];
	["textTemplateDisplayFormat_insert_input"]: ValueTypes["textTemplateDisplayFormat_insert_input"];
	["textTemplateDisplayFormat_max_order_by"]: ValueTypes["textTemplateDisplayFormat_max_order_by"];
	["textTemplateDisplayFormat_min_order_by"]: ValueTypes["textTemplateDisplayFormat_min_order_by"];
	["textTemplateDisplayFormat_on_conflict"]: ValueTypes["textTemplateDisplayFormat_on_conflict"];
	["textTemplateDisplayFormat_order_by"]: ValueTypes["textTemplateDisplayFormat_order_by"];
	["textTemplateDisplayFormat_pk_columns_input"]: ValueTypes["textTemplateDisplayFormat_pk_columns_input"];
	["textTemplateDisplayFormat_select_column"]: ValueTypes["textTemplateDisplayFormat_select_column"];
	["textTemplateDisplayFormat_set_input"]: ValueTypes["textTemplateDisplayFormat_set_input"];
	["textTemplateDisplayFormat_stream_cursor_input"]: ValueTypes["textTemplateDisplayFormat_stream_cursor_input"];
	["textTemplateDisplayFormat_stream_cursor_value_input"]: ValueTypes["textTemplateDisplayFormat_stream_cursor_value_input"];
	["textTemplateDisplayFormat_update_column"]: ValueTypes["textTemplateDisplayFormat_update_column"];
	["textTemplateDisplayFormat_updates"]: ValueTypes["textTemplateDisplayFormat_updates"];
	["timestamp"]: ValueTypes["timestamp"];
	["timestamp_comparison_exp"]: ValueTypes["timestamp_comparison_exp"];
	["timestamptz"]: ValueTypes["timestamptz"];
	["timestamptz_comparison_exp"]: ValueTypes["timestamptz_comparison_exp"];
	["userAppIntegration_append_input"]: ValueTypes["userAppIntegration_append_input"];
	["userAppIntegration_bool_exp"]: ValueTypes["userAppIntegration_bool_exp"];
	["userAppIntegration_constraint"]: ValueTypes["userAppIntegration_constraint"];
	["userAppIntegration_delete_at_path_input"]: ValueTypes["userAppIntegration_delete_at_path_input"];
	["userAppIntegration_delete_elem_input"]: ValueTypes["userAppIntegration_delete_elem_input"];
	["userAppIntegration_delete_key_input"]: ValueTypes["userAppIntegration_delete_key_input"];
	["userAppIntegration_insert_input"]: ValueTypes["userAppIntegration_insert_input"];
	["userAppIntegration_on_conflict"]: ValueTypes["userAppIntegration_on_conflict"];
	["userAppIntegration_order_by"]: ValueTypes["userAppIntegration_order_by"];
	["userAppIntegration_pk_columns_input"]: ValueTypes["userAppIntegration_pk_columns_input"];
	["userAppIntegration_prepend_input"]: ValueTypes["userAppIntegration_prepend_input"];
	["userAppIntegration_select_column"]: ValueTypes["userAppIntegration_select_column"];
	["userAppIntegration_set_input"]: ValueTypes["userAppIntegration_set_input"];
	["userAppIntegration_stream_cursor_input"]: ValueTypes["userAppIntegration_stream_cursor_input"];
	["userAppIntegration_stream_cursor_value_input"]: ValueTypes["userAppIntegration_stream_cursor_value_input"];
	["userAppIntegration_update_column"]: ValueTypes["userAppIntegration_update_column"];
	["userAppIntegration_updates"]: ValueTypes["userAppIntegration_updates"];
	["userCreditUsageSummary_bool_exp"]: ValueTypes["userCreditUsageSummary_bool_exp"];
	["userCreditUsageSummary_constraint"]: ValueTypes["userCreditUsageSummary_constraint"];
	["userCreditUsageSummary_inc_input"]: ValueTypes["userCreditUsageSummary_inc_input"];
	["userCreditUsageSummary_insert_input"]: ValueTypes["userCreditUsageSummary_insert_input"];
	["userCreditUsageSummary_on_conflict"]: ValueTypes["userCreditUsageSummary_on_conflict"];
	["userCreditUsageSummary_order_by"]: ValueTypes["userCreditUsageSummary_order_by"];
	["userCreditUsageSummary_pk_columns_input"]: ValueTypes["userCreditUsageSummary_pk_columns_input"];
	["userCreditUsageSummary_select_column"]: ValueTypes["userCreditUsageSummary_select_column"];
	["userCreditUsageSummary_set_input"]: ValueTypes["userCreditUsageSummary_set_input"];
	["userCreditUsageSummary_stream_cursor_input"]: ValueTypes["userCreditUsageSummary_stream_cursor_input"];
	["userCreditUsageSummary_stream_cursor_value_input"]: ValueTypes["userCreditUsageSummary_stream_cursor_value_input"];
	["userCreditUsageSummary_update_column"]: ValueTypes["userCreditUsageSummary_update_column"];
	["userCreditUsageSummary_updates"]: ValueTypes["userCreditUsageSummary_updates"];
	["userCreditUsage_bool_exp"]: ValueTypes["userCreditUsage_bool_exp"];
	["userCreditUsage_constraint"]: ValueTypes["userCreditUsage_constraint"];
	["userCreditUsage_inc_input"]: ValueTypes["userCreditUsage_inc_input"];
	["userCreditUsage_insert_input"]: ValueTypes["userCreditUsage_insert_input"];
	["userCreditUsage_on_conflict"]: ValueTypes["userCreditUsage_on_conflict"];
	["userCreditUsage_order_by"]: ValueTypes["userCreditUsage_order_by"];
	["userCreditUsage_pk_columns_input"]: ValueTypes["userCreditUsage_pk_columns_input"];
	["userCreditUsage_select_column"]: ValueTypes["userCreditUsage_select_column"];
	["userCreditUsage_set_input"]: ValueTypes["userCreditUsage_set_input"];
	["userCreditUsage_stream_cursor_input"]: ValueTypes["userCreditUsage_stream_cursor_input"];
	["userCreditUsage_stream_cursor_value_input"]: ValueTypes["userCreditUsage_stream_cursor_value_input"];
	["userCreditUsage_update_column"]: ValueTypes["userCreditUsage_update_column"];
	["userCreditUsage_updates"]: ValueTypes["userCreditUsage_updates"];
	["userCredits_bool_exp"]: ValueTypes["userCredits_bool_exp"];
	["userCredits_constraint"]: ValueTypes["userCredits_constraint"];
	["userCredits_inc_input"]: ValueTypes["userCredits_inc_input"];
	["userCredits_insert_input"]: ValueTypes["userCredits_insert_input"];
	["userCredits_on_conflict"]: ValueTypes["userCredits_on_conflict"];
	["userCredits_order_by"]: ValueTypes["userCredits_order_by"];
	["userCredits_pk_columns_input"]: ValueTypes["userCredits_pk_columns_input"];
	["userCredits_select_column"]: ValueTypes["userCredits_select_column"];
	["userCredits_set_input"]: ValueTypes["userCredits_set_input"];
	["userCredits_stream_cursor_input"]: ValueTypes["userCredits_stream_cursor_input"];
	["userCredits_stream_cursor_value_input"]: ValueTypes["userCredits_stream_cursor_value_input"];
	["userCredits_update_column"]: ValueTypes["userCredits_update_column"];
	["userCredits_updates"]: ValueTypes["userCredits_updates"];
	["userFeedback_bool_exp"]: ValueTypes["userFeedback_bool_exp"];
	["userFeedback_constraint"]: ValueTypes["userFeedback_constraint"];
	["userFeedback_insert_input"]: ValueTypes["userFeedback_insert_input"];
	["userFeedback_on_conflict"]: ValueTypes["userFeedback_on_conflict"];
	["userFeedback_order_by"]: ValueTypes["userFeedback_order_by"];
	["userFeedback_pk_columns_input"]: ValueTypes["userFeedback_pk_columns_input"];
	["userFeedback_select_column"]: ValueTypes["userFeedback_select_column"];
	["userFeedback_set_input"]: ValueTypes["userFeedback_set_input"];
	["userFeedback_stream_cursor_input"]: ValueTypes["userFeedback_stream_cursor_input"];
	["userFeedback_stream_cursor_value_input"]: ValueTypes["userFeedback_stream_cursor_value_input"];
	["userFeedback_update_column"]: ValueTypes["userFeedback_update_column"];
	["userFeedback_updates"]: ValueTypes["userFeedback_updates"];
	["userMedia_append_input"]: ValueTypes["userMedia_append_input"];
	["userMedia_bool_exp"]: ValueTypes["userMedia_bool_exp"];
	["userMedia_constraint"]: ValueTypes["userMedia_constraint"];
	["userMedia_delete_at_path_input"]: ValueTypes["userMedia_delete_at_path_input"];
	["userMedia_delete_elem_input"]: ValueTypes["userMedia_delete_elem_input"];
	["userMedia_delete_key_input"]: ValueTypes["userMedia_delete_key_input"];
	["userMedia_insert_input"]: ValueTypes["userMedia_insert_input"];
	["userMedia_on_conflict"]: ValueTypes["userMedia_on_conflict"];
	["userMedia_order_by"]: ValueTypes["userMedia_order_by"];
	["userMedia_pk_columns_input"]: ValueTypes["userMedia_pk_columns_input"];
	["userMedia_prepend_input"]: ValueTypes["userMedia_prepend_input"];
	["userMedia_select_column"]: ValueTypes["userMedia_select_column"];
	["userMedia_set_input"]: ValueTypes["userMedia_set_input"];
	["userMedia_stream_cursor_input"]: ValueTypes["userMedia_stream_cursor_input"];
	["userMedia_stream_cursor_value_input"]: ValueTypes["userMedia_stream_cursor_value_input"];
	["userMedia_update_column"]: ValueTypes["userMedia_update_column"];
	["userMedia_updates"]: ValueTypes["userMedia_updates"];
	["userMetadata_aggregate_bool_exp"]: ValueTypes["userMetadata_aggregate_bool_exp"];
	["userMetadata_aggregate_bool_exp_bool_and"]: ValueTypes["userMetadata_aggregate_bool_exp_bool_and"];
	["userMetadata_aggregate_bool_exp_bool_or"]: ValueTypes["userMetadata_aggregate_bool_exp_bool_or"];
	["userMetadata_aggregate_bool_exp_count"]: ValueTypes["userMetadata_aggregate_bool_exp_count"];
	["userMetadata_aggregate_order_by"]: ValueTypes["userMetadata_aggregate_order_by"];
	["userMetadata_arr_rel_insert_input"]: ValueTypes["userMetadata_arr_rel_insert_input"];
	["userMetadata_avg_order_by"]: ValueTypes["userMetadata_avg_order_by"];
	["userMetadata_bool_exp"]: ValueTypes["userMetadata_bool_exp"];
	["userMetadata_constraint"]: ValueTypes["userMetadata_constraint"];
	["userMetadata_inc_input"]: ValueTypes["userMetadata_inc_input"];
	["userMetadata_insert_input"]: ValueTypes["userMetadata_insert_input"];
	["userMetadata_max_order_by"]: ValueTypes["userMetadata_max_order_by"];
	["userMetadata_min_order_by"]: ValueTypes["userMetadata_min_order_by"];
	["userMetadata_on_conflict"]: ValueTypes["userMetadata_on_conflict"];
	["userMetadata_order_by"]: ValueTypes["userMetadata_order_by"];
	["userMetadata_pk_columns_input"]: ValueTypes["userMetadata_pk_columns_input"];
	["userMetadata_select_column"]: ValueTypes["userMetadata_select_column"];
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"]: ValueTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_and_arguments_columns"];
	["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"]: ValueTypes["userMetadata_select_column_userMetadata_aggregate_bool_exp_bool_or_arguments_columns"];
	["userMetadata_set_input"]: ValueTypes["userMetadata_set_input"];
	["userMetadata_stddev_order_by"]: ValueTypes["userMetadata_stddev_order_by"];
	["userMetadata_stddev_pop_order_by"]: ValueTypes["userMetadata_stddev_pop_order_by"];
	["userMetadata_stddev_samp_order_by"]: ValueTypes["userMetadata_stddev_samp_order_by"];
	["userMetadata_stream_cursor_input"]: ValueTypes["userMetadata_stream_cursor_input"];
	["userMetadata_stream_cursor_value_input"]: ValueTypes["userMetadata_stream_cursor_value_input"];
	["userMetadata_sum_order_by"]: ValueTypes["userMetadata_sum_order_by"];
	["userMetadata_update_column"]: ValueTypes["userMetadata_update_column"];
	["userMetadata_updates"]: ValueTypes["userMetadata_updates"];
	["userMetadata_var_pop_order_by"]: ValueTypes["userMetadata_var_pop_order_by"];
	["userMetadata_var_samp_order_by"]: ValueTypes["userMetadata_var_samp_order_by"];
	["userMetadata_variance_order_by"]: ValueTypes["userMetadata_variance_order_by"];
	["userOnboarding_bool_exp"]: ValueTypes["userOnboarding_bool_exp"];
	["userOnboarding_constraint"]: ValueTypes["userOnboarding_constraint"];
	["userOnboarding_inc_input"]: ValueTypes["userOnboarding_inc_input"];
	["userOnboarding_insert_input"]: ValueTypes["userOnboarding_insert_input"];
	["userOnboarding_on_conflict"]: ValueTypes["userOnboarding_on_conflict"];
	["userOnboarding_order_by"]: ValueTypes["userOnboarding_order_by"];
	["userOnboarding_pk_columns_input"]: ValueTypes["userOnboarding_pk_columns_input"];
	["userOnboarding_select_column"]: ValueTypes["userOnboarding_select_column"];
	["userOnboarding_set_input"]: ValueTypes["userOnboarding_set_input"];
	["userOnboarding_stream_cursor_input"]: ValueTypes["userOnboarding_stream_cursor_input"];
	["userOnboarding_stream_cursor_value_input"]: ValueTypes["userOnboarding_stream_cursor_value_input"];
	["userOnboarding_update_column"]: ValueTypes["userOnboarding_update_column"];
	["userOnboarding_updates"]: ValueTypes["userOnboarding_updates"];
	["userTemplateUsage_bool_exp"]: ValueTypes["userTemplateUsage_bool_exp"];
	["userTemplateUsage_constraint"]: ValueTypes["userTemplateUsage_constraint"];
	["userTemplateUsage_insert_input"]: ValueTypes["userTemplateUsage_insert_input"];
	["userTemplateUsage_on_conflict"]: ValueTypes["userTemplateUsage_on_conflict"];
	["userTemplateUsage_order_by"]: ValueTypes["userTemplateUsage_order_by"];
	["userTemplateUsage_pk_columns_input"]: ValueTypes["userTemplateUsage_pk_columns_input"];
	["userTemplateUsage_select_column"]: ValueTypes["userTemplateUsage_select_column"];
	["userTemplateUsage_set_input"]: ValueTypes["userTemplateUsage_set_input"];
	["userTemplateUsage_stream_cursor_input"]: ValueTypes["userTemplateUsage_stream_cursor_input"];
	["userTemplateUsage_stream_cursor_value_input"]: ValueTypes["userTemplateUsage_stream_cursor_value_input"];
	["userTemplateUsage_update_column"]: ValueTypes["userTemplateUsage_update_column"];
	["userTemplateUsage_updates"]: ValueTypes["userTemplateUsage_updates"];
	["users_aggregate_bool_exp"]: ValueTypes["users_aggregate_bool_exp"];
	["users_aggregate_bool_exp_bool_and"]: ValueTypes["users_aggregate_bool_exp_bool_and"];
	["users_aggregate_bool_exp_bool_or"]: ValueTypes["users_aggregate_bool_exp_bool_or"];
	["users_aggregate_bool_exp_count"]: ValueTypes["users_aggregate_bool_exp_count"];
	["users_aggregate_order_by"]: ValueTypes["users_aggregate_order_by"];
	["users_append_input"]: ValueTypes["users_append_input"];
	["users_arr_rel_insert_input"]: ValueTypes["users_arr_rel_insert_input"];
	["users_bool_exp"]: ValueTypes["users_bool_exp"];
	["users_constraint"]: ValueTypes["users_constraint"];
	["users_delete_at_path_input"]: ValueTypes["users_delete_at_path_input"];
	["users_delete_elem_input"]: ValueTypes["users_delete_elem_input"];
	["users_delete_key_input"]: ValueTypes["users_delete_key_input"];
	["users_insert_input"]: ValueTypes["users_insert_input"];
	["users_max_order_by"]: ValueTypes["users_max_order_by"];
	["users_min_order_by"]: ValueTypes["users_min_order_by"];
	["users_obj_rel_insert_input"]: ValueTypes["users_obj_rel_insert_input"];
	["users_on_conflict"]: ValueTypes["users_on_conflict"];
	["users_order_by"]: ValueTypes["users_order_by"];
	["users_pk_columns_input"]: ValueTypes["users_pk_columns_input"];
	["users_prepend_input"]: ValueTypes["users_prepend_input"];
	["users_select_column"]: ValueTypes["users_select_column"];
	["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"]: ValueTypes["users_select_column_users_aggregate_bool_exp_bool_and_arguments_columns"];
	["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"]: ValueTypes["users_select_column_users_aggregate_bool_exp_bool_or_arguments_columns"];
	["users_set_input"]: ValueTypes["users_set_input"];
	["users_stream_cursor_input"]: ValueTypes["users_stream_cursor_input"];
	["users_stream_cursor_value_input"]: ValueTypes["users_stream_cursor_value_input"];
	["users_update_column"]: ValueTypes["users_update_column"];
	["users_updates"]: ValueTypes["users_updates"];
	["uuid"]: ValueTypes["uuid"];
	["uuid_comparison_exp"]: ValueTypes["uuid_comparison_exp"];
	["virus_append_input"]: ValueTypes["virus_append_input"];
	["virus_bool_exp"]: ValueTypes["virus_bool_exp"];
	["virus_constraint"]: ValueTypes["virus_constraint"];
	["virus_delete_at_path_input"]: ValueTypes["virus_delete_at_path_input"];
	["virus_delete_elem_input"]: ValueTypes["virus_delete_elem_input"];
	["virus_delete_key_input"]: ValueTypes["virus_delete_key_input"];
	["virus_insert_input"]: ValueTypes["virus_insert_input"];
	["virus_on_conflict"]: ValueTypes["virus_on_conflict"];
	["virus_order_by"]: ValueTypes["virus_order_by"];
	["virus_pk_columns_input"]: ValueTypes["virus_pk_columns_input"];
	["virus_prepend_input"]: ValueTypes["virus_prepend_input"];
	["virus_select_column"]: ValueTypes["virus_select_column"];
	["virus_set_input"]: ValueTypes["virus_set_input"];
	["virus_stream_cursor_input"]: ValueTypes["virus_stream_cursor_input"];
	["virus_stream_cursor_value_input"]: ValueTypes["virus_stream_cursor_value_input"];
	["virus_update_column"]: ValueTypes["virus_update_column"];
	["virus_updates"]: ValueTypes["virus_updates"];
	["website_bool_exp"]: ValueTypes["website_bool_exp"];
	["website_constraint"]: ValueTypes["website_constraint"];
	["website_insert_input"]: ValueTypes["website_insert_input"];
	["website_on_conflict"]: ValueTypes["website_on_conflict"];
	["website_order_by"]: ValueTypes["website_order_by"];
	["website_pk_columns_input"]: ValueTypes["website_pk_columns_input"];
	["website_select_column"]: ValueTypes["website_select_column"];
	["website_set_input"]: ValueTypes["website_set_input"];
	["website_stream_cursor_input"]: ValueTypes["website_stream_cursor_input"];
	["website_stream_cursor_value_input"]: ValueTypes["website_stream_cursor_value_input"];
	["website_update_column"]: ValueTypes["website_update_column"];
	["website_updates"]: ValueTypes["website_updates"];
}