import { DI } from 'aurelia';
import { ModelTypes } from '@zeus';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface IProductService {
  getProducts(type: string): Promise<ModelTypes['products'][]>;
}

class ProductService implements IProductService {
  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  async getProducts(type: string): Promise<ModelTypes['products'][]> {
    const result = await this.zeusClient.chain('query')({
      products: [
        {
          where: { type: { _eq: type } },
        },
        {
          id: true,
          alias: true,
          imageOneId: true,
          linkConversion: true,
        },
      ]
    });

    return result.products as ModelTypes['products'][];
  }
}

export const IProductService = DI.createInterface<IProductService>((x) => x.singleton(ProductService));
