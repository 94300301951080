import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR'

@valueConverter('sameDayClass')
export class SameDayClass {
  constructor(@I18N private I18N: I18N) { }

  toView(date, baseDate) {
    const isEqual = format(date, 'yyyy-MM-dd', { locale: ptBR }) === format(baseDate, 'yyyy-MM-dd', { locale: ptBR });
    console.log('isEqualDay', isEqual);

  }

}