// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dash-category .Instagram:hover {
  background-color: #46c1f6 !important;
}

#dash-category .group:hover .Instagram {
  background-color: #46c1f6 !important;
}

#dash-category .group:hover .Facebook:hover {
  background-color: #3b5999 !important;
}

#dash-category .group:hover .Facebook {
  background-color: #3b5999 !important;
}

#dash-category .group:hover .LinkedIn:hover {
  background-color: #0077b5 !important;
}

#dash-category .group:hover .LinkedIn {
  background-color: #0077b5 !important;
}

#dash-category .group:hover .TikTok:hover {
  background-color: #000000 !important;
}

#dash-category .group:hover .TikTok {
  background-color: #000000 !important;
}

#dash-category .group:hover .Blog:hover {
  background-color: #f26522 !important;
}

#dash-category .group:hover .Blog {
  background-color: #f26522 !important;
}

#dash-category .group:hover .Site:hover {
  background-color: #f26522 !important;
}

#dash-category .group:hover .Site {
  background-color: #f26522 !important;
}

#dash-category .group:hover .E-mail:hover {
  background-color: #f26522 !important;
}

#dash-category .group:hover .E-mail {
  background-color: #f26522 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/private/pages/home/components/categories/categories-component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["#dash-category .Instagram:hover {\r\n  background-color: #46c1f6 !important;\r\n}\r\n\r\n#dash-category .group:hover .Instagram {\r\n  background-color: #46c1f6 !important;\r\n}\r\n\r\n#dash-category .group:hover .Facebook:hover {\r\n  background-color: #3b5999 !important;\r\n}\r\n\r\n#dash-category .group:hover .Facebook {\r\n  background-color: #3b5999 !important;\r\n}\r\n\r\n#dash-category .group:hover .LinkedIn:hover {\r\n  background-color: #0077b5 !important;\r\n}\r\n\r\n#dash-category .group:hover .LinkedIn {\r\n  background-color: #0077b5 !important;\r\n}\r\n\r\n#dash-category .group:hover .TikTok:hover {\r\n  background-color: #000000 !important;\r\n}\r\n\r\n#dash-category .group:hover .TikTok {\r\n  background-color: #000000 !important;\r\n}\r\n\r\n#dash-category .group:hover .Blog:hover {\r\n  background-color: #f26522 !important;\r\n}\r\n\r\n#dash-category .group:hover .Blog {\r\n  background-color: #f26522 !important;\r\n}\r\n\r\n#dash-category .group:hover .Site:hover {\r\n  background-color: #f26522 !important;\r\n}\r\n\r\n#dash-category .group:hover .Site {\r\n  background-color: #f26522 !important;\r\n}\r\n\r\n#dash-category .group:hover .E-mail:hover {\r\n  background-color: #f26522 !important;\r\n}\r\n\r\n#dash-category .group:hover .E-mail {\r\n  background-color: #f26522 !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
