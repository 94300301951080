<import from='@components/undraw/undraw-component' />
<import from='@components/card-title/card-title-component' />

<import from='./components/card/card-component' />

<section>

  <card-title-component title-id="audiencias">
    <div au-slot="actions">
      <a href="/audience" type="button" class="btn-primary">Criar audiência</a>
    </div>
  </card-title-component>

  <template if.bind="!audience">
    <undraw-component message="Nenhuma audiência encontrada"></undraw-component>
  </template>
  <template else>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 p-4">
      <template repeat.for="personality of audience.personas">
        <!-- begin::card -->
        <card-component personality.bind="personality"></card-component>
        <!-- end::card -->
      </template>
    </div>
  </template>

</section>