import { BindingMode, bindable } from 'aurelia';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class AiComponent {
  @bindable({ mode: BindingMode.twoWay }) selectedImage: string;

  private query: string;
  private refresher: number;
  private callTriggerAIImageSearch: boolean = false;

  private images: any[] = [];
  private selectedImageId: string;

  constructor(@IZeusClient private zeusClient: ZeusClient) { }

  private triggerAIImageSearch() {
    this.callTriggerAIImageSearch = true;

    this.refresher = Math.random();
  }

  async generateImageFromPrompt() {
    const result = await this.zeusClient.generateImageFromPrompt(this.query)

    this.selectedImageId = null;
    this.images = result.images;

    return result
  }

  private selectImage(id: string) {
    this.selectedImageId = id;
    this.selectedImage = id;
    // this.selectedImage = this.images.find(i => i.id === id).url;
  }

  detaching() {
    this.callTriggerAIImageSearch = false;
    this.selectedImage = null;
    this.selectedImageId = null;
    this.images = [];
    this.query = null;
  }
}