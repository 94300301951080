<button id="dropdown-aspect-ratio-button" type="button" class="form-input">
  <div class="flex h-full w-full items-center">
    <div class="flex flex-1 items-center justify-start gap-2">
      <svg if.bind="selectedAspectRatio" width="18" height="19" fill="none" viewBox="0 0 18 19" class="h-5 w-5 [.sm_&amp;]:h-[18px] [.sm_&amp;]:w-[18px]">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.102V5.414a3.033 3.033 0 0 1 3.037-3.037h1.688m4.05 0h1.688a3.033 3.033 0 0 1 3.037 3.037v1.688m-.001 4.724v1.013a3.033 3.033 0 0 1-3.037 3.037h-1.013m-4.724.001H5.287A3.033 3.033 0 0 1 2.25 12.84v-1.688"></path>
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M4.125 7.665V5.993c0-1.214.527-1.741 1.741-1.741h1.672m6.337 6.825v1.184c0 1.214-.527 1.741-1.741 1.741H10.95"></path>
      </svg>
      <span class="max-w-[133px] truncate">${selectedAspectRatio ? selectedAspectRatio.text : 'Selecione'}</span>
    </div>
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" class="h-5 w-5 rotate-0 transform stroke-[1.5] text-neutral-400 transition-all duration-500 group-data-[state=open]:rotate-90 dark:text-neutral-40 [.sm_&amp;]:h-[18px] [.sm_&amp;]:w-[18px]">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="currentStroke" d="m9 18 6-6-6-6"></path>
    </svg>
  </div>
</button>

<div id="dropdown-aspect-ratio" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-md dark:bg-gray-700 dark:divide-gray-600 border max-h-[620px] min-w-[373px] max-w-[373px] w-[373px]">
  <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-${project.id}-button">
    <template repeat.for="item of aspectRatioData">

      <li>
        <label for="${item.id}-aspect-ratio" click.trigger="handleSelectedAspectRatio(item)" class="flex items-center justify-start gap-2 py-2 px-2.5 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">
          <svg width="18" height="19" fill="none" viewBox="0 0 18 19" class="h-5 w-5 [.sm_&amp;]:h-[18px] [.sm_&amp;]:w-[18px]">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.102V5.414a3.033 3.033 0 0 1 3.037-3.037h1.688m4.05 0h1.688a3.033 3.033 0 0 1 3.037 3.037v1.688m-.001 4.724v1.013a3.033 3.033 0 0 1-3.037 3.037h-1.013m-4.724.001H5.287A3.033 3.033 0 0 1 2.25 12.84v-1.688"></path>
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M4.125 7.665V5.993c0-1.214.527-1.741 1.741-1.741h1.672m6.337 6.825v1.184c0 1.214-.527 1.741-1.741 1.741H10.95"></path>
          </svg>${item.text}
        </label>
      </li>

    </template>
  </ul>

</div>