import { IEventAggregator } from 'aurelia';
import { initAccordions } from "flowbite";

import { AlertModalComponent } from '@components/alert-modal/alert-modal-component'
import { ToastComponent } from '@components/toast/toast-component';
import { ToastType } from '@components/toast/events/toast-event';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class HomePage {

  constructor(
    @IZeusClient readonly zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator
  ) { }

  attached() {
    initAccordions();
  }

  teste() {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: 'Teste',
        message: 'Teste message',
        type: ToastType.DANGER
      }
    });
  }
  async teste2() {
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    }
    );
    console.log('teste2');
  }
}