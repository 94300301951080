<import from="@components/icons/icons-component" />

<section class="relative">

  <button id="dropdownFilterListButton-${id}" type="button"
    class="rounded-full p-1 text-gray-900 dark:text-gray-400 hover:text-gray-900 cursor-pointer hover:bg-gray-100 dark:hover:text-white dark:hover:bg-gray-700">
    <i class="fa-solid fa-filter p-1"></i>
  </button>

  <div id="dropdownFilterListMenu-${id}" class="z-50 hidden p-4 h-[calc(100%-1rem)] max-h-full w-96">
    <div class="w-full max-h-full">
      <!-- beggin::content -->
      <div class="bg-[#F6F6F6] rounded-lg shadow dark:bg-gray-700">

        <!-- beggin::header -->
        <div class="flex items-center justify-between p-5 rounded-t">
          <span class="text-base font-bold text-[#071C42] dark:text-white"><i
              class="fa-solid fa-filter mr-3"></i>Filtrar por:</span>
          <icons-component click.trigger="hideDropdown()" class="h-5 w-5 text-[#071C42] cursor-pointer"
            icon="X"></icons-component>
        </div>
        <!-- end::header -->

        <!-- beggin:body -->
        <div
          class="flex flex-col gap-4 px-6 pb-3 h-[250px] overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-md scrollbar-thumb-gray-300">

          <template repeat.for="filter of filters">
            <template if.bind="filter.comparsionType === 'equals'">
              <div>
                <label for="filter.field" class="form-label">${filter.title}</label>
                <input type="${filter.type}" class="form-input" id="filter.field" value.bind="filter.value"
                  autocomplete="off">
              </div>
            </template>

            <template if.bind="filter.comparsionType === 'like' || filter.comparsionType === 'ilike'">
              <div>
                <label for="filter.field" class="form-label">${filter.title}</label>
                <input type="${filter.type}" class="form-input" id="filter.field" value.bind="filter.value"
                  autocomplete="off">
              </div>
            </template>

            <template if.bind="filter.comparsionType === 'greaterThan'">
              <div>
                <label for="filter.field" class="form-label">${filter.title}</label>
                <input type="${filter.type}" class="form-input" id="filter.field" value.bind="filter.value"
                  autocomplete="off">
              </div>
            </template>

            <template if.bind="filter.comparsionType === 'lessThan'">
              <div>
                <label for="filter.field" class="form-label">${filter.title}</label>
                <input type="${filter.type}" class="form-input" id="filter.field" value.bind="filter.value"
                  autocomplete="off">
              </div>
            </template>

            <template if.bind="filter.comparsionType === 'greaterThanEquals'">
              <div>
                <label for="filter.field" class="form-label">${filter.title}</label>
                <input type="${filter.type}" class="form-input" id="filter.field" value.bind="filter.value"
                  autocomplete="off">
              </div>
            </template>

            <template if.bind="filter.comparsionType === 'lessThanEquals'">
              <div>
                <label for="filter.field" class="form-label">${filter.title}</label>
                <input type="${filter.type}" class="form-input" id="filter.field" value.bind="filter.value"
                  autocomplete="off">
              </div>
            </template>

            <template if.bind="filter.comparsionType === 'between'">
              <div class="flex flex-col gap-2">
                <label for="filter.field" class="form-label">${filter.title}</label>
                <div class="flex flex-row gap-2">
                  <div repeat.for="item of filter.between" class="flex-col gap-2">
                    <label for="${$index}" class="form-label">${item.title}</label>
                    <input type="${filter.type}" class="form-input" id="${$index}" value.bind="item.value"
                      autocomplete="off">
                  </div>
                </div>
              </div>
            </template>

            <template if.bind="filter.comparsionType === 'in'">
              <div class="flex flex-col gap-2">
                <label class="form-label">${filter.title}</label>
                <template repeat.for="item of filter.options">
                  <div class="flex flex-row items-center gap-1">
                    <input id="${item}" type="checkbox" checked.bind="filter.value" model.bind="item"
                      class="w-4 h-4 text-primary-600 bg-gray-100 border-gray-400 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer">
                    <label for="${item}" class="form-label cursor-pointer"
                      style="margin-bottom: 0 !important;">${item}</label>
                  </div>
                </template>
              </div>
            </template>

          </template>

        </div>
        <!-- end:body -->

        <!-- beggin::footer -->
        <div class="flex flex-row justify-between items-center p-5 rounded-b gap-3">
          <button class="btn-primary-small flex-1" click.trigger="handleFilter()">Filtrar</button>
          <button class="btn-primary-outline-small" click.trigger="handleClearFilter()"><i
              class="fa-solid fa-filter-circle-xmark"></i></button>
        </div>
        <!-- end::footer -->

      </div>
      <!-- end::content -->
    </div>
  </div>

  <div if.bind="isShowDropdown" click.trigger="hideDropdown()"
    class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"></div>
</section>