<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Diferenciais da Sua Marca">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">
      <div class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class="row">
          <div class="flex-1 my-2">
            <input-component type="text" label="Título da Sessão Sobre Diferenciais"
              value.bind="website.homeOurDifferentiatorsTitle" />
          </div>
          <div class="flex-1">
            <input-component type="text" label="Descrição da Sessão Sobre Diferenciais"
              value.bind="website.homeOurDifferentiatorsDescription" />
          </div>
        </div>

        <div class="row">
          <div class="flex-1 my-2">
            <input-component type="text" label="Título do Principal Diferencial"
              value.bind="website.homeOurDifferentiatorsKeypointTitleOne" />
          </div>

          <div class="flex-1">
            <input-component type="text" label="Descrição do Principal Diferencial"
              value.bind="website.homeOurDifferentiatorsKeypointSubtitleOne" />
          </div>
        </div>

        <div class="row">
          <div class="flex-1 my-2">
            <input-component type="text" label="Título do Diferencial Secundário"
              value.bind="website.homeOurDifferentiatorsKeypointTitleTwo" />
          </div>
          <div class="flex-1">
            <input-component type="text" label="Descrição do Diferencial Secundário"
              value.bind="website.homeOurDifferentiatorsKeypointSubtitleTwo" />
          </div>
        </div>
      </div>

      <div class="flex-1">
        <single-file-upload-component label="Banner da Sessão"
          selected-file-id.bind="website.homeOurDifferentiatorsBannerImageFileId"></single-file-upload-component>
      </div>


      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20"
          trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>