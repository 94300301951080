
import { I18N } from "@aurelia/i18n";
import { valueConverter } from "aurelia";

@valueConverter('valueFromParameters')
export class ValueFromParameters {
  constructor(@I18N private I18N: I18N) { }

  toView(id: string | number, parameters: { userParameters: any[]; selectedParameters: { [x: string]: any; }; }) {
    if (!parameters?.userParameters) return '';

    const selectedValueId = parameters?.selectedParameters[id];
    const value = parameters?.userParameters.find((p: { id: any; }) => p.id === id)?.TextGeneratorOptionEntries.find((x: { id: any; }) => x.id === selectedValueId)?.Label[this.I18N.getLocale()];

    if (!value) return '';

    return value;
  }
}