import { IRouter } from '@aurelia/router';
import { CreateProjectModalComponent } from '@components/create-modal/create-project-modal/create-project-modal-component';
import { ModelTypes } from '@zeus';
import { Dropdown, type DropdownInterface, type DropdownOptions } from 'flowbite';
import { ISearchTemplateService } from './services/search-template-service';

export class SearchTemplatesComponent {
  private dropdown: DropdownInterface

  private $targetEl: HTMLElement
  private $triggerEl: HTMLElement

  private resultCache: Array<ModelTypes['newTemplateType']> = [];

  private createProjectModalRef: CreateProjectModalComponent;

  constructor(
    @IRouter private router: IRouter,
    @ISearchTemplateService private service: ISearchTemplateService
  ) { }

  attached() {
    this.$targetEl = document.getElementById('dropdown-search-template');

    this.$triggerEl = document.getElementById('dropdown-search-template-button');

    const options: DropdownOptions = {
      placement: 'bottom-end',
      triggerType: 'click',
      offsetSkidding: 0,
      offsetDistance: 20,
      delay: 300,
    };

    this.dropdown = new Dropdown(this.$targetEl, this.$triggerEl, options);
  }

  private hideDropdown(): void {
    this.dropdown.hide();
  }

  private handleSearchTemplateClick(type: string): void {
    this.hideDropdown();

    this.createProjectModalRef.showModal(type);
  }

  private async searchNewTemplateType(search: string): Promise<Array<ModelTypes['newTemplateType']>> {
    if (this.resultCache.length === 0) {
      this.resultCache = await this.service.newTemplateType();
    }

    if (search && search.length > 0) {
      const filtered = this.resultCache.filter((item) => item.name.toUpperCase().includes(search.toUpperCase()));

      return filtered;
    }

    return this.resultCache || [];
  }
}