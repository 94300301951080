<import from='./../../components/header/header-component' />

<section class="h-full p-4">
  <header-component title="Contatos" subtitle="Registro de contatos realizados">
    <div au-slot="actions">
      <button type="button" click.trigger="openInteractionDialog()" class="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs p-2 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500">
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
  </header-component>

  <div class="flex flex-col gap-2 mt-6">

    <template if.bind="interactions.length > 0" repeat.for="item of interactions">
      <!-- begin::card -->
      <div class="flex flex-row w-full h-[75px] rounded-lg bg-white dark:bg-gray-800 shadow-soft-xs shadow-gray-300 dark:shadow-current border border-solid border-gray-200 dark:border-gray-700 px-4 py-2.5 gap-4">
        <div class="w-1 ${item.success ? 'bg-success-600' : 'bg-red-600'}"></div>

        <div class="flex flex-col w-full">
          <div class="flex justify-between items-center">
            <span class="text-xs text-gray-900 dark:text-gray-200 font-semibold line-clamp-1">${getChannelById(item.channel)}</span>
            <span class="text-[10px] text-gray-700 dark:text-gray-400 line-clamp-1">${item.date | dateTime:'pt-BR'}</span>
          </div>

          <span class="text-[10px] text-gray-700 dark:text-gray-400 line-clamp-2">${item.observation}</span>
        </div>
      </div>
      <!-- end::card -->
    </template>
    <template else>
      <div class="flex flex-col items-center justify-center gap-4 mt-6">
        <span class="text-gray-700 dark:text-gray-400 text-sm">Você não possui interações</span>
      </div>
    </template>

  </div>

</section>