import { ModelTypes } from '@zeus';
import { bindable } from "aurelia";

export class IntegrationCardComponent {

  @bindable integration: ModelTypes["AppIntegration"]
  @bindable configureIntegrationCallback: (e: any) => void;

  configureIntegration() {
    this.configureIntegrationCallback(this.integration.type);
  }
}