import { DI } from 'aurelia';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { ModelTypes, deal_select_column, order_by } from '@zeus';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';

export type SizeObject = {
  id: string;
  alias: string;
  height: string;
  width: string;
};

export interface ICreateProjectService {
  getFormats(): Promise<SizeObject[]>;
  getBrands(): Promise<ModelTypes["brands"][]>;
  getMedia(): Promise<ModelTypes["userMedia"][]>;
  getProducts(): Promise<ModelTypes["products"][]>;
  getAudiences(): Promise<ModelTypes["audiences"][]>;
  getProductsById(productId: string): Promise<ModelTypes["products"]>;
  getTemplates(command: { height: string, width: string }): Promise<ModelTypes["pTemplate"][]>
  createNewProject(command: { brandId: string, productId: string, audienceId: string, templateId: string, image: string, artDirectional: string }): Promise<ModelTypes["CreateAutomaticProjectOutput"]>;
}

class CreateProjectService implements ICreateProjectService {

  constructor(
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient
  ) { }

  async createNewProject(command: { brandId: string; productId: string; audienceId: string; templateId: string; image: string; artDirectional: string; }): Promise<ModelTypes["CreateAutomaticProjectOutput"]> {
    const result = await this.zeusClient.chain('mutation')({
      createAutomaticProject: [
        {
          brandId: command.brandId,
          productId: command.productId,
          audienceId: command.audienceId,
          selectedTemplateId: command.templateId,
          selectedImage: command.image,
          artDirectional: command.artDirectional,
        },
        {
          projectId: true,
        }
      ]
    });
    return result.createAutomaticProject
  }

  async getBrands(): Promise<ModelTypes["brands"][]> {
    const result = await this.zeusClient.chain('query')({
      brands: [
        {
          limit: 1,
        },
        {
          id: true,
          brandName: true,
          slogan: true,
          voiceTone: true,
          segment: true,
          productOrService: true,
          fontPrimary: true,
          fontSecondary: true,
          isBrand: true,
          personality: true,
          colors: [{ path: '$', }, true],
          logoFileId: true,
        }
      ]
    });

    return result.brands as ModelTypes["brands"][];
  }

  async getAudiences(): Promise<ModelTypes["audiences"][]> {
    const result = await this.zeusClient.chain('query')({
      audiences: [
        {},
        {
          id: true,
          brandName: true,
          segment: true,
          productOrService: true,
          targetAudience: true,
          personas: [{ path: '$', }, true],
          created_by: true,
          created_at: true,
          updated_at: true,
        }
      ]
    });

    return result.audiences as ModelTypes["audiences"][];
  }

  async getProducts(): Promise<ModelTypes["products"][]> {
    const result = await this.zeusClient.chain('query')({
      products: [
        {
          where: { created_by: { _eq: this.auth.user.id } },
        },
        {
          id: true,
          alias: true,
          type: true,
          category: true,
          price: true,
          salePrice: true,
          linkConversion: true,
          created_by: true,
          created_at: true,
          updated_at: true,
        }
      ]
    })

    return result.products as ModelTypes["products"][];
  }

  async getProductsById(productId: string): Promise<ModelTypes["products"]> {
    const result = await this.zeusClient.chain('query')({
      products_by_pk: [
        {
          id: productId
        },
        {
          id: true,
          imageOneId: true,
          imageTwoId: true,
          imageThreeId: true,
          imageFourId: true,
        }
      ]
    })

    return result.products_by_pk as ModelTypes["products"];
  }

  async getTemplates(command: { height: string, width: string }): Promise<ModelTypes["pTemplate"][]> {
    const result = await this.zeusClient.chain('query')({
      pTemplate: [
        {
          where: {
            height: { _eq: command.height },
            width: { _eq: command.width },
            deletedAt: { _is_null: true },
          },
          order_by: [{ created_at: order_by.desc }]
        },
        {
          id: true,
          previewFileId: true,
        }
      ]
    });



    return result.pTemplate as ModelTypes["pTemplate"][];
  }

  async getFormats(): Promise<SizeObject[]> {
    const valuesArray: SizeObject[] = [
      { alias: '851 x 315', id: '315851', height: '315', width: '851' },
      { alias: '1584 x 396', id: '3961584', height: '396', width: '1584' },
      { alias: '1500 x 500', id: '5001500', height: '500', width: '1500' },
      { alias: '1200 x 628', id: '6281200', height: '628', width: '1200' },
      { alias: '510 x 660', id: '660510', height: '660', width: '510' },
      { alias: '1280 x 720', id: '7201280', height: '720', width: '1280' },
      { alias: '940 x 788', id: '788940', height: '788', width: '940' },
      { alias: '1600 x 900', id: '9001600', height: '900', width: '1600' },
      { alias: '816 x 1056', id: '1056816', height: '1056', width: '816' },
      { alias: '1080 x 1080', id: '10801080', height: '1080', width: '1080' },
      { alias: '1920 x 1080', id: '10801920', height: '1080', width: '1920' },
      { alias: '1200 x 1200', id: '12001200', height: '1200', width: '1200' },
      { alias: '2560 x 1440', id: '14402560', height: '1440', width: '2560' },
      { alias: '1000 x 1500', id: '15001000', height: '1500', width: '1000' },
      { alias: '1080 x 1920', id: '19201080', height: '1920', width: '1080' },
    ];

    return valuesArray;
  }

  async getMedia(): Promise<ModelTypes["userMedia"][]> {
    const result = await this.zeusClient.chain('query')({
      userMedia: [
        {
          where: { deleted: { _eq: false } },
        },
        {
          id: true,
          fileId: true,
          mediaType: true,
        }
      ]
    });

    return result.userMedia as ModelTypes["userMedia"][];
  }

}

export const ICreateProjectService = DI.createInterface<ICreateProjectService>((x) => x.singleton(CreateProjectService));
