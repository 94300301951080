import { ModelTypes } from '@zeus';
import { bindable } from 'aurelia';
import Toastify from 'toastify-js';
import { watch } from '@aurelia/runtime-html';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class ContactCardSection {
  @bindable website: ModelTypes['website']

  constructor(
    @IZeusClient private zeusClient: ZeusClient,
  ) { }

  @watch('website')
  async handleDealSubscription(newData: ModelTypes['website'], oldData: ModelTypes['website']) {
    if (newData && newData !== oldData) {
      this.website = newData;
    }
  }

  async handleSave() {
    try {
      const result = await this.zeusClient.chain('mutation')({
        update_brands_by_pk: [
          {
            pk_columns: { id: this.website.brandId },
            _set: {
              twitterUrl: this.website.brand.twitterUrl,
              facebookUrl: this.website.brand.facebookUrl,
              instagramUrl: this.website.brand.instagramUrl,
              linkedinUrl: this.website.brand.linkedinUrl,
              tiktokUrl: this.website.brand.tiktokUrl,
              youtubeUrl: this.website.brand.youtubeUrl,
              phoneNumber: this.website.brand.phoneNumber,
              whatsappNumber: this.website.brand.whatsappNumber,
              email: this.website.brand.email,
              addressStreet: this.website.brand.addressStreet,
              addressNumber: this.website.brand.addressNumber,
              addressUf: this.website.brand.addressUf,
              addressCity: this.website.brand.addressCity,
              addressZipCode: this.website.brand.addressZipCode,
              addressDistrict: this.website.brand.addressDistrict,
              addressLatitude: this.website.brand.addressLatitude,
              addressLongitude: this.website.brand.addressLongitude,
              shortAbout: this.website.brand.shortAbout,
            }
          },
          {
            id: true,
          }
        ]
      })

      if (!result) {
        throw new Error('Error saving data!')
      }

      Toastify({ text: 'Salvo com sucesso!', duration: 3000, gravity: 'top', position: 'right', backgroundColor: '#4CAF50', stopOnFocus: true }).showToast();
    } catch (error) {
      console.error(error)

      Toastify({ text: 'Erro ao salvar!', duration: 3000, gravity: 'top', position: 'right', backgroundColor: '#F44336', stopOnFocus: true }).showToast();
    }
  }
}