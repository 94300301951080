<!-- beggin::title -->
<div class="block w-full px-4 py-4 mb-6 card">

  <div class="flex flex-col md:flex-row items-start md:items-center justify-between gap-3 md:gap-0">

    <!-- begin::title -->
    <div class="flex flex-col">
      <div class="flex flex-row items-center justify-start text-gray-700 dark:text-gray-300 gap-2.5">
        <h5 class="text-xl font-bold">${(titleId ? titles[titleId][I18N.getLocale()].title : title) & signal:'parameterChanged'}</h5>
      </div>

      <p class="font-normal text-sm text-gray-700 dark:text-gray-400">${(titleId ? titles[titleId][I18N.getLocale()].subtitle : subtitle) & signal:'parameterChanged'}</p>
    </div>
    <!-- end::title -->

    <!-- begin::action -->
    <div class="ml-auto">
      <au-slot name="actions"></au-slot>
    </div>
    <!-- and::action -->
  </div>
</div>
<!-- end::title -->