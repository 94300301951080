<import from="@components/spinner-button/spinner-button-component" />

<div id="modal-colors-brand" data-modal-target="modal-colors-brand" data-modal-backdrop="static" aria-hidden="true"
  tabindex="-1"
  class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative w-full max-w-2xl max-h-full">

    <!-- begin::modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">

      <!-- begin::modal header -->
      <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">

        <div class="flex flex-col">
          <h5 class="text-xl font-semibold text-gray-900 dark:text-white">Prepare uma combinação de cores</h5>
          <p class="text-sm font-normal text-gray-700 dark:text-gray-400">As combinações de cores poderão ser escolhidas
            no momento de criação das artes</p>
        </div>

        <button type="button" data-modal-hide="modal-colors-brand" click.trigger="hideModal()"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <!-- end::modal header -->

      <!-- begin::modal body -->
      <div class="flex flex-col gap-6 p-6">

        <!-- beggin:inputs -->
        <div class="w-full flex flex-col">

          <div class="h-full flex gap-3">

            <!-- beggin::backgroundColor -->
            <div class="flex-1">
              <label for="colors" class="form-label">Cor do fundo</label>

              <label for="backgroundColor"
                class="inline-flex relative items-center justify-center w-full h-20 cursor-pointer text-gray-400 border border-gray-400 dark:text-gray-500 dark:border-gray-600 rounded-md group"
                style.bind="`background-color: ${backgroundColor ? backgroundColor : ''};`">
                <input type="color" id="backgroundColor" value.bind="backgroundColor" class="h-0 w-0 -z-50">
                <i if.bind="!backgroundColor" class="fa-solid fa-plus"></i>

                <span if.bind="backgroundColor" click.trigger="handleRemoveBackgroundColor(id)"
                  class="absolute hidden px-1 border rounded-md -top-1 -right-2 z-50 bg-danger-600 shadow-lg shadow-gray-600 text-xs text-white group-hover:block">
                  <i class="fa-solid fa-minus "></i>
                </span>
              </label>
            </div>
            <!-- end::backgroundColor -->

            <!-- beggin::foregroundColor -->
            <div class="flex-1">
              <label for="colors" class="form-label">Cor do texto</label>

              <label for="foregroundColor"
                class="inline-flex relative items-center justify-center w-full h-20 cursor-pointer text-gray-400 border border-gray-400 dark:text-gray-500 dark:border-gray-600 rounded-md group"
                style.bind="`background-color: ${foregroundColor ? foregroundColor : ''};`">
                <input type="color" id="foregroundColor" value.bind="foregroundColor" class="h-0 w-0 -z-50">
                <i if.bind="!foregroundColor" class="fa-solid fa-plus"></i>

                <span if.bind="foregroundColor" click.trigger="handleRemoveForegroundColor(id)"
                  class="absolute hidden px-1 border rounded-md -top-1 -right-2 z-50 bg-danger-600 shadow-lg shadow-gray-600 text-xs text-white group-hover:block">
                  <i class="fa-solid fa-minus "></i>
                </span>
              </label>
            </div>
            <!-- end::foregroundColor -->

          </div>

        </div>
        <!-- end:inputs -->

        <!-- beggin:outputs -->
        <div class="w-full h-fit">
          <div
            class="flex items-center justify-center border border-gray-400 dark:border-gray-600 rounded-md h-28 gap-3"
            style.bind="`background-color: ${backgroundColor ? backgroundColor : ''}; color: ${foregroundColor ? foregroundColor : 'gray'};`">
            <h3 class="text-2xl font-semibold italic">Cor da fonte</h3>
            <i class="fa-solid fa-droplet text-2xl"></i>
          </div>
        </div>
        <!-- end:outputs -->

        <div class="flex justify-end">
          <button type="button" class="btn-primary" click.trigger="handleAddColor()">Salvar</button>
        </div>
      </div>
      <!-- end::modal body -->

    </div>
    <!-- end::modal content -->
  </div>
</div>