import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class DomainOverviewPage {

  constructor(
    @IZeusClient private zeusClient: ZeusClient
  ) { }


  attached() {
    this.zeusClient.getSerpStatTestOutput().then((result) => {
      console.log(result);
    }
    );
  }
}