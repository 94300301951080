import { IRouter } from '@aurelia/router';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class InstagramSuccessPage {
  async loading(parameters: { code: any, state: any }) {
    if (parameters) {
      const { code, state } = parameters;

      await this.handleInstagramTokenCallback(code, state);
    }
  }

  constructor(
    @IZeusClient private zeusClient: ZeusClient,
    @IRouter private router: IRouter
  ) { }

  private async handleInstagramTokenCallback(code: string, brandId: string) {
    await this.zeusClient.instagramAuthCallback(code, brandId);
  }
}