<section class="mt-0 transition-all duration-200 ease-soft-in-out ps">
  <div
    class="pb-56 pt-12 m-4 min-h-[50vh] items-start rounded-xl p-0 relative overflow-hidden flex bg-cover bg-center bg-[url('assets/images/socialmedia3.png')]">
    <span
      class="absolute top-0 left-0 w-full h-full bg-center bg-cover opacity-60 bg-gradient-to-tl from-gray-900 to-slate-800"></span>
    <div class="w-full mx-auto px-6 z-50">
      <div class="flex flex-wrap justify-center -mx-3">
        <div class="w-full max-w-full px-3 mx-auto text-center shrink-0 lg:flex-0 lg:w-7/12">
          <h1 class="mb-3 text-white text-5xl font-bold">Welcome!</h1>
          <p class="mb-3 text-white text-base">Use these awesome forms to login or create new account in your project
            for free.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full mx-auto px-6">
    <!-- <div class="flex flex-wrap justify-center -mx-3 -mt-48 lg:-mt-48 md:-mt-56"> -->
    <div class="flex flex-wrap justify-center -mx-3 -mt-60 lg:-mt-56 md:-mt-56">
      <div class="w-full max-w-full px-3 mx-auto shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
        <!-- begin::card -->
        <div
          class="relative z-0 flex flex-col min-w-0 break-words bg-white border-0 py-4 dark:bg-gray-950 shadow-xl rounded-2xl bg-clip-border">

          <div class="text-center border-black/12.5 rounded-t-2xl border-b-0 border-solid px-6 pb-4">
            <h5 t="recoverPassword.recoverPassword"
              class="text-xl font-semibold leading-tight tracking-tight text-gray-700 md:text-2xl dark:text-white">
              Recover password</h5>
          </div>

          <div class="flex-auto p-6 text-start">
            <form class="space-y-4 md:space-y-6" submit.trigger="sendPasswordResetEmail()">

              <!-- beggin::alert -->
              <div if.bind="isMessageSent"
                class="flex p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"></path>
                </svg>
                <span class="sr-only">Info</span>
                <div>
                  <span class="font-medium">Sucesso:</span> enviamos um e-mail para recuperação de senha para seus
                  e-mail.
                </div>
              </div>
              <!-- end::alert -->

              <div>
                <label t="recoverPassword.yourEmail" for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                <validation-container>
                  <input type="email" name="email" id="email" autocomplete="off" value.bind="email & validate:'change'"
                    disabled.bind="isMessageSent"
                    class="bg-gray-50 border border-gray-400 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com" required>
                </validation-container>
              </div>

              <button
                disabled.bind="validationController.results.length == 0 || validationController.results.some((result) => result.valid === false)"
                type="submit" click.trigger="sendPasswordResetEmail()" class="btn-primary w-full">
                <span t="recoverPassword.recoverPassword" hidden.class="isLoading">Recover password</span>

                <span hidden.class="!isLoading">
                  <span t="recoverPassword.pleaseWait">Please wait...</span>
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor" />
                  </svg>
                </span>
              </button>

              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                <span t="recoverPassword.youRecoverThePassword">You recover the password?? </span> <span><a
                    t="recoverPassword.signIn" href="/login"
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</a></span>
              </p>
            </form>
          </div>
        </div>
        <!-- end::card -->
      </div>
    </div>
  </div>

</section>