import { ModelTypes } from '@zeus';
import { watch } from '@aurelia/runtime-html';
import { IDialogService } from '@aurelia/dialog';
import { TasksDialog } from './components/tasks-dialog';
import { ITasksService } from './services/tasks-service';
import { IOpportunityService } from '../../services/opportunity-service';
import { AlertModalComponent } from '@components/alert-modal/alert-modal-component';
import { IEventAggregator } from 'aurelia';
import { ToastComponent } from '@components/toast/toast-component';
import { ToastType } from '@components/toast/events/toast-event';
import { AlertModalType } from '@components/alert-modal/events/alert-modal-event';

export class TasksSection {
  private opportunity: ModelTypes["deal"];
  private tasks: ModelTypes["task"][] = [];

  constructor(
    @ITasksService private service: ITasksService,
    @IEventAggregator readonly ea: IEventAggregator,
    @IDialogService private dialogService: IDialogService,
    @IOpportunityService private opportunityService: IOpportunityService
  ) { }

  attached() {
    this.opportunity = this.opportunityService.opportunitySubscription;
    this.tasks = this.opportunity?.tasks;
  }

  @watch('opportunityService.opportunitySubscription')
  async handleDealSubscription(newData: ModelTypes['deal'], oldData: ModelTypes['deal']) {
    this.opportunity = newData;
    this.tasks = this.opportunity?.tasks;
  }

  private async resolveTask(task: ModelTypes['task'], event: Event): Promise<void> {
    event.stopPropagation();

    if (task.finished) {
      this.showMessage({ title: 'Informação', message: 'Tarefa ja resolvida', type: ToastType.INFO });

      return;
    }

    AlertModalComponent.show({
      event: this.ea,
      payload: {
        label: 'Sim',
        message: 'Deseja resolver a tarefa?',
        type: AlertModalType.SUCCESS,
        confirmFunction: async () => {
          try {
            const response = await this.service.setToFinish(task.id);

            if (!response) throw new Error('Erro ao resolver tarefa');

            this.showMessage({ title: 'Sucesso', message: 'Dados salvo com sucesso', type: ToastType.SUCCESS });
          } catch (error) {
            if (error instanceof Error) {
              this.showMessage({ title: 'Erro', message: error.message, type: ToastType.DANGER });
            } else {
              this.showMessage({ title: 'Erro', message: 'Algo deu errado, tente novamente mais tarde', type: ToastType.DANGER });
            }
          }
        },
      },
    });
  }

  private async openTaskDialog() {
    await this.dialogService.open({
      component: () => TasksDialog,
      model: { opportunityId: this.opportunity.id },
      lock: false,
      startingZIndex: 100,
    });
  }

  private showMessage({ title, message, type }: { title: string; message: string; type: ToastType }) {
    ToastComponent.show({
      event: this.ea,
      payload: {
        title: title,
        message,
        duration: 5000,
        type: type,
      },
    });
  }
}