<import from="@components/spinner-button/spinner-button-component" />

<div class="overflow-y-auto overflow-x-hidden z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
	<div class="relative p-4 w-full max-w-2xl max-h-full">
		<div class="relative bg-white shadow dark:bg-gray-700">

			<div class="p-6 text-center">
				<!-- icon -->
				<svg aria-hidden="true" class="mx-auto mb-4 text-gray-500 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
				</svg>

				<!-- message -->
				<h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Gostaria de avançar etapa?</h3>

				<!-- beggin::actions -->
				<div class="flex flex-row justify-center gap-2">
					<button click.trigger="closeModal()" data-modal-hide="alert-modal" type="button" class="btn-neutral">Cancelar</button>
					<spinner-button-component label="Avançar" trigger-function.bind="() => handleAdvanceStep()"></spinner-button-component>
				</div>
				<!-- end::actions -->
			</div>
		</div>
	</div>
</div>