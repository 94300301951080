import { valueConverter } from 'aurelia';

@valueConverter('phone')
export class PhoneNumberConverter {
  toView(value: string) {
    if(!value) return value;
      // Remove espaços e caracteres especiais que não são dígitos
      let numLimpo = value.replace(/[^0-9]/g, '');

      // Verifica se começa com o código do Brasil e remove
      if (numLimpo.startsWith('55')) {
          numLimpo = numLimpo.substring(2);
      }
  
      // Verifica o comprimento e formata adequadamente
      if (numLimpo.length === 10) {
          // Telefone sem o 9 inicial, formato antigo
          return `(${numLimpo.substring(0, 2)}) ${numLimpo.substring(2, 6)}-${numLimpo.substring(6)}`;
      } else if (numLimpo.length === 11) {
          // Telefone com o 9 inicial
          return `(${numLimpo.substring(0, 2)}) ${numLimpo.substring(2, 7)}-${numLimpo.substring(7)}`;
      } else {
          // Número não corresponde aos formatos esperados
          return 'N/A';
      }
  }
}