import { DI } from "aurelia";
import { ModelTypes } from '@zeus';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export interface IWebsiteService {
  websiteSubscription: ModelTypes['website']

  getWebsiteSubscription(): Promise<void>
}

export class WebsiteService implements IWebsiteService {
  public websiteSubscription: ModelTypes['website']

  constructor(
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient,
  ) { }

  public async getWebsiteSubscription(): Promise<void> {
    const query = await this.zeusClient.chain("query")({
      brands: [
        {
          limit: 1,
        },
        {
          id: true,
        }
      ]
    });

    if (!query || query?.brands?.length <= 0) return;

    const brandId = query.brands[0].id;

    const subscription = this.zeusClient.subscription("subscription")({
      website: [
        {
          where: { brandId: { _eq: brandId } },
        },
        {
          id: true,
          domain: true,
          backgroundColor: true,
          foregroundColor: true,
          accentColor: true,
          googleAnalyticsKey: true,
          googleTagManagerKey: true,
          homeHeroLabel: true,
          homeHeroTitle: true,
          homeHeroDescription: true,
          homeHeroCallToActionText: true,
          homeHeroBannerImageFileId: true,
          homeBrandHighlightTitle: true,
          homeBrandHighlightDescription: true,
          homeBrandHighlightKeypointTitleOne: true,
          homeBrandHighlightKeypointSubtitleOne: true,
          homeBrandHighlightKeypointTitleTwo: true,
          homeBrandHighlightKeypointSubtitleTwo: true,
          homeBrandHighlightKeypointValuePropositionList: true,
          homeBrandHighlightBannerImageFileId: true,
          homeWhatWeDoTitle: true,
          homeWhatWeDoDescription: true,
          homeWhatWeDoCallToActionText: true,
          homeWhatWeDoCallToActionTargetUrl: true,
          homeWhatWeDoCallToBannerOneLegend: true,
          homeWhatWeDoCallToBannerOneImageFileId: true,
          homeWhatWeDoCallToBannerTwoLegend: true,
          homeWhatWeDoCallToBannerTwoImageFileId: true,
          homeWhatWeDoCallToBannerThreeLegend: true,
          homeWhatWeDoCallToBannerThreeImageFileId: true,
          aboutUsHistoryTitle: true,
          aboutUsHistoryDescription: true,
          aboutUsHistorytKeypointTitleOne: true,
          aboutUsHistorytKeypointSubtitleOne: true,
          aboutUsHistorytKeypointTitleTwo: true,
          aboutUsHistorytKeypointSubtitleTwo: true,
          aboutUsHistoryBannerImageFileId: true,
          aboutUsWhatMoveUsTitle: true,
          aboutUsWhatMoveUsMission: true,
          aboutUsWhatMoveUsVision: true,
          aboutUsWhatMoveUsValues: true,
          aboutUsWhatMoveUsPurpose: true,
          aboutUsWhatMoveBannerImageFileId: true,
          aboutUsStatementBannerText: true,
          aboutUsStatementBannerImageFileId: true,
          aboutUsWeAreReadyTitle: true,
          aboutUsWeAreReadyBannerImageId: true,
          aboutUsWeAreReadySubjectMatterExpertiseDescription: true,
          aboutUsWeAreReadySpecializedSupportDescription: true,
          aboutUsWeAreReadyTransparencyDescription: true,
          aboutUsWeAreReadyInnovationDescription: true,
          aboutUsWeAreReadyCustomerSatisfactionDescription: true,
          aboutUsWeAreReadySocialResponsibilityDescription: true,
          aboutUsContactTitle: true,
          aboutUsContactBannerImageFileId: true,
          homeOurDifferentiatorsTitle: true,
          homeOurDifferentiatorsDescription: true,
          homeOurDifferentiatorsKeypointTitleOne: true,
          homeOurDifferentiatorsKeypointSubtitleOne: true,
          homeOurDifferentiatorsKeypointTitleTwo: true,
          homeOurDifferentiatorsKeypointSubtitleTwo: true,
          homeOurDifferentiatorsBannerImageFileId: true,
          openGraphDescription: true,
          brandId: true,
          brand: {
            id: true,
            brandName: true,
            logoFileId: true,
            twitterUrl: true,
            facebookUrl: true,
            instagramUrl: true,
            linkedinUrl: true,
            tiktokUrl: true,
            youtubeUrl: true,
            phoneNumber: true,
            whatsappNumber: true,
            email: true,
            addressStreet: true,
            addressNumber: true,
            addressComplement: true,
            addressUf: true,
            addressCity: true,
            addressZipCode: true,
            addressLatitude: true,
            addressLongitude: true,
            addressDistrict: true,
            shortAbout: true,
            products: [
              {},
              {
                id: true,
                alias: true,
                description: true,
                characteristics: [{ path: '$', }, true],
                imageOneId: true,
                price: true,
                salePrice: true,
              }
            ]
          }
        }
      ]
    });

    subscription.error((error) => { console.error('Website Subscription: ', error) });

    subscription.on(({ website }: { website: ModelTypes['website'][] }) => {
      if (!website || website.length <= 0) return;

      const data = website[0];

      console.log('Website Subscription: ', data);

      this.websiteSubscription = data;
    });
  }

}

export const IWebsiteService = DI.createInterface<IWebsiteService>((x) => x.singleton(WebsiteService));