<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Sobre a Empresa">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <div class="flex-1">
          <input-component type="text" label="Título da Sessão Sobre a Empresa"
            value.bind="website.homeWhatWeDoTitle" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Descrição da Sessão Sobre a Empresa"
            value.bind="website.homeWhatWeDoDescription" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Convite Para Ação" value.bind="website.homeWhatWeDoCallToActionText" />
        </div>

        <div class="flex-1">
          <input-component type="text" label="Link de Redirecionamento da Ação"
            value.bind="website.homeWhatWeDoCallToActionTargetUrl" />
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div class="row">
          <div class="flex-1">
            <single-file-upload-component label="Banner #1"
              selected-file-id.bind="website.homeWhatWeDoCallToBannerOneImageFileId"></single-file-upload-component>
          </div>
          <div class="flex-1 my-2">
            <input-component type="text" label="Legenda do Banner #1"
              value.bind="website.homeWhatWeDoCallToBannerOneLegend" />
          </div>
        </div>
        <div class="row">
          <div class="flex-1">
            <single-file-upload-component label="Banner #2"
              selected-file-id.bind="website.homeWhatWeDoCallToBannerTwoImageFileId"></single-file-upload-component>
          </div>
          <div class="flex-1 my-2">
            <input-component type="text" label="Legenda do Banner #2"
              value.bind="website.homeWhatWeDoCallToBannerTwoLegend" />
          </div>
        </div>
        <div class="row">
          <div class="flex-1">
            <single-file-upload-component label="Banner #3"
              selected-file-id.bind="website.homeWhatWeDoCallToBannerThreeImageFileId"></single-file-upload-component>
          </div>
          <div class="flex-1 my-2">
            <input-component type="text" label="Legenda do Banner #3"
              value.bind="website.homeWhatWeDoCallToBannerThreeLegend" />
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20"
          trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>