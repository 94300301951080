import { IEventAggregator } from 'aurelia';
import { Modal, type ModalOptions, type ModalInterface } from 'flowbite';
import { ColorsModalEvent, IColorsCallback, IColorsModalEvent } from './events/colors-modal-event';

export class ColorsModalComponent {
  private modal: ModalInterface;

  private id: string;
  private backgroundColor: string;
  private foregroundColor: string;
  private modalSubscription: any;

  private addColor: (callback: IColorsCallback) => void;

  constructor(@IEventAggregator readonly ea: IEventAggregator) { }

  attached() {
    const $modalElement: HTMLElement = document.querySelector('#modal-colors-brand');

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
      closable: true,
    }

    this.modal = new Modal($modalElement, modalOptions);

    this.modalSubscription = this.ea.subscribe(ColorsModalEvent.SHOW, (payload: IColorsModalEvent) => {

      this.id = payload.id;
      this.backgroundColor = payload.backgroundColor;
      this.foregroundColor = payload.foregroundColor;
      this.addColor = payload.addColor;

      this.showModal();
    });
  }

  private handleAddColor(): void {
    this.addColor({ id: this.id, backgroundColor: this.backgroundColor, foregroundColor: this.foregroundColor });

    this.hideModal();
  }

  private handleRemoveBackgroundColor(): void {
    this.backgroundColor = null;
  }

  private handleRemoveForegroundColor(): void {
    this.foregroundColor = null;
  }

  private showModal(): void {
    this.modal.show();
  }

  private hideModal(): void {
    this.modal.hide();
  }

  static show({ event, payload }: { event: IEventAggregator, payload: IColorsModalEvent }): void {
    event.publish(ColorsModalEvent.SHOW, payload);
  }

  detaching() {
    this.modalSubscription.dispose();
  }
}