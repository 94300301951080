import { I18N } from "@aurelia/i18n";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';
import { bindable } from 'aurelia';
import { initFlowbite, Modal, ModalInterface, ModalOptions } from 'flowbite';
import Quill, { QuillOptionsStatic } from 'quill';

import 'quill/dist/quill.snow.css';

export class QuillComponent {
  @bindable content: { html?: string; text?: string; };

  quillId = crypto.randomUUID();

  quill: Quill;
  imageModal: ModalInterface;
  currentImageTabIndex = 0;
  selectedImageId: string;
  pexelImages: any[] = [];
  selectedImageUrl: string;

  private callTriggerAIImageSearch: boolean = false;
  private refresher: number;
  private aiImageSearchQuery: string;

  constructor(
    @I18N private I18N: I18N,
    @IZeusClient private zeusClient: ZeusClient
  ) { }

  contentChanged(newValue: { html?: string; text?: string; }, oldValue: { html?: string; text?: string; }) {
    if (newValue) {
      this.setContent(newValue);
    }
  }

  attached() {
    initFlowbite();

    (window as any).Quill = Quill;

    const ImageResize = require('quill-image-resize-module').default
    Quill.register('modules/imageResize', ImageResize);

    this.quill = new Quill(`#kt_inbox_form_editor-${this.quillId}`, {
      modules: {
        imageResize: {
          displaySize: true
        },
        toolbar: [
          [{
            header: [1, 2, false]
          }],
          ['bold', 'italic', 'underline'],
          ['image', 'code-block'],
          //aligment
          [{
            align: ''
          }, {
            align: 'center'
          }, {
            align: 'right'
          }, {
            align: 'justify'
          }],
          [{
            list: 'ordered'
          }, {
            list: 'bullet'
          }],
          ['clean'],
          ['link'],
        ]
      },
      placeholder: '',
      theme: 'snow' // or 'bubble'
    });

    var toolbar = this.quill.getModule('toolbar');

    toolbar.addHandler('image', () => {
      const $modalEl: HTMLElement = document.getElementById('kt_modal_2');

      const modalOptions: ModalOptions = {
        placement: 'top-center',
        backdrop: 'dynamic',
        backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        closable: true,
      }

      this.imageModal = new Modal($modalEl, modalOptions);
      this.imageModal.show();
    });

    this.setContent(this.content);
  }

  hideModal() {
    this.imageModal.hide();
  }

  setContent(content: { html?: string; text?: string; }) {
    // this.quill.setText(value);

    const html = this.quill.clipboard.convert(content);
    this.quill.setContents(html);
  }

  selectPexelsImage(id: string) {
    if (this.selectedImageId === id) {
      this.selectedImageId = null;
      return;
    }

    this.selectedImageId = id;
  }

  insertImage() {
    const range = this.quill.getSelection(true);

    if (this.currentImageTabIndex === 0) {
      this.quill.insertEmbed(range.index, 'image', this.selectedImageUrl, Quill.sources.USER);
      this.imageModal.hide();
    } else if (this.currentImageTabIndex === 1) {
      const image = this.pexelImages.find(i => i.id === this.selectedImageId);
      this.quill.insertEmbed(range.index, 'image', image.url, Quill.sources.USER);
      this.imageModal.hide();
    }
  }

  filesChanged(sevent: { target: { files: Blob[]; }; }) {
    if (sevent.target.files) {
      const reader = new FileReader();

      reader.readAsDataURL(sevent.target.files[0]);

      reader.onloadend = () => {
        const base64data = reader.result;
        this.selectedImageUrl = base64data as string;
      }
    }
  }

  changeCurrentImageTabIndex(index: number) {
    this.currentImageTabIndex = index;
  }

  async searchImagesFromPexels(query: string) {
    const result = await this.zeusClient.searchImagesFromPexels({ query, locale: this.I18N.getLocale(), orientationType: 'landscape', page: 1 });

    this.selectedImageId = null;
    this.pexelImages = result.images;

    return result;
  }

  private triggerAIImageSearch() {
    this.callTriggerAIImageSearch = true;

    this.refresher = Math.random();
  }

  async generateImageFromPrompt() {
    const result = await this.zeusClient.generateImageFromPrompt(this.aiImageSearchQuery)

    return result
  }
}