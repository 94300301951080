<import from="@components/loading/loading-component" />

<import from="./sections/contact-card/contact-card-section" />

<div class="flex flex-col gap-4 my-6">

  <template if.bind="website">
    <contact-card-section website.bind="website"></contact-card-section>
  </template>

  <template else>
    <loading-component></loading-component>
  </template>

</div>