import { IRouter } from "@aurelia/router";
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class WordpressSuccessPage {
  finalizing: boolean = true;
  isSuccess: boolean = false;

  constructor(
    @IRouter private router: IRouter,
    @IZeusClient private zeusClient: ZeusClient
  ) { }

  async load(parameters) {
    if (parameters) {

      const { brandId } = parameters;

      if (parameters.success == 'false') {
        this.isSuccess = false;
      } else if (parameters.error) {
        this.isSuccess = false;
      } else {
        this.isSuccess = true;

        try {
          await this.zeusClient.insertUserAppIntegration('wordpress', brandId, parameters);
          this.finalizing = false

        } catch (error) {
          console.log(parameters)
        }
      }
    }
  }
}