<import from="@components/form/input/input-component" />
<import from="@components/accordion/accordion-component" />
<import from="@components/spinner-button/spinner-button-component" />
<import from="@components/upload/new-single-file-upload/single-file-upload-component" />

<accordion-component title="Formulário de Contato">
  <div au-slot="body">
    <form class="flex flex-col gap-4" autocomplete="off">

      <div class="flex-1">
        <input-component type="text" label="Título do Formulário de Contato" value.bind="website.aboutUsContactTitle" />
      </div>

      <div class="flex-1">
        <single-file-upload-component label="Banner do Formulário de Contato"
          selected-file-id.bind="website.aboutUsContactBannerImageFileId"></single-file-upload-component>
      </div>

      <div class="flex flex-row justify-end mt-4">
        <spinner-button-component label="Salvar" btn-class="btn-primary-small min-w-20"
          trigger-function.bind="() => handleSave()"></spinner-button-component>
      </div>

    </form>
  </div>
</accordion-component>