import { User } from '@nhost/nhost-js';
import { IEventAggregator, bindable } from 'aurelia';
import { AlertModalComponent } from '@components/alert-modal/alert-modal-component';
import { AlertModalType } from '@components/alert-modal/events/alert-modal-event';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { newInstanceForScope } from '@aurelia/kernel';
import { IValidationRules } from '@aurelia/validation';
import { IValidationController } from '@aurelia/validation-html';
import { nhost } from 'lib/nhost';
import { ModelTypes } from '../../../../../../../utils/generated/zeus';
import { IStorageService } from '@interfaces/storage/storage-interface';
import { companySegmentData, expectationData, marketingObjectiveData, productOrServiceData, socialNetworksData, staffCountData, yesOrNoData } from 'common/data';
import { IZeusClient, ZeusClient } from '@services/graphql-service/zeus-client';

export class UserDataComponent {
  @bindable avatarFile: File;
  @bindable avatarFileId: string;

  private user: User;

  private name: string;
  private email: string;
  private phone: string;
  private brandName: string;
  private siteUrl: string;
  private companySegment: string;
  private productOrService: string;
  private staffCount: string;
  private marketingObjective: string;
  private expectation: string;
  private sellOnCopyMagico: string;
  private networkingOnCopyMagico: string;
  private importantSocialNetworkForBusiness: string;
  private investInPaidChannels: string;

  private companySegmentData = companySegmentData;
  private staffCountData = staffCountData;
  private expectationData = expectationData;
  private productOrServiceData = productOrServiceData;
  private marketingObjectiveData = marketingObjectiveData;
  private yesOrNoData = yesOrNoData;
  private socialNetworksData = socialNetworksData;

  constructor(
    @IAuthService private auth: IAuthService,
    @IZeusClient private zeusClient: ZeusClient,
    @IEventAggregator readonly ea: IEventAggregator,
    @IStorageService private storageService: IStorageService,
    @IValidationRules readonly validationRules: IValidationRules,
    @newInstanceForScope(IValidationController) readonly validationController: IValidationController
  ) {
    validationRules
      .on(this)
      .ensure('avatar')
      .required().withMessage('Selecione uma avatar')
      .ensure('name')
      .required()
      .ensure('phone')
      .required()
      .ensure('brandName')
      .required()
      .ensure('siteUrl')
      .required()
      .ensure('companySegment')
      .required()
      .ensure('staffCount')
      .required()
      .ensure('expectation')
      .required()
      .ensure('productOrService')
      .required()
      .ensure('marketingObjective')
      .required()
      .ensure('importantSocialNetworkForBusiness')
      .required()
      .ensure('sellOnCopyMagico')
      .required()
      .ensure('networkingOnCopyMagico')
      .required()
      .ensure('investInPaidChannels')
      .required()
  }

  async attached() {
    this.user = this.auth.getUser();

    await this.loadData();
  }

  private async loadData(): Promise<void> {
    const [userOnboarding, file] = await Promise.all([this.getUserOnboarding(), this.getFileById()]);

    this.email = this.user.email;

    this.name = userOnboarding?.name;
    this.phone = userOnboarding?.phone;
    this.brandName = userOnboarding?.brandName;
    this.siteUrl = userOnboarding?.siteUrl;
    this.companySegment = userOnboarding?.companySegment;
    this.productOrService = userOnboarding?.productOrService;
    this.staffCount = userOnboarding?.staffCount;

    this.marketingObjective = userOnboarding?.marketingObjective;
    this.expectation = userOnboarding?.expectation;
    this.sellOnCopyMagico = userOnboarding?.sellOnCopyMagico;
    this.networkingOnCopyMagico = userOnboarding?.networkingOnCopyMagico;
    this.importantSocialNetworkForBusiness = userOnboarding?.importantSocialNetworkForBusiness;
    this.investInPaidChannels = userOnboarding?.investInPaidChannels;

    if (!file) {
      const uploadResponse = await this.storageService.upload({
        id: this.user.id,
        data: this.user.avatarUrl,
        name: 'avatar',
        bucketId: 'public'
      });

      const publicUrl = nhost.storage.getPublicUrl({
        fileId: uploadResponse.id,
      });

      await this.zeusClient.updateUser({
        id: this.user.id,
        displayName: this.name,
        avatarUrl: publicUrl
      });
    } else {
      await this.zeusClient.updateUser({
        id: this.user.id,
        displayName: this.name,
      });
    }

    this.avatarFileId = file.id;
  }

  private async getUserOnboarding(): Promise<ModelTypes["userOnboarding"]> {
    const response = await this.zeusClient.getUserOnboarding();

    return response;
  }

  private async getFileById(): Promise<ModelTypes["files"]> {
    const response = await this.zeusClient.getFileById(this.user.id);

    return response;
  }

  async handleUpdateUserData() {
    const result = await this.validationController.validate();

    if (!result.valid) return;

    AlertModalComponent.show({
      event: this.ea,
      payload: {
        label: 'Salvar conta',
        message: 'Gostaria de salvar conta?',
        type: AlertModalType.SUCCESS,
        toastMessage: 'Conta salva com sucesso!',
        confirmFunction: async () => {

          const { userId } = await this.zeusClient.saveUserData({
            userId: this.auth.getUser().id,
            name: this.name,
            phone: this.phone,
            brandName: this.brandName,
            siteUrl: this.siteUrl,
            companySegment: this.companySegment,
            staffCount: this.staffCount,
            expectation: this.expectation,
            productOrService: this.productOrService,
            marketingObjective: this.marketingObjective,
            importantSocialNetworkForBusiness: this.importantSocialNetworkForBusiness,
            sellOnCopyMagico: this.sellOnCopyMagico,
            networkingOnCopyMagico: this.networkingOnCopyMagico,
            investInPaidChannels: this.investInPaidChannels,
          });

          if (userId) {

            if (!this.avatarFile) return;

            const isDeleted = await this.storageService.deleteById(userId);

            if (!isDeleted) return;

            const uploadResponse = await this.storageService.upload({
              id: userId,
              data: this.avatarFile,
              name: 'avatar',
              bucketId: 'public'
            });

            const publicUrl = nhost.storage.getPublicUrl({ fileId: uploadResponse.id });

            await this.zeusClient.updateUser({ id: userId, avatarUrl: publicUrl + '?' + new Date().getTime() });
          }
        }
      }
    });
  }
}