import { ModelTypes } from '@zeus';
import { IOpportunityService } from './services/opportunity-service';


export class OpportunityPage {
  private opportunityId: string;

  constructor(@IOpportunityService private service: IOpportunityService) { }

  async loading(params: { id: string; }) {
    this.opportunityId = params.id;
  }

  attached() {
    this.getOpportunityByIdSubscription();
  }

  private getOpportunityByIdSubscription() {
    if (!this.opportunityId) return;

    this.service.getOpportunityByIdSubscription(this.opportunityId);
  }
}