<import from='./../../components/header/header-component' />
<import from='@components/promise-bind/pending/pending-component' />
<import from='./components/image-carousel/image-carousel-component' />

<section class="p-4 flex flex-col justify-between h-full">

  <div class="flex flex-col">
    <header-component title="Produto" subtitle="Detalhes do produto de interesse"></header-component>

    <div class="flex flex-col gap-3 mt-2">
      <div class="flex flex-row gap-1">
        <div class="flex flex-col flex-1">
          <label for="name" class="form-label">Nome</label>
          <span class="form-input">${product.alias ??
            'N/A'}</span>
        </div>
      </div>

      <div class="flex flex-row gap-1">
        <div class="flex flex-col flex-1">
          <label for="name" class="form-label">URL</label>
          <span class="form-input">${product.linkConversion ??
            'N/A'}</span>
        </div>
      </div>

      <div class="flex flex-row gap-1">
        <div class="flex flex-col flex-1">
          <label for="name" class="form-label">Preço De:</label>
          <span class="form-input">${product.price | currency}</span>
        </div>

        <div class="flex flex-col flex-1">
          <label for="name" class="form-label">Preço Por:</label>
          <span class="form-input">${product.salePrice | currency}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-3 mt-3">
    <image-carousel-component show.bind="product" type.bind="product.type"></image-carousel-component>
  </div>

</section>