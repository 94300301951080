<button id="status-bar-dropdown-button" type="button" class="btn-primary-small">Mudar estágio</button>

<div id="status-bar-dropdown-menu" class="z-40 hidden text-sm text-neutral-700 dark:text-gray-200 bg-white divide-y divide-neutral-100 dark:bg-neutral-700 dark:divide-neutral-600 rounded-lg shadow w-80" data-popper-placement="bottom">
  <ul class="py-2">
    <span class="px-4 py-2.5 text-base font-bold">Mudar estágio</span>
  </ul>

  <ul class="py-2" aria-labelledby="dropdownUserMenuButton">
    <li repeat.for="item of dealStatusData">
      <a click.trigger="handleAdvancedStep(item.id)" class="flex items-center p-4 hover:bg-neutral-100 dark:hover:bg-gray-600 dark:hover:text-white font-normal gap-2 cursor-pointer" role="menuitem">
        <i class="fa-solid fa-up-right-from-square"></i>
        ${item.text}
      </a>
    </li>
  </ul>
</div>