import { IRouteableComponent, routes } from "@aurelia/router";

import { AboutModule } from './modules/about/about-module';
import { BlogModule } from './modules/blog/blog-module';
import { ContactModule } from './modules/contact/contact-module';
import { HomeModule } from './modules/home/home-module';
import { ProductModule } from './modules/product/product-module';
import { IWebsiteService } from './services/website-service';
import { OthersModule } from './modules/others/others-module';

@routes([
  {
    path: ['/home', ''],
    title: 'Página Inicial',
    component: HomeModule,
    data: {
      private: true,
    }
  },
  {
    path: '/about',
    title: 'Sobre',
    component: AboutModule,
    data: {
      private: true,
    }
  },
  {
    path: '/product',
    title: 'Produtos',
    component: ProductModule,
    data: {
      private: true,
    }
  },
  {
    path: '/contact',
    title: 'Contato',
    component: ContactModule,
    data: {
      private: true,
    }
  },
  {
    path: '/blog',
    title: 'Blog',
    component: BlogModule,
    data: {
      private: true,
    }
  },
  {
    path: '/settings',
    title: 'Settings',
    component: OthersModule,
    data: {
      private: true,
    }
  }
])

export class WebsiteConfig implements IRouteableComponent {
  constructor(@IWebsiteService private websiteService: IWebsiteService) { }

  loading() {
    this.websiteService.getWebsiteSubscription();
  }
}
