import Tagify from '@yaireo/tagify'

import '@yaireo/tagify/dist/tagify.css'
import { bindable } from 'aurelia';

export class TagifyComponent {
  @bindable tagify: Tagify;

  attached() {
    this.tagify = new Tagify(document.querySelector('#tags'), {
      enforceWhitelist: true,
      whitelist: [],
      dropdown: {
        maxItems: 20,           // <- mixumum allowed rendered suggestions
        classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
        enabled: 0,             // <- show suggestions on focus
        closeOnSelect: false    // <- do not hide the suggestions dropdown once an item has been selected
      }
    })
  }

  detached() {
    this.tagify.destroy();
  }
}