<!-- begin::drawer -->
<aside class="flex">
  <div id="drawer-event" class="flex flex-col bg-white rounded-lg dark:bg-gray-700 dark:text-gray-200 overflow-y-auto p-0 shadow-soft-xl inset-y-0 m-4 border-0 antialiased fixed top-0 right-0 z-40 w-[470px] transition-transform duration-500 translate-x-full h-[calc(100vh-32px)] hide-scrollbar" tabindex="-1" aria-labelledby="drawer-event">

    <!-- begin::header -->
    <div class="flex flex-col p-4 border-0 bg-gray-50 dark:bg-gray-800 text-gray-500 dark:text-gray-50">
      <div class="flex items-center justify-between">
        <h2 class="text-lg font-medium">Evento</h2>
      </div>
    </div>
    <!-- end::header -->

    <!-- begin::content -->
    <div class="flex flex-col p-4 gap-4">

      <div class="flex-1">
        <label for="alias" class="form-label">Alias:</label>
        <input type="text" id="alias" value.bind="project.alias" class="form-input" readonly>
      </div>

      <div class="flex-1">
        <label for="type" class="form-label">Type:</label>
        <input type="text" id="type" value.bind="project.type" class="form-input" readonly>
      </div>

      <div class="flex-1">
        <label for="status" class="form-label">Status:</label>
        <input type="text" id="status" value.bind="project.status" class="form-input" readonly>
      </div>

      <div class="flex-1">
        <label for="created_at" class="form-label">Criado:</label>
        <input type="text" id="created_at" value.bind="project.created_at | date:pt-BR" class="form-input" readonly>
      </div>

      <div class="flex-1">
        <label for="caption" class="form-label">Caption:</label>
        <textarea type="text" id="caption" value.bind="project.caption" rows="4" class="form-input h-[calc(100%-2rem)]" readonly></textarea>
      </div>

    </div>
    <!-- end::content -->

  </div>
</aside>
<!-- end::drawer -->