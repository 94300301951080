<import from="@converters/currency-format-converter" />
<import from="@converters/credit-to-brl-format-converter" />

<div class="flex items-center bg-gray-100 rounded-full px-4 py-1.5 mx-4 gap-4" data-popover-target="popover-credit" data-popover-placement="bottom-end">

  <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="coin">
    <path fill="#58bf97" d="M25 20h20c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H10"></path>
    <path fill="#38a378" d="M25 38h15c0-1.7 1.3-3 3-3v-8c-1.7 0-3-1.3-3-3H12"></path>
    <path fill="#38a378" d="M28 20h-2v2c0 .6-.4 1-1 1h-2l-1.4 2H25c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1h-5c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1h-5c-.6 0-1 .4-1 1s.4 1 1 1h4c.5 0 1 .4 1 1v2c0 .6-.4 1-1 1H13c-.6 0-1 .4-1 1h15.2c.5-.5.8-1.2.8-2v-2c0-.5-.2-1-.4-1.5.8-.5 1.4-1.4 1.4-2.5v-2c0-1.1-.6-2-1.4-2.5.2-.5.4-1 .4-1.5v-2c0-.8-.3-1.5-.8-2 .5-.5.8-1.2.8-2v-2z">
    </path>
    <path fill="#010101" d="M40 39H25c-.6 0-1-.4-1-1s.4-1 1-1h14.1c.4-1.4 1.5-2.5 2.9-2.9v-6.2c-1.4-.4-2.5-1.5-2.9-2.9H12c-.6 0-1-.4-1-1s.4-1 1-1h28c.6 0 1 .4 1 1 0 1.1.9 2 2 2 .3 0 .5.1.7.3s.3.5.3.7v8c0 .6-.4 1-1 1-1.1 0-2 .9-2 2 0 .3-.1.5-.3.7-.2.2-.5.3-.7.3z">
    </path>
    <path fill="#308c66" d="M21.6 25H25c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1h-5c-.6 0-1 .4-1 1s.4 1 1 1h6c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1h-3.8l1.9 2h.9c.5 0 1 .4 1 1h2c0-.5-.2-1-.4-1.4.8-.6 1.4-1.5 1.4-2.6v-2c0-1.1-.6-2-1.4-2.5.2-.5.4-1 .4-1.5v-2c0-.8-.3-1.5-.8-2h-5l-.6 1z">
    </path>
    <path fill="#58bf97" d="M26 27.5c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5"></path>
    <path fill="#38a378" d="M29 32.8V32c0-1.1-.6-2-1.4-2.5.2-.4.4-.9.4-1.3-.6-.4-1.3-.6-2-.6v.4c0 .6-.4 1-1 1h-.4l-.6.4 2 1.6c.6 0 1 .4 1 1v2c0 .1 0 .2-.1.4.9-.3 1.6-.9 2.1-1.6z">
    </path>
    <path fill="#fec367" d="M24 30h-5v6l-8 6h12c1.1 0 2-.9 2-2v-2c0-1.1-.9-2-2-2h1c1.1 0 2-.9 2-2v-2c0-1.1-.9-2-2-2zM13 24l6 6h4c1.1 0 2-.9 2-2v-2c0-1.1-.9-2-2-2 1.1 0 2-.9 2-2v-2c0-1.1-.9-2-2-2 1.1 0 2-.9 2-2v-2c0-1.1-.9-2-2-2 1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2H11c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h2z">
    </path>
    <path fill="#f8ac4e" d="M20 33c0 4-2.3 7.4-5.7 9h3.6c2.5-2.2 4.1-5.4 4.1-9 0-1-.1-2-.4-3h-2.1c.3.9.5 2 .5 3zM10 21c-.3 0-.7 0-1 .1v.9c0 .4.1.7.3 1h.7c4.5 0 8.3 2.9 9.5 7h2.1c-1.3-5.2-6-9-11.6-9z">
    </path>
    <circle cx="10" cy="33" r="9" fill="#fec367"></circle>
    <path fill="#010101" d="M10 39c.6 0 1-.4 1-1v-.2c1.2-.4 2-1.5 2-2.8 0-1.7-1.3-3-3-3-.5 0-1-.5-1-1s.4-1 1-1c.5 0 1 .5 1 1 0 .6.4 1 1 1s1-.4 1-1c0-1.3-.8-2.4-2-2.8V28c0-.6-.4-1-1-1s-1 .4-1 1v.2c-1.2.4-2 1.5-2 2.8 0 1.7 1.3 3 3 3 .5 0 1 .5 1 1s-.4 1-1 1c-.5 0-1-.5-1-1 0-.6-.4-1-1-1s-1 .4-1 1c0 1.3.8 2.4 2 2.8v.2c0 .6.5 1 1 1z">
    </path>
    <circle cx="38" cy="31" r="2" fill="#010101"></circle>
    <path fill="#010101" d="M45 19H25.8c-.1-.4-.3-.7-.6-1 .5-.5.8-1.2.8-2v-2c0-.8-.3-1.5-.8-2 .5-.5.8-1.2.8-2V8c0-1.7-1.3-3-3-3H11C9.3 5 8 6.3 8 8v2c0 .8.3 1.5.8 2-.5.5-.8 1.2-.8 2v2c0 .8.3 1.5.8 2-.5.5-.8 1.2-.8 2v2c0 .4.1.8.2 1.2C3.6 24 0 28.1 0 33c0 5.5 4.5 10 10 10h35c1.7 0 3-1.3 3-3V22c0-1.7-1.3-3-3-3zM10 8c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v2c0 .5-.4 1-1 1H11c-.5 0-1-.5-1-1V8zm0 6c0-.5.4-1 1-1h12c.5 0 1 .5 1 1v2c0 .6-.4 1-1 1H11c-.6 0-1-.4-1-1v-2zm0 6c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1H11c-.6 0-1-.4-1-1v-2zm6 5h7c.5 0 1 .5 1 1v2c0 .6-.4 1-1 1h-3.8c-.7-1.6-1.8-3-3.2-4zM2 33c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm22 7c0 .6-.4 1-1 1h-7c1.4-1 2.5-2.4 3.2-4H23c.5 0 1 .5 1 1v2zm1-6c0 .6-.4 1-1 1h-4.2c.1-.6.2-1.3.2-2s-.1-1.4-.2-2H24c.6 0 1 .4 1 1v2zm.6-4.5c.2-.3.3-.6.4-1h.1c1.4 0 2.5 1.1 2.5 2.5 0 1-.6 1.9-1.5 2.3V32c-.1-1.1-.7-2-1.5-2.5zm0 7c.4-.3.8-.6 1-1.1 2.2-.3 3.9-2.2 3.9-4.4 0-2.5-2-4.5-4.5-4.5V26c0-.3-.1-.7-.2-1h13.3c.4 1.4 1.5 2.5 2.9 2.9v6.2c-1.4.4-2.5 1.5-2.9 2.9H25.8c-.1-.2-.1-.3-.2-.5zM46 40c0 .6-.4 1-1 1H25.8c.1-.3.2-.6.2-1v-1h14c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-1.1.9-2 2-2 .6 0 1-.4 1-1v-8c0-.3-.1-.5-.3-.7s-.4-.3-.7-.3c-1.1 0-2-.9-2-2 0-.6-.4-1-1-1H25.8c.1-.3.2-.7.2-1v-1h19c.6 0 1 .4 1 1v18z">
    </path>
  </svg>

  <!-- Remaining credits count -->
  <span class="text-sm text-gray-800 font-semibold">${creditBalance | creditToBRL | currency:'pt-BR'}</span>
</div>

<div data-popover id="popover-credit" role="tooltip" class="hidden md:inline-block absolute w-64 z-30 invisible text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
  <div class="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
    <h3 class="font-semibold text-gray-900 dark:text-white">Seus créditos</h3>
  </div>
  <div class="px-3 py-2 text-xs">
    <p>Você tem <strong>${creditBalance | creditToBRL | currency:'pt-BR'}</strong> em créditos grátis para usar. Quando
      os créditos acabarem, você poderá habilitar o modo "Pague quando usar", ou seja, você só será cobrando quando
      criar conteúdos.</p>
    <a href="/my-account" class="block btn-primary-small m-2">Cadastrar Dados</a>

  </div>
  <div data-popper-arrow></div>
</div>