import { bindable } from 'aurelia';
import { IRouter, Route } from '@aurelia/router';
import { initDropdowns, initCollapses, initDrawers } from 'flowbite';
import { IAuthService } from '@interfaces/auth-service/auth-service-interface';
import { INavBarStructure } from 'common/helpers/router/router-navigation-helper';

export class SidebarComponent {
  @bindable open: boolean;

  private routes: Route[];

  public constructor(
    @IRouter private router: IRouter,
    @IAuthService private auth: IAuthService,
    @INavBarStructure private navBarStructure: INavBarStructure
  ) {
    this.routes = this.router?.rootScope?.getRoutes() ?? [];
  }

  attached() {
    initDrawers();
    initCollapses();
    initDropdowns();
  }

  private routeById(id: string) {
    return this.routes.find((route) => route.data['id'] === id).path;
  }
}